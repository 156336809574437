import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@material-ui/core/Typography';
interface IProps{
    closePopup:Function;
    products:any[];
    productLocation:any;
}
interface IState{
    open:boolean;
}

const BoxStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: 'rgb(241, 241, 241)',
    border: '2px solid #000',
    boxShadow: 24,
    padding: '4ch'
};

const ExitButtonStyle = {
    bgcolor: 'rgb(174, 180, 184)',
    color: 'white',
    fontWeight: 'bold',
    left:'94%'
}
export default class ProductsOrderedPopup extends React.Component<IProps,IState>{
    constructor(props:IProps){
        super(props);
        this.state = {
            open:true
        }
    }
    closePopup(){
        this.props.closePopup();
    }
    render(){
        return (
        <>
            <Modal id='warehouse-emp-modal'
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state.open}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}
                    
                  ><Box sx={BoxStyle}>  
                    <Button sx={ExitButtonStyle} onClick={this.closePopup.bind(this)}>X</Button>    
                    <br></br>
                    <Typography variant="h6" component="h2">
                        Products Ordered
                        </Typography>
                     <TableContainer component={Paper}>
                    <Table size="small" aria-label="Product info">
                            <TableHead>
                            <TableRow>
                                <TableCell>Quantity</TableCell>
                                <TableCell align="right">Size</TableCell>
                                <TableCell align="right">Per</TableCell>
                                <TableCell align="right">Product</TableCell>
                                <TableCell align="right">Code</TableCell>
                                {(this.props.productLocation && this.props.productLocation !== "Bulk") &&
                                <>
                                    <TableCell align="right">C&W</TableCell>
                                    <TableCell align="right">Double C&W</TableCell>
                                </> }
                                <TableCell align="right">Batch #</TableCell>
                                {/* <TableCell align="right">Status</TableCell> */}
                            </TableRow>
                        </TableHead>
                        {this.props.products.map((product) => { 
                        return <TableBody>
                            <TableRow>
                                <TableCell>{product.quantity}</TableCell>
                                <TableCell align="right">{product.chosenSize}</TableCell>
                                <TableCell align="right">{product.chosenPer}</TableCell>
                                <TableCell align="right">{product.chosenProd}</TableCell>
                                <TableCell align="right">{product.code}</TableCell>
                                {(this.props.productLocation && this.props.productLocation !== "Bulk") &&
                                <>
                                    <TableCell align="right">{product.c_w}</TableCell>
                                    <TableCell align="right">{product.d_c_w}</TableCell>
                                </>}
                                <TableCell align="right">{product.batch}</TableCell>
                                {/* <TableCell align="right">{product.status}</TableCell> */}
                            </TableRow>
                        </TableBody>
                         })}
                        </Table> 
                </TableContainer>   
          </Box>
            </Modal>
        </>
              
        );
    }
}