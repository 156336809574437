import { IUpdateCarrierEmailRequest, IUPDATE_CARRIER_EMAIL_REQUEST } from "./updateCarrierEmailConstaints";

export interface IUpdateCarrierEmailLoadAction {
    type: IUPDATE_CARRIER_EMAIL_REQUEST.REQUEST;
    data: IUpdateCarrierEmailRequest
}
export const updateCarrierEmailLoadAction = (data: IUpdateCarrierEmailRequest): IUpdateCarrierEmailLoadAction => ({
    type: IUPDATE_CARRIER_EMAIL_REQUEST.REQUEST,
    data
});

export interface IUpdateCarrierEmailSuccessAction {
    type: IUPDATE_CARRIER_EMAIL_REQUEST.SUCCESS;
    message: string;
}
export const updateCarrierEmailLoadSuccessAction = (message: string): IUpdateCarrierEmailSuccessAction => ({
    type: IUPDATE_CARRIER_EMAIL_REQUEST.SUCCESS,
    message
});

export interface IUpdateCarrierEmailLoadFailedAction {
    type: IUPDATE_CARRIER_EMAIL_REQUEST.FAILED;
    message: string;
}
export const updateCarrierEmailLoadFailedAction = (message: string): IUpdateCarrierEmailLoadFailedAction => ({
    type: IUPDATE_CARRIER_EMAIL_REQUEST.FAILED,
    message
});
