import decode from 'jwt-decode';

export default class AuthenticateLogin{
   
    setToken(response:any){
        localStorage.setItem('id_token',response.token);
        localStorage.setItem('customer',response.customer);
        localStorage.setItem('token_expiry_date',response.token_expiry_date);
    }
    getToken(){
        return localStorage.getItem('id_token');
    }
    getCustomer(){
        console.log(localStorage.getItem('customer'));
        return localStorage.getItem('customer');
    }
    // returns true if token is expired
    isTokenExpired(token:any) {
        try {
            let expired:any = localStorage.getItem('token_expiry_date');
            expired = new Date(expired).getMinutes()+(new Date(expired).getHours()*60) +(new Date(expired).getSeconds()/60);
            let elapsed:any = new Date().getMinutes() + (new Date().getHours()*60) + (new Date().getSeconds()/60);
            // Logout user if token is expired
            if(expired < elapsed){
                this.logout();
            }
            // if expired < elapsed, token is expired and will be true
            return (expired < elapsed);
        }
        catch(err){
            return false
        }
    }
    loggedIn(){
        const token = this.getToken();
        console.log('loggedin in: ', !!token && !this.isTokenExpired(token));
        return !!token && !this.isTokenExpired(token);
    }

    login(username:string,password:string){
        const requestOptions:any = {
            method:'POST',
            headers:{
                'Content-Type':'application/json'
            },
            body: JSON.stringify({request:{
                Username:username,
                Password:password,
                Token_Expiry_DateTime: new Date()
            },token: '19515195-d571-44a3-b0db-637f5bf24f54' })
            
        }
        if(this.loggedIn()){
            requestOptions.headers['Authorization'] = 'Bearer '+this.getToken();
            return true;
        }
        fetch('https://api2.propsense.com/api/loginExternalUser', requestOptions)
        .then(this._checkStatus)
        .then(response => {
            response.json().then((res:any) => {
                  if(res.isAuthenticated){
                     
                      this.setToken(res);
                    } 
                    return res.isAuthenticated;
                })
                .catch((err:any) => {
                    console.log(err.toString(), err)
                })
             })
        return false;
    }
    logout(){
        localStorage.removeItem('id_token');
        localStorage.removeItem('customer');
        localStorage.removeItem('token_expiry_date');
    }

    getProfile(){
        const decoded:any = this.getToken();
        return decode(decoded);
    }

    _checkStatus(response:any) {
        if(response.status >=200 && response.status <300){
            return response;
        } else {
            throw Error(response.statusText);
        }
    }
}