import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IGetProductsByIdLoadAction, IGetProductsByIdLoadFailedAction, IGetProductsByIdSuccessAction, 
    getProductsByIdLoadFailedAction, getProductsByIdLoadSuccessAction
} from "./getProductsByIdActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IGET_PRODUCTS_BY_ID_REQUEST, ISIMOSProductsById } from "./getProductsByIdConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SimosAPIResponse } from "../../../../react/shared/publicInterfaces";

export const getProductsByIdEpic: Epic = (
    action$: ActionsObservable<IGetProductsByIdLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IGetProductsByIdSuccessAction | IGetProductsByIdLoadFailedAction> =>
    action$.ofType(IGET_PRODUCTS_BY_ID_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SimosAPIResponse<ISIMOSProductsById[]>>(
                    END_POINTS.PROPSENSE.SIMOS.GET_PRODUCTS_BY_ID,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SimosAPIResponse<ISIMOSProductsById[]>): IGetProductsByIdSuccessAction | IGetProductsByIdLoadFailedAction => {
                            if(response.message !== "Success")
                               return getProductsByIdLoadFailedAction(response.message);
                            
                            return getProductsByIdLoadSuccessAction(response);
                        }),
                        catchError(() => ActionsObservable.of(getProductsByIdLoadFailedAction("Unable to get products")))
                    )
            )
        );