import { IUpdateCarrierFleetTrailerRequest, IUPDATE_CARRIER_FLEET_TRAILER_REQUEST } from "./updateCarrierFleetTrailerConstaints";

export interface IUpdateCarrierFleetTrailerLoadAction {
    type: IUPDATE_CARRIER_FLEET_TRAILER_REQUEST.REQUEST;
    data: IUpdateCarrierFleetTrailerRequest
}
export const updateCarrierFleetTrailerLoadAction = (data: IUpdateCarrierFleetTrailerRequest): IUpdateCarrierFleetTrailerLoadAction => ({
    type: IUPDATE_CARRIER_FLEET_TRAILER_REQUEST.REQUEST,
    data
});

export interface IUpdateCarrierFleetTrailerSuccessAction {
    type: IUPDATE_CARRIER_FLEET_TRAILER_REQUEST.SUCCESS;
    message: string;
}
export const updateCarrierFleetTrailerLoadSuccessAction = (message: string): IUpdateCarrierFleetTrailerSuccessAction => ({
    type: IUPDATE_CARRIER_FLEET_TRAILER_REQUEST.SUCCESS,
    message
});

export interface IUpdateCarrierFleetTrailerLoadFailedAction {
    type: IUPDATE_CARRIER_FLEET_TRAILER_REQUEST.FAILED;
    message: string;
}
export const updateCarrierFleetTrailerLoadFailedAction = (message: string): IUpdateCarrierFleetTrailerLoadFailedAction => ({
    type: IUPDATE_CARRIER_FLEET_TRAILER_REQUEST.FAILED,
    message
});
