import { IADD_SIMOS_CUSTOMER_CONTACT_REQUEST, IAddSimosCustomerContactRequest } from "./addSimosCustomerContactConstaints";

export interface IAddSimosCustomerContactLoadAction {
    type: IADD_SIMOS_CUSTOMER_CONTACT_REQUEST.REQUEST;
    data: IAddSimosCustomerContactRequest
}
export const addSimosCustomerContactLoadAction = (data: IAddSimosCustomerContactRequest): IAddSimosCustomerContactLoadAction => ({
    type: IADD_SIMOS_CUSTOMER_CONTACT_REQUEST.REQUEST,
    data
});

export interface IAddSimosCustomerContactSuccessAction {
    type: IADD_SIMOS_CUSTOMER_CONTACT_REQUEST.SUCCESS;
    message: string;
}
export const addSimosCustomerContactLoadSuccessAction = (message: string): IAddSimosCustomerContactSuccessAction => ({
    type: IADD_SIMOS_CUSTOMER_CONTACT_REQUEST.SUCCESS,
    message
});

export interface IAddSimosCustomerContactLoadFailedAction {
    type: IADD_SIMOS_CUSTOMER_CONTACT_REQUEST.FAILED;
    message: string;
}
export const addSimosCustomerContactLoadFailedAction = (message: string): IAddSimosCustomerContactLoadFailedAction => ({
    type: IADD_SIMOS_CUSTOMER_CONTACT_REQUEST.FAILED,
    message
});
