import { IPassTokenWithRequest } from "../../../../sil/dryPlant/token/getTokenConstants";

export enum IADD_YHD_CARRIER_EMAIL_REQUEST {
    REQUEST = "addYHDCarrierEmail/ADD_YHD_CARRIER_EMAIL_REQUEST",
    SUCCESS = "addYHDCarrierEmail/ADD_YHD_CARRIER_EMAIL_SUCCESS",
    FAILED = "addYHDCarrierEmail/ADD_YHD_CARRIER_EMAIL_FAILED"
};

export interface IAddYHDCarrierEmailRequest extends IPassTokenWithRequest {
    request: {
        subject: string;
        email_Body: string;
        email_All_Carriers: string;
        carriers: number[];
        attachment?: string;
        attachment_Name?: string;
        created_By: string;
        modified_By: string;
        attachments: IAddYHDCarrierEmailAttachment[];
    };
};

export interface IAddYHDCarrierEmailAttachment {
    Name: string;
    Base64String: string;
};