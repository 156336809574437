import { ISieveWashPlant } from "../getSieveWashPlants/getSieveWashPlantsConstaints";
import { IUpdateSieveWashPlantRequest, IUPDATE_SIEVE_WASH_PLANT_REQUEST } from "./updateSieveWashPlantConstaints";

export interface IUpdateSieveWashPlantLoadAction {
    type: IUPDATE_SIEVE_WASH_PLANT_REQUEST.REQUEST;
    data: IUpdateSieveWashPlantRequest
}
export const updateSieveWashPlantLoadAction = (data: IUpdateSieveWashPlantRequest): IUpdateSieveWashPlantLoadAction => ({
    type: IUPDATE_SIEVE_WASH_PLANT_REQUEST.REQUEST,
    data
});

export interface IUpdateSieveWashPlantSuccessAction {
    type: IUPDATE_SIEVE_WASH_PLANT_REQUEST.SUCCESS;
    data: ISieveWashPlant;
}
export const updateSieveWashPlantLoadSuccessAction = (data: ISieveWashPlant): IUpdateSieveWashPlantSuccessAction => ({
    type: IUPDATE_SIEVE_WASH_PLANT_REQUEST.SUCCESS,
    data
});

export interface IUpdateSieveWashPlantLoadFailedAction {
    type: IUPDATE_SIEVE_WASH_PLANT_REQUEST.FAILED;
    message: string;
}
export const updateSieveWashPlantLoadFailedAction = (message: string): IUpdateSieveWashPlantLoadFailedAction => ({
    type: IUPDATE_SIEVE_WASH_PLANT_REQUEST.FAILED,
    message
});
