import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IAddYHDCarrierInfractionLoadAction, IAddYHDCarrierInfractionLoadFailedAction, IAddYHDCarrierInfractionSuccessAction, addYHDCarrierInfractionLoadFailedAction,
     addYHDCarrierInfractionLoadSuccessAction } from "./addCarrierInfractionActions";
import { IStore } from "../../../../reducers";
import { EpicDependencies } from "../../../../store";
import { IADD_YHD_CARRIER_INFRACTION_REQUEST } from "./addCarrierInfractionConstaints";
import { END_POINTS } from "../../../../endpoints";
import { withJsonContentType } from "../../../../epicUtils";
import { YHDAPIResponse } from "../../../../../react/shared/publicInterfaces";

export const addYHDCarrierInfractionEpic: Epic = (
    action$: ActionsObservable<IAddYHDCarrierInfractionLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IAddYHDCarrierInfractionSuccessAction | IAddYHDCarrierInfractionLoadFailedAction> =>
    action$.ofType(IADD_YHD_CARRIER_INFRACTION_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<YHDAPIResponse<string>>(
                    END_POINTS.PROPSENSE.YHD.CARRIER.ADD_CARRIER_INFRACTIONS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: YHDAPIResponse<string>): IAddYHDCarrierInfractionSuccessAction | IAddYHDCarrierInfractionLoadFailedAction => {
                            
                            if(response.message === "Success"){
                                return addYHDCarrierInfractionLoadSuccessAction(response.message);
                            } else {
                                return addYHDCarrierInfractionLoadFailedAction(response.message);
                            }
                        }),
                        catchError(() => ActionsObservable.of(addYHDCarrierInfractionLoadFailedAction("Unable to add your changes")))
                    )
            )
        );