import { ById, SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetSieveChecksRequest, IGET_SIEVES_CHECKS_REQUEST, ISieveCheck } from "./getSievesChecksConstaints";

export interface IGetSieveChecksLoadAction {
    type: IGET_SIEVES_CHECKS_REQUEST.REQUEST;
    data: IGetSieveChecksRequest
}
export const getSieveChecksLoadAction = (data: IGetSieveChecksRequest): IGetSieveChecksLoadAction => ({
    type: IGET_SIEVES_CHECKS_REQUEST.REQUEST,
    data
});

export interface IGetSieveChecksSuccessAction {
    type: IGET_SIEVES_CHECKS_REQUEST.SUCCESS;
    data: SilAPIResponse<ById<ISieveCheck>>;
}
export const getSieveChecksLoadSuccessAction = (data: SilAPIResponse<ById<ISieveCheck>>): IGetSieveChecksSuccessAction => ({
    type: IGET_SIEVES_CHECKS_REQUEST.SUCCESS,
    data
});

export interface IGetSieveChecksLoadFailedAction {
    type: IGET_SIEVES_CHECKS_REQUEST.FAILED;
    message: string;
}
export const getSieveChecksLoadFailedAction = (message: string): IGetSieveChecksLoadFailedAction => ({
    type: IGET_SIEVES_CHECKS_REQUEST.FAILED,
    message
});
