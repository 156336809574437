import { ById, SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IGET_SIEVES_REQUEST, ISieve } from "./getSievesConstaints";
import { IGetSievesLoadAction, IGetSievesLoadFailedAction, IGetSievesSuccessAction } from "./getSievesActions";
import { IUPDATE_SIEVE_REQUEST } from "../updateSieve/updateSieveConstaints";
import { IADD_SIEVE_REQUEST } from "../addSieve/addSieveConstaints";
import { IAddSieveSuccessAction } from "../addSieve/addSieveActions";
import { IUpdateSieveSuccessAction } from "../updateSieve/updateSieveActions";

type Actions =
    IGetSievesLoadAction
    | IGetSievesLoadFailedAction
    | IGetSievesSuccessAction
    | IAddSieveSuccessAction
    | IUpdateSieveSuccessAction
    | IFlushDataSuccessAction;

export const GetSievesReducer = (state: Server<SilAPIResponse<ById<ISieve>>> = notLoaded, action: Actions): Server<SilAPIResponse<ById<ISieve>>> => {
    switch (action.type) {
        case IGET_SIEVES_REQUEST.REQUEST:
            return loading;

        case IGET_SIEVES_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_SIEVES_REQUEST.FAILED:
            return failed(action.message);

        case IUPDATE_SIEVE_REQUEST.SUCCESS:
            return notLoaded;

        case IADD_SIEVE_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};