import { IPassTokenWithRequest } from "../../dryPlant/token/getTokenConstants";
import { IAddSieveMeasurementRequest } from "../addSieve/addSieveConstaints";

export enum IUPDATE_SIEVE_REQUEST {
    REQUEST = "updateSieve/UPDATE_SIEVE_REQUEST",
    SUCCESS = "updateSieve/UPDATE_SIEVE_SUCCESS",
    FAILED = "updateSieve/UPDATE_SIEVE_FAILED"
};

export interface IUpdateSieveRequest extends IPassTokenWithRequest {
    request: IUpdateSieve;
};

export interface IUpdateSieve {
    ID: number;
    Date: string;
    Operator: string;
    Bin?: string;
    Plant: string;
    Product: string;
    Notes: string;
    Load_No: string;
    Feed_Material_1?: string;
    Feed_Material_2?: string;
    Feed_Material_3?: string;
    Feed_Material_4?: string;
    Feed_Material_1_Perc?: number;
    Feed_Material_2_Perc?: number;
    Feed_Material_3_Perc?: number;
    Feed_Material_4_Perc?: number;
    Cancelled: string;
    Wash_Mass?: number;
    Wash_NTU?: number;
    Duplicate_CantyID?: number;
    Screen_Section?: string;
    Initial_Mass: number;
    Split_Mass?: number;
    Perc_Fines?: number;
    Rotap_ID?: number;
    Created_By: string;         
    Modified_By: string;
    Measurements: IAddSieveMeasurementRequest[];
};