import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetCarrierEmailsRequest, IGET_CARRIER_EMAILS_REQUEST, ICarrierEmail } from "./getCarrierEmailsConstaints";

export interface IGetCarrierEmailsLoadAction {
    type: IGET_CARRIER_EMAILS_REQUEST.REQUEST;
    data: IGetCarrierEmailsRequest
}
export const getCarrierEmailsLoadAction = (data: IGetCarrierEmailsRequest): IGetCarrierEmailsLoadAction => ({
    type: IGET_CARRIER_EMAILS_REQUEST.REQUEST,
    data
});

export interface IGetCarrierEmailsSuccessAction {
    type: IGET_CARRIER_EMAILS_REQUEST.SUCCESS;
    data: SilAPIResponse<ICarrierEmail[]>;
}
export const getCarrierEmailsLoadSuccessAction = (data: SilAPIResponse<ICarrierEmail[]>): IGetCarrierEmailsSuccessAction => ({
    type: IGET_CARRIER_EMAILS_REQUEST.SUCCESS,
    data
});

export interface IGetCarrierEmailsLoadFailedAction {
    type: IGET_CARRIER_EMAILS_REQUEST.FAILED;
    message: string;
}
export const getCarrierEmailsLoadFailedAction = (message: string): IGetCarrierEmailsLoadFailedAction => ({
    type: IGET_CARRIER_EMAILS_REQUEST.FAILED,
    message
});
