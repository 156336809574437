import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IGetWashPlantProductionSummaryLoadAction, IGetWashPlantProductionSummaryLoadFailedAction, IGetWashPlantProductionSummarySuccessAction, 
    getWashPlantProductionSummaryLoadFailedAction, getWashPlantProductionSummaryLoadSuccessAction
} from "./getWashPlantProductionSummaryActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IGET_WASH_PLANT_PRODUCTION_SUMMARY_REQUEST, IWashPlantProductionSummary } from "./getWashPlantProductionSummaryConstants";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";

export const getWashPlantProductionSummaryEpic: Epic = (
    action$: ActionsObservable<IGetWashPlantProductionSummaryLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IGetWashPlantProductionSummarySuccessAction | IGetWashPlantProductionSummaryLoadFailedAction> =>
    action$.ofType(IGET_WASH_PLANT_PRODUCTION_SUMMARY_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<IWashPlantProductionSummary[]>>(
                    END_POINTS.PROPSENSE.SIL.WASH_PLANT.GET_WASH_PLANT_PRODUCTION_SUMMARY,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<IWashPlantProductionSummary[]>): IGetWashPlantProductionSummarySuccessAction => {
                            return getWashPlantProductionSummaryLoadSuccessAction(response);
                        }),
                        catchError(() => ActionsObservable.of(getWashPlantProductionSummaryLoadFailedAction("Unable to getWashPlant production summary")))
                    )
            )
        );