import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IAddWashPlantRunMaterialLoadAction, IAddWashPlantRunMaterialLoadFailedAction, IAddWashPlantRunMaterialSuccessAction, addWashPlantRunMaterialLoadFailedAction,
     addWashPlantRunMaterialLoadSuccessAction } from "./addWashPlantRunMaterialActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IADD_WASH_PLANT_RUN_MATERIAL_REQUEST } from "./addWashPlantRunMaterialConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";

export const addWashPlantRunMaterialEpic: Epic = (
    action$: ActionsObservable<IAddWashPlantRunMaterialLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IAddWashPlantRunMaterialSuccessAction | IAddWashPlantRunMaterialLoadFailedAction> =>
    action$.ofType(IADD_WASH_PLANT_RUN_MATERIAL_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<string>>(
                    END_POINTS.PROPSENSE.SIL.WASH_PLANT.ADD_WASH_PLANT_RUN_MATERIALS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<string>): IAddWashPlantRunMaterialSuccessAction | IAddWashPlantRunMaterialLoadFailedAction => {
                            
                            if(response.message === "Success"){
                                return addWashPlantRunMaterialLoadSuccessAction(response.message);
                            }
                            return addWashPlantRunMaterialLoadFailedAction(response.message);
                        }),
                        catchError(() => ActionsObservable.of(addWashPlantRunMaterialLoadFailedAction("Unable to save your changes")))
                    )
            )
        );