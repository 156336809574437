import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithLessPadding, LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import { ById, SilAPIResponse } from "../../../shared/publicInterfaces";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import { callRouteWithQueryString, userName } from "../../../shared/constExports";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { LAButton } from "../../../shared/buttons";
import { AddIcon } from "../../../shared/icons";
import { WHITE_COLOR } from "../../../shared/theme";
import { ROUTE } from "../../../routes";
import PageSpacing from "../../../shared/pageSpacing";
import { IGetSieveProductsRequest, ISieveProduct } from "../../../../redux/sil/sieves/getSievesProducts/getSievesProductsConstaints";
import { getSieveProductsLoadAction } from "../../../../redux/sil/sieves/getSievesProducts/getSievesProductsActions";
import { getSieveProductsStatus } from "../../../../redux/sil/sieves/getSievesProducts/getSievesProductsAccessor";
import LALoading from "../../../shared/loading";
import SilRoleCheck from "../../../shared/silRoleCheck";
import { LAPopover } from "../../../shared/popOver";
import DataGrid, { Column } from "devextreme-react/data-grid";

interface ISievesProductsStoreProps {
    getToken: Server<SilAPIResponse<IToken>>;
    getSievesProducts: Server<SilAPIResponse<ById<ISieveProduct>>>;
};

interface ISievesProductsDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getSievesProductsRequest: (data: IGetSieveProductsRequest) => unknown;
};


interface ISievesProductsOwnProps {

};

interface ISievesProductsState {
    data: ISieveProduct[];
    popupId: number | undefined;
};

const SievesProductsStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
    };
`;

type ISievesProductsProps = RouteComponentProps
    & ISievesProductsStoreProps
    & ISievesProductsDispatchProps
    & ISievesProductsOwnProps;

class SievesProducts extends PureComponent<ISievesProductsProps, ISievesProductsState> {

    public constructor(props: ISievesProductsProps) {
        super(props);
        this.state = {
            data: [],
            popupId: undefined
        };
    }

    public componentDidMount(): void {
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: ISievesProductsProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();
        };
    };


    public render(): ReactNode {

        const { data, popupId } = this.state;
        const { getSievesProducts } = this.props;
        const popup = popupId ? data.find(x => x.id === popupId) : undefined;

        return (
            <PageSpacing title="SIL - Sieves Products" description="SIL - Sieves Products" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="sieveAccess">
                    <SievesProductsStyles>

                        {getSievesProducts.kind !== STATUS_ENUM.LOADING &&
                            <SilRoleCheck error={false} roleFor="sieveAccess">
                                <LAButton className="pull-left" startIcon={<AddIcon color={WHITE_COLOR} />} label="Add" onClick={this.handleAdd} />
                            </SilRoleCheck>}

                        <h2 className="text-center">PRODUCTS</h2>
                        <hr />

                        {getSievesProducts.kind === STATUS_ENUM.LOADING &&
                            <LALoading className="loading" message="Loading..." />}

                        {getSievesProducts.kind !== STATUS_ENUM.LOADING && <LADevExtremeGrid
                            data={data}
                            onEdit={this.onEdit}
                            onClick={this.onClick}
                            height={1000}
                            searchPanel={true}
                            filterHeader={true}
                            export={true}
                            onCustomClick={this.handlePopup}
                            exportFileName="SievesProducts"
                            storageKey="silSievesProductsStorageKey"
                            columns={[
                                { name: "product", caption: "Product", type: "string" },
                                { name: "plant_Type", caption: "Plant Type", type: "string" },
                                { name: "items", caption: "Items", type: "button" },
                                { name: "created", caption: "Created", type: "date" },
                                { name: "created_By", caption: "Created By", type: "string" },
                                { name: "modified", caption: "Modified", type: "date" },
                                { name: "modified_By", caption: "Modified By", type: "string" }
                            ]}
                        />}
                    </SievesProductsStyles>

                    {popup && <LAPopover open={popup ? true : false} onClose={this.handlePopupClose} anchorRef={null} transformOrigin={{ vertical: "top", horizontal: "center" }} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                        <LAPaperWithLessPadding>
                            <h3 className="text-center">{popup.product} Items</h3>

                            <DataGrid
                                keyExpr="id"
                                showBorders={true}
                                columnAutoWidth={true}
                                dataSource={popup.items}
                            >
                                <Column dataField="mesh" caption="Mesh" allowEditing={false} dataType="string" />
                                <Column dataField="perc_Ret_Spec" caption="Perc Ret Spec" allowEditing={false} dataType="number" />
                                <Column dataField="perc_Pass_Spec" caption="Perc Pass Spec" allowEditing={false} dataType="string" />
                            </DataGrid>
                        </LAPaperWithLessPadding>
                    </LAPopover>}

                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private handlePopupClose = (): void => {
        this.setState({ popupId: undefined });
    };

    private handlePopup = (name: string, e: any): void => {
        this.setState({
            popupId: e.row.data.id
        });
    };

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString() },
            pathName: ROUTE.SIL.SIEVES.SIEVE_PRODUCT
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0" },
            pathName: ROUTE.SIL.SIEVES.SIEVE_PRODUCT
        });
    };

    private setDataToState = (): void => {

        if (hasPayload(this.props.getSievesProducts)) {
            const data: ISieveProduct[] = Object.values(this.props.getSievesProducts.payload.response);
            this.setState({ data });
        };

        if (isNotLoaded(this.props.getSievesProducts))
            this.callServer();
    };

    private callServer = (): void => {
        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getSievesProducts))
            this.props.getSievesProductsRequest({
                token: this.props.getToken.payload.response.token
            });


        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });
    }

}

const mapStateToProps = (state: IStore): ISievesProductsStoreProps => ({
    getToken: getTokenStatus(state),
    getSievesProducts: getSieveProductsStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): ISievesProductsDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getSievesProductsRequest: (data: IGetSieveProductsRequest) => dispatch(getSieveProductsLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(SievesProducts);