import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IGetYHDCarrierFleetTruckLoadAction, IGetYHDCarrierFleetTruckLoadFailedAction, IGetYHDCarrierFleetTruckSuccessAction, 
    getYHDCarrierFleetTruckLoadFailedAction, getYHDCarrierFleetTruckLoadSuccessAction
} from "./getCarrierFleetTruckActions";
import { IStore } from "../../../../reducers";
import { EpicDependencies } from "../../../../store";
import { IGET_YHD_CARRIER_FLEET_TRUCK_REQUEST, IYHDCarrierFleetTruck } from "./getCarrierFleetTruckConstaints";
import { END_POINTS } from "../../../../endpoints";
import { withJsonContentType } from "../../../../epicUtils";
import { YHDAPIResponse } from "../../../../../react/shared/publicInterfaces";

export const getYHDCarrierFleetTruckEpic: Epic = (
    action$: ActionsObservable<IGetYHDCarrierFleetTruckLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IGetYHDCarrierFleetTruckSuccessAction | IGetYHDCarrierFleetTruckLoadFailedAction> =>
    action$.ofType(IGET_YHD_CARRIER_FLEET_TRUCK_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<YHDAPIResponse<IYHDCarrierFleetTruck[]>>(
                    END_POINTS.PROPSENSE.YHD.CARRIER.GET_CARRIER_FLEET_TRUCKS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: YHDAPIResponse<IYHDCarrierFleetTruck[]>): IGetYHDCarrierFleetTruckSuccessAction => {
                            return getYHDCarrierFleetTruckLoadSuccessAction(response);
                        }),
                        catchError(() => ActionsObservable.of(getYHDCarrierFleetTruckLoadFailedAction("Unable to get carrier fleet truck")))
                    )
            )
        );