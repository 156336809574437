import { IBinAdjustments } from "../getBinAdjustments/getBinAdjustmentsConstaints";

export enum IUPDATE_BIN_ADJUSTMENT_REQUEST {
    REQUEST = "updateBinAdjustment/UPDATE_BIN_ADJUSTMENT_REQUEST",
    SUCCESS = "updateBinAdjustment/UPDATE_BIN_ADJUSTMENT_SUCCESS",
    FAILED = "updateBinAdjustment/UPDATE_BIN_ADJUSTMENT_FAILED"
};

export interface IUpdateBinAdjustmentRequest {
    token: string;
    request: IBinAdjustments;
};