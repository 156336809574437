import React from 'react';
import DataGrid, {
    Column, FilterRow, HeaderFilter, 
    Paging, Button, Pager, SearchPanel, 
    FilterPanel,
    FilterBuilderPopup,
    Export,
  } from 'devextreme-react/data-grid';
  import 'devextreme/dist/css/dx.light.css';

  const renderTitleHeader = (data:any) => {
    return <p style={{color:'#5383d3',font: 'Cookie',fontSize:'14px'}}>{data.column.caption}</p>
}
function StyledComponent(cellData:any):any {
    return (
        <div className={"devex-styled-grid-row-default"}>{cellData.value}</div>
    )
}
const filterBuilderPopupPosition = {
    of: window,
    at: 'top',
    my: 'top',
    offset: { y: 10 },
  };
interface IProps {
    history:any;
}
interface IState {
    productHistory:any;
    searchValue:string;
}
export default class ProductHistoryTable extends React.Component<IProps,IState>{
    constructor(props:IProps){
        super(props);
        this.state = {productHistory:[], searchValue: ''}

    }
    componentDidMount(){
        var n = window.location.pathname.lastIndexOf('=');
        var productID = Number(window.location.pathname.substring(n + 1));
        var productHistory:any = [];
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type':'application/json' }, 
            body: JSON.stringify({token: '19515195-d571-44a3-b0db-637f5bf24f54', id:productID})
        }
        fetch('https://api2.propsense.com/api/GetSIMOSOrderProductsVersionHistory', requestOptions)
        .then(async response => {
            const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
              // error response
              if(!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            // no error
            data.map((history:any) => {
                history.created = history.created.replace("T"," ");
                history.modified = history.modified.replace("T"," ");
                productHistory.push(history);
            });
            this.setState({productHistory:productHistory});
        })
    }

    handleValueChange = (e:any) => {
        // console.log(e);
        const trimmedValue = e.trim();
        this.setState({searchValue: trimmedValue});
      };

    render(){
        console.log(this.state.productHistory);
        return (
            <div className='grid-view'>
            <br></br><br></br>
                <div style={{width:'100%'}}>
                    <DataGrid id={'gridContainer'}
                    focusedRowEnabled={true}
                    hoverStateEnabled={true}
                    rowAlternationEnabled={true}
                        dataSource={this.state.productHistory}
                        keyExpr="id"
                        showBorders={true}
                        // took out height={740}
                        width={'100%'}> 
                    <SearchPanel visible={true}
                        width={340}
                        placeholder="Search for..." 
                        text={this.state.searchValue}
                        onTextChange={this.handleValueChange}
                        />    
                    <Paging enabled={true} defaultPageSize={10}/>
                    <Pager
                        visible={true}
                        allowedPageSizes={[10,15,20]}
                        displayMode={'full'}
                        showPageSizeSelector={true}
                        showInfo={true}
                        showNavigationButtons={true} />
                        <FilterRow visible={true}></FilterRow>
                        <FilterBuilderPopup position={filterBuilderPopupPosition} />
                    <HeaderFilter visible={true} allowSearch={true} />
                    <FilterPanel visible={true} />
                        <Export enabled={true} allowExportSelectedData={true} />
                        <Column dataField="modified" width={300} caption="Modified" alignment="left"  cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
                        <Column dataField="modified_By" width={300} caption="Modified By" alignment="left"  cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
                        <Column dataField="status" width={300} caption="Status" alignment="left"  cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
                        <Column dataField="product" width={300} caption="Product" alignment="left"  cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
                        <Column dataField="quantity" width={300} caption="Quantity" alignment="left"  cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
                        <Column dataField="size" width={300} caption="Size" alignment="left"  cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
                        <Column dataField="per" width={300} caption="Per" alignment="left"  cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
                        <Column dataField="code" width={300} caption="Code" alignment="left"  cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
                        <Column dataField="cw" width={300} caption="C&W" alignment="left"  cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
                        <Column dataField="double_CW" width={300} caption="Double C&W" alignment="left"  cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
                        <Column dataField="actual_Quantity" width={300} caption="Actual Quantity" alignment="left"  cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
                        <Column dataField="status" width={300} caption="Status" alignment="left"  cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
                    </DataGrid>
                </div>
            </div>
        );
    }
}