import { ById, SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetSievesRequest, IGET_SIEVES_REQUEST, ISieve } from "./getSievesConstaints";

export interface IGetSievesLoadAction {
    type: IGET_SIEVES_REQUEST.REQUEST;
    data: IGetSievesRequest
}
export const getSievesLoadAction = (data: IGetSievesRequest): IGetSievesLoadAction => ({
    type: IGET_SIEVES_REQUEST.REQUEST,
    data
});

export interface IGetSievesSuccessAction {
    type: IGET_SIEVES_REQUEST.SUCCESS;
    data: SilAPIResponse<ById<ISieve>>;
}
export const getSievesLoadSuccessAction = (data: SilAPIResponse<ById<ISieve>>): IGetSievesSuccessAction => ({
    type: IGET_SIEVES_REQUEST.SUCCESS,
    data
});

export interface IGetSievesLoadFailedAction {
    type: IGET_SIEVES_REQUEST.FAILED;
    message: string;
}
export const getSievesLoadFailedAction = (message: string): IGetSievesLoadFailedAction => ({
    type: IGET_SIEVES_REQUEST.FAILED,
    message
});
