import { IWashPlantProductionRecords } from "../getWashPlantProductionRecords/getWashPlantProductionRecordsConstaints";

export enum IUPDATE_WASH_PLANT_PRODUCTION_REQUEST {
    REQUEST = "updateWashPlantProduction/UPDATE_WASH_PLANT_PRODUCTION_REQUEST",
    SUCCESS = "updateWashPlantProduction/UPDATE_WASH_PLANT_PRODUCTION_SUCCESS",
    FAILED = "updateWashPlantProduction/UPDATE_WASH_PLANT_PRODUCTION_FAILED"
};

export interface IUpdateWashPlantProductionRequest {
    token: string;
    request: IWashPlantProductionRecords;
};