import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IGetSalesOrderNumbersByCustomerIDLoadAction, IGetSalesOrderNumbersByCustomerIDLoadFailedAction, IGetSalesOrderNumbersByCustomerIDSuccessAction, 
    getSalesOrderNumbersByCustomerIDLoadFailedAction, getSalesOrderNumbersByCustomerIDLoadSuccessAction
} from "./getSalesOrderNumbersByCustomerIDActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IGET_SALES_ORDER_NUMBER_BY_CUSTOMER_ID_REQUEST, ISalesOrderNumbersByCustomerID } from "./getSalesOrderNumbersByCustomerIDConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SimosAPIResponse } from "../../../../react/shared/publicInterfaces";

export const getSalesOrderNumbersByCustomerIDEpic: Epic = (
    action$: ActionsObservable<IGetSalesOrderNumbersByCustomerIDLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IGetSalesOrderNumbersByCustomerIDSuccessAction | IGetSalesOrderNumbersByCustomerIDLoadFailedAction> =>
    action$.ofType(IGET_SALES_ORDER_NUMBER_BY_CUSTOMER_ID_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SimosAPIResponse<ISalesOrderNumbersByCustomerID[]>>(
                    END_POINTS.PROPSENSE.SIMOS.GET_SALES_ORDER_NUMBERS_BY_CUSTOMER_ID,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SimosAPIResponse<ISalesOrderNumbersByCustomerID[]>): IGetSalesOrderNumbersByCustomerIDSuccessAction | IGetSalesOrderNumbersByCustomerIDLoadFailedAction => {
                            if(response.message !== "Success")
                                return getSalesOrderNumbersByCustomerIDLoadFailedAction(response.message);
                            return getSalesOrderNumbersByCustomerIDLoadSuccessAction(response);
                        }),
                        catchError(() => ActionsObservable.of(getSalesOrderNumbersByCustomerIDLoadFailedAction("Unable to get sales order numbers by customer")))
                    )
            )
        );