import { IADD_SIMOS_PRODUCT_REQUEST } from "./addSimosProductConstaints";
import { IAddSimosProductLoadAction, IAddSimosProductLoadFailedAction, IAddSimosProductSuccessAction } from "./addSimosProductActions";
import { failed, loading, notLoaded, Server, succeeded } from "../../../../server";
import { IFlushDataSuccessAction } from '../../../../flushData.tsx/flushDataActions';
import { FLUSH_DATA } from '../../../../flushData.tsx/flushDataConstants';

type Actions =
    IAddSimosProductLoadAction
    | IAddSimosProductLoadFailedAction
    | IAddSimosProductSuccessAction
    | IFlushDataSuccessAction;

export const AddSimosProductReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IADD_SIMOS_PRODUCT_REQUEST.REQUEST:
            return loading;

        case IADD_SIMOS_PRODUCT_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IADD_SIMOS_PRODUCT_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};