import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../../shared/paper";
import { IDispatch, IStore } from "../../../../../redux/reducers";
import { hasPayload, isNotLoaded, isSucceeded, Server } from "../../../../../redux/server";
import { ById, YHDAPIResponse } from "../../../../shared/publicInterfaces";
import { userName, undefinedFunction, yesOrNoOptions, deleteText } from "../../../../shared/constExports";
import { LAButton, LAIconButton, LASaveAndCancelButton } from "../../../../shared/buttons";
import { ArrowLeftIcon, DeleteIcon } from "../../../../shared/icons";
import { WHITE_COLOR } from "../../../../shared/theme";
import LAGrid from "../../../../shared/grid";
import LAGridItem from "../../../../shared/gridList";
import LATextField from "../../../../shared/textField";
import LAAutoComplete from "../../../../shared/autoComplete";
import { ROUTE } from "../../../../routes";
import queryString from "query-string";
import { updateYHDCarrierFleetTruckLoadAction } from "../../../../../redux/propsense/yhd/carrier/updateCarrierFleetTruck/updateCarrierFleetTruckActions";
import { IUpdateYHDCarrierFleetTruckRequest } from "../../../../../redux/propsense/yhd/carrier/updateCarrierFleetTruck/updateCarrierFleetTruckConstaints";
import { IAddYHDCarrierFleetTruckRequest } from "../../../../../redux/propsense/yhd/carrier/addCarrierFleetTruck/addCarrierFleetTruckConstaints";
import RequestStatus from "../../../../shared/requestStatusSnackbar";
import { FieldValidator, FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../../../shared/fieldValidator";
import PageSpacing from "../../../../shared/pageSpacing";
import { IGetTokenRequest, IToken } from "../../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenStatus } from "../../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { getTokenLoadAction } from "../../../../../redux/sil/dryPlant/token/getTokenActions";
import { END_POINTS } from "../../../../../redux/endpoints";
import { getYHDCarrierLookupsStatus } from "../../../../../redux/propsense/yhd/carrier/getCarrierLookups/getCarrierLookupsAccessor";
import { getYHDCarrierLookupsLoadAction } from "../../../../../redux/propsense/yhd/carrier/getCarrierLookups/getCarrierLookupsActions";
import { IYHDCarrierLookup, IGetYHDCarrierLookupsRequest } from "../../../../../redux/propsense/yhd/carrier/getCarrierLookups/getCarrierLookupsConstaints";
import SilRoleCheck from "../../../../shared/silRoleCheck";
import { LADropDone } from "../../../../shared/dragDropFiles";
import LALinkButton from "../../../../shared/linkButton";
import { IGetYHDCarrierFleetTruckRequest, IYHDCarrierFleetTruck } from "../../../../../redux/propsense/yhd/carrier/getCarrierFleetTruck/getCarrierFleetTruckConstaints";
import { getYHDCarrierFleetTruckStatus } from "../../../../../redux/propsense/yhd/carrier/getCarrierFleetTruck/getCarrierFleetTruckAccessor";
import { getYHDAddCarrierFleetTruckStatus } from "../../../../../redux/propsense/yhd/carrier/addCarrierFleetTruck/addCarrierFleetTruckAccessor";
import { getYHDUpdateCarrierFleetTruckStatus } from "../../../../../redux/propsense/yhd/carrier/updateCarrierFleetTruck/updateCarrierFleetTruckAccessor";
import { addYHDCarrierFleetTruckLoadAction } from "../../../../../redux/propsense/yhd/carrier/addCarrierFleetTruck/addCarrierFleetTruckActions";
import { getYHDCarrierFleetTruckLoadAction } from "../../../../../redux/propsense/yhd/carrier/getCarrierFleetTruck/getCarrierFleetTruckActions";
import { LADateTimePicker } from "../../../../shared/dateTimePicker";
import { IIdName } from "../../../../../utils/sharedTypes";

const requiredFields = ["asgA_No"];

interface ICarrierFleetTruckStoreProps {
    addCarrierFleetTruck: Server<string>;
    updateCarrierFleetTruck: Server<string>;
    getToken: Server<YHDAPIResponse<IToken>>;
    getCarrierFleetTrucks: Server<YHDAPIResponse<IYHDCarrierFleetTruck[]>>;
    getCarrierLookups: Server<YHDAPIResponse<IYHDCarrierLookup[]>>;
};

interface ICarrierFleetTruckDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getCarrierFleetTrucksRequest: (data: IGetYHDCarrierFleetTruckRequest) => unknown;
    addCarrierFleetTruckRequest: (data: IAddYHDCarrierFleetTruckRequest) => unknown;
    updateCarrierFleetTruckRequest: (data: IUpdateYHDCarrierFleetTruckRequest) => unknown;
    getCarrierLookupsRequest: (data: IGetYHDCarrierLookupsRequest) => unknown;
};


interface ICarrierFleetTruckOwnProps {
    id?: number;
    carrierName?: string;
    onClose?: () => void;
};

interface ICarrierFleetTruckState {
    fleetTruck: IYHDCarrierFleetTruck;
    carriers: IYHDCarrierLookup[];
    errors: ById<IFieldErrorKeyValue>;
};

const CarrierFleetTruckStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
`;

type ICarrierFleetTruckProps = RouteComponentProps
    & ICarrierFleetTruckStoreProps
    & ICarrierFleetTruckDispatchProps
    & ICarrierFleetTruckOwnProps;

class YHDCarrierFleetTruck extends PureComponent<ICarrierFleetTruckProps, ICarrierFleetTruckState> {

    public constructor(props: ICarrierFleetTruckProps) {
        super(props);
        this.state = {
            errors: {
                "asgA_No": { key: "asgA_No", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "fleet_Trucks": { key: "fleet_Trucks", message: FIELD_VALIDATOR_ERRORS.REQUIRED }
            },
            fleetTruck: {
                id: 0,
                asgA_No: "",
                // truck_Type: "",
                // truck_Registration_No: "",
                // truck_Registration_Expiry: "",
                // truck_Registration_File_Name: "",
                // trailer_Inspection_File_Name: "",
                // truck_Inspection_Date: "",
                fleet_Trucks: this.props.carrierName,
                // truck_Inspection_File_Name: "",
                // trailer_Inspection_Date: "",
                active: "Yes",
                created: "",
                created_By: userName,
                modified: "",
                modified_By: userName
            },
            carriers: []
        };
    }

    public componentDidMount(): void {

        if (hasPayload(this.props.getToken))
            this.props.getCarrierLookupsRequest({
                token: this.props.getToken.payload.response.token,
                requested_Page: "Carriers_Fleet_Trucks"
            });

        this.callServer();
    };

    public componentDidUpdate(prevProps: ICarrierFleetTruckProps): void {

        if (this.props !== prevProps) {
            this.callServer();

            if (this.props.addCarrierFleetTruck !== prevProps.addCarrierFleetTruck) {
                if (isSucceeded(this.props.addCarrierFleetTruck)) {
                    this.handleCancel();
                };
            };

            if (this.props.updateCarrierFleetTruck !== prevProps.updateCarrierFleetTruck) {
                if (isSucceeded(this.props.updateCarrierFleetTruck)) {
                    this.handleCancel();
                };
            };
        };
    };


    public render(): ReactNode {

        const { fleetTruck, errors, carriers } = this.state;
        const { updateCarrierFleetTruck, addCarrierFleetTruck, id, carrierName } = this.props;

        const truckRegistrationFile = (event?: any): void => this.uploadFile("truck_Registration_File", event);
        const truckRegistrationFileDownload = (): void => this.downloadFile(fleetTruck.truck_Registration_File_Name, fleetTruck.truck_Registration_File, "Truck_Registration_File");
        const truckInspectionFile = (event?: any): void => this.uploadFile("truck_Inspection_File", event);
        const truckInspectionFileDownload = (): void => this.downloadFile(fleetTruck.truck_Inspection_File_Name, fleetTruck.truck_Inspection_File, "Truck_Inspection_File");
        const trailerInspectionFile = (event?: any): void => this.uploadFile("trailer_Inspection_File", event);
        const trailerInspectionFileDownload = (): void => this.downloadFile(fleetTruck.trailer_Inspection_File_Name, fleetTruck.trailer_Inspection_File, "Trailer_Inspection_File");
        const onActive = (event: unknown, value: any): void => this.onChange("active", value !== null ? value.name : fleetTruck.active);
        const isAdd = fleetTruck.id > 0 ? false : true;

        return (
            <PageSpacing title="YHD - Carrier" description="Carrier FleetTruck" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="yhdCarrierAccess">
                    <CarrierFleetTruckStyles>

                        {(id === undefined) && <LAButton startIcon={<ArrowLeftIcon color={WHITE_COLOR} />} label="Back to list" onClick={this.handleCancel} />}
                        <h2 className="text-center">{!isAdd ? "VIEW " : "ADD "} CARRIER FLEET TRUCK</h2>
                        <hr />

                        <LAPaperWithPadding>
                            <LAGrid spacing={3}>

                                <LAGridItem xs={12}>
                                    <LATextField
                                        varient="outlined"
                                        label="ASGA No"
                                        fullWidth={true}
                                        name="asgA_No"
                                        value={fleetTruck.asgA_No}
                                        onChange={this.onChange}
                                        disabled={isAdd ? undefined : true}
                                        errorText={errors["asgA_No"] ? errors["asgA_No"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12}>
                                    <LATextField
                                        varient="outlined"
                                        label="Truck Type"
                                        fullWidth={true}
                                        name="truck_Type"
                                        onChange={this.onChange}
                                        value={fleetTruck.truck_Type ?? ""}
                                        errorText={errors["truck_Type"] ? errors["truck_Type"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12}>
                                    <LATextField
                                        varient="outlined"
                                        label="Truck Registration No"
                                        fullWidth={true}
                                        name="truck_Registration_No"
                                        onChange={this.onChange}
                                        value={fleetTruck.truck_Registration_No ?? ""}
                                        errorText={errors["truck_Registration_No"] ? errors["truck_Registration_No"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={6}>
                                    <LADateTimePicker
                                        fullWidth={true}
                                        name="truck_Registration_Expiry"
                                        onChange={this.onDateChange}
                                        label="Truck Registration Expiry"
                                        value={fleetTruck.truck_Registration_Expiry ?? ""}
                                        errorText={errors["truck_Registration_Expiry"] ? errors["truck_Registration_Expiry"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={6}>
                                <LAAutoComplete
                                    multiple={false}
                                    option={yesOrNoOptions}
                                    getOptionLabel="name"
                                    autoHighlight={true}
                                    onChange={onActive}
                                    filterSelectedOptions={true}
                                    dropDownPlaceHolder="Active"
                                    selectionRemove={undefinedFunction}
                                    value={fleetTruck.active ? yesOrNoOptions.find(q => q.name === fleetTruck.active) : null}
                                    defaultValue={fleetTruck.active ? yesOrNoOptions.find(q => q.name === fleetTruck.active) : null}
                                />
                            </LAGridItem>

                                <LAGridItem xs={6} className="text-center">
                                    <strong>Truck Registration File</strong>
                                    <LADropDone onFileDrop={truckRegistrationFile} />
                                    {fleetTruck.truck_Registration_File_Name && fleetTruck.truck_Registration_File_Name.length > 0
                                        && <LALinkButton className="pl-5" label={fleetTruck.truck_Registration_File_Name}
                                            onClick={truckRegistrationFileDownload} />}
                                    {fleetTruck.truck_Registration_File_Name && <LAIconButton
                                        name="truck_Registration_File_Name"
                                        label="Remove"
                                        icon={<DeleteIcon />}
                                        onClick={this.onFormRemove}
                                    />}
                                </LAGridItem>

                                <LAGridItem xs={6} className="text-center">
                                    <strong>Truck Inspection File</strong>
                                    <LADropDone onFileDrop={truckInspectionFile} />
                                    {fleetTruck.truck_Inspection_File_Name && fleetTruck.truck_Inspection_File_Name.length > 0
                                        && <LALinkButton className="pl-5" label={fleetTruck.truck_Inspection_File_Name}
                                            onClick={truckInspectionFileDownload} />}
                                    {fleetTruck.truck_Inspection_File_Name && <LAIconButton
                                        name="truck_Inspection_File_Name"
                                        label="Remove"
                                        icon={<DeleteIcon />}
                                        onClick={this.onFormRemove}
                                    />}
                                </LAGridItem>

                                <LAGridItem xs={6}>
                                    <LADateTimePicker
                                        fullWidth={true}
                                        name="truck_Inspection_Date"
                                        onChange={this.onDateChange}
                                        label="Truck Inspection Date"
                                        value={fleetTruck.truck_Inspection_Date ?? ""}
                                        errorText={errors["truck_Inspection_Date"] ? errors["truck_Inspection_Date"].message : undefined}
                                    />
                                </LAGridItem>


                                {/* <LAGridItem xs={6}>
                                    <LAAutoComplete
                                        filterSelectedOptions={true}
                                        autoHighlight={true}
                                        selectionRemove={undefinedFunction}
                                        dropDownPlaceHolder="FleetTruck All Carriers"
                                        getOptionLabel="name"
                                        name="email_All_Carriers"
                                        option={activeOptions}
                                        multiple={false}
                                        onChange={this.handleDropDownChange}
                                        value={activeOptions.find(x => x.name === YEs or no)}
                                        defaultValue={activeOptions.find(x => x.name === email.email_All_Carriers)}
                                    />
                                </LAGridItem> */}


                                <LAGridItem xs={6}>
                                    <LAAutoComplete
                                        filterSelectedOptions={true}
                                        autoHighlight={true}
                                        selectionRemove={undefinedFunction}
                                        dropDownPlaceHolder="Carrier Fleet Trucks"
                                        getOptionLabel="name"
                                        name="fleet_Trucks"
                                        option={carriers}
                                        multiple={false}
                                        onChange={this.handleDropDownChange}
                                        disabled={((id !== undefined) && (carrierName !== undefined)) ? true : undefined}
                                        value={(carriers && fleetTruck.fleet_Trucks) ? carriers.find(x => x.name === fleetTruck.fleet_Trucks) : ""}
                                        defaultValue={(carriers && fleetTruck.fleet_Trucks) ? carriers.find(x => x.name === fleetTruck.fleet_Trucks) : ""}
                                        errorText={errors["fleet_Trucks"] ? errors["fleet_Trucks"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={6}>
                                    <LADateTimePicker
                                        fullWidth={true}
                                        name="trailer_Inspection_Date"
                                        onChange={this.onDateChange}
                                        label="Trailer Inspection Date"
                                        value={fleetTruck.trailer_Inspection_Date ?? ""}
                                        errorText={errors["trailer_Inspection_Date"] ? errors["trailer_Inspection_Date"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={6} className="text-center">
                                    <strong>Trailer Inspection File</strong>
                                    <LADropDone onFileDrop={trailerInspectionFile} />
                                    {fleetTruck.trailer_Inspection_File_Name && fleetTruck.trailer_Inspection_File_Name.length > 0
                                        && <LALinkButton className="pl-5" label={fleetTruck.trailer_Inspection_File_Name}
                                            onClick={trailerInspectionFileDownload} />}
                                    {fleetTruck.trailer_Inspection_File_Name && <LAIconButton
                                        name="trailer_Inspection_File_Name"
                                        label="Remove"
                                        icon={<DeleteIcon />}
                                        onClick={this.onFormRemove}
                                    />}
                                </LAGridItem>

                                <LAGridItem xs={12}>
                                    <hr />
                                    <LASaveAndCancelButton
                                        onSave={this.onSave}
                                        onCancel={this.handleCancel}
                                        disableSave={(Object.values(errors).length > 0 ? true : undefined)}
                                    />
                                </LAGridItem>

                            </LAGrid>
                        </LAPaperWithPadding>

                        <RequestStatus requestStatus={addCarrierFleetTruck.kind} successMessage="Carrier FleetTruck successfully saved" />
                        <RequestStatus requestStatus={updateCarrierFleetTruck.kind} successMessage="Carrier FleetTruck successfully updated" />
                    </CarrierFleetTruckStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private onFormRemove = (event?: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined, name?: string): void => {
        if (name) {
            const selection = window.confirm(deleteText);
            if(selection) {
                this.setState({
                    ...this.state,
                    fleetTruck: {
                        ...this.state.fleetTruck,
                        [name]: undefined,
                        [name + "_Name"]: undefined
                    }
                });
            }
        }
    };

    private uploadFile = (name: string, event?: any): void => {
        new Promise((resolve, reject): any => {
            if (event && (event !== null)) {

                Object.values(event).forEach((x: any, index, array) => {

                    if (x !== undefined) {
                        const reader = new FileReader();

                        reader.onloadend = async (e): Promise<void> => {
                            if (reader.result !== null) {
                                this.setState({
                                    ...this.state,
                                    fleetTruck: {
                                        ...this.state.fleetTruck,
                                        [name + "_Name"]: x.name,
                                        [name]: reader.result
                                    }
                                });
                                resolve(true);
                            };
                        };
                        reader.readAsDataURL(x);
                    }
                })
            } else {
                reject();
            }
        })
    };

    private downloadFile = (name?: string, base64?: string, objName?: string): void => {

        if (name) {
            if ((base64 !== undefined) && (base64 !== null)) {
                let a = document.createElement("a");
                a.href = base64;
                a.download = name;
                a.click();
            };

            if ((base64 === undefined) || (base64 === null)) {

                if ((objName !== undefined) || (objName !== null)) {
                    if (hasPayload(this.props.getToken) && this.state.fleetTruck.id) {

                        fetch(END_POINTS.PROPSENSE.YHD.CARRIER.GET_CARRIER_FLEET_TRUCK_FILE, {
                            method: "POST",
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify({
                                request: {
                                    "Carrier_Fleet_Truck_ID": this.state.fleetTruck.id,
                                    "File": objName,
                                },
                                token: this.props.getToken.payload.response.token
                            })
                        })
                            .then((res: any) => {
                                return res.blob();
                            })
                            .then(blob => {

                                var url = window.URL.createObjectURL(blob);
                                var a = document.createElement('a');
                                a.href = url;
                                a.download = name;
                                document.body.appendChild(a);
                                a.click();
                                a.remove();
                            })
                            .catch((res) => console.log(res));
                    };
                };
            };
        }
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, isRequired: boolean): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: isRequired ? true : undefined, minLength: 1, decimal: undefined });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        };
        return err;
    };

    private handleCancel = (): void => {
        if((this.props.id !== undefined) && (this.props.onClose)){
            this.props.onClose();
        } else {
            this.props.history.push(ROUTE.PROPSENSE.YHD.CARRIERS.CARRIER_FLEET_TRUCKS);
        };
    };

    private onSave = (): void => {
        const { fleetTruck } = this.state;

        if (hasPayload(this.props.getToken))
            if (fleetTruck.id === 0) {
                this.props.addCarrierFleetTruckRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        ASGA_No: fleetTruck.asgA_No,
                        Truck_Type: fleetTruck.truck_Type,
                        Truck_Registration_No: fleetTruck.truck_Registration_No,
                        Truck_Registration_Expiry: fleetTruck.truck_Registration_Expiry,
                        Truck_Registration_File_Name: fleetTruck.truck_Registration_File_Name,
                        Truck_Registration_File: fleetTruck.truck_Registration_File,
                        Truck_Inspection_File_Name: fleetTruck.truck_Inspection_File_Name,
                        Truck_Inspection_File: fleetTruck.truck_Inspection_File,
                        Fleet_Trucks: fleetTruck.fleet_Trucks,
                        Truck_Inspection_Date: fleetTruck.truck_Inspection_Date,
                        Trailer_Inspection_File_Name: fleetTruck.trailer_Inspection_File_Name,
                        Trailer_Inspection_File: fleetTruck.trailer_Inspection_File,
                        Trailer_Inspection_Date: fleetTruck.trailer_Inspection_Date,
                        Active: fleetTruck.active,
                        Modified: fleetTruck.modified,
                        Modified_By: userName,
                        Created: fleetTruck.created,
                        Created_By: userName,
                    }
                });
            } else {
                this.props.updateCarrierFleetTruckRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        ID: fleetTruck.id,
                        ASGA_No: fleetTruck.asgA_No,
                        Truck_Type: fleetTruck.truck_Type,
                        Truck_Registration_No: fleetTruck.truck_Registration_No,
                        Truck_Registration_Expiry: fleetTruck.truck_Registration_Expiry,
                        Truck_Registration_File_Name: fleetTruck.truck_Registration_File_Name,
                        Truck_Registration_File: fleetTruck.truck_Registration_File,
                        Truck_Inspection_File_Name: fleetTruck.truck_Inspection_File_Name,
                        Truck_Inspection_File: fleetTruck.truck_Inspection_File,
                        Fleet_Trucks: fleetTruck.fleet_Trucks,
                        Truck_Inspection_Date: fleetTruck.truck_Inspection_Date,
                        Trailer_Inspection_File_Name: fleetTruck.trailer_Inspection_File_Name,
                        Trailer_Inspection_File: fleetTruck.trailer_Inspection_File,
                        Trailer_Inspection_Date: fleetTruck.trailer_Inspection_Date,
                        Active: fleetTruck.active,
                        Modified: fleetTruck.modified,
                        Modified_By: userName,
                        Created: fleetTruck.created,
                        Created_By: fleetTruck.created_By,
                    }
                });
            };
    };

    private onDateChange = (name: string, value: string): void => {
        const date = new Date(value);
        let errors = { ...this.state.errors };

        if (date.toString() === "Invalid Date") {
            if (!errors[name]) {
                errors[name] = { key: name, message: "Invalid Date" };
            }
        } else {
            if (errors[name])
                delete errors[name];
        };

        if (requiredFields.includes(name)) {
            errors = this.errorChecker(name, value, errors, true);
        };

        this.setState({
            ...this.state,
            fleetTruck: {
                ...this.state.fleetTruck,
                [name]: value
            },
            errors
        });
    };

    private handleDropDownChange = (event: unknown, value: { id: number, name: string } | "", name?: string): void => {
        console.log(name);
        console.log(value);
        let fieldName = name != undefined ? name : "fleet_Trucks";
        let errors = { ...this.state.errors };

        if (errors[fieldName] && value !== "" && value !== undefined)
        delete errors[fieldName];

        if (requiredFields.includes(fieldName)) {
            errors = this.errorChecker(fieldName, value !== "" ? value.name : "", errors, true);
        };

        if (name)
            this.setState({
                ...this.state,
                fleetTruck: {
                    ...this.state.fleetTruck,
                    [name]: value !== "" ? value.name : ""
                },
                errors
            });
    };


    private onChange = (name: string, value: string): void => {
        let errors = this.state.errors;
        errors = this.errorChecker(name, value, errors, true);


     if(name == "asgA_No" && value !==undefined && value !== "")
     {

        let fieldName = "fleet_Trucks";
        let fleetTruckVal = this.state.fleetTruck.fleet_Trucks;
        if (errors[fieldName] && fleetTruckVal !== "" && fleetTruckVal !== undefined)
        delete errors[fieldName];

        if (requiredFields.includes(fieldName)) {
            errors = this.errorChecker(fieldName, fleetTruckVal !== undefined ? fleetTruckVal : "", errors, true);
        };
    }
        this.setState({
            ...this.state,
            errors,
            fleetTruck: {
                ...this.state.fleetTruck,
                [name]: value
            },
        });
    
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });


        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getCarrierLookups))
            this.props.getCarrierLookupsRequest({
                token: this.props.getToken.payload.response.token,
                requested_Page: "Carriers_Fleet_Trucks"
            });

        if (hasPayload(this.props.getCarrierLookups)) {
            this.setState({ carriers: this.props.getCarrierLookups.payload.response });
        };

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getCarrierFleetTrucks))
            this.props.getCarrierFleetTrucksRequest({
                token: this.props.getToken.payload.response.token
            });


        const query = queryString.parse(this.props.location.search);
        const id = (this.props.id !== undefined) ? this.props.id : (query.id ?? "0");

        if ((id !== "0") && (hasPayload(this.props.getCarrierFleetTrucks)) && hasPayload(this.props.getCarrierLookups)) {
            const fleet = this.props.getCarrierFleetTrucks.payload.response.find(x => x.id === Number(id));
            const carriers = this.props.getCarrierLookups.payload.response;

            if (fleet)
                this.setState({ carriers, fleetTruck: fleet, errors: {} });
        };

    };

}

const mapStateToProps = (state: IStore): ICarrierFleetTruckStoreProps => ({
    getToken: getTokenStatus(state),
    getCarrierFleetTrucks: getYHDCarrierFleetTruckStatus(state),
    addCarrierFleetTruck: getYHDAddCarrierFleetTruckStatus(state),
    updateCarrierFleetTruck: getYHDUpdateCarrierFleetTruckStatus(state),
    getCarrierLookups: getYHDCarrierLookupsStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): ICarrierFleetTruckDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getCarrierFleetTrucksRequest: (data: IGetYHDCarrierFleetTruckRequest) => dispatch(getYHDCarrierFleetTruckLoadAction(data)),
    addCarrierFleetTruckRequest: (data: IAddYHDCarrierFleetTruckRequest) => dispatch(addYHDCarrierFleetTruckLoadAction(data)),
    updateCarrierFleetTruckRequest: (data: IUpdateYHDCarrierFleetTruckRequest) => dispatch(updateYHDCarrierFleetTruckLoadAction(data)),
    getCarrierLookupsRequest: (data: IGetYHDCarrierLookupsRequest) => dispatch(getYHDCarrierLookupsLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(YHDCarrierFleetTruck);