import { IPassTokenWithRequest } from "../../dryPlant/token/getTokenConstants";


export enum IGET_WASH_PLANT_RUN_MATERIALS_REQUEST {
    REQUEST = "getWashPlantRunMaterials/GET_WASH_PLANT_RUN_MATERIALS_REQUEST",
    SUCCESS = "getWashPlantRunMaterials/GET_WASH_PLANT_RUN_MATERIALS_SUCCESS",
    FAILED = "getWashPlantRunMaterials/GET_WASH_PLANT_RUN_MATERIALS_FAILED"
};

export interface IGetWashPlantRunMaterialsRequest extends IPassTokenWithRequest {

};

export interface IWashPlantRunMaterials {
    id: number;
    by_Product: string;
    product_Code: string;
    yield_Product_1:string;
    yield_Product_1_Perc:string;
    yield_Product_2:string;
    yield_Product_2_Perc:string;
    yield_Product_3:string;
    yield_Product_3_Perc:string;
    yield_Product_4:string;
    yield_Product_4_Perc:string;
    active: string;
    ball_Mill: string;
    created: string;
    modified: string;
    created_By: string;
    modified_By: string;
};