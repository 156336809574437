import { SilAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../../flushData.tsx/flushDataConstants";
import { Server, failed, loading, notLoaded, succeeded } from "../../../../server";
import { IAddLabScaleSuccessAction } from "../addLabScale/addLabScaleActions";
import { IADD_LAB_SCALE_REQUEST } from "../addLabScale/addLabScaleConstaints";
import { IUpdateLabScaleSuccessAction } from "../updateLabScale/updateLabScaleActions";
import { IUPDATE_LAB_SCALE_REQUEST } from "../updateLabScale/updateLabScaleConstaints";
import { IGetLabScaleLoadAction, IGetLabScaleLoadFailedAction, IGetLabScaleSuccessAction } from "./getLabScalesActions";
import { ILabScale, IGET_LAB_SCALES_REQUEST } from "./getLabScalesConstaints";

type Actions =
    IGetLabScaleLoadAction
    | IGetLabScaleLoadFailedAction
    | IGetLabScaleSuccessAction
    | IAddLabScaleSuccessAction
    | IUpdateLabScaleSuccessAction
    | IFlushDataSuccessAction;

export const GetLabScaleReducer = (state: Server<SilAPIResponse<ILabScale[]>> = notLoaded, action: Actions): Server<SilAPIResponse<ILabScale[]>> => {
    switch (action.type) {
        case IGET_LAB_SCALES_REQUEST.REQUEST:
            return loading;

        case IGET_LAB_SCALES_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_LAB_SCALES_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_LAB_SCALE_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_LAB_SCALE_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};