import {currentUser} from '../../../../simosHome';


async function updateNewContact(new_contact:any) {
    var responseStatus:any = null;
    let requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type':'application/json'},
        body: JSON.stringify({
            request: {
                ID:new_contact.id,
                Customer_ID:new_contact.customer_ID,
                Delivery_ID:new_contact.delivery_ID,
                Customer:new_contact.customer,
                Delivery_Address:new_contact.delivery_Address,
                Contact:new_contact.contact,
                Phone:new_contact.phone,
                Fax:new_contact.fax,
                Email:new_contact.email,
                Modified_By:currentUser
            },
            token: '19515195-d571-44a3-b0db-637f5bf24f54'})
    }
    await fetch('https://api2.propsense.com/api/UpdateSIMOSCustomerContact', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json')&& await response.json();
        // error response
        if(!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        responseStatus = response;
    });
    return responseStatus;
}

export default updateNewContact;