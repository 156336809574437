import { IPassTokenWithRequest } from "../../dryPlant/token/getTokenConstants";

export enum IADD_SIEVE_CALIBRATION_REQUEST {
    REQUEST = "addSieveCalibration/ADD_SIEVE_CALIBRATION_REQUEST",
    SUCCESS = "addSieveCalibration/ADD_SIEVE_CALIBRATION_SUCCESS",
    FAILED = "addSieveCalibration/ADD_SIEVE_CALIBRATION_FAILED"
};

export interface IAddSieveCalibrationRequest extends IPassTokenWithRequest {
    request: IAddSieveCalibration;
};

export interface IAddSieveCalibration {
    ID: number;
    Date: string;
    Operator?: string;
    Plant: string;
    Product: string;
    Notes?: string;
    Initial_Mass?: string;
    Created_By: string;
    Modified_By: string;
    Measurements: IAddSieveCalibrationMeasurementRequest[];
};

export interface IAddSieveCalibrationMeasurementRequest {
    ID: number;
    Sieve_Serial_No: string;
    ASTM_Serial_No: string;
    Master_Load: number;
    Working_Load: number;
    Difference: number;
    Relative_Difference: number;
    Absolute_Difference: number;
    Absolute_Relative_Difference: number;
};