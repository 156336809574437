import { IPassTokenWithRequest } from "../../../../sil/dryPlant/token/getTokenConstants";

export enum IGET_YHD_CARRIER_LOOKUPS_REQUEST {
    REQUEST = "getYHDCarrierLookups/GET_YHD_CARRIER_LOOKUPS_REQUEST",
    SUCCESS = "getYHDCarrierLookups/GET_YHD_CARRIER_LOOKUPS_SUCCESS",
    FAILED = "getYHDCarrierLookups/GET_YHD_CARRIER_LOOKUPS_FAILED"
};

export interface IGetYHDCarrierLookupsRequest extends IPassTokenWithRequest {
    requested_Page: "Carriers_Fleet_Trucks" | "Carriers_Email" | "Carrier_Infractions";
};

export interface IYHDCarrierLookup {
    id: number;
    name: string;
};