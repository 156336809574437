import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IUpdateWashPlantFeedMaterialLoadAction, IUpdateWashPlantFeedMaterialLoadFailedAction, IUpdateWashPlantFeedMaterialSuccessAction, updateWashPlantFeedMaterialLoadFailedAction,
    updateWashPlantFeedMaterialLoadSuccessAction
} from "./updateWashPlantFeedMaterialActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IUPDATE_WASH_PLANT_FEED_MATERIAL_REQUEST } from "./updateWashPlantFeedMaterialConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";

export const updateWashPlantFeedMaterialEpic: Epic = (
    action$: ActionsObservable<IUpdateWashPlantFeedMaterialLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IUpdateWashPlantFeedMaterialSuccessAction | IUpdateWashPlantFeedMaterialLoadFailedAction> =>
    action$.ofType(IUPDATE_WASH_PLANT_FEED_MATERIAL_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<string>>(
                    END_POINTS.PROPSENSE.SIL.WASH_PLANT.UPDATE_WASH_PLANT_FEED_MATERIALS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<string>): IUpdateWashPlantFeedMaterialSuccessAction | IUpdateWashPlantFeedMaterialLoadFailedAction => {

                            if (response.message === "Success") {
                                return updateWashPlantFeedMaterialLoadSuccessAction(action.data.request);
                            };
                            return updateWashPlantFeedMaterialLoadFailedAction(response.message);
                        }),
                        catchError(() => ActionsObservable.of(updateWashPlantFeedMaterialLoadFailedAction("Unable to save your changes")))
                    )
            )
        );