import { IUpdateSieveRequest, IUPDATE_SIEVE_REQUEST } from "./updateSieveConstaints";

export interface IUpdateSieveLoadAction {
    type: IUPDATE_SIEVE_REQUEST.REQUEST;
    data: IUpdateSieveRequest
}
export const updateSieveLoadAction = (data: IUpdateSieveRequest): IUpdateSieveLoadAction => ({
    type: IUPDATE_SIEVE_REQUEST.REQUEST,
    data
});

export interface IUpdateSieveSuccessAction {
    type: IUPDATE_SIEVE_REQUEST.SUCCESS;
    message: string;
}
export const updateSieveLoadSuccessAction = (message: string): IUpdateSieveSuccessAction => ({
    type: IUPDATE_SIEVE_REQUEST.SUCCESS,
    message
});

export interface IUpdateSieveLoadFailedAction {
    type: IUPDATE_SIEVE_REQUEST.FAILED;
    message: string;
}
export const updateSieveLoadFailedAction = (message: string): IUpdateSieveLoadFailedAction => ({
    type: IUPDATE_SIEVE_REQUEST.FAILED,
    message
});
