import { ICarrierForm } from "../../../../react/components/sil/carriers/carrierForm";

export enum ISAVE_CARRIER_FORM_REQUEST {
    REQUEST = "saveCarrierForm/SAVE_CARRIER_FORM_REQUEST",
    SUCCESS = "saveCarrierForm/SAVE_CARRIER_FORM_SUCCESS",
    FAILED = "saveCarrierForm/SAVE_CARRIER_FORM_FAILED"
};

export interface ISaveCarrierFormRequest {
    token: string;
    request: ICarrierForm;
};