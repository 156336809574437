import React from 'react';
import {addNewProduct} from '../APICalls/addSIMOSProduct';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import isProductUnique, {isUnique, checkDuplicateProductInfo} from '../APICalls/checkProductExists';
import BackButton from '../../../../shared/simos/helper/backButton';
import { Select, MenuItem, FormLabel } from '@mui/material';
import {productscopy} from './productTable';
import { getSILURLParams } from '../../../../shared/simos/helper/getURLContainsSIL';
interface IProps {
    history:any;
}
interface IState {
}
var newProduct:any = {product_name:'', product_location:'', product_size:'', product_code:''};
var product_size_options:string[] = [];
var product_location_options:string[] = [];
var noMatch:boolean = false;
export default class CreateProductForm extends React.Component<IProps,IState>{
    constructor(props: IProps){
        super(props);
        this.state = {};
        this.onTextChange = this.onTextChange.bind(this);
    }

    onTextChange(event:any) {
        const fieldName:string = event.target.name;
        switch(fieldName) {
            case 'product_location':
                newProduct.product_location = event.target.value;
                newProduct.product_size = '';
                if(event.target.value.includes('Warehouse')){
                    product_size_options = ["10 kg","22.7 kg", "40 kg", "Bulk Bag", "Poly"];
                }
                else{
                    product_size_options = ["N/A", "Single", "Super B", "Tri-Axle", "B-Train"];
                }
                break;
            case 'product_name':
                // Check if product name already exists
                // isProductUnique(event.target.value.trim());
                // if(isUnique){
                    newProduct.product_name = event.target.value;
                //}
                break;
            case 'product_size':
                newProduct.product_size = event.target.value;
                break;
            case 'product_code':
                newProduct.product_code = event.target.value;
                break;
        }
        this.setState({});
    }

    async onSaveForm(event:any){
        event.preventDefault();
        if(newProduct.product_name!="" && newProduct.product_code!="" && newProduct.product_size!="" && newProduct.product_location!=""){
          //  if(isUnique){
                if(checkDuplicateProductInfo(newProduct, productscopy)){
                    var res:any = await addNewProduct(newProduct);
                    if(res.status === 200){
                        alert("Successfully added new product");
                        this.props.history.push('/simos/products' + getSILURLParams());
                        newProduct = {product_name:'', product_location:'', product_size:'', product_code:''};
                        this.setState({});
                        window.location.reload();
                    }
                    else{
                        alert("There was an issue adding the product. Please refresh the page and try again");
                    }
                }
                else{
                    alert("A code already exists for this combination (Location, Name, Size) already exists")
                }
            // }
            // else{
            //     alert("This product already exists!");
            // }
        }
        else {
            alert("All fields must be filled!");
        }
    }
    onCancelForm(){
        newProduct = {product_name:'', product_location:'', product_size:'', product_code:''};
       this.props.history.push('/simos/products' + getSILURLParams())
    }

    componentDidMount(): void {
        if(getSILURLParams() === "?sil_bru"){
             product_location_options= ["SIL BRU Warehouse", "Bulk"];
        }
        else{
            product_location_options= ["SIL ED Warehouse", "Bulk"];
        }
        this.setState({});
    }


    render() {
        return (
            <div className={getSILURLParams()?.includes("bru") ? "edit-popup":"edit-popup-sil-ed"}>
                <div className = 'edit-popup_inner'>
                <BackButton goBack={this.onCancelForm.bind(this)} history={this.props.history}></BackButton>
                <h3>Create New Product</h3>
                    <form>
                        <div id='edit-info-fields'>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <FormLabel component="legend">Location</FormLabel>
                                    <Select fullWidth label="Location" name="product_location" onChange={this.onTextChange} defaultValue={newProduct.product_location}>
                                    {product_location_options.map((location:any) => {return <MenuItem value={location}>{location}</MenuItem>})}
                                    </Select>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField fullWidth label="Product Name" name="product_name" onChange={this.onTextChange} value={newProduct.product_name}></TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormLabel component="legend">Product Size</FormLabel>
                                    <Select fullWidth label="Product Size" name="product_size" onChange={this.onTextChange} defaultValue={newProduct.product_size}>
                                    {product_size_options.map((sizes:any) => {return <MenuItem value={sizes}>{sizes}</MenuItem>})}
                                    </Select>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField fullWidth label="Product Code" name="product_code" onChange={this.onTextChange} value={newProduct.product_code}></TextField>
                                </Grid>
                            </Grid>
                        </div><br></br>
                        <button type='submit' onClick={this.onSaveForm.bind(this)} id='submit-contact-form-btn'>Save</button>
                        <button onClick={this.onCancelForm.bind(this)} id='cancel-contact-form-btn'>Cancel</button>
                    </form>
                </div>
            </div>
        );
    }
}