import { IProductionRecords } from "../getProductionRecords/getProductionRecordsConstaints";

export enum IADD_PRODUCTION_REQUEST {
    REQUEST = "addProduction/ADD_PRODUCTION_REQUEST",
    SUCCESS = "addProduction/ADD_PRODUCTION_SUCCESS",
    FAILED = "addProduction/ADD_PRODUCTION_FAILED"
};

export interface IAddProductionRequest {
    token: string;
    request: IProductionRecords;
};