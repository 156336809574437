import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithLessPadding, LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import { SilAPIResponse } from "../../../shared/publicInterfaces";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import { callRouteWithQueryString, undefinedFunction, userName } from "../../../shared/constExports";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { LAButton } from "../../../shared/buttons";
import { AddIcon } from "../../../shared/icons";
import { WHITE_COLOR } from "../../../shared/theme";
import { ROUTE } from "../../../routes";
import PageSpacing from "../../../shared/pageSpacing";
import SilRoleCheck from "../../../shared/silRoleCheck";
import LALoading from "../../../shared/loading";
import { ITrailerActivityLog, IGetTrailerActivityLogsRequest } from "../../../../redux/sil/dispatch/getTrailerActivityLogs/getTrailerActivityLogsConstaints";
import { getTrailerActivityLogsLoadAction } from "../../../../redux/sil/dispatch/getTrailerActivityLogs/getTrailerActivityLogsActions";
import { getTrailerActivityLogsStatus } from "../../../../redux/sil/dispatch/getTrailerActivityLogs/getTrailerActivityLogsAccessor";
import { LAPopover } from "../../../shared/popOver";


interface ITrailerActivityLogsStoreProps {
    getToken: Server<SilAPIResponse<IToken>>;
    getTrailerActivityLogs: Server<SilAPIResponse<ITrailerActivityLog[]>>;
};

interface ITrailerActivityLogsDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getTrailerActivityLogsRequest: (data: IGetTrailerActivityLogsRequest) => unknown;
};


interface ITrailerActivityLogsOwnProps {

};

interface ITrailerActivityLogsState {
    servicePopup: boolean;
    replacementPopup: boolean;
    popupId: number | undefined;
};

const TrailerActivityLogsStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
    };
`;

type ITrailerActivityLogsProps = RouteComponentProps
    & ITrailerActivityLogsStoreProps
    & ITrailerActivityLogsDispatchProps
    & ITrailerActivityLogsOwnProps;

class TrailerActivityLogs extends PureComponent<ITrailerActivityLogsProps, ITrailerActivityLogsState> {

    public constructor(props: ITrailerActivityLogsProps) {
        super(props);
        this.state = {
            popupId: undefined,
            servicePopup: false,
            replacementPopup: false
        };
    }

    public componentDidMount(): void {
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: ITrailerActivityLogsProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();
        };
    };


    public render(): ReactNode {

        const { getTrailerActivityLogs } = this.props;
        const { popupId, servicePopup, replacementPopup } = this.state;

        return (
            <PageSpacing title="SIL - CV" description="SIL - TrailerActivityLogs" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="carrierAccess">
                    <TrailerActivityLogsStyles>

                        {getTrailerActivityLogs.kind !== STATUS_ENUM.LOADING &&
                            <LAButton className="pull-left" startIcon={<AddIcon color={WHITE_COLOR} />} label="Add" onClick={this.handleAdd} />}
                        <h2 className="text-center">TRAILER ACTIVITY LOGS</h2>
                        <hr />

                        {getTrailerActivityLogs.kind === STATUS_ENUM.LOADING &&
                            <LALoading className="loading" message="Loading..." />}

                        {getTrailerActivityLogs.kind === STATUS_ENUM.SUCCEEDED && <LADevExtremeGrid
                            data={getTrailerActivityLogs.payload.response}
                            onEdit={this.onEdit}
                            onClick={this.onClick}
                            height={1000}
                            searchPanel={true}
                            filterHeader={true}
                            export={true}
                            onCustomClick={this.handlePopup}
                            storageKey="silTrailerActivityLogsStorageKey"
                            exportFileName="TrailerActivityLogsList"
                            columns={[
                                { name: "trailer", caption: "Trailer", type: "string" },
                                { name: "carrier", caption: "Carrier", type: "string" },
                                { name: "status", caption: "Status", type: "string" },
                                { name: "in_Service", caption: "In Service", type: "datetime" },
                                { name: "out_Of_Service", caption: "Out of Service", type: "datetime" },
                                { name: "comments", caption: "Comments", type: "string" },
                                { name: "Service", caption: "Service", type: "button" },
                                { name: "Tire Replacement", caption: "Tire Replacement", type: "button" },
                                { name: "created", caption: "Created", type: "datetime" },
                                { name: "created_By", caption: "Created By", type: "string" },
                                { name: "modified", caption: "Modified", type: "datetime" },
                                { name: "modified_By", caption: "Modified By", type: "string" }
                            ]}
                        />}

                    </TrailerActivityLogsStyles>

                    {servicePopup && <LAPopover open={servicePopup ? true : false} onClose={this.handlePopupClose} anchorRef={null} transformOrigin={{ vertical: "top", horizontal: "center" }} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                        <LAPaperWithLessPadding>
                            <h3 className="text-center">Service</h3>

                            <LADevExtremeGrid
                                data={[]}
                                searchPanel={true}
                                filterHeader={true}
                                onClick={undefinedFunction}
                                columns={[
                                    { name: "service_TypeInfo", caption: "Service Type", type: "string" },
                                    { name: "km_At_Service", caption: "Km at Service", type: "string" },
                                    { name: "service_Date", caption: "Service Date", type: "date" },
                                    { name: "work_Order_Number", caption: "Work Order #", type: "string" },
                                    { name: "comments", caption: "Comments", type: "string", width: 110 },
                                    { name: "mechanics", caption: "Mechanics", type: "string", width: 110 }
                                ]}
                            />
                        </LAPaperWithLessPadding>
                    </LAPopover>}

                    {replacementPopup && <LAPopover open={replacementPopup ? true : false} onClose={this.handlePopupClose} anchorRef={null} transformOrigin={{ vertical: "top", horizontal: "center" }} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                        <LAPaperWithLessPadding>
                            <h3 className="text-center">Tire Replacement</h3>

                            <LADevExtremeGrid
                                data={[]}
                                searchPanel={true}
                                filterHeader={true}
                                onClick={undefinedFunction}
                                columns={[
                                    { name: "km_At_Replacement", caption: "Service Type", type: "string" },
                                    { name: "replacement_Date", caption: "Service Date", type: "date" },
                                    { name: "work_Order_Number", caption: "Work Order #", type: "string" },
                                    { name: "condition", caption: "Condition", type: "string" },
                                    { name: "brand", caption: "Brand", type: "string" },
                                    { name: "FLi", caption: "FLi", type: "boolean" },
                                    { name: "FLO", caption: "FLO", type: "boolean" },
                                    { name: "FRI", caption: "FRI", type: "boolean" },
                                    { name: "FRO", caption: "FRO", type: "boolean" },
                                    { name: "MLi", caption: "MLi", type: "boolean" },
                                    { name: "MLO", caption: "MLO", type: "boolean" },
                                    { name: "MRI", caption: "MRI", type: "boolean" },
                                    { name: "MRO", caption: "MRO", type: "boolean" },
                                    { name: "RLi", caption: "RLi", type: "boolean" },
                                    { name: "RLO", caption: "RLO", type: "boolean" },
                                    { name: "RRi", caption: "RRi", type: "boolean" },
                                    { name: "RRO", caption: "RRO", type: "boolean" },
                                    { name: "comments", caption: "Comments", type: "string", width: 110 }
                                ]}
                            />
                        </LAPaperWithLessPadding>
                    </LAPopover>}

                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private handlePopup = (name: string, e: any): void => {
        const isService = name === "Service";

        this.setState({
            popupId: e.row.data.id,
            servicePopup: isService,
            replacementPopup: !isService
        });
    };

    private handlePopupClose = (): void => {
        this.setState({
            popupId: undefined,
            servicePopup: false,
            replacementPopup: false
        });
    };

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString() },
            pathName: ROUTE.SIL.DISPATCH.TRAILER_ACTIVITY_LOG
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0" },
            pathName: ROUTE.SIL.DISPATCH.TRAILER_ACTIVITY_LOG
        });
    };

    private setDataToState = (): void => {
        if (isNotLoaded(this.props.getTrailerActivityLogs))
            this.callServer();
    };

    private callServer = (): void => {
        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getTrailerActivityLogs))
            this.props.getTrailerActivityLogsRequest({
                token: this.props.getToken.payload.response.token
            });

        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });
    }

}

const mapStateToProps = (state: IStore): ITrailerActivityLogsStoreProps => ({
    getToken: getTokenStatus(state),
    getTrailerActivityLogs: getTrailerActivityLogsStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): ITrailerActivityLogsDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getTrailerActivityLogsRequest: (data: IGetTrailerActivityLogsRequest) => dispatch(getTrailerActivityLogsLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(TrailerActivityLogs);