import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import { SilAPIResponse } from "../../../shared/publicInterfaces";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import { callRouteWithQueryString, userName } from "../../../shared/constExports";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { LAButton } from "../../../shared/buttons";
import { AddIcon } from "../../../shared/icons";
import { WHITE_COLOR } from "../../../shared/theme";
import { ROUTE } from "../../../routes";
import PageSpacing from "../../../shared/pageSpacing";
import SilRoleCheck from "../../../shared/silRoleCheck";
import LALoading from "../../../shared/loading";
import { IGetLabScaleRequest, ILabScale } from "../../../../redux/sil/cv/labScales/getLabScales/getLabScalesConstaints";
import { getLabScaleStatus } from "../../../../redux/sil/cv/labScales/getLabScales/getLabScalesAccessor";
import { getLabScaleLoadAction } from "../../../../redux/sil/cv/labScales/getLabScales/getLabScalesActions";


interface ILabScalesStoreProps {
    getToken: Server<SilAPIResponse<IToken>>;
    getLabScales: Server<SilAPIResponse<ILabScale[]>>;
};

interface ILabScalesDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getLabScalesRequest: (data: IGetLabScaleRequest) => unknown;
};


interface ILabScalesOwnProps {

};

interface ILabScalesState {

};

const LabScalesStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
    };
`;

type ILabScalesProps = RouteComponentProps
    & ILabScalesStoreProps
    & ILabScalesDispatchProps
    & ILabScalesOwnProps;

class LabScales extends PureComponent<ILabScalesProps, ILabScalesState> {

    public constructor(props: ILabScalesProps) {
        super(props);
        this.state = {
        };
    }

    public componentDidMount(): void {
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: ILabScalesProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();
        };
    };


    public render(): ReactNode {

        const { getLabScales } = this.props;

        return (
            <PageSpacing title="SIL - CV" description="SIL - Lab Scales" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="carrierAccess">
                    <LabScalesStyles>

                        {getLabScales.kind !== STATUS_ENUM.LOADING &&
                            <LAButton className="pull-left" startIcon={<AddIcon color={WHITE_COLOR} />} label="Add" onClick={this.handleAdd} />}
                        <h2 className="text-center">LAB SCALE</h2>
                        <hr />

                        {getLabScales.kind === STATUS_ENUM.LOADING &&
                            <LALoading className="loading" message="Loading..." />}

                        {getLabScales.kind === STATUS_ENUM.SUCCEEDED && <LADevExtremeGrid
                            data={getLabScales.payload.response}
                            onEdit={this.onEdit}
                            onClick={this.onClick}
                            height={1000}
                            searchPanel={true}
                            filterHeader={true}
                            export={true}
                            exportFileName="LabScalesList"
                            storageKey="silLabScalesStorageKey"
                            columns={[
                                { name: "plant", caption: "Plant", type: "number" },
                                { name: "model", caption: "Model", type: "number" },
                                { name: "employee", caption: "Employee", type: "string" },
                                { name: "month", caption: "Month", type: "number" },
                                { name: "year", caption: "Year", type: "number" },
                                { name: "ten_G_Reading", caption: "10g Reading", type: "number" },
                                { name: "five_Hundred_G_Reading", caption: "500g Reading", type: "number" },
                                { name: "thousand_G_Reading", caption: "1000g Reading", type: "number" },
                                { name: "modified", caption: "Modified", type: "datetime" },
                                { name: "modified_By", caption: "Modified By", type: "string" }
                            ]}
                        />}

                    </LabScalesStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString() },
            pathName: ROUTE.SIL.CV.LAB_SCALES.LAB_SCALE
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0" },
            pathName: ROUTE.SIL.CV.LAB_SCALES.LAB_SCALE
        });
    };

    private setDataToState = (): void => {
        if (isNotLoaded(this.props.getLabScales))
            this.callServer();
    };

    private callServer = (): void => {
        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getLabScales))
            this.props.getLabScalesRequest({
                token: this.props.getToken.payload.response.token
            });

        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });
    }

}

const mapStateToProps = (state: IStore): ILabScalesStoreProps => ({
    getToken: getTokenStatus(state),
    getLabScales: getLabScaleStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): ILabScalesDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getLabScalesRequest: (data: IGetLabScaleRequest) => dispatch(getLabScaleLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(LabScales);