import { Component, ReactNode } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, Route, Switch } from "react-router";
import { IStore, IDispatch } from "../redux/reducers";
import { ROUTE } from "./routes";
import Error404Page from "./error404Page";
import Header from "./header/header";
import CatapultHome from "./catapultHome";
import HomeComponent from "./homeComponent";
import SIMOSHome from "./simosHome";
import SilHome from "./silHome";
import Carriers from "./components/sil/carriers/carriers";
import DryPlantHome from "./components/sil/dryPlant/dryPlantHome";
import DryPlantFormComponent from "./components/sil/dryPlant/dryPlantItemForm";
import DryPlantRunMaterial from "./components/sil/dryPlant/runMaterial";
import DryPlantEmployees from "./components/sil/dryPlant/employees";
import DryPlantEmployee from "./components/sil/dryPlant/employee";
import DryPlantFeedMaterial from "./components/sil/dryPlant/feedMaterial";
import DryPlantItems from "./components/sil/dryPlant/items";
import DryPlantFeedMaterials from "./components/sil/dryPlant/feedMaterials";
import DryPlantRunMaterials from "./components/sil/dryPlant/runMaterials";

import WashPlantHome from "./components/sil/washPlant/washPlantHome";
import WashPlantFormComponent from "./components/sil/washPlant/washPlantItemForm";
import WashPlantRunMaterial from "./components/sil/washPlant/runMaterial";
import WashPlantFeedMaterial from "./components/sil/washPlant/feedMaterial";
import WashPlantItems from "./components/sil/washPlant/items";
import WashPlantFeedMaterials from "./components/sil/washPlant/feedMaterials";
import WashPlantRunMaterials from "./components/sil/washPlant/runMaterials";
import WashPlantBinAdjustment from "./components/sil/washPlant/pileAdjustment";
import WashPlantBinAdjustments from "./components/sil/washPlant/pileAdjustments";
import SievesProducts from "./components/sil/sieves/sieveProducts";
import CarrierFormComponent from "./components/sil/carriers/carrierForm";
import SIMOSOrdersPage from "./components/propsense/simos/SIMOSOrderPage";
import CreateForm from "./components/propsense/simos/OrderPage/createForm";
import EditForm from "./components/propsense/simos/OrderPage/editForm";
import CloneFormPage from "./components/propsense/simos/OrderPage/cloneFormPage";
import ProductPage from './components/propsense/simos/ProductLookup/ProductPage';
import CrewScheduling from "./components/propsense/catapult/crew_scheduling/crew-scheduling";
import CreateProductForm from "./components/propsense/simos/ProductLookup/CreateProductForm";
import CustomerPage from "./components/propsense/simos/CustomerContactPage/CustomerPageView";
import CreateContactForm from "./components/propsense/simos/CustomerContactPage/createContactForm";
import InternalCustomer from "./components/propsense/simos/CustomerPage/internalCustomer";
import InternalCustomerCreate from "./components/propsense/simos/CustomerPage/internalCustomerCreate";
import InternalCustomerEdit from "./components/propsense/simos/CustomerPage/internalCustomerEdit";
import LoginHome from "./components/propsense/simos/LoginPage/LoginHome";
import DryPlantBinAdjustment from "./components/sil/dryPlant/binAdjustment";
import DryPlantBinAdjustments from "./components/sil/dryPlant/binAdjustments";
import TransportCompanyPage from "./components/propsense/simos/TransportCompanyPage/transportCompanyPageView";
import CreateTransportCompanyForm from "./components/propsense/simos/TransportCompanyPage/createTransportCompanyForm";
import EditTransportCompanyForm from "./components/propsense/simos/TransportCompanyPage/editTransportCompanyForm";
import ViewSIMOSOrderForm from "./components/propsense/simos/OrderPage/viewOrderForm";
import OrderHistoryPage from "./components/propsense/simos/HistoryPages/OrderHistory/orderHistoryPage";
import ProductHistoryPage from "./components/propsense/simos/HistoryPages/ProductHistory/productHistoryPage";
import CarrierEmails from "./components/sil/carriers/carrierEmails";
import CarrierEmail from "./components/sil/carriers/carrierEmail";
import CarrierFleetTrailers from "./components/sil/carriers/carrierFleetTrailers";
import CarrierFleetTrailer from "./components/sil/carriers/carrierFleetTrailer";
import YHDHome from "./yhdHome";
import SieveProduct from "./components/sil/sieves/sieveProduct";
import YHDCarrierFleetTrucks from "./components/propsense/yhd/carriers/fleetTrucks";
import YHDCarrierInfractions from "./components/propsense/yhd/carriers/carrierInfractions";
import YHDCarrierInfraction from "./components/propsense/yhd/carriers/carrierInfraction";
import YHDCarrierFleetTruck from "./components/propsense/yhd/carriers/fleetTruck";
import YHDCarriers from "./components/propsense/yhd/carriers/carriers";
import YHDCarrierEmails from "./components/propsense/yhd/carriers/carrierEmails";
import YHDCarrierEmail from "./components/propsense/yhd/carriers/carrierEmail";
import YHDCarrierFormComponent from "./components/propsense/yhd/carriers/carrierForm";
import SievesCalibrations from "./components/sil/sieves/sievesCalibrations";
import SieveCalibration from "./components/sil/sieves/sieveCalibration";
import SievesChecks from "./components/sil/sieves/sieveChecks";
import SieveCheck from "./components/sil/sieves/sieveCheck";
import Sieves from "./components/sil/sieves/sieves";
import Sieve from "./components/sil/sieves/sieve";
import CarrierInfractions from "./components/sil/carriers/carrierInfractions";
import CarrierInfraction from "./components/sil/carriers/carrierInfraction";
import ChemicalAnalysesList from "./components/sil/exploration/chemicalAnalysesList";
import ChemicalAnalyses from "./components/sil/exploration/chemicalAnalyses";
import warehouseProducts from "./components/sil/warehouse_products/warehouseProducts";
import warehouseProduct from "./components/sil/warehouse_products/warehouseProduct";
import baggedInventory from "./components/sil/bagged_inventory/baggedInventory";
import baggedInvetories from "./components/sil/bagged_inventory/baggedInvetories";
import complaintLogging from "./components/sil/complaint_loggings/complaintLogging";
import complaintLoggings from "./components/sil/complaint_loggings/complaintLoggings";

import BeltScales from "./components/sil/cv/beltScales";
import BeltScale from "./components/sil/cv/beltScale";
import Cranes from "./components/sil/cv/cranes";
import Crane from "./components/sil/cv/crane";
import LabScales from "./components/sil/cv/labScales";
import LabScale from "./components/sil/cv/labScale";
import NCRS from "./components/sil/cv/ncrs";
import NCR from "./components/sil/cv/ncr";
import SieveDryPlants from "./components/sil/cv/sieveDryPlants";
import SieveDryPlant from "./components/sil/cv/sieveDryPlant";
import SieveFracBalls from "./components/sil/cv/sieveFracBalls";
import SieveFracBall from "./components/sil/cv/sieveFracBall";
import SieveWashPlants from "./components/sil/cv/sieveWashPlants";
import SieveWashPlant from "./components/sil/cv/sieveWashPlant";
import TruckScales from "./components/sil/cv/truckScales";
import TruckScale from "./components/sil/cv/truckScale";
import Warehouses from "./components/sil/cv/warehouses";
import Warehouse from "./components/sil/cv/warehouse";
import GeoBag from "./components/sil/dispatch/geoBag";
import GeoBags from "./components/sil/dispatch/geoBags";
import TransloadBOLCount from "./components/sil/dispatch/transloadBOLCount";
import TransloadBOLCounts from "./components/sil/dispatch/transloadBOLCounts";
import TrailerActivityLog from "./components/sil/dispatch/trailerActivityLog";
import TrailerActivityLogs from "./components/sil/dispatch/trailerActivityLogs";
import SILBruOrders from "./components/propsense/simos/Order/silBruOrders";
import SILEdOrders from "./components/propsense/simos/Order/silEdOrders";
import SILBruOrder from "./components/propsense/simos/Order/silBruOrder";
import SILEdOrder from "./components/propsense/simos/Order/silEdOrder";
import SILAudits from "./components/sil/audit/audits";
import SILAudit from "./components/sil/audit/audit";

import qualityControlTesting from "./components/sil/qualityControlTesting/qualityControlTesting";
import qualityControlTestings from "./components/sil/qualityControlTesting/qualityControlTestings";
import { userName } from "./shared/constExports";
import SimosProducts from "./components/propsense/simos/Products/products";
import AddUpdateSimosProduct from "./components/propsense/simos/Products/addUpdateProduct";
import SimosTransportCompanies from "./components/propsense/simos/TransportCompanies/TransportCompanies";
import AddUpdateSimosTransportCompany from "./components/propsense/simos/TransportCompanies/addUpdateTransportCompany";
import SimosCustomers from "./components/propsense/simos/Customers/customers";
import AddUpdateSimosCustomer from "./components/propsense/simos/Customers/addUpdateCustomer";
import SimosCustomerContacts from "./components/propsense/simos/CustomerContacts/customerContacts";
import AddUpdateSimosCustomerContact from "./components/propsense/simos/CustomerContacts/addUpdateCustomerContact";

interface ISecuredRoutesStoreProps {
};

interface ISecuredRoutesDispatchProps {
};

interface ISecuredRoutesState {
    showHeader: boolean;
    showFooter: boolean;
};

type ISecuredRoutesProps =
    RouteComponentProps &
    ISecuredRoutesDispatchProps &
    ISecuredRoutesStoreProps;

class SecuredRoutes extends Component<ISecuredRoutesProps, ISecuredRoutesState> {
    public constructor(props: ISecuredRoutesProps) {
        super(props);
        this.state = {
            showHeader: true,
            showFooter: true
        };
    }

    public render(): ReactNode {
        return (
            <>
                {this.state.showHeader && <Header 
                    {...this.props}
                    userName={userName}
                />}
                <Switch>
                    <Route exact={true} path={ROUTE.INDEX} component={HomeComponent} />
                    <Route exact={true} path={ROUTE.PROPSENSE.INDEX} component={CatapultHome} />
                    <Route exact={true} path={ROUTE.SIMOS.INDEX} component={SIMOSHome} />
                    <Route exact={true} path={ROUTE.SIMOS.LOGIN} component={LoginHome} />
                    <Route exact={true} path={ROUTE.SIMOS.ORDER.CREATEORDER} component={CreateForm} />
                    <Route exact={true} path={ROUTE.SIMOS.ORDER.EDITORDER} component={EditForm} />
                    <Route exact={true} path={ROUTE.SIMOS.ORDER.CLONEORDER} component={CloneFormPage} />
                    <Route exact={true} path={ROUTE.SIMOS.ORDER.VIEWORDER} component={ViewSIMOSOrderForm} />
                    <Route exact={true} path={ROUTE.SIMOS.ORDER.INDEX} component={SIMOSOrdersPage} />

                    <Route exact={true} path={ROUTE.SIMOS.SIL_Bru_Orders.INDEX} component={SILBruOrders} />
                    <Route exact={true} path={ROUTE.SIMOS.SIL_Bru_Orders.ADD_UPDATE} component={SILBruOrder} />
                    <Route exact={true} path={ROUTE.SIMOS.SIL_Ed_Orders.INDEX} component={SILEdOrders} />
                    <Route exact={true} path={ROUTE.SIMOS.SIL_Ed_Orders.ADD_UPDATE} component={SILEdOrder} />
                    {/* <Route exact={true} path={ROUTE.SIMOS.PRODUCT.INDEX} component={ProductPage} /> */}
                    <Route exact={true} path={ROUTE.SIMOS.PRODUCT.INDEX} component={SimosProducts} />
                    <Route exact={true} path={ROUTE.SIMOS.PRODUCT.ADDUPDATEPRODUCT} component={AddUpdateSimosProduct} />
                    <Route exact={true} path={ROUTE.SIMOS.PRODUCT.CREATEPRODUCT} component={CreateProductForm} />
                    {/* <Route exact={true} path={ROUTE.SIMOS.CUSTOMERCONTACT.INDEX} component={CustomerPage} /> */}
                    <Route exact={true} path={ROUTE.SIMOS.CUSTOMERCONTACT.INDEX} component={SimosCustomerContacts} />
                    <Route exact={true} path={ROUTE.SIMOS.CUSTOMERCONTACT.ADDUPDATECUSTOMERCONTACT} component={AddUpdateSimosCustomerContact} />
                    <Route exact={true} path={ROUTE.SIMOS.CUSTOMERCONTACT.CREATECUSTOMERCONTACT} component={CreateContactForm} />
                    {/* <Route exact={true} path={ROUTE.SIMOS.INTERNALCUSTOMER.INDEX} component={InternalCustomer} /> */}
                    <Route exact={true} path={ROUTE.SIMOS.CUSTOMER.INDEX} component={SimosCustomers} />
                    <Route exact={true} path={ROUTE.SIMOS.CUSTOMER.ADDUPDATECUSTOMER} component={AddUpdateSimosCustomer} />
                    <Route exact={true} path={ROUTE.SIMOS.INTERNALCUSTOMER.CREATECUSTOMER} component={InternalCustomerCreate} />
                    <Route exact={true} path={ROUTE.SIMOS.INTERNALCUSTOMER.EDITCUSTOMER} component={InternalCustomerEdit} />
                    {/* <Route exact={true} path={ROUTE.SIMOS.TRANSPORTCOMPANIES.INDEX} component={TransportCompanyPage} /> */}
                    <Route exact={true} path={ROUTE.SIMOS.TRANSPORTCOMPANIES.INDEX} component={SimosTransportCompanies} />
                    <Route exact={true} path={ROUTE.SIMOS.TRANSPORTCOMPANIES.ADDUPDATETRANSPORTCOMPANY} component={AddUpdateSimosTransportCompany} />
                    <Route exact={true} path={ROUTE.SIMOS.TRANSPORTCOMPANIES.CREATETRANSPORTCOMPANY} component={CreateTransportCompanyForm} />
                    <Route exact={true} path={ROUTE.SIMOS.TRANSPORTCOMPANIES.EDITTRANSPORTCOMPANY} component={EditTransportCompanyForm} />
                    <Route exact={true} path={ROUTE.SIMOS.HISTORY.ORDER} component={OrderHistoryPage}/>
                    <Route exact={true} path={ROUTE.SIMOS.HISTORY.PRODUCT} component={ProductHistoryPage}/>
                    
                    <Route exact={true} path={ROUTE.PROPSENSE.CATAPULT.CREW_SCHEDULING.INDEX()} component={CrewScheduling} />
                    <Route exact={true} path={ROUTE.SIL.INDEX} component={SilHome} />
                    <Route exact={true} path={ROUTE.SIL.CARRIERS.INDEX} component={Carriers} />
                    <Route exact={true} path={ROUTE.SIL.CARRIERS.CARRIERS} component={Carriers} />
                    <Route exact={true} path={ROUTE.SIL.CARRIERS.CARRIER_EMAILS} component={CarrierEmails} />
                    <Route exact={true} path={ROUTE.SIL.CARRIERS.CARRIER_EMAIL} component={CarrierEmail} />
                    <Route exact={true} path={ROUTE.SIL.CARRIERS.CARRIER_TRAILERS} component={CarrierFleetTrailers} />
                    <Route exact={true} path={ROUTE.SIL.CARRIERS.CARRIER_TRAILER} component={CarrierFleetTrailer} />
                    <Route exact={true} path={ROUTE.SIL.CARRIERS.CARRIER_INFRACTIONS} component={CarrierInfractions} />
                    <Route exact={true} path={ROUTE.SIL.CARRIERS.CARRIER_INFRACTION} component={CarrierInfraction} />
                    <Route exact={true} path={ROUTE.SIL.CARRIERS.CARRIER} component={CarrierFormComponent} />
                    <Route exact={true} path={ROUTE.SIL.CARRIERS.CARRIER_AUDITS} component={SILAudits} />
                    <Route exact={true} path={ROUTE.SIL.CARRIERS.CARRIER_AUDIT} component={SILAudit} />
                    <Route exact={true} path={ROUTE.SIL.DRY_PLANT.INDEX} component={DryPlantHome} />
                    <Route exact={true} path={ROUTE.SIL.DRY_PLANT.DRY_PLANT_ITEMS} component={DryPlantItems} />
                    <Route exact={true} path={ROUTE.SIL.DRY_PLANT.DRY_PLANT_ITEM} component={DryPlantFormComponent} />
                    <Route exact={true} path={ROUTE.SIL.DRY_PLANT.DRY_PLANT_RUN_MATERIALS} component={DryPlantRunMaterials} />
                    <Route exact={true} path={ROUTE.SIL.DRY_PLANT.DRY_PLANT_RUN_MATERIAL} component={DryPlantRunMaterial} />
                    <Route exact={true} path={ROUTE.SIL.DRY_PLANT.DRY_PLANT_FEED_MATERIALS} component={DryPlantFeedMaterials} />
                    <Route exact={true} path={ROUTE.SIL.DRY_PLANT.DRY_PLANT_FEED_MATERIAL} component={DryPlantFeedMaterial} />
                    <Route exact={true} path={ROUTE.SIL.DRY_PLANT.DRY_PLANT_EMPLOYEES} component={DryPlantEmployees} />
                    <Route exact={true} path={ROUTE.SIL.DRY_PLANT.DRY_PLANT_EMPLOYEE} component={DryPlantEmployee} />
                    <Route exact={true} path={ROUTE.SIL.DRY_PLANT.DRY_PLANT_BIN_ADJUSTMENTS} component={DryPlantBinAdjustments} />
                    <Route exact={true} path={ROUTE.SIL.DRY_PLANT.DRY_PLANT_BIN_ADJUSTMENT} component={DryPlantBinAdjustment} />

                    <Route exact={true} path={ROUTE.SIL.SIEVES.SIEVES_CALIBRATIONS} component={SievesCalibrations} />
                    <Route exact={true} path={ROUTE.SIL.SIEVES.SIEVES_CALIBRATION} component={SieveCalibration} />
                    <Route exact={true} path={ROUTE.SIL.SIEVES.SIEVES_CHECKS} component={SievesChecks} />
                    <Route exact={true} path={ROUTE.SIL.SIEVES.SIEVES_CHECK} component={SieveCheck} />
                    <Route exact={true} path={ROUTE.SIL.SIEVES.SIEVES_ITEMS} component={Sieves} />
                    <Route exact={true} path={ROUTE.SIL.SIEVES.SIEVES_ITEM} component={Sieve} />
                    <Route exact={true} path={ROUTE.SIL.SIEVES.SIEVE_PRODUCTS} component={SievesProducts} />
                    <Route exact={true} path={ROUTE.SIL.SIEVES.SIEVE_PRODUCT} component={SieveProduct} />
                    <Route exact={true} path={ROUTE.SIL.SIEVES.INDEX} component={Sieves} />

                    <Route exact={true} path={ROUTE.SIL.DISPATCH.INDEX} component={GeoBags} />
                    <Route exact={true} path={ROUTE.SIL.DISPATCH.GEO_BAGS} component={GeoBags} />
                    <Route exact={true} path={ROUTE.SIL.DISPATCH.GEO_BAG} component={GeoBag} />
                    <Route exact={true} path={ROUTE.SIL.DISPATCH.TRAILER_ACTIVITY_LOG} component={TrailerActivityLog} />
                    <Route exact={true} path={ROUTE.SIL.DISPATCH.TRAILER_ACTIVITY_LOGS} component={TrailerActivityLogs} />
                    <Route exact={true} path={ROUTE.SIL.DISPATCH.TRANSLOAD_BOL_COUNT} component={TransloadBOLCount} />
                    <Route exact={true} path={ROUTE.SIL.DISPATCH.TRANSLOAD_BOL_COUNTS} component={TransloadBOLCounts} />

                    <Route exact={true} path={ROUTE.SIL.WASH_PLANT.INDEX} component={WashPlantHome} />
                    <Route exact={true} path={ROUTE.SIL.WASH_PLANT.WASH_PLANT_ITEMS} component={WashPlantItems} />
                    <Route exact={true} path={ROUTE.SIL.WASH_PLANT.WASH_PLANT_ITEM} component={WashPlantFormComponent} />
                    <Route exact={true} path={ROUTE.SIL.WASH_PLANT.WASH_PLANT_RUN_MATERIALS} component={WashPlantRunMaterials} />
                    <Route exact={true} path={ROUTE.SIL.WASH_PLANT.WASH_PLANT_RUN_MATERIAL} component={WashPlantRunMaterial} />
                    <Route exact={true} path={ROUTE.SIL.WASH_PLANT.WASH_PLANT_FEED_MATERIALS} component={WashPlantFeedMaterials} />
                    <Route exact={true} path={ROUTE.SIL.WASH_PLANT.WASH_PLANT_FEED_MATERIAL} component={WashPlantFeedMaterial} />
                    <Route exact={true} path={ROUTE.SIL.WASH_PLANT.WASH_PLANT_EMPLOYEES} component={DryPlantEmployees} />
                    <Route exact={true} path={ROUTE.SIL.WASH_PLANT.WASH_PLANT_EMPLOYEE} component={DryPlantEmployee} />
                    <Route exact={true} path={ROUTE.SIL.WASH_PLANT.WASH_PLANT_BIN_ADJUSTMENTS} component={WashPlantBinAdjustments} />
                    <Route exact={true} path={ROUTE.SIL.WASH_PLANT.WASH_PLANT_BIN_ADJUSTMENT} component={WashPlantBinAdjustment} />

                    <Route exact={true} path={ROUTE.SIL.EXPLORATION.INDEX} component={ChemicalAnalysesList} />
                    <Route exact={true} path={ROUTE.SIL.EXPLORATION.CHEMICAL_ANALYSES_LIST} component={ChemicalAnalysesList} />
                    <Route exact={true} path={ROUTE.SIL.EXPLORATION.CHEMICAL_ANALYSES} component={ChemicalAnalyses} />

                    <Route exact={true} path={ROUTE.SIL.WAREHOUSE_PRODUCTS.PRODUCTS} component={warehouseProducts} />
                    <Route exact={true} path={ROUTE.SIL.WAREHOUSE_PRODUCTS.PRODUCT} component={warehouseProduct} />

                    <Route exact={true} path={ROUTE.SIL.BAGGED_INVENTORIES.INDEX} component={baggedInvetories} />
                    <Route exact={true} path={ROUTE.SIL.BAGGED_INVENTORIES.BAGGED_INVENTORY} component={baggedInventory} />

                    <Route exact={true} path={ROUTE.SIL.COMPLAINT_LOGGINGS.INDEX} component={complaintLoggings} />
                    <Route exact={true} path={ROUTE.SIL.COMPLAINT_LOGGINGS.COMPLAINT_LOGGING} component={complaintLogging} />
                    
                    <Route exact={true} path={ROUTE.SIL.QUALITY_CONTROL_TESTINGS.INDEX} component={qualityControlTestings} />
                    <Route exact={true} path={ROUTE.SIL.QUALITY_CONTROL_TESTINGS.QUALITY_CONTROL_TESTING} component={qualityControlTesting} />

                    <Route exact={true} path={ROUTE.SIL.CV.BELT_SCALES.INDEX} component={BeltScales} />
                    <Route exact={true} path={ROUTE.SIL.CV.BELT_SCALES.BELT_SCALE} component={BeltScale} />
                    <Route exact={true} path={ROUTE.SIL.CV.CRANES.INDEX} component={Cranes} />
                    <Route exact={true} path={ROUTE.SIL.CV.CRANES.CRANE} component={Crane} />
                    <Route exact={true} path={ROUTE.SIL.CV.LAB_SCALES.INDEX} component={LabScales} />
                    <Route exact={true} path={ROUTE.SIL.CV.LAB_SCALES.LAB_SCALE} component={LabScale} />
                    <Route exact={true} path={ROUTE.SIL.CV.NCR.INDEX} component={NCRS} />
                    <Route exact={true} path={ROUTE.SIL.CV.NCR.NCR} component={NCR} />
                    <Route exact={true} path={ROUTE.SIL.CV.SIEVE_DRY_PLANTS.INDEX} component={SieveDryPlants} />
                    <Route exact={true} path={ROUTE.SIL.CV.SIEVE_DRY_PLANTS.SIEVE_DRY_PLANT} component={SieveDryPlant} />
                    <Route exact={true} path={ROUTE.SIL.CV.SIEVE_FRAC_BALLS.INDEX} component={SieveFracBalls} />
                    <Route exact={true} path={ROUTE.SIL.CV.SIEVE_FRAC_BALLS.SIEVE_DRY_PLANT} component={SieveFracBall} />
                    <Route exact={true} path={ROUTE.SIL.CV.SIEVE_WASH_PLANTS.INDEX} component={SieveWashPlants} />
                    <Route exact={true} path={ROUTE.SIL.CV.SIEVE_WASH_PLANTS.SIEVE_WASH_PLANT} component={SieveWashPlant} />
                    <Route exact={true} path={ROUTE.SIL.CV.TRUCK_SCALES.INDEX} component={TruckScales} />
                    <Route exact={true} path={ROUTE.SIL.CV.TRUCK_SCALES.TRUCK_SCALE} component={TruckScale} />
                    <Route exact={true} path={ROUTE.SIL.CV.WAREHOUSES.INDEX} component={Warehouses} />
                    <Route exact={true} path={ROUTE.SIL.CV.WAREHOUSES.WAREHOUSE} component={Warehouse} />

                    <Route exact={true} path={ROUTE.PROPSENSE.YHD.INDEX} component={YHDHome} />
                    <Route exact={true} path={ROUTE.PROPSENSE.YHD.CARRIERS.INDEX} component={YHDCarriers} />
                    <Route exact={true} path={ROUTE.PROPSENSE.YHD.CARRIERS.CARRIERS} component={YHDCarriers} />
                    <Route exact={true} path={ROUTE.PROPSENSE.YHD.CARRIERS.CARRIER_EMAILS} component={YHDCarrierEmails} />
                    <Route exact={true} path={ROUTE.PROPSENSE.YHD.CARRIERS.CARRIER_EMAIL} component={YHDCarrierEmail} />
                    <Route exact={true} path={ROUTE.PROPSENSE.YHD.CARRIERS.CARRIER} component={YHDCarrierFormComponent} />
                    <Route exact={true} path={ROUTE.PROPSENSE.YHD.CARRIERS.CARRIER_FLEET_TRUCKS} component={YHDCarrierFleetTrucks} />
                    <Route exact={true} path={ROUTE.PROPSENSE.YHD.CARRIERS.CARRIER_FLEET_TRUCK} component={YHDCarrierFleetTruck} />
                    <Route exact={true} path={ROUTE.PROPSENSE.YHD.CARRIERS.CARRIER_INFRACTIONS} component={YHDCarrierInfractions} />
                    <Route exact={true} path={ROUTE.PROPSENSE.YHD.CARRIERS.CARRIER_INFRACTION} component={YHDCarrierInfraction} />

                    <Route render={(): ReactNode => <Error404Page handleShowHeader={this.handleShowHeader} />} />
                </Switch>
            </>
        );

    }

    private handleShowHeader = (showHeader: boolean): void => {
        this.setState({ showHeader });
    };
}

const mapStateToProps = (state: IStore): ISecuredRoutesStoreProps => ({
});

const mapDispatchToProps = (dispatch: IDispatch): ISecuredRoutesDispatchProps => ({
});


export default connect(mapStateToProps, mapDispatchToProps)(SecuredRoutes);
