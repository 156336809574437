import { IFeedMaterials } from "../getFeedMaterials/getFeedMaterialsConstaints";

export enum IADD_FEED_MATERIAL_REQUEST {
    REQUEST = "addFeedMaterial/ADD_FEED_MATERIAL_REQUEST",
    SUCCESS = "addFeedMaterial/ADD_FEED_MATERIAL_SUCCESS",
    FAILED = "addFeedMaterial/ADD_FEED_MATERIAL_FAILED"
};

export interface IAddFeedMaterialRequest {
    token: string;
    request: IFeedMaterials;
};