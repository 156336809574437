import React from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import BackButton from '../../../../shared/simos/helper/backButton';
import {customerscopy} from './internalCustomerTable';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import {currentUser} from '../../../../simosHome';
import {getSIMOSUserGroupAccess} from '../APICalls/getSIMOSGroupAccess';
import { getSILURLParams } from '../../../../shared/simos/helper/getURLContainsSIL';

interface IProps {
    history:any;
}
interface IState {
    username:string;
    password:string;
    error:boolean;
    showError:boolean;
    errorText:string;
}
const customer = {
    customer : '',
    id:0,
    active:'',
    explorer_code:'',
}
var user_role:any = "";
export default class InternalCustomerCreate extends React.Component<IProps, IState>{
    constructor(props:any){
        super(props);
        this.state = {username:'',password:'', error:false, showError:false, errorText:''}
        this.onTextChange = this.onTextChange.bind(this);
    }
    async addCustomerAddress(customer_name: string, explorer_code:string) {
        var responseStatus:any = null;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type':'application/json'},
            body: JSON.stringify({
                request: {
                    Customer:customer_name,
                    Active:"Yes",
                    Explorer_Code:explorer_code,
                    Created_By:currentUser,
                    Modified_By:currentUser
                },
                token: '19515195-d571-44a3-b0db-637f5bf24f54'})
        }
        await fetch('https://api2.propsense.com/api/AddSIMOSCustomer', requestOptions)
        .then(async response => {
            const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
            // error response
            if(!response.ok) {
              const error = (data && data.message) || response.status;
              return Promise.reject(error);
          }
          // no error
          responseStatus = response;
        });
        return responseStatus;
    }
    async onSave(event:any){
        event.preventDefault();
        if(customer.active != "" && customer.customer != "" && !this.state.error){
                var res:any = await this.addCustomerAddress(customer.customer, customer.explorer_code);
                if(res.status === 200){
                    alert("Successfully added new SIMOS customer");
                    this.props.history.replace('/simos/customers'+getSILURLParams());
                    window.location.reload();
                }
                else{
                    alert("Error creating new SIMOS customer: server error");
                }
            }
            else{
                //alert("All fields must be filled!");
                this.setState({showError:true, errorText:'All fields must be filled!'});
            }
    }
    onCancel(event:any){
        event.preventDefault();
        this.props.history.replace('/simos/customers'+getSILURLParams());
    }
    onTextChange(event:any) {
        var exists = false;
        switch(event.target.name){
            case 'customername':
                customerscopy.map((cust:any) => {
                    if(cust.customer === event.target.value){
                        exists = true;
                        this.setState({error:exists});
                    }
                })
                customer.customer=event.target.value;
                this.setState({error:exists});
                break;
            case 'activeradio':
                customer.active=event.target.value;
                break;
            case 'explorercode':
                customer.explorer_code = event.target.value;
                break;
        }
    }
    async componentWillMount(){
        var response:any = await getSIMOSUserGroupAccess();
        var userRole:any = response.role;
        user_role = userRole;
        if(userRole!='Admin' && userRole!='Dispatcher' && userRole!='SuperAdmin'){
            this.props.history.push('/simos');
        }
    }
    render(){
        return (
            <div className={getSILURLParams()?.includes("bru") ? "edit-popup":"edit-popup-sil-ed"}>
            <div className = 'edit-popup_inner'>
            {this.state.showError ? 
              <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert variant="filled" severity="error" action={<IconButton color="inherit" onClick={() => {this.setState({showError:false})}}>X</IconButton>}>
                  {this.state.errorText}
                </Alert>
              </Stack>
            :null}
            <br></br>
            <BackButton goBack={this.onCancel.bind(this)} history={this.props.history}></BackButton>
                <form>
                    <h3>Creating New Customer</h3>
                        <div id="edit-info-fields">
                            <Grid container spacing={3} direction="column" alignItems="center">
                                <Grid item xs={12}>
                                    <TextField autoComplete='nope' helperText={this.state.error === true ? 'Customer already exists':null} error={this.state.error===true} id={'outlined-error-helper-text'} label="Customer Name" name="customername" onChange={this.onTextChange}></TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField disabled={user_role!=='SuperAdmin'} autoComplete='nope' label="Explorer Code" name="explorercode" onChange={this.onTextChange}></TextField>
                                </Grid>
                                <Grid item xs={6}>
                                <FormLabel component="legend">Active</FormLabel>
                                    <RadioGroup aria-label="gender" name="activeradio" onChange={this.onTextChange}>
                                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="No" control={<Radio />} label="No" />
                                    </RadioGroup>
                                </Grid>
                                </Grid>
                                
                                </div>
                     <br></br>
                        <button type='submit' onClick={this.onSave.bind(this)} id='submit-contact-form-btn'>Save</button>
                        <button onClick={this.onCancel.bind(this)} id='cancel-contact-form-btn'>Cancel</button>
                </form>
            </div>
            </div>
        );
    }
}