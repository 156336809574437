import { IPassTokenWithRequest } from "../token/getTokenConstants";

export enum IGET_PRODUCTION_RECORDS_REQUEST {
    REQUEST = "getProductionRecords/GET_PRODUCTION_RECORDS_REQUEST",
    SUCCESS = "getProductionRecords/GET_PRODUCTION_RECORDS_SUCCESS",
    FAILED = "getProductionRecords/GET_PRODUCTION_RECORDS_FAILED"
};

export interface IGetProductionRecordsRequest extends IPassTokenWithRequest {

};

export interface IProductionRecords {
    id: number;
    date: string;
    location: string;
    shift_Worked: string;
    control_Room: string;
    plants: string;
    dryplant_Operator: string;
    load_Operator: string;
    employee4?: string;
    employee5?: string;
    production_Shift_Goal: string;
    qA_QC_Notes: string;
    production_Notes: string;
    screens_Changed: string;
    wear_Issues_Notes: string;
    maintenance_By?: string;
    maintenance_Performed?: string;
    created: string;
    modified: string;
    created_By: string;
    modified_By: string;
    downtimes: IDownTime[];
    production_Data: IProductionData[];
    // specifyValue?: string;
};

export interface IDownTime {
    id: number;
    listID: number;
    plant?: string;
    un_Scheduled_Downtime_Hrs?: number;
    unschD_Notes?: string;
    scheduled_Downtime_Hrs?: number;
    schD_Notes?: string;
    component?: string;
    cause?: string;
    reason?: string;
    category?: string;
};

export interface IProductionData {
    id: number;
    listID: number;
    plant?: string;
    run_Material: string;
    run_Material_MT: number; //Decimal
    by_Product_1?: string;
    by_Product_1_MT?: number;
    by_Product_2?: string;
    by_Product_2_MT?: number;
    by_Product_3?: string;
    by_Product_3_MT?: number;
    by_Product_4?: string;
    by_Product_4_MT?: number;
    start_Time: number;
    start_Time_Mins: number;
    stop_Time: number;
    stop_Time_Mins: number;
    run_Hours?: string;
    production_Top: string;
    production_Mid: string;
    production_Bottom: string;
    tph?: number; //Decimal
    feed_Tonnage?: number; //Decimal
    magging?: string;
    bottle_Neck: string;
    feed_1_Material: string;
    feed_1_Perc: number; //Decimal
    feed_2_Material?: string;
    feed_2_Perc?: number;
    feed_3_Material?: string;
    feed_3_Perc?: number;
    feed_4_Material?: string;
    feed_4_Perc?: number;
    polish?: string;
    created: string;
    modified: string;
    created_By: string;
    modified_By: string;
};