import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IAddWashPlantProductionLoadAction, IAddWashPlantProductionLoadFailedAction, IAddWashPlantProductionSuccessAction, addWashPlantProductionLoadFailedAction,
     addWashPlantProductionLoadSuccessAction } from "./addWashPlantProductionActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IADD_WASH_PLANT_PRODUCTION_REQUEST } from "./addWashPlantProductionConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";

export const addWashPlantProductionEpic: Epic = (
    action$: ActionsObservable<IAddWashPlantProductionLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IAddWashPlantProductionSuccessAction | IAddWashPlantProductionLoadFailedAction> =>
    action$.ofType(IADD_WASH_PLANT_PRODUCTION_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<string>>(
                    END_POINTS.PROPSENSE.SIL.WASH_PLANT.ADD_WASH_PLANT_PRODUCTION,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<string>): IAddWashPlantProductionSuccessAction | IAddWashPlantProductionLoadFailedAction => {
                        
                            if(response.message === "Success"){
                                return addWashPlantProductionLoadSuccessAction(response.message);
                            }
                            return addWashPlantProductionLoadFailedAction(response.message);
                        }),
                        catchError(() => ActionsObservable.of(addWashPlantProductionLoadFailedAction("Unable to save your changes")))
                    )
            )
        );