import { IPassTokenWithRequest } from "../../../sil/dryPlant/token/getTokenConstants";
import { ISIMOSOrders } from "../getOrders/getOrdersConstaints";

export enum IADD_ORDER_REQUEST {
    REQUEST = "addOrder/ADD_ORDER_REQUEST",
    SUCCESS = "addOrder/ADD_ORDER_SUCCESS",
    FAILED = "addOrder/ADD_ORDER_FAILED"
};

export interface IAddOrderRequest extends IPassTokenWithRequest {
    request: ISIMOSOrders;
};