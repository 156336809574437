import React from 'react';
import updateNewContact from '../APICalls/editCustomerContact';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import BackButton from '../../../../shared/simos/helper/backButton';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import {all_customer_contacts} from './CustomerContactTable';
import { getSILURLParams } from '../../../../shared/simos/helper/getURLContainsSIL';

interface IProps{
    editID: number;
    customer_contacts:any[];
    history:any;
    closePopup:any;
}

interface IState {
    contacts:string[];
    customers:string[];
    delivery:string[];
    showError:boolean;
}
let customerID:any=[];
let chosenDeliveryID:any = null;
let chosenCustomerID:any = null;
var prevCustomer:any, prevDelivery:any,prevContact:string,prevPhone:string,prevEmail:string,prevFax:string; var prevContactID:number;
export default class EditCustomerContactForm extends React.Component<IProps, IState>{
    constructor(props:any) {
        super(props);
        this.state = {
            contacts:[],customers:[],delivery:[],showError:false
        }
        this.handleEdit = this.handleEdit.bind(this);
        //console.log(this.props);
    }

    handleEdit(event:any) {
        switch(event.target.name){
            case 'inputCustomer':
                prevCustomer = event.target.value;
                customerID.map((each:any) => {
                    if(each.customer === event.target.value) {
                        chosenCustomerID = each.id;
                    }
                });
                this.fetchDeliveryAddress(null);
                break;
            case 'inputDelivery':
                prevDelivery = event.target.value;
                break;
            case 'contact_name':
                prevContact = event.target.value;
                break;
            case 'phone_number':
                prevPhone = event.target.value;
                break;
            case 'email':
                prevEmail = event.target.value;
                break;
            case 'fax':
                prevFax = event.target.value;
                break;
        }
    }
    async updateContact(old_contact:any){
        var res:any = await updateNewContact(old_contact);
        if(res.status === 200){
            alert("Successfully updated customer contact");
            this.props.closePopup();
            window.location.reload();
        }
        else{
            alert("There was an error updating customer contact: server error");
        }
    }
     async fetchDeliveryAddress(delivery_addr:any) {
        let initialDelivery:string[] = [];
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type':'application/json' }, 
            body: JSON.stringify({token: '19515195-d571-44a3-b0db-637f5bf24f54' })
        }
        fetch('https://api2.propsense.com/api/GetSIMOSCustomerAddress', requestOptions)
        .then(async response => {
            const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
              // error response
              if(!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            // no error
            data.map((deliveryaddr:any) => {
                // obtain a list of all delivery addresses
                if(deliveryaddr.customer_ID === chosenCustomerID){
                    initialDelivery.push(deliveryaddr.address);
                }
                // Save the order if a delivery_address was provided
                if(delivery_addr!= null && deliveryaddr.address === delivery_addr) {
                    chosenDeliveryID = deliveryaddr.id;
                    this.props.customer_contacts.map(old_contact => { 
                    if(old_contact.id === this.props.editID) {
                        old_contact.customer_ID = chosenCustomerID;
                        old_contact.delivery_ID = chosenDeliveryID;
                        old_contact.customer = prevCustomer;
                        old_contact.delivery_Address = prevDelivery;
                        old_contact.contact = prevContact;
                        old_contact.phone = prevPhone;
                        old_contact.email = prevEmail;
                        old_contact.fax = prevFax;
                        var isUnique = true;
                        all_customer_contacts.map((each:any) => {
                            // Check each condition but ignore if the same record matches
                            if(each.contact === prevContact && each.customer === prevCustomer
                                && each.delivery_Address === prevDelivery && each.phone === prevPhone && each.id != old_contact.id){
                                    isUnique = false;
                                }
                        });

                        if(old_contact.contact != "" && old_contact.phone != ""){
                            if(isUnique){
                                this.updateContact(old_contact);
                            }
                            else{
                                alert("This customer contact already exists for this customer/delivery address");
                            }
                        }
                        else{
                            //alert("All fields must be filled!");
                            this.setState({showError:true});
                        }
                    }
                })
                }
            });
            this.setState({delivery:initialDelivery});

        })
    }

    saveEdit(event:any) {
        event.preventDefault();
        // fetch the deliveryID and save it
        this.fetchDeliveryAddress(prevDelivery);
        //this.props.history.push('/viewCustomerContacts')
    }
    cancelEdit() {
        this.props.closePopup();
        //this.props.history.push('/viewCustomerContacts')
    }
    // Fetch the customers from db
    componentDidMount() {
        let initialCustomers:string[] = [];
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token: '19515195-d571-44a3-b0db-637f5bf24f54' })
        };
    
        fetch('https://api2.propsense.com/api/GetSIMOSCustomers', requestOptions)
        .then(async response => {
            const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
            
            // error response
            if(!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            // no error
            data.map((customername:any) => {
                    initialCustomers.push(customername.customer);
                    customerID.push(customername);
                   // if(customername.id === prevContactID){
                   //     chosenCustomerID = customername.id;
                   // }
                chosenCustomerID = prevContactID;
            });
            this.setState({customers:initialCustomers});
        })
        .catch(error => {
            console.log('There was an error!', error);
        });
    }
    render() {
        let customer_options = this.state.customers.map((customer) => <option>{customer}</option>);
        customer_options.push(<option disabled selected></option>)
        let delivery_options = this.state.delivery.map((address) => <option>{address}</option>);
        return (
            <div className={getSILURLParams()?.includes("bru") ? "edit-popup":"edit-popup-sil-ed"}>
                <div className = 'edit-popup_inner'>
                {this.state.showError ? 
                    <Stack sx={{ width: '100%' }} spacing={2}>
                        <Alert variant="filled" severity="error" action={<IconButton color="inherit" onClick={() => {this.setState({showError:false})}}>X</IconButton>}>
                        Please enter Customer, Customer Address, Contact Name and Phone #.
                        </Alert><br></br>
                    </Stack>
                :null}
                <BackButton goBack={this.cancelEdit.bind(this)} history={this.props.history}></BackButton>
                    <form>
                        <h3>Editing Customer Contact <label id='header-3-label'>#{this.props.editID}</label></h3>
                        {this.props.customer_contacts.map((obj, ind) => {
                            if(obj.id === this.props.editID) {
                                // Store previous values in case no data was entered
                                prevContactID = obj.customer_ID;
                                prevCustomer = obj.customer;
                                prevDelivery = obj.delivery_Address;
                                prevEmail = obj.email;
                                prevFax=obj.fax;
                                prevContact = obj.contact;
                                prevPhone = obj.phone;
                                return( 
                                    <div id="edit-info-fields"> 
                                        <div id='customer-deliver'>
                                        <Grid container spacing={3} direction="column" alignItems="center">
                                            <Grid item xs={6}>
                                                <TextField label="Customer" disabled defaultValue={prevCustomer}></TextField>
                                            {/* <FormControl required>
                                            <InputLabel htmlFor="customer-dropdown-options">Customer</InputLabel>
                                                <Select native value={prevCustomer} onChange={this.handleEdit} inputProps={{name:'inputCustomer',id:'customer-dropdown-options'}}>
                                                <option selected disabled>{prevCustomer}</option>
                                                {customer_options}
                                                </Select>        
                                                </FormControl> */}
                                            </Grid>
                                            <Grid item xs={6}>
                                            <TextField label="Delivery Address" disabled defaultValue={prevDelivery}></TextField>
                                            {/* <FormControl required>
                                                    <InputLabel htmlFor="delivery-dropdown-options">Delivery</InputLabel>
                                                    <Select native onChange={this.handleEdit} value={prevDelivery} inputProps={{name:'inputDelivery',id:'delivery-dropdown-options'}}>
                                                    <option selected disabled>{prevDelivery}</option>
                                                    {delivery_options}
                                                    </Select>         
                                                </FormControl> */}
                                                </Grid>
                                                {/* <label>Customer</label>
                                                <select name="inputCustomer" id='create-dropdown-options' placeholder ={obj.customer}value={this.state.chosenCustomer} onChange={this.handleEdit}>
                                                {customer_options}
                                                </select>
                                                <br></br>
                                        
                                                <label>Delivery</label>
                                                <select id='create-dropdown-options' name ="inputDelivery" placeholder={obj.delivery_Address}value={this.state.chosenDelivery} onChange={this.handleEdit}>
                                                    {delivery_options}
                                                </select> */}
                                        </Grid>
                                        </div><br></br>
                                        <Grid container spacing={3} direction="column" alignItems="center">
                                            <Grid item xs={6}>
                                                <TextField autoComplete='nope' label="Contact Name" name="contact_name" onChange={this.handleEdit} defaultValue={obj.contact}></TextField>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField autoComplete='nope' label="Phone Number" name="phone_number" onChange={this.handleEdit} defaultValue={obj.phone}></TextField>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField autoComplete='nope' label="Email" name="email" onChange={this.handleEdit} defaultValue={obj.email}></TextField>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField autoComplete='nope' label="Fax" name="fax" onChange={this.handleEdit} defaultValue={obj.fax}></TextField>
                                            </Grid>
                                            {/* <label id='contact-page-label'>Contact Name</label>
                                            <input type='text' id='edit-contact-input-field' name='contact_name' placeholder ={obj.contact}onChange={this.handleEdit}></input>
                                            <br></br> */}
                                            {/* <label id='contact-page-label'>Phone Number</label>
                                            <input type='text' id='edit-contact-input-field' name='phone_number' placeholder ={obj.phone}onChange={this.handleEdit}></input>
                                            <br></br>
                                            <label id='contact-page-label'>Email</label><br></br>
                                            <input type='text' id='edit-contact-input-field' name='email' placeholder ={obj.email}onChange={this.handleEdit}></input>
                                            <br></br>
                                            <label id='contact-page-label'>Fax</label><br></br>
                                            <input type='text' id='edit-contact-input-field' name='fax' placeholder ={obj.fax}onChange={this.handleEdit}></input> */}
                                        </Grid>
                                        </div>
                                   
                                );
                                  
                            }
                        })}
                        <br></br>
                    <button onClick={this.cancelEdit.bind(this)} id="cancel-contact-form-btn">Cancel</button>
                    <button onClick ={this.saveEdit.bind(this)} id="submit-contact-form-btn">Save</button>
                    </form>
                </div>
            </div>
        );
    }
}