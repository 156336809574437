import React from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import BackButton from '../../../../shared/simos/helper/backButton';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import {copyedit} from './transportCompanyTable';
import {updateTransportCompany} from '../APICalls/addUpdateTransportCompany';
import {getSIMOSUserGroupAccess} from '../APICalls/getSIMOSGroupAccess';
import { getSILURLParams } from '../../../../shared/simos/helper/getURLContainsSIL';

interface IProps {
    history:any;
    editID:number;
}
interface IState {
    transportCompanies:any[];
    showError:boolean;
}
var user_role:any = "";
export default class EditTransportCompanyForm extends React.Component<IProps, IState>{
    constructor(props:IProps){
        super(props);
        this.state = {transportCompanies:[],showError:false};
        this.onSaveEdit = this.onSaveEdit.bind(this);
    }
    onTextChange(event:any){
        switch(event.target.name) {
            case 'email':
                copyedit.email = event.target.value;
                break;
            case 'activeradio':
                copyedit.active = event.target.value;
                break;
            case 'truck_code_y':
                copyedit.truck_Code_Y = event.target.value;
                break;
            case 'truck_code_z':
                copyedit.truck_Code_Z = event.target.value;
                break;
        }
    }
    async onSaveEdit(event:any) {
        event.preventDefault();
if(copyedit.email != "" && copyedit.active != ""){
                var updateSuccess:any = await updateTransportCompany(copyedit.id, copyedit.email, copyedit.active, copyedit.truck_Code_Y, copyedit.truck_Code_Z);
                if(updateSuccess.status === 200){
                    alert("Success updating transport company");
                    this.props.history.replace('/simos/transport-companies'+getSILURLParams());
                    window.location.reload();
                }
                else {
                    alert("Error updating transport company");
                    this.props.history.push('/simos/transport-companies'+getSILURLParams());
                    window.location.reload();
                }
        }
        else{
            this.setState({showError:true});
        }
    }
    onCancelEdit(event:any){
        event.preventDefault();
        this.props.history.replace('/simos/transport-companies'+getSILURLParams());
    }
    async componentDidMount() {
        if(copyedit!=null){
            let initialTransport:string[] = [];
            const requestOptions = { method: 'POST',
            headers: { 'Content-Type':'application/json' }, 
            body: JSON.stringify({token: '19515195-d571-44a3-b0db-637f5bf24f54' })};

            fetch('https://api2.propsense.com/api/GetSIMOSTransportCompany', requestOptions)
            .then(async response => {
                const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
                // error response
                if(!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                // no error
                data.map((user:any) => {
                    initialTransport.push(user);
                });
                this.setState({transportCompanies:initialTransport});
            });   
            var response:any = await getSIMOSUserGroupAccess();
            var userRole:any = response.role;
            user_role = userRole;
            this.setState({});
        }
    }
    render() {
        if(copyedit!=null){
            console.log(copyedit)
        return (
            <div className={getSILURLParams()?.includes("bru") ? "edit-popup":"edit-popup-sil-ed"}>
                <div className = 'edit-popup_inner'> 
                {this.state.showError ? 
                    <Stack sx={{ width: '100%' }} spacing={2}>
                        <Alert variant="filled" severity="error" action={<IconButton color="inherit" onClick={() => {this.setState({showError:false})}}>X</IconButton>}>
                        All fields must be filled!
                        </Alert><br></br>
                    </Stack>
                :null}
                <BackButton goBack={this.onCancelEdit.bind(this)} history={this.props.history}></BackButton>
                    <form>
                        <h3>Editing Transport Company <label id='header-3-label'>#{copyedit.id}</label></h3>
                            <div id="edit-info-fields"> 
                                {this.state.transportCompanies.map(transport => {
                                    if(transport.id === copyedit.id){
                                           return <div className="row">
                                               <Grid container spacing={3} direction="column">
                                                   <Grid item xs={12}>
                                                        <TextField disabled label="Transport Company" name="transportcompany" onChange={this.onTextChange} defaultValue={copyedit.transport_Company}></TextField>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField autoComplete="nope" label="Email" name="email" onChange={this.onTextChange} defaultValue={copyedit.email}></TextField>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <FormLabel component="legend">Active</FormLabel>
                                                            <RadioGroup aria-label="gender" name="activeradio" defaultValue={copyedit.active} onChange={this.onTextChange}>
                                                                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                                                <FormControlLabel value="No" control={<Radio />} label="No" />
                                                            </RadioGroup>
                                                     </Grid>
                                                       <Grid item xs={12}>
                                                            <TextField disabled={user_role !== 'SuperAdmin'} autoComplete='nope'  label="Truck Code Y" name="truck_code_y" onChange={this.onTextChange} defaultValue={copyedit.truck_Code_Y}></TextField>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField disabled={user_role !== 'SuperAdmin'} autoComplete='nope'  label="Truck Code Z" name="truck_code_z" onChange={this.onTextChange} defaultValue={copyedit.truck_Code_Z}></TextField>
                                                        </Grid>
                                                </Grid>
                                            </div>
                                    }
                                })}
                            </div><br></br>
                            <button type='submit' onClick={this.onSaveEdit} id='submit-contact-form-btn'>Save</button>
                            <button onClick={this.onCancelEdit.bind(this)} id='cancel-contact-form-btn'>Cancel</button>
                    </form>
                </div>
            </div>
    );
                            }else{
                                return null;
                            }
    }
}