import React from 'react';
import DataGrid, {
    Column, FilterRow, HeaderFilter,Paging, Button,Pager,SearchPanel,
    FilterPanel,
    FilterBuilderPopup,
    Export,
  } from 'devextreme-react/data-grid';
  import 'devextreme/dist/css/dx.light.css';
  import {FaPencilAlt} from 'react-icons/fa';
  import EditTransportCompanyForm from './editTransportCompanyForm';
import { getSILURLParams } from '../../../../shared/simos/helper/getURLContainsSIL';

interface IProps {
    history:any;
}
const renderTitleHeader = (data:any) => {
    return <p style={{ color:getSILURLParams()?.includes("bru")?"#5383d3":"#a73030",font: 'Cookie', fontSize:'14px'}}>{data.column.caption}</p>
}
const filterBuilderPopupPosition = {
    of: window,
    at: 'top',
    my: 'top',
    offset: { y: 10 },
  };
function StyledComponent(cellData:any):any {
/* <div className={cellData.data.email === 'admin.test@gmail.com' ? "devex-styled-grid-row-green": cellData.data.email ==='dylan.ollikka@sil.ab.ca' ? "devex-styled-grid-row-red":"devex-styled-grid-row-orange"}>{cellData.value}</div> */
    return (
        <div className={"devex-styled-grid-row-default"}>{cellData.value}</div>
    )
}

interface IState {
    all_transports:any[];
    pageSize:number;
    showTransportEditForm:boolean;
    searchValue: string;
}
var globalTransportID:any = 0;
var copyedit:any;
var transportcompanycopy:any;
// IProps: holds the interface with history
export default class TransportCompanyTable extends React.Component<IProps, IState>{
    constructor(props:IProps) {
        super(props);
        this.state = {
            all_transports:[],pageSize:5,showTransportEditForm:false, searchValue: ''
        }
        this.handleTransportEdit = this.handleTransportEdit.bind(this);
    }

    setSearchResult(newSearch:any){
        this.setState({all_transports:newSearch});
    }
    setPageSize(newPageSize:number){
        this.setState({pageSize: newPageSize});
    }
    handleTransportEdit(id:number, data:any){
        globalTransportID = id;
        copyedit = data;
        this.setState({showTransportEditForm:true});
        this.props.history.push('/simos/transport-companies/edit-transport-company/id='+globalTransportID+getSILURLParams());
        return <EditTransportCompanyForm history={this.props.history} editID={globalTransportID}></EditTransportCompanyForm>
    }

    componentDidMount() {
        let allTransports:string[] = [];
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token: '19515195-d571-44a3-b0db-637f5bf24f54' })
        };
        fetch('https://api2.propsense.com/api/GetSIMOSTransportCompany', requestOptions)
        .then(async response => {
            const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
            // error response
            if(!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            // no error
            data.map((each:any) => {
                each.created = each.created.slice(0,10) + ' ' + each.created.slice(11,16);
                each.modified = each.modified.slice(0,10) + ' ' + each.modified.slice(11,16);
                allTransports.push(each);
            });
            this.setState({all_transports: allTransports});
        })
        .catch(error => {
            console.log('There was an error!', error);
        });
    }

    handleValueChange = (e:any) => {
        // console.log(e);
        const trimmedValue = e.trim();
        this.setState({searchValue: trimmedValue});
      };
   
    render() {
        transportcompanycopy = this.state.all_transports;
        return (
            <div className='grid-view'>
            <br></br><br></br>
            <div style={{ width:'100%'}}>
                <DataGrid id={'gridContainer'}
                 focusedRowEnabled={true}
                 hoverStateEnabled={true}
                 rowAlternationEnabled={true}
                    dataSource={this.state.all_transports}
                    keyExpr="id"
                    showBorders={true}
                    showColumnLines={true}
                //    took out heihgt={700}
                > 
                <SearchPanel visible={true}
                width={340}
                placeholder="Search for..." 
                text={this.state.searchValue}
                onTextChange={this.handleValueChange}
                />       
          <Paging enabled={true} defaultPageSize={10}/>
          <Pager
            visible={true}
            allowedPageSizes={[10,20,30]}
            displayMode={'full'}
            showPageSizeSelector={true}
            showInfo={true}
            showNavigationButtons={true} />
            <FilterRow visible={true}></FilterRow>
            <FilterPanel visible={true} />
        <FilterBuilderPopup position={filterBuilderPopupPosition} />
          <HeaderFilter visible={true} allowSearch={true} />
          <Export enabled={true} allowExportSelectedData={true} />
          <Column type="buttons" caption="Edit" headerCellRender={renderTitleHeader}>
              <Button text="edit" visible={true} onClick={(e:any) =>  {this.handleTransportEdit (e.row.data.id, e.row.data)}}><FaPencilAlt/></Button>
            </Column>
           <Column dataField="transport_Company" alignment="left" width={200} cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
          <Column dataField="email"  alignment="left" width={300} cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
          <Column dataField="active"  alignment="left" width={100} cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
          <Column dataField="created" alignment="left"  width={300} cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
          <Column dataField="modified" alignment="left" width={300} cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
          <Column dataField="created_By" alignment="left" width={200} cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
          <Column dataField="modified_By"  alignment="left" width={200} cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
        </DataGrid>
            </div>
        </div>
        );
    }
}
export {transportcompanycopy, copyedit};