import { YHDAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { IGetYHDCarrierEmailsRequest, IGET_YHD_CARRIER_EMAILS_REQUEST, IYHDCarrierEmail } from "./getCarrierEmailsConstaints";

export interface IGetYHDCarrierEmailsLoadAction {
    type: IGET_YHD_CARRIER_EMAILS_REQUEST.REQUEST;
    data: IGetYHDCarrierEmailsRequest
}
export const getYHDCarrierEmailsLoadAction = (data: IGetYHDCarrierEmailsRequest): IGetYHDCarrierEmailsLoadAction => ({
    type: IGET_YHD_CARRIER_EMAILS_REQUEST.REQUEST,
    data
});

export interface IGetYHDCarrierEmailsSuccessAction {
    type: IGET_YHD_CARRIER_EMAILS_REQUEST.SUCCESS;
    data: YHDAPIResponse<IYHDCarrierEmail[]>;
}
export const getYHDCarrierEmailsLoadSuccessAction = (data: YHDAPIResponse<IYHDCarrierEmail[]>): IGetYHDCarrierEmailsSuccessAction => ({
    type: IGET_YHD_CARRIER_EMAILS_REQUEST.SUCCESS,
    data
});

export interface IGetYHDCarrierEmailsLoadFailedAction {
    type: IGET_YHD_CARRIER_EMAILS_REQUEST.FAILED;
    message: string;
}
export const getYHDCarrierEmailsLoadFailedAction = (message: string): IGetYHDCarrierEmailsLoadFailedAction => ({
    type: IGET_YHD_CARRIER_EMAILS_REQUEST.FAILED,
    message
});
