import { IGET_SIMOS_TRANSPORT_COMPANIES_REQUEST, ISimosTransportCompanies } from "./getSimosTransportCompaniesConstants";
import { IGetSimosTransportCompaniesLoadAction, IGetSimosTransportCompaniesLoadFailedAction, IGetSimosTransportCompaniesSuccessAction } from "./getSimosTransportCompaniesActions";
import { SimosAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../../server";
import { IFlushDataSuccessAction } from '../../../../flushData.tsx/flushDataActions';
import { FLUSH_DATA } from '../../../../flushData.tsx/flushDataConstants';
import { IAddSimosTransportCompanySuccessAction } from "../addSimosTransportCompany/addSimosTransportCompanyActions";
import { IUpdateSimosTransportCompanySuccessAction } from "../updateSimosTransportCompany/updateSimosTransportCompanyActions";
import { IADD_SIMOS_TRANSPORT_COMPANY_REQUEST } from "../addSimosTransportCompany/addSimosTransportCompanyConstaints";
import { IUPDATE_SIMOS_TRANSPORT_COMPANY_REQUEST } from "../updateSimosTransportCompany/updateSimosTransportCompanyConstaints";

type Actions =
    | IGetSimosTransportCompaniesLoadAction
    | IGetSimosTransportCompaniesSuccessAction
    | IGetSimosTransportCompaniesLoadFailedAction
    | IAddSimosTransportCompanySuccessAction
    | IUpdateSimosTransportCompanySuccessAction
    | IFlushDataSuccessAction;

export const GetSimosTransportCompaniesReducer = (state: Server<SimosAPIResponse<ISimosTransportCompanies[]>> = notLoaded, action: Actions): Server<SimosAPIResponse<ISimosTransportCompanies[]>> => {
    switch (action.type) {
        case IGET_SIMOS_TRANSPORT_COMPANIES_REQUEST.REQUEST:
            return loading;

        case IGET_SIMOS_TRANSPORT_COMPANIES_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_SIMOS_TRANSPORT_COMPANIES_REQUEST.FAILED:
            return failed(action.message);

        case IADD_SIMOS_TRANSPORT_COMPANY_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_SIMOS_TRANSPORT_COMPANY_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};