import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IUpdateWarehouseLoadAction, IUpdateWarehouseLoadFailedAction, IUpdateWarehouseSuccessAction, updateWarehouseLoadFailedAction,
     updateWarehouseLoadSuccessAction } from "./updateWarehouseActions";
import { IUPDATE_WAREHOUSE_REQUEST } from "./updateWarehouseConstaints";
import { IStore } from "../../../../reducers";
import { EpicDependencies } from "../../../../store";
import { SilAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { END_POINTS } from "../../../../endpoints";
import { withJsonContentType } from "../../../../epicUtils";

export const updateWarehouseEpic: Epic = (
    action$: ActionsObservable<IUpdateWarehouseLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IUpdateWarehouseSuccessAction | IUpdateWarehouseLoadFailedAction> =>
    action$.ofType(IUPDATE_WAREHOUSE_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<string>>(
                    END_POINTS.PROPSENSE.SIL.CV.UpdateCVWarehouse,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<string>): IUpdateWarehouseSuccessAction | IUpdateWarehouseLoadFailedAction => {
                            if(response.message === "Success"){
                                return updateWarehouseLoadSuccessAction(action.data.request);
                            }
                            return updateWarehouseLoadFailedAction(response.message);
                        }),
                        catchError(() => ActionsObservable.of(updateWarehouseLoadFailedAction("Unable to save your changes")))
                    )
            )
        );