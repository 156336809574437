import { PropsenseAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { IJob, IGET_JOBS_REQUEST, IGetRequestBody } from "./getJobsConstants";

export interface IGetJobsLoadAction {
    type: IGET_JOBS_REQUEST.REQUEST;
    data: IGetRequestBody
}
export const getJobsLoadAction = (data: IGetRequestBody): IGetJobsLoadAction => ({
    type: IGET_JOBS_REQUEST.REQUEST,
    data
});
export interface IGetJobsSuccessAction {
    type: IGET_JOBS_REQUEST.SUCCESS;
    list: PropsenseAPIResponse<IJob[]>;
}
export const getJobsLoadSuccessAction = (list: PropsenseAPIResponse<IJob[]>): IGetJobsSuccessAction => ({
    type: IGET_JOBS_REQUEST.SUCCESS,
    list
});
export interface IGetJobsLoadFailedAction {
    type: IGET_JOBS_REQUEST.FAILED;
    message: string;
}
export const getJobsLoadFailedAction = (message: string): IGetJobsLoadFailedAction => ({
    type: IGET_JOBS_REQUEST.FAILED,
    message
});
