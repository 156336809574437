import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IAddSimosCustomerContactLoadAction, 
    IAddSimosCustomerContactLoadFailedAction, 
    IAddSimosCustomerContactSuccessAction, 
    addSimosCustomerContactLoadFailedAction, addSimosCustomerContactLoadSuccessAction
} from "./addSimosCustomerContactActions";
import { IADD_SIMOS_CUSTOMER_CONTACT_REQUEST } from "./addSimosCustomerContactConstaints";
import { EpicDependencies } from "../../../../store";
import { SimosAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { END_POINTS } from "../../../../endpoints";
import { withJsonContentType } from "../../../../epicUtils";
import { IStore } from "../../../../reducers";

export const addSimosCustomerContactEpic: Epic = (
    action$: ActionsObservable<IAddSimosCustomerContactLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IAddSimosCustomerContactSuccessAction | IAddSimosCustomerContactLoadFailedAction> =>
    action$.ofType(IADD_SIMOS_CUSTOMER_CONTACT_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SimosAPIResponse<string>>(
                    END_POINTS.PROPSENSE.SIMOS.ADD_SIMOS_CUSTOMER_CONTACT,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SimosAPIResponse<string>): IAddSimosCustomerContactSuccessAction | IAddSimosCustomerContactLoadFailedAction => {
                            if(response.message === "Success"){
                                return addSimosCustomerContactLoadSuccessAction(response.message);
                            };
                            return addSimosCustomerContactLoadFailedAction(response.message)
                        }),
                        catchError(() => ActionsObservable.of(addSimosCustomerContactLoadFailedAction("Unable to add Simos Customer Contact")))
                    )
            )
        );