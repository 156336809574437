import { IPassTokenRequestBody } from "../../../../../react/shared/publicInterfaces";

export enum IGET_SIMOS_CUSTOMERS_REQUEST {
    REQUEST = "getSimosCustomers/GET_SIMOS_CUSTOMERS_REQUEST",
    SUCCESS = "getSimosCustomers/GET_SIMOS_CUSTOMERS_SUCCESS",
    FAILED = "getSimosCustomers/GET_SIMOS_CUSTOMERS_FAILED"
};

export interface ISimosCustomersRequest extends IPassTokenRequestBody {

};

export interface ISimosCustomers {
    id: number;
    customer: string;
    customer_Display?: string;
    active: string;
    on_Hold: string;
    explorer_Code: string;
    address?: ISimosCustomerAddress[];
    created: string;
    created_By: string;
    modified: string;
    modified_By: string;   
};

export interface ISimosCustomerAddress {
    id: number;
    address: string;
    customer_Id: number;
    created_By: string;
    modified_By: string;
    created: Date;
    modified: Date;
}