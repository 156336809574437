import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, hasPayload, loading, notLoaded, Server, succeeded } from "../../../server";
import { IGET_RUN_MATERIALS_REQUEST, IRunMaterials } from "./getRunMaterialsConstaints";
import { IGetRunMaterialsLoadAction, IGetRunMaterialsLoadFailedAction, IGetRunMaterialsSuccessAction } from "./getRunMaterialsActions";
import { IUPDATE_RUN_MATERIAL_REQUEST } from "../updateRunMaterial/updateRunMaterialConstaints";
import { IADD_RUN_MATERIAL_REQUEST } from "../addRunMaterial/addRunMaterialConstaints";
import { IAddRunMaterialSuccessAction } from "../addRunMaterial/addRunMaterialActions";
import { IUpdateRunMaterialSuccessAction } from "../updateRunMaterial/updateRunMaterialActions";

type Actions =
    IGetRunMaterialsLoadAction
    | IGetRunMaterialsLoadFailedAction
    | IGetRunMaterialsSuccessAction
    | IUpdateRunMaterialSuccessAction
    | IAddRunMaterialSuccessAction
    | IFlushDataSuccessAction;

export const GetRunMaterialsReducer = (state: Server<SilAPIResponse<IRunMaterials[]>> = notLoaded, action: Actions): Server<SilAPIResponse<IRunMaterials[]>> => {
    switch (action.type) {
        case IGET_RUN_MATERIALS_REQUEST.REQUEST:
            return loading;

        case IGET_RUN_MATERIALS_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_RUN_MATERIALS_REQUEST.FAILED:
            return failed(action.message);

        case IUPDATE_RUN_MATERIAL_REQUEST.SUCCESS:
            if (hasPayload(state)) {
                const currentState = [...state.payload.response];
                const ind = currentState.findIndex(x => x.id === action.data.id);

                if (ind >= 0)
                    currentState[ind] = action.data;

                return {
                    ...state,
                    payload: {
                        ...state.payload,
                        response: currentState
                    }
                };
            }
            return notLoaded;

        case IADD_RUN_MATERIAL_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};