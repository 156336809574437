import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IGetWashPlantProductionRecordsLoadAction, IGetWashPlantProductionRecordsLoadFailedAction, IGetWashPlantProductionRecordsSuccessAction, 
    getWashPlantProductionRecordsLoadFailedAction, getWashPlantProductionRecordsLoadSuccessAction
} from "./getWashPlantProductionRecordsActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IGET_WASH_PLANT_PRODUCTION_RECORDS_REQUEST, IWashPlantProductionRecords } from "./getWashPlantProductionRecordsConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { ById, SilAPIResponse } from "../../../../react/shared/publicInterfaces";

export const getWashPlantProductionRecordsEpic: Epic = (
    action$: ActionsObservable<IGetWashPlantProductionRecordsLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IGetWashPlantProductionRecordsSuccessAction | IGetWashPlantProductionRecordsLoadFailedAction> =>
    action$.ofType(IGET_WASH_PLANT_PRODUCTION_RECORDS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<ById<IWashPlantProductionRecords>>>(
                    END_POINTS.PROPSENSE.SIL.WASH_PLANT.GET_WASH_PLANT_PRODUCTION_RECORDS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<ById<IWashPlantProductionRecords>>): IGetWashPlantProductionRecordsSuccessAction => {
                            return getWashPlantProductionRecordsLoadSuccessAction(response);
                        }),
                        catchError(() => ActionsObservable.of(getWashPlantProductionRecordsLoadFailedAction("Unable to getWashPlant production records")))
                    )
            )
        );