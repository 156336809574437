import { SilAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { IGetSieveFracBallRequest, IGET_SIEVE_FRAC_BALLS_REQUEST, ISieveFracBall } from "./getSieveFracBallsConstaints";

export interface IGetSieveFracBallLoadAction {
    type: IGET_SIEVE_FRAC_BALLS_REQUEST.REQUEST;
    data: IGetSieveFracBallRequest
}
export const getSieveFracBallLoadAction = (data: IGetSieveFracBallRequest): IGetSieveFracBallLoadAction => ({
    type: IGET_SIEVE_FRAC_BALLS_REQUEST.REQUEST,
    data
});

export interface IGetSieveFracBallSuccessAction {
    type: IGET_SIEVE_FRAC_BALLS_REQUEST.SUCCESS;
    data: SilAPIResponse<ISieveFracBall[]>;
}
export const getSieveFracBallLoadSuccessAction = (data: SilAPIResponse<ISieveFracBall[]>): IGetSieveFracBallSuccessAction => ({
    type: IGET_SIEVE_FRAC_BALLS_REQUEST.SUCCESS,
    data
});

export interface IGetSieveFracBallLoadFailedAction {
    type: IGET_SIEVE_FRAC_BALLS_REQUEST.FAILED;
    message: string;
}
export const getSieveFracBallLoadFailedAction = (message: string): IGetSieveFracBallLoadFailedAction => ({
    type: IGET_SIEVE_FRAC_BALLS_REQUEST.FAILED,
    message
});
