import { PropsenseAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../../server";
import { ISaveAssignedCrewsSuccessAction } from "../saveAssignedCrews/saveAssignedCrewsActions";
import { ISAVE_ASSIGNED_CREWS_REQUEST } from "../saveAssignedCrews/saveAssignedCrewsConstaints";
import { IGetAssignedCrewsLoadAction, IGetAssignedCrewsLoadFailedAction, IGetAssignedCrewsSuccessAction } from "./getAssignedCrewsActions";
import { IAssignedCrew, IGET_ASSIGNED_CREWS_REQUEST } from "./getAssignedCrewsConstants";

type Actions =
    | IGetAssignedCrewsLoadAction
    | IGetAssignedCrewsSuccessAction
    | IGetAssignedCrewsLoadFailedAction
    | ISaveAssignedCrewsSuccessAction
    | IFlushDataSuccessAction;

export const GetAssignedCrewsReducer = (state: Server<PropsenseAPIResponse<IAssignedCrew[]>> = notLoaded, action: Actions): Server<PropsenseAPIResponse<IAssignedCrew[]>> => {
    switch (action.type) {
        case IGET_ASSIGNED_CREWS_REQUEST.REQUEST:
            return loading;

        case IGET_ASSIGNED_CREWS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_ASSIGNED_CREWS_REQUEST.FAILED:
            return failed(action.message);

        case ISAVE_ASSIGNED_CREWS_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};