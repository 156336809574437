export enum ISAVE_ASSIGNED_CREWS_REQUEST {
    REQUEST = "saveAssignedCrews/SAVE_ASSIGNED_CREWS_REQUEST",
    SUCCESS = "saveAssignedCrews/SAVE_ASSIGNED_CREWS_SUCCESS",
    FAILED = "saveAssignedCrews/SAVE_ASSIGNED_CREWS_FAILED"
};

export interface ISaveAssignedCrewsRequest {
    token: string;
    request: ISaveAssignedCrews[]
};

export interface ISaveAssignedCrews {
    Start_Date: string;
    End_Date: string;
    Shift: string;
    Order_ID: number;
    Crew_ID: number;
    Crew_Shift_ID: number;
    Created: string;
    Created_By: string;
    Modified: string;
    Modified_By: string
};