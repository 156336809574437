import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IGetOrderByIdLoadAction, IGetOrderByIdLoadFailedAction, IGetOrderByIdSuccessAction, 
    getOrderByIdLoadFailedAction, getOrderByIdLoadSuccessAction
} from "./getOrderByIdActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IGET_ORDER_BY_ID_REQUEST, ISIMOSOrderById } from "./getOrderByIdConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SimosAPIResponse } from "../../../../react/shared/publicInterfaces";

export const getOrderByIdEpic: Epic = (
    action$: ActionsObservable<IGetOrderByIdLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IGetOrderByIdSuccessAction | IGetOrderByIdLoadFailedAction> =>
    action$.ofType(IGET_ORDER_BY_ID_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SimosAPIResponse<ISIMOSOrderById>>(
                    END_POINTS.PROPSENSE.SIMOS.GET_ORDER_BY_ID,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SimosAPIResponse<ISIMOSOrderById>): IGetOrderByIdSuccessAction | IGetOrderByIdLoadFailedAction => {
                            if(response.message !== "Success")
                                return getOrderByIdLoadFailedAction(response.message);
                            return getOrderByIdLoadSuccessAction(response);
                        }),
                        catchError(() => ActionsObservable.of(getOrderByIdLoadFailedAction("Unable to get orders")))
                    )
            )
        );