import { ById, SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, hasPayload, loading, notLoaded, Server, succeeded } from "../../../server";
import { IAddWashPlantProductionSuccessAction } from "../addWashPlantProduction/addWashPlantProductionActions";
import { IADD_WASH_PLANT_PRODUCTION_REQUEST } from "../addWashPlantProduction/addWashPlantProductionConstaints";
import { IUpdateWashPlantProductionSuccessAction } from "../updateWashPlantProduction/updateWashPlantProductionActions";
import { IUPDATE_WASH_PLANT_PRODUCTION_REQUEST } from "../updateWashPlantProduction/updateWashPlantProductionConstaints";
import { IGetWashPlantProductionRecordsLoadAction, IGetWashPlantProductionRecordsLoadFailedAction, IGetWashPlantProductionRecordsSuccessAction } from "./getWashPlantProductionRecordsActions";
import { IGET_WASH_PLANT_PRODUCTION_RECORDS_REQUEST, IWashPlantProductionRecords } from "./getWashPlantProductionRecordsConstaints";

type Actions =
    IGetWashPlantProductionRecordsLoadAction
    | IGetWashPlantProductionRecordsLoadFailedAction
    | IGetWashPlantProductionRecordsSuccessAction
    | IUpdateWashPlantProductionSuccessAction
    | IAddWashPlantProductionSuccessAction
    | IFlushDataSuccessAction;

export const GetWashPlantProductionRecordsReducer = (state: Server<SilAPIResponse<ById<IWashPlantProductionRecords>>> = notLoaded, action: Actions): Server<SilAPIResponse<ById<IWashPlantProductionRecords>>> => {
    switch (action.type) {
        case IGET_WASH_PLANT_PRODUCTION_RECORDS_REQUEST.REQUEST:
            return loading;

        case IGET_WASH_PLANT_PRODUCTION_RECORDS_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_WASH_PLANT_PRODUCTION_RECORDS_REQUEST.FAILED:
            return failed(action.message);

        case IADD_WASH_PLANT_PRODUCTION_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_WASH_PLANT_PRODUCTION_REQUEST.SUCCESS:
            if (hasPayload(state) && (action.data.id > 0)) {
                console.log(action.data)
                const currentState = { ...state.payload.response };
                currentState[action.data.id] = action.data;

                return {
                    ...state,
                    payload: {
                        ...state.payload,
                        response: currentState
                    }
                };
            }
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};