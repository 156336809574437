import { ICarrierForm } from "../../../../react/components/sil/carriers/carrierForm";

export enum IUPDATE_CARRIER_FORM_REQUEST {
    REQUEST = "updateCarrierForm/UPDATE_CARRIER_FORM_REQUEST",
    SUCCESS = "updateCarrierForm/UPDATE_CARRIER_FORM_SUCCESS",
    FAILED = "updateCarrierForm/UPDATE_CARRIER_FORM_FAILED"
};

export interface IUpdateCarrierFormRequest {
    token: string;
    request: ICarrierForm;
};