import { IRunMaterials } from "../getRunMaterials/getRunMaterialsConstaints";
import { IUpdateRunMaterialRequest, IUPDATE_RUN_MATERIAL_REQUEST } from "./updateRunMaterialConstaints";

export interface IUpdateRunMaterialLoadAction {
    type: IUPDATE_RUN_MATERIAL_REQUEST.REQUEST;
    data: IUpdateRunMaterialRequest
}
export const updateRunMaterialLoadAction = (data: IUpdateRunMaterialRequest): IUpdateRunMaterialLoadAction => ({
    type: IUPDATE_RUN_MATERIAL_REQUEST.REQUEST,
    data
});

export interface IUpdateRunMaterialSuccessAction {
    type: IUPDATE_RUN_MATERIAL_REQUEST.SUCCESS;
    data: IRunMaterials;
}
export const updateRunMaterialLoadSuccessAction = (data: IRunMaterials): IUpdateRunMaterialSuccessAction => ({
    type: IUPDATE_RUN_MATERIAL_REQUEST.SUCCESS,
    data
});
export interface IUpdateRunMaterialLoadFailedAction {
    type: IUPDATE_RUN_MATERIAL_REQUEST.FAILED;
    message: string;
}
export const updateRunMaterialLoadFailedAction = (message: string): IUpdateRunMaterialLoadFailedAction => ({
    type: IUPDATE_RUN_MATERIAL_REQUEST.FAILED,
    message
});
