import React from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { ICrew } from "../../../../../../redux/propsense/catapult/crew_sheduling/getCrews/getCrewsConstants";
import { DropPaper, dragItemStyle } from "../../../../../shared/dragAndDropPaper";
import LAGrid from "../../../../../shared/grid";
import LAGridItem from "../../../../../shared/gridList";
import { ByIdNumber, HasClass } from "../../../../../shared/publicInterfaces";
import { BLUE_COLOR, ORANGE_COLOR, PURPLE_COLOR, RED_COLOR } from "../../../../../shared/theme";
import { IJob } from "../../../../../../redux/propsense/catapult/crew_sheduling/getJobs/getJobsConstants";
import { LACheckBox } from "../../../../../shared/checkBox";
import { IAssignedCrew } from "../../../../../../redux/propsense/catapult/crew_sheduling/getAssignedCrews/getAssignedCrewsConstants";
import { getDate, ZEROTH } from "../../../../../shared/constExports";
import { LAGreyPaper, LAPaperWithPadding } from "../../../../../shared/paper";
import styled from "styled-components";
import { getJobTitle } from "./shiftList";


interface ICrewListDDProps extends HasClass {
    jobs: IJob[];
    crewList: ICrew[];
    dropableId: string;
    disable: boolean;
    label: string;
    assignedCrewList: IAssignedCrew[];
    checkBoxVal: ByIdNumber<boolean>;
    checkBoxClick: (indexNum: number, value: boolean) => void;
};

const CrewListDDStyles = styled.div`
    .job-list-popup {
        display: none;
    };

    .crew-item:hover .job-list-popup  {
        display: block;
        position: fixed;
        z-index: 20;
        left: 20%;
        top: 30%;
        width: 30%;
        height: auto;
        overflow: auto;
    }
`;

const CrewListDD: React.FC<ICrewListDDProps> = React.memo((props: ICrewListDDProps) =>
    <CrewListDDStyles>
        <Droppable droppableId={props.dropableId}>

            {(provided, snapshot) => (
                <DropPaper className="text-center"
                    innerRef={provided.innerRef}
                    styleDraggar={snapshot.isDraggingOver}>

                    {!props.disable && <div className="p-3">
                        <LACheckBox label="Super"
                            className="red-dot p-2"
                            value={props.checkBoxVal[0]}
                            onChange={(v) => props.checkBoxClick(0, v)}
                        />

                        <LACheckBox label="OP1"
                            className="blue-dot p-2"
                            value={props.checkBoxVal[1]}
                            onChange={(v) => props.checkBoxClick(1, v)}
                        />

                        <LACheckBox label="OP2"
                            className="orange-dot p-2"
                            value={props.checkBoxVal[2]}
                            onChange={(v) => props.checkBoxClick(2, v)}
                        />

                        <LACheckBox label="OP3"
                            className="purple-dot p-2"
                            value={props.checkBoxVal[3]}
                            onChange={(v) => props.checkBoxClick(3, v)}
                        />
                    </div>}

                    <strong>{props.label}</strong>
                    <LAGrid>
                        {props.crewList
                            .map((item, index) => (
                                <LAGridItem xs={4}>
                                    <Draggable
                                        key={item.crew_ID}
                                        isDragDisabled={props.disable}
                                        draggableId={props.dropableId + "," + item.crew_ID.toString()}
                                        index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                className="crew-item"
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={dragItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style,
                                                    getJobColor(item.job_Level)
                                                )}>

                                                <LAGrid spacing={0}>
                                                    <LAGridItem sm={12} md={12}>
                                                        <div className="p-1">
                                                            <strong>{getJobTitle(item)}</strong>
                                                            <br />
                                                            <span>{item.name}</span>
                                                        </div>
                                                    </LAGridItem>

                                                </LAGrid>

                                                <div className="job-list-popup">
                                                    <LAGreyPaper>
                                                        
                                                    <div className="text-center">
                                                        <strong>Assigned Job List</strong>
                                                        <hr />
                                                        <span>{GetAssignedJobs(item.crew_ID, props.jobs, props.assignedCrewList)}</span>
                                                    </div>

                                                    </LAGreyPaper>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                </LAGridItem>
                            ))}
                        {provided.placeholder}

                    </LAGrid>
                </DropPaper>
            )}

        </Droppable>
    </CrewListDDStyles>
);

export default CrewListDD;



export const getJobColor = (value: string): string => {
    switch (value) {
        case "Supervisor":
            return RED_COLOR;

        case "Operator_Level1":
            return BLUE_COLOR;

        case "Operator_Level2":
            return ORANGE_COLOR;

        case "Operator_Level3":
            return PURPLE_COLOR;

        default:
            return "";
    }
};

const GetAssignedJobs = (crewId: number, jobs: IJob[], assignedCrew: IAssignedCrew[]): JSX.Element => {
    const results: { id: number, name: string, from: string, to: string }[] = [];
    const assigned = assignedCrew.filter((x) => x.crew_ID === crewId);

    assigned.forEach((x) => {
        const findJob = jobs.find((a) => a.order_ID === x.order_ID);
        if (findJob) {
            if (!results.some((a) => (a.id === x.order_ID)))
                results.push({ id: x.order_ID, name: findJob.po + " - " + findJob.location, from: x.start_Date, to: x.end_Date });
        };
    });


    return <>
        {results.length > ZEROTH ? results.map((x, index) => {
            return (
                <div className="p-1">
                    <LAPaperWithPadding>
                        <strong>{index + 1}. {x.name}</strong>
                        <br />
                        <span>{getDate(false, x.from)} - {getDate(false, x.to)}</span>
                    </LAPaperWithPadding>
                </div>
            )
        }) : <div className="p-3">No job assigned yet</div>}
    </>;
};


// interface IMapWithMarkProps {
//     jobs: IJob[];
//     userCord: { lat: number, lng: number };
// };

// const MapWithAMarker = withScriptjs(withGoogleMap((props: IMapWithMarkProps) =>
//     <GoogleMap
//         defaultZoom={4 / props.jobs.filter((x) => x.Country === "Canada").length + 1}
//         defaultCenter={getCenter(props)}
//     >
//         <Marker
//             label="User"
//             position={props.userCord}
//         />

//         {props.jobs.filter((x) => x.Country === "Canada").map((x, index) => (
//             <Marker
//                 key={index}
//                 label={x.Customer}
//                 position={{ lat: Number(x.Latitude), lng: Number(x.Longitude) }}
//             />
//         ))}

//     </GoogleMap>
// ));


// const getCenter = (props: IMapWithMarkProps): any => {

//     const response: any = { lat: 0, lng: 0 };

//     const list = props.jobs.filter((x) => x.Country === "Canada");
//     list.forEach((x, index) => {

//         response.lat = response.lat + Number(x.Latitude);
//         response.lng = response.lng + Number(x.Longitude);

//         if (index + 1 === list.length) {
//             response.lat = (response.lat + Number(props.userCord.lat)) / (list.length + 1);
//             response.lng = (response.lng + Number(props.userCord.lng)) / (list.length + 1);
//         }
//     });

//     return response;
// };