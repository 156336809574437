import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, Server } from "../../../../redux/server";
import { SilAPIResponse } from "../../../shared/publicInterfaces";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import { callRouteWithQueryString, userName } from "../../../shared/constExports";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { LAButton } from "../../../shared/buttons";
import { AddIcon } from "../../../shared/icons";
import { WHITE_COLOR } from "../../../shared/theme";
import { ROUTE } from "../../../routes";
import PageSpacing from "../../../shared/pageSpacing";
import { getCarrierInfractionsStatus } from "../../../../redux/sil/carrier/getCarrierInfractions/getCarrierInfractionsAccessor";
import { getCarrierInfractionsLoadAction } from "../../../../redux/sil/carrier/getCarrierInfractions/getCarrierInfractionsActions";
import { ICarrierInfraction, IGetCarrierInfractionsRequest } from "../../../../redux/sil/carrier/getCarrierInfractions/getCarrierInfractionsConstaints";
import { ICarrierLookup, IGetCarrierLookupsRequest } from "../../../../redux/sil/carrier/getCarrierLookups/getCarrierLookupsConstaints";
import { getCarrierLookupsStatus } from "../../../../redux/sil/carrier/getCarrierLookups/getCarrierLookupsAccessor";
import { getCarrierLookupsLoadAction } from "../../../../redux/sil/carrier/getCarrierLookups/getCarrierLookupsActions";
import SilRoleCheck from "../../../shared/silRoleCheck";


interface ICarrierInfractionsStoreProps {
    getToken: Server<SilAPIResponse<IToken>>;
    getCarrierInfractions: Server<SilAPIResponse<ICarrierInfraction[]>>;
    getCarrierLookups: Server<SilAPIResponse<ICarrierLookup[]>>;
};

interface ICarrierInfractionsDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getCarrierInfractionsRequest: (data: IGetCarrierInfractionsRequest) => unknown;
    getCarrierLookupsRequest: (data: IGetCarrierLookupsRequest) => unknown;
};


interface ICarrierInfractionsOwnProps {

};

interface ICarrierInfractionsState {
    data: ICarrierInfraction[];
};

const CarrierInfractionsStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
    };
`;

type ICarrierInfractionsProps = RouteComponentProps
    & ICarrierInfractionsStoreProps
    & ICarrierInfractionsDispatchProps
    & ICarrierInfractionsOwnProps;

class CarrierInfractions extends PureComponent<ICarrierInfractionsProps, ICarrierInfractionsState> {

    public constructor(props: ICarrierInfractionsProps) {
        super(props);
        this.state = {
            data: []
        };
    }

    public componentDidMount(): void {
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: ICarrierInfractionsProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();
        };
    };


    public render(): ReactNode {

        const { data } = this.state;

        return (
            <PageSpacing title="SIL - CarrierInfractions" description="SIL - Carrier Infractions" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="carrierAccess">
                    <CarrierInfractionsStyles>

                        <LAButton className="pull-left" startIcon={<AddIcon color={WHITE_COLOR} />} label="Add" onClick={this.handleAdd} />
                        <h2 className="text-center">CARRIER INFRACTIONS</h2>
                        <hr />

                        <LADevExtremeGrid
                            data={data}
                            export={true}
                            buttonLabel="View"
                            searchPanel={true}
                            actionWidth={100}
                            filterHeader={true}
                            onEdit={this.onEdit}
                            onClick={this.onClick}
                            storageKey="silCarrierInfractionsStorageKey"
                            exportFileName={"Carrier Infractions"}
                            columns={[
                                { name: "date_Of_Infraction", caption: "Date", type: "datetime", sortDesc: true },
                                { name: "infraction_Notification_Method", caption: "Infraction Notification Method", type: "string" },
                                { name: "carrier", caption: "Carrier", type: "string" },
                                { name: "load_No", caption: "Load No", type: "string" },
                                { name: "trailer_No", caption: "Trailer No", type: "string" },
                                { name: "license_Plate", caption: "License Plate", type: "string" },
                                { name: "location", caption: "Location", type: "string" },
                                { name: "ticket_No", caption: "Ticket No", type: "string" },
                                { name: "supervisor", caption: "Supervisor", type: "string" },
                                { name: "modified_By", caption: "Modified By", type: "string" },
                                { name: "modified", caption: "Modified", type: "datetime" },
                            ]}
                        />
                    </CarrierInfractionsStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString() },
            pathName: ROUTE.SIL.CARRIERS.CARRIER_INFRACTION
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0" },
            pathName: ROUTE.SIL.CARRIERS.CARRIER_INFRACTION
        });
    };

    private setDataToState = (): void => {

        if (hasPayload(this.props.getCarrierInfractions)) {
            const val: ICarrierInfraction[] = Object.values(this.props.getCarrierInfractions.payload.response);
            this.setState({ data: val });
        };

        if (isNotLoaded(this.props.getCarrierInfractions))
            this.callServer();
    };

    private callServer = (): void => {
        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getCarrierInfractions))
            this.props.getCarrierInfractionsRequest({
                token: this.props.getToken.payload.response.token
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getCarrierLookups))
            this.props.getCarrierLookupsRequest({
                token: this.props.getToken.payload.response.token,
                requested_Page: "Carriers_Infractions"
            });


        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });
    }

}

const mapStateToProps = (state: IStore): ICarrierInfractionsStoreProps => ({
    getToken: getTokenStatus(state),
    getCarrierLookups: getCarrierLookupsStatus(state),
    getCarrierInfractions: getCarrierInfractionsStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): ICarrierInfractionsDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getCarrierLookupsRequest: (data: IGetCarrierLookupsRequest) => dispatch(getCarrierLookupsLoadAction(data)),
    getCarrierInfractionsRequest: (data: IGetCarrierInfractionsRequest) => dispatch(getCarrierInfractionsLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(CarrierInfractions);