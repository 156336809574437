import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, isSucceeded, Server } from "../../../../redux/server";
import { ById, SilAPIResponse } from "../../../shared/publicInterfaces";
import { userName } from "../../../shared/constExports";
import { LAButton, LASaveAndCancelButton } from "../../../shared/buttons";
import { ArrowLeftIcon } from "../../../shared/icons";
import { WARNING_COLOR, WHITE_COLOR } from "../../../shared/theme";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import LATextField from "../../../shared/textField";
import { ROUTE } from "../../../routes";
import queryString from "query-string";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import { FieldValidator, FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../../shared/fieldValidator";
import PageSpacing from "../../../shared/pageSpacing";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import SilRoleCheck from "../../../shared/silRoleCheck";
import LATextArea from "../../../shared/textArea";
import React from "react";
import splitDate from "../../../shared/splitDate";
import { IGetQualityControlTestingsRequest, IQualityControlTestings } from "../../../../redux/sil/qualityControlTestings/getQualityControlTestings/getQualityControlTestingsConstaints";
import { getQualityControlTestingsStatus } from "../../../../redux/sil/qualityControlTestings/getQualityControlTestings/getQualityControlTestingsAccessor";
import { getQualityControlTestingsLoadAction } from "../../../../redux/sil/qualityControlTestings/getQualityControlTestings/getQualityControlTestingsActions";
import { IAddQualityControlTesting, IAddQualityControlTestingRequest } from "../../../../redux/sil/qualityControlTestings/addQualityControlTesting/addQualityControlTestingConstaints";
import { addQualityControlTestingStatus } from "../../../../redux/sil/qualityControlTestings/addQualityControlTesting/addQualityControlTestingAccessor";
import { addQualityControlTestingLoadAction } from "../../../../redux/sil/qualityControlTestings/addQualityControlTesting/addQualityControlTestingActions";
import { IUpdateQualityControlTestingRequest } from "../../../../redux/sil/qualityControlTestings/updateQualityControlTesting/updateQualityControlTestingConstaints";
import { updateQualityControlTestingStatus } from "../../../../redux/sil/qualityControlTestings/updateQualityControlTesting/updateQualityControlTestingAccessor";
import { updateQualityControlTestingLoadAction } from "../../../../redux/sil/qualityControlTestings/updateQualityControlTesting/updateQualityControlTestingActions";

const requiredFields = ["test_For", "product", "date"];

interface IQualityControlTestingStoreProps {
    addQualityControlTesting: Server<string>;
    updateQualityControlTesting: Server<string>;
    getToken: Server<SilAPIResponse<IToken>>;
    getQualityControlTestings: Server<SilAPIResponse<IQualityControlTestings[]>>;
};

interface IQualityControlTestingDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getQualityControlTestingsRequest: (data: IGetQualityControlTestingsRequest) => unknown;
    addQualityControlTestingRequest: (data: IAddQualityControlTestingRequest) => unknown;
    updateQualityControlTestingRequest: (data: IUpdateQualityControlTestingRequest) => unknown;
};


interface IQualityControlTestingOwnProps {

};

interface IQualityControlTestingState {
    data: IQualityControlTestings;
    inputRef: any;
    errors: ById<IFieldErrorKeyValue>;
};

const QualityControlTestingStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;

    .dx-toolbar-after {
        display: none;
    };

    .required {
        color: ${WARNING_COLOR};
    }

    .variancepopup{
        height: 50% !important;
    }
`;

type IQualityControlTestingProps = RouteComponentProps
    & IQualityControlTestingStoreProps
    & IQualityControlTestingDispatchProps
    & IQualityControlTestingOwnProps;

class QualityControlTesting extends PureComponent<IQualityControlTestingProps, IQualityControlTestingState> {

    public constructor(props: IQualityControlTestingProps) {
        super(props);
        this.state = {
            errors: {
                "test_For": { key: "test_For", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "product": { key: "product", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "date": { key: "date", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
            },
            inputRef: React.createRef(),
            data: {
                id: 0,
                test_For: "",
                date: "",
                product: "",
                turbidity_Test_Result: "",
                remarks: "",
                created: "",
                created_By: userName,
                modified: "",
                modified_By: userName
            }
        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: IQualityControlTestingProps): void {
        if (this.props !== prevProps) {
            this.callServer();

            if (this.props.addQualityControlTesting !== prevProps.addQualityControlTesting) {
                if (isSucceeded(this.props.addQualityControlTesting)) {
                    this.handleCancel();
                };
            };

            if (this.props.updateQualityControlTesting !== prevProps.updateQualityControlTesting) {
                if (isSucceeded(this.props.updateQualityControlTesting)) {
                    this.handleCancel();
                };
            };
        };
    };


    public render(): ReactNode {

        const { data, errors } = this.state;
        const { addQualityControlTesting, updateQualityControlTesting } = this.props;

        return (
            <PageSpacing title="SIL - Quality Control Testing" description="Turbidity QAQC Tests" fixedSpaceOnSmallerScreens={true}>
                
                    <QualityControlTestingStyles>

                        <LAButton startIcon={<ArrowLeftIcon color={WHITE_COLOR} />} label="Back to list" onClick={this.handleCancel} />
                        <h2 className="text-center">{data.id > 0 ? "View/Update " : "ADD "} Turbidity test result</h2>
                        <hr />

                        <LAPaperWithPadding>
                            <LAGrid spacing={1}>
                                <LAGridItem xs={12} sm={6} md={4}>                                       
                                    <LATextField
                                        varient="outlined"
                                        label="Who the test is for (Customer or Internal)"
                                        fullWidth={true}
                                        name="test_For"
                                        onChange={this.onChange}
                                        value={data.test_For}
                                        errorText={errors && errors["test_For"] ? errors["test_For"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={4}>                        
                                    <LATextField
                                        varient="outlined"
                                        label="Product"
                                        fullWidth={true}
                                        name="product"
                                        onChange={this.onChange}
                                        value={data.product}
                                        errorText={errors && errors["product"] ? errors["product"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={4}>
                                    <LATextField
                                        id="date"
                                        value={splitDate(data.date)}
                                        fullWidth={true}
                                        varient="outlined"
                                        name="date"
                                        label="Date"
                                        inputRef={this.state.inputRef}
                                        errorText={errors["date"] ? errors["date"].message : undefined}
                                        onChange={this.onChange} 
                                        type="date"
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={4}>                            
                                    <LATextField
                                        varient="outlined"
                                        label="Turbidity test result"
                                        fullWidth={true}
                                        name="turbidity_Test_Result"
                                        onChange={this.onChange}
                                        value={data.turbidity_Test_Result}
                                        />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6}>                                
                                    <LATextArea
                                        minRows={4}
                                        rowsMax={10}
                                        label="Remarks"
                                        fullWidth={true}
                                        name="remarks"
                                        onChange={this.onChange}
                                        value={data.remarks}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12}>
                                    <LASaveAndCancelButton
                                        onSave={this.onSave}
                                        onCancel={this.handleCancel}
                                        disableSave={(Object.values(errors).length > 0 ? true : undefined)}
                                    />
                                </LAGridItem>

                            </LAGrid>
                        </LAPaperWithPadding>

                        <RequestStatus requestStatus={addQualityControlTesting.kind} successMessage="QC Testing successfully saved" />
                        <RequestStatus requestStatus={updateQualityControlTesting.kind} successMessage="QC Testing successfully updated" />

                    </QualityControlTestingStyles>
                
            </PageSpacing>
        );
    }

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, isRequired: boolean): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: isRequired ? true : undefined, minLength: 1, decimal: undefined });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        };
        return err;
    };

    private handleCancel = (): void => {
        this.props.history.push(ROUTE.SIL.QUALITY_CONTROL_TESTINGS.INDEX);
    };

    private onSave = async (): Promise<void> => {

        if (hasPayload(this.props.getToken)) {
            const { data } = this.state;

            let request: IAddQualityControlTesting = {
                Date: data.date,
                Test_For: data.test_For,
                Product: data.product,
                Turbidity_Test_Result : data.turbidity_Test_Result,
                Remarks: data.remarks,
                // Created: data.created,
                // Modified: data.modified,
                Created_By: data.created_By,
                Modified_By: userName
            };

            if (data.id === 0) {
                this.props.addQualityControlTestingRequest({
                    token: this.props.getToken.payload.response.token,
                    request
                });
            } 
            else {
                request.Id = data.id;

                this.props.updateQualityControlTestingRequest({
                    token: this.props.getToken.payload.response.token,
                    request
                });
            };
        }
    };

    private onChange = (name: string, value: string): void => {
        let errors = this.state.errors;

        if (requiredFields.includes(name))
            errors = this.errorChecker(name, value, errors, true);

            this.setState({
                ...this.state,
                errors,
                data: {
                    ...this.state.data,
                    [name]: value
                }
            });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getQualityControlTestings))
            this.props.getQualityControlTestingsRequest({
                token: this.props.getToken.payload.response.token
            });


        const query = queryString.parse(this.props.location.search);
        const id = query.id ?? "0";

        if ((id !== "0") && (hasPayload(this.props.getQualityControlTestings)) && (this.state.data.id === 0)) {
            const data = this.props.getQualityControlTestings.payload.response.find((x) => x.id === +id);

            if (data){
                this.setState({ data, errors: {} });
            }
        };

    };

}

const mapStateToProps = (state: IStore): IQualityControlTestingStoreProps => ({
    getToken: getTokenStatus(state),
    getQualityControlTestings: getQualityControlTestingsStatus(state),
    updateQualityControlTesting: updateQualityControlTestingStatus(state),
    addQualityControlTesting: addQualityControlTestingStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IQualityControlTestingDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getQualityControlTestingsRequest: (data: IGetQualityControlTestingsRequest) => dispatch(getQualityControlTestingsLoadAction(data)),
    addQualityControlTestingRequest: (data: IAddQualityControlTestingRequest) => dispatch(addQualityControlTestingLoadAction(data)),
    updateQualityControlTestingRequest: (data: IUpdateQualityControlTestingRequest) => dispatch(updateQualityControlTestingLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QualityControlTesting);