import { getGeoBagsStatus } from '../../../../redux/sil/dispatch/getGeoBags/getGeoBagsAccessor';
import { addGeoBagStatus } from '../../../../redux/sil/dispatch/addGeoBag/addGeoBagAccessor';
import { updateGeoBagStatus } from '../../../../redux/sil/dispatch/updateGeoBag/updateGeoBagAccessor';
import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, isSucceeded, Server } from "../../../../redux/server";
import { ById, SilAPIResponse } from "../../../shared/publicInterfaces";
import { userName } from "../../../shared/constExports";
import { ROUTE } from "../../../routes";
import queryString from "query-string";
import { FieldValidator, FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../../shared/fieldValidator";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import { IGeoBag, IGetGeoBagsRequest } from '../../../../redux/sil/dispatch/getGeoBags/getGeoBagsConstaints';
import { IAddGeoBagRequest } from '../../../../redux/sil/dispatch/addGeoBag/addGeoBagConstaints';
import { IUpdateGeoBagRequest } from '../../../../redux/sil/dispatch/updateGeoBag/updateGeoBagConstaints';
import { getGeoBagsLoadAction } from '../../../../redux/sil/dispatch/getGeoBags/getGeoBagsActions';
import { addGeoBagLoadAction } from '../../../../redux/sil/dispatch/addGeoBag/addGeoBagActions';
import { updateGeoBagLoadAction } from '../../../../redux/sil/dispatch/updateGeoBag/updateGeoBagActions';
import PageSpacing from '../../../shared/pageSpacing';
import SilRoleCheck from '../../../shared/silRoleCheck';
import { LAButton, LASaveAndCancelButton } from '../../../shared/buttons';
import LAGrid from '../../../shared/grid';
import LAGridItem from '../../../shared/gridList';
import { ArrowLeftIcon } from '../../../shared/icons';
import RequestStatus from '../../../shared/requestStatusSnackbar';
import LATextField from '../../../shared/textField';
import { WHITE_COLOR } from '../../../shared/theme';
import { LADateTimeMUIPicker } from '../../../shared/dateTimePickerMUI';
import LATextArea from '../../../shared/textArea';


interface IGeoBagStoreProps {
    addGeoBag: Server<string>;
    updateGeoBag: Server<string>;
    getToken: Server<SilAPIResponse<IToken>>;
    getGeoBags: Server<SilAPIResponse<IGeoBag[]>>;
};

interface IGeoBagDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getGeoBagsRequest: (data: IGetGeoBagsRequest) => unknown;
    addGeoBagRequest: (data: IAddGeoBagRequest) => unknown;
    updateGeoBagRequest: (data: IUpdateGeoBagRequest) => unknown;
};


interface IGeoBagOwnProps {

};

interface IGeoBagState {
    geoBag: IGeoBag;
    errors: ById<IFieldErrorKeyValue>;
};

const GeoBagStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
`;

type IGeoBagProps = RouteComponentProps
    & IGeoBagStoreProps
    & IGeoBagDispatchProps
    & IGeoBagOwnProps;

class GeoBag extends PureComponent<IGeoBagProps, IGeoBagState> {

    public constructor(props: IGeoBagProps) {
        super(props);
        this.state = {
            errors: {
                "operator": { key: "operator", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "date": { key: "date", message: FIELD_VALIDATOR_ERRORS.REQUIRED }
            },
            geoBag: {
                id: 0,
                operator: "",
                date: "",
                notes: "",
                created: "",
                created_By: userName,
                modified: "",
                modified_By: userName
            }
        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: IGeoBagProps): void {
        if (this.props !== prevProps) {
            this.callServer();

            if (this.props.addGeoBag !== prevProps.addGeoBag) {
                if (isSucceeded(this.props.addGeoBag)) {
                    this.handleCancel();
                };
            };

            if (this.props.updateGeoBag !== prevProps.updateGeoBag) {
                if (isSucceeded(this.props.updateGeoBag)) {
                    this.handleCancel();
                };
            };
        };
    };


    public render(): ReactNode {

        const { updateGeoBag, addGeoBag } = this.props;
        const { geoBag, errors } = this.state;
        const isAdd = geoBag.id > 0 ? false : true;

        return (
            <PageSpacing title="SIL - DISPATCH" description="Geo Bag" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="carrierAccess">
                    <GeoBagStyles>

                        <LAButton startIcon={<ArrowLeftIcon color={WHITE_COLOR} />} label="Back to list" onClick={this.handleCancel} />
                        <h2 className="text-center">{!isAdd ? "VIEW " : "ADD "} GEO BAG</h2>
                        <hr />

                        <LAPaperWithPadding>
                            <LAGrid spacing={3}>

                                <LAGridItem xs={4}>
                                    <LATextField
                                        varient="outlined"
                                        label="Operator"
                                        fullWidth={true}
                                        name="operator"
                                        value={geoBag.operator ?? ""}
                                        onChange={this.onChange}
                                        errorText={errors["operator"] ? errors["operator"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LADateTimeMUIPicker
                                        fullWidth={true}
                                        name="date"
                                        onChange={this.onDateChange}
                                        label="Date"
                                        value={geoBag.date ?? ""}
                                        errorText={errors["date"] ? errors["date"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LATextField
                                        varient="outlined"
                                        label="SampleNTU to Pond"
                                        fullWidth={true}
                                        type="number"
                                        name="sampleNTU_to_Pond"
                                        value={geoBag.sampleNTU_to_Pond ?? ""}
                                        onChange={this.onNumberChange}
                                        errorText={errors["sampleNTU_to_Pond"] ? errors["sampleNTU_to_Pond"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LATextField
                                        varient="outlined"
                                        label="SampleNTU Before"
                                        fullWidth={true}
                                        type="number"
                                        name="sampleNTU_Before"
                                        value={geoBag.sampleNTU_Before ?? ""}
                                        onChange={this.onNumberChange}
                                        errorText={errors["sampleNTU_Before"] ? errors["sampleNTU_Before"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LATextField
                                        varient="outlined"
                                        label="SampleNTU Bag 1"
                                        fullWidth={true}
                                        type="number"
                                        name="sampleNTU_Bag_One"
                                        value={geoBag.sampleNTU_Bag_One ?? ""}
                                        onChange={this.onNumberChange}
                                        errorText={errors["sampleNTU_Bag_One"] ? errors["sampleNTU_Bag_One"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LATextField
                                        varient="outlined"
                                        label="SampleNTU Bag 2"
                                        fullWidth={true}
                                        type="number"
                                        name="sampleNTU_Bag_Two"
                                        value={geoBag.sampleNTU_Bag_Two ?? ""}
                                        onChange={this.onNumberChange}
                                        errorText={errors["sampleNTU_Bag_Two"] ? errors["sampleNTU_Bag_Two"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LATextField
                                        varient="outlined"
                                        label="SampleNTU Bag 3"
                                        fullWidth={true}
                                        type="number"
                                        name="sampleNTU_Bag_Three"
                                        value={geoBag.sampleNTU_Bag_Three ?? ""}
                                        onChange={this.onNumberChange}
                                        errorText={errors["sampleNTU_Bag_Three"] ? errors["sampleNTU_Bag_Three"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LATextField
                                        varient="outlined"
                                        label="SampleNTU Bag 4"
                                        fullWidth={true}
                                        type="number"
                                        name="sampleNTU_Bag_Four"
                                        value={geoBag.sampleNTU_Bag_Four ?? ""}
                                        onChange={this.onNumberChange}
                                        errorText={errors["sampleNTU_Bag_Four"] ? errors["sampleNTU_Bag_Four"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LATextField
                                        varient="outlined"
                                        label="SampleNTU Bag 5"
                                        fullWidth={true}
                                        type="number"
                                        name="sampleNTU_Bag_Five"
                                        value={geoBag.sampleNTU_Bag_Five ?? ""}
                                        onChange={this.onNumberChange}
                                        errorText={errors["sampleNTU_Bag_Five"] ? errors["sampleNTU_Bag_Five"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LATextField
                                        varient="outlined"
                                        label="SampleNTU Bag 6"
                                        fullWidth={true}
                                        type="number"
                                        name="sampleNTU_Bag_Six"
                                        value={geoBag.sampleNTU_Bag_Six ?? ""}
                                        onChange={this.onNumberChange}
                                        errorText={errors["sampleNTU_Bag_Six"] ? errors["sampleNTU_Bag_Six"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LATextField
                                        varient="outlined"
                                        label="Bag 1 Before"
                                        fullWidth={true}
                                        type="number"
                                        name="bag_One_Before"
                                        value={geoBag.bag_One_Before ?? ""}
                                        onChange={this.onNumberChange}
                                        errorText={errors["bag_One_Before"] ? errors["bag_One_Before"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LATextField
                                        varient="outlined"
                                        label="Bag 1 After"
                                        fullWidth={true}
                                        type="number"
                                        name="bag_One_After"
                                        value={geoBag.bag_One_After ?? ""}
                                        onChange={this.onNumberChange}
                                        errorText={errors["bag_One_After"] ? errors["bag_One_After"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LATextField
                                        varient="outlined"
                                        label="Bag 2 Before"
                                        fullWidth={true}
                                        type="number"
                                        name="bag_Two_Before"
                                        value={geoBag.bag_Two_Before ?? ""}
                                        onChange={this.onNumberChange}
                                        errorText={errors["bag_Two_Before"] ? errors["bag_Two_Before"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LATextField
                                        varient="outlined"
                                        label="Bag 2 After"
                                        fullWidth={true}
                                        type="number"
                                        name="bag_Two_After"
                                        value={geoBag.bag_Two_After ?? ""}
                                        onChange={this.onNumberChange}
                                        errorText={errors["bag_Two_After"] ? errors["bag_Two_After"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LATextField
                                        varient="outlined"
                                        label="Bag 3 Before"
                                        fullWidth={true}
                                        type="number"
                                        name="bag_Three_Before"
                                        value={geoBag.bag_Three_Before ?? ""}
                                        onChange={this.onNumberChange}
                                        errorText={errors["bag_Three_Before"] ? errors["bag_Three_Before"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LATextField
                                        varient="outlined"
                                        label="Bag 3 After"
                                        fullWidth={true}
                                        type="number"
                                        name="bag_Three_After"
                                        value={geoBag.bag_Three_After ?? ""}
                                        onChange={this.onNumberChange}
                                        errorText={errors["bag_Three_After"] ? errors["bag_Three_After"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LATextField
                                        varient="outlined"
                                        label="Bag 4 Before"
                                        fullWidth={true}
                                        type="number"
                                        name="bag_Four_Before"
                                        value={geoBag.bag_Four_Before ?? ""}
                                        onChange={this.onNumberChange}
                                        errorText={errors["bag_Four_Before"] ? errors["bag_Four_Before"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LATextField
                                        varient="outlined"
                                        label="Bag 4 After"
                                        fullWidth={true}
                                        type="number"
                                        name="bag_Four_After"
                                        value={geoBag.bag_Four_After ?? ""}
                                        onChange={this.onNumberChange}
                                        errorText={errors["bag_Four_After"] ? errors["bag_Four_After"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LATextField
                                        varient="outlined"
                                        label="Bag 5 Before"
                                        fullWidth={true}
                                        type="number"
                                        name="bag_Five_Before"
                                        value={geoBag.bag_Five_Before ?? ""}
                                        onChange={this.onNumberChange}
                                        errorText={errors["bag_Five_Before"] ? errors["bag_Five_Before"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LATextField
                                        varient="outlined"
                                        label="Bag 5 After"
                                        fullWidth={true}
                                        type="number"
                                        name="bag_Five_After"
                                        value={geoBag.bag_Five_After ?? ""}
                                        onChange={this.onNumberChange}
                                        errorText={errors["bag_Five_After"] ? errors["bag_Five_After"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LATextField
                                        varient="outlined"
                                        label="Bag 6 Before"
                                        fullWidth={true}
                                        type="number"
                                        name="bag_Six_Before"
                                        value={geoBag.bag_Six_Before ?? ""}
                                        onChange={this.onNumberChange}
                                        errorText={errors["bag_Six_Before"] ? errors["bag_Six_Before"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LATextField
                                        varient="outlined"
                                        label="Bag 6 After"
                                        fullWidth={true}
                                        type="number"
                                        name="bag_Six_After"
                                        value={geoBag.bag_Six_After ?? ""}
                                        onChange={this.onNumberChange}
                                        errorText={errors["bag_Six_After"] ? errors["bag_Six_After"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LATextField
                                        varient="outlined"
                                        label="Dosage %"
                                        fullWidth={true}
                                        type="number"
                                        name="dosagePercentage"
                                        value={geoBag.dosagePercentage ?? ""}
                                        onChange={this.onNumberChange}
                                        errorText={errors["dosagePercentage"] ? errors["dosagePercentage"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LATextField
                                        varient="outlined"
                                        label="Consumption"
                                        fullWidth={true}
                                        type="number"
                                        name="consumption"
                                        value={geoBag.consumption ?? ""}
                                        onChange={this.onNumberChange}
                                        errorText={errors["consumption"] ? errors["consumption"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LATextField
                                        varient="outlined"
                                        label="Plant Uptime"
                                        fullWidth={true}
                                        type="number"
                                        name="plantUptime"
                                        value={geoBag.plantUptime ?? ""}
                                        onChange={this.onNumberChange}
                                        errorText={errors["plantUptime"] ? errors["plantUptime"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LATextField
                                        varient="outlined"
                                        label="Fuel Usage for TB"
                                        fullWidth={true}
                                        type="number"
                                        name="fuelUsageForTB"
                                        value={geoBag.fuelUsageForTB ?? ""}
                                        onChange={this.onNumberChange}
                                        errorText={errors["fuelUsageForTB"] ? errors["fuelUsageForTB"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LATextField
                                        varient="outlined"
                                        label="TB 1 Downtime"
                                        fullWidth={true}
                                        type="number"
                                        name="tB_One_Downtime"
                                        value={geoBag.tB_One_Downtime ?? ""}
                                        onChange={this.onNumberChange}
                                        errorText={errors["tB_One_Downtime"] ? errors["tB_One_Downtime"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LATextField
                                        varient="outlined"
                                        label="TB 2 Downtime"
                                        fullWidth={true}
                                        type="number"
                                        name="tB_Two_Downtime"
                                        value={geoBag.tB_Two_Downtime ?? ""}
                                        onChange={this.onNumberChange}
                                        errorText={errors["tB_Two_Downtime"] ? errors["tB_Two_Downtime"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LATextField
                                        varient="outlined"
                                        label="TB 3 Downtime"
                                        fullWidth={true}
                                        type="number"
                                        name="tB_Three_Downtime"
                                        value={geoBag.tB_Three_Downtime ?? ""}
                                        onChange={this.onNumberChange}
                                        errorText={errors["tB_Three_Downtime"] ? errors["tB_Three_Downtime"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LATextField
                                        varient="outlined"
                                        label="TB 4 Downtime"
                                        fullWidth={true}
                                        type="number"
                                        name="tB_Four_Downtime"
                                        value={geoBag.tB_Four_Downtime ?? ""}
                                        onChange={this.onNumberChange}
                                        errorText={errors["tB_Four_Downtime"] ? errors["tB_Four_Downtime"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LATextField
                                        varient="outlined"
                                        label="TB 5 Downtime"
                                        fullWidth={true}
                                        type="number"
                                        name="tB_Five_Downtime"
                                        value={geoBag.tB_Five_Downtime ?? ""}
                                        onChange={this.onNumberChange}
                                        errorText={errors["tB_Five_Downtime"] ? errors["tB_Five_Downtime"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LATextField
                                        varient="outlined"
                                        label="TB 6 Downtime"
                                        fullWidth={true}
                                        type="number"
                                        name="tB_Six_Downtime"
                                        value={geoBag.tB_Six_Downtime ?? ""}
                                        onChange={this.onNumberChange}
                                        errorText={errors["tB_Six_Downtime"] ? errors["tB_Six_Downtime"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={6}>
                                    <LATextArea
                                        minRows={4}
                                        rowsMax={10}
                                        varient="outlined"
                                        label="Notes"
                                        fullWidth={true}
                                        name="notes"
                                        value={geoBag.notes ?? ""}
                                        onChange={this.onChange}
                                        errorText={errors["notes"] ? errors["notes"].message : undefined}
                                    />
                                </LAGridItem>


                                <LAGridItem xs={12}>
                                    <hr />
                                    <LASaveAndCancelButton
                                        onSave={this.onSave}
                                        onCancel={this.handleCancel}
                                        disableSave={(Object.values(errors).length > 0 ? true : undefined)}
                                    />
                                </LAGridItem>

                            </LAGrid>
                        </LAPaperWithPadding>

                        <RequestStatus requestStatus={addGeoBag.kind} successMessage="Geo Bag successfully saved" />
                        <RequestStatus requestStatus={updateGeoBag.kind} successMessage="Geo Bag successfully updated" />
                    </GeoBagStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private onDateChange = (name: string, value: string): void => {
        const date = new Date(value);
        let errors = { ...this.state.errors };

        if (date.toString() === "Invalid Date") {
            if (!errors[name]) {
                errors[name] = { key: name, message: "Invalid Date" };
            }
        } else {
            if (errors[name])
                delete errors[name];
        };

        this.setState({
            ...this.state,
            geoBag: {
                ...this.state.geoBag,
                [name]: value
            },
            errors
        });
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, isRequired: boolean): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: isRequired ? true : undefined, minLength: 1, decimal: undefined });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        };
        return err;
    };

    private handleCancel = (): void => {
        this.props.history.push(ROUTE.SIL.DISPATCH.GEO_BAGS);
    };

    private onSave = (): void => {
        const { geoBag } = this.state;

        if (hasPayload(this.props.getToken))
            if (geoBag.id === 0) {
                this.props.addGeoBagRequest({
                    token: this.props.getToken.payload.response.token,
                    request: geoBag
                });
            } else {
                geoBag.modified_By = userName;

                this.props.updateGeoBagRequest({
                    token: this.props.getToken.payload.response.token,
                    request: geoBag
                });
            };
    };


    private onNumberChange = (name: string, value: string): void => {
        if (!isNaN(+value)) {
            let errors = this.state.errors;
            errors = this.errorChecker(name, value, errors, true);

            this.setState({
                ...this.state,
                errors,
                geoBag: {
                    ...this.state.geoBag,
                    [name]: +value
                }
            });
        }
    };

    private onChange = (name: string, value: string): void => {
        let errors = this.state.errors;
        errors = this.errorChecker(name, value, errors, true);

        this.setState({
            ...this.state,
            errors,
            geoBag: {
                ...this.state.geoBag,
                [name]: value
            }
        });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getGeoBags))
            this.props.getGeoBagsRequest({
                token: this.props.getToken.payload.response.token
            });


        const query = queryString.parse(this.props.location.search);
        const id = query.id ?? "0";

        if ((id !== "0") && (hasPayload(this.props.getGeoBags))) {
            const geoBag = this.props.getGeoBags.payload.response.find(x => x.id === Number(id));

            if (geoBag)
                this.setState({ geoBag, errors: {} });
        };

    };

}

const mapStateToProps = (state: IStore): IGeoBagStoreProps => ({
    getToken: getTokenStatus(state),
    updateGeoBag: updateGeoBagStatus(state),
    addGeoBag: addGeoBagStatus(state),
    getGeoBags: getGeoBagsStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IGeoBagDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getGeoBagsRequest: (data: IGetGeoBagsRequest) => dispatch(getGeoBagsLoadAction(data)),
    addGeoBagRequest: (data: IAddGeoBagRequest) => dispatch(addGeoBagLoadAction(data)),
    updateGeoBagRequest: (data: IUpdateGeoBagRequest) => dispatch(updateGeoBagLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(GeoBag);