import { SimosAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetOrderVersionHistoryRequest, IGET_ORDER_VERSION_HISTORY_REQUEST, ISIMOSOrderVersionHistory } from "./getOrderVersionHistoryConstaints";

export interface IGetOrderVersionHistoryLoadAction {
    type: IGET_ORDER_VERSION_HISTORY_REQUEST.REQUEST;
    data: IGetOrderVersionHistoryRequest
}
export const getOrderVersionHistoryLoadAction = (data: IGetOrderVersionHistoryRequest): IGetOrderVersionHistoryLoadAction => ({
    type: IGET_ORDER_VERSION_HISTORY_REQUEST.REQUEST,
    data
});

export interface IGetOrderVersionHistorySuccessAction {
    type: IGET_ORDER_VERSION_HISTORY_REQUEST.SUCCESS;
    data: SimosAPIResponse<ISIMOSOrderVersionHistory[]>;
}
export const getOrderVersionHistoryLoadSuccessAction = (data: SimosAPIResponse<ISIMOSOrderVersionHistory[]>): IGetOrderVersionHistorySuccessAction => ({
    type: IGET_ORDER_VERSION_HISTORY_REQUEST.SUCCESS,
    data
});

export interface IGetOrderVersionHistoryLoadFailedAction {
    type: IGET_ORDER_VERSION_HISTORY_REQUEST.FAILED;
    message: string;
}
export const getOrderVersionHistoryLoadFailedAction = (message: string): IGetOrderVersionHistoryLoadFailedAction => ({
    type: IGET_ORDER_VERSION_HISTORY_REQUEST.FAILED,
    message
});
