import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, isSucceeded, Server } from "../../../../redux/server";
import { ById, SilAPIResponse } from "../../../shared/publicInterfaces";
import { userName, undefinedFunction, getYearsList, yesOrNoOptions, deleteText } from "../../../shared/constExports";
import { LAButton, LAIconButton, LASaveAndCancelButton } from "../../../shared/buttons";
import { ArrowLeftIcon, DeleteIcon } from "../../../shared/icons";
import { BLUE_GREEN_COLOR, WHITE_COLOR } from "../../../shared/theme";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import LATextField from "../../../shared/textField";
import LAAutoComplete from "../../../shared/autoComplete";
import { ROUTE } from "../../../routes";
import queryString from "query-string";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import { FieldValidator, IFieldErrorKeyValue } from "../../../shared/fieldValidator";
import PageSpacing from "../../../shared/pageSpacing";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import SilRoleCheck from "../../../shared/silRoleCheck";
import { IGetNCRRequest, INCR } from "../../../../redux/sil/cv/ncrs/getNCRs/getNCRsConstaints";
import { IAddNCRRequest } from "../../../../redux/sil/cv/ncrs/addNCR/addNCRConstaints";
import { IUpdateNCRRequest } from "../../../../redux/sil/cv/ncrs/updateNCR/updateNCRConstaints";
import { getNCRStatus } from "../../../../redux/sil/cv/ncrs/getNCRs/getNCRsAccessor";
import { addNCRStatus } from "../../../../redux/sil/cv/ncrs/addNCR/addNCRAccessor";
import { updateNCRStatus } from "../../../../redux/sil/cv/ncrs/updateNCR/updateNCRAccessor";
import { getNCRLoadAction } from "../../../../redux/sil/cv/ncrs/getNCRs/getNCRsActions";
import { addNCRLoadAction } from "../../../../redux/sil/cv/ncrs/addNCR/addNCRActions";
import { updateNCRLoadAction } from "../../../../redux/sil/cv/ncrs/updateNCR/updateNCRActions";
import { IIdName } from "../../../../utils/sharedTypes";
import { LADropDone } from "../../../shared/dragDropFiles";
import LALinkButton from "../../../shared/linkButton";
import { END_POINTS } from "../../../../redux/endpoints";
import { LAExpansionPanel } from "../../../shared/expansionPanel";
import LATextArea from "../../../shared/textArea";
import { LADateTimeMUIPicker } from "../../../shared/dateTimePickerMUI";


const InfoGatheringList: IIdName<number>[] = [
    { id: 0, name: "Late Load" },
    { id: 1, name: "Wrong Product" },
    { id: 2, name: "Contamination" },
    { id: 3, name: "Wrong Address" },
    { id: 4, name: "Dustnet" },
    { id: 5, name: "Wrong Spec" },
    { id: 6, name: "Dusty" },
    { id: 7, name: "Wet Material" },
    { id: 8, name: "Packaging" },
    { id: 9, name: "Other" }
];


const NCRCatList: IIdName<number>[] = [
    { id: 0, name: "Sales/Order Desk" },
    { id: 1, name: "Purchasing" },
    { id: 2, name: "Production" },
    { id: 3, name: "Shipping" },
    { id: 4, name: "Quality" },
    { id: 5, name: "Support" }
];

const DispositionList: IIdName<number>[] = [
    { id: 0, name: "Replace Product" },
    { id: 1, name: "Passed as is" },
    { id: 2, name: "Credit through Sil" },
    { id: 3, name: "Credit through Supplier" },
    { id: 4, name: "Return to Supplier" },
    { id: 5, name: "Dismissed as is" },
    { id: 6, name: "Other" }
];

interface INCRStoreProps {
    addNCR: Server<string>;
    updateNCR: Server<string>;
    getToken: Server<SilAPIResponse<IToken>>;
    getNCRs: Server<SilAPIResponse<INCR[]>>;
};

interface INCRDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getNCRsRequest: (data: IGetNCRRequest) => unknown;
    addNCRRequest: (data: IAddNCRRequest) => unknown;
    updateNCRRequest: (data: IUpdateNCRRequest) => unknown;
};


interface INCROwnProps {

};

interface INCRState {
    ncr: INCR;
    errors: ById<IFieldErrorKeyValue>;
};

const NCRStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
`;

type INCRProps = RouteComponentProps
    & INCRStoreProps
    & INCRDispatchProps
    & INCROwnProps;

class NCR extends PureComponent<INCRProps, INCRState> {

    public constructor(props: INCRProps) {
        super(props);
        this.state = {
            errors: {
            },
            ncr: {
                id: 0,
                non_Conformance: yesOrNoOptions[1].name,
                accreditation: yesOrNoOptions[1].name,
                date: "",
                customer: "",
                information_Gathered: InfoGatheringList[0].name,
                nCR_Category: NCRCatList[0].name,
                manager_Responsible: "",
                circumstances_Surrounding_Report: "",
                attachments: "",
                circumstances_Reported_By: "",
                immediate_Action_Taken: "",
                immediate_Action_Reported_By: "",
                disposition: DispositionList[0].name,
                cost_Of_Non_Conformance: "",
                comments: "",
                resolution_Date: "",
                root_Cause: "",
                resolution: "",
                manager_Notes: "",
                attachmentContent: [],
                created: "",
                created_By: userName,
                modified: "",
                modified_By: userName
            }
        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: INCRProps): void {
        if (this.props !== prevProps) {
            this.callServer();

            if (this.props.addNCR !== prevProps.addNCR) {
                if (isSucceeded(this.props.addNCR)) {
                    this.handleCancel();
                };
            };

            if (this.props.updateNCR !== prevProps.updateNCR) {
                if (isSucceeded(this.props.updateNCR)) {
                    this.handleCancel();
                };
            };
        };
    };


    public render(): ReactNode {

        const { updateNCR, addNCR } = this.props;
        const { ncr, errors } = this.state;

        const isAdd = ncr.id > 0 ? false : true;
        const years = getYearsList(2018);

        return (
            <PageSpacing title="SIL - CV" description="Belt Scale" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="carrierAccess">
                    <NCRStyles>

                        <LAButton startIcon={<ArrowLeftIcon color={WHITE_COLOR} />} label="Back to list" onClick={this.handleCancel} />
                        <h2 className="text-center">{!isAdd ? "VIEW " : "ADD "} BELT SCALE</h2>
                        <hr />

                        <LAPaperWithPadding>
                            <LAGrid>

                                <LAGridItem xs={12}>
                                    <LAExpansionPanel color={BLUE_GREEN_COLOR} textColor={WHITE_COLOR} label="Conformance Report" expanded={true} onClick={undefinedFunction}>
                                        <LAGrid spacing={3}>
                                            <LAGridItem xs={4}>
                                                <LAAutoComplete
                                                    filterSelectedOptions={true}
                                                    autoHighlight={true}
                                                    selectionRemove={undefinedFunction}
                                                    dropDownPlaceHolder="Non-Conformance"
                                                    getOptionLabel="name"
                                                    name="non_Conformance"
                                                    option={yesOrNoOptions}
                                                    multiple={false}
                                                    onChange={this.handleDropDownChange}
                                                    value={yesOrNoOptions.find(x => x.name === ncr.non_Conformance)}
                                                    defaultValue={yesOrNoOptions.find(x => x.name === ncr.non_Conformance)}
                                                    errorText={errors["non_Conformance"] ? errors["non_Conformance"].message : undefined}
                                                />
                                            </LAGridItem>

                                            <LAGridItem xs={4}>
                                                <LAAutoComplete
                                                    filterSelectedOptions={true}
                                                    autoHighlight={true}
                                                    selectionRemove={undefinedFunction}
                                                    dropDownPlaceHolder="Accreditation"
                                                    getOptionLabel="name"
                                                    name="accreditation"
                                                    option={yesOrNoOptions}
                                                    multiple={false}
                                                    onChange={this.handleDropDownChange}
                                                    value={yesOrNoOptions.find(x => x.name === ncr.accreditation)}
                                                    defaultValue={yesOrNoOptions.find(x => x.name === ncr.accreditation)}
                                                    errorText={errors["accreditation"] ? errors["accreditation"].message : undefined}
                                                />
                                            </LAGridItem>

                                            <LAGridItem xs={4}>
                                                <LADateTimeMUIPicker
                                                    fullWidth={true}
                                                    name="date"
                                                    onChange={this.onDateChange}
                                                    label="Date"
                                                    dateOnly={true}
                                                    value={ncr.date ?? ""}
                                                    errorText={errors["date"] ? errors["date"].message : undefined}
                                                />
                                            </LAGridItem>

                                            <LAGridItem xs={4}>
                                                <LATextField
                                                    varient="outlined"
                                                    label="Customer"
                                                    fullWidth={true}
                                                    name="customer"
                                                    value={ncr.customer ?? ""}
                                                    onChange={this.onChange}
                                                    errorText={errors["customer"] ? errors["customer"].message : undefined}
                                                />
                                            </LAGridItem>

                                            <LAGridItem xs={4}>
                                                <LAAutoComplete
                                                    filterSelectedOptions={true}
                                                    autoHighlight={true}
                                                    selectionRemove={undefinedFunction}
                                                    dropDownPlaceHolder="Information Gathered"
                                                    getOptionLabel="name"
                                                    name="information_Gathered"
                                                    option={InfoGatheringList}
                                                    multiple={false}
                                                    onChange={this.handleDropDownChange}
                                                    value={InfoGatheringList.find(x => x.name === ncr.information_Gathered)}
                                                    defaultValue={InfoGatheringList.find(x => x.name === ncr.accreditation)}
                                                    errorText={errors["information_Gathered"] ? errors["information_Gathered"].message : undefined}
                                                />
                                            </LAGridItem>

                                            <LAGridItem xs={4}>
                                                <LAAutoComplete
                                                    filterSelectedOptions={true}
                                                    autoHighlight={true}
                                                    selectionRemove={undefinedFunction}
                                                    dropDownPlaceHolder="NCR Category"
                                                    getOptionLabel="name"
                                                    name="nCR_Category"
                                                    option={NCRCatList}
                                                    multiple={false}
                                                    onChange={this.handleDropDownChange}
                                                    value={NCRCatList.find(x => x.name === ncr.nCR_Category)}
                                                    defaultValue={NCRCatList.find(x => x.name === ncr.nCR_Category)}
                                                    errorText={errors["nCR_Category"] ? errors["nCR_Category"].message : undefined}
                                                />
                                            </LAGridItem>

                                            <LAGridItem xs={4}>
                                                <LATextField
                                                    varient="outlined"
                                                    label="Manager Responsible"
                                                    fullWidth={true}
                                                    name="manager_Responsible"
                                                    value={ncr.manager_Responsible ?? ""}
                                                    onChange={this.onChange}
                                                    errorText={errors["manager_Responsible"] ? errors["manager_Responsible"].message : undefined}
                                                />
                                            </LAGridItem>

                                        </LAGrid>
                                    </LAExpansionPanel>
                                </LAGridItem>

                                <LAGridItem xs={12}>
                                    <LAExpansionPanel color={BLUE_GREEN_COLOR} textColor={WHITE_COLOR} label="Report Details" expanded={true} onClick={undefinedFunction}>
                                        <LAGrid spacing={3}>

                                            <LAGridItem xs={6}>
                                                <LATextArea
                                                    minRows={6}
                                                    rowsMax={8}
                                                    varient="outlined"
                                                    label="Circumstances Surrounding Report"
                                                    fullWidth={true}
                                                    name="circumstances_Surrounding_Report"
                                                    value={ncr.circumstances_Surrounding_Report ?? ""}
                                                    onChange={this.onChange}
                                                    errorText={errors["circumstances_Surrounding_Report"] ? errors["circumstances_Surrounding_Report"].message : undefined}
                                                />
                                            </LAGridItem>

                                            <LAGridItem xs={4}>
                                                <LATextField
                                                    varient="outlined"
                                                    label="Circumstances Reported By"
                                                    fullWidth={true}
                                                    name="circumstances_Reported_By"
                                                    value={ncr.circumstances_Reported_By ?? ""}
                                                    onChange={this.onChange}
                                                    errorText={errors["circumstances_Reported_By"] ? errors["circumstances_Reported_By"].message : undefined}
                                                />
                                            </LAGridItem>

                                            <LAGridItem xs={6}>
                                                <LATextArea
                                                    minRows={6}
                                                    rowsMax={8}
                                                    varient="outlined"
                                                    label="Immediate Action Taken"
                                                    fullWidth={true}
                                                    name="immediate_Action_Taken"
                                                    value={ncr.immediate_Action_Taken ?? ""}
                                                    onChange={this.onChange}
                                                    errorText={errors["immediate_Action_Taken"] ? errors["immediate_Action_Taken"].message : undefined}
                                                />
                                            </LAGridItem>

                                            <LAGridItem xs={4}>
                                                <LATextField
                                                    varient="outlined"
                                                    label="Immediate Action Reported By"
                                                    fullWidth={true}
                                                    name="immediate_Action_Reported_By"
                                                    value={ncr.immediate_Action_Reported_By ?? ""}
                                                    onChange={this.onChange}
                                                    errorText={errors["immediate_Action_Reported_By"] ? errors["immediate_Action_Reported_By"].message : undefined}
                                                />
                                            </LAGridItem>

                                            <LAGridItem xs={6} className="text-center">
                                                <strong>File Attachment</strong>
                                                <LADropDone onFileDrop={(e: Array<File | DataTransferItem>) => isAdd ? this.handleAtt(e) : undefinedFunction} />
                                                {ncr.attachments && ncr.attachments.length > 0 && <LALinkButton className="pl-5" label={ncr.attachments} onClick={this.handleDownloadAtt} />}
                                                {ncr.attachments && <LAIconButton
                                                    name="attachment_Name"
                                                    label="Remove"
                                                    icon={<DeleteIcon />}
                                                    onClick={this.handleDeleteAtt}
                                                />}
                                            </LAGridItem>

                                        </LAGrid>
                                    </LAExpansionPanel>
                                </LAGridItem>

                                <LAGridItem xs={12}>
                                    <LAExpansionPanel color={BLUE_GREEN_COLOR} textColor={WHITE_COLOR} label="Outcome" expanded={true} onClick={undefinedFunction}>
                                        <LAGrid spacing={3}>

                                            <LAGridItem xs={4}>
                                                <LAAutoComplete
                                                    filterSelectedOptions={true}
                                                    autoHighlight={true}
                                                    selectionRemove={undefinedFunction}
                                                    dropDownPlaceHolder="Disposition"
                                                    getOptionLabel="name"
                                                    name="disposition"
                                                    option={DispositionList}
                                                    multiple={false}
                                                    onChange={this.handleDropDownChange}
                                                    value={DispositionList.find(x => x.name === ncr.disposition)}
                                                    defaultValue={DispositionList.find(x => x.name === ncr.disposition)}
                                                    errorText={errors["disposition"] ? errors["disposition"].message : undefined}
                                                />
                                            </LAGridItem>

                                            <LAGridItem xs={4}>
                                                <LATextField
                                                    varient="outlined"
                                                    label="Cost of Non Conformance"
                                                    fullWidth={true}
                                                    name="cost_Of_Non_Conformance"
                                                    value={ncr.cost_Of_Non_Conformance ?? ""}
                                                    onChange={this.onChange}
                                                    errorText={errors["cost_Of_Non_Conformance"] ? errors["cost_Of_Non_Conformance"].message : undefined}
                                                />
                                            </LAGridItem>

                                            <LAGridItem xs={4}>
                                                <LATextArea
                                                    minRows={3}
                                                    rowsMax={8}
                                                    varient="outlined"
                                                    label="Comments on disposition/liability"
                                                    fullWidth={true}
                                                    name="comments"
                                                    value={ncr.comments ?? ""}
                                                    onChange={this.onChange}
                                                    errorText={errors["comments"] ? errors["comments"].message : undefined}
                                                />
                                            </LAGridItem>

                                        </LAGrid>
                                    </LAExpansionPanel>
                                </LAGridItem>

                                <LAGridItem xs={12}>
                                    <LAExpansionPanel color={BLUE_GREEN_COLOR} textColor={WHITE_COLOR} label="Closing" expanded={true} onClick={undefinedFunction}>
                                        <LAGrid spacing={3}>

                                            <LAGridItem xs={4}>
                                                <LADateTimeMUIPicker
                                                    fullWidth={true}
                                                    name="resolution_Date"
                                                    onChange={this.onDateChange}
                                                    label="Resolution Date"
                                                    dateOnly={true}
                                                    value={ncr.resolution_Date ?? ""}
                                                    errorText={errors["resolution_Date"] ? errors["resolution_Date"].message : undefined}
                                                />
                                            </LAGridItem>

                                            <LAGridItem xs={4}>
                                                <LATextArea
                                                    minRows={3}
                                                    rowsMax={8}
                                                    varient="outlined"
                                                    label="Root Cause"
                                                    fullWidth={true}
                                                    name="root_Cause"
                                                    value={ncr.root_Cause ?? ""}
                                                    onChange={this.onChange}
                                                    errorText={errors["root_Cause"] ? errors["root_Cause"].message : undefined}
                                                />
                                            </LAGridItem>

                                            <LAGridItem xs={4}>
                                                <LATextArea
                                                    minRows={3}
                                                    rowsMax={8}
                                                    varient="outlined"
                                                    label="Resolution"
                                                    fullWidth={true}
                                                    name="resolution"
                                                    value={ncr.resolution ?? ""}
                                                    onChange={this.onChange}
                                                    errorText={errors["resolution"] ? errors["resolution"].message : undefined}
                                                />
                                            </LAGridItem>

                                            <LAGridItem xs={4}>
                                                <LATextArea
                                                    minRows={3}
                                                    rowsMax={8}
                                                    varient="outlined"
                                                    label="Manager Notes and Follow-Up Actions"
                                                    fullWidth={true}
                                                    name="manager_Notes"
                                                    value={ncr.manager_Notes ?? ""}
                                                    onChange={this.onChange}
                                                    errorText={errors["manager_Notes"] ? errors["manager_Notes"].message : undefined}
                                                />
                                            </LAGridItem>

                                        </LAGrid>
                                    </LAExpansionPanel>
                                </LAGridItem>

                                <LAGridItem xs={12}>
                                    <hr />
                                    <LASaveAndCancelButton
                                        onSave={this.onSave}
                                        onCancel={this.handleCancel}
                                        disableSave={(Object.values(errors).length > 0 ? true : undefined)}
                                    />
                                </LAGridItem>

                            </LAGrid>
                        </LAPaperWithPadding>

                        <RequestStatus requestStatus={addNCR.kind} successMessage="Belt Scale successfully saved" />
                        <RequestStatus requestStatus={updateNCR.kind} successMessage="Belt Scale successfully updated" />
                    </NCRStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private onDateChange = (name: string, value: string): void => {
        const date = new Date(value);
        let errors = { ...this.state.errors };

        if (date.toString() === "Invalid Date") {
            if (!errors[name]) {
                errors[name] = { key: name, message: "Invalid Date" };
            }
        } else {
            if (errors[name])
                delete errors[name];
        };

        this.setState({
            ...this.state,
            ncr: {
                ...this.state.ncr,
                [name]: value
            },
            errors
        });
    };

    private handleDeleteAtt = (): void => {
        const selection = window.confirm(deleteText);
        if(selection) {
            this.setState({
                ncr: {
                    ...this.state.ncr,
                    attachments: "",
                    attachmentContent: []
                }
            });
        }
    };

    private handleDownloadAtt = (): void => {
        const { ncr } = this.state;
        if (ncr.attachments) {
            if ((ncr.attachmentContent !== null) && (ncr.attachmentContent[0] !== undefined) && (ncr.attachmentContent[0].Base64String !== undefined)) {
                let a = document.createElement("a");
                a.href = ncr.attachmentContent[0].Base64String;
                a.download = ncr.attachments;
                a.click();
            };

            if ((ncr.attachmentContent === null) || (ncr.attachmentContent[0] === undefined)) {
                if (hasPayload(this.props.getToken) && ncr.id) {

                    fetch(END_POINTS.PROPSENSE.SIL.CV.GetCVFile, {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            request: {
                                "ID": ncr.id,
                                "File": ncr.attachments,
                                "Page": "NCR"
                            },
                            token: this.props.getToken.payload.response.token
                        })
                    })
                        .then((res: any) => {
                            return res.blob();
                        })
                        .then(blob => {
                            if (ncr.attachments) {
                                let url = window.URL.createObjectURL(blob);
                                let a = document.createElement('a');
                                a.href = url;
                                a.download = ncr.attachments;
                                document.body.appendChild(a);
                                a.click();
                                a.remove();
                            }
                        })
                        .catch((res) => console.log(res));
                };
            };
        }
    };

    private handleAtt = (files: Array<File | DataTransferItem>): void => {
        new Promise((resolve, reject): any => {
            if (files && (files !== null)) {

                Object.values(files).forEach((x: any, index, array) => {
                    const reader = new FileReader();

                    reader.onloadend = async (e): Promise<void> => {
                        if (reader.result !== null) {
                            this.setState({
                                ...this.state,
                                ncr: {
                                    ...this.state.ncr,
                                    attachments: x.name,
                                    attachmentContent: [{
                                        Name: x.name,
                                        Base64String: reader.result.toString()
                                    }]
                                },
                            });
                            resolve(true);
                        };
                    };
                    reader.readAsDataURL(x);
                })
            } else {
                reject();
            }
        });
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, isRequired: boolean): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: isRequired ? true : undefined, minLength: 1, decimal: undefined });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        };
        return err;
    };

    private handleCancel = (): void => {
        this.props.history.push(ROUTE.SIL.CV.NCR.INDEX);
    };

    private onSave = (): void => {
        const { ncr } = this.state;

        if (hasPayload(this.props.getToken))
            if (ncr.id === 0) {
                this.props.addNCRRequest({
                    token: this.props.getToken.payload.response.token,
                    request: ncr
                });
            } else {
                ncr.modified_By = userName;
                
                this.props.updateNCRRequest({
                    token: this.props.getToken.payload.response.token,
                    request: ncr
                });
            };
    };

    private handleDropDownChange = (event: unknown, value: { id: number, name: string } | "", name?: string): void => {
        if (name) {
            let errors = this.state.errors;
            errors = this.errorChecker(name, value !== "" ? value.name : "", errors, true);

            this.setState({
                ...this.state,
                errors,
                ncr: {
                    ...this.state.ncr,
                    [name]: value !== "" ? value.name : ""
                }
            });
        }
    };


    private onChange = (name: string, value: string): void => {
        let errors = this.state.errors;
        errors = this.errorChecker(name, value, errors, true);

        this.setState({
            ...this.state,
            errors,
            ncr: {
                ...this.state.ncr,
                [name]: value
            }
        });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getNCRs))
            this.props.getNCRsRequest({
                token: this.props.getToken.payload.response.token
            });


        const query = queryString.parse(this.props.location.search);
        const id = query.id ?? "0";

        if ((id !== "0") && (hasPayload(this.props.getNCRs))) {
            const ncr = this.props.getNCRs.payload.response.find(x => x.id === Number(id));

            if (ncr)
                this.setState({ ncr, errors: {} });
        };

    };

}

const mapStateToProps = (state: IStore): INCRStoreProps => ({
    getToken: getTokenStatus(state),
    getNCRs: getNCRStatus(state),
    addNCR: addNCRStatus(state),
    updateNCR: updateNCRStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): INCRDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getNCRsRequest: (data: IGetNCRRequest) => dispatch(getNCRLoadAction(data)),
    addNCRRequest: (data: IAddNCRRequest) => dispatch(addNCRLoadAction(data)),
    updateNCRRequest: (data: IUpdateNCRRequest) => dispatch(updateNCRLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(NCR);