import { IADD_SIMOS_CUSTOMER_REQUEST, IAddSimosCustomerRequest } from "./addSimosCustomerConstaints";

export interface IAddSimosCustomerLoadAction {
    type: IADD_SIMOS_CUSTOMER_REQUEST.REQUEST;
    data: IAddSimosCustomerRequest
}
export const addSimosCustomerLoadAction = (data: IAddSimosCustomerRequest): IAddSimosCustomerLoadAction => ({
    type: IADD_SIMOS_CUSTOMER_REQUEST.REQUEST,
    data
});

export interface IAddSimosCustomerSuccessAction {
    type: IADD_SIMOS_CUSTOMER_REQUEST.SUCCESS;
    message: string;
}
export const addSimosCustomerLoadSuccessAction = (message: string): IAddSimosCustomerSuccessAction => ({
    type: IADD_SIMOS_CUSTOMER_REQUEST.SUCCESS,
    message
});

export interface IAddSimosCustomerLoadFailedAction {
    type: IADD_SIMOS_CUSTOMER_REQUEST.FAILED;
    message: string;
}
export const addSimosCustomerLoadFailedAction = (message: string): IAddSimosCustomerLoadFailedAction => ({
    type: IADD_SIMOS_CUSTOMER_REQUEST.FAILED,
    message
});
