
import { SilAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { IGetPSLookupsRequest, IGET_PS_LOOKUPS_REQUEST, IPSLookup } from "./getPSLookupsConstaints";

export interface IGetPSLookupsLoadAction {
    type: IGET_PS_LOOKUPS_REQUEST.REQUEST;
    data: IGetPSLookupsRequest
}
export const getPSLookupsLoadAction = (data: IGetPSLookupsRequest): IGetPSLookupsLoadAction => ({
    type: IGET_PS_LOOKUPS_REQUEST.REQUEST,
    data
});

export interface IGetPSLookupsSuccessAction {
    type: IGET_PS_LOOKUPS_REQUEST.SUCCESS;
    data: SilAPIResponse<IPSLookup>;
}
export const getPSLookupsLoadSuccessAction = (data: SilAPIResponse<IPSLookup>): IGetPSLookupsSuccessAction => ({
    type: IGET_PS_LOOKUPS_REQUEST.SUCCESS,
    data
});

export interface IGetPSLookupsLoadFailedAction {
    type: IGET_PS_LOOKUPS_REQUEST.FAILED;
    message: string;
}
export const getPSLookupsLoadFailedAction = (message: string): IGetPSLookupsLoadFailedAction => ({
    type: IGET_PS_LOOKUPS_REQUEST.FAILED,
    message
});
