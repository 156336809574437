import { ById, SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, hasPayload, loading, notLoaded, Server, succeeded } from "../../../server";
import { IAddWashPlantProductionSuccessAction } from "../addWashPlantProduction/addWashPlantProductionActions";
import { IADD_WASH_PLANT_PRODUCTION_REQUEST } from "../addWashPlantProduction/addWashPlantProductionConstaints";
import { IWashPlantProductionRecords } from "../getWashPlantProductionRecords/getWashPlantProductionRecordsConstaints";
import { IUpdateWashPlantProductionSuccessAction } from "../updateWashPlantProduction/updateWashPlantProductionActions";
import { IUPDATE_WASH_PLANT_PRODUCTION_REQUEST } from "../updateWashPlantProduction/updateWashPlantProductionConstaints";
import { IGetWashPlantProductionRecordByIdLoadAction, IGetWashPlantProductionRecordByIdLoadFailedAction, IGetWashPlantProductionRecordByIdSuccessAction } from "./getWashPlantProductionRecordByIdActions";
import { IGET_WASH_PLANT_PRODUCTION_RECORDS_REQUEST } from "./getWashPlantProductionRecordByIdConstaints";

type Actions =
    IGetWashPlantProductionRecordByIdLoadAction
    | IGetWashPlantProductionRecordByIdLoadFailedAction
    | IGetWashPlantProductionRecordByIdSuccessAction
    | IUpdateWashPlantProductionSuccessAction
    | IAddWashPlantProductionSuccessAction
    | IFlushDataSuccessAction;

export const GetWashPlantProductionRecordByIdReducer = (state: Server<SilAPIResponse<IWashPlantProductionRecords>> = notLoaded, action: Actions): Server<SilAPIResponse<IWashPlantProductionRecords>> => {
    switch (action.type) {
        case IGET_WASH_PLANT_PRODUCTION_RECORDS_REQUEST.REQUEST:
            return loading;

        case IGET_WASH_PLANT_PRODUCTION_RECORDS_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_WASH_PLANT_PRODUCTION_RECORDS_REQUEST.FAILED:
            return failed(action.message);

        case IADD_WASH_PLANT_PRODUCTION_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_WASH_PLANT_PRODUCTION_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};