import React from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import BackButton from '../../../../shared/simos/helper/backButton';
import {transportcompanycopy} from './transportCompanyTable';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import {addTransportCompany} from '../APICalls/addUpdateTransportCompany';
import {getSIMOSUserGroupAccess} from '../APICalls/getSIMOSGroupAccess';
import { getSILURLParams } from '../../../../shared/simos/helper/getURLContainsSIL';

interface IProps {
    history:any;
}
interface IState {
    transportcompany:string;
    email:string;
    active:string;
    error:boolean;
    showError:boolean;
    errorText:string;
    truck_code_y:string;
    truck_code_z:string;
}
var user_role:any = "";
export default class CreateTransportCompanyForm extends React.Component<IProps, IState>{
    constructor(props:any) {
        super(props);
        this.state = {transportcompany:'',email:'',active:'', error:false, showError:false, errorText:'', truck_code_y:'', truck_code_z:''}
        this.onTextChange = this.onTextChange.bind(this);
    }
    onTextChange(event:any) {
        var exists = false;
        switch(event.target.name){
            case 'transportcompany':
                transportcompanycopy.map((cust:any) => {
                    if(cust.transport_Company === event.target.value){
                        exists = true;
                        this.setState({error:exists});
                    }
                });
                this.setState({transportcompany:event.target.value});
                this.setState({error:exists});
                break;
            case 'transportemail':
                this.setState({email:event.target.value});
                break;
            case 'activeradio':
                this.setState({active:event.target.value});
                break;
            case 'truck_code_y':
                this.setState({truck_code_y:event.target.value});
                break;
            case 'truck_code_z':
                this.setState({truck_code_z:event.target.value});
                break;
        }
    }
  
    async onSave(event:any){
        event.preventDefault();
        if(this.state.email!="" && this.state.active != "" && this.state.transportcompany!= "" && !this.state.error){
                var success:any = await addTransportCompany(this.state.transportcompany, this.state.active, this.state.email, this.state.truck_code_y, this.state.truck_code_z);
                if(success.status === 200){
                    this.props.history.replace('/simos/transport-companies'+getSILURLParams());
                    alert("Successfully added new transport company");
                    window.location.reload();
                }
            }
            else{
                //alert("All fields must be filled!");
                this.setState({showError:true, errorText:'All fields must be filled!'});
            }
    }
    onCancel(event:any){
        event.preventDefault();
        this.props.history.replace('/simos/transport-companies'+getSILURLParams());
    }
  
    async componentWillMount(){
        var response:any = await getSIMOSUserGroupAccess();
        var userRole:any = response.role;
        user_role = userRole;
        if(userRole!='Admin' && userRole!='Dispatcher' && userRole!='SuperAdmin'){
            this.props.history.push('/simos');
        }
        this.setState({});
    }
    render() {   
        // let customerOptions = transportcompanycopy.map((customer:string) => <option>{customer}</option>);
        // customerOptions.push(<option disabled selected></option>);
        
        return (
            <div className={getSILURLParams()?.includes("bru") ? "edit-popup":"edit-popup-sil-ed"}>
            <div className = 'edit-popup_inner'>
            {this.state.showError ? 
              <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert variant="filled" severity="error" action={<IconButton color="inherit" onClick={() => {this.setState({showError:false})}}>X</IconButton>}>
                  {this.state.errorText}
                </Alert>
              </Stack>
            :null}
            <br></br>
            <BackButton goBack={this.onCancel.bind(this)} history={this.props.history}></BackButton>
                <form>
                    <h3>Creating New Transport Company</h3>
                        <div id="edit-info-fields">
                            <Grid container spacing={3} direction="column" alignItems="center">
                                <Grid item xs={12}>
                                    <TextField autoComplete='nope' helperText={this.state.error === true ? 'Transport Company already exists':null} error={this.state.error===true} id={'outlined-error-helper-text'} label="Transport Company" name="transportcompany" onChange={this.onTextChange}></TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField autoComplete='nope'  label="Email" name="transportemail" onChange={this.onTextChange}></TextField>
                                </Grid>
                                <Grid item xs={6}>
                                <FormLabel component="legend">Active</FormLabel>
                                    <RadioGroup name="activeradio" onChange={this.onTextChange}>
                                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="No" control={<Radio />} label="No" />
                                    </RadioGroup>
                                </Grid>
                                <Grid item xs={12}>
                                <TextField disabled={user_role !== 'SuperAdmin'} autoComplete='nope'  label="Truck Code Y" name="truck_code_y" onChange={this.onTextChange}></TextField>
                            </Grid><Grid item xs={12}>
                                    <TextField disabled={user_role !== 'SuperAdmin'} autoComplete='nope'  label="Truck Code Z" name="truck_code_z" onChange={this.onTextChange}></TextField>
                                </Grid>
                                </Grid>
                                </div>
                     <br></br>
                        <button type='submit' onClick={this.onSave.bind(this)} id='submit-contact-form-btn'>Save</button>
                        <button onClick={this.onCancel.bind(this)} id='cancel-contact-form-btn'>Cancel</button>
                </form>
            </div>
            </div>
        );
    }
}
