import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_SIMOS_CUSTOMERS_REQUEST, ISimosCustomers } from "./getSimosCustomersConstants";
import { IGetSimosCustomersLoadAction, IGetSimosCustomersLoadFailedAction, IGetSimosCustomersSuccessAction, getSimosCustomersLoadFailedAction, getSimosCustomersLoadSuccessAction } from "./getSimosCustomersActions";
import { IStore } from "../../../../reducers";
import { EpicDependencies } from "../../../../store";
import { SimosAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { END_POINTS } from "../../../../endpoints";
import { withJsonContentType } from "../../../../epicUtils";

export const getSimosCustomersEpic: Epic = (
    action$: ActionsObservable<IGetSimosCustomersLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetSimosCustomersSuccessAction | IGetSimosCustomersLoadFailedAction> =>
    action$.ofType(IGET_SIMOS_CUSTOMERS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SimosAPIResponse<ISimosCustomers[]>>(
                    END_POINTS.PROPSENSE.SIMOS.GET_SIMOS_CUSTOMERS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SimosAPIResponse<ISimosCustomers[]>): IGetSimosCustomersSuccessAction | IGetSimosCustomersLoadFailedAction => {
                            if(response.message === "Success"){
                                return getSimosCustomersLoadSuccessAction(response);
                            }
                            return getSimosCustomersLoadFailedAction(response.message);
                        }),
                        catchError((response: SimosAPIResponse<string>) => ActionsObservable.of(getSimosCustomersLoadFailedAction(response.message)))
                    )
            )
        );