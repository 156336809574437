import { ByIdNumber, YHDAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../../server";
import { IGET_YHD_CARRIERS_REQUEST, IYHDCarrier } from "./getCarriersConstaints";
import { IGetYHDCarriersLoadAction, IGetYHDCarriersLoadFailedAction, IGetYHDCarriersSuccessAction } from "./getCarriersActions";
import { ISAVE_YHD_CARRIER_FORM_REQUEST } from "../saveCarrierForm/saveCarrierFormConstaints";
import { ISaveYHDCarrierFormSuccessAction } from "../saveCarrierForm/saveCarrierFormActions";
import { IUpdateYHDCarrierFormSuccessAction } from "../updateCarrierForm/updateCarrierFormActions";
import { IUPDATE_YHD_CARRIER_FORM_REQUEST } from "../updateCarrierForm/updateCarrierFormConstaints";

type Actions =
    IGetYHDCarriersLoadAction
    | IGetYHDCarriersLoadFailedAction
    | IGetYHDCarriersSuccessAction
    | ISaveYHDCarrierFormSuccessAction
    | IUpdateYHDCarrierFormSuccessAction
    | IFlushDataSuccessAction;

export const GetYHDCarriersReducer = (state: Server<YHDAPIResponse<ByIdNumber<IYHDCarrier>>> = notLoaded, action: Actions): Server<YHDAPIResponse<ByIdNumber<IYHDCarrier>>> => {
    switch (action.type) {
        case IGET_YHD_CARRIERS_REQUEST.REQUEST:
            return loading;

        case IGET_YHD_CARRIERS_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_YHD_CARRIERS_REQUEST.FAILED:
            return failed(action.message);

        case ISAVE_YHD_CARRIER_FORM_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_YHD_CARRIER_FORM_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};