import { IAddLabScaleRequest, IADD_LAB_SCALE_REQUEST } from "./addLabScaleConstaints";

export interface IAddLabScaleLoadAction {
    type: IADD_LAB_SCALE_REQUEST.REQUEST;
    data: IAddLabScaleRequest
}
export const addLabScaleLoadAction = (data: IAddLabScaleRequest): IAddLabScaleLoadAction => ({
    type: IADD_LAB_SCALE_REQUEST.REQUEST,
    data
});

export interface IAddLabScaleSuccessAction {
    type: IADD_LAB_SCALE_REQUEST.SUCCESS;
    message: string;
}
export const addLabScaleLoadSuccessAction = (message: string): IAddLabScaleSuccessAction => ({
    type: IADD_LAB_SCALE_REQUEST.SUCCESS,
    message
});
export interface IAddLabScaleLoadFailedAction {
    type: IADD_LAB_SCALE_REQUEST.FAILED;
    message: string;
}
export const addLabScaleLoadFailedAction = (message: string): IAddLabScaleLoadFailedAction => ({
    type: IADD_LAB_SCALE_REQUEST.FAILED,
    message
});
