import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IAddComplaintLoggingLoadAction, IAddComplaintLoggingLoadFailedAction, IAddComplaintLoggingSuccessAction, 
    addComplaintLoggingLoadFailedAction, addComplaintLoggingLoadSuccessAction
} from "./addComplaintLoggingActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IADD_COMPLAINT_LOGGING_REQUEST } from "./addComplaintLoggingConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";

export const addComplaintLoggingEpic: Epic = (
    action$: ActionsObservable<IAddComplaintLoggingLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IAddComplaintLoggingSuccessAction | IAddComplaintLoggingLoadFailedAction> =>
    action$.ofType(IADD_COMPLAINT_LOGGING_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<string>>(
                    END_POINTS.PROPSENSE.SIL.COMPLAINT_LOGGING.ADD_COMPLAINT_LOGGING,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<string>): IAddComplaintLoggingSuccessAction | IAddComplaintLoggingLoadFailedAction => {
                            if(response.message === "Success"){
                                return addComplaintLoggingLoadSuccessAction(response.message);
                            };
                            return addComplaintLoggingLoadFailedAction(response.message)
                        }),
                        catchError(() => ActionsObservable.of(addComplaintLoggingLoadFailedAction("Unable to add sieves product")))
                    )
            )
        );