import { IPassTokenRequestBody } from "../../../../../react/shared/publicInterfaces";

export enum IADD_SIMOS_TRANSPORT_COMPANY_REQUEST {
    REQUEST = "addSimosTransportCompany/ADD_SIMOS_TRANSPORT_COMPANY_REQUEST",
    SUCCESS = "addSimosTransportCompany/ADD_SIMOS_TRANSPORT_COMPANY_SUCCESS",
    FAILED = "addSimosTransportCompany/ADD_SIMOS_TRANSPORT_COMPANY_FAILED"
};

export interface IAddSimosTransportCompanyRequest extends IPassTokenRequestBody {
    request: IAddUpdateSimosTransportCompany;
};

export interface IAddUpdateSimosTransportCompany {
    ID: number;
    Transport_Company: string;
    Email: string;
    Active: string;
    Truck_Code_Y: string;
    Truck_Code_Z: string;
    Created_By: string;
    Modified_By: string;
}