import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IGetSieveProductsLoadAction, IGetSieveProductsLoadFailedAction, IGetSieveProductsSuccessAction, 
    getSieveProductsLoadFailedAction, getSieveProductsLoadSuccessAction
} from "./getSievesProductsActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IGET_SIEVES_PRODUCTS_REQUEST, ISieveProduct } from "./getSievesProductsConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { ById, SilAPIResponse } from "../../../../react/shared/publicInterfaces";

export const getSieveProductsEpic: Epic = (
    action$: ActionsObservable<IGetSieveProductsLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IGetSieveProductsSuccessAction | IGetSieveProductsLoadFailedAction> =>
    action$.ofType(IGET_SIEVES_PRODUCTS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<ById<ISieveProduct>>>(
                    END_POINTS.PROPSENSE.SIL.SIEVES.GET_SIEVES_PRODUCT,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<ById<ISieveProduct>>): IGetSieveProductsSuccessAction => {
                            return getSieveProductsLoadSuccessAction(response);
                        }),
                        catchError(() => ActionsObservable.of(getSieveProductsLoadFailedAction("Unable to get sieve products")))
                    )
            )
        );