import {currentUser} from '../../../../simosHome';

let delivery_id = 0;
// ! This function only adds new contacts to SIMOS_Customer_Contact
async function addCustomerContact(customer_id: number, obj:any, chosenDelivery: string, chosenCustomer:string) {
    var res:any = null;
    // const requestOptionsDeliv = {
    //     method: 'POST',
    //     headers: { 'Content-Type':'application/json' }, 
    //     body: JSON.stringify({token: '19515195-d571-44a3-b0db-637f5bf24f54' })
    // }
    // await fetch('https://api2.propsense.com/api/GetSIMOSCustomerAddress', requestOptionsDeliv)
    // .then(async response => {
    //     const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
    //       // error response
    //       if(!response.ok) {
    //         const error = (data && data.message) || response.status;
    //         return Promise.reject(error);
    //     }
    //     // no error
    //     data.map((deliveryaddr:any) => {
    //         if(deliveryaddr.address === chosenDelivery){
    //             delivery_id = deliveryaddr.id;
    //         }
    //     });
    //     res = response;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify({
                request: {
                    Contact: obj[0].orderformName,
                    Phone: obj[0].orderformPhone,
                    Fax: obj[0].orderformFax,
                    Email:obj[0].orderformEmail,
                    Customer_ID: customer_id,
                    Delivery_ID:delivery_id,
                    Customer:chosenCustomer,
                    Delivery_Address:chosenDelivery,
                    Created_By:currentUser,
                    Modified_By:currentUser
                }, 
                token: '19515195-d571-44a3-b0db-637f5bf24f54'
            })
        }
       // if(res.status === 200){
            await fetch('https://api2.propsense.com/api/AddSIMOSCustomerContact', requestOptions)
            .then(async response => {
                const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
                // error response
                if(!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            } 
            res = response;
            });
       // }
   // });
    return res;
    
}

// ! This function only adds contacts that are selected, which are added to SIMOS_Ordered_By_Contact
async function addOrderedByContact(id: number, obj:any, index:number, orderID:number) {
    var responseStatus:any = null;
    let selected = "No";
    if(obj[index].contact_selected === true) {
        selected = "Yes";
    }
    const requestOptionsDeliv = {
        method: 'POST',
        headers: { 'Content-Type':'application/json' }, 
        body: JSON.stringify({token: '19515195-d571-44a3-b0db-637f5bf24f54' })
    }
     await fetch('https://api2.propsense.com/api/GetSIMOSCustomerAddress', requestOptionsDeliv)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
          // error response
          if(!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        // no error
        data.map((deliveryaddr:any) => {
            if(deliveryaddr.customer_ID === id){
                delivery_id = deliveryaddr.id;
            }
        });
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type':'application/json'},
        body: JSON.stringify({
            request: {
                Contact:obj[index].orderformName,
                Phone: obj[index].orderformPhone,
                Fax: obj[index].orderformFax,
                Email:obj[index].orderformEmail,
                Selected: selected,
                OrderID: orderID,
                Customer_ID: id,
                Delivery_ID: delivery_id,
                Created_By:currentUser,
                Modified_By:currentUser
            },
            token: '19515195-d571-44a3-b0db-637f5bf24f54'})
    }
     await fetch('https://api2.propsense.com/api/AddSIMOSOrderedByContact', requestOptions)
        .then(async response => {
            const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
            // error response
            if(!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        responseStatus = response;
        // no error
        });
    });
    return responseStatus;
}

async function deleteOrderedByContact(contact_id: number) {
    var output = null;
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type':'application/json'},
        body: JSON.stringify({
            request: {
                ID: contact_id
            },
            token: '19515195-d571-44a3-b0db-637f5bf24f54'})
    }
    await fetch('https://api2.propsense.com/api/DeleteSIMOSCustomerContact', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
        // error response
        if(!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
      // no error
      output = response;
    });
    return output;
}

export {addOrderedByContact, deleteOrderedByContact, addCustomerContact};