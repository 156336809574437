import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IADD_TRANSLOAD_BOL_COUNT_REQUEST } from "./addTransloadBOLCountConstaints";
import { IAddTransloadBOLCountLoadAction, IAddTransloadBOLCountLoadFailedAction, IAddTransloadBOLCountSuccessAction } from "./addTransloadBOLCountActions";

type Actions =
    IAddTransloadBOLCountLoadAction
    | IAddTransloadBOLCountLoadFailedAction
    | IAddTransloadBOLCountSuccessAction
    | IFlushDataSuccessAction;

export const AddTransloadBOLCountReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IADD_TRANSLOAD_BOL_COUNT_REQUEST.REQUEST:
            return loading;

        case IADD_TRANSLOAD_BOL_COUNT_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IADD_TRANSLOAD_BOL_COUNT_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};