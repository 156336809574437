import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAMaroonPaper, LAPaperWithLessPadding, LAPaperWithPadding } from "../../../../shared/paper";
import { IDispatch, IStore } from "../../../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../../redux/server";
import { SimosAPIResponse } from "../../../../shared/publicInterfaces";
import { IGetTokenRequest, IToken } from "../../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenLoadAction } from "../../../../../redux/sil/dryPlant/token/getTokenActions";
import { NotApplicable, callRouteWithQueryString, userName } from "../../../../shared/constExports";
import { LADevExtremeGrid } from "../../../../shared/devExtreme";
import { getTokenStatus } from "../../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { LAButton, LAIconButton } from "../../../../shared/buttons";
import { AddIcon, DownloadIcon } from "../../../../shared/icons";
import { LIGHT_BLUE_COLOR, LIGHT_GREEN_COLOR, LIGHT_ORANGE_YELLOW_COLOR, LIGHT_RED_COLOR, MEDIA_QUERY_PHONE, RED_COLOR, WHITE_COLOR } from "../../../../shared/theme";
import { ROUTE } from "../../../../routes";
import PageSpacing from "../../../../shared/pageSpacing";
import LALoading from "../../../../shared/loading";
import LAErrorBox from "../../../../shared/errorBox";
import LAGrid from "../../../../shared/grid";
import LAGridItem from "../../../../shared/gridList";
import { SimosSubHeader, SimosSubHeaderMobile } from "../../../../header/simosHeader";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import queryString from "query-string";
import { IGetOrdersRequest, ISIMOSOrders } from "../../../../../redux/propsense/simos/getOrders/getOrdersConstaints";
import { getOrdersLoadAction } from "../../../../../redux/propsense/simos/getOrders/getOrdersActions";
import { getOrdersStatus } from "../../../../../redux/propsense/simos/getOrders/getOrdersAccessor";
import { downloadOrdersExcel, downloadSIMOSOrderTicketPDF, SIMOSOrderVersionHistoryPopup, SIMOSProductsPopup } from "./silBruOrders";
import { IGetOrderVersionHistoryRequest, ISIMOSOrderVersionHistory } from "../../../../../redux/propsense/simos/getOrderVersionHistory/getOrderVersionHistoryConstaints";
import { IGetProductsByIdRequest, ISIMOSProductsById } from "../../../../../redux/propsense/simos/getProductsById/getProductsByIdConstaints";
import { getProductsById } from "../../../../../redux/propsense/simos/getProductsById/getProductsByIdAccessor";
import { getOrderVersionHistory } from "../../../../../redux/propsense/simos/getOrderVersionHistory/getOrderVersionHistoryAccessor";
import { getProductsByIdLoadAction } from "../../../../../redux/propsense/simos/getProductsById/getProductsByIdActions";
import { getOrderVersionHistoryLoadAction } from "../../../../../redux/propsense/simos/getOrderVersionHistory/getOrderVersionHistoryActions";
import Tooltip from "devextreme-react/tooltip";
import { on } from "devextreme/events";

interface ISILEdOrdersStoreProps {
    getToken: Server<SimosAPIResponse<IToken>>;
    getSILEdOrders: Server<SimosAPIResponse<ISIMOSOrders[]>>;
    getVersionHistory: Server<SimosAPIResponse<ISIMOSOrderVersionHistory[]>>;
    getProducts: Server<SimosAPIResponse<ISIMOSProductsById[]>>;
};

interface ISILEdOrdersDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getRequest: (data: IGetOrdersRequest) => unknown;
    getProductsRequest: (data: IGetProductsByIdRequest) => unknown;
    getVersionHistoryRequest: (data: IGetOrderVersionHistoryRequest) => unknown;
};


interface ISILEdOrdersOwnProps {

};

interface ISILEdOrdersState {
    view: string;
    versionPopup: boolean;
    productPopup: boolean;
    commentsTooltip: string | undefined;
    orderNumberTooltip: number | undefined;
    orderNumber: number | undefined;
};

const SILEdOrdersStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .loading {
        margin-top: 5%;
    };

    .white-color {
        color: white;
    }

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
    };

    .show-on-phone {
        display: none;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 5px 5px;

        .title {
            padding-top: 11%;
        };

        .pull-left {
            position: absolute;
            left: 3%;
            height: 3%;
            top: 1%;
        };

        .hide-on-phone, .hide-on-phone * {
            display: none;
        };

        .show-on-phone {
            display: block;
        };
    };
`;

type ISILEdOrdersProps = RouteComponentProps
    & ISILEdOrdersStoreProps
    & ISILEdOrdersDispatchProps
    & ISILEdOrdersOwnProps;

class SILEdOrders extends PureComponent<ISILEdOrdersProps, ISILEdOrdersState> {
    tooltip: Tooltip | null | undefined;

    public constructor(props: ISILEdOrdersProps) {
        super(props);
        this.state = {
            view: "All",
            versionPopup: false,
            productPopup: false,
            orderNumber: undefined,
            orderNumberTooltip: undefined,
            commentsTooltip: undefined,
        };
    }

    public componentDidMount(): void {
        this.getDataForTable();
        this.callServer();
    };

    public componentDidUpdate(prevProps: ISILEdOrdersProps): void {
        if (this.props !== prevProps) {
            this.callServer();
        };
    };


    public render(): ReactNode {

        const { view, versionPopup, productPopup } = this.state;
        const { getSILEdOrders, getToken, getProducts, getVersionHistory } = this.props;
        const getRole = hasPayload(getToken) && getToken.payload.response.simosAccess.access;
        const data = hasPayload(getSILEdOrders) ? getSILEdOrders.payload.response : [];
        const filterData = (view === "All") ? data : data.filter((x) => x.location === view && (x.status==='Assigned' || x.status ==='Initiated' || x.status==='Ready for pickup' || x.status === 'Loading'));

        return (
            <PageSpacing title="SIMOS - Orders" description="SIMOS - Orders" fixedSpaceOnSmallerScreens={true}>
                {(getRole !== NotApplicable) &&
                    <SILEdOrdersStyles>

                        <div className="hide-on-phone">
                            <SimosSubHeader
                                {...this.props}
                            />
                        </div>

                        <div className="show-on-phone">
                            <SimosSubHeaderMobile
                                {...this.props}
                            />
                        </div>

                        <LAMaroonPaper>
                            <LAGrid>
                                <LAGridItem xs={12}>
                                    <LAButton
                                        className="pull-left"
                                        startIcon={<AddIcon color={WHITE_COLOR} />}
                                        label="Add"
                                        // color={RED_COLOR}
                                        onClick={this.handleAdd}
                                    />
                                    <br></br>
                                    <h2 className="text-center white-color">SIL ED Orders</h2>
                                    <hr />

                                    {getSILEdOrders.kind === STATUS_ENUM.LOADING && <LALoading className="loading" message="Loading..." />}
                                    {getSILEdOrders.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load Orders..." />}

                                    {getSILEdOrders.kind === STATUS_ENUM.SUCCEEDED &&
                                        <LAGrid>
                                            <LAGridItem xs={12} sm={8} md={3}>
                                                <LAPaperWithLessPadding>
                                                    <RadioGroup className="view-btn" row aria-label="" name="radioGroup" value={view} onChange={this.onViewClick}>
                                                        <FormControlLabel value="All" control={<Radio size="small" />} label="All" />
                                                        <FormControlLabel value="SIL ED Warehouse" control={<Radio size="small" />} label="SIL Ed Warehouse" />
                                                        <FormControlLabel value="Bulk" control={<Radio size="small" />} label="Bulk" />
                                                        <LAIconButton label="Export" icon={<DownloadIcon />} onClick={this.handleExport} />
                                                    </RadioGroup>
                                                </LAPaperWithLessPadding>
                                            </LAGridItem>

                                            <LAGridItem xs={12} className="text-center">
                                                <LADevExtremeGrid
                                                    data={filterData}
                                                    onEdit={this.onEdit}
                                                    onClick={this.onClick}
                                                    height={1500}
                                                    export={true}
                                                    searchPanel={true}
                                                    actionWidth={120}
                                                    filterHeader={true}
                                                    id="SILEdOrdersList"
                                                    exportFileName="SILEdOrders"
                                                    onCustomClick={this.customClick}
                                                    onRowPrepared={this.rowPrepSILEdOrdersPage}
                                                    columnsHiding={true}
                                                    storageKey="SILEdOrdersStorageKey"
                                                    columns={[
                                                        { name: "id", caption: "Order #", type: "number", sortDesc: true, width: 80 },
                                                        { name: "pdf", caption: "Batch Summary", type: "button" },
                                                        { name: "customer", caption: "Customer", type: "string" },
                                                        { name: "pO_Number", caption: "PO #", type: "string" },
                                                        { name: "pickup_Datetime", caption: "Schedule Pickup Date", type: "datetime", width: 120 },
                                                        { name: "delivery_Datetime", caption: "Delivery Date", type: "datetime", width: 120 },
                                                        { name: "transport_Company", caption: "Transport Company", type: "string" },
                                                        { name: "products", caption: "Products", type: "string",
                                                            renderCell: (e:any) => {
                                                                if (e.data) {
                                                                    const find = e.data["products"];
                                                                    const onClick = (): void => find &&  this.customClick("products", e) ;
                                                                    return <div>
                                                                        {find ? <div className="link-btn" onClick={onClick}>{find}</div> : ""}
                                                                    </div>
                                                                }
                                                            } 
                                                        },
                                                        { name: "status", caption: "Status", type: "string" },
                                                        { name: "comments", caption: "Comments", type: "string", width: 100,
                                                            renderCell: (e:any) => {
                                                                if (e.data) {
                                                                    const find = e.data["comments"];
                                                                    return <div>
                                                                        {find ? <div className="link-btn" onMouseOver={(event:any) => this.showTooltipForComments("mouseOver", event,e.data)} onMouseOut={(event:any) => this.showTooltipForComments("mouseOut",event,e.data)}>Comments</div> : ""}
                                                                    </div>
                                                                }
                                                            } 
                                                        },
                                                        { name: "history", caption: "Version History", type: "button" },
                                                        { name: "created", caption: "Created", type: "datetime", width: 120 },
                                                        { name: "created_By", caption: "Created By", type: "string", width: 120 },
                                                        { name: "modified", caption: "Modified", type: "datetime", show: false },
                                                        { name: "modified_By", caption: "Modified By", type: "string", show: false }
                                                    ]}
                                                />
                                                <Tooltip ref={ref => this.tooltip = ref} position={'top'}>
                                                    <div style={{ maxWidth: "600px", height: "auto", wordWrap: "break-word", overflowWrap: "break-word" , whiteSpace: "normal" }}>
                                                        <div><b>{this.state.orderNumberTooltip && `Order# : ${this.state.orderNumberTooltip}`}</b></div>
                                                        <div><b>{this.state.commentsTooltip}</b></div>
                                                    </div>
                                                </Tooltip>
                                            </LAGridItem>
                                        </LAGrid>
                                    }
                                </LAGridItem>
                            </LAGrid>

                            {versionPopup && <SIMOSOrderVersionHistoryPopup
                                data={getVersionHistory}
                                onClose={this.closePopUp}
                                orderNumber={this.state.orderNumber}
                            />}

                            {productPopup && <SIMOSProductsPopup
                                data={getProducts}
                                onClose={this.closePopUp}
                                orderNumber={this.state.orderNumber}
                            />}
                        </LAMaroonPaper>
                    </SILEdOrdersStyles>
                }
            </PageSpacing>
        );
    }

    private rowPrepSILEdOrdersPage = (e: any) => {
        // console.log(e)
        if(e.data) {
            e.columns.map((item: any, index: number) => {

                if(item.caption === "Status") {
                    if(e.data.status === "Loading") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = LIGHT_BLUE_COLOR;
                    }
                    if(e.data.status === "Completed") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = LIGHT_GREEN_COLOR;
                    }
                    if(e.data.status === "Ready for pickup") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = LIGHT_ORANGE_YELLOW_COLOR;
                    }
                    if(e.data.status === "Cancelled") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = LIGHT_RED_COLOR;
                    }
                }
            
            });
        }
    }

    private showTooltipForComments = (name: string, e:any, data:any) => {
        // console.log(e)
        if(name === "mouseOver") {
            if(data) {
                this.setState({
                    commentsTooltip: data.comments,
                    orderNumberTooltip: data.id
                });
                if(data.comments)
                    this.tooltip && this.tooltip.instance.show(e.target);
            }
        }
        else if(name === "mouseOut") {
            this.tooltip && this.tooltip.instance.hide();
            this.setState({
                commentsTooltip: undefined,
                orderNumberTooltip: undefined
            });
        }
    }

    private handleExport = (): void => {
        if (hasPayload(this.props.getToken)) {
            let view = this.state.view;

            if (view === "Bulk") {
                view = view.toLocaleLowerCase();
            } else if(view === "All"){
                view = "none"
            } else {
                view = "sil_ed_warehouse";
            };

            downloadOrdersExcel(view, "sil_ed", this.props.getToken.payload.response.token);
        };
    };

    private closePopUp = (): void => {
        this.setState({
            versionPopup: false,
            productPopup: false,
            orderNumber: undefined
        });
    };

    private customClick = (name: string, e: any): void => {
        if (hasPayload(this.props.getToken)) {
            const id = e.row.data.id;
            const token = this.props.getToken.payload.response.token;

            if (name === "pdf") {
                downloadSIMOSOrderTicketPDF(id, token);
            } else if (name === "products") {
                this.props.getProductsRequest({
                    token,
                    request: {
                        Order_ID: id
                    }
                });

                this.setState({
                    productPopup: true,
                    orderNumber: e.row.data.id
                });
            } else {
                this.props.getVersionHistoryRequest({
                    token,
                    id
                });

                this.setState({
                    versionPopup: true,
                    orderNumber: e.row.data.id
                });
            }
        }
    };

    private onViewClick = (event: unknown, view: string): void => {
        this.setState({ view });
    };

    private onEdit = (e: any): void => {
        const query = queryString.parse(this.props.location.search);
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString(), page: query.page ? query.page.toString() : "" },
            pathName: ROUTE.SIMOS.SIL_Ed_Orders.ADD_UPDATE
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        const query = queryString.parse(this.props.location.search);
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0", page: query.page ? query.page.toString() : "" },
            pathName: ROUTE.SIMOS.SIL_Ed_Orders.ADD_UPDATE
        });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });

        if (hasPayload(this.props.getToken)) {
            if (this.props.getToken.payload.response.simosAccess.access !== NotApplicable) {
                if (isNotLoaded(this.props.getSILEdOrders)) {
                    this.getDataForTable();
                };
            } else {
                this.props.history.push({
                    pathname: ROUTE.SIMOS.INDEX,
                });
            };
        };
    }

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken)) {
            this.props.getRequest({
                token: this.props.getToken.payload.response.token,
                request: {
                    Customer: "orderdesk",
                    Warehouse_Type: "SIL ED Warehouse"
                }
            });
        };
    };

}

const mapStateToProps = (state: IStore): ISILEdOrdersStoreProps => ({
    getToken: getTokenStatus(state),
    getSILEdOrders: getOrdersStatus(state),
    getProducts: getProductsById(state),
    getVersionHistory: getOrderVersionHistory(state),
});

const mapDispatchToProps = (dispatch: IDispatch): ISILEdOrdersDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getRequest: (data: IGetOrdersRequest) => dispatch(getOrdersLoadAction(data)),
    getProductsRequest: (data: IGetProductsByIdRequest) => dispatch(getProductsByIdLoadAction(data)),
    getVersionHistoryRequest: (data: IGetOrderVersionHistoryRequest) => dispatch(getOrderVersionHistoryLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SILEdOrders);