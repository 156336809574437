import { ROUTE } from "../routes";
import styled from "styled-components";
import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { LAMenuItem } from "../shared/dropDownMenu";
import { DARK_GREY_COLOR, MAIN_COLOR, RED_COLOR } from "../shared/theme";


const CVHeaderStyles = styled.div`
    .cv-page {
        background-color: ${MAIN_COLOR};
        width: 100%;
        display: flex;
        align-items: center;
        height: 50px;
    };

    span {
        color: ${RED_COLOR};
    };

    .current {
        background-color: ${DARK_GREY_COLOR};
    };
`;


type ICVHeaderProps =
    RouteComponentProps;

class CVHeader extends PureComponent<ICVHeaderProps> {

    public constructor(props: ICVHeaderProps) {
        super(props);
        this.state = {
        };
    }

    public render(): ReactNode {
        const path = this.props.location.pathname;
        return (
            <CVHeaderStyles>
                <div className="cv-page">

                <LAMenuItem onClick={this.onHome}>
                        <span className="">
                        Home
                        </span>
                    </LAMenuItem>

                    <LAMenuItem onClick={this.onBeltScales} className={(path === ROUTE.SIL.CV.BELT_SCALES.INDEX) ? "current": ""}>
                        <span className="">
                        Belt Scales
                        </span>
                    </LAMenuItem>

                    <LAMenuItem onClick={this.onCranes} className={(path === ROUTE.SIL.CV.CRANES.INDEX) ? "current": ""}>
                        <span>
                        Cranes
                        </span>
                    </LAMenuItem>

                    <LAMenuItem onClick={this.onLabScales} className={(path === ROUTE.SIL.CV.LAB_SCALES.INDEX) ? "current": ""}>
                        <span className="">
                        Lab Scales
                        </span>
                    </LAMenuItem>

                    <LAMenuItem onClick={this.onNCR} className={(path === ROUTE.SIL.CV.NCR.INDEX) ? "current": ""}>
                        <span className="">
                        NCR
                        </span>
                    </LAMenuItem>

                    <LAMenuItem onClick={this.onTruckScales} className={(path === ROUTE.SIL.CV.TRUCK_SCALES.INDEX) ? "current": ""}>
                        <span className="">
                        Truck Scales
                        </span>
                    </LAMenuItem>

                    <LAMenuItem onClick={this.onWarehouses} className={(path === ROUTE.SIL.CV.WAREHOUSES.INDEX) ? "current": ""}>
                        <span className="">
                        Warehouses
                        </span>
                    </LAMenuItem>

                    <LAMenuItem onClick={this.onSieveDryPlant} className={(path === ROUTE.SIL.CV.SIEVE_DRY_PLANTS.INDEX) ? "current": ""}>
                        <span className="">
                        Sieve Dry Plant
                        </span>
                    </LAMenuItem>

                    <LAMenuItem onClick={this.onSieveFracBall} className={(path === ROUTE.SIL.CV.SIEVE_FRAC_BALLS.INDEX) ? "current": ""}>
                        <span className="">
                        Sieve Frac Ball
                        </span>
                    </LAMenuItem>

                    <LAMenuItem onClick={this.onSieveWashPlant} className={(path === ROUTE.SIL.CV.SIEVE_WASH_PLANTS.INDEX) ? "current": ""}>
                        <span className="">
                        Sieve Wash Plant
                        </span>
                    </LAMenuItem>

                </div>
            </CVHeaderStyles>
        );
    }

    private onHome = (): void => {
        this.props.history.push(ROUTE.SIL.INDEX);
    };

    private onBeltScales = (): void => {
        this.props.history.push(ROUTE.SIL.CV.BELT_SCALES.INDEX);
    };

    private onCranes = (): void => {
        this.props.history.push(ROUTE.SIL.CV.CRANES.INDEX);
    };

    private onLabScales = (): void => {
        this.props.history.push(ROUTE.SIL.CV.LAB_SCALES.INDEX);
    };

    private onNCR = (): void => {
        this.props.history.push(ROUTE.SIL.CV.NCR.INDEX);
    };

    private onTruckScales = (): void => {
        this.props.history.push(ROUTE.SIL.CV.TRUCK_SCALES.INDEX);
    };

    private onWarehouses = (): void => {
        this.props.history.push(ROUTE.SIL.CV.WAREHOUSES.INDEX);
    };

    private onSieveDryPlant = (): void => {
        this.props.history.push(ROUTE.SIL.CV.SIEVE_DRY_PLANTS.INDEX);
    };

    private onSieveWashPlant = (): void => {
        this.props.history.push(ROUTE.SIL.CV.SIEVE_WASH_PLANTS.INDEX);
    };

    private onSieveFracBall = (): void => {
        this.props.history.push(ROUTE.SIL.CV.SIEVE_FRAC_BALLS.INDEX);
    };

}

export default (CVHeader);