import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, isSucceeded, Server } from "../../../../redux/server";
import { ById, SilAPIResponse } from "../../../shared/publicInterfaces";
import { userName, undefinedFunction } from "../../../shared/constExports";
import { LAButton, LASaveAndCancelButton } from "../../../shared/buttons";
import { ArrowLeftIcon } from "../../../shared/icons";
import { RED_COLOR, WARNING_COLOR, WHITE_COLOR } from "../../../shared/theme";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import LATextField from "../../../shared/textField";
import LAAutoComplete from "../../../shared/autoComplete";
import { ROUTE } from "../../../routes";
import queryString from "query-string";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import { FieldValidator, FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../../shared/fieldValidator";
import PageSpacing from "../../../shared/pageSpacing";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import LATextArea from "../../../shared/textArea";
import { LADateTimePicker } from "../../../shared/dateTimePicker";
import SilRoleCheck from "../../../shared/silRoleCheck";
import { IAddSieveCheckMeasurementRequest, IAddSieveCheckRequest } from "../../../../redux/sil/sieves/addSieveCheck/addSieveCheckConstaints";
import { IUpdateSieveCheckRequest } from "../../../../redux/sil/sieves/updateSieveCheck/updateSieveCheckConstaints";
import { IGetSieveLookupsRequest, ISieveLookup } from "../../../../redux/sil/sieves/getSieveLookups/getSieveLookupsConstaints";
import { updateSieveCheckStatus } from "../../../../redux/sil/sieves/updateSieveCheck/updateSieveCheckAccessor";
import { getSieveLookupsStatus } from "../../../../redux/sil/sieves/getSieveLookups/getSieveLookupsAccessor";
import { addSieveCheckStatus } from "../../../../redux/sil/sieves/addSieveCheck/addSieveCheckAccessor";
import { addSieveCheckLoadAction } from "../../../../redux/sil/sieves/addSieveCheck/addSieveCheckActions";
import { updateSieveCheckLoadAction } from "../../../../redux/sil/sieves/updateSieveCheck/updateSieveCheckActions";
import { getSieveLookupsLoadAction } from "../../../../redux/sil/sieves/getSieveLookups/getSieveLookupsActions";
import { IGetSieveChecksRequest, ISieveCheck, ISieveCheckMeasurement } from "../../../../redux/sil/sieves/getSievesChecks/getSievesChecksConstaints";
import { getSieveChecksStatus } from "../../../../redux/sil/sieves/getSievesChecks/getSievesChecksAccessor";
import { getSieveChecksLoadAction } from "../../../../redux/sil/sieves/getSievesChecks/getSievesChecksActions";
import DataGrid, { Column, Editing, Lookup, Paging } from "devextreme-react/data-grid";


interface ISieveCheckStoreProps {
    addSieveCheck: Server<string>;
    updateSieveCheck: Server<string>;
    getToken: Server<SilAPIResponse<IToken>>;
    getSieveChecks: Server<SilAPIResponse<ById<ISieveCheck>>>;
    getSieveLookups: Server<SilAPIResponse<ISieveLookup>>;
};

interface ISieveCheckDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getSieveChecksRequest: (data: IGetSieveChecksRequest) => unknown;
    addSieveCheckRequest: (data: IAddSieveCheckRequest) => unknown;
    updateSieveCheckRequest: (data: IUpdateSieveCheckRequest) => unknown;
    getSieveLookupsRequest: (data: IGetSieveLookupsRequest) => unknown;
};


interface ISieveCheckOwnProps {

};

interface ISieveCheckState {
    sieve: ISieveCheck;
    currentTableView: string;
    errors: ById<IFieldErrorKeyValue>;
};

const SieveCheckStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;

    .dx-toolbar-after {
        display: none;
    }
`;

type ISieveCheckProps = RouteComponentProps
    & ISieveCheckStoreProps
    & ISieveCheckDispatchProps
    & ISieveCheckOwnProps;

class SieveCheck extends PureComponent<ISieveCheckProps, ISieveCheckState> {

    public constructor(props: ISieveCheckProps) {
        super(props);
        this.state = {
            errors: {
                "date": { key: "date", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "products": { key: "products", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "plant": { key: "plant", message: FIELD_VALIDATOR_ERRORS.REQUIRED }
            },
            sieve: {
                id: 0,
                date: "",
                operator: "",
                products: "",
                plant: "",
                notes: "",
                created: "",
                modified: "",
                created_By: userName,
                modified_By: userName,
                measurements: []
            },
            currentTableView: ""
        };
    }

    public componentDidMount(): void {

        if (hasPayload(this.props.getToken))
            this.props.getSieveLookupsRequest({
                token: this.props.getToken.payload.response.token,
                Requested_Page: "Checks"
            });

        this.callServer();
    };

    public componentDidUpdate(prevProps: ISieveCheckProps): void {
        if (this.props !== prevProps) {
            this.callServer();

            if (this.props.addSieveCheck !== prevProps.addSieveCheck) {
                if (isSucceeded(this.props.addSieveCheck)) {
                    this.handleCancel();
                };
            };

            if (this.props.updateSieveCheck !== prevProps.updateSieveCheck) {
                if (isSucceeded(this.props.updateSieveCheck)) {
                    this.handleCancel();
                };
            };
        };
    };


    public render(): ReactNode {

        const { sieve, errors, currentTableView } = this.state;
        const { updateSieveCheck, addSieveCheck, getSieveLookups, getToken } = this.props;
        const lookUps = hasPayload(getSieveLookups) ? getSieveLookups.payload.response : { plants: [], products: [], mesh: [], screens: [] };
        const plants = convertList(lookUps.plants);
        const filteredProduct = this.filterProduct(lookUps);
        const products = convertList(filteredProduct);
        const selectedProducts = sieve.products ? sieve.products.split(",") : [];
        const sieveAccess = hasPayload(getToken) ? getToken.payload.response.sieveAccess : false;

        return (
            <PageSpacing title="SIL - Sieve Check" description="Sieve Check" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="sieveAccess">
                    <SieveCheckStyles>

                        <LAButton startIcon={<ArrowLeftIcon color={WHITE_COLOR} />} label="Back to list" onClick={this.handleCancel} />
                        <h2 className="text-center">{sieve ? (sieve.id > 0 ? "VIEW/UPDATE " : "ADD ") : "VIEW "} SIEVE CHECK</h2>
                        <hr />

                        <LAPaperWithPadding>
                            <LAGrid spacing={3}>

                                <LAGridItem xs={6}>
                                    <LADateTimePicker
                                        name="date"
                                        label="Date"
                                        fullWidth={true}
                                        value={sieve.date}
                                        onChange={this.handleDate}
                                        disabled={sieveAccess ? undefined : true}
                                        errorText={errors && errors["date"] ? errors["date"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={6}>
                                    <LATextField
                                        varient="outlined"
                                        label="Operator"
                                        fullWidth={true}
                                        name="operator"
                                        onChange={this.onChange}
                                        value={sieve.operator ?? ""}
                                        disabled={sieveAccess ? undefined : true}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={6}>
                                    <LAAutoComplete
                                        filterSelectedOptions={true}
                                        autoHighlight={true}
                                        selectionRemove={undefinedFunction}
                                        dropDownPlaceHolder="Plant"
                                        getOptionLabel="name"
                                        name="plant"
                                        option={plants}
                                        multiple={false}
                                        onChange={this.handleDropDownChange}
                                        disabled={sieveAccess ? (sieve.id > 0 ? true : undefined) : true}
                                        errorText={errors && errors["plant"] ? errors["plant"].message : undefined}
                                        value={hasPayload(getSieveLookups) && sieve.plant ? plants.find(x => x.name === sieve.plant) : ""}
                                        defaultValue={hasPayload(getSieveLookups) && sieve.plant ? plants.find(x => x.name === sieve.plant) : ""}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={6}>
                                    <LAAutoComplete
                                        name="products"
                                        multiple={true}
                                        option={products}
                                        autoHighlight={true}
                                        getOptionLabel="name"
                                        filterSelectedOptions={true}
                                        dropDownPlaceHolder="Products"
                                        onChange={this.handleProductSelection}
                                        disabled={sieveAccess ? undefined : true}
                                        selectionRemove={this.handleProductRemove}
                                        errorText={errors["products"] ? errors["products"].message : undefined}
                                        value={(hasPayload(getSieveLookups) && products && (selectedProducts.length > 0) ? filterProductList(products, selectedProducts) : [])}
                                        defaultValue={(hasPayload(getSieveLookups) && products && (selectedProducts.length > 0) ? filterProductList(products, selectedProducts) : [])}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={6}>
                                    <LATextArea
                                        varient="outlined"
                                        label="Notes"
                                        fullWidth={true}
                                        name="notes"
                                        minRows={3}
                                        rowsMax={5}
                                        onChange={this.onChange}
                                        value={sieve.notes ?? ""}
                                        disabled={sieveAccess ? undefined : true}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12}>
                                    <LAGrid spacing={0}>

                                        <LAGridItem xs={12}>
                                            {selectedProducts.map((q, index) => {
                                                const onProductTableView = () => this.handleProductTableView(q);
                                                return (
                                                    <LAButton
                                                        label={q}
                                                        key={index}
                                                        className="mr-2"
                                                        onClick={onProductTableView}
                                                        color={(currentTableView === q) ? RED_COLOR : WARNING_COLOR}
                                                    />
                                                );
                                            })}
                                        </LAGridItem>

                                    </LAGrid>
                                </LAGridItem>

                                <LAGridItem xs={12}>
                                    <DataGrid
                                        keyExpr="id"
                                        showBorders={true}
                                        columnAutoWidth={true}
                                        dataSource={sieve.measurements}
                                    >
                                        <Paging enabled={false} />
                                        <Editing
                                            mode="batch"
                                            onChangesChange={this.onChangesChange}
                                            allowUpdating={sieveAccess ? true : false}
                                        />

                                        <Column filterValue={currentTableView} dataField="product" caption="Product" allowEditing={false} dataType="string" />
                                        <Column dataField="sieve_Serial_No" caption="Sieve Serial No" dataType="number" />
                                        <Column dataField="astM_Serial_No" caption="ASTM Serial No" allowEditing={false} dataType="string" />
                                        <Column dataField="result" caption="Results" width={200}>
                                            <Lookup dataSource={resultDropDown} displayExpr="name" valueExpr="name" />
                                        </Column>
                                        <Column width={800} dataField="notes" caption="Notes" dataType="string" cssClass="note" />
                                    </DataGrid>

                                </LAGridItem>


                                <LAGridItem xs={12}>
                                    <LASaveAndCancelButton
                                        onSave={this.onSave}
                                        cancelButtonText="Close"
                                        onCancel={this.handleCancel}
                                        disableSave={sieveAccess ? (Object.values(errors).length > 0 ? true : undefined) : true}
                                    />
                                </LAGridItem>

                            </LAGrid>
                        </LAPaperWithPadding>

                        <RequestStatus requestStatus={addSieveCheck.kind} successMessage="Sieve Check successfully saved" />
                        <RequestStatus requestStatus={updateSieveCheck.kind} successMessage="Sieve Check successfully updated" />
                    </SieveCheckStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private onChangesChange = (updatedData: { data: ISieveCheckMeasurement, key: number, type: string }[]): void => {
        const iS = [...this.state.sieve.measurements];
        
        updatedData.forEach((x: { data: ISieveCheckMeasurement, key: number, type: string }) => {
            const index = iS.findIndex((q) => q.id === x.key);
            let record = { ...iS[index] };

            record = { ...record, ...x.data };
            iS[index] = record;
        });

        this.setState({ 
            ...this.state,
            sieve: {
                ...this.state.sieve,
                measurements: iS
            }
         });
    };

    private handleProductTableView = (currentTableView: string): void => {
        this.setState({ currentTableView });
    };

    private handleProductRemove = (value: string, index: number): void => {

        const { sieve } = this.state;

        let products = "";
        let selected = sieve.products.split(",");
        let currentMesh = sieve.measurements.filter(x => x.product !== value);

        selected.forEach(x => {
            if (x !== "" && x !== value) {
                if (products.length === 0) {
                    products = x;
                } else {
                    products = products + "," + x;
                }
            }

        });

        this.setState({
            ...this.state,
            sieve: {
                ...this.state.sieve,
                products,
                measurements: currentMesh
            }
        });
    };

    private filterProduct = (data: ISieveLookup): string[] => {
        const res: string[] = [];
        const plant = this.state.sieve.plant;

        for (let i in data.products) {
            if (data.products[i].plant === plant)
                res.push(data.products[i].product);
        };

        return res;
    };

    private handleDate = (name: string, date: string): void => {
        let errors = this.state.errors;
        errors = this.errorChecker(name, date, errors, true);

        this.setState({
            ...this.state,
            errors,
            sieve: {
                ...this.state.sieve,
                [name]: date
            }
        });
    };


    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, isRequired: boolean): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: isRequired ? true : undefined, minLength: 1, decimal: undefined });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        };
        return err;
    };

    private handleCancel = (): void => {
        this.props.history.push(ROUTE.SIL.SIEVES.SIEVES_CHECKS);
    };

    private onSave = async (): Promise<void> => {
        const { sieve } = this.state;

        const measurements = [...sieve.measurements];

        if (hasPayload(this.props.getToken)) {

            const meas: IAddSieveCheckMeasurementRequest[] = [];

            measurements
                .forEach((x) => {

                    meas.push({
                        ID: x.id,
                        Sieve_Serial_No: x.sieve_Serial_No,
                        ASTM_Serial_No: x.astM_Serial_No.toString(),
                        Product: x.product,
                        Result: x.result,
                        Notes: x.notes
                    });

                });
            console.log(meas)
            if (sieve.id === 0) {
                this.props.addSieveCheckRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        ID: sieve.id,
                        Date: sieve.date,
                        Operator: sieve.operator,
                        Plant: sieve.plant,
                        Products: sieve.products,
                        Notes: sieve.notes,
                        Modified_By: sieve.modified_By,
                        Created_By: sieve.created_By,
                        Measurements: meas
                    }
                });
            } else {
                let data = this.state.sieve;
                data.modified_By = userName;
                this.props.updateSieveCheckRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        ID: sieve.id,
                        Date: sieve.date,
                        Operator: sieve.operator,
                        Plant: sieve.plant,
                        Products: sieve.products,
                        Notes: sieve.notes,
                        Created_By: sieve.created_By,
                        Modified_By: sieve.modified_By,
                        Measurements: meas
                    }
                });
            };
        };

        this.setState({
            ...this.state,
            sieve: {
                ...this.state.sieve,
                measurements
            }
        });

    };

    private handleDropDownChange = (event: unknown, value: { id: number, name: string } | "", name?: string): void => {
        if (name) {
            let errors = this.state.errors;
            errors = this.errorChecker(name, value !== "" ? value.name : "", errors, true);

            this.setState({
                ...this.state,
                errors,
                sieve: {
                    ...this.state.sieve,
                    [name]: value !== "" ? value.name : ""
                }
            });
        };
    };

    private handleProductSelection = (event: unknown, values: { id: number, name: string }[]): void => {
        let products = "";

        const newMesh: ISieveCheckMeasurement[] = [];

        if (hasPayload(this.props.getSieveLookups)) {

            const { created_By, modified_By } = this.state.sieve;
            const { mesh } = this.props.getSieveLookups.payload.response;

            values.forEach(x => {
                if (x.name !== "") {
                    if (products.length === 0) {
                        products = x.name;
                    } else {
                        products = products + "," + x.name;
                    }
                    const index = mesh.findIndex((y) => y.product === x.name);
                    const array = mesh[index].mesh.split(",");

                    array.forEach((y, ind) => {
                        newMesh.push({
                            id: newMesh.length + 1,
                            listID: newMesh.length + 1,
                            product: x.name,
                            sieve_Serial_No: "",
                            astM_Serial_No: y,
                            result: "",
                            notes: "",
                            created: "",
                            modified: "",
                            created_By: created_By,
                            modified_By: modified_By
                        });
                    });
                }
            });

            let errors = this.state.errors;
            errors = this.errorChecker("products", products, errors, true);

            this.setState({
                ...this.state,
                errors,
                currentTableView: newMesh[0].product,
                sieve: {
                    ...this.state.sieve,
                    products,
                    measurements: newMesh
                }
            });
        };

    };


    private onChange = (name: string, value: string): void => {
        let errors = this.state.errors;
        errors = this.errorChecker(name, value, errors, true);

        this.setState({
            ...this.state,
            errors,
            sieve: {
                ...this.state.sieve,
                [name]: value
            }
        });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getSieveLookups))
            this.props.getSieveLookupsRequest({
                token: this.props.getToken.payload.response.token,
                Requested_Page: "Checks"
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getSieveChecks))
            this.props.getSieveChecksRequest({
                token: this.props.getToken.payload.response.token
            });


        const query = queryString.parse(this.props.location.search);
        const id = query.id ?? "0";

        if ((id !== "0") && (hasPayload(this.props.getSieveChecks)) && (this.state.sieve.id === 0)) {
            const sieve = this.props.getSieveChecks.payload.response[id.toString()];

            if (sieve)
                this.setState({ sieve, currentTableView: sieve.measurements[0].product, errors: {} });
        };

    };

}

const mapStateToProps = (state: IStore): ISieveCheckStoreProps => ({
    getToken: getTokenStatus(state),
    getSieveChecks: getSieveChecksStatus(state),
    updateSieveCheck: updateSieveCheckStatus(state),
    getSieveLookups: getSieveLookupsStatus(state),
    addSieveCheck: addSieveCheckStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): ISieveCheckDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getSieveChecksRequest: (data: IGetSieveChecksRequest) => dispatch(getSieveChecksLoadAction(data)),
    addSieveCheckRequest: (data: IAddSieveCheckRequest) => dispatch(addSieveCheckLoadAction(data)),
    updateSieveCheckRequest: (data: IUpdateSieveCheckRequest) => dispatch(updateSieveCheckLoadAction(data)),
    getSieveLookupsRequest: (data: IGetSieveLookupsRequest) => dispatch(getSieveLookupsLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(SieveCheck);


interface IConvertList {
    id: string;
    name: string;
};

const convertList = (list: any[]): IConvertList[] => {
    let response = [];
    for (let i = 0; i < list.length; i++) {
        response.push({ id: list[i], name: list[i] });
    };
    return response;
};

const filterProductList = (available: IConvertList[], selected: string[]): IConvertList[] => {
    let response: IConvertList[] = [];
    available.forEach(x => {
        if (selected.includes(x.name))
            response.push(x);
    });
    return response;
};

const resultDropDown = [
    { name: "Pass" },
    { name: "Fail" },
    { name: "Need Attention" }
];