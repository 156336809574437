import { IFlushDataSuccessAction } from "../../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../../flushData.tsx/flushDataConstants";
import { Server, failed, loading, notLoaded, succeeded } from "../../../../server";
import { IUpdateSieveDryPlantLoadAction, IUpdateSieveDryPlantLoadFailedAction, IUpdateSieveDryPlantSuccessAction } from "./updateSieveDryPlantActions";
import { IUPDATE_SIEVE_DRY_PLANT_REQUEST } from "./updateSieveDryPlantConstaints";


type Actions =
    IUpdateSieveDryPlantLoadAction
    | IUpdateSieveDryPlantLoadFailedAction
    | IUpdateSieveDryPlantSuccessAction
    | IFlushDataSuccessAction;

export const UpdateSieveDryPlantReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IUPDATE_SIEVE_DRY_PLANT_REQUEST.REQUEST:
            return loading;

        case IUPDATE_SIEVE_DRY_PLANT_REQUEST.SUCCESS:
            return succeeded("Success");

        case IUPDATE_SIEVE_DRY_PLANT_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};