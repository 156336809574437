import { IUPDATE_SIMOS_CUSTOMER_CONTACT_REQUEST, IUpdateSimosCustomerContactRequest } from "./updateSimosCustomerContactConstaints";

export interface IUpdateSimosCustomerContactLoadAction {
    type: IUPDATE_SIMOS_CUSTOMER_CONTACT_REQUEST.REQUEST;
    data: IUpdateSimosCustomerContactRequest
}
export const updateSimosCustomerContactLoadAction = (data: IUpdateSimosCustomerContactRequest): IUpdateSimosCustomerContactLoadAction => ({
    type: IUPDATE_SIMOS_CUSTOMER_CONTACT_REQUEST.REQUEST,
    data
});

export interface IUpdateSimosCustomerContactSuccessAction {
    type: IUPDATE_SIMOS_CUSTOMER_CONTACT_REQUEST.SUCCESS;
    message: string;
}
export const updateSimosCustomerContactLoadSuccessAction = (message: string): IUpdateSimosCustomerContactSuccessAction => ({
    type: IUPDATE_SIMOS_CUSTOMER_CONTACT_REQUEST.SUCCESS,
    message
});

export interface IUpdateSimosCustomerContactLoadFailedAction {
    type: IUPDATE_SIMOS_CUSTOMER_CONTACT_REQUEST.FAILED;
    message: string;
}
export const updateSimosCustomerContactLoadFailedAction = (message: string): IUpdateSimosCustomerContactLoadFailedAction => ({
    type: IUPDATE_SIMOS_CUSTOMER_CONTACT_REQUEST.FAILED,
    message
});
