import { ITruckScale } from "../getTruckScales/getTruckScalesConstaints";

export enum IUPDATE_TRUCK_SCALE_REQUEST {
    REQUEST = "updateTruckScale/UPDATE_TRUCK_SCALE_REQUEST",
    SUCCESS = "updateTruckScale/UPDATE_TRUCK_SCALE_SUCCESS",
    FAILED = "updateTruckScale/UPDATE_TRUCK_SCALE_FAILED"
};

export interface IUpdateTruckScaleRequest {
    token: string;
    request: ITruckScale;
};