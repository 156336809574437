import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../../shared/paper";
import { IDispatch, IStore } from "../../../../../redux/reducers";
import { hasPayload, isNotLoaded, isSucceeded, Server } from "../../../../../redux/server";
import { ById, YHDAPIResponse } from "../../../../shared/publicInterfaces";
import { userName, undefinedFunction, methodOfNotification, infractionStatus, findInfractionNotification, deleteText } from "../../../../shared/constExports";
import { LAButton, LAIconButton, LASaveAndCancelButton } from "../../../../shared/buttons";
import { ArrowLeftIcon, DeleteIcon } from "../../../../shared/icons";
import { DRY_PLANT_FORM_TILE_COLOR, WHITE_COLOR } from "../../../../shared/theme";
import LAGrid from "../../../../shared/grid";
import LAGridItem from "../../../../shared/gridList";
import LATextField from "../../../../shared/textField";
import LAAutoComplete from "../../../../shared/autoComplete";
import { ROUTE } from "../../../../routes";
import queryString from "query-string";
import { updateYHDCarrierInfractionLoadAction } from "../../../../../redux/propsense/yhd/carrier/updateCarrierInfraction/updateCarrierInfractionActions";
import { IUpdateYHDCarrierInfractionRequest } from "../../../../../redux/propsense/yhd/carrier/updateCarrierInfraction/updateCarrierInfractionConstaints";
import { IAddYHDCarrierInfractionRequest } from "../../../../../redux/propsense/yhd/carrier/addCarrierInfraction/addCarrierInfractionConstaints";
import RequestStatus from "../../../../shared/requestStatusSnackbar";
import { FieldValidator, FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../../../shared/fieldValidator";
import PageSpacing from "../../../../shared/pageSpacing";
import { IGetTokenRequest, IToken } from "../../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenStatus } from "../../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { getTokenLoadAction } from "../../../../../redux/sil/dryPlant/token/getTokenActions";
import { END_POINTS } from "../../../../../redux/endpoints";
import { getYHDCarrierLookupsStatus } from "../../../../../redux/propsense/yhd/carrier/getCarrierLookups/getCarrierLookupsAccessor";
import { getYHDCarrierLookupsLoadAction } from "../../../../../redux/propsense/yhd/carrier/getCarrierLookups/getCarrierLookupsActions";
import { IYHDCarrierLookup, IGetYHDCarrierLookupsRequest } from "../../../../../redux/propsense/yhd/carrier/getCarrierLookups/getCarrierLookupsConstaints";
import SilRoleCheck from "../../../../shared/silRoleCheck";
import { LADropDone } from "../../../../shared/dragDropFiles";
import LALinkButton from "../../../../shared/linkButton";
import { getYHDAddCarrierInfractionStatus } from "../../../../../redux/propsense/yhd/carrier/addCarrierInfraction/addCarrierInfractionAccessor";
import { getYHDUpdateCarrierInfractionStatus } from "../../../../../redux/propsense/yhd/carrier/updateCarrierInfraction/updateCarrierInfractionAccessor";
import { addYHDCarrierInfractionLoadAction } from "../../../../../redux/propsense/yhd/carrier/addCarrierInfraction/addCarrierInfractionActions";
import { LADateTimePicker } from "../../../../shared/dateTimePicker";
import { IGetYHDCarrierInfractionsRequest, IYHDCarrierInfractions } from "../../../../../redux/propsense/yhd/carrier/getCarrierInfractions/getCarrierInfractionsConstaints";
import LATextArea from "../../../../shared/textArea";
import { LAExpansionPanel } from "../../../../shared/expansionPanel";
import { getYHDCarrierInfractionsLoadAction } from "../../../../../redux/propsense/yhd/carrier/getCarrierInfractions/getCarrierInfractionsActions";
import { getYHDCarrierInfractionsStatus } from "../../../../../redux/propsense/yhd/carrier/getCarrierInfractions/getCarrierInfractionsAccessor";

enum ICarrierInfractionSection {
    "Infraction" = 1,
    "Infraction Reported By" = 2
};

const requiredFields = ["date_Of_Infraction", "infraction_Notification_Method", "alternate_Name", "trailer_No", "location", "infraction", "description", "immediate_Action_Taken"];


interface ICarrierInfractionStoreProps {
    addCarrierInfraction: Server<string>;
    updateCarrierInfraction: Server<string>;
    getToken: Server<YHDAPIResponse<IToken>>;
    getCarrierInfractions: Server<YHDAPIResponse<IYHDCarrierInfractions[]>>;
    getCarrierLookups: Server<YHDAPIResponse<IYHDCarrierLookup[]>>;
};

interface ICarrierInfractionDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getCarrierInfractionsRequest: (data: IGetYHDCarrierInfractionsRequest) => unknown;
    addCarrierInfractionRequest: (data: IAddYHDCarrierInfractionRequest) => unknown;
    updateCarrierInfractionRequest: (data: IUpdateYHDCarrierInfractionRequest) => unknown;
    getCarrierLookupsRequest: (data: IGetYHDCarrierLookupsRequest) => unknown;
};


interface ICarrierInfractionOwnProps {

};

interface ICarrierInfractionState {
    expanded: number[];
    infraction: IYHDCarrierInfractions;
    carriers: IYHDCarrierLookup[];
    errors: ById<IFieldErrorKeyValue>;
    otherNotification: string;
};

const CarrierInfractionStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
`;

type ICarrierInfractionProps = RouteComponentProps
    & ICarrierInfractionStoreProps
    & ICarrierInfractionDispatchProps
    & ICarrierInfractionOwnProps;

class YHDCarrierInfraction extends PureComponent<ICarrierInfractionProps, ICarrierInfractionState> {

    public constructor(props: ICarrierInfractionProps) {
        super(props);
        this.state = {
            expanded: [1, 2],
            errors: {
                "date_Of_Infraction": { key: "date_Of_Infraction", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                // "alternate_Name": { key: "alternate_Name", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "trailer_No": { key: "trailer_No", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "location": { key: "location", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "infraction": { key: "infraction", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "description": { key: "description", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "immediate_Action_Taken": { key: "immediate_Action_Taken", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
            },
            infraction: {
                id: 0,
                date_Of_Infraction: "",
                infraction_Notification_Method: "Phone",
                alternate_Name: "",
                trailer_No: "",
                location: "",
                infraction: "",
                description: "",
                immediate_Action_Taken: "",
                status: "Pending",
                created: "",
                created_By: userName,
                modified: "",
                modified_By: userName
            },
            carriers: [],
            otherNotification: ""
        };
    }

    public componentDidMount(): void {

        if (hasPayload(this.props.getToken))
            this.props.getCarrierLookupsRequest({
                token: this.props.getToken.payload.response.token,
                requested_Page: "Carrier_Infractions"
            });

        this.callServer();
    };

    public componentDidUpdate(prevProps: ICarrierInfractionProps): void {

        if (this.props !== prevProps) {
            this.callServer();

            if (this.props.addCarrierInfraction !== prevProps.addCarrierInfraction) {
                if (isSucceeded(this.props.addCarrierInfraction)) {
                    this.handleCancel();
                };
            };

            if (this.props.updateCarrierInfraction !== prevProps.updateCarrierInfraction) {
                if (isSucceeded(this.props.updateCarrierInfraction)) {
                    this.handleCancel();
                };
            };
        };
    };


    public render(): ReactNode {

        const { infraction, errors, expanded, otherNotification } = this.state;
        const { updateCarrierInfraction, addCarrierInfraction, getCarrierLookups } = this.props;

        const handleInfraction = (): void => this.handleExpand(ICarrierInfractionSection.Infraction);
        const handleInfractionReported = (): void => this.handleExpand(ICarrierInfractionSection["Infraction Reported By"]);
        const truckRegistrationFile = (event?: any): void => this.uploadFile("attachment_File", event);
        const truckRegistrationFileDownload = (): void => this.downloadFile(infraction.attachment_File_Name, infraction.attachment_File, "Attachment_File");
        const names = hasPayload(getCarrierLookups) ? getCarrierLookups.payload.response : [];

        const notification: any = findInfractionNotification(infraction.infraction_Notification_Method);

        const isAdd = infraction.id > 0 ? false : true;

        return (
            <PageSpacing title="YHD - Carrier" description="Carrier Infraction" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="yhdCarrierAccess">
                    <CarrierInfractionStyles>

                        <LAButton startIcon={<ArrowLeftIcon color={WHITE_COLOR} />} label="Back to list" onClick={this.handleCancel} />
                        <h2 className="text-center">{!isAdd ? "VIEW " : "ADD "} CARRIER INFRACTION</h2>
                        <hr />


                        <LAGrid spacing={3}>
                            <LAGridItem xs={12}>

                                <LAExpansionPanel color={DRY_PLANT_FORM_TILE_COLOR} textColor={WHITE_COLOR} label="Infraction" expanded={expanded.includes(ICarrierInfractionSection.Infraction)} onClick={handleInfraction}>
                                    <LAGrid>

                                        <LAGridItem xs={6}>
                                            <LADateTimePicker
                                                fullWidth={true}
                                                name="date_Of_Infraction"
                                                onChange={this.onDateChange}
                                                label="Date of Infraction"
                                                value={infraction.date_Of_Infraction ?? ""}
                                                errorText={errors["date_Of_Infraction"] ? errors["date_Of_Infraction"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={6}>
                                        <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="Method of Infraction Notification"
                                                getOptionLabel="name"
                                                name="infraction_Notification_Method"
                                                option={methodOfNotification}
                                                multiple={false}
                                                onChange={this.handleNotificationDropDown}
                                                value={notification}
                                                defaultValue={notification}
                                            />

                                            {((notification.name) && (notification.name === methodOfNotification[3].name)) &&
                                                <>
                                                    <br />
                                                    <LATextArea
                                                        minRows={1}
                                                        rowsMax={1}
                                                        name="otherNotification"
                                                        fullWidth={true}
                                                        label="Other Notification"
                                                        value={otherNotification}
                                                        onChange={this.handleOtherNotification}
                                                    />
                                                </>}
                                        </LAGridItem>

                                        <LAGridItem xs={6}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="Alternate Name"
                                                getOptionLabel="name"
                                                name="alternate_Name"
                                                option={names}
                                                multiple={false}
                                                onChange={this.handleDropDownChange}
                                                value={names.find(x => x.name === infraction.alternate_Name)}
                                                defaultValue={names.find(x => x.name === infraction.alternate_Name)}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={6}>
                                            <LATextField
                                                varient="outlined"
                                                label="Trailer #"
                                                fullWidth={true}
                                                name="trailer_No"
                                                onChange={this.onChange}
                                                value={infraction.trailer_No}
                                                errorText={errors["trailer_No"] ? errors["trailer_No"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={6}>
                                            <LATextField
                                                varient="outlined"
                                                label="License Plate"
                                                fullWidth={true}
                                                name="license_Plate"
                                                onChange={this.onChange}
                                                value={infraction.license_Plate ?? ""}
                                                errorText={errors["license_Plate"] ? errors["license_Plate"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={6}>
                                            <LATextField
                                                varient="outlined"
                                                label="Location of Infraction"
                                                fullWidth={true}
                                                name="location"
                                                onChange={this.onChange}
                                                value={infraction.location}
                                                errorText={errors["location"] ? errors["location"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={6}>
                                            <LATextField
                                                varient="outlined"
                                                label="Ticket # (if applicable)"
                                                fullWidth={true}
                                                name="ticket_No"
                                                onChange={this.onChange}
                                                value={infraction.ticket_No ?? ""}
                                                errorText={errors["ticket_No"] ? errors["ticket_No"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={6}>
                                            <LATextField
                                                varient="outlined"
                                                label="Infraction"
                                                fullWidth={true}
                                                name="infraction"
                                                onChange={this.onChange}
                                                value={infraction.infraction}
                                                errorText={errors["infraction"] ? errors["infraction"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={6}>
                                            <LATextField
                                                varient="outlined"
                                                label="Carrier Notification Provided To"
                                                fullWidth={true}
                                                name="carrier_Notification_Provided_To"
                                                onChange={this.onChange}
                                                value={infraction.carrier_Notification_Provided_To ?? ""}
                                                errorText={errors["carrier_Notification_Provided_To"] ? errors["carrier_Notification_Provided_To"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={6}>
                                            <LATextArea
                                                minRows={3}
                                                rowsMax={6}
                                                varient="outlined"
                                                label="Description of Infraction"
                                                fullWidth={true}
                                                name="description"
                                                onChange={this.onChange}
                                                value={infraction.description ?? ""}
                                                errorText={errors["description"] ? errors["description"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={6}>
                                            <LATextArea
                                                minRows={3}
                                                rowsMax={6}
                                                varient="outlined"
                                                label="Immediate Action Taken"
                                                fullWidth={true}
                                                name="immediate_Action_Taken"
                                                onChange={this.onChange}
                                                value={infraction.immediate_Action_Taken ?? ""}
                                                errorText={errors["immediate_Action_Taken"] ? errors["immediate_Action_Taken"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={6}>
                                            <LATextArea
                                                minRows={2}
                                                rowsMax={6}
                                                varient="outlined"
                                                label="Follow Up Action Taken"
                                                fullWidth={true}
                                                name="followup_Action_Taken"
                                                onChange={this.onChange}
                                                value={infraction.followup_Action_Taken ?? ""}
                                                errorText={errors["followup_Action_Taken"] ? errors["followup_Action_Taken"].message : undefined}
                                            />
                                        </LAGridItem>


                                        <LAGridItem xs={6} className="text-center">
                                            <strong>Attachment</strong>
                                            <LADropDone onFileDrop={truckRegistrationFile} />
                                            {infraction.attachment_File_Name && infraction.attachment_File_Name.length > 0
                                                && <LALinkButton className="pl-5" label={infraction.attachment_File_Name}
                                                    onClick={truckRegistrationFileDownload} />}
                                            {infraction.attachment_File_Name && <LAIconButton
                                                name="attachment_File_Name"
                                                label="Remove"
                                                icon={<DeleteIcon />}
                                                onClick={this.onFormRemove}
                                            />}
                                        </LAGridItem>

                                        <LAGridItem xs={6}>
                                            <LATextField
                                                varient="outlined"
                                                label="Attachment Details"
                                                fullWidth={true}
                                                name="attachment_Details"
                                                onChange={this.onChange}
                                                value={infraction.attachment_Details ?? ""}
                                                errorText={errors["attachment_Details"] ? errors["attachment_Details"].message : undefined}
                                            />
                                        </LAGridItem>


                                    </LAGrid>
                                </LAExpansionPanel>
                            </LAGridItem>

                            <LAGridItem xs={12}>

                                <LAExpansionPanel color={DRY_PLANT_FORM_TILE_COLOR} textColor={WHITE_COLOR} label="Infraction Reported By" expanded={expanded.includes(ICarrierInfractionSection["Infraction Reported By"])} onClick={handleInfractionReported}>
                                    <LAGrid>

                                        <LAGridItem xs={6}>
                                            <LATextField
                                                varient="outlined"
                                                label="Reported By Name"
                                                fullWidth={true}
                                                name="reported_By_Name"
                                                onChange={this.onChange}
                                                value={infraction.reported_By_Name ?? ""}
                                                errorText={errors["reported_By_Name"] ? errors["reported_By_Name"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={6}>
                                            <LATextField
                                                varient="outlined"
                                                label="Contact No"
                                                fullWidth={true}
                                                name="contact_No"
                                                type="number"
                                                onChange={this.onChange}
                                                value={infraction.contact_No ?? ""}
                                                errorText={errors["contact_No"] ? errors["contact_No"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={6}>
                                            <LATextField
                                                varient="outlined"
                                                label="Complaint Recorded By"
                                                fullWidth={true}
                                                name="complaint_Recorded_By"
                                                onChange={this.onChange}
                                                value={infraction.complaint_Recorded_By ?? ""}
                                                errorText={errors["complaint_Recorded_By"] ? errors["complaint_Recorded_By"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={6}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="Status"
                                                getOptionLabel="name"
                                                name="status"
                                                option={infractionStatus}
                                                multiple={false}
                                                onChange={this.handleDropDownChange}
                                                value={infractionStatus.find(x => x.name === infraction.status)}
                                                defaultValue={infractionStatus.find(x => x.name === infraction.status)}
                                            />
                                        </LAGridItem>

                                    </LAGrid>
                                </LAExpansionPanel>
                            </LAGridItem>



                            <LAGridItem xs={12}>
                                <hr />
                                <LASaveAndCancelButton
                                    onSave={this.onSave}
                                    onCancel={this.handleCancel}
                                    disableSave={(Object.values(errors).length > 0 ? true : undefined)}
                                />
                            </LAGridItem>

                        </LAGrid>

                        <RequestStatus requestStatus={addCarrierInfraction.kind} successMessage="Carrier Infraction successfully saved" />
                        <RequestStatus requestStatus={updateCarrierInfraction.kind} successMessage="Carrier Infraction successfully updated" />
                    </CarrierInfractionStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private handleOtherNotification = (name: string, value: string): void => {
        this.setState({
            ...this.state,
            [name]: value
        });
    };

    private handleNotificationDropDown = (event: unknown, value: { id: number, name: string } | "", name?: string): void => {
        if (name) {
            let errors = this.state.errors;
            let val = value !== "" ? value.name : "";

            if (requiredFields.includes(name))
                errors = this.errorChecker(name, val, errors, true);

            this.setState({
                ...this.state,
                errors,
                infraction: {
                    ...this.state.infraction,
                    [name]: val
                },
                otherNotification: ""
            });
        }
    };

    private handleExpand = (expanded: number): void => {
        const iS = [...this.state.expanded];

        if (this.state.expanded.includes(expanded)) {
            iS.splice(iS.findIndex(x => x === expanded), 1);
        } else {
            iS.push(expanded);
        };

        this.setState({ expanded: iS });
    };

    private onFormRemove = (event?: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined, name?: string): void => {
        if (name) {
            const selection = window.confirm(deleteText);
            if(selection) {
                this.setState({
                    ...this.state,
                    infraction: {
                        ...this.state.infraction,
                        [name]: undefined,
                        [name + "_Name"]: undefined
                    }
                });
            }
        }
    };

    private uploadFile = (name: string, event?: any): void => {
        new Promise((resolve, reject): any => {
            if (event && (event !== null)) {

                Object.values(event).forEach((x: any, index, array) => {

                    if (x !== undefined) {
                        const reader = new FileReader();

                        reader.onloadend = async (e): Promise<void> => {
                            if (reader.result !== null) {
                                this.setState({
                                    ...this.state,
                                    infraction: {
                                        ...this.state.infraction,
                                        [name + "_Name"]: x.name,
                                        [name]: reader.result
                                    }
                                });
                                resolve(true);
                            };
                        };
                        reader.readAsDataURL(x);
                    }
                })
            } else {
                reject();
            }
        })
    };

    private downloadFile = (name?: string, base64?: string, objName?: string): void => {

        if (name) {
            if ((base64 !== undefined) && (base64 !== null)) {
                let a = document.createElement("a");
                a.href = base64;
                a.download = name;
                a.click();
            };

            if ((base64 === undefined) || (base64 === null)) {

                if ((objName !== undefined) || (objName !== null)) {
                    if (hasPayload(this.props.getToken) && this.state.infraction.id) {

                        fetch(END_POINTS.PROPSENSE.YHD.CARRIER.GET_CARRIER_INFRACTIONS_FILE, {
                            method: "POST",
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify({
                                request: {
                                    "ID": this.state.infraction.id,
                                    "File": objName,
                                },
                                token: this.props.getToken.payload.response.token
                            })
                        })
                            .then((res: any) => {
                                return res.blob();
                            })
                            .then(blob => {

                                var url = window.URL.createObjectURL(blob);
                                var a = document.createElement('a');
                                a.href = url;
                                a.download = name;
                                document.body.appendChild(a);
                                a.click();
                                a.remove();
                            })
                            .catch((res) => console.log(res));
                    };
                };
            };
        }
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, isRequired: boolean): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: isRequired ? true : undefined, minLength: 1, decimal: undefined });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        };
        return err;
    };

    private handleCancel = (): void => {
        this.props.history.push(ROUTE.PROPSENSE.YHD.CARRIERS.CARRIER_INFRACTIONS);
    };

    private onSave = (): void => {
        const { infraction, otherNotification } = this.state;

        if (hasPayload(this.props.getToken))
            if (infraction.id === 0) {
                this.props.addCarrierInfractionRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        Date_Of_Infraction: infraction.date_Of_Infraction,
                        Infraction_Notification_Method: (infraction.infraction_Notification_Method === methodOfNotification[3].name) ? otherNotification : infraction.infraction_Notification_Method,
                        Alternate_Name: infraction.alternate_Name,
                        Trailer_No: infraction.trailer_No,
                        License_Plate: infraction.license_Plate,
                        Location: infraction.location,
                        Ticket_No: infraction.ticket_No,
                        Infraction: infraction.infraction,
                        Carrier_Notification_Provided_To: infraction.carrier_Notification_Provided_To,
                        Description: infraction.description,
                        Immediate_Action_Taken: infraction.immediate_Action_Taken,
                        Trailer_Inspection_File: "",
                        Followup_Action_Taken: infraction.followup_Action_Taken,
                        Attachment_File_Name: infraction.attachment_File_Name,
                        Attachment_File: infraction.attachment_File,
                        Attachment_Details: infraction.attachment_Details,
                        Reported_By_Name: infraction.reported_By_Name,
                        Contact_No: infraction.contact_No,
                        Complaint_Recorded_By: infraction.complaint_Recorded_By,
                        Status: infraction.status,
                        Modified: infraction.modified,
                        Modified_By: userName,
                        Created: infraction.created,
                        Created_By: userName,
                    }
                });
            } else {
                this.props.updateCarrierInfractionRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        ID: infraction.id,
                        Date_Of_Infraction: infraction.date_Of_Infraction,
                        Infraction_Notification_Method: (infraction.infraction_Notification_Method === methodOfNotification[3].name) ? otherNotification : infraction.infraction_Notification_Method,
                        Alternate_Name: infraction.alternate_Name,
                        Trailer_No: infraction.trailer_No,
                        License_Plate: infraction.license_Plate,
                        Location: infraction.location,
                        Ticket_No: infraction.ticket_No,
                        Infraction: infraction.infraction,
                        Carrier_Notification_Provided_To: infraction.carrier_Notification_Provided_To,
                        Description: infraction.description,
                        Immediate_Action_Taken: infraction.immediate_Action_Taken,
                        Trailer_Inspection_File: "",
                        Followup_Action_Taken: infraction.followup_Action_Taken,
                        Attachment_File_Name: infraction.attachment_File_Name,
                        Attachment_File: infraction.attachment_File,
                        Attachment_Details: infraction.attachment_Details,
                        Reported_By_Name: infraction.reported_By_Name,
                        Contact_No: infraction.contact_No,
                        Complaint_Recorded_By: infraction.complaint_Recorded_By,
                        Status: infraction.status,
                        Modified: infraction.modified,
                        Modified_By: userName,
                        Created: infraction.created,
                        Created_By: infraction.created_By,
                    }
                });
            };
    };

    private onDateChange = (name: string, value: string): void => {
        const date = new Date(value);
        let errors = { ...this.state.errors };

        if (date.toString() === "Invalid Date") {
            if (!errors[name]) {
                errors[name] = { key: name, message: "Invalid Date" };
            }
        } else {
            if (errors[name])
                delete errors[name];
        };

        if (requiredFields.includes(name)) {
            errors = this.errorChecker(name, value, errors, true);
        };

        this.setState({
            ...this.state,
            infraction: {
                ...this.state.infraction,
                [name]: value
            },
            errors
        });
    };

    private handleDropDownChange = (event: unknown, value: { id: number, name: string } | "", name?: string): void => {
        if (name)
            this.setState({
                ...this.state,
                infraction: {
                    ...this.state.infraction,
                    [name]: value !== "" ? value.name : ""
                }
            });
    };


    private onChange = (name: string, value: string): void => {
        let errors = this.state.errors;
        errors = this.errorChecker(name, value, errors, true);

        this.setState({
            ...this.state,
            errors,
            infraction: {
                ...this.state.infraction,
                [name]: value
            }
        });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });


        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getCarrierLookups))
            this.props.getCarrierLookupsRequest({
                token: this.props.getToken.payload.response.token,
                requested_Page: "Carrier_Infractions"
            });

        if (hasPayload(this.props.getCarrierLookups)) {
            this.setState({ carriers: this.props.getCarrierLookups.payload.response });
        };

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getCarrierInfractions))
            this.props.getCarrierInfractionsRequest({
                token: this.props.getToken.payload.response.token
            });


        const query = queryString.parse(this.props.location.search);
        const id = query.id ?? "0";

        if ((id !== "0") && (hasPayload(this.props.getCarrierInfractions))) {
            const infraction = this.props.getCarrierInfractions.payload.response.find(x => x.id === Number(id));

            if (infraction){
                const notification: any = findInfractionNotification(infraction.infraction_Notification_Method);

                this.setState({ 
                    infraction, 
                    errors: {}, 
                    otherNotification: ((notification.name) && (notification.name === methodOfNotification[3].name)) ? infraction.infraction_Notification_Method : ""
                 });
            }
        };

    };

}

const mapStateToProps = (state: IStore): ICarrierInfractionStoreProps => ({
    getToken: getTokenStatus(state),
    getCarrierInfractions: getYHDCarrierInfractionsStatus(state),
    addCarrierInfraction: getYHDAddCarrierInfractionStatus(state),
    updateCarrierInfraction: getYHDUpdateCarrierInfractionStatus(state),
    getCarrierLookups: getYHDCarrierLookupsStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): ICarrierInfractionDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getCarrierInfractionsRequest: (data: IGetYHDCarrierInfractionsRequest) => dispatch(getYHDCarrierInfractionsLoadAction(data)),
    addCarrierInfractionRequest: (data: IAddYHDCarrierInfractionRequest) => dispatch(addYHDCarrierInfractionLoadAction(data)),
    updateCarrierInfractionRequest: (data: IUpdateYHDCarrierInfractionRequest) => dispatch(updateYHDCarrierInfractionLoadAction(data)),
    getCarrierLookupsRequest: (data: IGetYHDCarrierLookupsRequest) => dispatch(getYHDCarrierLookupsLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(YHDCarrierInfraction);