import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import { ById, SilAPIResponse } from "../../../shared/publicInterfaces";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import { callRouteWithQueryString, userName } from "../../../shared/constExports";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { LAButton } from "../../../shared/buttons";
import { AddIcon } from "../../../shared/icons";
import { WHITE_COLOR } from "../../../shared/theme";
import { ROUTE } from "../../../routes";
import PageSpacing from "../../../shared/pageSpacing";
import SilRoleCheck from "../../../shared/silRoleCheck";
import LALoading from "../../../shared/loading";
import { IGetQualityControlTestingsRequest, IQualityControlTestings } from "../../../../redux/sil/qualityControlTestings/getQualityControlTestings/getQualityControlTestingsConstaints";
import { getQualityControlTestingsStatus } from "../../../../redux/sil/qualityControlTestings/getQualityControlTestings/getQualityControlTestingsAccessor";
import { getQualityControlTestingsLoadAction } from "../../../../redux/sil/qualityControlTestings/getQualityControlTestings/getQualityControlTestingsActions";

interface IQualityControlTestingsStoreProps {
    getToken: Server<SilAPIResponse<IToken>>;
    getQualityControlTestingsList: Server<SilAPIResponse<IQualityControlTestings[]>>;
};

interface IQualityControlTestingsDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getQualityControlTestingsListRequest: (data: IGetQualityControlTestingsRequest) => unknown;
};


interface IQualityControlTestingsOwnProps {

};

interface IQualityControlTestingsState {
    data: IQualityControlTestings[];
};

const QualityControlTestingsStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .loading {
        margin-top: 5%;
    };

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
    };
`;

type IQualityControlTestingsProps = RouteComponentProps
    & IQualityControlTestingsStoreProps
    & IQualityControlTestingsDispatchProps
    & IQualityControlTestingsOwnProps;

class QualityControlTestings extends PureComponent<IQualityControlTestingsProps, IQualityControlTestingsState> {

    public constructor(props: IQualityControlTestingsProps) {
        super(props);
        this.state = {
            data: []
        };
    }

    public componentDidMount(): void {
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IQualityControlTestingsProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();
        };
    };


    public render(): ReactNode {

        const { data } = this.state;
        const { getQualityControlTestingsList } = this.props;

        return (
            <PageSpacing title="SIL - Quality Control Testings" description="SIL - Quality Control Testings" fixedSpaceOnSmallerScreens={true}>
                {/* <SilRoleCheck error={true} roleFor="complaintLoggingAccess"> */}
                    <QualityControlTestingsStyles>

                        {getQualityControlTestingsList.kind !== STATUS_ENUM.LOADING &&
                            <LAButton className="pull-left" startIcon={<AddIcon color={WHITE_COLOR} />} label="Add" onClick={this.handleAdd} />}
                        <h2 className="text-center">Turbidity QAQC Tests</h2>
                        <hr />

                        {getQualityControlTestingsList.kind === STATUS_ENUM.LOADING &&
                            <LALoading className="loading" message="Loading..." />}

                        {getQualityControlTestingsList.kind !== STATUS_ENUM.LOADING && <LADevExtremeGrid
                            data={data}
                            onEdit={this.onEdit}
                            onClick={this.onClick}
                            height={1000}
                            searchPanel={true}
                            filterHeader={true}
                            export={true}
                            exportFileName="QualityControlTestings"
                            storageKey="silQualityControlTestingsStorageKey"
                            columns={[                               
                                { name: "test_For", caption: "Who the test is for (customer or internal)", type: "string" },
                                { name: "product", caption: "Product", type: "string" },
                                { name: "date", caption: "Date", type: "date", sortDesc: true },
                                { name: "turbidity_Test_Result", caption: "Turbidity test result", type: "string" },
                                { name: "remarks", caption: "Remarks", type: "string" },
                                { name: "created", caption: "Created", type: "datetime" },
                                { name: "created_By", caption: "Created By", type: "string" },
                                { name: "modified", caption: "Modified", type: "datetime" },
                                { name: "modified_By", caption: "Modified By", type: "string" }
                            ]}
                        />}

                    </QualityControlTestingsStyles>
                
            </PageSpacing>
        );
    }

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString() },
            pathName: ROUTE.SIL.QUALITY_CONTROL_TESTINGS.QUALITY_CONTROL_TESTING
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0" },
            pathName: ROUTE.SIL.QUALITY_CONTROL_TESTINGS.QUALITY_CONTROL_TESTING
        });
    };

    private setDataToState = (): void => {

        if (hasPayload(this.props.getQualityControlTestingsList)) {
            const data: IQualityControlTestings[] = Object.values(this.props.getQualityControlTestingsList.payload.response);
            this.setState({ data });
        };

        if (isNotLoaded(this.props.getQualityControlTestingsList))
            this.callServer();
    };

    private callServer = (): void => {
        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getQualityControlTestingsList))
            this.props.getQualityControlTestingsListRequest({
                token: this.props.getToken.payload.response.token
            });

        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });
    }

}

const mapStateToProps = (state: IStore): IQualityControlTestingsStoreProps => ({
    getToken: getTokenStatus(state),
    getQualityControlTestingsList: getQualityControlTestingsStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IQualityControlTestingsDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getQualityControlTestingsListRequest: (data: IGetQualityControlTestingsRequest) => dispatch(getQualityControlTestingsLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QualityControlTestings);