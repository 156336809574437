import { IPassTokenWithRequest } from "../../../sil/dryPlant/token/getTokenConstants";
export enum IADD_SALES_ORDER_NUMBER_REQUEST {
    REQUEST = "addSalesOrderNumber/ADD_SALES_ORDER_NUMBER_REQUEST",
    SUCCESS = "addSalesOrderNumber/ADD_SALES_ORDER_NUMBER_SUCCESS",
    FAILED = "addSalesOrderNumber/ADD_SALES_ORDER_NUMBER_FAILED"
};

export interface IAddSalesOrderNumberRequest extends IPassTokenWithRequest {
    request: IAddSalesOrderNumber;
};

export interface IAddSalesOrderNumber {
    Sales_Order_Number: string;
    Customer_ID: number;
    Created_By: string;
}