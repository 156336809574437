import { ROUTE } from "../routes";
import styled from "styled-components";
import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { LAMenuItem } from "../shared/dropDownMenu";
import { DARK_GREY_COLOR, MAIN_COLOR, RED_COLOR } from "../shared/theme";


const YHDCarrierHeaderStyles = styled.div`
    .yhd-page {
        background-color: ${MAIN_COLOR};
        width: 100%;
        display: flex;
        align-items: center;
        height: 50px;
    };

    span {
        color: ${RED_COLOR};
    };

    .current {
        background-color: ${DARK_GREY_COLOR};
    };
`;


type IYHDCarrierHeaderProps =
    RouteComponentProps;

class YHDCarrierHeader extends PureComponent<IYHDCarrierHeaderProps> {

    public constructor(props: IYHDCarrierHeaderProps) {
        super(props);
        this.state = {
        };
    }

    public render(): ReactNode {
        const path = this.props.location.pathname;
        return (
            <YHDCarrierHeaderStyles>
                <div className="yhd-page">

                    <LAMenuItem onClick={this.onHome}>
                        <span className="">
                            YHD Home
                        </span>
                    </LAMenuItem>

                    <LAMenuItem onClick={this.onCarrier} className={(path === ROUTE.PROPSENSE.YHD.CARRIERS.CARRIERS) ? "current": ""}>
                        <span>
                            Carriers
                        </span>
                    </LAMenuItem>

                    <LAMenuItem onClick={this.onCarrierEmail} className={(path === ROUTE.PROPSENSE.YHD.CARRIERS.CARRIER_EMAILS) ? "current": ""}>
                        <span className="">
                            Carriers Emails
                        </span>
                    </LAMenuItem>

                    <LAMenuItem onClick={this.onCarrierFleetTruck} className={(path === ROUTE.PROPSENSE.YHD.CARRIERS.CARRIER_FLEET_TRUCKS) ? "current": ""}>
                        <span className="">
                            Carrier Fleet Trucks
                        </span>
                    </LAMenuItem>

                    <LAMenuItem onClick={this.onCarrierInfraction} className={(path === ROUTE.PROPSENSE.YHD.CARRIERS.CARRIER_INFRACTIONS) ? "current": ""}>
                        <span className="">
                            Carrier Infractions
                        </span>
                    </LAMenuItem>

                </div>
            </YHDCarrierHeaderStyles>
        );
    }

    private onHome = (): void => {
        this.props.history.push(ROUTE.PROPSENSE.YHD.INDEX);
    };

    private onCarrier = (): void => {
        this.props.history.push(ROUTE.PROPSENSE.YHD.CARRIERS.CARRIERS);
    };

    private onCarrierEmail = (): void => {
        this.props.history.push(ROUTE.PROPSENSE.YHD.CARRIERS.CARRIER_EMAILS);
    };

    private onCarrierFleetTruck = (): void => {
        this.props.history.push(ROUTE.PROPSENSE.YHD.CARRIERS.CARRIER_FLEET_TRUCKS);
    };

    private onCarrierInfraction = (): void => {
        this.props.history.push(ROUTE.PROPSENSE.YHD.CARRIERS.CARRIER_INFRACTIONS);
    };

}

export default (YHDCarrierHeader);