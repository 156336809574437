import { SimosAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetOrderByIdRequest, IGET_ORDER_BY_ID_REQUEST, ISIMOSOrderById } from "./getOrderByIdConstaints";

export interface IGetOrderByIdLoadAction {
    type: IGET_ORDER_BY_ID_REQUEST.REQUEST;
    data: IGetOrderByIdRequest
}
export const getOrderByIdLoadAction = (data: IGetOrderByIdRequest): IGetOrderByIdLoadAction => ({
    type: IGET_ORDER_BY_ID_REQUEST.REQUEST,
    data
});

export interface IGetOrderByIdSuccessAction {
    type: IGET_ORDER_BY_ID_REQUEST.SUCCESS;
    data: SimosAPIResponse<ISIMOSOrderById>;
}
export const getOrderByIdLoadSuccessAction = (data: SimosAPIResponse<ISIMOSOrderById>): IGetOrderByIdSuccessAction => ({
    type: IGET_ORDER_BY_ID_REQUEST.SUCCESS,
    data
});

export interface IGetOrderByIdLoadFailedAction {
    type: IGET_ORDER_BY_ID_REQUEST.FAILED;
    message: string;
}
export const getOrderByIdLoadFailedAction = (message: string): IGetOrderByIdLoadFailedAction => ({
    type: IGET_ORDER_BY_ID_REQUEST.FAILED,
    message
});
