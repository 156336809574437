import { ROUTE } from "../routes";
import styled from "styled-components";
import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { LAMenuItem } from "../shared/dropDownMenu";
import { DARK_GREY_COLOR, MAIN_COLOR, RED_COLOR } from "../shared/theme";


const SieveHeaderStyles = styled.div`
    .sil-page {
        background-color: ${MAIN_COLOR};
        width: 100%;
        display: flex;
        align-items: center;
        height: 50px;
    };

    span {
        color: ${RED_COLOR};
    };

    .current {
        background-color: ${DARK_GREY_COLOR};
    };
`;


type ISieveHeaderProps =
    RouteComponentProps;

class SieveHeader extends PureComponent<ISieveHeaderProps> {

    public constructor(props: ISieveHeaderProps) {
        super(props);
        this.state = {
        };
    }

    public render(): ReactNode {
        const path = this.props.location.pathname;
        return (
            <SieveHeaderStyles>
                <div className="sil-page">

                    <LAMenuItem onClick={this.onHome}>
                        <span className="">
                            SIL Home
                        </span>
                    </LAMenuItem>

                    <LAMenuItem onClick={this.onSieve} className={(path === ROUTE.SIL.SIEVES.SIEVES_ITEMS) ? "current": ""}>
                        <span>
                            Sieves
                        </span>
                    </LAMenuItem>

                    <LAMenuItem onClick={this.onSieveCalibration} className={(path === ROUTE.SIL.SIEVES.SIEVES_CALIBRATIONS) ? "current": ""}>
                        <span className="">
                            Calibrations
                        </span>
                    </LAMenuItem>

                    <LAMenuItem onClick={this.onSieveCheck} className={(path === ROUTE.SIL.SIEVES.SIEVES_CHECKS) ? "current": ""}>
                        <span>
                            Checks
                        </span>
                    </LAMenuItem>

                    <LAMenuItem onClick={this.onSieveProduct} className={(path === ROUTE.SIL.SIEVES.SIEVE_PRODUCTS) ? "current": ""}>
                        <span>
                            Products
                        </span>
                    </LAMenuItem>

                    <LAMenuItem onClick={this.onCantyReport}>
                        <span className="">
                            Canty Report
                        </span>
                    </LAMenuItem>

                </div>
            </SieveHeaderStyles>
        );
    }

    private onHome = (): void => {
        this.props.history.push(ROUTE.SIL.INDEX);
    };

    private onSieve = (): void => {
        this.props.history.push(ROUTE.SIL.SIEVES.SIEVES_ITEMS);
    };

    private onSieveCalibration = (): void => {
        this.props.history.push(ROUTE.SIL.SIEVES.SIEVES_CALIBRATIONS);
    };

    private onSieveCheck = (): void => {
        this.props.history.push(ROUTE.SIL.SIEVES.SIEVES_CHECKS);
    };

    private onSieveProduct = (): void => {
        this.props.history.push(ROUTE.SIL.SIEVES.SIEVE_PRODUCTS);
    };

    private onCantyReport = (): void => {
        window.open('https://reportserver.propsense.com/reports/report/Reports/Canty/Canty_DRY_Plant_PivotTable?rc:Zoom=Page%20Width', '_blank');
    };

}

export default (SieveHeader);