import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IAddProductionLoadAction, IAddProductionLoadFailedAction, IAddProductionSuccessAction } from "./addProductionActions";
import { IADD_PRODUCTION_REQUEST } from "./addProductionConstaints";


type Actions =
    IAddProductionLoadAction
    | IAddProductionLoadFailedAction
    | IAddProductionSuccessAction
    | IFlushDataSuccessAction;

export const AddProductionReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IADD_PRODUCTION_REQUEST.REQUEST:
            return loading;

        case IADD_PRODUCTION_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IADD_PRODUCTION_REQUEST.FAILED:
            return failed(action.message);

            case FLUSH_DATA.SUCCESS:
                return notLoaded;

        default:
            return state;
    }
};