import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IGetTrailerActivityLogsLoadAction, IGetTrailerActivityLogsLoadFailedAction, IGetTrailerActivityLogsSuccessAction, 
    getTrailerActivityLogsLoadFailedAction, getTrailerActivityLogsLoadSuccessAction
} from "./getTrailerActivityLogsActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IGET_TRAILER_ACTIVITY_LOGS_REQUEST, ITrailerActivityLog } from "./getTrailerActivityLogsConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";

export const getTrailerActivityLogsEpic: Epic = (
    action$: ActionsObservable<IGetTrailerActivityLogsLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IGetTrailerActivityLogsSuccessAction | IGetTrailerActivityLogsLoadFailedAction> =>
    action$.ofType(IGET_TRAILER_ACTIVITY_LOGS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<ITrailerActivityLog[]>>(
                    END_POINTS.PROPSENSE.SIL.DISPATCH.GET_TRAILER_ACTIVITY_LOGS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<ITrailerActivityLog[]>): IGetTrailerActivityLogsSuccessAction => {
                            return getTrailerActivityLogsLoadSuccessAction(response);
                        }),
                        catchError(() => ActionsObservable.of(getTrailerActivityLogsLoadFailedAction("Unable to get trailer activity logs")))
                    )
            )
        );