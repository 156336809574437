import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithLessPadding, LAPaperWithPadding } from "./shared/paper";
import { IStore, IDispatch } from "../redux/reducers";
import { ROUTE } from "./routes";
import LAGrid from "./shared/grid";
import LAGridItem from "./shared/gridList";
import LALinkButton from "./shared/linkButton";
import { MEDIA_QUERY_PHONE } from "./shared/theme";


interface ICatapultHomeStoreProps {

};

interface ICatapultHomeDispatchProps {

};

interface ICatapultHomeOwnProps {

};

interface ICatapultHomeState {

};

const CatapultHomeStyles = styled(LAPaperWithPadding)`
    margin: 40px 30px;
    word-break: break-word;

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };
`;

type ICatapultHomeProps = RouteComponentProps
    & ICatapultHomeStoreProps
    & ICatapultHomeDispatchProps
    & ICatapultHomeOwnProps;

class CatapultHome extends PureComponent<ICatapultHomeProps, ICatapultHomeState> {

    public constructor(props: ICatapultHomeProps) {
        super(props);
        this.state = {

        };

    }

    public componentDidMount(): void {

    };

    public async componentDidUpdate(prevProps: ICatapultHomeProps, prevState: ICatapultHomeState): Promise<void> {
    };

    public render(): ReactNode {

        return (
            <CatapultHomeStyles>
                <LAGrid spacing={3} className="text-center">

                    <LAGridItem xs={12}>
                        <h2>Catapult</h2>
                    </LAGridItem>

                    <LAGridItem xs={6}>
                        <LAPaperWithLessPadding>
                            <LALinkButton label="Crew Scheduling" onClick={this.onCrewScheduling} />
                        </LAPaperWithLessPadding>
                    </LAGridItem>

                </LAGrid>
            </CatapultHomeStyles>
        );
    }

    private onCrewScheduling = (): void => {
        this.props.history.push(ROUTE.PROPSENSE.CATAPULT.CREW_SCHEDULING.INDEX());
    };

}

const mapStateToProps = (state: IStore): ICatapultHomeStoreProps => ({

});

const mapDispatchToProps = (dispatch: IDispatch): ICatapultHomeDispatchProps => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(CatapultHome);

