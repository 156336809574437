import { IPassTokenWithRequest } from "../../../sil/dryPlant/token/getTokenConstants";

export enum IGET_SIMOS_ORDER_PRODUCT_VERSION_HISTORY_REQUEST {
    REQUEST = "getSIMOSOrderProductVersionHistory/GET_SIMOS_ORDER_PRODUCT_VERSION_HISTORY_REQUEST",
    SUCCESS = "getSIMOSOrderProductVersionVersionHistory/GET_SIMOS_ORDER_PRODUCT_VERSION_HISTORY_SUCCESS",
    FAILED = "getSIMOSOrderProductVersionVersionHistory/GET_SIMOS_ORDER_PRODUCT_VERSION_HISTORY_FAILED"
};

export interface IGetSIMOSOrderProductVersionHistoryRequest extends IPassTokenWithRequest {
    id: number;
};

export interface ISIMOSOrderProductVersionHistory {
    id: number;
    itemID: number;
    customer_ID: number;
    quantity: number;
    size: string;
    per: string;
    product: string;
    code: string;
    cw: string;
    actual_Quantity: number;
    orderID: number;
    operation: number;
    status: string;
    created_By: string;
    modified_By: string;
    created: string;
    modified: string;
};