import { IAddChemicalAnalysesRequest, IADD_CHEMICAL_ANALYSES_REQUEST } from "./addChemicalAnalysesConstaints";

export interface IAddChemicalAnalysesLoadAction {
    type: IADD_CHEMICAL_ANALYSES_REQUEST.REQUEST;
    data: IAddChemicalAnalysesRequest
}
export const addChemicalAnalysesLoadAction = (data: IAddChemicalAnalysesRequest): IAddChemicalAnalysesLoadAction => ({
    type: IADD_CHEMICAL_ANALYSES_REQUEST.REQUEST,
    data
});

export interface IAddChemicalAnalysesSuccessAction {
    type: IADD_CHEMICAL_ANALYSES_REQUEST.SUCCESS;
    message: string;
}
export const addChemicalAnalysesLoadSuccessAction = (message: string): IAddChemicalAnalysesSuccessAction => ({
    type: IADD_CHEMICAL_ANALYSES_REQUEST.SUCCESS,
    message
});

export interface IAddChemicalAnalysesLoadFailedAction {
    type: IADD_CHEMICAL_ANALYSES_REQUEST.FAILED;
    message: string;
}
export const addChemicalAnalysesLoadFailedAction = (message: string): IAddChemicalAnalysesLoadFailedAction => ({
    type: IADD_CHEMICAL_ANALYSES_REQUEST.FAILED,
    message
});
