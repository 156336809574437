import { IPassTokenWithRequest } from "../../dryPlant/token/getTokenConstants";

export enum IGET_WASH_PLANT_PRODUCTION_RECORDS_REQUEST {
    REQUEST = "getWashPlantProductionRecordById/GET_WASH_PLANT_PRODUCTION_RECORDS_REQUEST",
    SUCCESS = "getWashPlantProductionRecordById/GET_WASH_PLANT_PRODUCTION_RECORDS_SUCCESS",
    FAILED = "getWashPlantProductionRecordById/GET_WASH_PLANT_PRODUCTION_RECORDS_FAILED"
};

export interface IGetWashPlantProductionRecordByIdRequest extends IPassTokenWithRequest {
    request: {
        id: number;
    };
};