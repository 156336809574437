import { IPassTokenRequestBody } from "../../../../../react/shared/publicInterfaces";

export enum IGET_SIMOS_TRANSPORT_COMPANIES_REQUEST {
    REQUEST = "getSimosTransportCompanies/GET_SIMOS_PRODUCTS_REQUEST",
    SUCCESS = "getSimosTransportCompanies/GET_SIMOS_PRODUCTS_SUCCESS",
    FAILED = "getSimosTransportCompanies/GET_SIMOS_PRODUCTS_FAILED"
};

export interface ISimosTransportCompaniesRequest extends IPassTokenRequestBody {

};

export interface ISimosTransportCompanies {
    id: number;
    transport_Company: string;
    email: string;
    active: string;
    truck_Code_Y: string;
    truck_Code_Z: string;
    created: string;
    created_By: string;
    modified: string;
    modified_By: string;   
};
