import { IPassTokenWithRequest } from "../../dryPlant/token/getTokenConstants";

export enum IGET_QUALITY_CONTROL_TESTINGS_REQUEST {
    REQUEST = "getQualityControlTestings/GET_QUALITY_CONTROL_TESTINGS_REQUEST",
    SUCCESS = "getQualityControlTestings/GET_QUALITY_CONTROL_TESTINGS_SUCCESS",
    FAILED = "getQualityControlTestings/GET_QUALITY_CONTROL_TESTINGS_FAILED"
};

export interface IGetQualityControlTestingsRequest extends IPassTokenWithRequest {

};

export interface IQualityControlTestings {
    id: number;
    test_For: string;
    product: string;
    date: string;
    turbidity_Test_Result: string;
    remarks: string;
    created: string;
    created_By: string;
    modified: string;
    modified_By: string;
};
