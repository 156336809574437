import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, isSucceeded, Server } from "../../../../redux/server";
import { ById, SilAPIResponse } from "../../../shared/publicInterfaces";
import { userName, undefinedFunction } from "../../../shared/constExports";
import { LAButton, LASaveAndCancelButton } from "../../../shared/buttons";
import { ArrowLeftIcon } from "../../../shared/icons";
import { WHITE_COLOR } from "../../../shared/theme";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import LATextField from "../../../shared/textField";
import LAAutoComplete from "../../../shared/autoComplete";
import { ROUTE } from "../../../routes";
import queryString from "query-string";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import { FieldValidator, FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../../shared/fieldValidator";
import PageSpacing from "../../../shared/pageSpacing";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import SilRoleCheck from "../../../shared/silRoleCheck";
import { IAddSieveProductRequest } from "../../../../redux/sil/sieves/addSieveProduct/addSieveProductConstaints";
import { IUpdateSieveProductRequest } from "../../../../redux/sil/sieves/updateSieveProduct/updateSieveProductConstaints";
import { updateSieveProductStatus } from "../../../../redux/sil/sieves/updateSieveProduct/updateSieveProductAccessor";
import { addSieveProductStatus } from "../../../../redux/sil/sieves/addSieveProduct/addSieveProductAccessor";
import { addSieveProductLoadAction } from "../../../../redux/sil/sieves/addSieveProduct/addSieveProductActions";
import { updateSieveProductLoadAction } from "../../../../redux/sil/sieves/updateSieveProduct/updateSieveProductActions";
import { IGetSieveProductsRequest, ISieveProduct } from "../../../../redux/sil/sieves/getSievesProducts/getSievesProductsConstaints";
import { getSieveProductsStatus } from "../../../../redux/sil/sieves/getSievesProducts/getSievesProductsAccessor";
import { getSieveProductsLoadAction } from "../../../../redux/sil/sieves/getSievesProducts/getSievesProductsActions";
import DataGrid, { Column, Editing, Paging } from "devextreme-react/data-grid";


interface ISieveProductStoreProps {
    addSieveProduct: Server<string>;
    updateSieveProduct: Server<string>;
    getToken: Server<SilAPIResponse<IToken>>;
    getSieveProducts: Server<SilAPIResponse<ById<ISieveProduct>>>;
};

interface ISieveProductDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getSieveProductsRequest: (data: IGetSieveProductsRequest) => unknown;
    addSieveProductRequest: (data: IAddSieveProductRequest) => unknown;
    updateSieveProductRequest: (data: IUpdateSieveProductRequest) => unknown;
};


interface ISieveProductOwnProps {

};

interface ISieveProductState {
    sieve: ISieveProduct;
    errors: ById<IFieldErrorKeyValue>;
};

const SieveProductStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;

    .dx-toolbar-after {
        display: none;
    }
`;

type ISieveProductProps = RouteComponentProps
    & ISieveProductStoreProps
    & ISieveProductDispatchProps
    & ISieveProductOwnProps;

class SieveProduct extends PureComponent<ISieveProductProps, ISieveProductState> {

    public constructor(props: ISieveProductProps) {
        super(props);
        this.state = {
            errors: {
                "product": { key: "product", message: FIELD_VALIDATOR_ERRORS.REQUIRED }
            },
            sieve: {
                id: 0,
                product: "",
                plant_Type: "Dry",
                created: "",
                modified: "",
                created_By: userName,
                modified_By: userName,
                items: []
            }
        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: ISieveProductProps): void {
        if (this.props !== prevProps) {
            this.callServer();

            if (this.props.addSieveProduct !== prevProps.addSieveProduct) {
                if (isSucceeded(this.props.addSieveProduct)) {
                    this.handleCancel();
                };
            };

            if (this.props.updateSieveProduct !== prevProps.updateSieveProduct) {
                if (isSucceeded(this.props.updateSieveProduct)) {
                    this.handleCancel();
                };
            };
        };
    };


    public render(): ReactNode {

        const { sieve, errors } = this.state;
        const { updateSieveProduct, addSieveProduct, getToken } = this.props;
        const sieveAccess = hasPayload(getToken) ? getToken.payload.response.sieveAccess : false;

        const onPlantType = (event: unknown, value: any): void => this.onChange("plant_Type", (value !== null && value !== "") ? value.name : sieve.plant_Type);

        return (
            <PageSpacing title="SIL - Sieve Product" description="Sieve Product" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="sieveAccess">
                    <SieveProductStyles>

                        <LAButton startIcon={<ArrowLeftIcon color={WHITE_COLOR} />} label="Back to list" onClick={this.handleCancel} />
                        <h2 className="text-center">{sieve ? (sieve.id > 0 ? "VIEW/UPDATE " : "ADD ") : "VIEW "} SIEVE PRODUCT</h2>
                        <hr />

                        <LAPaperWithPadding>
                            <LAGrid spacing={3}>

                                <LAGridItem xs={6}>
                                    <LATextField
                                        varient="outlined"
                                        label="Product"
                                        fullWidth={true}
                                        name="product"
                                        onChange={this.onChange}
                                        value={sieve.product ?? ""}
                                        disabled={sieveAccess ? undefined : true}
                                        errorText={errors["product"] ? errors["product"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={6}>
                                <LAAutoComplete
                                        name="plant_Type"
                                        multiple={false}
                                        option={plantTypes}
                                        autoHighlight={true}
                                        getOptionLabel="name"
                                        filterSelectedOptions={true}
                                        dropDownPlaceHolder="Plant Type"
                                        onChange={onPlantType}
                                        selectionRemove={undefinedFunction}
                                        disabled={sieveAccess ? undefined : true}
                                        errorText={errors["plant_Type"] ? errors["plant_Type"].message : undefined}
                                        value={(sieve.plant_Type) ? plantTypes.find(x => x.name === sieve.plant_Type) : ""}
                                        defaultValue={(sieve.plant_Type) ? plantTypes.find(x => x.name === sieve.plant_Type) : ""}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12}>
                                    <DataGrid
                                        keyExpr="id"
                                        showBorders={true}
                                        columnAutoWidth={true}
                                        dataSource={sieve.items}
                                    >
                                        <Paging enabled={false} />
                                        <Editing
                                            mode="batch"
                                            onChangesChange={this.onChangesChange}
                                            allowUpdating={sieveAccess ? true : false}
                                        />

                                        <Column dataField="mesh" caption="Mesh" allowEditing={false} dataType="string" />
                                        <Column dataField="perc_Ret_Spec" caption="Perc Ret Spec" dataType="number" />
                                        <Column dataField="perc_Pass_Spec" caption="Perc Pass Spec" allowEditing={false} dataType="string" />
                                    </DataGrid>

                                </LAGridItem>


                                <LAGridItem xs={12}>
                                    <LASaveAndCancelButton
                                        onSave={this.onSave}
                                        cancelButtonText="Close"
                                        onCancel={this.handleCancel}
                                        disableSave={sieveAccess ? (Object.values(errors).length > 0 ? true : undefined) : true}
                                    />
                                </LAGridItem>

                            </LAGrid>
                        </LAPaperWithPadding>

                        <RequestStatus requestStatus={addSieveProduct.kind} successMessage="Sieve Product successfully saved" />
                        <RequestStatus requestStatus={updateSieveProduct.kind} successMessage="Sieve Product successfully updated" />
                    </SieveProductStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private onChangesChange = (updatedData: { data: any, key: number, type: string }[]): void => {
        const iS = [...this.state.sieve.items];
        
        updatedData.forEach((x: { data: any, key: number, type: string }) => {
            const index = iS.findIndex((q) => q.id === x.key);
            let record = { ...iS[index] };

            record = { ...record, ...x.data };
            iS[index] = record;
        });

        this.setState({ 
            ...this.state,
            sieve: {
                ...this.state.sieve,
                items: iS
            }
         });
    };

    private errorProduct = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, isRequired: boolean): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: isRequired ? true : undefined, minLength: 1, decimal: undefined });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        };
        return err;
    };

    private handleCancel = (): void => {
        this.props.history.push(ROUTE.SIL.SIEVES.SIEVE_PRODUCTS);
    };

    private onSave = async (): Promise<void> => {
        const { sieve } = this.state;


        if (hasPayload(this.props.getToken)) {

            if (sieve.id === 0) {
                this.props.addSieveProductRequest({
                    token: this.props.getToken.payload.response.token,
                    request: sieve
                });
            } else {
                sieve.modified_By = userName;
                this.props.updateSieveProductRequest({
                    token: this.props.getToken.payload.response.token,
                    request: sieve
                });
            };
        };

    };

    private onChange = (name: string, value: string): void => {
        let errors = this.state.errors;
        errors = this.errorProduct(name, value, errors, true);

        this.setState({
            ...this.state,
            errors,
            sieve: {
                ...this.state.sieve,
                [name]: value
            }
        });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getSieveProducts))
            this.props.getSieveProductsRequest({
                token: this.props.getToken.payload.response.token
            });


        const query = queryString.parse(this.props.location.search);
        const id = query.id ?? "0";

        if ((id !== "0") && (hasPayload(this.props.getSieveProducts)) && (this.state.sieve.id === 0)) {
            const sieve = this.props.getSieveProducts.payload.response[id.toString()];

            if (sieve)
                this.setState({ sieve, errors: {} });
        };

    };

}

const mapStateToProps = (state: IStore): ISieveProductStoreProps => ({
    getToken: getTokenStatus(state),
    getSieveProducts: getSieveProductsStatus(state),
    updateSieveProduct: updateSieveProductStatus(state),
    addSieveProduct: addSieveProductStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): ISieveProductDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getSieveProductsRequest: (data: IGetSieveProductsRequest) => dispatch(getSieveProductsLoadAction(data)),
    addSieveProductRequest: (data: IAddSieveProductRequest) => dispatch(addSieveProductLoadAction(data)),
    updateSieveProductRequest: (data: IUpdateSieveProductRequest) => dispatch(updateSieveProductLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(SieveProduct);


const plantTypes = [{ id: 0, name: "Dry" }, { id: 1, name: "Wash"}, { id: 2, name: "both" }];