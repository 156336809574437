import { IPSAttachment } from "../../../../utils/sharedTypes";
import { IPassTokenWithRequest } from "../../dryPlant/token/getTokenConstants";

export enum IGET_TRAILER_ACTIVITY_LOGS_REQUEST {
    REQUEST = "getTrailerActivityLogs/GET_TRAILER_ACTIVITY_LOGS_REQUEST",
    SUCCESS = "getTrailerActivityLogs/GET_TRAILER_ACTIVITY_LOGS_SUCCESS",
    FAILED = "getTrailerActivityLogs/GET_TRAILER_ACTIVITY_LOGS_FAILED"
};

export interface IGetTrailerActivityLogsRequest extends IPassTokenWithRequest {

};

export interface ITrailerActivityLog {
    id: number;
    trailer: string;
    carrier: string;
    status: string;
    in_Service: string;
    out_Of_Service: string;
    comments: string;
    created: string;
    created_By: string;
    modified: string;
    modified_By: string;
    services: ITrailerActivityLogServices[];
    replacements: ITrailerActivityLogTireReplacements[];
};

export interface ITrailerActivityLogServices {
    id: number;
    list_ID?: number;
    service_TypeInfo: string;
    km_At_Service: string;
    service_Date: string;
    work_Order_Number: string;
    comments: string;
    mechanics: string;
    attachment: string;
    created: string ;
    created_By: string;
    modified: string;
    modified_By: string;
    attachmentContent?: IPSAttachment[];
}


export interface ITrailerActivityLogTireReplacements {
    id: number;
    list_ID: number;
    km_At_Replacement: string;
    replacement_Date: string;
    work_Order_Number: string;
    condition: string;
    brand: string;
    comments: string;
    FLi: boolean;
    FLO: boolean;
    FRI: boolean;
    FRO: boolean;
    MLi: boolean;
    MLO: boolean;
    MRI: boolean;
    MRO: boolean;
    RLi: boolean;
    RLO: boolean;
    RRi: boolean;
    RRO: boolean;
    created: string;
    created_By: string;
    modified: string;
    modified_By: string;
}