

async function getSIMOSTransportCompany():Promise<string[]> {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: '19515195-d571-44a3-b0db-637f5bf24f54' })
    };
    let allTransports:string[] = [];
      
    fetch('https://api2.propsense.com/api/GetSIMOSTransportCompany', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
        // error response
        if(!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        // no error
        data.map((company_name:any) => {
            if(company_name.active === "Yes"){
                allTransports.push(company_name.transport_Company);
            }
        });
    })
    .catch(error => {
        console.log('There was an error!', error);
    });
    return allTransports;
}

export default getSIMOSTransportCompany;