import { ITruckScale } from "../getTruckScales/getTruckScalesConstaints";

export enum IADD_TRUCK_SCALE_REQUEST {
    REQUEST = "addTruckScale/ADD_TRUCK_SCALE_REQUEST",
    SUCCESS = "addTruckScale/ADD_TRUCK_SCALE_SUCCESS",
    FAILED = "addTruckScale/ADD_TRUCK_SCALE_FAILED"
};

export interface IAddTruckScaleRequest {
    token: string;
    request: ITruckScale;
};