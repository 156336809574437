import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IAddCarrierEmailLoadAction, IAddCarrierEmailLoadFailedAction, IAddCarrierEmailSuccessAction, 
    addCarrierEmailLoadFailedAction, addCarrierEmailLoadSuccessAction
} from "./addCarrierEmailActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IADD_CARRIER_EMAIL_REQUEST } from "./addCarrierEmailConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";

export const addCarrierEmailEpic: Epic = (
    action$: ActionsObservable<IAddCarrierEmailLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IAddCarrierEmailSuccessAction | IAddCarrierEmailLoadFailedAction> =>
    action$.ofType(IADD_CARRIER_EMAIL_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<string>>(
                    END_POINTS.PROPSENSE.SIL.CARRIER.ADD_CARRIER_EMAIL,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<string>): IAddCarrierEmailSuccessAction | IAddCarrierEmailLoadFailedAction => {
                            if(response.message === "Success"){
                                return addCarrierEmailLoadSuccessAction(response.message);
                            };
                            return addCarrierEmailLoadFailedAction(response.message)
                        }),
                        catchError(() => ActionsObservable.of(addCarrierEmailLoadFailedAction("Unable to add carrier emails")))
                    )
            )
        );