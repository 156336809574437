import { IADD_SIMOS_TRANSPORT_COMPANY_REQUEST} from "./addSimosTransportCompanyConstaints";
import { IAddSimosTransportCompanyLoadAction, IAddSimosTransportCompanyLoadFailedAction, IAddSimosTransportCompanySuccessAction } from "./addSimosTransportCompanyActions";
import { failed, loading, notLoaded, Server, succeeded } from "../../../../server";
import { IFlushDataSuccessAction } from '../../../../flushData.tsx/flushDataActions';
import { FLUSH_DATA } from '../../../../flushData.tsx/flushDataConstants';

type Actions =
    IAddSimosTransportCompanyLoadAction
    | IAddSimosTransportCompanyLoadFailedAction
    | IAddSimosTransportCompanySuccessAction
    | IFlushDataSuccessAction;

export const AddSimosTransportCompanyReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IADD_SIMOS_TRANSPORT_COMPANY_REQUEST.REQUEST:
            return loading;

        case IADD_SIMOS_TRANSPORT_COMPANY_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IADD_SIMOS_TRANSPORT_COMPANY_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};