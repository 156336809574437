import React, {Component} from 'react';
import '../../../../shared/simos/styling/siteinfostyles.css';
import {hasInputError} from './formValidation';
import isUnique, {isDisabled} from '../APICalls/checkPONumExists';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { FormHelperText } from '@material-ui/core';
import {BsPlusCircle} from 'react-icons/bs';
import CreateTransportCompanyPopup from '../../../../shared/simos/helper/createTransportCompanyPopup';

interface IProps{
    history:any;
}

interface IState {
    site_contact: string;
    po_num: string;
    site_num: string;
    email_check: boolean;
    email_transport: boolean;
    truck_req: [];
    trns_company: string;
    all_transports:string[];
    prepaid:string;
    transport_notes:string;
    zone:string;
    hired_truck_rate:number;
    truck_code:string;
    showCreateTransportForm:boolean;
    collectCheck:boolean;
    customer_job_number:string;
}
var allSiteInfo = {site_contact: '', po_num:'', site_num:'', email_check:false, email_transport:false, truck_req:[],trns_company:'', prepaid:'', transport_Company_Notes:'',
zone:'', hired_truck_rate:0,truck_code:'', customer_job_number:''}
let optionTransport:any[] = [];

class SiteInfo extends Component<IProps, IState> {
    constructor(props: any) {
        super(props);
        this.state = {
            site_contact: '', po_num: '', site_num: '', email_check: false, email_transport: false, 
            truck_req: [], trns_company: '',all_transports:[], prepaid:'', transport_notes:'', zone:'', hired_truck_rate:0, truck_code:'',showCreateTransportForm:false, collectCheck:false, customer_job_number:''
        }
        this.onTextChange = this.onTextChange.bind(this);
        this.handleCancelTransportCompany = this.handleCancelTransportCompany.bind(this);
        this.refreshTransportCompanies = this.refreshTransportCompanies.bind(this);
    }  
    refreshTransportCompanies(event:any){
        let allTransports:string[] = [];
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token: '19515195-d571-44a3-b0db-637f5bf24f54' })
        };
        fetch('https://api2.propsense.com/api/GetSIMOSTransportCompany', requestOptions)
        .then(async response => {
            const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
            // error response
            if(!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            // no error
            data.map((company_name:any) => {
                if(company_name.active === "Yes"){
                    allTransports.push(company_name.transport_Company);
                }
            });
            this.setState({all_transports: allTransports});
        })
        .catch(error => {
            console.log('There was an error!', error);
        });
        
    }
    handleAddTransportCompany(event:any){
        this.setState({showCreateTransportForm:true});
    }
    handleCancelTransportCompany(event:any){
        this.setState({showCreateTransportForm:false});
    }
    private onTextChange (event: any) {
        const fieldName = event.target.name;
        switch(fieldName) {
            case 'sitecontact':
                this.setState({site_contact:event.target.value});
                break;
            case 'sitecontact_num':
                this.setState({site_num:event.target.value});
                break;
            case 'po_num':
                // po_num_error holds the recent PO_Num typed to display on error
                //po_num_error = this.state.po_num;
                this.setState({po_num:event.target.value});
                // Check if the new PO_Num is unique
                isUnique(event.target.value); 
                break;
            case 'emailcheck':
                this.setState(initialState => ({email_check: !initialState.email_check}));
                break;
            case 'transportcheck':
                this.setState(initialState => ({email_transport: !initialState.email_transport}));
                break;
            case 'prepaid_collect':
                this.setState({prepaid: event.target.value});
                // If notes are filled but the choice to change back to prepaid was chosen, change transport notes back to N/A
                if(event.target.value === "prepaid"){
                    this.setState({transport_notes:"N/A"});
                }
                else{
                    this.setState({zone:'00', truck_code:'', hired_truck_rate:0});
                }
                break;
            case 'trnscompany':
                this.setState({trns_company:event.target.value});
                if(event.target.value!='Yellowhead Trucking'){
                    this.setState({truck_code:''});
                }
                break;
            case 'truckreq':
                this.setState({truck_req:event.target.value});
                break;
            case 'transport_notes':
                this.setState({transport_notes:event.target.value});
                break;
            case 'zone':
                this.setState({zone:event.target.value});
                break;
            case 'hired_truck_rate':
                this.setState({hired_truck_rate:event.target.value});
                break;
            case 'truck_code':
                this.setState({truck_code:event.target.value});
                break;
            case 'collect_check':
                this.setState(initialState => ({collectCheck: !initialState.collectCheck}));
                if(this.state.collectCheck){
                    this.setState({transport_notes:"N/A", prepaid:"prepaid"});
                }
                else{
                    this.setState({zone:'00', truck_code:'', hired_truck_rate:0, prepaid:"collect"});
                }
                break;
            case 'customer_job_number':
                this.setState({customer_job_number:event.target.value});
                break;
        }
    }

    componentDidMount() {
        let allTransports:string[] = [];
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token: '19515195-d571-44a3-b0db-637f5bf24f54' })
        };
        fetch('https://api2.propsense.com/api/GetSIMOSTransportCompany', requestOptions)
        .then(async response => {
            const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
            // error response
            if(!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            // no error
            data.map((company_name:any) => {
                if(company_name.active === "Yes"){
                    allTransports.push(company_name.transport_Company);
                }
            });
            this.setState({all_transports: allTransports});
        })
        .catch(error => {
            console.log('There was an error!', error);
        });
        
    }
   

    public render() {
        let transports = this.state.all_transports;
        let transCompany = this.state.trns_company;
        let truckReq:any = this.state.truck_req;
        if(this.state.prepaid === 'collect'){
            transCompany = "Customer Pickup";
            truckReq = "Customer Pickup";
        }
        allSiteInfo = {site_contact: this.state.site_contact, po_num:this.state.po_num,
             site_num:this.state.site_num, email_check:this.state.email_check, email_transport:this.state.email_transport, 
             truck_req:truckReq,trns_company:transCompany, prepaid:this.state.prepaid, transport_Company_Notes:this.state.transport_notes,
            zone:this.state.zone, hired_truck_rate:this.state.hired_truck_rate, truck_code:this.state.truck_code, customer_job_number:this.state.customer_job_number};
        optionTransport = transports.map((company) => <option>{company}</option>);
        return (
                <Grid container spacing={5}>
                    <Grid item xs={3}>
                        <TextField autoComplete='nope' id="standard-required" name="sitecontact" label="Site Contact" onChange={this.onTextChange}></TextField>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField autoComplete='nope' id="standard-required" name="sitecontact_num" label="Site Contact Phone" onChange={this.onTextChange}></TextField>
                    </Grid>
           
                    <Grid item xs={3}>
                        <TextField autoComplete='nope'  id="standard-required" name="po_num" label="PO #" onChange={this.onTextChange}></TextField>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField autoComplete='nope'  id="standard-required" name="customer_job_number" label="Customer Job #" onChange={this.onTextChange}></TextField>
                    </Grid>
               {/* <Grid item xs={3}>
               <RadioGroup name="prepaid_collect" value={this.state.prepaid} onChange={this.onTextChange}>
                                    <FormControlLabel value="prepaid" control={<Radio />} label="Prepaid" />
                                    <FormControlLabel value="collect" control={<Radio />} label="Collect" />
                                </RadioGroup>
               </Grid> */}
               <Grid item xs={3}>
                        <FormControlLabel control={
                                          <Checkbox checked={this.state.collectCheck} onChange={this.onTextChange} name="collect_check" color="primary"/>
                            } 
                            label="Collect"
                        />
                     </Grid>
                <Grid item xs={3}>
                    {this.state.prepaid === 'collect' ? <><InputLabel htmlFor="transport-native-default">Transport Company</InputLabel>
                            <TextField disabled defaultValue="Customer Pickup">
                        </TextField>  </> :
                        //  <TextField autoComplete='off' id="standard-required" name="trnscompany" label="Transport Company" onChange={this.onTextChange}></TextField>
                    <>
                    <FormControl>
                        <InputLabel htmlFor="transport-native-simple">Transport Company</InputLabel>
                            <Select native value = {this.state.trns_company} onChange={this.onTextChange} inputProps={{name:'trnscompany',id:'transport-native-simple'}}>
                            <option disabled value=""></option>
                            {optionTransport}
                        </Select>
                    </FormControl>
                    <button onClick={this.handleAddTransportCompany.bind(this)} id='create-customer'><BsPlusCircle/></button> 
                    </>
                    }
                         {this.state.showCreateTransportForm? <CreateTransportCompanyPopup refresh={this.refreshTransportCompanies} cancelPopup={this.handleCancelTransportCompany}></CreateTransportCompanyPopup>:null}
                </Grid>
                <Grid item xs={3}>
                {this.state.prepaid === 'collect' ? <><InputLabel htmlFor="transport-native-default">Truck Size</InputLabel>
                            <TextField disabled defaultValue="Customer Pickup">
                        </TextField></>
                        :
                    <FormControl>
                    <InputLabel htmlFor="truckreq-native-simple">Truck Size</InputLabel>
                    <Select native value = {this.state.truck_req} onChange={this.onTextChange} inputProps={{name:'truckreq', id:'truckreq-native-simple'}}>
                        <option disabled selected></option>
                        <option>Single Bulk</option>
                        <option>Single Deck</option>
                        <option>BTrain Bulk</option>
                        <option>BTrain Deck</option>
                        <option>Super BTrain Bulk</option>
                        <option>Super BTrain Deck</option>
                        <option>Truck and Pup</option>
                        <option>Tandem</option>
                        <option>End Dump</option>
                        <option>Truck and Wagon</option>
                        <option>Triaxle Deck</option>
                        <option>Triaxle Bulk</option>
                        <option>Customer Pickup</option>
                    </Select>
                    </FormControl>
                }
                </Grid> 
                {this.state.prepaid === 'collect' ?
                <Grid item xs={3}>
                <TextField autoComplete='off' value = {this.state.transport_notes} multiline rows={2} variant="outlined"  id="standard-required" name="transport_notes" label="Transport Company/Truck Size Notes" onChange={this.onTextChange}></TextField>
                </Grid>
         
                :null}
                {this.state.trns_company === 'Yellowhead Trucking' && this.state.prepaid==='prepaid'?  
                    <Grid item xs={3}>
                    <TextField autoComplete='off' value = {this.state.truck_code} variant="outlined"  id="standard-required" name="truck_code" label="Truck Code" onChange={this.onTextChange}></TextField>
                    <FormHelperText style={{color:'orange'}}>Required</FormHelperText>
                    </Grid>
                :null}
                 <Grid item xs={3}>
                    <TextField autoComplete='off' value = {this.state.zone} variant="outlined"  id="standard-required" name="zone" label="Zone" onChange={this.onTextChange}></TextField>
                </Grid>
                {/* <Grid item xs={3}>
                    <TextField type="number" autoComplete='off' value = {this.state.hired_truck_rate} variant="outlined"  id="standard-required" name="hired_truck_rate" label="Truck Hired Rate" onChange={this.onTextChange}></TextField>
                    {this.state.zone==='00' && this.state.truck_code!=''?<FormHelperText style={{color:'orange'}}>Required</FormHelperText>:null}
                </Grid> */}
                {/* <Grid item xs={3}>
                        <FormControlLabel control={
                                          <Checkbox checked={this.state.email_check} onChange={this.onTextChange} name="emailcheck" color="primary"/>
                            } 
                            label="Email User"
                        />
                     </Grid> */}

                     {/* <Grid item xs={3}>
                     <FormControlLabel control={
                                          <Checkbox checked={this.state.email_transport} onChange={this.onTextChange} name="transportcheck" color="primary"/>
                            } 
                            label="Email Transport"
                        /> 
                     </Grid> */}
                </Grid>
        );
    }
}

export default SiteInfo;
export {allSiteInfo};
export {optionTransport};