import { IPassTokenWithRequest } from "../../dryPlant/token/getTokenConstants";
import { IAddComplaintLogging } from "../addComplaintLogging/addComplaintLoggingConstaints";

export enum IUPDATE_COMPLAINT_LOGGING_REQUEST {
    REQUEST = "updateComplaintLogging/UPDATE_COMPLAINT_LOGGING_REQUEST",
    SUCCESS = "updateComplaintLogging/UPDATE_COMPLAINT_LOGGING_SUCCESS",
    FAILED = "updateComplaintLogging/UPDATE_COMPLAINT_LOGGING_FAILED"
};

export interface IUpdateComplaintLoggingRequest extends IPassTokenWithRequest {
    request: IAddComplaintLogging;
};