import { IFlushDataSuccessAction } from "../../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../../server";
import { ISaveYHDCarrierFormLoadAction, ISaveYHDCarrierFormLoadFailedAction, ISaveYHDCarrierFormSuccessAction } from "./saveCarrierFormActions";
import { ISAVE_YHD_CARRIER_FORM_REQUEST } from "./saveCarrierFormConstaints";


type Actions =
    ISaveYHDCarrierFormLoadAction
    | ISaveYHDCarrierFormLoadFailedAction
    | ISaveYHDCarrierFormSuccessAction
    | IFlushDataSuccessAction;

export const SaveYHDCarrierFormReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case ISAVE_YHD_CARRIER_FORM_REQUEST.REQUEST:
            return loading;

        case ISAVE_YHD_CARRIER_FORM_REQUEST.SUCCESS:
            return succeeded("Success");

        case ISAVE_YHD_CARRIER_FORM_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};