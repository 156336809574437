import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetBinAdjustmentsRequest, IGET_BIN_ADJUSTMENTS_REQUEST, IBinAdjustments } from "./getBinAdjustmentsConstaints";

export interface IGetBinAdjustmentsLoadAction {
    type: IGET_BIN_ADJUSTMENTS_REQUEST.REQUEST;
    data: IGetBinAdjustmentsRequest
}
export const getBinAdjustmentsLoadAction = (data: IGetBinAdjustmentsRequest): IGetBinAdjustmentsLoadAction => ({
    type: IGET_BIN_ADJUSTMENTS_REQUEST.REQUEST,
    data
});

export interface IGetBinAdjustmentsSuccessAction {
    type: IGET_BIN_ADJUSTMENTS_REQUEST.SUCCESS;
    data: SilAPIResponse<IBinAdjustments[]>;
}
export const getBinAdjustmentsLoadSuccessAction = (data: SilAPIResponse<IBinAdjustments[]>): IGetBinAdjustmentsSuccessAction => ({
    type: IGET_BIN_ADJUSTMENTS_REQUEST.SUCCESS,
    data
});

export interface IGetBinAdjustmentsLoadFailedAction {
    type: IGET_BIN_ADJUSTMENTS_REQUEST.FAILED;
    message: string;
}
export const getBinAdjustmentsLoadFailedAction = (message: string): IGetBinAdjustmentsLoadFailedAction => ({
    type: IGET_BIN_ADJUSTMENTS_REQUEST.FAILED,
    message
});
