import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IAddSimosProductLoadAction, 
    IAddSimosProductLoadFailedAction, 
    IAddSimosProductSuccessAction, 
    addSimosProductLoadFailedAction, addSimosProductLoadSuccessAction
} from "./addSimosProductActions";
import { IADD_SIMOS_PRODUCT_REQUEST } from "./addSimosProductConstaints";
import { EpicDependencies } from "../../../../store";
import { SimosAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { END_POINTS } from "../../../../endpoints";
import { withJsonContentType } from "../../../../epicUtils";
import { IStore } from "../../../../reducers";

export const addSimosProductEpic: Epic = (
    action$: ActionsObservable<IAddSimosProductLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IAddSimosProductSuccessAction | IAddSimosProductLoadFailedAction> =>
    action$.ofType(IADD_SIMOS_PRODUCT_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SimosAPIResponse<string>>(
                    END_POINTS.PROPSENSE.SIMOS.ADD_SIMOS_PRODUCT,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SimosAPIResponse<string>): IAddSimosProductSuccessAction | IAddSimosProductLoadFailedAction => {
                            if(response.message === "Success"){
                                return addSimosProductLoadSuccessAction(response.message);
                            };
                            return addSimosProductLoadFailedAction(response.message)
                        }),
                        catchError(() => ActionsObservable.of(addSimosProductLoadFailedAction("Unable to add Simos product")))
                    )
            )
        );