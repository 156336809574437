import { IPassTokenWithRequest } from "../../dryPlant/token/getTokenConstants";

export enum IGET_TRANSLOAD_BOL_COUNTS_REQUEST {
    REQUEST = "getTransloadBOLCounts/GET_TRANSLOAD_BOL_COUNTS_REQUEST",
    SUCCESS = "getTransloadBOLCounts/GET_TRANSLOAD_BOL_COUNTS_SUCCESS",
    FAILED = "getTransloadBOLCounts/GET_TRANSLOAD_BOL_COUNTS_FAILED"
};

export interface IGetTransloadBOLCountsRequest extends IPassTokenWithRequest {

};

export interface ITransloadBOLCount {
    id: number;
    date: string;
    transload: string;
    customer_BOL_Submitted: string;
    direct_BOL_Submitted: string;
    trans_BOL_Submitted: string;
    created: string;
    created_By: string;
    modified: string;
    modified_By: string;
};