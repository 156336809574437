import { IPassTokenWithRequest } from "../../../../sil/dryPlant/token/getTokenConstants";
import { IAddYHDCarrierEmailAttachment } from "../addCarrierEmail/addCarrierEmailConstaints";

export enum IUPDATE_YHD_CARRIER_EMAIL_REQUEST {
    REQUEST = "updateYHDCarrierEmail/UPDATE_YHD_CARRIER_EMAIL_REQUEST",
    SUCCESS = "updateYHDCarrierEmail/UPDATE_YHD_CARRIER_EMAIL_SUCCESS",
    FAILED = "updateYHDCarrierEmail/UPDATE_YHD_CARRIER_EMAIL_FAILED"
};

export interface IUpdateYHDCarrierEmailRequest extends IPassTokenWithRequest {
    request: {
        id: number;
        subject: string;
        email_Body: string;
        email_All_Carriers: string;
        carriers: number[];
        attachments: IAddYHDCarrierEmailAttachment[];
        attachment_Name?: string;
        created_By: string;
        modified_By: string;
    };
};
