import { IProductionRecords } from "../getProductionRecords/getProductionRecordsConstaints";

export enum IUPDATE_PRODUCTION_REQUEST {
    REQUEST = "updateProduction/UPDATE_PRODUCTION_REQUEST",
    SUCCESS = "updateProduction/UPDATE_PRODUCTION_SUCCESS",
    FAILED = "updateProduction/UPDATE_PRODUCTION_FAILED"
};

export interface IUpdateProductionRequest {
    token: string;
    request: IProductionRecords;
};