import React from "react";
import styled from "styled-components";
import { RouteComponentProps } from "react-router-dom";
import { MAIN_COLOR, MEDIA_QUERY_PHONE } from "../shared/theme";
import { logo } from "../shared/icons";
import { LAButton } from "../shared/buttons";
import { authContext } from "../../adalConfig";
import headerBack from "../../headerImg.png";
import { ROUTE } from "../routes";
import SilHeader from "./dryPlantHeader";
import WashPlantHeader from "./washPlantHeader";
import CarrierHeader from "./carrierHeader";
import YHDCarrierHeader from "./yhdCarrierHeader";
import SieveHeader from "./sieveHeader";
import CVHeader from "./cvHeader";

const HeaderStyles = styled.div`
    .main-header {
        background-image: url(${headerBack});
        background-color: #cccccc;
        width: 100%;
        display: flex;
        align-items: center;
        height: 45px;

        .logo-container {
            display: flex;
            align-items: center;
            color: ${MAIN_COLOR};
            padding: 1rem 2rem;
            margin-right: 1.5rem; 
        }

        .right-side {
            margin-left: auto;
            margin-right: 1rem;
        }
    };

    .sub-header {
        display: flex;
        align-items: center;
        background-color: #fff;
        width: 100%;
        height: 45px;
        box-shadow: 0 2px 10px 0 rgba(204, 204, 204, 0.5);
    };

    .logo-img {
        padding-left: 40px;
        width: 200px;
        height: 35px;
    };

    .upn {
        margin-left: auto;
        margin-right: -70rem;
        color: #bf0000;
    };

    .small-logo {
        width: 45px;
        height: 45px;
    };

    .logo-img:hover {
        cursor: pointer;
    }

    .show-on-phone {
        display: none;
    };

    @media (max-width: ${MEDIA_QUERY_PHONE}) {
        .hide-on-phone, .hide-on-phone * {
            display: none;
        }
        .main-header .logo-container {
            margin-right: 0.5rem;
            padding: 0, 1rem, 1rem, 1rem;
        }
        .main-header .logo-container img {
            margin-right: 0;
        }
    };
`;

// temporary, should be deleted before going to production
export const doNothing = (): void => { };

interface IHeader extends RouteComponentProps {
    userName: string;
}

const Header: React.FC<IHeader> = React.memo((props: IHeader) => {
    const onLogoClick = (): void => props.history.push(ROUTE.INDEX);

    return <HeaderStyles>
        <div className="main-header">
            <img src={logo} className="logo-img" alt="logo" title="logo" onClick={onLogoClick} />

            <strong className="upn hide-on-phone">
                    Hello, {props.userName ? props.userName.split('.')[0].charAt(0).toUpperCase() + props.userName.split('.')[0].slice(1) : ''}
                </strong>

            <div className="right-side">

                <LAButton label="Log Off" onClick={() => authContext.logOut()} />
            </div>
        </div>

        {props.location.pathname.includes(ROUTE.SIL.DRY_PLANT.INDEX) && <SilHeader {...props} />}
        {props.location.pathname.includes(ROUTE.SIL.WASH_PLANT.INDEX) && <WashPlantHeader {...props} />}
        {props.location.pathname.includes(ROUTE.SIL.CARRIERS.INDEX) && <CarrierHeader {...props} />}
        {props.location.pathname.includes(ROUTE.PROPSENSE.YHD.CARRIERS.INDEX) && <YHDCarrierHeader {...props} />}
        {props.location.pathname.includes(ROUTE.SIL.SIEVES.INDEX) && <SieveHeader {...props} />}
        {props.location.pathname.includes(ROUTE.SIL.CV.INDEX) && <CVHeader {...props} />}
    </HeaderStyles>
}
);

export default Header;