import { IPassTokenWithRequest } from "../../dryPlant/token/getTokenConstants";
import { ITransloadBOLCount } from "../getTransloadBOLCounts/getTransloadBOLCountsConstaints";


export enum IUPDATE_TRANSLOAD_BOL_COUNT_REQUEST {
    REQUEST = "updateTransloadBOLCount/UPDATE_TRANSLOAD_BOL_COUNT_REQUEST",
    SUCCESS = "updateTransloadBOLCount/UPDATE_TRANSLOAD_BOL_COUNT_SUCCESS",
    FAILED = "updateTransloadBOLCount/UPDATE_TRANSLOAD_BOL_COUNT_FAILED"
};

export interface IUpdateTransloadBOLCountRequest extends IPassTokenWithRequest {
    request: ITransloadBOLCount;
};