import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, isSucceeded, Server } from "../../../../redux/server";
import { ById, SilAPIResponse } from "../../../shared/publicInterfaces";
import { userName, undefinedFunction, monthNames, getYearsList, yesOrNoOptions } from "../../../shared/constExports";
import { LAButton, LASaveAndCancelButton } from "../../../shared/buttons";
import { ArrowLeftIcon } from "../../../shared/icons";
import { WHITE_COLOR } from "../../../shared/theme";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import LATextField from "../../../shared/textField";
import LAAutoComplete from "../../../shared/autoComplete";
import { ROUTE } from "../../../routes";
import queryString from "query-string";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import { FieldValidator, FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../../shared/fieldValidator";
import PageSpacing from "../../../shared/pageSpacing";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import SilRoleCheck from "../../../shared/silRoleCheck";
import { IGetSieveDryPlantRequest, ISieveDryPlant } from "../../../../redux/sil/cv/sieveDryPlant/getSieveDryPlants/getSieveDryPlantsConstaints";
import { IAddSieveDryPlantRequest } from "../../../../redux/sil/cv/sieveDryPlant/addSieveDryPlant/addSieveDryPlantConstaints";
import { IUpdateSieveDryPlantRequest } from "../../../../redux/sil/cv/sieveDryPlant/updateSieveDryPlant/updateSieveDryPlantConstaints";
import { getSieveDryPlantStatus } from "../../../../redux/sil/cv/sieveDryPlant/getSieveDryPlants/getSieveDryPlantsAccessor";
import { addSieveDryPlantStatus } from "../../../../redux/sil/cv/sieveDryPlant/addSieveDryPlant/addSieveDryPlantAccessor";
import { updateSieveDryPlantStatus } from "../../../../redux/sil/cv/sieveDryPlant/updateSieveDryPlant/updateSieveDryPlantAccessor";
import { getSieveDryPlantLoadAction } from "../../../../redux/sil/cv/sieveDryPlant/getSieveDryPlants/getSieveDryPlantsActions";
import { addSieveDryPlantLoadAction } from "../../../../redux/sil/cv/sieveDryPlant/addSieveDryPlant/addSieveDryPlantActions";
import { updateSieveDryPlantLoadAction } from "../../../../redux/sil/cv/sieveDryPlant/updateSieveDryPlant/updateSieveDryPlantActions";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { oneForthSagList } from "./sieveWashPlant";

interface ISieveDryPlantStoreProps {
    addSieveDryPlant: Server<string>;
    updateSieveDryPlant: Server<string>;
    getToken: Server<SilAPIResponse<IToken>>;
    getSieveDryPlants: Server<SilAPIResponse<ISieveDryPlant[]>>;
};

interface ISieveDryPlantDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getSieveDryPlantsRequest: (data: IGetSieveDryPlantRequest) => unknown;
    addSieveDryPlantRequest: (data: IAddSieveDryPlantRequest) => unknown;
    updateSieveDryPlantRequest: (data: IUpdateSieveDryPlantRequest) => unknown;
};


interface ISieveDryPlantOwnProps {

};

interface ISieveDryPlantState {
    unSavedDownTime: boolean;
    sieveDryPlant: ISieveDryPlant;
    errors: ById<IFieldErrorKeyValue>;
};

const SieveDryPlantStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
`;

type ISieveDryPlantProps = RouteComponentProps
    & ISieveDryPlantStoreProps
    & ISieveDryPlantDispatchProps
    & ISieveDryPlantOwnProps;

class SieveDryPlant extends PureComponent<ISieveDryPlantProps, ISieveDryPlantState> {

    public constructor(props: ISieveDryPlantProps) {
        super(props);
        this.state = {
            errors: {
                "employee": { key: "employee", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "month": { key: "month", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "year": { key: "year", message: FIELD_VALIDATOR_ERRORS.REQUIRED }
            },
            unSavedDownTime: false,
            sieveDryPlant: {
                id: 0,
                employee: "",
                is_Calibration_Good: yesOrNoOptions[0].name,
                completed: yesOrNoOptions[0].name,
                calibrations: [],
                created: "",
                created_By: userName,
                modified: "",
                modified_By: userName
            }
        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: ISieveDryPlantProps): void {
        if (this.props !== prevProps) {
            this.callServer();

            if (this.props.addSieveDryPlant !== prevProps.addSieveDryPlant) {
                if (isSucceeded(this.props.addSieveDryPlant)) {
                    this.handleCancel();
                };
            };

            if (this.props.updateSieveDryPlant !== prevProps.updateSieveDryPlant) {
                if (isSucceeded(this.props.updateSieveDryPlant)) {
                    this.handleCancel();
                };
            };
        };
    };


    public render(): ReactNode {

        const { updateSieveDryPlant, addSieveDryPlant } = this.props;
        const { sieveDryPlant, errors } = this.state;

        const isAdd = sieveDryPlant.id > 0 ? false : true;
        const years = getYearsList(2018);
        const onDevExtremeEdit = (): void => this.handleEditMode();

        return (
            <PageSpacing title="SIL - CV" description="SieveDryPlant" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="carrierAccess">
                    <SieveDryPlantStyles>

                        <LAButton startIcon={<ArrowLeftIcon color={WHITE_COLOR} />} label="Back to list" onClick={this.handleCancel} />
                        <h2 className="text-center">{!isAdd ? "VIEW " : "ADD "} DRY PLANT SIEVE CALIBRATION</h2>
                        <hr />

                        <LAPaperWithPadding>
                            <LAGrid spacing={3}>

                                <LAGridItem xs={4}>
                                <LAAutoComplete
                                        filterSelectedOptions={true}
                                        autoHighlight={true}
                                        selectionRemove={undefinedFunction}
                                        dropDownPlaceHolder="Month"
                                        getOptionLabel="name"
                                        name="month"
                                        option={monthNames}
                                        multiple={false}
                                        onChange={this.handleDropDownIdChange}
                                        value={sieveDryPlant.month ? monthNames.find(x => x.id === sieveDryPlant.month) : ""}
                                        defaultValue={sieveDryPlant.month ? monthNames.find(x => x.id === sieveDryPlant.month) : ""}
                                        errorText={errors["month"] ? errors["month"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                <LAAutoComplete
                                        filterSelectedOptions={true}
                                        autoHighlight={true}
                                        selectionRemove={undefinedFunction}
                                        dropDownPlaceHolder="Year"
                                        getOptionLabel="name"
                                        name="year"
                                        option={years}
                                        multiple={false}
                                        onChange={this.handleDropDownIdChange}
                                        value={sieveDryPlant.year ? years.find(x => x.id === sieveDryPlant.year) : ""}
                                        defaultValue={sieveDryPlant.year ? years.find(x => x.id === sieveDryPlant.year) : ""}
                                        errorText={errors["year"] ? errors["year"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                <LATextField
                                        varient="outlined"
                                        label="Employee"
                                        fullWidth={true}
                                        name="employee"
                                        value={sieveDryPlant.employee ?? ""}
                                        onChange={this.onChange}
                                        errorText={errors["employee"] ? errors["employee"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12}>
                                    <LADevExtremeGrid
                                        add={true}
                                        height={500}
                                        id="sieveDryPlant"
                                        columnWidth={200}
                                        searchPanel={false}
                                        filterHeader={false}
                                        moveBtnToLeft={true}
                                        data={sieveDryPlant.calibrations}
                                        onDelete={onDevExtremeEdit}
                                        onCustomEdit={this.onDTEdit}
                                        onClick={undefinedFunction}
                                        onEditSave={onDevExtremeEdit}
                                        onEditStart={onDevExtremeEdit}
                                        onEditCancel={onDevExtremeEdit}
                                        onNewRowInsert={onDevExtremeEdit}
                                        columns={[
                                            { name: "mesh", caption: "Mesh", dropDownId: "id", dropDownName: "name" },
                                            { name: "visual", caption: "Visual", type: "number", width: 110 },
                                            { name: "one_Fourth_Sag", caption: "1/4 Sag", dropDownId: "id", dropDownName: "name", dropDownSource: oneForthSagList },
                                            { name: "serial_Number", caption: "Serial Number", type: "number", width: 100 },
                                            { name: "fail_Description", caption: "fail_Description", type: "string" },
                                        ]}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LAAutoComplete
                                        filterSelectedOptions={true}
                                        autoHighlight={true}
                                        selectionRemove={undefinedFunction}
                                        dropDownPlaceHolder="Completed"
                                        getOptionLabel="name"
                                        name="completed"
                                        option={yesOrNoOptions}
                                        multiple={false}
                                        onChange={this.handleDropDownChange}
                                        value={yesOrNoOptions.find(x => x.name === sieveDryPlant.completed)}
                                        defaultValue={yesOrNoOptions.find(x => x.name === sieveDryPlant.completed)}
                                        errorText={errors["completed"] ? errors["completed"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                <LAAutoComplete
                                        filterSelectedOptions={true}
                                        autoHighlight={true}
                                        selectionRemove={undefinedFunction}
                                        dropDownPlaceHolder="Is Calibration Good ?"
                                        getOptionLabel="name"
                                        name="is_Calibration_Good"
                                        option={yesOrNoOptions}
                                        multiple={false}
                                        onChange={this.handleDropDownChange}
                                        value={yesOrNoOptions.find(x => x.name === sieveDryPlant.is_Calibration_Good)}
                                        defaultValue={yesOrNoOptions.find(x => x.name === sieveDryPlant.is_Calibration_Good)}
                                        errorText={errors["is_Calibration_Good"] ? errors["is_Calibration_Good"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12}>
                                    <hr />
                                    <LASaveAndCancelButton
                                        onSave={this.onSave}
                                        onCancel={this.handleCancel}
                                        disableSave={(Object.values(errors).length > 0 ? true : undefined)}
                                    />
                                </LAGridItem>

                            </LAGrid>
                        </LAPaperWithPadding>

                        <RequestStatus requestStatus={addSieveDryPlant.kind} successMessage="SieveDryPlant successfully saved" />
                        <RequestStatus requestStatus={updateSieveDryPlant.kind} successMessage="SieveDryPlant successfully updated" />
                    </SieveDryPlantStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private onDTEdit = (): void => {
    };

    private handleEditMode = (): void => {
        this.setState({ unSavedDownTime: !this.state.unSavedDownTime });
    };

    private onChange = (name: string, value: string): void => {
        let errors = this.state.errors;
        errors = this.errorChecker(name, value, errors, true);

        this.setState({
            ...this.state,
            errors,
            sieveDryPlant: {
                ...this.state.sieveDryPlant,
                [name]: value
            }
        });
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, isRequired: boolean): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: isRequired ? true : undefined, minLength: 1, decimal: undefined });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        };
        return err;
    };

    private handleCancel = (): void => {
        this.props.history.push(ROUTE.SIL.CV.SIEVE_DRY_PLANTS.INDEX);
    };

    private onSave = (): void => {
        const { sieveDryPlant } = this.state;

        if (hasPayload(this.props.getToken))
            if (sieveDryPlant.id === 0) {
                this.props.addSieveDryPlantRequest({
                    token: this.props.getToken.payload.response.token,
                    request: sieveDryPlant
                });
            } else {
                sieveDryPlant.modified_By = userName;
                
                this.props.updateSieveDryPlantRequest({
                    token: this.props.getToken.payload.response.token,
                    request: sieveDryPlant
                });
            };
    };

    private handleDropDownChange = (event: unknown, value: { id: number, name: string } | "", name?: string): void => {
        if (name){
            let errors = this.state.errors;
            errors = this.errorChecker(name, value !== "" ? value.name : "", errors, true);

            this.setState({
                ...this.state,
                errors,
                sieveDryPlant: {
                    ...this.state.sieveDryPlant,
                    [name]: value !== "" ? value.name : ""
                }
            });
        }
    };

    private handleDropDownIdChange = (event: unknown, value: { id: number, name: string } | "", name?: string): void => {
        if (name){
            let errors = this.state.errors;
            errors = this.errorChecker(name, value !== "" ? value.name : "", errors, true);

            this.setState({
                ...this.state,
                errors,
                sieveDryPlant: {
                    ...this.state.sieveDryPlant,
                    [name]: value !== "" ? value.id : undefined
                }
            });
        }
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getSieveDryPlants))
            this.props.getSieveDryPlantsRequest({
                token: this.props.getToken.payload.response.token
            });


        const query = queryString.parse(this.props.location.search);
        const id = query.id ?? "0";

        if ((id !== "0") && (hasPayload(this.props.getSieveDryPlants))) {
            const sieveDryPlant = this.props.getSieveDryPlants.payload.response.find(x => x.id === Number(id));

            if(sieveDryPlant)
                this.setState({ sieveDryPlant, errors: {} });
        };

    };

}

const mapStateToProps = (state: IStore): ISieveDryPlantStoreProps => ({
    getToken: getTokenStatus(state),
    getSieveDryPlants: getSieveDryPlantStatus(state),
    addSieveDryPlant: addSieveDryPlantStatus(state),
    updateSieveDryPlant: updateSieveDryPlantStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): ISieveDryPlantDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getSieveDryPlantsRequest: (data: IGetSieveDryPlantRequest) => dispatch(getSieveDryPlantLoadAction(data)),
    addSieveDryPlantRequest: (data: IAddSieveDryPlantRequest) => dispatch(addSieveDryPlantLoadAction(data)),
    updateSieveDryPlantRequest: (data: IUpdateSieveDryPlantRequest) => dispatch(updateSieveDryPlantLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(SieveDryPlant);