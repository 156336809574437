import { IAddEmployeeRequest, IADD_EMPLOYEE_REQUEST } from "./addEmployeeConstaints";

export interface IAddEmployeeLoadAction {
    type: IADD_EMPLOYEE_REQUEST.REQUEST;
    data: IAddEmployeeRequest
}
export const addEmployeeLoadAction = (data: IAddEmployeeRequest): IAddEmployeeLoadAction => ({
    type: IADD_EMPLOYEE_REQUEST.REQUEST,
    data
});

export interface IAddEmployeeSuccessAction {
    type: IADD_EMPLOYEE_REQUEST.SUCCESS;
    message: string;
}
export const addEmployeeLoadSuccessAction = (message: string): IAddEmployeeSuccessAction => ({
    type: IADD_EMPLOYEE_REQUEST.SUCCESS,
    message
});
export interface IAddEmployeeLoadFailedAction {
    type: IADD_EMPLOYEE_REQUEST.FAILED;
    message: string;
}
export const addEmployeeLoadFailedAction = (message: string): IAddEmployeeLoadFailedAction => ({
    type: IADD_EMPLOYEE_REQUEST.FAILED,
    message
});
