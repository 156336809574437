import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IGetProductionLookupsLoadAction, IGetProductionLookupsLoadFailedAction, IGetProductionLookupsSuccessAction } from "./getProductionLookupsActions";
import { IGET_PRODUCTION_LOOKUPS_REQUEST, IProductionLookups } from "./getProductionLookupsConstaints";

type Actions =
    IGetProductionLookupsLoadAction
    | IGetProductionLookupsLoadFailedAction
    | IGetProductionLookupsSuccessAction
    | IFlushDataSuccessAction;

export const GetProductionLookupsReducer = (state: Server<SilAPIResponse<IProductionLookups>> = notLoaded, action: Actions): Server<SilAPIResponse<IProductionLookups>> => {
    switch (action.type) {
        case IGET_PRODUCTION_LOOKUPS_REQUEST.REQUEST:
            return loading;

        case IGET_PRODUCTION_LOOKUPS_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_PRODUCTION_LOOKUPS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};