import React, { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAGreyPaper, LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, isSucceeded, Server } from "../../../../redux/server";
import { ById, SilAPIResponse } from "../../../shared/publicInterfaces";
import { userName, undefinedFunction, yesOrNoOptions, ZEROTH } from "../../../shared/constExports";
import { LAButton, LASaveAndCancelButton } from "../../../shared/buttons";
import { ArrowLeftIcon } from "../../../shared/icons";
import { WHITE_COLOR } from "../../../shared/theme";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import LATextField from "../../../shared/textField";
import LAAutoComplete from "../../../shared/autoComplete";
import { ROUTE } from "../../../routes";
import queryString from "query-string";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import { FieldValidator, FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../../shared/fieldValidator";
import PageSpacing from "../../../shared/pageSpacing";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import SilRoleCheck from "../../../shared/silRoleCheck";
import { convertList } from "../washPlant/washPlantItemForm";
import RRTable from "./rrTable";
import { IBaggedInventories, IBaggedInventoriesItem, IGetBaggedInventoriesRequest } from "../../../../redux/sil/warehouse/baggedInventory/getBaggedInventories/getBaggedInventoriesConstaints";
import { IUpdateBaggedInventoryRequest } from "../../../../redux/sil/warehouse/baggedInventory/updateBaggedInventory/updateBaggedInventoryConstaints";
import { IAddBaggedInventoryRequest, IAddUpdateBaggedInventory } from "../../../../redux/sil/warehouse/baggedInventory/addBaggedInventory/addBaggedInventoryConstaints";
import { getBaggedInventoriesStatus } from "../../../../redux/sil/warehouse/baggedInventory/getBaggedInventories/getBaggedInventoriesAccessor";
import { updateBaggedInventoryStatus } from "../../../../redux/sil/warehouse/baggedInventory/updateBaggedInventory/updateBaggedInventoryAccessor";
import { addBaggedInventoryStatus } from "../../../../redux/sil/warehouse/baggedInventory/addBaggedInventory/addBaggedInventoryAccessor";
import { getBaggedInventoriesLoadAction } from "../../../../redux/sil/warehouse/baggedInventory/getBaggedInventories/getBaggedInventoriesActions";
import { addBaggedInventoryLoadAction } from "../../../../redux/sil/warehouse/baggedInventory/addBaggedInventory/addBaggedInventoryActions";
import { updateBaggedInventoryLoadAction } from "../../../../redux/sil/warehouse/baggedInventory/updateBaggedInventory/updateBaggedInventoryActions";
import splitDate from "../../../shared/splitDate";
import { IGetPSLookupsRequest, IPSLookup } from "../../../../redux/sil/warehouse/baggedInventory/getSieveLookups/getPSLookupsConstaints";
import { getPSLookupsStatus } from "../../../../redux/sil/warehouse/baggedInventory/getSieveLookups/getPSLookupsAccessor";
import { getPSLookupsLoadAction } from "../../../../redux/sil/warehouse/baggedInventory/getSieveLookups/getPSLookupsActions";

const requiredFields = ["date", "count"];

interface IBaggedInventoryStoreProps {
    addBaggedInventory: Server<string>;
    updateBaggedInventory: Server<string>;
    getToken: Server<SilAPIResponse<IToken>>;
    getBaggedInventories: Server<SilAPIResponse<IBaggedInventories[]>>;
    getPSLookups: Server<SilAPIResponse<IPSLookup>>;
};

interface IBaggedInventoryDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getBaggedInventoriesRequest: (data: IGetBaggedInventoriesRequest) => unknown;
    addBaggedInventoryRequest: (data: IAddBaggedInventoryRequest) => unknown;
    updateBaggedInventoryRequest: (data: IUpdateBaggedInventoryRequest) => unknown;
    getPSLookupsRequest: (data: IGetPSLookupsRequest) => unknown;
};


interface IBaggedInventoryOwnProps {

};

interface IBaggedInventoryState {
    data: IBaggedInventories;
    errors: ById<IFieldErrorKeyValue>;
    inputRef: any;
};

const BaggedInventoryStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;

    .dx-toolbar-after {
        display: none;
    };

    .variancepopup{
        height: 50% !important;
    }
`;

type IBaggedInventoryProps = RouteComponentProps
    & IBaggedInventoryStoreProps
    & IBaggedInventoryDispatchProps
    & IBaggedInventoryOwnProps;

class BaggedInventory extends PureComponent<IBaggedInventoryProps, IBaggedInventoryState> { 
    public constructor(props: IBaggedInventoryProps) {
        super(props);
        this.state = {
            errors: {
                "date": { key: "date", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "count": { key: "count", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
            },
            inputRef: React.createRef(),
            data: {
                id: 0,
                date: "",
                count: 0,
                completed_By: "",
                // rr: 0,
                items : [
                    {id: 0,list_ID: 0,product:"",pallet_Size:"",count:0,created: "",modified: "",created_By: "",modified_By: ""}, 
                    {id: 0,list_ID: 0,product:"",pallet_Size:"",count:0,created: "",modified: "",created_By: "",modified_By: ""}, 
                    {id: 0,list_ID: 0,product:"",pallet_Size:"",count:0,created: "",modified: "",created_By: "",modified_By: ""}, 
                    {id: 0,list_ID: 0,product:"",pallet_Size:"",count:0,created: "",modified: "",created_By: "",modified_By: ""}, 
                    {id: 0,list_ID: 0,product:"",pallet_Size:"",count:0,created: "",modified: "",created_By: "",modified_By: ""}, 
                    {id: 0,list_ID: 0,product:"",pallet_Size:"",count:0,created: "",modified: "",created_By: "",modified_By: ""}, 
                    {id: 0,list_ID: 0,product:"",pallet_Size:"",count:0,created: "",modified: "",created_By: "",modified_By: ""}, 
                    {id: 0,list_ID: 0,product:"",pallet_Size:"",count:0,created: "",modified: "",created_By: "",modified_By: ""}, 
                    {id: 0,list_ID: 0,product:"",pallet_Size:"",count:0,created: "",modified: "",created_By: "",modified_By: ""}, 
                    {id: 0,list_ID: 0,product:"",pallet_Size:"",count:0,created: "",modified: "",created_By: "",modified_By: ""}, 
                    {id: 0,list_ID: 0,product:"",pallet_Size:"",count:0,created: "",modified: "",created_By: "",modified_By: ""}, 
                    {id: 0,list_ID: 0,product:"",pallet_Size:"",count:0,created: "",modified: "",created_By: "",modified_By: ""}, 
                    {id: 0,list_ID: 0,product:"",pallet_Size:"",count:0,created: "",modified: "",created_By: "",modified_By: ""}, 
                    {id: 0,list_ID: 0,product:"",pallet_Size:"",count:0,created: "",modified: "",created_By: "",modified_By: ""}, 
                    {id: 0,list_ID: 0,product:"",pallet_Size:"",count:0,created: "",modified: "",created_By: "",modified_By: ""}, 
                    {id: 0,list_ID: 0,product:"",pallet_Size:"",count:0,created: "",modified: "",created_By: "",modified_By: ""}, 
                    {id: 0,list_ID: 0,product:"",pallet_Size:"",count:0,created: "",modified: "",created_By: "",modified_By: ""}, 
                    {id: 0,list_ID: 0,product:"",pallet_Size:"",count:0,created: "",modified: "",created_By: "",modified_By: ""}, 
                    {id: 0,list_ID: 0,product:"",pallet_Size:"",count:0,created: "",modified: "",created_By: "",modified_By: ""}, 
                    {id: 0,list_ID: 0,product:"",pallet_Size:"",count:0,created: "",modified: "",created_By: "",modified_By: ""}, 
                ],
                created: "",
                created_By: userName,
                modified: "",
                modified_By: userName,
            }
        };
    }

    public componentDidMount(): void {
        // if (hasPayload(this.props.getToken))
        //     this.props.getPSLookupsRequest({
        //         token: this.props.getToken.payload.response.token,
        //         Requested_Page: "Bagged_Inventory"
        //     });

        this.callServer();
    };

    public componentDidUpdate(prevProps: IBaggedInventoryProps): void {
        if (this.props !== prevProps) {
            this.callServer();

            if (this.props.addBaggedInventory !== prevProps.addBaggedInventory) {
                if (isSucceeded(this.props.addBaggedInventory)) {
                    this.handleCancel();
                };
            };

            if (this.props.updateBaggedInventory !== prevProps.updateBaggedInventory) {
                if (isSucceeded(this.props.updateBaggedInventory)) {
                    this.handleCancel();
                };
            };
        };
    };

    public render(): ReactNode {

        const { data, errors } = this.state;
        const countArray = [{id: 1, count: "1"}, {id: 2, count:"2"}];
        // const completedByArray = [{id: 1, name: "DAUNE"}, {id: 2, name:"Daune Devolder"}, {id: 3, name:"Daune Devolder"}, {id: 4, name:"Test"}, {id: 5, name:"Wayne"}, {id:6, name:"Wyatt"}];
        const { updateBaggedInventory, addBaggedInventory, getPSLookups  } = this.props;
        const lookUps = hasPayload(getPSLookups) ? getPSLookups.payload.response : { products: [], palletList: [] };
        const products = lookUps.products;
        const palletList = convertList(lookUps.palletList);

        const handleOnCountChange = (event: unknown, value: any): void => this.onChange("count", value !== null ? value.count : data.count);
        const handleOnCompletedByChange = (event: unknown, value: any): void => this.onChange("completed_By", value !== null ? value.name : data.completed_By);

        return (
            <PageSpacing title="SIL - Bagged Inventory" description="Bagged Inventory" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="warehouseAccess">
                    <BaggedInventoryStyles>

                        <LAButton startIcon={<ArrowLeftIcon color={WHITE_COLOR} />} label="Back to list" onClick={this.handleCancel} />
                        <h2 className="text-center">{data.id > 0 ? "VIEW/UPDATE " : "ADD "} Bagged Inventory</h2>
                        <hr />

                        <LAPaperWithPadding>
                            <LAGrid spacing={1}>
                                <LAGridItem xs={12} sm={6} md={4}>
                                    <strong>Date</strong>
                                    <LATextField
                                        id="date"
                                        value={splitDate(data.date)}
                                        fullWidth={true}
                                        varient="outlined"
                                        name="date"
                                        inputRef={this.state.inputRef}
                                        label=""
                                        errorText={errors["date"] ? errors["date"].message : undefined}
                                        onChange={this.onChange} 
                                        type="date"
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={4}>
                                    <strong>Count</strong>
                                    <LAAutoComplete
                                        multiple={false}
                                        option={countArray}
                                        getOptionLabel="count"
                                        autoHighlight={true}
                                        onChange={handleOnCountChange}
                                        filterSelectedOptions={true}
                                        dropDownPlaceHolder="Count"
                                        selectionRemove={undefinedFunction}
                                        errorText={errors["count"] ? errors["count"].message : undefined}
                                        value={data.count ? countArray.find(q => q.count === data.count.toString()) : null}
                                        defaultValue={data.count ? countArray.find(q => q.count === data.count.toString()) : null}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={4}>
                                    <strong>Completed By</strong>
                                    {/* <LAAutoComplete
                                        multiple={false}
                                        option={completedByArray}
                                        getOptionLabel="name"
                                        autoHighlight={true}
                                        onChange={handleOnCompletedByChange}
                                        filterSelectedOptions={true}
                                        dropDownPlaceHolder="Completed By"
                                        selectionRemove={undefinedFunction}
                                        value={data.completed_By ? completedByArray.find(q => q.name === data.completed_By) : null}
                                        defaultValue={data.completed_By ? completedByArray.find(q => q.name === data.completed_By) : null}
                                    /> */}
                                    <LATextField
                                        label="Completed By"
                                        fullWidth={true}
                                        varient="outlined"
                                        name="completed_By"
                                        onChange={this.onChange}
                                        value={data.completed_By}
                                    />
                                </LAGridItem>

                                {/* <LAGridItem xs={12} sm={6} md={3}>
                                    <strong>RR</strong>
                                    <LATextField
                                            id="pilot"
                                            value={data.rr}
                                            fullWidth={true}
                                            varient="outlined"
                                            name="rr"
                                            label=""
                                            onChange={this.onChange}
                                            type="number"
                                    />
                                </LAGridItem> */}

                                {/* {hasPayload(getPSLookups) && */}
                                    <LAGridItem xs={12}>
                                        <LAGreyPaper>
                                            <LAGridItem xs={12}>
                                                <h2 className="text-center">Items</h2><hr/>
                                            </LAGridItem>
                                            <LAGridItem xs={12}>
                                                <RRTable 
                                                    productList={ products}
                                                    palletSizeList={palletList }
                                                    onAdd={this.onRRAdd} 
                                                    onDelete={this.onRRDelete} 
                                                    // disabled={data.rr > 0 ? true:undefined} 
                                                    data={data.items} 
                                                    handleChange={this.handleRRTableChange}
                                                />
                                            </LAGridItem>
                                        </LAGreyPaper>
                                    </LAGridItem>
                                {/* }  */}

                                <LAGridItem xs={12}>
                                    <LASaveAndCancelButton
                                        onSave={this.onSave}
                                        onCancel={this.handleCancel}
                                        disableSave={(Object.values(errors).length > 0 ? true : undefined)}
                                    />
                                </LAGridItem>

                                <RequestStatus requestStatus={addBaggedInventory.kind} successMessage="Bagged Inventory successfully saved" />
                                <RequestStatus requestStatus={updateBaggedInventory.kind} successMessage="Bagged Inventory successfully updated" />
                            </LAGrid>
                        </LAPaperWithPadding>
                    </BaggedInventoryStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private onRRAdd = () => {
        const initialRRObject :IBaggedInventoriesItem = {
            id: 0,
            list_ID: 0,
            product:"",
            pallet_Size:"",
            count:0,
            created: "",
            modified: "",
            created_By: "",
            modified_By: ""
        }
        const list = this.state.data.items !== null && this.state.data.items !== undefined ? [...this.state.data.items] : [];
        
        list.push(initialRRObject);
  
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                // rr: Number(this.state.data.rr) + 1,
                items: list
            }
        })
    }

    private handleRRTableChange = (event:any,value:any, index?:number,name?:any| undefined) =>{
        // console.log(value, index, name)
        if (index !== undefined) {
            const iS = this.state.data.items !== null && this.state.data.items !== undefined ? [...this.state.data.items] : [];
            let record = { ...iS[index] };
          
            // If count entered convert to a number
            if(name === "count"){
              value = Number(value);
            }
            // If autocomplete delete set value to empty string
            if(value === null){
              value={value:null};
            }
            
            record = { ...record, [name]: value };
            iS[index] = record;

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    items: iS
                }
            })
        }
    }

    private onRRDelete = (index:any | undefined) =>{
        const list:any = (this.state.data.items !== null && this.state.data.items !== undefined) ? [...this.state.data.items] : [];
     
        list.splice(index, 1);
       
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                // rr: Number(this.state.data.rr) - 1,
                items: list
            }
        })
    }

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, isRequired: boolean): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: isRequired ? true : undefined, minLength: 1, decimal: undefined });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        };
        return err;
    };

    private handleCancel = (): void => {
        this.props.history.push(ROUTE.SIL.BAGGED_INVENTORIES.INDEX);
    };

    private onSave = async (): Promise<void> => {

        if (hasPayload(this.props.getToken)) {
            const { data } = this.state;
            console.log(data)

            const request: IAddUpdateBaggedInventory = {
                ID: data.id,
                Date: data.date,
                Count: +data.count,
                Completed_By: data.completed_By,
                Items: data.items.map((x: any) => ({
                    ID: x.id,
                    Product: x.product,
                    Pallet_Size: x.pallet_Size,
                    Count: x.count,
                })),
                Created_By: userName,
                Modified_By:  userName,
            };

            console.log(request)

            if (data.id === 0) {
                this.props.addBaggedInventoryRequest({
                    token: this.props.getToken.payload.response.token,
                    request
                });
            } else {
                this.props.updateBaggedInventoryRequest({
                    token: this.props.getToken.payload.response.token,
                    request
                });
            };
        }
    };

    private onChange = (name: string, value: string): void => {
        let errors = this.state.errors;
        // console.log(name, value)
        if (requiredFields.includes(name))
            errors = this.errorChecker(name, value, errors, true);

        // if(name === "rr") {
        //     let originalRRArray = this.state.data.items !== undefined ? [...this.state.data.items] : [];
        //     if((this.state.data.rr <= +value) && (value !== "")){
        //         originalRRArray = this.state.data.items !== undefined ? [...this.state.data.items] : [];
        //     }

        //     this.state.data.rr = Number(value);
        //     let temp_array:object[] = [];
        //     const initialRRObject: object = {
        //         id: 0,
        //         product:{id:0, name:""},
        //         pallet_Size:{id: 0, size: ""},
        //         count:0,
        //     }

        //     if(this.state.data.rr <= 20){
        //         for(var ind=0;ind< this.state.data.rr; ind++) {
        //           let toPush:object = initialRRObject;
  
        //           if(originalRRArray?.length > 0 && originalRRArray[ind] && (value !== "")){
        //             toPush = originalRRArray[ind]
        //           }
  
        //           temp_array.push(toPush);
        //         }
        //         this.setState({
        //             ...this.state,
        //             data: {
        //                 ...this.state.data,
        //                 [name]: Number(value),
        //                 rrArray: temp_array
        //             },
        //         })
        //     } else {
        //         alert("Too many rr");
        //     }
        // }
        // else {
            this.setState({
                ...this.state,
                errors,
                data: {
                    ...this.state.data,
                    [name]: value
                }
            });
        // }
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getPSLookups))
            this.props.getPSLookupsRequest({
                token: this.props.getToken.payload.response.token,
                Requested_Page: "Bagged_Inventory"
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getBaggedInventories))
            this.props.getBaggedInventoriesRequest({
                token: this.props.getToken.payload.response.token
            });


        const query = queryString.parse(this.props.location.search);
        const id = query.id ?? "0";

        if ((id !== "0") && (hasPayload(this.props.getBaggedInventories)) && (this.state.data.id === 0)) {
            const data = this.props.getBaggedInventories.payload.response.find((x) => x.id === +id);

            if (data)
                this.setState({ data, errors: {} });
        };

    };
}

const mapStateToProps = (state: IStore): IBaggedInventoryStoreProps => ({
    getToken: getTokenStatus(state),
    getBaggedInventories: getBaggedInventoriesStatus(state),
    updateBaggedInventory: updateBaggedInventoryStatus(state),
    getPSLookups: getPSLookupsStatus(state),
    addBaggedInventory: addBaggedInventoryStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IBaggedInventoryDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getBaggedInventoriesRequest: (data: IGetBaggedInventoriesRequest) => dispatch(getBaggedInventoriesLoadAction(data)),
    addBaggedInventoryRequest: (data: IAddBaggedInventoryRequest) => dispatch(addBaggedInventoryLoadAction(data)),
    updateBaggedInventoryRequest: (data: IUpdateBaggedInventoryRequest) => dispatch(updateBaggedInventoryLoadAction(data)),
    getPSLookupsRequest: (data: IGetPSLookupsRequest) => dispatch(getPSLookupsLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(BaggedInventory);