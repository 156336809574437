import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IAddSieveProductLoadAction, IAddSieveProductLoadFailedAction, IAddSieveProductSuccessAction, 
    addSieveProductLoadFailedAction, addSieveProductLoadSuccessAction
} from "./addSieveProductActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IADD_SIEVE_PRODUCT_REQUEST } from "./addSieveProductConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";

export const addSieveProductEpic: Epic = (
    action$: ActionsObservable<IAddSieveProductLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IAddSieveProductSuccessAction | IAddSieveProductLoadFailedAction> =>
    action$.ofType(IADD_SIEVE_PRODUCT_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<string>>(
                    END_POINTS.PROPSENSE.SIL.SIEVES.ADD_SIEVE_PRODUCT,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<string>): IAddSieveProductSuccessAction | IAddSieveProductLoadFailedAction => {
                            if(response.message === "Success"){
                                return addSieveProductLoadSuccessAction(response.message);
                            };
                            return addSieveProductLoadFailedAction(response.message)
                        }),
                        catchError(() => ActionsObservable.of(addSieveProductLoadFailedAction("Unable to add sieves product")))
                    )
            )
        );