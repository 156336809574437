import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IAddBeltScaleLoadAction, IAddBeltScaleLoadFailedAction, IAddBeltScaleSuccessAction, addBeltScaleLoadFailedAction,
     addBeltScaleLoadSuccessAction } from "./addBeltScaleActions";
import { IADD_BELT_SCALE_REQUEST } from "./addBeltScaleConstaints";
import { IStore } from "../../../../reducers";
import { EpicDependencies } from "../../../../store";
import { SilAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { END_POINTS } from "../../../../endpoints";
import { withJsonContentType } from "../../../../epicUtils";

export const addBeltScaleEpic: Epic = (
    action$: ActionsObservable<IAddBeltScaleLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IAddBeltScaleSuccessAction | IAddBeltScaleLoadFailedAction> =>
    action$.ofType(IADD_BELT_SCALE_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<string>>(
                    END_POINTS.PROPSENSE.SIL.CV.AddCVBeltScale,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<string>): IAddBeltScaleSuccessAction | IAddBeltScaleLoadFailedAction => {

                            if(response.message === "Success"){
                                return addBeltScaleLoadSuccessAction(response.message);
                            }
                            return addBeltScaleLoadFailedAction(response.message);
                        }),
                        catchError(() => ActionsObservable.of(addBeltScaleLoadFailedAction("Unable to save your changes")))
                    )
            )
        );