import { YHDAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../../server";
import { IGET_YHD_CARRIER_LOOKUPS_REQUEST, IYHDCarrierLookup } from "./getCarrierLookupsConstaints";
import { IGetYHDCarrierLookupsLoadAction, IGetYHDCarrierLookupsLoadFailedAction, IGetYHDCarrierLookupsSuccessAction } from "./getCarrierLookupsActions";

type Actions =
    IGetYHDCarrierLookupsLoadAction
    | IGetYHDCarrierLookupsLoadFailedAction
    | IGetYHDCarrierLookupsSuccessAction
    | IFlushDataSuccessAction;

export const GetYHDCarrierLookupsReducer = (state: Server<YHDAPIResponse<IYHDCarrierLookup[]>> = notLoaded, action: Actions): Server<YHDAPIResponse<IYHDCarrierLookup[]>> => {
    switch (action.type) {
        case IGET_YHD_CARRIER_LOOKUPS_REQUEST.REQUEST:
            return loading;

        case IGET_YHD_CARRIER_LOOKUPS_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_YHD_CARRIER_LOOKUPS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};