import { SilAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { IGetNCRRequest, IGET_NCRS_REQUEST, INCR } from "./getNCRsConstaints";

export interface IGetNCRLoadAction {
    type: IGET_NCRS_REQUEST.REQUEST;
    data: IGetNCRRequest
}
export const getNCRLoadAction = (data: IGetNCRRequest): IGetNCRLoadAction => ({
    type: IGET_NCRS_REQUEST.REQUEST,
    data
});

export interface IGetNCRSuccessAction {
    type: IGET_NCRS_REQUEST.SUCCESS;
    data: SilAPIResponse<INCR[]>;
}
export const getNCRLoadSuccessAction = (data: SilAPIResponse<INCR[]>): IGetNCRSuccessAction => ({
    type: IGET_NCRS_REQUEST.SUCCESS,
    data
});

export interface IGetNCRLoadFailedAction {
    type: IGET_NCRS_REQUEST.FAILED;
    message: string;
}
export const getNCRLoadFailedAction = (message: string): IGetNCRLoadFailedAction => ({
    type: IGET_NCRS_REQUEST.FAILED,
    message
});
