import { allProducts } from "./productOrdered";
import {formatDateFromDBFormat} from '../../../../shared/simos/helper/dateFormatter';
import { allValues } from "./orderedBy";

let errors:any =[];
var errorStr:string = '';
/**
 * @param key: name of the field being checked
 * @returns true on error, false otherwise
*/
function hasInputError(key:any) {
    //console.log('checking error',key, errors, errors.indexOf(key) !==-1);
    return errors.indexOf(key) !== -1;
  }
  

/**
 * @param fieldname: name of the input field as a string
 * @param value: value contained in the input field
*/
function checkValidation (fieldname:string, value:any) {
    errors = [];
    switch(fieldname) {
        case 'customer':
            if(value.length <=1 ){
                errors.push('customer');
                errorStr="Customer name must be selected";
                return value.length > 1;
            }
            return value.length > 1;
        case 'delivery':
            if(value.length <=1 ){
                errors.push('delivery');
                errorStr="Delivery address must be selected";
                return value.length > 1;
            }
            return value.length > 1;
        case 'customer_contact':
            var count = 0;
            for(var ind=0; ind<value.length; ind++){
                if(value[ind].contact_selected === true){
                    count++;
                }
            }
            if(count != 1){
                errorStr = "Only 1 customer contact should be selected";
                //alert("Only 1 customer contact should be selected");
                return false;
            }
            return true;
        case 'location':
            if(value.length <=1) {
                errors.push('location');
                errorStr="Location must be selected";
                return value.length >1;
            }        
            return value.length > 1;
        case 'truck_required':
            if(value.length <=1) {
                errorStr="Truck Required must be selected";
                return value.length >1;
            }        
            return value.length > 1;
        case 'transport_company':
            if(value.length <=1) {
                errorStr="Transport Company must be selected";
                return value.length >1;
            }        
            return value.length > 1;
        case 'products':
           var isQuantityComplete = true;
            for (var i=0;i<value.product_Order.length; i++){
                if(Number(value.product_Order[i].quantity) === null || Number(value.product_Order[i].quantity) <=0 && value.product_Order[i].chosenProd!="" && value.status==="Assigned"){
                    errorStr="All product quantities must be greater than 0";
                    isQuantityComplete = false;
                }
            }
            return isQuantityComplete;
        case 'create-products':
            var isQuantityComplete = true;
            for (var i=0;i<value.length; i++){
                if(Number(value[i].quantity) === null || Number(value[i].quantity) <=0 && value[i].chosenProd!=""){
                    errorStr="All product quantities must be greater than 0";
                    isQuantityComplete = false;
                }
            }
            return isQuantityComplete;
        case 'truck_code':
            var isValid = true;
            // if(value.zone === '00' && value.truck_code!='' && (value.hired_truck_rate==='' || value.hired_truck_rate===null || value.hired_truck_rate===0)){
            //     isValid = false;
            //     errorStr = "Hired Truck Rate is required for zone 00 with a truck code";
            // }
            if(value.trns_company === 'Yellowhead Trucking' && value.truck_code ===''){
                isValid = false;
                errorStr = "Truck Code is required if transport company is yellowhead trucking";
            }
            return isValid;
    }
}

function checkPickupOrder(allValues:any, allDateField:any, allDeliveryDateField:any){
    let isPickup = allValues.pickupOrder;
    // Not a pickup, delivery Date/Time must be filled
    if(isPickup === "No"){
        if(allDeliveryDateField.date === "" || allDateField === null){
            errorStr="Delivery Date/Time must be filled for delivered orders!";
            //alert("Delivery Date/Time must be filled for delivered orders!");
            return false;
        }
        return true;
    }
    // Order is a pickup, scheduled pickup date/time must be filled
    if(isPickup==="Yes"){
        if(allDateField.date === "" || allDateField === null){
            errorStr="Scheduled pickup date/time must be filled for pickup orders!";
            //alert("Scheduled pickup date/time must be filled for pickup orders!");
            return false;
        }
        return true;
    }
    else{
        errorStr="Pickup field must be yes or no";
        //alert("Pickup field must be yes or no");
        return false;
    }

}

function validateCloneForm(cloneOrder:any, customercontacts:any){
    // format pickup order field before calling function
    var format_pickup = {pickupOrder: cloneOrder.pickup};
    var format_pickup_date = {date:cloneOrder.pickupDate};
    var format_delivery_date = {date:cloneOrder.deliveryDate};
    return checkValidation('customer', cloneOrder.customer) && checkValidation('delivery', cloneOrder.delivery) && checkValidation('customer_contact', customercontacts);
}

function validateEditForm(editOrder:any, customercontacts:any) {
    // format pickup order field before calling function
    var format_pickup = {pickupOrder: editOrder.pickup};
    var format_pickup_date = {date:editOrder.pickupdate};
    var format_delivery_date = {date:editOrder.deliverydate};
    return checkValidation('customer', editOrder.customer) && checkValidation('delivery', editOrder.address) && checkValidation('customer_contact', customercontacts) && checkValidation('products',editOrder);
}

function validateForm (allValues:any, allDateField:any,allDeliveryDateField:any, allSiteInfo:any, allTruckInfo:any, isDisabled:any, products:any) {
    return checkValidation('create-products', products) && checkValidation('customer', allValues.chosenCustomer) && checkValidation('delivery', allValues.chosenDelivery) &&
    checkValidation('location', allProducts.location) && checkValidation('customer_contact', allValues.orderedby)
     && checkValidation('truck_required', allSiteInfo.truck_req)  && checkValidation('transport_company', allSiteInfo.trns_company) && checkValidation('truck_code', allSiteInfo); 
}

export default validateForm;
export {errors, hasInputError, checkPickupOrder, validateCloneForm, validateEditForm, errorStr};
