import { currentUser } from "../../../../simosHome";

async function addSalesOrderNumber(sales_number:string, customer_id:number) {
    var success:any = null;
    let requestOptions = {
        method: 'POST',
        headers: { 'Content-Type':'application/json'},
        body: JSON.stringify({
            request: {
                Sales_Order_Number:sales_number,
                Created_By:currentUser,
                Modified_By:currentUser,
                CustomerID:customer_id
            },
            token: '19515195-d571-44a3-b0db-637f5bf24f54'})
    }
    await fetch('https://api2.propsense.com/api/AddSIMOSSalesOrderNumber', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json')&& await response.json();
        // error response
        if(!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        success = response;
    });
    return success;
}
export {addSalesOrderNumber}