import { INCR } from "../getNCRs/getNCRsConstaints";

export enum IUPDATE_NCR_REQUEST {
    REQUEST = "updateNCR/UPDATE_NCR_REQUEST",
    SUCCESS = "updateNCR/UPDATE_NCR_SUCCESS",
    FAILED = "updateNCR/UPDATE_NCR_FAILED"
};

export interface IUpdateNCRRequest {
    token: string;
    request: INCR;
};