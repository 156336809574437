export const ROUTE = {
    INDEX: "/",
    LOGIN: "/login",
    PROPSENSE: {
        INDEX: "/propsense",
        CATAPULT: {
            INDEX: "/propsense/catapult",
            CREW_SCHEDULING: {
                INDEX: (val?: string): string => `/propsense/catapult/crew-scheduling${val ? "?"+val:""}`,
                JOB_DETAILS: (id?: string): string => id ? `/propsense/catapult/crew-scheduling/${id}`: "/propsense/catapult/crew-scheduling/:id",
            },
        },
        YHD: {
            INDEX: "/yhd",
            CARRIERS: {
                INDEX: "/yhd/carrier",
                CARRIERS: "/yhd/carrier/carriers",
                CARRIER: "/yhd/carrier/carrier",
                CARRIER_EMAILS: "/yhd/carrier/carrier-emails",
                CARRIER_EMAIL: "/yhd/carrier/carrier-email",
                CARRIER_FLEET_TRUCKS: "/yhd/carrier/carrier-fleet-trucks",
                CARRIER_FLEET_TRUCK: "/yhd/carrier/carrier-fleet-truck",
                CARRIER_INFRACTIONS: "/yhd/carrier/carrier-infractions",
                CARRIER_INFRACTION: "/yhd/carrier/carrier-infraction",
            },
        }
    },
    SIL: {
        INDEX: "/sil",
        CARRIERS: {
            INDEX: "/sil/carrier",
            CARRIERS: "/sil/carrier/carriers",
            CARRIER: "/sil/carrier/carrier",
            CARRIER_EMAILS: "/sil/carrier/carrier-emails",
            CARRIER_EMAIL: "/sil/carrier/carrier-email",
            CARRIER_TRAILERS: "/sil/carrier/carrier-trailers",
            CARRIER_TRAILER: "/sil/carrier/carrier-trailer",
            CARRIER_INFRACTIONS: "/sil/carrier/carrier-infractions",
            CARRIER_INFRACTION: "/sil/carrier/carrier-infraction",
            CARRIER_AUDITS: "/sil/carrier/carrier-audits",
            CARRIER_AUDIT: "/sil/carrier/carrier-audit"
        },
        DRY_PLANT: {
            INDEX: "/sil/dry-plant",
            DRY_PLANT_ITEMS: "/sil/dry-plant/items",
            DRY_PLANT_ITEM: "/sil/dry-plant/item",
            DRY_PLANT_EMPLOYEES: "/sil/dry-plant/employees",
            DRY_PLANT_EMPLOYEE: "/sil/dry-plant/employee",
            DRY_PLANT_FEED_MATERIALS: "/sil/dry-plant/feed-materials",
            DRY_PLANT_FEED_MATERIAL: "/sil/dry-plant/feed-material",
            DRY_PLANT_RUN_MATERIALS: "/sil/dry-plant/by-products",
            DRY_PLANT_RUN_MATERIAL: "/sil/dry-plant/by-product",
            DRY_PLANT_BIN_ADJUSTMENTS: "/sil/dry-plant/bin-adjustments",
            DRY_PLANT_BIN_ADJUSTMENT: "/sil/dry-plant/bin-adjustment"
        },
        WASH_PLANT: {
            INDEX: "/sil/wash-plant",
            WASH_PLANT_ITEMS: "/sil/wash-plant/items",
            WASH_PLANT_ITEM: "/sil/wash-plant/item",
            WASH_PLANT_EMPLOYEES: "/sil/wash-plant/employees",
            WASH_PLANT_EMPLOYEE: "/sil/wash-plant/employee",
            WASH_PLANT_FEED_MATERIALS: "/sil/wash-plant/feed-materials",
            WASH_PLANT_FEED_MATERIAL: "/sil/wash-plant/feed-material",
            WASH_PLANT_RUN_MATERIALS: "/sil/wash-plant/by-products",
            WASH_PLANT_RUN_MATERIAL: "/sil/wash-plant/by-product",
            WASH_PLANT_BIN_ADJUSTMENTS: "/sil/wash-plant/pile-adjustments",
            WASH_PLANT_BIN_ADJUSTMENT: "/sil/wash-plant/pile-adjustment"
        },
        SIEVES: {
            INDEX: "/sil/sieves",
            SIEVES_CALIBRATIONS: "/sil/sieves/calibrations",
            SIEVES_CALIBRATION: "/sil/sieves/calibration",
            SIEVES_CHECKS: "/sil/sieves/checks",
            SIEVES_CHECK: "/sil/sieves/check",
            SIEVES_ITEMS: "/sil/sieves/items",
            SIEVES_ITEM: "/sil/sieves/item",
            SIEVE_PRODUCTS: "/sil/sieves/products",
            SIEVE_PRODUCT: "/sil/sieves/product",
        },
        DISPATCH: {
            INDEX: "/sil/dispatch",
            GEO_BAGS: "/sil/dispatch/geo-bags",
            GEO_BAG: "/sil/dispatch/geo-bag",
            TRAILER_ACTIVITY_LOGS: "/sil/dispatch/trailer-activity-logs",
            TRAILER_ACTIVITY_LOG: "/sil/dispatch/trailer-activity-log",
            TRANSLOAD_BOL_COUNTS: "/sil/dispatch/transload-bol-counts",
            TRANSLOAD_BOL_COUNT: "/sil/dispatch/transload-bol-count",
        },
        EXPLORATION: {
            INDEX: "/sil/exploration",
            CHEMICAL_ANALYSES_LIST: "/sil/exploration/chemical-analyses-list",
            CHEMICAL_ANALYSES: "/sil/exploration/chemical-analyses",
        },
        WAREHOUSE_PRODUCTS: {
            INDEX: "/sil/warehouse-products",
            PRODUCTS: "/sil/warehouse-products/products",
            PRODUCT: "/sil/warehouse-products/product",
        },
        BAGGED_INVENTORIES: {
            INDEX: "/sil/bagged-inventories",
            BAGGED_INVENTORY: "/sil/bagged-inventories/baggedInventory"
        },
        COMPLAINT_LOGGINGS: {
            INDEX: "/sil/complaint_loggings",
            COMPLAINT_LOGGING: "/sil/complaint_logging"
        },

        CV: {
            INDEX: "/cv",
            BELT_SCALES: {
                INDEX: "/cv/belt-scales",
                BELT_SCALE: "/cv/belt-scale",
            },
            CRANES: {
                INDEX: "/cv/cranes",
                CRANE: "/cv/crane",
            },
            LAB_SCALES: {
                INDEX: "/cv/lab-scales",
                LAB_SCALE: "/cv/lab-scale",
            },
            NCR: {
                INDEX: "/cv/ncrs",
                NCR: "/cv/ncr",
            },
            TRUCK_SCALES: {
                INDEX: "/cv/truck-scales",
                TRUCK_SCALE: "/cv/truck-scale",
            },
            WAREHOUSES: {
                INDEX: "/cv/warehouses",
                WAREHOUSE: "/cv/warehouse",
            },
            SIEVE_DRY_PLANTS: {
                INDEX: "/cv/sieve-dry-plants",
                SIEVE_DRY_PLANT: "/cv/sieve-dry-plant",
            },
            SIEVE_FRAC_BALLS: {
                INDEX: "/cv/sieve-frac-balls",
                SIEVE_DRY_PLANT: "/cv/sieve-frac-ball",
            },
            SIEVE_WASH_PLANTS: {
                INDEX: "/cv/sieve-wash-plants",
                SIEVE_WASH_PLANT: "/cv/sieve-wash-plant",
            },
        },

        QUALITY_CONTROL_TESTINGS: {
            INDEX: "/sil/quality_control_testings",
            QUALITY_CONTROL_TESTING: "/sil/quality_control_testing"
        },

    },
    SIMOS: {
        INDEX: "/simos",
        LOGIN: "/simos/login",
        ORDER: {
            INDEX: "/simos/orders",
            CREATEORDER: "/simos/orders/createForm",
            EDITORDER: "/simos/orders/editorder/id=:id",
            CLONEORDER: "/simos/orders/cloneForm/id=:id",
            VIEWORDER: "/simos/orders/vieworder/id=:id"
        },
        // WAREHOUSE: {
        //     INDEX: "/simos/warehouse",
        //     INVENTORY: "/simos/warehouse/inventory",
        //     INVENTORYHISTORY:"/simos/warehouse/inventory/history",
        //     INVENTORYADJUSTMENT:"/simos/warehouse/inventory/adjustment",
        //     INVENTORYPRODUCT:"/simos/warehouse/inventory/products",
        //     EMPLOYEE: "/simos/warehouse/employees"
        // },
        SIL_Bru_Orders: {
            INDEX: "/simos/sil-bru-orders",
            ADD_UPDATE: "/simos/sil-bru-order",
        },
        SIL_Ed_Orders: {
            INDEX: "/simos/sil-ed-orders",
            ADD_UPDATE: "/simos/sil-ed-order",
        },
        PRODUCT: {
            INDEX: "/simos/products",
            CREATEPRODUCT: "/simos/products/create",
            ADDUPDATEPRODUCT: "/simos/product",
        },
        CUSTOMERCONTACT: {
            INDEX: "/simos/customer-contacts",
            CREATECUSTOMERCONTACT: "/simos/createCustomerContacts",
            ADDUPDATECUSTOMERCONTACT: "/simos/customer-contact",
        },
        INTERNALCUSTOMER: {
            INDEX: "/simos/customers",
            CREATECUSTOMER: "/simos/customers/createcustomer",
            EDITCUSTOMER: "/simos/customers/editcustomer/id=:id"
        },
        CUSTOMER: {
            INDEX: "/simos/customers",
            ADDUPDATECUSTOMER: "/simos/customer",
        },
        TRANSPORTCOMPANIES: {
            INDEX: "/simos/transport-companies",
            ADDUPDATETRANSPORTCOMPANY: "/simos/transport-company",
            CREATETRANSPORTCOMPANY: "/simos/transport-companies/create-transport-company",
            EDITTRANSPORTCOMPANY: "/simos/transport-companies/edit-transport-company/id=:id"
        },
        HISTORY: {
            ORDER:"/simos/history/orders/id=:id",
            PRODUCT:"/simos/history/products/id=:id"
            //CONTACT:"/simos/history/contacts"
        }
    }
};
