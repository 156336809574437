import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IUPDATE_WASH_PLANT_PRODUCTION_REQUEST } from "./updateWashPlantProductionConstaints";
import { IUpdateWashPlantProductionLoadAction, IUpdateWashPlantProductionLoadFailedAction, IUpdateWashPlantProductionSuccessAction } from "./updateWashPlantProductionActions";


type Actions =
    IUpdateWashPlantProductionLoadAction
    | IUpdateWashPlantProductionLoadFailedAction
    | IUpdateWashPlantProductionSuccessAction
    | IFlushDataSuccessAction;

export const UpdateWashPlantProductionReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IUPDATE_WASH_PLANT_PRODUCTION_REQUEST.REQUEST:
            return loading;

        case IUPDATE_WASH_PLANT_PRODUCTION_REQUEST.SUCCESS:
            return succeeded("Success");

        case IUPDATE_WASH_PLANT_PRODUCTION_REQUEST.FAILED:
            return failed(action.message);

            case FLUSH_DATA.SUCCESS:
                return notLoaded;

        default:
            return state;
    }
};