import CircularProgress, {circularProgressClasses} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function LoadingPanel() {
    return (
        <Box sx={{position:'relative', flex:'inline-flex'}}>
                                <CircularProgress variant="determinate" sx={{
                                color: (theme) =>
                                    theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                                    position: 'absolute',
                                    left: '45%',
                                }} 
                                size={40}
                                thickness={4}
                                value={100}></CircularProgress>
                                <CircularProgress variant='indeterminate' disableShrink sx={{color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'),
                                        animationDuration: '550ms',
                                        position: 'absolute',
                                        left: '45%',
                                        [`& .${circularProgressClasses.circle}`]: {
                                        strokeLinecap: 'round',
                                        },}} size={40} thickness={4}></CircularProgress>
                                <Box sx={{
                                    top: 0,
                                    left: '50%',
                                    bottom: 0,
                                    right: 0,
                                    position: 'absolute',
                                    display: 'flex',
                                    alignItems: 'right',
                                    }}>
                                <Typography variant="caption" component="div" sx={{color:'red', fontSize:'20px', fontWeight:'bold'}}>
                                Loading...
                                </Typography>
                                </Box>
                              </Box>
    )
}