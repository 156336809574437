import { IPassTokenWithRequest } from "../../dryPlant/token/getTokenConstants";

export enum IADD_CARRIER_INFRACTION_REQUEST {
    REQUEST = "addCarrierInfraction/ADD_CARRIER_INFRACTION_REQUEST",
    SUCCESS = "addCarrierInfraction/ADD_CARRIER_INFRACTION_SUCCESS",
    FAILED = "addCarrierInfraction/ADD_CARRIER_INFRACTION_FAILED"
};

export interface IAddCarrierInfractionRequest extends IPassTokenWithRequest {
    request: {
        Date_Of_Infraction: string;
        Infraction_Notification_Method: string;
        Injuries?: string;
        Carrier: string;
        Load_No?: string;
        Trailer_No: string;
        License_Plate?: string;
        Location: string;
        Ticket_No?: string;
        Supervisor?: string;
        Infraction_Type: string;
        Carrier_Notification_Provided_To?: string;
        Description: string;
        Immediate_Action_Taken: string;
        Trailer_Inspection_File?: string;
        Followup_Action_Taken?: string;
        Inspection_Notes?: string;
        Attachment_File_Name?: string;
        Attachment_File?: string;
        Attachment_Details?: string;
        Conditions?: string;
        Corrective_Action_Recommended?: string;
        Corrective_Action_Taken?: string;
        Customer_Involved?: string;
        Root_Cause_Immediate?: string;
        Temperature?: string;
        Driver_Statement_File?: string;
        Driver_Statement_File_Name?: string;
        Customer_Statement_File?: string;
        Customer_Statement_File_Name?: string;
        Other_Statement_File?: string;
        Other_Statement_File_Name?: string;
        Reported_By_Name?: string;
        Contact_No?: string;
        Reported_To?: string;
        Status: string;
        Modified: string;
        Modified_By: string;
        Created: string;
        Created_By: string;
        persons_Involved: IAddUpdatePersonInvolvement[];
    };
};

export interface IAddUpdatePersonInvolvement {
    id?: number;
    Name: string;
    Occupation: string;
    Phone_No: string;
};