import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IUpdateEmployeeLoadAction, IUpdateEmployeeLoadFailedAction, IUpdateEmployeeSuccessAction, updateEmployeeLoadFailedAction,
     updateEmployeeLoadSuccessAction } from "./updateEmployeeActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IUPDATE_EMPLOYEE_REQUEST } from "./updateEmployeeConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";

export const updateEmployeeEpic: Epic = (
    action$: ActionsObservable<IUpdateEmployeeLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IUpdateEmployeeSuccessAction | IUpdateEmployeeLoadFailedAction> =>
    action$.ofType(IUPDATE_EMPLOYEE_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<string>>(
                    END_POINTS.PROPSENSE.SIL.DRY_PLANT.UPDATE_DRY_PLANT_EMPLOYEE,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<string>): IUpdateEmployeeSuccessAction | IUpdateEmployeeLoadFailedAction => {
                            if(response.message === "Success"){
                                return updateEmployeeLoadSuccessAction(action.data.request);
                            }
                            return updateEmployeeLoadFailedAction(response.message);
                        }),
                        catchError(() => ActionsObservable.of(updateEmployeeLoadFailedAction("Unable to save your changes")))
                    )
            )
        );