import { IPassTokenWithRequest } from "../../dryPlant/token/getTokenConstants";

export enum IGET_SIEVE_LOOKUPS_REQUEST {
    REQUEST = "getSieveLookups/GET_SIEVE_LOOKUPS_REQUEST",
    SUCCESS = "getSieveLookups/GET_SIEVE_LOOKUPS_SUCCESS",
    FAILED = "getSieveLookups/GET_SIEVE_LOOKUPS_FAILED"
};

export interface IGetSieveLookupsRequest extends IPassTokenWithRequest {
    Requested_Page: "Calibration" | "Checks" | "Sieve";
};

export interface ISieveLookup {
    plants: string[];
    products: { 
        plant: string;
        product: string;
    }[];
    mesh: {
        product: string;
        mesh: string;
        perc_Retain_Specs?: string;
        perc_Pass_Specs?: string;
    }[];
    screens: {
        plant: string;
        screen: string;
    }[];
};