import { IYHDCarrierForm } from "../../../../../react/components/propsense/yhd/carriers/carrierForm";

export enum IUPDATE_YHD_CARRIER_FORM_REQUEST {
    REQUEST = "updateYHDCarrierForm/UPDATE_YHD_CARRIER_FORM_REQUEST",
    SUCCESS = "updateYHDCarrierForm/UPDATE_YHD_CARRIER_FORM_SUCCESS",
    FAILED = "updateYHDCarrierForm/UPDATE_YHD_CARRIER_FORM_FAILED"
};

export interface IUpdateYHDCarrierFormRequest {
    token: string;
    request: IYHDCarrierForm;
};