import { IUPDATE_CHEMICAL_ANALYSES_REQUEST } from "./updateChemicalAnalysesConstaints";
import { IUpdateChemicalAnalysesLoadAction, IUpdateChemicalAnalysesLoadFailedAction, IUpdateChemicalAnalysesSuccessAction } from "./updateChemicalAnalysesActions";
import { IFlushDataSuccessAction } from "../../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../../flushData.tsx/flushDataConstants";
import { Server, notLoaded, loading, succeeded, failed } from "../../../../server";

type Actions =
    IUpdateChemicalAnalysesLoadAction
    | IUpdateChemicalAnalysesLoadFailedAction
    | IUpdateChemicalAnalysesSuccessAction
    | IFlushDataSuccessAction;

export const UpdateChemicalAnalysesReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IUPDATE_CHEMICAL_ANALYSES_REQUEST.REQUEST:
            return loading;

        case IUPDATE_CHEMICAL_ANALYSES_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IUPDATE_CHEMICAL_ANALYSES_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};