import { IPassTokenRequestBody } from "../../../../../react/shared/publicInterfaces";

export enum IGET_SIMOS_CUSTOMER_ADDRESS_REQUEST {
    REQUEST = "getSimosCustomerAddress/GET_SIMOS_CUSTOMER_ADDRESS_REQUEST",
    SUCCESS = "getSimosCustomerAddress/GET_SIMOS_CUSTOMER_ADDRESS_SUCCESS",
    FAILED = "getSimosCustomerAddress/GET_SIMOS_CUSTOMER_ADDRESS_FAILED"
};

export interface ISimosCustomerAddressRequest extends IPassTokenRequestBody {

};

export interface ISimosCustomerAddress {
    id: number;
    address: string;
    customer_ID: number;
    created: string;
    created_By: string;
    modified: string;
    modified_By: string;   
};
