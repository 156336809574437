import { IAddSieveRequest, IADD_SIEVE_REQUEST } from "./addSieveConstaints";

export interface IAddSieveLoadAction {
    type: IADD_SIEVE_REQUEST.REQUEST;
    data: IAddSieveRequest
}
export const addSieveLoadAction = (data: IAddSieveRequest): IAddSieveLoadAction => ({
    type: IADD_SIEVE_REQUEST.REQUEST,
    data
});

export interface IAddSieveSuccessAction {
    type: IADD_SIEVE_REQUEST.SUCCESS;
    message: string;
}
export const addSieveLoadSuccessAction = (message: string): IAddSieveSuccessAction => ({
    type: IADD_SIEVE_REQUEST.SUCCESS,
    message
});

export interface IAddSieveLoadFailedAction {
    type: IADD_SIEVE_REQUEST.FAILED;
    message: string;
}
export const addSieveLoadFailedAction = (message: string): IAddSieveLoadFailedAction => ({
    type: IADD_SIEVE_REQUEST.FAILED,
    message
});
