import { IUPDATE_SIMOS_TRANSPORT_COMPANY_REQUEST, IUpdateSimosTransportCompanyRequest } from "./updateSimosTransportCompanyConstaints";

export interface IUpdateSimosTransportCompanyLoadAction {
    type: IUPDATE_SIMOS_TRANSPORT_COMPANY_REQUEST.REQUEST;
    data: IUpdateSimosTransportCompanyRequest
}
export const updateSimosTransportCompanyLoadAction = (data: IUpdateSimosTransportCompanyRequest): IUpdateSimosTransportCompanyLoadAction => ({
    type: IUPDATE_SIMOS_TRANSPORT_COMPANY_REQUEST.REQUEST,
    data
});

export interface IUpdateSimosTransportCompanySuccessAction {
    type: IUPDATE_SIMOS_TRANSPORT_COMPANY_REQUEST.SUCCESS;
    message: string;
}
export const updateSimosTransportCompanyLoadSuccessAction = (message: string): IUpdateSimosTransportCompanySuccessAction => ({
    type: IUPDATE_SIMOS_TRANSPORT_COMPANY_REQUEST.SUCCESS,
    message
});

export interface IUpdateSimosTransportCompanyLoadFailedAction {
    type: IUPDATE_SIMOS_TRANSPORT_COMPANY_REQUEST.FAILED;
    message: string;
}
export const updateSimosTransportCompanyLoadFailedAction = (message: string): IUpdateSimosTransportCompanyLoadFailedAction => ({
    type: IUPDATE_SIMOS_TRANSPORT_COMPANY_REQUEST.FAILED,
    message
});
