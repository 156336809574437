let isDisabled = false;

function isUnique(po_number: string) {

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type':'application/json'},
        body: JSON.stringify({
            request: {PO_Number: po_number},
            token:'19515195-d571-44a3-b0db-637f5bf24f54'})
        }

    fetch('https://api2.propsense.com/api/checkPONumExists', requestOptions)
    .then(response => {
        const data = response.headers.get('Content-Type')?.includes('application/json') ;
        // error response
        if(!response.ok) {
            const error = (data) || response.status;
            return Promise.reject(error);
        }  
        // handle validation
        else {
            response.json().then(res => { 
               if(res === true ){ 
                   isDisabled = true;
               }
               else {
                   isDisabled = false;
               }
            });
        }
    });

}

export default isUnique;
export {isDisabled};