import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { ISaveAssignedCrewsLoadAction, ISaveAssignedCrewsLoadFailedAction, ISaveAssignedCrewsSuccessAction, saveAssignedCrewsLoadFailedAction,
     saveAssignedCrewsLoadSuccessAction } from "./saveAssignedCrewsActions";
import { IStore } from "../../../../reducers";
import { EpicDependencies } from "../../../../store";
import { ISAVE_ASSIGNED_CREWS_REQUEST } from "./saveAssignedCrewsConstaints";
import { END_POINTS } from "../../../../endpoints";
import { withJsonContentType } from "../../../../epicUtils";

export const saveAssignedCrewsEpic: Epic = (
    action$: ActionsObservable<ISaveAssignedCrewsLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<ISaveAssignedCrewsSuccessAction | ISaveAssignedCrewsLoadFailedAction> =>
    action$.ofType(ISAVE_ASSIGNED_CREWS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<string>(
                    END_POINTS.PROPSENSE.CATAPULT.CREW_SCHEDULING.SAVE_ASSIGNED_CREW,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: string): ISaveAssignedCrewsSuccessAction | ISaveAssignedCrewsLoadFailedAction => {
                            
                            if(response !== "Save Failed"){
                                return saveAssignedCrewsLoadSuccessAction(response);
                            }
                            return saveAssignedCrewsLoadFailedAction("Unable to save your changes");
                        }),
                        catchError(() => ActionsObservable.of(saveAssignedCrewsLoadFailedAction("Unable to save your changes")))
                    )
            )
        );