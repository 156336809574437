import React from 'react';
import {copyedit} from './internalCustomerTable';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import BackButton from '../../../../shared/simos/helper/backButton';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import updateSIMOSCustomer from '../APICalls/updateCustomer';
import {getSIMOSUserGroupAccess} from '../APICalls/getSIMOSGroupAccess';
import { getSILURLParams } from '../../../../shared/simos/helper/getURLContainsSIL';

interface IProps {
    history:any;
    editID:number;
}
interface IState {
    simosCustomers:any[];
    showError:boolean;
}
var onHoldAccess:any;
var user_role:any = "";
var old_customer_name:any = "";
export default class InternalCustomerEdit extends React.Component<IProps,IState>{
    constructor(props:any) {
        super(props);
        this.state = {simosCustomers:[], showError:false};
        this.onTextChange = this.onTextChange.bind(this);
        this.onSaveEdit = this.onSaveEdit.bind(this);
    }
    onTextChange(event:any){
        switch(event.target.name) {
            case 'activeradio':
                copyedit.active = event.target.value;
                break;
            case 'onholdradio':
                copyedit.on_Hold = event.target.value;
                break;
            case 'explorercode':
                copyedit.explorer_Code = event.target.value;
                break;
            case 'customername':
                copyedit.customer = event.target.value;
                break;
        }
        this.setState({});
    }
    async onSaveEdit(event:any){
        event.preventDefault();
        let exists = false;
        if(copyedit.customer != "" && copyedit.active != ""){
            this.state.simosCustomers.map((elem:any) => {
                if((elem.customer.trim() === copyedit.customer.trim()) && (old_customer_name.trim() != copyedit.customer.trim())){
                    exists = true;
                }
            });
            if(!exists){
                var res:any = await updateSIMOSCustomer(copyedit);
                if(res.status === 200){
                    alert("Successfully updated SIMOS customer");
                    this.props.history.replace('/simos/customers'+getSILURLParams());
                    window.location.reload();
                }
                else{
                    alert("Error updating SIMOS customer: server error");
                }
            }
            else{
                alert("This customer already exists, cannot update");
            }
        }
        else{
            this.setState({showError:true});
        }
    }
    onCancelEdit(event:any){
        event.preventDefault();
        this.props.history.replace('/simos/customers'+getSILURLParams());
    }

    async componentDidMount() {
        var response:any = await getSIMOSUserGroupAccess();
        onHoldAccess = response.customers_on_hold_access;
        var userRole:any = response.role;
        user_role = userRole;
        if(userRole!='Admin' && userRole!='Dispatcher' && userRole!='SuperAdmin'){
            this.props.history.push('/simos');
        }
        else{
            if(!copyedit){
                this.props.history.push('/simos/customers'+getSILURLParams())
            }
        }
        if(copyedit!=null){
            old_customer_name = copyedit.customer;
            let initialUsers:string[] = [];
            const requestOptions = { method: 'POST',
            headers: { 'Content-Type':'application/json' }, 
            body: JSON.stringify({token: '19515195-d571-44a3-b0db-637f5bf24f54' })};

            fetch('https://api2.propsense.com/api/GetAllSIMOSCustomers', requestOptions)
            .then(async response => {
                const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
                // error response
                if(!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                // no error
                data.map((user:any) => {
                        initialUsers.push(user);
                });
                this.setState({simosCustomers:initialUsers});
            });   
        }
    }
  //  async componentDid(){
        // var response:any = await getSIMOSUserGroupAccess();
        // onHoldAccess = response.customers_on_hold_access;
        // console.log(onHoldAccess);
        // var userRole:any = response.role;
        // if(userRole!='Admin' && userRole!='Dispatcher'){
        //     this.props.history.push('/simos');
        // }
        // else{
        //     if(!copyedit){
        //         this.props.history.push('/simos/customers')
        //     }
        // }
  //  }
    render() {
        if(copyedit!=null){
        return (
            <div className={getSILURLParams()?.includes("bru") ? "edit-popup":"edit-popup-sil-ed"}>
                <div className = 'edit-popup_inner'> 
                {this.state.showError ? 
                    <Stack sx={{ width: '100%' }} spacing={2}>
                        <Alert variant="filled" severity="error" action={<IconButton color="inherit" onClick={() => {this.setState({showError:false})}}>X</IconButton>}>
                        All fields must be filled!
                        </Alert><br></br>
                    </Stack>
                :null}
                <BackButton goBack={this.onCancelEdit.bind(this)} history={this.props.history}></BackButton>
                    <form>
                        <h3>Editing Customer <label id='header-3-label'>#{copyedit.id}</label></h3>
                            <div id="edit-info-fields"> 
                                {this.state.simosCustomers.map(user => {
                                    if(user.id === copyedit.id){
                                           return <div className="row">
                                               <Grid container spacing={3} direction="column">
                                                   <Grid item xs={12}>
                                                    <TextField disabled={user_role !== 'SuperAdmin'}  label="Customer" name="customername" onChange={this.onTextChange} defaultValue={copyedit.customer}></TextField>
                                                    </Grid>
                                                     
                                                    <Grid item xs={12}>
                                                    <TextField disabled={user_role !== 'SuperAdmin'} label="Explorer Code" name="explorercode" onChange={this.onTextChange} defaultValue={copyedit.explorer_Code}></TextField>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <FormLabel component="legend">Active</FormLabel>
                                                            <RadioGroup aria-label="gender" name="activeradio" defaultValue={copyedit.active} onChange={this.onTextChange}>
                                                                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                                                <FormControlLabel value="No" control={<Radio />} label="No" />
                                                            </RadioGroup>
                                                     </Grid>
                                                     <Grid item xs={6}>
                                                        <FormLabel component="legend">On Hold</FormLabel>
                                                            <RadioGroup aria-label="gender" name="onholdradio" defaultValue={copyedit.on_Hold != "Yes" ? "No":"Yes"} onChange={this.onTextChange}>
                                                                <FormControlLabel value="Yes" control={<Radio disabled ={onHoldAccess != true}/>} label="Yes" />
                                                                <FormControlLabel value="No" control={<Radio disabled = {onHoldAccess != true}/>} label="No" />
                                                            </RadioGroup>
                                                            
                                                     </Grid>
                                                     {onHoldAccess != true && copyedit.on_Hold ==="Yes"? <div style={{color:'red', fontSize:'14px'}}>Please contact Michelle Scales regarding this hold</div>:null}
                                                </Grid>
                                            </div>
                                    }
                                })}
                            </div><br></br>
                            <button type='submit' onClick={this.onSaveEdit} id='submit-contact-form-btn'>Save</button>
                            <button onClick={this.onCancelEdit.bind(this)} id='cancel-contact-form-btn'>Cancel</button>
                    </form>
                </div>
            </div>
    );
                            }else{
                                return null;
                            }
    }
}