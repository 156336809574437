import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { getBinAdjustmentsStatus } from "../../../../redux/sil/dryPlant/getBinAdjustments/getBinAdjustmentsAccessor";
import { getBinAdjustmentsLoadAction } from "../../../../redux/sil/dryPlant/getBinAdjustments/getBinAdjustmentsActions";
import { IBinAdjustments, IGetBinAdjustmentsRequest } from "../../../../redux/sil/dryPlant/getBinAdjustments/getBinAdjustmentsConstaints";
import { hasPayload, isNotLoaded, isSucceeded, Server } from "../../../../redux/server";
import { ById, SilAPIResponse } from "../../../shared/publicInterfaces";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import { userName, undefinedFunction, ZEROTH } from "../../../shared/constExports";
import { LAButton, LASaveAndCancelButton } from "../../../shared/buttons";
import { ArrowLeftIcon } from "../../../shared/icons";
import { WHITE_COLOR } from "../../../shared/theme";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import LATextField from "../../../shared/textField";
import LAAutoComplete from "../../../shared/autoComplete";
import { convertList } from "./dryPlantItemForm";
import { ROUTE } from "../../../routes";
import queryString from "query-string";
import { updateBinAdjustmentLoadAction } from "../../../../redux/sil/dryPlant/updateBinAdjustment/updateBinAdjustmentActions";
import { updateBinAdjustmentStatus } from "../../../../redux/sil/dryPlant/updateBinAdjustment/updateBinAdjustmentAccessor";
import { IUpdateBinAdjustmentRequest } from "../../../../redux/sil/dryPlant/updateBinAdjustment/updateBinAdjustmentConstaints";
import { IAddBinAdjustmentRequest } from "../../../../redux/sil/dryPlant/addBinAdjustment/addBinAdjustmentConstaints";
import { addBinAdjustmentStatus } from "../../../../redux/sil/dryPlant/addBinAdjustment/addBinAdjustmentAccessor";
import { addBinAdjustmentLoadAction } from "../../../../redux/sil/dryPlant/addBinAdjustment/addBinAdjustmentActions";
import { IGetProductionLookupsRequest, IProductionLookups } from "../../../../redux/sil/dryPlant/getProductionLookups/getProductionLookupsConstaints";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import { FieldValidator, IFieldErrorKeyValue } from "../../../shared/fieldValidator";
import PageSpacing from "../../../shared/pageSpacing";
import React from "react";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import LATextArea from "../../../shared/textArea";
import { getProductLookupsLoadAction } from "../../../../redux/sil/dryPlant/getProductLookups/getProductLookupsActions";
import { getProductLookupsStatus } from "../../../../redux/sil/dryPlant/getProductLookups/getProductLookupsAccessor";
import SilRoleCheck from "../../../shared/silRoleCheck";
import { LADateTimePicker } from "../../../shared/dateTimePicker";


interface IDryPlantBinAdjustmentStoreProps {
    addBinAdjustment: Server<string>;
    updateBinAdjustment: Server<string>;
    getToken: Server<SilAPIResponse<IToken>>;
    getBinAdjustments: Server<SilAPIResponse<IBinAdjustments[]>>;
    getDropDownData: Server<SilAPIResponse<IProductionLookups>>;
};

interface IDryPlantBinAdjustmentDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getBinAdjustmentsRequest: (data: IGetBinAdjustmentsRequest) => unknown;
    addBinAdjustmentRequest: (data: IAddBinAdjustmentRequest) => unknown;
    updateBinAdjustmentRequest: (data: IUpdateBinAdjustmentRequest) => unknown;
    getDropDownDataRequest: (data: IGetProductionLookupsRequest) => unknown;
};


interface IDryPlantBinAdjustmentOwnProps {

};

interface IDryPlantBinAdjustmentState {
    binAdjustment: IBinAdjustments;
    errors: ById<IFieldErrorKeyValue>;
};

const DryPlantBinAdjustmentStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
`;

type IDryPlantBinAdjustmentProps = RouteComponentProps
    & IDryPlantBinAdjustmentStoreProps
    & IDryPlantBinAdjustmentDispatchProps
    & IDryPlantBinAdjustmentOwnProps;

class DryPlantBinAdjustment extends PureComponent<IDryPlantBinAdjustmentProps, IDryPlantBinAdjustmentState> {

    public constructor(props: IDryPlantBinAdjustmentProps) {
        super(props);
        this.state = {
            errors: {
                "bin": { key: "bin", message: "Required" },
                "product": { key: "product", message: "Required" },
                "tonnage": { key: "tonnage", message: "Required" },
                "reason": { key: "reason", message: "Required" }
            },
            binAdjustment: {
                id: 0,
                type: "Adjustment",
                adjustment_Type: "Add",
                bin: "",
                bin_From: "",
                bin_To: "",
                product: "",
                transfer_Date: undefined,
                tonnage: undefined,
                reason: "",
                created_By: userName,
                modified_By: userName
            }
        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: IDryPlantBinAdjustmentProps): void {
        if (this.props !== prevProps) {
            this.callServer();

            if (this.props.addBinAdjustment !== prevProps.addBinAdjustment) {
                if (isSucceeded(this.props.addBinAdjustment)) {
                    this.handleCancel();
                };
            };

            if (this.props.updateBinAdjustment !== prevProps.updateBinAdjustment) {
                if (isSucceeded(this.props.updateBinAdjustment)) {
                    this.handleCancel();
                };
            };
        }
    };


    public render(): ReactNode {

        const { binAdjustment, errors } = this.state;
        const { getDropDownData, updateBinAdjustment, addBinAdjustment } = this.props;
        const handleBlur = (): void => this.onNumberChange("tonnage", Number(binAdjustment.tonnage).toFixed(2));
        const products = hasPayload(getDropDownData) ? convertList(getDropDownData.payload.response.products ?? []) : [];

        return (
            <PageSpacing title="SIL - BinAdjustment" description="SIL - BinAdjustment" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="dryPlantAccess">
                    <DryPlantBinAdjustmentStyles>

                        <LAButton startIcon={<ArrowLeftIcon color={WHITE_COLOR} />} label="Back to list" onClick={this.handleCancel} />
                        <h2 className="text-center">{binAdjustment.id > 0 ? "VIEW/UPDATE " : "ADD "} BIN ADJUSTMENT</h2>
                        <hr />

                        <LAPaperWithPadding>
                            <LAGrid>

                                <LAGridItem xs={6}>
                                    <LAAutoComplete
                                        name="type"
                                        multiple={false}
                                        autoHighlight={true}
                                        getOptionLabel="name"
                                        dropDownPlaceHolder="Type"
                                        option={binAdjustmentTypes}
                                        filterSelectedOptions={true}
                                        selectionRemove={undefinedFunction}
                                        onChange={this.handleTypeDropDownChange}
                                        value={binAdjustmentTypes.find(x => x.name === binAdjustment.type)}
                                        defaultValue={binAdjustmentTypes.find(x => x.name === binAdjustment.type)}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={6}>
                                    <LAAutoComplete
                                        name="product"
                                        multiple={false}
                                        option={products}
                                        autoHighlight={true}
                                        getOptionLabel="name"
                                        filterSelectedOptions={true}
                                        dropDownPlaceHolder="Product"
                                        selectionRemove={undefinedFunction}
                                        onChange={this.handleDropDownChange}
                                        errorText={errors["product"] ? errors["product"].message : undefined}
                                        value={(products && (products.length > 0) && (binAdjustment.product.length > 0)) ? products.find(x => x.name === binAdjustment.product) : ""}
                                        defaultValue={(products && (products.length > 0) && (binAdjustment.product.length > 0)) ? products.find(x => x.name === binAdjustment.product) : ""}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={6}>
                                            <LADateTimePicker
                                                fullWidth={true}
                                                name="transfer_Date"
                                                label="Transfer Date"
                                                value={binAdjustment.transfer_Date ?? ""}
                                                onChange={this.onChange}
                                                errorText={errors["transfer_Date"] ? errors["transfer_Date"].message : undefined}
                                            />
                                        </LAGridItem>

                                {binAdjustment.type === "Adjustment" && <AdjustmentFields data={binAdjustment} onChange={this.onChange} errors={errors} />}
                                {binAdjustment.type === "Transfer" && <TransferFields data={binAdjustment} onChange={this.onChange} errors={errors} />}

                                <LAGridItem xs={6}>
                                    <LATextArea
                                        rowsMax={1}
                                        name="tonnage"
                                        label="Tonnage"
                                        fullWidth={true}
                                        onBlur={handleBlur}
                                        onChange={this.onNumberChange}
                                        errorText={errors["tonnage"] ? errors["tonnage"].message : undefined}
                                        value={(binAdjustment.tonnage !== undefined) ? binAdjustment.tonnage.toString() : ""}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={6}>
                                    <LATextArea
                                        minRows={4}
                                        rowsMax={8}
                                        label="Reason"
                                        name="reason"
                                        fullWidth={true}
                                        onChange={this.onChange}
                                        value={binAdjustment.reason}
                                        errorText={errors["reason"] ? errors["reason"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12}>
                                    <LASaveAndCancelButton
                                        onSave={this.onSave}
                                        onCancel={this.handleCancel}
                                        disableSave={Object.values(errors).length > ZEROTH ? true : undefined}
                                    />
                                </LAGridItem>

                            </LAGrid>
                        </LAPaperWithPadding>

                        <RequestStatus requestStatus={addBinAdjustment.kind} successMessage="Bin Adjustment successfully saved" />
                        <RequestStatus requestStatus={updateBinAdjustment.kind} successMessage="Bin Adjustment successfully updated" />
                    </DryPlantBinAdjustmentStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, isRequired: boolean): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: isRequired ? true : undefined, minLength: 1, decimal: undefined });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        };
        return err;
    };

    private handleCancel = (): void => {
        this.props.history.push(ROUTE.SIL.DRY_PLANT.DRY_PLANT_BIN_ADJUSTMENTS);
    };

    private onSave = (): void => {
        if (hasPayload(this.props.getToken)) {
            if (this.state.binAdjustment.id === 0) {
                this.props.addBinAdjustmentRequest({
                    token: this.props.getToken.payload.response.token,
                    request: this.state.binAdjustment
                });
            } else {
                let data = this.state.binAdjustment;
                data.modified_By = userName;
                this.props.updateBinAdjustmentRequest({
                    token: this.props.getToken.payload.response.token,
                    request: data
                });
            };
        };
    };

    private handleDropDownChange = (event: unknown, value: { id: number, name: string } | "", name?: string): void => {
        let errors = this.state.errors;

        if (name) {
            errors = this.errorChecker(name, value !== "" ? value.name : "", errors, true);

            this.setState({
                ...this.state,
                errors,
                binAdjustment: {
                    ...this.state.binAdjustment,
                    [name]: value !== "" ? value.name : ""
                }
            });
        }
    };

    private handleTypeDropDownChange = (event: unknown, value: any, name?: string): void => {
        let errors = {};

        if (name && value !== null) {
            if (value.name === "Adjustment") {
                errors = {
                    "bin": { key: "bin", message: "Required" },
                    "product": { key: "product", message: "Required" },
                    "tonnage": { key: "tonnage", message: "Required" },
                    "reason": { key: "reason", message: "Required" }
                };
            } else {
                errors = {
                    "bin_From": { key: "bin_From", message: "Required" },
                    "bin_To": { key: "product", message: "Required" },
                    "product": { key: "product", message: "Required" },
                    "tonnage": { key: "tonnage", message: "Required" },
                    "reason": { key: "reason", message: "Required" }
                };
            }

            this.setState({
                errors,
                binAdjustment: {
                    id: this.state.binAdjustment.id,
                    type: value.name,
                    adjustment_Type: "Add",
                    bin: "",
                    bin_From: "",
                    bin_To: "",
                    product: "",
                    tonnage: undefined,
                    reason: "",
                    created_By: this.state.binAdjustment.created_By,
                    modified_By: this.state.binAdjustment.modified_By
                }
            });
        }
    };

    private onChange = (name: string, value: string): void => {
        let errors = this.state.errors;
        errors = this.errorChecker(name, value, errors, true);

        this.setState({
            ...this.state,
            errors,
            binAdjustment: {
                ...this.state.binAdjustment,
                [name]: value
            }
        });
    };

    private onNumberChange = (name: string, value: string): void => {
        if (!isNaN(Number(value))) {
            let errors = this.state.errors;
            errors = this.errorChecker(name, value, errors, true);

            this.setState({
                ...this.state,
                errors,
                binAdjustment: {
                    ...this.state.binAdjustment,
                    [name]: value
                }
            });
        };
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });


        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getBinAdjustments))
            this.props.getBinAdjustmentsRequest({
                token: this.props.getToken.payload.response.token
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getDropDownData))
            this.props.getDropDownDataRequest({
                token: this.props.getToken.payload.response.token,
                requested_Page: "BinAdjustment"
            });

        const query = queryString.parse(this.props.location.search);
        const id = query.id ?? "0";

        if ((id !== "0") && (hasPayload(this.props.getBinAdjustments)) && (this.state.binAdjustment.id === 0)) {
            const binAdjustment = this.props.getBinAdjustments.payload.response.find(x => x.id === Number(id));

            if (binAdjustment)
                this.setState({ binAdjustment, errors: {} });
        };

    };

}

const mapStateToProps = (state: IStore): IDryPlantBinAdjustmentStoreProps => ({
    getToken: getTokenStatus(state),
    getBinAdjustments: getBinAdjustmentsStatus(state),
    addBinAdjustment: addBinAdjustmentStatus(state),
    updateBinAdjustment: updateBinAdjustmentStatus(state),
    getDropDownData: getProductLookupsStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IDryPlantBinAdjustmentDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getBinAdjustmentsRequest: (data: IGetBinAdjustmentsRequest) => dispatch(getBinAdjustmentsLoadAction(data)),
    addBinAdjustmentRequest: (data: IUpdateBinAdjustmentRequest) => dispatch(addBinAdjustmentLoadAction(data)),
    updateBinAdjustmentRequest: (data: IUpdateBinAdjustmentRequest) => dispatch(updateBinAdjustmentLoadAction(data)),
    getDropDownDataRequest: (data: IGetProductionLookupsRequest) => dispatch(getProductLookupsLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DryPlantBinAdjustment);


const binAdjustmentTypes: { id: string, name: string }[] = [{ id: "Adjustment", name: "Adjustment" }, { id: "Transfer", name: "Transfer" }];

interface IBinFormFields {
    data: IBinAdjustments;
    errors: ById<IFieldErrorKeyValue>;
    onChange: (name: string, value: string) => void;
};

const AdjustmentFields: React.FC<IBinFormFields> = React.memo((props: IBinFormFields) => {
    const onAdjustmentType = (e: any, value: any): void => props.onChange("adjustment_Type", value);
    return (
        <>
            <LAGridItem xs={6}>
                <LATextArea
                    rowsMax={1}
                    name="bin"
                    label="Bin"
                    fullWidth={true}
                    value={props.data.bin}
                    onChange={props.onChange}
                    errorText={props.errors["bin"] ? props.errors["bin"].message : undefined}
                />
            </LAGridItem>

            <LAGridItem xs={6}>
                <FormLabel component="legend">Adjustment Type</FormLabel>
                <FormControl component="fieldset">
                    <RadioGroup row={true} aria-label="Adjustment Type" name="Adjustment Type" value={props.data.adjustment_Type} onChange={onAdjustmentType}>
                        <FormControlLabel value="Add" control={<Radio />} label="Add" />
                        <FormControlLabel value="Remove" control={<Radio />} label="Remove" />
                    </RadioGroup>
                </FormControl>
            </LAGridItem>
        </>
    );
});

const TransferFields: React.FC<IBinFormFields> = React.memo((props: IBinFormFields) => {
    return (
        <>
            <LAGridItem xs={6}>
                <LATextField
                    label="Bin From"
                    fullWidth={true}
                    name="bin_From"
                    onChange={props.onChange}
                    value={props.data.bin_From}
                    errorText={props.errors["bin_From"] ? props.errors["bin_From"].message : undefined}
                />
            </LAGridItem>

            <LAGridItem xs={6}>
                <LATextField
                    label="Pile To"
                    fullWidth={true}
                    name="bin_To"
                    onChange={props.onChange}
                    value={props.data.bin_To}
                    errorText={props.errors["bin_To"] ? props.errors["bin_To"].message : undefined}
                />
            </LAGridItem>
        </>
    );
});