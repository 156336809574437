import { IPassTokenWithRequest } from "../../../sil/dryPlant/token/getTokenConstants";

export enum IGET_PRODUCTS_BY_ID_REQUEST {
    REQUEST = "getProductsById/GET_PRODUCTS_BY_ID_REQUEST",
    SUCCESS = "getProductsById/GET_PRODUCTS_BY_ID_SUCCESS",
    FAILED = "getProductsById/GET_PRODUCTS_BY_ID_FAILED"
};

export interface IGetProductsByIdRequest extends IPassTokenWithRequest {
    request: {
        Order_ID: number;
    };
};

export interface ISIMOSProductsById {
    id: number;
    customer_ID: number;
    quantity: number;
    size: string;
    per: string;
    product: string;
    code: string;
    cw: string;
    double_CW: string;
    orderID: number;
    actual_Quantity: number;
    status: string;
    created_By: string;
    modified_By: string;
    created: string;
    modified: string;
    batch: string;
};