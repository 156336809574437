import { ByIdNumber, SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetCarriersRequest, IGET_CARRIERS_REQUEST, ICarrier } from "./getCarriersConstaints";

export interface IGetCarriersLoadAction {
    type: IGET_CARRIERS_REQUEST.REQUEST;
    data: IGetCarriersRequest
}
export const getCarriersLoadAction = (data: IGetCarriersRequest): IGetCarriersLoadAction => ({
    type: IGET_CARRIERS_REQUEST.REQUEST,
    data
});

export interface IGetCarriersSuccessAction {
    type: IGET_CARRIERS_REQUEST.SUCCESS;
    data: SilAPIResponse<ByIdNumber<ICarrier>>;
}
export const getCarriersLoadSuccessAction = (data: SilAPIResponse<ByIdNumber<ICarrier>>): IGetCarriersSuccessAction => ({
    type: IGET_CARRIERS_REQUEST.SUCCESS,
    data
});

export interface IGetCarriersLoadFailedAction {
    type: IGET_CARRIERS_REQUEST.FAILED;
    message: string;
}
export const getCarriersLoadFailedAction = (message: string): IGetCarriersLoadFailedAction => ({
    type: IGET_CARRIERS_REQUEST.FAILED,
    message
});
