import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import styled from "styled-components";
import { LAPaperWithPadding } from "./shared/paper";
import { ROUTE } from "./routes";
import LAGrid from "./shared/grid";
import LAGridItem from "./shared/gridList";
import { DARK_RED_COLOR, BLUE_GREEN_COLOR, MEDIA_QUERY_PHONE, WHITE_COLOR } from "./shared/theme";
import { getTokenStatus } from "../redux/sil/dryPlant/token/getTokenAccessor";
import { IGetTokenRequest, IToken } from "../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenLoadAction } from "../redux/sil/dryPlant/token/getTokenActions";
import { isNotLoaded, Server } from "../redux/server";
import { SilAPIResponse } from "./shared/publicInterfaces";
import { IDispatch, IStore } from "../redux/reducers";
import { connect } from "react-redux";
import { undefinedFunction, userName } from "./shared/constExports";
import PageSpacing from "./shared/pageSpacing";
import SilRoleCheck from "./shared/silRoleCheck";
import { LAExpansionPanel } from "./shared/expansionPanel";


interface ISilHomeStoreProps {
    getToken: Server<SilAPIResponse<IToken>>;
};

interface ISilHomeDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
};

const SilHomeStyles = styled(LAPaperWithPadding)`
    margin: 40px 30px;
    word-break: break-word;

    .title {
        color: ${DARK_RED_COLOR};
        font-size: 40px;
        font-weight: bold;
        text-shadow: 2px 1px #b30003;
        
    };

    .homeMenu {
        color: #000;
        height: 90px;
        cursor: pointer;
        border-radius: 15px;
        padding: 5px;
        border: 2px solid #fff;
        background-color: #E7F2F8;
        box-shadow: 0 2px 10px 0 #b30003;
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 5.0;
        margin-right: 10%;
        -webkit-transition: background 2.5s linear;
        -moz-transition: background 2.5s linear;
        -o-transition: background 2.5s linear;
        -ms-transition: background 2.5s linear;
        transition: background 2.5s linear;
    };

    .appTitle {
        font-size: 20px;
        font-weight: bold;
        margin-top: 10%;
        text-shadow: 0.5px 1px #719de3;
        color: #5d95f0;
    };

    .TileSpacing{
        padding: 20px;
    }

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };
`;

type ISilHomeProps = RouteComponentProps &
    ISilHomeDispatchProps & ISilHomeStoreProps;

class SilHome extends PureComponent<ISilHomeProps> {

    public constructor(props: ISilHomeProps) {
        super(props);
        this.state = {
        };
    }

    public componentDidMount(): void {
        this.checkTokenStatus();
    };

    public componentDidUpdate(prevProps: ISilHomeProps): void {
        if (this.props !== prevProps)
            this.checkTokenStatus();
    };


    public render(): ReactNode {

        return (
            <PageSpacing title="SIL" description="SIL" fixedSpaceOnSmallerScreens={true}>
                <SilHomeStyles>
                    <LAGrid spacing={3} className="text-center" >

                        <LAGridItem xs={12}>
                            <h2 className="title">SIL HOME</h2>
                            <hr />
                        </LAGridItem>

                        <LAGridItem xs={12}>
                            <LAExpansionPanel color={BLUE_GREEN_COLOR} textColor={WHITE_COLOR} label="DISPATCH" expanded={true} onClick={undefinedFunction}>
                                <LAGrid className="TileSpacing">
                                    <SilRoleCheck error={false} roleFor="carrierAccess">
                                        <LAGridItem xs={2}>
                                            <div className="homeMenu" onClick={this.onCarrier}>
                                                <h2 className="appTitle carrierTitle">Carrier</h2>
                                            </div>
                                        </LAGridItem>

                                        <LAGridItem xs={2}>
                                            <div className="homeMenu" onClick={this.onAudit}>
                                                <h2 className="appTitle carrierTitle">Carrier Audit</h2>
                                            </div>
                                        </LAGridItem>

                                        <LAGridItem xs={2}>
                                            <div className="homeMenu" onClick={this.onSilPS}>
                                                <h2 className="appTitle carrierTitle">LMS (Dispatch/Orders/Loads)</h2>
                                            </div>
                                        </LAGridItem>
                                    </SilRoleCheck>

                                </LAGrid>
                            </LAExpansionPanel>
                        </LAGridItem>

                        <LAGridItem xs={12}>
                            <LAExpansionPanel color={BLUE_GREEN_COLOR} textColor={WHITE_COLOR} label="PRODUCTION" expanded={true} onClick={undefinedFunction}>
                                <LAGrid spacing={3} className="TileSpacing" >

                                    <SilRoleCheck error={false} roleFor="dryPlantAccess" loadingDisable={true}>
                                        <LAGridItem xs={2}>
                                            <div className="homeMenu" onClick={this.onDryPlant}>
                                                <h2 className="appTitle">Dry Plant</h2>
                                            </div>
                                        </LAGridItem>
                                    </SilRoleCheck>

                                    <SilRoleCheck error={false} roleFor="washPlantAccess" loadingDisable={true}>
                                        <LAGridItem xs={2}>
                                            <div className="homeMenu" onClick={this.onWashPlant}>
                                                <h2 className="appTitle">Wash Plant</h2>
                                            </div>
                                        </LAGridItem>
                                    </SilRoleCheck>

                                    <SilRoleCheck error={false} roleFor="sieveAccess" loadingDisable={true}>
                                        <LAGridItem xs={2}>
                                            <div className="homeMenu" onClick={this.onSieves}>
                                                <h2 className="appTitle">Sieves</h2>
                                            </div>
                                        </LAGridItem>
                                    </SilRoleCheck>

                                    <SilRoleCheck error={false} roleFor="explorationAccess" loadingDisable={true}>
                                        <LAGridItem xs={2}>
                                            <div className="homeMenu" onClick={this.onExploration}>
                                                <h2 className="appTitle">Exploration</h2>
                                            </div>
                                        </LAGridItem>
                                    </SilRoleCheck>

                                    <SilRoleCheck error={false} roleFor="warehouseAccess" loadingDisable={true}>
                                        <LAGridItem xs={2}>
                                            <div className="homeMenu" onClick={this.onWarehouseProducts}>
                                                <h2 className="appTitle">Warehouse Products</h2>
                                            </div>
                                        </LAGridItem>
                                    </SilRoleCheck>

                                    <SilRoleCheck error={false} roleFor="warehouseAccess" loadingDisable={true}>
                                        <LAGridItem xs={2}>
                                            <div className="homeMenu" onClick={this.onBaggedInventories}>
                                                <h2 className="appTitle">Bagged Inventories</h2>
                                            </div>
                                        </LAGridItem>
                                    </SilRoleCheck>

                                </LAGrid>
                            </LAExpansionPanel>
                        </LAGridItem>

                        <LAGridItem xs={12}>
                            <LAExpansionPanel color={BLUE_GREEN_COLOR} textColor={WHITE_COLOR} label="UTILITIES" expanded={true} onClick={undefinedFunction}>
                                <LAGrid className="TileSpacing">
                                    <SilRoleCheck error={false} roleFor="complaintLoggingAccess">
                                        <LAGridItem xs={2}>
                                            <div className="homeMenu" onClick={this.onComplaintLogging}>
                                                <h2 className="appTitle">Complaint Loggings</h2>
                                            </div>
                                        </LAGridItem>
                                    </SilRoleCheck>

                                   
                                        <LAGridItem xs={2}>
                                            <div className="homeMenu" onClick={this.onQualityControlTestings}>
                                                <h2 className="appTitle">Turbidity QAQC Tests</h2>
                                            </div>
                                        </LAGridItem>
                                   
                                </LAGrid>
                            </LAExpansionPanel>
                        </LAGridItem>

                        {/* <LAGridItem xs={12}>
                            <LAExpansionPanel color={BLUE_GREEN_COLOR} textColor={WHITE_COLOR} label="CV" expanded={true} onClick={undefinedFunction}>
                                <SilRoleCheck error={false} roleFor="complaintLoggingAccess">
                                    <LAGrid className="TileSpacing">

                                        <LAGridItem xs={2}>
                                            <div className="homeMenu" onClick={this.onBeltScales}>
                                                <h2 className="appTitle">Belt Scales</h2>
                                            </div>
                                        </LAGridItem>

                                        <LAGridItem xs={2}>
                                            <div className="homeMenu" onClick={this.onCranes}>
                                                <h2 className="appTitle">Cranes</h2>
                                            </div>
                                        </LAGridItem>

                                        <LAGridItem xs={2}>
                                            <div className="homeMenu" onClick={this.onLabScales}>
                                                <h2 className="appTitle">Lab Scales</h2>
                                            </div>
                                        </LAGridItem>

                                        <LAGridItem xs={2}>
                                            <div className="homeMenu" onClick={this.onNCR}>
                                                <h2 className="appTitle">NCR</h2>
                                            </div>
                                        </LAGridItem>

                                        <LAGridItem xs={2}>
                                            <div className="homeMenu" onClick={this.onTruckScales}>
                                                <h2 className="appTitle">Truck Scales</h2>
                                            </div>
                                        </LAGridItem>

                                        <LAGridItem xs={2}>
                                            <div className="homeMenu" onClick={this.onWarehouses}>
                                                <h2 className="appTitle">Warehouses</h2>
                                            </div>
                                        </LAGridItem>

                                        <LAGridItem xs={2}>
                                            <div className="homeMenu" onClick={this.onSieveDryPlant}>
                                                <h2 className="appTitle">Sieve Dry Plant</h2>
                                            </div>
                                        </LAGridItem>

                                        <LAGridItem xs={2}>
                                            <div className="homeMenu" onClick={this.onSieveFracBall}>
                                                <h2 className="appTitle">Sieve Frac Ball</h2>
                                            </div>
                                        </LAGridItem>

                                        <LAGridItem xs={2}>
                                            <div className="homeMenu" onClick={this.onSieveWashPlant}>
                                                <h2 className="appTitle">Sieve Wash Plant</h2>
                                            </div>
                                        </LAGridItem>

                                    </LAGrid>
                                </SilRoleCheck>
                            </LAExpansionPanel>
                        </LAGridItem>

                        <LAGridItem xs={12}>
                            <LAExpansionPanel color={BLUE_GREEN_COLOR} textColor={WHITE_COLOR} label="DISPATCH" expanded={true} onClick={undefinedFunction}>
                                <LAGrid className="TileSpacing">
                                        <LAGridItem xs={2}>
                                            <div className="homeMenu" onClick={this.onGeoBags}>
                                                <h2 className="appTitle">Geo Bags</h2>
                                            </div>
                                        </LAGridItem>
                                
                                        <LAGridItem xs={2}>
                                            <div className="homeMenu" onClick={this.onTrailerActivityLogs}>
                                                <h2 className="appTitle">Trailer Activity Logs</h2>
                                            </div>
                                        </LAGridItem>

                                        <LAGridItem xs={2}>
                                            <div className="homeMenu" onClick={this.onTransloadCounts}>
                                                <h2 className="appTitle">Transload BOL Counts</h2>
                                            </div>
                                        </LAGridItem>
                                   
                                </LAGrid>
                            </LAExpansionPanel>
                        </LAGridItem> */}

                    </LAGrid>
                </SilHomeStyles>
            </PageSpacing>
        );
    }

    private onGeoBags = (): void => {
        this.props.history.push(ROUTE.SIL.DISPATCH.GEO_BAGS);
    };

    private onTrailerActivityLogs = (): void => {
        this.props.history.push(ROUTE.SIL.DISPATCH.TRAILER_ACTIVITY_LOGS);
    };

    private onTransloadCounts = (): void => {
        this.props.history.push(ROUTE.SIL.DISPATCH.TRANSLOAD_BOL_COUNTS);
    };

    private onBeltScales = (): void => {
        this.props.history.push(ROUTE.SIL.CV.BELT_SCALES.INDEX);
    };

    private onCranes = (): void => {
        this.props.history.push(ROUTE.SIL.CV.CRANES.INDEX);
    };

    private onLabScales = (): void => {
        this.props.history.push(ROUTE.SIL.CV.LAB_SCALES.INDEX);
    };

    private onNCR = (): void => {
        this.props.history.push(ROUTE.SIL.CV.NCR.INDEX);
    };

    private onTruckScales = (): void => {
        this.props.history.push(ROUTE.SIL.CV.TRUCK_SCALES.INDEX);
    };

    private onWarehouses = (): void => {
        this.props.history.push(ROUTE.SIL.CV.WAREHOUSES.INDEX);
    };

    private onSieveDryPlant = (): void => {
        this.props.history.push(ROUTE.SIL.CV.SIEVE_DRY_PLANTS.INDEX);
    };

    private onSieveWashPlant = (): void => {
        this.props.history.push(ROUTE.SIL.CV.SIEVE_WASH_PLANTS.INDEX);
    };

    private onSieveFracBall = (): void => {
        this.props.history.push(ROUTE.SIL.CV.SIEVE_FRAC_BALLS.INDEX);
    };

    private onQualityControlTestings = (): void => {
        this.props.history.push(ROUTE.SIL.QUALITY_CONTROL_TESTINGS.INDEX);
    };

    private onComplaintLogging = (): void => {
        this.props.history.push(ROUTE.SIL.COMPLAINT_LOGGINGS.INDEX);
    };

    private onBaggedInventories = (): void => {
        this.props.history.push(ROUTE.SIL.BAGGED_INVENTORIES.INDEX);
    };

    private onWarehouseProducts = (): void => {
        this.props.history.push(ROUTE.SIL.WAREHOUSE_PRODUCTS.PRODUCTS);
    };

    private onExploration = (): void => {
        this.props.history.push(ROUTE.SIL.EXPLORATION.INDEX);
    };

    private onSieves = (): void => {
        this.props.history.push(ROUTE.SIL.SIEVES.SIEVES_ITEMS);
    };

    private onWashPlant = (): void => {
        this.props.history.push(ROUTE.SIL.WASH_PLANT.WASH_PLANT_ITEMS);
    };

    private onCarrier = (): void => {
        this.props.history.push(ROUTE.SIL.CARRIERS.INDEX);
    };

    private onAudit = (): void => {
        this.props.history.push(ROUTE.SIL.CARRIERS.CARRIER_AUDITS);
    };

    private onSilPS = (): void => {
        window.open(`https://lms.propsense.com/`, "_blank");
    };

    private onDryPlant = (): void => {
        this.props.history.push(ROUTE.SIL.DRY_PLANT.DRY_PLANT_ITEMS);
    };

    private checkTokenStatus = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            })
    };

}

const mapStateToProps = (state: IStore): ISilHomeStoreProps => ({
    getToken: getTokenStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): ISilHomeDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(SilHome);