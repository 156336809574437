import * as React from 'react';
import '../../../../shared/simos/styling/createcontactform.css';
import {addCustomerContact} from '../APICalls/addOrderedByContact';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import BackButton from '../../../../shared/simos/helper/backButton';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import {all_customer_contacts} from './CustomerContactTable';
import {getSIMOSUserGroupAccess} from '../APICalls/getSIMOSGroupAccess';
import { getSILURLParams } from '../../../../shared/simos/helper/getURLContainsSIL';

interface IProps {
    history:any;
    isCreateFromOrderPage:boolean;
    closeCreateForm:any;
    values:any;
}
interface IState{
    customers:any;
    deliveries:any;
    chosenCustomer:string;
    chosenDelivery:string;
    orderformName:string;
    orderformEmail:string;
    orderformFax:string;
    orderformPhone:string;
    showError:boolean;
}
let customerID:any = [];
var chosenId:number;
var exportContact:any;
export default class CreateContactForm extends React.Component<IProps, IState>{
    constructor(props:any) {
        super(props);
        this.state = {
            customers:[],deliveries:[],chosenCustomer:'',chosenDelivery:'',orderformName:'',orderformEmail:'',orderformFax:'',orderformPhone:'', showError:false
        }
        this.onTextChange = this.onTextChange.bind(this);
    }
    onTextChange(event:any) {
        switch(event.target.name) {
            case 'customer-dropdown':
                var c_id= event.target.value.substr(0, event.target.value.indexOf(" "));
                this.setState({chosenCustomer:event.target.value.substr(event.target.value.indexOf(" ")+1)});
                 // * Finds the ID of selected customer which is used for cascading dropdown
                 customerID.map((each:any) => {
                    if(each.id === Number(c_id)) {
                        chosenId = each.id;
                    }
                });
                this.fetchDeliveryAddress();
                break;
            case 'delivery-dropdown':
                this.setState({chosenDelivery:event.target.value});
                break;
            case 'contact':
                this.setState({orderformName:event.target.value});
                break;
            case 'email':
                this.setState({orderformEmail:event.target.value});
                break;
            case 'fax':
                this.setState({orderformFax:event.target.value});
                break;
            case 'phone':
                this.setState({orderformPhone:event.target.value});
                break;
        }
    }
    async fetchDeliveryAddress() {
        let initialDelivery:string[] = [];
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type':'application/json' }, 
            body: JSON.stringify({token: '19515195-d571-44a3-b0db-637f5bf24f54' })
        }
        fetch('https://api2.propsense.com/api/GetSIMOSCustomerAddress', requestOptions)
        .then(async response => {
            const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
              // error response
              if(!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            // no error
            data.map((deliveryaddr:any) => {
                if(deliveryaddr.customer_ID === chosenId){
                    initialDelivery.push(deliveryaddr.address);
                }
            });
            this.setState({deliveries:initialDelivery});
        })
    }
    async onSaveForm(event:any) {
        var isUnique = true;
        event.preventDefault();
        // create a temp obj to hold the contact info to be added, instead of renaming variables
        let tempContact = [{orderformName:this.state.orderformName, 
                            orderformEmail:this.state.orderformEmail, 
                            orderformFax:this.state.orderformFax,
                            orderformPhone:this.state.orderformPhone}];
        if(this.state.orderformName != "" && this.state.orderformPhone != ""
        && this.state.chosenCustomer!="" &&this.state.chosenDelivery !=""){
            // Check if customer contact already exists
            all_customer_contacts.map((customer_contact:any) => {
                if(customer_contact.contact === this.state.orderformName && customer_contact.customer === this.state.chosenCustomer
                     && customer_contact.delivery_Address === this.state.chosenDelivery && customer_contact.phone === this.state.orderformPhone){
                         isUnique = false;
                     }
            });
            if(isUnique){
                if(this.props.isCreateFromOrderPage){
                    var res:any = await addCustomerContact(this.props.values.c_id, tempContact, this.state.chosenDelivery, this.state.chosenCustomer);
                }
                else{
                    var res:any = await addCustomerContact(chosenId, tempContact, this.state.chosenDelivery, this.state.chosenCustomer);
                }
               // var res:any = await addCustomerContact(chosenId, tempContact, this.state.chosenDelivery, this.state.chosenCustomer);
                if(res.status === 200){
                    alert("Successfully created new customer contact");
                    // If creating customer contact came from create simos order page
                    if(this.props.isCreateFromOrderPage){
                        exportContact = tempContact[0];
                        this.props.closeCreateForm();
                    }
                    // If creating customer contact came from customer contact page
                    else{
                        this.props.history.push('/simos/viewCustomerContacts'+getSILURLParams());
                        window.location.reload();
                    }
                }
                else {
                    alert("Error creating new customer contact");
                    this.props.history.push('/simos/viewCustomerContacts'+getSILURLParams());
                }
            }
            else{
                alert("This customer contact already exists for this customer/delivery address");
            }

        }
        else{
           // alert("All fields must be filled");
           this.setState({showError:true});
        }
    }
    onCancelForm() {
        // If creating customer contact came from create simos order page
        if(this.props.isCreateFromOrderPage){
            exportContact = null;
            this.props.closeCreateForm();
        }
         // If creating customer contact came from customer contact page
        else{
            this.props.history.push('/simos/viewCustomerContacts'+getSILURLParams());
        }
    }
    async componentWillMount(){
        var response:any = await getSIMOSUserGroupAccess();
        var userRole:any = response.role;
        if(userRole!='Admin' && userRole!='Dispatcher' && userRole!='SuperAdmin'){
            this.props.history.push('/simos');
        }
    }
    componentDidMount() {
        let initialCustomers:string[] = [];
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token: '19515195-d571-44a3-b0db-637f5bf24f54' })
        };
    
        fetch('https://api2.propsense.com/api/GetSIMOSCustomers', requestOptions)
        .then(async response => {
            const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
            
            // error response
            if(!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            // no error
            data.map((customername:any) => {
                    initialCustomers.push(customername);
                    customerID.push(customername);
            });
            this.setState({customers:initialCustomers});
            
        })
        .catch(error => {
            console.log('There was an error!', error);
        });
        // When create customer contact form is rendered from create simos order page set the pre-determined customer,delivery address and customer ID
        //! todo: customerID is currently null
        //console.log(this.props.values);
        if(this.props.values){
            customerID.map((each:any) => {
                if(each.customer === this.props.values.chosenCustomer) {
                    chosenId = each.id;
                }
            });
            this.setState({chosenCustomer: this.props.values.chosenCustomer, chosenDelivery:this.props.values.chosenDelivery});
        }
    }
    render() {   
        let customerOptions = this.state.customers.map((customer:any) => <option value={customer.id +' '+customer.customer}>{customer.customer}</option>);
        customerOptions.push(<option disabled selected></option>);
        let deliveryOptions = this.state.deliveries.map((address:string) => <option>{address}</option>);

        return (
            <div className={getSILURLParams()?.includes("bru") ? "edit-popup":"edit-popup-sil-ed"}>
                <div className="edit-popup_inner">  
                {this.state.showError ? 
              <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert variant="filled" severity="error" action={<IconButton color="inherit" onClick={() => {this.setState({showError:false})}}>X</IconButton>}>
                  Please enter Customer, Customer Address, Contact Name and Phone #.
                </Alert>
              </Stack>
            :null}
            <br></br>
                <BackButton goBack={this.onCancelForm.bind(this)} history={this.props.history}></BackButton>
                    <form>
                    <h3 ><label id='header-3-label'>Simos - </label>Create Customer Contact</h3>
                        <div id='edit-info-fields'>
                            <div id='customer-deliver'>
                                <Grid container spacing={3} alignItems="center" direction="column">
                                <Grid item xs={8}>
                                    {this.props.values ? <TextField disabled value={this.props.values.chosenCustomer}></TextField>:
                                    <FormControl required>
                                    <InputLabel htmlFor="customer-dropdown-options">Customer</InputLabel>
                                        <Select native placeholder={this.state.chosenCustomer} onChange={this.onTextChange} inputProps={{name:'customer-dropdown',id:'customer-dropdown-options'}}>
                                        {customerOptions}
                                        </Select>        
                                    </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={8}>
                                {this.props.values ? <TextField disabled value={this.props.values.chosenDelivery}></TextField>:
                                    <FormControl required>
                                    <InputLabel htmlFor="delivery-dropdown-options">Delivery Address</InputLabel>
                                        <Select native placeholder={this.state.chosenDelivery} onChange={this.onTextChange} inputProps={{name:'delivery-dropdown',id:'delivery-dropdown-options'}}>
                                        <option selected disabled>N/A</option>
                                        {deliveryOptions}
                                        </Select>        
                                    </FormControl>
                                }
                                </Grid>
                             
                                </Grid>
                            </div>
                            <br></br>
                            <Grid container spacing={3} alignItems="center" direction="column">
                            <Grid item xs={12}>
                                    <TextField autoComplete='nope' label="Contact Name" name="contact" onChange={this.onTextChange} placeholder={this.state.orderformName}></TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField autoComplete='nope' label="Email" name="email" onChange={this.onTextChange} placeholder={this.state.orderformEmail}></TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField autoComplete='nope' label="Phone" name="phone" onChange={this.onTextChange} placeholder={this.state.orderformPhone}></TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField autoComplete='nope' label="Fax" name="fax" onChange={this.onTextChange} placeholder={this.state.orderformFax}></TextField>
                                </Grid>
                            
                            </Grid>
                        </div><br></br>
                        <button type='submit' onClick={this.onSaveForm.bind(this)} id='submit-contact-form-btn'>Save</button>
                        <button onClick={this.onCancelForm.bind(this)} id='cancel-contact-form-btn'>Cancel</button>
                    </form>
                </div>
            </div>
        );
    }
}
export {exportContact};