import { IPSAttachment } from "../../../../../utils/sharedTypes";
import { IPassTokenWithRequest } from "../../../dryPlant/token/getTokenConstants";


export enum IGET_TRUCK_SCALES_REQUEST {
    REQUEST = "getTruckScale/GET_TRUCK_SCALES_REQUEST",
    SUCCESS = "getTruckScale/GET_TRUCK_SCALES_SUCCESS",
    FAILED = "getTruckScale/GET_TRUCK_SCALES_FAILED"
};

export interface IGetTruckScaleRequest extends IPassTokenWithRequest {

};

export interface ITruckScale {
    id: number;
    month?: number;
    year?: number;
    scale: string;
    active: string;
    latest: string;
    attachments: string;
    created: string;
    created_By: string;
    modified: string ;
    modified_By: string;
    attachmentContent: IPSAttachment[];
};