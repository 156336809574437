import React from 'react';
import Button from '@material-ui/core/Button';
import HistoryIcon from '@material-ui/icons/History';
import '../styling/backButton.css';

interface IProps {
    history:any;
    goBack:any;
}

export default class BackButton extends React.Component<IProps>{
    constructor(props:IProps){
        super(props);
        this.backPage = this.backPage.bind(this);
    }

    backPage(event:any){
        this.props.goBack(event);
        // event.preventDefault();
        // this.props.history.goBack();
    }

    render() {
        return (
            <div>
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<HistoryIcon/>}
                    className="back-btn"
                    onClick={this.backPage}
                >
                    Back
                </Button>
            </div>
        );
    }
}