import { Paper } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { HasClassAndChildren } from "./publicInterfaces";
import { BLUE_GREEN_COLOR, DARK_GREY_COLOR, DEFAULT_BOX_SHADOW, WHITE_COLOR } from "./theme";


const LAPaperWithPaddingStyles = styled(Paper)`
padding: 5px 5px 5px !important;
position: relative;
border: 1px solid ${DARK_GREY_COLOR};
${DEFAULT_BOX_SHADOW}
`;

//#region Drop Paper
interface IDropPaperProps extends HasClassAndChildren {
    innerRef?: any;
    styleDraggar?: any;
    onClick?: () => void;
};

export const dropListStyle = (isDraggingOver: any) => ({
    background: isDraggingOver ? BLUE_GREEN_COLOR : WHITE_COLOR,
    padding: 8,
    margin: 10
});

export const DropPaper: React.FC<IDropPaperProps> = (props: IDropPaperProps) =>
    <LAPaperWithPaddingStyles
        className={`${props.className}`}
        innerRef={props.innerRef}
        style={dropListStyle(props.styleDraggar)}
        onClick={props.onClick}
        elevation={0}
    >
        {props.children}
    </LAPaperWithPaddingStyles>
;
//#endregion


//#region  Drag Paper
export const dragItemStyle = (isDragging: any, draggableStyle: any, borderColor?: string) => ({
    userSelect: "none",
    padding: 8,
    margin: 10,
    background: isDragging ? WHITE_COLOR : WHITE_COLOR,
    border: `2px solid ${borderColor ?? DARK_GREY_COLOR}`,
    borderRadius: "5px",

    ...draggableStyle
});


//#endregion