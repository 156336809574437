import { IFlushDataSuccessAction } from "../../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../../server";
import { ISaveAssignedCrewsLoadAction, ISaveAssignedCrewsLoadFailedAction, ISaveAssignedCrewsSuccessAction } from "./saveAssignedCrewsActions";
import { ISAVE_ASSIGNED_CREWS_REQUEST } from "./saveAssignedCrewsConstaints";


type Actions =
    ISaveAssignedCrewsLoadAction
    | ISaveAssignedCrewsLoadFailedAction
    | ISaveAssignedCrewsSuccessAction
    | IFlushDataSuccessAction;

export const SaveAssignedCrewsReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case ISAVE_ASSIGNED_CREWS_REQUEST.REQUEST:
            return loading;

        case ISAVE_ASSIGNED_CREWS_REQUEST.SUCCESS:
            return succeeded("Success");

        case ISAVE_ASSIGNED_CREWS_REQUEST.FAILED:
            return failed(action.message);

            case FLUSH_DATA.SUCCESS:
                return notLoaded;

        default:
            return state;
    }
};