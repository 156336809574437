import { IPassTokenWithRequest } from "../../../dryPlant/token/getTokenConstants";


export enum IGET_CHEMICAL_ANALYSES_REQUEST {
    REQUEST = "getChemicalAnalyses/GET_CHEMICAL_ANALYSES_REQUEST",
    SUCCESS = "getChemicalAnalyses/GET_CHEMICAL_ANALYSES_SUCCESS",
    FAILED = "getChemicalAnalyses/GET_CHEMICAL_ANALYSES_FAILED"
};

export interface IGetChemicalAnalysesRequest extends IPassTokenWithRequest {

};

export interface IChemicalAnalyses {
    id: number;
    product: string;
    plant: string;
    sample_Location: string;
    sample_Date: string;
    sampler: string;
    analysis_Date: string;
    tests_Ran?: number;
    analyzed_By: string;
    mgO_Perc?: number;
    al2O3_Perc?: number;
    siO2_Perc?: number;
    p2O5_Perc?: number;
    caO_Perc?: number;
    tiO2_Perc?: number;
    mnO_Perc?: number;
    feO_Perc?: number;
    lE_Perc?: number;
    created: string;
    created_By: string;
    modified: string;
    modified_By: string;
};