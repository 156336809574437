import { IGET_SIMOS_PRODUCTS_REQUEST, ISimosProducts } from "./getSimosProductsConstants";
import { IGetSimosProductsLoadAction, IGetSimosProductsLoadFailedAction, IGetSimosProductsSuccessAction } from "./getSimosProductsActions";
import { SimosAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../../server";
import { IFlushDataSuccessAction } from '../../../../flushData.tsx/flushDataActions';
import { FLUSH_DATA } from '../../../../flushData.tsx/flushDataConstants';
import { IAddSimosProductSuccessAction } from "../addSimosProduct/addSimosProductActions";
import { IADD_SIMOS_PRODUCT_REQUEST } from '../addSimosProduct/addSimosProductConstaints';
import { IUpdateSimosProductSuccessAction } from "../updateSimosProduct/updateSimosProductActions";
import { IUPDATE_SIMOS_PRODUCT_REQUEST } from "../updateSimosProduct/updateSimosProductConstaints";

type Actions =
    | IGetSimosProductsLoadAction
    | IGetSimosProductsSuccessAction
    | IGetSimosProductsLoadFailedAction
    | IAddSimosProductSuccessAction
    | IUpdateSimosProductSuccessAction
    | IFlushDataSuccessAction;

export const GetSimosProductsReducer = (state: Server<SimosAPIResponse<ISimosProducts[]>> = notLoaded, action: Actions): Server<SimosAPIResponse<ISimosProducts[]>> => {
    switch (action.type) {
        case IGET_SIMOS_PRODUCTS_REQUEST.REQUEST:
            return loading;

        case IGET_SIMOS_PRODUCTS_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_SIMOS_PRODUCTS_REQUEST.FAILED:
            return failed(action.message);

        case IADD_SIMOS_PRODUCT_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_SIMOS_PRODUCT_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};