import React from "react";
import { IWashPlantDownTime, IWashPlantProductionData } from "../../../../redux/sil/washPlant/getWashPlantProductionRecords/getWashPlantProductionRecordsConstaints";
import { IWashPlantProductionSummary } from "../../../../redux/sil/washPlant/getWashPlantProductionSummary/getWashPlantProductionSummaryConstants";
import { undefinedFunction } from "../../../shared/constExports";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { LAPaperWithLessPadding, LAPaperWithPadding } from "../../../shared/paper";
import { LAPopover } from "../../../shared/popOver";

interface IProductionDataPopup {
    open: boolean;
    onClose: () => void;
    data: IWashPlantProductionData[];
    summary: IWashPlantProductionSummary[];
};

export const ProductionDataPopup: React.FC<IProductionDataPopup> = React.memo((props: IProductionDataPopup) => (
    <LAPopover open={props.open} onClose={props.onClose} anchorRef={null}>
        <LAPaperWithPadding>
            <LADevExtremeGrid
                height={400}
                id="production"
                searchPanel={false}
                filterHeader={false}
                data={props.data}
                onClick={undefinedFunction}
                columns={[
                    { name: "plant", caption: "Plant", type: "string", width: 80 },
                    { name: "feed_1_Material", caption: "Feeder 1 North", type: "string", width: 80 },
                    { name: "feed_1_Perc", caption: "Feeder1 North MT", type: "number", width: 80 },
                    { name: "feed_2_Material", caption: "Feeder 1 South", type: "string", width: 80 },
                    { name: "feed_2_Perc", caption: "Feeder1 South MT", type: "number", width: 80 },
                    { name: "feed_3_Material", caption: "Feeder 2 North", type: "string", width: 80 },
                    { name: "feed_3_Perc", caption: "Feeder2 North MT", type: "number", width: 80 },
                    { name: "feed_4_Material", caption: "Feeder 2 South", type: "string", width: 80 },
                    { name: "feed_4_Perc", caption: "Feeder2 South MT", type: "number", width: 80 },
                    { name: "feed_Tonnage", caption: "Feed Tonnage", type: "string", width: 80 },
                    { name: "run_Hours", caption: "Run Hours", type: "string", width: 80 },
                    { name: "by_Product_1", caption: "Tower 1", width: 80 },
                    { name: "by_Product_1_MT", caption: "Tower 1 MT", width: 80 },
                    { name: "by_Product_2", caption: "Tower 2", width: 80 },
                    { name: "by_Product_2_MT", caption: "Tower 2 MT", type: "string", width: 80 },
                    { name: "by_Product_3", caption: "Tower 4", width: 80 },
                    { name: "by_Product_3_MT", caption: "Tower 4 MT", type: "string", width: 80 },
                    { name: "by_Product_4", caption: "Fines Tower", width: 80 },
                    { name: "by_Product_4_MT", caption: "Fines Tower MT", type: "string", width: 80 },
                    { name: "by_Product_5", caption: "Ultra Fines Tower", width: 80 },
                    { name: "by_Product_5_MT", caption: "Ultra Fines Tower MT", type: "string", width: 80 },
                    { name: "by_Product_6", caption: "9U Tower", width: 80 },
                    { name: "by_Product_6_MT", caption: "9U Tower MT", type: "string", width: 80 },
                    { name: "by_Product_7", caption: "4X8 Tower", width: 80 },
                    { name: "by_Product_7_MT", caption: "4X8 Tower MT", type: "string", width: 80 },
                    { name: "by_Product_8", caption: "Radon Tower", width: 80 },
                    { name: "by_Product_8_MT", caption: "Radon Tower MT", type: "string", width: 80 },
                    // { name: "by_Product_4", caption: "By Product4", width: 150  },
                    // { name: "by_Product_4_MT", caption: "By Product4 MT", type: "string", width: 120  },
                   
                ]}
                sumColumn={[
                    { columnName: "by_Product_1_MT", caption: "Total" },
                    { columnName: "by_Product_2_MT", caption: "Total" },
                    { columnName: "by_Product_3_MT", caption: "Total" },
                    { columnName: "by_Product_4_MT", caption: "Total" },
                    { columnName: "by_Product_5_MT", caption: "Total" },
                    { columnName: "by_Product_6_MT", caption: "Total" },
                    { columnName: "by_Product_7_MT", caption: "Total" },
                    { columnName: "by_Product_8_MT", caption: "Total" },
                    { columnName: "feed_Tonnage", caption: "Total" }
                ]}
            />
            <ProductionSummaryTable data={props.summary} />
        </LAPaperWithPadding>
    </LAPopover>
));

//Downtime Popup
interface IDowntimeDataPopup {
    open: boolean;
    data: IWashPlantDownTime[];
    onClose: () => void;
};

export const DowntimeDataPopup: React.FC<IDowntimeDataPopup> = React.memo((props: IDowntimeDataPopup) => (
    <LAPopover open={props.open} onClose={props.onClose} anchorRef={null}>
        <LAPaperWithPadding>
            <LADevExtremeGrid
                height={400}
                id="downtime"
                searchPanel={false}
                filterHeader={false}
                data={props.data}
                onClick={undefinedFunction}
                columns={[
                    { name: "plant", caption: "Plant", type: "string" },
                    { name: "un_Scheduled_Downtime_Hrs", caption: "UNSCHD Hrs", type: "number", width: 110 },
                    // { name: "unschD_Notes", caption: "UNSCHD Notes", type: "string" },
                    // { name: "scheduled_Downtime_Hrs", caption: "SCHD Hrs", type: "number", width: 100 },
                    // { name: "schD_Notes", caption: "SCHD Notes", type: "string" },
                    // { name: "component", caption: "Component", type: "string" },
                    // { name: "cause", caption: "Cause", type: "string" },
                    // { name: "reason", caption: "Reason", type: "string" },
                    // { name: "category", caption: "Category", type: "string" }
                    { name: "downtime_Note_1", caption: "Note 1", type: "string"},
                    { name: "downtime_Note_2", caption: "Note 2", type: "string"},
                    { name: "downtime_Note_3", caption: "Note 3", type: "string"},
                    { name: "downtime_Note_4", caption: "Note 4", type: "string"},
                    { name: "downtime_Note_5", caption: "Note 5", type: "string"},
                    { name: "downtime_Note_6", caption: "Note 6", type: "string"},
                ]}
                sumColumn={[
                    { columnName: "un_Scheduled_Downtime_Hrs", caption: "Total Hrs" },
                    // { columnName: "scheduled_Downtime_Hrs", caption: "Total Hrs" }
                ]}
            />
        </LAPaperWithPadding>
    </LAPopover>
));

interface IProductionSummaryTableProps {
    data: IWashPlantProductionSummary[];
};

export const ProductionSummaryTable: React.FC<IProductionSummaryTableProps> = React.memo((props: IProductionSummaryTableProps) => (
    <LAPaperWithLessPadding>
        <LADevExtremeGrid
            height={300}
            id="pro-summary"
            searchPanel={false}
            filterHeader={false}
            data={props.data}
            columnWidth={130}
            onClick={undefinedFunction}
            columns={[
                { name: "product", caption: "By Products", type: "string" },
                { name: "tonnage", caption: "Total Tonnage (MT)", type: "number" },
            ]}
        />
    </LAPaperWithLessPadding>
));