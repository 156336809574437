import { IWarehouse } from "../getWarehouses/getWarehousesConstaints";

export enum IADD_WAREHOUSE_REQUEST {
    REQUEST = "addWarehouse/ADD_WAREHOUSE_REQUEST",
    SUCCESS = "addWarehouse/ADD_WAREHOUSE_SUCCESS",
    FAILED = "addWarehouse/ADD_WAREHOUSE_FAILED"
};

export interface IAddWarehouseRequest {
    token: string;
    request: IWarehouse;
};