import { IPSAttachment } from "../../../../../utils/sharedTypes";
import { IPassTokenWithRequest } from "../../../dryPlant/token/getTokenConstants";


export enum IGET_WAREHOUSES_REQUEST {
    REQUEST = "getWarehouse/GET_WAREHOUSES_REQUEST",
    SUCCESS = "getWarehouse/GET_WAREHOUSES_SUCCESS",
    FAILED = "getWarehouse/GET_WAREHOUSES_FAILED"
};

export interface IGetWarehouseRequest extends IPassTokenWithRequest {

};
export interface IWarehouse {
    id: number;
    scale: string;
    model?: string;
    month?: number;
    year?: number;
    five_Hundred_G_Reading?: number;
    one_KG_Reading?: number;
    two_KG_Reading?: number;
    employee: string;
    created: string | null;
    created_By: string;
    modified: string | null;
    modified_By: string;
};