import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetCarrierLookupsRequest, IGET_CARRIER_LOOKUPS_REQUEST, ICarrierLookup } from "./getCarrierLookupsConstaints";

export interface IGetCarrierLookupsLoadAction {
    type: IGET_CARRIER_LOOKUPS_REQUEST.REQUEST;
    data: IGetCarrierLookupsRequest
}
export const getCarrierLookupsLoadAction = (data: IGetCarrierLookupsRequest): IGetCarrierLookupsLoadAction => ({
    type: IGET_CARRIER_LOOKUPS_REQUEST.REQUEST,
    data
});

export interface IGetCarrierLookupsSuccessAction {
    type: IGET_CARRIER_LOOKUPS_REQUEST.SUCCESS;
    data: SilAPIResponse<ICarrierLookup[]>;
}
export const getCarrierLookupsLoadSuccessAction = (data: SilAPIResponse<ICarrierLookup[]>): IGetCarrierLookupsSuccessAction => ({
    type: IGET_CARRIER_LOOKUPS_REQUEST.SUCCESS,
    data
});

export interface IGetCarrierLookupsLoadFailedAction {
    type: IGET_CARRIER_LOOKUPS_REQUEST.FAILED;
    message: string;
}
export const getCarrierLookupsLoadFailedAction = (message: string): IGetCarrierLookupsLoadFailedAction => ({
    type: IGET_CARRIER_LOOKUPS_REQUEST.FAILED,
    message
});
