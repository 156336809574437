import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { getEmployeesStatus } from "../../../../redux/sil/dryPlant/getEmployees/getEmployeesAccessor";
import { getEmployeesLoadAction } from "../../../../redux/sil/dryPlant/getEmployees/getEmployeesActions";
import { IEmployees, IGetEmployeesRequest } from "../../../../redux/sil/dryPlant/getEmployees/getEmployeesConstaints";
import { hasPayload, isNotLoaded, isSucceeded, Server } from "../../../../redux/server";
import { ById, SilAPIResponse } from "../../../shared/publicInterfaces";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import { userName, undefinedFunction } from "../../../shared/constExports";
import { LAButton, LASaveAndCancelButton } from "../../../shared/buttons";
import { ArrowLeftIcon } from "../../../shared/icons";
import { WHITE_COLOR } from "../../../shared/theme";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import LATextField from "../../../shared/textField";
import LAAutoComplete from "../../../shared/autoComplete";
import { activeOptions, convertList } from "./dryPlantItemForm";
import { ROUTE } from "../../../routes";
import queryString from "query-string";
import { updateEmployeeLoadAction } from "../../../../redux/sil/dryPlant/updateEmployee/updateEmployeeActions";
import { updateEmployeeStatus } from "../../../../redux/sil/dryPlant/updateEmployee/updateEmployeeAccessor";
import { IUpdateEmployeeRequest } from "../../../../redux/sil/dryPlant/updateEmployee/updateEmployeeConstaints";
import { IAddEmployeeRequest } from "../../../../redux/sil/dryPlant/addEmployee/addEmployeeConstaints";
import { addEmployeeStatus } from "../../../../redux/sil/dryPlant/addEmployee/addEmployeeAccessor";
import { addEmployeeLoadAction } from "../../../../redux/sil/dryPlant/addEmployee/addEmployeeActions";
import { IGetProductionLookupsRequest, IProductionLookups } from "../../../../redux/sil/dryPlant/getProductionLookups/getProductionLookupsConstaints";
import { getProductionLookupsStatus } from "../../../../redux/sil/dryPlant/getProductionLookups/getProductionLookupsAccessor";
import { getProductionLookupsLoadAction } from "../../../../redux/sil/dryPlant/getProductionLookups/getProductionLookupsActions";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import { FieldValidator, IFieldErrorKeyValue } from "../../../shared/fieldValidator";
import PageSpacing from "../../../shared/pageSpacing";
import SilRoleCheck from "../../../shared/silRoleCheck";


interface IDryPlantEmployeeStoreProps {
    addEmployee: Server<string>;
    updateEmployee: Server<string>;
    getToken: Server<SilAPIResponse<IToken>>;
    getEmployees: Server<SilAPIResponse<IEmployees[]>>;
    getDropDownData: Server<SilAPIResponse<IProductionLookups>>;
};

interface IDryPlantEmployeeDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getEmployeesRequest: (data: IGetEmployeesRequest) => unknown;
    addEmployeeRequest: (data: IAddEmployeeRequest) => unknown;
    updateEmployeeRequest: (data: IUpdateEmployeeRequest) => unknown;
    getDropDownDataRequest: (data: IGetProductionLookupsRequest) => unknown;
};


interface IDryPlantEmployeeOwnProps {

};

interface IDryPlantEmployeeState {
    employee: IEmployees;
    errors: ById<IFieldErrorKeyValue>;
};

const DryPlantEmployeeStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
`;

type IDryPlantEmployeeProps = RouteComponentProps
    & IDryPlantEmployeeStoreProps
    & IDryPlantEmployeeDispatchProps
    & IDryPlantEmployeeOwnProps;

class DryPlantEmployee extends PureComponent<IDryPlantEmployeeProps, IDryPlantEmployeeState> {

    public constructor(props: IDryPlantEmployeeProps) {
        super(props);
        this.state = {
            errors: {
                "employee_Name": { key: "employee_Name", message: "Required" },
                "location": { key: "location", message: "Required" }
            },
            employee: {
                id: 0,
                active: "Yes",
                created: "",
                modified: "",
                location: "",
                ball_Mill: "",
                created_By: userName,
                modified_By: userName,
                employee_Name: ""
            }
        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: IDryPlantEmployeeProps): void {
        if (this.props !== prevProps) {
            this.callServer();

            if (this.props.addEmployee !== prevProps.addEmployee) {
                if (isSucceeded(this.props.addEmployee)) {
                    this.handleCancel();
                };
            };

            if (this.props.updateEmployee !== prevProps.updateEmployee) {
                if (isSucceeded(this.props.updateEmployee)) {
                    this.handleCancel();
                };
            };
        }
    };


    public render(): ReactNode {

        const { employee, errors } = this.state;
        const { getDropDownData, updateEmployee, addEmployee } = this.props;
        const locations = hasPayload(getDropDownData) ? convertList(getDropDownData.payload.response.locations ?? []) : [];

        return (
            <PageSpacing title="SIL - Employee" description="SIL - Employee" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="dryPlantAccess">
                    <DryPlantEmployeeStyles>

                        <LAButton startIcon={<ArrowLeftIcon color={WHITE_COLOR} />} label="Back to list" onClick={this.handleBack} />
                        <h2 className="text-center">{employee.id > 0 ? "VIEW/UPDATE " : "ADD "} EMPLOYEE</h2>
                        <hr />

                        <LAPaperWithPadding>
                            <LAGrid>

                                <LAGridItem xs={6}>
                                    <LATextField
                                        label="Name"
                                        fullWidth={true}
                                        name="employee_Name"
                                        onChange={this.onChange}
                                        value={employee.employee_Name}
                                        errorText={errors["employee_Name"] ? errors["employee_Name"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={6}>
                                    <LAAutoComplete
                                        filterSelectedOptions={true}
                                        autoHighlight={true}
                                        getOptionLabel="name"
                                        selectionRemove={undefinedFunction}
                                        dropDownPlaceHolder="Location"
                                        option={locations}
                                        name="location"
                                        multiple={false}
                                        onChange={this.handleDropDownChange}
                                        value={hasPayload(getDropDownData) ? locations.find(x => x.name === employee.location) : ""}
                                        defaultValue={hasPayload(getDropDownData) ? locations.find(x => x.name === employee.location) : ""}
                                        errorText={errors["location"] ? errors["location"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={6}>
                                    <LAAutoComplete
                                        filterSelectedOptions={true}
                                        autoHighlight={true}
                                        selectionRemove={undefinedFunction}
                                        dropDownPlaceHolder="Active"
                                        getOptionLabel="name"
                                        name="active"
                                        option={activeOptions}
                                        multiple={false}
                                        onChange={this.handleDropDownChange}
                                        value={activeOptions.find(x => x.name === employee.active)}
                                        defaultValue={activeOptions.find(x => x.name === employee.active)}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12}>
                                    <LASaveAndCancelButton
                                        onSave={this.onSave}
                                        onCancel={this.handleCancel}
                                    />
                                </LAGridItem>

                            </LAGrid>
                        </LAPaperWithPadding>

                        <RequestStatus requestStatus={addEmployee.kind} successMessage="Employee profile successfully saved" />
                        <RequestStatus requestStatus={updateEmployee.kind} successMessage="Employee profile successfully updated" />
                    </DryPlantEmployeeStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, isRequired: boolean): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: isRequired ? true : undefined, minLength: 1, decimal: undefined });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        };
        return err;
    };

    private handleCancel = (): void => {
        this.props.history.push(ROUTE.SIL.DRY_PLANT.DRY_PLANT_EMPLOYEES);
    };

    private onSave = (): void => {
        if (hasPayload(this.props.getToken))
            if (this.state.employee.id === 0) {
                this.props.addEmployeeRequest({
                    token: this.props.getToken.payload.response.token,
                    request: this.state.employee
                });
            } else {
                let data = this.state.employee;
                data.modified_By = userName;
                this.props.updateEmployeeRequest({
                    token: this.props.getToken.payload.response.token,
                    request: data
                });
            };
    };

    private handleDropDownChange = (event: unknown, value: { id: number, name: string } | "", name?: string): void => {
        let errors = this.state.errors;

        if (name === "location")
            errors = this.errorChecker(name, value !== "" ? value.name : "", errors, true);

        if (name)
            this.setState({
                ...this.state,
                errors,
                employee: {
                    ...this.state.employee,
                    [name]: value !== "" ? value.name : ""
                }
            });
    };

    private onChange = (name: string, value: string): void => {
        let errors = this.state.errors;
        errors = this.errorChecker(name, value, errors, true);

        this.setState({
            ...this.state,
            errors,
            employee: {
                ...this.state.employee,
                [name]: value
            }
        });
    };

    private handleBack = (): void => {
        this.props.history.push(ROUTE.SIL.DRY_PLANT.DRY_PLANT_EMPLOYEES);
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });


        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getEmployees))
            this.props.getEmployeesRequest({
                token: this.props.getToken.payload.response.token
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getDropDownData))
            this.props.getDropDownDataRequest({
                token: this.props.getToken.payload.response.token,
                requested_Page: "Dryplant"
            });

        const query = queryString.parse(this.props.location.search);
        const id = query.id ?? "0";

        if ((id !== "0") && (hasPayload(this.props.getEmployees)) && (this.state.employee.id === 0)) {
            const employee = this.props.getEmployees.payload.response.find(x => x.id === Number(id));

            if (employee)
                this.setState({ employee, errors: {} });
        };

    };

}

const mapStateToProps = (state: IStore): IDryPlantEmployeeStoreProps => ({
    getToken: getTokenStatus(state),
    getEmployees: getEmployeesStatus(state),
    addEmployee: addEmployeeStatus(state),
    updateEmployee: updateEmployeeStatus(state),
    getDropDownData: getProductionLookupsStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IDryPlantEmployeeDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getEmployeesRequest: (data: IGetEmployeesRequest) => dispatch(getEmployeesLoadAction(data)),
    addEmployeeRequest: (data: IUpdateEmployeeRequest) => dispatch(addEmployeeLoadAction(data)),
    updateEmployeeRequest: (data: IUpdateEmployeeRequest) => dispatch(updateEmployeeLoadAction(data)),
    getDropDownDataRequest: (data: IGetProductionLookupsRequest) => dispatch(getProductionLookupsLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DryPlantEmployee);