import { IPassTokenWithRequest } from "../../dryPlant/token/getTokenConstants";


export enum IGET_WASH_PLANT_PRODUCTION_SUMMARY_REQUEST {
    REQUEST = "getWashPlantProductionSummary/GET_WASH_PLANT_PRODUCTION_SUMMARY_REQUEST",
    SUCCESS = "getWashPlantProductionSummary/GET_WASH_PLANT_PRODUCTION_SUMMARY_SUCCESS",
    FAILED = "getWashPlantProductionSummary/GET_WASH_PLANT_PRODUCTION_SUMMARY_FAILED"
};

export interface IGetWashPlantProductionSummaryRequest extends IPassTokenWithRequest {
    request: {
        itemId: number;
    }
};

export interface IWashPlantProductionSummary {
    product: string;
    tonnage: number;
};