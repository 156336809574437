import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_SIMOS_TRANSPORT_COMPANIES_REQUEST, ISimosTransportCompanies } from "./getSimosTransportCompaniesConstants";
import { IGetSimosTransportCompaniesLoadAction, IGetSimosTransportCompaniesLoadFailedAction, IGetSimosTransportCompaniesSuccessAction, getSimosTransportCompaniesLoadFailedAction, getSimosTransportCompaniesLoadSuccessAction } from "./getSimosTransportCompaniesActions";
import { IStore } from "../../../../reducers";
import { EpicDependencies } from "../../../../store";
import { ById, SimosAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { END_POINTS } from "../../../../endpoints";
import { withJsonContentType } from "../../../../epicUtils";

export const getSimosTransportCompaniesEpic: Epic = (
    action$: ActionsObservable<IGetSimosTransportCompaniesLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetSimosTransportCompaniesSuccessAction | IGetSimosTransportCompaniesLoadFailedAction> =>
    action$.ofType(IGET_SIMOS_TRANSPORT_COMPANIES_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SimosAPIResponse<ISimosTransportCompanies[]>>(
                    END_POINTS.PROPSENSE.SIMOS.GET_SIMOS_TRANSPORT_COMPANIES,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SimosAPIResponse<ISimosTransportCompanies[]>): IGetSimosTransportCompaniesSuccessAction | IGetSimosTransportCompaniesLoadFailedAction => {
                            if(response.message === "Success"){
                                return getSimosTransportCompaniesLoadSuccessAction(response);
                            }
                            return getSimosTransportCompaniesLoadFailedAction(response.message);
                        }),
                        catchError((response: SimosAPIResponse<string>) => ActionsObservable.of(getSimosTransportCompaniesLoadFailedAction(response.message)))
                    )
            )
        );