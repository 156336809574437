import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IUpdateSieveFracBallLoadAction, IUpdateSieveFracBallLoadFailedAction, IUpdateSieveFracBallSuccessAction, updateSieveFracBallLoadFailedAction,
     updateSieveFracBallLoadSuccessAction } from "./updateSieveFracBallActions";
import { IUPDATE_SIEVE_FRAC_BALL_REQUEST } from "./updateSieveFracBallConstaints";
import { IStore } from "../../../../reducers";
import { EpicDependencies } from "../../../../store";
import { SilAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { END_POINTS } from "../../../../endpoints";
import { withJsonContentType } from "../../../../epicUtils";

export const updateSieveFracBallEpic: Epic = (
    action$: ActionsObservable<IUpdateSieveFracBallLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IUpdateSieveFracBallSuccessAction | IUpdateSieveFracBallLoadFailedAction> =>
    action$.ofType(IUPDATE_SIEVE_FRAC_BALL_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<string>>(
                    END_POINTS.PROPSENSE.SIL.CV.UpdateCVSieveFracBall,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<string>): IUpdateSieveFracBallSuccessAction | IUpdateSieveFracBallLoadFailedAction => {
                            if(response.message === "Success"){
                                return updateSieveFracBallLoadSuccessAction(action.data.request);
                            }
                            return updateSieveFracBallLoadFailedAction(response.message);
                        }),
                        catchError(() => ActionsObservable.of(updateSieveFracBallLoadFailedAction("Unable to save your changes")))
                    )
            )
        );