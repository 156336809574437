import { IPassTokenWithRequest } from "../../dryPlant/token/getTokenConstants";


export enum IGET_WASH_PLANT_FEED_MATERIALS_REQUEST {
    REQUEST = "getWashPlantFeedMaterials/GET_WASH_PLANT_FEED_MATERIALS_REQUEST",
    SUCCESS = "getWashPlantFeedMaterials/GET_WASH_PLANT_FEED_MATERIALS_SUCCESS",
    FAILED = "getWashPlantFeedMaterials/GET_WASH_PLANT_FEED_MATERIALS_FAILED"
};

export interface IGetWashPlantFeedMaterialsRequest extends IPassTokenWithRequest {

};

export interface IWashPlantFeedMaterials {
    id: number;
    feed_Material: string;
    product_Code: string;
    active: string;
    ball_Mill: string;
    created: string;
    modified: string;
    created_By: string;
    modified_By: string;
};