import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IGetProductionSummaryLoadAction, IGetProductionSummaryLoadFailedAction, IGetProductionSummarySuccessAction } from "./getProductionSummaryActions";
import { IGET_PRODUCTION_SUMMARY_REQUEST, IProductionSummary } from "./getProductionSummaryConstants";

type Actions =
    IGetProductionSummaryLoadAction
    | IGetProductionSummaryLoadFailedAction
    | IGetProductionSummarySuccessAction
    | IFlushDataSuccessAction;

export const GetProductionSummaryReducer = (state: Server<SilAPIResponse<IProductionSummary[]>> = notLoaded, action: Actions): Server<SilAPIResponse<IProductionSummary[]>> => {
    switch (action.type) {
        case IGET_PRODUCTION_SUMMARY_REQUEST.REQUEST:
            return loading;

        case IGET_PRODUCTION_SUMMARY_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_PRODUCTION_SUMMARY_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};