import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, Server } from "../../../../redux/server";
import { ByIdNumber, SilAPIResponse } from "../../../shared/publicInterfaces";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import { callRouteWithQueryString, userName } from "../../../shared/constExports";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { LAButton } from "../../../shared/buttons";
import { AddIcon } from "../../../shared/icons";
import { WHITE_COLOR } from "../../../shared/theme";
import { ROUTE } from "../../../routes";
import PageSpacing from "../../../shared/pageSpacing";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { ICarrier, IGetCarriersRequest } from "../../../../redux/sil/carrier/getCarriers/getCarriersConstaints";
import { getCarriersLoadAction } from "../../../../redux/sil/carrier/getCarriers/getCarriersActions";
import { getCarriersStatus } from "../../../../redux/sil/carrier/getCarriers/getCarriersAccessor";
import SilRoleCheck from "../../../shared/silRoleCheck";


interface ICarriersStoreProps {
    getToken: Server<SilAPIResponse<IToken>>;
    getCarriers: Server<SilAPIResponse<ByIdNumber<ICarrier>>>;
};

interface ICarriersDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getCarriersRequest: (data: IGetCarriersRequest) => unknown;
};


interface ICarriersOwnProps {

};

interface ICarriersState {
    data: ICarrier[];
    allData: ICarrier[];
    radioValue: string;
};

const CarriersStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
    };
`;

type ICarriersProps = RouteComponentProps
    & ICarriersStoreProps
    & ICarriersDispatchProps
    & ICarriersOwnProps;

class Carriers extends PureComponent<ICarriersProps, ICarriersState> {

    public constructor(props: ICarriersProps) {
        super(props);
        this.state = {
            data: [],
            allData: [],
            radioValue: "Active"
        };
    }

    public componentDidMount(): void {
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: ICarriersProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();
        };
    };


    public render(): ReactNode {

        const { radioValue, data } = this.state;

        return (
            <PageSpacing title="SIL - Carriers" description="SIL - Carriers" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="carrierAccess">
                    <CarriersStyles>

                        <LAButton className="pull-left" startIcon={<AddIcon color={WHITE_COLOR} />} label="Add" onClick={this.handleAdd} />
                        <h2 className="text-center">CARRIERS</h2>
                        <RadioGroup row aria-label="" name="radioGroup" value={radioValue} onChange={this.handleRadioClick}>
                            <FormControlLabel value="Active" control={<Radio />} label="Active" />
                            <FormControlLabel value="All" control={<Radio />} label="All" />
                        </RadioGroup>
                        <hr />

                        <LADevExtremeGrid
                            data={data}
                            onEdit={this.onEdit}
                            onClick={this.onClick}
                            height={1000}
                            searchPanel={true}
                            filterHeader={true}
                            export={true}
                            storageKey="silCarriersStorageKey"
                            exportFileName={"Carriers"}
                            columns={[
                                { name: "name", caption: "Name", type: "string", sortAsc: true },
                                { name: "active", caption: "Active", type: "string" },
                                { name: "uses_Sil_Trailers", caption: "Uses Sil Trailers", type: "string" },
                                { name: "approved", caption: "Approved", type: "string" },
                                { name: "dispatch_Contact_Name", caption: "Dispatch Contact Name", type: "string" },
                                { name: "dispatch_Phone_Work", caption: "Dispatch Phone", type: "string" },
                                { name: "dispatch_Email", caption: "Dispatch Email", type: "string" },                                
                                { name: "prorated_BC", caption: "Prorated BC", type: "string" },
                                { name: "prorated_SK", caption: "Prorated SK", type: "string" },                                
                                { name: "truck_Code_IN", caption: "Truck Code IN", type: "string" },
                                { name: "truck_Code_Out", caption: "Truck Code Out", type: "string" },
                                { name: "modified", caption: "Modified", width: 100, type: "datetime" },                                
                            ]}
                        />
                    </CarriersStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private handleRadioClick = (event: React.ChangeEvent<HTMLInputElement>, radioValue: string): void => {
        if (radioValue === "Active") {
            const val = this.state.allData;
            const data = val.filter(x => x.active === "Yes");
            this.setState({ data, radioValue });
        } else {
            this.setState({ radioValue, data: this.state.allData });
        };
    };

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString() },
            pathName: ROUTE.SIL.CARRIERS.CARRIER
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0" },
            pathName: ROUTE.SIL.CARRIERS.CARRIER
        });
    };

    private setDataToState = (): void => {

        if (hasPayload(this.props.getCarriers)) {
            const val: ICarrier[] = Object.values(this.props.getCarriers.payload.response);
            const list = val.filter(x => x.active === "Yes");
            this.setState({ data: list, allData: val });
        };

        if (isNotLoaded(this.props.getCarriers))
            this.callServer();
    };

    private callServer = (): void => {
        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getCarriers))
            this.props.getCarriersRequest({
                token: this.props.getToken.payload.response.token
            });


        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });
    }

}

const mapStateToProps = (state: IStore): ICarriersStoreProps => ({
    getToken: getTokenStatus(state),
    getCarriers: getCarriersStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): ICarriersDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getCarriersRequest: (data: IGetCarriersRequest) => dispatch(getCarriersLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Carriers);