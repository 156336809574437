import React from "react";
import Chip from "@material-ui/core/Chip/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { HasClass } from "./publicInterfaces";
import { WarningIcon } from "./icons";
import { ORANGE_COLOR } from "./theme";


interface ILAAutoCompleteDropDown extends HasClass {
    option: any | undefined;
    getOptionLabel?: any;
    getOptionLabel2?: any;
    id?: id;
    defaultValue: any | [];
    disabled?: boolean;
    multiple: boolean;
    value: any;
    filterSelectedOptions: boolean;
    autoHighlight: boolean;
    onChange: (event: unknown, values: any, name?: string, getLableName?: string) => void;
    onOpen?: (event: any) => void;
    selectionRemove: (values: any, index: any) => void;
    dropDownPlaceHolder?: string;
    onClose?: () => void;
    loading?: boolean;
    errorText?: string;
    name?: string;
    freeText?: true;
    hideIcon?: boolean;
    keyPress?: (event: React.KeyboardEvent<HTMLDivElement> | undefined) => void;
    onInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

type id = "disableOpenOnFocus" | "autoHighlight" | "autoComplete" | "includeInputInList" | "disableClearable" | "clearOnEscape" | "select-on-focus";
const DEFAULT_ID = "disableOpenOnFocus";


const LAAutoComplete: React.FC<ILAAutoCompleteDropDown> = (props: ILAAutoCompleteDropDown) => {
    const helperText: string | undefined = props.errorText;
    const error: true | undefined = helperText ? true : undefined;
    const onChange = (event: unknown, value: any, name?: string): void => props.onChange(event, value !== null ? value : "", props.name, props.getOptionLabel);

    return (
        <Autocomplete
            onKeyPress={props.keyPress}
            freeSolo={props.freeText ?? false}
            className={props.className}
            filterSelectedOptions={props.filterSelectedOptions}
            options={props.option ? Object.values(props.option) : []}
            getOptionLabel={(option: any): string => getLabel(option, props.getOptionLabel, props.getOptionLabel2)}
            id={props.id || DEFAULT_ID}
            disabled={props.disabled || false}
            onChange={onChange}
            defaultValue={props.defaultValue}
            multiple={props.multiple}
            value={props.value}
            autoHighlight={props.autoHighlight}
            loading={props.loading}
            onOpen={props.onOpen}
            onClose={props.onClose}
            forcePopupIcon={props.hideIcon ? false : true}
            renderTags={(value: any): JSX.Element[] =>
                value.map((option: any, index: number): JSX.Element =>
                    <Chip
                        className="mb-2 ml-2 mr-2"
                        variant="outlined"
                        color="secondary"
                        key={index}
                        clickable={true}
                        disabled={props.disabled || false}
                        label={getLabel(option, props.getOptionLabel, props.getOptionLabel2)}
                        onDelete={(): void => props.selectionRemove(option[props.getOptionLabel], index)}
                    />
                )
            }
            renderInput={(params): JSX.Element => {
                const inputProps: any = params.inputProps;
                inputProps.autoComplete = "off";
                return <TextField {...params}
                // style={{overflow: "hidden", wordBreak: "break-all"}}
                    rowsMax={2}
                    inputProps={inputProps}
                    multiline={true}
                    variant="outlined"
                    color="secondary"
                    label={props.dropDownPlaceHolder ?? "Search"}
                    error={error}
                    helperText={helperText}
                    name={props.name}
                    fullWidth={true}
                    onChange={props.onInputChange}
                    required={error}
                />;
            }}
            renderOption={(option) => {
                return (
                    <React.Fragment>
                       {option.error === true && <span className="mr-2 mt-1"><WarningIcon color={ORANGE_COLOR} /></span>}
                        {getLabel(option, props.getOptionLabel, props.getOptionLabel2)}
                    </React.Fragment>
                );
            }}
        />
    );
};

export default LAAutoComplete;


const getLabel = (options: any, label1: string, label2?: string): string => {
    if(label2 === undefined){
        return options[label1];
    } else {
        if((options[label1]) && (options[label2] !== undefined)){
            return `${options[label1]} - ${options[label2]}`;
        } else {
            return "";
        }
    
    }
};