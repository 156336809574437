import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IGET_SIEVE_LOOKUPS_REQUEST, ISieveLookup } from "./getSieveLookupsConstaints";
import { IGetSieveLookupsLoadAction, IGetSieveLookupsLoadFailedAction, IGetSieveLookupsSuccessAction } from "./getSieveLookupsActions";

type Actions =
    IGetSieveLookupsLoadAction
    | IGetSieveLookupsLoadFailedAction
    | IGetSieveLookupsSuccessAction
    | IFlushDataSuccessAction;

export const GetSieveLookupsReducer = (state: Server<SilAPIResponse<ISieveLookup>> = notLoaded, action: Actions): Server<SilAPIResponse<ISieveLookup>> => {
    switch (action.type) {
        case IGET_SIEVE_LOOKUPS_REQUEST.REQUEST:
            return loading;

        case IGET_SIEVE_LOOKUPS_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_SIEVE_LOOKUPS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};