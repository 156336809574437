import React, {Component} from 'react';
import '../../../../shared/simos/styling/truckinfostyles.css';
import {FaPencilAlt} from 'react-icons/fa';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';

interface IProps {

}

interface IState {
    comments: string;
    packaged: boolean;
    completed: boolean;
    cancelled: string;
    truck_num: number;
    trailer_num: number;
    tare: string;
    weight: string;
}

var allTruckInfo = {comments: '', packaged: false, completed: false, cancelled: '', truck_num:0, trailer_num: 0, tare: '', weight:''}
class TruckInfo extends Component <IProps, IState>{
    constructor(props: IProps) {
        super(props);
        this.state = {
            comments: '', packaged: false, completed: false, cancelled: '', truck_num:0, trailer_num: 0, tare: '', weight:''
        }
        this.onTextChange = this.onTextChange.bind(this);
    }

    private onTextChange (event: any){
        const fieldname = event.target.name;
        switch(fieldname) {
            case 'comment':
                this.setState({comments: event.target.value});
                break;
            case 'packaged_check':
                this.setState(initialState => ({packaged: !initialState.packaged}));
                break;
            case 'completed_check':
                this.setState(initialState => ({completed: !initialState.completed}));
                break;
            case 'cancel_radio':
                this.setState({cancelled: event.target.value});
                break;
            case 'truck_no':
                this.setState({truck_num: event.target.value});
                break;
            case 'trailer_no':
                this.setState({trailer_num: event.target.value});
                break;
            case 'tare_weight':
                this.setState({tare: event.target.value});
                break;
            case 'gross_weight':
                this.setState({weight: event.target.value});
                break;
        }
    }

    public render() {
        allTruckInfo = {comments: this.state.comments, packaged: this.state.packaged, completed: this.state.completed, cancelled: this.state.cancelled, truck_num: this.state.truck_num, 
        trailer_num: this.state.trailer_num, tare: this.state.tare, weight: this.state.weight};
        return (
             <Grid container spacing={3}>
                   
              <Grid item xs={12}>
              <TextField fullWidth autoComplete='nope' label="Comments" name="comment" multiline rows={4} onChange={this.onTextChange} variant="outlined"></TextField>
              </Grid>
            
                {/* <Grid item xs={3}>
                    <TextField label="Truck #" name="truck_no" onChange={this.onTextChange}></TextField>
                </Grid>
                <Grid item xs={3} >
                    <TextField label="Trailer #" name="trailer_no" onChange={this.onTextChange}></TextField>
                </Grid>
                <Grid item xs={3}>
                    <TextField label="Tare Weight" name="tare_weight" onChange={this.onTextChange}></TextField>
                </Grid>
                <Grid item xs={3}>
                    <TextField label="Gross Weight" name="gross_weight" onChange={this.onTextChange}></TextField>
                </Grid> */}
               
            </Grid>
            
        );
    }

}
export default TruckInfo;
export {allTruckInfo};