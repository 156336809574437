import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IGetSIMOSOrderProductVersionHistoryLoadAction, IGetSIMOSOrderProductVersionHistoryLoadFailedAction, IGetSIMOSOrderProductVersionHistorySuccessAction, 
    getSIMOSOrderProductVersionHistoryLoadFailedAction, getSIMOSOrderProductVersionHistoryLoadSuccessAction
} from "./getSIMOSOrderProductVersionHistoryActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IGET_SIMOS_ORDER_PRODUCT_VERSION_HISTORY_REQUEST, ISIMOSOrderProductVersionHistory } from "./getSIMOSOrderProductVersionHistoryConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SimosAPIResponse } from "../../../../react/shared/publicInterfaces";

export const getSIMOSOrderProductVersionHistoryEpic: Epic = (
    action$: ActionsObservable<IGetSIMOSOrderProductVersionHistoryLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IGetSIMOSOrderProductVersionHistorySuccessAction | IGetSIMOSOrderProductVersionHistoryLoadFailedAction> =>
    action$.ofType(IGET_SIMOS_ORDER_PRODUCT_VERSION_HISTORY_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SimosAPIResponse<ISIMOSOrderProductVersionHistory[]>>(
                    END_POINTS.PROPSENSE.SIMOS.GET_SIMOS_PRODUCT_ORDER_VERSION_HISTORY,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SimosAPIResponse<ISIMOSOrderProductVersionHistory[]>): IGetSIMOSOrderProductVersionHistorySuccessAction | IGetSIMOSOrderProductVersionHistoryLoadFailedAction => {
                            if(response.message !== "Success")
                                return getSIMOSOrderProductVersionHistoryLoadFailedAction(response.message);
                            return getSIMOSOrderProductVersionHistoryLoadSuccessAction(response);
                        }),
                        catchError(() => ActionsObservable.of(getSIMOSOrderProductVersionHistoryLoadFailedAction("Unable to get simos order product version history")))
                    )
            )
        );