import { IUpdateChemicalAnalysesRequest, IUPDATE_CHEMICAL_ANALYSES_REQUEST } from "./updateChemicalAnalysesConstaints";

export interface IUpdateChemicalAnalysesLoadAction {
    type: IUPDATE_CHEMICAL_ANALYSES_REQUEST.REQUEST;
    data: IUpdateChemicalAnalysesRequest
}
export const updateChemicalAnalysesLoadAction = (data: IUpdateChemicalAnalysesRequest): IUpdateChemicalAnalysesLoadAction => ({
    type: IUPDATE_CHEMICAL_ANALYSES_REQUEST.REQUEST,
    data
});

export interface IUpdateChemicalAnalysesSuccessAction {
    type: IUPDATE_CHEMICAL_ANALYSES_REQUEST.SUCCESS;
    message: string;
}
export const updateChemicalAnalysesLoadSuccessAction = (message: string): IUpdateChemicalAnalysesSuccessAction => ({
    type: IUPDATE_CHEMICAL_ANALYSES_REQUEST.SUCCESS,
    message
});

export interface IUpdateChemicalAnalysesLoadFailedAction {
    type: IUPDATE_CHEMICAL_ANALYSES_REQUEST.FAILED;
    message: string;
}
export const updateChemicalAnalysesLoadFailedAction = (message: string): IUpdateChemicalAnalysesLoadFailedAction => ({
    type: IUPDATE_CHEMICAL_ANALYSES_REQUEST.FAILED,
    message
});
