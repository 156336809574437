import { getTrailerActivityLogsStatus } from '../../../../redux/sil/dispatch/getTrailerActivityLogs/getTrailerActivityLogsAccessor';
import { addTrailerActivityLogStatus } from '../../../../redux/sil/dispatch/addTrailerActivityLog/addTrailerActivityLogAccessor';
import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithLessPadding, LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, isSucceeded, Server } from "../../../../redux/server";
import { ById, ByIdNumber, SilAPIResponse } from "../../../shared/publicInterfaces";
import { undefinedFunction, userName } from "../../../shared/constExports";
import { ROUTE } from "../../../routes";
import queryString from "query-string";
import { FieldValidator, FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../../shared/fieldValidator";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import { ITrailerActivityLog, IGetTrailerActivityLogsRequest, ITrailerActivityLogServices } from '../../../../redux/sil/dispatch/getTrailerActivityLogs/getTrailerActivityLogsConstaints';
import { IAddTrailerActivityLogRequest } from '../../../../redux/sil/dispatch/addTrailerActivityLog/addTrailerActivityLogConstaints';
import { IUpdateTrailerActivityLogRequest } from '../../../../redux/sil/dispatch/updateTrailerActivityLog/updateTrailerActivityLogConstaints';
import { getTrailerActivityLogsLoadAction } from '../../../../redux/sil/dispatch/getTrailerActivityLogs/getTrailerActivityLogsActions';
import { addTrailerActivityLogLoadAction } from '../../../../redux/sil/dispatch/addTrailerActivityLog/addTrailerActivityLogActions';
import { updateTrailerActivityLogLoadAction } from '../../../../redux/sil/dispatch/updateTrailerActivityLog/updateTrailerActivityLogActions';
import PageSpacing from '../../../shared/pageSpacing';
import SilRoleCheck from '../../../shared/silRoleCheck';
import { LAButton, LAIconButton, LASaveAndCancelButton } from '../../../shared/buttons';
import LAGrid from '../../../shared/grid';
import LAGridItem from '../../../shared/gridList';
import { ArrowLeftIcon, DeleteIcon } from '../../../shared/icons';
import RequestStatus from '../../../shared/requestStatusSnackbar';
import LATextField from '../../../shared/textField';
import { BLUE_GREEN_COLOR, WHITE_COLOR } from '../../../shared/theme';
import { LADateTimeMUIPicker } from '../../../shared/dateTimePickerMUI';
import LATextArea from '../../../shared/textArea';
import { updateTrailerActivityLogStatus } from '../../../../redux/sil/dispatch/updateTrailerActivityLog/updateTrailerActivityLogAccessor';
import LAAutoComplete from '../../../shared/autoComplete';
import { IIdName } from '../../../../utils/sharedTypes';
import { LAExpansionPanel } from '../../../shared/expansionPanel';
import { LADevExtremeGrid } from '../../../shared/devExtreme';
import React from 'react';
import { ICarrier, IGetCarriersRequest } from '../../../../redux/sil/carrier/getCarriers/getCarriersConstaints';
import { getCarriersStatus } from '../../../../redux/sil/carrier/getCarriers/getCarriersAccessor';
import { getCarriersLoadAction } from '../../../../redux/sil/carrier/getCarriers/getCarriersActions';
import { LAPopover } from '../../../shared/popOver';
import LALinkButton from '../../../shared/linkButton';
import { LADropDone } from '../../../shared/dragDropFiles';
import { END_POINTS } from '../../../../redux/endpoints';

const statusList: IIdName<number>[] = [
    { id: 1, name: "In Service" },
    { id: 2, name: "Out of Service" },
    { id: 3, name: "Available" },
    { id: 4, name: "Being repaired" },
    { id: 5, name: "Spare" },
    { id: 6, name: "Other" },
];

interface ITrailerActivityLogStoreProps {
    addTrailerActivityLog: Server<string>;
    updateTrailerActivityLog: Server<string>;
    getToken: Server<SilAPIResponse<IToken>>;
    getCarriers: Server<SilAPIResponse<ByIdNumber<ICarrier>>>;
    getTrailerActivityLogs: Server<SilAPIResponse<ITrailerActivityLog[]>>;
};

interface ITrailerActivityLogDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getCarriersRequest: (data: IGetCarriersRequest) => unknown;
    addTrailerActivityLogRequest: (data: IAddTrailerActivityLogRequest) => unknown;
    getTrailerActivityLogsRequest: (data: IGetTrailerActivityLogsRequest) => unknown;
    updateTrailerActivityLogRequest: (data: IUpdateTrailerActivityLogRequest) => unknown;
};


interface ITrailerActivityLogOwnProps {

};

interface ITrailerActivityLogState {
    unSavedService: boolean;
    unSavedReplacement: boolean;
    errors: ById<IFieldErrorKeyValue>;
    popupErrors: ById<IFieldErrorKeyValue>;
    trailerActivityLog: ITrailerActivityLog;
    servicePopup: ITrailerActivityLogServices | undefined;
};

const TrailerActivityLogStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
`;

type ITrailerActivityLogProps = RouteComponentProps
    & ITrailerActivityLogStoreProps
    & ITrailerActivityLogDispatchProps
    & ITrailerActivityLogOwnProps;

class TrailerActivityLog extends PureComponent<ITrailerActivityLogProps, ITrailerActivityLogState> {
    fileInput: any;

    public constructor(props: ITrailerActivityLogProps) {
        super(props);
        this.state = {
            errors: {
                "trailer": { key: "trailer", message: FIELD_VALIDATOR_ERRORS.REQUIRED }
            },
            trailerActivityLog: {
                id: 0,
                trailer: "",
                carrier: "",
                status: statusList[0].name,
                in_Service: "",
                out_Of_Service: "",
                comments: "",
                services: [],
                replacements: [],
                created: "",
                created_By: userName,
                modified: "",
                modified_By: userName
            },
            popupErrors: {},
            unSavedService: false,
            servicePopup: undefined,
            unSavedReplacement: false
        };
        this.fileInput = React.createRef();
    }



    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: ITrailerActivityLogProps): void {
        if (this.props !== prevProps) {
            this.callServer();

            if (this.props.addTrailerActivityLog !== prevProps.addTrailerActivityLog) {
                if (isSucceeded(this.props.addTrailerActivityLog)) {
                    this.handleCancel();
                };
            };

            if (this.props.updateTrailerActivityLog !== prevProps.updateTrailerActivityLog) {
                if (isSucceeded(this.props.updateTrailerActivityLog)) {
                    this.handleCancel();
                };
            };
        };
    };


    public render(): ReactNode {

        const { updateTrailerActivityLog, addTrailerActivityLog, getCarriers, getToken } = this.props;
        const { trailerActivityLog, errors, servicePopup, popupErrors } = this.state;
        const isAdd = trailerActivityLog.id > 0 ? false : true;

        const onTireDevExtremeEdit = (): void => this.handleTireEditMode();
        const token = hasPayload(getToken) ? getToken.payload.response.token : "";
        const handleDropDownChange = (event: unknown, value: any, name?: string): void => this.onChange(name ?? "", value !== null ? value.name : "");

        const carrierRes: ICarrier[] = hasPayload(getCarriers) ? Object.values(getCarriers.payload.response) : [];
        const carriers = carrierRes.filter((x) => (x.active && x.active === "Yes") && (x.uses_Sil_Trailers && x.uses_Sil_Trailers === "Yes"));

        return (
            <PageSpacing title="SIL - DISPATCH" description="Trailer Activity Log" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="carrierAccess">
                    <TrailerActivityLogStyles>

                        <LAButton startIcon={<ArrowLeftIcon color={WHITE_COLOR} />} label="Back to list" onClick={this.handleCancel} />
                        <h2 className="text-center">{!isAdd ? "VIEW " : "ADD "} TRAILER ACTIVITY LOG</h2>
                        <hr />

                        <LAPaperWithPadding>
                            <LAGrid spacing={3}>

                                <LAGridItem xs={12}>
                                    <LAExpansionPanel color={BLUE_GREEN_COLOR} textColor={WHITE_COLOR} label="General" expanded={true} onClick={undefinedFunction}>

                                        <LAGrid spacing={0}>

                                            <LAGridItem xs={4}>
                                                <LATextField
                                                    varient="outlined"
                                                    label="Trailer #"
                                                    fullWidth={true}
                                                    name="trailer"
                                                    onChange={this.onChange}
                                                    value={trailerActivityLog.trailer ?? ""}
                                                    errorText={errors["trailer"] ? errors["trailer"].message : undefined}
                                                />
                                            </LAGridItem>

                                            <LAGridItem xs={4}>
                                                <LAAutoComplete
                                                    name="carrier"
                                                    multiple={false}
                                                    autoHighlight={true}
                                                    getOptionLabel="name"
                                                    dropDownPlaceHolder="Carrier"
                                                    option={carriers}
                                                    filterSelectedOptions={true}
                                                    selectionRemove={undefinedFunction}
                                                    onChange={handleDropDownChange}
                                                    value={carriers.find(x => x.name === trailerActivityLog.carrier)}
                                                    defaultValue={carriers.find(x => x.name === trailerActivityLog.carrier)}
                                                />
                                            </LAGridItem>

                                            <LAGridItem xs={4}>
                                                <LAAutoComplete
                                                    name="status"
                                                    multiple={false}
                                                    autoHighlight={true}
                                                    getOptionLabel="name"
                                                    dropDownPlaceHolder="Status"
                                                    option={statusList}
                                                    filterSelectedOptions={true}
                                                    selectionRemove={undefinedFunction}
                                                    onChange={handleDropDownChange}
                                                    value={statusList.find(x => x.name === trailerActivityLog.status)}
                                                    defaultValue={statusList.find(x => x.name === trailerActivityLog.status)}
                                                />
                                            </LAGridItem>

                                            <LAGridItem xs={4}>
                                                <LADateTimeMUIPicker
                                                    fullWidth={true}
                                                    name="in_Service"
                                                    label="In Service"
                                                    dateOnly={true}
                                                    onChange={this.onDateChange}
                                                    value={trailerActivityLog.in_Service ?? ""}
                                                    errorText={errors["in_Service"] ? errors["in_Service"].message : undefined}
                                                />
                                            </LAGridItem>

                                            <LAGridItem xs={4}>
                                                <LADateTimeMUIPicker
                                                    dateOnly={true}
                                                    fullWidth={true}
                                                    name="out_Of_Service"
                                                    label="Out Of Service"
                                                    onChange={this.onDateChange}
                                                    value={trailerActivityLog.out_Of_Service ?? ""}
                                                    errorText={errors["out_Of_Service"] ? errors["out_Of_Service"].message : undefined}
                                                />
                                            </LAGridItem>

                                            <LAGridItem xs={6}>
                                                <LATextArea
                                                    minRows={4}
                                                    rowsMax={10}
                                                    varient="outlined"
                                                    label="Comments"
                                                    fullWidth={true}
                                                    name="comments"
                                                    onChange={this.onChange}
                                                    value={trailerActivityLog.comments ?? ""}
                                                    errorText={errors["comments"] ? errors["comments"].message : undefined}
                                                />
                                            </LAGridItem>

                                        </LAGrid>
                                    </LAExpansionPanel>

                                </LAGridItem>

                                <LAGridItem xs={12}>
                                    <LAExpansionPanel color={BLUE_GREEN_COLOR} textColor={WHITE_COLOR} label="Service" expanded={true} onClick={undefinedFunction}>
                                        <LAGrid>

                                            <LAGridItem xs={12}>
                                                <LAButton
                                                    label="Add New"
                                                    onClick={this.handleServiceAdd}
                                                />
                                            </LAGridItem>

                                            <LAGridItem xs={12}>
                                                <LADevExtremeGrid
                                                    height={500}
                                                    id="service"
                                                    searchPanel={false}
                                                    filterHeader={false}
                                                    moveBtnToLeft={true}
                                                    onClick={this.onServiceClick}
                                                    onEdit={this.onServiceEditClick}
                                                    data={trailerActivityLog.services}
                                                    columns={[
                                                        { name: "service_TypeInfo", caption: "Service Type", type: "string" },
                                                        { name: "km_At_Service", caption: "Km at Service", type: "string" },
                                                        { name: "service_Date", caption: "Service Date", type: "date" },
                                                        { name: "work_Order_Number", caption: "Work Order #", type: "string" },
                                                        { name: "comments", caption: "Comments", type: "string", width: 350 },
                                                        { name: "mechanics", caption: "Mechanics", type: "string" }
                                                    ]}
                                                />
                                            </LAGridItem>

                                        </LAGrid>
                                    </LAExpansionPanel>
                                </LAGridItem>

                                <LAGridItem xs={12}>
                                    <LAExpansionPanel color={BLUE_GREEN_COLOR} textColor={WHITE_COLOR} label="Tire Replacement" expanded={true} onClick={undefinedFunction}>
                                        <LADevExtremeGrid
                                            add={true}
                                            height={500}
                                            id="tireReplacement"
                                            searchPanel={false}
                                            filterHeader={false}
                                            moveBtnToLeft={true}
                                            onDelete={onTireDevExtremeEdit}
                                            onClick={undefinedFunction}
                                            onEditSave={onTireDevExtremeEdit}
                                            onEditStart={onTireDevExtremeEdit}
                                            onEditCancel={onTireDevExtremeEdit}
                                            onNewRowInsert={onTireDevExtremeEdit}
                                            onCustomEdit={this.onReplacementEdit}
                                            data={trailerActivityLog.replacements}
                                            columns={[
                                                { name: "km_At_Replacement", caption: "Service Type", type: "string" },
                                                { name: "replacement_Date", caption: "Service Date", type: "date" },
                                                { name: "work_Order_Number", caption: "Work Order #", type: "string" },
                                                { name: "condition", caption: "Condition", type: "string" },
                                                { name: "brand", caption: "Brand", type: "string" },
                                                { name: "FLi", caption: "FLi", type: "boolean" },
                                                { name: "FLO", caption: "FLO", type: "boolean" },
                                                { name: "FRI", caption: "FRI", type: "boolean" },
                                                { name: "FRO", caption: "FRO", type: "boolean" },
                                                { name: "MLi", caption: "MLi", type: "boolean" },
                                                { name: "MLO", caption: "MLO", type: "boolean" },
                                                { name: "MRI", caption: "MRI", type: "boolean" },
                                                { name: "MRO", caption: "MRO", type: "boolean" },
                                                { name: "RLi", caption: "RLi", type: "boolean" },
                                                { name: "RLO", caption: "RLO", type: "boolean" },
                                                { name: "RRi", caption: "RRi", type: "boolean" },
                                                { name: "RRO", caption: "RRO", type: "boolean" },
                                                { name: "comments", caption: "Comments", type: "string", width: 160 },
                                            ]}
                                        />
                                    </LAExpansionPanel>
                                </LAGridItem>

                                <LAGridItem xs={12}>
                                    <hr />
                                    <LASaveAndCancelButton
                                        onSave={this.onSave}
                                        onCancel={this.handleCancel}
                                        disableSave={(Object.values(errors).length > 0 ? true : undefined)}
                                    />
                                </LAGridItem>

                            </LAGrid>
                        </LAPaperWithPadding>

                        {servicePopup && <ServicePopup
                            token={token}
                            errors={popupErrors}
                            onSave={this.popupSave}
                            onClose={this.popupClose}
                            handleAtt={this.handleAtt}
                            servicePopup={servicePopup}
                            onChange={this.handlePopupChange}
                            handleDeleteAtt={this.handleDeleteAtt}
                            onDateChange={this.handleDatePopupChange}
                        />}

                        <RequestStatus requestStatus={addTrailerActivityLog.kind} successMessage="Trailer Activity Log successfully saved" />
                        <RequestStatus requestStatus={updateTrailerActivityLog.kind} successMessage="Trailer Activity Log successfully updated" />
                    </TrailerActivityLogStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private handleDeleteAtt = (): void => {
        if (this.state.servicePopup)
            this.setState({
                ...this.state,
                servicePopup: {
                    ...this.state.servicePopup,
                    attachment: "",
                    attachmentContent: []
                }
            });
    };

    private handleAtt = (files: Array<File | DataTransferItem>): void => {
        new Promise((resolve, reject): any => {
            if (files && (files !== null)) {

                Object.values(files).forEach((x: any, index, array) => {
                    const reader = new FileReader();

                    reader.onloadend = async (e): Promise<void> => {
                        if (reader.result !== null && this.state.servicePopup) {
                            this.setState({
                                ...this.state,
                                servicePopup: {
                                    ...this.state.servicePopup,
                                    attachment: x.name,
                                    attachmentContent: [{
                                        Name: x.name,
                                        Base64String: reader.result.toString()
                                    }]
                                }
                            });
                            resolve(true);
                        };
                    };
                    reader.readAsDataURL(x);
                })
            } else {
                reject();
            }
        });
    };

    private handleServiceAdd = (): void => {
        this.setState({
            ...this.state,
            servicePopup: {
                id: 0,
                service_TypeInfo: "",
                km_At_Service: "",
                service_Date: "",
                work_Order_Number: "",
                comments: "",
                mechanics: "",
                attachment: "",
                created: "",
                created_By: userName,
                modified: "",
                modified_By: userName,
                attachmentContent: []
            }
        });
    };

    private popupSave = async (): Promise<void> => {
        const { servicePopup, trailerActivityLog } = this.state;

        if (servicePopup && trailerActivityLog) {
            const data = [...trailerActivityLog.services];
            const idx = data.findIndex((x) => x.id === servicePopup.id);

            if (idx >= 0) {
                data[idx] = { ...servicePopup };
            } else {
                data.push(servicePopup);
            }

            await this.setState({
                trailerActivityLog: {
                    ...trailerActivityLog,
                    services: data
                }
            });
            this.popupClose();
        };
    };

    private handlePopupChange = (name: string, value: string): void => {
        let popupErrors = this.state.popupErrors;
        popupErrors = this.errorChecker(name, value, popupErrors, true);

        if (this.state.servicePopup)
            this.setState({
                ...this.state,
                popupErrors,
                servicePopup: {
                    ...this.state.servicePopup,
                    [name]: value
                }
            });
    };

    private handleDatePopupChange = (name: string, value: string): void => {
        const date = new Date(value);
        let popupErrors = { ...this.state.popupErrors };

        if (date.toString() === "Invalid Date") {
            if (!popupErrors[name]) {
                popupErrors[name] = { key: name, message: "Invalid Date" };
            }
        } else {
            if (popupErrors[name])
                delete popupErrors[name];
        };

        if (this.state.servicePopup)
            this.setState({
                ...this.state,
                popupErrors,
                servicePopup: {
                    ...this.state.servicePopup,
                    [name]: value
                }
            });
    };

    private popupClose = (): void => {
        this.setState({ servicePopup: undefined, popupErrors: {} });
    };

    private onServiceEditClick = (e: any) => {
        const data = this.state.trailerActivityLog;
        const servicePopup = data.services.find((x) => x.id === e.row.data.id);

        if (servicePopup)
            this.setState({ servicePopup, popupErrors: {} });
    };

    private onServiceClick = () => {
    };

    private onReplacementEdit = (): void => {
    };

    private handleTireEditMode = (): void => {
        this.setState({ unSavedReplacement: !this.state.unSavedReplacement });
    };

    private onDateChange = (name: string, value: string): void => {
        const date = new Date(value);
        let errors = { ...this.state.errors };

        if (date.toString() === "Invalid Date") {
            if (!errors[name]) {
                errors[name] = { key: name, message: "Invalid Date" };
            }
        } else {
            if (errors[name])
                delete errors[name];
        };

        this.setState({
            ...this.state,
            trailerActivityLog: {
                ...this.state.trailerActivityLog,
                [name]: value
            },
            errors
        });
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, isRequired: boolean): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: isRequired ? true : undefined, minLength: 1, decimal: undefined });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        };
        return err;
    };

    private handleCancel = (): void => {
        this.props.history.push(ROUTE.SIL.DISPATCH.TRAILER_ACTIVITY_LOGS);
    };

    private onSave = (): void => {
        const { trailerActivityLog } = this.state;

        if (hasPayload(this.props.getToken))
            if (trailerActivityLog.id === 0) {
                this.props.addTrailerActivityLogRequest({
                    token: this.props.getToken.payload.response.token,
                    request: trailerActivityLog
                });
            } else {
                trailerActivityLog.modified_By = userName;

                this.props.updateTrailerActivityLogRequest({
                    token: this.props.getToken.payload.response.token,
                    request: trailerActivityLog
                });
            };
    };

    private onChange = (name: string, value: string): void => {
        let errors = this.state.errors;
        errors = this.errorChecker(name, value, errors, true);

        this.setState({
            ...this.state,
            errors,
            trailerActivityLog: {
                ...this.state.trailerActivityLog,
                [name]: value
            }
        });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getTrailerActivityLogs))
            this.props.getTrailerActivityLogsRequest({
                token: this.props.getToken.payload.response.token
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getCarriers))
            this.props.getCarriersRequest({
                token: this.props.getToken.payload.response.token
            });


        const query = queryString.parse(this.props.location.search);
        const id = query.id ?? "0";

        if ((id !== "0") && (hasPayload(this.props.getTrailerActivityLogs))) {
            const trailerActivityLog = this.props.getTrailerActivityLogs.payload.response.find(x => x.id === Number(id));

            if (trailerActivityLog)
                this.setState({ trailerActivityLog, errors: {} });
        };

    };

}

const mapStateToProps = (state: IStore): ITrailerActivityLogStoreProps => ({
    getToken: getTokenStatus(state),
    getCarriers: getCarriersStatus(state),
    addTrailerActivityLog: addTrailerActivityLogStatus(state),
    getTrailerActivityLogs: getTrailerActivityLogsStatus(state),
    updateTrailerActivityLog: updateTrailerActivityLogStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): ITrailerActivityLogDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getCarriersRequest: (data: IGetCarriersRequest) => dispatch(getCarriersLoadAction(data)),
    addTrailerActivityLogRequest: (data: IAddTrailerActivityLogRequest) => dispatch(addTrailerActivityLogLoadAction(data)),
    getTrailerActivityLogsRequest: (data: IGetTrailerActivityLogsRequest) => dispatch(getTrailerActivityLogsLoadAction(data)),
    updateTrailerActivityLogRequest: (data: IUpdateTrailerActivityLogRequest) => dispatch(updateTrailerActivityLogLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(TrailerActivityLog);



interface IServicePopup {
    token: string;
    onSave: () => void;
    onClose: () => void;
    handleDeleteAtt: () => void;
    errors: ById<IFieldErrorKeyValue>;
    servicePopup: ITrailerActivityLogServices;
    onChange: (name: string, value: string) => void;
    onDateChange: (name: string, date: any) => void;
    handleAtt: (e: Array<File | DataTransferItem>) => void;
};

const ServicePopup = React.memo((props: IServicePopup): JSX.Element => {
    const onNumberChange = (name: string, value: string): void => props.onChange(name, value.toString());

    const handleDownloadAtt = (): void => {
        if (props.servicePopup.attachment) {
            if ((props.servicePopup.attachmentContent !== undefined) && (props.servicePopup.attachmentContent[0])) {
                let a = document.createElement("a");
                a.href = props.servicePopup.attachmentContent[0].Base64String;
                a.download = props.servicePopup.attachmentContent[0].Name;
                a.click();
            } else {
                    fetch(END_POINTS.PROPSENSE.SIL.CARRIER.GET_CARRIER_EMAIL_FILE, {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            request: {
                                "ID": props.servicePopup.id,
                                "File": props.servicePopup.attachment
                            },
                            token: props.token
                        })
                    })
                        .then((res: any) => {
                            return res.blob();
                        })
                        .then(blob => {
                            if (props.servicePopup.attachment) {
                                let url = window.URL.createObjectURL(blob);
                                let a = document.createElement('a');
                                a.href = url;
                                a.download = props.servicePopup.attachment;
                                document.body.appendChild(a);
                                a.click();
                                a.remove();
                            }
                        })
                        .catch((res) => console.log(res));
            };
        }
    };

    return (
        <LAPopover open={true} onClose={props.onClose} anchorRef={null} transformOrigin={{ vertical: "top", horizontal: "center" }} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
            <LAPaperWithLessPadding>
                <h3 className="text-center">Service</h3>

                <LAGrid>

                    <LAGridItem xs={4}>
                        <LATextField
                            varient="outlined"
                            label="List Id"
                            fullWidth={true}
                            name="list_ID"
                            type="number"
                            onChange={onNumberChange}
                            value={props.servicePopup.list_ID ?? ""}
                            errorText={props.errors["list_ID"] ? props.errors["list_ID"].message : undefined}
                        />
                    </LAGridItem>

                    <LAGridItem xs={4}>
                        <LATextField
                            varient="outlined"
                            label="Service Type Info"
                            fullWidth={true}
                            name="service_TypeInfo"
                            onChange={props.onChange}
                            value={props.servicePopup.service_TypeInfo ?? ""}
                            errorText={props.errors["service_TypeInfo"] ? props.errors["service_TypeInfo"].message : undefined}
                        />
                    </LAGridItem>

                    <LAGridItem xs={4}>
                        <LATextField
                            varient="outlined"
                            label="Km At Service"
                            fullWidth={true}
                            name="km_At_Service"
                            onChange={props.onChange}
                            value={props.servicePopup.km_At_Service ?? ""}
                            errorText={props.errors["km_At_Service"] ? props.errors["km_At_Service"].message : undefined}
                        />
                    </LAGridItem>

                    <LAGridItem xs={4}>
                        <LADateTimeMUIPicker
                            dateOnly={true}
                            fullWidth={true}
                            name="service_Date"
                            label="Service Date"
                            onChange={props.onDateChange}
                            value={props.servicePopup.service_Date ?? ""}
                            errorText={props.errors["service_Date"] ? props.errors["service_Date"].message : undefined}
                        />
                    </LAGridItem>

                    <LAGridItem xs={4}>
                        <LATextField
                            varient="outlined"
                            label="Work Order Number"
                            fullWidth={true}
                            name="work_Order_Number"
                            onChange={props.onChange}
                            value={props.servicePopup.work_Order_Number ?? ""}
                            errorText={props.errors["work_Order_Number"] ? props.errors["work_Order_Number"].message : undefined}
                        />
                    </LAGridItem>

                    <LAGridItem xs={4}>
                        <LATextField
                            varient="outlined"
                            label="Mechanics"
                            fullWidth={true}
                            name="mechanics"
                            onChange={props.onChange}
                            value={props.servicePopup.mechanics ?? ""}
                            errorText={props.errors["mechanics"] ? props.errors["mechanics"].message : undefined}
                        />
                    </LAGridItem>

                    <LAGridItem xs={6}>
                        <LATextArea
                            minRows={4}
                            rowsMax={10}
                            varient="outlined"
                            label="Comments"
                            fullWidth={true}
                            name="comments"
                            onChange={props.onChange}
                            value={props.servicePopup.comments ?? ""}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} className="text-center">
                        <strong>Upload Attachment</strong>
                        <LADropDone onFileDrop={(e: Array<File | DataTransferItem>) => props.handleAtt(e)} />
                        {props.servicePopup.attachment && props.servicePopup.attachment.length > 0 && <>
                            <LALinkButton className="pl-5" label={props.servicePopup.attachment} onClick={handleDownloadAtt} />
                            <LAIconButton
                                name="attachment_Name"
                                label="Remove"
                                icon={<DeleteIcon />}
                                onClick={props.handleDeleteAtt}
                            />
                        </>}
                    </LAGridItem>

                    <LAGridItem xs={12}>
                        <hr />
                        <LASaveAndCancelButton
                            onSave={props.onSave}
                            onCancel={props.onClose}
                        />
                    </LAGridItem>

                </LAGrid>
            </LAPaperWithLessPadding>
        </LAPopover>
    )
});