import { IPassTokenWithRequest } from "../../../sil/dryPlant/token/getTokenConstants";

export enum IGET_ORDER_VERSION_HISTORY_REQUEST {
    REQUEST = "getOrderVersionHistory/GET_ORDER_VERSION_HISTORY_REQUEST",
    SUCCESS = "getOrderVersionHistory/GET_ORDER_VERSION_HISTORY_SUCCESS",
    FAILED = "getOrderVersionHistory/GET_ORDER_VERSION_HISTORY_FAILED"
};

export interface IGetOrderVersionHistoryRequest extends IPassTokenWithRequest {
    id: number;
};

export interface ISIMOSOrderVersionHistory {
    id: number;
    itemID: number;
    customer_ID: number;
    customer: string;
    address: string;
    sitecontact: string;
    sitecontact_Phone: string;
    pO_Number: string;
    emailuser: string;
    emailtransport: string;
    transport_Company: string;
    truck_Required: string;
    location: string;
    comments: string;
    packaged: string;
    completed: string;
    cancelled: string;
    truck_Number: string;
    trailer_Number: string;
    tare_Weight: string;
    gross_Weight: string;
    created_By: string;
    modified_By: string;
    in_Bin: string;
    status: string;
    pickup: string;
    last_Email_Sent_Timestamp: string;
    created: string;
    modified: string;
    total_Tonnage: string;
    order_Type: string;
    transport_Company_Notes: string;
    pickup_Datetime: string;
    delivery_Datetime: string;
    operation: number;
};