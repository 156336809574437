import { IRunMaterials } from "../getRunMaterials/getRunMaterialsConstaints";

export enum IADD_RUN_MATERIAL_REQUEST {
    REQUEST = "addRunMaterial/ADD_RUN_MATERIAL_REQUEST",
    SUCCESS = "addRunMaterial/ADD_RUN_MATERIAL_SUCCESS",
    FAILED = "addRunMaterial/ADD_RUN_MATERIAL_FAILED"
};

export interface IAddRunMaterialRequest {
    token: string;
    request: IRunMaterials;
};