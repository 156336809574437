import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IUPDATE_WASH_PLANT_RUN_MATERIAL_REQUEST } from "./updateWashPlantRunMaterialConstaints";
import { IUpdateWashPlantRunMaterialLoadAction, IUpdateWashPlantRunMaterialLoadFailedAction, IUpdateWashPlantRunMaterialSuccessAction } from "./updateWashPlantRunMaterialActions";

type Actions =
    IUpdateWashPlantRunMaterialLoadAction
    | IUpdateWashPlantRunMaterialLoadFailedAction
    | IUpdateWashPlantRunMaterialSuccessAction
    | IFlushDataSuccessAction;

export const UpdateWashPlantRunMaterialReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IUPDATE_WASH_PLANT_RUN_MATERIAL_REQUEST.REQUEST:
            return loading;

        case IUPDATE_WASH_PLANT_RUN_MATERIAL_REQUEST.SUCCESS:
            return succeeded("Success");

        case IUPDATE_WASH_PLANT_RUN_MATERIAL_REQUEST.FAILED:
            return failed(action.message);

            case FLUSH_DATA.SUCCESS:
                return notLoaded;

        default:
            return state;
    }
};