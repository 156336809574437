import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IGetYHDCarriersLoadAction, IGetYHDCarriersLoadFailedAction, IGetYHDCarriersSuccessAction, 
    getYHDCarriersLoadFailedAction, getYHDCarriersLoadSuccessAction
} from "./getCarriersActions";
import { IStore } from "../../../../reducers";
import { EpicDependencies } from "../../../../store";
import { IGET_YHD_CARRIERS_REQUEST, IYHDCarrier } from "./getCarriersConstaints";
import { END_POINTS } from "../../../../endpoints";
import { withJsonContentType } from "../../../../epicUtils";
import { ByIdNumber, YHDAPIResponse } from "../../../../../react/shared/publicInterfaces";

export const getYHDCarriersEpic: Epic = (
    action$: ActionsObservable<IGetYHDCarriersLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IGetYHDCarriersSuccessAction | IGetYHDCarriersLoadFailedAction> =>
    action$.ofType(IGET_YHD_CARRIERS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<YHDAPIResponse<ByIdNumber<IYHDCarrier>>>(
                    END_POINTS.PROPSENSE.YHD.CARRIER.GET_CARRIERS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: YHDAPIResponse<ByIdNumber<IYHDCarrier>>): IGetYHDCarriersSuccessAction => {
                            return getYHDCarriersLoadSuccessAction(response);
                        }),
                        catchError(() => ActionsObservable.of(getYHDCarriersLoadFailedAction("Unable to get carriers")))
                    )
            )
        );