import { IPassTokenWithRequest } from "../../../dryPlant/token/getTokenConstants";


export enum IGET_WAREHOUSE_PRODUCTS_REQUEST {
    REQUEST = "getWarehouseProducts/GET_WAREHOUSE_PRODUCTS_REQUEST",
    SUCCESS = "getWarehouseProducts/GET_WAREHOUSE_PRODUCTS_SUCCESS",
    FAILED = "getWarehouseProducts/GET_WAREHOUSE_PRODUCTS_FAILED"
};

export interface IGetWarehouseProductsRequest extends IPassTokenWithRequest {

};

export interface IWarehouseProducts {
    id: number;
    product: string;
    description: string;
    unit_Conversion: number;
    depletes_Bulk_Code_1: string;
    yield_1: number;
    depletes_Bulk_Code_2: string;
    yield_2: number;
    consumables_Depletion_Product: string;
    bags: number;
    bag_Size: string;
    bag_Depletion: number;
    active: string;
    created: string;
    created_By: string;
    modified: string;
    modified_By: string;
};