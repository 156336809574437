import { IAddOrderRequest, IADD_ORDER_REQUEST } from "./addOrderConstaints";

export interface IAddOrderLoadAction {
    type: IADD_ORDER_REQUEST.REQUEST;
    data: IAddOrderRequest;
}
export const addOrderLoadAction = (data: IAddOrderRequest): IAddOrderLoadAction => ({
    type: IADD_ORDER_REQUEST.REQUEST,
    data
});

export interface IAddOrderSuccessAction {
    type: IADD_ORDER_REQUEST.SUCCESS;
    message: string;
}
export const addOrderLoadSuccessAction = (message: string): IAddOrderSuccessAction => ({
    type: IADD_ORDER_REQUEST.SUCCESS,
    message
});

export interface IAddOrderLoadFailedAction {
    type: IADD_ORDER_REQUEST.FAILED;
    message: string;
}
export const addOrderLoadFailedAction = (message: string): IAddOrderLoadFailedAction => ({
    type: IADD_ORDER_REQUEST.FAILED,
    message
});
