import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import { ById, SilAPIResponse } from "../../../shared/publicInterfaces";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import { callRouteWithQueryString, userName } from "../../../shared/constExports";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { LAButton } from "../../../shared/buttons";
import { AddIcon } from "../../../shared/icons";
import { WHITE_COLOR } from "../../../shared/theme";
import { ROUTE } from "../../../routes";
import PageSpacing from "../../../shared/pageSpacing";
import { IGetSievesRequest, ISieve } from "../../../../redux/sil/sieves/getSieves/getSievesConstaints";
import SilRoleCheck from "../../../shared/silRoleCheck";
import { getSievesStatus } from "../../../../redux/sil/sieves/getSieves/getSievesAccessor";
import { getSievesLoadAction } from "../../../../redux/sil/sieves/getSieves/getSievesActions";
import LALoading from "../../../shared/loading";


interface ISievesStoreProps {
    getToken: Server<SilAPIResponse<IToken>>;
    getSieves: Server<SilAPIResponse<ById<ISieve>>>;
};

interface ISievesDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getSievesRequest: (data: IGetSievesRequest) => unknown;
};


interface ISievesOwnProps {

};

interface ISievesState {
    data: ISieve[];
};

const SievesStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .loading {
        margin-top: 5%;
    };

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
    };
`;

type ISievesProps = RouteComponentProps
    & ISievesStoreProps
    & ISievesDispatchProps
    & ISievesOwnProps;

class Sieves extends PureComponent<ISievesProps, ISievesState> {

    public constructor(props: ISievesProps) {
        super(props);
        this.state = {
            data: []
        };
    }

    public componentDidMount(): void {
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: ISievesProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();
        };
    };


    public render(): ReactNode {

        const { data } = this.state;
        const { getSieves } = this.props;

        return (
            <PageSpacing title="SIL - Sieves" description="SIL - Sieves" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="sieveAccess">
                    <SievesStyles>

                        {getSieves.kind !== STATUS_ENUM.LOADING &&
                            <LAButton className="pull-left" startIcon={<AddIcon color={WHITE_COLOR} />} label="Add" onClick={this.handleAdd} />}
                        <h2 className="text-center">SIEVES</h2>
                        <hr />

                        {getSieves.kind === STATUS_ENUM.LOADING &&
                            <LALoading className="loading" message="Loading..." />}

                        {getSieves.kind !== STATUS_ENUM.LOADING && <LADevExtremeGrid
                            data={data}
                            onEdit={this.onEdit}
                            onClick={this.onClick}
                            height={1000}
                            searchPanel={true}
                            filterHeader={true}
                            export={true}
                            exportFileName="Sieves"
                            storageKey="silSievesStorageKey"
                            columns={[
                                { name: "id", caption: "PS ID", type: "number"},                                
                                { name: "duplicate_CantyID", caption: "Duplicate Canty ID", type: "string" },
                                { name: "date", caption: "Sample Time", type: "datetime", sortDesc: true },
                                { name: "operator", caption: "Operator", type: "string" },
                                { name: "plant", caption: "Plant", type: "string" },
                                { name: "bin", caption: "Bin/Tower", type: "string" },
                                { name: "product", caption: "Product", type: "string" },                               
                                { name: "load_No", caption: "Load #", type: "string" },
                                { name: "feed_Material_1", caption: "Feed Material 1", type: "string" },
                                { name: "feed_Material_1_Perc", caption: "Feed Material 1 %", type: "number" },
                                { name: "feed_Material_2", caption: "Feed Material 2", type: "string" },
                                { name: "feed_Material_2_Perc", caption: "Feed Material 2 %", type: "number" },
                                { name: "initial_Mass", caption: "Initial Mass", type: "string" },
                                { name: "screen_Section", caption: "Screen Section", type: "string" },
                                { name: "modified", caption: "Modified", type: "datetime" },
                                { name: "modified_By", caption: "Modified By", type: "string" }
                            ]}
                        />}


                    </SievesStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString() },
            pathName: ROUTE.SIL.SIEVES.SIEVES_ITEM
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0" },
            pathName: ROUTE.SIL.SIEVES.SIEVES_ITEM
        });
    };

    private setDataToState = (): void => {

        if (hasPayload(this.props.getSieves)) {
            const data: ISieve[] = Object.values(this.props.getSieves.payload.response);
            this.setState({ data });
        };

        if (isNotLoaded(this.props.getSieves))
            this.callServer();
    };

    private callServer = (): void => {
        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getSieves))
            this.props.getSievesRequest({
                token: this.props.getToken.payload.response.token
            });


        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });
    }

}

const mapStateToProps = (state: IStore): ISievesStoreProps => ({
    getToken: getTokenStatus(state),
    getSieves: getSievesStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): ISievesDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getSievesRequest: (data: IGetSievesRequest) => dispatch(getSievesLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Sieves);