import { IAddProductionRequest, IADD_PRODUCTION_REQUEST } from "./addProductionConstaints";

export interface IAddProductionLoadAction {
    type: IADD_PRODUCTION_REQUEST.REQUEST;
    data: IAddProductionRequest
}
export const addProductionLoadAction = (data: IAddProductionRequest): IAddProductionLoadAction => ({
    type: IADD_PRODUCTION_REQUEST.REQUEST,
    data
});

export interface IAddProductionSuccessAction {
    type: IADD_PRODUCTION_REQUEST.SUCCESS;
    message: string;
}
export const addProductionLoadSuccessAction = (message: string): IAddProductionSuccessAction => ({
    type: IADD_PRODUCTION_REQUEST.SUCCESS,
    message
});
export interface IAddProductionLoadFailedAction {
    type: IADD_PRODUCTION_REQUEST.FAILED;
    message: string;
}
export const addProductionLoadFailedAction = (message: string): IAddProductionLoadFailedAction => ({
    type: IADD_PRODUCTION_REQUEST.FAILED,
    message
});
