import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IGET_SALES_ORDER_NUMBER_BY_CUSTOMER_ID_REQUEST, ISalesOrderNumbersByCustomerID } from "./getSalesOrderNumbersByCustomerIDConstaints";
import { IGetSalesOrderNumbersByCustomerIDLoadAction, IGetSalesOrderNumbersByCustomerIDLoadFailedAction, IGetSalesOrderNumbersByCustomerIDSuccessAction } from "./getSalesOrderNumbersByCustomerIDActions";
import { SimosAPIResponse } from "../../../../react/shared/publicInterfaces";

type Actions =
    IGetSalesOrderNumbersByCustomerIDLoadAction
    | IGetSalesOrderNumbersByCustomerIDLoadFailedAction
    | IGetSalesOrderNumbersByCustomerIDSuccessAction
    | IFlushDataSuccessAction;

export const GetSalesOrderNumbersByCustomerIDReducer = (state: Server<SimosAPIResponse<ISalesOrderNumbersByCustomerID[]>> = notLoaded, action: Actions): Server<SimosAPIResponse<ISalesOrderNumbersByCustomerID[]>> => {
    switch (action.type) {
        case IGET_SALES_ORDER_NUMBER_BY_CUSTOMER_ID_REQUEST.REQUEST:
            return loading;

        case IGET_SALES_ORDER_NUMBER_BY_CUSTOMER_ID_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_SALES_ORDER_NUMBER_BY_CUSTOMER_ID_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};