import { IADD_BAGGED_INVENTORY_REQUEST, IAddBaggedInventoryRequest } from "./addBaggedInventoryConstaints";

export interface IAddBaggedInventoryLoadAction {
    type: IADD_BAGGED_INVENTORY_REQUEST.REQUEST;
    data: IAddBaggedInventoryRequest
}
export const addBaggedInventoryLoadAction = (data: IAddBaggedInventoryRequest): IAddBaggedInventoryLoadAction => ({
    type: IADD_BAGGED_INVENTORY_REQUEST.REQUEST,
    data
});

export interface IAddBaggedInventorySuccessAction {
    type: IADD_BAGGED_INVENTORY_REQUEST.SUCCESS;
    message: string;
}
export const addBaggedInventoryLoadSuccessAction = (message: string): IAddBaggedInventorySuccessAction => ({
    type: IADD_BAGGED_INVENTORY_REQUEST.SUCCESS,
    message
});

export interface IAddBaggedInventoryLoadFailedAction {
    type: IADD_BAGGED_INVENTORY_REQUEST.FAILED;
    message: string;
}
export const addBaggedInventoryLoadFailedAction = (message: string): IAddBaggedInventoryLoadFailedAction => ({
    type: IADD_BAGGED_INVENTORY_REQUEST.FAILED,
    message
});
