import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetTrailerActivityLogsRequest, IGET_TRAILER_ACTIVITY_LOGS_REQUEST, ITrailerActivityLog } from "./getTrailerActivityLogsConstaints";

export interface IGetTrailerActivityLogsLoadAction {
    type: IGET_TRAILER_ACTIVITY_LOGS_REQUEST.REQUEST;
    data: IGetTrailerActivityLogsRequest
}
export const getTrailerActivityLogsLoadAction = (data: IGetTrailerActivityLogsRequest): IGetTrailerActivityLogsLoadAction => ({
    type: IGET_TRAILER_ACTIVITY_LOGS_REQUEST.REQUEST,
    data
});

export interface IGetTrailerActivityLogsSuccessAction {
    type: IGET_TRAILER_ACTIVITY_LOGS_REQUEST.SUCCESS;
    data: SilAPIResponse<ITrailerActivityLog[]>;
}
export const getTrailerActivityLogsLoadSuccessAction = (data: SilAPIResponse<ITrailerActivityLog[]>): IGetTrailerActivityLogsSuccessAction => ({
    type: IGET_TRAILER_ACTIVITY_LOGS_REQUEST.SUCCESS,
    data
});

export interface IGetTrailerActivityLogsLoadFailedAction {
    type: IGET_TRAILER_ACTIVITY_LOGS_REQUEST.FAILED;
    message: string;
}
export const getTrailerActivityLogsLoadFailedAction = (message: string): IGetTrailerActivityLogsLoadFailedAction => ({
    type: IGET_TRAILER_ACTIVITY_LOGS_REQUEST.FAILED,
    message
});
