import { IAddWashPlantFeedMaterialRequest, IADD_WASH_PLANT_FEED_MATERIAL_REQUEST } from "./addWashPlantFeedMaterialConstaints";

export interface IAddWashPlantFeedMaterialLoadAction {
    type: IADD_WASH_PLANT_FEED_MATERIAL_REQUEST.REQUEST;
    data: IAddWashPlantFeedMaterialRequest
}
export const addWashPlantFeedMaterialLoadAction = (data: IAddWashPlantFeedMaterialRequest): IAddWashPlantFeedMaterialLoadAction => ({
    type: IADD_WASH_PLANT_FEED_MATERIAL_REQUEST.REQUEST,
    data
});

export interface IAddWashPlantFeedMaterialSuccessAction {
    type: IADD_WASH_PLANT_FEED_MATERIAL_REQUEST.SUCCESS;
    message: string;
}
export const addWashPlantFeedMaterialLoadSuccessAction = (message: string): IAddWashPlantFeedMaterialSuccessAction => ({
    type: IADD_WASH_PLANT_FEED_MATERIAL_REQUEST.SUCCESS,
    message
});
export interface IAddWashPlantFeedMaterialLoadFailedAction {
    type: IADD_WASH_PLANT_FEED_MATERIAL_REQUEST.FAILED;
    message: string;
}
export const addWashPlantFeedMaterialLoadFailedAction = (message: string): IAddWashPlantFeedMaterialLoadFailedAction => ({
    type: IADD_WASH_PLANT_FEED_MATERIAL_REQUEST.FAILED,
    message
});
