import { IPassTokenRequestBody } from "../../../../../react/shared/publicInterfaces";

export enum IADD_SIMOS_CUSTOMER_CONTACT_REQUEST {
    REQUEST = "addSimosCustomerContact/ADD_SIMOS_CUSTOMER_CONTACT_REQUEST",
    SUCCESS = "addSimosCustomerContact/ADD_SIMOS_CUSTOMER_CONTACT_SUCCESS",
    FAILED = "addSimosCustomerContact/ADD_SIMOS_CUSTOMER_CONTACT_FAILED"
};

export interface IAddSimosCustomerContactRequest extends IPassTokenRequestBody {
    request: IAddUpdateSimosCustomerContact;
};

export interface IAddUpdateSimosCustomerContact {
    ID: number;
    Contact: string;
    Phone: string;
    Cell: string;
    Email: string;
    Customer: string;
    Customer_ID: number;
    Delivery_Address: string;
    Delivery_ID: number;
    Created_By: string;
    Modified_By: string;
}