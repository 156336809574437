import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IGET_ORDER_VERSION_HISTORY_REQUEST, ISIMOSOrderVersionHistory } from "./getOrderVersionHistoryConstaints";
import { IGetOrderVersionHistoryLoadAction, IGetOrderVersionHistoryLoadFailedAction, IGetOrderVersionHistorySuccessAction } from "./getOrderVersionHistoryActions";
import { SimosAPIResponse } from "../../../../react/shared/publicInterfaces";

type Actions =
    IGetOrderVersionHistoryLoadAction
    | IGetOrderVersionHistoryLoadFailedAction
    | IGetOrderVersionHistorySuccessAction
    | IFlushDataSuccessAction;

export const GetOrderVersionHistoryReducer = (state: Server<SimosAPIResponse<ISIMOSOrderVersionHistory[]>> = notLoaded, action: Actions): Server<SimosAPIResponse<ISIMOSOrderVersionHistory[]>> => {
    switch (action.type) {
        case IGET_ORDER_VERSION_HISTORY_REQUEST.REQUEST:
            return loading;

        case IGET_ORDER_VERSION_HISTORY_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_ORDER_VERSION_HISTORY_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};