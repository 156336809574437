import { IPassTokenWithRequest } from "../../dryPlant/token/getTokenConstants";
import { ITrailerActivityLog } from "../getTrailerActivityLogs/getTrailerActivityLogsConstaints";


export enum IADD_TRAILER_ACTIVITY_LOG_REQUEST {
    REQUEST = "addTrailerActivityLog/ADD_TRAILER_ACTIVITY_LOG_REQUEST",
    SUCCESS = "addTrailerActivityLog/ADD_TRAILER_ACTIVITY_LOG_SUCCESS",
    FAILED = "addTrailerActivityLog/ADD_TRAILER_ACTIVITY_LOG_FAILED"
};

export interface IAddTrailerActivityLogRequest extends IPassTokenWithRequest {
    request: ITrailerActivityLog;
};