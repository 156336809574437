import { ISieveFracBall } from "../getSieveFracBalls/getSieveFracBallsConstaints";
import { IUpdateSieveFracBallRequest, IUPDATE_SIEVE_FRAC_BALL_REQUEST } from "./updateSieveFracBallConstaints";

export interface IUpdateSieveFracBallLoadAction {
    type: IUPDATE_SIEVE_FRAC_BALL_REQUEST.REQUEST;
    data: IUpdateSieveFracBallRequest
}
export const updateSieveFracBallLoadAction = (data: IUpdateSieveFracBallRequest): IUpdateSieveFracBallLoadAction => ({
    type: IUPDATE_SIEVE_FRAC_BALL_REQUEST.REQUEST,
    data
});

export interface IUpdateSieveFracBallSuccessAction {
    type: IUPDATE_SIEVE_FRAC_BALL_REQUEST.SUCCESS;
    data: ISieveFracBall;
}
export const updateSieveFracBallLoadSuccessAction = (data: ISieveFracBall): IUpdateSieveFracBallSuccessAction => ({
    type: IUPDATE_SIEVE_FRAC_BALL_REQUEST.SUCCESS,
    data
});

export interface IUpdateSieveFracBallLoadFailedAction {
    type: IUPDATE_SIEVE_FRAC_BALL_REQUEST.FAILED;
    message: string;
}
export const updateSieveFracBallLoadFailedAction = (message: string): IUpdateSieveFracBallLoadFailedAction => ({
    type: IUPDATE_SIEVE_FRAC_BALL_REQUEST.FAILED,
    message
});
