import { IFlushDataSuccessAction } from "../../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../../server";
import { IUpdateYHDCarrierInfractionLoadAction, IUpdateYHDCarrierInfractionLoadFailedAction, IUpdateYHDCarrierInfractionSuccessAction } from "./updateCarrierInfractionActions";
import { IUPDATE_YHD_CARRIER_INFRACTION_REQUEST } from "./updateCarrierInfractionConstaints";


type Actions =
    IUpdateYHDCarrierInfractionLoadAction
    | IUpdateYHDCarrierInfractionLoadFailedAction
    | IUpdateYHDCarrierInfractionSuccessAction
    | IFlushDataSuccessAction;

export const UpdateYHDCarrierInfractionReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IUPDATE_YHD_CARRIER_INFRACTION_REQUEST.REQUEST:
            return loading;

        case IUPDATE_YHD_CARRIER_INFRACTION_REQUEST.SUCCESS:
            return succeeded("Success");

        case IUPDATE_YHD_CARRIER_INFRACTION_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};