import React, { PureComponent, ReactNode } from "react";
import { RouteComponentProps } from "react-router";
import styled from "styled-components";
import { ROUTE } from "../routes";
import { LAMenuItem } from "../shared/dropDownMenu";
import { DARK_GREY_COLOR } from "../shared/theme";
import { SwipeableDrawer } from "@mui/material";
import LAGrid from "../shared/grid";
import LAGridItem from "../shared/gridList";
import { LAPaperWithLessPadding } from "../shared/paper";
import { LAIconButton } from "../shared/buttons";
import { SecondListIcon } from "../shared/icons";
import { callRouteWithQueryString } from "../shared/constExports";
import queryString from "query-string";

const SimosSubHeaderStyles = styled.div`
    .subHeader {
        background-color: #282c34;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        font-size: calc(10px + 2vmin);
        color: #61dafb;
        overflow-x: hidden;
    };

    .field-page {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    };

    .current {
        box-shadow: 0 0 2px 2px #FFFFFF;
    };

    .current-mobile {
        background-color: ${DARK_GREY_COLOR};
    };
`;

interface ISimosSubHeaderProps extends RouteComponentProps {

};

export const SimosSubHeader: React.FC<ISimosSubHeaderProps> = React.memo((props: ISimosSubHeaderProps) => {

    const path = props.location.pathname;
    const query = queryString.parse(props.location.search);

    const onClick = (value: string): void => {
        window.open(value, "_blank");
    };

    const onSimosHome = (): void => {
        callRouteWithQueryString({
            route: props,
            search: { },
            pathName: ROUTE.SIMOS.INDEX 
        });
    };

    const onSimosOrders = (): void => {
        // props.history.push(ROUTE.SIMOS.ORDER.INDEX + "?sil_bru");
        callRouteWithQueryString({
            route: props,
            search: { page: query.page ? query.page.toString() : "" },
            pathName: ROUTE.SIMOS.ORDER.INDEX
        });
    };

    const onCustomerContacts = (): void => {
        // props.history.push(ROUTE.SIMOS.CUSTOMERCONTACT.INDEX);
        callRouteWithQueryString({
            route: props,
            search: {  },
            pathName: ROUTE.SIMOS.CUSTOMERCONTACT.INDEX
        });
    };

    const onSilBru = (): void => {
        callRouteWithQueryString({
            route: props,
            search: { page: "sil_bru" },
            pathName: ROUTE.SIMOS.SIL_Bru_Orders.INDEX
        });
    };
    
    const onSilEd = (): void => {
        callRouteWithQueryString({
            route: props,
            search: { page: "sil_ed" },
            pathName: ROUTE.SIMOS.SIL_Ed_Orders.INDEX
        });
    };

    const onCustomers = (): void => {
        // props.history.push(ROUTE.SIMOS.CUSTOMER.INDEX);
        callRouteWithQueryString({
            route: props,
            search: {  },
            pathName: ROUTE.SIMOS.CUSTOMER.INDEX
        });
    };

    const onTransportCompanies = (): void => {
        // props.history.push(ROUTE.SIMOS.TRANSPORTCOMPANIES.INDEX);
        callRouteWithQueryString({
            route: props,
            search: {  },
            pathName: ROUTE.SIMOS.TRANSPORTCOMPANIES.INDEX
        });
    };

    const onProducts = (): void => {
        // props.history.push(ROUTE.SIMOS.PRODUCT.INDEX);
        callRouteWithQueryString({
            route: props,
            search: {  },
            pathName: ROUTE.SIMOS.PRODUCT.INDEX
        });
    };

    return (
        <SimosSubHeaderStyles>
            <div className="subHeader">
                <p>
                    <div className="field-page">
                        <LAMenuItem onClick={onSimosHome}>
                            SIMOS Home
                        </LAMenuItem>

                        <LAMenuItem onClick={() => onClick("https://sharepoint.sureway.ca/sil/Pages/SIL_Home.aspx")}>
                            SIL SharePoint
                        </LAMenuItem>

                        <LAMenuItem onClick={onSilBru} className={(path.includes(ROUTE.SIMOS.SIL_Bru_Orders.INDEX)) ? "current" : ""}>
                            Sil Bru Orders
                        </LAMenuItem>

                        <LAMenuItem onClick={onSilEd} className={(path.includes(ROUTE.SIMOS.SIL_Ed_Orders.INDEX)) ? "current" : ""}>
                            Sil Ed Orders
                        </LAMenuItem>

                        <LAMenuItem onClick={onCustomerContacts} className={(path.includes(ROUTE.SIMOS.CUSTOMERCONTACT.INDEX)) ? "current" : ""}>
                            SIMOS Customer Contacts
                        </LAMenuItem>

                        <LAMenuItem onClick={onCustomers} className={(path.includes(ROUTE.SIMOS.CUSTOMER.INDEX)) ? "current" : ""}>
                            SIMOS Customers
                        </LAMenuItem>

                        <LAMenuItem onClick={onTransportCompanies} className={(path.includes(ROUTE.SIMOS.TRANSPORTCOMPANIES.INDEX)) ? "current" : ""}>
                            SIMOS Transport Companies
                        </LAMenuItem>

                        <LAMenuItem onClick={onProducts} className={(path.includes(ROUTE.SIMOS.PRODUCT.INDEX)) ? "current" : ""}>
                            SIMOS Products
                        </LAMenuItem>

                    </div>
                </p>
            </div>
        </SimosSubHeaderStyles>
    );
});

interface ISimosSubHeaderMobileState {
    drawer: boolean;
};

export class SimosSubHeaderMobile extends PureComponent<ISimosSubHeaderProps, ISimosSubHeaderMobileState> {

    public constructor(props: ISimosSubHeaderProps) {
        super(props);
        this.state = {
            drawer: false
        };
    }

    public render(): ReactNode {

        const path = this.props.location.pathname;
        const query = queryString.parse(this.props.location.search);

        return (
            <LAPaperWithLessPadding>
                <LAIconButton
                    label="Open"
                    icon={<SecondListIcon />}
                    onClick={this.handleDrawer}
                />

                <SwipeableDrawer
                    sx={{
                        width: 240,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: 240,
                            boxSizing: 'border-box',
                        },
                    }}
                    open={this.state.drawer}
                    onClose={this.handleDrawer}
                    onOpen={this.handleDrawer}
                >
                    <SimosSubHeaderStyles>
                        <LAGrid className="ml-3 mt-3" spacing={3}>

                            <LAGridItem xs={12} onClick={this.onSimosHome}>
                                <span>
                                    SIMOS Home
                                </span>
                            </LAGridItem>

                            <LAGridItem xs={12} onClick={this.onSILSharepoint}>
                                <span>
                                    SIL Sharepoint
                                </span>
                            </LAGridItem>

                            {/* <LAGridItem xs={12} onClick={this.onSimosOrders} className={(path.includes(ROUTE.SIMOS.ORDER.INDEX)) ? "current-mobile" : ""}>
                                <span>
                                    {query.page?.toString() === "sil_bru" ? "SIMOS SIL BRU Orders" : query.page?.toString() === "sil_ed" ? "SIMOS SIL ED Orders" : "SIMOS ORDERS"}
                                </span>
                            </LAGridItem> */}

                            <LAGridItem xs={12} onClick={this.onSilBruOrders} className={(path.includes(ROUTE.SIMOS.SIL_Bru_Orders.INDEX)) ? "current-mobile" : ""}>
                                <span>
                                    Sil Bru Orders
                                </span>
                            </LAGridItem>

                            <LAGridItem xs={12} onClick={this.onSilEdOrders} className={(path.includes(ROUTE.SIMOS.SIL_Ed_Orders.INDEX)) ? "current-mobile" : ""}>
                                <span>
                                    Sil ED Orders
                                </span>
                            </LAGridItem>

                            <LAGridItem xs={12} onClick={this.onCustomerContacts} className={(path.includes(ROUTE.SIMOS.CUSTOMERCONTACT.INDEX)) ? "current-mobile" : ""}>
                                <span>
                                    SIMOS Customer Contacts
                                </span>
                            </LAGridItem>

                            <LAGridItem xs={12} onClick={this.onCustomers} className={(path.includes(ROUTE.SIMOS.CUSTOMER.INDEX)) ? "current-mobile" : ""}>
                                <span>
                                    SIMOS Customers
                                </span>
                            </LAGridItem>

                            <LAGridItem xs={12} onClick={this.onTransportCompanies} className={(path.includes(ROUTE.SIMOS.TRANSPORTCOMPANIES.INDEX)) ? "current-mobile" : ""}>
                                <span>
                                    SIMOS Transport Companies
                                </span>
                            </LAGridItem>

                            <LAGridItem xs={12} onClick={this.onProducts} className={(path.includes(ROUTE.SIMOS.PRODUCT.INDEX)) ? "current-mobile" : ""}>
                                <span>
                                    SIMOS Products
                                </span>
                            </LAGridItem>

                        </LAGrid>
                    </SimosSubHeaderStyles>
                </SwipeableDrawer>
            </LAPaperWithLessPadding>
        );
    };

    private onSimosHome = (): void => {
        this.handleDrawer();

        // this.props.history.push(
        //     ROUTE.SIMOS.ORDER.INDEX + "?sil_bru"
        // );
        callRouteWithQueryString({
            route: this.props,
            search: { },
            pathName: ROUTE.SIMOS.INDEX
        });
    };
    private onSimosOrders = (): void => {
        this.handleDrawer();

        // this.props.history.push(
        //     ROUTE.SIMOS.ORDER.INDEX + "?sil_bru"
        // );
        const query = queryString.parse(this.props.location.search);
        callRouteWithQueryString({
            route: this.props,
            search: { page: query.page ? query.page.toString() : "" },
            pathName: ROUTE.SIMOS.ORDER.INDEX
        });
    };
    private onSilBruOrders = (): void => {
        this.handleDrawer();

        // this.props.history.push(
        //     ROUTE.SIMOS.CUSTOMERCONTACT.INDEX
        // );
        const query = queryString.parse(this.props.location.search);
        callRouteWithQueryString({
            route: this.props,
            search: { page: "sil_bru"},
            pathName: ROUTE.SIMOS.SIL_Bru_Orders.INDEX
        });
    };
    private onSilEdOrders = (): void => {
        this.handleDrawer();

        // this.props.history.push(
        //     ROUTE.SIMOS.CUSTOMERCONTACT.INDEX
        // );
        // const query = queryString.parse(this.props.location.search);
        callRouteWithQueryString({
            route: this.props,
            search: { page: "sil_ed"},
            pathName: ROUTE.SIMOS.SIL_Ed_Orders.INDEX
        });
    };
    private onCustomerContacts = (): void => {
        this.handleDrawer();

        // this.props.history.push(
        //     ROUTE.SIMOS.CUSTOMERCONTACT.INDEX
        // );
        // const query = queryString.parse(this.props.location.search);
        callRouteWithQueryString({
            route: this.props,
            search: {  },
            pathName: ROUTE.SIMOS.CUSTOMERCONTACT.INDEX
        });
    };
    private onCustomers = (): void => {
        this.handleDrawer();

        // this.props.history.push(
        //     ROUTE.SIMOS.CUSTOMER.INDEX
        // );
        // const query = queryString.parse(this.props.location.search);
        callRouteWithQueryString({
            route: this.props,
            search: {  },
            pathName: ROUTE.SIMOS.CUSTOMER.INDEX
        });
    };
    private onTransportCompanies = (): void => {
        this.handleDrawer();

        // this.props.history.push(
        //     ROUTE.SIMOS.TRANSPORTCOMPANIES.INDEX
        // );
        // const query = queryString.parse(this.props.location.search);
        callRouteWithQueryString({
            route: this.props,
            search: {  },
            pathName: ROUTE.SIMOS.TRANSPORTCOMPANIES.INDEX
        });
    };
    private onProducts = (): void => {
        this.handleDrawer();

        // const query = queryString.parse(this.props.location.search);
        callRouteWithQueryString({
            route: this.props,
            search: {  },
            pathName: ROUTE.SIMOS.PRODUCT.INDEX
        });
    };

    private onSILSharepoint = (): void => {
        window.open("https://sharepoint.sureway.ca/sil/Pages/SIL_Home.aspx", "_blank");
    };

    private handleDrawer = (): void => {
        this.setState({ drawer: !this.state.drawer });
    };

    

    
    
   

}

