import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../../shared/paper";
import { IDispatch, IStore } from "../../../../../redux/reducers";
import { hasPayload, isNotLoaded, isSucceeded, Server } from "../../../../../redux/server";
import { ById, SimosAPIResponse } from "../../../../shared/publicInterfaces";
import { userName, numberAndNotDecimalCheck, undefinedFunction, callRouteWithQueryString, NotApplicable, ReadOnly } from "../../../../shared/constExports";
import { LAButton, LASaveAndCancelButton } from "../../../../shared/buttons";
import { ArrowLeftIcon } from "../../../../shared/icons";
import { WHITE_COLOR } from "../../../../shared/theme";
import LAGrid from "../../../../shared/grid";
import LAGridItem from "../../../../shared/gridList";
import LATextField from "../../../../shared/textField";
import { ROUTE } from "../../../../routes";
import queryString from "query-string";
import RequestStatus from "../../../../shared/requestStatusSnackbar";
import { FieldValidator, FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../../../shared/fieldValidator";
import PageSpacing from "../../../../shared/pageSpacing";
import { IGetTokenRequest, IToken } from "../../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenStatus } from "../../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { getTokenLoadAction } from "../../../../../redux/sil/dryPlant/token/getTokenActions";
import SilRoleCheck from "../../../../shared/silRoleCheck";
import { ISimosProducts, ISimosProductsRequest } from "../../../../../redux/propsense/simos/simosProducts/getSimosProducts/getSimosProductsConstants";
import { IAddSimosProductRequest, IAddUpdateSimosProduct } from "../../../../../redux/propsense/simos/simosProducts/addSimosProduct/addSimosProductConstaints";
import { getSimosProducts } from "../../../../../redux/propsense/simos/simosProducts/getSimosProducts/getSimosProductsAccessor";
import { addSimosProductStatus } from "../../../../../redux/propsense/simos/simosProducts/addSimosProduct/addSimosProductAccessor";
import { getSimosProductsLoadAction } from "../../../../../redux/propsense/simos/simosProducts/getSimosProducts/getSimosProductsActions";
import { addSimosProductLoadAction } from "../../../../../redux/propsense/simos/simosProducts/addSimosProduct/addSimosProductActions";
import { IIdName } from "../../../../../utils/sharedTypes";
import LAAutoComplete from "../../../../shared/autoComplete";
import { updateSimosProductStatus } from "../../../../../redux/propsense/simos/simosProducts/updateSimosProduct/updateSimosProductAccessor";
import { IUpdateSimosProductRequest } from "../../../../../redux/propsense/simos/simosProducts/updateSimosProduct/updateSimosProductConstaints";
import { updateSimosProductLoadAction } from "../../../../../redux/propsense/simos/simosProducts/updateSimosProduct/updateSimosProductActions";
import LAErrorBox from "../../../../shared/errorBox";

const RequiredFields = ["description", "location", "code", "size"];

const productLocationsOptions: IIdName<string>[] = [{ id: "1", name: "SIL BRU Warehouse" }, { id: "2", name: "SIL ED Warehouse" }, { id: "3", name: "Bulk" }];
// const productLocationsOptionsForSILBru: IIdName<string>[] = [{ id: "1", name: "SIL BRU Warehouse" }, { id: "2", name: "Bulk" }];
// const productLocationsOptionsForSILED: IIdName<string>[] = [{ id: "1", name: "SIL ED Warehouse" }, { id: "2", name: "Bulk" }];
const productSizeOptionsForSILBruOrED: IIdName<string>[] = [{ id: "1", name: "10 kg" }, { id: "2", name: "22.7 kg" }, { id: "3", name: "40 kg" }, { id: "4", name: "Bulk Bag" }, { id: "5", name: "Poly" }];
const productSizeOptionsForBulk: IIdName<string>[] = [{ id: "1", name: "N/A" }, { id: "2", name: "Single" }, { id: "3", name: "Super B" }, { id: "5", name: "Tri-Axle" }, { id: "5", name: "B-Train" }];

interface ISimosProductStoreProps {
    getToken: Server<SimosAPIResponse<IToken>>;
    getSimosProducts: Server<SimosAPIResponse<ISimosProducts[]>>;
    addSimosProduct: Server<string>;
    updateSimosProduct: Server<string>;
};

interface ISimosProductDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getSimosProductsRequest: (data: ISimosProductsRequest) => unknown;
    addSimosProductRequest: (data: IAddSimosProductRequest) => unknown;
    updateSimosProductRequest: (data: IUpdateSimosProductRequest) => unknown;
};


interface ISimosProductOwnProps {

};

interface ISimosProductState {
    data: ISimosProducts;
    errors: ById<IFieldErrorKeyValue>;
};

const SimosProductStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;

    .dx-toolbar-after {
        display: none;
    };

    .variancepopup{
        height: 50% !important;
    }
`;

type ISimosProductProps = RouteComponentProps
    & ISimosProductStoreProps
    & ISimosProductDispatchProps
    & ISimosProductOwnProps;

class AddUpdateSimosProduct extends PureComponent<ISimosProductProps, ISimosProductState> {

    public constructor(props: ISimosProductProps) {
        super(props);
        this.state = {
            errors: {
                
            },
            data: {
                id: 0,
                description: "",
                code: "",
                size: "",
                location: "",
                created: "",
                created_By: userName,
                modified: "",
                modified_By: userName,
            }
        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: ISimosProductProps): void {
        if (this.props !== prevProps) {
            this.callServer();

            if (this.props.addSimosProduct !== prevProps.addSimosProduct) {
                if (isSucceeded(this.props.addSimosProduct)) {
                    this.handleCancel();
                };
            };

            if (this.props.updateSimosProduct !== prevProps.updateSimosProduct) {
                if (isSucceeded(this.props.updateSimosProduct)) {
                    this.handleCancel();
                };
            };
        };
    };


    public render(): ReactNode {

        const { data, errors } = this.state;
        const { updateSimosProduct, addSimosProduct, getToken  } = this.props;
        const getRole = hasPayload(getToken) && getToken.payload.response.simosAccess.access;
        const readOnly = getRole === ReadOnly;
        // const query = queryString.parse(this.props.location.search);
        // const productLocationsOptions = query.page === "sil_bru" ? productLocationsOptionsForSILBru : productLocationsOptionsForSILED;
        // const productSizeOptions = data.location === productLocationsOptions[0].name ? productSizeOptionsForSILBruOrED : data.location === productLocationsOptions[1].name ? productSizeOptionsForBulk : []
        const productSizeOptions = data.location === productLocationsOptions[0].name || data.location === productLocationsOptions[1].name ? productSizeOptionsForSILBruOrED : data.location === productLocationsOptions[2].name ? productSizeOptionsForBulk : []
        const handleOnLocationChange = (event: unknown, value: { id: number, name: string }): void => this.onChange("location", value !== null ? value.name : data.location);
        const handleOnSizeChange = (event: unknown, value: { id: number, name: string }): void => this.onChange("size", value !== null ? value.name : data.size);

        return (
            <PageSpacing title="SIMOS - Simos Product" description="Simos Product" fixedSpaceOnSmallerScreens={true}>
                {getRole !== NotApplicable ?
                    <SimosProductStyles>

                        <LAButton startIcon={<ArrowLeftIcon color={WHITE_COLOR} />} label="Back to list" onClick={this.handleCancel} />
                        <h2 className="text-center">{data.id > 0 ? "VIEW/UPDATE " : "ADD "}SIMOS PRODUCT</h2>
                        <hr />

                        <LAPaperWithPadding>
                            <LAGrid spacing={1}>
                            
                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LAAutoComplete
                                        multiple={false}
                                        disabled={data.id > 0 || readOnly}
                                        option={productLocationsOptions}
                                        getOptionLabel="name"
                                        autoHighlight={true}
                                        onChange={handleOnLocationChange}
                                        errorText={errors && errors["location"] ? errors["location"].message : undefined}
                                        filterSelectedOptions={true}
                                        dropDownPlaceHolder="Product Location"
                                        selectionRemove={undefinedFunction}
                                        value={data.location ? productLocationsOptions.find(q => q.name === data.location) : null}
                                        defaultValue={data.location ? productLocationsOptions.find(q => q.name === data.location) : null}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>                                   
                                     <LATextField
                                        varient="outlined"
                                        label="Product Name"
                                        fullWidth={true}
                                        name="description"
                                        disabled={readOnly}
                                        onChange={this.onChange}
                                        value={data.description ?? ""}
                                        errorText={errors && errors["description"] ? errors["description"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LAAutoComplete
                                        multiple={false}
                                        option={productSizeOptions}
                                        getOptionLabel="name"
                                        disabled={data.id > 0 || readOnly}
                                        autoHighlight={true}
                                        onChange={handleOnSizeChange}
                                        errorText={errors && errors["size"] ? errors["size"].message : undefined}
                                        filterSelectedOptions={true}
                                        dropDownPlaceHolder="Product Size"
                                        selectionRemove={undefinedFunction}
                                        value={data.size ? productSizeOptions.find(q => q.name === data.size) : null}
                                        defaultValue={data.size ? productSizeOptions.find(q => q.name === data.size) : null}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>                                   
                                     <LATextField
                                        varient="outlined"
                                        label="Product Code"
                                        disabled={data.id > 0 || readOnly}
                                        fullWidth={true}
                                        name="code"
                                        onChange={this.onChange}
                                        errorText={errors && errors["code"] ? errors["code"].message : undefined}
                                        value={data.code ?? ""}
                                    />
                                </LAGridItem>

                                {data.id && data.id > 0 ? 
                                <>
                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <LATextField
                                            fullWidth={true}
                                            name="created"
                                            label="Created"
                                            varient="outlined"
                                            disabled={true}
                                            value={data.created ? new Date(data.created).toLocaleString() : ""}
                                            onChange={undefinedFunction}
                                            errorText={errors["created"] ? errors["created"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <LATextField
                                            fullWidth={true}
                                            name="created_By"
                                            label="Created By"
                                            disabled={true}
                                            varient="outlined"
                                            value={data.created_By}
                                            onChange={undefinedFunction}
                                            errorText={errors["created_By"] ? errors["created_By"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <LATextField
                                            fullWidth={true}
                                            name="modified"
                                            label="Last Modified"
                                            varient="outlined"
                                            disabled={true}
                                            value={data.modified ? new Date(data.modified).toLocaleString() : ""}
                                            onChange={undefinedFunction}
                                            errorText={errors["modified"] ? errors["modified"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <LATextField
                                            fullWidth={true}
                                            name="modified_By"
                                            label="Modified By"
                                            varient="outlined"
                                            disabled={true}
                                            value={data.modified_By}
                                            onChange={undefinedFunction}
                                            errorText={errors["modified_By"] ? errors["modified_By"].message : undefined}
                                        />
                                    </LAGridItem>
                                </> : null}

                                <LAGridItem xs={12}>
                                    <LASaveAndCancelButton
                                        onSave={this.onSave}
                                        onCancel={this.handleCancel}
                                        disableSave={(Object.values(errors).length > 0 || readOnly) ? true : undefined}
                                    />
                                </LAGridItem>

                            </LAGrid>
                        </LAPaperWithPadding>

                        <RequestStatus requestStatus={addSimosProduct.kind} successMessage="Simos Product successfully saved" failedMessage={addSimosProduct.kind === "failed" ? addSimosProduct.message : "Your Request has failed. Please try again or contact IT.Developers for assistance."} />
                        <RequestStatus requestStatus={updateSimosProduct.kind} successMessage="Simos Product  successfully updated" failedMessage={updateSimosProduct.kind === "failed" ? updateSimosProduct.message : "Your Request has failed. Please try again or contact IT.Developers for assistance."}/>

                    </SimosProductStyles> : 
                    <LAErrorBox text="You do not have permission to view this page. Contact your system admin." />
                }
            </PageSpacing>
        );
    }

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, isRequired: boolean): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: isRequired ? true : undefined, minLength: 1, decimal: undefined });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        };
        return err;
    };

    private handleCancel = (): void => {
        // this.props.history.push(ROUTE.SIMOS.PRODUCT.INDEX);
        const query = queryString.parse(this.props.location.search);
        callRouteWithQueryString({
            route: this.props,
            search: { page: query.page ? query.page.toString() : "" },
            pathName: ROUTE.SIMOS.PRODUCT.INDEX
        });
    };

    private onSave = async (): Promise<void> => {

        if (hasPayload(this.props.getToken)) {
            const { data } = this.state;

            const request: IAddUpdateSimosProduct = {
                ID: data.id,
                Description: data.description,
                Code: data.code,
                Size: data.size,
                Location: data.location,
                Created_By: userName,
                Modified_By:  userName,
            };

            // console.log(request);

            if (data.id === 0) {
                this.props.addSimosProductRequest({
                    token: this.props.getToken.payload.response.token,
                    request
                });
            } 
            else {
                this.props.updateSimosProductRequest({
                    token: this.props.getToken.payload.response.token,
                    request
                });
            };
        }
    };

    private onChange = (name: string, value: string): void => {
        let errors = this.state.errors;

        if (RequiredFields.includes(name))
            errors = this.errorChecker(name, value ? value : "", errors, true);

        this.setState({
            ...this.state,
            errors,
            data: {
                ...this.state.data,
                [name]: value,
            }
        });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getSimosProducts))
            this.props.getSimosProductsRequest({
                token: this.props.getToken.payload.response.token
            });
        
        const query = queryString.parse(this.props.location.search);
        const id = query.id ?? "0";

        if ((id !== "0") && (hasPayload(this.props.getSimosProducts)) && (this.state.data.id === 0)) {
            const data = this.props.getSimosProducts.payload.response.find((x) => x.id === +id);

            if (data)
                this.setState({ data, errors: {} });
        } else {
            const errors: ById<IFieldErrorKeyValue> = {};
            RequiredFields.forEach((x) => {
                errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
            });

            this.setState({ errors });
        }

    };

}

const mapStateToProps = (state: IStore): ISimosProductStoreProps => ({
    getToken: getTokenStatus(state),
    getSimosProducts: getSimosProducts(state),
    addSimosProduct: addSimosProductStatus(state),
    updateSimosProduct: updateSimosProductStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): ISimosProductDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getSimosProductsRequest: (data: ISimosProductsRequest) => dispatch(getSimosProductsLoadAction(data)),
    addSimosProductRequest: (data: IAddSimosProductRequest) => dispatch(addSimosProductLoadAction(data)),
    updateSimosProductRequest: (data: IUpdateSimosProductRequest) => dispatch(updateSimosProductLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateSimosProduct);