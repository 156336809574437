import { IGET_SIMOS_CUSTOMER_CONTACTS_REQUEST, ISimosCustomerContacts } from "./getSimosCustomerContactsConstants";
import { IGetSimosCustomerContactsLoadAction, IGetSimosCustomerContactsLoadFailedAction, IGetSimosCustomerContactsSuccessAction } from "./getSimosCustomerContactsActions";
import { SimosAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../../server";
import { IFlushDataSuccessAction } from '../../../../flushData.tsx/flushDataActions';
import { FLUSH_DATA } from '../../../../flushData.tsx/flushDataConstants';
import { IAddSimosCustomerContactSuccessAction } from "../addSimosCustomerContact/addSimosCustomerContactActions";
import { IUpdateSimosCustomerContactSuccessAction } from "../updateSimosCustomerContact/updateSimosCustomerContactActions";
import { IADD_SIMOS_CUSTOMER_CONTACT_REQUEST } from "../addSimosCustomerContact/addSimosCustomerContactConstaints";
import { IUPDATE_SIMOS_CUSTOMER_CONTACT_REQUEST } from "../updateSimosCustomerContact/updateSimosCustomerContactConstaints";

type Actions =
    | IGetSimosCustomerContactsLoadAction
    | IGetSimosCustomerContactsSuccessAction
    | IGetSimosCustomerContactsLoadFailedAction
    | IAddSimosCustomerContactSuccessAction
    | IUpdateSimosCustomerContactSuccessAction
    | IFlushDataSuccessAction;

export const GetSimosCustomerContactsReducer = (state: Server<SimosAPIResponse<ISimosCustomerContacts[]>> = notLoaded, action: Actions): Server<SimosAPIResponse<ISimosCustomerContacts[]>> => {
    switch (action.type) {
        case IGET_SIMOS_CUSTOMER_CONTACTS_REQUEST.REQUEST:
            return loading;

        case IGET_SIMOS_CUSTOMER_CONTACTS_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_SIMOS_CUSTOMER_CONTACTS_REQUEST.FAILED:
            return failed(action.message);

        case IADD_SIMOS_CUSTOMER_CONTACT_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_SIMOS_CUSTOMER_CONTACT_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};