import { IWashPlantProductionRecords } from "../getWashPlantProductionRecords/getWashPlantProductionRecordsConstaints";
import { IUpdateWashPlantProductionRequest, IUPDATE_WASH_PLANT_PRODUCTION_REQUEST } from "./updateWashPlantProductionConstaints";

export interface IUpdateWashPlantProductionLoadAction {
    type: IUPDATE_WASH_PLANT_PRODUCTION_REQUEST.REQUEST;
    data: IUpdateWashPlantProductionRequest
}
export const updateWashPlantProductionLoadAction = (data: IUpdateWashPlantProductionRequest): IUpdateWashPlantProductionLoadAction => ({
    type: IUPDATE_WASH_PLANT_PRODUCTION_REQUEST.REQUEST,
    data
});

export interface IUpdateWashPlantProductionSuccessAction {
    type: IUPDATE_WASH_PLANT_PRODUCTION_REQUEST.SUCCESS;
    data: IWashPlantProductionRecords;
}
export const updateWashPlantProductionLoadSuccessAction = (data: IWashPlantProductionRecords): IUpdateWashPlantProductionSuccessAction => ({
    type: IUPDATE_WASH_PLANT_PRODUCTION_REQUEST.SUCCESS,
    data
});
export interface IUpdateWashPlantProductionLoadFailedAction {
    type: IUPDATE_WASH_PLANT_PRODUCTION_REQUEST.FAILED;
    message: string;
}
export const updateWashPlantProductionLoadFailedAction = (message: string): IUpdateWashPlantProductionLoadFailedAction => ({
    type: IUPDATE_WASH_PLANT_PRODUCTION_REQUEST.FAILED,
    message
});
