import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, isSucceeded, Server } from "../../../../redux/server";
import { ById, SilAPIResponse } from "../../../shared/publicInterfaces";
import { userName, undefinedFunction, monthNames, getYearsList, yesOrNoOptions, deleteText } from "../../../shared/constExports";
import { LAButton, LAIconButton, LASaveAndCancelButton } from "../../../shared/buttons";
import { ArrowLeftIcon, DeleteIcon } from "../../../shared/icons";
import { WHITE_COLOR } from "../../../shared/theme";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import LATextField from "../../../shared/textField";
import LAAutoComplete from "../../../shared/autoComplete";
import { ROUTE } from "../../../routes";
import queryString from "query-string";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import { FieldValidator, FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../../shared/fieldValidator";
import PageSpacing from "../../../shared/pageSpacing";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import SilRoleCheck from "../../../shared/silRoleCheck";
import { IGetCraneRequest, ICrane } from "../../../../redux/sil/cv/cranes/getCranes/getCranesConstaints";
import { IAddCraneRequest } from "../../../../redux/sil/cv/cranes/addCrane/addCraneConstaints";
import { IUpdateCraneRequest } from "../../../../redux/sil/cv/cranes/updateCrane/updateCraneConstaints";
import { getCraneStatus } from "../../../../redux/sil/cv/cranes/getCranes/getCranesAccessor";
import { addCraneStatus } from "../../../../redux/sil/cv/cranes/addCrane/addCraneAccessor";
import { updateCraneStatus } from "../../../../redux/sil/cv/cranes/updateCrane/updateCraneAccessor";
import { getCraneLoadAction } from "../../../../redux/sil/cv/cranes/getCranes/getCranesActions";
import { addCraneLoadAction } from "../../../../redux/sil/cv/cranes/addCrane/addCraneActions";
import { updateCraneLoadAction } from "../../../../redux/sil/cv/cranes/updateCrane/updateCraneActions";
import { IIdName } from "../../../../utils/sharedTypes";
import { LADropDone } from "../../../shared/dragDropFiles";
import LALinkButton from "../../../shared/linkButton";
import { END_POINTS } from "../../../../redux/endpoints";


const scaleList: IIdName<number>[] = [
    { id: 0, name: "BR A1/A2" },
    { id: 1, name: "BR Truck In" },
    { id: 2, name: "BR Truck Out" },
    { id: 3, name: "BR X4" },
    { id: 4, name: "BR Warehouse" },
    { id: 5, name: "Fox Creek" },
    { id: 6, name: "Lloyd" },
    { id: 7, name: "Marlboro" },
    { id: 8, name: "Netherhill" }
];

interface ICraneStoreProps {
    addCrane: Server<string>;
    updateCrane: Server<string>;
    getToken: Server<SilAPIResponse<IToken>>;
    getCranes: Server<SilAPIResponse<ICrane[]>>;
};

interface ICraneDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getCranesRequest: (data: IGetCraneRequest) => unknown;
    addCraneRequest: (data: IAddCraneRequest) => unknown;
    updateCraneRequest: (data: IUpdateCraneRequest) => unknown;
};


interface ICraneOwnProps {

};

interface ICraneState {
    crane: ICrane;
    errors: ById<IFieldErrorKeyValue>;
};

const CraneStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
`;

type ICraneProps = RouteComponentProps
    & ICraneStoreProps
    & ICraneDispatchProps
    & ICraneOwnProps;

class Crane extends PureComponent<ICraneProps, ICraneState> {

    public constructor(props: ICraneProps) {
        super(props);
        this.state = {
            errors: {
                "crane": { key: "plant", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "month": { key: "month", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "year": { key: "year", message: FIELD_VALIDATOR_ERRORS.REQUIRED }
            },
            crane: {
                id: 0,
                crane: "",
                completed: yesOrNoOptions[0].name,
                attachments: "",
                attachmentContent: [],
                created: "",
                created_By: userName,
                modified: "",
                modified_By: userName
            }
        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: ICraneProps): void {
        if (this.props !== prevProps) {
            this.callServer();

            if (this.props.addCrane !== prevProps.addCrane) {
                if (isSucceeded(this.props.addCrane)) {
                    this.handleCancel();
                };
            };

            if (this.props.updateCrane !== prevProps.updateCrane) {
                if (isSucceeded(this.props.updateCrane)) {
                    this.handleCancel();
                };
            };
        };
    };


    public render(): ReactNode {

        const { updateCrane, addCrane } = this.props;
        const { crane, errors } = this.state;

        const isAdd = crane.id > 0 ? false : true;
        const years = getYearsList(2018);

        return (
            <PageSpacing title="SIL - CV" description="Crane" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="carrierAccess">
                    <CraneStyles>

                        <LAButton startIcon={<ArrowLeftIcon color={WHITE_COLOR} />} label="Back to list" onClick={this.handleCancel} />
                        <h2 className="text-center">{!isAdd ? "VIEW " : "ADD "} CRANE</h2>
                        <hr />

                        <LAPaperWithPadding>
                            <LAGrid spacing={3}>

                                <LAGridItem xs={4}>
                                <LAAutoComplete
                                        filterSelectedOptions={true}
                                        autoHighlight={true}
                                        selectionRemove={undefinedFunction}
                                        dropDownPlaceHolder="Month"
                                        getOptionLabel="name"
                                        name="month"
                                        option={monthNames}
                                        multiple={false}
                                        onChange={this.handleDropDownIdChange}
                                        value={crane.month ? monthNames.find(x => x.id === crane.month) : ""}
                                        defaultValue={crane.month ? monthNames.find(x => x.id === crane.month) : ""}
                                        errorText={errors["month"] ? errors["month"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                <LAAutoComplete
                                        filterSelectedOptions={true}
                                        autoHighlight={true}
                                        selectionRemove={undefinedFunction}
                                        dropDownPlaceHolder="Year"
                                        getOptionLabel="name"
                                        name="year"
                                        option={years}
                                        multiple={false}
                                        onChange={this.handleDropDownIdChange}
                                        value={crane.year ? years.find(x => x.id === crane.year) : ""}
                                        defaultValue={crane.year ? years.find(x => x.id === crane.year) : ""}
                                        errorText={errors["year"] ? errors["year"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LAAutoComplete
                                        filterSelectedOptions={true}
                                        autoHighlight={true}
                                        selectionRemove={undefinedFunction}
                                        dropDownPlaceHolder="Scale"
                                        getOptionLabel="name"
                                        name="crane"
                                        option={scaleList}
                                        multiple={false}
                                        onChange={this.handleDropDownChange}
                                        value={scaleList.find(x => x.name === crane.crane)}
                                        defaultValue={scaleList.find(x => x.name === crane.crane)}
                                        errorText={errors["crane"] ? errors["crane"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LAAutoComplete
                                        filterSelectedOptions={true}
                                        autoHighlight={true}
                                        selectionRemove={undefinedFunction}
                                        dropDownPlaceHolder="Completed"
                                        getOptionLabel="name"
                                        name="completed"
                                        option={yesOrNoOptions}
                                        multiple={false}
                                        onChange={this.handleDropDownChange}
                                        value={yesOrNoOptions.find(x => x.name === crane.completed)}
                                        defaultValue={yesOrNoOptions.find(x => x.name === crane.completed)}
                                        errorText={errors["completed"] ? errors["completed"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={8}>
                                </LAGridItem>
                                
                                <LAGridItem xs={6} className="text-center">
                                    <strong>Calibration Form</strong>
                                    <LADropDone onFileDrop={(e: Array<File | DataTransferItem>) => this.handleAtt(e)} />
                                    {crane.attachments && crane.attachments.length > 0 && <LALinkButton className="pl-5" label={crane.attachments} onClick={this.handleDownloadAtt} />}
                                    {crane.attachments && <LAIconButton
                                        name="attachment_Name"
                                        label="Remove"
                                        icon={<DeleteIcon />}
                                        onClick={this.handleDeleteAtt}
                                    />}
                                </LAGridItem>

                                <LAGridItem xs={12}>
                                    <hr />
                                    <LASaveAndCancelButton
                                        onSave={this.onSave}
                                        onCancel={this.handleCancel}
                                        disableSave={(Object.values(errors).length > 0 ? true : undefined)}
                                    />
                                </LAGridItem>

                            </LAGrid>
                        </LAPaperWithPadding>

                        <RequestStatus requestStatus={addCrane.kind} successMessage="Crane successfully saved" />
                        <RequestStatus requestStatus={updateCrane.kind} successMessage="Crane successfully updated" />
                    </CraneStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private handleDeleteAtt = (): void => {
        const selection = window.confirm(deleteText);
        if(selection) {
            this.setState({
                crane: {
                    ...this.state.crane,
                    attachments: "",
                    attachmentContent: []
                }
            });
        }
    };

    private handleDownloadAtt = (): void => {
        const { crane } = this.state;
        if (crane.attachments) {
            if ((crane.attachmentContent !== null) && (crane.attachmentContent[0] !== undefined) 
            && (crane.attachmentContent[0].Base64String !== undefined)) {
                let a = document.createElement("a");
                a.href = crane.attachmentContent[0].Base64String;
                a.download = crane.attachments;
                a.click();
            };

            if ((crane.attachmentContent === null) || (crane.attachmentContent[0] === undefined)) {
                if (hasPayload(this.props.getToken) && crane.id) {

                    fetch(END_POINTS.PROPSENSE.SIL.CV.GetCVFile, {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            request: {
                                "ID": crane.id,
                                "File": crane.attachments,
                                "Page": "Crane"
                            },
                            token: this.props.getToken.payload.response.token
                        })
                    })
                        .then((res: any) => {
                            return res.blob();
                        })
                        .then(blob => {
                            if (crane.attachments) {
                                let url = window.URL.createObjectURL(blob);
                                let a = document.createElement('a');
                                a.href = url;
                                a.download = crane.attachments;
                                document.body.appendChild(a);
                                a.click();
                                a.remove();
                            }
                        })
                        .catch((res) => console.log(res));
                };
            };
        }
    };

    private handleAtt = (files: Array<File | DataTransferItem>): void => {
        new Promise((resolve, reject): any => {
            if (files && (files !== null)) {

                Object.values(files).forEach((x: any, index, array) => {
                    const reader = new FileReader();

                    reader.onloadend = async (e): Promise<void> => {
                        if (reader.result !== null) {
                            this.setState({
                                ...this.state,
                                crane: {
                                    ...this.state.crane,
                                    attachments: x.name,
                                    attachmentContent: [{
                                        Name: x.name,
                                        Base64String: reader.result.toString()
                                    }]
                                },
                            });
                            resolve(true);
                        };
                    };
                    reader.readAsDataURL(x);
                })
            } else {
                reject();
            }
        });
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, isRequired: boolean): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: isRequired ? true : undefined, minLength: 1, decimal: undefined });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        };
        return err;
    };

    private handleCancel = (): void => {
        this.props.history.push(ROUTE.SIL.CV.CRANES.INDEX);
    };

    private onSave = (): void => {
        const { crane } = this.state;

        if (hasPayload(this.props.getToken))
            if (crane.id === 0) {
                this.props.addCraneRequest({
                    token: this.props.getToken.payload.response.token,
                    request: crane
                });
            } else {
                crane.modified_By = userName;
                
                this.props.updateCraneRequest({
                    token: this.props.getToken.payload.response.token,
                    request: crane
                });
            };
    };

    private handleDropDownChange = (event: unknown, value: { id: number, name: string } | "", name?: string): void => {
        if (name){
            let errors = this.state.errors;
            errors = this.errorChecker(name, value !== "" ? value.name : "", errors, true);

            this.setState({
                ...this.state,
                errors,
                crane: {
                    ...this.state.crane,
                    [name]: value !== "" ? value.name : ""
                }
            });
        }
    };

    private handleDropDownIdChange = (event: unknown, value: { id: number, name: string } | "", name?: string): void => {
        if (name){
            let errors = this.state.errors;
            errors = this.errorChecker(name, value !== "" ? value.name : "", errors, true);

            this.setState({
                ...this.state,
                errors,
                crane: {
                    ...this.state.crane,
                    [name]: value !== "" ? value.id : undefined
                }
            });
        }
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getCranes))
            this.props.getCranesRequest({
                token: this.props.getToken.payload.response.token
            });


        const query = queryString.parse(this.props.location.search);
        const id = query.id ?? "0";

        if ((id !== "0") && (hasPayload(this.props.getCranes))) {
            const crane = this.props.getCranes.payload.response.find(x => x.id === Number(id));

            if(crane)
                this.setState({ crane, errors: {} });
        };

    };

}

const mapStateToProps = (state: IStore): ICraneStoreProps => ({
    getToken: getTokenStatus(state),
    getCranes: getCraneStatus(state),
    addCrane: addCraneStatus(state),
    updateCrane: updateCraneStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): ICraneDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getCranesRequest: (data: IGetCraneRequest) => dispatch(getCraneLoadAction(data)),
    addCraneRequest: (data: IAddCraneRequest) => dispatch(addCraneLoadAction(data)),
    updateCraneRequest: (data: IUpdateCraneRequest) => dispatch(updateCraneLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Crane);