import { IADD_SIMOS_CUSTOMER_REQUEST } from "./addSimosCustomerConstaints";
import { IAddSimosCustomerLoadAction, IAddSimosCustomerLoadFailedAction, IAddSimosCustomerSuccessAction } from "./addSimosCustomerActions";
import { failed, loading, notLoaded, Server, succeeded } from "../../../../server";
import { IFlushDataSuccessAction } from '../../../../flushData.tsx/flushDataActions';
import { FLUSH_DATA } from '../../../../flushData.tsx/flushDataConstants';

type Actions =
    IAddSimosCustomerLoadAction
    | IAddSimosCustomerLoadFailedAction
    | IAddSimosCustomerSuccessAction
    | IFlushDataSuccessAction;

export const AddSimosCustomerReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IADD_SIMOS_CUSTOMER_REQUEST.REQUEST:
            return loading;

        case IADD_SIMOS_CUSTOMER_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IADD_SIMOS_CUSTOMER_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};