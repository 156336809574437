import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import { ById, SilAPIResponse } from "../../../shared/publicInterfaces";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import { callRouteWithQueryString, userName } from "../../../shared/constExports";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { IWashPlantDownTime, IGetWashPlantProductionRecordsRequest, IWashPlantProductionData, IWashPlantProductionRecords } from "../../../../redux/sil/washPlant/getWashPlantProductionRecords/getWashPlantProductionRecordsConstaints";
import { getWashPlantProductionRecordsLoadAction } from "../../../../redux/sil/washPlant/getWashPlantProductionRecords/getWashPlantProductionRecordsActions";
import { getWashPlantProductionRecordsStatus } from "../../../../redux/sil/washPlant/getWashPlantProductionRecords/getWashPlantProductionRecordsAccessor";
import { ROUTE } from "../../../routes";
import { IGetWashPlantProductionLookupsRequest, IWashPlantProductionLookups } from "../../../../redux/sil/washPlant/getWashPlantProductionLookups/getWashPlantProductionLookupsConstaints";
import { getWashPlantProductionLookupsLoadAction } from "../../../../redux/sil/washPlant/getWashPlantProductionLookups/getWashPlantProductionLookupsActions";
import { getWashPlantProductionLookupsStatus } from "../../../../redux/sil/washPlant/getWashPlantProductionLookups/getWashPlantProductionLookupsAccessor";
import PageSpacing from "../../../shared/pageSpacing";
import { DowntimeDataPopup, ProductionDataPopup } from "./productionPopups";
import { getWashPlantProductionSummaryStatus } from "../../../../redux/sil/washPlant/getWashPlantProductionSummary/getWashPlantProductionSummaryAccessor";
import { getWashPlantProductionSummaryLoadAction } from "../../../../redux/sil/washPlant/getWashPlantProductionSummary/getWashPlantProductionSummaryActions";
import { IGetWashPlantProductionSummaryRequest, IWashPlantProductionSummary } from "../../../../redux/sil/washPlant/getWashPlantProductionSummary/getWashPlantProductionSummaryConstants";
import LALoading from "../../../shared/loading";
import LAErrorBox from "../../../shared/errorBox";
import SilRoleCheck from "../../../shared/silRoleCheck";
import { RadioGroup, FormControlLabel, Radio } from "@material-ui/core";


interface IWashPlantItemsStoreProps {
    getToken: Server<SilAPIResponse<IToken>>;
    getItems: Server<SilAPIResponse<ById<IWashPlantProductionRecords>>>;
    getDropDownData: Server<SilAPIResponse<IWashPlantProductionLookups>>;
    getProductionSummary: Server<SilAPIResponse<IWashPlantProductionSummary[]>>;
};

interface IWashPlantItemsDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getItemsRequest: (data: IGetWashPlantProductionRecordsRequest) => unknown;
    getProductionSummaryRequest: (data: IGetWashPlantProductionSummaryRequest) => unknown;
    getDropDownDataRequest: (data: IGetWashPlantProductionLookupsRequest) => unknown;
};


interface IWashPlantItemsOwnProps {

};

interface IWashPlantItemsState {
    view: string;
    downtimePopup: IWashPlantDownTime[] | undefined;
    productionPopup: IWashPlantProductionData[] | undefined;
};

const WashPlantItemsStyles = styled(LAPaperWithPadding)`
 
    word-break: break-word;

    .pull-left {
        top: 2%;
        left: 3%;
        height: 3%;
        position: absolute;
    };

    .pro-popup {
        display: none;
    };
    
   
    .pro-item:hover .pro-popup  {
        display: block;
        position: fixed;
        z-index: 20;
        left: 20%;
        top: 30%;
        width: 30%;
        height: auto;
        overflow: auto;
    };
`;

type IWashPlantItemsProps = RouteComponentProps
    & IWashPlantItemsStoreProps
    & IWashPlantItemsDispatchProps
    & IWashPlantItemsOwnProps;

class WashPlantItems extends PureComponent<IWashPlantItemsProps, IWashPlantItemsState> {

    public constructor(props: IWashPlantItemsProps) {
        super(props);
        this.state = {
            view: "Latest",
            downtimePopup: undefined,
            productionPopup: undefined
        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: IWashPlantItemsProps): void {
        if (this.props !== prevProps)
            this.callServer();
    };


    public render(): ReactNode {

        const { getItems, getProductionSummary } = this.props;
        const { downtimePopup, productionPopup, view } = this.state;
        const data = hasPayload(getItems) ? Object.values(getItems.payload.response) : [];
        const summary = hasPayload(getProductionSummary) ? getProductionSummary.payload.response : [];

        return (
            <PageSpacing title="SIL - Wash Plant Items" description="SIL - Wash Plant Items" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="washPlantAccess">
                    <WashPlantItemsStyles>

                        <h2 className="text-center">WASH PLANT PRODUCTION RUNS</h2>
                        <RadioGroup row aria-label="" name="radioGroup" value={view} onChange={this.handleRadioClick}>
                            <FormControlLabel value="Latest" control={<Radio />} label="Latest (Last 7 Days)" />
                            <FormControlLabel value="All" control={<Radio />} label="All" />
                        </RadioGroup>
                        <hr />

                        {getItems.kind === STATUS_ENUM.LOADING && <LALoading message="Loading Production Data" />}

                        {getItems.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load data.. Please refresh window or contact IT" />}

                        {getItems.kind === STATUS_ENUM.SUCCEEDED && <LADevExtremeGrid
                            data={data}
                            onEdit={this.onEdit}
                            onClick={this.onClick}
                            searchPanel={true}
                            filterHeader={true}
                            height={1200}
                            export={true}
                            onCustomClick={this.handleCustom}
                            exportFileName={"Wash Plant Productions"}
                            storageKey="silWashPlantProductionRunsStorageKey"
                            columns={[
                                { name: "date", caption: "Date", type: "date", width: 130, sortDesc: true },
                                { name: "shift_Worked", caption: "Shift Worked", type: "string", width: 80 },
                                { name: "plants", caption: "Plants", type: "string", width: 100 },
                                { name: "washplant_Operator", caption: "Washplant Operator", type: "string", width: 130 },
                                { name: "load_Operator1", caption: "Load Operator1", type: "string", width: 100 },
                                { name: "load_Operator2", caption: "Load Operator2", type: "string", width: 100 },
                                { name: "flocculant_Operator", caption: "Flocculant Operator", type: "string", width: 100 },
                                { name: "cleanup_Operator", caption: "Cleanup Operator", type: "string", width: 100 },
                                { name: "production", caption: "Production Data", type: "button", width: 150 },
                                { name: "downtime", caption: "DownTime", type: "button", width: 150 },
                                { name: "production_Shift_Goal", caption: "Production Shift Goal", type: "string", width: 150 },
                                { name: "production_Notes", caption: "Production Notes", type: "string" },
                                { name: "qA_QC_Notes", caption: "QA QC Notes", type: "string", width: 150 }
                            ]}
                        />}

                    </WashPlantItemsStyles>

                    {productionPopup &&
                        <ProductionDataPopup
                            summary={summary}
                            data={productionPopup}
                            onClose={this.handlePopupClose}
                            open={productionPopup ? true : false}
                        />}

                    {downtimePopup &&
                        <DowntimeDataPopup
                            data={downtimePopup}
                            onClose={this.handlePopupClose}
                            open={downtimePopup ? true : false}
                        />}
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private handleRadioClick = async (event: React.ChangeEvent<HTMLInputElement>, view: string): Promise<void> => {
        await this.setState({ view });

        if (hasPayload(this.props.getToken))
            this.props.getItemsRequest({
                request: {
                    view: this.state.view,
                },
                token: this.props.getToken.payload.response.token
            });
    };

    private handlePopupClose = (): void => {
        this.setState({ productionPopup: undefined, downtimePopup: undefined });
    };

    private handleCustom = (name: string, column: any): void => {
        const data: IWashPlantProductionRecords = column.row.data;
        switch (name) {
            case "production":
                if (hasPayload(this.props.getToken))
                    this.props.getProductionSummaryRequest({
                        token: this.props.getToken.payload.response.token,
                        request: {
                            itemId: data.id
                        }
                    });
                this.setState({ productionPopup: data.production_Data });
                break;

            case "downtime":
                this.setState({ downtimePopup: data.downtimes });
                break;
        }
    };

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString() },
            pathName: ROUTE.SIL.WASH_PLANT.WASH_PLANT_ITEM
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0" },
            pathName: ROUTE.SIL.WASH_PLANT.WASH_PLANT_ITEM
        });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getDropDownData))
            this.props.getDropDownDataRequest({
                token: this.props.getToken.payload.response.token,
                requested_Page: "Washplant"
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getItems))
            this.props.getItemsRequest({
                request: {
                    view: this.state.view,
                },
                token: this.props.getToken.payload.response.token
            });
    }
}

const mapStateToProps = (state: IStore): IWashPlantItemsStoreProps => ({
    getToken: getTokenStatus(state),
    getItems: getWashPlantProductionRecordsStatus(state),
    getDropDownData: getWashPlantProductionLookupsStatus(state),
    getProductionSummary: getWashPlantProductionSummaryStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IWashPlantItemsDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getItemsRequest: (data: IGetWashPlantProductionRecordsRequest) => dispatch(getWashPlantProductionRecordsLoadAction(data)),
    getProductionSummaryRequest: (data: IGetWashPlantProductionSummaryRequest) => dispatch(getWashPlantProductionSummaryLoadAction(data)),
    getDropDownDataRequest: (data: IGetWashPlantProductionLookupsRequest) => dispatch(getWashPlantProductionLookupsLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WashPlantItems);