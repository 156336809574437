import { ById, SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetWashPlantProductionRecordsRequest, IGET_WASH_PLANT_PRODUCTION_RECORDS_REQUEST, IWashPlantProductionRecords } from "./getWashPlantProductionRecordsConstaints";

export interface IGetWashPlantProductionRecordsLoadAction {
    type: IGET_WASH_PLANT_PRODUCTION_RECORDS_REQUEST.REQUEST;
    data: IGetWashPlantProductionRecordsRequest
}
export const getWashPlantProductionRecordsLoadAction = (data: IGetWashPlantProductionRecordsRequest): IGetWashPlantProductionRecordsLoadAction => ({
    type: IGET_WASH_PLANT_PRODUCTION_RECORDS_REQUEST.REQUEST,
    data
});

export interface IGetWashPlantProductionRecordsSuccessAction {
    type: IGET_WASH_PLANT_PRODUCTION_RECORDS_REQUEST.SUCCESS;
    data: SilAPIResponse<ById<IWashPlantProductionRecords>>;
}
export const getWashPlantProductionRecordsLoadSuccessAction = (data: SilAPIResponse<ById<IWashPlantProductionRecords>>): IGetWashPlantProductionRecordsSuccessAction => ({
    type: IGET_WASH_PLANT_PRODUCTION_RECORDS_REQUEST.SUCCESS,
    data
});

export interface IGetWashPlantProductionRecordsLoadFailedAction {
    type: IGET_WASH_PLANT_PRODUCTION_RECORDS_REQUEST.FAILED;
    message: string;
}
export const getWashPlantProductionRecordsLoadFailedAction = (message: string): IGetWashPlantProductionRecordsLoadFailedAction => ({
    type: IGET_WASH_PLANT_PRODUCTION_RECORDS_REQUEST.FAILED,
    message
});
