import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetProductionLookupsRequest, IGET_PRODUCTION_LOOKUPS_REQUEST, IProductionLookups } from "./getProductionLookupsConstaints";

export interface IGetProductionLookupsLoadAction {
    type: IGET_PRODUCTION_LOOKUPS_REQUEST.REQUEST;
    data: IGetProductionLookupsRequest
}
export const getProductionLookupsLoadAction = (data: IGetProductionLookupsRequest): IGetProductionLookupsLoadAction => ({
    type: IGET_PRODUCTION_LOOKUPS_REQUEST.REQUEST,
    data
});

export interface IGetProductionLookupsSuccessAction {
    type: IGET_PRODUCTION_LOOKUPS_REQUEST.SUCCESS;
    data: SilAPIResponse<IProductionLookups>;
}
export const getProductionLookupsLoadSuccessAction = (data: SilAPIResponse<IProductionLookups>): IGetProductionLookupsSuccessAction => ({
    type: IGET_PRODUCTION_LOOKUPS_REQUEST.SUCCESS,
    data
});

export interface IGetProductionLookupsLoadFailedAction {
    type: IGET_PRODUCTION_LOOKUPS_REQUEST.FAILED;
    message: string;
}
export const getProductionLookupsLoadFailedAction = (message: string): IGetProductionLookupsLoadFailedAction => ({
    type: IGET_PRODUCTION_LOOKUPS_REQUEST.FAILED,
    message
});
