import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IUpdateFeedMaterialLoadAction, IUpdateFeedMaterialLoadFailedAction, IUpdateFeedMaterialSuccessAction, updateFeedMaterialLoadFailedAction,
    updateFeedMaterialLoadSuccessAction
} from "./updateFeedMaterialActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IUPDATE_FEED_MATERIAL_REQUEST } from "./updateFeedMaterialConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";

export const updateFeedMaterialEpic: Epic = (
    action$: ActionsObservable<IUpdateFeedMaterialLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IUpdateFeedMaterialSuccessAction | IUpdateFeedMaterialLoadFailedAction> =>
    action$.ofType(IUPDATE_FEED_MATERIAL_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<string>>(
                    END_POINTS.PROPSENSE.SIL.DRY_PLANT.UPDATE_DRY_PLANT_FEED_MATERIALS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<string>): IUpdateFeedMaterialSuccessAction | IUpdateFeedMaterialLoadFailedAction => {

                            if (response.message === "Success") {
                                return updateFeedMaterialLoadSuccessAction(action.data.request);
                            };
                            return updateFeedMaterialLoadFailedAction(response.message);
                        }),
                        catchError(() => ActionsObservable.of(updateFeedMaterialLoadFailedAction("Unable to save your changes")))
                    )
            )
        );