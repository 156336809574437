import { IFlushDataSuccessAction } from "../../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../../flushData.tsx/flushDataConstants";
import { Server, failed, loading, notLoaded, succeeded } from "../../../../server";
import { IAddWarehouseLoadAction, IAddWarehouseLoadFailedAction, IAddWarehouseSuccessAction } from "./addWarehouseActions";
import { IADD_WAREHOUSE_REQUEST } from "./addWarehouseConstaints";


type Actions =
    IAddWarehouseLoadAction
    | IAddWarehouseLoadFailedAction
    | IAddWarehouseSuccessAction
    | IFlushDataSuccessAction;

export const AddWarehouseReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IADD_WAREHOUSE_REQUEST.REQUEST:
            return loading;

        case IADD_WAREHOUSE_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IADD_WAREHOUSE_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};