import { IWashPlantRunMaterials } from "../getWashPlantRunMaterials/getWashPlantRunMaterialsConstaints";
import { IUpdateWashPlantRunMaterialRequest, IUPDATE_WASH_PLANT_RUN_MATERIAL_REQUEST } from "./updateWashPlantRunMaterialConstaints";

export interface IUpdateWashPlantRunMaterialLoadAction {
    type: IUPDATE_WASH_PLANT_RUN_MATERIAL_REQUEST.REQUEST;
    data: IUpdateWashPlantRunMaterialRequest
}
export const updateWashPlantRunMaterialLoadAction = (data: IUpdateWashPlantRunMaterialRequest): IUpdateWashPlantRunMaterialLoadAction => ({
    type: IUPDATE_WASH_PLANT_RUN_MATERIAL_REQUEST.REQUEST,
    data
});

export interface IUpdateWashPlantRunMaterialSuccessAction {
    type: IUPDATE_WASH_PLANT_RUN_MATERIAL_REQUEST.SUCCESS;
    data: IWashPlantRunMaterials;
}
export const updateWashPlantRunMaterialLoadSuccessAction = (data: IWashPlantRunMaterials): IUpdateWashPlantRunMaterialSuccessAction => ({
    type: IUPDATE_WASH_PLANT_RUN_MATERIAL_REQUEST.SUCCESS,
    data
});
export interface IUpdateWashPlantRunMaterialLoadFailedAction {
    type: IUPDATE_WASH_PLANT_RUN_MATERIAL_REQUEST.FAILED;
    message: string;
}
export const updateWashPlantRunMaterialLoadFailedAction = (message: string): IUpdateWashPlantRunMaterialLoadFailedAction => ({
    type: IUPDATE_WASH_PLANT_RUN_MATERIAL_REQUEST.FAILED,
    message
});
