

export default function urlContainsSILParameter(){
    return window.location.href.includes("sil_bru") || window.location.href.includes("sil_ed");
}

export function getSILURLParams(){
    if (window.location.href.includes("sil_bru")){
        return "?sil_bru";
    }
    if(window.location.href.includes("sil_ed")){
        return "?sil_ed";
    } 
}

export function redirectSIMOSHome(){
    alert("Error accessing SIL Bru/Ed orders, redirecting to SIMOS landing page");
    window.location.replace('/simos');
}