import { SimosAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { IGET_SIMOS_CUSTOMERS_REQUEST, ISimosCustomers, ISimosCustomersRequest } from "./getSimosCustomersConstants";


export interface IGetSimosCustomersLoadAction {
    type: IGET_SIMOS_CUSTOMERS_REQUEST.REQUEST;
    data: ISimosCustomersRequest
}
export const getSimosCustomersLoadAction = (data: ISimosCustomersRequest): IGetSimosCustomersLoadAction => ({
    type: IGET_SIMOS_CUSTOMERS_REQUEST.REQUEST,
    data
});
export interface IGetSimosCustomersSuccessAction {
    type: IGET_SIMOS_CUSTOMERS_REQUEST.SUCCESS;
    data: SimosAPIResponse<ISimosCustomers[]>;
}
export const getSimosCustomersLoadSuccessAction = (data: SimosAPIResponse<ISimosCustomers[]>): IGetSimosCustomersSuccessAction => ({
    type: IGET_SIMOS_CUSTOMERS_REQUEST.SUCCESS,
    data
});
export interface IGetSimosCustomersLoadFailedAction {
    type: IGET_SIMOS_CUSTOMERS_REQUEST.FAILED;
    message: string;
}
export const getSimosCustomersLoadFailedAction = (message: string): IGetSimosCustomersLoadFailedAction => ({
    type: IGET_SIMOS_CUSTOMERS_REQUEST.FAILED,
    message
});
