import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IGetYHDCarrierLookupsLoadAction, IGetYHDCarrierLookupsLoadFailedAction, IGetYHDCarrierLookupsSuccessAction, 
    getYHDCarrierLookupsLoadFailedAction, getYHDCarrierLookupsLoadSuccessAction
} from "./getCarrierLookupsActions";
import { IStore } from "../../../../reducers";
import { EpicDependencies } from "../../../../store";
import { IGET_YHD_CARRIER_LOOKUPS_REQUEST, IYHDCarrierLookup } from "./getCarrierLookupsConstaints";
import { END_POINTS } from "../../../../endpoints";
import { withJsonContentType } from "../../../../epicUtils";
import { YHDAPIResponse } from "../../../../../react/shared/publicInterfaces";

export const getYHDCarrierLookupsEpic: Epic = (
    action$: ActionsObservable<IGetYHDCarrierLookupsLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IGetYHDCarrierLookupsSuccessAction | IGetYHDCarrierLookupsLoadFailedAction> =>
    action$.ofType(IGET_YHD_CARRIER_LOOKUPS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<YHDAPIResponse<IYHDCarrierLookup[]>>(
                    END_POINTS.PROPSENSE.YHD.CARRIER.GET_CARRIER_LOOKUPS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: YHDAPIResponse<IYHDCarrierLookup[]>): IGetYHDCarrierLookupsSuccessAction => {
                            return getYHDCarrierLookupsLoadSuccessAction(response);
                        }),
                        catchError(() => ActionsObservable.of(getYHDCarrierLookupsLoadFailedAction("Unable to get carriers")))
                    )
            )
        );