import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IGetWashPlantFeedMaterialsLoadAction, IGetWashPlantFeedMaterialsLoadFailedAction, IGetWashPlantFeedMaterialsSuccessAction, 
    getWashPlantFeedMaterialsLoadFailedAction, getWashPlantFeedMaterialsLoadSuccessAction
} from "./getWashPlantFeedMaterialsActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IGET_WASH_PLANT_FEED_MATERIALS_REQUEST, IWashPlantFeedMaterials } from "./getWashPlantFeedMaterialsConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";

export const getWashPlantFeedMaterialsEpic: Epic = (
    action$: ActionsObservable<IGetWashPlantFeedMaterialsLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IGetWashPlantFeedMaterialsSuccessAction | IGetWashPlantFeedMaterialsLoadFailedAction> =>
    action$.ofType(IGET_WASH_PLANT_FEED_MATERIALS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<IWashPlantFeedMaterials[]>>(
                    END_POINTS.PROPSENSE.SIL.WASH_PLANT.GET_WASH_PLANT_FEED_MATERIALS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<IWashPlantFeedMaterials[]>): IGetWashPlantFeedMaterialsSuccessAction => {
                            return getWashPlantFeedMaterialsLoadSuccessAction(response);
                        }),
                        catchError(() => ActionsObservable.of(getWashPlantFeedMaterialsLoadFailedAction("Unable to getWashPlant feed materials")))
                    )
            )
        );