import { IAddTrailerActivityLogRequest, IADD_TRAILER_ACTIVITY_LOG_REQUEST } from "./addTrailerActivityLogConstaints";

export interface IAddTrailerActivityLogLoadAction {
    type: IADD_TRAILER_ACTIVITY_LOG_REQUEST.REQUEST;
    data: IAddTrailerActivityLogRequest
}
export const addTrailerActivityLogLoadAction = (data: IAddTrailerActivityLogRequest): IAddTrailerActivityLogLoadAction => ({
    type: IADD_TRAILER_ACTIVITY_LOG_REQUEST.REQUEST,
    data
});

export interface IAddTrailerActivityLogSuccessAction {
    type: IADD_TRAILER_ACTIVITY_LOG_REQUEST.SUCCESS;
    message: string;
}
export const addTrailerActivityLogLoadSuccessAction = (message: string): IAddTrailerActivityLogSuccessAction => ({
    type: IADD_TRAILER_ACTIVITY_LOG_REQUEST.SUCCESS,
    message
});

export interface IAddTrailerActivityLogLoadFailedAction {
    type: IADD_TRAILER_ACTIVITY_LOG_REQUEST.FAILED;
    message: string;
}
export const addTrailerActivityLogLoadFailedAction = (message: string): IAddTrailerActivityLogLoadFailedAction => ({
    type: IADD_TRAILER_ACTIVITY_LOG_REQUEST.FAILED,
    message
});
