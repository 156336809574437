import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IGET_SIMOS_TRUCKS_REQUEST, ISimosTrucks } from "./getSimosTrucksConstaints";
import { IGetSimosTrucksLoadAction, IGetSimosTrucksLoadFailedAction, IGetSimosTrucksSuccessAction } from "./getSimosTrucksActions";
import { SimosAPIResponse } from "../../../../react/shared/publicInterfaces";

type Actions =
    IGetSimosTrucksLoadAction
    | IGetSimosTrucksLoadFailedAction
    | IGetSimosTrucksSuccessAction
    | IFlushDataSuccessAction;

export const GetSimosTrucksReducer = (state: Server<SimosAPIResponse<ISimosTrucks[]>> = notLoaded, action: Actions): Server<SimosAPIResponse<ISimosTrucks[]>> => {
    switch (action.type) {
        case IGET_SIMOS_TRUCKS_REQUEST.REQUEST:
            return loading;

        case IGET_SIMOS_TRUCKS_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_SIMOS_TRUCKS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};