import { IAddSieveWashPlantRequest, IADD_SIEVE_WASH_PLANT_REQUEST } from "./addSieveWashPlantConstaints";

export interface IAddSieveWashPlantLoadAction {
    type: IADD_SIEVE_WASH_PLANT_REQUEST.REQUEST;
    data: IAddSieveWashPlantRequest
}
export const addSieveWashPlantLoadAction = (data: IAddSieveWashPlantRequest): IAddSieveWashPlantLoadAction => ({
    type: IADD_SIEVE_WASH_PLANT_REQUEST.REQUEST,
    data
});

export interface IAddSieveWashPlantSuccessAction {
    type: IADD_SIEVE_WASH_PLANT_REQUEST.SUCCESS;
    message: string;
}
export const addSieveWashPlantLoadSuccessAction = (message: string): IAddSieveWashPlantSuccessAction => ({
    type: IADD_SIEVE_WASH_PLANT_REQUEST.SUCCESS,
    message
});
export interface IAddSieveWashPlantLoadFailedAction {
    type: IADD_SIEVE_WASH_PLANT_REQUEST.FAILED;
    message: string;
}
export const addSieveWashPlantLoadFailedAction = (message: string): IAddSieveWashPlantLoadFailedAction => ({
    type: IADD_SIEVE_WASH_PLANT_REQUEST.FAILED,
    message
});
