


export default function deleteProductOrder(item:any){
    let requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type':'application/json'},
        body: JSON.stringify({
            request: {
                ID: item.id,
            },
            token: '19515195-d571-44a3-b0db-637f5bf24f54'})
    }
    fetch("https://api2.propsense.com/api/DeleteSIMOSOrderedByProduct", requestOptions)
    .then(response => {
        const data = response.headers.get('Content-Type')?.includes('application/json');
        // error response
        if(!response.ok) {
          const error = (data) || response.status;
          console.log("should not be here");
          return error;
      }
    })
}