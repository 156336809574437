import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetTokenRequest, IGET_TOKEN_REQUEST, IToken } from "./getTokenConstants";

export interface IGetTokenLoadAction {
    type: IGET_TOKEN_REQUEST.REQUEST;
    data: IGetTokenRequest
}
export const getTokenLoadAction = (data: IGetTokenRequest): IGetTokenLoadAction => ({
    type: IGET_TOKEN_REQUEST.REQUEST,
    data
});

export interface IGetTokenSuccessAction {
    type: IGET_TOKEN_REQUEST.SUCCESS;
    data: SilAPIResponse<IToken>;
}
export const getTokenLoadSuccessAction = (data: SilAPIResponse<IToken>): IGetTokenSuccessAction => ({
    type: IGET_TOKEN_REQUEST.SUCCESS,
    data
});

export interface IGetTokenLoadFailedAction {
    type: IGET_TOKEN_REQUEST.FAILED;
    message: string;
}
export const getTokenLoadFailedAction = (message: string): IGetTokenLoadFailedAction => ({
    type: IGET_TOKEN_REQUEST.FAILED,
    message
});
