import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IGetWashPlantProductionRecordByIdLoadAction, IGetWashPlantProductionRecordByIdLoadFailedAction, IGetWashPlantProductionRecordByIdSuccessAction, 
    getWashPlantProductionRecordByIdLoadFailedAction, getWashPlantProductionRecordByIdLoadSuccessAction
} from "./getWashPlantProductionRecordByIdActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IGET_WASH_PLANT_PRODUCTION_RECORDS_REQUEST } from "./getWashPlantProductionRecordByIdConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IWashPlantProductionRecords } from "../getWashPlantProductionRecords/getWashPlantProductionRecordsConstaints";

export const getWashPlantProductionRecordByIdEpic: Epic = (
    action$: ActionsObservable<IGetWashPlantProductionRecordByIdLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IGetWashPlantProductionRecordByIdSuccessAction | IGetWashPlantProductionRecordByIdLoadFailedAction> =>
    action$.ofType(IGET_WASH_PLANT_PRODUCTION_RECORDS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<IWashPlantProductionRecords>>(
                    END_POINTS.PROPSENSE.SIL.WASH_PLANT.GET_WASH_PLANT_PRODUCTION_RECORD_BY_ID,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<IWashPlantProductionRecords>): IGetWashPlantProductionRecordByIdSuccessAction => {
                            return getWashPlantProductionRecordByIdLoadSuccessAction(response);
                        }),
                        catchError(() => ActionsObservable.of(getWashPlantProductionRecordByIdLoadFailedAction("Unable to getWashPlant production records")))
                    )
            )
        );