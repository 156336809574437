import { PropsenseAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../../server";
import { IGetCrewsLoadAction, IGetCrewsLoadFailedAction, IGetCrewsSuccessAction } from "./getCrewsActions";
import { ICrew, IGET_CREWS_REQUEST } from "./getCrewsConstants";

type Actions =
    | IGetCrewsLoadAction
    | IGetCrewsSuccessAction
    | IGetCrewsLoadFailedAction
    | IFlushDataSuccessAction;

export const GetCrewsReducer = (state: Server<PropsenseAPIResponse<ICrew[]>> = notLoaded, action: Actions): Server<PropsenseAPIResponse<ICrew[]>> => {
    switch (action.type) {
        case IGET_CREWS_REQUEST.REQUEST:
            return loading;

        case IGET_CREWS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_CREWS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};