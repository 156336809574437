import { IUpdateCarrierFormRequest, IUPDATE_CARRIER_FORM_REQUEST } from "./updateCarrierFormConstaints";

export interface IUpdateCarrierFormLoadAction {
    type: IUPDATE_CARRIER_FORM_REQUEST.REQUEST;
    data: IUpdateCarrierFormRequest
}
export const updateCarrierFormLoadAction = (data: IUpdateCarrierFormRequest): IUpdateCarrierFormLoadAction => ({
    type: IUPDATE_CARRIER_FORM_REQUEST.REQUEST,
    data
});

export interface IUpdateCarrierFormSuccessAction {
    type: IUPDATE_CARRIER_FORM_REQUEST.SUCCESS;
    message: string;
}
export const updateCarrierFormLoadSuccessAction = (message: string): IUpdateCarrierFormSuccessAction => ({
    type: IUPDATE_CARRIER_FORM_REQUEST.SUCCESS,
    message
});
export interface IUpdateCarrierFormLoadFailedAction {
    type: IUPDATE_CARRIER_FORM_REQUEST.FAILED;
    message: string;
}
export const updateCarrierFormLoadFailedAction = (message: string): IUpdateCarrierFormLoadFailedAction => ({
    type: IUPDATE_CARRIER_FORM_REQUEST.FAILED,
    message
});
