import { IFlushDataSuccessAction } from "../../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../../flushData.tsx/flushDataConstants";
import { Server, failed, loading, notLoaded, succeeded } from "../../../../server";
import { IAddTruckScaleLoadAction, IAddTruckScaleLoadFailedAction, IAddTruckScaleSuccessAction } from "./addTruckScaleActions";
import { IADD_TRUCK_SCALE_REQUEST } from "./addTruckScaleConstaints";


type Actions =
    IAddTruckScaleLoadAction
    | IAddTruckScaleLoadFailedAction
    | IAddTruckScaleSuccessAction
    | IFlushDataSuccessAction;

export const AddTruckScaleReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IADD_TRUCK_SCALE_REQUEST.REQUEST:
            return loading;

        case IADD_TRUCK_SCALE_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IADD_TRUCK_SCALE_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};