import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IUpdateSieveCalibrationLoadAction, IUpdateSieveCalibrationLoadFailedAction, IUpdateSieveCalibrationSuccessAction, 
    updateSieveCalibrationLoadFailedAction, updateSieveCalibrationLoadSuccessAction
} from "./updateSieveCalibrationActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IUPDATE_SIEVE_CALIBRATION_REQUEST } from "./updateSieveCalibrationConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";

export const updateSieveCalibrationEpic: Epic = (
    action$: ActionsObservable<IUpdateSieveCalibrationLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IUpdateSieveCalibrationSuccessAction | IUpdateSieveCalibrationLoadFailedAction> =>
    action$.ofType(IUPDATE_SIEVE_CALIBRATION_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<string>>(
                    END_POINTS.PROPSENSE.SIL.SIEVES.UPDATE_SIEVES_CALIBRATION,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<string>): IUpdateSieveCalibrationSuccessAction | IUpdateSieveCalibrationLoadFailedAction => {
                            if(response.message === "Success"){
                                return updateSieveCalibrationLoadSuccessAction(response.message);
                            };
                            return updateSieveCalibrationLoadFailedAction(response.message)
                        }),
                        catchError(() => ActionsObservable.of(updateSieveCalibrationLoadFailedAction("Unable to update sieves calibrations")))
                    )
            )
        );