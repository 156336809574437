import { IPassTokenRequestBody } from "../../../../../react/shared/publicInterfaces";
import { IAddUpdateSimosCustomerContact } from "../addSimosCustomerContact/addSimosCustomerContactConstaints";

export enum IUPDATE_SIMOS_CUSTOMER_CONTACT_REQUEST {
    REQUEST = "updateSimosCustomerContact/UPDATE_SIMOS_CUSTOMER_CONTACT_REQUEST",
    SUCCESS = "updateSimosCustomerContact/UPDATE_SIMOS_CUSTOMER_CONTACT_SUCCESS",
    FAILED = "updateSimosCustomerContact/UPDATE_SIMOS_CUSTOMER_CONTACT_FAILED"
};

export interface IUpdateSimosCustomerContactRequest extends IPassTokenRequestBody {
    request: IAddUpdateSimosCustomerContact;
};
