import { IAddSieveCheckRequest, IADD_SIEVE_CHECK_REQUEST } from "./addSieveCheckConstaints";

export interface IAddSieveCheckLoadAction {
    type: IADD_SIEVE_CHECK_REQUEST.REQUEST;
    data: IAddSieveCheckRequest
}
export const addSieveCheckLoadAction = (data: IAddSieveCheckRequest): IAddSieveCheckLoadAction => ({
    type: IADD_SIEVE_CHECK_REQUEST.REQUEST,
    data
});

export interface IAddSieveCheckSuccessAction {
    type: IADD_SIEVE_CHECK_REQUEST.SUCCESS;
    message: string;
}
export const addSieveCheckLoadSuccessAction = (message: string): IAddSieveCheckSuccessAction => ({
    type: IADD_SIEVE_CHECK_REQUEST.SUCCESS,
    message
});

export interface IAddSieveCheckLoadFailedAction {
    type: IADD_SIEVE_CHECK_REQUEST.FAILED;
    message: string;
}
export const addSieveCheckLoadFailedAction = (message: string): IAddSieveCheckLoadFailedAction => ({
    type: IADD_SIEVE_CHECK_REQUEST.FAILED,
    message
});
