import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IGetSieveDryPlantLoadAction, IGetSieveDryPlantLoadFailedAction, IGetSieveDryPlantSuccessAction, 
    getSieveDryPlantLoadFailedAction, getSieveDryPlantLoadSuccessAction
} from "./getSieveDryPlantsActions";
import { IGET_SIEVE_DRY_PLANTS_REQUEST, ISieveDryPlant } from "./getSieveDryPlantsConstaints";
import { IStore } from "../../../../reducers";
import { EpicDependencies } from "../../../../store";
import { SilAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { END_POINTS } from "../../../../endpoints";
import { withJsonContentType } from "../../../../epicUtils";

export const getSieveDryPlantEpic: Epic = (
    action$: ActionsObservable<IGetSieveDryPlantLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IGetSieveDryPlantSuccessAction | IGetSieveDryPlantLoadFailedAction> =>
    action$.ofType(IGET_SIEVE_DRY_PLANTS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<ISieveDryPlant[]>>(
                    END_POINTS.PROPSENSE.SIL.CV.GetCVSieveDryPlant,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<ISieveDryPlant[]>): IGetSieveDryPlantSuccessAction => {
                            return getSieveDryPlantLoadSuccessAction(response);
                        }),
                        catchError(() => ActionsObservable.of(getSieveDryPlantLoadFailedAction("Unable to get sieve dry plant")))
                    )
            )
        );