import { IAddGeoBagRequest, IADD_GEO_BAG_REQUEST } from "./addGeoBagConstaints";

export interface IAddGeoBagLoadAction {
    type: IADD_GEO_BAG_REQUEST.REQUEST;
    data: IAddGeoBagRequest
}
export const addGeoBagLoadAction = (data: IAddGeoBagRequest): IAddGeoBagLoadAction => ({
    type: IADD_GEO_BAG_REQUEST.REQUEST,
    data
});

export interface IAddGeoBagSuccessAction {
    type: IADD_GEO_BAG_REQUEST.SUCCESS;
    message: string;
}
export const addGeoBagLoadSuccessAction = (message: string): IAddGeoBagSuccessAction => ({
    type: IADD_GEO_BAG_REQUEST.SUCCESS,
    message
});

export interface IAddGeoBagLoadFailedAction {
    type: IADD_GEO_BAG_REQUEST.FAILED;
    message: string;
}
export const addGeoBagLoadFailedAction = (message: string): IAddGeoBagLoadFailedAction => ({
    type: IADD_GEO_BAG_REQUEST.FAILED,
    message
});
