import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetWashPlantProductionLookupsRequest, IGET_WASH_PLANT_PRODUCTION_LOOKUPS_REQUEST, IWashPlantProductionLookups } from "./getWashPlantProductionLookupsConstaints";

export interface IGetWashPlantProductionLookupsLoadAction {
    type: IGET_WASH_PLANT_PRODUCTION_LOOKUPS_REQUEST.REQUEST;
    data: IGetWashPlantProductionLookupsRequest
}
export const getWashPlantProductionLookupsLoadAction = (data: IGetWashPlantProductionLookupsRequest): IGetWashPlantProductionLookupsLoadAction => ({
    type: IGET_WASH_PLANT_PRODUCTION_LOOKUPS_REQUEST.REQUEST,
    data
});

export interface IGetWashPlantProductionLookupsSuccessAction {
    type: IGET_WASH_PLANT_PRODUCTION_LOOKUPS_REQUEST.SUCCESS;
    data: SilAPIResponse<IWashPlantProductionLookups>;
}
export const getWashPlantProductionLookupsLoadSuccessAction = (data: SilAPIResponse<IWashPlantProductionLookups>): IGetWashPlantProductionLookupsSuccessAction => ({
    type: IGET_WASH_PLANT_PRODUCTION_LOOKUPS_REQUEST.SUCCESS,
    data
});

export interface IGetWashPlantProductionLookupsLoadFailedAction {
    type: IGET_WASH_PLANT_PRODUCTION_LOOKUPS_REQUEST.FAILED;
    message: string;
}
export const getWashPlantProductionLookupsLoadFailedAction = (message: string): IGetWashPlantProductionLookupsLoadFailedAction => ({
    type: IGET_WASH_PLANT_PRODUCTION_LOOKUPS_REQUEST.FAILED,
    message
});
