import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IUpdateWashPlantProductionLoadAction, IUpdateWashPlantProductionLoadFailedAction, IUpdateWashPlantProductionSuccessAction, 
    updateWashPlantProductionLoadFailedAction, updateWashPlantProductionLoadSuccessAction } from "./updateWashPlantProductionActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IUPDATE_WASH_PLANT_PRODUCTION_REQUEST } from "./updateWashPlantProductionConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";

export const updateWashPlantProductionEpic: Epic = (
    action$: ActionsObservable<IUpdateWashPlantProductionLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IUpdateWashPlantProductionSuccessAction | IUpdateWashPlantProductionLoadFailedAction> =>
    action$.ofType(IUPDATE_WASH_PLANT_PRODUCTION_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<string>>(
                    END_POINTS.PROPSENSE.SIL.WASH_PLANT.UPDATE_WASH_PLANT_PRODUCTION,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<string>): IUpdateWashPlantProductionSuccessAction | IUpdateWashPlantProductionLoadFailedAction => {
                            
                            if(response.message === "Success"){
                                return updateWashPlantProductionLoadSuccessAction(action.data.request);
                            }
                            return updateWashPlantProductionLoadFailedAction(response.message);
                        }),
                        catchError(() => ActionsObservable.of(updateWashPlantProductionLoadFailedAction("Unable to save your changes")))
                    )
            )
        );