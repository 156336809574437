import { IPassTokenWithRequest } from "../../../sil/dryPlant/token/getTokenConstants";

export enum IGET_ORDER_CONTACT_REQUEST {
    REQUEST = "getOrderContact/GET_ORDER_CONTACT_REQUEST",
    SUCCESS = "getOrderContact/GET_ORDER_CONTACT_SUCCESS",
    FAILED = "getOrderContact/GET_ORDER_CONTACT_FAILED"
};

export interface IGetOrderContactRequest extends IPassTokenWithRequest {
    request: {
        order_id: number;
        // customer_id: number;
    }
};

export interface ISIMOSOrderContact {
    contact: string;
    created: string;
    created_By: string;
    customer_ID: number;
    delivery_ID: number;
    email: string;
    cell: string;
    id: number;
    modified: string;
    modified_By: string;
    orderID: number;
    phone: string;
    selected: string;
};