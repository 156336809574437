import { SilAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { IGetLabScaleRequest, IGET_LAB_SCALES_REQUEST, ILabScale } from "./getLabScalesConstaints";

export interface IGetLabScaleLoadAction {
    type: IGET_LAB_SCALES_REQUEST.REQUEST;
    data: IGetLabScaleRequest
}
export const getLabScaleLoadAction = (data: IGetLabScaleRequest): IGetLabScaleLoadAction => ({
    type: IGET_LAB_SCALES_REQUEST.REQUEST,
    data
});

export interface IGetLabScaleSuccessAction {
    type: IGET_LAB_SCALES_REQUEST.SUCCESS;
    data: SilAPIResponse<ILabScale[]>;
}
export const getLabScaleLoadSuccessAction = (data: SilAPIResponse<ILabScale[]>): IGetLabScaleSuccessAction => ({
    type: IGET_LAB_SCALES_REQUEST.SUCCESS,
    data
});

export interface IGetLabScaleLoadFailedAction {
    type: IGET_LAB_SCALES_REQUEST.FAILED;
    message: string;
}
export const getLabScaleLoadFailedAction = (message: string): IGetLabScaleLoadFailedAction => ({
    type: IGET_LAB_SCALES_REQUEST.FAILED,
    message
});
