import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, isSucceeded, Server } from "../../../../redux/server";
import { ById, SilAPIResponse } from "../../../shared/publicInterfaces";
import { userName, undefinedFunction, monthNames, getYearsList } from "../../../shared/constExports";
import { LAButton, LAIconButton, LASaveAndCancelButton } from "../../../shared/buttons";
import { ArrowLeftIcon } from "../../../shared/icons";
import { WHITE_COLOR } from "../../../shared/theme";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import LATextField from "../../../shared/textField";
import LAAutoComplete from "../../../shared/autoComplete";
import { ROUTE } from "../../../routes";
import queryString from "query-string";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import { FieldValidator, FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../../shared/fieldValidator";
import PageSpacing from "../../../shared/pageSpacing";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import SilRoleCheck from "../../../shared/silRoleCheck";
import { IGetLabScaleRequest, ILabScale } from "../../../../redux/sil/cv/labScales/getLabScales/getLabScalesConstaints";
import { IAddLabScaleRequest } from "../../../../redux/sil/cv/labScales/addLabScale/addLabScaleConstaints";
import { IUpdateLabScaleRequest } from "../../../../redux/sil/cv/labScales/updateLabScale/updateLabScaleConstaints";
import { getLabScaleStatus } from "../../../../redux/sil/cv/labScales/getLabScales/getLabScalesAccessor";
import { addLabScaleStatus } from "../../../../redux/sil/cv/labScales/addLabScale/addLabScaleAccessor";
import { updateLabScaleStatus } from "../../../../redux/sil/cv/labScales/updateLabScale/updateLabScaleAccessor";
import { getLabScaleLoadAction } from "../../../../redux/sil/cv/labScales/getLabScales/getLabScalesActions";
import { addLabScaleLoadAction } from "../../../../redux/sil/cv/labScales/addLabScale/addLabScaleActions";
import { updateLabScaleLoadAction } from "../../../../redux/sil/cv/labScales/updateLabScale/updateLabScaleActions";
import { IIdName } from "../../../../utils/sharedTypes";


const plantsList: IIdName<number>[] = [
    { id: 0, name: "Dry Plant Scale" },
    { id: 1, name: "Exploration Plant Scale" },
    { id: 2, name: "Wash Plant Scale" }
];

interface ILabScaleStoreProps {
    addLabScale: Server<string>;
    updateLabScale: Server<string>;
    getToken: Server<SilAPIResponse<IToken>>;
    getLabScales: Server<SilAPIResponse<ILabScale[]>>;
};

interface ILabScaleDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getLabScalesRequest: (data: IGetLabScaleRequest) => unknown;
    addLabScaleRequest: (data: IAddLabScaleRequest) => unknown;
    updateLabScaleRequest: (data: IUpdateLabScaleRequest) => unknown;
};


interface ILabScaleOwnProps {

};

interface ILabScaleState {
    labScale: ILabScale;
    errors: ById<IFieldErrorKeyValue>;
};

const LabScaleStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
`;

type ILabScaleProps = RouteComponentProps
    & ILabScaleStoreProps
    & ILabScaleDispatchProps
    & ILabScaleOwnProps;

class LabScale extends PureComponent<ILabScaleProps, ILabScaleState> {

    public constructor(props: ILabScaleProps) {
        super(props);
        this.state = {
            errors: {
                "plant": { key: "plant", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "model": { key: "model", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "month": { key: "month", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "year": { key: "year", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "employee": { key: "employee", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
            },
            labScale: {
                id: 0,
                plant: "",
                created: "",
                employee: "",
                created_By: userName,
                modified: "",
                modified_By: userName
            }
        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: ILabScaleProps): void {
        if (this.props !== prevProps) {
            this.callServer();

            if (this.props.addLabScale !== prevProps.addLabScale) {
                if (isSucceeded(this.props.addLabScale)) {
                    this.handleCancel();
                };
            };

            if (this.props.updateLabScale !== prevProps.updateLabScale) {
                if (isSucceeded(this.props.updateLabScale)) {
                    this.handleCancel();
                };
            };
        };
    };


    public render(): ReactNode {

        const { updateLabScale, addLabScale } = this.props;
        const { labScale, errors } = this.state;

        const isAdd = labScale.id > 0 ? false : true;
        const years = getYearsList(2018);

        return (
            <PageSpacing title="SIL - CV" description="Lab Scale" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="carrierAccess">
                    <LabScaleStyles>

                        <LAButton startIcon={<ArrowLeftIcon color={WHITE_COLOR} />} label="Back to list" onClick={this.handleCancel} />
                        <h2 className="text-center">{!isAdd ? "VIEW " : "ADD "} LAB SCALE</h2>
                        <hr />

                        <LAPaperWithPadding>
                            <LAGrid spacing={3}>

                            <LAGridItem xs={4}>
                                    <LAAutoComplete
                                        filterSelectedOptions={true}
                                        autoHighlight={true}
                                        selectionRemove={undefinedFunction}
                                        dropDownPlaceHolder="Plant"
                                        getOptionLabel="name"
                                        name="plant"
                                        option={plantsList}
                                        multiple={false}
                                        onChange={this.handleDropDownChange}
                                        value={plantsList.find(x => x.name === labScale.plant)}
                                        defaultValue={plantsList.find(x => x.name === labScale.plant)}
                                        errorText={errors["plant"] ? errors["plant"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LATextField
                                        varient="outlined"
                                        label="Model #"
                                        fullWidth={true}
                                        name="model"
                                        type="number"
                                        value={labScale.model ?? ""}
                                        onChange={this.onNumberChange}
                                        errorText={errors["model"] ? errors["model"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                <LAAutoComplete
                                        filterSelectedOptions={true}
                                        autoHighlight={true}
                                        selectionRemove={undefinedFunction}
                                        dropDownPlaceHolder="Month"
                                        getOptionLabel="name"
                                        name="month"
                                        option={monthNames}
                                        multiple={false}
                                        onChange={this.handleDropDownIdChange}
                                        value={labScale.month ? monthNames.find(x => x.id === labScale.month) : ""}
                                        defaultValue={labScale.month ? monthNames.find(x => x.id === labScale.month) : ""}
                                        errorText={errors["month"] ? errors["month"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                <LAAutoComplete
                                        filterSelectedOptions={true}
                                        autoHighlight={true}
                                        selectionRemove={undefinedFunction}
                                        dropDownPlaceHolder="Year"
                                        getOptionLabel="name"
                                        name="year"
                                        option={years}
                                        multiple={false}
                                        onChange={this.handleDropDownIdChange}
                                        value={labScale.year ? years.find(x => x.id === labScale.year) : ""}
                                        defaultValue={labScale.year ? years.find(x => x.id === labScale.year) : ""}
                                        errorText={errors["year"] ? errors["year"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LATextField
                                        varient="outlined"
                                        label="10 g Reading"
                                        fullWidth={true}
                                        name="ten_G_Reading"
                                        type="number"
                                        value={labScale.ten_G_Reading ?? ""}
                                        onChange={this.onNumberChange}
                                        errorText={errors["ten_G_Reading"] ? errors["ten_G_Reading"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LATextField
                                        varient="outlined"
                                        label="500 g Reading"
                                        fullWidth={true}
                                        name="five_Hundred_G_Reading"
                                        type="number"
                                        value={labScale.five_Hundred_G_Reading ?? ""}
                                        onChange={this.onNumberChange}
                                        errorText={errors["five_Hundred_G_Reading"] ? errors["five_Hundred_G_Reading"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LATextField
                                        varient="outlined"
                                        label="1000 g Reading"
                                        fullWidth={true}
                                        name="thousand_G_Reading"
                                        type="number"
                                        value={labScale.thousand_G_Reading ?? ""}
                                        onChange={this.onNumberChange}
                                        errorText={errors["thousand_G_Reading"] ? errors["thousand_G_Reading"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LATextField
                                        varient="outlined"
                                        label="Employee"
                                        fullWidth={true}
                                        name="employee"
                                        value={labScale.employee ?? ""}
                                        onChange={this.onChange}
                                        errorText={errors["employee"] ? errors["employee"].message : undefined}
                                    />
                                </LAGridItem>
                                

                                <LAGridItem xs={12}>
                                    <hr />
                                    <LASaveAndCancelButton
                                        onSave={this.onSave}
                                        onCancel={this.handleCancel}
                                        disableSave={(Object.values(errors).length > 0 ? true : undefined)}
                                    />
                                </LAGridItem>

                            </LAGrid>
                        </LAPaperWithPadding>

                        <RequestStatus requestStatus={addLabScale.kind} successMessage="Lab Scale successfully saved" />
                        <RequestStatus requestStatus={updateLabScale.kind} successMessage="Lab Scale successfully updated" />
                    </LabScaleStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, isRequired: boolean): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: isRequired ? true : undefined, minLength: 1, decimal: undefined });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        };
        return err;
    };

    private handleCancel = (): void => {
        this.props.history.push(ROUTE.SIL.CV.LAB_SCALES.INDEX);
    };

    private onSave = (): void => {
        const { labScale } = this.state;

        if (hasPayload(this.props.getToken))
            if (labScale.id === 0) {
                this.props.addLabScaleRequest({
                    token: this.props.getToken.payload.response.token,
                    request: labScale
                });
            } else {
                labScale.modified_By = userName;
                
                this.props.updateLabScaleRequest({
                    token: this.props.getToken.payload.response.token,
                    request: labScale
                });
            };
    };

    private handleDropDownChange = (event: unknown, value: { id: number, name: string } | "", name?: string): void => {
        if (name){
            let errors = this.state.errors;
            errors = this.errorChecker(name, value !== "" ? value.name : "", errors, true);

            this.setState({
                ...this.state,
                errors,
                labScale: {
                    ...this.state.labScale,
                    [name]: value !== "" ? value.name : ""
                }
            });
        }
    };

    private handleDropDownIdChange = (event: unknown, value: { id: number, name: string } | "", name?: string): void => {
        if (name){
            let errors = this.state.errors;
            errors = this.errorChecker(name, value !== "" ? value.name : "", errors, true);

            this.setState({
                ...this.state,
                errors,
                labScale: {
                    ...this.state.labScale,
                    [name]: value !== "" ? value.id : undefined
                }
            });
        }
    };

    private onNumberChange = (name: string, value: string): void => {
        if(!isNaN(+value)){
            let errors = this.state.errors;
        errors = this.errorChecker(name, value, errors, true);

        this.setState({
            ...this.state,
            errors,
            labScale: {
                ...this.state.labScale,
                [name]: +value
            }
        });
        }
    };

    private onChange = (name: string, value: string): void => {
        let errors = this.state.errors;
        errors = this.errorChecker(name, value, errors, true);

        this.setState({
            ...this.state,
            errors,
            labScale: {
                ...this.state.labScale,
                [name]: value
            }
        });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getLabScales))
            this.props.getLabScalesRequest({
                token: this.props.getToken.payload.response.token
            });


        const query = queryString.parse(this.props.location.search);
        const id = query.id ?? "0";

        if ((id !== "0") && (hasPayload(this.props.getLabScales))) {
            const labScale = this.props.getLabScales.payload.response.find(x => x.id === Number(id));

            if(labScale)
                this.setState({ labScale, errors: {} });
        };

    };

}

const mapStateToProps = (state: IStore): ILabScaleStoreProps => ({
    getToken: getTokenStatus(state),
    getLabScales: getLabScaleStatus(state),
    addLabScale: addLabScaleStatus(state),
    updateLabScale: updateLabScaleStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): ILabScaleDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getLabScalesRequest: (data: IGetLabScaleRequest) => dispatch(getLabScaleLoadAction(data)),
    addLabScaleRequest: (data: IAddLabScaleRequest) => dispatch(addLabScaleLoadAction(data)),
    updateLabScaleRequest: (data: IUpdateLabScaleRequest) => dispatch(updateLabScaleLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(LabScale);