import { IUPDATE_SIMOS_TRANSPORT_COMPANY_REQUEST } from "./updateSimosTransportCompanyConstaints";
import { IUpdateSimosTransportCompanyLoadAction, IUpdateSimosTransportCompanyLoadFailedAction, IUpdateSimosTransportCompanySuccessAction } from "./updateSimosTransportCompanyActions";
import { failed, loading, notLoaded, Server, succeeded } from "../../../../server";
import { IFlushDataSuccessAction } from '../../../../flushData.tsx/flushDataActions';
import { FLUSH_DATA } from '../../../../flushData.tsx/flushDataConstants';

type Actions =
    IUpdateSimosTransportCompanyLoadAction
    | IUpdateSimosTransportCompanyLoadFailedAction
    | IUpdateSimosTransportCompanySuccessAction
    | IFlushDataSuccessAction;

export const UpdateSimosTransportCompanyReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IUPDATE_SIMOS_TRANSPORT_COMPANY_REQUEST.REQUEST:
            return loading;

        case IUPDATE_SIMOS_TRANSPORT_COMPANY_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IUPDATE_SIMOS_TRANSPORT_COMPANY_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};