import { IAddNCRRequest, IADD_NCR_REQUEST } from "./addNCRConstaints";

export interface IAddNCRLoadAction {
    type: IADD_NCR_REQUEST.REQUEST;
    data: IAddNCRRequest
}
export const addNCRLoadAction = (data: IAddNCRRequest): IAddNCRLoadAction => ({
    type: IADD_NCR_REQUEST.REQUEST,
    data
});

export interface IAddNCRSuccessAction {
    type: IADD_NCR_REQUEST.SUCCESS;
    message: string;
}
export const addNCRLoadSuccessAction = (message: string): IAddNCRSuccessAction => ({
    type: IADD_NCR_REQUEST.SUCCESS,
    message
});
export interface IAddNCRLoadFailedAction {
    type: IADD_NCR_REQUEST.FAILED;
    message: string;
}
export const addNCRLoadFailedAction = (message: string): IAddNCRLoadFailedAction => ({
    type: IADD_NCR_REQUEST.FAILED,
    message
});
