import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IGetWarehouseLoadAction, IGetWarehouseLoadFailedAction, IGetWarehouseSuccessAction, 
    getWarehouseLoadFailedAction, getWarehouseLoadSuccessAction
} from "./getWarehousesActions";
import { IGET_WAREHOUSES_REQUEST, IWarehouse } from "./getWarehousesConstaints";
import { IStore } from "../../../../reducers";
import { EpicDependencies } from "../../../../store";
import { SilAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { END_POINTS } from "../../../../endpoints";
import { withJsonContentType } from "../../../../epicUtils";

export const getWarehouseEpic: Epic = (
    action$: ActionsObservable<IGetWarehouseLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IGetWarehouseSuccessAction | IGetWarehouseLoadFailedAction> =>
    action$.ofType(IGET_WAREHOUSES_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<IWarehouse[]>>(
                    END_POINTS.PROPSENSE.SIL.CV.GetCVWarehouse,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<IWarehouse[]>): IGetWarehouseSuccessAction => {
                            return getWarehouseLoadSuccessAction(response);
                        }),
                        catchError(() => ActionsObservable.of(getWarehouseLoadFailedAction("Unable to get warehouses")))
                    )
            )
        );