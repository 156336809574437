import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import styled from "styled-components";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import PageSpacing from "../../../shared/pageSpacing";
import { LAPaperWithPadding } from "../../../shared/paper";
import SilRoleCheck from "../../../shared/silRoleCheck";


const DryPlantHomeStyles = styled(LAPaperWithPadding)`
    margin: 40px 30px;
`;

type IDryPlantHomeProps = RouteComponentProps;

class DryPlantHome extends PureComponent<IDryPlantHomeProps> {

    public constructor(props: IDryPlantHomeProps) {
        super(props);
        this.state = {
        };
    }

    public componentDidMount(): void {

    };

    public render(): ReactNode {

        return (
            <PageSpacing title="SIL - Dry Plant" description="SIL - Dry Plant" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="dryPlantAccess">
                    <DryPlantHomeStyles>
                        <LAGrid spacing={3} className="text-center">

                            <LAGridItem xs={12}>
                                <h2 className="title">Dry Plant Home</h2>
                            </LAGridItem>

                        </LAGrid>
                    </DryPlantHomeStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

}

export default DryPlantHome;


