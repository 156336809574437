import { IPassTokenWithRequest } from "../../dryPlant/token/getTokenConstants";

export enum IGET_COMPLAINT_LOGGING_REQUEST {
    REQUEST = "get/GET_COMPLAINT_LOGGING_REQUEST",
    SUCCESS = "getComplaintLogging/GET_COMPLAINT_LOGGING_SUCCESS",
    FAILED = "getComplaintLogging/GET_COMPLAINT_LOGGING_FAILED"
};

export interface IComplaintLogging {
    id: number;
    date_Of_Complaint: string;
    method_Of_Complaint: string;
    complainant_First_Name: string;
    complainant_Last_Name: string;
    contact_Number: string;
    residential_Address: string;
    email_Address: string;
    plant: string;
    company: string;
    description: string;
    immediate_Action_Taken: string;
    suspected_Cause: string;
    equipment_Running: string;
    last_Note: string;
    last_Note_User: string;
    last_Note_Date: string;
    follow_Up_Actions: string;
    created: string;
    created_By: string;
    modified: string;
    modified_By: string;
};

export interface IGetComplaintLoggingRequest extends IPassTokenWithRequest {

};