import { IUpdateSieveProductRequest, IUPDATE_SIEVE_PRODUCT_REQUEST } from "./updateSieveProductConstaints";

export interface IUpdateSieveProductLoadAction {
    type: IUPDATE_SIEVE_PRODUCT_REQUEST.REQUEST;
    data: IUpdateSieveProductRequest
}
export const updateSieveProductLoadAction = (data: IUpdateSieveProductRequest): IUpdateSieveProductLoadAction => ({
    type: IUPDATE_SIEVE_PRODUCT_REQUEST.REQUEST,
    data
});

export interface IUpdateSieveProductSuccessAction {
    type: IUPDATE_SIEVE_PRODUCT_REQUEST.SUCCESS;
    message: string;
}
export const updateSieveProductLoadSuccessAction = (message: string): IUpdateSieveProductSuccessAction => ({
    type: IUPDATE_SIEVE_PRODUCT_REQUEST.SUCCESS,
    message
});

export interface IUpdateSieveProductLoadFailedAction {
    type: IUPDATE_SIEVE_PRODUCT_REQUEST.FAILED;
    message: string;
}
export const updateSieveProductLoadFailedAction = (message: string): IUpdateSieveProductLoadFailedAction => ({
    type: IUPDATE_SIEVE_PRODUCT_REQUEST.FAILED,
    message
});
