import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IUpdateBaggedInventoryLoadAction,
    IUpdateBaggedInventoryLoadFailedAction,
    IUpdateBaggedInventorySuccessAction,
    updateBaggedInventoryLoadFailedAction, 
    updateBaggedInventoryLoadSuccessAction, 
} from "./updateBaggedInventoryActions";
import { IUPDATE_BAGGED_INVENTORY_REQUEST } from "./updateBaggedInventoryConstaints";
import { SilAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { END_POINTS } from "../../../../endpoints";
import { withJsonContentType } from "../../../../epicUtils";
import { IStore } from "../../../../reducers";
import { EpicDependencies } from "../../../../store";

export const updateBaggedInventoryEpic: Epic = (
    action$: ActionsObservable<IUpdateBaggedInventoryLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IUpdateBaggedInventorySuccessAction | IUpdateBaggedInventoryLoadFailedAction> =>
    action$.ofType(IUPDATE_BAGGED_INVENTORY_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<string>>(
                    END_POINTS.PROPSENSE.SIL.WAREHOUSE.UPDATE_BAGGED_INVENTORY,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<string>): IUpdateBaggedInventorySuccessAction | IUpdateBaggedInventoryLoadFailedAction => {
                            if(response.message === "Success"){
                                return updateBaggedInventoryLoadSuccessAction(response.message);
                            };
                            return updateBaggedInventoryLoadFailedAction(response.message)
                        }),
                        catchError(() => ActionsObservable.of(updateBaggedInventoryLoadFailedAction("Unable to update bagged inventory ")))
                    )
            )
        );