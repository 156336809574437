import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IGetSieveChecksLoadAction, IGetSieveChecksLoadFailedAction, IGetSieveChecksSuccessAction, 
    getSieveChecksLoadFailedAction, getSieveChecksLoadSuccessAction
} from "./getSievesChecksActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IGET_SIEVES_CHECKS_REQUEST, ISieveCheck } from "./getSievesChecksConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { ById, SilAPIResponse } from "../../../../react/shared/publicInterfaces";

export const getSieveChecksEpic: Epic = (
    action$: ActionsObservable<IGetSieveChecksLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IGetSieveChecksSuccessAction | IGetSieveChecksLoadFailedAction> =>
    action$.ofType(IGET_SIEVES_CHECKS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<ById<ISieveCheck>>>(
                    END_POINTS.PROPSENSE.SIL.SIEVES.GET_SIEVES_CHECKS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<ById<ISieveCheck>>): IGetSieveChecksSuccessAction => {
                            return getSieveChecksLoadSuccessAction(response);
                        }),
                        catchError(() => ActionsObservable.of(getSieveChecksLoadFailedAction("Unable to get sieve checks")))
                    )
            )
        );