import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { getCarrierFleetTrailersStatus } from "../../../../redux/sil/carrier/getCarrierFleetTrailers/getCarrierFleetTrailersAccessor";
import { getCarrierFleetTrailersLoadAction } from "../../../../redux/sil/carrier/getCarrierFleetTrailers/getCarrierFleetTrailersActions";
import { ICarrierFleetTrailer, IGetCarrierFleetTrailersRequest } from "../../../../redux/sil/carrier/getCarrierFleetTrailers/getCarrierFleetTrailersConstaints";
import { hasPayload, isNotLoaded, isSucceeded, Server } from "../../../../redux/server";
import { ById, SilAPIResponse } from "../../../shared/publicInterfaces";
import { userName, undefinedFunction, deleteText } from "../../../shared/constExports";
import { LAButton, LAIconButton, LASaveAndCancelButton } from "../../../shared/buttons";
import { ArrowLeftIcon, DeleteIcon } from "../../../shared/icons";
import { WHITE_COLOR } from "../../../shared/theme";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import LATextField from "../../../shared/textField";
import LAAutoComplete from "../../../shared/autoComplete";
import { ROUTE } from "../../../routes";
import queryString from "query-string";
import { updateCarrierFleetTrailerLoadAction } from "../../../../redux/sil/carrier/updateCarrierFleetTrailer/updateCarrierFleetTrailerActions";
import { updateCarrierFleetTrailerStatus } from "../../../../redux/sil/carrier/updateCarrierFleetTrailer/updateCarrierFleetTrailerAccessor";
import { IUpdateCarrierFleetTrailerRequest } from "../../../../redux/sil/carrier/updateCarrierFleetTrailer/updateCarrierFleetTrailerConstaints";
import { IAddCarrierFleetTrailerRequest } from "../../../../redux/sil/carrier/addCarrierFleetTrailer/addCarrierFleetTrailerConstaints";
import { addCarrierFleetTrailerStatus } from "../../../../redux/sil/carrier/addCarrierFleetTrailer/addCarrierFleetTrailerAccessor";
import { addCarrierFleetTrailerLoadAction } from "../../../../redux/sil/carrier/addCarrierFleetTrailer/addCarrierFleetTrailerActions";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import { FieldValidator, FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../../shared/fieldValidator";
import PageSpacing from "../../../shared/pageSpacing";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import LATextArea from "../../../shared/textArea";
import { END_POINTS } from "../../../../redux/endpoints";
import { getCarrierLookupsStatus } from "../../../../redux/sil/carrier/getCarrierLookups/getCarrierLookupsAccessor";
import { getCarrierLookupsLoadAction } from "../../../../redux/sil/carrier/getCarrierLookups/getCarrierLookupsActions";
import { ICarrierLookup, IGetCarrierLookupsRequest } from "../../../../redux/sil/carrier/getCarrierLookups/getCarrierLookupsConstaints";
import { LACheckBox } from "../../../shared/checkBox";
import { LADateTimePicker } from "../../../shared/dateTimePicker";
import SilRoleCheck from "../../../shared/silRoleCheck";
import { LADropDone } from "../../../shared/dragDropFiles";
import LALinkButton from "../../../shared/linkButton";


interface ICarrierFleetTrailerStoreProps {
    addCarrierFleetTrailer: Server<string>;
    updateCarrierFleetTrailer: Server<string>;
    getToken: Server<SilAPIResponse<IToken>>;
    getCarrierFleetTrailers: Server<SilAPIResponse<ICarrierFleetTrailer[]>>;
    getCarrierLookups: Server<SilAPIResponse<ICarrierLookup[]>>;
};

interface ICarrierFleetTrailerDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getCarrierFleetTrailersRequest: (data: IGetCarrierFleetTrailersRequest) => unknown;
    addCarrierFleetTrailerRequest: (data: IAddCarrierFleetTrailerRequest) => unknown;
    updateCarrierFleetTrailerRequest: (data: IUpdateCarrierFleetTrailerRequest) => unknown;
    getCarrierLookupsRequest: (data: IGetCarrierLookupsRequest) => unknown;
};


interface ICarrierFleetTrailerOwnProps {

};

interface ICarrierFleetTrailerState {
    fleetTrailer: ICarrierFleetTrailer;
    attachment: string | undefined;
    errors: ById<IFieldErrorKeyValue>;
};

const CarrierFleetTrailerStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
`;

type ICarrierFleetTrailerProps = RouteComponentProps
    & ICarrierFleetTrailerStoreProps
    & ICarrierFleetTrailerDispatchProps
    & ICarrierFleetTrailerOwnProps;

class CarrierFleetTrailer extends PureComponent<ICarrierFleetTrailerProps, ICarrierFleetTrailerState> {

    public constructor(props: ICarrierFleetTrailerProps) {
        super(props);
        this.state = {
            errors: {
                "trailer_No": { key: "trailer_No", message: FIELD_VALIDATOR_ERRORS.REQUIRED }
            },
            fleetTrailer: {
                id: 0,
                trailer_No: "",
                carrier: "",
                positive_Air_Shutoff: "",
                trailer_Registration_No: "",
                trailer_Registration_Expiry: "",
                trailer_Inspection_File_Name: "",
                trailer_Inspection_Date: "",
                active: "",
                inactive_Date: "",
                notes: "",
                created: "",
                created_By: userName,
                modified: "",
                modified_By: userName
            },
            attachment: undefined,
        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: ICarrierFleetTrailerProps): void {
        if (this.props !== prevProps) {
            this.callServer();

            if (this.props.addCarrierFleetTrailer !== prevProps.addCarrierFleetTrailer) {
                if (isSucceeded(this.props.addCarrierFleetTrailer)) {
                    this.handleCancel();
                };
            };

            if (this.props.updateCarrierFleetTrailer !== prevProps.updateCarrierFleetTrailer) {
                if (isSucceeded(this.props.updateCarrierFleetTrailer)) {
                    this.handleCancel();
                };
            };
        };
    };


    public render(): ReactNode {

        const { fleetTrailer, errors } = this.state;
        const { updateCarrierFleetTrailer, addCarrierFleetTrailer, getCarrierLookups } = this.props;
        const carriers = hasPayload(getCarrierLookups) ? getCarrierLookups.payload.response : undefined;

        return (
            <PageSpacing title="SIL - Carrier" description="Carrier FleetTrailer" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="carrierAccess">
                    <CarrierFleetTrailerStyles>

                        <LAButton startIcon={<ArrowLeftIcon color={WHITE_COLOR} />} label="Back to list" onClick={this.handleCancel} />
                        <h2 className="text-center">{fleetTrailer.id > 0 ? "VIEW/UPDATE " : "ADD "} CARRIER FLEET TRAILER</h2>
                        <hr />

                        <LAPaperWithPadding>
                            <LAGrid spacing={3}>

                                <LAGridItem xs={6}>
                                    <LATextField
                                        varient="outlined"
                                        label="Trailer No"
                                        fullWidth={true}
                                        name="trailer_No"
                                        onChange={this.onChange}
                                        value={fleetTrailer.trailer_No}
                                        errorText={errors["trailer_No"] ? errors["trailer_No"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={6}>
                                    <LAAutoComplete
                                        filterSelectedOptions={true}
                                        autoHighlight={true}
                                        selectionRemove={undefinedFunction}
                                        dropDownPlaceHolder="Carrier - Fleet Trailer"
                                        getOptionLabel="name"
                                        name="carrier"
                                        option={carriers}
                                        multiple={false}
                                        onChange={this.handleDropDownChange}
                                        value={carriers ? carriers.find(x => x.name === fleetTrailer.carrier) : ""}
                                        defaultValue={carriers ? carriers.find(x => x.name === fleetTrailer.carrier) : ""}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={6}>
                                    <LACheckBox
                                        name="positive_Air_Shutoff"
                                        label="Positive Air Shutoff"
                                        onChange={this.handleCheckBox}
                                        value={fleetTrailer.positive_Air_Shutoff && fleetTrailer.positive_Air_Shutoff === "Yes" ? true : false}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={6}>
                                    <LATextField
                                        varient="outlined"
                                        label="Trailer Registration No"
                                        fullWidth={true}
                                        name="trailer_Registration_No"
                                        value={fleetTrailer.trailer_Registration_No}
                                        onChange={this.onChange}
                                        errorText={errors["trailer_Registration_No"] ? errors["trailer_Registration_No"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={6}>
                                    <LADateTimePicker
                                        fullWidth={true}
                                        name="trailer_Registration_Expiry"
                                        onChange={this.handleDate}
                                        label="Trailer Registration Expiry"
                                        value={fleetTrailer.trailer_Registration_Expiry ?? ""}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={6}>
                                    <LADateTimePicker
                                        fullWidth={true}
                                        name="trailer_Inspection_Date"
                                        onChange={this.handleDate}
                                        label="Trailer Inspection Date"
                                        value={fleetTrailer.trailer_Inspection_Date ?? ""}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={6} className="text-center">
                                    <strong>Upload Inspection File</strong>
                                    <LADropDone onFileDrop={this.handleAtt} />
                                    {fleetTrailer.trailer_Inspection_File_Name && fleetTrailer.trailer_Inspection_File_Name.length > 0
                                        && <LALinkButton className="pl-5" label={fleetTrailer.trailer_Inspection_File_Name}
                                            onClick={this.handleDownloadAtt} />}
                                    {fleetTrailer.trailer_Inspection_File_Name && <LAIconButton
                                        name="trailer_Inspection_File_Name"
                                        label="Remove"
                                        icon={<DeleteIcon />}
                                        onClick={this.handleDeleteAtt}
                                    />}
                                </LAGridItem>

                                <LAGridItem xs={6}>
                                    <LACheckBox
                                        name="active"
                                        label="Active"
                                        onChange={this.handleCheckBox}
                                        value={fleetTrailer.active && fleetTrailer.active === "Yes" ? true : false}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={6}>
                                    <LADateTimePicker
                                        fullWidth={true}
                                        name="inactive_Date"
                                        onChange={this.handleDate}
                                        label="Inactive Date"
                                        value={fleetTrailer.inactive_Date ?? ""}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12}>
                                    <LATextArea
                                        minRows={5}
                                        rowsMax={10}
                                        varient="outlined"
                                        label="Notes"
                                        fullWidth={true}
                                        name="notes"
                                        value={fleetTrailer.notes}
                                        onChange={this.onChange}
                                        errorText={errors["notes"] ? errors["notes"].message : undefined}
                                    />
                                </LAGridItem>


                                <LAGridItem xs={12}>
                                    <LASaveAndCancelButton
                                        onSave={this.onSave}
                                        onCancel={this.handleCancel}
                                        disableSave={Object.values(errors).length > 0 ? true : undefined}
                                    />
                                </LAGridItem>

                            </LAGrid>
                        </LAPaperWithPadding>

                        <RequestStatus requestStatus={addCarrierFleetTrailer.kind} successMessage="Carrier FleetTrailer successfully saved" />
                        <RequestStatus requestStatus={updateCarrierFleetTrailer.kind} successMessage="Carrier FleetTrailer successfully updated" />
                    </CarrierFleetTrailerStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private handleCheckBox = (value: boolean, name?: string): void => {
        if (name)
            this.setState({
                ...this.state,
                fleetTrailer: {
                    ...this.state.fleetTrailer,
                    [name]: value ? "Yes" : "No"
                }
            });
    };

    private handleDate = (name: string, date: string): void => {
        this.setState({
            ...this.state,
            fleetTrailer: {
                ...this.state.fleetTrailer,
                [name]: date
            }
        });
    };

    private handleDeleteAtt = (): void => {
        const selection = window.confirm(deleteText);
        if(selection) {
            this.setState({
                fleetTrailer: {
                    ...this.state.fleetTrailer,
                    trailer_Inspection_File_Name: null
                },
                attachment: undefined
            });
        }
    };

    private handleDownloadAtt = (): void => {
        const { fleetTrailer, attachment } = this.state;
        if (fleetTrailer.trailer_Inspection_File_Name) {
            if ((attachment !== undefined) && (attachment !== null)) {
                let a = document.createElement("a");
                a.href = attachment;
                a.download = fleetTrailer.trailer_Inspection_File_Name;
                a.click();
            };

            if ((attachment === undefined) || (attachment === null)) {
                if (hasPayload(this.props.getToken) && fleetTrailer.id) {

                    fetch(END_POINTS.PROPSENSE.SIL.CARRIER.GET_CARRIER_FLEET_TRAILER_FILE, {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            request: {
                                "Carrier_Fleet_Trailer_ID": fleetTrailer.id,
                                "File": fleetTrailer.trailer_Inspection_File_Name
                            },
                            token: this.props.getToken.payload.response.token
                        })
                    })
                        .then((res: any) => {
                            return res.blob();
                        })
                        .then(blob => {
                            if (fleetTrailer.trailer_Inspection_File_Name) {
                                let url = window.URL.createObjectURL(blob);
                                let a = document.createElement('a');
                                a.href = url;
                                a.download = fleetTrailer.trailer_Inspection_File_Name;
                                document.body.appendChild(a);
                                a.click();
                                a.remove();
                            }
                        })
                        .catch((res) => console.log(res));
                };
            };
        }
    };

    private handleAtt = (event?: any): void => {
        new Promise((resolve, reject): any => {
            if (event && (event !== null)) {
                Object.values(event).forEach((x: any, index, array) => {

                if (x !== undefined) {
                    const reader = new FileReader();

                    reader.onloadend = async (e): Promise<void> => {
                        if (reader.result !== null) {
                            this.setState({
                                ...this.state,
                                fleetTrailer: {
                                    ...this.state.fleetTrailer,
                                    trailer_Inspection_File_Name: x.name,
                                },
                                attachment: reader.result.toString()
                            });
                            resolve(true);
                        };
                    };
                    reader.readAsDataURL(x);
                }
            })
            } else {
                reject();
            }
        });
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, isRequired: boolean): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: isRequired ? true : undefined, minLength: 1, decimal: undefined });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        };
        return err;
    };

    private handleCancel = (): void => {
        this.props.history.push(ROUTE.SIL.CARRIERS.CARRIER_TRAILERS);
    };

    private onSave = (): void => {
        const { fleetTrailer, attachment } = this.state;
        if (hasPayload(this.props.getToken))
            if (fleetTrailer.id === 0) {
                this.props.addCarrierFleetTrailerRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        trailer_No: fleetTrailer.trailer_No,
                        carrier: fleetTrailer.carrier,
                        positive_Air_Shutoff: fleetTrailer.positive_Air_Shutoff,
                        trailer_Registration_No: fleetTrailer.trailer_Registration_No,
                        trailer_Registration_Expiry: fleetTrailer.trailer_Registration_Expiry,
                        trailer_Inspection_File_Name: fleetTrailer.trailer_Inspection_File_Name,
                        trailer_Inspection_File: attachment ?? null,
                        trailer_Inspection_Date: fleetTrailer.trailer_Inspection_Date,
                        active: fleetTrailer.active,
                        inactive_Date: fleetTrailer.inactive_Date,
                        notes: fleetTrailer.notes,
                        created_By: fleetTrailer.created_By,
                        modified_By: fleetTrailer.modified_By
                    }
                });
            } else {
                let data = this.state.fleetTrailer;
                data.modified_By = userName;
                this.props.updateCarrierFleetTrailerRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        id: fleetTrailer.id,
                        trailer_No: fleetTrailer.trailer_No,
                        carrier: fleetTrailer.carrier,
                        positive_Air_Shutoff: fleetTrailer.positive_Air_Shutoff,
                        trailer_Registration_No: fleetTrailer.trailer_Registration_No,
                        trailer_Registration_Expiry: fleetTrailer.trailer_Registration_Expiry,
                        trailer_Inspection_File_Name: fleetTrailer.trailer_Inspection_File_Name,
                        trailer_Inspection_File: attachment ?? null,
                        trailer_Inspection_Date: fleetTrailer.trailer_Inspection_Date,
                        active: fleetTrailer.active,
                        inactive_Date: fleetTrailer.inactive_Date,
                        notes: fleetTrailer.notes,
                        created_By: fleetTrailer.created_By,
                        modified_By: fleetTrailer.modified_By
                    }
                });
            };
    };

    private handleDropDownChange = (event: unknown, value: { id: number, name: string } | "", name?: string): void => {
        if (name)
            this.setState({
                ...this.state,
                fleetTrailer: {
                    ...this.state.fleetTrailer,
                    [name]: value !== "" ? value.name : ""
                }
            });
    };

    private onNumberChange = (name: string, value: string): void => {
        if (!isNaN(Number(value))) {
            let errors = this.state.errors;
            errors = this.errorChecker(name, value, errors, true);

            this.setState({
                ...this.state,
                errors,
                fleetTrailer: {
                    ...this.state.fleetTrailer,
                    [name]: value
                }
            });
        };
    };


    private onChange = (name: string, value: string): void => {
        let errors = this.state.errors;
        errors = this.errorChecker(name, value, errors, true);

        this.setState({
            ...this.state,
            errors,
            fleetTrailer: {
                ...this.state.fleetTrailer,
                [name]: value
            }
        });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getCarrierLookups))
            this.props.getCarrierLookupsRequest({
                token: this.props.getToken.payload.response.token,
                requested_Page: "Carriers_Email"
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getCarrierFleetTrailers))
            this.props.getCarrierFleetTrailersRequest({
                token: this.props.getToken.payload.response.token
            });


        const query = queryString.parse(this.props.location.search);
        const id = query.id ?? "0";

        if ((id !== "0") && (hasPayload(this.props.getCarrierFleetTrailers)) && (this.state.fleetTrailer.id === 0)) {
            const fleetTrailer = this.props.getCarrierFleetTrailers.payload.response.find(x => x.id === Number(id));

            if (fleetTrailer)
                this.setState({ fleetTrailer, errors: {} });
        };

    };

}

const mapStateToProps = (state: IStore): ICarrierFleetTrailerStoreProps => ({
    getToken: getTokenStatus(state),
    getCarrierFleetTrailers: getCarrierFleetTrailersStatus(state),
    addCarrierFleetTrailer: addCarrierFleetTrailerStatus(state),
    updateCarrierFleetTrailer: updateCarrierFleetTrailerStatus(state),
    getCarrierLookups: getCarrierLookupsStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): ICarrierFleetTrailerDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getCarrierFleetTrailersRequest: (data: IGetCarrierFleetTrailersRequest) => dispatch(getCarrierFleetTrailersLoadAction(data)),
    addCarrierFleetTrailerRequest: (data: IAddCarrierFleetTrailerRequest) => dispatch(addCarrierFleetTrailerLoadAction(data)),
    updateCarrierFleetTrailerRequest: (data: IUpdateCarrierFleetTrailerRequest) => dispatch(updateCarrierFleetTrailerLoadAction(data)),
    getCarrierLookupsRequest: (data: IGetCarrierLookupsRequest) => dispatch(getCarrierLookupsLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(CarrierFleetTrailer);