import React from 'react';
import {BsCheck} from 'react-icons/bs';
import {Button} from '@material-ui/core';
import '../styling/popupModal.css';
import Zoom from '@material-ui/core/Zoom';

interface IProps {
    text: string;
    savePopup: any;
    closePopup: any;
    headerText:string;
}

export default class Popup extends React.Component<IProps>{
   
    render(){
        return (
            <div className="popup-box">
                 <Zoom in={true}>
                <div className="box">   <div className="popup-header-icon"></div>
                    <div className="header-limiter"> 
                    <h3>{this.props.headerText}</h3>
                        </div><br></br>
                    {this.props.text}
                    <Button variant="contained" color="default" className="save-icon" onClick={this.props.closePopup}>x</Button>
                <Button variant="contained" className="close-icon" onClick={this.props.savePopup}><BsCheck></BsCheck></Button>
                </div>
                </Zoom>
        </div>
   
        )
    }
}