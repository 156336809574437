import { IPassTokenWithRequest } from "../../dryPlant/token/getTokenConstants";

export enum IGET_CARRIER_INFRACTIONS_REQUEST {
    REQUEST = "getCarrierInfractions/GET_CARRIER_INFRACTIONS_REQUEST",
    SUCCESS = "getCarrierInfractions/GET_CARRIER_INFRACTIONS_SUCCESS",
    FAILED = "getCarrierInfractions/GET_CARRIER_INFRACTIONS_FAILED"
};

export interface IGetCarrierInfractionsRequest extends IPassTokenWithRequest {

};

export interface ICarrierInfraction {
    id: number;
    date_Of_Infraction: string;
    infraction_Notification_Method: string;
    injuries?: string;
    carrier: string;
    load_No?: string;
    trailer_No: string;
    license_Plate?: string;
    location: string;
    ticket_No?: string;
    supervisor?: string;
    infraction_Type: string;
    preventable?: any;
    reportable?: any;
    carrier_Notification_Provided_To?: string;
    description: string;
    immediate_Action_Taken: string;
    followup_Action_Taken?: string;
    investigation_Notes?: any;
    attachment_File_Name?: string;
    attachment_Details?: string;
    conditions?: string;
    corrective_Action_Recommended?: string;
    corrective_Action_Taken?: string;
    customer_Involved?: string;
    root_Cause_Immediate?: string;
    temperature?: string;
    driver_Statement_File_Name?: string;
    customer_Statement_File_Name?: string;
    other_Statement_File_Name?: string;
    reported_To?: string;
    contact_No?: string;
    reported_By_Name?: string;
    status: string;
    created: string;
    created_By: string;
    modified: string;
    modified_By: string;
    persons_Involved: {
        id: number;
        list_ID: number;
        name: string;
        occupation: string;
        phone_No: string;
        created: string;
        created_By: string;
        modified: string;
        modified_By: string;
    }[]
};