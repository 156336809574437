
import '../../../../shared/simos/styling/App.css';
import * as React from 'react';
import OrderedBy, {allValues} from './orderedBy';
import  PickupDateField, {allDateField}  from './dateField';
import DeliveryDateField, {allDeliveryDateField} from './deliveryDateField';
import SiteInfo, {allSiteInfo} from './siteInfo';
import TruckInfo, {allTruckInfo} from './truckInfo';
import ProductOrder, {allProducts} from './productOrdered';
import '../../../../shared/simos/styling/createpopupstyle.css';
import validateForm, {errors, checkPickupOrder, errorStr}from './formValidation';
import {AiOutlineCarryOut} from 'react-icons/ai';
import {chosenId} from './orderedBy';
import addOrder from '../APICalls/addOrder';
import {isDisabled} from '../APICalls/checkPONumExists';
import Grid from '@material-ui/core/Grid';
import Popup from '../../../../shared/simos/helper/popupModal';
import BackButton from '../../../../shared/simos/helper/backButton';
import Paper from '@material-ui/core/Paper';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import {getSIMOSUserGroupAccess} from '../APICalls/getSIMOSGroupAccess';
import { getSILURLParams } from '../../../../shared/simos/helper/getURLContainsSIL';

var orderID:number;

interface IState {
  cancelPopup: any;
  history:any;
}

interface allReqs{
  allReq:[{id:number,customer:string,
    delivery:string,
    pickupDate:string,
    pickupHour:string,
    pickupMin:string,
    transCompany:any,
    contacts:any[],
    siteContact:string,
    siteContactPhone:string,
    poNum:string,
    emailUser:boolean,
    emailTransport:boolean,
    truckRequired:any,
    deliveryDate:string,
    deliveryHour:string,
    deliveryMin:string,
    products:any[],
    location:string,
    comments:string,
    packaged:boolean,
    completed:boolean,
    cancelled:string,
    truckNumber:number,
    trailerNumber:number,
    tareWeight:string,
    grossWeight:string, 
    customer_ID:number,
    status:string,
    orderType:string,
    transport_Company_Notes:string,
    zone:string,
    hired_truck_rate:number,
    truck_code:string,
    delivery_Datetime:any,
    pickup_Datetime:any,
    salesOrderNumber:string,
    customerJobNumber:string}];
    showPopup:boolean;
    isOrderPickup:boolean;
    showError:boolean;
    errorText:string;
}

function initialState (newRequest:any) {
  newRequest.customer = allValues.chosenCustomer;
  newRequest.delivery = allValues.chosenDelivery;
  newRequest.pickupDate = allDateField.date;
  newRequest.pickupHour = allDateField.chosenHour;
  newRequest.pickupMin = allDateField.chosenMin;
  newRequest.transCompany = allSiteInfo.trns_company;
  newRequest.zone = allSiteInfo.zone;
  newRequest.hired_truck_rate = allSiteInfo.hired_truck_rate;
  newRequest.truck_code = allSiteInfo.truck_code;
  newRequest.contacts.push(allValues.orderedby);
  newRequest.siteContact = allSiteInfo.site_contact;
  newRequest.siteContactPhone = allSiteInfo.site_num;
  newRequest.poNum = allSiteInfo.po_num;
  newRequest.emailUser = allSiteInfo.email_check;
  newRequest.emailTransport = allSiteInfo.email_transport;
  newRequest.truckRequired = allSiteInfo.truck_req;
  newRequest.deliveryDate = allDeliveryDateField.date;
  newRequest.deliveryHour = allDeliveryDateField.chosenHour;
  newRequest.deliveryMin = allDeliveryDateField.chosenMin;
  newRequest.products.push(allProducts.productOrder);
  newRequest.location = allProducts.location;
  newRequest.comments = allTruckInfo.comments;
  newRequest.packaged = allTruckInfo.packaged;
  newRequest.completed = allTruckInfo.completed;
  newRequest.cancelled = allTruckInfo.cancelled;
  newRequest.truckNumber = allTruckInfo.truck_num;
  newRequest.trailerNumber = allTruckInfo.trailer_num;
  newRequest.tareWeight = allTruckInfo.tare;
  newRequest.grossWeight = allTruckInfo.weight;
  newRequest.status = 'Assigned';
  newRequest.orderType = allSiteInfo.prepaid;
  newRequest.transport_Company_Notes = allSiteInfo.transport_Company_Notes;
  newRequest.delivery_Datetime = allDeliveryDateField.delivery_date;
  newRequest.pickup_Datetime = allDateField.pickup_datefield;
  newRequest.salesOrderNumber = allValues.salesOrderNumber;
  newRequest.customerJobNumber = allSiteInfo.customer_job_number;
  return newRequest;
}

var request = {id:0,customer:'',delivery:'',pickupDate:'',pickupHour:'',pickupMin:'',transCompany:[],contacts:[],siteContact:'',siteContactPhone:'',
poNum:'',emailUser:false,emailTransport:false,truckRequired:[],deliveryDate:'',deliveryHour:'',deliveryMin:'',products:[],location:'',comments:'',
packaged:false, completed:false,cancelled:'',truckNumber:0,trailerNumber:0,tareWeight:'',grossWeight:'', delivery_Datetime:null, pickup_Datetime:null, zone:'', 
truck_code:'', hired_truck_rate:0, salesOrderNumber:'',customerJobNumber:''};
var allRequests:any =  [];
var allreq:any = [];
 function onFormComplete(event:any) {
  event.preventDefault();
}

class CreateForm extends React.Component<IState, allReqs>{

  constructor(props:any) {
    super(props);
    this.state = {
      allReq:[{id:0,customer:'',delivery:'',pickupDate:'',pickupHour:'',pickupMin:'',transCompany:[],contacts:[],siteContact:'',siteContactPhone:'',
      poNum:'',emailUser:false,emailTransport:false,truckRequired:[],deliveryDate:'',deliveryHour:'',deliveryMin:'',products:[],location:'',comments:'',
      packaged:false, completed:false,cancelled:'',truckNumber:0,trailerNumber:0,tareWeight:'',grossWeight:'', customer_ID:0,status:'Assigned', orderType:'', 
    transport_Company_Notes:'', delivery_Datetime:null, pickup_Datetime:null, zone:'', truck_code:'', hired_truck_rate:0, salesOrderNumber:'',customerJobNumber:''}],
      showPopup:false, isOrderPickup:false, showError:false, errorText:''
    }
    this.addData = this.addData.bind(this);
  }

  async addData(){ 
    var newrequest = initialState(request);
   
    let tempReq = this.state.allReq;
    newrequest.id+=1;
    tempReq.push({id:newrequest.id, customer: allValues.chosenCustomer, delivery:allValues.chosenDelivery, pickupDate: allDateField.date, pickupHour:allDateField.chosenHour, 
    pickupMin:allDateField.chosenMin, transCompany:allSiteInfo.trns_company,contacts:allValues.orderedby,siteContact:allSiteInfo.site_contact,siteContactPhone:allSiteInfo.site_num,
    poNum:allSiteInfo.po_num,emailUser:allSiteInfo.email_check,emailTransport:allSiteInfo.email_transport,truckRequired:allSiteInfo.truck_req,deliveryDate:allDeliveryDateField.date,
    deliveryHour:allDeliveryDateField.chosenHour,deliveryMin:allDeliveryDateField.chosenMin,products:allProducts.productOrder,location:allProducts.location,
    comments:allTruckInfo.comments,packaged:allTruckInfo.packaged,completed:allTruckInfo.completed,
    cancelled:allTruckInfo.cancelled,
    truckNumber:allTruckInfo.truck_num,
    trailerNumber:allTruckInfo.trailer_num,
    tareWeight:allTruckInfo.tare,
    grossWeight:allTruckInfo.weight, customer_ID:0,status:'Assigned', orderType:allSiteInfo.prepaid, transport_Company_Notes:allSiteInfo.transport_Company_Notes,
  delivery_Datetime:allDeliveryDateField.delivery_date, pickup_Datetime:allDateField.pickup_datefield, zone:allSiteInfo.zone, hired_truck_rate:allSiteInfo.hired_truck_rate, salesOrderNumber:allValues.salesOrderNumber,
  truck_code:allSiteInfo.truck_code, customerJobNumber:allSiteInfo.customer_job_number});
    
    // ! calls API endpoint to add entire order form to database
    
    this.setState({allReq:tempReq});
    // tempReq[1] contains the correct formatted data
    let temp:any = tempReq;
    if(!allRequests[0]){
      allRequests.push(temp[1]);
    }else{
      allRequests[0].push(temp[1]);
    }
    var result:any = await addOrder(chosenId, tempReq, orderID, allProducts, allValues);
    if(result.status === 200){
      alert("Successfully created new order");
      this.props.history.push('/simos/orders'+ getSILURLParams());
      window.location.reload();
    }
    else{
      alert("Error creating new order: server error");
    }
  }

   // All current information is stored here. Place information from here into JSON object/DB
onSaveForm(event: any) {
  if(allProducts.productOrder.length>1){
    if (validateForm(allValues, allDateField,allDeliveryDateField,allSiteInfo, allTruckInfo, isDisabled, allProducts.productOrder)) {
     // if(checkPickupOrder(allValues, allDateField, allDeliveryDateField)){
        // Add all data to the large request list
        this.addData();
        //this.props.cancelPopup();
        // switch with setstate or refresh grid
        //window.location.reload();
      //} 
      // else{
      //   this.setState({showError:true, errorText:errorStr});
      // }
    } 
    
    else {
      this.setState({showError:true, errorText:errorStr});
    }
  }
  else{
    //window.scrollTo({behavior:'smooth',top:0});
    this.setState({showError:true, errorText:'Error creating order — At least one product must be added!'});
    //alert("At least one product must be added to this order!");
    
  }
}
onCancel(event:any) {
  event.preventDefault();
  this.setState({showPopup:!this.state.showPopup});
}
handlePopupExit(){
  this.props.history.push('/simos/orders'+ getSILURLParams());
}
handlePopupCancel(){
  this.setState({showPopup:!this.state.showPopup});
}
changePickupOrder(){
  this.setState({isOrderPickup:!this.state.isOrderPickup});
}

async componentWillMount(){
  var response:any = await getSIMOSUserGroupAccess();
  var userRole:any = response.role;
  if(userRole!='Admin' && userRole!='Dispatcher' && userRole!='SuperAdmin'){
    this.props.history.push('/simos');
}
}
 public render (){ 
   var allReq = this.state.allReq;
  return (
    <Paper className={getSILURLParams()?.includes("bru") ? "paper-popup":"paper-popup-sil-ed"}>
      <Paper className="paper-popup-inner">
      
      <BackButton goBack={this.onCancel.bind(this)} history={this.props.history}></BackButton>
        <form onSubmit={onFormComplete} autoComplete="off"> 
        <h3>New SIMOS Order for {getSILURLParams()?.includes("bru") ? "SIL BRU":"SIL ED"}</h3>
        <Paper className="paper-card">
          <OrderedBy changePickupOrder={this.changePickupOrder.bind(this)}></OrderedBy>
          <Grid container spacing={6}>
        
            <Grid item xs={6}>
              <PickupDateField> </PickupDateField>
              </Grid>
             
              <Grid item xs={6}>
             <DeliveryDateField></DeliveryDateField></Grid>
          <br></br>
             </Grid>
              <SiteInfo history={this.props.history}></SiteInfo>
          <br></br>
          </Paper><div><br></br></div>
          <Paper className="paper-card">
            <ProductOrder></ProductOrder><br></br>
            <TruckInfo></TruckInfo>
          </Paper>
            <br></br>
            {this.state.showError ? 
              <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert variant="filled" severity="error" action={<IconButton color="inherit" onClick={() => {this.setState({showError:false})}}>X</IconButton>}>
                  {this.state.errorText}
                </Alert>
              </Stack>
            :null}<br></br>
            <button onClick={this.onCancel.bind(this)} id="cancel-create-btn">Cancel</button>
            {this.state.showPopup? <Popup headerText="Leaving Page" text='Cancel edits and exit?' savePopup = {this.handlePopupExit.bind(this)} closePopup={this.handlePopupCancel.bind(this)}/>:null}
            <button type="submit" onClick={this.onSaveForm.bind(this)} id="submit-form-btn"><AiOutlineCarryOut/>Save</button>
        </form>
        
    </Paper>
  </Paper>
);}
}
export default CreateForm;
export {allRequests};
export {errors};
export {allreq};