import { IAttachmentUpload } from "../../../../../react/shared/publicInterfaces";

export enum IGET_CARRIER_AUDIT_REQUEST {
    REQUEST = "getCarrierAudit/GET_CARRIER_AUDIT_REQUEST",
    SUCCESS = "getCarrierAudit/GET_CARRIER_AUDIT_SUCCESS",
    FAILED = "getCarrierAudit/GET_CARRIER_AUDIT_FAILED"
};

export interface IGetCarrierAuditRequest {
    token: string;
    request: {
        IsAdmin: boolean;
    }
};


export interface IGetCarrierAudit {
    is_Admin?: boolean;
    id: number;
    carrier_ID: number;
    date: string;
    insurance: string;
    policy_date: string;
    insurance_Comments: string;
    wcb: string;
    wcB_No: string;
    wcB_Comments: string;
    substance_Abuse_Policy: string;
    post_Incident_Testing: string;
    substance_Comments: string;
    seatbelt_Policy: string;
    seatbelt_Comments: string;
    carrier_Profile_Reviewed: string;
    audited: string;
    unaudited: string;
    r_Factor: string;
    profile_Comments: string;
    driver_Abstracts: string;
    _6_Demerits: string;
    alcohol_Free: string;
    pdic: string;
    abstracts_Frequency: string;
    reversing_Alarms: string;
    wheel_Chocks: string;
    anti_lock_brakes: string;
    ivms: string;
    radio_Communication: string;
    spill_Kit: string;
    emergency_Survival_Kit: string;
    vehicle_Comments: string;
    cell_Phone_Policy: string;
    cell_Phone_Comments: string;
    maintenance_Policy: string;
    a_Service_Intervals: string;
    b_Service_Intervals: string;
    current_CVIP: string;
    how_Tracked: string;
    maintenance_Comments: string;
    first_Aid: string;
    h2S_Alive: string;
    bear_Awareness: string;
    oilfield_Driver_Awareness: string;
    service_Hours: string;
    cargo_Securement: string;
    pre_Post_Trip: string;
    training_Comments: string;
    auditor_Printed_Name: string;
    auditor_Signature: string;
    carrier_Name: string;
    carrier_Printed_Name: string;
    carrier_Signature: string;
    audit_Completion_Date: string;
    audit: string;
    created: string;
    created_By: string;
    modified: string;
    modified_By: string;
    tdg: string;
    cso: string;
    whmis: string;
    distracted_Driving_Policy: string;
    distracted_Driving_Comments: string;
    speed_Limit_Policy: string;
    speed_Limit_Comments: string;
    defensive_Driving_Policy: string;
    defensive_Driving_Comments: string;
    fueling_Policy: string;
    fueling_Comments: string;
    safety_Fitness_Expiry_Date: string;
    awaiting_Paper_Work: string;
    awaiting_Paper_Work_Comments: string;

    insurance_Policy_File_Name: string;
    wcB_Policy_File_Name: string;
    drug_Alcohol_Policy_File_Name: string;
    seatbelt_Policy_File_Name: string;
    driver_Abstracts_Policy_File_Name: string;
    cell_Phone_Policy_File_Name: string;
    distract_Driving_Policy_File_Name: string;
    maintenance_Policy_File_Name: string;
    speed_Limit_Policy_File_Name: string;
    defensive_Driving_Policy_File_Name: string;
    fueling_Policy_File_Name: string;
    safety_Fitness_Certificate_File_Name: string;
    Insurance_Policy_Files?: IAttachmentUpload[];
    WCB_Policy_Files?: IAttachmentUpload[];
    Drug_Alcohol_Policy_Files?: IAttachmentUpload[];
    Seatbelt_Policy_Files?: IAttachmentUpload[];
    Driver_Abstracts_Policy_Files?: IAttachmentUpload[];
    Cell_Phone_Policy_Files?: IAttachmentUpload[];
    Distract_Driving_Policy_Files?: IAttachmentUpload[];
    Maintenance_Policy_Files?: IAttachmentUpload[];
    Speed_Limit_Policy_Files?: IAttachmentUpload[];
    Defensive_Driving_Policy_Files?: IAttachmentUpload[];
    Fueling_Policy_Files?: IAttachmentUpload[];
    Safety_Fitness_Certificate_Files?: IAttachmentUpload[];

    approvedDevExtreme?: boolean;
};
  