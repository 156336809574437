import { ByIdNumber, YHDAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { IGetYHDCarriersRequest, IGET_YHD_CARRIERS_REQUEST, IYHDCarrier } from "./getCarriersConstaints";

export interface IGetYHDCarriersLoadAction {
    type: IGET_YHD_CARRIERS_REQUEST.REQUEST;
    data: IGetYHDCarriersRequest
}
export const getYHDCarriersLoadAction = (data: IGetYHDCarriersRequest): IGetYHDCarriersLoadAction => ({
    type: IGET_YHD_CARRIERS_REQUEST.REQUEST,
    data
});

export interface IGetYHDCarriersSuccessAction {
    type: IGET_YHD_CARRIERS_REQUEST.SUCCESS;
    data: YHDAPIResponse<ByIdNumber<IYHDCarrier>>;
}
export const getYHDCarriersLoadSuccessAction = (data: YHDAPIResponse<ByIdNumber<IYHDCarrier>>): IGetYHDCarriersSuccessAction => ({
    type: IGET_YHD_CARRIERS_REQUEST.SUCCESS,
    data
});

export interface IGetYHDCarriersLoadFailedAction {
    type: IGET_YHD_CARRIERS_REQUEST.FAILED;
    message: string;
}
export const getYHDCarriersLoadFailedAction = (message: string): IGetYHDCarriersLoadFailedAction => ({
    type: IGET_YHD_CARRIERS_REQUEST.FAILED,
    message
});
