import { ISieveFracBall } from "../getSieveFracBalls/getSieveFracBallsConstaints";

export enum IUPDATE_SIEVE_FRAC_BALL_REQUEST {
    REQUEST = "updateSieveFracBall/UPDATE_SIEVE_FRAC_BALL_REQUEST",
    SUCCESS = "updateSieveFracBall/UPDATE_SIEVE_FRAC_BALL_SUCCESS",
    FAILED = "updateSieveFracBall/UPDATE_SIEVE_FRAC_BALL_FAILED"
};

export interface IUpdateSieveFracBallRequest {
    token: string;
    request: ISieveFracBall;
};