import { SimosAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { IGET_SIMOS_CUSTOMER_CONTACTS_REQUEST, ISimosCustomerContacts, ISimosCustomerContactsRequest } from "./getSimosCustomerContactsConstants";


export interface IGetSimosCustomerContactsLoadAction {
    type: IGET_SIMOS_CUSTOMER_CONTACTS_REQUEST.REQUEST;
    data: ISimosCustomerContactsRequest
}
export const getSimosCustomerContactsLoadAction = (data: ISimosCustomerContactsRequest): IGetSimosCustomerContactsLoadAction => ({
    type: IGET_SIMOS_CUSTOMER_CONTACTS_REQUEST.REQUEST,
    data
});
export interface IGetSimosCustomerContactsSuccessAction {
    type: IGET_SIMOS_CUSTOMER_CONTACTS_REQUEST.SUCCESS;
    data: SimosAPIResponse<ISimosCustomerContacts[]>;
}
export const getSimosCustomerContactsLoadSuccessAction = (data: SimosAPIResponse<ISimosCustomerContacts[]>): IGetSimosCustomerContactsSuccessAction => ({
    type: IGET_SIMOS_CUSTOMER_CONTACTS_REQUEST.SUCCESS,
    data
});
export interface IGetSimosCustomerContactsLoadFailedAction {
    type: IGET_SIMOS_CUSTOMER_CONTACTS_REQUEST.FAILED;
    message: string;
}
export const getSimosCustomerContactsLoadFailedAction = (message: string): IGetSimosCustomerContactsLoadFailedAction => ({
    type: IGET_SIMOS_CUSTOMER_CONTACTS_REQUEST.FAILED,
    message
});
