import React from 'react';
import {FaPencilAlt} from 'react-icons/fa';
import EditProductLookupForm from './EditProductLookupForm';
import '../../../../shared/simos/styling/productLookupPages.css';
//import { DataGrid} from '@mui/x-data-grid';
import SearchBar from '../../../../shared/simos/helper/sharedSearchBar';
import getSIMOSProductName from '../APICalls/getRequests/getSIMOSProductName';
import '../../../../shared/simos/styling/gridviewstyles.css';
import DataGrid, {
    Column, FilterRow, HeaderFilter, 
    Paging, Button, Pager, SearchPanel, 
    FilterPanel,
    FilterBuilderPopup,
    Export,
  } from 'devextreme-react/data-grid';
  import 'devextreme/dist/css/dx.light.css';
import { getSILURLParams } from '../../../../shared/simos/helper/getURLContainsSIL';


const renderTitleHeader = (data:any) => {
    return <p style={{ color:getSILURLParams()?.includes("bru")?"#5383d3":"#a73030",font: 'Cookie', fontSize:'14px'}}>{data.column.caption}</p>
}

function StyledComponent(cellData:any):any {
    if(cellData.column.dataField === 'customer'){
        if(cellData.data.active === 'No'){
                return <div className={"devex-styled-grid-row-red"}>{cellData.value}</div>;
        }
        if(cellData.data.on_Hold === 'Yes'){
            return <div className={"devex-styled-grid-row-red"}>{cellData.value}</div>;
        }
        return <div className={"devex-styled-grid-row-default"}>{cellData.value}</div>;
    }
    return (
        <div className={"devex-styled-grid-row-default"}>{cellData.value}</div>
    )
}
const filterBuilderPopupPosition = {
    of: window,
    at: 'top',
    my: 'top',
    offset: { y: 10 },
  };

interface IProps {
    history:any;
    refreshGrid:any;
}
interface IState {
    products:any[];
    showProductEditForm:boolean;
    showPagination:boolean;
    pageSize:number;
    searchValue:string;
}
var globalProductID:any;
var all_products:any[] = [];
var productscopy:any;
export default class ProductTable extends React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);
        this.state = {
           products:[],showProductEditForm:false,showPagination:true,pageSize:10, searchValue: ''
        }
        this.handleProductEdit = this.handleProductEdit.bind(this);
    }
    handleProductEdit(data:any,productID:number) {
        globalProductID = productID;
        this.setState({showProductEditForm:true, showPagination:false});
        //this.props.history.push('/simos/viewProducts/editProduct/id='+productID);
        window.scrollTo({behavior:'smooth',top:0});
    }

    closePopup() {
        this.setState({showProductEditForm:false, showPagination:true});
        this.props.history.push('/simos/products' + getSILURLParams());
        this.props.refreshGrid();
    }

    setSearchResult(newSearch:any){
        this.setState({products:newSearch});
    }
    setPageSize(newPageSize:number){
        this.setState({pageSize: newPageSize});
    }
    async componentDidMount() {
        let allProducts:string[] = [];
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token: '19515195-d571-44a3-b0db-637f5bf24f54' })
        }
        fetch('https://api2.propsense.com/api/GetSIMOSProductName', requestOptions)
        .then(async response => {
            const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
            // error response
            if(!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            //no error
            // console.log(data)
            data.map((products:any) => {
                if(allProducts.indexOf(products.description)===-1 && products.description!="" &&products.description!=null){
                    allProducts.push(products);
                }
            })
            var d:any[] = []
            d = allProducts.filter(function(obj:any, pos:any){
                return allProducts.indexOf(obj) === pos;
            })
            console.log(d);
            all_products = allProducts;
            this.setState({products:d});
        })
        .catch(error => {
            console.log('There was an error!', error);
        });
    }

    handleValueChange = (e:any) => {
        // console.log(e);
        const trimmedValue = e.trim();
        this.setState({searchValue: trimmedValue});
      };

    render() {
        var columns:any[] = [];
        // this.state.products.map((product, ind) => {
        //     columns.push({field: 'edit', headerName: 'Edit', sortable: false,filterable: false,width:200, headerClassName: 'gridview-th', renderCell:(cellValues:any) => {
        //         return <button onClick={this.handleProductEdit.bind(this,cellValues.id)}  id="edit-grid-table"><FaPencilAlt/></button>
        //     }});
        //     columns.push({field: 'description',  headerName: 'Product Name', width:600,headerClassName: 'gridview-th'});
        // })
        productscopy = this.state.products;
        // let allpages = Math.round(this.state.products.length /10);
        // let filters=['']
        return (
            <div className='grid-view'>
            <br></br><br></br>
            <div style={{width:'100%'}}>
                <DataGrid
                className="data-grid-table"
                    focusedRowEnabled={true}
                 hoverStateEnabled={true}
                 rowAlternationEnabled={true}
                    dataSource={this.state.products}
                    keyExpr="id"
                    showBorders={true}
                    // took out height={740}
                    width={'100%'} >
                    <SearchPanel visible={true}
                    width={340}
                    placeholder="Search for..." 
                    text={this.state.searchValue}
                    onTextChange={this.handleValueChange}
                    />    
          <Paging enabled={true} defaultPageSize={10}/>
          <Pager
            visible={true}
            allowedPageSizes={[10,15,20]}
            displayMode={'full'}
            showPageSizeSelector={true}
            showInfo={true}
            showNavigationButtons={true} />
            <FilterRow visible={true}></FilterRow>
            <FilterBuilderPopup position={filterBuilderPopupPosition} />
          <HeaderFilter visible={true} allowSearch={true} />
          <FilterPanel visible={true} />
          <Export enabled={true} allowExportSelectedData={true} />
          <Column type="buttons" caption="Edit" headerCellRender={renderTitleHeader}>
              <Button text="edit" visible={true} onClick={(e:any) =>  {this.handleProductEdit(e.row.data,e.row.data.id)}}><FaPencilAlt/></Button>
            </Column>
            <Column dataField="description"  caption="Description" alignment="left"  cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
            <Column dataField="code" width={100} caption="Code" alignment="left"  cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
            <Column dataField="size" width={100} caption="Size" alignment="left"  cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
            <Column dataField="location" width={150} caption="Location" alignment="left"  cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
            <Column dataField="created" width={250} caption="Created" alignment="left"  cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
            <Column defaultSortOrder={"desc"} dataField="modified" width={250} caption="Modified" alignment="left"  cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
            </DataGrid>
            {this.state.showProductEditForm ? <EditProductLookupForm closePopup={this.closePopup.bind(this)}history={this.props.history}editID={globalProductID} products={this.state.products}></EditProductLookupForm>:null}
                </div>
            </div>
            
        );
    }
}
export {productscopy};