import { IPassTokenWithRequest } from "../token/getTokenConstants";

export enum IGET_PRODUCTION_SUMMARY_REQUEST {
    REQUEST = "getProductionSummary/GET_PRODUCTION_SUMMARY_REQUEST",
    SUCCESS = "getProductionSummary/GET_PRODUCTION_SUMMARY_SUCCESS",
    FAILED = "getProductionSummary/GET_PRODUCTION_SUMMARY_FAILED"
};

export interface IGetProductionSummaryRequest extends IPassTokenWithRequest {
    request: {
        itemId: number;
    }
};

export interface IProductionSummary {
    product: string;
    tonnage: number;
};