import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IGetWashPlantProductionSummaryLoadAction, IGetWashPlantProductionSummaryLoadFailedAction, IGetWashPlantProductionSummarySuccessAction } from "./getWashPlantProductionSummaryActions";
import { IGET_WASH_PLANT_PRODUCTION_SUMMARY_REQUEST, IWashPlantProductionSummary } from "./getWashPlantProductionSummaryConstants";

type Actions =
    IGetWashPlantProductionSummaryLoadAction
    | IGetWashPlantProductionSummaryLoadFailedAction
    | IGetWashPlantProductionSummarySuccessAction
    | IFlushDataSuccessAction;

export const GetWashPlantProductionSummaryReducer = (state: Server<SilAPIResponse<IWashPlantProductionSummary[]>> = notLoaded, action: Actions): Server<SilAPIResponse<IWashPlantProductionSummary[]>> => {
    switch (action.type) {
        case IGET_WASH_PLANT_PRODUCTION_SUMMARY_REQUEST.REQUEST:
            return loading;

        case IGET_WASH_PLANT_PRODUCTION_SUMMARY_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_WASH_PLANT_PRODUCTION_SUMMARY_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};