import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IADD_SIEVE_PRODUCT_REQUEST } from "./addSieveProductConstaints";
import { IAddSieveProductLoadAction, IAddSieveProductLoadFailedAction, IAddSieveProductSuccessAction } from "./addSieveProductActions";

type Actions =
    IAddSieveProductLoadAction
    | IAddSieveProductLoadFailedAction
    | IAddSieveProductSuccessAction
    | IFlushDataSuccessAction;

export const AddSieveProductReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IADD_SIEVE_PRODUCT_REQUEST.REQUEST:
            return loading;

        case IADD_SIEVE_PRODUCT_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IADD_SIEVE_PRODUCT_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};