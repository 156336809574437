import { IWashPlantFeedMaterials } from "../getWashPlantFeedMaterials/getWashPlantFeedMaterialsConstaints";
import { IUpdateWashPlantFeedMaterialRequest, IUPDATE_WASH_PLANT_FEED_MATERIAL_REQUEST } from "./updateWashPlantFeedMaterialConstaints";

export interface IUpdateWashPlantFeedMaterialLoadAction {
    type: IUPDATE_WASH_PLANT_FEED_MATERIAL_REQUEST.REQUEST;
    data: IUpdateWashPlantFeedMaterialRequest
}
export const updateWashPlantFeedMaterialLoadAction = (data: IUpdateWashPlantFeedMaterialRequest): IUpdateWashPlantFeedMaterialLoadAction => ({
    type: IUPDATE_WASH_PLANT_FEED_MATERIAL_REQUEST.REQUEST,
    data
});

export interface IUpdateWashPlantFeedMaterialSuccessAction {
    type: IUPDATE_WASH_PLANT_FEED_MATERIAL_REQUEST.SUCCESS;
    data: IWashPlantFeedMaterials;
}
export const updateWashPlantFeedMaterialLoadSuccessAction = (data: IWashPlantFeedMaterials): IUpdateWashPlantFeedMaterialSuccessAction => ({
    type: IUPDATE_WASH_PLANT_FEED_MATERIAL_REQUEST.SUCCESS,
    data
});
export interface IUpdateWashPlantFeedMaterialLoadFailedAction {
    type: IUPDATE_WASH_PLANT_FEED_MATERIAL_REQUEST.FAILED;
    message: string;
}
export const updateWashPlantFeedMaterialLoadFailedAction = (message: string): IUpdateWashPlantFeedMaterialLoadFailedAction => ({
    type: IUPDATE_WASH_PLANT_FEED_MATERIAL_REQUEST.FAILED,
    message
});
