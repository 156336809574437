import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IGetOrderVersionHistoryLoadAction, IGetOrderVersionHistoryLoadFailedAction, IGetOrderVersionHistorySuccessAction, 
    getOrderVersionHistoryLoadFailedAction, getOrderVersionHistoryLoadSuccessAction
} from "./getOrderVersionHistoryActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IGET_ORDER_VERSION_HISTORY_REQUEST, ISIMOSOrderVersionHistory } from "./getOrderVersionHistoryConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SimosAPIResponse } from "../../../../react/shared/publicInterfaces";

export const getOrderVersionHistoryEpic: Epic = (
    action$: ActionsObservable<IGetOrderVersionHistoryLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IGetOrderVersionHistorySuccessAction | IGetOrderVersionHistoryLoadFailedAction> =>
    action$.ofType(IGET_ORDER_VERSION_HISTORY_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SimosAPIResponse<ISIMOSOrderVersionHistory[]>>(
                    END_POINTS.PROPSENSE.SIMOS.GET_ORDER_VERSION_HISTORY,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SimosAPIResponse<ISIMOSOrderVersionHistory[]>): IGetOrderVersionHistorySuccessAction | IGetOrderVersionHistoryLoadFailedAction => {
                            if(response.message !== "Success")
                                return getOrderVersionHistoryLoadFailedAction(response.message);
                            return getOrderVersionHistoryLoadSuccessAction(response);
                        }),
                        catchError(() => ActionsObservable.of(getOrderVersionHistoryLoadFailedAction("Unable to get order version history")))
                    )
            )
        );