import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import { ById, SilAPIResponse } from "../../../shared/publicInterfaces";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import { callRouteWithQueryString, userName } from "../../../shared/constExports";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { IDownTime, IGetProductionRecordsRequest, IProductionData, IProductionRecords } from "../../../../redux/sil/dryPlant/getProductionRecords/getProductionRecordsConstaints";
import { getProductionRecordsLoadAction } from "../../../../redux/sil/dryPlant/getProductionRecords/getProductionRecordsActions";
import { getProductionRecordsStatus } from "../../../../redux/sil/dryPlant/getProductionRecords/getProductionRecordsAccessor";
import { LAButton } from "../../../shared/buttons";
import { ROUTE } from "../../../routes";
import { AddIcon } from "../../../shared/icons";
import { WHITE_COLOR } from "../../../shared/theme";
import { IGetProductionLookupsRequest, IProductionLookups } from "../../../../redux/sil/dryPlant/getProductionLookups/getProductionLookupsConstaints";
import { getProductionLookupsLoadAction } from "../../../../redux/sil/dryPlant/getProductionLookups/getProductionLookupsActions";
import { getProductionLookupsStatus } from "../../../../redux/sil/dryPlant/getProductionLookups/getProductionLookupsAccessor";
import PageSpacing from "../../../shared/pageSpacing";
import { DowntimeDataPopup, ProductionDataPopup } from "./productionPopups";
import { getProductionSummaryStatus } from "../../../../redux/sil/dryPlant/getProductionSummary/getProductionSummaryAccessor";
import { getProductionSummaryLoadAction } from "../../../../redux/sil/dryPlant/getProductionSummary/getProductionSummaryActions";
import { IGetProductionSummaryRequest, IProductionSummary } from "../../../../redux/sil/dryPlant/getProductionSummary/getProductionSummaryConstants";
import LALoading from "../../../shared/loading";
import LAErrorBox from "../../../shared/errorBox";
import SilRoleCheck from "../../../shared/silRoleCheck";


interface IDryPlantItemsStoreProps {
    getToken: Server<SilAPIResponse<IToken>>;
    getItems: Server<SilAPIResponse<ById<IProductionRecords>>>;
    getDropDownData: Server<SilAPIResponse<IProductionLookups>>;
    getProductionSummary: Server<SilAPIResponse<IProductionSummary[]>>;
};

interface IDryPlantItemsDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getItemsRequest: (data: IGetProductionRecordsRequest) => unknown;
    getProductionSummaryRequest: (data: IGetProductionSummaryRequest) => unknown;
    getDropDownDataRequest: (data: IGetProductionLookupsRequest) => unknown;
};


interface IDryPlantItemsOwnProps {

};

interface IDryPlantItemsState {
    downtimePopup: IDownTime[] | undefined;
    productionPopup: IProductionData[] | undefined;
};

const DryPlantItemsStyles = styled(LAPaperWithPadding)`
 
    word-break: break-word;

    .pull-left {
        top: 2%;
        left: 3%;
        height: 3%;
        position: absolute;
    };

    .pro-popup {
        display: none;
    };
    
   
    .pro-item:hover .pro-popup  {
        display: block;
        position: fixed;
        z-index: 20;
        left: 20%;
        top: 30%;
        width: 30%;
        height: auto;
        overflow: auto;
    };
`;

type IDryPlantItemsProps = RouteComponentProps
    & IDryPlantItemsStoreProps
    & IDryPlantItemsDispatchProps
    & IDryPlantItemsOwnProps;

class DryPlantItems extends PureComponent<IDryPlantItemsProps, IDryPlantItemsState> {

    public constructor(props: IDryPlantItemsProps) {
        super(props);
        this.state = {
            downtimePopup: undefined,
            productionPopup: undefined
        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: IDryPlantItemsProps): void {
        if (this.props !== prevProps)
            this.callServer();
    };


    public render(): ReactNode {

        const { getItems, getProductionSummary } = this.props;
        const { downtimePopup, productionPopup } = this.state;
        const data = hasPayload(getItems) ? Object.values(getItems.payload.response) : [];
        const summary = hasPayload(getProductionSummary) ? getProductionSummary.payload.response : [];

        return (
            <PageSpacing title="SIL - Dry Plant Items" description="SIL - Dry Plant Items" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="dryPlantAccess">
                    <DryPlantItemsStyles>

                        <LAButton className="pull-left" startIcon={<AddIcon color={WHITE_COLOR} />} label="Add" onClick={this.handleAdd} />
                        <h2 className="text-center">DRY PLANT PRODUCTION RUNS</h2>
                        <hr />

                        {getItems.kind === STATUS_ENUM.LOADING && <LALoading message="Loading Production Data" />}

                        {getItems.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load data.. Please refresh window or contact IT" />}

                        {getItems.kind === STATUS_ENUM.SUCCEEDED && <LADevExtremeGrid
                            data={data}
                            onEdit={this.onEdit}
                            onClick={this.onClick}
                            searchPanel={true}
                            filterHeader={true}
                            height={1200}
                            export={true}
                            onCustomClick={this.handleCustom}
                            exportFileName={"Dry Plant Productions"}
                            storageKey="silDryPlantProductionsStorageKey"
                            columns={[
                                { name: "date", caption: "Date", type: "date", width: 130, sortDesc: true },
                                { name: "location", caption: "Location", type: "string", width: 130 },
                                { name: "shift_Worked", caption: "Shift Worked", type: "string", width: 80 },
                                { name: "plants", caption: "Plants", type: "string", width: 150 },
                                { name: "control_Room", caption: "Control Room", type: "string", width: 130 },
                                { name: "dryplant_Operator", caption: "Plant Operator", type: "string", width: 80 },
                                { name: "load_Operator", caption: "Load Operator", type: "string", width: 80 },
                                { name: "production", caption: "Production Data", type: "button", width: 150 },
                                { name: "downtime", caption: "DownTime", type: "button", width: 150 },
                                { name: "production_Shift_Goal", caption: "Production Shift Goal", type: "string", width: 150 },
                                { name: "production_Notes", caption: "Production Notes", type: "string" },
                                { name: "qA_QC_Notes", caption: "QA QC Notes", type: "string", width: 150 }
                            ]}
                        />}

                    </DryPlantItemsStyles>

                    {productionPopup &&
                        <ProductionDataPopup
                            summary={summary}
                            data={productionPopup}
                            onClose={this.handlePopupClose}
                            open={productionPopup ? true : false}
                        />}

                    {downtimePopup &&
                        <DowntimeDataPopup
                            data={downtimePopup}
                            onClose={this.handlePopupClose}
                            open={downtimePopup ? true : false}
                        />}
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private handlePopupClose = (): void => {
        this.setState({ productionPopup: undefined, downtimePopup: undefined });
    };

    private handleCustom = (name: string, column: any): void => {
        const data: IProductionRecords = column.row.data;
        switch (name) {
            case "production":
                if (hasPayload(this.props.getToken))
                    this.props.getProductionSummaryRequest({
                        token: this.props.getToken.payload.response.token,
                        request: {
                            itemId: data.id
                        }
                    });
                this.setState({ productionPopup: data.production_Data });
                break;

            case "downtime":
                this.setState({ downtimePopup: data.downtimes });
                break;
        }
    };

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString() },
            pathName: ROUTE.SIL.DRY_PLANT.DRY_PLANT_ITEM
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0" },
            pathName: ROUTE.SIL.DRY_PLANT.DRY_PLANT_ITEM
        });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getDropDownData))
            this.props.getDropDownDataRequest({
                token: this.props.getToken.payload.response.token,
                requested_Page: "Dryplant"
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getItems))
            this.props.getItemsRequest({
                token: this.props.getToken.payload.response.token
            });
    }
}

const mapStateToProps = (state: IStore): IDryPlantItemsStoreProps => ({
    getToken: getTokenStatus(state),
    getItems: getProductionRecordsStatus(state),
    getDropDownData: getProductionLookupsStatus(state),
    getProductionSummary: getProductionSummaryStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IDryPlantItemsDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getItemsRequest: (data: IGetProductionRecordsRequest) => dispatch(getProductionRecordsLoadAction(data)),
    getProductionSummaryRequest: (data: IGetProductionSummaryRequest) => dispatch(getProductionSummaryLoadAction(data)),
    getDropDownDataRequest: (data: IGetProductionLookupsRequest) => dispatch(getProductionLookupsLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DryPlantItems);