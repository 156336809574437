import { SimosAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { IGET_SIMOS_CUSTOMER_ADDRESS_REQUEST, ISimosCustomerAddress, ISimosCustomerAddressRequest } from "./getSimosCustomerAddressConstants";


export interface IGetSimosCustomerAddressLoadAction {
    type: IGET_SIMOS_CUSTOMER_ADDRESS_REQUEST.REQUEST;
    data: ISimosCustomerAddressRequest
}
export const getSimosCustomerAddressLoadAction = (data: ISimosCustomerAddressRequest): IGetSimosCustomerAddressLoadAction => ({
    type: IGET_SIMOS_CUSTOMER_ADDRESS_REQUEST.REQUEST,
    data
});
export interface IGetSimosCustomerAddressSuccessAction {
    type: IGET_SIMOS_CUSTOMER_ADDRESS_REQUEST.SUCCESS;
    data: SimosAPIResponse<ISimosCustomerAddress[]>;
}
export const getSimosCustomerAddressLoadSuccessAction = (data: SimosAPIResponse<ISimosCustomerAddress[]>): IGetSimosCustomerAddressSuccessAction => ({
    type: IGET_SIMOS_CUSTOMER_ADDRESS_REQUEST.SUCCESS,
    data
});
export interface IGetSimosCustomerAddressLoadFailedAction {
    type: IGET_SIMOS_CUSTOMER_ADDRESS_REQUEST.FAILED;
    message: string;
}
export const getSimosCustomerAddressLoadFailedAction = (message: string): IGetSimosCustomerAddressLoadFailedAction => ({
    type: IGET_SIMOS_CUSTOMER_ADDRESS_REQUEST.FAILED,
    message
});
