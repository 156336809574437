import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IAddYHDCarrierFleetTruckLoadAction, IAddYHDCarrierFleetTruckLoadFailedAction, IAddYHDCarrierFleetTruckSuccessAction, addYHDCarrierFleetTruckLoadFailedAction,
     addYHDCarrierFleetTruckLoadSuccessAction } from "./addCarrierFleetTruckActions";
import { IStore } from "../../../../reducers";
import { EpicDependencies } from "../../../../store";
import { IADD_YHD_CARRIER_FLEET_TRUCK_REQUEST } from "./addCarrierFleetTruckConstaints";
import { END_POINTS } from "../../../../endpoints";
import { withJsonContentType } from "../../../../epicUtils";
import { YHDAPIResponse } from "../../../../../react/shared/publicInterfaces";

export const addYHDCarrierFleetTruckEpic: Epic = (
    action$: ActionsObservable<IAddYHDCarrierFleetTruckLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IAddYHDCarrierFleetTruckSuccessAction | IAddYHDCarrierFleetTruckLoadFailedAction> =>
    action$.ofType(IADD_YHD_CARRIER_FLEET_TRUCK_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<YHDAPIResponse<string>>(
                    END_POINTS.PROPSENSE.YHD.CARRIER.ADD_CARRIER_FLEET_TRUCKS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: YHDAPIResponse<string>): IAddYHDCarrierFleetTruckSuccessAction | IAddYHDCarrierFleetTruckLoadFailedAction => {
                            
                            if(response.message === "Success"){
                                return addYHDCarrierFleetTruckLoadSuccessAction(response.message);
                            } else {
                                return addYHDCarrierFleetTruckLoadFailedAction(response.message);
                            }
                        }),
                        catchError(() => ActionsObservable.of(addYHDCarrierFleetTruckLoadFailedAction("Unable to add your changes")))
                    )
            )
        );