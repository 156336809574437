import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithLessPadding, LAPaperWithPadding } from "../../../../shared/paper";
import { IDispatch, IStore } from "../../../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../../redux/server";
import { SimosAPIResponse } from "../../../../shared/publicInterfaces";
import { IGetTokenRequest, IToken } from "../../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenLoadAction } from "../../../../../redux/sil/dryPlant/token/getTokenActions";
import { NotApplicable, callRouteWithQueryString, pageAccessCheck, userName } from "../../../../shared/constExports";
import { LADevExtremeGrid } from "../../../../shared/devExtreme";
import { getTokenStatus } from "../../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { LAButton } from "../../../../shared/buttons";
import { AddIcon } from "../../../../shared/icons";
import { MEDIA_QUERY_PHONE, WHITE_COLOR } from "../../../../shared/theme";
import { ROUTE } from "../../../../routes";
import PageSpacing from "../../../../shared/pageSpacing";
import LALoading from "../../../../shared/loading";
import { ISimosTransportCompanies, ISimosTransportCompaniesRequest } from "../../../../../redux/propsense/simos/transportCompanies/getSimosTransportCompanies/getSimosTransportCompaniesConstants";
import LAErrorBox from "../../../../shared/errorBox";
import { getSimosTransportCompaniesLoadAction } from "../../../../../redux/propsense/simos/transportCompanies/getSimosTransportCompanies/getSimosTransportCompaniesActions";
import { getSimosTransportCompanies } from "../../../../../redux/propsense/simos/transportCompanies/getSimosTransportCompanies/getSimosTransportCompaniesAccessor";
import LAGrid from "../../../../shared/grid";
import LAGridItem from "../../../../shared/gridList";
import { SimosSubHeader, SimosSubHeaderMobile } from "../../../../header/simosHeader";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import queryString from "query-string";

interface ISimosTransportCompaniesStoreProps {
    getToken: Server<SimosAPIResponse<IToken>>;
    getSimosTransportCompaniesList: Server<SimosAPIResponse<ISimosTransportCompanies[]>>;
};

interface ISimosTransportCompaniesDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getSimosTransportCompaniesListRequest: (data: ISimosTransportCompaniesRequest) => unknown;
};


interface ISimosTransportCompaniesOwnProps {

};

interface ISimosTransportCompaniesState {
    activeView: boolean;
};

const SimosTransportCompaniesStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .loading {
        margin-top: 5%;
    };

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
    };

    .show-on-phone {
        display: none;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 5px 5px;

        .title {
            padding-top: 11%;
        };

        .pull-left {
            position: absolute;
            left: 3%;
            height: 3%;
            top: 1%;
        };

        .hide-on-phone, .hide-on-phone * {
            display: none;
        };

        .show-on-phone {
            display: block;
        };
    };
`;

type ISimosTransportCompaniesProps = RouteComponentProps
    & ISimosTransportCompaniesStoreProps
    & ISimosTransportCompaniesDispatchProps
    & ISimosTransportCompaniesOwnProps;

class SimosTransportCompanies extends PureComponent<ISimosTransportCompaniesProps, ISimosTransportCompaniesState> {

    public constructor(props: ISimosTransportCompaniesProps) {
        super(props);
        this.state = {
            activeView: true
        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: ISimosTransportCompaniesProps): void {
        if (this.props !== prevProps) {
            this.callServer();
        };
    };


    public render(): ReactNode {

        const { activeView } = this.state;
        const { getSimosTransportCompaniesList , getToken} = this.props;
        const data = hasPayload(getSimosTransportCompaniesList) ? Object.values(getSimosTransportCompaniesList.payload.response) : [];
        const getRole = hasPayload(getToken) && getToken.payload.response.simosAccess.access;
        const filteredData = activeView ? data.filter(x => x.active === "Yes") : data;
        // console.log(data)

        return (
            <PageSpacing title="SIMOS - Transport Companies" description="SIMOS - Transport Companies" fixedSpaceOnSmallerScreens={true}>
                {(getRole !== NotApplicable) &&
                    <SimosTransportCompaniesStyles>

                        <div className="hide-on-phone">
                            <SimosSubHeader
                                {...this.props}
                            />
                        </div>

                        <div className="show-on-phone">
                            <SimosSubHeaderMobile
                                {...this.props}
                            />
                        </div>

                        <LAPaperWithPadding>
                            <LAGrid>
                                <LAGridItem xs={12}>
                                    <LAButton 
                                        className="pull-left" 
                                        startIcon={<AddIcon color={WHITE_COLOR} />} 
                                        label="Add" 
                                        onClick={this.handleAdd} 
                                    />
                                    <br></br>
                                    <h2 className="text-center">SIMOS TRANSPORT COMPANIES</h2>
                                    <hr />
                                    
                                    {getSimosTransportCompaniesList.kind === STATUS_ENUM.LOADING && <LALoading className="loading" message="Loading..." />}
                                    {getSimosTransportCompaniesList.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load Transport Companies..." />}

                                    {getSimosTransportCompaniesList.kind === STATUS_ENUM.SUCCEEDED && 
                                        <LAGrid>
                                            <LAGridItem xs={12}>
                                                <RadioGroup className="view-btn" row aria-label="" name="radioGroup" value={activeView ? "active" : "all"} onChange={this.onViewClick}>
                                                    <FormControlLabel value="active" control={<Radio />} label="Active Items" />
                                                    <FormControlLabel value="all" control={<Radio />} label="All Items" />
                                                </RadioGroup>
                                            </LAGridItem>
                                            <LAGridItem xs={12} className="text-center">
                                                <LADevExtremeGrid
                                                    data={filteredData}
                                                    onEdit={this.onEdit}
                                                    onClick={this.onClick}
                                                    height={1000}
                                                    searchPanel={true}
                                                    actionWidth={120}
                                                    filterHeader={true}
                                                    export={true}
                                                    id="SimosTransportCompaniesList"
                                                    exportFileName="SimosTransportCompanies"
                                                    storageKey="SimosTransportCompaniesStorageKey"
                                                    columns={[                               
                                                        { name: "transport_Company", caption: "Transport Company", type: "string" },
                                                        { name: "email", caption: "Email", type: "string" },
                                                        { name: "active", caption: "Active", type: "string" },
                                                        { name: "truck_Code_Y", caption: "Truck Code Y", type: "string" },
                                                        { name: "truck_Code_Z", caption: "Truck Code Z", type: "string" },
                                                        { name: "created", caption: "Created", type: "datetime" },
                                                        { name: "created_By", caption: "Created By", type: "string" },
                                                        { name: "modified", caption: "Modified", type: "datetime" },
                                                        { name: "modified_By", caption: "Modified By", type: "string" }
                                                    ]}
                                                />
                                            </LAGridItem>
                                        </LAGrid>
                                    }
                                </LAGridItem>
                            </LAGrid>
                        </LAPaperWithPadding>
                    </SimosTransportCompaniesStyles>
                }
            </PageSpacing>
        );
    }

    private onViewClick = (): void => {
        this.setState({ activeView: !this.state.activeView });
    };

    private onEdit = (e: any): void => {
        const query = queryString.parse(this.props.location.search);
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString(), page: query.page ? query.page.toString() : "" },
            pathName: ROUTE.SIMOS.TRANSPORTCOMPANIES.ADDUPDATETRANSPORTCOMPANY
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        const query = queryString.parse(this.props.location.search);
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0", page: query.page ? query.page.toString() : ""},
            pathName: ROUTE.SIMOS.TRANSPORTCOMPANIES.ADDUPDATETRANSPORTCOMPANY
        });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });

        if (hasPayload(this.props.getToken)) {
            if (this.props.getToken.payload.response.simosAccess.access !== NotApplicable) {
                if (isNotLoaded(this.props.getSimosTransportCompaniesList)) {
                    this.getDataForTable();
                };
            } else {
                this.props.history.push({
                    pathname: ROUTE.SIMOS.INDEX,
                });
            };
        };
    }

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken)) {
            this.props.getSimosTransportCompaniesListRequest({
                token: this.props.getToken.payload.response.token
            });
        };
    };

}

const mapStateToProps = (state: IStore): ISimosTransportCompaniesStoreProps => ({
    getToken: getTokenStatus(state),
    getSimosTransportCompaniesList: getSimosTransportCompanies(state)
});

const mapDispatchToProps = (dispatch: IDispatch): ISimosTransportCompaniesDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getSimosTransportCompaniesListRequest: (data: ISimosTransportCompaniesRequest) => dispatch(getSimosTransportCompaniesLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(SimosTransportCompanies);