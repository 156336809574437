import { IAddSieveProductRequest, IADD_SIEVE_PRODUCT_REQUEST } from "./addSieveProductConstaints";

export interface IAddSieveProductLoadAction {
    type: IADD_SIEVE_PRODUCT_REQUEST.REQUEST;
    data: IAddSieveProductRequest
}
export const addSieveProductLoadAction = (data: IAddSieveProductRequest): IAddSieveProductLoadAction => ({
    type: IADD_SIEVE_PRODUCT_REQUEST.REQUEST,
    data
});

export interface IAddSieveProductSuccessAction {
    type: IADD_SIEVE_PRODUCT_REQUEST.SUCCESS;
    message: string;
}
export const addSieveProductLoadSuccessAction = (message: string): IAddSieveProductSuccessAction => ({
    type: IADD_SIEVE_PRODUCT_REQUEST.SUCCESS,
    message
});

export interface IAddSieveProductLoadFailedAction {
    type: IADD_SIEVE_PRODUCT_REQUEST.FAILED;
    message: string;
}
export const addSieveProductLoadFailedAction = (message: string): IAddSieveProductLoadFailedAction => ({
    type: IADD_SIEVE_PRODUCT_REQUEST.FAILED,
    message
});
