import { IPassTokenWithRequest } from "../../dryPlant/token/getTokenConstants";

export enum IGET_SIEVES_CALIBRATIONS_REQUEST {
    REQUEST = "getSieveCalibrations/GET_SIEVES_CALIBRATIONS_REQUEST",
    SUCCESS = "getSieveCalibrations/GET_SIEVES_CALIBRATIONS_SUCCESS",
    FAILED = "getSieveCalibrations/GET_SIEVES_CALIBRATIONS_FAILED"
};

export interface IGetSieveCalibrationsRequest extends IPassTokenWithRequest {

};

export interface ISieveCalibration {
    id: number;
    date: string;
    operator?: string;
    product: string;
    plant: string;
    notes?: string;
    initial_Mass?: number;
    created: string;
    modified: string;
    created_By: string;
    modified_By: string;
    measurements: ISieveCalibrationMeasurement[];
};

export interface ISieveCalibrationMeasurement {
    id: number;
    listID: number;
    sieve_Serial_No: string;
    astM_Serial_No: string;
    master_Load: number;
    working_Load: number;
    difference: number;
    relative_Difference: number;
    absolute_Difference: number;
    absolute_Relative_Difference: number;
    created: string;
    modified: string;
    created_By: string;
    modified_By: string;
}