import { YHDAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { IGetYHDCarrierInfractionsRequest, IGET_YHD_CARRIER_INFRACTIONS_REQUEST, IYHDCarrierInfractions } from "./getCarrierInfractionsConstaints";

export interface IGetYHDCarrierInfractionsLoadAction {
    type: IGET_YHD_CARRIER_INFRACTIONS_REQUEST.REQUEST;
    data: IGetYHDCarrierInfractionsRequest
}
export const getYHDCarrierInfractionsLoadAction = (data: IGetYHDCarrierInfractionsRequest): IGetYHDCarrierInfractionsLoadAction => ({
    type: IGET_YHD_CARRIER_INFRACTIONS_REQUEST.REQUEST,
    data
});

export interface IGetYHDCarrierInfractionsSuccessAction {
    type: IGET_YHD_CARRIER_INFRACTIONS_REQUEST.SUCCESS;
    data: YHDAPIResponse<IYHDCarrierInfractions[]>;
}
export const getYHDCarrierInfractionsLoadSuccessAction = (data: YHDAPIResponse<IYHDCarrierInfractions[]>): IGetYHDCarrierInfractionsSuccessAction => ({
    type: IGET_YHD_CARRIER_INFRACTIONS_REQUEST.SUCCESS,
    data
});

export interface IGetYHDCarrierInfractionsLoadFailedAction {
    type: IGET_YHD_CARRIER_INFRACTIONS_REQUEST.FAILED;
    message: string;
}
export const getYHDCarrierInfractionsLoadFailedAction = (message: string): IGetYHDCarrierInfractionsLoadFailedAction => ({
    type: IGET_YHD_CARRIER_INFRACTIONS_REQUEST.FAILED,
    message
});
