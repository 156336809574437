import { IPassTokenWithRequest } from "../../dryPlant/token/getTokenConstants";
import { ITrailerActivityLog } from "../getTrailerActivityLogs/getTrailerActivityLogsConstaints";


export enum IUPDATE_TRAILER_ACTIVITY_LOG_REQUEST {
    REQUEST = "updateTrailerActivityLog/UPDATE_TRAILER_ACTIVITY_LOG_REQUEST",
    SUCCESS = "updateTrailerActivityLog/UPDATE_TRAILER_ACTIVITY_LOG_SUCCESS",
    FAILED = "updateTrailerActivityLog/UPDATE_TRAILER_ACTIVITY_LOG_FAILED"
};

export interface IUpdateTrailerActivityLogRequest extends IPassTokenWithRequest {
    request: ITrailerActivityLog;
};