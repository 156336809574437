import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import { ById, SilAPIResponse } from "../../../shared/publicInterfaces";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import { callRouteWithQueryString, userName } from "../../../shared/constExports";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { LAButton } from "../../../shared/buttons";
import { AddIcon } from "../../../shared/icons";
import { WHITE_COLOR } from "../../../shared/theme";
import { ROUTE } from "../../../routes";
import PageSpacing from "../../../shared/pageSpacing";
import SilRoleCheck from "../../../shared/silRoleCheck";
import { IGetSieveCalibrationsRequest, ISieveCalibration } from "../../../../redux/sil/sieves/getSievesCalibrations/getSievesCalibrationsConstaints";
import { getSieveCalibrationsLoadAction } from "../../../../redux/sil/sieves/getSievesCalibrations/getSievesCalibrationsActions";
import { getSieveCalibrationsStatus } from "../../../../redux/sil/sieves/getSievesCalibrations/getSievesCalibrationsAccessor";
import LALoading from "../../../shared/loading";


interface ISievesCalibrationsStoreProps {
    getToken: Server<SilAPIResponse<IToken>>;
    getSievesCalibrations: Server<SilAPIResponse<ById<ISieveCalibration>>>;
};

interface ISievesCalibrationsDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getSievesCalibrationsRequest: (data: IGetSieveCalibrationsRequest) => unknown;
};


interface ISievesCalibrationsOwnProps {

};

interface ISievesCalibrationsState {
    data: ISieveCalibration[];
};

const SievesCalibrationsStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
    };
`;

type ISievesCalibrationsProps = RouteComponentProps
    & ISievesCalibrationsStoreProps
    & ISievesCalibrationsDispatchProps
    & ISievesCalibrationsOwnProps;

class SievesCalibrations extends PureComponent<ISievesCalibrationsProps, ISievesCalibrationsState> {

    public constructor(props: ISievesCalibrationsProps) {
        super(props);
        this.state = {
            data: []
        };
    }

    public componentDidMount(): void {
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: ISievesCalibrationsProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();
        };
    };


    public render(): ReactNode {

        const { data } = this.state;
        const { getSievesCalibrations } = this.props;

        return (
            <PageSpacing title="SIL - Sieves Calibrations" description="SIL - Sieves Calibrations" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="sieveAccess">
                    <SievesCalibrationsStyles>

                        {getSievesCalibrations.kind !== STATUS_ENUM.LOADING &&
                            <SilRoleCheck error={false} roleFor="sieveAccess">
                                <LAButton className="pull-left" startIcon={<AddIcon color={WHITE_COLOR} />} label="Add" onClick={this.handleAdd} />
                            </SilRoleCheck>}

                        <h2 className="text-center">CALIBRATIONS</h2>
                        <hr />

                        {getSievesCalibrations.kind === STATUS_ENUM.LOADING &&
                            <LALoading className="loading" message="Loading..." />}

                        {getSievesCalibrations.kind !== STATUS_ENUM.LOADING && <LADevExtremeGrid
                            data={data}
                            onEdit={this.onEdit}
                            onClick={this.onClick}
                            height={1000}
                            searchPanel={true}
                            filterHeader={true}
                            export={true}
                            exportFileName={"SievesCalibrations"}
                            storageKey="silSievesCalibrationsStorageKey"
                            columns={[
                                { name: "date", caption: "Date", type: "date", sortAsc: true },
                                { name: "operator", caption: "Operator", type: "string" },
                                { name: "product", caption: "Product", type: "string" },
                                { name: "plant", caption: "Plant", type: "string" },
                                { name: "notes", caption: "Notes", type: "string" },
                                { name: "initial_Mass", caption: "Initial Mass", type: "number" },
                                { name: "created", caption: "Created", type: "date" },
                                { name: "created_By", caption: "Created By", type: "string" }
                            ]}
                        />}
                    </SievesCalibrationsStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString() },
            pathName: ROUTE.SIL.SIEVES.SIEVES_CALIBRATION
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0" },
            pathName: ROUTE.SIL.SIEVES.SIEVES_CALIBRATION
        });
    };

    private setDataToState = (): void => {

        if (hasPayload(this.props.getSievesCalibrations)) {
            const data: ISieveCalibration[] = Object.values(this.props.getSievesCalibrations.payload.response);
            this.setState({ data });
        };

        if (isNotLoaded(this.props.getSievesCalibrations))
            this.callServer();
    };

    private callServer = (): void => {
        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getSievesCalibrations))
            this.props.getSievesCalibrationsRequest({
                token: this.props.getToken.payload.response.token
            });


        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });
    }

}

const mapStateToProps = (state: IStore): ISievesCalibrationsStoreProps => ({
    getToken: getTokenStatus(state),
    getSievesCalibrations: getSieveCalibrationsStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): ISievesCalibrationsDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getSievesCalibrationsRequest: (data: IGetSieveCalibrationsRequest) => dispatch(getSieveCalibrationsLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(SievesCalibrations);