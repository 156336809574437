import { IUPDATE_SIMOS_PRODUCT_REQUEST, IUpdateSimosProductRequest } from "./updateSimosProductConstaints";

export interface IUpdateSimosProductLoadAction {
    type: IUPDATE_SIMOS_PRODUCT_REQUEST.REQUEST;
    data: IUpdateSimosProductRequest
}
export const updateSimosProductLoadAction = (data: IUpdateSimosProductRequest): IUpdateSimosProductLoadAction => ({
    type: IUPDATE_SIMOS_PRODUCT_REQUEST.REQUEST,
    data
});

export interface IUpdateSimosProductSuccessAction {
    type: IUPDATE_SIMOS_PRODUCT_REQUEST.SUCCESS;
    message: string;
}
export const updateSimosProductLoadSuccessAction = (message: string): IUpdateSimosProductSuccessAction => ({
    type: IUPDATE_SIMOS_PRODUCT_REQUEST.SUCCESS,
    message
});

export interface IUpdateSimosProductLoadFailedAction {
    type: IUPDATE_SIMOS_PRODUCT_REQUEST.FAILED;
    message: string;
}
export const updateSimosProductLoadFailedAction = (message: string): IUpdateSimosProductLoadFailedAction => ({
    type: IUPDATE_SIMOS_PRODUCT_REQUEST.FAILED,
    message
});
