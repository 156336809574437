import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, isSucceeded, Server } from "../../../../redux/server";
import { ById, SilAPIResponse } from "../../../shared/publicInterfaces";
import { userName, undefinedFunction, yesOrNoOptions, ZEROTH } from "../../../shared/constExports";
import { LAButton, LASaveAndCancelButton } from "../../../shared/buttons";
import { ArrowLeftIcon } from "../../../shared/icons";
import { WHITE_COLOR } from "../../../shared/theme";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import LATextField from "../../../shared/textField";
import LAAutoComplete from "../../../shared/autoComplete";
import { ROUTE } from "../../../routes";
import queryString from "query-string";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import { FieldValidator, FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../../shared/fieldValidator";
import PageSpacing from "../../../shared/pageSpacing";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import SilRoleCheck from "../../../shared/silRoleCheck";
import { convertList } from "../washPlant/washPlantItemForm";
import { LADateTimeMUIPicker } from "../../../shared/dateTimePickerMUI";
import { IChemicalAnalyses, IGetChemicalAnalysesRequest } from "../../../../redux/sil/exploration/chemicalAnalyses/getChemicalAnalyses/getChemicalAnalysesConstaints";
import { IGetSieveLookupsRequest, ISieveLookup } from "../../../../redux/sil/sieves/getSieveLookups/getSieveLookupsConstaints";
import { IUpdateChemicalAnalysesRequest } from "../../../../redux/sil/exploration/chemicalAnalyses/updateChemicalAnalyses/updateChemicalAnalysesConstaints";
import { IAddChemicalAnalysesRequest, IAddUpdateChemicalAnalyses } from "../../../../redux/sil/exploration/chemicalAnalyses/addChemicalAnalyses/addChemicalAnalysesConstaints";
import { getChemicalAnalysesStatus } from "../../../../redux/sil/exploration/chemicalAnalyses/getChemicalAnalyses/getChemicalAnalysesAccessor";
import { updateChemicalAnalysesStatus } from "../../../../redux/sil/exploration/chemicalAnalyses/updateChemicalAnalyses/updateChemicalAnalysesAccessor";
import { getSieveLookupsStatus } from "../../../../redux/sil/sieves/getSieveLookups/getSieveLookupsAccessor";
import { addChemicalAnalysesStatus } from "../../../../redux/sil/exploration/chemicalAnalyses/addChemicalAnalyses/addChemicalAnalysesAccessor";
import { getSieveLookupsLoadAction } from "../../../../redux/sil/sieves/getSieveLookups/getSieveLookupsActions";
import { updateChemicalAnalysesLoadAction } from "../../../../redux/sil/exploration/chemicalAnalyses/updateChemicalAnalyses/updateChemicalAnalysesActions";
import { addChemicalAnalysesLoadAction } from "../../../../redux/sil/exploration/chemicalAnalyses/addChemicalAnalyses/addChemicalAnalysesActions";
import { getChemicalAnalysesLoadAction } from "../../../../redux/sil/exploration/chemicalAnalyses/getChemicalAnalyses/getChemicalAnalysesActions";


const requiredFields = ["sample_Date", "product", "sampler","sample_Location", "analysis_Date", "analyzed_By", "tests_Ran"];

interface IChemicalAnalysesStoreProps {
    addChemicalAnalyses: Server<string>;
    updateChemicalAnalyses: Server<string>;
    getToken: Server<SilAPIResponse<IToken>>;
    getChemicalAnalyses: Server<SilAPIResponse<IChemicalAnalyses[]>>;
    getChemicalAnalysesLookups: Server<SilAPIResponse<ISieveLookup>>;
};

interface IChemicalAnalysesDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getChemicalAnalysesRequest: (data: IGetChemicalAnalysesRequest) => unknown;
    addChemicalAnalysesRequest: (data: IAddChemicalAnalysesRequest) => unknown;
    updateChemicalAnalysesRequest: (data: IUpdateChemicalAnalysesRequest) => unknown;
    getChemicalAnalysesLookupsRequest: (data: IGetSieveLookupsRequest) => unknown;
};


interface IChemicalAnalysesOwnProps {

};

interface IChemicalAnalysesState {
    data: IChemicalAnalyses;
    errors: ById<IFieldErrorKeyValue>;
};

const ChemicalAnalysesStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;

    .dx-toolbar-after {
        display: none;
    };

    .variancepopup{
        height: 50% !important;
    }
`;

type IChemicalAnalysesProps = RouteComponentProps
    & IChemicalAnalysesStoreProps
    & IChemicalAnalysesDispatchProps
    & IChemicalAnalysesOwnProps;

class ChemicalAnalyses extends PureComponent<IChemicalAnalysesProps, IChemicalAnalysesState> {

    public constructor(props: IChemicalAnalysesProps) {
        super(props);
        this.state = {
            errors: {
                "product": { key: "product", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "sample_Location": { key: "sample_Location", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "sample_Date": { key: "sample_Date", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "sampler": { key: "sampler", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "analysis_Date": { key: "analysis_Date", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "analyzed_By": { key: "analyzed_By", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "tests_Ran": { key: "tests_Ran", message: FIELD_VALIDATOR_ERRORS.REQUIRED }
            },
            data: {
                id: 0,
                product: "",
                plant: "",
                sample_Location: "",
                sample_Date: "",
                sampler: "",
                analysis_Date: "",
                analyzed_By: "",
                created: "",
                created_By: userName,
                modified: "",
                modified_By: userName,
            }
        };
    }

    public componentDidMount(): void {
        if (hasPayload(this.props.getToken))
            this.props.getChemicalAnalysesLookupsRequest({
                token: this.props.getToken.payload.response.token,
                Requested_Page: "Sieve"
            });

        this.callServer();
    };

    public componentDidUpdate(prevProps: IChemicalAnalysesProps): void {
        if (this.props !== prevProps) {
            this.callServer();

            if (this.props.addChemicalAnalyses !== prevProps.addChemicalAnalyses) {
                if (isSucceeded(this.props.addChemicalAnalyses)) {
                    this.handleCancel();
                };
            };

            if (this.props.updateChemicalAnalyses !== prevProps.updateChemicalAnalyses) {
                if (isSucceeded(this.props.updateChemicalAnalyses)) {
                    this.handleCancel();
                };
            };
        };
    };


    public render(): ReactNode {

        const { data, errors } = this.state;
        const { updateChemicalAnalyses, addChemicalAnalyses, getChemicalAnalysesLookups } = this.props;
        const lookUps = hasPayload(getChemicalAnalysesLookups) ? getChemicalAnalysesLookups.payload.response : { plants: [], products: [], mesh: [], screens: [] };
        const plants = convertList(lookUps.plants);
        const filteredProduct = this.filterProduct(lookUps);
        const products = convertList(filteredProduct);

        return (
            <PageSpacing title="SIL - ChemicalAnalyses" description="ChemicalAnalyses" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="explorationAccess">
                    <ChemicalAnalysesStyles>

                        <LAButton startIcon={<ArrowLeftIcon color={WHITE_COLOR} />} label="Back to list" onClick={this.handleCancel} />
                        <h2 className="text-center">{data.id > 0 ? "VIEW/UPDATE " : "ADD "} CHEMICAL ANALYSES</h2>
                        <hr />

                        <LAPaperWithPadding>
                            <LAGrid spacing={1}>

                                <LAGridItem xs={3}>
                                    <LAAutoComplete
                                        filterSelectedOptions={true}
                                        autoHighlight={true}
                                        selectionRemove={undefinedFunction}
                                        dropDownPlaceHolder="Product"
                                        getOptionLabel="name"
                                        name="product"
                                        option={products}
                                        multiple={false}
                                        onChange={this.handleDropDownChange}
                                        errorText={errors && errors["product"] ? errors["product"].message : undefined}
                                        value={hasPayload(getChemicalAnalysesLookups) && data.product ? products.find(x => x.name === data.product) : ""}
                                        defaultValue={hasPayload(getChemicalAnalysesLookups) && data.product ? products.find(x => x.name === data.product) : ""}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={3}>
                                    <LAAutoComplete
                                        filterSelectedOptions={true}
                                        autoHighlight={true}
                                        selectionRemove={undefinedFunction}
                                        dropDownPlaceHolder="Plant"
                                        getOptionLabel="name"
                                        name="plant"
                                        option={plants}
                                        multiple={false}
                                        onChange={this.handlePlantDropDownChange}
                                        errorText={errors && errors["plant"] ? errors["plant"].message : undefined}
                                        value={hasPayload(getChemicalAnalysesLookups) && data.plant ? plants.find(x => x.name === data.plant) : ""}
                                        defaultValue={hasPayload(getChemicalAnalysesLookups) && data.plant ? plants.find(x => x.name === data.plant) : ""}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={3}>                                   
                                     <LATextField
                                        varient="outlined"
                                        label="Sample Location"
                                        fullWidth={true}
                                        name="sample_Location"
                                        onChange={this.onChange}
                                        value={data.sample_Location ?? ""}
                                        errorText={errors && errors["sample_Location"] ? errors["sample_Location"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={3}>
                                    <LADateTimeMUIPicker
                                        name="sample_Date"
                                        label="Sample Date"
                                        fullWidth={true}
                                        dateOnly={true}
                                        value={data.sample_Date}
                                        onChange={this.handleDate}
                                        errorText={errors && errors["sample_Date"] ? errors["sample_Date"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={3}>
                                    <LATextField
                                        varient="outlined"
                                        label="Sampler"
                                        fullWidth={true}
                                        name="sampler"
                                        onChange={this.onChange}
                                        value={data.sampler ?? ""}
                                        errorText={errors && errors["sampler"] ? errors["sampler"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={3}>
                                    <LADateTimeMUIPicker
                                        name="analysis_Date"
                                        label="Analysis Date"
                                        fullWidth={true}
                                        dateOnly={true}
                                        value={data.analysis_Date}
                                        onChange={this.handleDate}
                                        errorText={errors && errors["analysis_Date"] ? errors["analysis_Date"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={3}>
                                    <LATextField
                                        varient="outlined"
                                        label="Tests Ran"
                                        fullWidth={true}
                                        name="tests_Ran"
                                        type="number"
                                        onChange={this.onNumberChange}
                                        value={data.tests_Ran ?? ""}
                                        errorText={errors && errors["tests_Ran"] ? errors["tests_Ran"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={3}>
                                    <LATextField
                                        varient="outlined"
                                        label="Analyzed By"
                                        fullWidth={true}
                                        name="analyzed_By"
                                        onChange={this.onChange}
                                        value={data.analyzed_By ?? ""}
                                        errorText={errors && errors["analyzed_By"] ? errors["analyzed_By"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={3}>
                                    <LATextField
                                        varient="outlined"
                                        label="MgO %"
                                        fullWidth={true}
                                        name="mgO_Perc"
                                        onChange={this.onDecimalChange}
                                        value={data.mgO_Perc ?? ""}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={3}>
                                    <LATextField
                                        varient="outlined"
                                        label="Al203 %"
                                        fullWidth={true}
                                        name="al2O3_Perc"
                                        onChange={this.onDecimalChange}
                                        value={data.al2O3_Perc ?? ""}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={3}>
                                    <LATextField
                                        varient="outlined"
                                        label="SiO2 %"
                                        fullWidth={true}
                                        name="siO2_Perc"
                                        onChange={this.onDecimalChange}
                                        value={data.siO2_Perc ?? ""}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={3}>
                                    <LATextField
                                        varient="outlined"
                                        label="P2O5 %"
                                        fullWidth={true}
                                        name="p2O5_Perc"
                                        onChange={this.onDecimalChange}
                                        value={data.p2O5_Perc ?? ""}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={3}>
                                    <LATextField
                                        varient="outlined"
                                        label="CaO %"
                                        fullWidth={true}
                                        name="caO_Perc"
                                        onChange={this.onDecimalChange}
                                        value={data.caO_Perc ?? ""}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={3}>
                                    <LATextField
                                        varient="outlined"
                                        label="TiO2 %"
                                        fullWidth={true}
                                        name="tiO2_Perc"
                                        onChange={this.onDecimalChange}
                                        value={data.tiO2_Perc ?? ""}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={3}>
                                    <LATextField
                                        varient="outlined"
                                        label="MnO %"
                                        fullWidth={true}
                                        name="mnO_Perc"
                                        onChange={this.onDecimalChange}
                                        value={data.mnO_Perc ?? ""}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={3}>
                                    <LATextField
                                        varient="outlined"
                                        label="FeO %"
                                        fullWidth={true}
                                        name="feO_Perc"
                                        onChange={this.onDecimalChange}
                                        value={data.feO_Perc ?? ""}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={3}>
                                    <LATextField
                                        varient="outlined"
                                        label="Le %"
                                        fullWidth={true}
                                        name="lE_Perc"
                                        onChange={this.onDecimalChange}
                                        value={data.lE_Perc ?? ""}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12}>
                                    <LASaveAndCancelButton
                                        onSave={this.onSave}
                                        onCancel={this.handleCancel}
                                        disableSave={(Object.values(errors).length > 0 ? true : undefined)}
                                    />
                                </LAGridItem>

                            </LAGrid>
                        </LAPaperWithPadding>

                        <RequestStatus requestStatus={addChemicalAnalyses.kind} successMessage="Chemical Analyses  successfully saved" />
                        <RequestStatus requestStatus={updateChemicalAnalyses.kind} successMessage="Chemical Analyses  successfully updated" />

                    </ChemicalAnalysesStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private filterProduct = (data: ISieveLookup): string[] => {
        const res: string[] = [];

        for (let i in data.products) {
            if (!res.includes(data.products[i].product))
                res.push(data.products[i].product);
        };

        return res;
    };

    private handleDate = (name: string, date: string): void => {
        let errors = this.state.errors;
        errors = this.errorChecker(name, date, errors, true);

        this.setState({
            ...this.state,
            errors,
            data: {
                ...this.state.data,
                [name]: date,
            }
        });
    };


    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, isRequired: boolean): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: isRequired ? true : undefined, minLength: 1, decimal: undefined });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        };
        return err;
    };

    private handleCancel = (): void => {
        this.props.history.push(ROUTE.SIL.EXPLORATION.CHEMICAL_ANALYSES_LIST);
    };

    private onSave = async (): Promise<void> => {

        if (hasPayload(this.props.getToken)) {
            const { data } = this.state;

            const request: IAddUpdateChemicalAnalyses = {
                ID: data.id,
                Product: data.product,
                Plant: data.plant,
                Sample_Location: data.sample_Location,
                Sample_Date: data.sample_Date,
                Sampler: data.sampler,
                Analysis_Date: data.analysis_Date,
                Tests_Ran: data.tests_Ran ?? 0,
                Analyzed_By: data.analyzed_By,
                MgO_Perc: data.mgO_Perc ?? 0,
                Al2O3_Perc: data.al2O3_Perc ?? 0,
                SiO2_Perc: data.siO2_Perc ?? 0,
                P2O5_Perc: data.p2O5_Perc ?? 0,
                CaO_Perc: data.caO_Perc ?? 0,
                TiO2_Perc: data.tiO2_Perc ?? 0,
                MnO_Perc: data.mnO_Perc ?? 0,
                FeO_Perc: data.feO_Perc ?? 0,
                LE_Perc: data.lE_Perc ?? 0,
                Created_By: userName,
                Modified_By:  userName,
            };

            if (data.id === 0) {
                this.props.addChemicalAnalysesRequest({
                    token: this.props.getToken.payload.response.token,
                    request
                });
            } else {
                this.props.updateChemicalAnalysesRequest({
                    token: this.props.getToken.payload.response.token,
                    request
                });
            };
        }
    };

    private handleDropDownChange = (event: unknown, value: { id: number, name: string } | "", name?: string): void => {
        if (name) {
            let errors = this.state.errors;

            if (requiredFields.includes(name))
                errors = this.errorChecker(name, value !== "" ? value.name : "", errors, true);

            this.setState({
                ...this.state,
                errors,
                data: {
                    ...this.state.data,
                    [name]: value !== "" ? value.name : ""
                }
            });
        };
    };

    private handlePlantDropDownChange = (event: unknown, value: { id: number, name: string } | "", name?: string): void => {
        if (name && hasPayload(this.props.getChemicalAnalysesLookups)) {
            let errors = this.state.errors;

            if (requiredFields.includes(name))
                errors = this.errorChecker(name, value !== "" ? value.name : "", errors, true);

            this.setState({
                ...this.state,
                errors,
                data: {
                    ...this.state.data,
                    [name]: value !== "" ? value.name : ""
                }
            });
        };
    };

    private onDecimalChange = (name: string, value: string): void => {
        if (!isNaN(Number(value))) {
            let errors = this.state.errors;

            if (requiredFields.includes(name))
                errors = this.errorChecker(name, value, errors, true);

            this.setState({
                ...this.state,
                errors,
                data: {
                    ...this.state.data,
                    [name]: value
                }
            });
        };
    };



    private onNumberChange = (name: string, value: string): void => {
        if (!isNaN(Number(value)) && !value.includes(".")) {
            let errors = this.state.errors;

            if (requiredFields.includes(name))
                errors = this.errorChecker(name, value, errors, true);

            this.setState({
                ...this.state,
                errors,
                data: {
                    ...this.state.data,
                    [name]: value
                }
            });
        };
    };


    private onChange = (name: string, value: string): void => {
        let errors = this.state.errors;

        if (requiredFields.includes(name))
            errors = this.errorChecker(name, value, errors, true);

        this.setState({
            ...this.state,
            errors,
            data: {
                ...this.state.data,
                [name]: value
            }
        });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getChemicalAnalysesLookups))
            this.props.getChemicalAnalysesLookupsRequest({
                token: this.props.getToken.payload.response.token,
                Requested_Page: "Sieve"
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getChemicalAnalyses))
            this.props.getChemicalAnalysesRequest({
                token: this.props.getToken.payload.response.token
            });


        const query = queryString.parse(this.props.location.search);
        const id = query.id ?? "0";

        if ((id !== "0") && (hasPayload(this.props.getChemicalAnalyses)) && (this.state.data.id === 0)) {
            const data = this.props.getChemicalAnalyses.payload.response.find((x) => x.id === +id);

            if (data)
                this.setState({ data, errors: {} });
        };

    };

}

const mapStateToProps = (state: IStore): IChemicalAnalysesStoreProps => ({
    getToken: getTokenStatus(state),
    getChemicalAnalyses: getChemicalAnalysesStatus(state),
    updateChemicalAnalyses: updateChemicalAnalysesStatus(state),
    getChemicalAnalysesLookups: getSieveLookupsStatus(state),
    addChemicalAnalyses: addChemicalAnalysesStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IChemicalAnalysesDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getChemicalAnalysesRequest: (data: IGetChemicalAnalysesRequest) => dispatch(getChemicalAnalysesLoadAction(data)),
    addChemicalAnalysesRequest: (data: IAddChemicalAnalysesRequest) => dispatch(addChemicalAnalysesLoadAction(data)),
    updateChemicalAnalysesRequest: (data: IUpdateChemicalAnalysesRequest) => dispatch(updateChemicalAnalysesLoadAction(data)),
    getChemicalAnalysesLookupsRequest: (data: IGetSieveLookupsRequest) => dispatch(getSieveLookupsLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ChemicalAnalyses);