import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IADD_QUALITY_CONTROL_TESTING_REQUEST } from "./addQualityControlTestingConstaints";
import { IAddQualityControlTestingLoadAction, IAddQualityControlTestingLoadFailedAction, IAddQualityControlTestingSuccessAction } from "./addQualityControlTestingActions";

type Actions =
    IAddQualityControlTestingLoadAction
    | IAddQualityControlTestingLoadFailedAction
    | IAddQualityControlTestingSuccessAction
    | IFlushDataSuccessAction;

export const AddQualityControlTestingReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IADD_QUALITY_CONTROL_TESTING_REQUEST.REQUEST:
            return loading;

        case IADD_QUALITY_CONTROL_TESTING_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IADD_QUALITY_CONTROL_TESTING_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};