import { IPSAttachment } from "../../../../../utils/sharedTypes";
import { IPassTokenWithRequest } from "../../../dryPlant/token/getTokenConstants";


export enum IGET_NCRS_REQUEST {
    REQUEST = "getNCR/GET_NCRS_REQUEST",
    SUCCESS = "getNCR/GET_NCRS_SUCCESS",
    FAILED = "getNCR/GET_NCRS_FAILED"
};

export interface IGetNCRRequest extends IPassTokenWithRequest {

};

export interface INCR {
    id: number;
    non_Conformance: string;
    accreditation: string;
    date: string;
    customer: string;
    information_Gathered: string;
    nCR_Category: string;
    manager_Responsible: string;
    circumstances_Surrounding_Report: string;
    attachments: string;
    circumstances_Reported_By: string;
    immediate_Action_Taken: string;
    immediate_Action_Reported_By: string;
    disposition: string;
    cost_Of_Non_Conformance: string;
    comments: string;
    resolution_Date: string;
    root_Cause: string;
    resolution: string;
    manager_Notes: string;
    created: string;
    created_By: string;
    modified: string;
    modified_By: string;
    attachmentContent: IPSAttachment[];
};