import React from "react";
import { IBaggedInventoriesItem } from "../../../../redux/sil/warehouse/baggedInventory/getBaggedInventories/getBaggedInventoriesConstaints";
import { LAPopover } from "../../../shared/popOver";
import { LAPaperWithPadding } from "../../../shared/paper";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { undefinedFunction } from "../../../shared/constExports";

interface IBaggedInventoryPopup {
    open: boolean;
    onClose: () => void;
    data: IBaggedInventoriesItem[];
};

export const BaggedInventoryPopup: React.FC<IBaggedInventoryPopup> = React.memo((props: IBaggedInventoryPopup) => (
    <LAPopover open={props.open} onClose={props.onClose} anchorRef={null}>
        <LAPaperWithPadding>
            <LADevExtremeGrid
                height={400}
                id="production"
                searchPanel={false}
                filterHeader={false}
                data={props.data}
                onClick={undefinedFunction}
                columns={[
                    { name: "product", caption: "Product", type: "string" },
                    { name: "pallet_Size", caption: "Pallet Size", type: "string" },
                    { name: "count", caption: "Count", type: "number" },
                    { name: "created", caption: "Created", type: "datetime" },
                    { name: "created_By", caption: "Created By", type: "string" },
                    { name: "modified", caption: "Modified", type: "datetime" },
                    { name: "modified_By", caption: "Modified By", type: "string" }
                   
                ]}
            />
        </LAPaperWithPadding>
    </LAPopover>
));