import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import { SilAPIResponse } from "../../../shared/publicInterfaces";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import { callRouteWithQueryString, userName } from "../../../shared/constExports";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { LAButton } from "../../../shared/buttons";
import { AddIcon } from "../../../shared/icons";
import { WHITE_COLOR } from "../../../shared/theme";
import { ROUTE } from "../../../routes";
import PageSpacing from "../../../shared/pageSpacing";
import SilRoleCheck from "../../../shared/silRoleCheck";
import LALoading from "../../../shared/loading";
import { IGetNCRRequest, INCR } from "../../../../redux/sil/cv/ncrs/getNCRs/getNCRsConstaints";
import { getNCRStatus } from "../../../../redux/sil/cv/ncrs/getNCRs/getNCRsAccessor";
import { getNCRLoadAction } from "../../../../redux/sil/cv/ncrs/getNCRs/getNCRsActions";


interface INCRsStoreProps {
    getToken: Server<SilAPIResponse<IToken>>;
    getNCRs: Server<SilAPIResponse<INCR[]>>;
};

interface INCRsDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getNCRsRequest: (data: IGetNCRRequest) => unknown;
};


interface INCRsOwnProps {

};

interface INCRsState {

};

const NCRsStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
    };
`;

type INCRsProps = RouteComponentProps
    & INCRsStoreProps
    & INCRsDispatchProps
    & INCRsOwnProps;

class NCRs extends PureComponent<INCRsProps, INCRsState> {

    public constructor(props: INCRsProps) {
        super(props);
        this.state = {
        };
    }

    public componentDidMount(): void {
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: INCRsProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();
        };
    };


    public render(): ReactNode {

        const { getNCRs } = this.props;

        return (
            <PageSpacing title="SIL - CV" description="SIL - NCRs" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="carrierAccess">
                    <NCRsStyles>

                        {getNCRs.kind !== STATUS_ENUM.LOADING &&
                            <LAButton className="pull-left" startIcon={<AddIcon color={WHITE_COLOR} />} label="Add" onClick={this.handleAdd} />}
                        <h2 className="text-center">NCR</h2>
                        <hr />

                        {getNCRs.kind === STATUS_ENUM.LOADING &&
                            <LALoading className="loading" message="Loading..." />}

                        {getNCRs.kind === STATUS_ENUM.SUCCEEDED && <LADevExtremeGrid
                            data={getNCRs.payload.response}
                            onEdit={this.onEdit}
                            onClick={this.onClick}
                            height={1000}
                            searchPanel={true}
                            filterHeader={true}
                            export={true}
                            exportFileName="NCRsList"
                            storageKey="silNCRsStorageKey"
                            columns={[
                                { name: "non_Conformance", caption: "Non-Conformance", type: "string" },
                                { name: "accreditation", caption: "Accreditation", type: "string" },
                                { name: "date", caption: "Date", type: "datetime" },
                                { name: "customer", caption: "Customer", type: "string" },
                                { name: "information_Gathered", caption: "Information Gathered", type: "string" },
                                { name: "nCR_Category", caption: "NCR Category", type: "string" },
                                { name: "manager_Responsible", caption: "Manager Responsible", type: "string" },
                                { name: "circumstances_Surrounding_Report", caption: "Circumstances Surrounding Report", type: "string" },
                                { name: "attachments", caption: "Attachments", type: "string" },
                                { name: "circumstances_Reported_By", caption: "Circumstances Reported By", type: "string" },
                                { name: "immediate_Action_Taken", caption: "Immediate Action Taken", type: "string" },
                                { name: "immediate_Action_Reported_By", caption: "Immediate Action Reported By", type: "string" },
                                { name: "disposition", caption: "Disposition", type: "string" },
                                { name: "cost_Of_Non_Conformance", caption: "Cost of Non-Conformance", type: "string" },
                                { name: "comments", caption: "Comments", type: "string" },
                                { name: "resolution_Date", caption: "Resolution Date", type: "datetime" },
                                { name: "root_Cause", caption: "Root Cause", type: "string" },
                                { name: "resolution", caption: "Resolution", type: "string" },
                                { name: "manager_Notes", caption: "Manager Notes", type: "string" },
                                { name: "modified", caption: "Modified", type: "datetime" },
                                { name: "modified_By", caption: "Modified By", type: "string" }
                            ]}
                        />}

                    </NCRsStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString() },
            pathName: ROUTE.SIL.CV.NCR.NCR
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0" },
            pathName: ROUTE.SIL.CV.NCR.NCR
        });
    };

    private setDataToState = (): void => {
        if (isNotLoaded(this.props.getNCRs))
            this.callServer();
    };

    private callServer = (): void => {
        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getNCRs))
            this.props.getNCRsRequest({
                token: this.props.getToken.payload.response.token
            });

        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });
    }

}

const mapStateToProps = (state: IStore): INCRsStoreProps => ({
    getToken: getTokenStatus(state),
    getNCRs: getNCRStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): INCRsDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getNCRsRequest: (data: IGetNCRRequest) => dispatch(getNCRLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(NCRs);