import React, { ReactNode, PureComponent, useState, useCallback } from "react";
import { Prompt, RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../../shared/paper";
import { IDispatch, IStore } from "../../../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../../redux/server";
import { SimosAPIResponse } from "../../../../shared/publicInterfaces";
import { IGetTokenRequest, IToken } from "../../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenLoadAction } from "../../../../../redux/sil/dryPlant/token/getTokenActions";
import { NotApplicable, callRouteWithQueryString, pageAccessCheck, userName } from "../../../../shared/constExports";
import { LADevExtremeGrid, onExporting } from "../../../../shared/devExtreme";
import { getTokenStatus } from "../../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { LAButton } from "../../../../shared/buttons";
import { AddIcon } from "../../../../shared/icons";
import { MEDIA_QUERY_PHONE, WHITE_COLOR } from "../../../../shared/theme";
import { ROUTE } from "../../../../routes";
import PageSpacing from "../../../../shared/pageSpacing";
import LALoading from "../../../../shared/loading";
import { ISimosCustomerContacts, ISimosCustomerContactsRequest } from "../../../../../redux/propsense/simos/simosCustomerContacts/getSimosCustomerContacts/getSimosCustomerContactsConstants";
import LAErrorBox from "../../../../shared/errorBox";
import { getSimosCustomerContactsLoadAction } from "../../../../../redux/propsense/simos/simosCustomerContacts/getSimosCustomerContacts/getSimosCustomerContactsActions";
import { getSimosCustomerContacts } from "../../../../../redux/propsense/simos/simosCustomerContacts/getSimosCustomerContacts/getSimosCustomerContactsAccessor";
import LAGrid from "../../../../shared/grid";
import LAGridItem from "../../../../shared/gridList";
import { SimosSubHeader, SimosSubHeaderMobile } from "../../../../header/simosHeader";
import queryString from "query-string";
import DataGrid, { Column, ColumnChooser, Editing, Export, FilterPanel, FilterRow, HeaderFilter, Lookup, Pager, PatternRule, RequiredRule, Scrolling, SearchPanel, StateStoring, StringLengthRule } from "devextreme-react/data-grid";
import { ISimosCustomers, ISimosCustomersRequest } from "../../../../../redux/propsense/simos/simosCustomers/getSimosCustomers/getSimosCustomersConstants";
import { ISimosCustomerAddress, ISimosCustomerAddressRequest } from "../../../../../redux/propsense/simos/simosCustomerAddress/getSimosCustomerAddress/getSimosCustomerAddressConstants";
import { getSimosCustomers } from "../../../../../redux/propsense/simos/simosCustomers/getSimosCustomers/getSimosCustomersAccessor";
import { getSimosCustomerAddress } from "../../../../../redux/propsense/simos/simosCustomerAddress/getSimosCustomerAddress/getSimosCustomerAddressAccessor";
import { getSimosCustomersLoadAction } from "../../../../../redux/propsense/simos/simosCustomers/getSimosCustomers/getSimosCustomersActions";
import { getSimosCustomerAddressLoadAction } from "../../../../../redux/propsense/simos/simosCustomerAddress/getSimosCustomerAddress/getSimosCustomerAddressActions";
import { IAddSimosCustomerContactRequest, IAddUpdateSimosCustomerContact } from "../../../../../redux/propsense/simos/simosCustomerContacts/addSimosCustomerContact/addSimosCustomerContactConstaints";
import { IUpdateSimosCustomerContactRequest } from "../../../../../redux/propsense/simos/simosCustomerContacts/updateSimosCustomerContact/updateSimosCustomerContactConstaints";
import { addSimosCustomerContactStatus } from "../../../../../redux/propsense/simos/simosCustomerContacts/addSimosCustomerContact/addSimosCustomerContactAccessor";
import { updateSimosCustomerContactStatus } from "../../../../../redux/propsense/simos/simosCustomerContacts/updateSimosCustomerContact/updateSimosCustomerContactAccessor";
import { addSimosCustomerContactLoadAction } from "../../../../../redux/propsense/simos/simosCustomerContacts/addSimosCustomerContact/addSimosCustomerContactActions";
import { updateSimosCustomerContactLoadAction } from "../../../../../redux/propsense/simos/simosCustomerContacts/updateSimosCustomerContact/updateSimosCustomerContactActions";
import RequestStatus from "../../../../shared/requestStatusSnackbar";

interface ISimosCustomerContactsStoreProps {
    getToken: Server<SimosAPIResponse<IToken>>;
    getSimosCustomerContactsList: Server<SimosAPIResponse<ISimosCustomerContacts[]>>;
    getSimosCustomers: Server<SimosAPIResponse<ISimosCustomers[]>>;
    getSimosCustomerAddress: Server<SimosAPIResponse<ISimosCustomerAddress[]>>;
    addSimosCustomerContact: Server<string>;
    updateSimosCustomerContact: Server<string>;
};

interface ISimosCustomerContactsDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getSimosCustomerContactsListRequest: (data: ISimosCustomerContactsRequest) => unknown;
    getSimosCustomersRequest: (data: ISimosCustomersRequest) => unknown;
    getSimosCustomerAddressRequest: (data: ISimosCustomerAddressRequest) => unknown;
    addSimosCustomerContactRequest: (data: IAddSimosCustomerContactRequest) => unknown;
    updateSimosCustomerContactRequest: (data: IUpdateSimosCustomerContactRequest) => unknown;
};


interface ISimosCustomerContactsOwnProps {

};

interface ISimosCustomerContactsState {
    editMode: boolean;
    serverError: string | undefined;
};

const SimosCustomerContactsStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .loading {
        margin-top: 5%;
    };

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
    };

    .show-on-phone {
        display: none;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 5px 5px;

        .title {
            padding-top: 11%;
        };

        .pull-left {
            position: absolute;
            left: 3%;
            height: 3%;
            top: 1%;
        };

        .hide-on-phone, .hide-on-phone * {
            display: none;
        };

        .show-on-phone {
            display: block;
        };
    };
`;

type ISimosCustomerContactsProps = RouteComponentProps
    & ISimosCustomerContactsStoreProps
    & ISimosCustomerContactsDispatchProps
    & ISimosCustomerContactsOwnProps;

class SimosCustomerContacts extends PureComponent<ISimosCustomerContactsProps, ISimosCustomerContactsState> {

    public constructor(props: ISimosCustomerContactsProps) {
        super(props);
        this.state = {
            editMode: false,
            serverError: undefined
        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: ISimosCustomerContactsProps): void {
        if (this.props !== prevProps) {
            this.callServer();

            if (this.props.addSimosCustomerContact !== prevProps.addSimosCustomerContact) {
                if (this.props.addSimosCustomerContact.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.addSimosCustomerContact.message });

                if (hasPayload(this.props.addSimosCustomerContact) && (this.props.addSimosCustomerContact.kind === STATUS_ENUM.SUCCEEDED) && (this.state.serverError))
                    this.setState({ serverError: undefined });
            };

            if (this.props.updateSimosCustomerContact !== prevProps.updateSimosCustomerContact) {
                if (this.props.updateSimosCustomerContact.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.updateSimosCustomerContact.message });

                if (hasPayload(this.props.updateSimosCustomerContact) && (this.props.updateSimosCustomerContact.kind === STATUS_ENUM.SUCCEEDED) && (this.state.serverError))
                    this.setState({ serverError: undefined });
            };
        };
    };


    public render(): ReactNode {

        const { editMode, serverError } = this.state;
        const { getSimosCustomerContactsList, getSimosCustomers, getSimosCustomerAddress, getToken, addSimosCustomerContact, updateSimosCustomerContact} = this.props;
        const data = hasPayload(getSimosCustomerContactsList) ? Object.values(getSimosCustomerContactsList.payload.response) : [];
        const getRole = hasPayload(getToken) && getToken.payload.response.simosAccess.access;
        // console.log(data)
        const customerOptions = hasPayload(getSimosCustomers) ? getSimosCustomers.payload.response : [];
        const onEditStart = (): void => this.updateEditMode(true);
        const onEditCancel = (): void => this.updateEditMode(false);

        return (
            <PageSpacing title="SIMOS - Customer Contacts" description="SIMOS - Customer Contacts" fixedSpaceOnSmallerScreens={true}>
                {(getRole !== NotApplicable) &&
                    <SimosCustomerContactsStyles>

                        <div className="hide-on-phone">
                            <SimosSubHeader
                                {...this.props}
                            />
                        </div>

                        <div className="show-on-phone">
                            <SimosSubHeaderMobile
                                {...this.props}
                            />
                        </div>

                        <LAPaperWithPadding>
                            <LAGrid>
                                <LAGridItem xs={12}>
                                    {/* <LAButton 
                                        className="pull-left" 
                                        startIcon={<AddIcon color={WHITE_COLOR} />} 
                                        label="Add" 
                                        onClick={this.handleAdd} 
                                    /> */}

                                    <h2 className="text-center">SIMOS CUSTOMER CONTACTS</h2>
                                    <hr />

                                    {serverError && <LAErrorBox text={serverError} />}
                                    
                                    {getSimosCustomerContactsList.kind === STATUS_ENUM.LOADING && <LALoading className="loading" message="Loading..." />}
                                    {getSimosCustomerContactsList.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load Customer Contacts..." />}

                                    {getSimosCustomerContactsList.kind === STATUS_ENUM.SUCCEEDED && 
                                        <>
                                        {/* <LADevExtremeGrid
                                            data={data}
                                            onEdit={this.onEdit}
                                            onClick={this.onClick}
                                            height={1000}
                                            searchPanel={true}
                                            actionWidth={120}
                                            filterHeader={true}
                                            export={true}
                                            id="SimosCustomerContactsList"
                                            exportFileName="SimosCustomerContacts"
                                            storageKey="SimosCustomerContactsStorageKey"
                                            columns={[                               
                                                { name: "contact", caption: "Contact", type: "string" },
                                                { name: "phone", caption: "Phone", type: "string" },
                                                { name: "fax", caption: "Fax", type: "string" },
                                                { name: "email", caption: "Email", type: "string" },
                                                { name: "customer", caption: "Customer", type: "string" },
                                                { name: "delivery_Address", caption: "Delivery Address", type: "string" },
                                                { name: "created", caption: "Created", type: "datetime" },
                                                { name: "created_By", caption: "Created By", type: "string" },
                                                { name: "modified", caption: "Modified", type: "datetime" },
                                                { name: "modified_By", caption: "Modified By", type: "string" }
                                            ]}
                                        /> */}
                                        <LAGridItem xs={12} className="text-center">
                                            <CustomerContactsDevExtreme
                                                data={data}
                                                customers={customerOptions}
                                                filterDeliveryAddress={this.filterDeliveryAddress}
                                                editMode={editMode}
                                                onEditStart={onEditStart}
                                                onEditCancel={onEditCancel}
                                                onSaveClick={this.onSaveClick}
                                                setCustomerValue={this.setCustomerValue}
                                                setDeliveryAddressValue={this.setDeliveryAddressValue}
                                                // getRefreshDataCall={this.getDataForTable}
                                            />
                                        </LAGridItem>
                                        <Prompt when={editMode} message="You have unsaved changes ?" />
                                        <RequestStatus requestStatus={addSimosCustomerContact.kind} successMessage="Simos Customer Contact successfully saved" failedMessage={addSimosCustomerContact.kind === "failed" ? addSimosCustomerContact.message : "Your Request has failed. Please try again or contact IT.Developers for assistance."} />
                                        <RequestStatus requestStatus={updateSimosCustomerContact.kind} successMessage="Simos Customer Contact successfully updated" failedMessage={updateSimosCustomerContact.kind === "failed" ? updateSimosCustomerContact.message : "Your Request has failed. Please try again or contact IT.Developers for assistance."}/>

                                        </>
                                    }
                                </LAGridItem>
                            </LAGrid>
                        </LAPaperWithPadding>
                    </SimosCustomerContactsStyles>
                }
            </PageSpacing>
        );
    }

    private updateEditMode = (editMode: boolean): void => {
        this.setState({ editMode });
    };

    private setCustomerValue = (rowData: any, value: any, currentRowData: any): void => {
        const customers = hasPayload(this.props.getSimosCustomers) ? this.props.getSimosCustomers.payload.response : [];
        const selectedCustomer = customers.find(c => c.customer === value);
        rowData.delivery_Address = "";
        rowData.delivery_ID =  0;
        rowData.customer = value;
        rowData.customer_ID = selectedCustomer?.id ?? 0;
    }

    private setDeliveryAddressValue = (rowData: any, value: any, currentRowData: any): void => {
        const deliveryAddress = hasPayload(this.props.getSimosCustomerAddress) ? this.props.getSimosCustomerAddress.payload.response : [];
        const selectedDeliveryAddress = deliveryAddress.find(c => c.address === value);
        rowData.delivery_Address = value;
        rowData.delivery_ID = selectedDeliveryAddress?.id ?? 0;
    }

    private filterDeliveryAddress = (options: any): any => {
        const deliveryAddressOptions = hasPayload(this.props.getSimosCustomerAddress) ? this.props.getSimosCustomerAddress.payload.response : [];
        let filteredDeliveryAddressOptions = (options.data && options.data.customer_ID) ? this.filteredDeliveryAddress(deliveryAddressOptions, options.data.customer_ID) : this.filteredDeliveryAddress(deliveryAddressOptions);
        // const filterCondition = (x: any) => (options.data && options.data.customer_ID) && x.customer_ID === options.data.customer_ID || x.customer_ID === 0;
        // console.log(filteredDeliveryAddressOptions)
        return {
            store: filteredDeliveryAddressOptions,
            filter: options.data && options.data.customer_ID ? [['customer_ID', '=', options.data.customer_ID],'|', ['customer_ID', '=', 0]] : null
        };
    };

    private filteredDeliveryAddress = (data: ISimosCustomerAddress[], customer_ID?: number): object[] => {
        const res: object[] = [];
        
        res.push({id: 0, address: "N/A", customer_ID: 0});
        if (customer_ID && customer_ID > 0) {
            data.forEach((x) => {
                if ((x.customer_ID === customer_ID))
                    if (res.indexOf(x) === -1) {
                        res.push(x);
                    }
            });
        }
        else {
            data.forEach((x) => {
                if (res.indexOf(x) === -1) {
                    res.push(x);
                }
            });
        }
        return res;
    };

    private onSaveClick = (record: any): void => {
        // console.log(record)
        const { getToken, } = this.props;
        if (hasPayload(getToken) && record.changes[0] !== undefined) {
            const { data } = record.changes[0];
                // console.log(data)
                let dataId = data.id;
                if (typeof data.id != "string") {
                    dataId = data.id;
                }
                else {
                    dataId = 0;
                }
                const request: IAddUpdateSimosCustomerContact = {
                    ID: dataId,
                    Contact: data.contact,
                    Phone: data.phone,
                    Cell: data.cell,
                    Email: data.email,
                    Customer: data.customer,
                    Customer_ID: data.customer_ID,
                    Delivery_Address: data.delivery_Address,
                    Delivery_ID: data.delivery_ID,
                    Created_By: userName,
                    Modified_By:  userName,
                };

                // console.log(request);

                if (isNaN(data.id)) {
                    this.props.addSimosCustomerContactRequest({
                        token: getToken.payload.response.token,
                        request
                    });
                } 
                else {
                    this.props.updateSimosCustomerContactRequest({
                        token: getToken.payload.response.token,
                        request
                    });
                };
                this.updateEditMode(false);            
        };
    }

    private onEdit = (e: any): void => {
        const query = queryString.parse(this.props.location.search);
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString(), page: query.page ? query.page.toString() : "" },
            pathName: ROUTE.SIMOS.CUSTOMERCONTACT.ADDUPDATECUSTOMERCONTACT
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        const query = queryString.parse(this.props.location.search);
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0", page: query.page ? query.page.toString() : ""},
            pathName: ROUTE.SIMOS.CUSTOMERCONTACT.ADDUPDATECUSTOMERCONTACT
        });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });

        if (hasPayload(this.props.getToken)) {
            if (this.props.getToken.payload.response.simosAccess.access !== NotApplicable) {
                if (isNotLoaded(this.props.getSimosCustomerContactsList)) {
                    this.getDataForTable();
                };

                if (isNotLoaded(this.props.getSimosCustomers))
                    this.props.getSimosCustomersRequest({
                        token: this.props.getToken.payload.response.token
                    });

                if (isNotLoaded(this.props.getSimosCustomerAddress))
                    this.props.getSimosCustomerAddressRequest({
                        token: this.props.getToken.payload.response.token
                    });

            } else {
                this.props.history.push({
                    pathname: ROUTE.SIMOS.INDEX,
                });
            };
        };
    }

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken)) {
            this.props.getSimosCustomerContactsListRequest({
                token: this.props.getToken.payload.response.token
            });
        };
    };
}

const mapStateToProps = (state: IStore): ISimosCustomerContactsStoreProps => ({
    getToken: getTokenStatus(state),
    getSimosCustomerContactsList: getSimosCustomerContacts(state),
    getSimosCustomers: getSimosCustomers(state),
    getSimosCustomerAddress: getSimosCustomerAddress(state),
    addSimosCustomerContact: addSimosCustomerContactStatus(state),
    updateSimosCustomerContact: updateSimosCustomerContactStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): ISimosCustomerContactsDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getSimosCustomerContactsListRequest: (data: ISimosCustomerContactsRequest) => dispatch(getSimosCustomerContactsLoadAction(data)),
    getSimosCustomersRequest: (data: ISimosCustomersRequest) => dispatch(getSimosCustomersLoadAction(data)),
    getSimosCustomerAddressRequest: (data: ISimosCustomerAddressRequest) => dispatch(getSimosCustomerAddressLoadAction(data)),
    addSimosCustomerContactRequest: (data: IAddSimosCustomerContactRequest) => dispatch(addSimosCustomerContactLoadAction(data)),
    updateSimosCustomerContactRequest: (data: IUpdateSimosCustomerContactRequest) => dispatch(updateSimosCustomerContactLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SimosCustomerContacts);

const storageKey = "customer-contacts-storage-key";
interface ICustomerContactsDevExtreme {
    data: ISimosCustomerContacts[];
    customers: ISimosCustomers[];
    filterDeliveryAddress: any;
    editMode: boolean;
    onEditCancel: () => void;
    getRefreshDataCall?: () => void;
    onSaveClick: (record: any) => void;
    onEditStart: (record: any) => void;
    setCustomerValue: (rowData: any, value: any, currentRowData: any) => void;
    setDeliveryAddressValue: (rowData: any, value: any, currentRowData: any) => void;
};

const CustomerContactsDevExtreme: React.FC<ICustomerContactsDevExtreme> = React.memo((props: ICustomerContactsDevExtreme) => {

    const onExportClick = (e: any): void => onExporting(e, "SIMOS_Customer_Contacts");
    const [storageChange, setStorageChange] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState('');

    const loadState = useCallback(() => {
        if (storageKey) {
            let data = localStorage.getItem(storageKey);

            if (data)
                return JSON.parse(data);
        }
    }, [storageChange]);

    const saveState = useCallback((state) => {
        if (state) {
            for (let i = 0; i < state.columns.length; i++) {
                state.columns[i].filterValue = null;
            }
        }

        if (storageKey)
            localStorage.setItem(storageKey, JSON.stringify(state));
    }, []);


    const clearFilters = async (): Promise<void> => {
        if (storageKey) {
            const getCurrent = localStorage.getItem(storageKey);

            if (getCurrent) {
                let parsed = JSON.parse(getCurrent);
                parsed.filterValue = null;
                parsed.filterValues = null;
                parsed.searchText = null;
                parsed.filterPanel = null;
                parsed.columns.map((row: any, id: number) => (
                    row.filterValue = null,
                    row.filterValues = null,
                    row.filterType = null
                ));

                localStorage.setItem(storageKey, JSON.stringify(parsed));
                setStorageChange(!storageChange);
            }

        }
    };

    const handleValueChange = (e:any) => {
        const trimmedValue = e.trim();
        setSearchValue(trimmedValue);
    };

    return (
        <LAGrid>

            <LAGridItem xs={12}>
                <LAButton
                    label="Clear Filters"
                    className="clear-filters"
                    onClick={clearFilters}
                    disabled={undefined}
                />

                {/* {props.getRefreshDataCall && <DataTimer
                    key={storageKey}
                    className="right-side"
                    onTimerEnd={props.getRefreshDataCall}
                />} */}
            </LAGridItem>

            <LAGridItem xs={12}>
                <DataGrid
                    keyExpr="id"
                    showBorders={true}
                    columnAutoWidth={true}
                    repaintChangesOnly={true}
                    onInitNewRow={props.onEditCancel}
                    onSaved={props.onSaveClick}
                    onExporting={onExportClick}
                    rowAlternationEnabled={true}
                    onEditingStart={props.onEditStart}
                    onEditCanceled={props.onEditCancel}
                    dataSource={Object.values(props.data)}
                    // syncLookupFilterValues={true}
                    columnHidingEnabled={true}
                    onRowPrepared = {(e:any) => {  
                        if (e.isEditing == true) {  
                            e.rowElement.style.border = "3px solid green";  
                        }  
                    }}
                    onEditorPreparing={(e:any) => {
                        if(e.dataField === "customer" || e.dataField === "delivery_Address") {
                            if(e.row?.data.id) 
                                e.editorOptions.readOnly = true
                        }
                    }}
                    onToolbarPreparing={(e) => {
                        let toolbarItems: any = e.toolbarOptions.items;

                        toolbarItems.forEach(function (item: any) {
                            if (item.name === "addRowButton") {
                                item.location = "before";
                            }
                        });
                    }}
                    filterSyncEnabled={false}
                >

                    <Export enabled={true} />
                    <Scrolling columnRenderingMode="virtual" />
                    <FilterRow visible={true} />
                    <HeaderFilter allowSearch={true} visible={true} />
                    <FilterPanel visible={false} />
                    <SearchPanel
                        visible={true}
                        width={300}
                        placeholder="Search..."
                        text={searchValue}
                        onTextChange={handleValueChange}
                    />
                    <StateStoring
                        type="custom"
                        customLoad={loadState}
                        customSave={saveState}
                        storageKey={storageKey}
                        enabled={true}
                    />
                    <Scrolling columnRenderingMode="virtual" />
                    <Editing
                        allowUpdating={true}
                        allowAdding={true}
                        allowDeleting={false}
                        mode="row"
                    />
                    <Pager
                        visible={true}
                        allowedPageSizes={"auto"}
                        displayMode={'full'}
                        showPageSizeSelector={true}
                        showInfo={true}
                        showNavigationButtons={true} 
                    />
                    <ColumnChooser
                        enabled={true}
                        mode="select"
                        height={window.innerHeight - 100}
                    />

                    <Column dataField="customer" caption="Customer" setCellValue={props.setCustomerValue} allowEditing={true} width={220} >
                        <Lookup dataSource={props.customers} displayExpr="customer" valueExpr="customer"/> 
                        <StringLengthRule
                            min={1}
                            max={100}
                            message={"Customer Field Required"}
                        /> 
                    </Column>
                    <Column dataField="delivery_Address" caption="Delivery Address" allowEditing={true} setCellValue={props.setDeliveryAddressValue}>
                        <Lookup dataSource={props.filterDeliveryAddress} displayExpr="address" valueExpr="address" />
                        <StringLengthRule
                            min={1}
                            max={100}
                            message={"Delivery Address Field Required"}
                        />
                    </Column>
                    <Column dataField="contact" > 
                        <StringLengthRule
                            min={1}
                            max={100}
                            message={"Contact Field Required"}
                        />
                    </Column>
                    <Column dataField="phone" > 
                        <StringLengthRule
                            min={1}
                            max={100}
                            message={"Phone Field Required"}
                        />
                    </Column>
                    <Column dataField="email" >
                        <PatternRule 
                            message="Please enter a valid email address"
                            pattern={/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/} // Regular expression for validating email
                        />
                    </Column>
                    <Column dataField="cell" />
                    <Column dataField="created" dataType="datetime" format="MM/dd/yyyy HH:mm" width={140} caption="Created" allowEditing={false} />
                    <Column dataField="created_By" caption="Created By" width={130} allowEditing={false} visible={false}/>
                    <Column dataField="modified" dataType="datetime" format="MM/dd/yyyy HH:mm" width={140} caption="Modified" allowEditing={false} sortOrder={"desc"}/>
                    <Column dataField="modified_By" caption="Modified By" width={130} allowEditing={false} visible={false}/>

                </DataGrid>
            </LAGridItem>
        </LAGrid>
    )
});