import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IUpdateWashPlantBinAdjustmentLoadAction, IUpdateWashPlantBinAdjustmentLoadFailedAction, IUpdateWashPlantBinAdjustmentSuccessAction } from "./updateWashPlantBinAdjustmentActions";
import { IUPDATE_WASH_PLANT_BIN_ADJUSTMENT_REQUEST } from "./updateWashPlantBinAdjustmentConstaints";


type Actions =
    IUpdateWashPlantBinAdjustmentLoadAction
    | IUpdateWashPlantBinAdjustmentLoadFailedAction
    | IUpdateWashPlantBinAdjustmentSuccessAction
    | IFlushDataSuccessAction;

export const UpdateWashPlantBinAdjustmentReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IUPDATE_WASH_PLANT_BIN_ADJUSTMENT_REQUEST.REQUEST:
            return loading;

        case IUPDATE_WASH_PLANT_BIN_ADJUSTMENT_REQUEST.SUCCESS:
            return succeeded("Success");

        case IUPDATE_WASH_PLANT_BIN_ADJUSTMENT_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};