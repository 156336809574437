import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IUPDATE_ORDER_REQUEST } from "./updateOrderConstaints";
import { IUpdateOrderLoadAction, IUpdateOrderLoadFailedAction, IUpdateOrderSuccessAction } from "./updateOrderActions";

type Actions =
    IUpdateOrderLoadAction
    | IUpdateOrderLoadFailedAction
    | IUpdateOrderSuccessAction
    | IFlushDataSuccessAction;

export const UpdateOrderReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IUPDATE_ORDER_REQUEST.REQUEST:
            return loading;

        case IUPDATE_ORDER_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IUPDATE_ORDER_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};