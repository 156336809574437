import { IAddWashPlantBinAdjustmentRequest, IADD_WASH_PLANT_BIN_ADJUSTMENT_REQUEST } from "./addWashPlantBinAdjustmentConstaints";

export interface IAddWashPlantBinAdjustmentLoadAction {
    type: IADD_WASH_PLANT_BIN_ADJUSTMENT_REQUEST.REQUEST;
    data: IAddWashPlantBinAdjustmentRequest
}
export const addWashPlantBinAdjustmentLoadAction = (data: IAddWashPlantBinAdjustmentRequest): IAddWashPlantBinAdjustmentLoadAction => ({
    type: IADD_WASH_PLANT_BIN_ADJUSTMENT_REQUEST.REQUEST,
    data
});

export interface IAddWashPlantBinAdjustmentSuccessAction {
    type: IADD_WASH_PLANT_BIN_ADJUSTMENT_REQUEST.SUCCESS;
    message: string;
}
export const addWashPlantBinAdjustmentLoadSuccessAction = (message: string): IAddWashPlantBinAdjustmentSuccessAction => ({
    type: IADD_WASH_PLANT_BIN_ADJUSTMENT_REQUEST.SUCCESS,
    message
});
export interface IAddWashPlantBinAdjustmentLoadFailedAction {
    type: IADD_WASH_PLANT_BIN_ADJUSTMENT_REQUEST.FAILED;
    message: string;
}
export const addWashPlantBinAdjustmentLoadFailedAction = (message: string): IAddWashPlantBinAdjustmentLoadFailedAction => ({
    type: IADD_WASH_PLANT_BIN_ADJUSTMENT_REQUEST.FAILED,
    message
});
