import { IFlushDataSuccessAction } from "../../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../../flushData.tsx/flushDataConstants";
import { Server, failed, loading, notLoaded, succeeded } from "../../../../server";
import { IUpdateNCRLoadAction, IUpdateNCRLoadFailedAction, IUpdateNCRSuccessAction } from "./updateNCRActions";
import { IUPDATE_NCR_REQUEST } from "./updateNCRConstaints";


type Actions =
    IUpdateNCRLoadAction
    | IUpdateNCRLoadFailedAction
    | IUpdateNCRSuccessAction
    | IFlushDataSuccessAction;

export const UpdateNCRReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IUPDATE_NCR_REQUEST.REQUEST:
            return loading;

        case IUPDATE_NCR_REQUEST.SUCCESS:
            return succeeded("Success");

        case IUPDATE_NCR_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};