import { IAddQualityControlTestingRequest, IADD_QUALITY_CONTROL_TESTING_REQUEST } from "./addQualityControlTestingConstaints";

export interface IAddQualityControlTestingLoadAction {
    type: IADD_QUALITY_CONTROL_TESTING_REQUEST.REQUEST;
    data: IAddQualityControlTestingRequest
}
export const addQualityControlTestingLoadAction = (data: IAddQualityControlTestingRequest): IAddQualityControlTestingLoadAction => ({
    type: IADD_QUALITY_CONTROL_TESTING_REQUEST.REQUEST,
    data
});

export interface IAddQualityControlTestingSuccessAction {
    type: IADD_QUALITY_CONTROL_TESTING_REQUEST.SUCCESS;
    message: string;
}
export const addQualityControlTestingLoadSuccessAction = (message: string): IAddQualityControlTestingSuccessAction => ({
    type: IADD_QUALITY_CONTROL_TESTING_REQUEST.SUCCESS,
    message
});

export interface IAddQualityControlTestingLoadFailedAction {
    type: IADD_QUALITY_CONTROL_TESTING_REQUEST.FAILED;
    message: string;
}
export const addQualityControlTestingLoadFailedAction = (message: string): IAddQualityControlTestingLoadFailedAction => ({
    type: IADD_QUALITY_CONTROL_TESTING_REQUEST.FAILED,
    message
});
