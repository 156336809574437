import { SimosAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetSimosTrucksRequest, IGET_SIMOS_TRUCKS_REQUEST, ISimosTrucks } from "./getSimosTrucksConstaints";

export interface IGetSimosTrucksLoadAction {
    type: IGET_SIMOS_TRUCKS_REQUEST.REQUEST;
    data: IGetSimosTrucksRequest
}
export const getSimosTrucksLoadAction = (data: IGetSimosTrucksRequest): IGetSimosTrucksLoadAction => ({
    type: IGET_SIMOS_TRUCKS_REQUEST.REQUEST,
    data
});

export interface IGetSimosTrucksSuccessAction {
    type: IGET_SIMOS_TRUCKS_REQUEST.SUCCESS;
    data: SimosAPIResponse<ISimosTrucks[]>;
}
export const getSimosTrucksLoadSuccessAction = (data: SimosAPIResponse<ISimosTrucks[]>): IGetSimosTrucksSuccessAction => ({
    type: IGET_SIMOS_TRUCKS_REQUEST.SUCCESS,
    data
});

export interface IGetSimosTrucksLoadFailedAction {
    type: IGET_SIMOS_TRUCKS_REQUEST.FAILED;
    message: string;
}
export const getSimosTrucksLoadFailedAction = (message: string): IGetSimosTrucksLoadFailedAction => ({
    type: IGET_SIMOS_TRUCKS_REQUEST.FAILED,
    message
});
