import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { getWashPlantRunMaterialsStatus } from "../../../../redux/sil/washPlant/getWashPlantRunMaterials/getWashPlantRunMaterialsAccessor";
import { getWashPlantRunMaterialsLoadAction } from "../../../../redux/sil/washPlant/getWashPlantRunMaterials/getWashPlantRunMaterialsActions";
import { IWashPlantRunMaterials, IGetWashPlantRunMaterialsRequest } from "../../../../redux/sil/washPlant/getWashPlantRunMaterials/getWashPlantRunMaterialsConstaints";
import { hasPayload, isFailed, isNotLoaded, isSucceeded, Server } from "../../../../redux/server";
import { ById, SilAPIResponse } from "../../../shared/publicInterfaces";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import { userName, undefinedFunction } from "../../../shared/constExports";
import { LAButton, LASaveAndCancelButton } from "../../../shared/buttons";
import { ArrowLeftIcon } from "../../../shared/icons";
import { WHITE_COLOR } from "../../../shared/theme";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import LATextField from "../../../shared/textField";
import LAAutoComplete from "../../../shared/autoComplete";
import { activeOptions } from "./washPlantItemForm";
import { ROUTE } from "../../../routes";
import queryString from "query-string";
import { updateWashPlantRunMaterialLoadAction } from "../../../../redux/sil/washPlant/updateWashPlantRunMaterial/updateWashPlantRunMaterialActions";
import { updateWashPlantRunMaterialStatus } from "../../../../redux/sil/washPlant/updateWashPlantRunMaterial/updateWashPlantRunMaterialAccessor";
import { IUpdateWashPlantRunMaterialRequest } from "../../../../redux/sil/washPlant/updateWashPlantRunMaterial/updateWashPlantRunMaterialConstaints";
import { IAddWashPlantRunMaterialRequest } from "../../../../redux/sil/washPlant/addWashPlantRunMaterial/addWashPlantRunMaterialConstaints";
import { addWashPlantRunMaterialStatus } from "../../../../redux/sil/washPlant/addWashPlantRunMaterial/addWashPlantRunMaterialAccessor";
import { addWashPlantRunMaterialLoadAction } from "../../../../redux/sil/washPlant/addWashPlantRunMaterial/addWashPlantRunMaterialActions";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import { FieldValidator, IFieldErrorKeyValue } from "../../../shared/fieldValidator";
import PageSpacing from "../../../shared/pageSpacing";
import LAErrorBox from "../../../shared/errorBox";
import SilRoleCheck from "../../../shared/silRoleCheck";


interface IWashPlantRunMaterialStoreProps {
    addRunMaterial: Server<string>;
    updateRunMaterial: Server<string>;
    getToken: Server<SilAPIResponse<IToken>>;
    getRunMaterials: Server<SilAPIResponse<IWashPlantRunMaterials[]>>;
};

interface IWashPlantRunMaterialDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getRunMaterialsRequest: (data: IGetWashPlantRunMaterialsRequest) => unknown;
    addRunMaterialRequest: (data: IAddWashPlantRunMaterialRequest) => unknown;
    updateRunMaterialRequest: (data: IUpdateWashPlantRunMaterialRequest) => unknown;
};


interface IWashPlantRunMaterialOwnProps {

};

interface IWashPlantRunMaterialState {
    sError: string;
    runM: IWashPlantRunMaterials;
    errors: ById<IFieldErrorKeyValue>;
};

const WashPlantRunMaterialStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
`;

type IWashPlantRunMaterialProps = RouteComponentProps
    & IWashPlantRunMaterialStoreProps
    & IWashPlantRunMaterialDispatchProps
    & IWashPlantRunMaterialOwnProps;

class WashPlantRunMaterial extends PureComponent<IWashPlantRunMaterialProps, IWashPlantRunMaterialState> {

    public constructor(props: IWashPlantRunMaterialProps) {
        super(props);
        this.state = {
            errors: {
                "run_Material": { key: "run_Material", message: "Required" },
                "product_Code": { key: "product_Code", message: "Required" }
            },
            runM: {
                id: 0,
                by_Product: "",
                product_Code: "",
                active: "Yes",
                created: "",
                modified: "",
                ball_Mill: "No",
                yield_Product_1: "",
                yield_Product_1_Perc: "",
                yield_Product_2: "",
                yield_Product_2_Perc: "",
                yield_Product_3: "",
                yield_Product_3_Perc: "",
                yield_Product_4: "",
                yield_Product_4_Perc: "",
                created_By: userName,
                modified_By: userName
            },
            sError: ""
        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: IWashPlantRunMaterialProps): void {
        if (this.props !== prevProps) {
            this.callServer();

            if (this.props.addRunMaterial !== prevProps.addRunMaterial) {
                if (isSucceeded(this.props.addRunMaterial)) {
                    this.handleCancel();
                };
                if (isFailed(this.props.addRunMaterial)) {
                    this.setState({ sError: this.props.addRunMaterial.message });
                };
            };

            if (this.props.updateRunMaterial !== prevProps.updateRunMaterial) {
                if (isSucceeded(this.props.updateRunMaterial)) {
                    this.handleCancel();
                };
                if (isFailed(this.props.updateRunMaterial)) {
                    this.setState({ sError: this.props.updateRunMaterial.message });
                };
            };
        };
    };


    public render(): ReactNode {

        const { runM, errors, sError } = this.state;
        const { updateRunMaterial, addRunMaterial } = this.props;
        // const handleBlurYield1 = (): void => this.onNumberChange("yield_Product_1_Perc", runM.yield_Product_1_Perc? Number(runM.yield_Product_1_Perc).toFixed(2): "");
        // const handleBlurYield2 = (): void => this.onNumberChange("yield_Product_2_Perc", runM.yield_Product_2_Perc? Number(runM.yield_Product_2_Perc).toFixed(2): "");
        // const handleBlurYield3 = (): void => this.onNumberChange("yield_Product_3_Perc", runM.yield_Product_3_Perc? Number(runM.yield_Product_3_Perc).toFixed(2): "");
        // const handleBlurYield4 = (): void => this.onNumberChange("yield_Product_4_Perc", runM.yield_Product_4_Perc? Number(runM.yield_Product_4_Perc).toFixed(2): "");

        return (
            <PageSpacing title="SIL - Run Material" description="SIL - Run Material" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="washPlantAccess">
                    <WashPlantRunMaterialStyles>

                        <LAButton startIcon={<ArrowLeftIcon color={WHITE_COLOR} />} label="Back to list" onClick={this.handleBack} />
                        <h2 className="text-center">{runM.id > 0 ? "VIEW/UPDATE " : "ADD "} BY PRODUCT</h2>
                        <hr />

                        <LAPaperWithPadding>
                            <LAGrid>

                                <LAGridItem xs={6}>
                                    <LATextField
                                        label="By Product"
                                        fullWidth={true}
                                        name="by_Product"
                                        onChange={this.onChange}
                                        value={runM.by_Product}
                                        disabled={runM.id > 0 ? true : undefined}
                                        errorText={errors["by_product"] ? errors["by_product"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={6}>
                                    <LATextField
                                        label="Product Code"
                                        fullWidth={true}
                                        name="product_Code"
                                        onChange={this.onChange}
                                        value={runM.product_Code}
                                        errorText={errors["product_Code"] ? errors["product_Code"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={6}>
                                    <LAAutoComplete
                                        filterSelectedOptions={true}
                                        autoHighlight={true}
                                        selectionRemove={undefinedFunction}
                                        dropDownPlaceHolder="Active"
                                        getOptionLabel="name"
                                        name="active"
                                        option={activeOptions}
                                        multiple={false}
                                        onChange={this.handleDropDownChange}
                                        value={activeOptions.find(x => x.name === runM.active)}
                                        defaultValue={activeOptions.find(x => x.name === runM.active)}
                                    />
                                </LAGridItem>

                                {sError.length > 0 && <LAGridItem xs={12}>
                                    <LAErrorBox text={sError} />
                                </LAGridItem>}

                                <LAGridItem xs={12}>
                                    <LASaveAndCancelButton
                                        onSave={this.onSave}
                                        onCancel={this.handleCancel}
                                    />
                                </LAGridItem>

                            </LAGrid>
                        </LAPaperWithPadding>

                        <RequestStatus requestStatus={addRunMaterial.kind} successMessage="Run Material successfully saved" />
                        <RequestStatus requestStatus={updateRunMaterial.kind} successMessage="Run Material successfully updated" />
                    </WashPlantRunMaterialStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, isRequired: boolean): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: isRequired ? true : undefined, minLength: 1, decimal: undefined });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        };
        return err;
    };

    private handleCancel = (): void => {
        this.props.history.push(ROUTE.SIL.WASH_PLANT.WASH_PLANT_RUN_MATERIALS);
    };

    private onSave = (): void => {
        if (hasPayload(this.props.getToken))
            if (this.state.runM.id === 0) {
                this.props.addRunMaterialRequest({
                    token: this.props.getToken.payload.response.token,
                    request: this.state.runM
                });
            } else {
                let data = this.state.runM;
                data.modified_By = userName;
                this.props.updateRunMaterialRequest({
                    token: this.props.getToken.payload.response.token,
                    request: data
                });
            };

        this.setState({ sError: "" });
    };

    private handleDropDownChange = (event: unknown, value: { id: number, name: string } | "", name?: string): void => {
        if (name)
            this.setState({
                ...this.state,
                runM: {
                    ...this.state.runM,
                    [name]: value !== "" ? value.name : ""
                }
            });
    };

    private onNumberChange = (name: string, value: string): void => {
        if (!isNaN(Number(value))) {
            let errors = this.state.errors;
            errors = this.errorChecker(name, value, errors, true);

            this.setState({
                ...this.state,
                errors,
                runM: {
                    ...this.state.runM,
                    [name]: value
                }
            });
        };
    };


    private onChange = (name: string, value: string): void => {
        let errors = this.state.errors;
        errors = this.errorChecker(name, value, errors, true);

        this.setState({
            ...this.state,
            errors,
            runM: {
                ...this.state.runM,
                [name]: value
            }
        });
    };

    private handleBack = (): void => {
        this.setState({ sError: "" });
        this.props.history.push(ROUTE.SIL.WASH_PLANT.WASH_PLANT_RUN_MATERIALS);
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });


        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getRunMaterials))
            this.props.getRunMaterialsRequest({
                token: this.props.getToken.payload.response.token
            });


        const query = queryString.parse(this.props.location.search);
        const id = query.id ?? "0";

        if ((id !== "0") && (hasPayload(this.props.getRunMaterials)) && (this.state.runM.id === 0)) {
            const runM = this.props.getRunMaterials.payload.response.find(x => x.id === Number(id));

            if (runM)
                this.setState({ runM, errors: {} });
        };

    };

}

const mapStateToProps = (state: IStore): IWashPlantRunMaterialStoreProps => ({
    getToken: getTokenStatus(state),
    getRunMaterials: getWashPlantRunMaterialsStatus(state),
    addRunMaterial: addWashPlantRunMaterialStatus(state),
    updateRunMaterial: updateWashPlantRunMaterialStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IWashPlantRunMaterialDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getRunMaterialsRequest: (data: IGetWashPlantRunMaterialsRequest) => dispatch(getWashPlantRunMaterialsLoadAction(data)),
    addRunMaterialRequest: (data: IUpdateWashPlantRunMaterialRequest) => dispatch(addWashPlantRunMaterialLoadAction(data)),
    updateRunMaterialRequest: (data: IUpdateWashPlantRunMaterialRequest) => dispatch(updateWashPlantRunMaterialLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(WashPlantRunMaterial);