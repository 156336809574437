import { IAddWashPlantProductionRequest, IADD_WASH_PLANT_PRODUCTION_REQUEST } from "./addWashPlantProductionConstaints";

export interface IAddWashPlantProductionLoadAction {
    type: IADD_WASH_PLANT_PRODUCTION_REQUEST.REQUEST;
    data: IAddWashPlantProductionRequest
}
export const addWashPlantProductionLoadAction = (data: IAddWashPlantProductionRequest): IAddWashPlantProductionLoadAction => ({
    type: IADD_WASH_PLANT_PRODUCTION_REQUEST.REQUEST,
    data
});

export interface IAddWashPlantProductionSuccessAction {
    type: IADD_WASH_PLANT_PRODUCTION_REQUEST.SUCCESS;
    message: string;
}
export const addWashPlantProductionLoadSuccessAction = (message: string): IAddWashPlantProductionSuccessAction => ({
    type: IADD_WASH_PLANT_PRODUCTION_REQUEST.SUCCESS,
    message
});
export interface IAddWashPlantProductionLoadFailedAction {
    type: IADD_WASH_PLANT_PRODUCTION_REQUEST.FAILED;
    message: string;
}
export const addWashPlantProductionLoadFailedAction = (message: string): IAddWashPlantProductionLoadFailedAction => ({
    type: IADD_WASH_PLANT_PRODUCTION_REQUEST.FAILED,
    message
});
