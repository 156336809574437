import { IPassTokenWithRequest } from "../../dryPlant/token/getTokenConstants";

export enum IADD_CARRIER_EMAIL_REQUEST {
    REQUEST = "addCarrierEmail/ADD_CARRIER_EMAIL_REQUEST",
    SUCCESS = "addCarrierEmail/ADD_CARRIER_EMAIL_SUCCESS",
    FAILED = "addCarrierEmail/ADD_CARRIER_EMAIL_FAILED"
};

export interface IAddCarrierEmailRequest extends IPassTokenWithRequest {
    request: {
        subject: string;
        email_Body: string;
        email_All_Carriers: string;
        carriers: number[];
        attachment?: string;
        attachment_Name?: string;
        created_By: string;
        modified_By: string;
    };
};
