import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { getBinAdjustmentsStatus } from "../../../../redux/sil/dryPlant/getBinAdjustments/getBinAdjustmentsAccessor";
import { getBinAdjustmentsLoadAction } from "../../../../redux/sil/dryPlant/getBinAdjustments/getBinAdjustmentsActions";
import { IBinAdjustments, IGetBinAdjustmentsRequest } from "../../../../redux/sil/dryPlant/getBinAdjustments/getBinAdjustmentsConstaints";
import { hasPayload, isNotLoaded, Server } from "../../../../redux/server";
import { SilAPIResponse } from "../../../shared/publicInterfaces";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import { callRouteWithQueryString, userName } from "../../../shared/constExports";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { LAButton } from "../../../shared/buttons";
import { AddIcon } from "../../../shared/icons";
import { WHITE_COLOR } from "../../../shared/theme";
import { ROUTE } from "../../../routes";
import PageSpacing from "../../../shared/pageSpacing";
import { IGetProductionLookupsRequest, IProductionLookups } from "../../../../redux/sil/dryPlant/getProductionLookups/getProductionLookupsConstaints";
import { getProductLookupsLoadAction } from "../../../../redux/sil/dryPlant/getProductLookups/getProductLookupsActions";
import { getProductLookupsStatus } from "../../../../redux/sil/dryPlant/getProductLookups/getProductLookupsAccessor";
import SilRoleCheck from "../../../shared/silRoleCheck";


interface IDryPlantBinAdjustmentsStoreProps {
    getToken: Server<SilAPIResponse<IToken>>;
    getBinAdjustments: Server<SilAPIResponse<IBinAdjustments[]>>;
    getDropDownData: Server<SilAPIResponse<IProductionLookups>>;
};

interface IDryPlantBinAdjustmentsDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getBinAdjustmentsRequest: (data: IGetBinAdjustmentsRequest) => unknown;
    getDropDownDataRequest: (data: IGetProductionLookupsRequest) => unknown;
};


interface IDryPlantBinAdjustmentsOwnProps {

};

interface IDryPlantBinAdjustmentsState {

};

const DryPlantBinAdjustmentsStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
    };
`;

type IDryPlantBinAdjustmentsProps = RouteComponentProps
    & IDryPlantBinAdjustmentsStoreProps
    & IDryPlantBinAdjustmentsDispatchProps
    & IDryPlantBinAdjustmentsOwnProps;

class DryPlantBinAdjustments extends PureComponent<IDryPlantBinAdjustmentsProps, IDryPlantBinAdjustmentsState> {

    public constructor(props: IDryPlantBinAdjustmentsProps) {
        super(props);
        this.state = {
        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: IDryPlantBinAdjustmentsProps): void {
        if (this.props !== prevProps)
            this.callServer();
    };


    public render(): ReactNode {

        const { getBinAdjustments } = this.props;
        const data = hasPayload(getBinAdjustments) ? Object.values(getBinAdjustments.payload.response) : [];

        return (
            <PageSpacing title="SIL - Bin Adjustments" description="SIL - Bin Adjustments" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="dryPlantAccess">
                    <DryPlantBinAdjustmentsStyles>

                        <LAButton className="pull-left" startIcon={<AddIcon color={WHITE_COLOR} />} label="Add" onClick={this.handleAdd} />
                        <h2 className="text-center">BIN ADJUSTMENTS</h2>
                        <hr />

                        <LADevExtremeGrid
                            data={data}
                            onEdit={this.onEdit}
                            onClick={this.onClick}
                            searchPanel={true}
                            filterHeader={true}
                            export={true}
                            exportFileName={"Bin Adjustments"}
                            storageKey="silDryPlantBinAdjustmentsStorageKey"
                            columns={[
                                { name: "type", caption: "Type", type: "string" },
                                { name: "adjustment_Type", caption: "Adjustment Type", type: "string" },
                                { name: "transfer_Date", caption: "Transfer Date", type: "date", width: 130, sortDesc: true },
                                { name: "bin", caption: "Bin", type: "string" },
                                { name: "bin_From", caption: "Bin From", type: "string" },
                                { name: "bin_To", caption: "Pile To", type: "string" },
                                { name: "product", caption: "Product", type: "string" },
                                { name: "tonnage", caption: "Tonnage", type: "string" },
                                { name: "reason", caption: "Reason", type: "string" },
                                { name: "modified", caption: "Modified", type: "string" },
                                { name: "modified_By", caption: "Modified By", type: "string" }
                            ]}
                        />
                    </DryPlantBinAdjustmentsStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString() },
            pathName: ROUTE.SIL.DRY_PLANT.DRY_PLANT_BIN_ADJUSTMENT
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0" },
            pathName: ROUTE.SIL.DRY_PLANT.DRY_PLANT_BIN_ADJUSTMENT
        });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getDropDownData))
            this.props.getDropDownDataRequest({
                token: this.props.getToken.payload.response.token,
                requested_Page: "BinAdjustment"
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getBinAdjustments))
            this.props.getBinAdjustmentsRequest({
                token: this.props.getToken.payload.response.token
            });
    };

}

const mapStateToProps = (state: IStore): IDryPlantBinAdjustmentsStoreProps => ({
    getToken: getTokenStatus(state),
    getBinAdjustments: getBinAdjustmentsStatus(state),
    getDropDownData: getProductLookupsStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IDryPlantBinAdjustmentsDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getBinAdjustmentsRequest: (data: IGetBinAdjustmentsRequest) => dispatch(getBinAdjustmentsLoadAction(data)),
    getDropDownDataRequest: (data: IGetProductionLookupsRequest) => dispatch(getProductLookupsLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DryPlantBinAdjustments);