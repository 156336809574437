import { IAddCarrierEmailRequest, IADD_CARRIER_EMAIL_REQUEST } from "./addCarrierEmailConstaints";

export interface IAddCarrierEmailLoadAction {
    type: IADD_CARRIER_EMAIL_REQUEST.REQUEST;
    data: IAddCarrierEmailRequest
}
export const addCarrierEmailLoadAction = (data: IAddCarrierEmailRequest): IAddCarrierEmailLoadAction => ({
    type: IADD_CARRIER_EMAIL_REQUEST.REQUEST,
    data
});

export interface IAddCarrierEmailSuccessAction {
    type: IADD_CARRIER_EMAIL_REQUEST.SUCCESS;
    message: string;
}
export const addCarrierEmailLoadSuccessAction = (message: string): IAddCarrierEmailSuccessAction => ({
    type: IADD_CARRIER_EMAIL_REQUEST.SUCCESS,
    message
});

export interface IAddCarrierEmailLoadFailedAction {
    type: IADD_CARRIER_EMAIL_REQUEST.FAILED;
    message: string;
}
export const addCarrierEmailLoadFailedAction = (message: string): IAddCarrierEmailLoadFailedAction => ({
    type: IADD_CARRIER_EMAIL_REQUEST.FAILED,
    message
});
