import { IGetCarrierAudit } from "../getAudit/getCarrierAuditConstants";

export enum IUPDATE_CARRIER_AUDIT_REQUEST {
    REQUEST = "updateCarrierAudit/UPDATE_CARRIER_AUDIT_REQUEST",
    SUCCESS = "updateCarrierAudit/UPDATE_CARRIER_AUDIT_SUCCESS",
    FAILED = "updateCarrierAudit/UPDATE_CARRIER_AUDIT_FAILED"
};

export interface IUpdateCarrierAuditRequest {
    token: string;
    request: IGetCarrierAudit;
};