import {currentUser} from '../../../../simosHome';

async function updateProductLookup(new_product:any, editID:number) {
    var res:any = null;
    let requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type':'application/json'},
        body: JSON.stringify({
            request: {
                ID:editID,
                Description:new_product,
                Modified_By:currentUser
            },
            token: '19515195-d571-44a3-b0db-637f5bf24f54'})
    }
    await fetch('https://api2.propsense.com/api/UpdateSIMOSProductLookup', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json')&& await response.json();
        // error response
        if(!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        res = response;
    });
    return res;
}

export {updateProductLookup};