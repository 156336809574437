import { SilAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../../flushData.tsx/flushDataConstants";
import { Server, failed, loading, notLoaded, succeeded } from "../../../../server";
import { IAddSieveFracBallSuccessAction } from "../addSieveFracBall/addSieveFracBallActions";
import { IADD_SIEVE_FRAC_BALL_REQUEST } from "../addSieveFracBall/addSieveFracBallConstaints";
import { IUpdateSieveFracBallSuccessAction } from "../updateSieveFracBall/updateSieveFracBallActions";
import { IUPDATE_SIEVE_FRAC_BALL_REQUEST } from "../updateSieveFracBall/updateSieveFracBallConstaints";
import { IGetSieveFracBallLoadAction, IGetSieveFracBallLoadFailedAction, IGetSieveFracBallSuccessAction } from "./getSieveFracBallsActions";
import { ISieveFracBall, IGET_SIEVE_FRAC_BALLS_REQUEST } from "./getSieveFracBallsConstaints";

type Actions =
    IGetSieveFracBallLoadAction
    | IGetSieveFracBallLoadFailedAction
    | IGetSieveFracBallSuccessAction
    | IAddSieveFracBallSuccessAction
    | IUpdateSieveFracBallSuccessAction
    | IFlushDataSuccessAction;

export const GetSieveFracBallReducer = (state: Server<SilAPIResponse<ISieveFracBall[]>> = notLoaded, action: Actions): Server<SilAPIResponse<ISieveFracBall[]>> => {
    switch (action.type) {
        case IGET_SIEVE_FRAC_BALLS_REQUEST.REQUEST:
            return loading;

        case IGET_SIEVE_FRAC_BALLS_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_SIEVE_FRAC_BALLS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_SIEVE_FRAC_BALL_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_SIEVE_FRAC_BALL_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};