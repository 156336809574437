import { IRunMaterials } from "../getRunMaterials/getRunMaterialsConstaints";

export enum IUPDATE_RUN_MATERIAL_REQUEST {
    REQUEST = "updateRunMaterial/UPDATE_RUN_MATERIAL_REQUEST",
    SUCCESS = "updateRunMaterial/UPDATE_RUN_MATERIAL_SUCCESS",
    FAILED = "updateRunMaterial/UPDATE_RUN_MATERIAL_FAILED"
};

export interface IUpdateRunMaterialRequest {
    token: string;
    request: IRunMaterials;
};