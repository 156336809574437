import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { getWashPlantFeedMaterialsStatus } from "../../../../redux/sil/washPlant/getWashPlantFeedMaterials/getWashPlantFeedMaterialsAccessor";
import { getWashPlantFeedMaterialsLoadAction } from "../../../../redux/sil/washPlant/getWashPlantFeedMaterials/getWashPlantFeedMaterialsActions";
import { IWashPlantFeedMaterials, IGetWashPlantFeedMaterialsRequest } from "../../../../redux/sil/washPlant/getWashPlantFeedMaterials/getWashPlantFeedMaterialsConstaints";
import { hasPayload, isFailed, isNotLoaded, isSucceeded, Server } from "../../../../redux/server";
import { ById, SilAPIResponse } from "../../../shared/publicInterfaces";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import { userName, undefinedFunction } from "../../../shared/constExports";
import { LAButton, LASaveAndCancelButton } from "../../../shared/buttons";
import { ArrowLeftIcon } from "../../../shared/icons";
import { WHITE_COLOR } from "../../../shared/theme";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import LATextField from "../../../shared/textField";
import LAAutoComplete from "../../../shared/autoComplete";
import { activeOptions } from "./washPlantItemForm";
import { ROUTE } from "../../../routes";
import queryString from "query-string";
import { updateWashPlantFeedMaterialLoadAction } from "../../../../redux/sil/washPlant/updateWashPlantFeedMaterial/updateWashPlantFeedMaterialActions";
import { updateWashPlantFeedMaterialStatus } from "../../../../redux/sil/washPlant/updateWashPlantFeedMaterial/updateWashPlantFeedMaterialAccessor";
import { IUpdateWashPlantFeedMaterialRequest } from "../../../../redux/sil/washPlant/updateWashPlantFeedMaterial/updateWashPlantFeedMaterialConstaints";
import { IAddWashPlantFeedMaterialRequest } from "../../../../redux/sil/washPlant/addWashPlantFeedMaterial/addWashPlantFeedMaterialConstaints";
import { addWashPlantFeedMaterialStatus } from "../../../../redux/sil/washPlant/addWashPlantFeedMaterial/addWashPlantFeedMaterialAccessor";
import { addWashPlantFeedMaterialLoadAction } from "../../../../redux/sil/washPlant/addWashPlantFeedMaterial/addWashPlantFeedMaterialActions";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import { FieldValidator, IFieldErrorKeyValue } from "../../../shared/fieldValidator";
import PageSpacing from "../../../shared/pageSpacing";
import LAErrorBox from "../../../shared/errorBox";
import SilRoleCheck from "../../../shared/silRoleCheck";


interface IWashPlantFeedMaterialStoreProps {
    addFeedMaterial: Server<string>;
    updateFeedMaterial: Server<string>;
    getToken: Server<SilAPIResponse<IToken>>;
    getFeedMaterials: Server<SilAPIResponse<IWashPlantFeedMaterials[]>>;
};

interface IWashPlantFeedMaterialDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getFeedMaterialsRequest: (data: IGetWashPlantFeedMaterialsRequest) => unknown;
    addFeedMaterialRequest: (data: IAddWashPlantFeedMaterialRequest) => unknown;
    updateFeedMaterialRequest: (data: IUpdateWashPlantFeedMaterialRequest) => unknown;
};


interface IWashPlantFeedMaterialOwnProps {

};

interface IWashPlantFeedMaterialState {
    sError: string;
    feedM: IWashPlantFeedMaterials;
    errors: ById<IFieldErrorKeyValue>;
};

const WashPlantFeedMaterialStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
`;

type IWashPlantFeedMaterialProps = RouteComponentProps
    & IWashPlantFeedMaterialStoreProps
    & IWashPlantFeedMaterialDispatchProps
    & IWashPlantFeedMaterialOwnProps;

class WashPlantFeedMaterial extends PureComponent<IWashPlantFeedMaterialProps, IWashPlantFeedMaterialState> {

    public constructor(props: IWashPlantFeedMaterialProps) {
        super(props);
        this.state = {
            errors: {
                "feed_Material": { key: "feed_Material", message: "Required" },
                "product_Code": { key: "product_Code", message: "Required" }
            },
            feedM: {
                id: 0,
                feed_Material: "",
                product_Code: "",
                active: "Yes",
                created: "",
                modified: "",
                ball_Mill: "",
                created_By: userName,
                modified_By: userName
            },
            sError: ""
        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: IWashPlantFeedMaterialProps): void {
        if (this.props !== prevProps) {
            this.callServer();

            if (this.props.addFeedMaterial !== prevProps.addFeedMaterial) {
                if (isSucceeded(this.props.addFeedMaterial)) {
                    this.handleCancel();
                };
                if (isFailed(this.props.addFeedMaterial)) {
                    this.setState({ sError: this.props.addFeedMaterial.message });
                };
            };

            if (this.props.updateFeedMaterial !== prevProps.updateFeedMaterial) {
                if (isSucceeded(this.props.updateFeedMaterial)) {
                    this.handleCancel();
                };
                if (isFailed(this.props.updateFeedMaterial)) {
                    this.setState({ sError: this.props.updateFeedMaterial.message });
                };
            };
        }
    };


    public render(): ReactNode {

        const { feedM, errors, sError } = this.state;
        const { updateFeedMaterial, addFeedMaterial } = this.props;

        return (
            <PageSpacing title="SIL - Feed Material" description="SIL - Feed Material" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="washPlantAccess">
                    <WashPlantFeedMaterialStyles>

                        <LAButton startIcon={<ArrowLeftIcon color={WHITE_COLOR} />} label="Back to list" onClick={this.handleBack} />
                        <h2 className="text-center">{feedM.id > 0 ? "VIEW/UPDATE " : "ADD "} FEED MATERIAL</h2>
                        <hr />

                        <LAPaperWithPadding>
                            <LAGrid>

                                <LAGridItem xs={6}>
                                    <LATextField
                                        label="Feed Material"
                                        fullWidth={true}
                                        name="feed_Material"
                                        onChange={this.onChange}
                                        value={feedM.feed_Material}
                                        disabled={feedM.id > 0 ? true : undefined}
                                        errorText={errors["feed_Material"] ? errors["feed_Material"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={6}>
                                    <LATextField
                                        label="Product Code"
                                        fullWidth={true}
                                        name="product_Code"
                                        onChange={this.onChange}
                                        value={feedM.product_Code}
                                        errorText={errors["product_Code"] ? errors["product_Code"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={6}>
                                    <LAAutoComplete
                                        filterSelectedOptions={true}
                                        autoHighlight={true}
                                        selectionRemove={undefinedFunction}
                                        dropDownPlaceHolder="Active"
                                        getOptionLabel="name"
                                        name="active"
                                        option={activeOptions}
                                        multiple={false}
                                        onChange={this.handleDropDownChange}
                                        value={activeOptions.find(x => x.name === feedM.active)}
                                        defaultValue={activeOptions.find(x => x.name === feedM.active)}
                                    />
                                </LAGridItem>

                                {sError.length > 0 && <LAGridItem xs={12}>
                                    <LAErrorBox text={sError} />
                                </LAGridItem>}

                                <LAGridItem xs={12}>
                                    <LASaveAndCancelButton
                                        onSave={this.onSave}
                                        onCancel={this.handleCancel}
                                    />
                                </LAGridItem>

                            </LAGrid>
                        </LAPaperWithPadding>

                        <RequestStatus requestStatus={addFeedMaterial.kind} successMessage="Feed Material successfully saved" />
                        <RequestStatus requestStatus={updateFeedMaterial.kind} successMessage="Feed Material successfully updated" />
                    </WashPlantFeedMaterialStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, isRequired: boolean): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: isRequired ? true : undefined, minLength: 1, decimal: undefined });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        };
        return err;
    };

    private handleCancel = (): void => {
        this.props.history.push(ROUTE.SIL.WASH_PLANT.WASH_PLANT_FEED_MATERIALS);
    };

    private onSave = (): void => {
        if (hasPayload(this.props.getToken))
            if (this.state.feedM.id === 0) {
                this.props.addFeedMaterialRequest({
                    token: this.props.getToken.payload.response.token,
                    request: this.state.feedM
                });
            } else {
                let data = this.state.feedM;
                data.modified_By = userName;
                this.props.updateFeedMaterialRequest({
                    token: this.props.getToken.payload.response.token,
                    request: data
                });
            };
        this.setState({ sError: "" });
    };

    private handleDropDownChange = (event: unknown, value: { id: number, name: string } | "", name?: string): void => {
        if (name)
            this.setState({
                ...this.state,
                feedM: {
                    ...this.state.feedM,
                    [name]: value !== "" ? value.name : ""
                }
            });
    };

    private onChange = (name: string, value: string): void => {
        let errors = this.state.errors;
        errors = this.errorChecker(name, value, errors, true);

        this.setState({
            ...this.state,
            errors,
            feedM: {
                ...this.state.feedM,
                [name]: value
            }
        });
    };

    private handleBack = (): void => {
        this.setState({ sError: "" });
        this.props.history.push(ROUTE.SIL.WASH_PLANT.WASH_PLANT_FEED_MATERIALS);
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });


        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getFeedMaterials))
            this.props.getFeedMaterialsRequest({
                token: this.props.getToken.payload.response.token
            });


        const query = queryString.parse(this.props.location.search);
        const id = query.id ?? "0";

        if ((id !== "0") && (hasPayload(this.props.getFeedMaterials)) && (this.state.feedM.id === 0)) {
            const feedM = this.props.getFeedMaterials.payload.response.find(x => x.id === Number(id));

            if (feedM)
                this.setState({ feedM, errors: {} });
        };

    };

}

const mapStateToProps = (state: IStore): IWashPlantFeedMaterialStoreProps => ({
    getToken: getTokenStatus(state),
    getFeedMaterials: getWashPlantFeedMaterialsStatus(state),
    addFeedMaterial: addWashPlantFeedMaterialStatus(state),
    updateFeedMaterial: updateWashPlantFeedMaterialStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IWashPlantFeedMaterialDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getFeedMaterialsRequest: (data: IGetWashPlantFeedMaterialsRequest) => dispatch(getWashPlantFeedMaterialsLoadAction(data)),
    addFeedMaterialRequest: (data: IUpdateWashPlantFeedMaterialRequest) => dispatch(addWashPlantFeedMaterialLoadAction(data)),
    updateFeedMaterialRequest: (data: IUpdateWashPlantFeedMaterialRequest) => dispatch(updateWashPlantFeedMaterialLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(WashPlantFeedMaterial);