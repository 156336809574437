            

 function formatDateFromDBFormat(date:string, hour:string, min:string){
    var formatted;
    if(hour[hour.length-2]+hour[hour.length-1]==="AM"){
        if(hour.length===4){
            formatted = date+"T"+"0"+hour[0]+":"+min;
        }
        else {
            formatted = date+"T"+hour[0]+hour[1]+":"+min;
        }
    } else {
        if(hour.length===5){
            var convertToNumber = String(12 + Number(hour[0]));
            formatted = date+"T"+convertToNumber+":"+min;
        }
        else {
            
            var convertToNumber = hour[0]+hour[1];
            convertToNumber = String(12 + Number(convertToNumber));
            formatted = date+"T"+convertToNumber+":"+min;
        }
    }
    //("YYYY-MM-DDTkk:mm");
    return formatted;
}
function formatDateToDBFormat(date:any){
    var format_hour = ( ( Number(date[date.length-5])*10) + Number(date[date.length-4]));
    var pickup_min = String(date[date.length-2]) + String(date[date.length-1]);

    let pickup_hour = "";

    if(format_hour-12 >= 0){
        if(format_hour !== 12){
            format_hour= format_hour -12;
        }
        pickup_hour = format_hour + " PM";
    } else {
        if(format_hour===0){
            format_hour = 12;
        }
        pickup_hour = format_hour + " AM";
    }
    var format="";
    for(var i=0;i<10;i++){
        format+=(date[i]);
    }
    return [format, pickup_hour, pickup_min]
}

export {formatDateFromDBFormat, formatDateToDBFormat}