import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAGreyPaper, LAPaperWithPadding } from "../../../../shared/paper";
import { IDispatch, IStore } from "../../../../../redux/reducers";
import { hasPayload, isNotLoaded, isSucceeded, Server } from "../../../../../redux/server";
import { ById, SimosAPIResponse } from "../../../../shared/publicInterfaces";
import { userName, undefinedFunction, yesOrNoOptions, callRouteWithQueryString, NotApplicable, ReadOnly } from "../../../../shared/constExports";
import { LAButton, LASaveAndCancelButton } from "../../../../shared/buttons";
import { ArrowLeftIcon } from "../../../../shared/icons";
import { WHITE_COLOR } from "../../../../shared/theme";
import LAGrid from "../../../../shared/grid";
import LAGridItem from "../../../../shared/gridList";
import LATextField from "../../../../shared/textField";
import { ROUTE } from "../../../../routes";
import queryString from "query-string";
import RequestStatus from "../../../../shared/requestStatusSnackbar";
import { FieldValidator, FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../../../shared/fieldValidator";
import PageSpacing from "../../../../shared/pageSpacing";
import { IGetTokenRequest, IToken } from "../../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenStatus } from "../../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { getTokenLoadAction } from "../../../../../redux/sil/dryPlant/token/getTokenActions";
import { ISimosCustomers, ISimosCustomersRequest } from "../../../../../redux/propsense/simos/simosCustomers/getSimosCustomers/getSimosCustomersConstants";
import { IAddSimosCustomerRequest, IAddUpdateSimosCustomer } from "../../../../../redux/propsense/simos/simosCustomers/addSimosCustomer/addSimosCustomerConstaints";
import { getSimosCustomers } from "../../../../../redux/propsense/simos/simosCustomers/getSimosCustomers/getSimosCustomersAccessor";
import { addSimosCustomerStatus } from "../../../../../redux/propsense/simos/simosCustomers/addSimosCustomer/addSimosCustomerAccessor";
import { getSimosCustomersLoadAction } from "../../../../../redux/propsense/simos/simosCustomers/getSimosCustomers/getSimosCustomersActions";
import { addSimosCustomerLoadAction } from "../../../../../redux/propsense/simos/simosCustomers/addSimosCustomer/addSimosCustomerActions";
import LAAutoComplete from "../../../../shared/autoComplete";
import { updateSimosCustomerStatus } from "../../../../../redux/propsense/simos/simosCustomers/updateSimosCustomer/updateSimosCustomerAccessor";
import { IUpdateSimosCustomerRequest } from "../../../../../redux/propsense/simos/simosCustomers/updateSimosCustomer/updateSimosCustomerConstaints";
import { updateSimosCustomerLoadAction } from "../../../../../redux/propsense/simos/simosCustomers/updateSimosCustomer/updateSimosCustomerActions";
import { LADevExtremeGrid } from "../../../../shared/devExtreme";
import LAErrorBox from "../../../../shared/errorBox";

const RequiredFields = ["customer"];

interface ISimosCustomerStoreProps {
    getToken: Server<SimosAPIResponse<IToken>>;
    getSimosCustomers: Server<SimosAPIResponse<ISimosCustomers[]>>;
    addSimosCustomer: Server<string>;
    updateSimosCustomer: Server<string>;
};

interface ISimosCustomerDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getSimosCustomersRequest: (data: ISimosCustomersRequest) => unknown;
    addSimosCustomerRequest: (data: IAddSimosCustomerRequest) => unknown;
    updateSimosCustomerRequest: (data: IUpdateSimosCustomerRequest) => unknown;
};

interface ISimosCustomerOwnProps {
    addressOnly?: boolean;
    id?: number | undefined;
    onClose?: () => void;
};

interface ISimosCustomerState {
    data: ISimosCustomers;
    errors: ById<IFieldErrorKeyValue>;
    unSavedAddress: boolean,
};

const SimosCustomerStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;

    .dx-toolbar-after {
        display: none;
    };

    .variancepopup{
        height: 50% !important;
    }
`;

type ISimosCustomerProps = RouteComponentProps
    & ISimosCustomerStoreProps
    & ISimosCustomerDispatchProps
    & ISimosCustomerOwnProps;

class AddUpdateSimosCustomer extends PureComponent<ISimosCustomerProps, ISimosCustomerState> {

    public constructor(props: ISimosCustomerProps) {
        super(props);
        this.state = {
            errors: { },
            data: {
                id: 0,
                customer: "",
                active: "Yes",
                on_Hold: "No",
                explorer_Code: "",
                address: [],
                created: "",
                created_By: userName,
                modified: "",
                modified_By: userName,
            },
            unSavedAddress: false
        };
    }

    public async componentDidMount(): Promise<void> {
        await this.getSimosCustomers();
        this.callServer();
    };

    public componentDidUpdate(prevProps: ISimosCustomerProps): void {
        if (this.props !== prevProps) {
            this.callServer();

            if (this.props.addSimosCustomer !== prevProps.addSimosCustomer) {
                if (isSucceeded(this.props.addSimosCustomer)) {
                    this.handleCancel();
                };
            };

            if (this.props.updateSimosCustomer !== prevProps.updateSimosCustomer) {
                if (isSucceeded(this.props.updateSimosCustomer)) {
                    this.handleCancel();
                };
            };
        };
    };


    public render(): ReactNode {

        const { data, errors } = this.state;
        const { updateSimosCustomer, addSimosCustomer, getToken, addressOnly } = this.props;
        const getRole = hasPayload(getToken) && getToken.payload.response.simosAccess.access;
        const readOnly = getRole === ReadOnly;
        const superAdminAccess = hasPayload(getToken) && getToken.payload.response.simosAccess.superAdmin;
        const customerHoldAccess = hasPayload(getToken) && getToken.payload.response.simosAccess.customerHoldAccess;
        const handleOnActiveChange = (event: unknown, value: { id: number, name: string }): void => this.onChange("active", value !== null ? value.name : data.active);
        const handleOnHoldChange = (event: unknown, value: { id: number, name: string }): void => this.onChange("on_Hold", value !== null ? value.name : data.on_Hold);
        const onAddressEdit = (): void => this.handleAddressTableEditMode();

        return (
            <PageSpacing title="SIMOS - Simos Customer" description="Simos Customer" fixedSpaceOnSmallerScreens={true}>
                {getRole !== NotApplicable ?
                    <SimosCustomerStyles>

                        <LAButton startIcon={<ArrowLeftIcon color={WHITE_COLOR} />} label="Back to list" onClick={this.handleCancel} />
                        {addressOnly ? <h2 className="text-center">ADD SIMOS CUSTOMER ADDRESS</h2>
                        : <h2 className="text-center">{data.id > 0 ? "VIEW/UPDATE " : "ADD "} SIMOS CUSTOMER</h2>}
                        <hr />

                        <LAPaperWithPadding>
                            <LAGrid spacing={1}>

                            {!addressOnly && <>
                                <LAGridItem xs={12} sm={6} md={3}>                                   
                                     <LATextField
                                        varient="outlined"
                                        label="Customer Name"
                                        fullWidth={true}
                                        name="customer"
                                        disabled={readOnly}
                                        onChange={this.onChange}
                                        value={data.customer ?? ""}
                                        errorText={errors && errors["customer"] ? errors["customer"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>                                   
                                     <LATextField
                                        varient="outlined"
                                        label="Explorer Code"
                                        fullWidth={true}
                                        disabled={!superAdminAccess || readOnly}
                                        name="explorer_Code"
                                        onChange={this.onChange}
                                        errorText={errors && errors["explorer_Code"] ? errors["explorer_Code"].message : undefined}
                                        value={data.explorer_Code ?? ""}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LAAutoComplete
                                        multiple={false}
                                        option={yesOrNoOptions}
                                        getOptionLabel="name"
                                        autoHighlight={true}
                                        disabled={readOnly}
                                        onChange={handleOnActiveChange}
                                        filterSelectedOptions={true}
                                        dropDownPlaceHolder="Active"
                                        selectionRemove={undefinedFunction}
                                        value={data.active ? yesOrNoOptions.find(q => q.name === data.active) : null}
                                        defaultValue={data.active ? yesOrNoOptions.find(q => q.name === data.active) : null}
                                    />
                                </LAGridItem>

                                {data.id > 0 && <LAGridItem xs={12} sm={6} md={3}>
                                    <LAAutoComplete
                                        multiple={false}
                                        option={yesOrNoOptions}
                                        getOptionLabel="name"
                                        autoHighlight={true}
                                        onChange={handleOnHoldChange}
                                        filterSelectedOptions={true}
                                        disabled={!customerHoldAccess || readOnly}
                                        dropDownPlaceHolder="On Hold"
                                        selectionRemove={undefinedFunction}
                                        value={data.on_Hold ? yesOrNoOptions.find(q => q.name === data.on_Hold) : null}
                                        defaultValue={data.on_Hold ? yesOrNoOptions.find(q => q.name === data.on_Hold) : null}
                                    />
                                </LAGridItem>}

                                </>}

                                <LAGridItem xs={12}>
                                    <LAGreyPaper>
                                        <LAGrid>
                                            <LAGridItem xs={12} className="text-center">
                                                <h2>Customer Address</h2>
                                                <hr />
                                            </LAGridItem>
                                            <LAGridItem xs={12}>
                                                <LADevExtremeGrid
                                                    add={true}
                                                    height={500}
                                                    id="customerAddressTable"
                                                    searchPanel={false}
                                                    filterHeader={true}
                                                    moveBtnToLeft={true}
                                                    data={data.address ? data.address : []}
                                                    // onDelete={onAddressEdit}
                                                    onCustomEdit={(): void => {}}
                                                    onClick={undefinedFunction}
                                                    onEditSave={onAddressEdit}
                                                    onEditStart={onAddressEdit}
                                                    onEditCancel={onAddressEdit}
                                                    onNewRowInsert={onAddressEdit}
                                                    onDelete={onAddressEdit}
                                                    // storageKey="customerAddressTableStorageKey"
                                                    columns={[
                                                        { name: "address", caption: "Address", type: "string", allowEditing: true , requiredField: true},
                                                        { name: "created", caption: "Created", type: "datetime", allowEditing: false},
                                                        { name: "created_By", caption: "Created By", type: "string", allowEditing: false },
                                                        { name: "modified", caption: "Modified", type: "datetime", allowEditing: false, sortDesc:true},
                                                        { name: "modified_By", caption: "Modified By", type: "string", allowEditing: false },
                                                    ]}
                                                />
                                        </LAGridItem> 
                                        </LAGrid>
                                    </LAGreyPaper>
                                </LAGridItem>

                                {!addressOnly && data.id && data.id > 0 ? 
                                <>
                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <LATextField
                                            fullWidth={true}
                                            name="created"
                                            label="Created"
                                            varient="outlined"
                                            disabled={true}
                                            value={data.created ? new Date(data.created).toLocaleString() : ""}
                                            onChange={undefinedFunction}
                                            errorText={errors["created"] ? errors["created"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <LATextField
                                            fullWidth={true}
                                            name="created_By"
                                            label="Created By"
                                            disabled={true}
                                            varient="outlined"
                                            value={data.created_By}
                                            onChange={undefinedFunction}
                                            errorText={errors["created_By"] ? errors["created_By"].message : undefined}
                                        />
                                    </LAGridItem>
                                    

                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <LATextField
                                            fullWidth={true}
                                            name="modified"
                                            label="Last Modified"
                                            varient="outlined"
                                            disabled={true}
                                            value={data.modified ? new Date(data.modified).toLocaleString() : ""}
                                            onChange={undefinedFunction}
                                            errorText={errors["modified"] ? errors["modified"].message : undefined}
                                        />
                                    </LAGridItem>
                                    

                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <LATextField
                                            fullWidth={true}
                                            name="modified_By"
                                            label="Modified By"
                                            varient="outlined"
                                            disabled={true}
                                            value={data.modified_By}
                                            onChange={undefinedFunction}
                                            errorText={errors["modified_By"] ? errors["modified_By"].message : undefined}
                                        />
                                    </LAGridItem>
                                </> : null}

                                <LAGridItem xs={12}>
                                    <LASaveAndCancelButton
                                        onSave={this.onSave}
                                        onCancel={this.handleCancel}
                                        disableSave={(Object.values(errors).length > 0 || readOnly) ? true : undefined}
                                    />
                                </LAGridItem>

                                {customerHoldAccess !== true && data.on_Hold ==="Yes" && <LAGridItem xs={12}>
                                    <LAErrorBox text="Please contact Michelle Scales regarding this hold"></LAErrorBox>
                                </LAGridItem>}
                                
                            </LAGrid>
                        </LAPaperWithPadding>

                        <RequestStatus requestStatus={addSimosCustomer.kind} successMessage="Simos Customer successfully saved" failedMessage={addSimosCustomer.kind === "failed" ? addSimosCustomer.message : "Your Request has failed. Please try again or contact IT.Developers for assistance."} />
                        <RequestStatus requestStatus={updateSimosCustomer.kind} successMessage="Simos Customer  successfully updated" failedMessage={updateSimosCustomer.kind === "failed" ? updateSimosCustomer.message : "Your Request has failed. Please try again or contact IT.Developers for assistance."}/>

                    </SimosCustomerStyles> : 
                    <LAErrorBox text="You do not have permission to view this page. Contact your system admin." />
                }
            </PageSpacing>
        );
    }

    private handleAddressTableEditMode = () => {
        this.setState({ unSavedAddress: !this.state.unSavedAddress });
    }
    
    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, isRequired: boolean): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: isRequired ? true : undefined, minLength: 1, decimal: undefined });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        };
        return err;
    };

    private handleCancel = (): void => {
        const query = queryString.parse(this.props.location.search);
        if(this.props.onClose) {
            this.props.onClose();
        }
        else {
            callRouteWithQueryString({
                route: this.props,
                search: { page: query.page ? query.page.toString() : "" },
                pathName: ROUTE.SIMOS.CUSTOMER.INDEX
            });
        }
    };

    private onSave = async (): Promise<void> => {
        if (!this.state.unSavedAddress) {
            if (hasPayload(this.props.getToken)) {
                const { data } = this.state;
    
                const request: IAddUpdateSimosCustomer = {
                    ID: data.id,
                    Customer: data.customer,
                    Active: data.active,
                    Explorer_Code: data.explorer_Code,
                    On_Hold: data.on_Hold,
                    Address: data.address ? data.address : [],
                    Created_By: userName,
                    Modified_By:  userName,
                };
    
                if (data.id === 0) {
                    this.props.addSimosCustomerRequest({
                        token: this.props.getToken.payload.response.token,
                        request
                    });
                }
                else {
                    this.props.updateSimosCustomerRequest({
                        token: this.props.getToken.payload.response.token,
                        request
                    });
                };
            }
        } else {
            window.confirm("You have some unsaved changes in Address Section")
        }
    };

    private onChange = (name: string, value: string): void => {
        let errors = this.state.errors;

        if (RequiredFields.includes(name))
            errors = this.errorChecker(name, value, errors, true);

        this.setState({
            ...this.state,
            errors,
            data: {
                ...this.state.data,
                [name]: value
            }
        });
    };

    private callServer = (): void => {

        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getSimosCustomers))
            this.props.getSimosCustomersRequest({
                token: this.props.getToken.payload.response.token
            });
        
        const query = queryString.parse(this.props.location.search);
        const id = this.props.id ? this.props.id : query.id ?? "0";

        if ((id !== "0") && (hasPayload(this.props.getSimosCustomers)) && (this.state.data.id === 0)) {
            const data = this.props.getSimosCustomers.payload.response.find((x) => x.id === +id);

            if (data)
                this.setState({ data, errors: {} });

        } else {
            const errors: ById<IFieldErrorKeyValue> = {};

            RequiredFields.forEach((x) => {
                errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
            });

            this.setState({ errors });
        }

    };

    private getSimosCustomers = (): void => {
        if (hasPayload(this.props.getToken)) {
            this.props.getSimosCustomersRequest({
                token: this.props.getToken.payload.response.token
            });
        };
    };

}

const mapStateToProps = (state: IStore): ISimosCustomerStoreProps => ({
    getToken: getTokenStatus(state),
    getSimosCustomers: getSimosCustomers(state),
    addSimosCustomer: addSimosCustomerStatus(state),
    updateSimosCustomer: updateSimosCustomerStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): ISimosCustomerDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getSimosCustomersRequest: (data: ISimosCustomersRequest) => dispatch(getSimosCustomersLoadAction(data)),
    addSimosCustomerRequest: (data: IAddSimosCustomerRequest) => dispatch(addSimosCustomerLoadAction(data)),
    updateSimosCustomerRequest: (data: IUpdateSimosCustomerRequest) => dispatch(updateSimosCustomerLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateSimosCustomer);