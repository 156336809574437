import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IADD_SIEVE_CHECK_REQUEST } from "./addSieveCheckConstaints";
import { IAddSieveCheckLoadAction, IAddSieveCheckLoadFailedAction, IAddSieveCheckSuccessAction } from "./addSieveCheckActions";

type Actions =
    IAddSieveCheckLoadAction
    | IAddSieveCheckLoadFailedAction
    | IAddSieveCheckSuccessAction
    | IFlushDataSuccessAction;

export const AddSieveCheckReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IADD_SIEVE_CHECK_REQUEST.REQUEST:
            return loading;

        case IADD_SIEVE_CHECK_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IADD_SIEVE_CHECK_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};