import { ISieveWashPlant } from "../getSieveWashPlants/getSieveWashPlantsConstaints";

export enum IADD_SIEVE_WASH_PLANT_REQUEST {
    REQUEST = "addSieveWashPlant/ADD_SIEVE_WASH_PLANT_REQUEST",
    SUCCESS = "addSieveWashPlant/ADD_SIEVE_WASH_PLANT_SUCCESS",
    FAILED = "addSieveWashPlant/ADD_SIEVE_WASH_PLANT_FAILED"
};

export interface IAddSieveWashPlantRequest {
    token: string;
    request: ISieveWashPlant;
};