import { IAddWashPlantRunMaterialRequest, IADD_WASH_PLANT_RUN_MATERIAL_REQUEST } from "./addWashPlantRunMaterialConstaints";

export interface IAddWashPlantRunMaterialLoadAction {
    type: IADD_WASH_PLANT_RUN_MATERIAL_REQUEST.REQUEST;
    data: IAddWashPlantRunMaterialRequest
}
export const addWashPlantRunMaterialLoadAction = (data: IAddWashPlantRunMaterialRequest): IAddWashPlantRunMaterialLoadAction => ({
    type: IADD_WASH_PLANT_RUN_MATERIAL_REQUEST.REQUEST,
    data
});

export interface IAddWashPlantRunMaterialSuccessAction {
    type: IADD_WASH_PLANT_RUN_MATERIAL_REQUEST.SUCCESS;
    message: string;
}
export const addWashPlantRunMaterialLoadSuccessAction = (message: string): IAddWashPlantRunMaterialSuccessAction => ({
    type: IADD_WASH_PLANT_RUN_MATERIAL_REQUEST.SUCCESS,
    message
});
export interface IAddWashPlantRunMaterialLoadFailedAction {
    type: IADD_WASH_PLANT_RUN_MATERIAL_REQUEST.FAILED;
    message: string;
}
export const addWashPlantRunMaterialLoadFailedAction = (message: string): IAddWashPlantRunMaterialLoadFailedAction => ({
    type: IADD_WASH_PLANT_RUN_MATERIAL_REQUEST.FAILED,
    message
});
