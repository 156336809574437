import { IFeedMaterials } from "../getFeedMaterials/getFeedMaterialsConstaints";
import { IUpdateFeedMaterialRequest, IUPDATE_FEED_MATERIAL_REQUEST } from "./updateFeedMaterialConstaints";

export interface IUpdateFeedMaterialLoadAction {
    type: IUPDATE_FEED_MATERIAL_REQUEST.REQUEST;
    data: IUpdateFeedMaterialRequest
}
export const updateFeedMaterialLoadAction = (data: IUpdateFeedMaterialRequest): IUpdateFeedMaterialLoadAction => ({
    type: IUPDATE_FEED_MATERIAL_REQUEST.REQUEST,
    data
});

export interface IUpdateFeedMaterialSuccessAction {
    type: IUPDATE_FEED_MATERIAL_REQUEST.SUCCESS;
    data: IFeedMaterials;
}
export const updateFeedMaterialLoadSuccessAction = (data: IFeedMaterials): IUpdateFeedMaterialSuccessAction => ({
    type: IUPDATE_FEED_MATERIAL_REQUEST.SUCCESS,
    data
});
export interface IUpdateFeedMaterialLoadFailedAction {
    type: IUPDATE_FEED_MATERIAL_REQUEST.FAILED;
    message: string;
}
export const updateFeedMaterialLoadFailedAction = (message: string): IUpdateFeedMaterialLoadFailedAction => ({
    type: IUPDATE_FEED_MATERIAL_REQUEST.FAILED,
    message
});
