import { IUpdateCarrierInfractionRequest, IUPDATE_CARRIER_INFRACTION_REQUEST } from "./updateCarrierInfractionConstaints";

export interface IUpdateCarrierInfractionLoadAction {
    type: IUPDATE_CARRIER_INFRACTION_REQUEST.REQUEST;
    data: IUpdateCarrierInfractionRequest
}
export const updateCarrierInfractionLoadAction = (data: IUpdateCarrierInfractionRequest): IUpdateCarrierInfractionLoadAction => ({
    type: IUPDATE_CARRIER_INFRACTION_REQUEST.REQUEST,
    data
});

export interface IUpdateCarrierInfractionSuccessAction {
    type: IUPDATE_CARRIER_INFRACTION_REQUEST.SUCCESS;
    message: string;
}
export const updateCarrierInfractionLoadSuccessAction = (message: string): IUpdateCarrierInfractionSuccessAction => ({
    type: IUPDATE_CARRIER_INFRACTION_REQUEST.SUCCESS,
    message
});

export interface IUpdateCarrierInfractionLoadFailedAction {
    type: IUPDATE_CARRIER_INFRACTION_REQUEST.FAILED;
    message: string;
}
export const updateCarrierInfractionLoadFailedAction = (message: string): IUpdateCarrierInfractionLoadFailedAction => ({
    type: IUPDATE_CARRIER_INFRACTION_REQUEST.FAILED,
    message
});
