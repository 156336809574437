import { IPassTokenWithRequest } from "../../../dryPlant/token/getTokenConstants";


export enum IADD_WAREHOUSE_PRODUCT_REQUEST {
    REQUEST = "addWarehouseProduct/ADD_WAREHOUSE_PRODUCT_REQUEST",
    SUCCESS = "addWarehouseProduct/ADD_WAREHOUSE_PRODUCT_SUCCESS",
    FAILED = "addWarehouseProduct/ADD_WAREHOUSE_PRODUCT_FAILED"
};

export interface IAddWarehouseProductRequest extends IPassTokenWithRequest {
    request: IAddUpdateWarehouseProduct;
};

export interface IAddUpdateWarehouseProduct {
    ID: number;
    Product: string;
    Description: string;
    Unit_Conversion: number;
    Depletes_Bulk_Code_1: string;
    Yield_1: number;
    Depletes_Bulk_Code_2: string;
    Yield_2: number;
    Consumables_Depletion_Product: string;
    Bags: number;
    Bag_Size: string;
    Bag_Depletion: number;
    Active: string;
    Created_By: string;
    Modified_By: string;
}