import { IAddYHDCarrierFleetTruckRequest, IADD_YHD_CARRIER_FLEET_TRUCK_REQUEST } from "./addCarrierFleetTruckConstaints";

export interface IAddYHDCarrierFleetTruckLoadAction {
    type: IADD_YHD_CARRIER_FLEET_TRUCK_REQUEST.REQUEST;
    data: IAddYHDCarrierFleetTruckRequest
}
export const addYHDCarrierFleetTruckLoadAction = (data: IAddYHDCarrierFleetTruckRequest): IAddYHDCarrierFleetTruckLoadAction => ({
    type: IADD_YHD_CARRIER_FLEET_TRUCK_REQUEST.REQUEST,
    data
});

export interface IAddYHDCarrierFleetTruckSuccessAction {
    type: IADD_YHD_CARRIER_FLEET_TRUCK_REQUEST.SUCCESS;
    message: string;
}
export const addYHDCarrierFleetTruckLoadSuccessAction = (message: string): IAddYHDCarrierFleetTruckSuccessAction => ({
    type: IADD_YHD_CARRIER_FLEET_TRUCK_REQUEST.SUCCESS,
    message
});
export interface IAddYHDCarrierFleetTruckLoadFailedAction {
    type: IADD_YHD_CARRIER_FLEET_TRUCK_REQUEST.FAILED;
    message: string;
}
export const addYHDCarrierFleetTruckLoadFailedAction = (message: string): IAddYHDCarrierFleetTruckLoadFailedAction => ({
    type: IADD_YHD_CARRIER_FLEET_TRUCK_REQUEST.FAILED,
    message
});
