import { IWashPlantBinAdjustments } from "../getWashPlantBinAdjustments/getWashPlantBinAdjustmentsConstaints";
import { IUpdateWashPlantBinAdjustmentRequest, IUPDATE_WASH_PLANT_BIN_ADJUSTMENT_REQUEST } from "./updateWashPlantBinAdjustmentConstaints";

export interface IUpdateWashPlantBinAdjustmentLoadAction {
    type: IUPDATE_WASH_PLANT_BIN_ADJUSTMENT_REQUEST.REQUEST;
    data: IUpdateWashPlantBinAdjustmentRequest
}
export const updateWashPlantBinAdjustmentLoadAction = (data: IUpdateWashPlantBinAdjustmentRequest): IUpdateWashPlantBinAdjustmentLoadAction => ({
    type: IUPDATE_WASH_PLANT_BIN_ADJUSTMENT_REQUEST.REQUEST,
    data
});

export interface IUpdateWashPlantBinAdjustmentSuccessAction {
    type: IUPDATE_WASH_PLANT_BIN_ADJUSTMENT_REQUEST.SUCCESS;
    data: IWashPlantBinAdjustments;
}
export const updateWashPlantBinAdjustmentLoadSuccessAction = (data: IWashPlantBinAdjustments): IUpdateWashPlantBinAdjustmentSuccessAction => ({
    type: IUPDATE_WASH_PLANT_BIN_ADJUSTMENT_REQUEST.SUCCESS,
    data
});

export interface IUpdateWashPlantBinAdjustmentLoadFailedAction {
    type: IUPDATE_WASH_PLANT_BIN_ADJUSTMENT_REQUEST.FAILED;
    message: string;
}
export const updateWashPlantBinAdjustmentLoadFailedAction = (message: string): IUpdateWashPlantBinAdjustmentLoadFailedAction => ({
    type: IUPDATE_WASH_PLANT_BIN_ADJUSTMENT_REQUEST.FAILED,
    message
});
