import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { failed, hasPayload, loading, notLoaded, Server, succeeded } from "../../../server";
import { IAddWashPlantBinAdjustmentSuccessAction } from "../addWashPlantBinAdjustment/addWashPlantBinAdjustmentActions";
import { IADD_WASH_PLANT_BIN_ADJUSTMENT_REQUEST } from "../addWashPlantBinAdjustment/addWashPlantBinAdjustmentConstaints";
import { IUpdateWashPlantBinAdjustmentSuccessAction } from "../updateWashPlantBinAdjustment/updateWashPlantBinAdjustmentActions";
import { IUPDATE_WASH_PLANT_BIN_ADJUSTMENT_REQUEST } from "../updateWashPlantBinAdjustment/updateWashPlantBinAdjustmentConstaints";
import { IGET_WASH_PLANT_BIN_ADJUSTMENTS_REQUEST, IWashPlantBinAdjustments } from "./getWashPlantBinAdjustmentsConstaints";
import { IGetWashPlantBinAdjustmentsLoadAction, IGetWashPlantBinAdjustmentsLoadFailedAction, IGetWashPlantBinAdjustmentsSuccessAction } from "./getWashPlantBinAdjustmentsActions";

type Actions =
    IGetWashPlantBinAdjustmentsLoadAction
    | IGetWashPlantBinAdjustmentsLoadFailedAction
    | IGetWashPlantBinAdjustmentsSuccessAction
    | IAddWashPlantBinAdjustmentSuccessAction
    | IUpdateWashPlantBinAdjustmentSuccessAction
    | IFlushDataSuccessAction;

export const GetWashPlantBinAdjustmentsReducer = (state: Server<SilAPIResponse<IWashPlantBinAdjustments[]>> = notLoaded, action: Actions): Server<SilAPIResponse<IWashPlantBinAdjustments[]>> => {
    switch (action.type) {
        case IGET_WASH_PLANT_BIN_ADJUSTMENTS_REQUEST.REQUEST:
            return loading;

        case IGET_WASH_PLANT_BIN_ADJUSTMENTS_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_WASH_PLANT_BIN_ADJUSTMENTS_REQUEST.FAILED:
            return failed(action.message);

        case IUPDATE_WASH_PLANT_BIN_ADJUSTMENT_REQUEST.SUCCESS:

            if (hasPayload(state)) {
                const currentState = [...state.payload.response];
                const ind = currentState.findIndex(x => x.id === action.data.id);

                if (ind >= 0)
                    currentState[ind] = action.data;

                return {
                    ...state,
                    payload: {
                        ...state.payload,
                        response: currentState
                    }
                };
            }
            return notLoaded;

        case IADD_WASH_PLANT_BIN_ADJUSTMENT_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};