import { IUpdateYHDCarrierInfractionRequest, IUPDATE_YHD_CARRIER_INFRACTION_REQUEST } from "./updateCarrierInfractionConstaints";

export interface IUpdateYHDCarrierInfractionLoadAction {
    type: IUPDATE_YHD_CARRIER_INFRACTION_REQUEST.REQUEST;
    data: IUpdateYHDCarrierInfractionRequest
}
export const updateYHDCarrierInfractionLoadAction = (data: IUpdateYHDCarrierInfractionRequest): IUpdateYHDCarrierInfractionLoadAction => ({
    type: IUPDATE_YHD_CARRIER_INFRACTION_REQUEST.REQUEST,
    data
});

export interface IUpdateYHDCarrierInfractionSuccessAction {
    type: IUPDATE_YHD_CARRIER_INFRACTION_REQUEST.SUCCESS;
    message: string;
}
export const updateYHDCarrierInfractionLoadSuccessAction = (message: string): IUpdateYHDCarrierInfractionSuccessAction => ({
    type: IUPDATE_YHD_CARRIER_INFRACTION_REQUEST.SUCCESS,
    message
});
export interface IUpdateYHDCarrierInfractionLoadFailedAction {
    type: IUPDATE_YHD_CARRIER_INFRACTION_REQUEST.FAILED;
    message: string;
}
export const updateYHDCarrierInfractionLoadFailedAction = (message: string): IUpdateYHDCarrierInfractionLoadFailedAction => ({
    type: IUPDATE_YHD_CARRIER_INFRACTION_REQUEST.FAILED,
    message
});
