import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_SIMOS_PRODUCTS_REQUEST, ISimosProducts } from "./getSimosProductsConstants";
import { IGetSimosProductsLoadAction, IGetSimosProductsLoadFailedAction, IGetSimosProductsSuccessAction, getSimosProductsLoadFailedAction, getSimosProductsLoadSuccessAction } from "./getSimosProductsActions";
import { IStore } from "../../../../reducers";
import { EpicDependencies } from "../../../../store";
import { ById, SimosAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { END_POINTS } from "../../../../endpoints";
import { withJsonContentType } from "../../../../epicUtils";

export const getSimosProductsEpic: Epic = (
    action$: ActionsObservable<IGetSimosProductsLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetSimosProductsSuccessAction | IGetSimosProductsLoadFailedAction> =>
    action$.ofType(IGET_SIMOS_PRODUCTS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SimosAPIResponse<ISimosProducts[]>>(
                    END_POINTS.PROPSENSE.SIMOS.GET_SIMOS_PRODUCTS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SimosAPIResponse<ISimosProducts[]>): IGetSimosProductsSuccessAction | IGetSimosProductsLoadFailedAction => {
                            if(response.message === "Success"){
                                return getSimosProductsLoadSuccessAction(response);
                            }
                            return getSimosProductsLoadFailedAction(response.message);
                        }),
                        catchError((response: SimosAPIResponse<string>) => ActionsObservable.of(getSimosProductsLoadFailedAction(response.message)))
                    )
            )
        );