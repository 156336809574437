import { IPassTokenWithRequest } from "../../../../sil/dryPlant/token/getTokenConstants";

export enum IUPDATE_YHD_CARRIER_INFRACTION_REQUEST {
    REQUEST = "updateYHDCarrierInfraction/UPDATE_YHD_CARRIER_INFRACTION_REQUEST",
    SUCCESS = "updateYHDCarrierInfraction/UPDATE_YHD_CARRIER_INFRACTION_SUCCESS",
    FAILED = "updateYHDCarrierInfraction/UPDATE_YHD_CARRIER_INFRACTION_FAILED"
};

export interface IUpdateCarrierInfraction {
    ID: number;
    Date_Of_Infraction: string;
    Infraction_Notification_Method: string;
    Alternate_Name: string;
    Trailer_No: string;
    License_Plate?: string;
    Location: string;
    Ticket_No?: string;
    Infraction: string;
    Carrier_Notification_Provided_To?: string;
    Description: string;
    Immediate_Action_Taken: string;
    Trailer_Inspection_File: string;
    Followup_Action_Taken?: string;
    Attachment_File_Name?: string;
    Attachment_File?: string;
    Attachment_Details?: string;
    Reported_By_Name?: string;
    Contact_No?: string;
    Complaint_Recorded_By?: string;
    Status: string;
    Modified: string;
    Modified_By: string;
    Created: string;
    Created_By: string;
};

export interface IUpdateYHDCarrierInfractionRequest extends IPassTokenWithRequest {
    request: IUpdateCarrierInfraction;
};