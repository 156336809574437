import { SilAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../../flushData.tsx/flushDataConstants";
import { Server, failed, loading, notLoaded, succeeded } from "../../../../server";
import { IAddSieveWashPlantSuccessAction } from "../addSieveWashPlant/addSieveWashPlantActions";
import { IADD_SIEVE_WASH_PLANT_REQUEST } from "../addSieveWashPlant/addSieveWashPlantConstaints";
import { IUpdateSieveWashPlantSuccessAction } from "../updateSieveWashPlant/updateSieveWashPlantActions";
import { IUPDATE_SIEVE_WASH_PLANT_REQUEST } from "../updateSieveWashPlant/updateSieveWashPlantConstaints";
import { IGetSieveWashPlantLoadAction, IGetSieveWashPlantLoadFailedAction, IGetSieveWashPlantSuccessAction } from "./getSieveWashPlantsActions";
import { ISieveWashPlant, IGET_SIEVE_WASH_PLANTS_REQUEST } from "./getSieveWashPlantsConstaints";

type Actions =
    IGetSieveWashPlantLoadAction
    | IGetSieveWashPlantLoadFailedAction
    | IGetSieveWashPlantSuccessAction
    | IAddSieveWashPlantSuccessAction
    | IUpdateSieveWashPlantSuccessAction
    | IFlushDataSuccessAction;

export const GetSieveWashPlantReducer = (state: Server<SilAPIResponse<ISieveWashPlant[]>> = notLoaded, action: Actions): Server<SilAPIResponse<ISieveWashPlant[]>> => {
    switch (action.type) {
        case IGET_SIEVE_WASH_PLANTS_REQUEST.REQUEST:
            return loading;

        case IGET_SIEVE_WASH_PLANTS_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_SIEVE_WASH_PLANTS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_SIEVE_WASH_PLANT_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_SIEVE_WASH_PLANT_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};