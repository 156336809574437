import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IUpdateTrailerActivityLogLoadAction, IUpdateTrailerActivityLogLoadFailedAction, IUpdateTrailerActivityLogSuccessAction, 
    updateTrailerActivityLogLoadFailedAction, updateTrailerActivityLogLoadSuccessAction
} from "./updateTrailerActivityLogActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IUPDATE_TRAILER_ACTIVITY_LOG_REQUEST } from "./updateTrailerActivityLogConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";

export const updateTrailerActivityLogEpic: Epic = (
    action$: ActionsObservable<IUpdateTrailerActivityLogLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IUpdateTrailerActivityLogSuccessAction | IUpdateTrailerActivityLogLoadFailedAction> =>
    action$.ofType(IUPDATE_TRAILER_ACTIVITY_LOG_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<string>>(
                    END_POINTS.PROPSENSE.SIL.DISPATCH.UPDATE_TRAILER_ACTIVITY_LOG,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<string>): IUpdateTrailerActivityLogSuccessAction => {
                            return updateTrailerActivityLogLoadSuccessAction(response.message);
                        }),
                        catchError(() => ActionsObservable.of(updateTrailerActivityLogLoadFailedAction("Unable to update trailer activity log")))
                    )
            )
        );