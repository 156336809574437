import { SilAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../../flushData.tsx/flushDataConstants";
import { Server, failed, loading, notLoaded, succeeded } from "../../../../server";
import { IAddNCRSuccessAction } from "../addNCR/addNCRActions";
import { IADD_NCR_REQUEST } from "../addNCR/addNCRConstaints";
import { IUpdateNCRSuccessAction } from "../updateNCR/updateNCRActions";
import { IUPDATE_NCR_REQUEST } from "../updateNCR/updateNCRConstaints";
import { IGetNCRLoadAction, IGetNCRLoadFailedAction, IGetNCRSuccessAction } from "./getNCRsActions";
import { INCR, IGET_NCRS_REQUEST } from "./getNCRsConstaints";

type Actions =
    IGetNCRLoadAction
    | IGetNCRLoadFailedAction
    | IGetNCRSuccessAction
    | IAddNCRSuccessAction
    | IUpdateNCRSuccessAction
    | IFlushDataSuccessAction;

export const GetNCRReducer = (state: Server<SilAPIResponse<INCR[]>> = notLoaded, action: Actions): Server<SilAPIResponse<INCR[]>> => {
    switch (action.type) {
        case IGET_NCRS_REQUEST.REQUEST:
            return loading;

        case IGET_NCRS_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_NCRS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_NCR_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_NCR_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};