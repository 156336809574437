import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IUpdateWashPlantRunMaterialLoadAction, IUpdateWashPlantRunMaterialLoadFailedAction, IUpdateWashPlantRunMaterialSuccessAction, updateWashPlantRunMaterialLoadFailedAction,
     updateWashPlantRunMaterialLoadSuccessAction } from "./updateWashPlantRunMaterialActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IUPDATE_WASH_PLANT_RUN_MATERIAL_REQUEST } from "./updateWashPlantRunMaterialConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";

export const updateWashPlantRunMaterialEpic: Epic = (
    action$: ActionsObservable<IUpdateWashPlantRunMaterialLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IUpdateWashPlantRunMaterialSuccessAction | IUpdateWashPlantRunMaterialLoadFailedAction> =>
    action$.ofType(IUPDATE_WASH_PLANT_RUN_MATERIAL_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<string>>(
                    END_POINTS.PROPSENSE.SIL.WASH_PLANT.UPDATE_WASH_PLANT_RUN_MATERIALS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<string>): IUpdateWashPlantRunMaterialSuccessAction | IUpdateWashPlantRunMaterialLoadFailedAction => {
                            
                            if(response.message === "Success"){
                                return updateWashPlantRunMaterialLoadSuccessAction(action.data.request);
                            }
                            return updateWashPlantRunMaterialLoadFailedAction(response.message);
                        }),
                        catchError(() => ActionsObservable.of(updateWashPlantRunMaterialLoadFailedAction("Unable to save your changes")))
                    )
            )
        );