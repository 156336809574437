import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IUpdateEmployeeLoadAction, IUpdateEmployeeLoadFailedAction, IUpdateEmployeeSuccessAction } from "./updateEmployeeActions";
import { IUPDATE_EMPLOYEE_REQUEST } from "./updateEmployeeConstaints";


type Actions =
    IUpdateEmployeeLoadAction
    | IUpdateEmployeeLoadFailedAction
    | IUpdateEmployeeSuccessAction
    | IFlushDataSuccessAction;

export const UpdateEmployeeReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IUPDATE_EMPLOYEE_REQUEST.REQUEST:
            return loading;

        case IUPDATE_EMPLOYEE_REQUEST.SUCCESS:
            return succeeded("Success");

        case IUPDATE_EMPLOYEE_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};