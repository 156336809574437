import { IPassTokenWithRequest } from "../../../../sil/dryPlant/token/getTokenConstants";

export enum IGET_YHD_CARRIER_FLEET_TRUCK_REQUEST {
    REQUEST = "getYHDCarrierFleetTruck/GET_YHD_CARRIER_FLEET_TRUCK_REQUEST",
    SUCCESS = "getYHDCarrierFleetTruck/GET_YHD_CARRIER_FLEET_TRUCK_SUCCESS",
    FAILED = "getYHDCarrierFleetTruck/GET_YHD_CARRIER_FLEET_TRUCK_FAILED"
};

export interface IGetYHDCarrierFleetTruckRequest extends IPassTokenWithRequest {

};

export interface IYHDCarrierFleetTruck {
    id: number;
    asgA_No: string;
    truck_Type?: string;
    truck_Registration_No?: string;
    truck_Registration_Expiry?: string;
    truck_Registration_File_Name?: string;
    trailer_Inspection_File_Name?: string;
    truck_Inspection_Date?: string;
    fleet_Trucks?: string;
    truck_Inspection_File_Name?: string;
    trailer_Inspection_Date?: string;
    created: string;
    created_By: string;
    modified: string;
    modified_By: string;
    truck_Registration_File?: string | undefined;
    trailer_Inspection_File?: string | undefined;
    truck_Inspection_File?: string | undefined;
    active: string;
};