import { IBinAdjustments } from "../getBinAdjustments/getBinAdjustmentsConstaints";
import { IUpdateBinAdjustmentRequest, IUPDATE_BIN_ADJUSTMENT_REQUEST } from "./updateBinAdjustmentConstaints";

export interface IUpdateBinAdjustmentLoadAction {
    type: IUPDATE_BIN_ADJUSTMENT_REQUEST.REQUEST;
    data: IUpdateBinAdjustmentRequest
}
export const updateBinAdjustmentLoadAction = (data: IUpdateBinAdjustmentRequest): IUpdateBinAdjustmentLoadAction => ({
    type: IUPDATE_BIN_ADJUSTMENT_REQUEST.REQUEST,
    data
});

export interface IUpdateBinAdjustmentSuccessAction {
    type: IUPDATE_BIN_ADJUSTMENT_REQUEST.SUCCESS;
    data: IBinAdjustments;
}
export const updateBinAdjustmentLoadSuccessAction = (data: IBinAdjustments): IUpdateBinAdjustmentSuccessAction => ({
    type: IUPDATE_BIN_ADJUSTMENT_REQUEST.SUCCESS,
    data
});

export interface IUpdateBinAdjustmentLoadFailedAction {
    type: IUPDATE_BIN_ADJUSTMENT_REQUEST.FAILED;
    message: string;
}
export const updateBinAdjustmentLoadFailedAction = (message: string): IUpdateBinAdjustmentLoadFailedAction => ({
    type: IUPDATE_BIN_ADJUSTMENT_REQUEST.FAILED,
    message
});
