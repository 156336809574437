import { SilAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../../server";
import { IUpdateCarrierAuditSuccessAction } from "../updateAudit/updateAuditActions";
import { IUPDATE_CARRIER_AUDIT_REQUEST } from "../updateAudit/updateAuditConstants";
import { IGetCarrierAuditLoadAction, IGetCarrierAuditLoadFailedAction, IGetCarrierAuditSuccessAction } from "./getCarrierAuditActions";
import { IGetCarrierAudit, IGET_CARRIER_AUDIT_REQUEST } from "./getCarrierAuditConstants";

type Actions =
    | IGetCarrierAuditLoadAction
    | IGetCarrierAuditSuccessAction
    | IGetCarrierAuditLoadFailedAction
    | IUpdateCarrierAuditSuccessAction
    | IFlushDataSuccessAction;

export const GetCarrierAuditReducer = (state: Server<SilAPIResponse<IGetCarrierAudit[]>> = notLoaded, action: Actions): Server<SilAPIResponse<IGetCarrierAudit[]>> => {
    switch (action.type) {
        case IGET_CARRIER_AUDIT_REQUEST.REQUEST:
            return loading;

        case IGET_CARRIER_AUDIT_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_CARRIER_AUDIT_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IUPDATE_CARRIER_AUDIT_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};