import { IAddCarrierInfractionRequest, IADD_CARRIER_INFRACTION_REQUEST } from "./addCarrierInfractionConstaints";

export interface IAddCarrierInfractionLoadAction {
    type: IADD_CARRIER_INFRACTION_REQUEST.REQUEST;
    data: IAddCarrierInfractionRequest
}
export const addCarrierInfractionLoadAction = (data: IAddCarrierInfractionRequest): IAddCarrierInfractionLoadAction => ({
    type: IADD_CARRIER_INFRACTION_REQUEST.REQUEST,
    data
});

export interface IAddCarrierInfractionSuccessAction {
    type: IADD_CARRIER_INFRACTION_REQUEST.SUCCESS;
    message: string;
}
export const addCarrierInfractionLoadSuccessAction = (message: string): IAddCarrierInfractionSuccessAction => ({
    type: IADD_CARRIER_INFRACTION_REQUEST.SUCCESS,
    message
});

export interface IAddCarrierInfractionLoadFailedAction {
    type: IADD_CARRIER_INFRACTION_REQUEST.FAILED;
    message: string;
}
export const addCarrierInfractionLoadFailedAction = (message: string): IAddCarrierInfractionLoadFailedAction => ({
    type: IADD_CARRIER_INFRACTION_REQUEST.FAILED,
    message
});
