import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IGetPSLookupsLoadAction, IGetPSLookupsLoadFailedAction, IGetPSLookupsSuccessAction, 
    getPSLookupsLoadFailedAction, getPSLookupsLoadSuccessAction
} from "./getPSLookupsActions";
import { IStore } from "../../../../reducers";
import { EpicDependencies } from "../../../../store";
import { IGET_PS_LOOKUPS_REQUEST, IPSLookup } from "./getPSLookupsConstaints";
import { END_POINTS } from "../../../../endpoints";
import { withJsonContentType } from "../../../../epicUtils";
import { SilAPIResponse } from "../../../../../react/shared/publicInterfaces";

export const getPSLookupsEpic: Epic = (
    action$: ActionsObservable<IGetPSLookupsLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IGetPSLookupsSuccessAction | IGetPSLookupsLoadFailedAction> =>
    action$.ofType(IGET_PS_LOOKUPS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<IPSLookup>>(
                    END_POINTS.PROPSENSE.SIL.WAREHOUSE.GET_PS_LOOKUPS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<IPSLookup>): IGetPSLookupsSuccessAction => {
                            return getPSLookupsLoadSuccessAction(response);
                        }),
                        catchError(() => ActionsObservable.of(getPSLookupsLoadFailedAction("Unable to get ps lookups")))
                    )
            )
        );