import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetSieveLookupsRequest, IGET_SIEVE_LOOKUPS_REQUEST, ISieveLookup } from "./getSieveLookupsConstaints";

export interface IGetSieveLookupsLoadAction {
    type: IGET_SIEVE_LOOKUPS_REQUEST.REQUEST;
    data: IGetSieveLookupsRequest
}
export const getSieveLookupsLoadAction = (data: IGetSieveLookupsRequest): IGetSieveLookupsLoadAction => ({
    type: IGET_SIEVE_LOOKUPS_REQUEST.REQUEST,
    data
});

export interface IGetSieveLookupsSuccessAction {
    type: IGET_SIEVE_LOOKUPS_REQUEST.SUCCESS;
    data: SilAPIResponse<ISieveLookup>;
}
export const getSieveLookupsLoadSuccessAction = (data: SilAPIResponse<ISieveLookup>): IGetSieveLookupsSuccessAction => ({
    type: IGET_SIEVE_LOOKUPS_REQUEST.SUCCESS,
    data
});

export interface IGetSieveLookupsLoadFailedAction {
    type: IGET_SIEVE_LOOKUPS_REQUEST.FAILED;
    message: string;
}
export const getSieveLookupsLoadFailedAction = (message: string): IGetSieveLookupsLoadFailedAction => ({
    type: IGET_SIEVE_LOOKUPS_REQUEST.FAILED,
    message
});
