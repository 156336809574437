import { IUpdateTrailerActivityLogRequest, IUPDATE_TRAILER_ACTIVITY_LOG_REQUEST } from "./updateTrailerActivityLogConstaints";

export interface IUpdateTrailerActivityLogLoadAction {
    type: IUPDATE_TRAILER_ACTIVITY_LOG_REQUEST.REQUEST;
    data: IUpdateTrailerActivityLogRequest
}
export const updateTrailerActivityLogLoadAction = (data: IUpdateTrailerActivityLogRequest): IUpdateTrailerActivityLogLoadAction => ({
    type: IUPDATE_TRAILER_ACTIVITY_LOG_REQUEST.REQUEST,
    data
});

export interface IUpdateTrailerActivityLogSuccessAction {
    type: IUPDATE_TRAILER_ACTIVITY_LOG_REQUEST.SUCCESS;
    message: string;
}
export const updateTrailerActivityLogLoadSuccessAction = (message: string): IUpdateTrailerActivityLogSuccessAction => ({
    type: IUPDATE_TRAILER_ACTIVITY_LOG_REQUEST.SUCCESS,
    message
});

export interface IUpdateTrailerActivityLogLoadFailedAction {
    type: IUPDATE_TRAILER_ACTIVITY_LOG_REQUEST.FAILED;
    message: string;
}
export const updateTrailerActivityLogLoadFailedAction = (message: string): IUpdateTrailerActivityLogLoadFailedAction => ({
    type: IUPDATE_TRAILER_ACTIVITY_LOG_REQUEST.FAILED,
    message
});
