import { IUpdateTransloadBOLCountRequest, IUPDATE_TRANSLOAD_BOL_COUNT_REQUEST } from "./updateTransloadBOLCountConstaints";

export interface IUpdateTransloadBOLCountLoadAction {
    type: IUPDATE_TRANSLOAD_BOL_COUNT_REQUEST.REQUEST;
    data: IUpdateTransloadBOLCountRequest
}
export const updateTransloadBOLCountLoadAction = (data: IUpdateTransloadBOLCountRequest): IUpdateTransloadBOLCountLoadAction => ({
    type: IUPDATE_TRANSLOAD_BOL_COUNT_REQUEST.REQUEST,
    data
});

export interface IUpdateTransloadBOLCountSuccessAction {
    type: IUPDATE_TRANSLOAD_BOL_COUNT_REQUEST.SUCCESS;
    message: string;
}
export const updateTransloadBOLCountLoadSuccessAction = (message: string): IUpdateTransloadBOLCountSuccessAction => ({
    type: IUPDATE_TRANSLOAD_BOL_COUNT_REQUEST.SUCCESS,
    message
});

export interface IUpdateTransloadBOLCountLoadFailedAction {
    type: IUPDATE_TRANSLOAD_BOL_COUNT_REQUEST.FAILED;
    message: string;
}
export const updateTransloadBOLCountLoadFailedAction = (message: string): IUpdateTransloadBOLCountLoadFailedAction => ({
    type: IUPDATE_TRANSLOAD_BOL_COUNT_REQUEST.FAILED,
    message
});
