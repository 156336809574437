import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IGetTruckScaleLoadAction, IGetTruckScaleLoadFailedAction, IGetTruckScaleSuccessAction, 
    getTruckScaleLoadFailedAction, getTruckScaleLoadSuccessAction
} from "./getTruckScalesActions";
import { IGET_TRUCK_SCALES_REQUEST, ITruckScale } from "./getTruckScalesConstaints";
import { IStore } from "../../../../reducers";
import { EpicDependencies } from "../../../../store";
import { SilAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { END_POINTS } from "../../../../endpoints";
import { withJsonContentType } from "../../../../epicUtils";

export const getTruckScaleEpic: Epic = (
    action$: ActionsObservable<IGetTruckScaleLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IGetTruckScaleSuccessAction | IGetTruckScaleLoadFailedAction> =>
    action$.ofType(IGET_TRUCK_SCALES_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<ITruckScale[]>>(
                    END_POINTS.PROPSENSE.SIL.CV.GetCVTruckScales,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<ITruckScale[]>): IGetTruckScaleSuccessAction => {
                            return getTruckScaleLoadSuccessAction(response);
                        }),
                        catchError(() => ActionsObservable.of(getTruckScaleLoadFailedAction("Unable to get truck scales")))
                    )
            )
        );