import { IUpdateSieveCheckRequest, IUPDATE_SIEVE_CHECK_REQUEST } from "./updateSieveCheckConstaints";

export interface IUpdateSieveCheckLoadAction {
    type: IUPDATE_SIEVE_CHECK_REQUEST.REQUEST;
    data: IUpdateSieveCheckRequest
}
export const updateSieveCheckLoadAction = (data: IUpdateSieveCheckRequest): IUpdateSieveCheckLoadAction => ({
    type: IUPDATE_SIEVE_CHECK_REQUEST.REQUEST,
    data
});

export interface IUpdateSieveCheckSuccessAction {
    type: IUPDATE_SIEVE_CHECK_REQUEST.SUCCESS;
    message: string;
}
export const updateSieveCheckLoadSuccessAction = (message: string): IUpdateSieveCheckSuccessAction => ({
    type: IUPDATE_SIEVE_CHECK_REQUEST.SUCCESS,
    message
});

export interface IUpdateSieveCheckLoadFailedAction {
    type: IUPDATE_SIEVE_CHECK_REQUEST.FAILED;
    message: string;
}
export const updateSieveCheckLoadFailedAction = (message: string): IUpdateSieveCheckLoadFailedAction => ({
    type: IUPDATE_SIEVE_CHECK_REQUEST.FAILED,
    message
});
