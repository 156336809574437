import { IAddBaggedInventoryRequest } from "../addBaggedInventory/addBaggedInventoryConstaints";

export enum IUPDATE_BAGGED_INVENTORY_REQUEST {
    REQUEST = "updateBaggedInventory/UPDATE_BAGGED_INVENTORY_REQUEST",
    SUCCESS = "updateBaggedInventory/UPDATE_BAGGED_INVENTORY_SUCCESS",
    FAILED = "updateBaggedInventory/UPDATE_BAGGED_INVENTORY_FAILED"
};

export interface IUpdateBaggedInventoryRequest extends IAddBaggedInventoryRequest {
};
