import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IQualityControlTestings, IGET_QUALITY_CONTROL_TESTINGS_REQUEST, IGetQualityControlTestingsRequest } from "./getQualityControlTestingsConstaints";

export interface IGetQualityControlTestingsLoadAction {
    type: IGET_QUALITY_CONTROL_TESTINGS_REQUEST.REQUEST;
    data: IGetQualityControlTestingsRequest
}
export const getQualityControlTestingsLoadAction = (data: IGetQualityControlTestingsRequest): IGetQualityControlTestingsLoadAction => ({
    type: IGET_QUALITY_CONTROL_TESTINGS_REQUEST.REQUEST,
    data
});

export interface IGetQualityControlTestingsSuccessAction {
    type: IGET_QUALITY_CONTROL_TESTINGS_REQUEST.SUCCESS;
    data: SilAPIResponse<IQualityControlTestings[]>;
}
export const getQualityControlTestingsLoadSuccessAction = (data: SilAPIResponse<IQualityControlTestings[]>): IGetQualityControlTestingsSuccessAction => ({
    type: IGET_QUALITY_CONTROL_TESTINGS_REQUEST.SUCCESS,
    data
});

export interface IGetQualityControlTestingsLoadFailedAction {
    type: IGET_QUALITY_CONTROL_TESTINGS_REQUEST.FAILED;
    message: string;
}
export const getQualityControlTestingsLoadFailedAction = (message: string): IGetQualityControlTestingsLoadFailedAction => ({
    type: IGET_QUALITY_CONTROL_TESTINGS_REQUEST.FAILED,
    message
});
