import { IPassTokenRequestBody } from "../../../../../react/shared/publicInterfaces";

export enum IGET_SIMOS_PRODUCTS_REQUEST {
    REQUEST = "getSimosProducts/GET_SIMOS_PRODUCTS_REQUEST",
    SUCCESS = "getSimosProducts/GET_SIMOS_PRODUCTS_SUCCESS",
    FAILED = "getSimosProducts/GET_SIMOS_PRODUCTS_FAILED"
};

export interface ISimosProductsRequest extends IPassTokenRequestBody {

};

export interface ISimosProducts {
    id: number;
    code: string;
    description: string;
    location: string;
    size: string;
    created: string;
    created_By: string;
    modified: string;
    modified_By: string;   
};
