import { IPassTokenRequestBody } from "../../../../../react/shared/publicInterfaces";
import { IAddUpdateSimosCustomer } from "../addSimosCustomer/addSimosCustomerConstaints";

export enum IUPDATE_SIMOS_CUSTOMER_REQUEST {
    REQUEST = "updateSimosCustomer/UPDATE_SIMOS_CUSTOMER_REQUEST",
    SUCCESS = "updateSimosCustomer/UPDATE_SIMOS_CUSTOMER_SUCCESS",
    FAILED = "updateSimosCustomer/UPDATE_SIMOS_CUSTOMER_FAILED"
};

export interface IUpdateSimosCustomerRequest extends IPassTokenRequestBody {
    request: IAddUpdateSimosCustomer;
};
