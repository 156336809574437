import { IGET_SIMOS_CUSTOMERS_REQUEST, ISimosCustomers } from "./getSimosCustomersConstants";
import { IGetSimosCustomersLoadAction, IGetSimosCustomersLoadFailedAction, IGetSimosCustomersSuccessAction } from "./getSimosCustomersActions";
import { SimosAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../../server";
import { IFlushDataSuccessAction } from '../../../../flushData.tsx/flushDataActions';
import { FLUSH_DATA } from '../../../../flushData.tsx/flushDataConstants';
import { IAddSimosCustomerSuccessAction } from "../addSimosCustomer/addSimosCustomerActions";
import { IUpdateSimosCustomerSuccessAction } from "../updateSimosCustomer/updateSimosCustomerActions";
import { IADD_SIMOS_CUSTOMER_REQUEST } from "../addSimosCustomer/addSimosCustomerConstaints";
import { IUPDATE_SIMOS_CUSTOMER_REQUEST } from "../updateSimosCustomer/updateSimosCustomerConstaints";

type Actions =
    | IGetSimosCustomersLoadAction
    | IGetSimosCustomersSuccessAction
    | IGetSimosCustomersLoadFailedAction
    | IAddSimosCustomerSuccessAction
    | IUpdateSimosCustomerSuccessAction
    | IFlushDataSuccessAction;

export const GetSimosCustomersReducer = (state: Server<SimosAPIResponse<ISimosCustomers[]>> = notLoaded, action: Actions): Server<SimosAPIResponse<ISimosCustomers[]>> => {
    switch (action.type) {
        case IGET_SIMOS_CUSTOMERS_REQUEST.REQUEST:
            return loading;

        case IGET_SIMOS_CUSTOMERS_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_SIMOS_CUSTOMERS_REQUEST.FAILED:
            return failed(action.message);

        case IADD_SIMOS_CUSTOMER_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_SIMOS_CUSTOMER_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};