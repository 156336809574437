import { SilAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { IGetBeltScaleRequest, IGET_BELT_SCALES_REQUEST, IBeltScale } from "./getBeltScalesConstaints";

export interface IGetBeltScaleLoadAction {
    type: IGET_BELT_SCALES_REQUEST.REQUEST;
    data: IGetBeltScaleRequest
}
export const getBeltScaleLoadAction = (data: IGetBeltScaleRequest): IGetBeltScaleLoadAction => ({
    type: IGET_BELT_SCALES_REQUEST.REQUEST,
    data
});

export interface IGetBeltScaleSuccessAction {
    type: IGET_BELT_SCALES_REQUEST.SUCCESS;
    data: SilAPIResponse<IBeltScale[]>;
}
export const getBeltScaleLoadSuccessAction = (data: SilAPIResponse<IBeltScale[]>): IGetBeltScaleSuccessAction => ({
    type: IGET_BELT_SCALES_REQUEST.SUCCESS,
    data
});

export interface IGetBeltScaleLoadFailedAction {
    type: IGET_BELT_SCALES_REQUEST.FAILED;
    message: string;
}
export const getBeltScaleLoadFailedAction = (message: string): IGetBeltScaleLoadFailedAction => ({
    type: IGET_BELT_SCALES_REQUEST.FAILED,
    message
});
