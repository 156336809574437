import { IPassTokenWithRequest } from "../token/getTokenConstants";

export enum IGET_FEED_MATERIALS_REQUEST {
    REQUEST = "getFeedMaterials/GET_FEED_MATERIALS_REQUEST",
    SUCCESS = "getFeedMaterials/GET_FEED_MATERIALS_SUCCESS",
    FAILED = "getFeedMaterials/GET_FEED_MATERIALS_FAILED"
};

export interface IGetFeedMaterialsRequest extends IPassTokenWithRequest {

};

export interface IFeedMaterials {
    id: number;
    feed_Material: string;
    product_Code: string;
    active: string;
    ball_Mill: string;
    created: string;
    modified: string;
    created_By: string;
    modified_By: string;
};