import { SilAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { IGetSieveWashPlantRequest, IGET_SIEVE_WASH_PLANTS_REQUEST, ISieveWashPlant } from "./getSieveWashPlantsConstaints";

export interface IGetSieveWashPlantLoadAction {
    type: IGET_SIEVE_WASH_PLANTS_REQUEST.REQUEST;
    data: IGetSieveWashPlantRequest
}
export const getSieveWashPlantLoadAction = (data: IGetSieveWashPlantRequest): IGetSieveWashPlantLoadAction => ({
    type: IGET_SIEVE_WASH_PLANTS_REQUEST.REQUEST,
    data
});

export interface IGetSieveWashPlantSuccessAction {
    type: IGET_SIEVE_WASH_PLANTS_REQUEST.SUCCESS;
    data: SilAPIResponse<ISieveWashPlant[]>;
}
export const getSieveWashPlantLoadSuccessAction = (data: SilAPIResponse<ISieveWashPlant[]>): IGetSieveWashPlantSuccessAction => ({
    type: IGET_SIEVE_WASH_PLANTS_REQUEST.SUCCESS,
    data
});

export interface IGetSieveWashPlantLoadFailedAction {
    type: IGET_SIEVE_WASH_PLANTS_REQUEST.FAILED;
    message: string;
}
export const getSieveWashPlantLoadFailedAction = (message: string): IGetSieveWashPlantLoadFailedAction => ({
    type: IGET_SIEVE_WASH_PLANTS_REQUEST.FAILED,
    message
});
