import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IComplaintLogging, IGET_COMPLAINT_LOGGING_REQUEST } from "./getComplaintLoggingConstaints";
import { IGetComplaintLoggingLoadAction, IGetComplaintLoggingLoadFailedAction, IGetComplaintLoggingSuccessAction } from "./getComplaintLoggingActions";
import { IADD_COMPLAINT_LOGGING_REQUEST } from "../addComplaintLogging/addComplaintLoggingConstaints";
import { IUPDATE_COMPLAINT_LOGGING_REQUEST } from "../updateComplaintLogging/updateComplaintLoggingConstaints";
import { IAddComplaintLoggingSuccessAction } from "../addComplaintLogging/addComplaintLoggingActions";
import { IUpdateComplaintLoggingSuccessAction } from "../updateComplaintLogging/updateComplaintLoggingActions";

type Actions =
    IGetComplaintLoggingLoadAction
    | IGetComplaintLoggingLoadFailedAction
    | IGetComplaintLoggingSuccessAction
    | IAddComplaintLoggingSuccessAction
    | IUpdateComplaintLoggingSuccessAction
    | IFlushDataSuccessAction;

export const GetComplaintLoggingReducer = (state: Server<SilAPIResponse<IComplaintLogging[]>> = notLoaded, action: Actions): Server<SilAPIResponse<IComplaintLogging[]>> => {
    switch (action.type) {
        case IGET_COMPLAINT_LOGGING_REQUEST.REQUEST:
            return loading;

        case IGET_COMPLAINT_LOGGING_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_COMPLAINT_LOGGING_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_COMPLAINT_LOGGING_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_COMPLAINT_LOGGING_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};