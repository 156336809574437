import { IPassTokenRequestBody } from "../../../../../react/shared/publicInterfaces";
import { IAddUpdateSimosProduct } from "../addSimosProduct/addSimosProductConstaints";

export enum IUPDATE_SIMOS_PRODUCT_REQUEST {
    REQUEST = "updateSimosProduct/UPDATE_SIMOS_PRODUCT_REQUEST",
    SUCCESS = "updateSimosProduct/UPDATE_SIMOS_PRODUCT_SUCCESS",
    FAILED = "updateSimosProduct/UPDATE_SIMOS_PRODUCT_FAILED"
};

export interface IUpdateSimosProductRequest extends IPassTokenRequestBody {
    request: IAddUpdateSimosProduct;
};
