import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, isSucceeded, Server } from "../../../../redux/server";
import { ById, SilAPIResponse } from "../../../shared/publicInterfaces";
import { userName, undefinedFunction, monthNames, getYearsList } from "../../../shared/constExports";
import { LAButton, LASaveAndCancelButton } from "../../../shared/buttons";
import { ArrowLeftIcon } from "../../../shared/icons";
import { WHITE_COLOR } from "../../../shared/theme";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import LATextField from "../../../shared/textField";
import LAAutoComplete from "../../../shared/autoComplete";
import { ROUTE } from "../../../routes";
import queryString from "query-string";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import { FieldValidator, FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../../shared/fieldValidator";
import PageSpacing from "../../../shared/pageSpacing";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import SilRoleCheck from "../../../shared/silRoleCheck";
import { IIdName } from "../../../../utils/sharedTypes";
import { IGetWarehouseRequest, IWarehouse } from "../../../../redux/sil/cv/warehouses/getWarehouses/getWarehousesConstaints";
import { IAddWarehouseRequest } from "../../../../redux/sil/cv/warehouses/addWarehouse/addWarehouseConstaints";
import { IUpdateWarehouseRequest } from "../../../../redux/sil/cv/warehouses/updateWarehouse/updateWarehouseConstaints";
import { getWarehouseStatus } from "../../../../redux/sil/cv/warehouses/getWarehouses/getWarehousesAccessor";
import { addWarehouseStatus } from "../../../../redux/sil/cv/warehouses/addWarehouse/addWarehouseAccessor";
import { updateWarehouseStatus } from "../../../../redux/sil/cv/warehouses/updateWarehouse/updateWarehouseAccessor";
import { getWarehouseLoadAction } from "../../../../redux/sil/cv/warehouses/getWarehouses/getWarehousesActions";
import { addWarehouseLoadAction } from "../../../../redux/sil/cv/warehouses/addWarehouse/addWarehouseActions";
import { updateWarehouseLoadAction } from "../../../../redux/sil/cv/warehouses/updateWarehouse/updateWarehouseActions";


const scaleList: IIdName<number>[] = [
    { id: 0, name: "Paper Bag test Scale" },
    { id: 1, name: "Bagger Scale East" },
    { id: 2, name: "Bagger Scale West" },
    { id: 3, name: "Bulk Bag Scale" },
    { id: 4, name: "Truck Scale" }
];

interface IWarehouseStoreProps {
    addWarehouse: Server<string>;
    updateWarehouse: Server<string>;
    getToken: Server<SilAPIResponse<IToken>>;
    getWarehouses: Server<SilAPIResponse<IWarehouse[]>>;
};

interface IWarehouseDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getWarehousesRequest: (data: IGetWarehouseRequest) => unknown;
    addWarehouseRequest: (data: IAddWarehouseRequest) => unknown;
    updateWarehouseRequest: (data: IUpdateWarehouseRequest) => unknown;
};


interface IWarehouseOwnProps {

};

interface IWarehouseState {
    warehouse: IWarehouse;
    errors: ById<IFieldErrorKeyValue>;
};

const WarehouseStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
`;

type IWarehouseProps = RouteComponentProps
    & IWarehouseStoreProps
    & IWarehouseDispatchProps
    & IWarehouseOwnProps;

class Warehouse extends PureComponent<IWarehouseProps, IWarehouseState> {

    public constructor(props: IWarehouseProps) {
        super(props);
        this.state = {
            errors: {
                "scale": { key: "scale", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "model": { key: "model", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "month": { key: "month", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "year": { key: "year", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "employee": { key: "employee", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
            },
            warehouse: {
                id: 0,
                scale: "",
                created: "",
                employee: "",
                created_By: userName,
                modified: "",
                modified_By: userName
            }
        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: IWarehouseProps): void {
        if (this.props !== prevProps) {
            this.callServer();

            if (this.props.addWarehouse !== prevProps.addWarehouse) {
                if (isSucceeded(this.props.addWarehouse)) {
                    this.handleCancel();
                };
            };

            if (this.props.updateWarehouse !== prevProps.updateWarehouse) {
                if (isSucceeded(this.props.updateWarehouse)) {
                    this.handleCancel();
                };
            };
        };
    };


    public render(): ReactNode {

        const { updateWarehouse, addWarehouse } = this.props;
        const { warehouse, errors } = this.state;

        const isAdd = warehouse.id > 0 ? false : true;
        const years = getYearsList(2018);

        return (
            <PageSpacing title="SIL - CV" description="Warehouse" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="carrierAccess">
                    <WarehouseStyles>

                        <LAButton startIcon={<ArrowLeftIcon color={WHITE_COLOR} />} label="Back to list" onClick={this.handleCancel} />
                        <h2 className="text-center">{!isAdd ? "VIEW " : "ADD "} Warehouse</h2>
                        <hr />

                        <LAPaperWithPadding>
                            <LAGrid spacing={3}>

                            <LAGridItem xs={4}>
                                    <LAAutoComplete
                                        filterSelectedOptions={true}
                                        autoHighlight={true}
                                        selectionRemove={undefinedFunction}
                                        dropDownPlaceHolder="Scale"
                                        getOptionLabel="name"
                                        name="scale"
                                        option={scaleList}
                                        multiple={false}
                                        onChange={this.handleDropDownChange}
                                        value={scaleList.find(x => x.name === warehouse.scale)}
                                        defaultValue={scaleList.find(x => x.name === warehouse.scale)}
                                        errorText={errors["scale"] ? errors["scale"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LATextField
                                        varient="outlined"
                                        label="Model #"
                                        fullWidth={true}
                                        name="model"
                                        value={warehouse.model ?? ""}
                                        onChange={this.onChange}
                                        errorText={errors["model"] ? errors["model"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                <LAAutoComplete
                                        filterSelectedOptions={true}
                                        autoHighlight={true}
                                        selectionRemove={undefinedFunction}
                                        dropDownPlaceHolder="Month"
                                        getOptionLabel="name"
                                        name="month"
                                        option={monthNames}
                                        multiple={false}
                                        onChange={this.handleDropDownIdChange}
                                        value={warehouse.month ? monthNames.find(x => x.id === warehouse.month) : ""}
                                        defaultValue={warehouse.month ? monthNames.find(x => x.id === warehouse.month) : ""}
                                        errorText={errors["month"] ? errors["month"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                <LAAutoComplete
                                        filterSelectedOptions={true}
                                        autoHighlight={true}
                                        selectionRemove={undefinedFunction}
                                        dropDownPlaceHolder="Year"
                                        getOptionLabel="name"
                                        name="year"
                                        option={years}
                                        multiple={false}
                                        onChange={this.handleDropDownIdChange}
                                        value={warehouse.year ? years.find(x => x.id === warehouse.year) : ""}
                                        defaultValue={warehouse.year ? years.find(x => x.id === warehouse.year) : ""}
                                        errorText={errors["year"] ? errors["year"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LATextField
                                        varient="outlined"
                                        label="500 g Reading"
                                        fullWidth={true}
                                        name="five_Hundred_G_Reading"
                                        type="number"
                                        value={warehouse.five_Hundred_G_Reading ?? ""}
                                        onChange={this.onNumberChange}
                                        errorText={errors["five_Hundred_G_Reading"] ? errors["five_Hundred_G_Reading"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LATextField
                                        varient="outlined"
                                        label="1 Kg Reading"
                                        fullWidth={true}
                                        name="one_KG_Reading"
                                        type="number"
                                        value={warehouse.one_KG_Reading ?? ""}
                                        onChange={this.onNumberChange}
                                        errorText={errors["one_KG_Reading"] ? errors["one_KG_Reading"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LATextField
                                        varient="outlined"
                                        label="2 Kg Reading"
                                        fullWidth={true}
                                        name="two_KG_Reading"
                                        type="number"
                                        value={warehouse.two_KG_Reading ?? ""}
                                        onChange={this.onNumberChange}
                                        errorText={errors["two_KG_Reading"] ? errors["two_KG_Reading"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LATextField
                                        varient="outlined"
                                        label="Employee"
                                        fullWidth={true}
                                        name="employee"
                                        value={warehouse.employee ?? ""}
                                        onChange={this.onChange}
                                        errorText={errors["employee"] ? errors["employee"].message : undefined}
                                    />
                                </LAGridItem>
                                

                                <LAGridItem xs={12}>
                                    <hr />
                                    <LASaveAndCancelButton
                                        onSave={this.onSave}
                                        onCancel={this.handleCancel}
                                        disableSave={(Object.values(errors).length > 0 ? true : undefined)}
                                    />
                                </LAGridItem>

                            </LAGrid>
                        </LAPaperWithPadding>

                        <RequestStatus requestStatus={addWarehouse.kind} successMessage="Warehouse successfully saved" />
                        <RequestStatus requestStatus={updateWarehouse.kind} successMessage="Warehouse successfully updated" />
                    </WarehouseStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, isRequired: boolean): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: isRequired ? true : undefined, minLength: 1, decimal: undefined });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        };
        return err;
    };

    private handleCancel = (): void => {
        this.props.history.push(ROUTE.SIL.CV.WAREHOUSES.INDEX);
    };

    private onNumberChange = (name: string, value: string): void => {
        if(!isNaN(+value)){
            let errors = this.state.errors;
        errors = this.errorChecker(name, value, errors, true);

        this.setState({
            ...this.state,
            errors,
            warehouse: {
                ...this.state.warehouse,
                [name]: +value
            }
        });
        }
    };

    private onSave = (): void => {
        const { warehouse } = this.state;

        if (hasPayload(this.props.getToken))
            if (warehouse.id === 0) {
                this.props.addWarehouseRequest({
                    token: this.props.getToken.payload.response.token,
                    request: warehouse
                });
            } else {
                warehouse.modified_By = userName;
                
                this.props.updateWarehouseRequest({
                    token: this.props.getToken.payload.response.token,
                    request: warehouse
                });
            };
    };

    private handleDropDownChange = (event: unknown, value: { id: number, name: string } | "", name?: string): void => {
        if (name){
            let errors = this.state.errors;
            errors = this.errorChecker(name, value !== "" ? value.name : "", errors, true);

            this.setState({
                ...this.state,
                errors,
                warehouse: {
                    ...this.state.warehouse,
                    [name]: value !== "" ? value.name : ""
                }
            });
        }
    };

    private handleDropDownIdChange = (event: unknown, value: { id: number, name: string } | "", name?: string): void => {
        if (name){
            let errors = this.state.errors;
            errors = this.errorChecker(name, value !== "" ? value.name : "", errors, true);

            this.setState({
                ...this.state,
                errors,
                warehouse: {
                    ...this.state.warehouse,
                    [name]: value !== "" ? value.id : undefined
                }
            });
        }
    };


    private onChange = (name: string, value: string): void => {
        let errors = this.state.errors;
        errors = this.errorChecker(name, value, errors, true);

        this.setState({
            ...this.state,
            errors,
            warehouse: {
                ...this.state.warehouse,
                [name]: value
            }
        });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getWarehouses))
            this.props.getWarehousesRequest({
                token: this.props.getToken.payload.response.token
            });


        const query = queryString.parse(this.props.location.search);
        const id = query.id ?? "0";

        if ((id !== "0") && (hasPayload(this.props.getWarehouses))) {
            const warehouse = this.props.getWarehouses.payload.response.find(x => x.id === Number(id));

            if(warehouse)
                this.setState({ warehouse, errors: {} });
        };

    };

}

const mapStateToProps = (state: IStore): IWarehouseStoreProps => ({
    getToken: getTokenStatus(state),
    getWarehouses: getWarehouseStatus(state),
    addWarehouse: addWarehouseStatus(state),
    updateWarehouse: updateWarehouseStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IWarehouseDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getWarehousesRequest: (data: IGetWarehouseRequest) => dispatch(getWarehouseLoadAction(data)),
    addWarehouseRequest: (data: IAddWarehouseRequest) => dispatch(addWarehouseLoadAction(data)),
    updateWarehouseRequest: (data: IUpdateWarehouseRequest) => dispatch(updateWarehouseLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Warehouse);