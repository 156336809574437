import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IUpdateOrderLoadAction, IUpdateOrderLoadFailedAction, IUpdateOrderSuccessAction, 
    updateOrderLoadFailedAction, updateOrderLoadSuccessAction
} from "./updateOrderActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IUPDATE_ORDER_REQUEST } from "./updateOrderConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SimosAPIResponse } from "../../../../react/shared/publicInterfaces";

export const updateOrderEpic: Epic = (
    action$: ActionsObservable<IUpdateOrderLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IUpdateOrderSuccessAction | IUpdateOrderLoadFailedAction> =>
    action$.ofType(IUPDATE_ORDER_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SimosAPIResponse<string>>(
                    END_POINTS.PROPSENSE.SIMOS.UPDATE_ORDER,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SimosAPIResponse<string>): IUpdateOrderSuccessAction | IUpdateOrderLoadFailedAction => {
                            if(response.message !== "Success")
                                return updateOrderLoadFailedAction(response.message);
                            return updateOrderLoadSuccessAction(response.message);
                        }),
                        catchError(() => ActionsObservable.of(updateOrderLoadFailedAction("Unable to update orders")))
                    )
            )
        );