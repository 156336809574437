import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IAddYHDCarrierEmailLoadAction, IAddYHDCarrierEmailLoadFailedAction, IAddYHDCarrierEmailSuccessAction, 
    addYHDCarrierEmailLoadFailedAction, addYHDCarrierEmailLoadSuccessAction
} from "./addCarrierEmailActions";
import { IStore } from "../../../../reducers";
import { EpicDependencies } from "../../../../store";
import { IADD_YHD_CARRIER_EMAIL_REQUEST } from "./addCarrierEmailConstaints";
import { END_POINTS } from "../../../../endpoints";
import { withJsonContentType } from "../../../../epicUtils";
import { YHDAPIResponse } from "../../../../../react/shared/publicInterfaces";

export const addYHDCarrierEmailEpic: Epic = (
    action$: ActionsObservable<IAddYHDCarrierEmailLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IAddYHDCarrierEmailSuccessAction | IAddYHDCarrierEmailLoadFailedAction> =>
    action$.ofType(IADD_YHD_CARRIER_EMAIL_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<YHDAPIResponse<string>>(
                    END_POINTS.PROPSENSE.YHD.CARRIER.ADD_CARRIER_EMAIL,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: YHDAPIResponse<string>): IAddYHDCarrierEmailSuccessAction | IAddYHDCarrierEmailLoadFailedAction => {
                            if(response.message === "Success"){
                                return addYHDCarrierEmailLoadSuccessAction(response.message);
                            };
                            return addYHDCarrierEmailLoadFailedAction(response.message)
                        }),
                        catchError(() => ActionsObservable.of(addYHDCarrierEmailLoadFailedAction("Unable to add carrier emails")))
                    )
            )
        );