import React from 'react';
import EditCustomerContactForm from './EditCustomerContactForm';
import {deleteOrderedByContact} from '../APICalls/addOrderedByContact';
import {FaPencilAlt} from 'react-icons/fa';
import Popup from '../../../../shared/simos/helper/popupModal';
import DataGrid, {
    Column, FilterRow, HeaderFilter,Paging, Button,Pager,SearchPanel,
    FilterPanel,
    FilterBuilderPopup,
    Export,
  } from 'devextreme-react/data-grid';
  import 'devextreme/dist/css/dx.light.css';
import { getSILURLParams } from '../../../../shared/simos/helper/getURLContainsSIL';

interface IProps {
    history:any;
}
const renderTitleHeader = (data:any) => {
    return <p style={{ color:getSILURLParams()?.includes("bru")?"#5383d3":"#a73030",font: 'Cookie', fontSize:'14px'}}>{data.column.caption}</p>
}
const filterBuilderPopupPosition = {
    of: window,
    at: 'top',
    my: 'top',
    offset: { y: 10 },
  };
function StyledComponent(cellData:any):any {
/* <div className={cellData.data.email === 'admin.test@gmail.com' ? "devex-styled-grid-row-green": cellData.data.email ==='dylan.ollikka@sil.ab.ca' ? "devex-styled-grid-row-red":"devex-styled-grid-row-orange"}>{cellData.value}</div> */
    return (
        <div className={"devex-styled-grid-row-default"}>{cellData.value}</div>
    )
}

interface IState {
    customer_contacts:any[];
    showCustomerEditForm:boolean;
    customer_filter:any[];
    delivery_filter:any[];
    pageSize:number;
    deleteContactPopup:boolean;
    searchValue: string;
}
var globalContactID = 0;
let all_customer_contacts:any = [];
var filtered:any;
var contactID:number = 0;
// IProps: holds the interface with history
export default class CustomerContactTable extends React.Component<IProps, IState>{
    constructor(props:IProps) {
        super(props);
        this.state = {
            customer_contacts:[],showCustomerEditForm:false,customer_filter:[],delivery_filter:[],pageSize:5,deleteContactPopup:false, searchValue: ''
        }
        this.handleContactEdit = this.handleContactEdit.bind(this);
    }

    handleContactEdit(contactID:number) {
        globalContactID = contactID;
        this.setState({showCustomerEditForm:true});
        //this.props.history.push('/editCustomerContacts/id='+contactID);
    }
    handleContactDelete(elem:any){
        contactID = elem.id
        this.setState({deleteContactPopup:true});
    }
   
    // Deletes a customer contact
    async handleDelete(contactID: number) {
        let res:any = await deleteOrderedByContact(contactID);
        if(res.status === 200){
            alert("Contact deleted successfully");
            this.setState({deleteContactPopup:false});
            window.location.reload();
        }
    }
    // Cancel deleting a customer contact
    handleDeleteContactCancel(){
        this.setState({deleteContactPopup:false});
    }
    closePopup() {
        this.setState({showCustomerEditForm:false});
    }
    setSearchResult(newSearch:any){
        this.setState({customer_contacts:newSearch});
    }
    setPageSize(newPageSize:number){
        this.setState({pageSize: newPageSize});
    }

    componentDidMount() {
        let allContacts:any[] = [];
        let allCustomers:any[] = [];
        let allDelivery:any[] = [];
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({request:{Customer:'orderdesk'}, token: '19515195-d571-44a3-b0db-637f5bf24f54' })
        }
        fetch('https://api2.propsense.com/api/GetSIMOSCustomerContacts', requestOptions)
        .then(async response => {
            const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
            // error response
            if(!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            // no error
            data.map((contact:any) => {
                allContacts.push(contact);
                // Add possible filters to each dropdown
                if(allCustomers.indexOf(contact.customer) === -1 ){
                     allCustomers.push(contact.customer);
                }
              
                if(allDelivery.indexOf(contact.delivery_Address) === -1) {
                    allDelivery.push(contact.delivery_Address);
                }
            });
            this.setState({customer_contacts: allContacts, customer_filter:allCustomers, delivery_filter:allDelivery});
            all_customer_contacts = this.state.customer_contacts;
        })
        .catch(error => {
            console.log('There was an error!', error);
        });
    }

    handleValueChange = (e:any) => {
        // console.log(e);
        const trimmedValue = e.trim();
        this.setState({searchValue: trimmedValue});
      };
   
    render() {
        var columns:any[] = [];
        return (
            <div className='grid-view'>
                 {/* <SearchBar setSearchResult={this.setSearchResult.bind(this)} searchArray={this.state.customer_contacts} filterBy={['customer','delivery_Address','phone','fax','email','contact']} showGrid={this.closePopup.bind(this)}></SearchBar> */}
            <br></br><br></br>
            <div style={{ width:'100%'}}>
                {/* <DataGrid
                className="data-grid-table"
                    rows={this.state.customer_contacts}
                    rowHeight={90}
                    getRowClassName={() => "data-grid-row-style"}
                    columns={columns}
                    pageSize={this.state.pageSize}
                    onPageSizeChange={this.setPageSize.bind(this)}
                    rowsPerPageOptions={[5,10,20]}
                    disableSelectionOnClick
                /> */}
                <DataGrid id={'gridContainer'}
                 focusedRowEnabled={true}
                 hoverStateEnabled={true}
                 rowAlternationEnabled={true}
                    dataSource={this.state.customer_contacts}
                    keyExpr="id"
                    showBorders={true}
                    showColumnLines={true}
                    // took out height={700}
                > 
                <SearchPanel visible={true}
                width={340}
                placeholder="Search for..." 
                text={this.state.searchValue}
                onTextChange={this.handleValueChange}
                />       
          <Paging enabled={true} defaultPageSize={10}/>
          <Pager
            visible={true}
            allowedPageSizes={[10,20,30]}
            displayMode={'full'}
            showPageSizeSelector={true}
            showInfo={true}
            showNavigationButtons={true} />
            <FilterRow visible={true}></FilterRow>
            <FilterPanel visible={true} />
        <FilterBuilderPopup position={filterBuilderPopupPosition} />
          <HeaderFilter visible={true} allowSearch={true} />
          <Export enabled={true} allowExportSelectedData={true} />
           <Column type="buttons" caption="Edit" headerCellRender={renderTitleHeader}>   
            <Button text="edit" visible={true} onClick={(e:any) =>  {this.handleContactEdit(e.row.data.id)}}><FaPencilAlt/></Button>
           </Column>
           {/* <Column type="buttons" caption="Delete" headerCellRender={renderTitleHeader}>   
            <Button text="X" cssClass={"del-customer-btn"} visible={true} onClick={(e:any) =>  {this.handleContactDelete(e.row.data)}}></Button>
           </Column> */}
           <Column dataField="contact" alignment="left" width={200} cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
          <Column dataField="phone" alignment="left" width={200} cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
          <Column dataField="fax" alignment="left" dataType="number" width={200} cellRender={StyledComponent} headerCellRender={renderTitleHeader}></Column>
          <Column dataField="email"  alignment="left"  width={300} cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
          <Column dataField="customer" width={300} alignment="left"caption="Customer" cellRender={StyledComponent} headerCellRender={renderTitleHeader}>
          </Column>
          <Column dataField="delivery_Address"  width={300} alignment="left" cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
          
        </DataGrid>
                 {this.state.showCustomerEditForm ? <EditCustomerContactForm closePopup={this.closePopup.bind(this)}history={this.props.history}editID={globalContactID} customer_contacts={this.state.customer_contacts}></EditCustomerContactForm>:null}
                {this.state.deleteContactPopup ? <Popup headerText="Deleting Contact" text='Are you sure you want to delete this contact?' savePopup = {this.handleDelete.bind(this, contactID)} closePopup={this.handleDeleteContactCancel.bind(this)}/>:null}
            </div>
        </div>
        );
    }
}
export {all_customer_contacts, filtered};