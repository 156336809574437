import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "./shared/paper";
import { IStore, IDispatch } from "../redux/reducers";
import { ROUTE } from "./routes";
import LAGrid from "./shared/grid";
import LAGridItem from "./shared/gridList";
import { DARK_RED_COLOR, MEDIA_QUERY_PHONE } from "./shared/theme";
import jwt_decode from "jwt-decode";
import { callRouteWithQueryString } from "./shared/constExports";
import queryString from "query-string";

// decoded.upn -> sp.test1@sureway.ca

//publish to api2.propsense.com
// call api to get user group
// const getToken = localStorage.getItem("adal.idtoken");
// const decoded: any = getToken ? jwt_decode(getToken) : "";
// const currentUser: string = decoded.upn;

const getToken = localStorage.getItem("adal.idtoken");
const token: any = (getToken !== null && getToken.length > 0) ? jwt_decode(getToken) : { unique_name: "SUREWAYGROUP\\amit.patel" };
const currentUser = token.unique_name.substr(token.unique_name.lastIndexOf("\\") + 1);
//const currentUser:string = "dylan.ollikka"; // group = Admin
// const currentUser:string = "sp.test1"; // group = Dispatcher
// const currentUser:string = "sp.test2"; // group = operator

interface ICatapultHomeStoreProps {

};

interface ICatapultHomeDispatchProps {

};

interface ICatapultHomeOwnProps {

};

interface ICatapultHomeState {
    userrole: string;
};

const CatapultHomeStyles = styled(LAPaperWithPadding)`
    margin: 40px 30px;
    word-break: break-word;

    .title {
        color: ${DARK_RED_COLOR};
        font-size: 40px;
        font-weight: bold;
        text-shadow: 2px 1px #b30003;
        
    };

    .homeMenu {
        color: #000;
        height: 90px;
        cursor: pointer;
        border-radius: 15px;
        padding: 5px;
        border: 2px solid #fff;
        background-color: #E7F2F8;
        box-shadow: 0 2px 10px 0 #b30003;
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 5.0;
        margin-right: 10%;
        -webkit-transition: background 2.5s linear;
        -moz-transition: background 2.5s linear;
        -o-transition: background 2.5s linear;
        -ms-transition: background 2.5s linear;
        transition: background 2.5s linear;
    };

    .appTitle {
        font-size: 20px;
        font-weight: bold;
        margin-top: 10%;
        text-shadow: 0.5px 1px #719de3;
        color: #5d95f0;
    };

    .TileSpacing{
        padding: 20px;
    }

    .orderTitle {
        color: #8289dd;
    };

    .dryPlantTitle {
        color: #a9e7ff;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };
`;

type ICatapultHomeProps = RouteComponentProps
    & ICatapultHomeStoreProps
    & ICatapultHomeDispatchProps
    & ICatapultHomeOwnProps;

class SimosHome extends PureComponent<ICatapultHomeProps, ICatapultHomeState> {

    public constructor(props: ICatapultHomeProps) {
        super(props);
        this.state = {
            userrole: ""
        };

    }

    public componentDidMount(): void {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ request: { username: currentUser }, token: "19515195-d571-44a3-b0db-637f5bf24f54" })
        }
        fetch("https://api2.propsense.com/api/GetSIMOSUserGroup", requestOptions)
            .then(response => {
                const data = response.headers.get("Content-Type")?.includes("application/json");
                // error response
                if (!response.ok) {
                    const error = (data) || response.status;
                    return Promise.reject(error);
                } else {
                    response.json().then((res: any) => {
                        if (res.isAuthenticated) {
                            this.setState({ userrole: res.role });
                            //localStorage.setItem("userrole",res.role);
                        }
                    })
                        .catch((err: any) => {
                            console.log(err.toString(), err)
                        })
                }
            })

    };

    public async componentDidUpdate(prevProps: ICatapultHomeProps, prevState: ICatapultHomeState): Promise<void> {
    };

    public render(): ReactNode {

        return (
            <CatapultHomeStyles>
                <LAGrid spacing={3} className="text-center">

                    <LAGridItem xs={12}>
                        <h2 className="title">SIMOS</h2>
                        <hr />
                    </LAGridItem>

                    {this.state.userrole === "SuperAdmin" || this.state.userrole === "Admin" || this.state.userrole === "Dispatcher" ? (
                        <>
                            <LAGridItem xs={2}>
                                <div className="homeMenu" onClick={this.onsimosOrders}>
                                    <h2 className="appTitle orderTitle">Sil Bru Orders</h2>
                                </div>
                            </LAGridItem>
                            <LAGridItem xs={2}>
                                <div className="homeMenu" onClick={this.onsimosSILEdOrders}>
                                    <h2 className="appTitle orderTitle">SIL Ed Orders</h2>
                                </div>
                            </LAGridItem>
                        </>
                    ) : this.state.userrole === "operator" ? (
                        <></>
                    ) : (
                        <LAGridItem xs={12}>
                            <div style={{ color: "red", fontWeight: "bold" }}>
                                contact it.supportdesk@sureway.ca for access
                            </div>
                        </LAGridItem>
                    )}

                </LAGrid>
            </CatapultHomeStyles>
        )
    }

    private onsimosOrders = (): void => {
        // this.props.history.push(ROUTE.SIMOS.ORDER.INDEX + "?" + "sil_bru");
        const query = queryString.parse(this.props.location.search);
        callRouteWithQueryString({
            route: this.props,
            search: { page:  "sil_bru" },
            pathName: ROUTE.SIMOS.SIL_Bru_Orders.INDEX
        });
        
    };
    private onsimosSILEdOrders = (): void => {
        // this.props.history.push(ROUTE.SIMOS.ORDER.INDEX + "?" + "sil_ed");
        const query = queryString.parse(this.props.location.search);
        callRouteWithQueryString({
            route: this.props,
            search: { page:  "sil_ed" },
            pathName: ROUTE.SIMOS.SIL_Ed_Orders.INDEX
        });
    };

}

const mapStateToProps = (state: IStore): ICatapultHomeStoreProps => ({

});

const mapDispatchToProps = (dispatch: IDispatch): ICatapultHomeDispatchProps => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(SimosHome);

export { currentUser };