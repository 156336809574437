import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import queryString from "query-string";
import SignatureCanvas from "react-signature-canvas";
import { hasPayload, isNotLoaded, isSucceeded, Server } from "../../../../redux/server";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import PageSpacing from "../../../shared/pageSpacing";
import { ROUTE } from "../../../routes";
import { LAButton, LAIconButton, LASaveAndCancelButton } from "../../../shared/buttons";
import LAAutoComplete from "../../../shared/autoComplete";
import { deleteText, getDate, undefinedFunction, userName, yesOrNoOptions } from "../../../shared/constExports";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import { ArrowLeftIcon, DeleteIcon } from "../../../shared/icons";
import LATextArea from "../../../shared/textArea";
import LATextField from "../../../shared/textField";
import { LIGHT_BLUE_COLOR, WARNING_COLOR, WHITE_COLOR } from "../../../shared/theme";
import { LAExpansionPanel } from "../../../shared/expansionPanel";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import { END_POINTS } from "../../../../redux/endpoints";
import { LADropDone } from "../../../shared/dragDropFiles";
import LALinkButton from "../../../shared/linkButton";
import React from "react";
import { IIdName } from "../../../../utils/sharedTypes";
import { ById, IAttachmentUpload, SilAPIResponse } from "../../../shared/publicInterfaces";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { IGetCarrierAudit, IGetCarrierAuditRequest } from "../../../../redux/sil/carrier/audit/getAudit/getCarrierAuditConstants";
import { IUpdateCarrierAuditRequest } from "../../../../redux/sil/carrier/audit/updateAudit/updateAuditConstants";
import { FIELD_VALIDATOR_ERRORS, FieldValidator, IFieldErrorKeyValue } from "../../../shared/fieldValidator";
import { getSILCarrierAuditStatus } from "../../../../redux/sil/carrier/audit/getAudit/getCarrierAuditAccessor";
import { updateSILCarrierAuditStatus } from "../../../../redux/sil/carrier/audit/updateAudit/updateAuditAccessor";
import { getCarrierAuditLoadAction } from "../../../../redux/sil/carrier/audit/getAudit/getCarrierAuditActions";
import { updateCarrierAuditLoadAction } from "../../../../redux/sil/carrier/audit/updateAudit/updateAuditActions";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { LADateTimePicker } from "../../../shared/dateTimePicker";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import { LACheckBox } from "../../../shared/checkBox";

export const abstractsFrequency: IIdName<string>[] = [{ id: "6 Months", name: "6 Months" }, { id: "Yearly", name: "Yearly" }];
const requiredFields = [
    "audit",
    "insurance",
    "policy_date",
    "wcb",
    "substance_Abuse_Policy",
    "seatbelt_Policy",
    "driver_Abstracts",
    "reversing_Alarms",
    "wheel_Chocks",
    "anti_lock_brakes",
    "ivms",
    "radio_Communication",
    "spill_Kit",
    "emergency_Survival_Kit",
    "cell_Phone_Policy",
    "maintenance_Policy",
    "distracted_Driving_Policy",
    "speed_Limit_Policy",
    "defensive_Driving_Policy",
    "fueling_Policy",
    "safety_Fitness_Expiry_Date",
    "audit_Completion_Date",
    "carrier_Profile_Reviewed",
    "audit"
];

interface ICarrierAuditStoreProps {
    getToken: Server<SilAPIResponse<IToken>>;
    getSILAudits: Server<SilAPIResponse<IGetCarrierAudit[]>>;
    updateAudit: Server<SilAPIResponse<string>>;
};

interface ICarrierAuditDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getSILAuditsRequest: (data: IGetCarrierAuditRequest) => unknown;
    updateSILAuditsRequest: (data: IUpdateCarrierAuditRequest) => unknown;
};


interface ICarrierAuditOwnProps {

};

interface ICarrierAuditState {
    audit: IGetCarrierAudit;
    errors: ById<IFieldErrorKeyValue>;
    carrierExpand: boolean;
    insuranceExpand: boolean;
    wcbCoverageExpand: boolean;
    drugAlcoholPolicyExpand: boolean;
    seatbeltPolicyExpand: boolean;
    driversAbstractsExpand: boolean;
    vehiclesExpand: boolean;
    cellPhonePolicyExpand: boolean;
    distractedDrivingPolicyExpand: boolean;
    maintenancePolicyExpand: boolean;
    speedLimitPolicyExpand: boolean;
    defensiveDrivingPolicyExpand: boolean;
    fuelingPolicyExpand: boolean;
    driverTrainingExpand: boolean;
    safetyFitnessExpand: boolean;
    signatureExpand: boolean;
    approvalExpand: boolean;
};

const SilAuditStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;

    .text-left {
        text-align: left;
        padding-left: 5px;
    };

    .required {
        color: ${WARNING_COLOR};
    };

    .sign {
        border: 2px solid #000;
    };

    .required-sign {
        border: 2px solid ${WARNING_COLOR};
    };

    .signature-container {
        padding: 5px;
        display: inline-block;
    }

    .sigCanvas {
        border: 1px solid #ccc;
    }
`;

type ICarrierAuditProps = RouteComponentProps
    & ICarrierAuditStoreProps
    & ICarrierAuditDispatchProps
    & ICarrierAuditOwnProps;

class SILAudit extends PureComponent<ICarrierAuditProps, ICarrierAuditState> {
    sigCanvas: React.RefObject<any>;

    public constructor(props: ICarrierAuditProps) {
        super(props);
        this.sigCanvas = React.createRef();
        this.state = {
            errors: {
            },
            audit: {
                id: 0,
                carrier_ID: 0,
                carrier_Name: "",
                date: "",
                insurance: "",
                policy_date: "",
                insurance_Comments: "",
                wcb: "",
                wcB_No: "",
                wcB_Comments: "",
                substance_Abuse_Policy: "",
                post_Incident_Testing: "",
                substance_Comments: "",
                seatbelt_Policy: "",
                seatbelt_Comments: "",
                carrier_Profile_Reviewed: "",
                audited: "",
                unaudited: "",
                r_Factor: "",
                profile_Comments: "",
                driver_Abstracts: "",
                _6_Demerits: "",
                alcohol_Free: "",
                pdic: "",
                abstracts_Frequency: "",
                reversing_Alarms: "",
                wheel_Chocks: "",
                anti_lock_brakes: "",
                ivms: "",
                radio_Communication: "",
                spill_Kit: "",
                emergency_Survival_Kit: "",
                vehicle_Comments: "",
                cell_Phone_Policy: "",
                cell_Phone_Comments: "",
                maintenance_Policy: "",
                a_Service_Intervals: "",
                b_Service_Intervals: "",
                current_CVIP: "",
                how_Tracked: "",
                maintenance_Comments: "",
                first_Aid: "",
                h2S_Alive: "",
                bear_Awareness: "",
                oilfield_Driver_Awareness: "",
                service_Hours: "",
                cargo_Securement: "",
                pre_Post_Trip: "",
                training_Comments: "",
                auditor_Printed_Name: "",
                auditor_Signature: "",
                carrier_Printed_Name: "",
                carrier_Signature: "",
                audit_Completion_Date: "",
                audit: "",
                tdg: "",
                cso: "",
                whmis: "",
                distracted_Driving_Policy: "",
                distracted_Driving_Comments: "",
                speed_Limit_Policy: "",
                speed_Limit_Comments: "",
                defensive_Driving_Policy: "",
                defensive_Driving_Comments: "",
                fueling_Policy: "",
                fueling_Comments: "",
                safety_Fitness_Expiry_Date: "",
                awaiting_Paper_Work: "",
                awaiting_Paper_Work_Comments: "",

                insurance_Policy_File_Name: "",
                wcB_Policy_File_Name: "",
                drug_Alcohol_Policy_File_Name: "",
                seatbelt_Policy_File_Name: "",
                driver_Abstracts_Policy_File_Name: "",
                cell_Phone_Policy_File_Name: "",
                distract_Driving_Policy_File_Name: "",
                maintenance_Policy_File_Name: "",
                speed_Limit_Policy_File_Name: "",
                defensive_Driving_Policy_File_Name: "",
                fueling_Policy_File_Name: "",
                safety_Fitness_Certificate_File_Name: "",

                created: "",
                created_By: "",
                modified: "",
                modified_By: "",
            },
            carrierExpand: true,
            insuranceExpand: true,
            wcbCoverageExpand: true,
            drugAlcoholPolicyExpand: true,
            seatbeltPolicyExpand: true,
            driversAbstractsExpand: true,
            vehiclesExpand: true,
            cellPhonePolicyExpand: true,
            distractedDrivingPolicyExpand: true,
            maintenancePolicyExpand: true,
            speedLimitPolicyExpand: true,
            defensiveDrivingPolicyExpand: true,
            fuelingPolicyExpand: true,
            driverTrainingExpand: true,
            safetyFitnessExpand: true,
            signatureExpand: true,
            approvalExpand: true,
        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: ICarrierAuditProps): void {

        if (this.props !== prevProps) {
            this.callServer();

            if (this.props.updateAudit !== prevProps.updateAudit) {
                if (isSucceeded(this.props.updateAudit)) {
                    this.handleCancel();
                };
            };
        };
    };


    public render(): ReactNode {

        const { updateAudit } = this.props;
        const { audit, errors, carrierExpand, insuranceExpand, wcbCoverageExpand, drugAlcoholPolicyExpand, seatbeltPolicyExpand, driversAbstractsExpand, 
            vehiclesExpand, cellPhonePolicyExpand, distractedDrivingPolicyExpand, maintenancePolicyExpand, speedLimitPolicyExpand, defensiveDrivingPolicyExpand, fuelingPolicyExpand,
            driverTrainingExpand, safetyFitnessExpand, signatureExpand, approvalExpand } = this.state;

        return (
            <PageSpacing title="SIL - Audit" description="SIL Audit" fixedSpaceOnSmallerScreens={true}>
                <SilAuditStyles>

                    <LAButton startIcon={<ArrowLeftIcon color={WHITE_COLOR} />} label="Back to list" onClick={this.handleCancel} />
                    <h2 className="text-center">VIEW AUDIT</h2>
                    <hr />

                    <LAPaperWithPadding>
                        <LAGrid spacing={1}>

                            <LAGridItem xs={12}>
                                <LAExpansionPanel color={LIGHT_BLUE_COLOR} label="Carrier" expanded={carrierExpand} onClick={() => this.handleExpand("carrierExpand")}>
                                    <LAGrid>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LATextField
                                                label="Carrier"
                                                disabled={true}
                                                fullWidth={true}
                                                name="carrier_Name"
                                                value={audit.carrier_Name}
                                                onChange={undefinedFunction}
                                                errorText={errors["carrier_Name"] ? errors["carrier_Name"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LATextField
                                                label="Date"
                                                fullWidth={true}
                                                name="date"
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={getDate(false, audit.date)}
                                                errorText={errors["date"] ? errors["date"].message : undefined}
                                            />
                                        </LAGridItem>

                                    </LAGrid>
                                </LAExpansionPanel>
                            </LAGridItem>

                            <LAGridItem xs={12}>
                                <LAExpansionPanel color={LIGHT_BLUE_COLOR} label="Does the carrier have the appropriate insurance?" expanded={insuranceExpand} onClick={() => this.handleExpand("insuranceExpand")}>
                                    <LAGrid>
                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="Insurance"
                                                getOptionLabel="name"
                                                name="insurance"
                                                option={yesOrNoOptions}
                                                multiple={false}
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.insurance ? yesOrNoOptions.find(x => x.name === audit.insurance) : null}
                                                defaultValue={audit.insurance ? yesOrNoOptions.find(x => x.name === audit.insurance) : null}
                                                errorText={errors["insurance"] ? errors["insurance"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4} className="text-center">
                                            <LADateTimePicker
                                                name="policy_date"
                                                label="Date"
                                                fullWidth={true}
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.policy_date ?? ""}
                                                errorText={errors && errors["policy_date"] ? errors["policy_date"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LATextArea
                                                rowsMax={10}
                                                label="Insurance Comments"
                                                fullWidth={true}
                                                name="insurance_Comments"
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.insurance_Comments}
                                                errorText={errors["insurance_Comments"] ? errors["insurance_Comments"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={6} className="text-center">
                                            <strong>Upload Insurance Policy</strong>
                                            {/* <LADropDone onFileDrop={(e: Array<File | DataTransferItem>) => this.handleAtt(e, "insurance_Policy")} /> */}

                                            <LAGrid>
                                                {audit.insurance_Policy_File_Name && audit.insurance_Policy_File_Name.split(";").map((fName: string, ind) => {
                                                    const onAttFileDownload = (): void => this.handleDownloadAtt("Insurance_Policy", fName, audit.Insurance_Policy_Files);
                                                    const onRemove = (event: any): void => this.handleDeleteAtt(fName, "insurance_Policy")
                                                    return (
                                                        <LAGridItem key={ind} xs={6}>

                                                            <LALinkButton
                                                                className="pl-5"
                                                                label={fName}
                                                                onClick={onAttFileDownload}
                                                            />

                                                            {/* <LAIconButton
                                                                name={fName}
                                                                label="Remove"
                                                                icon={<DeleteIcon />}
                                                                onClick={onRemove}
                                                            /> */}
                                                        </LAGridItem>
                                                    )
                                                })}
                                            </LAGrid>
                                        </LAGridItem>

                                    </LAGrid>
                                </LAExpansionPanel>
                            </LAGridItem>

                            <LAGridItem xs={12}>
                                <LAExpansionPanel color={LIGHT_BLUE_COLOR} label="Does the carrier have WCB coverage for workers ?" expanded={wcbCoverageExpand} onClick={() => this.handleExpand("wcbCoverageExpand")}>
                                    <LAGrid>
                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="WCB"
                                                getOptionLabel="name"
                                                name="wcb"
                                                option={yesOrNoOptions}
                                                multiple={false}
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.wcb ? yesOrNoOptions.find(x => x.name === audit.wcb) : null}
                                                defaultValue={audit.wcb ? yesOrNoOptions.find(x => x.name === audit.wcb) : null}
                                                errorText={errors["wcb"] ? errors["wcb"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LATextField
                                                label="WCB #"
                                                fullWidth={true}
                                                name="wcB_No"
                                                value={audit.wcB_No}
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                errorText={errors["wcB_No"] ? errors["wcB_No"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LATextArea
                                                rowsMax={10}
                                                label="WCB Comments"
                                                fullWidth={true}
                                                name="wcB_Comments"
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.wcB_Comments}
                                                errorText={errors["wcB_Comments"] ? errors["wcB_Comments"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={6} className="text-center">
                                            <strong>Upload WCB Policy</strong>
                                            {/* <LADropDone onFileDrop={(e: Array<File | DataTransferItem>) => this.handleAtt(e, "wcB_Policy")} /> */}

                                            <LAGrid>
                                                {audit.wcB_Policy_File_Name && audit.wcB_Policy_File_Name.split(";").map((fName: string, ind) => {
                                                    const onAttFileDownload = (): void => this.handleDownloadAtt("WCB_Policy", fName, audit.WCB_Policy_Files);
                                                    const onRemove = (event: any): void => this.handleDeleteAtt(fName, "wcB_Policy")
                                                    return (
                                                        <LAGridItem key={ind} xs={6}>

                                                            <LALinkButton
                                                                className="pl-5"
                                                                label={fName}
                                                                onClick={onAttFileDownload}
                                                            />

                                                            {/* <LAIconButton
                                                                name={fName}
                                                                label="Remove"
                                                                icon={<DeleteIcon />}
                                                                onClick={onRemove}
                                                            /> */}
                                                        </LAGridItem>
                                                    )
                                                })}
                                            </LAGrid>
                                        </LAGridItem>

                                    </LAGrid>
                                </LAExpansionPanel>
                            </LAGridItem>

                            <LAGridItem xs={12}>
                                <LAExpansionPanel color={LIGHT_BLUE_COLOR} label="Does the carrier have a Drug & Alcohol use policy? Please submit policy" expanded={drugAlcoholPolicyExpand} onClick={() => this.handleExpand("drugAlcoholPolicyExpand")}>
                                    <LAGrid>
                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="Drug & Alcohol Policy"
                                                getOptionLabel="name"
                                                name="substance_Abuse_Policy"
                                                option={yesOrNoOptions}
                                                multiple={false}
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.substance_Abuse_Policy ? yesOrNoOptions.find(x => x.name === audit.substance_Abuse_Policy) : null}
                                                defaultValue={audit.substance_Abuse_Policy ? yesOrNoOptions.find(x => x.name === audit.substance_Abuse_Policy) : null}
                                                errorText={errors["substance_Abuse_Policy"] ? errors["substance_Abuse_Policy"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="Post Incident Testing"
                                                getOptionLabel="name"
                                                name="post_Incident_Testing"
                                                option={yesOrNoOptions}
                                                multiple={false}
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.post_Incident_Testing ? yesOrNoOptions.find(x => x.name === audit.post_Incident_Testing) : null}
                                                defaultValue={audit.post_Incident_Testing ? yesOrNoOptions.find(x => x.name === audit.post_Incident_Testing) : null}
                                                errorText={errors["post_Incident_Testing"] ? errors["post_Incident_Testing"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LATextArea
                                                rowsMax={10}
                                                label="Drug & Alcohol Comments"
                                                fullWidth={true}
                                                name="substance_Comments"
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.substance_Comments}
                                                errorText={errors["substance_Comments"] ? errors["substance_Comments"].message : undefined}
                                            />
                                        </LAGridItem>


                                        <LAGridItem xs={6} className="text-center">
                                            <strong>Upload Drug & Alcohol use Policy</strong>
                                            {/* <LADropDone onFileDrop={(e: Array<File | DataTransferItem>) => this.handleAtt(e, "drug_Alcohol_Policy")} /> */}

                                            <LAGrid>
                                                {audit.drug_Alcohol_Policy_File_Name && audit.drug_Alcohol_Policy_File_Name.split(";").map((fName: string, ind) => {
                                                    const onAttFileDownload = (): void => this.handleDownloadAtt("Drug_Alcohol_Policy", fName, audit.Drug_Alcohol_Policy_Files);
                                                    const onRemove = (event: any): void => this.handleDeleteAtt(fName, "drug_Alcohol_Policy")
                                                    return (
                                                        <LAGridItem key={ind} xs={6}>

                                                            <LALinkButton
                                                                className="pl-5"
                                                                label={fName}
                                                                onClick={onAttFileDownload}
                                                            />

                                                            {/* <LAIconButton
                                                                name={fName}
                                                                label="Remove"
                                                                icon={<DeleteIcon />}
                                                                onClick={onRemove}
                                                            /> */}
                                                        </LAGridItem>
                                                    )
                                                })}
                                            </LAGrid>
                                        </LAGridItem>

                                    </LAGrid>
                                </LAExpansionPanel>
                            </LAGridItem>

                            <LAGridItem xs={12}>
                                <LAExpansionPanel color={LIGHT_BLUE_COLOR} label="Does the carrier have a seatbelt policy? Please submit copy of policy" expanded={seatbeltPolicyExpand} onClick={() => this.handleExpand("seatbeltPolicyExpand")}>
                                    <LAGrid>
                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="Seatbelt Policy"
                                                getOptionLabel="name"
                                                name="seatbelt_Policy"
                                                option={yesOrNoOptions}
                                                multiple={false}
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.seatbelt_Policy ? yesOrNoOptions.find(x => x.name === audit.seatbelt_Policy) : null}
                                                defaultValue={audit.seatbelt_Policy ? yesOrNoOptions.find(x => x.name === audit.seatbelt_Policy) : null}
                                                errorText={errors["seatbelt_Policy"] ? errors["seatbelt_Policy"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LATextArea
                                                rowsMax={10}
                                                label="Seatbelt Comments"
                                                fullWidth={true}
                                                name="seatbelt_Comments"
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.seatbelt_Comments}
                                                errorText={errors["seatbelt_Comments"] ? errors["seatbelt_Comments"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={6} className="text-center">
                                            <strong>Upload Seatbelt Policy</strong>
                                            {/* <LADropDone onFileDrop={(e: Array<File | DataTransferItem>) => this.handleAtt(e, "seatbelt_Policy")} /> */}

                                            <LAGrid>
                                                {audit.seatbelt_Policy_File_Name && audit.seatbelt_Policy_File_Name.split(";").map((fName: string, ind) => {
                                                    const onAttFileDownload = (): void => this.handleDownloadAtt("Seatbelt_Policy", fName, audit.Seatbelt_Policy_Files);
                                                    const onRemove = (event: any): void => this.handleDeleteAtt(fName, "seatbelt_Policy")
                                                    return (
                                                        <LAGridItem key={ind} xs={6}>

                                                            <LALinkButton
                                                                className="pl-5"
                                                                label={fName}
                                                                onClick={onAttFileDownload}
                                                            />

                                                            {/* <LAIconButton
                                                                name={fName}
                                                                label="Remove"
                                                                icon={<DeleteIcon />}
                                                                onClick={onRemove}
                                                            /> */}
                                                        </LAGridItem>
                                                    )
                                                })}
                                            </LAGrid>
                                        </LAGridItem>

                                    </LAGrid>
                                </LAExpansionPanel>
                            </LAGridItem>

                            <LAGridItem xs={12}>
                                <LAExpansionPanel color={LIGHT_BLUE_COLOR} label="Does the carrier have a policy about drivers abstracts? Please submit copy" expanded={driversAbstractsExpand} onClick={() => this.handleExpand("driversAbstractsExpand")}>
                                    <LAGrid>
                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="Driver Abstracts Policy"
                                                getOptionLabel="name"
                                                name="driver_Abstracts"
                                                option={yesOrNoOptions}
                                                multiple={false}
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.driver_Abstracts ? yesOrNoOptions.find(x => x.name === audit.driver_Abstracts) : null}
                                                defaultValue={audit.driver_Abstracts ? yesOrNoOptions.find(x => x.name === audit.driver_Abstracts) : null}
                                                errorText={errors["driver_Abstracts"] ? errors["driver_Abstracts"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={6} className="text-center">
                                            <strong>Upload Abstracts Policy</strong>
                                            {/* <LADropDone onFileDrop={(e: Array<File | DataTransferItem>) => this.handleAtt(e, "driver_Abstracts_Policy")} /> */}

                                            <LAGrid>
                                                {audit.driver_Abstracts_Policy_File_Name && audit.driver_Abstracts_Policy_File_Name.split(";").map((fName: string, ind) => {
                                                    const onAttFileDownload = (): void => this.handleDownloadAtt("Driver_Abstracts_Policy", fName, audit.Driver_Abstracts_Policy_Files);
                                                    const onRemove = (event: any): void => this.handleDeleteAtt(fName, "driver_Abstracts_Policy")
                                                    return (
                                                        <LAGridItem key={ind} xs={6}>

                                                            <LALinkButton
                                                                className="pl-5"
                                                                label={fName}
                                                                onClick={onAttFileDownload}
                                                            />

                                                            {/* <LAIconButton
                                                                name={fName}
                                                                label="Remove"
                                                                icon={<DeleteIcon />}
                                                                onClick={onRemove}
                                                            /> */}
                                                        </LAGridItem>
                                                    )
                                                })}
                                            </LAGrid>
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="No more than 6 demerits"
                                                getOptionLabel="name"
                                                name="_6_Demerits"
                                                option={yesOrNoOptions}
                                                multiple={false}
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit._6_Demerits ? yesOrNoOptions.find(x => x.name === audit._6_Demerits) : null}
                                                defaultValue={audit._6_Demerits ? yesOrNoOptions.find(x => x.name === audit._6_Demerits) : null}
                                                errorText={errors["_6_Demerits"] ? errors["_6_Demerits"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="Free from any alcohol or drug related offenses"
                                                getOptionLabel="name"
                                                name="alcohol_Free"
                                                option={yesOrNoOptions}
                                                multiple={false}
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.alcohol_Free ? yesOrNoOptions.find(x => x.name === audit.alcohol_Free) : null}
                                                defaultValue={audit.alcohol_Free ? yesOrNoOptions.find(x => x.name === audit.alcohol_Free) : null}
                                                errorText={errors["alcohol_Free"] ? errors["alcohol_Free"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="PDIC in the last 3 years"
                                                getOptionLabel="name"
                                                name="pdic"
                                                option={yesOrNoOptions}
                                                multiple={false}
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.pdic ? yesOrNoOptions.find(x => x.name === audit.pdic) : null}
                                                defaultValue={audit.pdic ? yesOrNoOptions.find(x => x.name === audit.pdic) : null}
                                                errorText={errors["pdic"] ? errors["pdic"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="How often are abstracts checked"
                                                getOptionLabel="name"
                                                name="abstracts_Frequency"
                                                option={abstractsFrequency}
                                                multiple={false}
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.abstracts_Frequency ? abstractsFrequency.find(x => x.name === audit.abstracts_Frequency) : null}
                                                defaultValue={audit.abstracts_Frequency ? abstractsFrequency.find(x => x.name === audit.abstracts_Frequency) : null}
                                                errorText={errors["abstracts_Frequency"] ? errors["abstracts_Frequency"].message : undefined}
                                            />
                                        </LAGridItem>
                                    </LAGrid>
                                </LAExpansionPanel>
                            </LAGridItem>

                            <LAGridItem xs={12}>
                                <LAExpansionPanel color={LIGHT_BLUE_COLOR} label="Does the carrier's vehicles have:" expanded={vehiclesExpand} onClick={() => this.handleExpand("vehiclesExpand")}>
                                    <LAGrid>
                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="Reversing Alarms"
                                                getOptionLabel="name"
                                                name="reversing_Alarms"
                                                option={yesOrNoOptions}
                                                multiple={false}
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.reversing_Alarms ? yesOrNoOptions.find(x => x.name === audit.reversing_Alarms) : null}
                                                defaultValue={audit.reversing_Alarms ? yesOrNoOptions.find(x => x.name === audit.reversing_Alarms) : null}
                                                errorText={errors["reversing_Alarms"] ? errors["reversing_Alarms"].message : undefined}
                                            />
                                        </LAGridItem>

                                        {/* <LAGridItem xs={12} sm={6} md={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="PDIC in the last 3 years"
                                                getOptionLabel="name"
                                                name="pdic"
                                                option={yesOrNoOptions}
                                                multiple={false}
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.pdic ? yesOrNoOptions.find(x => x.name === audit.pdic) : null}
                                                defaultValue={audit.pdic ? yesOrNoOptions.find(x => x.name === audit.pdic) : null}
                                                errorText={errors["pdic"] ? errors["pdic"].message : undefined}
                                            />
                                        </LAGridItem> */}

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="Wheel Chocks"
                                                getOptionLabel="name"
                                                name="wheel_Chocks"
                                                option={yesOrNoOptions}
                                                multiple={false}
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.wheel_Chocks ? yesOrNoOptions.find(x => x.name === audit.wheel_Chocks) : null}
                                                defaultValue={audit.wheel_Chocks ? yesOrNoOptions.find(x => x.name === audit.wheel_Chocks) : null}
                                                errorText={errors["wheel_Chocks"] ? errors["wheel_Chocks"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="Anti-lock Brakes"
                                                getOptionLabel="name"
                                                name="anti_lock_brakes"
                                                option={yesOrNoOptions}
                                                multiple={false}
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.anti_lock_brakes ? yesOrNoOptions.find(x => x.name === audit.anti_lock_brakes) : null}
                                                defaultValue={audit.anti_lock_brakes ? yesOrNoOptions.find(x => x.name === audit.anti_lock_brakes) : null}
                                                errorText={errors["anti_lock_brakes"] ? errors["anti_lock_brakes"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="IVMS"
                                                getOptionLabel="name"
                                                name="ivms"
                                                option={yesOrNoOptions}
                                                multiple={false}
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.ivms ? yesOrNoOptions.find(x => x.name === audit.ivms) : null}
                                                defaultValue={audit.ivms ? yesOrNoOptions.find(x => x.name === audit.ivms) : null}
                                                errorText={errors["ivms"] ? errors["ivms"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="Radio Communication"
                                                getOptionLabel="name"
                                                name="radio_Communication"
                                                option={yesOrNoOptions}
                                                multiple={false}
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.radio_Communication ? yesOrNoOptions.find(x => x.name === audit.radio_Communication) : null}
                                                defaultValue={audit.radio_Communication ? yesOrNoOptions.find(x => x.name === audit.radio_Communication) : null}
                                                errorText={errors["radio_Communication"] ? errors["radio_Communication"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="Spill Kit"
                                                getOptionLabel="name"
                                                name="spill_Kit"
                                                option={yesOrNoOptions}
                                                multiple={false}
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.spill_Kit ? yesOrNoOptions.find(x => x.name === audit.spill_Kit) : null}
                                                defaultValue={audit.spill_Kit ? yesOrNoOptions.find(x => x.name === audit.spill_Kit) : null}
                                                errorText={errors["spill_Kit"] ? errors["spill_Kit"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="Emergency Survival Kit"
                                                getOptionLabel="name"
                                                name="emergency_Survival_Kit"
                                                option={yesOrNoOptions}
                                                multiple={false}
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.emergency_Survival_Kit ? yesOrNoOptions.find(x => x.name === audit.emergency_Survival_Kit) : null}
                                                defaultValue={audit.emergency_Survival_Kit ? yesOrNoOptions.find(x => x.name === audit.emergency_Survival_Kit) : null}
                                                errorText={errors["emergency_Survival_Kit"] ? errors["emergency_Survival_Kit"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LATextArea
                                                rowsMax={10}
                                                label="Vehicle Comments"
                                                fullWidth={true}
                                                name="vehicle_Comments"
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.vehicle_Comments}
                                                errorText={errors["vehicle_Comments"] ? errors["vehicle_Comments"].message : undefined}
                                            />
                                        </LAGridItem>
                                    </LAGrid>
                                </LAExpansionPanel>
                            </LAGridItem>

                            <LAGridItem xs={12}>
                                <LAExpansionPanel color={LIGHT_BLUE_COLOR} label="Does the carrier have a cell phone policy?" expanded={cellPhonePolicyExpand} onClick={() => this.handleExpand("cellPhonePolicyExpand")}>
                                    <LAGrid>
                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="Cell Phone Policy"
                                                getOptionLabel="name"
                                                name="cell_Phone_Policy"
                                                option={yesOrNoOptions}
                                                multiple={false}
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.cell_Phone_Policy ? yesOrNoOptions.find(x => x.name === audit.cell_Phone_Policy) : null}
                                                defaultValue={audit.cell_Phone_Policy ? yesOrNoOptions.find(x => x.name === audit.cell_Phone_Policy) : null}
                                                errorText={errors["cell_Phone_Policy"] ? errors["cell_Phone_Policy"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LATextArea
                                                rowsMax={10}
                                                label="Cell Phone Comments"
                                                fullWidth={true}
                                                name="cell_Phone_Comments"
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.cell_Phone_Comments}
                                                errorText={errors["cell_Phone_Comments"] ? errors["cell_Phone_Comments"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={6} className="text-center">
                                            <strong>Upload Cell Phone Policy</strong>
                                            {/* <LADropDone onFileDrop={(e: Array<File | DataTransferItem>) => this.handleAtt(e, "cell_Phone_Policy")} /> */}

                                            <LAGrid>
                                                {audit.cell_Phone_Policy_File_Name && audit.cell_Phone_Policy_File_Name.split(";").map((fName: string, ind) => {
                                                    const onAttFileDownload = (): void => this.handleDownloadAtt("Cell_Phone_Policy", fName, audit.Cell_Phone_Policy_Files);
                                                    const onRemove = (event: any): void => this.handleDeleteAtt(fName, "cell_Phone_Policy")
                                                    return (
                                                        <LAGridItem key={ind} xs={6}>

                                                            <LALinkButton
                                                                className="pl-5"
                                                                label={fName}
                                                                onClick={onAttFileDownload}
                                                            />

                                                            {/* <LAIconButton
                                                                name={fName}
                                                                label="Remove"
                                                                icon={<DeleteIcon />}
                                                                onClick={onRemove}
                                                            /> */}
                                                        </LAGridItem>
                                                    )
                                                })}
                                            </LAGrid>
                                        </LAGridItem>

                                    </LAGrid>
                                </LAExpansionPanel>
                            </LAGridItem>

                            <LAGridItem xs={12}>
                                <LAExpansionPanel color={LIGHT_BLUE_COLOR} label="Does the carrier have a Distracted Driving policy? Please submit policy" expanded={distractedDrivingPolicyExpand} onClick={() => this.handleExpand("distractedDrivingPolicyExpand")}>
                                    <LAGrid>
                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="Distracted Driving Policy"
                                                getOptionLabel="name"
                                                name="distracted_Driving_Policy"
                                                option={yesOrNoOptions}
                                                multiple={false}
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.distracted_Driving_Policy ? yesOrNoOptions.find(x => x.name === audit.distracted_Driving_Policy) : null}
                                                defaultValue={audit.distracted_Driving_Policy ? yesOrNoOptions.find(x => x.name === audit.distracted_Driving_Policy) : null}
                                                errorText={errors["distracted_Driving_Policy"] ? errors["distracted_Driving_Policy"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LATextArea
                                                rowsMax={10}
                                                label="Distracted Driving Comments"
                                                fullWidth={true}
                                                name="distracted_Driving_Comments"
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.distracted_Driving_Comments}
                                                errorText={errors["distracted_Driving_Comments"] ? errors["distracted_Driving_Comments"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={6} className="text-center">
                                            <strong>Upload Distract Driver Policy</strong>
                                            {/* <LADropDone onFileDrop={(e: Array<File | DataTransferItem>) => this.handleAtt(e, "distract_Driving_Policy")} /> */}

                                            <LAGrid>
                                                {audit.distract_Driving_Policy_File_Name && audit.distract_Driving_Policy_File_Name.split(";").map((fName: string, ind) => {
                                                    const onAttFileDownload = (): void => this.handleDownloadAtt("Distract_Driving_Policy", fName, audit.Distract_Driving_Policy_Files);
                                                    const onRemove = (event: any): void => this.handleDeleteAtt(fName, "distract_Driving_Policy")
                                                    return (
                                                        <LAGridItem key={ind} xs={6}>

                                                            <LALinkButton
                                                                className="pl-5"
                                                                label={fName}
                                                                onClick={onAttFileDownload}
                                                            />

                                                            {/* <LAIconButton
                                                                name={fName}
                                                                label="Remove"
                                                                icon={<DeleteIcon />}
                                                                onClick={onRemove}
                                                            /> */}
                                                        </LAGridItem>
                                                    )
                                                })}
                                            </LAGrid>
                                        </LAGridItem>

                                    </LAGrid>
                                </LAExpansionPanel>
                            </LAGridItem>

                            <LAGridItem xs={12}>
                                <LAExpansionPanel color={LIGHT_BLUE_COLOR} label="Does the carrier have a maintenance policy? Please submit copy of policy" expanded={maintenancePolicyExpand} onClick={() => this.handleExpand("maintenancePolicyExpand")}>
                                    <LAGrid>
                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="Maintenance Policy"
                                                getOptionLabel="name"
                                                name="maintenance_Policy"
                                                option={yesOrNoOptions}
                                                multiple={false}
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.maintenance_Policy ? yesOrNoOptions.find(x => x.name === audit.maintenance_Policy) : null}
                                                defaultValue={audit.maintenance_Policy ? yesOrNoOptions.find(x => x.name === audit.maintenance_Policy) : null}
                                                errorText={errors["maintenance_Policy"] ? errors["maintenance_Policy"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LATextField
                                                label="'A' Service Intervals"
                                                fullWidth={true}
                                                name="a_Service_Intervals"
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.a_Service_Intervals}
                                                errorText={errors["a_Service_Intervals"] ? errors["a_Service_Intervals"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LATextField
                                                label="'B' Service Intervals"
                                                fullWidth={true}
                                                name="b_Service_Intervals"
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.b_Service_Intervals}
                                                errorText={errors["b_Service_Intervals"] ? errors["b_Service_Intervals"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LATextField
                                                label="Current CVIP"
                                                fullWidth={true}
                                                name="current_CVIP"
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.current_CVIP}
                                                errorText={errors["current_CVIP"] ? errors["current_CVIP"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LATextField
                                                label="How is this tracked ?"
                                                fullWidth={true}
                                                name="how_Tracked"
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.how_Tracked}
                                                errorText={errors["how_Tracked"] ? errors["how_Tracked"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LATextArea
                                                rowsMax={8}
                                                label="Maintenance Comments"
                                                fullWidth={true}
                                                name="maintenance_Comments"
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.maintenance_Comments}
                                                errorText={errors["maintenance_Comments"] ? errors["maintenance_Comments"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={6} className="text-center">
                                            <strong>Upload Maintenance Policy</strong>
                                            {/* <LADropDone onFileDrop={(e: Array<File | DataTransferItem>) => this.handleAtt(e, "maintenance_Policy")} /> */}

                                            <LAGrid>
                                                {audit.maintenance_Policy_File_Name && audit.maintenance_Policy_File_Name.split(";").map((fName: string, ind) => {
                                                    const onAttFileDownload = (): void => this.handleDownloadAtt("Maintenance_Policy", fName, audit.Maintenance_Policy_Files);
                                                    const onRemove = (event: any): void => this.handleDeleteAtt(fName, "maintenance_Policy")
                                                    return (
                                                        <LAGridItem key={ind} xs={6}>

                                                            <LALinkButton
                                                                className="pl-5"
                                                                label={fName}
                                                                onClick={onAttFileDownload}
                                                            />

                                                            {/* <LAIconButton
                                                                name={fName}
                                                                label="Remove"
                                                                icon={<DeleteIcon />}
                                                                onClick={onRemove}
                                                            /> */}
                                                        </LAGridItem>
                                                    )
                                                })}
                                            </LAGrid>
                                        </LAGridItem>

                                    </LAGrid>
                                </LAExpansionPanel>
                            </LAGridItem>

                            <LAGridItem xs={12}>
                                <LAExpansionPanel color={LIGHT_BLUE_COLOR} label="Does the carrier have a Speed Limit policy? Please submit copy of policy" expanded={speedLimitPolicyExpand} onClick={() => this.handleExpand("speedLimitPolicyExpand")}>
                                    <LAGrid>
                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="Speed Limit Policy"
                                                getOptionLabel="name"
                                                name="speed_Limit_Policy"
                                                option={yesOrNoOptions}
                                                multiple={false}
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.speed_Limit_Policy ? yesOrNoOptions.find(x => x.name === audit.speed_Limit_Policy) : null}
                                                defaultValue={audit.speed_Limit_Policy ? yesOrNoOptions.find(x => x.name === audit.speed_Limit_Policy) : null}
                                                errorText={errors["speed_Limit_Policy"] ? errors["speed_Limit_Policy"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LATextArea
                                                rowsMax={8}
                                                label="Speed Limit Comments"
                                                fullWidth={true}
                                                name="speed_Limit_Comments"
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.speed_Limit_Comments}
                                                errorText={errors["speed_Limit_Comments"] ? errors["speed_Limit_Comments"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={6} className="text-center">
                                            <strong>Upload Speed Limit Policy</strong>
                                            {/* <LADropDone onFileDrop={(e: Array<File | DataTransferItem>) => this.handleAtt(e, "speed_Limit_Policy")} /> */}

                                            <LAGrid>
                                                {audit.speed_Limit_Policy_File_Name && audit.speed_Limit_Policy_File_Name.split(";").map((fName: string, ind) => {
                                                    const onAttFileDownload = (): void => this.handleDownloadAtt("Speed_Limit_Policy", fName, audit.Speed_Limit_Policy_Files);
                                                    const onRemove = (event: any): void => this.handleDeleteAtt(fName, "speed_Limit_Policy")
                                                    return (
                                                        <LAGridItem key={ind} xs={6}>

                                                            <LALinkButton
                                                                className="pl-5"
                                                                label={fName}
                                                                onClick={onAttFileDownload}
                                                            />

                                                            {/* <LAIconButton
                                                                name={fName}
                                                                label="Remove"
                                                                icon={<DeleteIcon />}
                                                                onClick={onRemove}
                                                            /> */}
                                                        </LAGridItem>
                                                    )
                                                })}
                                            </LAGrid>
                                        </LAGridItem>

                                    </LAGrid>
                                </LAExpansionPanel>
                            </LAGridItem>

                            <LAGridItem xs={12}>
                                <LAExpansionPanel color={LIGHT_BLUE_COLOR} label="Does the carrier have a Defensive Driving Policy? Please submit policy" expanded={defensiveDrivingPolicyExpand} onClick={() => this.handleExpand("defensiveDrivingPolicyExpand")}>
                                    <LAGrid>
                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="Defensive Driving Policy"
                                                getOptionLabel="name"
                                                name="defensive_Driving_Policy"
                                                option={yesOrNoOptions}
                                                multiple={false}
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.defensive_Driving_Policy ? yesOrNoOptions.find(x => x.name === audit.defensive_Driving_Policy) : null}
                                                defaultValue={audit.defensive_Driving_Policy ? yesOrNoOptions.find(x => x.name === audit.defensive_Driving_Policy) : null}
                                                errorText={errors["defensive_Driving_Policy"] ? errors["defensive_Driving_Policy"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LATextArea
                                                rowsMax={8}
                                                label="Defensive Driving Comments"
                                                fullWidth={true}
                                                name="defensive_Driving_Comments"
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.defensive_Driving_Comments}
                                                errorText={errors["defensive_Driving_Comments"] ? errors["defensive_Driving_Comments"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={6} className="text-center">
                                            <strong>Upload Defensive Policy</strong>
                                            {/* <LADropDone onFileDrop={(e: Array<File | DataTransferItem>) => this.handleAtt(e, "defensive_Driving_Policy")} /> */}

                                            <LAGrid>
                                                {audit.defensive_Driving_Policy_File_Name && audit.defensive_Driving_Policy_File_Name.split(";").map((fName: string, ind) => {
                                                    const onAttFileDownload = (): void => this.handleDownloadAtt("Defensive_Driving_Policy", fName, audit.Defensive_Driving_Policy_Files);
                                                    const onRemove = (event: any): void => this.handleDeleteAtt(fName, "defensive_Driving_Policy")
                                                    return (
                                                        <LAGridItem key={ind} xs={6}>
                                                            <LALinkButton
                                                                className="pl-5"
                                                                label={fName}
                                                                onClick={onAttFileDownload}
                                                            />

                                                            {/* <LAIconButton
                                                                name={fName}
                                                                label="Remove"
                                                                icon={<DeleteIcon />}
                                                                onClick={onRemove}
                                                            /> */}
                                                        </LAGridItem>
                                                    )
                                                })}
                                            </LAGrid>
                                        </LAGridItem>

                                    </LAGrid>
                                </LAExpansionPanel>
                            </LAGridItem>

                            <LAGridItem xs={12}>
                                <LAExpansionPanel color={LIGHT_BLUE_COLOR} label="Does the carrier have a Fueling policy? Please submit policy" expanded={fuelingPolicyExpand} onClick={() => this.handleExpand("fuelingPolicyExpand")}>
                                    <LAGrid>
                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="Fueling Policy"
                                                getOptionLabel="name"
                                                name="fueling_Policy"
                                                option={yesOrNoOptions}
                                                multiple={false}
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.fueling_Policy ? yesOrNoOptions.find(x => x.name === audit.fueling_Policy) : null}
                                                defaultValue={audit.fueling_Policy ? yesOrNoOptions.find(x => x.name === audit.fueling_Policy) : null}
                                                errorText={errors["fueling_Policy"] ? errors["fueling_Policy"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LATextArea
                                                rowsMax={8}
                                                label="Fueling Comments"
                                                fullWidth={true}
                                                name="fueling_Comments"
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.fueling_Comments}
                                                errorText={errors["fueling_Comments"] ? errors["fueling_Comments"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={6} className="text-center">
                                            <strong>Upload Fueling Policy</strong>
                                            {/* <LADropDone onFileDrop={(e: Array<File | DataTransferItem>) => this.handleAtt(e, "fueling_Policy")} /> */}

                                            <LAGrid>
                                                {audit.fueling_Policy_File_Name && audit.fueling_Policy_File_Name.split(";").map((fName: string, ind) => {
                                                    const onAttFileDownload = (): void => this.handleDownloadAtt("Fueling_Policy", fName, audit.Fueling_Policy_Files);
                                                    const onRemove = (event: any): void => this.handleDeleteAtt(fName, "fueling_Policy")
                                                    return (
                                                        <LAGridItem key={ind} xs={6}>

                                                            <LALinkButton
                                                                className="pl-5"
                                                                label={fName}
                                                                onClick={onAttFileDownload}
                                                            />

                                                            {/* <LAIconButton
                                                                name={fName}
                                                                label="Remove"
                                                                icon={<DeleteIcon />}
                                                                onClick={onRemove}
                                                            /> */}
                                                        </LAGridItem>
                                                    )
                                                })}
                                            </LAGrid>
                                        </LAGridItem>

                                    </LAGrid>
                                </LAExpansionPanel>
                            </LAGridItem>

                            <LAGridItem xs={12}>
                                <LAExpansionPanel color={LIGHT_BLUE_COLOR} label="Driver Training" expanded={driverTrainingExpand} onClick={() => this.handleExpand("driverTrainingExpand")}>
                                    <LAGrid>
                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="First Aid"
                                                getOptionLabel="name"
                                                name="first_Aid"
                                                option={yesOrNoOptions}
                                                multiple={false}
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.first_Aid ? yesOrNoOptions.find(x => x.name === audit.first_Aid) : null}
                                                defaultValue={audit.first_Aid ? yesOrNoOptions.find(x => x.name === audit.first_Aid) : null}
                                                errorText={errors["first_Aid"] ? errors["first_Aid"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="H2S Alive"
                                                getOptionLabel="name"
                                                name="h2S_Alive"
                                                option={yesOrNoOptions}
                                                multiple={false}
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.h2S_Alive ? yesOrNoOptions.find(x => x.name === audit.h2S_Alive) : null}
                                                defaultValue={audit.h2S_Alive ? yesOrNoOptions.find(x => x.name === audit.h2S_Alive) : null}
                                                errorText={errors["h2S_Alive"] ? errors["h2S_Alive"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="Bear Awareness"
                                                getOptionLabel="name"
                                                name="bear_Awareness"
                                                option={yesOrNoOptions}
                                                multiple={false}
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.bear_Awareness ? yesOrNoOptions.find(x => x.name === audit.bear_Awareness) : null}
                                                defaultValue={audit.bear_Awareness ? yesOrNoOptions.find(x => x.name === audit.bear_Awareness) : null}
                                                errorText={errors["bear_Awareness"] ? errors["bear_Awareness"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="Oilfield Driver Awareness"
                                                getOptionLabel="name"
                                                name="oilfield_Driver_Awareness"
                                                option={yesOrNoOptions}
                                                multiple={false}
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.oilfield_Driver_Awareness ? yesOrNoOptions.find(x => x.name === audit.oilfield_Driver_Awareness) : null}
                                                defaultValue={audit.oilfield_Driver_Awareness ? yesOrNoOptions.find(x => x.name === audit.oilfield_Driver_Awareness) : null}
                                                errorText={errors["oilfield_Driver_Awareness"] ? errors["oilfield_Driver_Awareness"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="Hours of Service"
                                                getOptionLabel="name"
                                                name="service_Hours"
                                                option={yesOrNoOptions}
                                                multiple={false}
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.insurance ? yesOrNoOptions.find(x => x.name === audit.service_Hours) : null}
                                                defaultValue={audit.insurance ? yesOrNoOptions.find(x => x.name === audit.service_Hours) : null}
                                                errorText={errors["service_Hours"] ? errors["service_Hours"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="Cargo Securement"
                                                getOptionLabel="name"
                                                name="cargo_Securement"
                                                option={yesOrNoOptions}
                                                multiple={false}
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.cargo_Securement ? yesOrNoOptions.find(x => x.name === audit.cargo_Securement) : null}
                                                defaultValue={audit.cargo_Securement ? yesOrNoOptions.find(x => x.name === audit.cargo_Securement) : null}
                                                errorText={errors["cargo_Securement"] ? errors["cargo_Securement"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="Pre/Post Trip"
                                                getOptionLabel="name"
                                                name="pre_Post_Trip"
                                                option={yesOrNoOptions}
                                                multiple={false}
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.pre_Post_Trip ? yesOrNoOptions.find(x => x.name === audit.pre_Post_Trip) : null}
                                                defaultValue={audit.pre_Post_Trip ? yesOrNoOptions.find(x => x.name === audit.pre_Post_Trip) : null}
                                                errorText={errors["pre_Post_Trip"] ? errors["pre_Post_Trip"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="TDG"
                                                getOptionLabel="name"
                                                name="tdg"
                                                option={yesOrNoOptions}
                                                multiple={false}
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.tdg ? yesOrNoOptions.find(x => x.name === audit.tdg) : null}
                                                defaultValue={audit.tdg ? yesOrNoOptions.find(x => x.name === audit.tdg) : null}
                                                errorText={errors["tdg"] ? errors["tdg"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="CSO"
                                                getOptionLabel="name"
                                                name="cso"
                                                option={yesOrNoOptions}
                                                multiple={false}
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.cso ? yesOrNoOptions.find(x => x.name === audit.cso) : null}
                                                defaultValue={audit.cso ? yesOrNoOptions.find(x => x.name === audit.cso) : null}
                                                errorText={errors["cso"] ? errors["cso"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="WHMIS ?"
                                                getOptionLabel="name"
                                                name="whmis"
                                                option={yesOrNoOptions}
                                                multiple={false}
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.whmis ? yesOrNoOptions.find(x => x.name === audit.whmis) : null}
                                                defaultValue={audit.whmis ? yesOrNoOptions.find(x => x.name === audit.whmis) : null}
                                                errorText={errors["whmis"] ? errors["whmis"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LATextArea
                                                rowsMax={8}
                                                label="Traning Comments"
                                                fullWidth={true}
                                                name="training_Comments"
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.training_Comments}
                                                errorText={errors["training_Comments"] ? errors["training_Comments"].message : undefined}
                                            />
                                        </LAGridItem>
                                    </LAGrid>
                                </LAExpansionPanel>
                            </LAGridItem>

                            <LAGridItem xs={12}>
                                <LAExpansionPanel color={LIGHT_BLUE_COLOR} label="Safety Fitness. Please submit Certificate" expanded={safetyFitnessExpand} onClick={() => this.handleExpand("safetyFitnessExpand")}>
                                    <LAGrid>
                                        <LAGridItem xs={12} sm={6} md={4} className="text-center">
                                            <LADateTimePicker
                                                name="safety_Fitness_Expiry_Date"
                                                label="Date"
                                                fullWidth={true}
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.safety_Fitness_Expiry_Date ?? ""}
                                                errorText={errors && errors["safety_Fitness_Expiry_Date"] ? errors["safety_Fitness_Expiry_Date"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={6} className="text-center">
                                            <strong>Upload Safety Fitness Certificate</strong>
                                            {/* <LADropDone onFileDrop={(e: Array<File | DataTransferItem>) => this.handleAtt(e, "safety_Fitness_Certificate")} /> */}

                                            <LAGrid>
                                                {audit.safety_Fitness_Certificate_File_Name && audit.safety_Fitness_Certificate_File_Name.split(";").map((fName: string, ind) => {
                                                    const onAttFileDownload = (): void => this.handleDownloadAtt("Safety_Fitness_Certificate", fName, audit.Safety_Fitness_Certificate_Files);
                                                    const onRemove = (event: any): void => this.handleDeleteAtt(fName, "safety_Fitness_Certificate")
                                                    return (
                                                        <LAGridItem key={ind} xs={6}>

                                                            <LALinkButton
                                                                className="pl-5"
                                                                label={fName}
                                                                onClick={onAttFileDownload}
                                                            />

                                                            {/* <LAIconButton
                                                                name={fName}
                                                                label="Remove"
                                                                icon={<DeleteIcon />}
                                                                onClick={onRemove}
                                                            /> */}
                                                        </LAGridItem>
                                                    )
                                                })}
                                            </LAGrid>
                                        </LAGridItem>

                                    </LAGrid>
                                </LAExpansionPanel>
                            </LAGridItem>

                            <LAGridItem xs={12}>
                                <LAExpansionPanel color={LIGHT_BLUE_COLOR} label="Signature" expanded={signatureExpand} onClick={() => this.handleExpand("signatureExpand")}>
                                    <LAGrid>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LATextField
                                                label="Carrier Printed Name"
                                                name="carrier_Printed_Name"
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.carrier_Printed_Name}
                                                fullWidth={true}
                                                errorText={errors["carrier_Printed_Name"] ? errors["carrier_Printed_Name"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4} className="text-center">
                                            <LADateTimePicker
                                                name="audit_Completion_Date"
                                                label="Date"
                                                fullWidth={true}
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={audit.audit_Completion_Date ?? ""}
                                                errorText={errors && errors["audit_Completion_Date"] ? errors["audit_Completion_Date"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12}>
                                            {audit.carrier_Signature && (
                                                <div>
                                                    <img src={audit.carrier_Signature} alt="signature" />
                                                </div>
                                            )}
                                        </LAGridItem>

                                    </LAGrid>
                                </LAExpansionPanel>
                            </LAGridItem>

                            <LAGridItem xs={12}>
                                <LAExpansionPanel color={LIGHT_BLUE_COLOR} label="Approval" expanded={approvalExpand} onClick={() => this.handleExpand("approvalExpand")}>
                                    <LAGrid>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="Carrier Profile Reviewed"
                                                getOptionLabel="name"
                                                name="carrier_Profile_Reviewed"
                                                option={yesOrNoOptions}
                                                multiple={false}
                                                disabled={undefined}
                                                onChange={this.handleDropDownChange}
                                                value={audit && audit.carrier_Profile_Reviewed
                                                    ? yesOrNoOptions.find(x => x.name === audit.carrier_Profile_Reviewed) : null}
                                                defaultValue={audit && audit.carrier_Profile_Reviewed
                                                    ? yesOrNoOptions.find(x => x.name === audit.carrier_Profile_Reviewed) : null}
                                                errorText={errors["carrier_Profile_Reviewed"] ? errors["carrier_Profile_Reviewed"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={12} md={8}>
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LACheckBox
                                                label="Audited"
                                                name="audited"
                                                className="red-dot p-2"
                                                onChange={this.checkBoxClick}
                                                value={audit.audited === "Yes" ? true : false}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LACheckBox
                                                label="Unaudited"
                                                name="unaudited"
                                                className="red-dot p-2"
                                                onChange={this.checkBoxClick}
                                                value={audit.unaudited === "Yes" ? true : false}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LACheckBox
                                                label="R Factor"
                                                name="r_Factor"
                                                className="red-dot p-2"
                                                onChange={this.checkBoxClick}
                                                value={audit.r_Factor === "Yes" ? true : false}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LATextArea
                                                rowsMax={8}
                                                label="Profile Comments"
                                                name="profile_Comments"
                                                onChange={this.onChange}
                                                value={audit.profile_Comments}
                                                disabled={undefined}
                                                fullWidth={true}
                                                errorText={errors["profile_Comments"] ? errors["profile_Comments"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="Audit Approved"
                                                getOptionLabel="name"
                                                name="audit"
                                                option={yesOrNoOptions}
                                                multiple={false}
                                                disabled={undefined}
                                                onChange={this.handleDropDownChange}
                                                value={audit && audit.audit ? yesOrNoOptions.find(x => x.name === audit.audit) : null}
                                                defaultValue={audit && audit.audit ? yesOrNoOptions.find(x => x.name === audit.audit) : null}
                                                errorText={errors["audit"] ? errors["audit"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LACheckBox
                                                label="Awaiting Paper Work"
                                                name="awaiting_Paper_Work"
                                                className="red-dot p-2"
                                                onChange={this.checkBoxClick}
                                                value={audit.awaiting_Paper_Work === "Yes" ? true : false}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LATextArea
                                                rowsMax={8}
                                                fullWidth={true}
                                                onChange={this.onChange}
                                                disabled={undefined}
                                                label="Awaiting Paper Work Comments"
                                                name="awaiting_Paper_Work_Comments"
                                                value={audit.awaiting_Paper_Work_Comments}
                                                errorText={errors["awaiting_Paper_Work_Comments"] ? errors["awaiting_Paper_Work_Comments"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12}>                                        
                                            {!audit.auditor_Signature && <>
                                                <span className="required">After review, please sign below and click on the save signature button!</span>
                                                <br />
                                            </>}

                                            <div className={`signature-container ${!audit.auditor_Signature ? "required-sign" : "sign"}`}>
                                                <SignatureCanvas
                                                    penColor="black"
                                                    ref={this.sigCanvas}
                                                    canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
                                                />
                                            </div>

                                            <br />
                                            <LAButton
                                                className="mt-2"
                                                label="Save Signature"
                                                onClick={this.saveSignature}
                                            />

<LAButton
                                                className="ml-1 mt-2"
                                                label="Clear"
                                                onClick={this.clearSignature}
                                            />

                                            <br />
                                            <br />
                                            {audit.auditor_Signature && (
                                                <div>
                                                    <img src={audit.auditor_Signature} alt="signature" />
                                                </div>
                                            )}
                                        </LAGridItem>

                                    </LAGrid>
                                </LAExpansionPanel>
                            </LAGridItem>

                            <hr />
                            {audit.id && audit.id > 0 ? <LAGrid>
                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="created"
                                        label="Created"
                                        varient="outlined"
                                        disabled={true}
                                        value={audit.created ? new Date(audit.created).toLocaleString() : ""}
                                        onChange={undefinedFunction}
                                        errorText={errors["created"] ? errors["created"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="created_By"
                                        label="Created By"
                                        disabled={true}
                                        varient="outlined"
                                        value={audit.created_By}
                                        onChange={undefinedFunction}
                                        errorText={errors["created_By"] ? errors["created_By"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="modified"
                                        label="Last Modified"
                                        varient="outlined"
                                        disabled={true}
                                        value={audit.modified ? new Date(audit.modified).toLocaleString() : ""}
                                        onChange={undefinedFunction}
                                        errorText={errors["modified"] ? errors["modified"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="modified_By"
                                        label="Modified By"
                                        varient="outlined"
                                        disabled={true}
                                        value={audit.modified_By}
                                        onChange={undefinedFunction}
                                        errorText={errors["modified_By"] ? errors["modified_By"].message : undefined}
                                    />
                                </LAGridItem>
                            </LAGrid> : null}

                            <LAGridItem xs={12}>
                                <hr />
                                <LASaveAndCancelButton
                                    onSave={this.onSave}
                                    onCancel={this.handleCancel}
                                    disableSave={(Object.values(errors).length > 0
                                        ? true : (audit.auditor_Signature === null || audit.auditor_Signature === "")
                                            ? true : undefined)}
                                />
                            </LAGridItem>

                        </LAGrid>
                    </LAPaperWithPadding>

                    <RequestStatus requestStatus={updateAudit.kind} successMessage="Audit successfully updated" />
                </SilAuditStyles>
            </PageSpacing>
        );
    }

    private handleExpand = (name: keyof ICarrierAuditState) => {
        this.setState((prevState) => ({
            ...prevState,               
            [name]: !prevState[name] 
        }));
    };

    private checkBoxClick = (value: boolean, name?: string): void => {
        if (name) {
            let errors = this.state.errors;

            if (requiredFields.includes(name))
                errors = this.errorChecker(name, value ? "Yes" : "No", errors, true);

            this.setState({
                ...this.state,
                errors,
                audit: {
                    ...this.state.audit,
                    [name]: value ? "Yes" : "No"
                }
            });
        };
    };

    private clearSignature = (): void => {
        this.sigCanvas.current.clear();
        this.setState({
            audit: {
                ...this.state.audit,
                auditor_Signature: ""
            }
        });
    };

    private saveSignature = () => {
        const dataUrl = this.sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
        this.setState({
            audit: {
                ...this.state.audit,
                auditor_Signature: dataUrl
            }
        });
    };

    private handleDeleteAtt = (fName: string, key: string): void => {
        const selection = window.confirm(deleteText);
        if (selection) {
            const state: any = this.state;

            const iS = { ...state.audit[key = "_File_Name"] };
            const atts = [...state.audit[key + "_Files"]];

            const eAttachments: string[] = iS.split(";");

            const findIsIndex = eAttachments.findIndex(x => x === fName);
            const findIndex = atts.findIndex(x => x.Name === fName);

            if (findIndex >= 0) atts.splice(findIndex, 1);
            if (findIsIndex >= 0) eAttachments.splice(findIsIndex, 1);

            const newAtts = eAttachments.join(";");

            this.setState({
                ...this.state,
                audit: {
                    ...this.state.audit,
                    [key + "_File_Name"]: newAtts,
                    [key + "_Files"]: atts
                }
            });
        }
    };

    private handleAtt = (files: Array<File | DataTransferItem>, name: string): void => {
        new Promise((resolve, reject): any => {
            if (files && (files !== null)) {

                const state: any = this.state;

                const atts = state.audit[name + "_Files"] ? [...state.audit[name + "_Files"]] : [];
                let iS = { ...state.audit[name + "_File_Name"] };

                const eAttachments = iS !== null && typeof iS !== 'object' ? iS.split(";") : [];

                Object.values(files).forEach((x: any) => {
                    const reader = new FileReader();

                    reader.onloadend = async (e): Promise<void> => {
                        if (reader.result !== null) {

                            eAttachments.push(x.name);
                            iS = eAttachments.join(";");

                            atts.push({
                                name: x.name,
                                base64String: reader.result.toString()
                            });

                            this.setState({
                                ...this.state,
                                audit: {
                                    ...this.state.audit,
                                    [name + "_Files"]: atts,
                                    [name + "_File_Name"]: iS
                                }
                            });

                            resolve(true);
                        }
                    };
                    reader.readAsDataURL(x);
                });
            } else {
                reject();
            }
        });

    };

    private handleDownloadAtt = (folderName: string, fName: string, attachment?: IAttachmentUpload[]): void => {

        if (attachment && attachment.length > 0) {
            const idx = attachment.findIndex((x) => x.name === fName);

            if (idx >= 0) {
                let a = document.createElement("a");
                a.href = attachment[idx].base64String;
                a.download = fName;
                a.click();
            } else {
                this.downloadFromServer(folderName, fName);
            }
        } else {
            this.downloadFromServer(folderName, fName);
        }
    };

    private downloadFromServer = (folderName: string, fName: string): void => {
        const { id, carrier_ID } = this.state.audit;

        fetch(END_POINTS.PROPSENSE.SIL.CARRIER.GET_AUDIT_FILE, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                request: {
                    "AuditId": id,
                    "CarrierId": carrier_ID,
                    "FolderName": folderName,
                    "FileName": fName
                },
                token: "b3123a8f-3437-4d36-8dd3-1206ae066503"
            })
        })
            .then((res: any) => {
                return res.blob();
            })
            .then(blob => {
                if (fName) {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = fName;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                }
            })
            .catch((res) => console.log(res));
    };

    private onDateChange = (name: string, value: string): void => {
        const date = new Date(value);
        let errors = { ...this.state.errors };

        if (date.toString() === "Invalid Date") {
            if (!errors[name]) {
                errors[name] = { key: name, message: "Invalid Date" };
            }
        } else {
            if (errors[name])
                delete errors[name];
        };

        if (requiredFields.includes(name))
            errors = this.errorChecker(name, value, errors, true);

        this.setState({
            ...this.state,
            audit: {
                ...this.state.audit,
                [name]: value
            },
            errors
        });
    };

    private handleCancel = (): void => {
        this.props.history.push(ROUTE.SIL.CARRIERS.CARRIER_AUDITS);
    };

    private onSave = (): void => {
        const { getToken } = this.props;

        if (hasPayload(getToken)) {
            let iS = this.state.audit;
            iS.is_Admin = true;
            iS.modified_By = userName;

            this.props.updateSILAuditsRequest({
                token: getToken.payload.response.token,
                request: iS
            });
        }
    };

    private handleDropDownChange = (event: unknown, value: IIdName<string>, name?: string): void => {
        if (name && value !== null) {
            let errors = this.state.errors;

            if (requiredFields.includes(name))
                errors = this.errorChecker(name, value ? value.name : "", errors, true);

            this.setState({
                ...this.state,
                errors,
                audit: {
                    ...this.state.audit,
                    [name]: value.name
                }
            });
        }
    };

    private onChange = (name: string, value: string): void => {
        let errors = this.state.errors;

        if (requiredFields.includes(name))
            errors = this.errorChecker(name, value, errors, true);

        this.setState({
            ...this.state,
            errors,
            audit: {
                ...this.state.audit,
                [name]: value
            }
        });
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, isRequired: boolean): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: isRequired ? true : undefined, minLength: 1, decimal: undefined });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        };
        return err;
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });

        if (hasPayload(this.props.getToken)) {

            if (isNotLoaded(this.props.getSILAudits)) {
                this.props.getSILAuditsRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        IsAdmin: true
                    }
                });
            }

            const query = queryString.parse(this.props.location.search);
            const id = query.id ?? "0";

            if (id !== "0") {
                if ((hasPayload(this.props.getSILAudits))) {
                    const audit = this.props.getSILAudits.payload.response.find(x => x.id === Number(id));
                    if (audit) {
                        let errors = this.state.errors;
                        if (audit.audit === null || audit.audit === "")
                            errors["audit"] = { key: "audit", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                        if (audit.carrier_Profile_Reviewed === null || audit.carrier_Profile_Reviewed === "")
                            errors["carrier_Profile_Reviewed"] = { key: "carrier_Profile_Reviewed", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                        this.setState({ audit, errors });
                    }
                };
            }
        }

    };

}

const mapStateToProps = (state: IStore): ICarrierAuditStoreProps => ({
    getToken: getTokenStatus(state),
    getSILAudits: getSILCarrierAuditStatus(state),
    updateAudit: updateSILCarrierAuditStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): ICarrierAuditDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getSILAuditsRequest: (data: IGetCarrierAuditRequest) => dispatch(getCarrierAuditLoadAction(data)),
    updateSILAuditsRequest: (data: IUpdateCarrierAuditRequest) => dispatch(updateCarrierAuditLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(SILAudit);