import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, hasPayload, loading, notLoaded, Server, succeeded } from "../../../server";
import { IGET_FEED_MATERIALS_REQUEST, IFeedMaterials } from "./getFeedMaterialsConstaints";
import { IGetFeedMaterialsLoadAction, IGetFeedMaterialsLoadFailedAction, IGetFeedMaterialsSuccessAction } from "./getFeedMaterialsActions";
import { IUPDATE_FEED_MATERIAL_REQUEST } from "../updateFeedMaterial/updateFeedMaterialConstaints";
import { IADD_FEED_MATERIAL_REQUEST } from "../addFeedMaterial/addFeedMaterialConstaints";
import { IAddFeedMaterialSuccessAction } from "../addFeedMaterial/addFeedMaterialActions";
import { IUpdateFeedMaterialSuccessAction } from "../updateFeedMaterial/updateFeedMaterialActions";

type Actions =
    IGetFeedMaterialsLoadAction
    | IGetFeedMaterialsLoadFailedAction
    | IGetFeedMaterialsSuccessAction
    | IUpdateFeedMaterialSuccessAction
    | IAddFeedMaterialSuccessAction
    | IFlushDataSuccessAction;

export const GetFeedMaterialsReducer = (state: Server<SilAPIResponse<IFeedMaterials[]>> = notLoaded, action: Actions): Server<SilAPIResponse<IFeedMaterials[]>> => {
    switch (action.type) {
        case IGET_FEED_MATERIALS_REQUEST.REQUEST:
            return loading;

        case IGET_FEED_MATERIALS_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_FEED_MATERIALS_REQUEST.FAILED:
            return failed(action.message);

        case IUPDATE_FEED_MATERIAL_REQUEST.SUCCESS:
            if (hasPayload(state)) {
                const currentState = [...state.payload.response];
                const ind = currentState.findIndex(x => x.id === action.data.id);

                if (ind >= 0)
                    currentState[ind] = action.data;

                return {
                    ...state,
                    payload: {
                        ...state.payload,
                        response: currentState
                    }
                };
            }
            return notLoaded;

        case IADD_FEED_MATERIAL_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};