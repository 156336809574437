import { SilAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../../flushData.tsx/flushDataConstants";
import { Server, failed, loading, notLoaded, succeeded } from "../../../../server";
import { IAddTruckScaleSuccessAction } from "../addTruckScale/addTruckScaleActions";
import { IADD_TRUCK_SCALE_REQUEST } from "../addTruckScale/addTruckScaleConstaints";
import { IUpdateTruckScaleSuccessAction } from "../updateTruckScale/updateTruckScaleActions";
import { IUPDATE_TRUCK_SCALE_REQUEST } from "../updateTruckScale/updateTruckScaleConstaints";
import { IGetTruckScaleLoadAction, IGetTruckScaleLoadFailedAction, IGetTruckScaleSuccessAction } from "./getTruckScalesActions";
import { ITruckScale, IGET_TRUCK_SCALES_REQUEST } from "./getTruckScalesConstaints";

type Actions =
    IGetTruckScaleLoadAction
    | IGetTruckScaleLoadFailedAction
    | IGetTruckScaleSuccessAction
    | IAddTruckScaleSuccessAction
    | IUpdateTruckScaleSuccessAction
    | IFlushDataSuccessAction;

export const GetTruckScaleReducer = (state: Server<SilAPIResponse<ITruckScale[]>> = notLoaded, action: Actions): Server<SilAPIResponse<ITruckScale[]>> => {
    switch (action.type) {
        case IGET_TRUCK_SCALES_REQUEST.REQUEST:
            return loading;

        case IGET_TRUCK_SCALES_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_TRUCK_SCALES_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_TRUCK_SCALE_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_TRUCK_SCALE_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};