import { IPassTokenWithRequest } from "../../dryPlant/token/getTokenConstants";

export enum IUPDATE_CARRIER_EMAIL_REQUEST {
    REQUEST = "updateCarrierEmail/UPDATE_CARRIER_EMAIL_REQUEST",
    SUCCESS = "updateCarrierEmail/UPDATE_CARRIER_EMAIL_SUCCESS",
    FAILED = "updateCarrierEmail/UPDATE_CARRIER_EMAIL_FAILED"
};

export interface IUpdateCarrierEmailRequest extends IPassTokenWithRequest {
    request: {
        id: number;
        subject: string;
        email_Body: string;
        email_All_Carriers: string;
        carriers: number[];
        attachment?: string;
        attachment_Name?: string;
        created_By: string;
        modified_By: string;
    };
};
