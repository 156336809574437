import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetGeoBagsRequest, IGET_GEO_BAGS_REQUEST, IGeoBag } from "./getGeoBagsConstaints";

export interface IGetGeoBagsLoadAction {
    type: IGET_GEO_BAGS_REQUEST.REQUEST;
    data: IGetGeoBagsRequest
}
export const getGeoBagsLoadAction = (data: IGetGeoBagsRequest): IGetGeoBagsLoadAction => ({
    type: IGET_GEO_BAGS_REQUEST.REQUEST,
    data
});

export interface IGetGeoBagsSuccessAction {
    type: IGET_GEO_BAGS_REQUEST.SUCCESS;
    data: SilAPIResponse<IGeoBag[]>;
}
export const getGeoBagsLoadSuccessAction = (data: SilAPIResponse<IGeoBag[]>): IGetGeoBagsSuccessAction => ({
    type: IGET_GEO_BAGS_REQUEST.SUCCESS,
    data
});

export interface IGetGeoBagsLoadFailedAction {
    type: IGET_GEO_BAGS_REQUEST.FAILED;
    message: string;
}
export const getGeoBagsLoadFailedAction = (message: string): IGetGeoBagsLoadFailedAction => ({
    type: IGET_GEO_BAGS_REQUEST.FAILED,
    message
});
