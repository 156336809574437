import { SimosAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetOrdersRequest, IGET_ORDERS_REQUEST, ISIMOSOrders } from "./getOrdersConstaints";

export interface IGetOrdersLoadAction {
    type: IGET_ORDERS_REQUEST.REQUEST;
    data: IGetOrdersRequest
}
export const getOrdersLoadAction = (data: IGetOrdersRequest): IGetOrdersLoadAction => ({
    type: IGET_ORDERS_REQUEST.REQUEST,
    data
});

export interface IGetOrdersSuccessAction {
    type: IGET_ORDERS_REQUEST.SUCCESS;
    data: SimosAPIResponse<ISIMOSOrders[]>;
}
export const getOrdersLoadSuccessAction = (data: SimosAPIResponse<ISIMOSOrders[]>): IGetOrdersSuccessAction => ({
    type: IGET_ORDERS_REQUEST.SUCCESS,
    data
});

export interface IGetOrdersLoadFailedAction {
    type: IGET_ORDERS_REQUEST.FAILED;
    message: string;
}
export const getOrdersLoadFailedAction = (message: string): IGetOrdersLoadFailedAction => ({
    type: IGET_ORDERS_REQUEST.FAILED,
    message
});
