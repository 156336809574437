import { IEmployees } from "../getEmployees/getEmployeesConstaints";
import { IUpdateEmployeeRequest, IUPDATE_EMPLOYEE_REQUEST } from "./updateEmployeeConstaints";

export interface IUpdateEmployeeLoadAction {
    type: IUPDATE_EMPLOYEE_REQUEST.REQUEST;
    data: IUpdateEmployeeRequest
}
export const updateEmployeeLoadAction = (data: IUpdateEmployeeRequest): IUpdateEmployeeLoadAction => ({
    type: IUPDATE_EMPLOYEE_REQUEST.REQUEST,
    data
});

export interface IUpdateEmployeeSuccessAction {
    type: IUPDATE_EMPLOYEE_REQUEST.SUCCESS;
    data: IEmployees;
}
export const updateEmployeeLoadSuccessAction = (data: IEmployees): IUpdateEmployeeSuccessAction => ({
    type: IUPDATE_EMPLOYEE_REQUEST.SUCCESS,
    data
});

export interface IUpdateEmployeeLoadFailedAction {
    type: IUPDATE_EMPLOYEE_REQUEST.FAILED;
    message: string;
}
export const updateEmployeeLoadFailedAction = (message: string): IUpdateEmployeeLoadFailedAction => ({
    type: IUPDATE_EMPLOYEE_REQUEST.FAILED,
    message
});
