import { IPassTokenRequestBody } from "../../../../../react/shared/publicInterfaces";
import { IAddUpdateSimosTransportCompany } from "../addSimosTransportCompany/addSimosTransportCompanyConstaints";

export enum IUPDATE_SIMOS_TRANSPORT_COMPANY_REQUEST {
    REQUEST = "updateSimosTransportCompany/UPDATE_SIMOS_TRANSPORT_COMPANY_REQUEST",
    SUCCESS = "updateSimosTransportCompany/UPDATE_SIMOS_TRANSPORT_COMPANY_SUCCESS",
    FAILED = "updateSimosTransportCompany/UPDATE_SIMOS_TRANSPORT_COMPANY_FAILED"
};

export interface IUpdateSimosTransportCompanyRequest extends IPassTokenRequestBody {
    request: IAddUpdateSimosTransportCompany;
};
