import { ReactNode, PureComponent } from "react";
import { Prompt, RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { hasPayload, isFailed, isNotLoaded, isSucceeded, Server } from "../../../../redux/server";
import { getSaveCarrierFormStatus } from "../../../../redux/sil/carrier/saveCarrierForm/saveCarrierFormAccessor";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { saveCarrierFormLoadAction } from "../../../../redux/sil/carrier/saveCarrierForm/saveCarrierFormActions";
import { ISaveCarrierFormRequest } from "../../../../redux/sil/carrier/saveCarrierForm/saveCarrierFormConstaints";
import { FieldValidator, FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../../shared/fieldValidator";
import { ById, ByIdNumber, SilAPIResponse } from "../../../shared/publicInterfaces";
import { LAGreyPaper, LAPaperWithPadding } from "../../../shared/paper";
import LAGridItem from "../../../shared/gridList";
import LAGrid from "../../../shared/grid";
import { LAButton, LAIconButton, LASaveAndCancelButton } from "../../../shared/buttons";
import { LAExpansionPanel } from "../../../shared/expansionPanel";
import LATextArea from "../../../shared/textArea";
import { ArrowLeftIcon, DeleteIcon } from "../../../shared/icons";
import { LADateTimePicker } from "../../../shared/dateTimePicker";
import { addDashesToPhone, deleteText, generateRandomText, numberAndDashCheck, undefinedFunction, userName, ZEROTH } from "../../../shared/constExports";
import LAAutoComplete from "../../../shared/autoComplete";
import { LACheckBox } from "../../../shared/checkBox";
import { ROUTE } from "../../../routes";
import queryString from "query-string";
import { IUpdateCarrierFormRequest } from "../../../../redux/sil/carrier/updateCarrierForm/updateCarrierFormConstaints";
import { updateCarrierFormLoadAction } from "../../../../redux/sil/carrier/updateCarrierForm/updateCarrierFormActions";
import { getUpdateCarrierFormStatus } from "../../../../redux/sil/carrier/updateCarrierForm/updateCarrierFormAccessor";
import LAErrorBox from "../../../shared/errorBox";
import { ICarrier, IGetCarriersRequest } from "../../../../redux/sil/carrier/getCarriers/getCarriersConstaints";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { getCarriersStatus } from "../../../../redux/sil/carrier/getCarriers/getCarriersAccessor";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import { getCarriersLoadAction } from "../../../../redux/sil/carrier/getCarriers/getCarriersActions";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import { DRY_PLANT_FORM_TILE_COLOR, WHITE_COLOR } from "../../../shared/theme";
import { END_POINTS } from "../../../../redux/endpoints";
import SilRoleCheck from "../../../shared/silRoleCheck";
import { LADropDone } from "../../../shared/dragDropFiles";
import LALinkButton from "../../../shared/linkButton";
import LATextField from "../../../shared/textField";

enum ICarrierFormSection {
    "Company" = 1,
    "Owner" = 2,
    "Dispatch" = 3,
    "Insurance" = 4,
    "Other" = 5,
    "Fleet" = 6,
    "Admin" = 7
};

const AD_Category_Values = [{ id: 1, name: "1" }, { id: 2, name: "2" }, { id: 3, name: "3" }, { id: 4, name: "4" }, { id: 5, name: "5" }, { id: 6, name: "6" }];
const requiredFields = ["name", "mailing_Address", "city", "province", "postal_Code", "phone", "email", "owner_Name", "owner_Phone", "dispatch_Contact_Name", "dispatch_Email", "aD_Category"];

interface ICarrierFormComponentStoreProps {
    SaveCarrierFormStatus: Server<string>;
    UpdateCarrierFormStatus: Server<string>;
    getToken: Server<SilAPIResponse<IToken>>;
    getCarriers: Server<SilAPIResponse<ByIdNumber<ICarrier>>>;
};

interface ICarrierFormComponentDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getCarriersRequest: (data: IGetCarriersRequest) => unknown;
    RequestSaveCarrierForm: (data: ISaveCarrierFormRequest) => unknown;
    RequestUpdateCarrierForm: (data: IUpdateCarrierFormRequest) => unknown;
};

interface ICarrierFormComponentState {
    expanded: number[];
    data: ICarrierForm;
    cancelPopup: boolean;
    errors: ById<IFieldErrorKeyValue>;
};

const CarrierFormComponentStyles = styled(LAGreyPaper)`
    margin: 20px 20px;

`;

type ICarrierFormComponentProps =
    RouteComponentProps
    & ICarrierFormComponentStoreProps
    & ICarrierFormComponentDispatchProps;

class CarrierFormComponent extends PureComponent<ICarrierFormComponentProps, ICarrierFormComponentState> {

    public constructor(props: ICarrierFormComponentProps) {
        super(props);
        this.state = {
            expanded: [1, 2, 3, 4, 5, 6, 7],
            data: {
                active: "Yes",
                approved: "Yes",
                uses_Sil_Trailers: "No",
                modified: new Date().toISOString(),
                created: new Date().toISOString(),
                created_By: userName,
                modified_By: userName,
                hexCode: ''
            },
            cancelPopup: false,
            errors: {}
        };
    }

    public componentDidMount(): void {
        this.getData();
    };

    public async componentDidUpdate(prevProps: ICarrierFormComponentProps): Promise<void> {
        if ((this.props.getCarriers !== prevProps.getCarriers) || (this.props.getToken !== prevProps.getToken))
            this.getData();

        if ((this.props.SaveCarrierFormStatus !== prevProps.SaveCarrierFormStatus) && isSucceeded(this.props.SaveCarrierFormStatus))
            this.props.history.push(ROUTE.SIL.CARRIERS.CARRIERS);

        if ((this.props.UpdateCarrierFormStatus !== prevProps.UpdateCarrierFormStatus) && isSucceeded(this.props.UpdateCarrierFormStatus))
            this.props.history.push(ROUTE.SIL.CARRIERS.CARRIERS);
    };


    public render(): ReactNode {

        const { SaveCarrierFormStatus, UpdateCarrierFormStatus } = this.props;
        const { expanded, data, errors, cancelPopup } = this.state;

        const handleCompany = (): void => this.handleExpand(ICarrierFormSection.Company);
        const handleOwner = (): void => this.handleExpand(ICarrierFormSection.Owner);
        const handleDispatch = (): void => this.handleExpand(ICarrierFormSection.Dispatch);
        const handleInsurance = (): void => this.handleExpand(ICarrierFormSection.Insurance);
        const handleOther = (): void => this.handleExpand(ICarrierFormSection.Other);
        const handleFleet = (): void => this.handleExpand(ICarrierFormSection.Fleet);
        const handleAdmin = (): void => this.handleExpand(ICarrierFormSection.Admin);
        const WCBClearanceLetterFile = (event?: any): void => this.uploadFile("wcB_Clearance_Letter_File", event);
        const CarrierProfileFile = (event?: any): void => this.uploadFile("carrier_Profile_File", event);
        const InsuranceCertificateFile = (event?: any): void => this.uploadFile("insurance_Certificate_File", event);
        const CorporateSearchFile = (event?: any): void => this.uploadFile("corporate_Search_File", event);
        const AuditForm = (event?: any): void => this.uploadFile("audit_Form", event);
        const SafetyFitnessCertificateFile = (event?: any): void => this.uploadFile("safety_Fitness_Certificate_File", event);
        const CarrierAgreementFile = (event?: any): void => this.uploadFile("carrier_Agreement_File", event);
        const RateScheduleFile = (event?: any): void => this.uploadFile("rate_Scheduled_File", event);
        const MaintenanceInspectionPolicyFile = (event?: any): void => this.uploadFile("maintenance_Inspection_Policy_File", event);
        const FleetTruckFile = (event?: any): void => this.uploadFile("fleet_Trucks_List_File", event);
        const RegistrationForm = (event?: any): void => this.uploadFile("registration_Form", event);
        const RegistrationFormFileDownload = (): void => this.downloadFile(data.registration_Form_Name, data.registration_Form, "Registration_Form");
        const WCBClearanceLetterFileDownload = (): void => this.downloadFile(data.wcB_Clearance_Letter_File_Name, data.wcB_Clearance_Letter_File, "WCB_Clearance_Letter_File");
        const CarrierProfileFileDownload = (): void => this.downloadFile(data.carrier_Profile_File_Name, data.carrier_Profile_File, "Carrier_Profile_File");
        const InsuranceCertificateFileDownload = (): void => this.downloadFile(data.insurance_Certificate_File_Name, data.insurance_Certificate_File, "Insurance_Certificate_File");
        const CorporateSearchFileDownload = (): void => this.downloadFile(data.corporate_Search_File_Name, data.corporate_Search_File, "Corporate_Search_File");
        const AuditFormDownload = (): void => this.downloadFile(data.audit_Form_Name, data.audit_Form, "Audit_Form");
        const SafetyFitnessCertificateFileDownload = (): void => this.downloadFile(data.safety_Fitness_Certificate_File_Name, data.safety_Fitness_Certificate_File, "Safety_Fitness_Certificate_File");
        const CarrierAgreementFileDownload = (): void => this.downloadFile(data.carrier_Agreement_File_Name, data.carrier_Agreement_File, "Carrier_Agreement_File");
        const RateScheduleFileDownload = (): void => this.downloadFile(data.rate_Scheduled_File_Name, data.rate_Scheduled_File, "Rate_Scheduled_File");
        const MaintenanceInspectionPolicyFileDownload = (): void => this.downloadFile(data.maintenance_Inspection_Policy_File_Name, data.maintenance_Inspection_Policy_File, "Maintenance_Inspection_Policy_File");
        const FleetTruckFileDownload = (): void => this.downloadFile(data.fleet_Trucks_List_File_Name, data.fleet_Trucks_List_File, "Fleet_Trucks_List_File");
        const HandleADCatChange = (event: unknown, value: { id: string }): void => this.handleNumberField("aD_Category", value.id);

        const handlePhone = (name: string, value: string): void => numberAndDashCheck(value) ? this.handleChange(name, value) : undefined;
        const onPhoneBlur = (name: string, value: string): void => value.toString().length > 9 ? this.handleChange(name, addDashesToPhone(value.toString())) : undefined;

        let sError: string = "";

        if (isFailed(SaveCarrierFormStatus)) {
            sError = SaveCarrierFormStatus.message;
        } else if (isFailed(UpdateCarrierFormStatus)) {
            sError = UpdateCarrierFormStatus.message;
        };

        return (
            <CarrierFormComponentStyles>
                <SilRoleCheck error={true} roleFor="carrierAccess">
                    <LAGrid>

                        <LAGridItem xs={12}>
                            <LAButton startIcon={<ArrowLeftIcon color={WHITE_COLOR} />} label="Back to list" onClick={this.handleCancel} />
                            <h2 className="text-center">{(data.id && data.id !== 0) ? "UPDATE" : "ADD"} CARRIER FORM</h2>
                            <hr />
                            <LAExpansionPanel color={DRY_PLANT_FORM_TILE_COLOR} textColor={WHITE_COLOR} label="COMPANY" expanded={expanded.includes(ICarrierFormSection.Company)} onClick={handleCompany}>
                                <LAGrid>

                                    <LAGridItem xs={6}>
                                        <LATextArea
                                            rowsMax={1}
                                            fullWidth={true}
                                            name="name"
                                            onChange={this.handleChange}
                                            label="Name"
                                            value={data.name ?? ""}
                                            errorText={errors["name"] ? errors["name"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LATextArea
                                            rowsMax={1}
                                            fullWidth={true}
                                            name="alternate_Name"
                                            onChange={this.handleChange}
                                            label="Alternate Name"
                                            value={data.alternate_Name ?? ""}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={3}>
                                        <LACheckBox
                                            name="active"
                                            label="Active"
                                            value={data.active && data.active === "Yes" ? true : false}
                                            onChange={this.handleCheckBoxChange}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={3}>
                                        <LACheckBox
                                            name="is_YHD_Carrier"
                                            label="Is YHD Carrier"
                                            value={data.is_YHD_Carrier && data.is_YHD_Carrier === "Yes" ? true : false}
                                            onChange={this.handleCheckBoxChange}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={3}>
                                        <LACheckBox
                                            name="approved"
                                            label="Approved"
                                            onChange={this.handleCheckBoxChange}
                                            value={data.approved && data.approved === "Yes" ? true : false}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LADateTimePicker
                                            fullWidth={true}
                                            name="approved_Date"
                                            onChange={this.onDateChange}
                                            label="Approved Date"
                                            value={data.approved_Date ?? ""}
                                            errorText={errors["approved_Date"] ? errors["approved_Date"].message : undefined}
                                        />
                                    </LAGridItem>

                                    {((data.approved === undefined) || (data.approved === "No")) ? <LAGridItem xs={6}>
                                        <LATextArea
                                            rowsMax={1}
                                            fullWidth={true}
                                            name="unApproved_Reason"
                                            label="Unapproved Reason"
                                            onChange={this.handleChange}
                                            value={data.unApproved_Reason ?? ""}
                                            errorText={errors["unApproved_Reason"] ? errors["unApproved_Reason"].message : undefined}
                                        />
                                    </LAGridItem>
                                        : <></>}

                                    <LAGridItem xs={6}>
                                        <LACheckBox
                                            name="uses_Sil_Trailers"
                                            label="Uses Sil Trailers"
                                            value={data.uses_Sil_Trailers && data.uses_Sil_Trailers === "Yes" ? true : false}
                                            onChange={this.handleCheckBoxChange}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LATextArea
                                            rowsMax={1}
                                            fullWidth={true}
                                            name="mailing_Address"
                                            onChange={this.handleChange}
                                            label="Mailing Address"
                                            value={data.mailing_Address ?? ""}
                                            errorText={errors["mailing_Address"] ? errors["mailing_Address"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LATextArea
                                            rowsMax={1}
                                            fullWidth={true}
                                            name="city"
                                            onChange={this.handleChange}
                                            label="City"
                                            value={data.city ?? ""}
                                            errorText={errors["city"] ? errors["city"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LATextArea
                                            rowsMax={1}
                                            fullWidth={true}
                                            name="province"
                                            onChange={this.handleChange}
                                            label="Province"
                                            value={data.province ?? ""}
                                            errorText={errors["province"] ? errors["province"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LATextArea
                                            rowsMax={1}
                                            fullWidth={true}
                                            name="postal_Code"
                                            onChange={this.handleChange}
                                            label="Postal Code"
                                            value={data.postal_Code ?? ""}
                                            errorText={errors["postal_Code"] ? errors["postal_Code"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LATextArea
                                            rowsMax={1}
                                            type="number"
                                            fullWidth={true}
                                            name="phone"
                                            label="Phone"
                                            value={data.phone ?? ""}
                                            onChange={handlePhone}
                                            onBlur={() => onPhoneBlur("phone", data.phone ?? "")}
                                            errorText={errors["phone"] ? errors["phone"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LATextArea
                                            rowsMax={1}
                                            fullWidth={true}
                                            name="email"
                                            onChange={this.handleChange}
                                            label="Email"
                                            value={data.email ?? ""}
                                            errorText={errors["email"] ? errors["email"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LATextField
                                            fullWidth={true}
                                            name="service_Email"
                                            onChange={this.handleChange}
                                            label="Service Email"
                                            value={data.service_Email ?? ""}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LATextArea
                                            minRows={5}
                                            rowsMax={10}
                                            name="notes"
                                            label="Notes"
                                            fullWidth={true}
                                            value={data.notes ?? ""}
                                            onChange={this.handleChange}
                                        />
                                    </LAGridItem>

                                </LAGrid>
                            </LAExpansionPanel>
                        </LAGridItem>

                        <LAGridItem xs={12}>
                            <LAExpansionPanel color={DRY_PLANT_FORM_TILE_COLOR} textColor={WHITE_COLOR} label="OWNER" expanded={expanded.includes(ICarrierFormSection.Owner)} onClick={handleOwner}>
                                <LAGrid>

                                    <LAGridItem xs={6}>
                                        <LATextArea
                                            rowsMax={1}
                                            fullWidth={true}
                                            name="owner_Name"
                                            onChange={this.handleChange}
                                            label="Owner Name"
                                            value={data.owner_Name ?? ""}
                                            errorText={errors["owner_Name"] ? errors["owner_Name"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LATextField
                                            fullWidth={true}
                                            name="owner_Email"
                                            onChange={this.handleChange}
                                            label="Owner Email"
                                            value={data.owner_Email ?? ""}
                                            errorText={errors["owner_Email"] ? errors["owner_Email"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LATextArea
                                            rowsMax={1}
                                            fullWidth={true}
                                            name="owner_Phone"
                                            type="number"

                                            label="Owner Phone"
                                            value={data.owner_Phone ?? ""}
                                            onChange={handlePhone}
                                            onBlur={() => onPhoneBlur("owner_Phone", data.owner_Phone ?? "")}
                                            errorText={errors["owner_Phone"] ? errors["owner_Phone"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6} className="text-center">
                                    <strong>Registration Form</strong>
                                    <LADropDone onFileDrop={RegistrationForm} />
                                    {data.registration_Form_Name && data.registration_Form_Name.length > 0 
                                    && <LALinkButton className="pl-5" label={data.registration_Form_Name} 
                                    onClick={RegistrationFormFileDownload} />}
                                    {data.registration_Form_Name && <LAIconButton
                                        name="registration_Form_Name"
                                        label="Remove"
                                        icon={<DeleteIcon />}
                                        onClick={this.onFormRemove}
                                    />}
                                </LAGridItem>

                                </LAGrid>
                            </LAExpansionPanel>
                        </LAGridItem>

                        <LAGridItem xs={12}>
                            <LAExpansionPanel color={DRY_PLANT_FORM_TILE_COLOR} textColor={WHITE_COLOR} label="DISPATCH" expanded={(expanded.includes(ICarrierFormSection.Dispatch))} onClick={handleDispatch}>
                                <LAGrid>

                                    <LAGridItem xs={6}>
                                        <LATextArea
                                            rowsMax={1}
                                            fullWidth={true}
                                            name="dispatch_Contact_Name"
                                            onChange={this.handleChange}
                                            label="Dispatch Contract Name"
                                            value={data.dispatch_Contact_Name ?? ""}
                                            errorText={errors["dispatch_Contact_Name"] ? errors["dispatch_Contact_Name"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LATextField
                                            fullWidth={true}
                                            name="dispatch_Email"
                                            onChange={this.handleChange}
                                            label="Dispatch Email"
                                            value={data.dispatch_Email ?? ""}
                                            errorText={errors["dispatch_Email"] ? errors["dispatch_Email"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LATextArea
                                            rowsMax={1}
                                            fullWidth={true}
                                            type="number"
                                            name="dispatch_Phone_Cell"
                                            onChange={handlePhone}
                                            onBlur={() => onPhoneBlur("dispatch_Phone_Cell", data.dispatch_Phone_Cell ?? "")}
                                            label="Dispatch Phone Cell"
                                            value={data.dispatch_Phone_Cell ?? ""}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LATextArea
                                            rowsMax={1}
                                            fullWidth={true}
                                            type="number"
                                            name="dispatch_Phone_Work"
                                            onChange={handlePhone}
                                            onBlur={() => onPhoneBlur("dispatch_Phone_Work", data.dispatch_Phone_Work ?? "")}
                                            label="Disptach Phone Work"
                                            value={data.dispatch_Phone_Work ?? ""}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LATextArea
                                            rowsMax={1}
                                            fullWidth={true}
                                            name="dispatch_Contact_After_Hours"
                                            onChange={this.handleChange}
                                            label="Dispatch Contact After Hours"
                                            value={data.dispatch_Contact_After_Hours ?? ""}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LATextArea
                                            rowsMax={1}
                                            type="number"
                                            fullWidth={true}
                                            onChange={handlePhone}
                                            onBlur={() => onPhoneBlur("dispatch_Phone_After_Hours", data.dispatch_Phone_After_Hours ?? "")}
                                            name="dispatch_Phone_After_Hours"
                                            label="Dispatch Phone After Hours"
                                            value={data.dispatch_Phone_After_Hours ?? ""}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12}>
                                        <LAAutoComplete
                                            multiple={false}
                                            getOptionLabel="name"
                                            autoHighlight={true}
                                            option={AD_Category_Values}
                                            filterSelectedOptions={true}
                                            onChange={HandleADCatChange}
                                            dropDownPlaceHolder="AD Category"
                                            selectionRemove={undefinedFunction}
                                            value={data.aD_Category ? AD_Category_Values.find(x => x.id === data.aD_Category) : ""}
                                            defaultValue={data.aD_Category ? AD_Category_Values.find(x => x.id === data.aD_Category) : ""}
                                            errorText={errors["aD_Category"] ? errors["aD_Category"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LATextArea
                                            rowsMax={1}
                                            fullWidth={true}
                                            name="username"
                                            onChange={undefinedFunction}
                                            label="Username"
                                            disabled={true}
                                            value={data.username ?? ""}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6} md={4}>
                                        <LATextArea
                                            rowsMax={1}
                                            fullWidth={true}
                                            name="password"
                                            label="Password"
                                            value={data.password ?? ""}
                                            onChange={this.handleChange}
                                        />
                                    </LAGridItem>

                                   

                                    <LAGridItem xs={12} md={2} className="pt-3">
                                        <LAButton label="Auto Generate" onClick={this.onGenerate} />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LATextArea
                                            rowsMax={1}
                                            fullWidth={true}
                                            type="number"
                                            name="prorated_BC"
                                            onChange={this.handleNumberField}
                                            label="Prorated BC"
                                            value={data.prorated_BC ?? ""}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LATextArea
                                            rowsMax={1}
                                            fullWidth={true}
                                            type="number"
                                            name="prorated_SK"
                                            onChange={this.handleNumberField}
                                            label="Prorated SK"
                                            value={data.prorated_SK ?? ""}
                                        />
                                    </LAGridItem>

                                    {((data.id) && (data.id > 0)) && <LAGridItem xs={12}>
                                        <LAGreyPaper>
                                            <strong>
                                                Carrier Portal Information
                                            </strong>
                                            <hr />
                                            <br />
                                            <span>
                                                Username: {data.username}
                                            </span>
                                            <br />

                                            <span>
                                                Password: {data.password}
                                            </span>
                                            <br />

                                            <span>
                                                Go To Portal:
                                            </span>
                                            <a href={`https://ext-apps.propsense.com/?username=${data.username}&password=${data.password}`} target="_blank"> CLICK HERE</a>

                                        </LAGreyPaper>
                                    </LAGridItem>}
                                    {((data.id) && (data.id > 0)) && <LAGridItem xs={12}>
                                        <LAGreyPaper>
                                            <strong>
                                                Drivers Portal Information
                                            </strong>
                                            <hr />
                                            <br />

                                            <span>
                                                Go To Portal:
                                            </span>
                                            <a href={`https://ext-apps.propsense.com/Sil/?carrierName=${data.hexCode}`} target="_blank"> CLICK HERE</a>

                                        </LAGreyPaper>
                                    </LAGridItem>}
                                </LAGrid>
                            </LAExpansionPanel>
                        </LAGridItem>

                        <LAGridItem xs={12}>
                            <LAExpansionPanel color={DRY_PLANT_FORM_TILE_COLOR} textColor={WHITE_COLOR} label="INSURANCE" expanded={expanded.includes(ICarrierFormSection.Insurance)} onClick={handleInsurance}>
                                <LAGrid>

                                    <LAGridItem xs={6}>
                                        <LATextArea
                                            rowsMax={1}
                                            fullWidth={true}
                                            name="gst"
                                            onChange={this.handleChange}
                                            label="GST"
                                            value={data.gst ?? ""}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LATextArea
                                            rowsMax={1}
                                            fullWidth={true}
                                            name="wcb"
                                            onChange={this.handleChange}
                                            label="WCB"
                                            value={data.wcb ?? ""}
                                            errorText={errors["wcb"] ? errors["wcb"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LADateTimePicker
                                            fullWidth={true}
                                            name="wcB_Clearance_Letter_Date"
                                            onChange={this.onDateChange}
                                            label="WCB Clearance Letter Date"
                                            value={data.wcB_Clearance_Letter_Date ?? ""}
                                            errorText={errors["wcB_Clearance_Letter_Date"] ? errors["wcB_Clearance_Letter_Date"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6} className="text-center">
                                    <strong>WCB Clearance Letter File</strong>
                                    <LADropDone onFileDrop={WCBClearanceLetterFile} />
                                    {data.wcB_Clearance_Letter_File_Name && data.wcB_Clearance_Letter_File_Name.length > 0 
                                    && <LALinkButton className="pl-5" label={data.wcB_Clearance_Letter_File_Name} 
                                    onClick={WCBClearanceLetterFileDownload} />}
                                    {data.wcB_Clearance_Letter_File_Name && <LAIconButton
                                        name="wcB_Clearance_Letter_File_Name"
                                        label="Remove"
                                        icon={<DeleteIcon />}
                                        onClick={this.onFormRemove}
                                    />}
                                </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LADateTimePicker
                                            fullWidth={true}
                                            name="insurance_Certificate_Date"
                                            onChange={this.onDateChange}
                                            label="Insurance Certificate Date"
                                            value={data.insurance_Certificate_Date ?? ""}
                                            errorText={errors["insurance_Certificate_Date"] ? errors["insurance_Certificate_Date"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6} className="text-center">
                                        <strong>Insurance Certificate File</strong>
                                        <LADropDone onFileDrop={InsuranceCertificateFile} />
                                        {data.insurance_Certificate_File_Name && data.insurance_Certificate_File_Name.length > 0
                                            && <LALinkButton className="pl-5" label={data.insurance_Certificate_File_Name}
                                                onClick={InsuranceCertificateFileDownload} />}
                                        {data.insurance_Certificate_File_Name && <LAIconButton
                                            name="insurance_Certificate_File_Name"
                                            label="Remove"
                                            icon={<DeleteIcon />}
                                            onClick={this.onFormRemove}
                                        />}
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LADateTimePicker
                                            fullWidth={true}
                                            name="insurance_Expiry"
                                            onChange={this.onDateChange}
                                            label="Insurance Expiry"
                                            value={data.insurance_Expiry ?? ""}
                                            errorText={errors["insurance_Expiry"] ? errors["insurance_Expiry"].message : undefined}
                                        />
                                    </LAGridItem>

                                </LAGrid>
                            </LAExpansionPanel>
                        </LAGridItem>

                        <LAGridItem xs={12}>
                            <LAExpansionPanel color={DRY_PLANT_FORM_TILE_COLOR} textColor={WHITE_COLOR} label="OTHER" expanded={expanded.includes(ICarrierFormSection.Other)} onClick={handleOther}>
                                <LAGrid>

                                    <LAGridItem xs={6}>
                                        <LADateTimePicker
                                            fullWidth={true}
                                            name="last_Verified"
                                            onChange={this.onDateChange}
                                            label="Last Verified"
                                            value={data.last_Verified ?? ""}
                                            errorText={errors["last_Verified"] ? errors["last_Verified"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                    <LADateTimePicker
                                            fullWidth={true}
                                            name="carrier_Profile"
                                            onChange={this.onDateChange}
                                            label="Carrier Profile"
                                            value={data.carrier_Profile ?? ""}
                                            errorText={errors["carrier_Profile"] ? errors["carrier_Profile"].message : undefined}
                                        />
                                    </LAGridItem>
                                    
                                    <LAGridItem xs={6} className="text-center">
                                        <strong>Carrier Profile File</strong>
                                        <LADropDone onFileDrop={CarrierProfileFile} />
                                        {data.carrier_Profile_File_Name && data.carrier_Profile_File_Name.length > 0
                                            && <LALinkButton className="pl-5" label={data.carrier_Profile_File_Name}
                                                onClick={CarrierProfileFileDownload} />}
                                        {data.carrier_Profile_File_Name && <LAIconButton
                                            name="carrier_Profile_File_Name"
                                            label="Remove"
                                            icon={<DeleteIcon />}
                                            onClick={this.onFormRemove}
                                        />}
                                    </LAGridItem>

                                    <LAGridItem xs={6} className="text-center">
                                        <strong>Corporate Search File</strong>
                                        <LADropDone onFileDrop={CorporateSearchFile} />
                                        {data.corporate_Search_File_Name && data.corporate_Search_File_Name.length > 0
                                            && <LALinkButton className="pl-5" label={data.corporate_Search_File_Name}
                                                onClick={CorporateSearchFileDownload} />}
                                        {data.corporate_Search_File_Name && <LAIconButton
                                            name="corporate_Search_File_Name"
                                            label="Remove"
                                            icon={<DeleteIcon />}
                                            onClick={this.onFormRemove}
                                        />}
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LADateTimePicker
                                            fullWidth={true}
                                            name="audit_Completed_Date"
                                            onChange={this.onDateChange}
                                            label="Audit Completed Date"
                                            value={data.audit_Completed_Date ?? ""}
                                            errorText={errors["audit_Completed_Date"] ? errors["audit_Completed_Date"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LADateTimePicker
                                            fullWidth={true}
                                            name="audit_Approved_Date"
                                            onChange={this.onDateChange}
                                            label="Audit Approved Date"
                                            value={data.audit_Approved_Date ?? ""}
                                            errorText={errors["audit_Approved_Date"] ? errors["audit_Approved_Date"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6} className="text-center">
                                        <strong>Audit Form</strong>
                                        <LADropDone onFileDrop={AuditForm} />
                                        {data.audit_Form_Name && data.audit_Form_Name.length > 0
                                            && <LALinkButton className="pl-5" label={data.audit_Form_Name}
                                                onClick={AuditFormDownload} />}
                                        {data.audit_Form_Name && <LAIconButton
                                            name="audit_Form_Name"
                                            label="Remove"
                                            icon={<DeleteIcon />}
                                            onClick={this.onFormRemove}
                                        />}
                                    </LAGridItem>
                                    
                                    <LAGridItem xs={6} className="text-center">
                                        <strong>safety Fitness Certificate File</strong>
                                        <LADropDone onFileDrop={SafetyFitnessCertificateFile} />
                                        {data.safety_Fitness_Certificate_File_Name && data.safety_Fitness_Certificate_File_Name.length > 0
                                            && <LALinkButton className="pl-5" label={data.safety_Fitness_Certificate_File_Name}
                                                onClick={SafetyFitnessCertificateFileDownload} />}
                                        {data.safety_Fitness_Certificate_File_Name && <LAIconButton
                                            name="safety_Fitness_Certificate_File_Name"
                                            label="Remove"
                                            icon={<DeleteIcon />}
                                            onClick={this.onFormRemove}
                                        />}
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                    <LADateTimePicker
                                            fullWidth={true}
                                            name="safety_Fitness_Expiry"
                                            onChange={this.onDateChange}
                                            label="Safety Fitness Expiry"
                                            value={data.safety_Fitness_Expiry ?? ""}
                                            errorText={errors["safety_Fitness_Expiry"] ? errors["safety_Fitness_Expiry"].message : undefined}
                                        />                                       
                                    </LAGridItem>

                                </LAGrid>
                            </LAExpansionPanel>
                        </LAGridItem>

                        <LAGridItem xs={12}>
                            <LAExpansionPanel color={DRY_PLANT_FORM_TILE_COLOR} textColor={WHITE_COLOR} label="FLEET" expanded={expanded.includes(ICarrierFormSection.Fleet)} onClick={handleFleet}>
                                <LAGrid>

                                    <LAGridItem xs={6}>
                                        <LATextField
                                            fullWidth={true}
                                            name="truck_Code_Out"
                                            onChange={this.handleChange}
                                            label="Truck Code Out"
                                            value={data.truck_Code_Out ?? ""}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LATextField
                                            fullWidth={true}
                                            name="truck_Code_IN"
                                            onChange={this.handleChange}
                                            label="Truck Code IN"
                                            value={data.truck_Code_IN ?? ""}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LATextArea
                                            rowsMax={1}
                                            fullWidth={true}
                                            name="no_of_Trailers"
                                            onChange={this.handleNumberField}
                                            label="No of Trailers"
                                            value={data.no_of_Trailers ?? ""}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6} className="text-center">
                                        <strong>Carrier Agreement File</strong>
                                        <LADropDone onFileDrop={CarrierAgreementFile} />
                                        {data.carrier_Agreement_File_Name && data.carrier_Agreement_File_Name.length > 0
                                            && <LALinkButton className="pl-5" label={data.carrier_Agreement_File_Name}
                                                onClick={CarrierAgreementFileDownload} />}
                                        {data.carrier_Agreement_File_Name && <LAIconButton
                                            name="carrier_Agreement_File_Name"
                                            label="Remove"
                                            icon={<DeleteIcon />}
                                            onClick={this.onFormRemove}
                                        />}
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LADateTimePicker
                                            fullWidth={true}
                                            name="carrier_Agreement_Date"
                                            onChange={this.onDateChange}
                                            label="Carrier Agreement Date"
                                            value={data.carrier_Agreement_Date ?? ""}
                                            errorText={errors["carrier_Agreement_Date"] ? errors["carrier_Agreement_Date"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LADateTimePicker
                                            fullWidth={true}
                                            name="rate_Scheduled_Date"
                                            onChange={this.onDateChange}
                                            label="Rate Schedule Date"
                                            value={data.rate_Scheduled_Date ?? ""}
                                            errorText={errors["rate_Scheduled_Date"] ? errors["rate_Scheduled_Date"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6} className="text-center">
                                        <strong>Rate Schedule File</strong>
                                        <LADropDone onFileDrop={RateScheduleFile} />
                                        {data.rate_Scheduled_File_Name && data.rate_Scheduled_File_Name.length > 0
                                            && <LALinkButton className="pl-5" label={data.rate_Scheduled_File_Name}
                                                onClick={RateScheduleFileDownload} />}
                                        {data.rate_Scheduled_File_Name && <LAIconButton
                                            name="rate_Scheduled_File_Name"
                                            label="Remove"
                                            icon={<DeleteIcon />}
                                            onClick={this.onFormRemove}
                                        />}
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LADateTimePicker
                                            fullWidth={true}
                                            name="maintenance_Inspection_Policy_Date"
                                            onChange={this.onDateChange}
                                            label="Maintenance Inspection Policy Date"
                                            value={data.maintenance_Inspection_Policy_Date ?? ""}
                                            errorText={errors["maintenance_Inspection_Policy_Date"] ? errors["maintenance_Inspection_Policy_Date"].message : undefined}
                                        />
                                    </LAGridItem>
                                    
                                    <LAGridItem xs={6} className="text-center">
                                        <strong>Maintenance Inspection Policy File</strong>
                                        <LADropDone onFileDrop={MaintenanceInspectionPolicyFile} />
                                        {data.maintenance_Inspection_Policy_File_Name && data.maintenance_Inspection_Policy_File_Name.length > 0
                                            && <LALinkButton className="pl-5" label={data.maintenance_Inspection_Policy_File_Name}
                                                onClick={MaintenanceInspectionPolicyFileDownload} />}
                                        {data.maintenance_Inspection_Policy_File_Name && <LAIconButton
                                            name="maintenance_Inspection_Policy_File_Name"
                                            label="Remove"
                                            icon={<DeleteIcon />}
                                            onClick={this.onFormRemove}
                                        />}
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LATextArea
                                            rowsMax={1}
                                            fullWidth={true}
                                            label="Site Qualified Drivers"
                                            onChange={this.handleNumberField}
                                            name="no_of_Site_Qualified_Drivers"
                                            value={data.no_of_Site_Qualified_Drivers ?? ""}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6} className="text-center">
                                        <strong>Fleet Truck File</strong>
                                        <LADropDone onFileDrop={FleetTruckFile} />
                                        {data.fleet_Trucks_List_File_Name && data.fleet_Trucks_List_File_Name.length > 0
                                            && <LALinkButton className="pl-5" label={data.fleet_Trucks_List_File_Name}
                                                onClick={FleetTruckFileDownload} />}
                                        {data.fleet_Trucks_List_File_Name && <LAIconButton
                                            name="fleet_Trucks_List_File_Name"
                                            label="Remove"
                                            icon={<DeleteIcon />}
                                            onClick={this.onFormRemove}
                                        />}
                                    </LAGridItem>

                                </LAGrid>
                            </LAExpansionPanel>
                        </LAGridItem>

                        <LAGridItem xs={12}>
                            <LAExpansionPanel color={DRY_PLANT_FORM_TILE_COLOR} textColor={WHITE_COLOR} label="ADMIN" expanded={expanded.includes(ICarrierFormSection.Admin)} onClick={handleAdmin}>
                                <LAGrid>

                                    <LAGridItem xs={6}>
                                        <LATextArea
                                            rowsMax={1}
                                            fullWidth={true}
                                            name="Status"
                                            onChange={this.handleChange}
                                            label="Status"
                                            value={data.Status ?? ""}
                                        />
                                    </LAGridItem>

                                </LAGrid>
                            </LAExpansionPanel>
                        </LAGridItem>

                        <LAGridItem xs={12}>
                            <LAPaperWithPadding>
                                {sError.length > 0 && <LAGridItem className="text-center" xs={12}>
                                    <LAErrorBox text={sError} />
                                </LAGridItem>}

                                <LASaveAndCancelButton
                                    onSave={this.handleSave}
                                    onCancel={this.handleCancel}
                                    disableSave={Object.values(errors).length > ZEROTH ? true : undefined}
                                />
                            </LAPaperWithPadding>
                        </LAGridItem>

                    </LAGrid>

                    <Prompt when={cancelPopup} message="Any unsaved changes will be lost" />
                    <RequestStatus requestStatus={SaveCarrierFormStatus.kind} successMessage="New Form successfully saved" />
                    <RequestStatus requestStatus={UpdateCarrierFormStatus.kind} successMessage="Form successfully updated" />
                </SilRoleCheck>
            </CarrierFormComponentStyles>
        );
    }

    private onDateChange = (name: string, value: string): void => {
        const date = new Date(value); 
        let errors = { ...this.state.errors };

        if(date.toString() === "Invalid Date"){
            if(!errors[name]){
                errors[name] = { key: name, message: "Invalid Date" };
            }
        } else {
            if(errors[name])
                delete errors[name];
        };

        if (requiredFields.includes(name)) {
            errors = this.errorChecker(name, value, errors, 1, true);
        };
        
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [name]: value
            },
            errors,
            cancelPopup: true
        });
    };

    private onGenerate = (): void => {
        let errors = this.state.errors;
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                password: generateRandomText(Math.floor(Math.random() * (8 - 6 + 1)) + 6)
            },
            errors,
            cancelPopup: true
        });
    };

    private getData = (): void => {
        const query = queryString.parse(this.props.location.search);
        const id = query.id ?? "0";

        this.callServer();

        if (id === "0") {
            let errors = { ...this.state.errors };
            requiredFields.forEach(x => {
                errors = this.errorChecker(x, "", errors, 1, true);
            });
            this.setState({ errors });
        };

        if (hasPayload(this.props.getCarriers) && id !== "0") {
            const data = this.props.getCarriers.payload.response[Number(id)];
            this.setState({ data });
        };
    };

    private callServer = (): void => {
        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getCarriers)){
            this.props.getCarriersRequest({
                token: this.props.getToken.payload.response.token
            });
        }

        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });
    };

    private handleCancel = (): void => {
        this.props.history.push(ROUTE.SIL.CARRIERS.CARRIERS);
    };

    private handleSave = (): void => {
        const data =  this.state.data;
        if (hasPayload(this.props.getToken)) {
            const { id } = data;
            data.modified_By = userName;
            if ((id === undefined) || (id === 0)) {
                this.props.RequestSaveCarrierForm({
                    token: this.props.getToken.payload.response.token,
                    request: data
                });
            } else {
                this.props.RequestUpdateCarrierForm({
                    token: this.props.getToken.payload.response.token,
                    request: data
                });
            }

            this.setState({ cancelPopup: false });
        }
    };

    private onFormRemove = (event?: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined, name?: string): void => {
        if (name) {
            const selection = window.confirm(deleteText);
            if(selection) {
                this.setState({
                    ...this.state,
                    data: {
                        ...this.state.data,
                        [name]: undefined,
                        [name + "_Name"]: undefined
                    },
                    cancelPopup: true
                });
            }
        }
    };

    private downloadFile = (name?: string, base64?: string, objName?: string): void => {

        if (name) {
            if ((base64 !== undefined) && (base64 !== null)) {
                let a = document.createElement("a");
                a.href = base64;
                a.download = name;
                a.click();
            };

            if ((base64 === undefined) || (base64 === null)) {

                if ((objName !== undefined) || (objName !== null)) {
                    if (hasPayload(this.props.getToken) && this.state.data.id) {

                        fetch(END_POINTS.PROPSENSE.SIL.CARRIER.GET_CARRIER_FILE, {
                            method: "POST",
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify({
                                request: {
                                    "Carrier_ID": this.state.data.id,
                                    "File": objName
                                },
                                token: this.props.getToken.payload.response.token
                            })
                        })
                            .then((res: any) => {
                                return res.blob();
                            })
                            .then(blob => {

                                var url = window.URL.createObjectURL(blob);
                                var a = document.createElement('a');
                                a.href = url;
                                a.download = name;
                                document.body.appendChild(a);
                                a.click();
                                a.remove();
                            })
                            .catch((res) => console.log(res));
                    };
                };
            };
        }
    };

    private uploadFile = (name: string, event?: any): void => {
        new Promise((resolve, reject): any => {
            if (event && (event !== null)) {
              
            Object.values(event).forEach((x: any, index, array) => {

                if (x !== undefined) {
                    const reader = new FileReader();

                    reader.onloadend = async (e): Promise<void> => {
                        if (reader.result !== null) {
                            this.setState({
                                ...this.state,
                                data: {
                                    ...this.state.data,
                                    [name + "_Name"]: x.name,
                                    [name]: reader.result
                                },
                                cancelPopup: true
                            });
                            resolve(true);
                        };
                    };
                    reader.readAsDataURL(x);
                }
            })
        } else {
            reject();
        }
        })
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, minLength: number, isRequired: boolean, email?: true): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: isRequired ? true : undefined, minLength, email });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        }
        return err;
    };


    private handleCheckBoxChange = (value: boolean, name?: string): void => {
        if (name) {

            let errors = this.state.errors;
            if (name === "approved") {
                if (value === true)
                    delete errors["unApproved_Reason"];
                if (value === false)
                    errors["unApproved_Reason"] = { key: "unApproved_Reason", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
            };

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    [name]: value === true ? "Yes" : "No"
                },
                errors,
                cancelPopup: true
            });
        }
    };

    private handleChange = (name: string, value: string): void => {
        let errors = { ...this.state.errors };

        if (requiredFields.includes(name)) {
            errors = this.errorChecker(name, value, errors, 1, true);
        };

        // if (name.includes("Email") || name.includes("email")) {
        //     errors = this.errorChecker(name, value, errors, 1, true, true);
        // };

        if (name === "unApproved_Reason") {
            if (value.length > ZEROTH)
                delete errors["unApproved_Reason"];

            if (value.length === ZEROTH)
                errors["unApproved_Reason"] = { key: "unApproved_Reason", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
        }

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [name]: value
            },
            errors,
            cancelPopup: true
        });
    };

    private handleNumberField = (name: string, value: string): void => {
        if (!isNaN(Number(value))) {
            let errors = { ...this.state.errors };

            if (name.includes("phone")) {
                errors = this.errorChecker(name, value, errors, 10, true);
            };

            if (requiredFields.includes(name)) {
                errors = this.errorChecker(name, value, errors, 1, true);
            };

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    [name]: Number(value)
                },
                errors,
                cancelPopup: true
            });
        };
    };

    private handleExpand = (expanded: number): void => {
        const iS = [...this.state.expanded];

        if (this.state.expanded.includes(expanded)) {
            iS.splice(iS.findIndex(x => x === expanded), 1);
        } else {
            iS.push(expanded);
        };

        this.setState({ expanded: iS });
    };

};

const mapStateToProps = (state: IStore): ICarrierFormComponentStoreProps => ({
    getToken: getTokenStatus(state),
    getCarriers: getCarriersStatus(state),
    SaveCarrierFormStatus: getSaveCarrierFormStatus(state),
    UpdateCarrierFormStatus: getUpdateCarrierFormStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): ICarrierFormComponentDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getCarriersRequest: (data: IGetCarriersRequest) => dispatch(getCarriersLoadAction(data)),
    RequestSaveCarrierForm: (data: ISaveCarrierFormRequest): unknown => dispatch(saveCarrierFormLoadAction(data)),
    RequestUpdateCarrierForm: (data: IUpdateCarrierFormRequest): unknown => dispatch(updateCarrierFormLoadAction(data))
});


export default connect(mapStateToProps, mapDispatchToProps)(CarrierFormComponent);


export interface ICarrierForm extends ICompanyForm {

};

interface ICompanyForm extends IOwnerForm {
    id?: number;
    name?: string;
    alternate_Name?: string;
    active?: string;
    approved?: string;
    is_YHD_Carrier?: string;
    approved_Date?: string;
    unApproved_Reason?: string;
    uses_Sil_Trailers?: string;
    mailing_Address?: string;
    city?: string;
    province?: string;
    postal_Code?: string;
    phone?: string;
    email?: string;
    service_Email?: string;
    notes?: string;
    modified: string;
    modified_By: string;
    created: string;
    created_By: string;
};

interface IOwnerForm extends IDispatchForm {
    owner_Name?: string;
    owner_Email?: string;
    owner_Phone?: string;
    registration_Form?: string;
    registration_Form_Name?: string;
};

interface IDispatchForm extends IInsuranceForm {
    dispatch_Contact_Name?: string;
    dispatch_Email?: string;
    dispatch_Phone_Cell?: string;
    dispatch_Phone_Work?: string;
    dispatch_Contact_After_Hours?: string;
    dispatch_Phone_After_Hours?: string;
    username?: string;
    password?: string;
    aD_Category?: number;
    prorated_BC?: number;
    prorated_SK?: number;
    hexCode: string;
};

interface IInsuranceForm extends IOtherForm {
    gst?: string;
    wcb?: string;
    wcB_Clearance_Letter_Date?: string;
    wcB_Clearance_Letter_File?: string;
    wcB_Clearance_Letter_File_Name?: string;
    insurance_Certificate_Date?: string;
    insurance_Certificate_File?: string;
    insurance_Certificate_File_Name?: string;
    insurance_Expiry?: string;
};

interface IOtherForm extends IFleetForm {
    last_Verified?: string;
    carrier_Profile?: string;
    carrier_Profile_File?: string;
    carrier_Profile_File_Name?: string;
    corporate_Search?: string;
    corporate_Search_File?: string;
    corporate_Search_File_Name?: string;
    audit_Completed_Date?: string;
    audit_Approved_Date?: string;
    audit_Form?: string;
    audit_Form_Name?: string;
    safety_Fitness_Certificate_File?: string;
    safety_Fitness_Certificate_File_Name?: string;
    safety_Fitness_Expiry?: string;
};

interface IFleetForm extends IAdminForm {
    truck_Code_Out?: string;
    truck_Code_IN?: string;
    no_of_Trailers?: number;
    carrierAgreementFileBase?: string;
    carrier_Agreement_Date?: string;
    carrier_Agreement_File?: string;
    carrier_Agreement_File_Name?: string;
    rate_Scheduled_Date?: string;
    rate_Scheduled_File?: string;
    rate_Scheduled_File_Name?: string;
    maintenance_Inspection_Policy_Date?: string;
    maintenance_Inspection_Policy_File?: string;
    maintenance_Inspection_Policy_File_Name?: string;
    fleet_Trucks_List_File?: string;
    fleet_Trucks_List_File_Name?: string;
    no_of_Site_Qualified_Drivers?: string;
};

interface IAdminForm {
    Status?: string;
};