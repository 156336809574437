import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { getWashPlantFeedMaterialsLoadAction } from "../../../../redux/sil/washPlant/getWashPlantFeedMaterials/getWashPlantFeedMaterialsActions";
import { IWashPlantFeedMaterials, IGetWashPlantFeedMaterialsRequest } from "../../../../redux/sil/washPlant/getWashPlantFeedMaterials/getWashPlantFeedMaterialsConstaints";
import { hasPayload, isNotLoaded, Server } from "../../../../redux/server";
import { SilAPIResponse } from "../../../shared/publicInterfaces";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import { callRouteWithQueryString, userName } from "../../../shared/constExports";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { getWashPlantFeedMaterialsStatus } from "../../../../redux/sil/washPlant/getWashPlantFeedMaterials/getWashPlantFeedMaterialsAccessor";
import { LAButton } from "../../../shared/buttons";
import { AddIcon } from "../../../shared/icons";
import { WHITE_COLOR } from "../../../shared/theme";
import { ROUTE } from "../../../routes";
import PageSpacing from "../../../shared/pageSpacing";
import SilRoleCheck from "../../../shared/silRoleCheck";


interface IWashPlantFeedMaterialsStoreProps {
    getToken: Server<SilAPIResponse<IToken>>;
    getFeedMaterials: Server<SilAPIResponse<IWashPlantFeedMaterials[]>>;
};

interface IWashPlantFeedMaterialsDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getFeedMaterialsRequest: (data: IGetWashPlantFeedMaterialsRequest) => unknown;
};


interface IWashPlantFeedMaterialsOwnProps {

};

interface IWashPlantFeedMaterialsState {

};

const WashPlantFeedMaterialsStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
    };
`;


type IWashPlantFeedMaterialsProps = RouteComponentProps
    & IWashPlantFeedMaterialsStoreProps
    & IWashPlantFeedMaterialsDispatchProps
    & IWashPlantFeedMaterialsOwnProps;

class WashPlantFeedMaterials extends PureComponent<IWashPlantFeedMaterialsProps, IWashPlantFeedMaterialsState> {

    public constructor(props: IWashPlantFeedMaterialsProps) {
        super(props);
        this.state = {

        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: IWashPlantFeedMaterialsProps): void {
        if (this.props.getToken !== prevProps.getToken) {
            this.callServer();
        }
    };

    public render(): ReactNode {

        const { getFeedMaterials } = this.props;
        const data = hasPayload(getFeedMaterials) ? getFeedMaterials.payload.response : [];

        return (
            <PageSpacing title="SIL - Feed Materials" description="SIL - Feed Materials" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="washPlantAccess">
                    <WashPlantFeedMaterialsStyles>

                        <LAButton className="pull-left" startIcon={<AddIcon color={WHITE_COLOR} />} label="Add" onClick={this.handleAdd} />
                        <h2 className="text-center">FEED MATERIALS</h2>
                        <hr />

                        <LADevExtremeGrid
                            data={data}
                            onEdit={this.onEdit}
                            onClick={this.onClick}
                            searchPanel={true}
                            filterHeader={true}
                            export={true}
                            exportFileName={"Feed Materials"}
                            storageKey="silWashPlantFeedMaterialsStorageKey"
                            columns={[
                                { name: "feed_Material", caption: "Feed Material", type: "string", width: 180 },
                                { name: "product_Code", caption: "Product Code", type: "string" },
                                { name: "active", caption: "Active", type: "string" },
                                { name: "created", caption: "Created", type: "date" },
                                { name: "modified", caption: "Modified", type: "date" },
                                { name: "created_By", caption: "Created By", type: "string" },
                                { name: "modified_By", caption: "Modified By", type: "string" }
                            ]}
                        />
                    </WashPlantFeedMaterialsStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString() },
            pathName: ROUTE.SIL.WASH_PLANT.WASH_PLANT_FEED_MATERIAL
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0" },
            pathName: ROUTE.SIL.WASH_PLANT.WASH_PLANT_FEED_MATERIAL
        });
    };

    private callServer = (): void => {
        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getFeedMaterials))
            this.props.getFeedMaterialsRequest({
                token: this.props.getToken.payload.response.token
            });


        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });
    };

}

const mapStateToProps = (state: IStore): IWashPlantFeedMaterialsStoreProps => ({
    getToken: getTokenStatus(state),
    getFeedMaterials: getWashPlantFeedMaterialsStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IWashPlantFeedMaterialsDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getFeedMaterialsRequest: (data: IGetWashPlantFeedMaterialsRequest) => dispatch(getWashPlantFeedMaterialsLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(WashPlantFeedMaterials);