import { IBeltScale } from "../getBeltScales/getBeltScalesConstaints";

export enum IUPDATE_BELT_SCALE_REQUEST {
    REQUEST = "updateBeltScale/UPDATE_BELT_SCALE_REQUEST",
    SUCCESS = "updateBeltScale/UPDATE_BELT_SCALE_SUCCESS",
    FAILED = "updateBeltScale/UPDATE_BELT_SCALE_FAILED"
};

export interface IUpdateBeltScaleRequest {
    token: string;
    request: IBeltScale;
};