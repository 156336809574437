import { ILabScale } from "../getLabScales/getLabScalesConstaints";

export enum IUPDATE_LAB_SCALE_REQUEST {
    REQUEST = "updateLabScale/UPDATE_LAB_SCALE_REQUEST",
    SUCCESS = "updateLabScale/UPDATE_LAB_SCALE_SUCCESS",
    FAILED = "updateLabScale/UPDATE_LAB_SCALE_FAILED"
};

export interface IUpdateLabScaleRequest {
    token: string;
    request: ILabScale;
};