import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IGET_PRODUCTS_BY_ID_REQUEST, ISIMOSProductsById } from "./getProductsByIdConstaints";
import { IGetProductsByIdLoadAction, IGetProductsByIdLoadFailedAction, IGetProductsByIdSuccessAction } from "./getProductsByIdActions";
import { SimosAPIResponse } from "../../../../react/shared/publicInterfaces";

type Actions =
    IGetProductsByIdLoadAction
    | IGetProductsByIdLoadFailedAction
    | IGetProductsByIdSuccessAction
    | IFlushDataSuccessAction;

export const GetProductsByIdReducer = (state: Server<SimosAPIResponse<ISIMOSProductsById[]>> = notLoaded, action: Actions): Server<SimosAPIResponse<ISIMOSProductsById[]>> => {
    switch (action.type) {
        case IGET_PRODUCTS_BY_ID_REQUEST.REQUEST:
            return loading;

        case IGET_PRODUCTS_BY_ID_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_PRODUCTS_BY_ID_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};