import { IPassTokenWithRequest } from "../../dryPlant/token/getTokenConstants";

export enum IGET_CARRIER_LOOKUPS_REQUEST {
    REQUEST = "getCarrierLookups/GET_CARRIER_LOOKUPS_REQUEST",
    SUCCESS = "getCarrierLookups/GET_CARRIER_LOOKUPS_SUCCESS",
    FAILED = "getCarrierLookups/GET_CARRIER_LOOKUPS_FAILED"
};

export interface IGetCarrierLookupsRequest extends IPassTokenWithRequest {
    requested_Page: string;
};

export interface ICarrierLookup {
    id: number;
    name: string;
};