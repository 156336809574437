import { IPassTokenWithRequest } from "../../dryPlant/token/getTokenConstants";
import { ISieveProduct } from "../getSievesProducts/getSievesProductsConstaints";

export enum IADD_SIEVE_PRODUCT_REQUEST {
    REQUEST = "addSieveProduct/ADD_SIEVE_PRODUCT_REQUEST",
    SUCCESS = "addSieveProduct/ADD_SIEVE_PRODUCT_SUCCESS",
    FAILED = "addSieveProduct/ADD_SIEVE_PRODUCT_FAILED"
};

export interface IAddSieveProductRequest extends IPassTokenWithRequest {
    request: IAddSieveProduct;
};

export interface IAddSieveProduct extends ISieveProduct {
};