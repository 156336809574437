import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IGetYHDCarrierEmailsLoadAction, IGetYHDCarrierEmailsLoadFailedAction, IGetYHDCarrierEmailsSuccessAction, 
    getYHDCarrierEmailsLoadFailedAction, getYHDCarrierEmailsLoadSuccessAction
} from "./getCarrierEmailsActions";
import { IStore } from "../../../../reducers";
import { EpicDependencies } from "../../../../store";
import { IGET_YHD_CARRIER_EMAILS_REQUEST, IYHDCarrierEmail } from "./getCarrierEmailsConstaints";
import { END_POINTS } from "../../../../endpoints";
import { withJsonContentType } from "../../../../epicUtils";
import { YHDAPIResponse } from "../../../../../react/shared/publicInterfaces";

export const getYHDCarrierEmailsEpic: Epic = (
    action$: ActionsObservable<IGetYHDCarrierEmailsLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IGetYHDCarrierEmailsSuccessAction | IGetYHDCarrierEmailsLoadFailedAction> =>
    action$.ofType(IGET_YHD_CARRIER_EMAILS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<YHDAPIResponse<IYHDCarrierEmail[]>>(
                    END_POINTS.PROPSENSE.YHD.CARRIER.GET_CARRIER_EMAIL,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: YHDAPIResponse<IYHDCarrierEmail[]>): IGetYHDCarrierEmailsSuccessAction => {
                            return getYHDCarrierEmailsLoadSuccessAction(response);
                        }),
                        catchError(() => ActionsObservable.of(getYHDCarrierEmailsLoadFailedAction("Unable to get carrier emails")))
                    )
            )
        );