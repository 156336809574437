import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IUpdateComplaintLoggingLoadAction, IUpdateComplaintLoggingLoadFailedAction, IUpdateComplaintLoggingSuccessAction, 
    updateComplaintLoggingLoadFailedAction, updateComplaintLoggingLoadSuccessAction
} from "./updateComplaintLoggingActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IUPDATE_COMPLAINT_LOGGING_REQUEST } from "./updateComplaintLoggingConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";

export const updateComplaintLoggingEpic: Epic = (
    action$: ActionsObservable<IUpdateComplaintLoggingLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IUpdateComplaintLoggingSuccessAction | IUpdateComplaintLoggingLoadFailedAction> =>
    action$.ofType(IUPDATE_COMPLAINT_LOGGING_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<string>>(
                    END_POINTS.PROPSENSE.SIL.COMPLAINT_LOGGING.UPDATE_COMPLAINT_LOGGING,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<string>): IUpdateComplaintLoggingSuccessAction | IUpdateComplaintLoggingLoadFailedAction => {
                            if(response.message === "Success"){
                                return updateComplaintLoggingLoadSuccessAction(response.message);
                            };
                            return updateComplaintLoggingLoadFailedAction(response.message)
                        }),
                        catchError(() => ActionsObservable.of(updateComplaintLoggingLoadFailedAction("Unable to update sieves product")))
                    )
            )
        );