import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IGetBinAdjustmentsLoadAction, IGetBinAdjustmentsLoadFailedAction, IGetBinAdjustmentsSuccessAction, 
    getBinAdjustmentsLoadFailedAction, getBinAdjustmentsLoadSuccessAction
} from "./getBinAdjustmentsActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IGET_BIN_ADJUSTMENTS_REQUEST, IBinAdjustments } from "./getBinAdjustmentsConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";

export const getBinAdjustmentsEpic: Epic = (
    action$: ActionsObservable<IGetBinAdjustmentsLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IGetBinAdjustmentsSuccessAction | IGetBinAdjustmentsLoadFailedAction> =>
    action$.ofType(IGET_BIN_ADJUSTMENTS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<IBinAdjustments[]>>(
                    END_POINTS.PROPSENSE.SIL.DRY_PLANT.GET_DRY_PLANT_BIN_ADJUSTMENT,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<IBinAdjustments[]>): IGetBinAdjustmentsSuccessAction => {
                            return getBinAdjustmentsLoadSuccessAction(response);
                        }),
                        catchError(() => ActionsObservable.of(getBinAdjustmentsLoadFailedAction("Unable to get bin adjustments")))
                    )
            )
        );