import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetRunMaterialsRequest, IGET_RUN_MATERIALS_REQUEST, IRunMaterials } from "./getRunMaterialsConstaints";

export interface IGetRunMaterialsLoadAction {
    type: IGET_RUN_MATERIALS_REQUEST.REQUEST;
    data: IGetRunMaterialsRequest
}
export const getRunMaterialsLoadAction = (data: IGetRunMaterialsRequest): IGetRunMaterialsLoadAction => ({
    type: IGET_RUN_MATERIALS_REQUEST.REQUEST,
    data
});

export interface IGetRunMaterialsSuccessAction {
    type: IGET_RUN_MATERIALS_REQUEST.SUCCESS;
    data: SilAPIResponse<IRunMaterials[]>;
}
export const getRunMaterialsLoadSuccessAction = (data: SilAPIResponse<IRunMaterials[]>): IGetRunMaterialsSuccessAction => ({
    type: IGET_RUN_MATERIALS_REQUEST.SUCCESS,
    data
});

export interface IGetRunMaterialsLoadFailedAction {
    type: IGET_RUN_MATERIALS_REQUEST.FAILED;
    message: string;
}
export const getRunMaterialsLoadFailedAction = (message: string): IGetRunMaterialsLoadFailedAction => ({
    type: IGET_RUN_MATERIALS_REQUEST.FAILED,
    message
});
