
import { SilAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../../server";
import { IUpdateCarrierAuditLoadAction, IUpdateCarrierAuditLoadFailedAction, IUpdateCarrierAuditSuccessAction } from "./updateAuditActions";
import { IUPDATE_CARRIER_AUDIT_REQUEST } from "./updateAuditConstants";

type Actions =
    | IUpdateCarrierAuditLoadAction
    | IUpdateCarrierAuditSuccessAction
    | IUpdateCarrierAuditLoadFailedAction
    | IFlushDataSuccessAction;

export const UpdateCarrierAuditReducer = (state: Server<SilAPIResponse<string>> = notLoaded, action: Actions): Server<SilAPIResponse<string>> => {
    switch (action.type) {
        case IUPDATE_CARRIER_AUDIT_REQUEST.REQUEST:
            return loading;

        case IUPDATE_CARRIER_AUDIT_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IUPDATE_CARRIER_AUDIT_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};