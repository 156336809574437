
import { SilAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { IGetChemicalAnalysesRequest, IGET_CHEMICAL_ANALYSES_REQUEST, IChemicalAnalyses } from "./getChemicalAnalysesConstaints";

export interface IGetChemicalAnalysesLoadAction {
    type: IGET_CHEMICAL_ANALYSES_REQUEST.REQUEST;
    data: IGetChemicalAnalysesRequest
}
export const getChemicalAnalysesLoadAction = (data: IGetChemicalAnalysesRequest): IGetChemicalAnalysesLoadAction => ({
    type: IGET_CHEMICAL_ANALYSES_REQUEST.REQUEST,
    data
});

export interface IGetChemicalAnalysesSuccessAction {
    type: IGET_CHEMICAL_ANALYSES_REQUEST.SUCCESS;
    data: SilAPIResponse<IChemicalAnalyses[]>;
}
export const getChemicalAnalysesLoadSuccessAction = (data: SilAPIResponse<IChemicalAnalyses[]>): IGetChemicalAnalysesSuccessAction => ({
    type: IGET_CHEMICAL_ANALYSES_REQUEST.SUCCESS,
    data
});

export interface IGetChemicalAnalysesLoadFailedAction {
    type: IGET_CHEMICAL_ANALYSES_REQUEST.FAILED;
    message: string;
}
export const getChemicalAnalysesLoadFailedAction = (message: string): IGetChemicalAnalysesLoadFailedAction => ({
    type: IGET_CHEMICAL_ANALYSES_REQUEST.FAILED,
    message
});
