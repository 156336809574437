import { ILabScale } from "../getLabScales/getLabScalesConstaints";

export enum IADD_LAB_SCALE_REQUEST {
    REQUEST = "addLabScale/ADD_LAB_SCALE_REQUEST",
    SUCCESS = "addLabScale/ADD_LAB_SCALE_SUCCESS",
    FAILED = "addLabScale/ADD_LAB_SCALE_FAILED"
};

export interface IAddLabScaleRequest {
    token: string;
    request: ILabScale;
};