import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IAddGeoBagLoadAction, IAddGeoBagLoadFailedAction, IAddGeoBagSuccessAction, 
    addGeoBagLoadFailedAction, addGeoBagLoadSuccessAction
} from "./addGeoBagActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IADD_GEO_BAG_REQUEST } from "./addGeoBagConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";

export const addGeoBagEpic: Epic = (
    action$: ActionsObservable<IAddGeoBagLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IAddGeoBagSuccessAction | IAddGeoBagLoadFailedAction> =>
    action$.ofType(IADD_GEO_BAG_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<string>>(
                    END_POINTS.PROPSENSE.SIL.DISPATCH.ADD_GEO_BAG,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<string>): IAddGeoBagSuccessAction => {
                            return addGeoBagLoadSuccessAction(response.message);
                        }),
                        catchError(() => ActionsObservable.of(addGeoBagLoadFailedAction("Unable to add geo bag")))
                    )
            )
        );