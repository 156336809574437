import { IPassTokenWithRequest } from "../../../sil/dryPlant/token/getTokenConstants";

export enum IGET_SIMOS_TRUCKS_REQUEST {
    REQUEST = "getSimosTrucks/GET_SIMOS_TRUCKS_REQUEST",
    SUCCESS = "getSimosTrucks/GET_SIMOS_TRUCKS_SUCCESS",
    FAILED = "getSimosTrucks/GET_SIMOS_TRUCKS_FAILED"
};

export interface IGetSimosTrucksRequest extends IPassTokenWithRequest {
    
};

export interface ISimosTrucks {
    id: number;
    size: number;
    truck: string
};
