import { IEmployees } from "../getEmployees/getEmployeesConstaints";

export enum IUPDATE_EMPLOYEE_REQUEST {
    REQUEST = "updateEmployee/UPDATE_EMPLOYEE_REQUEST",
    SUCCESS = "updateEmployee/UPDATE_EMPLOYEE_SUCCESS",
    FAILED = "updateEmployee/UPDATE_EMPLOYEE_FAILED"
};

export interface IUpdateEmployeeRequest {
    token: string;
    request: IEmployees;
};