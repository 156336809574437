import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetProductionLookupsRequest, IProductionLookups } from "../getProductionLookups/getProductionLookupsConstaints";
import { IGET_PRODUCT_LOOKUPS_REQUEST } from "./getProductLookupsConstaints";

export interface IGetProductLookupsLoadAction {
    type: IGET_PRODUCT_LOOKUPS_REQUEST.REQUEST;
    data: IGetProductionLookupsRequest
}
export const getProductLookupsLoadAction = (data: IGetProductionLookupsRequest): IGetProductLookupsLoadAction => ({
    type: IGET_PRODUCT_LOOKUPS_REQUEST.REQUEST,
    data
});

export interface IGetProductLookupsSuccessAction {
    type: IGET_PRODUCT_LOOKUPS_REQUEST.SUCCESS;
    data: SilAPIResponse<IProductionLookups>;
}
export const getProductLookupsLoadSuccessAction = (data: SilAPIResponse<IProductionLookups>): IGetProductLookupsSuccessAction => ({
    type: IGET_PRODUCT_LOOKUPS_REQUEST.SUCCESS,
    data
});

export interface IGetProductLookupsLoadFailedAction {
    type: IGET_PRODUCT_LOOKUPS_REQUEST.FAILED;
    message: string;
}
export const getProductLookupsLoadFailedAction = (message: string): IGetProductLookupsLoadFailedAction => ({
    type: IGET_PRODUCT_LOOKUPS_REQUEST.FAILED,
    message
});
