import { IPassTokenWithRequest } from "../../dryPlant/token/getTokenConstants";


export enum IGET_WASH_PLANT_BIN_ADJUSTMENTS_REQUEST {
    REQUEST = "getWashPlantBinAdjustments/GET_WASH_PLANT_BIN_ADJUSTMENTS_REQUEST",
    SUCCESS = "getWashPlantBinAdjustments/GET_WASH_PLANT_BIN_ADJUSTMENTS_SUCCESS",
    FAILED = "getWashPlantBinAdjustments/GET_WASH_PLANT_BIN_ADJUSTMENTS_FAILED"
};

export interface IGetWashPlantBinAdjustmentsRequest extends IPassTokenWithRequest {

};

export interface IWashPlantBinAdjustments {
    id: number;
    type: "Adjustment" | "Transfer";
    adjustment_Type: "Add" | "Remove";
    bin: string;
    bin_From: string;
    bin_To: string;
    product: string;
    tonnage: number | undefined;
    reason: string;
    transfer_Date?: string;
    created?: string;
    created_By: string;
    modified?: string;
    modified_By: string;
};