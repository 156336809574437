import { IUPDATE_WAREHOUSE_PRODUCT_REQUEST } from "./updateWarehouseProductConstaints";
import { IUpdateWarehouseProductLoadAction, IUpdateWarehouseProductLoadFailedAction, IUpdateWarehouseProductSuccessAction } from "./updateWarehouseProductActions";
import { IFlushDataSuccessAction } from "../../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../../flushData.tsx/flushDataConstants";
import { Server, notLoaded, loading, succeeded, failed } from "../../../../server";

type Actions =
    IUpdateWarehouseProductLoadAction
    | IUpdateWarehouseProductLoadFailedAction
    | IUpdateWarehouseProductSuccessAction
    | IFlushDataSuccessAction;

export const UpdateWarehouseProductReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IUPDATE_WAREHOUSE_PRODUCT_REQUEST.REQUEST:
            return loading;

        case IUPDATE_WAREHOUSE_PRODUCT_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IUPDATE_WAREHOUSE_PRODUCT_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};