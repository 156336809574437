import { IAddFeedMaterialRequest, IADD_FEED_MATERIAL_REQUEST } from "./addFeedMaterialConstaints";

export interface IAddFeedMaterialLoadAction {
    type: IADD_FEED_MATERIAL_REQUEST.REQUEST;
    data: IAddFeedMaterialRequest
}
export const addFeedMaterialLoadAction = (data: IAddFeedMaterialRequest): IAddFeedMaterialLoadAction => ({
    type: IADD_FEED_MATERIAL_REQUEST.REQUEST,
    data
});

export interface IAddFeedMaterialSuccessAction {
    type: IADD_FEED_MATERIAL_REQUEST.SUCCESS;
    message: string;
}
export const addFeedMaterialLoadSuccessAction = (message: string): IAddFeedMaterialSuccessAction => ({
    type: IADD_FEED_MATERIAL_REQUEST.SUCCESS,
    message
});
export interface IAddFeedMaterialLoadFailedAction {
    type: IADD_FEED_MATERIAL_REQUEST.FAILED;
    message: string;
}
export const addFeedMaterialLoadFailedAction = (message: string): IAddFeedMaterialLoadFailedAction => ({
    type: IADD_FEED_MATERIAL_REQUEST.FAILED,
    message
});
