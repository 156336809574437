import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IUpdateGeoBagRequest, IUPDATE_GEO_BAG_REQUEST } from "./updateGeoBagConstaints";

export interface IUpdateGeoBagLoadAction {
    type: IUPDATE_GEO_BAG_REQUEST.REQUEST;
    data: IUpdateGeoBagRequest
}
export const updateGeoBagLoadAction = (data: IUpdateGeoBagRequest): IUpdateGeoBagLoadAction => ({
    type: IUPDATE_GEO_BAG_REQUEST.REQUEST,
    data
});

export interface IUpdateGeoBagSuccessAction {
    type: IUPDATE_GEO_BAG_REQUEST.SUCCESS;
    message: string;
}
export const updateGeoBagLoadSuccessAction = (message: string): IUpdateGeoBagSuccessAction => ({
    type: IUPDATE_GEO_BAG_REQUEST.SUCCESS,
    message
});

export interface IUpdateGeoBagLoadFailedAction {
    type: IUPDATE_GEO_BAG_REQUEST.FAILED;
    message: string;
}
export const updateGeoBagLoadFailedAction = (message: string): IUpdateGeoBagLoadFailedAction => ({
    type: IUPDATE_GEO_BAG_REQUEST.FAILED,
    message
});
