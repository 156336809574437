import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, hasPayload, loading, notLoaded, Server, succeeded } from "../../../server";
import { IGET_WASH_PLANT_RUN_MATERIALS_REQUEST, IWashPlantRunMaterials } from "./getWashPlantRunMaterialsConstaints";
import { IGetWashPlantRunMaterialsLoadAction, IGetWashPlantRunMaterialsLoadFailedAction, IGetWashPlantRunMaterialsSuccessAction } from "./getWashPlantRunMaterialsActions";
import { IUPDATE_WASH_PLANT_RUN_MATERIAL_REQUEST } from "../updateWashPlantRunMaterial/updateWashPlantRunMaterialConstaints";
import { IADD_WASH_PLANT_RUN_MATERIAL_REQUEST } from "../addWashPlantRunMaterial/addWashPlantRunMaterialConstaints";
import { IAddWashPlantRunMaterialSuccessAction } from "../addWashPlantRunMaterial/addWashPlantRunMaterialActions";
import { IUpdateWashPlantRunMaterialSuccessAction } from "../updateWashPlantRunMaterial/updateWashPlantRunMaterialActions";

type Actions =
    IGetWashPlantRunMaterialsLoadAction
    | IGetWashPlantRunMaterialsLoadFailedAction
    | IGetWashPlantRunMaterialsSuccessAction
    | IUpdateWashPlantRunMaterialSuccessAction
    | IAddWashPlantRunMaterialSuccessAction
    | IFlushDataSuccessAction;

export const GetWashPlantRunMaterialsReducer = (state: Server<SilAPIResponse<IWashPlantRunMaterials[]>> = notLoaded, action: Actions): Server<SilAPIResponse<IWashPlantRunMaterials[]>> => {
    switch (action.type) {
        case IGET_WASH_PLANT_RUN_MATERIALS_REQUEST.REQUEST:
            return loading;

        case IGET_WASH_PLANT_RUN_MATERIALS_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_WASH_PLANT_RUN_MATERIALS_REQUEST.FAILED:
            return failed(action.message);

        case IUPDATE_WASH_PLANT_RUN_MATERIAL_REQUEST.SUCCESS:
            if (hasPayload(state)) {
                const currentState = [...state.payload.response];
                const ind = currentState.findIndex(x => x.id === action.data.id);

                if (ind >= 0)
                    currentState[ind] = action.data;

                return {
                    ...state,
                    payload: {
                        ...state.payload,
                        response: currentState
                    }
                };
            }
            return notLoaded;

        case IADD_WASH_PLANT_RUN_MATERIAL_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};