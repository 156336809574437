import React from 'react';
import {FaPencilAlt} from 'react-icons/fa';
import InternalCustomerEdit from './internalCustomerEdit';
import {Multiselect} from 'multiselect-react-dropdown';
import {filterCustomer} from './filterCustomer';
//import { DataGrid} from '@mui/x-data-grid';
//import Button from '@mui/material/Button';
import CustomerDeliveryPopup from './internalDeliveryPopupModal';
import '../../../../shared/simos/styling/gridviewstyles.css';
//import SearchBar from '../../../../shared/simos/helper/sharedSearchBar';
import DataGrid, {
    Column, FilterRow, HeaderFilter, 
    Paging, Button, Pager, SearchPanel, 
    FilterPanel,
    FilterBuilderPopup,
    Export,
  } from 'devextreme-react/data-grid';
  import 'devextreme/dist/css/dx.light.css';
import { getSILURLParams } from '../../../../shared/simos/helper/getURLContainsSIL';

  const renderTitleHeader = (data:any) => {
    return <p style={{ color:getSILURLParams()?.includes("bru")?"#5383d3":"#a73030",font: 'Cookie', fontSize:'14px'}}>{data.column.caption}</p>
}

const filterBuilderPopupPosition = {
    of: window,
    at: 'top',
    my: 'top',
    offset: { y: 10 },
  };
function StyledComponent(cellData:any):any {
    if(cellData.column.dataField === 'customer'){
        if(cellData.data.active === 'No'){
                return <div className={"devex-styled-grid-row-red"}>{cellData.value}</div>;
        }
        if(cellData.data.on_Hold === 'Yes'){
            return <div className={"devex-styled-grid-row-red"}>{cellData.value}</div>;
        }
        return <div className={"devex-styled-grid-row-default"}>{cellData.value}</div>;
    }
    return (
        <div className={"devex-styled-grid-row-default"}>{cellData.value}</div>
    )
}
interface IProps {
    history:any;
}
interface IState {
    customer:any[];
    showCustomerEditForm:boolean;
    deliveryAddr:any[];
    customername_filter:string[];
    customeractive_filter:string[];
    deliveryPopupModal:boolean;
    pageSize:number;
    searchValue:string;
}
var selected:any;
let customerID = [];
var customer_id:number=0;
var globalCustomerID;
var copyedit:any;
var customerscopy:any, filtered:any;
var all_customers:any[] = [];
export default class CustomerTable extends React.Component<IProps,IState>{
    
    constructor(props:any){
        super(props);
        this.state = {
            customer:[],showCustomerEditForm:false,deliveryAddr:[],customername_filter:[],customeractive_filter:["Yes","No"], deliveryPopupModal:false, pageSize:5, searchValue: ''
        }
        this.fetchDeliveryAddress = this.fetchDeliveryAddress.bind(this);
        this.handleCustomerEdit = this.handleCustomerEdit.bind(this);
        this.openPopup = this.openPopup.bind(this);
    }
    closePopup() {
       this.setState({});
    }

    handleCustomerEdit(customerID: number,obj:any){
        console.log(obj)
        globalCustomerID = customerID;
        copyedit=obj;
        this.setState({showCustomerEditForm:true});
        this.props.history.push('/simos/customers/editcustomer/id='+globalCustomerID+getSILURLParams());
        return <InternalCustomerEdit history={this.props.history}editID={globalCustomerID}></InternalCustomerEdit>
    }

    fetchDeliveryAddress() {
        let initialDelivery:string[] = [];
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type':'application/json' }, 
            body: JSON.stringify({token: '19515195-d571-44a3-b0db-637f5bf24f54' })
        }
        fetch('https://api2.propsense.com/api/GetSIMOSCustomerAddress', requestOptions)
        .then(async response => {
            const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
              // error response
              if(!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            // no error
            data.map((deliveryaddr:any) => {
                    initialDelivery.push(deliveryaddr);
            });
            this.setState({deliveryAddr:initialDelivery});
        })
    }

    componentDidMount() {
        let initialCustomers:string[] = [];
        let cname_filters:any=[];
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token: '19515195-d571-44a3-b0db-637f5bf24f54' })
        };
    
        fetch('https://api2.propsense.com/api/GetAllSIMOSCustomers', requestOptions)
        .then(async response => {
            const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
            
            // error response
            if(!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            // no error
            data.map((customername:any) => {
                    initialCustomers.push(customername);
                    customerID.push(customername);
                    cname_filters.push(customername.customer);
            });
            all_customers = initialCustomers;
            this.setState({customer:initialCustomers, customername_filter:cname_filters});
        })
        .catch(error => {
            console.log('There was an error!', error);
        });
        this.fetchDeliveryAddress();
    }
    openPopup(customer_ID:number, cellValues:any){
        selected=cellValues;
        customer_id = customer_ID;
        this.setState({deliveryPopupModal:true});
    }
    closeDeliveryPopup(){
        selected = null;
        // !TODO Create method to call the componentDidMount API call 
        this.setState({deliveryPopupModal:false});
    }
    setPageSize(newPageSize:number){
        this.setState({pageSize: newPageSize});
    }
    setSearchResult(newSearch:any){
        this.setState({customer:newSearch});
    }

    handleValueChange = (e:any) => {
        // console.log(e);
        const trimmedValue = e.trim();
        this.setState({searchValue: trimmedValue});
      };
   
    render() {
        customerscopy = this.state.customer;
        return (
            <div className='grid-view'>
            <br></br><br></br>
            <div style={{width:'100%'}}>
                <DataGrid id={'gridContainer'}
                 focusedRowEnabled={true}
                 hoverStateEnabled={true}
                 rowAlternationEnabled={true}
                    dataSource={this.state.customer}
                    keyExpr="id"
                    showBorders={true}
                    // took out height={740}
                    width={'100%'}> 
                <SearchPanel visible={true}
                width={340}
                placeholder="Search for..." 
                text={this.state.searchValue}
                onTextChange={this.handleValueChange}
                />    
          <Paging enabled={true} defaultPageSize={10}/>
          <Pager
            visible={true}
            allowedPageSizes={[10,15,20]}
            displayMode={'full'}
            showPageSizeSelector={true}
            showInfo={true}
            showNavigationButtons={true} />
            <FilterRow visible={true}></FilterRow>
            <FilterBuilderPopup position={filterBuilderPopupPosition} />
          <HeaderFilter visible={true} allowSearch={true} />
          <FilterPanel visible={true} />
          <Export enabled={true} allowExportSelectedData={true} />
          <Column type="buttons" caption="Edit" headerCellRender={renderTitleHeader}>
              <Button text="edit" visible={true} onClick={(e:any) =>  {this.handleCustomerEdit(e.row.data.id, e.row.data)}}><FaPencilAlt/></Button>
            </Column>
            <Column dataField="customer" width={400} caption="Customer" alignment="left"  cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
            <Column dataField="explorer_Code" width={150} caption="Explorer Code" alignment="left"  cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
            <Column dataField="active" width={300} caption="Active" alignment="left"  cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
            <Column dataField="on_Hold" width={300} caption="On Hold" alignment="left"  cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
            <Column type="buttons" width={600} caption="Delivery Address" alignment="left" headerCellRender={renderTitleHeader}>
            <Button text="view" cssClass="back-btn" visible={true} onClick={(e:any) => {this.openPopup(e.row.data.id, e.row.data)}}>View</Button>
            </Column>
          </DataGrid>
                {this.state.deliveryPopupModal ? <CustomerDeliveryPopup closePopup={this.closeDeliveryPopup.bind(this)} delivery={this.state.deliveryAddr}></CustomerDeliveryPopup>:null}
            <br></br>
            </div>
            </div>
        );
    }
}

export {copyedit};
export {customerscopy, filtered, customer_id};