import { IUPDATE_ORDER_REQUEST, IUpdateOrderRequest } from "./updateOrderConstaints";

export interface IUpdateOrderLoadAction {
    type: IUPDATE_ORDER_REQUEST.REQUEST;
    data: IUpdateOrderRequest;
}
export const updateOrderLoadAction = (data: IUpdateOrderRequest): IUpdateOrderLoadAction => ({
    type: IUPDATE_ORDER_REQUEST.REQUEST,
    data
});

export interface IUpdateOrderSuccessAction {
    type: IUPDATE_ORDER_REQUEST.SUCCESS;
    message: string;
}
export const updateOrderLoadSuccessAction = (message: string): IUpdateOrderSuccessAction => ({
    type: IUPDATE_ORDER_REQUEST.SUCCESS,
    message
});

export interface IUpdateOrderLoadFailedAction {
    type: IUPDATE_ORDER_REQUEST.FAILED;
    message: string;
}
export const updateOrderLoadFailedAction = (message: string): IUpdateOrderLoadFailedAction => ({
    type: IUPDATE_ORDER_REQUEST.FAILED,
    message
});
