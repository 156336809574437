import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetWashPlantRunMaterialsRequest, IGET_WASH_PLANT_RUN_MATERIALS_REQUEST, IWashPlantRunMaterials } from "./getWashPlantRunMaterialsConstaints";

export interface IGetWashPlantRunMaterialsLoadAction {
    type: IGET_WASH_PLANT_RUN_MATERIALS_REQUEST.REQUEST;
    data: IGetWashPlantRunMaterialsRequest
}
export const getWashPlantRunMaterialsLoadAction = (data: IGetWashPlantRunMaterialsRequest): IGetWashPlantRunMaterialsLoadAction => ({
    type: IGET_WASH_PLANT_RUN_MATERIALS_REQUEST.REQUEST,
    data
});

export interface IGetWashPlantRunMaterialsSuccessAction {
    type: IGET_WASH_PLANT_RUN_MATERIALS_REQUEST.SUCCESS;
    data: SilAPIResponse<IWashPlantRunMaterials[]>;
}
export const getWashPlantRunMaterialsLoadSuccessAction = (data: SilAPIResponse<IWashPlantRunMaterials[]>): IGetWashPlantRunMaterialsSuccessAction => ({
    type: IGET_WASH_PLANT_RUN_MATERIALS_REQUEST.SUCCESS,
    data
});

export interface IGetWashPlantRunMaterialsLoadFailedAction {
    type: IGET_WASH_PLANT_RUN_MATERIALS_REQUEST.FAILED;
    message: string;
}
export const getWashPlantRunMaterialsLoadFailedAction = (message: string): IGetWashPlantRunMaterialsLoadFailedAction => ({
    type: IGET_WASH_PLANT_RUN_MATERIALS_REQUEST.FAILED,
    message
});
