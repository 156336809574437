import { IPassTokenWithRequest } from "../../../sil/dryPlant/token/getTokenConstants";

export enum IGET_ORDERS_REQUEST {
    REQUEST = "getOrders/GET_ORDERS_REQUEST",
    SUCCESS = "getOrders/GET_ORDERS_SUCCESS",
    FAILED = "getOrders/GET_ORDERS_FAILED"
};

export interface IGetOrdersRequest extends IPassTokenWithRequest {
    request: {
        Customer: "orderdesk";
        Warehouse_Type: "SIL BRU Warehouse" | "SIL ED Warehouse";
    }
};

export interface ISIMOSOrders {
    id: number;
    customer_ID: number;
    customer: string;
    address: string;
    pickupdate: string;
    pickuphour: string;
    pickupminute: string;
    sitecontact: string;
    sitecontact_Phone: string;
    pO_Number: string;
    emailuser: string;
    emailtransport: string;
    transport_Company: string;
    truck_Required: string;
    deliverydate: string;
    deliveryhour: string;
    deliveryminute: string;
    location: string;
    comments: string;
    packaged: string;
    completed: string;
    cancelled: string;
    truck_Number?: string;
    trailer_Number?: string;
    tare_Weight: string;
    gross_Weight: string;
    created_By: string;
    modified_By: string;
    in_Bin: string;
    pickup: string;
    last_Email_Sent_Timestamp: string;
    created: string;
    modified: string;
    total_Tonnage: number;
    order_Type: string;
    transport_Company_Notes: string;
    pickup_Datetime: string;
    delivery_Datetime: string;
    zone: string;
    explorer_Code: string;
    hired_Truck_Rates: number;
    time_In: string;
    time_Out: string;
    truck_Code: string;
    sales_Order_Number: string;
    sales_Order_Number_ID?: number;
    customer_Job_Number: string;
    status: "Assigned" | "Completed" | "Cancelled" | "Ready for pickup" | "Initiated" | "Loading";
    product_Order: IProductOrder[];
    order_Contact: IOrderContact;
};

export interface IOrderContact {
    Email: string;
    Contact: string;
    Cell: string;
    Phone: string;
    Customer_ID: number;
    // Selected: "Yes" | "No";
};

export interface IProductOrder {
    id: number;
    quantity: number | null;
    size: string;
    per: string;
    product: string;
    code: string;
    cW: string;
    double_CW: string;
    actual_Quantity: number; //Decimal
    batch?: string;
};