import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import { ById, SilAPIResponse } from "../../../shared/publicInterfaces";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import { callRouteWithQueryString, userName } from "../../../shared/constExports";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { LAButton } from "../../../shared/buttons";
import { AddIcon } from "../../../shared/icons";
import { WHITE_COLOR } from "../../../shared/theme";
import { ROUTE } from "../../../routes";
import PageSpacing from "../../../shared/pageSpacing";
import SilRoleCheck from "../../../shared/silRoleCheck";
import LALoading from "../../../shared/loading";
import { IBaggedInventories, IBaggedInventoriesItem, IGetBaggedInventoriesRequest } from "../../../../redux/sil/warehouse/baggedInventory/getBaggedInventories/getBaggedInventoriesConstaints";
import { getBaggedInventoriesStatus } from "../../../../redux/sil/warehouse/baggedInventory/getBaggedInventories/getBaggedInventoriesAccessor";
import { getBaggedInventoriesLoadAction } from "../../../../redux/sil/warehouse/baggedInventory/getBaggedInventories/getBaggedInventoriesActions";
import { IGetPSLookupsRequest, IPSLookup } from "../../../../redux/sil/warehouse/baggedInventory/getSieveLookups/getPSLookupsConstaints";
import { getPSLookupsStatus } from "../../../../redux/sil/warehouse/baggedInventory/getSieveLookups/getPSLookupsAccessor";
import { getPSLookupsLoadAction } from "../../../../redux/sil/warehouse/baggedInventory/getSieveLookups/getPSLookupsActions";
import { BaggedInventoryPopup } from "./baggedInventoryPopup";

interface IBaggedInventoriesStoreProps {
    getToken: Server<SilAPIResponse<IToken>>;
    getBaggedInventoriesList: Server<SilAPIResponse<IBaggedInventories[]>>;
    getPSLookups: Server<SilAPIResponse<IPSLookup>>;
};

interface IBaggedInventoriesDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getBaggedInventoriesListRequest: (data: IGetBaggedInventoriesRequest) => unknown;
    getPSLookupsRequest: (data: IGetPSLookupsRequest) => unknown;
};


interface IBaggedInventoriesOwnProps {

};

interface IBaggedInventoriesState {
    data: IBaggedInventories[];
    popUp: IBaggedInventoriesItem[] | undefined;
};

const BaggedInventoriesStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .loading {
        margin-top: 5%;
    };

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
    };
`;

type IBaggedInventoriesProps = RouteComponentProps
    & IBaggedInventoriesStoreProps
    & IBaggedInventoriesDispatchProps
    & IBaggedInventoriesOwnProps;

class BaggedInventories extends PureComponent<IBaggedInventoriesProps, IBaggedInventoriesState> {

    public constructor(props: IBaggedInventoriesProps) {
        super(props);
        this.state = {
            data: [],
            popUp: undefined
        };
    }

    public componentDidMount(): void {
        if (hasPayload(this.props.getToken))
            this.props.getPSLookupsRequest({
                token: this.props.getToken.payload.response.token,
                Requested_Page: "Bagged_Inventory"
            });
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IBaggedInventoriesProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();
        };
    };


    public render(): ReactNode {

        const { data, popUp } = this.state;
        const { getBaggedInventoriesList } = this.props;

        return (
            <PageSpacing title="SIL - Bagged Inventories" description="SIL - Bagged Inventories" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="warehouseAccess">
                    <BaggedInventoriesStyles>

                        {getBaggedInventoriesList.kind !== STATUS_ENUM.LOADING &&
                            <LAButton className="pull-left" startIcon={<AddIcon color={WHITE_COLOR} />} label="Add" onClick={this.handleAdd} />}
                        <h2 className="text-center">Bagged Inventories</h2>
                        <hr />

                        {getBaggedInventoriesList.kind === STATUS_ENUM.LOADING &&
                            <LALoading className="loading" message="Loading..." />}

                        {getBaggedInventoriesList.kind !== STATUS_ENUM.LOADING && <LADevExtremeGrid
                            data={data}
                            onEdit={this.onEdit}
                            onClick={this.onClick}
                            height={1000}
                            searchPanel={true}
                            filterHeader={true}
                            export={true}
                            onCustomClick={this.handleCustom}
                            storageKey="yhdBaggedInventoriesStorageKey"
                            exportFileName="BaggedInventories"
                            columns={[
                                { name: "date", caption: "Date", type: "date" },
                                { name: "count", caption: "Count", type: "number" },
                                { name: "completed_By", caption: "Completed By", type: "string" },
                                { name: "details", caption: "View Details", type: "button", width: 150 },
                                { name: "created", caption: "Created", type: "datetime" },
                                { name: "created_By", caption: "Created By", type: "string" },
                                { name: "modified", caption: "Modified", type: "datetime" },
                                { name: "modified_By", caption: "Modified By", type: "string" }
                            ]}
                        />}

                        {popUp &&
                            <BaggedInventoryPopup
                                data={popUp}
                                open={popUp ? true : false}
                                onClose={this.handlePopupClose}
                            />}

                    </BaggedInventoriesStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private handlePopupClose = (): void => {
        this.setState({ popUp: undefined });
    };

    private handleCustom = (name: string, column: any): void => {
        const data: IBaggedInventories = column.row.data;
        if (name === "details")
            this.setState({ popUp: data.items });
    };

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString() },
            pathName: ROUTE.SIL.BAGGED_INVENTORIES.BAGGED_INVENTORY
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0" },
            pathName: ROUTE.SIL.BAGGED_INVENTORIES.BAGGED_INVENTORY
        });
    };

    private setDataToState = (): void => {

        if (hasPayload(this.props.getBaggedInventoriesList)) {
            const data: IBaggedInventories[] = Object.values(this.props.getBaggedInventoriesList.payload.response);
            this.setState({ data });
        };

        if (isNotLoaded(this.props.getBaggedInventoriesList))
            this.callServer();
    };

    private callServer = (): void => {
        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getBaggedInventoriesList))
            this.props.getBaggedInventoriesListRequest({
                token: this.props.getToken.payload.response.token
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getPSLookups))
            this.props.getPSLookupsRequest({
                token: this.props.getToken.payload.response.token,
                Requested_Page: "Bagged_Inventory"
            });


        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });
    }

}

const mapStateToProps = (state: IStore): IBaggedInventoriesStoreProps => ({
    getToken: getTokenStatus(state),
    getBaggedInventoriesList: getBaggedInventoriesStatus(state),
    getPSLookups: getPSLookupsStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IBaggedInventoriesDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getBaggedInventoriesListRequest: (data: IGetBaggedInventoriesRequest) => dispatch(getBaggedInventoriesLoadAction(data)),
    getPSLookupsRequest: (data: IGetPSLookupsRequest) => dispatch(getPSLookupsLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(BaggedInventories);