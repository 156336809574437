import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IGetTransloadBOLCountsLoadAction, IGetTransloadBOLCountsLoadFailedAction, IGetTransloadBOLCountsSuccessAction, 
    getTransloadBOLCountsLoadFailedAction, getTransloadBOLCountsLoadSuccessAction
} from "./getTransloadBOLCountsActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IGET_TRANSLOAD_BOL_COUNTS_REQUEST, ITransloadBOLCount } from "./getTransloadBOLCountsConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";

export const getTransloadBOLCountsEpic: Epic = (
    action$: ActionsObservable<IGetTransloadBOLCountsLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IGetTransloadBOLCountsSuccessAction | IGetTransloadBOLCountsLoadFailedAction> =>
    action$.ofType(IGET_TRANSLOAD_BOL_COUNTS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<ITransloadBOLCount[]>>(
                    END_POINTS.PROPSENSE.SIL.DISPATCH.GET_TRANSLOAD_BOL_COUNTS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<ITransloadBOLCount[]>): IGetTransloadBOLCountsSuccessAction => {
                            return getTransloadBOLCountsLoadSuccessAction(response);
                        }),
                        catchError(() => ActionsObservable.of(getTransloadBOLCountsLoadFailedAction("Unable to get transload bol count")))
                    )
            )
        );