import React, { PureComponent, ReactNode } from "react";
import { connect } from "react-redux";
import { IDispatch, IStore } from "../../redux/reducers";
import { hasPayload, Server } from "../../redux/server";
import { getTokenStatus } from "../../redux/sil/dryPlant/token/getTokenAccessor";
import { IToken } from "../../redux/sil/dryPlant/token/getTokenConstants";
import LAErrorBox from "./errorBox";
import LAGrid from "./grid";
import LAGridItem from "./gridList";
import LALoading from "./loading";
import { HasChildren, SilAPIResponse } from "./publicInterfaces";

interface ISilRoleCheck extends HasChildren {
    error: boolean;
    loadingDisable?: true;
    roleFor: "carrierAccess" | "dryPlantAccess" | "washPlantAccess" | 
    "yhdCarrierAccess" | "sieveAccess" | "explorationAccess" | "warehouseAccess"
    | "complaintLoggingAccess" ;
};


interface ISilRoleCheckDispatchProps {
}

interface ISilRoleCheckStoreProps {
    getToken: Server<SilAPIResponse<IToken>>;
}

interface ISilRoleCheckState {
    isLoading?: boolean;
}

type ISilRoleCheckProps =
    ISilRoleCheck &
    ISilRoleCheckDispatchProps &
    ISilRoleCheckStoreProps;

class SilRoleCheck extends PureComponent<ISilRoleCheckProps, ISilRoleCheckState> {


    public constructor(props: ISilRoleCheckProps) {
        super(props);
        this.state = {
            isLoading: true
        };
    };

    public componentDidMount(): void {
        if (hasPayload(this.props.getToken) && this.state.isLoading === true) {
            this.setState({ isLoading: false });
        }
    }

    public componentDidUpdate(prevProps: ISilRoleCheckProps): void {
        if (hasPayload(this.props.getToken) && this.state.isLoading === true) {
            this.setState({ isLoading: false });
        }
    }

    public render(): ReactNode {
        const { isLoading } = this.state;
        const { getToken, error, roleFor, loadingDisable } = this.props;
        const roles = hasPayload(getToken) ? getToken.payload.response : undefined;

        return (
            <>
                {isLoading === false ? roles !== undefined
                    && (roles[roleFor] === true)
                    ?
                    <>
                        {this.props.children}
                    </>
                    :
                    error === true ? <LAErrorBox text="You do not have permission to view this page. Contact your system admin." /> : null
                    : loadingDisable ? null : <LAGrid>
                        <LAGridItem xs={12} className="text-center">
                            <LALoading message="Checking your permission" />
                        </LAGridItem>
                    </LAGrid>
                }
            </>
        )
    }

};

const mapStateToProps = (state: IStore): ISilRoleCheckStoreProps => ({
    getToken: getTokenStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): ISilRoleCheckDispatchProps => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(SilRoleCheck);