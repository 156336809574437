import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IGetSieveWashPlantLoadAction, IGetSieveWashPlantLoadFailedAction, IGetSieveWashPlantSuccessAction, 
    getSieveWashPlantLoadFailedAction, getSieveWashPlantLoadSuccessAction
} from "./getSieveWashPlantsActions";
import { IGET_SIEVE_WASH_PLANTS_REQUEST, ISieveWashPlant } from "./getSieveWashPlantsConstaints";
import { IStore } from "../../../../reducers";
import { EpicDependencies } from "../../../../store";
import { SilAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { END_POINTS } from "../../../../endpoints";
import { withJsonContentType } from "../../../../epicUtils";

export const getSieveWashPlantEpic: Epic = (
    action$: ActionsObservable<IGetSieveWashPlantLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IGetSieveWashPlantSuccessAction | IGetSieveWashPlantLoadFailedAction> =>
    action$.ofType(IGET_SIEVE_WASH_PLANTS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<ISieveWashPlant[]>>(
                    END_POINTS.PROPSENSE.SIL.CV.GetCVSieveWashPlant,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<ISieveWashPlant[]>): IGetSieveWashPlantSuccessAction => {
                            return getSieveWashPlantLoadSuccessAction(response);
                        }),
                        catchError(() => ActionsObservable.of(getSieveWashPlantLoadFailedAction("Unable to get sieve wash plants")))
                    )
            )
        );