import { IEmployees } from "../getEmployees/getEmployeesConstaints";

export enum IADD_EMPLOYEE_REQUEST {
    REQUEST = "addEmployee/ADD_EMPLOYEE_REQUEST",
    SUCCESS = "addEmployee/ADD_EMPLOYEE_SUCCESS",
    FAILED = "addEmployee/ADD_EMPLOYEE_FAILED"
};

export interface IAddEmployeeRequest {
    token: string;
    request: IEmployees;
};