import { ISieveFracBall } from "../getSieveFracBalls/getSieveFracBallsConstaints";

export enum IADD_SIEVE_FRAC_BALL_REQUEST {
    REQUEST = "addSieveFracBall/ADD_SIEVE_FRAC_BALL_REQUEST",
    SUCCESS = "addSieveFracBall/ADD_SIEVE_FRAC_BALL_SUCCESS",
    FAILED = "addSieveFracBall/ADD_SIEVE_FRAC_BALL_FAILED"
};

export interface IAddSieveFracBallRequest {
    token: string;
    request: ISieveFracBall;
};