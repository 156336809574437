import { SilAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { IGetCarrierAudit, IGetCarrierAuditRequest, IGET_CARRIER_AUDIT_REQUEST } from "./getCarrierAuditConstants";

export interface IGetCarrierAuditLoadAction {
    type: IGET_CARRIER_AUDIT_REQUEST.REQUEST;
    data: IGetCarrierAuditRequest
}
export const getCarrierAuditLoadAction = (data: IGetCarrierAuditRequest): IGetCarrierAuditLoadAction => ({
    type: IGET_CARRIER_AUDIT_REQUEST.REQUEST,
    data
});
export interface IGetCarrierAuditSuccessAction {
    type: IGET_CARRIER_AUDIT_REQUEST.SUCCESS;
    list: SilAPIResponse<IGetCarrierAudit[]>;
}
export const getCarrierAuditLoadSuccessAction = (list: SilAPIResponse<IGetCarrierAudit[]>): IGetCarrierAuditSuccessAction => ({
    type: IGET_CARRIER_AUDIT_REQUEST.SUCCESS,
    list
});
export interface IGetCarrierAuditLoadFailedAction {
    type: IGET_CARRIER_AUDIT_REQUEST.FAILED;
    message: string;
}
export const getCarrierAuditLoadFailedAction = (message: string): IGetCarrierAuditLoadFailedAction => ({
    type: IGET_CARRIER_AUDIT_REQUEST.FAILED,
    message
});
