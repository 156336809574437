import { IAddComplaintLoggingRequest, IADD_COMPLAINT_LOGGING_REQUEST } from "./addComplaintLoggingConstaints";

export interface IAddComplaintLoggingLoadAction {
    type: IADD_COMPLAINT_LOGGING_REQUEST.REQUEST;
    data: IAddComplaintLoggingRequest
}
export const addComplaintLoggingLoadAction = (data: IAddComplaintLoggingRequest): IAddComplaintLoggingLoadAction => ({
    type: IADD_COMPLAINT_LOGGING_REQUEST.REQUEST,
    data
});

export interface IAddComplaintLoggingSuccessAction {
    type: IADD_COMPLAINT_LOGGING_REQUEST.SUCCESS;
    message: string;
}
export const addComplaintLoggingLoadSuccessAction = (message: string): IAddComplaintLoggingSuccessAction => ({
    type: IADD_COMPLAINT_LOGGING_REQUEST.SUCCESS,
    message
});

export interface IAddComplaintLoggingLoadFailedAction {
    type: IADD_COMPLAINT_LOGGING_REQUEST.FAILED;
    message: string;
}
export const addComplaintLoggingLoadFailedAction = (message: string): IAddComplaintLoggingLoadFailedAction => ({
    type: IADD_COMPLAINT_LOGGING_REQUEST.FAILED,
    message
});
