import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, isSucceeded, Server } from "../../../../redux/server";
import { ById, SilAPIResponse } from "../../../shared/publicInterfaces";
import { userName, undefinedFunction, ZEROTH } from "../../../shared/constExports";
import { LAButton, LASaveAndCancelButton } from "../../../shared/buttons";
import { ArrowLeftIcon } from "../../../shared/icons";
import { BLACK_COLOR, LIGHT_GREY_COLOR, RED_COLOR, WHITE_COLOR, YELLOW_COLOR } from "../../../shared/theme";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import LATextField from "../../../shared/textField";
import LAAutoComplete from "../../../shared/autoComplete";
import { ROUTE } from "../../../routes";
import queryString from "query-string";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import { FieldValidator, FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../../shared/fieldValidator";
import PageSpacing from "../../../shared/pageSpacing";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import LATextArea from "../../../shared/textArea";
import { LADateTimePicker } from "../../../shared/dateTimePicker";
import SilRoleCheck from "../../../shared/silRoleCheck";
import { IAddSieveCalibrationMeasurementRequest, IAddSieveCalibrationRequest } from "../../../../redux/sil/sieves/addSieveCalibration/addSieveCalibrationConstaints";
import { IUpdateSieveCalibrationRequest } from "../../../../redux/sil/sieves/updateSieveCalibration/updateSieveCalibrationConstaints";
import { IGetSieveLookupsRequest, ISieveLookup } from "../../../../redux/sil/sieves/getSieveLookups/getSieveLookupsConstaints";
import { updateSieveCalibrationStatus } from "../../../../redux/sil/sieves/updateSieveCalibration/updateSieveCalibrationAccessor";
import { getSieveLookupsStatus } from "../../../../redux/sil/sieves/getSieveLookups/getSieveLookupsAccessor";
import { addSieveCalibrationStatus } from "../../../../redux/sil/sieves/addSieveCalibration/addSieveCalibrationAccessor";
import { addSieveCalibrationLoadAction } from "../../../../redux/sil/sieves/addSieveCalibration/addSieveCalibrationActions";
import { updateSieveCalibrationLoadAction } from "../../../../redux/sil/sieves/updateSieveCalibration/updateSieveCalibrationActions";
import { getSieveLookupsLoadAction } from "../../../../redux/sil/sieves/getSieveLookups/getSieveLookupsActions";
import { IGetSieveCalibrationsRequest, ISieveCalibration, ISieveCalibrationMeasurement } from "../../../../redux/sil/sieves/getSievesCalibrations/getSievesCalibrationsConstaints";
import { getSieveCalibrationsStatus } from "../../../../redux/sil/sieves/getSievesCalibrations/getSievesCalibrationsAccessor";
import { getSieveCalibrationsLoadAction } from "../../../../redux/sil/sieves/getSievesCalibrations/getSievesCalibrationsActions";
import { convertList } from "../washPlant/washPlantItemForm";
import DataGrid, { Column, Editing, Paging, Summary, TotalItem } from "devextreme-react/data-grid";


interface ISieveCalibrationStoreProps {
    addSieveCalibration: Server<string>;
    updateSieveCalibration: Server<string>;
    getToken: Server<SilAPIResponse<IToken>>;
    getSieveCalibrations: Server<SilAPIResponse<ById<ISieveCalibration>>>;
    getSieveLookups: Server<SilAPIResponse<ISieveLookup>>;
};

interface ISieveCalibrationDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getSieveCalibrationsRequest: (data: IGetSieveCalibrationsRequest) => unknown;
    addSieveCalibrationRequest: (data: IAddSieveCalibrationRequest) => unknown;
    updateSieveCalibrationRequest: (data: IUpdateSieveCalibrationRequest) => unknown;
    getSieveLookupsRequest: (data: IGetSieveLookupsRequest) => unknown;
};


interface ISieveCalibrationOwnProps {

};

interface ISieveCalibrationState {
    sieve: ISieveCalibration;
    errors: ById<IFieldErrorKeyValue>;
};

const SieveCalibrationStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;

    .totalDiff {
        color: ${WHITE_COLOR};
        background-color: ${RED_COLOR};
    };

    .dx-toolbar-after {
        display: none;
    };
`;

type ISieveCalibrationProps = RouteComponentProps
    & ISieveCalibrationStoreProps
    & ISieveCalibrationDispatchProps
    & ISieveCalibrationOwnProps;

class SieveCalibration extends PureComponent<ISieveCalibrationProps, ISieveCalibrationState> {

    public constructor(props: ISieveCalibrationProps) {
        super(props);
        this.state = {
            errors: {
                "date": { key: "date", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "product": { key: "product", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "plant": { key: "plant", message: FIELD_VALIDATOR_ERRORS.REQUIRED }
            },
            sieve: {
                id: 0,
                date: "",
                product: "",
                plant: "",
                created: "",
                created_By: userName,
                modified: "",
                modified_By: userName,
                measurements: []
            }
        };
    }

    public componentDidMount(): void {
        if (hasPayload(this.props.getToken))
            this.props.getSieveLookupsRequest({
                token: this.props.getToken.payload.response.token,
                Requested_Page: "Calibration"
            });

        this.callServer();
    };

    public componentDidUpdate(prevProps: ISieveCalibrationProps): void {
        if (this.props !== prevProps) {
            this.callServer();

            if (this.props.addSieveCalibration !== prevProps.addSieveCalibration) {
                if (isSucceeded(this.props.addSieveCalibration)) {
                   // this.handleCancel();
                };
            };

            if (this.props.updateSieveCalibration !== prevProps.updateSieveCalibration) {
                if (isSucceeded(this.props.updateSieveCalibration)) {
                   // this.handleCancel();
                };
            };
        };
    };


    public render(): ReactNode {

        const { sieve, errors } = this.state;
        const { updateSieveCalibration, addSieveCalibration, getSieveLookups, getToken } = this.props;
        const lookUps = hasPayload(getSieveLookups) ? getSieveLookups.payload.response : { plants: [], products: [], mesh: [], screens: [] };
        const plants = convertList(lookUps.plants);
        const filteredProduct = this.filterProduct(lookUps);
        const products = convertList(filteredProduct);
        const sieveAccess = hasPayload(getToken) ? getToken.payload.response.sieveAccess : false;

        return (
            <PageSpacing title="SIL - Sieve Calibration" description="Sieve Calibration" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="sieveAccess">
                    <SieveCalibrationStyles>

                        <LAButton startIcon={<ArrowLeftIcon color={WHITE_COLOR} />} label="Back to list" onClick={this.handleCancel} />
                        <h2 className="text-center">{sieve.id > 0 ? "VIEW/UPDATE " : "ADD "} SIEVE CALIBRATION</h2>
                        <hr />

                        <LAPaperWithPadding>
                            <LAGrid spacing={3}>

                                <LAGridItem xs={6}>
                                    <LADateTimePicker
                                        name="date"
                                        label="Date"
                                        fullWidth={true}
                                        value={sieve.date}
                                        onChange={this.handleDate}
                                        disabled={sieveAccess ? undefined : true}
                                        errorText={errors && errors["date"] ? errors["date"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={6}>
                                    <LATextField
                                        varient="outlined"
                                        label="Operator"
                                        fullWidth={true}
                                        name="operator"
                                        onChange={this.onChange}
                                        value={sieve.operator ?? ""}
                                        disabled={sieveAccess ? undefined : true}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={6}>
                                    <LAAutoComplete
                                        filterSelectedOptions={true}
                                        autoHighlight={true}
                                        selectionRemove={undefinedFunction}
                                        dropDownPlaceHolder="Plant"
                                        getOptionLabel="name"
                                        name="plant"
                                        option={plants}
                                        multiple={false}
                                        onChange={this.handleDropDownChange}
                                        disabled={sieveAccess ? (sieve.id > 0 ? true : undefined) : true}
                                        errorText={errors && errors["plant"] ? errors["plant"].message : undefined}
                                        value={hasPayload(getSieveLookups) && sieve.plant ? plants.find(x => x.name === sieve.plant) : ""}
                                        defaultValue={hasPayload(getSieveLookups) && sieve.plant ? plants.find(x => x.name === sieve.plant) : ""}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={6}>
                                    <LAAutoComplete
                                        filterSelectedOptions={true}
                                        autoHighlight={true}
                                        selectionRemove={undefinedFunction}
                                        dropDownPlaceHolder="Product"
                                        getOptionLabel="name"
                                        name="product"
                                        option={products}
                                        multiple={false}
                                        onChange={this.handleProductSelection}
                                        errorText={errors && errors["product"] ? errors["product"].message : undefined}
                                        disabled={sieveAccess ? (((sieve.id === 0) && (sieve.plant)) ? undefined : true) : true}
                                        value={hasPayload(getSieveLookups) && sieve.product ? products.find(x => x.name === sieve.product) : ""}
                                        defaultValue={hasPayload(getSieveLookups) && sieve.product ? products.find(x => x.name === sieve.product) : ""}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={6}>
                                    <LATextArea
                                        varient="outlined"
                                        label="Notes"
                                        fullWidth={true}
                                        name="notes"
                                        minRows={3}
                                        rowsMax={5}
                                        onChange={this.onChange}
                                        value={sieve.notes ?? ""}
                                        disabled={sieveAccess ? undefined : true}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={6}>
                                    <LATextField
                                        varient="outlined"
                                        label="Initial Mass"
                                        fullWidth={true}
                                        name="initial_Mass"
                                        onChange={this.onNumberChange}
                                        value={sieve.initial_Mass ?? ""}
                                        disabled={sieveAccess ? undefined : true}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12}>

                                    <DataGrid
                                        keyExpr="id"
                                        showBorders={true}
                                        columnAutoWidth={true}
                                        dataSource={sieve.measurements}
                                        onRowPrepared={(e: any) => {
                                            if(e.rowElement.cells[1])
                                                e.rowElement.cells[1].style.backgroundColor = LIGHT_GREY_COLOR;

                                            if(e.rowElement.cells[4])
                                                e.rowElement.cells[4].style.backgroundColor = LIGHT_GREY_COLOR;

                                            if(e.rowElement.cells[5])
                                                e.rowElement.cells[5].style.backgroundColor = LIGHT_GREY_COLOR;

                                                if(e.rowElement.cells[6])
                                                e.rowElement.cells[6].style.backgroundColor = LIGHT_GREY_COLOR;

                                            if(e.rowElement.cells[7])
                                                e.rowElement.cells[7].style.backgroundColor = LIGHT_GREY_COLOR;
                                               
                          
                                            if (e.data) {
                                                const checkVal = this.calculateAbsoluteRelativeDifference(e.data);

                                                if (checkVal < -15.00) {
                                                    if (e.rowElement.cells[7]) {
                                                        e.rowElement.cells[7].style.color = WHITE_COLOR;
                                                        e.rowElement.cells[7].style.backgroundColor = RED_COLOR;
                                                    }
                                                }
                                                if (checkVal > 15.00) {
                                                    if (e.rowElement.cells[7]) {
                                                        e.rowElement.cells[7].style.color = WHITE_COLOR;
                                                        e.rowElement.cells[7].style.backgroundColor = RED_COLOR;
                                                    }
                                                }
                                                if (checkVal > 10.00 && checkVal < 15.00) {
                                                    if (e.rowElement.cells[7]) {
                                                        e.rowElement.cells[7].style.color = BLACK_COLOR;
                                                        e.rowElement.cells[7].style.backgroundColor = YELLOW_COLOR;
                                                    }
                                                }
                                                if (checkVal < -10.00 && checkVal > -15.00) {
                                                    if (e.rowElement.cells[7]) {
                                                        e.rowElement.cells[7].style.color = BLACK_COLOR;
                                                        e.rowElement.cells[7].style.backgroundColor = YELLOW_COLOR;
                                                    }
                                                }

                                            }
                                        }}
                                    >
                                        <Paging enabled={false} />
                                        <Editing
                                            mode="batch"
                                            onChangesChange={this.onChangesChange}
                                            allowUpdating={sieveAccess ? true : false}
                                        />

                                        <Column dataField="sieve_Serial_No" caption="Sieve Serial No" dataType="string" />
                                        <Column dataField="astM_Serial_No" allowEditing={false} caption="ASTM Serial No" dataType="string" />
                                        <Column dataField="master_Load" format="#.00" caption="Master Load" dataType="number" />
                                        <Column dataField="working_Load" format="#.00" caption="Working Load" dataType="number" />
                                        <Column dataField="difference" format="#.00" allowEditing={false} calculateCellValue={this.calculateDifference} caption="Difference" dataType="number" />
                                        <Column dataField="relative_Difference" format="#.00" allowEditing={false} calculateCellValue={this.calculateRelativeDifference} caption="Relative Difference" dataType="number" />
                                        <Column dataField="absolute_Difference" format="#.00" caption="Absolute Difference" dataType="number" calculateCellValue={this.calculateAbsoluteDifference} allowEditing={false} />
                                        <Column dataField="absolute_Relative_Difference" format="#.00" allowEditing={false} calculateCellValue={this.calculateAbsoluteRelativeDifference} caption="Absolute Relative Difference" dataType="number" />


                                        <Summary calculateCustomSummary={this.calculateSelectedRow}>
                                            <TotalItem
                                                summaryType="sum"
                                                valueFormat="#.00"
                                                column="master_Load"
                                                displayFormat="Total: {0}"
                                            />
                                            <TotalItem
                                                summaryType="sum"
                                                valueFormat="#.00"
                                                column="working_Load"
                                                displayFormat="Total: {0}"
                                            />

                                            <TotalItem
                                                name="relDiffTotal"
                                                summaryType="custom"
                                                valueFormat="#.00"
                                                displayFormat="Total: {0}"
                                                showInColumn="relative_Difference"
                                                cssClass={this.calculateTotalRelDiff() < 0 || this.calculateTotalRelDiff() > 5  ? "totalDiff" : ""}
                                            />
                                        </Summary>
                                    </DataGrid>

                                </LAGridItem>

                                <LAGridItem xs={12}>
                                    <LASaveAndCancelButton
                                        onSave={this.onSave}
                                        onCancel={this.handleCancel}
                                        disableSave={sieveAccess ? (Object.values(errors).length > 0 ? true : undefined) : true}
                                    />
                                </LAGridItem>

                            </LAGrid>
                        </LAPaperWithPadding>

                        <RequestStatus requestStatus={addSieveCalibration.kind} successMessage="Sieve Calibration successfully saved" />
                        <RequestStatus requestStatus={updateSieveCalibration.kind} successMessage="Sieve Calibration successfully updated" />
                    </SieveCalibrationStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private calculateSelectedRow = (options: any): any => {
        if (options.name === "relDiffTotal") {
            options.totalValue = this.calculateTotalRelDiff();
        }
    };

    private calculateTotalRelDiff = (): number => {
        const { measurements } = this.state.sieve;

        if (this.state.sieve.measurements.length > 0) {
            const totalWork = measurements.map(item => item.working_Load).reduce((prev, next) => prev + next);
            const totalMasterLoad = measurements.map(item => item.master_Load).reduce((prev, next) => prev + next);
            return ((totalWork) / (totalMasterLoad) - 1) * 100;
        } else {
            return 0;
        }
    };

    private onChangesChange = (updatedData: { data: ISieveCalibrationMeasurement, key: number, type: string }[]): void => {
        const iS = [...this.state.sieve.measurements];
        
        updatedData.forEach((x: { data: ISieveCalibrationMeasurement, key: number, type: string }) => {
            const index = iS.findIndex((q) => q.id === x.key);
            let record = { ...iS[index] };

            record = { ...record, ...x.data };
            iS[index] = record;
        });
       
        this.setState({ 
            ...this.state,
            sieve: {
                ...this.state.sieve,
                measurements: iS
            }
         });
    };

    private calculateDifference = (data: ISieveCalibrationMeasurement): number => {
        return data.working_Load - data.master_Load;
    };

    private calculateAbsoluteDifference = (data: ISieveCalibrationMeasurement): number => {

        const measurements = this.state.sieve.measurements;

        const index = measurements.findIndex((x) => x.id === data.id);

        if(index >= ZEROTH){
            if(index === ZEROTH){
                return data.working_Load - data.master_Load;
            } else {                
                if((data.working_Load > ZEROTH) || (data.master_Load > ZEROTH)){
                    var lastIndex = index -1;
                    var prevDifferenceVal = 0.0;
                    measurements
                    .forEach((x) => {
                        if(measurements.indexOf(x) >= 0 && measurements.indexOf(x) <= lastIndex)
                        {
                            prevDifferenceVal = prevDifferenceVal + (x.working_Load - x.master_Load);
                        }
                    });    
                  // console.log(prevDifferenceVal);
                    return (((data.working_Load - data.master_Load)) + prevDifferenceVal);
                } else {
                    return 0;
                }
            }
        } else {
            return 0;
        }
    };

    private calculateRelativeDifference = (data: ISieveCalibrationMeasurement): number => {
        var val = ((data.working_Load / data.master_Load) - 1) * 100;
        return isNaN(val) ? 0 : val;
    };

    private calculateAbsoluteRelativeDifference = (data: ISieveCalibrationMeasurement): number => {

        const measurements = this.state.sieve.measurements;

        const index = measurements.findIndex((x) => x.id === data.id);

        if(index >= ZEROTH){
            if(index === ZEROTH){
                return (((data.working_Load - data.master_Load)/data.master_Load) * 100) ;
            } else {
                if((data.working_Load > ZEROTH) || (data.master_Load > ZEROTH)){
                    var lastIndex = index -1;
                    var prevDifferenceVal = 0.0;
                    measurements
                    .forEach((x) => {
                        if(measurements.indexOf(x) >= 0 && measurements.indexOf(x) <= lastIndex)
                        {
                            prevDifferenceVal = prevDifferenceVal + (x.working_Load - x.master_Load);
                        }
                    });    
                  // console.log(prevDifferenceVal);
                    return ((((data.working_Load - data.master_Load) + (prevDifferenceVal))/data.master_Load) * 100);
                } else {
                    return 0;
                }
            }
        } else {
            return 0;
        }

};

    private filterProduct = (data: ISieveLookup): string[] => {
        const res: string[] = [];
        const plant = this.state.sieve.plant;

        for (let i in data.products) {
            if (data.products[i].plant === plant)
                res.push(data.products[i].product);
        };

        return res;
    };

    private handleDate = (name: string, date: string): void => {
        let errors = this.state.errors;
        errors = this.errorChecker(name, date, errors, true);

        this.setState({
            ...this.state,
            errors,
            sieve: {
                ...this.state.sieve,
                [name]: date
            }
        });
    };


    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, isRequired: boolean): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: isRequired ? true : undefined, minLength: 1, decimal: undefined });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        };
        return err;
    };

    private handleCancel = (): void => {
        this.props.history.push(ROUTE.SIL.SIEVES.SIEVES_CALIBRATIONS);
    };

    private onSave = async (): Promise<void> => {

        const { sieve } = this.state;

        const measurements = [...sieve.measurements];

        if (hasPayload(this.props.getToken)) {

            const meas: IAddSieveCalibrationMeasurementRequest[] = [];

            measurements
                .forEach((x) => {

                    meas.push({
                        ID: x.id,
                        Sieve_Serial_No: x.sieve_Serial_No,
                        ASTM_Serial_No: x.astM_Serial_No,
                        Master_Load: x.master_Load,
                        Working_Load: x.working_Load,
                        Difference: this.calculateDifference(x),
                        Relative_Difference: this.calculateRelativeDifference(x),
                        Absolute_Difference: this.calculateAbsoluteDifference(x),
                        Absolute_Relative_Difference: this.calculateAbsoluteRelativeDifference(x)
                    });

                });

            if (sieve.id === 0) {
                this.props.addSieveCalibrationRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        ID: sieve.id,
                        Date: sieve.date,
                        Operator: sieve.operator,
                        Plant: sieve.plant,
                        Product: sieve.product,
                        Notes: sieve.notes,
                        Initial_Mass: sieve.initial_Mass?.toString(),
                        Modified_By: sieve.modified_By,
                        Created_By: sieve.created_By,
                        Measurements: meas
                    }
                });
            } else {
                let data = this.state.sieve;
                data.modified_By = userName;
                this.props.updateSieveCalibrationRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        ID: sieve.id,
                        Date: sieve.date,
                        Operator: sieve.operator,
                        Plant: sieve.plant,
                        Product: sieve.product,
                        Notes: sieve.notes,
                        Initial_Mass: sieve.initial_Mass?.toString(),
                        Modified_By: sieve.modified_By,
                        Measurements: meas
                    }
                });
            };
        };

        this.setState({
            ...this.state,
            sieve: {
                ...this.state.sieve,
                measurements
            }
        });
    };

    private handleDropDownChange = (event: unknown, value: { id: number, name: string } | "", name?: string): void => {
        if (name) {
            let errors = this.state.errors;
            errors = this.errorChecker(name, value !== "" ? value.name : "", errors, true);

            this.setState({
                ...this.state,
                errors,
                sieve: {
                    ...this.state.sieve,
                    [name]: value !== "" ? value.name : ""
                }
            });
        };
    };

    private handleProductSelection = (event: unknown, value: { id: number, name: string } | "", name?: string): void => {
        if (name) {

            const newMesh: ISieveCalibrationMeasurement[] = [];

            if (hasPayload(this.props.getSieveLookups) && value !== "") {
                const { mesh } = this.props.getSieveLookups.payload.response;

                const index = mesh.findIndex((x) => x.product === value.name);
                const array = mesh[index].mesh.split(",");
                const { created_By, modified_By } = this.state.sieve;

                array.forEach((x, index) => {
                    newMesh.push({
                        id: index,
                        listID: index,
                        sieve_Serial_No: "",
                        astM_Serial_No: x,
                        master_Load: 0,
                        working_Load: 0,
                        difference: 0,
                        relative_Difference: 0,
                        absolute_Difference: 0,
                        absolute_Relative_Difference: 0,
                        created: "",
                        modified: "",
                        created_By: created_By,
                        modified_By: modified_By
                    });
                });

            };

            let errors = this.state.errors;
            errors = this.errorChecker(name, value !== "" ? value.name : "", errors, true);

            this.setState({
                ...this.state,
                errors,
                sieve: {
                    ...this.state.sieve,
                    measurements: newMesh,
                    [name]: value !== "" ? value.name : ""
                }
            });
        }
    };

    private onNumberChange = (name: string, value: string): void => {
        if (!isNaN(Number(value))) {
            let errors = this.state.errors;
            errors = this.errorChecker(name, value, errors, true);

            this.setState({
                ...this.state,
                errors,
                sieve: {
                    ...this.state.sieve,
                    [name]: value
                }
            });
        };
    };


    private onChange = (name: string, value: string): void => {
        let errors = this.state.errors;
        errors = this.errorChecker(name, value, errors, true);

        this.setState({
            ...this.state,
            errors,
            sieve: {
                ...this.state.sieve,
                [name]: value
            }
        });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getSieveLookups))
            this.props.getSieveLookupsRequest({
                token: this.props.getToken.payload.response.token,
                Requested_Page: "Calibration"
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getSieveCalibrations))
            this.props.getSieveCalibrationsRequest({
                token: this.props.getToken.payload.response.token
            });


        const query = queryString.parse(this.props.location.search);
        const id = query.id ?? "0";

        if ((id !== "0") && (hasPayload(this.props.getSieveCalibrations)) && (this.state.sieve.id === 0)) {
            const sieve = this.props.getSieveCalibrations.payload.response[id.toString()];

            if (sieve)
                this.setState({ sieve, errors: {} });
        };

    };

}

const mapStateToProps = (state: IStore): ISieveCalibrationStoreProps => ({
    getToken: getTokenStatus(state),
    getSieveCalibrations: getSieveCalibrationsStatus(state),
    updateSieveCalibration: updateSieveCalibrationStatus(state),
    getSieveLookups: getSieveLookupsStatus(state),
    addSieveCalibration: addSieveCalibrationStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): ISieveCalibrationDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getSieveCalibrationsRequest: (data: IGetSieveCalibrationsRequest) => dispatch(getSieveCalibrationsLoadAction(data)),
    addSieveCalibrationRequest: (data: IAddSieveCalibrationRequest) => dispatch(addSieveCalibrationLoadAction(data)),
    updateSieveCalibrationRequest: (data: IUpdateSieveCalibrationRequest) => dispatch(updateSieveCalibrationLoadAction(data)),
    getSieveLookupsRequest: (data: IGetSieveLookupsRequest) => dispatch(getSieveLookupsLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(SieveCalibration);