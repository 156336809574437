import { IAddRunMaterialRequest, IADD_RUN_MATERIAL_REQUEST } from "./addRunMaterialConstaints";

export interface IAddRunMaterialLoadAction {
    type: IADD_RUN_MATERIAL_REQUEST.REQUEST;
    data: IAddRunMaterialRequest
}
export const addRunMaterialLoadAction = (data: IAddRunMaterialRequest): IAddRunMaterialLoadAction => ({
    type: IADD_RUN_MATERIAL_REQUEST.REQUEST,
    data
});

export interface IAddRunMaterialSuccessAction {
    type: IADD_RUN_MATERIAL_REQUEST.SUCCESS;
    message: string;
}
export const addRunMaterialLoadSuccessAction = (message: string): IAddRunMaterialSuccessAction => ({
    type: IADD_RUN_MATERIAL_REQUEST.SUCCESS,
    message
});
export interface IAddRunMaterialLoadFailedAction {
    type: IADD_RUN_MATERIAL_REQUEST.FAILED;
    message: string;
}
export const addRunMaterialLoadFailedAction = (message: string): IAddRunMaterialLoadFailedAction => ({
    type: IADD_RUN_MATERIAL_REQUEST.FAILED,
    message
});
