import { IWashPlantBinAdjustments } from "../getWashPlantBinAdjustments/getWashPlantBinAdjustmentsConstaints";

export enum IADD_WASH_PLANT_BIN_ADJUSTMENT_REQUEST {
    REQUEST = "addWashPlantBinAdjustment/ADD_WASH_PLANT_BIN_ADJUSTMENT_REQUEST",
    SUCCESS = "addWashPlantBinAdjustment/ADD_WASH_PLANT_BIN_ADJUSTMENT_SUCCESS",
    FAILED = "addWashPlantBinAdjustment/ADD_WASH_PLANT_BIN_ADJUSTMENT_FAILED"
};

export interface IAddWashPlantBinAdjustmentRequest {
    token: string;
    request: IWashPlantBinAdjustments;
};