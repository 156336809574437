import { YHDAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { IGetYHDCarrierFleetTruckRequest, IGET_YHD_CARRIER_FLEET_TRUCK_REQUEST, IYHDCarrierFleetTruck } from "./getCarrierFleetTruckConstaints";

export interface IGetYHDCarrierFleetTruckLoadAction {
    type: IGET_YHD_CARRIER_FLEET_TRUCK_REQUEST.REQUEST;
    data: IGetYHDCarrierFleetTruckRequest
}
export const getYHDCarrierFleetTruckLoadAction = (data: IGetYHDCarrierFleetTruckRequest): IGetYHDCarrierFleetTruckLoadAction => ({
    type: IGET_YHD_CARRIER_FLEET_TRUCK_REQUEST.REQUEST,
    data
});

export interface IGetYHDCarrierFleetTruckSuccessAction {
    type: IGET_YHD_CARRIER_FLEET_TRUCK_REQUEST.SUCCESS;
    data: YHDAPIResponse<IYHDCarrierFleetTruck[]>;
}
export const getYHDCarrierFleetTruckLoadSuccessAction = (data: YHDAPIResponse<IYHDCarrierFleetTruck[]>): IGetYHDCarrierFleetTruckSuccessAction => ({
    type: IGET_YHD_CARRIER_FLEET_TRUCK_REQUEST.SUCCESS,
    data
});

export interface IGetYHDCarrierFleetTruckLoadFailedAction {
    type: IGET_YHD_CARRIER_FLEET_TRUCK_REQUEST.FAILED;
    message: string;
}
export const getYHDCarrierFleetTruckLoadFailedAction = (message: string): IGetYHDCarrierFleetTruckLoadFailedAction => ({
    type: IGET_YHD_CARRIER_FLEET_TRUCK_REQUEST.FAILED,
    message
});
