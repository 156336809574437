import { IUpdateComplaintLoggingRequest, IUPDATE_COMPLAINT_LOGGING_REQUEST } from "./updateComplaintLoggingConstaints";

export interface IUpdateComplaintLoggingLoadAction {
    type: IUPDATE_COMPLAINT_LOGGING_REQUEST.REQUEST;
    data: IUpdateComplaintLoggingRequest
}
export const updateComplaintLoggingLoadAction = (data: IUpdateComplaintLoggingRequest): IUpdateComplaintLoggingLoadAction => ({
    type: IUPDATE_COMPLAINT_LOGGING_REQUEST.REQUEST,
    data
});

export interface IUpdateComplaintLoggingSuccessAction {
    type: IUPDATE_COMPLAINT_LOGGING_REQUEST.SUCCESS;
    message: string;
}
export const updateComplaintLoggingLoadSuccessAction = (message: string): IUpdateComplaintLoggingSuccessAction => ({
    type: IUPDATE_COMPLAINT_LOGGING_REQUEST.SUCCESS,
    message
});

export interface IUpdateComplaintLoggingLoadFailedAction {
    type: IUPDATE_COMPLAINT_LOGGING_REQUEST.FAILED;
    message: string;
}
export const updateComplaintLoggingLoadFailedAction = (message: string): IUpdateComplaintLoggingLoadFailedAction => ({
    type: IUPDATE_COMPLAINT_LOGGING_REQUEST.FAILED,
    message
});
