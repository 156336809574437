import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, isSucceeded, Server } from "../../../../redux/server";
import { ById, SilAPIResponse } from "../../../shared/publicInterfaces";
import { userName, undefinedFunction, monthNames, getYearsList, yesOrNoOptions } from "../../../shared/constExports";
import { LAButton, LASaveAndCancelButton } from "../../../shared/buttons";
import { ArrowLeftIcon } from "../../../shared/icons";
import { WHITE_COLOR } from "../../../shared/theme";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import LATextField from "../../../shared/textField";
import LAAutoComplete from "../../../shared/autoComplete";
import { ROUTE } from "../../../routes";
import queryString from "query-string";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import { FieldValidator, FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../../shared/fieldValidator";
import PageSpacing from "../../../shared/pageSpacing";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import SilRoleCheck from "../../../shared/silRoleCheck";
import { IGetSieveWashPlantRequest, ISieveWashPlant } from "../../../../redux/sil/cv/sieveWashPlant/getSieveWashPlants/getSieveWashPlantsConstaints";
import { IAddSieveWashPlantRequest } from "../../../../redux/sil/cv/sieveWashPlant/addSieveWashPlant/addSieveWashPlantConstaints";
import { IUpdateSieveWashPlantRequest } from "../../../../redux/sil/cv/sieveWashPlant/updateSieveWashPlant/updateSieveWashPlantConstaints";
import { getSieveWashPlantStatus } from "../../../../redux/sil/cv/sieveWashPlant/getSieveWashPlants/getSieveWashPlantsAccessor";
import { addSieveWashPlantStatus } from "../../../../redux/sil/cv/sieveWashPlant/addSieveWashPlant/addSieveWashPlantAccessor";
import { updateSieveWashPlantStatus } from "../../../../redux/sil/cv/sieveWashPlant/updateSieveWashPlant/updateSieveWashPlantAccessor";
import { getSieveWashPlantLoadAction } from "../../../../redux/sil/cv/sieveWashPlant/getSieveWashPlants/getSieveWashPlantsActions";
import { addSieveWashPlantLoadAction } from "../../../../redux/sil/cv/sieveWashPlant/addSieveWashPlant/addSieveWashPlantActions";
import { updateSieveWashPlantLoadAction } from "../../../../redux/sil/cv/sieveWashPlant/updateSieveWashPlant/updateSieveWashPlantActions";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { IConvertIdNumberList } from "../dryPlant/dryPlantItemForm";

export const oneForthSagList: IConvertIdNumberList[] = [{ id: 0, name: "Pass" }, { id: 1, name: "Fail" }];

interface ISieveWashPlantStoreProps {
    addSieveWashPlant: Server<string>;
    updateSieveWashPlant: Server<string>;
    getToken: Server<SilAPIResponse<IToken>>;
    getSieveWashPlants: Server<SilAPIResponse<ISieveWashPlant[]>>;
};

interface ISieveWashPlantDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getSieveWashPlantsRequest: (data: IGetSieveWashPlantRequest) => unknown;
    addSieveWashPlantRequest: (data: IAddSieveWashPlantRequest) => unknown;
    updateSieveWashPlantRequest: (data: IUpdateSieveWashPlantRequest) => unknown;
};


interface ISieveWashPlantOwnProps {

};

interface ISieveWashPlantState {
    unSavedDownTime: boolean;
    sieveWashPlant: ISieveWashPlant;
    errors: ById<IFieldErrorKeyValue>;
};

const SieveWashPlantStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
`;

type ISieveWashPlantProps = RouteComponentProps
    & ISieveWashPlantStoreProps
    & ISieveWashPlantDispatchProps
    & ISieveWashPlantOwnProps;

class SieveWashPlant extends PureComponent<ISieveWashPlantProps, ISieveWashPlantState> {

    public constructor(props: ISieveWashPlantProps) {
        super(props);
        this.state = {
            errors: {
                "employee": { key: "employee", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "month": { key: "month", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "year": { key: "year", message: FIELD_VALIDATOR_ERRORS.REQUIRED }
            },
            unSavedDownTime: false,
            sieveWashPlant: {
                id: 0,
                employee: "",
                is_Calibration_Good: yesOrNoOptions[0].name,
                completed: yesOrNoOptions[0].name,
                calibrations: [],
                created: "",
                created_By: userName,
                modified: "",
                modified_By: userName
            }
        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: ISieveWashPlantProps): void {
        if (this.props !== prevProps) {
            this.callServer();

            if (this.props.addSieveWashPlant !== prevProps.addSieveWashPlant) {
                if (isSucceeded(this.props.addSieveWashPlant)) {
                    this.handleCancel();
                };
            };

            if (this.props.updateSieveWashPlant !== prevProps.updateSieveWashPlant) {
                if (isSucceeded(this.props.updateSieveWashPlant)) {
                    this.handleCancel();
                };
            };
        };
    };


    public render(): ReactNode {

        const { updateSieveWashPlant, addSieveWashPlant } = this.props;
        const { sieveWashPlant, errors } = this.state;

        const isAdd = sieveWashPlant.id > 0 ? false : true;
        const years = getYearsList(2018);

        const onDevExtremeEdit = (): void => this.handleEditMode();

        return (
            <PageSpacing title="SIL - CV" description="SieveWashPlant" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="carrierAccess">
                    <SieveWashPlantStyles>

                        <LAButton startIcon={<ArrowLeftIcon color={WHITE_COLOR} />} label="Back to list" onClick={this.handleCancel} />
                        <h2 className="text-center">{!isAdd ? "VIEW " : "ADD "} WASH PLANT SIEVE CALIBRATION</h2>
                        <hr />

                        <LAPaperWithPadding>
                            <LAGrid spacing={3}>

                                <LAGridItem xs={4}>
                                    <LAAutoComplete
                                        filterSelectedOptions={true}
                                        autoHighlight={true}
                                        selectionRemove={undefinedFunction}
                                        dropDownPlaceHolder="Month"
                                        getOptionLabel="name"
                                        name="month"
                                        option={monthNames}
                                        multiple={false}
                                        onChange={this.handleDropDownIdChange}
                                        value={sieveWashPlant.month ? monthNames.find(x => x.id === sieveWashPlant.month) : ""}
                                        defaultValue={sieveWashPlant.month ? monthNames.find(x => x.id === sieveWashPlant.month) : ""}
                                        errorText={errors["month"] ? errors["month"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LAAutoComplete
                                        filterSelectedOptions={true}
                                        autoHighlight={true}
                                        selectionRemove={undefinedFunction}
                                        dropDownPlaceHolder="Year"
                                        getOptionLabel="name"
                                        name="year"
                                        option={years}
                                        multiple={false}
                                        onChange={this.handleDropDownIdChange}
                                        value={sieveWashPlant.year ? years.find(x => x.id === sieveWashPlant.year) : ""}
                                        defaultValue={sieveWashPlant.year ? years.find(x => x.id === sieveWashPlant.year) : ""}
                                        errorText={errors["year"] ? errors["year"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LATextField
                                        varient="outlined"
                                        label="Employee"
                                        fullWidth={true}
                                        name="employee"
                                        value={sieveWashPlant.employee ?? ""}
                                        onChange={this.onChange}
                                        errorText={errors["employee"] ? errors["employee"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12}>
                                    <LADevExtremeGrid
                                        add={true}
                                        height={500}
                                        id="sieveWashPlant"
                                        columnWidth={200}
                                        searchPanel={false}
                                        filterHeader={false}
                                        moveBtnToLeft={true}
                                        data={sieveWashPlant.calibrations}
                                        onDelete={onDevExtremeEdit}
                                        onCustomEdit={this.onDTEdit}
                                        onClick={undefinedFunction}
                                        onEditSave={onDevExtremeEdit}
                                        onEditStart={onDevExtremeEdit}
                                        onEditCancel={onDevExtremeEdit}
                                        onNewRowInsert={onDevExtremeEdit}
                                        columns={[
                                            { name: "mesh", caption: "Mesh", dropDownId: "id", dropDownName: "name" },
                                            { name: "visual", caption: "Visual", type: "number", width: 110 },
                                            { name: "one_Fourth_Sag", caption: "1/4 Sag", dropDownId: "id", dropDownName: "name", dropDownSource: oneForthSagList },
                                            { name: "serial_Number", caption: "Serial Number", type: "number", width: 100 },
                                            { name: "fail_Description", caption: "fail_Description", type: "string" },
                                        ]}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LAAutoComplete
                                        filterSelectedOptions={true}
                                        autoHighlight={true}
                                        selectionRemove={undefinedFunction}
                                        dropDownPlaceHolder="Completed"
                                        getOptionLabel="name"
                                        name="completed"
                                        option={yesOrNoOptions}
                                        multiple={false}
                                        onChange={this.handleDropDownChange}
                                        value={yesOrNoOptions.find(x => x.name === sieveWashPlant.completed)}
                                        defaultValue={yesOrNoOptions.find(x => x.name === sieveWashPlant.completed)}
                                        errorText={errors["completed"] ? errors["completed"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LAAutoComplete
                                        filterSelectedOptions={true}
                                        autoHighlight={true}
                                        selectionRemove={undefinedFunction}
                                        dropDownPlaceHolder="Is Calibration Good ?"
                                        getOptionLabel="name"
                                        name="is_Calibration_Good"
                                        option={yesOrNoOptions}
                                        multiple={false}
                                        onChange={this.handleDropDownChange}
                                        value={yesOrNoOptions.find(x => x.name === sieveWashPlant.is_Calibration_Good)}
                                        defaultValue={yesOrNoOptions.find(x => x.name === sieveWashPlant.is_Calibration_Good)}
                                        errorText={errors["is_Calibration_Good"] ? errors["is_Calibration_Good"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12}>
                                    <hr />
                                    <LASaveAndCancelButton
                                        onSave={this.onSave}
                                        onCancel={this.handleCancel}
                                        disableSave={(Object.values(errors).length > 0 ? true : undefined)}
                                    />
                                </LAGridItem>

                            </LAGrid>
                        </LAPaperWithPadding>

                        <RequestStatus requestStatus={addSieveWashPlant.kind} successMessage="SieveWashPlant successfully saved" />
                        <RequestStatus requestStatus={updateSieveWashPlant.kind} successMessage="SieveWashPlant successfully updated" />
                    </SieveWashPlantStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private onDTEdit = (): void => {
    };

    private handleEditMode = (): void => {
        this.setState({ unSavedDownTime: !this.state.unSavedDownTime });
    };

    private onChange = (name: string, value: string): void => {
        let errors = this.state.errors;
        errors = this.errorChecker(name, value, errors, true);

        this.setState({
            ...this.state,
            errors,
            sieveWashPlant: {
                ...this.state.sieveWashPlant,
                [name]: value
            }
        });
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, isRequired: boolean): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: isRequired ? true : undefined, minLength: 1, decimal: undefined });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        };
        return err;
    };

    private handleCancel = (): void => {
        this.props.history.push(ROUTE.SIL.CV.SIEVE_WASH_PLANTS.INDEX);
    };

    private onSave = (): void => {
        const { sieveWashPlant } = this.state;

        if (hasPayload(this.props.getToken))
            if (sieveWashPlant.id === 0) {
                this.props.addSieveWashPlantRequest({
                    token: this.props.getToken.payload.response.token,
                    request: sieveWashPlant
                });
            } else {
                sieveWashPlant.modified_By = userName;
                
                this.props.updateSieveWashPlantRequest({
                    token: this.props.getToken.payload.response.token,
                    request: sieveWashPlant
                });
            };
    };

    private handleDropDownChange = (event: unknown, value: { id: number, name: string } | "", name?: string): void => {
        if (name) {
            let errors = this.state.errors;
            errors = this.errorChecker(name, value !== "" ? value.name : "", errors, true);

            this.setState({
                ...this.state,
                errors,
                sieveWashPlant: {
                    ...this.state.sieveWashPlant,
                    [name]: value !== "" ? value.name : ""
                }
            });
        }
    };

    private handleDropDownIdChange = (event: unknown, value: { id: number, name: string } | "", name?: string): void => {
        if (name) {
            let errors = this.state.errors;
            errors = this.errorChecker(name, value !== "" ? value.name : "", errors, true);

            this.setState({
                ...this.state,
                errors,
                sieveWashPlant: {
                    ...this.state.sieveWashPlant,
                    [name]: value !== "" ? value.id : undefined
                }
            });
        }
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getSieveWashPlants))
            this.props.getSieveWashPlantsRequest({
                token: this.props.getToken.payload.response.token
            });


        const query = queryString.parse(this.props.location.search);
        const id = query.id ?? "0";

        if ((id !== "0") && (hasPayload(this.props.getSieveWashPlants))) {
            const sieveWashPlant = this.props.getSieveWashPlants.payload.response.find(x => x.id === Number(id));

            if (sieveWashPlant)
                this.setState({ sieveWashPlant, errors: {} });
        };

    };

}

const mapStateToProps = (state: IStore): ISieveWashPlantStoreProps => ({
    getToken: getTokenStatus(state),
    getSieveWashPlants: getSieveWashPlantStatus(state),
    addSieveWashPlant: addSieveWashPlantStatus(state),
    updateSieveWashPlant: updateSieveWashPlantStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): ISieveWashPlantDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getSieveWashPlantsRequest: (data: IGetSieveWashPlantRequest) => dispatch(getSieveWashPlantLoadAction(data)),
    addSieveWashPlantRequest: (data: IAddSieveWashPlantRequest) => dispatch(addSieveWashPlantLoadAction(data)),
    updateSieveWashPlantRequest: (data: IUpdateSieveWashPlantRequest) => dispatch(updateSieveWashPlantLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(SieveWashPlant);