import { IPassTokenWithRequest } from "../../../../sil/dryPlant/token/getTokenConstants";

export enum IADD_YHD_CARRIER_INFRACTION_REQUEST {
    REQUEST = "addYHDCarrierInfraction/ADD_YHD_CARRIER_INFRACTION_REQUEST",
    SUCCESS = "addYHDCarrierInfraction/ADD_YHD_CARRIER_INFRACTION_SUCCESS",
    FAILED = "addYHDCarrierInfraction/ADD_YHD_CARRIER_INFRACTION_FAILED"
};

export interface IAddCarrierInfraction {
    Date_Of_Infraction: string;
    Infraction_Notification_Method: string;
    Alternate_Name: string;
    Trailer_No: string;
    License_Plate?: string;
    Location: string;
    Ticket_No?: string;
    Infraction: string;
    Carrier_Notification_Provided_To?: string;
    Description: string;
    Immediate_Action_Taken: string;
    Trailer_Inspection_File: string;
    Followup_Action_Taken?: string;
    Attachment_File_Name?: string;
    Attachment_File?: string;
    Attachment_Details?: string;
    Reported_By_Name?: string;
    Contact_No?: string;
    Complaint_Recorded_By?: string;
    Status: string;
    Modified: string;
    Modified_By: string;
    Created: string;
    Created_By: string;
};

export interface IAddYHDCarrierInfractionRequest extends IPassTokenWithRequest {
    request: IAddCarrierInfraction;
};