import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IUpdateSimosProductLoadAction, 
    IUpdateSimosProductLoadFailedAction, 
    IUpdateSimosProductSuccessAction, 
    updateSimosProductLoadFailedAction, updateSimosProductLoadSuccessAction
} from "./updateSimosProductActions";
import { IUPDATE_SIMOS_PRODUCT_REQUEST } from "./updateSimosProductConstaints";
import { EpicDependencies } from "../../../../store";
import { SimosAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { END_POINTS } from "../../../../endpoints";
import { withJsonContentType } from "../../../../epicUtils";
import { IStore } from "../../../../reducers";

export const updateSimosProductEpic: Epic = (
    action$: ActionsObservable<IUpdateSimosProductLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IUpdateSimosProductSuccessAction | IUpdateSimosProductLoadFailedAction> =>
    action$.ofType(IUPDATE_SIMOS_PRODUCT_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SimosAPIResponse<string>>(
                    END_POINTS.PROPSENSE.SIMOS.UPDATE_SIMOS_PRODUCT,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SimosAPIResponse<string>): IUpdateSimosProductSuccessAction | IUpdateSimosProductLoadFailedAction => {
                            console.log(response);
                            if(response.message === "Success"){
                                return updateSimosProductLoadSuccessAction(response.message);
                            };
                            return updateSimosProductLoadFailedAction(response.message)
                        }),
                        catchError(() => ActionsObservable.of(updateSimosProductLoadFailedAction("Unable to update Simos product")))
                    )
            )
        );