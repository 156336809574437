import { SilAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { IGetSieveDryPlantRequest, IGET_SIEVE_DRY_PLANTS_REQUEST, ISieveDryPlant } from "./getSieveDryPlantsConstaints";

export interface IGetSieveDryPlantLoadAction {
    type: IGET_SIEVE_DRY_PLANTS_REQUEST.REQUEST;
    data: IGetSieveDryPlantRequest
}
export const getSieveDryPlantLoadAction = (data: IGetSieveDryPlantRequest): IGetSieveDryPlantLoadAction => ({
    type: IGET_SIEVE_DRY_PLANTS_REQUEST.REQUEST,
    data
});

export interface IGetSieveDryPlantSuccessAction {
    type: IGET_SIEVE_DRY_PLANTS_REQUEST.SUCCESS;
    data: SilAPIResponse<ISieveDryPlant[]>;
}
export const getSieveDryPlantLoadSuccessAction = (data: SilAPIResponse<ISieveDryPlant[]>): IGetSieveDryPlantSuccessAction => ({
    type: IGET_SIEVE_DRY_PLANTS_REQUEST.SUCCESS,
    data
});

export interface IGetSieveDryPlantLoadFailedAction {
    type: IGET_SIEVE_DRY_PLANTS_REQUEST.FAILED;
    message: string;
}
export const getSieveDryPlantLoadFailedAction = (message: string): IGetSieveDryPlantLoadFailedAction => ({
    type: IGET_SIEVE_DRY_PLANTS_REQUEST.FAILED,
    message
});
