import { SimosAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetSIMOSOrderProductVersionHistoryRequest, IGET_SIMOS_ORDER_PRODUCT_VERSION_HISTORY_REQUEST, ISIMOSOrderProductVersionHistory } from "./getSIMOSOrderProductVersionHistoryConstaints";

export interface IGetSIMOSOrderProductVersionHistoryLoadAction {
    type: IGET_SIMOS_ORDER_PRODUCT_VERSION_HISTORY_REQUEST.REQUEST;
    data: IGetSIMOSOrderProductVersionHistoryRequest
}
export const getSIMOSOrderProductVersionHistoryLoadAction = (data: IGetSIMOSOrderProductVersionHistoryRequest): IGetSIMOSOrderProductVersionHistoryLoadAction => ({
    type: IGET_SIMOS_ORDER_PRODUCT_VERSION_HISTORY_REQUEST.REQUEST,
    data
});

export interface IGetSIMOSOrderProductVersionHistorySuccessAction {
    type: IGET_SIMOS_ORDER_PRODUCT_VERSION_HISTORY_REQUEST.SUCCESS;
    data: SimosAPIResponse<ISIMOSOrderProductVersionHistory[]>;
}
export const getSIMOSOrderProductVersionHistoryLoadSuccessAction = (data: SimosAPIResponse<ISIMOSOrderProductVersionHistory[]>): IGetSIMOSOrderProductVersionHistorySuccessAction => ({
    type: IGET_SIMOS_ORDER_PRODUCT_VERSION_HISTORY_REQUEST.SUCCESS,
    data
});

export interface IGetSIMOSOrderProductVersionHistoryLoadFailedAction {
    type: IGET_SIMOS_ORDER_PRODUCT_VERSION_HISTORY_REQUEST.FAILED;
    message: string;
}
export const getSIMOSOrderProductVersionHistoryLoadFailedAction = (message: string): IGetSIMOSOrderProductVersionHistoryLoadFailedAction => ({
    type: IGET_SIMOS_ORDER_PRODUCT_VERSION_HISTORY_REQUEST.FAILED,
    message
});
