import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IGetComplaintLoggingLoadAction, IGetComplaintLoggingLoadFailedAction, IGetComplaintLoggingSuccessAction, 
    getComplaintLoggingLoadFailedAction, getComplaintLoggingLoadSuccessAction
} from "./getComplaintLoggingActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IComplaintLogging, IGET_COMPLAINT_LOGGING_REQUEST } from "./getComplaintLoggingConstaints";

export const getComplaintLoggingEpic: Epic = (
    action$: ActionsObservable<IGetComplaintLoggingLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IGetComplaintLoggingSuccessAction | IGetComplaintLoggingLoadFailedAction> =>
    action$.ofType(IGET_COMPLAINT_LOGGING_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<IComplaintLogging[]>>(
                    END_POINTS.PROPSENSE.SIL.COMPLAINT_LOGGING.GET_COMPLAINT_LOGGING,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<IComplaintLogging[]>): IGetComplaintLoggingSuccessAction => {
                            return getComplaintLoggingLoadSuccessAction(response);
                        }),
                        catchError(() => ActionsObservable.of(getComplaintLoggingLoadFailedAction("Unable to get complaint loggings")))
                    )
            )
        );