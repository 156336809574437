import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import { LAPaperWithPadding } from "../../../shared/paper";
import styled from "styled-components";
import { IDispatch, IStore } from "../../../../redux/reducers";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import { LAExpansionPanel } from "../../../shared/expansionPanel";
import { LADateTimePicker } from "../../../shared/dateTimePicker";
import LAAutoComplete from "../../../shared/autoComplete";
import { userName, gethourTime, undefinedFunction, gethourOnlyTime } from "../../../shared/constExports";
import LATextArea from "../../../shared/textArea";
import queryString from "query-string";
import { ById, SilAPIResponse } from "../../../shared/publicInterfaces";
import { hasPayload, isFailed, isNotLoaded, isSucceeded, Server, STATUS_ENUM } from "../../../../redux/server";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { IGetWashPlantProductionRecordsRequest, IWashPlantProductionData, IWashPlantProductionRecords } from "../../../../redux/sil/washPlant/getWashPlantProductionRecords/getWashPlantProductionRecordsConstaints";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { getWashPlantProductionRecordsStatus } from "../../../../redux/sil/washPlant/getWashPlantProductionRecords/getWashPlantProductionRecordsAccessor";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import { getWashPlantProductionRecordsLoadAction } from "../../../../redux/sil/washPlant/getWashPlantProductionRecords/getWashPlantProductionRecordsActions";
import { IGetWashPlantProductionLookupsRequest, IWashPlantProductionLookups } from "../../../../redux/sil/washPlant/getWashPlantProductionLookups/getWashPlantProductionLookupsConstaints";
import { getWashPlantProductionLookupsLoadAction } from "../../../../redux/sil/washPlant/getWashPlantProductionLookups/getWashPlantProductionLookupsActions";
import { getWashPlantProductionLookupsStatus } from "../../../../redux/sil/washPlant/getWashPlantProductionLookups/getWashPlantProductionLookupsAccessor";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { LAButton, LASaveAndCancelButton } from "../../../shared/buttons";
import { IAddWashPlantProductionRequest } from "../../../../redux/sil/washPlant/addWashPlantProduction/addWashPlantProductionConstaints";
import { IUpdateWashPlantProductionRequest } from "../../../../redux/sil/washPlant/updateWashPlantProduction/updateWashPlantProductionConstaints";
import { addWashPlantProductionStatus } from "../../../../redux/sil/washPlant/addWashPlantProduction/addWashPlantProductionAccessor";
import { updateWashPlantProductionStatus } from "../../../../redux/sil/washPlant/updateWashPlantProduction/updateWashPlantProductionAccessor";
import { addWashPlantProductionLoadAction } from "../../../../redux/sil/washPlant/addWashPlantProduction/addWashPlantProductionActions";
import { updateWashPlantProductionLoadAction } from "../../../../redux/sil/washPlant/updateWashPlantProduction/updateWashPlantProductionActions";
import { ROUTE } from "../../../routes";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import { DRY_PLANT_FORM_TILE_COLOR, WHITE_COLOR } from "../../../shared/theme";
import { ArrowLeftIcon } from "../../../shared/icons";
import { IFieldErrorKeyValue, FieldValidator, FIELD_VALIDATOR_ERRORS } from "../../../shared/fieldValidator";
import { LAPopover } from "../../../shared/popOver";
import LAErrorBox from "../../../shared/errorBox";
import LATextField from "../../../shared/textField";
import { confirm } from "devextreme/ui/dialog";
import { WashPlantItemProdPopup } from "./washPlantItemProdPopup";
import PageSpacing from "../../../shared/pageSpacing";
import { getWashPlantProductionSummaryStatus } from "../../../../redux/sil/washPlant/getWashPlantProductionSummary/getWashPlantProductionSummaryAccessor";
import { getWashPlantProductionSummaryLoadAction } from "../../../../redux/sil/washPlant/getWashPlantProductionSummary/getWashPlantProductionSummaryActions";
import { IGetWashPlantProductionSummaryRequest, IWashPlantProductionSummary } from "../../../../redux/sil/washPlant/getWashPlantProductionSummary/getWashPlantProductionSummaryConstants";
import { ProductionSummaryTable } from "./productionPopups";
import SilRoleCheck from "../../../shared/silRoleCheck";
import LALoading from "../../../shared/loading";
import { IGetWashPlantProductionRecordByIdRequest } from "../../../../redux/sil/washPlant/getWashPlantProductionRecordById/getWashPlantProductionRecordByIdConstaints";
import { getWashPlantProductionRecordByIdStatus } from "../../../../redux/sil/washPlant/getWashPlantProductionRecordById/getWashPlantProductionRecordByIdAccessor";
import { getWashPlantProductionRecordByIdLoadAction } from "../../../../redux/sil/washPlant/getWashPlantProductionRecordById/getWashPlantProductionRecordByIdActions";

enum IWashPlantFormSection {
    "Production_Info" = 1,
    "Production" = 2,
    "Downtime" = 3,
    "Maintenance" = 4,
    "Notes" = 5
};

interface IWashPlantFormComponentStoreProps {
    getToken: Server<SilAPIResponse<IToken>>;
    getItems: Server<SilAPIResponse<ById<IWashPlantProductionRecords>>>;
    getItemById: Server<SilAPIResponse<IWashPlantProductionRecords>>;
    getDropDownData: Server<SilAPIResponse<IWashPlantProductionLookups>>;
    getProductionSummary: Server<SilAPIResponse<IWashPlantProductionSummary[]>>;
    addProduction: Server<string>;
    updateProduction: Server<string>;
};

interface IWashPlantFormComponentDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getItemsRequest: (data: IGetWashPlantProductionRecordsRequest) => unknown;
    getItemByIdRequest: (data: IGetWashPlantProductionRecordByIdRequest) => unknown;
    getDropDownDataRequest: (data: IGetWashPlantProductionLookupsRequest) => unknown;
    addProductionRequest: (data: IAddWashPlantProductionRequest) => unknown;
    getProductionSummaryRequest: (data: IGetWashPlantProductionSummaryRequest) => unknown;
    updateProductionRequest: (data: IUpdateWashPlantProductionRequest) => unknown;
};

interface IWashPlantFormComponentState {
    open: boolean;
    sError: string;
    expanded: number[];
    onErrorPopup: boolean;
    data: IWashPlantProductionRecords;
    unSavedDownTime: boolean;
    unSavedProduction: boolean;
    currentIndex: number | undefined;
    errors: ById<IFieldErrorKeyValue>;
    proPopup: IWashPlantProductionData | undefined;
    proErrors: ById<IFieldErrorKeyValue>;
};

const WashPlantFormComponentStyles = styled(LAPaperWithPadding)`
    margin: 20px 20px;
`;

type IWashPlantFormComponentProps =
    RouteComponentProps
    & IWashPlantFormComponentStoreProps
    & IWashPlantFormComponentDispatchProps;

class WashPlantFormComponent extends PureComponent<IWashPlantFormComponentProps, IWashPlantFormComponentState> {

    public constructor(props: IWashPlantFormComponentProps) {
        super(props);
        this.state = {
            errors: {
                "date": { key: "date", message: "Required" },
                "shift_Worked": { key: "shift_Worked", message: "Required" },
                // "control_Room": { key: "control_Room", message: "Required" },
                "plants": { key: "plants", message: "Required" },
                // "load_Operator1": { key: "load_Operator1", message: "Required" },
                // "load_Operator2": { key: "load_Operator2", message: "Required" },
                // "flocculant_Operator": { key: "flocculant_Operator", message: "Required" },
                // "employee4": { key: "employee4", message: "Required" },
                // "employee5": { key: "employee5", message: "Required" },               
                // "washplant_Operator": { key: "washplant_Operator", message: "Required" }
            },
            expanded: [1, 2, 3, 4, 5],
            data: {
                id: 0,
                date: "",
                washplant_Operator: "",
                shift_Worked: "",
                control_Room: "",
                load_Operator1: "",
                load_Operator2: "",
                flocculant_Operator: "",
                cleanup_Operator: "",
                employee4: "",
                employee5: "",
                labourer: "",
                plants: "",
                production_Shift_Goal: "",
                production_Notes: "",
                qA_QC_Notes: "",
                wear_Issues_Notes: "",
                created: "",
                modified: "",
                created_By: userName,
                modified_By: userName,
                downtimes: [],
                production_Data: [],
            },
            onErrorPopup: false,
            unSavedDownTime: false,
            unSavedProduction: false,
            open: false,
            proPopup: undefined,
            proErrors: {},
            currentIndex: undefined,
            sError: ""
        };
    }

    public componentDidMount(): void {
        this.getData();
        this.callSummaryData();
    };

    public async componentDidUpdate(prevProps: IWashPlantFormComponentProps): Promise<void> {
        if (this.props !== prevProps) {
            this.getData();

            if (this.props.addProduction !== prevProps.addProduction) {
                if (isSucceeded(this.props.addProduction)) {
                    this.handleBack();
                };
                if (isFailed(this.props.addProduction)) {
                    this.setState({ sError: this.props.addProduction.message });
                };
            };

            if (this.props.updateProduction !== prevProps.updateProduction) {
                if (isSucceeded(this.props.updateProduction)) {
                    this.handleBack();
                };
                if (isFailed(this.props.updateProduction)) {
                    this.setState({ sError: this.props.updateProduction.message });
                };
            };
        };
    }

    public render(): ReactNode {

        const { getDropDownData, addProduction, updateProduction, getProductionSummary, getItems, getItemById } = this.props;
        const { expanded, data, errors, unSavedProduction, onErrorPopup, proPopup, open, proErrors, sError } = this.state;

        const onDowntimeEdit = (): void => this.handleEditMode(false);
        const handleProductionInfo = (): void => this.handleExpand(IWashPlantFormSection.Production_Info);
        const handleProduction = (): void => this.handleExpand(IWashPlantFormSection.Production);
        const handleDowntime = (): void => this.handleExpand(IWashPlantFormSection.Downtime);
        const handleMaintenance = (): void => this.handleExpand(IWashPlantFormSection.Maintenance);
        const handleNotes = (): void => this.handleExpand(IWashPlantFormSection.Notes);
        const dropDowns = hasPayload(getDropDownData) ? getDropDownData.payload.response : undefined;
        const employees = dropDowns ? convertList(dropDowns.employees ?? []) : undefined;
        const plants = dropDowns ? convertList(dropDowns.plants ?? []) : undefined;
        const bottlenecks = dropDowns ? convertList(dropDowns.bottlenecks ?? []) : undefined;
        const byProducts = dropDowns ? convertList(dropDowns.byProducts ?? []) : undefined;
        const downtimeCategory = dropDowns ? convertList(dropDowns.downtime_Category ?? []) : undefined;
        const feedMaterials = dropDowns ? convertList(dropDowns.feedMaterials ?? []) : undefined;
        const runMaterials = dropDowns ? convertList(dropDowns.runMaterials ?? []) : undefined;
        const selectedPlants = data.plants ? data.plants.split(",") : [];
        const summary = (hasPayload(getProductionSummary) && data.id !== null && data.id !== 0) ? getProductionSummary.payload.response : [];
        const request = getItems.kind === STATUS_ENUM.NOT_LOADED ? getItemById : getItems;

        return (
            <PageSpacing title="SIL - Wash Plant Item" description="SIL - Wash Plant Item" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="washPlantAccess">

                    <WashPlantFormComponentStyles>

                        {request.kind === STATUS_ENUM.LOADING && <LALoading message="Loading data...." />}

                        {request.kind === STATUS_ENUM.SUCCEEDED && <LAGrid>

                            <LAGridItem xs={12}>

                                <LAButton startIcon={<ArrowLeftIcon color={WHITE_COLOR} />} label="Back to list" onClick={this.handleBack} />
                                <h2 className="text-center">{data.id === 0 ? "ADD NEW ITEM" : "UPDATE ITEM"}</h2>
                                <hr />

                                <LAExpansionPanel color={DRY_PLANT_FORM_TILE_COLOR} textColor={WHITE_COLOR} label="Production Info" expanded={(expanded.includes(IWashPlantFormSection.Production_Info))} onClick={handleProductionInfo}>
                                    <LAGrid>

                                        <LAGridItem xs={4}>
                                            <LADateTimePicker
                                                fullWidth={true}
                                                name="date"
                                                label="Date"
                                                disabled={true}
                                                value={data.date ?? ""}
                                                onChange={this.handleFormByChange}
                                                errorText={errors["date"] ? errors["date"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={4}>
                                            <LAAutoComplete
                                                name="plants"
                                                multiple={true}
                                                option={plants}
                                                autoHighlight={true}
                                                disabled={true}
                                                getOptionLabel="name"
                                                filterSelectedOptions={true}
                                                dropDownPlaceHolder="Plants"
                                                onChange={this.handlePlantsDropDown}
                                                selectionRemove={this.handlePlantRemove}
                                                errorText={errors["plants"] ? errors["plants"].message : undefined}
                                                value={(plants && (selectedPlants.length > 0) ? filterPlantList(plants, selectedPlants) : [])}
                                                defaultValue={(plants && (selectedPlants.length > 0) ? filterPlantList(plants, selectedPlants) : [])}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="Shift Worked"
                                                getOptionLabel="name"
                                                name="shift_Worked"
                                                option={shiftWorkOptions}
                                                disabled={true}
                                                multiple={false}
                                                onChange={this.handleFormByDropDownChange}
                                                errorText={errors["shift_Worked"] ? errors["shift_Worked"].message : undefined}
                                                value={((shiftWorkOptions.length > 0) && (data.shift_Worked.length > 0)) ? shiftWorkOptions.find(x => x.name === data.shift_Worked) : ""}
                                                defaultValue={((shiftWorkOptions.length > 0) && (data.shift_Worked.length > 0)) ? shiftWorkOptions.find(x => x.name === data.shift_Worked) : ""}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="Wash Plant Operator"
                                                name="washplant_Operator"
                                                getOptionLabel="name"
                                                option={employees}
                                                multiple={false}
                                                onChange={this.handleFormByDropDownChange}
                                                errorText={errors["washplant_Operator"] ? errors["washplant_Operator"].message : undefined}
                                                value={(employees && (employees.length > 0) && data.washplant_Operator && (data.washplant_Operator.length > 0)) ? employees.find(x => x.name === data.washplant_Operator) : ""}
                                                defaultValue={(employees && (employees.length > 0) && data.washplant_Operator && (data.washplant_Operator.length > 0)) ? employees.find(x => x.name === data.washplant_Operator) : ""}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="Load Operator 1"
                                                name="load_Operator1"
                                                getOptionLabel="name"
                                                option={employees}
                                                multiple={false}
                                                onChange={this.handleFormByDropDownChange}
                                                errorText={errors["load_Operator1"] ? errors["load_Operator1"].message : undefined}
                                                value={(employees && (employees.length > 0) && data.load_Operator1 && (data.load_Operator1.length > 0)) ? employees.find(x => x.name === data.load_Operator1) : ""}
                                                defaultValue={(employees && (employees.length > 0) && data.load_Operator1 && (data.load_Operator1.length > 0)) ? employees.find(x => x.name === data.load_Operator1) : ""}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="Load Operator 2"
                                                name="load_Operator2"
                                                getOptionLabel="name"
                                                option={employees}
                                                multiple={false}
                                                onChange={this.handleFormByDropDownChange}
                                                errorText={errors["load_Operator2"] ? errors["load_Operator2"].message : undefined}
                                                value={(employees && (employees.length > 0) && data.load_Operator2 && (data.load_Operator2.length > 0)) ? employees.find(x => x.name === data.load_Operator2) : ""}
                                                defaultValue={(employees && (employees.length > 0) && data.load_Operator2 && (data.load_Operator2.length > 0)) ? employees.find(x => x.name === data.load_Operator2) : ""}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="Flocculant Operator"
                                                name="flocculant_Operator"
                                                getOptionLabel="name"
                                                option={employees}
                                                multiple={false}
                                                onChange={this.handleFormByDropDownChange}
                                                errorText={errors["flocculant_Operator"] ? errors["flocculant_Operator"].message : undefined}
                                                value={(employees && (employees.length > 0) && data.flocculant_Operator && (data.flocculant_Operator.length > 0)) ? employees.find(x => x.name === data.flocculant_Operator) : ""}
                                                defaultValue={(employees && (employees.length > 0) && data.flocculant_Operator && (data.flocculant_Operator.length > 0)) ? employees.find(x => x.name === data.flocculant_Operator) : ""}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="Cleanup Operator"
                                                name="cleanup_Operator"
                                                getOptionLabel="name"
                                                option={employees}
                                                multiple={false}
                                                onChange={this.handleFormByDropDownChange}
                                                errorText={errors["cleanup_Operator"] ? errors["cleanup_Operator"].message : undefined}
                                                value={(employees && (employees.length > 0) && data.cleanup_Operator && (data.cleanup_Operator.length > 0)) ? employees.find(x => x.name === data.cleanup_Operator) : ""}
                                                defaultValue={(employees && (employees.length > 0) && data.cleanup_Operator && (data.cleanup_Operator.length > 0)) ? employees.find(x => x.name === data.cleanup_Operator) : ""}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="Jr. Operator"
                                                name="employee4"
                                                getOptionLabel="name"
                                                option={employees}
                                                multiple={false}
                                                onChange={this.handleFormByDropDownChange}
                                                errorText={errors["employee4"] ? errors["employee4"].message : undefined}
                                                value={(employees && (employees.length > 0) && (data.employee4) && (data.employee4.length > 0)) ?
                                                    employees.find(x => x.name === data.employee4) : ""}
                                                defaultValue={(employees && (employees.length > 0) && (data.employee4) && (data.employee4.length > 0)) ?
                                                    employees.find(x => x.name === data.employee4) : ""}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="QA/QC"
                                                name="employee5"
                                                getOptionLabel="name"
                                                option={employees}
                                                multiple={false}
                                                onChange={this.handleFormByDropDownChange}
                                                errorText={errors["employee5"] ? errors["employee5"].message : undefined}
                                                value={(employees && (employees.length > 0) && (data.employee5) && (data.employee5.length > 0)) ?
                                                    employees.find(x => x.name === data.employee5) : ""}
                                                defaultValue={(employees && (employees.length > 0) && (data.employee5) && (data.employee5.length > 0)) ?
                                                    employees.find(x => x.name === data.employee5) : ""}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="Labourer"
                                                name="labourer"
                                                getOptionLabel="name"
                                                option={employees}
                                                multiple={false}
                                                onChange={this.handleFormByDropDownChange}
                                                errorText={errors["labourer"] ? errors["labourer"].message : undefined}
                                                value={(employees && (employees.length > 0) && (data.labourer) && (data.labourer.length > 0)) ?
                                                    employees.find(x => x.name === data.labourer) : ""}
                                                defaultValue={(employees && (employees.length > 0) && (data.labourer) && (data.labourer.length > 0)) ?
                                                    employees.find(x => x.name === data.labourer) : ""}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={4}>
                                            <LATextArea
                                                minRows={5}
                                                rowsMax={6}
                                                fullWidth={true}
                                                name="production_Shift_Goal"
                                                label="Production Shift Goal"
                                                onChange={this.handleFormByChange}
                                                value={data.production_Shift_Goal ?? ""}
                                            />
                                        </LAGridItem>

                                    </LAGrid>
                                </LAExpansionPanel>
                            </LAGridItem>

                            <LAGridItem xs={12}>
                                <LAExpansionPanel color={DRY_PLANT_FORM_TILE_COLOR} textColor={WHITE_COLOR} label="Notes" expanded={expanded.includes(IWashPlantFormSection.Notes)} onClick={handleNotes}>
                                    <LAGrid>

                                        <LAGridItem xs={6}>
                                            <LATextArea
                                                minRows={5}
                                                rowsMax={8}
                                                fullWidth={true}
                                                name="production_Notes"
                                                label="Production Notes"
                                                value={data.production_Notes ?? ""}
                                                onChange={this.handleFormByChange}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={6}>
                                            <LATextArea
                                                minRows={5}
                                                rowsMax={8}
                                                fullWidth={true}
                                                name="qA_QC_Notes"
                                                label="QA QC Notes"
                                                value={data.qA_QC_Notes ?? ""}
                                                onChange={this.handleFormByChange}
                                            />
                                        </LAGridItem>

                                    </LAGrid>
                                </LAExpansionPanel>
                            </LAGridItem>

                            <LAGridItem xs={12}>
                                <LAExpansionPanel color={DRY_PLANT_FORM_TILE_COLOR} textColor={WHITE_COLOR} label="Production" expanded={expanded.includes(IWashPlantFormSection.Production)} onClick={handleProduction}>
                                    <LAGridItem xs={12}>

                                        <div onClick={this.onProductionAdd} className="dx-widget dx-button dx-button-mode-contained dx-button-normal dx-button-has-text dx-button-has-icon dx-datagrid-toolbar-button dx-edit-button dx-datagrid-addrow-button">
                                            <div className="dx-button-content"><i className="dx-icon dx-icon-edit-button-addrow"></i></div>
                                        </div>

                                        {plants && runMaterials && byProducts && bottlenecks && feedMaterials &&
                                            <LADevExtremeGrid
                                                height={500}
                                                id="production"
                                                columnWidth={175}
                                                searchPanel={false}
                                                filterHeader={false}
                                                moveBtnToLeft={true}
                                                data={data.production_Data}
                                                onClick={undefinedFunction}
                                                onEdit={this.onProEdit}
                                                onNewRowInsert={this.onProductionAdd}
                                                columns={[
                                                    { name: "plant", caption: "Plant", dropDownId: "id", dropDownName: "name", dropDownSource: plants, requiredField: true, width: 90 },
                                                    { name: "feed_1_Material", caption: "Feeder 1 North", type: "string", width: 100 },
                                                    { name: "feed_1_Perc", caption: "Feeder1_North_MT", type: "number", width: 100 },
                                                    { name: "feed_2_Material", caption: "Feeder 1 South", type: "string", width: 100 },
                                                    { name: "feed_2_Perc", caption: "Feeder1_South_MT", type: "number", width: 80 },
                                                    { name: "feed_3_Material", caption: "Feeder 2 North", type: "string", width: 100 },
                                                    { name: "feed_3_Perc", caption: "Feeder2_North_MT", type: "number", width: 100 },
                                                    { name: "feed_4_Material", caption: "Feeder 2 South", type: "string", width: 100 },
                                                    { name: "feed_4_Perc", caption: "Feeder2_South_MT", type: "number", width: 80 },
                                                    { name: "feed_Tonnage", caption: "Feed Tonnage", type: "number", width: 100 },
                                                    { name: "run_Hours", caption: "Run Hours", type: "string", allowEditing: false, width: 80 },
                                                    { name: "by_Product_1", caption: "Tower 1", dropDownId: "id", dropDownName: "name", dropDownSource: byProducts, width: 100 },
                                                    { name: "by_Product_1_MT", caption: "Tower 1 MT", type: "string", width: 100 },
                                                    { name: "by_Product_2", caption: "Tower 2", dropDownId: "id", dropDownName: "name", dropDownSource: runMaterials, width: 100 },
                                                    { name: "by_Product_2_MT", caption: "Tower 2 MT", type: "string", width: 100 },
                                                    { name: "by_Product_3", caption: "Tower 4", dropDownId: "id", dropDownName: "name", dropDownSource: byProducts, width: 100 },
                                                    // { name: "by_Product_3_MT", caption: "Tower 4 MT", type: "string", width: 100 },
                                                    // { name: "by_Product_4", caption: "Fines Tower", dropDownId: "id", dropDownName: "name", dropDownSource: runMaterials, width: 100 },
                                                    // { name: "by_Product_4_MT", caption: "Fines Tower MT", type: "string", width: 100 },
                                                    // { name: "tph", caption: "TPH", type: "number", width: 90 },
                                                ]}
                                                sumColumn={[
                                                    { columnName: "by_Product_1_MT", caption: "Total" },
                                                    { columnName: "by_Product_2_MT", caption: "Total" },
                                                    { columnName: "by_Product_3_MT", caption: "Total" },
                                                    { columnName: "by_Product_4_MT", caption: "Total" },
                                                    { columnName: "by_Product_5_MT", caption: "Total" },
                                                    { columnName: "by_Product_6_MT", caption: "Total" },
                                                    { columnName: "by_Product_7_MT", caption: "Total" },
                                                    { columnName: "by_Product_8_MT", caption: "Total" },
                                                    { columnName: "feed_Tonnage", caption: "Total" }
                                                ]}
                                            />}
                                    </LAGridItem>
                                </LAExpansionPanel>
                                <LAGridItem xs={12}>
                                    <ProductionSummaryTable {...this.props} data={summary} />
                                </LAGridItem>
                            </LAGridItem>

                            <LAGridItem xs={12}>
                                <LAExpansionPanel color={DRY_PLANT_FORM_TILE_COLOR} textColor={WHITE_COLOR} label="Downtime" expanded={expanded.includes(IWashPlantFormSection.Downtime)} onClick={handleDowntime}>
                                    <LAGridItem xs={12}>
                                        {plants && causeOptions && downtimeCategory &&
                                            <LADevExtremeGrid
                                                add={true}
                                                height={500}
                                                id="downtime"
                                                columnWidth={200}
                                                searchPanel={false}
                                                filterHeader={false}
                                                moveBtnToLeft={true}
                                                data={data.downtimes}
                                                // onDelete={onDowntimeEdit}
                                                // onCustomEdit={this.onDTEdit}
                                                onClick={undefinedFunction}
                                                onEditSave={onDowntimeEdit}
                                                onEditStart={onDowntimeEdit}
                                                onEditCancel={onDowntimeEdit}
                                                onNewRowInsert={onDowntimeEdit}
                                                columns={[
                                                    { name: "plant", caption: "Plant", dropDownId: "id", dropDownName: "name", dropDownSource: plants, requiredField: true },
                                                    // { name: "un_Scheduled_Downtime_Hrs", caption: "UNSCHD Hrs", type: "number", width: 110 },
                                                    // { name: "unschD_Notes", caption: "UNSCHD Notes", type: "string" },
                                                    // { name: "scheduled_Downtime_Hrs", caption: "SCHD Hrs", type: "number", width: 100 },
                                                    // { name: "schD_Notes", caption: "SCHD Notes", type: "string" },
                                                    // { name: "component", caption: "Component", type: "string" },
                                                    // { name: "cause", caption: "Cause", dropDownId: "id", dropDownName: "name", dropDownSource: causeOptions },
                                                    // { name: "reason", caption: "Reason", type: "string" },
                                                    // { name: "category", caption: "Category", dropDownId: "id", dropDownName: "name", dropDownSource: downtimeCategory }
                                                    { name: "un_Scheduled_Downtime_Hrs", caption: "UNSCHD Hrs", type: "number", width: 110 },
                                                    { name: "downtime_Note_1", caption: "Note 1", type: "string" },
                                                    { name: "downtime_Note_2", caption: "Note 2", type: "string" },
                                                    { name: "downtime_Note_3", caption: "Note 3", type: "string" },
                                                    { name: "downtime_Note_4", caption: "Note 4", type: "string" },
                                                    { name: "downtime_Note_5", caption: "Note 5", type: "string" },
                                                    { name: "downtime_Note_6", caption: "Note 6", type: "string" },
                                                ]}
                                                sumColumn={[
                                                    { columnName: "un_Scheduled_Downtime_Hrs", caption: "Total Hrs" },
                                                    { columnName: "scheduled_Downtime_Hrs", caption: "Total Hrs" }
                                                ]}
                                            />}
                                    </LAGridItem>
                                </LAExpansionPanel>
                            </LAGridItem>

                            <LAGridItem xs={12}>
                                <LAExpansionPanel color={DRY_PLANT_FORM_TILE_COLOR} textColor={WHITE_COLOR} label="Maintenance" expanded={expanded.includes(IWashPlantFormSection.Maintenance)} onClick={handleMaintenance}>
                                    <LAGrid>

                                        <LAGridItem xs={6}>
                                            <LATextField
                                                fullWidth={true}
                                                varient="outlined"
                                                name="maintenance_By"
                                                label="Maintenance By"
                                                value={data.maintenance_By ?? ""}
                                                onChange={this.handleFormByChange}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={6}>
                                            <LATextArea
                                                minRows={5}
                                                rowsMax={8}
                                                fullWidth={true}
                                                name="maintenance_Performed"
                                                label="Maintenance Performed"
                                                onChange={this.handleFormByChange}
                                                value={data.maintenance_Performed ?? ""}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={6}>
                                            <LATextArea
                                                minRows={5}
                                                rowsMax={8}
                                                fullWidth={true}
                                                name="wear_Issues_Notes"
                                                label="Wear Issues Notes"
                                                onChange={this.handleFormByChange}
                                                value={data.wear_Issues_Notes ?? ""}
                                            />
                                        </LAGridItem>

                                    </LAGrid>
                                </LAExpansionPanel>
                            </LAGridItem>

                            {sError.length > 0 && <LAGridItem xs={12}>
                                <LAErrorBox text={sError} />
                            </LAGridItem>}

                            <LAGridItem xs={12}>
                                <LASaveAndCancelButton
                                    onSave={this.handleSave}
                                    onCancel={this.handleBack}
                                    disableSave={(Object.values(errors).length > 0) ? true : undefined}
                                //disableSave={true}
                                />
                            </LAGridItem>
                        </LAGrid>}

                        {open && proPopup && plants && runMaterials && byProducts && bottlenecks && feedMaterials &&
                            <WashPlantItemProdPopup
                                {...this.state}
                                open={open}
                                data={proPopup}
                                errors={proErrors}
                                byProducts={byProducts}
                                onSave={this.onProSave}
                                bottlenecks={bottlenecks}
                                runMaterials={runMaterials}
                                feedMaterials={feedMaterials}
                                onClose={this.onProductionClose}
                                onChange={this.onProductionChange}
                                onWaterFeedTonnage={this.onWaterFeedTonnage}
                                plants={filterPlantList(plants, selectedPlants)}
                            />}

                        {onErrorPopup && <LAPopover open={onErrorPopup} onClose={this.handlePopup} anchorRef={null}>
                            <LAPaperWithPadding className="text-center">
                                <LAErrorBox text={
                                    unSavedProduction ? "You have some unsaved changes in Production Section" :
                                        "You have some unsaved changes in Downtime Section"
                                } />
                                <LAButton label="Close" onClick={this.handlePopup} />
                            </LAPaperWithPadding>
                        </LAPopover>}

                        <RequestStatus requestStatus={addProduction.kind} successMessage="New Item successfully saved" />
                        <RequestStatus requestStatus={updateProduction.kind} successMessage="Item successfully updated" />
                    </WashPlantFormComponentStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private onWaterFeedTonnage = (name: string, value: string | number): void => {
        const iS = this.state.proPopup;
        if (iS && !isNaN(+value)) {
            iS.water_Feed_Rate = +value;
            this.setState({
                ...this.state,
                proPopup: {
                    ...this.state.proPopup,
                    ...iS
                }
            });
        };
    };

    private onProductionChange = (name: string, value: string | number): void => {
        const iS = this.state.proPopup;
        let proErrors = this.state.proErrors;

        if (iS) {

            let disableMild = false;
            let run_Hours = iS.run_Hours;
            let tph = iS.tph;

            if ((name === "plant") || (name === "run_Material") || (name === "run_Material_MT") || (name === "feed_Tonnage") ||
                (name === "bottle_Neck") || (name === "feed_1_Material") || (name === "feed_1_Perc")) {
                proErrors = this.errorChecker(name, value.toString(), proErrors, true);
            };

            if (name === "plant") {
                if (value === "D201" || value === "D202")
                    disableMild = true;
            };

            switch (name) {

                case "by_Product_1": {
                    if (value !== null && value !== "") {
                        delete proErrors["by_Product_1"];
                    } else {
                        if (iS.by_Product_1_MT !== undefined || iS.by_Product_1_MT !== null)
                            proErrors["by_Product_1"] = { key: "by_Product_1", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                    }
                    break;
                }
                case "by_Product_2": {
                    if (value !== null && value !== "") {
                        delete proErrors["by_Product_2"];
                    } else {
                        if (iS.by_Product_2_MT !== undefined || iS.by_Product_2_MT !== null)
                            proErrors["by_Product_2"] = { key: "by_Product_2", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                    }
                    break;
                }
                case "by_Product_3": {
                    if (value !== null && value !== "") {
                        delete proErrors["by_Product_3"];
                    } else {
                        if (iS.by_Product_3_MT !== undefined || iS.by_Product_3_MT !== null)
                            proErrors["by_Product_3"] = { key: "by_Product_3", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                    }
                    break;
                }
                case "by_Product_4": {
                    if (value !== null && value !== "") {
                        delete proErrors["by_Product_4"];
                    } else {
                        if ((iS.by_Product_4_MT !== undefined || iS.by_Product_4_MT !== null))
                            proErrors["by_Product_4"] = { key: "by_Product_4", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                    }
                    break;
                }
            }

            if (name === "start_Time" || name === "start_Time_Mins" || name === "stop_Time" || name === "stop_Time_Mins") {
                let sT = new Date();
                let eT = new Date();
                let feedTons = iS.feed_Tonnage ?? 0;

                switch (name) {
                    case "start_Time": {
                        sT.setHours(Number(value) ?? 0);
                        sT.setMinutes(iS.start_Time_Mins ?? 0);
                        eT.setHours(iS.stop_Time ?? 0);
                        eT.setMinutes(iS.stop_Time_Mins ?? 0);
                        break;
                    }
                    case "start_Time_Mins": {
                        sT.setHours(iS.start_Time ?? 0);
                        sT.setMinutes(Number(value) ?? 0);
                        eT.setHours(iS.stop_Time ?? 0);
                        eT.setMinutes(iS.stop_Time_Mins ?? 0);
                        break;
                    }
                    case "stop_Time": {
                        sT.setHours(iS.start_Time ?? 0);
                        sT.setMinutes(iS.start_Time_Mins ?? 0);
                        eT.setHours(Number(value) ?? 0);
                        eT.setMinutes(iS.stop_Time_Mins ?? 0);
                        break;
                    }
                    case "stop_Time_Mins": {
                        sT.setHours(iS.start_Time ?? 0);
                        sT.setMinutes(iS.start_Time_Mins ?? 0);
                        eT.setHours(iS.stop_Time ?? 0);
                        eT.setMinutes(Number(value) ?? 0);
                        break;
                    }
                }

                if (sT > eT) {
                    eT.setDate(eT.getDate() + 1)
                }

                run_Hours = gethourTime(eT, sT);
                let totalHours = gethourOnlyTime(eT, sT);
                let tempTotal = totalHours ? Number(totalHours) : 0;
                if (tempTotal > 0) {
                    tph = Math.round(Number(feedTons / tempTotal));
                }

            };

            // proErrors = this.checkFeedPercentage(name, value, iS, proErrors);
            // proErrors = this.checkByProduct(name, value, iS, proErrors);

            this.setState({
                ...this.state,
                proPopup: {
                    ...iS,
                    [name]: value,
                    production_Mid: disableMild ? "N/A" : (name === "production_Mid" ? value.toString() : iS.production_Mid),
                    run_Hours, tph
                },
                proErrors
            });
        }
    };

    private checkFeedPercentage = (name: string, value: string | number, iS: IWashPlantProductionData | undefined, proErrors: ById<IFieldErrorKeyValue>): ById<IFieldErrorKeyValue> => {
        if (iS && !isNaN(+value)) {
            let p1 = iS.feed_1_Perc ?? 0;
            let p2 = iS.feed_2_Perc ?? 0;
            let p3 = iS.feed_3_Perc ?? 0;
            let p4 = iS.feed_4_Perc ?? 0;

            switch (name) {
                case "feed_1_Perc": {
                    p1 = Number(value);
                    break;
                }
                case "feed_2_Perc": {
                    p2 = Number(value);
                    break;
                }
                case "feed_3_Perc": {
                    p3 = Number(value);
                    break;
                }
                case "feed_4_Perc": {
                    p4 = Number(value);
                    break;
                }
            }

            let total = (p1) + (p2) + (p3) + (p4);
            if (total < 100) {
                proErrors["feed_1_Perc"] = { key: "feed_1_Perc", message: "Total feed % is less than 100" };
                proErrors["feed_2_Perc"] = { key: "feed_2_Perc", message: "Total feed % is less than 100" };
                proErrors["feed_3_Perc"] = { key: "feed_3_Perc", message: "Total feed % is less than 100" };
                proErrors["feed_4_Perc"] = { key: "feed_4_Perc", message: "Total feed % is less than 100" };
            } else if (total > 100) {
                proErrors["feed_1_Perc"] = { key: "feed_1_Perc", message: "Total feed % is more than 100" };
                proErrors["feed_2_Perc"] = { key: "feed_2_Perc", message: "Total feed % is more than 100" };
                proErrors["feed_3_Perc"] = { key: "feed_3_Perc", message: "Total feed % is more than 100" };
                proErrors["feed_4_Perc"] = { key: "feed_4_Perc", message: "Total feed % is more than 100" };
            } else if (total === 100) {
                delete proErrors["feed_1_Perc"];
                delete proErrors["feed_2_Perc"];
                delete proErrors["feed_3_Perc"];
                delete proErrors["feed_4_Perc"];
            };
        };
        return proErrors;
    };

    private checkByProduct = (name: string, value: string | number, iS: IWashPlantProductionData | undefined, proErrors: ById<IFieldErrorKeyValue>): ById<IFieldErrorKeyValue> => {
        if (iS && !isNaN(+value)) {
            //Total Check for By Products MT against feed tonnage

            let p1 = iS.by_Product_1_MT ?? 0;
            let p2 = iS.by_Product_2_MT ?? 0;
            let p3 = iS.by_Product_3_MT ?? 0;
            let p4 = iS.by_Product_4_MT ?? 0;

            switch (name) {
                case "by_Product_1_MT": {
                    p1 = Number(value);
                    if (iS.by_Product_1 === undefined || iS.by_Product_1 === null || iS.by_Product_1 === "")
                        proErrors["by_Product_1"] = { key: "by_Product_1", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                    break;
                }
                case "by_Product_2_MT": {
                    p2 = Number(value);
                    if ((iS.by_Product_2 === undefined || iS.by_Product_2 === null || iS.by_Product_2 === "") && p2 > 0)
                        proErrors["by_Product_2"] = { key: "by_Product_2", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                    break;
                }
                case "by_Product_3_MT": {
                    p3 = Number(value);
                    if ((iS.by_Product_3 === undefined || iS.by_Product_3 === null || iS.by_Product_3 === "") && p3 > 0)
                        proErrors["by_Product_3"] = { key: "by_Product_3", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                    break;
                }
                case "by_Product_4_MT": {
                    p4 = Number(value);
                    if ((iS.by_Product_4 === undefined || iS.by_Product_4 === null || iS.by_Product_4 === "") && p4 > 0)
                        proErrors["by_Product_4"] = { key: "by_Product_4", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                    break;
                }
            }

            let feedTonange = iS.feed_Tonnage ?? 0;

            let total = (p1) + (p2) + (p3) + (p4);
            if (total !== feedTonange) {
                proErrors["by_Product_1_MT"] = { key: "by_Product_1_MT", message: "Total By Products MT should be equal to Feed Tonnage" };
                proErrors["by_Product_2_MT"] = { key: "by_Product_2_MT", message: "Total By Products MT should be equal to Feed Tonnage" };
                proErrors["by_Product_3_MT"] = { key: "by_Product_3_MT", message: "Total By Products MT should be equal to Feed Tonnage" };
                proErrors["by_Product_4_MT"] = { key: "by_Product_4_MT", message: "Total By Products MT should be equal to Feed Tonnage" };
            } else {
                delete proErrors["by_Product_1_MT"];
                delete proErrors["by_Product_2_MT"];
                delete proErrors["by_Product_3_MT"];
                delete proErrors["by_Product_4_MT"];
            };
        };
        return proErrors;
    };

    private onProSave = (): void => {
        const production_Data = [...this.state.data.production_Data];

        const newData = this.state.proPopup;
        if (newData) {
            if (this.state.currentIndex === undefined) {
                production_Data.push(newData);
            } else {
                production_Data[this.state.currentIndex] = newData;
            }
        }

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                production_Data
            },
            open: false,
            proPopup: undefined,
            currentIndex: undefined
        });
    };

    private onProductionAdd = (): void => {
        let proErrors: ById<IFieldErrorKeyValue> = {};

        proErrors["plant"] = { key: "plant", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
        proErrors["bottle_Neck"] = { key: "bottle_Neck", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
        proErrors["feed_1_Material"] = { key: "feed_1_Material", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
        proErrors["by_Product_1"] = { key: "by_Product_1", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
        proErrors["feed_Tonnage"] = { key: "feed_Tonnage", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
        proErrors["feed_1_Perc"] = { key: "feed_1_Perc", message: "Total feed % is less than 100" };
        proErrors["feed_2_Perc"] = { key: "feed_2_Perc", message: "Total feed % is less than 100" };
        proErrors["feed_3_Perc"] = { key: "feed_3_Perc", message: "Total feed % is less than 100" };
        proErrors["feed_4_Perc"] = { key: "feed_4_Perc", message: "Total feed % is less than 100" };
        proErrors["by_Product_1"] = { key: "by_Product_1", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
        // proErrors["by_Product_2"] = { key: "by_Product_2", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
        // proErrors["by_Product_3"] = { key: "by_Product_3", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
        // proErrors["by_Product_4"] = { key: "by_Product_4", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
        proErrors["by_Product_1_MT"] = { key: "by_Product_1_MT", message: "Total By Products MT should be equal to Feed Tonnage" };
        proErrors["by_Product_2_MT"] = { key: "by_Product_2_MT", message: "Total By Products MT should be equal to Feed Tonnage" };
        proErrors["by_Product_3_MT"] = { key: "by_Product_3_MT", message: "Total By Products MT should be equal to Feed Tonnage" };
        proErrors["by_Product_4_MT"] = { key: "by_Product_4_MT", message: "Total By Products MT should be equal to Feed Tonnage" };

        this.setState({
            open: true,
            proPopup: {
                id: 0,
                listID: 0,
                plant: "",
                run_Material: "",
                run_Material_MT: 0,
                by_Product_1: "",
                by_Product_1_MT: 0,
                start_Time: 0,
                start_Time_Mins: 0,
                stop_Time: 0,
                stop_Time_Mins: 0,
                run_Hours: "0:00",
                production_Top: "",
                production_Mid: "",
                production_Bottom: "",
                bottle_Neck: "",
                feed_1_Material: "",
                feed_1_Perc: 0,
                created: "",
                modified: "",
                feed_Tonnage: 0,
                created_By: userName,
                modified_By: userName
            },
            proErrors,
            currentIndex: undefined
        });
    };

    private onProductionClose = (): void => {
        this.setState({
            open: false,
            proPopup: undefined
        });
    };

    private onProDelete = (e: any): void => {
        let result = confirm("<i>Are you sure you?</i>", "Confirm Delete");
        result.then((dialogResult) => {
            if (dialogResult) {

                const index = this.state.data.production_Data.findIndex(x => x.id === e.row.key.id);
                const data = [...this.state.data.production_Data];
                data.splice(index, 1);

                this.setState({
                    ...this.state,
                    data: {
                        ...this.state.data,
                        production_Data: data
                    }
                });
            }
        });
    };

    private handlePlantsDropDown = (event: unknown, values: { id: string, name: string }[]): void => {
        let plants = "";
        values.forEach(x => {
            if (x.name !== "") {
                if (plants.length === 0) {
                    plants = x.name;
                } else {
                    plants = plants + "," + x.name;
                }
            }
        });

        let errors = this.state.errors;
        errors = this.errorChecker("plants", plants, errors, true);

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                plants
            },
            errors
        });
    };

    private handlePlantRemove = (value: string, index: number): void => {
        let plants = "";
        let selected = this.state.data.plants.split(",");

        selected.forEach(x => {
            if (x !== "" && x !== value) {
                if (plants.length === 0) {
                    plants = x;
                } else {
                    plants = plants + "," + x;
                }
            }

        });

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                plants
            }
        });
    };

    private handleEditMode = (production: boolean): void => {
        if (production) {
            this.setState({ unSavedProduction: !this.state.unSavedProduction });
        } else {
            this.setState({ unSavedDownTime: !this.state.unSavedDownTime });
        };
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, isRequired: boolean): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: isRequired ? true : undefined, minLength: 1, decimal: undefined });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        };
        return err;
    };

    private handleBack = (): void => {
        this.props.history.push(ROUTE.SIL.WASH_PLANT.WASH_PLANT_ITEMS);
    };

    private handlePopup = (): void => {
        this.setState({ onErrorPopup: !this.state.onErrorPopup });
    };

    private handleSave = (): void => {
        const { data, unSavedProduction, unSavedDownTime } = this.state;
        if ((!unSavedDownTime) && (!unSavedProduction)) {

            if (hasPayload(this.props.getToken))
                if (data.id === 0) {
                    this.props.addProductionRequest({
                        token: this.props.getToken.payload.response.token,
                        request: data
                    });
                } else {
                    data.modified_By = userName;
                    this.props.updateProductionRequest({
                        token: this.props.getToken.payload.response.token,
                        request: data
                    });
                };
        } else {
            this.handlePopup();
        };
        this.setState({ sError: "" });
    };

    private callServer = (): void => {

        if (hasPayload(this.props.getToken)) {
            const query = queryString.parse(this.props.location.search);
            const id = query.id ?? "0";

            if (id !== "0") {
                if (isNotLoaded(this.props.getItems)) {
                    if (isNotLoaded(this.props.getItemById))
                        this.props.getItemByIdRequest({
                            token: this.props.getToken.payload.response.token,
                            request: {
                                id: +id
                            }
                        });
                } else {
                    if (isNotLoaded(this.props.getItems))
                        this.props.getItemsRequest({
                            token: this.props.getToken.payload.response.token
                        });
                }
            }
        }

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getDropDownData))
            this.props.getDropDownDataRequest({
                token: this.props.getToken.payload.response.token,
                requested_Page: "Washplant"
            });


        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });
    };

    private getData = (): void => {
        const query = queryString.parse(this.props.location.search);
        const id = query.id ?? "0";

        this.callServer();
        let data = undefined;

        if (isNotLoaded(this.props.getItems) && hasPayload(this.props.getItemById)) {
            data = this.props.getItemById.payload.response;
        } else {
            if (hasPayload(this.props.getItems))
                data = this.props.getItems.payload.response[id.toString()];
        }

        if (data && id !== "0") {
            let errors = this.state.errors;

            if (data.washplant_Operator !== null)
                delete errors["washplant_Operator"]
            if (data.load_Operator1 !== null)
                delete errors["load_Operator1"];
            if (data.load_Operator1 !== null)
                delete errors["load_Operator2"];
            if (data.flocculant_Operator !== null)
                delete errors["flocculant_Operator"];
            if (data.cleanup_Operator !== null)
                delete errors["cleanup_Operator"];
            if (data.employee4 !== null)
                delete errors["employee4"];
            if (data.employee5 !== null)
                delete errors["employee5"];
            if (data.date !== null)
                delete errors["date"];
            if (data.shift_Worked !== null)
                delete errors["shift_Worked"];
            if (data.control_Room !== null)
                delete errors["control_Room"];
            if (data.plants !== null)
                delete errors["plants"];

            this.setState({ data, errors });
        };
    };

    private callSummaryData = (): void => {
        const query = queryString.parse(this.props.location.search);
        const id = query.id;
        if (hasPayload(this.props.getToken) && id !== null && id !== "0") {
            this.props.getProductionSummaryRequest({
                token: this.props.getToken.payload.response.token,
                request: {
                    itemId: +id
                }
            });
        }
    };

    private handleFormByChange = (name: string, value: string): void => {

        let errors = this.state.errors;
        if (name === "date") {
            value = new Date(value).toDateString();
            errors = this.errorChecker(name, value, errors, true);
        }

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [name]: value
            },
            errors
        });
    };

    private handleFormByDropDownChange = (event: unknown, value: { id: number, name: string } | "", name?: string): void => {
        if (name) {
            let errors = this.state.errors;

            if ((name === "shift_Worked") || (name === "dryplant_Operator") || (name === "load_Operator1") || (name === "control_Room")
                || (name === "load_Operator2") || (name === "flocculant_Operator") || (name === "cleanup_Operator") || (name === "employee4") || (name === "employee5")
                || (name === "washplant_Operator")) {
                errors = this.errorChecker(name, value !== "" ? value.name : "", errors, true);
            }

            this.setState({
                ...this.state,
                errors,
                data: {
                    ...this.state.data,
                    [name]: value !== "" ? value.name : ""
                }
            });
        }
    };

    private handleExpand = (expanded: number): void => {
        const iS = [...this.state.expanded];

        if (this.state.expanded.includes(expanded)) {
            iS.splice(iS.findIndex(x => x === expanded), 1);
        } else {
            iS.push(expanded);
        };

        this.setState({ expanded: iS });
    };

    private onProEdit = (e: any): void => {
        const record = this.state.data.production_Data[e.row.dataIndex];
        let proErrors: ById<IFieldErrorKeyValue> = {};

        if (record)
            this.setState({
                open: true,
                proPopup: record,
                currentIndex: e.row.dataIndex,
                proErrors
            });
    };

    private onDTEdit = (): void => {
    };
};

const mapStateToProps = (state: IStore): IWashPlantFormComponentStoreProps => ({
    getToken: getTokenStatus(state),
    getItems: getWashPlantProductionRecordsStatus(state),
    getItemById: getWashPlantProductionRecordByIdStatus(state),
    getDropDownData: getWashPlantProductionLookupsStatus(state),
    addProduction: addWashPlantProductionStatus(state),
    updateProduction: updateWashPlantProductionStatus(state),
    getProductionSummary: getWashPlantProductionSummaryStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IWashPlantFormComponentDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getItemsRequest: (data: IGetWashPlantProductionRecordsRequest) => dispatch(getWashPlantProductionRecordsLoadAction(data)),
    getItemByIdRequest: (data: IGetWashPlantProductionRecordByIdRequest) => dispatch(getWashPlantProductionRecordByIdLoadAction(data)),
    addProductionRequest: (data: IUpdateWashPlantProductionRequest) => dispatch(addWashPlantProductionLoadAction(data)),
    getProductionSummaryRequest: (data: IGetWashPlantProductionSummaryRequest) => dispatch(getWashPlantProductionSummaryLoadAction(data)),
    getDropDownDataRequest: (data: IGetWashPlantProductionLookupsRequest) => dispatch(getWashPlantProductionLookupsLoadAction(data)),
    updateProductionRequest: (data: IUpdateWashPlantProductionRequest) => dispatch(updateWashPlantProductionLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(WashPlantFormComponent);


export interface IConvertList {
    id: string;
    name: string;
};

export interface IConvertIdNumberList {
    id: number;
    name: string;
};

export const shiftWorkOptions: IConvertIdNumberList[] = [{ id: 0, name: "Day" }, { id: 1, name: "Night" }];
export const activeOptions: IConvertIdNumberList[] = [{ id: 0, name: "No" }, { id: 1, name: "Yes" }];
export const causeOptions: IConvertIdNumberList[] = [{ id: 0, name: "Maintenance" }, { id: 1, name: "Failure" }, { id: 2, name: "Operator Error" }];

export const convertList = (list: any[]): IConvertList[] => {
    let response = [];
    for (let i = 0; i < list.length; i++) {
        response.push({ id: list[i], name: list[i] });
    };
    return response;
};

const filterPlantList = (available: IConvertList[], selected: string[]): IConvertList[] => {
    let response: IConvertList[] = [];
    available.forEach(x => {
        if (selected.includes(x.name))
            response.push(x);
    });
    return response;
};