import { IPassTokenRequestBody } from "../../../../../react/shared/publicInterfaces";

export enum IADD_SIMOS_PRODUCT_REQUEST {
    REQUEST = "addSimosProduct/ADD_SIMOS_PRODUCT_REQUEST",
    SUCCESS = "addSimosProduct/ADD_SIMOS_PRODUCT_SUCCESS",
    FAILED = "addSimosProduct/ADD_SIMOS_PRODUCT_FAILED"
};

export interface IAddSimosProductRequest extends IPassTokenRequestBody {
    request: IAddUpdateSimosProduct;
};

export interface IAddUpdateSimosProduct {
    ID: number;
    Description: string;
    Size: string;
    Code: string;
    Location: string;
    Created_By: string;
    Modified_By: string;
}