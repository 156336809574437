import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IGET_CARRIER_LOOKUPS_REQUEST, ICarrierLookup } from "./getCarrierLookupsConstaints";
import { IGetCarrierLookupsLoadAction, IGetCarrierLookupsLoadFailedAction, IGetCarrierLookupsSuccessAction } from "./getCarrierLookupsActions";

type Actions =
    IGetCarrierLookupsLoadAction
    | IGetCarrierLookupsLoadFailedAction
    | IGetCarrierLookupsSuccessAction
    | IFlushDataSuccessAction;

export const GetCarrierLookupsReducer = (state: Server<SilAPIResponse<ICarrierLookup[]>> = notLoaded, action: Actions): Server<SilAPIResponse<ICarrierLookup[]>> => {
    switch (action.type) {
        case IGET_CARRIER_LOOKUPS_REQUEST.REQUEST:
            return loading;

        case IGET_CARRIER_LOOKUPS_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_CARRIER_LOOKUPS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};