import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IGetOrderContactLoadAction, IGetOrderContactLoadFailedAction, IGetOrderContactSuccessAction, 
    getOrderContactLoadFailedAction, getOrderContactLoadSuccessAction
} from "./getOrderContactActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IGET_ORDER_CONTACT_REQUEST, ISIMOSOrderContact } from "./getOrderContactConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SimosAPIResponse } from "../../../../react/shared/publicInterfaces";

export const getOrderContactEpic: Epic = (
    action$: ActionsObservable<IGetOrderContactLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IGetOrderContactSuccessAction | IGetOrderContactLoadFailedAction> =>
    action$.ofType(IGET_ORDER_CONTACT_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SimosAPIResponse<ISIMOSOrderContact[]>>(
                    END_POINTS.PROPSENSE.SIMOS.GET_ORDER_CONTACT,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SimosAPIResponse<ISIMOSOrderContact[]>): IGetOrderContactSuccessAction => {
                            return getOrderContactLoadSuccessAction(response);
                        }),
                        catchError(() => ActionsObservable.of(getOrderContactLoadFailedAction("Unable to get orders")))
                    )
            )
        );