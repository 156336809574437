import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../../shared/paper";
import { IDispatch, IStore } from "../../../../../redux/reducers";
import { hasPayload, isNotLoaded, Server } from "../../../../../redux/server";
import { SilAPIResponse, YHDAPIResponse } from "../../../../shared/publicInterfaces";
import { IGetTokenRequest, IToken } from "../../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenLoadAction } from "../../../../../redux/sil/dryPlant/token/getTokenActions";
import { callRouteWithQueryString, userName } from "../../../../shared/constExports";
import { LADevExtremeGrid } from "../../../../shared/devExtreme";
import { getTokenStatus } from "../../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { LAButton } from "../../../../shared/buttons";
import { AddIcon } from "../../../../shared/icons";
import { WHITE_COLOR } from "../../../../shared/theme";
import { ROUTE } from "../../../../routes";
import PageSpacing from "../../../../shared/pageSpacing";
import SilRoleCheck from "../../../../shared/silRoleCheck";
import { getYHDCarrierInfractionsStatus } from "../../../../../redux/propsense/yhd/carrier/getCarrierInfractions/getCarrierInfractionsAccessor";
import { IGetYHDCarrierInfractionsRequest, IYHDCarrierInfractions } from "../../../../../redux/propsense/yhd/carrier/getCarrierInfractions/getCarrierInfractionsConstaints";
import { getYHDCarrierInfractionsLoadAction } from "../../../../../redux/propsense/yhd/carrier/getCarrierInfractions/getCarrierInfractionsActions";


interface IYHDCarrierInfractionsStoreProps {
    getToken: Server<SilAPIResponse<IToken>>;
    getYHDCarrierInfractions: Server<YHDAPIResponse<IYHDCarrierInfractions[]>>;
};

interface IYHDCarrierInfractionsDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getYHDCarrierInfractionsRequest: (data: IGetYHDCarrierInfractionsRequest) => unknown;
};


interface IYHDCarrierInfractionsOwnProps {

};

interface IYHDCarrierInfractionsState {
    data: IYHDCarrierInfractions[];
    allData: IYHDCarrierInfractions[];
    radioValue: string;
};

const YHDCarrierInfractionsStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
    };
`;

type IYHDCarrierInfractionsProps = RouteComponentProps
    & IYHDCarrierInfractionsStoreProps
    & IYHDCarrierInfractionsDispatchProps
    & IYHDCarrierInfractionsOwnProps;

class YHDCarrierInfractions extends PureComponent<IYHDCarrierInfractionsProps, IYHDCarrierInfractionsState> {

    public constructor(props: IYHDCarrierInfractionsProps) {
        super(props);
        this.state = {
            data: [],
            allData: [],
            radioValue: "Active"
        };
    }

    public componentDidMount(): void {
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IYHDCarrierInfractionsProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();
        };
    };


    public render(): ReactNode {

        const { data } = this.state;

        return (
            <PageSpacing title="YHD - CarrierInfractions" description="YHD - CarrierInfractions" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="yhdCarrierAccess">
                    <YHDCarrierInfractionsStyles>

                        <LAButton className="pull-left" startIcon={<AddIcon color={WHITE_COLOR} />} label="Add" onClick={this.handleAdd} />
                        <h2 className="text-center">CARRIER INFRACTIONS</h2>
                        {/* <RadioGroup row aria-label="" name="radioGroup" value={radioValue} onChange={this.handleRadioClick}>
                            <FormControlLabel value="Active" control={<Radio />} label="Active" />
                            <FormControlLabel value="All" control={<Radio />} label="All" />
                        </RadioGroup> */}
                        <hr />

                        <LADevExtremeGrid
                            data={data}
                            onEdit={this.onEdit}
                            onClick={this.onClick}
                            height={1000}
                            searchPanel={true}
                            filterHeader={true}
                            export={true}
                            storageKey="yhdCarrierInfractionsStorageKey"
                            exportFileName={"CarrierInfractions"}
                            columns={[
                                { name: "date_Of_Infraction", caption: "Date of Infraction", type: "date" },
                                { name: "infraction_Notification_Method", caption: "Infraction Notification Method", type: "string" },
                                { name: "alternate_Name", caption: "Alternate Name", type: "string", width: 100 },
                                { name: "trailer_No", caption: "Trailer No", type: "string" },
                                { name: "license_Plate", caption: "License Plate", type: "string" },
                                { name: "location", caption: "Location", type: "string" },
                                { name: "ticket_No", caption: "Ticket No", type: "string" },
                                { name: "infraction", caption: "Infraction", type: "string" },
                                { name: "description", caption: "Description", type: "string" },
                                { name: "status", caption: "Status", type: "string" },
                                { name: "modified", caption: "Modified", type: "datetime", width: 100, sortDesc: true },
                            ]}
                        />
                    </YHDCarrierInfractionsStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private handleRadioClick = (event: React.ChangeEvent<HTMLInputElement>, radioValue: string): void => {
        // if (radioValue === "Active") {
        //     const val = this.state.allData;
        //     const data = val.filter(x => x.active === "Yes");
        //     this.setState({ data, radioValue });
        // } else {
        //     this.setState({ radioValue, data: this.state.allData });
        // };
    };

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString() },
            pathName: ROUTE.PROPSENSE.YHD.CARRIERS.CARRIER_INFRACTION
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0" },
            pathName: ROUTE.PROPSENSE.YHD.CARRIERS.CARRIER_INFRACTION
        });
    };

    private setDataToState = (): void => {

        if (hasPayload(this.props.getYHDCarrierInfractions)) {
            const val: IYHDCarrierInfractions[] = Object.values(this.props.getYHDCarrierInfractions.payload.response);
            // const list = val.filter(x => x.active === "Yes");
            // this.setState({ data: list, allData: val });
            this.setState({ data: val, allData: val });
        };

        if (isNotLoaded(this.props.getYHDCarrierInfractions))
            this.callServer();
    };

    private callServer = (): void => {
        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getYHDCarrierInfractions))
            this.props.getYHDCarrierInfractionsRequest({
                token: this.props.getToken.payload.response.token
            });


        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });
    }

}

const mapStateToProps = (state: IStore): IYHDCarrierInfractionsStoreProps => ({
    getToken: getTokenStatus(state),
    getYHDCarrierInfractions: getYHDCarrierInfractionsStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IYHDCarrierInfractionsDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getYHDCarrierInfractionsRequest: (data: IGetYHDCarrierInfractionsRequest) => dispatch(getYHDCarrierInfractionsLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(YHDCarrierInfractions);