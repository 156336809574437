import React from 'react';
//import '../../../../shared/simos/styling/dropdownpopup.css';
import {BsCheckCircle} from 'react-icons/bs';
import {BsXCircle} from 'react-icons/bs';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
//import '../styling/warehouseEmployeeModal.css';

interface IProps {
    text: string;
    savePopup: any;
    closePopup: any;
    editPopup: any;
    inputfield:any;
    optionitems:any;
    labelText:string;
    editExplorerCode:any;
}
interface IState {
    open:boolean;
}

class CustomerPopup extends React.Component <IProps,IState>{
    constructor(props:any){
        super(props);
        this.state = {open:true}
        this.setOpen = this.setOpen.bind(this);
        this.setClose = this.setClose.bind(this);
        this.setCancel = this.setCancel.bind(this);
    }
    setOpen(event:any){
        this.setState({open:true});
        this.props.closePopup(event);
    }
    setClose(event:any){
        // If input field isnt empty 
        if(this.props.inputfield != ""){
            // Find if the customer already exists
            var exists = false;
            this.props.optionitems.map((each: string) => {
                // If item exists alert and do not remove popup
                if(each === this.props.inputfield){
                   exists = true;
                   alert("Item already exists");
                }
            });
            // if item doesnt exist close popup and save
            if(!exists){
                alert("Item saved successfully");
                this.setState({open:false});
                this.props.savePopup(event);
            }
        }
    }
    setCancel(event:any){
        this.props.closePopup(event)
    }
    render() {
        return (<>
          <Modal id='warehouse-emp-modal'
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={this.state.open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={this.state.open}>
          <Box style={{top:'50%',left:'50%',width:'30%',backgroundColor:'rgb(241, 241, 241)',
          border:'2px solid',boxShadow:'24', transform:'translate(-50%, -50%)',position:'absolute', padding:'4ch'}}>
            <Typography variant="h6" component="h2">
              {this.props.text}
            </Typography>
            <Grid container spacing={3} direction='row'>
                <Grid item xs={12}>
                    <TextField fullWidth multiline label={this.props.labelText} name="addnewcustomer" onChange={this.props.editPopup}></TextField>
                </Grid>
                {this.props.labelText === 'Customer Name' ?
                <Grid item xs={12}>
                    <TextField label="Explorer Code" name="addexplorercode" onChange={this.props.editExplorerCode}></TextField>
                </Grid>
                :null}
                <Grid item xs={6}>
            <Button className="cancel-employee-save" onClick={this.setCancel}>Cancel Changes</Button>
            </Grid>
            <Grid item xs={6}>
            <Button className="save-employee" onClick={this.setClose}>Save Info</Button>
            </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
        </>
        //   <div className="popup_outer_create">
        //       <div className = 'popup_inner_create'>
        //           <h1 id='add-customer-popup-h1'><label id='add-popup-label'>Simos - </label>{this.props.text}</h1>
        //           <input type="text" name="addnewcustomer" onChange={this.props.editPopup} id="add-new-address-input"></input>
        //           <button onClick ={this.props.savePopup} id="save-popup-button"><BsCheckCircle/></button>
        //           <button onClick={this.props.closePopup} id="cancel-popup-button"><BsXCircle/></button>
        //       </div>
        //   </div>  
        );
    }
}
export default CustomerPopup;