import { ISieveWashPlant } from "../getSieveWashPlants/getSieveWashPlantsConstaints";

export enum IUPDATE_SIEVE_WASH_PLANT_REQUEST {
    REQUEST = "updateSieveWashPlant/UPDATE_SIEVE_WASH_PLANT_REQUEST",
    SUCCESS = "updateSieveWashPlant/UPDATE_SIEVE_WASH_PLANT_SUCCESS",
    FAILED = "updateSieveWashPlant/UPDATE_SIEVE_WASH_PLANT_FAILED"
};

export interface IUpdateSieveWashPlantRequest {
    token: string;
    request: ISieveWashPlant;
};