import { IPSAttachment } from "../../../../../utils/sharedTypes";
import { IPassTokenWithRequest } from "../../../dryPlant/token/getTokenConstants";


export enum IGET_BELT_SCALES_REQUEST {
    REQUEST = "getBeltScale/GET_BELT_SCALES_REQUEST",
    SUCCESS = "getBeltScale/GET_BELT_SCALES_SUCCESS",
    FAILED = "getBeltScale/GET_BELT_SCALES_FAILED"
};

export interface IGetBeltScaleRequest extends IPassTokenWithRequest {

};

export interface IBeltScale {
    id: number;
    perc_Diff?: number;
    quarter: number;
    employee: string;
    location: string;
    attachments: string;
    year?: number;
    bucket_Weight?: number;
    scale_Weight?: number;
    int_Or_Ext: string;
    good_Or_Bad: string;
    completed: string;
    notes: string;
    created: string;
    created_By: string;
    modified: string;
    modified_By: string;
    attachmentContent: IPSAttachment[];
};