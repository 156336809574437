export enum IGET_ASSIGNED_CREWS_REQUEST {
    REQUEST = "getAssignedCrews/GET_ASSIGNED_CREWS_REQUEST",
    SUCCESS = "getAssignedCrews/GET_ASSIGNED_CREWS_SUCCESS",
    FAILED = "getAssignedCrews/GET_ASSIGNED_CREWS_FAILED"
};

export interface IAssignedCrew {
    id: string | number;
    name?: string;
    crew_Shift_ID: number;
    start_Date: string;
    end_Date: string;
    shift: string;
    order_ID: number;
    crew_ID: number;
    dateConflictError?: string;
    transport?: string;
};
