import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IGET_CARRIER_INFRACTIONS_REQUEST, ICarrierInfraction } from "./getCarrierInfractionsConstaints";
import { IGetCarrierInfractionsLoadAction, IGetCarrierInfractionsLoadFailedAction, IGetCarrierInfractionsSuccessAction } from "./getCarrierInfractionsActions";
import { IAddCarrierInfractionSuccessAction } from "../addCarrierInfraction/addCarrierInfractionActions";
import { IUpdateCarrierInfractionSuccessAction } from "../updateCarrierInfraction/updateCarrierInfractionActions";
import { IADD_CARRIER_INFRACTION_REQUEST } from "../addCarrierInfraction/addCarrierInfractionConstaints";
import { IUPDATE_CARRIER_INFRACTION_REQUEST } from "../updateCarrierInfraction/updateCarrierInfractionConstaints";

type Actions =
    IGetCarrierInfractionsLoadAction
    | IGetCarrierInfractionsLoadFailedAction
    | IGetCarrierInfractionsSuccessAction
    | IAddCarrierInfractionSuccessAction
    | IUpdateCarrierInfractionSuccessAction
    | IFlushDataSuccessAction;

export const GetCarrierInfractionsReducer = (state: Server<SilAPIResponse<ICarrierInfraction[]>> = notLoaded, action: Actions): Server<SilAPIResponse<ICarrierInfraction[]>> => {
    switch (action.type) {
        case IGET_CARRIER_INFRACTIONS_REQUEST.REQUEST:
            return loading;

        case IGET_CARRIER_INFRACTIONS_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_CARRIER_INFRACTIONS_REQUEST.FAILED:
            return failed(action.message);

        case IADD_CARRIER_INFRACTION_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_CARRIER_INFRACTION_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};