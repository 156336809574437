import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IAddFeedMaterialLoadAction, IAddFeedMaterialLoadFailedAction, IAddFeedMaterialSuccessAction, addFeedMaterialLoadFailedAction,
     addFeedMaterialLoadSuccessAction } from "./addFeedMaterialActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IADD_FEED_MATERIAL_REQUEST } from "./addFeedMaterialConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";

export const addFeedMaterialEpic: Epic = (
    action$: ActionsObservable<IAddFeedMaterialLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IAddFeedMaterialSuccessAction | IAddFeedMaterialLoadFailedAction> =>
    action$.ofType(IADD_FEED_MATERIAL_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<string>>(
                    END_POINTS.PROPSENSE.SIL.DRY_PLANT.ADD_DRY_PLANT_FEED_MATERIALS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<string>): IAddFeedMaterialSuccessAction | IAddFeedMaterialLoadFailedAction => {
                            
                            if(response.message === "Success"){
                                return addFeedMaterialLoadSuccessAction(response.message);
                            }
                            return addFeedMaterialLoadFailedAction(response.message);
                        }),
                        catchError(() => ActionsObservable.of(addFeedMaterialLoadFailedAction("Unable to save your changes")))
                    )
            )
        );