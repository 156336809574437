
import React from 'react';
import {AiOutlineCarryOut} from 'react-icons/ai';
import {copydata} from './gridView';
import isUnique from '../APICalls/checkPONumExists';
import addOrder from '../APICalls/addOrder';
import Popup from '../../../../shared/simos/helper/popupModal';
import {validateCloneForm, errorStr} from './formValidation';
import { formatDateFromDBFormat, formatDateToDBFormat} from '../../../../shared/simos/helper/dateFormatter';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {FaPencilAlt} from 'react-icons/fa';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import BackButton from '../../../../shared/simos/helper/backButton';
import Paper from '@material-ui/core/Paper';
import '../../../../shared/simos/styling/popupstyle.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import PrintIcon from '@mui/icons-material/Print';
import EmailIcon from '@mui/icons-material/Email';
import getSIMOSWHProductInfo from '../APICalls/getRequests/getSIMOSWHProductInfo';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
const StyledTableBorder = {
    border: '2px solid rgba(0, 0, 0, 0.28)',
};
const StyledTableHeader = {
    backgroundColor:'#e3efff'
};

interface IProps {
    history:any;
    data:any;
}
interface IState {
    customers:string[];
    delivery:string[];
    chosenCustomer:string;
    chosenDelivery:string;
    emailCheck:boolean;
    transportCheck:boolean;
    products:string[];
    packagedCheck:boolean;
    completedCheck:boolean;
    orderedby: [{orderformName: string, orderformPhone: string, orderformFax:string, orderformEmail:string, contact_selected:boolean, contactID:number}],
    orderformName:string;
    orderformPhone: string;
    orderformFax:string;
    orderformEmail:string;
    all_transports:string[];
    all_products:any;
    deleteProductPopup:boolean;
    showPopup:boolean;
    deleteContactPopup:boolean;
    allPerOptions:any[];
    allSizeOptions:any[];
    quantity:number;
    chosenProd:string;
    chosenPer:string;
    chosenSize:string;
    code:string;
    c_w:string;
    d_c_w:string;
    newProduct:[{quantity:number, chosenSize:string, chosenPer:string, chosenProd:string, code:string, c_w:string, d_c_w:string;}];
    showError:boolean;
    errorText:string;
    selectedContact:string;
    bulkSizes:string[];
}
let customerID:any=[];
let newProduct = {quantity:'',chosenSize:'',chosenPer:'',chosenProd:'',code:'',c_w:'', d_c_w:''};
let chosenDeliveryID = null;
var productCascadeDropdown:any[]= [];
let chosenCustomerID:any= null;
var optionTransport;
export default class CloneFormPage extends React.Component<IProps,IState>{
    constructor(props:any){
        super(props);
        this.state={customers:[],delivery:[],all_transports:[],chosenCustomer:'',chosenDelivery:'',emailCheck:false,transportCheck:false,products:[],packagedCheck:false,completedCheck:false,
        orderedby: [{orderformName:"", orderformPhone:"",orderformFax:"",orderformEmail:"", contact_selected:false, contactID:0}],
        orderformName: '', orderformPhone: '', orderformFax: '', orderformEmail:'',all_products:null, deleteProductPopup:false, showPopup:false, deleteContactPopup:false,
    allPerOptions:[], allSizeOptions:[], quantity:0,chosenProd:'', chosenPer:'', chosenSize:'',code:'',c_w:'',d_c_w:'',newProduct:[{quantity:0, chosenSize:'', chosenPer:'', chosenProd:'', code:"", c_w:'', d_c_w:''}],
    showError:false, errorText:'',selectedContact:'',  bulkSizes:["Single", "Tri-Axle", "B-Train", "Super B", "Tandem", "Tridem", "Wagon", "Quad"]}
        this.handleChange = this.handleChange.bind(this);
        // this.handleClick = this.handleClick.bind(this);
    }
    handlePopupCancel(){
        this.setState({showPopup:!this.state.showPopup});
    }
    handlePopupExit(){
        this.props.history.push('/simos/orders');
    }
    onCancel(event:any) {
        event.preventDefault();
        this.setState({showPopup:!this.state.showPopup});
    }
    async onSaveForm(event:any){
        event.preventDefault();
        let new_clone_order = copydata;
        let orderID =0;
        // Format parameters so they match parameters from CreateForm()
        let formatOrder = [];
        formatOrder.push(null)
        formatOrder.push(new_clone_order)
        let formatContacts ={orderedby:this.state.orderedby};
        let formatProducts={productOrder:new_clone_order.products};
        // validate form
        if(validateCloneForm(new_clone_order, formatContacts.orderedby)){
            //console.log(formatOrder, formatProducts, formatContacts)
            var result:any = await addOrder(chosenCustomerID, formatOrder, orderID, formatProducts, formatContacts);
            if(result.status === 200){
                alert("Successfully created new order");
                this.props.history.replace('/simos/orders');
                window.location.reload();
            }
            else{
                alert("Error creating new order: server error");
            }
        }
        else{
            this.setState({showError:true, errorText:errorStr});
        }
    }

    handleEdit(index: number, event:any) {
        let current_item = this.state.orderedby;
        switch(event.target.name){
            case 'contactselected':
                current_item[index].contact_selected = !current_item[index].contact_selected;
                if(current_item[index].contact_selected === false){
                    this.setState({selectedContact:''});
                }
                else{
                    this.setState({selectedContact:current_item[index].orderformName});
                }
                break;
            case 'contactname':
                current_item[index].orderformName = event.target.value;
                break;
            case 'contactphone':
                current_item[index].orderformPhone = event.target.value;
                break;
            case 'contactfax':
                current_item[index].orderformFax = event.target.value;
                break;
            case 'contactemail':
                current_item[index].orderformEmail = event.target.value;
                break;
        }
        this.setState({orderedby: current_item});
    }
    handleProductEdit(index:number,event:any){
        switch(event.target.name){
            case 'prod_quantity':
                copydata.products[index].quantity=event.target.value;
                break;
            case 'prod_size':
                copydata.products[index].chosenSize=event.target.value;
                break;
            case 'prod_per':
                copydata.products[index].chosenPer=event.target.value;
                break;
            case 'prod_name':
                copydata.products[index].chosenProd=event.target.value;
                break;
            case 'prod_code':
                copydata.products[index].code=event.target.value;
                break;
            case 'prod_cw':
                copydata.products[index].c_w=event.target.value;
                break;
            case 'prod_double_CW':
                copydata.products[index].d_c_w=event.target.value;
                break;
        }
    }
    handleDeleteProd(){
        this.setState({deleteProductPopup:!this.state.deleteProductPopup});
    }
    handleDeleteProdSave(idx:number,event:any) {
        event.preventDefault();
        copydata.products.splice(idx, 1);
        this.setState({all_products: copydata.products, deleteProductPopup:false});
    }
    // handleDeleteContact(){
    //     this.setState({deleteContactPopup:!this.state.deleteContactPopup});
    // }
    // handleDelete(index: number) {
    //     deleteOrderedByContact(this.state.orderedby[index].contactID);
    //     let current_item = this.state.orderedby;
    //     current_item.splice(index, 1);
    //     this.setState({orderedby: current_item, deleteContactPopup:false});
    // }

    async handleChange(event:any) {
        switch(event.target.name){
            case 'inputCustomer':
                this.setState({chosenCustomer:event.target.value});
                customerID.map((each:any) => {
                    if(each.customer === event.target.value) {
                        chosenCustomerID = each.id;
                    }
                });
                this.fetchDeliveryAddress(null); 
                break;
            case 'inputDelivery':
                this.setState({chosenDelivery:event.target.value});
                break;
            case 'pickuporder_radio':
                copydata.pickup = event.target.value;
                this.setState({});
                break;
            case 'pickup_date':
                var format= formatDateToDBFormat(event.target.value);
                copydata.pickupDate = format[0];
                copydata.pickupHour = format[1];
                copydata.pickupMin = format[2];  
                break;
            case 'delivery_date':
                var format= formatDateToDBFormat(event.target.value);
                copydata.deliveryDate = format[0];
                copydata.deliveryHour = format[1];
                copydata.deliveryMin = format[2];  
                break;
            case 'prepaid_collect':
                copydata.order_Type = event.target.value;
                // If notes are filled but the choice to change back to prepaid was chosen, change transport notes back to N/A
                if(copydata.order_Type === "prepaid"){
                    copydata.transport_Company_Notes = "N/A";
                }
                this.setState({});
                break;
            case 'transport_company':
                copydata.transCompany=event.target.value;
                break;
            case 'truck_required':
                copydata.truckRequired =event.target.value;
                break;
            case 'transport_notes':
                copydata.transport_Company_Notes = event.target.value;
                break;
            case 'emailuser':
                copydata.emailUser = !this.state.emailCheck;
                this.setState({emailCheck:!this.state.emailCheck});
                break;
            case 'emailtransport':
                copydata.emailTransport = !this.state.transportCheck;
                this.setState({transportCheck:!this.state.transportCheck});
                break;
            case 'site_contact':
                copydata.siteContact = event.target.value;
                break;
            case 'site_contact_phone':
                copydata.siteContactPhone=event.target.value;
                break;
            case 'po_num':
                copydata.poNum=event.target.value;
                isUnique(event.target.value);
                break;
            case 'location':
                copydata.location=event.target.value;
                var all_product_descriptions:string[] = [];
                this.setState({allSizeOptions:[],code:'', chosenProd:'', chosenSize:'', chosenPer:'', quantity:0, c_w:'', d_c_w:''});
                productCascadeDropdown = await getSIMOSWHProductInfo("","",event.target.value,"");
                productCascadeDropdown[0].map((res:any) => {
                    if(all_product_descriptions.indexOf(res.description)===-1){
                        all_product_descriptions.push(res.description);
                    }
                });
                this.setState({products:all_product_descriptions.sort()});
                break;
            case 'comments':
                copydata.comments=event.target.value;
                break;
            case 'packaged':
                copydata.packaged = !this.state.packagedCheck;
                this.setState({packagedCheck:!this.state.packagedCheck});
                break;
            case 'completed':
                copydata.completed = !this.state.completedCheck;
                this.setState({completedCheck:!this.state.completedCheck});
                break;
            case 'cancelradio':
                copydata.cancelled=event.target.value;
                break;
            case 'truck_no':
                copydata.truckNumber=event.target.value;
                break;
            case 'trailer_no':
                copydata.trailerNumber=event.target.value;
                break;
            case 'tare_weight':
                copydata.tareWeight=event.target.value;
                break;
            case 'gross_weight':
                copydata.grossWeight=event.target.value;
                break;
            case 'newcustomer':
                this.setState({orderformName:event.target.value});
                break;
            case 'newphone':
                this.setState({orderformPhone: event.target.value});
                break;
            case 'newemail':
                this.setState({orderformEmail: event.target.value});
                break;
            case 'newfax':
                this.setState({orderformFax:event.target.value});
                break;
        }
    }
    async onProductAddTextChange(event:any){
        const fieldname = event.target.name;
        switch(fieldname){ 
            case 'newquantity':
                this.setState({quantity:event.target.value});
                break;
            case 'newsize':
                this.setState({chosenSize:event.target.value});
                if(copydata.location === "Warehouse"){ 
                    productCascadeDropdown[0].map((res:any) => {
                        if(event.target.value === res.size && this.state.chosenProd === res.description){
                           this.setState({code:res.code});
                        }
                    });
                }
                else{
                    productCascadeDropdown[0].map((res:any) => {
                        if(this.state.chosenProd === res.description){
                           this.setState({code:res.code});
                        }
                    });
                }
                break;
            case 'newper':
                this.setState({chosenPer:event.target.value});
                break;
            case 'newproductname':
                let allSizes:string[] = [];
                this.setState({chosenProd:event.target.value, chosenSize:'', chosenPer:'', code:'', allPerOptions:[]});
                productCascadeDropdown[0].map((res:any) => {
                    if(res.description === event.target.value){
                        allSizes.push(res.size);   
                    }
                });
                if(copydata.location === "Bulk"){
                    this.setState({allSizeOptions:this.state.bulkSizes});
                }
                else{
                    this.setState({allSizeOptions:allSizes});   
                }
                break;
            case 'newcode':
                newProduct.code = event.target.value;
                this.setState({code: event.target.value});
                var chosenProd = "";
                let allSize:string[] = [];
                this.setState({code:event.target.value, chosenSize:'', chosenProd:''});
                productCascadeDropdown[0].map((res:any) => {
                    if(res.code === event.target.value){
                        chosenProd = res.description;
                        allSize.push(res.size);
                    }
                });
                this.setState({chosenProd:chosenProd, allSizeOptions:allSize});
                if(copydata.location === "Bulk"){
                    this.setState({allSizeOptions:this.state.bulkSizes});
                }
                break;
            case 'newcw':
                this.setState({c_w:event.target.value});
                break;
            case 'newdcw':
                this.setState({d_c_w:event.target.value});
                break;  
        }
    }
    // A new product can be added within editing a form
    handleAddProd(event:any) {
        if(this.state.chosenProd !="" && this.state.chosenSize !="" && this.state.chosenPer!="" && this.state.c_w !="" && this.state.d_c_w !="" && this.state.quantity!=0){
            // index = globalid;
            let temp = this.state.newProduct;
            temp.push({quantity: this.state.quantity, chosenSize: this.state.chosenSize, chosenPer: this.state.chosenPer, chosenProd:this.state.chosenProd, code:this.state.code, c_w:this.state.c_w, d_c_w:this.state.d_c_w});
            copydata.products.push(temp[temp.length-1]);
            console.log(temp)
            // currentitem = current_item;
            // idx = index;
            event.preventDefault();
            this.setState({all_products: copydata.products, chosenPer:'',chosenProd:'',chosenSize:'',code:'',c_w:'', d_c_w:'',quantity:0});
         
            //newProduct.quantity="";newProduct.code="";newProduct.chosenSize="";newProduct.chosenProd="";newProduct.chosenPer="";newProduct.c_w="";
        }
        else{
            alert("Quantity, Product, Per, Size, and C&W must be filled");
        }
    }
    async fetchOrderedBy() {
        let initialContacts:any = [];
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type':'application/json' }, 
            body: JSON.stringify({token: '19515195-d571-44a3-b0db-637f5bf24f54' })
        }
        fetch('https://api2.propsense.com/api/GetSIMOSOrderedByContact', requestOptions)
        .then(async response => {
            const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
              // error response
              if(!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            // no error
            data.map((contacts:any) => {
                if(contacts.customer_ID === chosenCustomerID) {
                    initialContacts.push({orderformName: contacts.contact, orderformEmail:contacts.email, orderformFax:contacts.fax, orderformPhone:contacts.phone, 
                    contact_selected:false, contactID: contacts.id});
                }
            });
            this.setState({orderedby:initialContacts});
        });
    }

    async fetchDeliveryAddress(delivery_addr:any) {
        let initialDelivery:string[] = [];
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type':'application/json' }, 
            body: JSON.stringify({token: '19515195-d571-44a3-b0db-637f5bf24f54' })
        }
        fetch('https://api2.propsense.com/api/GetSIMOSCustomerAddress', requestOptions)
        .then(async response => {
            const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
              // error response
              if(!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            // no error
            data.map((deliveryaddr:any) => {
                // obtain a list of all delivery addresses
                if(deliveryaddr.customer_ID === chosenCustomerID){
                    initialDelivery.push(deliveryaddr.address);
                }
                // Save the order if a delivery_address was provided
                this.setState({delivery:initialDelivery}); 
            });

        })
    }
    componentWillMount(){
        if(localStorage.getItem('userrole')!='Admin' && localStorage.getItem('userrole')!='Dispatcher'){
            this.props.history.push('/simos');
        }
        else{
            if(!copydata){
                this.props.history.push('/simos/orders');
            }
        }
    }
    async componentDidMount() {
        if(copydata != null){
        let initialCustomers:string[] = [];
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token: '19515195-d571-44a3-b0db-637f5bf24f54' })
        };
    
        fetch('https://api2.propsense.com/api/GetSIMOSCustomers', requestOptions)
        .then(async response => {
            const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
            
            // error response
            if(!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            // no error
            data.map((customername:any) => {
                // Get all customers if order is for orderdesk
                // if(localStorage.getItem('customer')=== 'orderdesk'){
                    initialCustomers.push(customername.customer);
                    customerID.push(customername);
                    if(copydata.customer === customername.customer){
                        chosenCustomerID = customername.id;
                    }   
            });
            this.setState({customers:initialCustomers});
            this.fetchDeliveryAddress(null);
        })
        .catch(error => {
            console.log('There was an error!', error);
        });
        let allTransports:string[] = [];
      
        fetch('https://api2.propsense.com/api/GetSIMOSTransportCompany', requestOptions)
        .then(async response => {
            const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
            // error response
            if(!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            // no error
            data.map((company_name:any) => {
                if(company_name.active ==="Yes"){
                    allTransports.push(company_name.transport_Company);
                }
            });
            this.setState({all_transports: allTransports});
        })
        .catch(error => {
            console.log('There was an error!', error);
        });
        var all_products:string[] = [];
        productCascadeDropdown = await getSIMOSWHProductInfo("","",copydata.location,"");
        productCascadeDropdown[0].map((res:any) => {
            if(all_products.indexOf(res.description)===-1){
                all_products.push(res.description);
            }
        });
        this.setState({products:all_products.sort()});
        this.fetchOrderedBy();
        this.setState({packagedCheck:copydata.packaged==='Yes',completedCheck:copydata.completed==='Yes'});
        // replace pickup/delivery dates with empty string to force user to pick them
        copydata.deliveryDate="";
        copydata.pickupDate="";
    }
    }

    render() {
        let customer_options = this.state.customers.map((customer) => <option>{customer}</option>);
        let delivery_options = this.state.delivery.map((address) => <option>{address}</option>);
        let transports = this.state.all_transports;
        if(copydata != null){
            optionTransport = transports.map((company) => <option>{company}</option>);
            // If pickup is yes, set transcompany and truckrequired to customer pickup
            if(copydata.order_Type === "collect"){
                copydata.transCompany = "Customer Pickup";
                copydata.truckRequired = "Customer Pickup";
            }
        if(copydata.transCompany==="TBD"){
            optionTransport.push(<option disabled selected></option>);
        }
        else{
            optionTransport.push(<option disabled selected>{copydata.transCompany}</option>);
        }
        let productOptions = this.state.products.sort().map((prod) => <option>{prod}</option>);
        productOptions.push(<option disabled selected></option>);
        let allperoptions:any[] = this.state.allPerOptions.map((per) => <option>{per}</option>);
        allperoptions.push(<option disabled selected></option>);
        let allsizeoptions:any[] = this.state.allSizeOptions.map((sizes) => <option>{sizes}</option>);
        allsizeoptions.push(<option disabled selected></option>);
        let customerContactOptions = this.state.orderedby.map((elem:any, idx:number) => 
        <MenuItem value={elem.orderformName}>
            <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: 0.1 }}>
                <Checkbox checked={elem.contact_selected} onChange={this.handleEdit.bind(this, idx)} name="contactselected"></Checkbox>
                <Grid item xs={3}>
                    <Chip icon={<PermContactCalendarIcon/>} label={elem.orderformName}></Chip>
                </Grid>
                <Grid item xs={3}>
                    <Chip icon={<LocalPhoneIcon/>} label={elem.orderformPhone}></Chip>
                </Grid>
                <Grid item xs={3}>
                    <Chip icon={<PrintIcon />} label={elem.orderformFax}></Chip>
                </Grid>
                <Grid item xs={3}>
                    <Chip icon={<EmailIcon/>} label={elem.orderformEmail}></Chip>
                </Grid>
            </Box>
        </MenuItem>
    );

        var formatPickupDate = formatDateFromDBFormat(copydata.pickupDate, copydata.pickupHour, copydata.pickupMin);
        var formatDeliveryDate = formatDateFromDBFormat(copydata.deliveryDate, copydata.deliveryHour, copydata.deliveryMin);
        return (
            
            <Paper className="paper-popup">
                <Paper className="paper-popup-inner">
                <BackButton goBack={this.onCancel.bind(this)} history={this.props.history}></BackButton>
                    <form autoComplete='off'> 
                    <h3>Cloning Order <label id='header-3-label'>#{copydata.id}</label></h3>
                    <Paper className="paper-card">
                        <Grid container spacing={3}> 
                        <Grid item xs={6}>
                            <FormControl required>
                            <InputLabel htmlFor="customer-dropdown-options">Customer</InputLabel>
                                <Select native placeholder = {copydata.customer} onChange={this.handleChange} inputProps={{name:'inputCustomer',id:'customer-dropdown-options'}}>
                                <option selected disabled>{copydata.customer}</option>
                                {customer_options}
                                 </Select>        
                                 </FormControl>
                            </Grid>
                        {/* <Grid item xs={4}>
                        <FormLabel component="legend">Is Pickup Required</FormLabel>
                                <RadioGroup aria-label="gender" name="pickuporder_radio" defaultValue={copydata.pickup} onChange={this.handleChange}>
                                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                    <FormControlLabel value="No" control={<Radio />} label="No" />
                                </RadioGroup>
                            </Grid>  */}
                            <Grid item xs={6}>
                        <FormControl required>
                                <InputLabel htmlFor="delivery-dropdown-options">Delivery Address</InputLabel>
                                <Select native placeholder = {copydata.delivery} onChange={this.handleChange} inputProps={{name:'inputDelivery',id:'delivery-dropdown-options'}}>
                                <option selected disabled></option>
                                {delivery_options}
                                 </Select>         
                            </FormControl>
                            </Grid>
                        </Grid>
                        <br></br>
                        <Paper className="paper-card">
                        <Grid container spacing={3}>
                
                    <Grid item xs={12}>
                    <Typography style={{fontWeight:"bold"}} variant="h5" gutterBottom component="div">Select a Customer Contact</Typography>
                        <Select 
                        variant="outlined"
                        fullWidth
                        name="customer_contact_select"
                        value={this.state.selectedContact}
                        onChange={this.handleChange}
                        >
                             {customerContactOptions}
                        </Select>
                  
                    </Grid>
                     <br></br>
                </Grid>
                    </Paper><br></br>
                        <Paper className="paper-card">
                            <Grid container spacing={3}>
                         
                                <Grid item xs={6}>
                                    <InputLabel htmlFor="datetime-local">Scheduled Pickup Date/Time</InputLabel>
                                    <TextField id='datetime-local' type='datetime-local' name="pickup_date" onChange={this.handleChange}  InputLabelProps={{shrink:true}}></TextField>
                            {/* <label>Pickup Date</label><input id='edit-form-input-field' type="date" name="pickup_date" onChange={this.handleChange} defaultValue={copydata.pickupDate}></input>
                            <label> Hour:</label><select id='edit-form-input-field' name="pickup_hour" onChange={this.handleChange} placeholder={copydata.pickupHour} defaultValue={copydata.pickupHour}>{allPickupHours}</select>
                            <label> Min:</label><select id='edit-form-input-field' name = "pickup_min" onChange={this.handleChange} placeholder={copydata.pickupMin} defaultValue={copydata.pickupMin}>{allPickupMin}</select><br></br>
                            <br></br> */}
                                </Grid>
                                
                            <Grid item xs={6}>
                                <InputLabel htmlFor="datetime-local">Delivery Date/Time</InputLabel>
                                <TextField id='datetime-local' type='datetime-local' name="delivery_date" onChange={this.handleChange}  InputLabelProps={{shrink:true}}></TextField>
                            </Grid>
                            
                            {/* <label>Delivery Date</label><input id='edit-form-input-field' type="date" name="delivery_date" onChange={this.handleChange} defaultValue={copydata.deliveryDate}></input>
                            <label> Hour:</label><select id='edit-form-input-field' name="delivery_hour" onChange={this.handleChange} placeholder={copydata.deliveryHour} defaultValue={copydata.deliveryHour}>{allDeliveryHours}</select>
                            <label> Min:</label><select id='edit-form-input-field' name ="delivery_min" onChange={this.handleChange} placeholder={copydata.deliveryMin} defaultValue={copydata.deliveryMin}>{allDeliveryMin}</select>
                            <br></br>
                            <br></br> */}
                             <Grid item xs={3}>
                                <TextField label="Site Contact" defaultValue={copydata.siteContact} name='site_contact' onChange={this.handleChange}></TextField>
                             </Grid>
                             <Grid item xs={3}>
                                 <TextField label="Site Contact Phone #" defaultValue={copydata.siteContactPhone} name='site_contact_phone' onChange={this.handleChange}></TextField>
                             </Grid>
                             <Grid item xs={3}>
                                <TextField className='po-num-colored' label="PO #" defaultValue={copydata.poNum} name='po_num' onChange={this.handleChange}></TextField>
                             </Grid>
                            {/* <label>Site Contact</label><input id='edit-form-input-field' type="text" name='site_contact' onChange={this.handleChange} placeholder={copydata.siteContact}></input>
                            <label> Site Contact Phone #</label><input id='edit-form-input-field' type="text" name='site_contact_phone' onChange={this.handleChange } placeholder={copydata.siteContactPhone}></input>
                            <label> poNum</label><input id='edit-form-input-field' type="text" name="po_num" onChange={this.handleChange}placeholder={copydata.poNum}></input><br></br>
                            <br></br> */}
                            <Grid item xs={3}>
                                <RadioGroup name="prepaid_collect" value={copydata.order_Type} onChange={this.handleChange}>
                                    <FormControlLabel value="prepaid" control={<Radio />} label="Prepaid" />
                                    <FormControlLabel value="collect" control={<Radio />} label="Collect" />
                                </RadioGroup>
                            </Grid>
                            <Grid item xs={3}>
                            {copydata.order_Type === "collect" ? <><InputLabel htmlFor="transport-native-default">Transport Company</InputLabel>
                            <TextField disabled defaultValue="Customer Pickup">
                        </TextField>     </>:
                                    // <TextField autoComplete='off' defaultValue={copydata.transCompany} id="standard-required" name="transport_company" label="Transport Company" onChange={this.handleChange} ></TextField>
                                    <FormControl>
                                    <InputLabel htmlFor="transport-native-simple">Transport Company</InputLabel>
                                    <Select native placeholder = {copydata.transCompany} onChange={this.handleChange} inputProps={{name:'transport_company', id:'transport-native-simple'}}>
                                                {/* <option selected disabled>{obj.transCompany}</option> */}
                                                {optionTransport}
                                                </Select>
                                    </FormControl>
                            }
                            </Grid>
                            <Grid item xs={3}>
                                {copydata.order_Type === "collect"  ? <><InputLabel htmlFor="transport-native-default">Truck Size</InputLabel>
                            <TextField disabled defaultValue="Customer Pickup">
                        </TextField></>
                        :
                                <FormControl>
                                <InputLabel htmlFor="truckreq-native-simple">Truck Size</InputLabel>
                                    <Select native defaultValue = {copydata.truckRequired} onChange={this.handleChange} inputProps={{name:'truck_required', id:'truckreq-native-simple'}}>
                                        <option>Single Bulk</option>
                                        <option>Single Deck</option>
                                        <option>BTrain Bulk</option>
                                        <option>BTrain Deck</option>
                                        <option>Super BTrain Bulk</option>
                                        <option>Super BTrain Deck</option>
                                        <option>Truck and Pup</option>
                                        <option>Tandem</option>
                                        <option>End Dump</option>
                                        <option>Truck and Wagon</option>
                                        <option>Triaxle Deck</option>
                                        <option>Triaxle Bulk</option>
                                        <option>Customer Pickup</option>
                                    </Select>
                                </FormControl>
                            }
                            </Grid>
                            {copydata.order_Type === 'collect' ?
                                    <Grid item xs={3}>
                                        <TextField autoComplete='off' value = {copydata.transport_Company_Notes} multiline rows={2} variant="outlined"  id="standard-required" name="transport_notes" label="Transport Company/Truck Size Notes"  onChange={this.handleChange} ></TextField>
                                    </Grid>
                                    :null}
                        </Grid>
                        </Paper><br></br>
                        <Paper className="paper-card">
                            <TableContainer style={StyledTableBorder} component={Paper}>
                                <Typography style={{fontWeight:"bold"}} variant="h5" gutterBottom component="div">Products</Typography>
                                    <FormControl required>
                                    <FormLabel style={{fontWeight:'bold'}} component="legend">Location</FormLabel>
                                        <RadioGroup row defaultValue={copydata.location} onChange={this.handleChange} name='location'>
                                            <FormControlLabel value="Warehouse" control={<Radio />} label="Warehouse"/>
                                            <FormControlLabel value="Bulk" control={<Radio />} label="Bulk"/>
                                        </RadioGroup>
                                    </FormControl>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={StyledTableHeader}>Product</TableCell>
                                            <TableCell style={StyledTableHeader}>Size</TableCell>
                                            <TableCell style={StyledTableHeader}>Bags Per Pallet</TableCell>
                                            <TableCell style={StyledTableHeader}># of Pallets</TableCell>
                                            <TableCell style={StyledTableHeader}>Product Code</TableCell>
                                            <TableCell style={StyledTableHeader}>C&W</TableCell>
                                            <TableCell style={StyledTableHeader}>Double C&W</TableCell>
                                            <TableCell style={StyledTableHeader}>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {copydata.products.map((product:any, index:number) => {
                                            return <StyledTableRow>
                                                        <TableCell>{product.chosenProd}</TableCell>
                                                        <TableCell>{product.chosenSize}</TableCell> 
                                                        <TableCell>{product.chosenPer}</TableCell> 
                                                        <TableCell><TextField name="prod_quantity" onChange={this.handleProductEdit.bind(this,index)} placeholder={product.quantity}></TextField></TableCell>
                                                        <TableCell>{product.code}</TableCell> 
                                                        <TableCell>{product.c_w}</TableCell>    
                                                        <TableCell>{product.d_c_w}</TableCell>    
                                                        <TableCell><Button id="del-product-btn" onClick={this.handleDeleteProd.bind(this)} >X</Button></TableCell> 
                                                        {this.state.deleteProductPopup? <Popup headerText="Deleting Product" text='Are you sure you want to delete this product?' savePopup = {this.handleDeleteProdSave.bind(this, index)} closePopup={this.handleDeleteProd.bind(this)}/>:null}
                                                    </StyledTableRow>
                                        })}
                                    </TableBody>
                                    <TableBody>
                                        <TableCell><Select native name="newproductname" value={this.state.chosenProd} onChange={this.onProductAddTextChange.bind(this)}>{productOptions}</Select></TableCell>
                                        <TableCell><Select native name="newsize" value={this.state.chosenSize} onChange={this.onProductAddTextChange.bind(this)}>{allsizeoptions}</Select></TableCell>
                                        <TableCell><TextField name="newper" value={this.state.chosenPer} onChange={this.onProductAddTextChange.bind(this)}></TextField></TableCell>
                                        <TableCell><TextField name="newquantity" value={this.state.quantity} onChange={this.onProductAddTextChange.bind(this)}></TextField></TableCell>
                                        <TableCell><TextField name="newcode" value={this.state.code} onChange={this.onProductAddTextChange.bind(this)}></TextField></TableCell>
                                        <TableCell>
                                            <Select native name="newcw" value={this.state.c_w} onChange={this.onProductAddTextChange.bind(this)}>
                                                <option disabled selected></option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Select>
                                        </TableCell>
                                        <TableCell>
                                            <Select native name="newdcw" value={this.state.d_c_w} onChange={this.onProductAddTextChange.bind(this)}>
                                                <option disabled selected></option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Select>
                                        </TableCell>
                                        <TableCell><Button onClick={this.handleAddProd.bind(this)} id="add-product-btn">Add Row</Button></TableCell>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <br></br>
                            <Grid item xs={12}>
                                        <FaPencilAlt/><TextField label="Comments" name="comments" multiline onChange={this.handleChange} placeholder={copydata.comments} variant="outlined"></TextField>
                                    </Grid>
                        </Paper>
                        <br></br>
                        {/* <Paper className="paper-card">
                            <Grid container spacing={3}> */}
                                {/* <Grid item xs={12}>
                                        <FormControl required>
                                             <InputLabel htmlFor="location-dropdown-options">Location</InputLabel>
                                             <Select native inputProps={{name:'location',id:'location-dropdown-options'}} defaultValue={copydata.location} onChange={this.handleChange}>
                                                 <option>Warehouse</option>
                                                 <option>Bulk</option>
                                             </Select>
                                        </FormControl>
                                </Grid> */}
                                {/* <Grid item xs={3}>
                                    <TextField label="Truck #" name="truck_no" onChange={this.handleChange} defaultValue={copydata.truckNumber}></TextField>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField label="Trailer #" name="trailer_no" onChange={this.handleChange} defaultValue={copydata.trailerNumber}></TextField>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField label="Tare Weight" name="tare_weight" onChange={this.handleChange} defaultValue={copydata.tareWeight}></TextField>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField label="Gross Weight" name="gross_weight" onChange={this.handleChange} defaultValue={copydata.grossWeight}></TextField>
                                </Grid> */}
                          {/* </Grid>
                        </Paper> */}
                    </Paper><br></br>
                    {this.state.showError ? 
                        <Stack sx={{ width: '100%' }} spacing={2}>
                            <Alert variant="filled" severity="error" action={<IconButton color="inherit" onClick={() => {this.setState({showError:false})}}>X</IconButton>}>
                            {this.state.errorText}
                            </Alert>
                        </Stack>
                    :null}
                    <br></br>
                        <button onClick={this.onCancel.bind(this)} id="cancel-create-btn">Cancel</button>
                        {this.state.showPopup ? <Popup headerText="Leaving Page" savePopup={this.handlePopupExit.bind(this)} closePopup={this.handlePopupCancel.bind(this)} text={"Are you sure you want to cancel changes and exit?"}></Popup>:null}
                        <button type="submit" onClick={this.onSaveForm.bind(this)} id="submit-form-btn"><label id='save-form-icon'><AiOutlineCarryOut/></label> Save</button>
                    </form>
               </Paper>
               </Paper>
        );
                                  // Dont render page until the copydata contains the copied order
                                }else{
                                    return null;
                                }
    }
}