import { SilAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { IGetWarehouseProductsRequest, IGET_WAREHOUSE_PRODUCTS_REQUEST, IWarehouseProducts } from "./getWarehouseProductsConstaints";

export interface IGetWarehouseProductsLoadAction {
    type: IGET_WAREHOUSE_PRODUCTS_REQUEST.REQUEST;
    data: IGetWarehouseProductsRequest
}
export const getWarehouseProductsLoadAction = (data: IGetWarehouseProductsRequest): IGetWarehouseProductsLoadAction => ({
    type: IGET_WAREHOUSE_PRODUCTS_REQUEST.REQUEST,
    data
});

export interface IGetWarehouseProductsSuccessAction {
    type: IGET_WAREHOUSE_PRODUCTS_REQUEST.SUCCESS;
    data: SilAPIResponse<IWarehouseProducts[]>;
}
export const getWarehouseProductsLoadSuccessAction = (data: SilAPIResponse<IWarehouseProducts[]>): IGetWarehouseProductsSuccessAction => ({
    type: IGET_WAREHOUSE_PRODUCTS_REQUEST.SUCCESS,
    data
});

export interface IGetWarehouseProductsLoadFailedAction {
    type: IGET_WAREHOUSE_PRODUCTS_REQUEST.FAILED;
    message: string;
}
export const getWarehouseProductsLoadFailedAction = (message: string): IGetWarehouseProductsLoadFailedAction => ({
    type: IGET_WAREHOUSE_PRODUCTS_REQUEST.FAILED,
    message
});
