import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import { SilAPIResponse } from "../../../shared/publicInterfaces";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import { callRouteWithQueryString, userName } from "../../../shared/constExports";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { ROUTE } from "../../../routes";
import PageSpacing from "../../../shared/pageSpacing";
import SilRoleCheck from "../../../shared/silRoleCheck";
import LALoading from "../../../shared/loading";
import { ITransloadBOLCount, IGetTransloadBOLCountsRequest } from "../../../../redux/sil/dispatch/getTransloadBOLCounts/getTransloadBOLCountsConstaints";
import { getTransloadBOLCountsLoadAction } from "../../../../redux/sil/dispatch/getTransloadBOLCounts/getTransloadBOLCountsActions";
import { getTransloadBOLCountsStatus } from "../../../../redux/sil/dispatch/getTransloadBOLCounts/getTransloadBOLCountsAccessor";


interface ITransloadBOLCountsStoreProps {
    getToken: Server<SilAPIResponse<IToken>>;
    getTransloadBOLCounts: Server<SilAPIResponse<ITransloadBOLCount[]>>;
};

interface ITransloadBOLCountsDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getTransloadBOLCountsRequest: (data: IGetTransloadBOLCountsRequest) => unknown;
};


interface ITransloadBOLCountsOwnProps {

};

interface ITransloadBOLCountsState {

};

const TransloadBOLCountsStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
    };
`;

type ITransloadBOLCountsProps = RouteComponentProps
    & ITransloadBOLCountsStoreProps
    & ITransloadBOLCountsDispatchProps
    & ITransloadBOLCountsOwnProps;

class TransloadBOLCounts extends PureComponent<ITransloadBOLCountsProps, ITransloadBOLCountsState> {

    public constructor(props: ITransloadBOLCountsProps) {
        super(props);
        this.state = {
        };
    }

    public componentDidMount(): void {
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: ITransloadBOLCountsProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();
        };
    };


    public render(): ReactNode {

        const { getTransloadBOLCounts } = this.props;

        return (
            <PageSpacing title="SIL - CV" description="SIL - TransloadBOLCounts" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="carrierAccess">
                    <TransloadBOLCountsStyles>

                        <h2 className="text-center">Transload BOL Counts</h2>
                        <hr />

                        {getTransloadBOLCounts.kind === STATUS_ENUM.LOADING &&
                            <LALoading className="loading" message="Loading..." />}

                        {getTransloadBOLCounts.kind === STATUS_ENUM.SUCCEEDED && <LADevExtremeGrid
                            data={getTransloadBOLCounts.payload.response}
                            onEdit={this.onEdit}
                            onClick={this.onClick}
                            height={1000}
                            searchPanel={true}
                            filterHeader={true}
                            export={true}
                            exportFileName="TransloadBOLCountsList"
                            storageKey="silTransloadBOLCountsStorageKey"
                            columns={[
                                { name: "date", caption: "Date", type: "date" },
                                { name: "transload", caption: "Transload", type: "string" },
                                { name: "customer_BOL_Submitted", caption: "Customer BOL Submitted", type: "string" },
                                { name: "direct_BOL_Submitted", caption: "Direct BOL Submitted", type: "string" },
                                { name: "trans_BOL_Submitted", caption: "Trans BOL Submitted", type: "string" },
                                { name: "created", caption: "Created", type: "datetime" },
                                { name: "created_By", caption: "Created By", type: "string" },
                                { name: "modified", caption: "Modified", type: "datetime" },
                                { name: "modified_By", caption: "Modified By", type: "string" }
                            ]}
                        />}

                    </TransloadBOLCountsStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString() },
            pathName: ROUTE.SIL.DISPATCH.TRANSLOAD_BOL_COUNT
        });
    };

    private onClick = (): void => {

    };

    private setDataToState = (): void => {
        if (isNotLoaded(this.props.getTransloadBOLCounts))
            this.callServer();
    };

    private callServer = (): void => {
        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getTransloadBOLCounts))
            this.props.getTransloadBOLCountsRequest({
                token: this.props.getToken.payload.response.token
            });

        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });
    }

}

const mapStateToProps = (state: IStore): ITransloadBOLCountsStoreProps => ({
    getToken: getTokenStatus(state),
    getTransloadBOLCounts: getTransloadBOLCountsStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): ITransloadBOLCountsDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getTransloadBOLCountsRequest: (data: IGetTransloadBOLCountsRequest) => dispatch(getTransloadBOLCountsLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(TransloadBOLCounts);