import { IPassTokenWithRequest } from "../../../sil/dryPlant/token/getTokenConstants";
import { IOrderContact } from "../getOrders/getOrdersConstaints";

export enum IUPDATE_ORDER_REQUEST {
    REQUEST = "updateOrder/UPDATE_ORDER_REQUEST",
    SUCCESS = "updateOrder/UPDATE_ORDER_SUCCESS",
    FAILED = "updateOrder/UPDATE_ORDER_FAILED"
};

export interface IUpdateOrderRequest extends IPassTokenWithRequest {
    request: IUpdateOrder;
};
  
  export interface IUpdateOrder {
    ID: number;
    Pickupdate: string;
    Pickuphour: string;
    Pickupminute: string;
    Sitecontact: string;
    Sitecontact_Phone: string;
    PO_Number: string;
    Emailuser: string;
    Emailtransport: string;
    Transport_Company: string;
    Truck_Required: string;
    Deliverydate: string;
    Deliveryhour: string;
    Deliveryminute: string;
    Location: string;
    Comments: string;
    Packaged: string;
    Completed: string;
    Cancelled: string;
    Truck_Number: string;
    Trailer_Number: string;
    Tare_Weight: string;
    Gross_Weight: string;
    Modified_By: string;
    Product_Order: IUpdateProductOrder[];
    Total_Tonnage: number;
    Status: string;
    Order_Type: string;
    Transport_Company_Notes: string;
    Delivery_Datetime: string;
    Pickup_Datetime: string;
    Zone: string;
    Hired_Truck_Rates: number;
    Truck_Code: string;
    Time_In: string;
    Time_Out: string;
    Customer_Job_Number: string;
    Order_Contact: IOrderContact;
    Customer_ID: number;
  }
  
  export interface IUpdateProductOrder {
    ID: number;
    Quantity: number;
    Size: string;
    Per: string;
    Product: string;
    Code: string;
    CW: string;
    Double_CW: string;
    Actual_Quantity: number;
  }