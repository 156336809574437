import { ISieveCalibration } from "../../../../../utils/sharedTypes";
import { IPassTokenWithRequest } from "../../../dryPlant/token/getTokenConstants";


export enum IGET_SIEVE_DRY_PLANTS_REQUEST {
    REQUEST = "getSieveDryPlant/GET_SIEVE_DRY_PLANTS_REQUEST",
    SUCCESS = "getSieveDryPlant/GET_SIEVE_DRY_PLANTS_SUCCESS",
    FAILED = "getSieveDryPlant/GET_SIEVE_DRY_PLANTS_FAILED"
};

export interface IGetSieveDryPlantRequest extends IPassTokenWithRequest {

};

export interface ISieveDryPlant {
    id: number;
    month?: number;
    year?: number;
    employee: string;
    completed: string;
    is_Calibration_Good: string;
    created: string;
    created_By: string;
    modified: string;
    modified_By: string;
    calibrations: ISieveCalibration[];
};