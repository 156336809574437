import React from "react";
import { IDownTime, IProductionData } from "../../../../redux/sil/dryPlant/getProductionRecords/getProductionRecordsConstaints";
import { IProductionSummary } from "../../../../redux/sil/dryPlant/getProductionSummary/getProductionSummaryConstants";
import { undefinedFunction } from "../../../shared/constExports";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { LAPaperWithLessPadding, LAPaperWithPadding } from "../../../shared/paper";
import { LAPopover } from "../../../shared/popOver";

interface IProductionDataPopup {
    open: boolean;
    onClose: () => void;
    data: IProductionData[];
    summary: IProductionSummary[];
};

export const ProductionDataPopup: React.FC<IProductionDataPopup> = React.memo((props: IProductionDataPopup) => (
    <LAPopover open={props.open} onClose={props.onClose} anchorRef={null}>
        <LAPaperWithPadding>
            <LADevExtremeGrid
                height={400}
                id="production"
                searchPanel={false}
                filterHeader={false}
                data={props.data}
                onClick={undefinedFunction}
                columns={[
                    { name: "plant", caption: "Plant", type: "string", width: 100 },
                    { name: "feed_1_Material", caption: "Feed 1 Material", type: "string", width: 150 },
                    { name: "feed_1_Perc", caption: "Feed 1 %", type: "number", width: 100 },
                    { name: "feed_2_Material", caption: "Feed 2 Material", type: "string", width: 150  },
                    { name: "feed_2_Perc", caption: "Feed 2 %", type: "number", width: 100 },                  
                    { name: "feed_Tonnage", caption: "Feed Tonnage", type: "string" , width: 100},   
                    { name: "run_Hours", caption: "Run Hours", type: "string", width: 80},
                    { name: "by_Product_1", caption: "By Product1", width: 150  },
                    { name: "by_Product_1_MT", caption: "By Product1 MT", width: 120 },
                    { name: "by_Product_2", caption: "By Product2", width: 150  },
                    { name: "by_Product_2_MT", caption: "By Product2 MT", type: "string", width: 120  },
                    { name: "by_Product_3", caption: "By Product3", width: 150  },
                    { name: "by_Product_3_MT", caption: "By Product3 MT", type: "string", width: 120 },
                    { name: "by_Product_4", caption: "By Product4", width: 150  },
                    { name: "by_Product_4_MT", caption: "By Product4 MT", type: "string", width: 120  },
                   
                ]}
                sumColumn={[
                    { columnName: "by_Product_1_MT", caption: "Total" },
                    { columnName: "by_Product_2_MT", caption: "Total" },
                    { columnName: "by_Product_3_MT", caption: "Total" },
                    { columnName: "by_Product_4_MT", caption: "Total" },
                    { columnName: "feed_Tonnage", caption: "Total" }
                ]}
            />
            <ProductionSummaryTable data={props.summary} />
        </LAPaperWithPadding>
    </LAPopover>
));

//Downtime Popup
interface IDowntimeDataPopup {
    open: boolean;
    data: IDownTime[];
    onClose: () => void;
};

export const DowntimeDataPopup: React.FC<IDowntimeDataPopup> = React.memo((props: IDowntimeDataPopup) => (
    <LAPopover open={props.open} onClose={props.onClose} anchorRef={null}>
        <LAPaperWithPadding>
            <LADevExtremeGrid
                height={400}
                id="downtime"
                searchPanel={false}
                filterHeader={false}
                data={props.data}
                onClick={undefinedFunction}
                columns={[
                    { name: "plant", caption: "Plant", type: "string" },
                    { name: "un_Scheduled_Downtime_Hrs", caption: "UNSCHD Hrs", type: "number", width: 110 },
                    { name: "unschD_Notes", caption: "UNSCHD Notes", type: "string" },
                    { name: "scheduled_Downtime_Hrs", caption: "SCHD Hrs", type: "number", width: 100 },
                    { name: "schD_Notes", caption: "SCHD Notes", type: "string" },
                    { name: "component", caption: "Component", type: "string" },
                    { name: "cause", caption: "Cause", type: "string" },
                    { name: "reason", caption: "Reason", type: "string" },
                    { name: "category", caption: "Category", type: "string" }
                ]}
                sumColumn={[
                    { columnName: "un_Scheduled_Downtime_Hrs", caption: "Total Hrs" },
                    { columnName: "scheduled_Downtime_Hrs", caption: "Total Hrs" }
                ]}
            />
        </LAPaperWithPadding>
    </LAPopover>
));

interface IProductionSummaryTableProps {
    data: IProductionSummary[];
};

export const ProductionSummaryTable: React.FC<IProductionSummaryTableProps> = React.memo((props: IProductionSummaryTableProps) => (
    <LAPaperWithLessPadding>
        <LADevExtremeGrid
            height={300}
            id="pro-summary"
            searchPanel={false}
            filterHeader={false}
            data={props.data}
            columnWidth={130}
            onClick={undefinedFunction}
            columns={[
                { name: "product", caption: "By Products", type: "string" },
                { name: "tonnage", caption: "Total Tonnage (MT)", type: "number" },
            ]}
        />
    </LAPaperWithLessPadding>
));