import { IPassTokenWithRequest } from "../../dryPlant/token/getTokenConstants";
import { IAddSieveCalibrationMeasurementRequest } from "../addSieveCalibration/addSieveCalibrationConstaints";

export enum IUPDATE_SIEVE_CALIBRATION_REQUEST {
    REQUEST = "updateSieveCalibration/UPDATE_SIEVE_CALIBRATION_REQUEST",
    SUCCESS = "updateSieveCalibration/UPDATE_SIEVE_CALIBRATION_SUCCESS",
    FAILED = "updateSieveCalibration/UPDATE_SIEVE_CALIBRATION_FAILED"
};

export interface IUpdateSieveCalibrationRequest extends IPassTokenWithRequest {
    request: IUpdateSieveCalibration;
};

export interface IUpdateSieveCalibration {
    ID: number;
    Date: string;
    Operator?: string;
    Plant: string;
    Product: string;
    Notes?: string;
    Initial_Mass?: string;
    Modified_By: string;
    Measurements: IAddSieveCalibrationMeasurementRequest[];
};