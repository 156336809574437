import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { HasClass } from "./publicInterfaces";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { useState } from "react";

interface ILADateTimePickerProps extends HasClass {
  name: string;
  label: string;
  value: string;
  disabled?: boolean; // Change type to boolean
  fullWidth?: boolean; // Change type to boolean
  errorText?: string;
  disableFuture?: boolean; // Change type to boolean
  dateOnly?: boolean; // New prop to control date-only display
  onChange: (name: string, date: any) => void;
  varient?: "standard" | "outlined";
}

export const LADateTimeMUIPicker = (props: ILADateTimePickerProps): JSX.Element => {
  const onChange = (date: any) => props.onChange(props.name, date);
  const [open, setOpen] = useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {props.dateOnly ? ( // Conditionally render based on dateOnly prop
        <DatePicker // Use DatePicker instead of DateTimePicker for date-only display
          label={props.label}
          value={props.value}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          onChange={onChange}
          disableFuture={props.disableFuture}
          renderInput={(params) => (
            <TextField
              {...params}
              autoComplete="off"
              // variant="standard"
              variant={props.varient ?? "standard"}
              color="secondary"
              name={props.name}
              fullWidth={props.fullWidth}
              disabled={props.disabled}
              helperText={props.errorText}
              onClick={(e) => setOpen(true)}
              error={props.errorText ? true : false}
            />
          )}
        />
      ) : (
        <DateTimePicker // Render DateTimePicker for date and time display
          label={props.label}
          value={props.value}
          onChange={onChange}
          disableFuture={props.disableFuture}
          renderInput={(params) => (
            <TextField
              {...params}
              autoComplete="off"
              variant={props.varient ?? "standard"}
              color="secondary"
              name={props.name}
              fullWidth={props.fullWidth}
              disabled={props.disabled}
              helperText={props.errorText}
              error={props.errorText ? true : false}
            />
          )}
        />
      )}
    </LocalizationProvider>
  );
};
