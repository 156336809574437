import { IAddCraneRequest, IADD_CRANE_REQUEST } from "./addCraneConstaints";

export interface IAddCraneLoadAction {
    type: IADD_CRANE_REQUEST.REQUEST;
    data: IAddCraneRequest
}
export const addCraneLoadAction = (data: IAddCraneRequest): IAddCraneLoadAction => ({
    type: IADD_CRANE_REQUEST.REQUEST,
    data
});

export interface IAddCraneSuccessAction {
    type: IADD_CRANE_REQUEST.SUCCESS;
    message: string;
}
export const addCraneLoadSuccessAction = (message: string): IAddCraneSuccessAction => ({
    type: IADD_CRANE_REQUEST.SUCCESS,
    message
});
export interface IAddCraneLoadFailedAction {
    type: IADD_CRANE_REQUEST.FAILED;
    message: string;
}
export const addCraneLoadFailedAction = (message: string): IAddCraneLoadFailedAction => ({
    type: IADD_CRANE_REQUEST.FAILED,
    message
});
