import { ById, SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetProductionRecordsRequest, IGET_PRODUCTION_RECORDS_REQUEST, IProductionRecords } from "./getProductionRecordsConstaints";

export interface IGetProductionRecordsLoadAction {
    type: IGET_PRODUCTION_RECORDS_REQUEST.REQUEST;
    data: IGetProductionRecordsRequest
}
export const getProductionRecordsLoadAction = (data: IGetProductionRecordsRequest): IGetProductionRecordsLoadAction => ({
    type: IGET_PRODUCTION_RECORDS_REQUEST.REQUEST,
    data
});

export interface IGetProductionRecordsSuccessAction {
    type: IGET_PRODUCTION_RECORDS_REQUEST.SUCCESS;
    data: SilAPIResponse<ById<IProductionRecords>>;
}
export const getProductionRecordsLoadSuccessAction = (data: SilAPIResponse<ById<IProductionRecords>>): IGetProductionRecordsSuccessAction => ({
    type: IGET_PRODUCTION_RECORDS_REQUEST.SUCCESS,
    data
});

export interface IGetProductionRecordsLoadFailedAction {
    type: IGET_PRODUCTION_RECORDS_REQUEST.FAILED;
    message: string;
}
export const getProductionRecordsLoadFailedAction = (message: string): IGetProductionRecordsLoadFailedAction => ({
    type: IGET_PRODUCTION_RECORDS_REQUEST.FAILED,
    message
});
