import { IAddSieveDryPlantRequest, IADD_SIEVE_DRY_PLANT_REQUEST } from "./addSieveDryPlantConstaints";

export interface IAddSieveDryPlantLoadAction {
    type: IADD_SIEVE_DRY_PLANT_REQUEST.REQUEST;
    data: IAddSieveDryPlantRequest
}
export const addSieveDryPlantLoadAction = (data: IAddSieveDryPlantRequest): IAddSieveDryPlantLoadAction => ({
    type: IADD_SIEVE_DRY_PLANT_REQUEST.REQUEST,
    data
});

export interface IAddSieveDryPlantSuccessAction {
    type: IADD_SIEVE_DRY_PLANT_REQUEST.SUCCESS;
    message: string;
}
export const addSieveDryPlantLoadSuccessAction = (message: string): IAddSieveDryPlantSuccessAction => ({
    type: IADD_SIEVE_DRY_PLANT_REQUEST.SUCCESS,
    message
});
export interface IAddSieveDryPlantLoadFailedAction {
    type: IADD_SIEVE_DRY_PLANT_REQUEST.FAILED;
    message: string;
}
export const addSieveDryPlantLoadFailedAction = (message: string): IAddSieveDryPlantLoadFailedAction => ({
    type: IADD_SIEVE_DRY_PLANT_REQUEST.FAILED,
    message
});
