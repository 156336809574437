import { ISieveDryPlant } from "../getSieveDryPlants/getSieveDryPlantsConstaints";
import { IUpdateSieveDryPlantRequest, IUPDATE_SIEVE_DRY_PLANT_REQUEST } from "./updateSieveDryPlantConstaints";

export interface IUpdateSieveDryPlantLoadAction {
    type: IUPDATE_SIEVE_DRY_PLANT_REQUEST.REQUEST;
    data: IUpdateSieveDryPlantRequest
}
export const updateSieveDryPlantLoadAction = (data: IUpdateSieveDryPlantRequest): IUpdateSieveDryPlantLoadAction => ({
    type: IUPDATE_SIEVE_DRY_PLANT_REQUEST.REQUEST,
    data
});

export interface IUpdateSieveDryPlantSuccessAction {
    type: IUPDATE_SIEVE_DRY_PLANT_REQUEST.SUCCESS;
    data: ISieveDryPlant;
}
export const updateSieveDryPlantLoadSuccessAction = (data: ISieveDryPlant): IUpdateSieveDryPlantSuccessAction => ({
    type: IUPDATE_SIEVE_DRY_PLANT_REQUEST.SUCCESS,
    data
});

export interface IUpdateSieveDryPlantLoadFailedAction {
    type: IUPDATE_SIEVE_DRY_PLANT_REQUEST.FAILED;
    message: string;
}
export const updateSieveDryPlantLoadFailedAction = (message: string): IUpdateSieveDryPlantLoadFailedAction => ({
    type: IUPDATE_SIEVE_DRY_PLANT_REQUEST.FAILED,
    message
});
