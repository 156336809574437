import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IGetChemicalAnalysesLoadAction, IGetChemicalAnalysesLoadFailedAction, IGetChemicalAnalysesSuccessAction, 
    getChemicalAnalysesLoadFailedAction, getChemicalAnalysesLoadSuccessAction
} from "./getChemicalAnalysesActions";
import { IGET_CHEMICAL_ANALYSES_REQUEST, IChemicalAnalyses } from "./getChemicalAnalysesConstaints";
import { IStore } from "../../../../reducers";
import { EpicDependencies } from "../../../../store";
import { SilAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { END_POINTS } from "../../../../endpoints";
import { withJsonContentType } from "../../../../epicUtils";

export const getChemicalAnalysesEpic: Epic = (
    action$: ActionsObservable<IGetChemicalAnalysesLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IGetChemicalAnalysesSuccessAction | IGetChemicalAnalysesLoadFailedAction> =>
    action$.ofType(IGET_CHEMICAL_ANALYSES_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<IChemicalAnalyses[]>>(
                    END_POINTS.PROPSENSE.SIL.EXPLORATION.GET_CHEMICAL_ANALYSES,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<IChemicalAnalyses[]>): IGetChemicalAnalysesSuccessAction => {
                            return getChemicalAnalysesLoadSuccessAction(response);
                        }),
                        catchError(() => ActionsObservable.of(getChemicalAnalysesLoadFailedAction("Unable to get chemical analyses")))
                    )
            )
        );