import React from "react";
import { Droppable } from "react-beautiful-dnd";
import { IAssignedCrew } from "../../../../../../redux/propsense/catapult/crew_sheduling/getAssignedCrews/getAssignedCrewsConstants";
import { ICrew } from "../../../../../../redux/propsense/catapult/crew_sheduling/getCrews/getCrewsConstants";
import { IJob, IREQUIRED_CREW } from "../../../../../../redux/propsense/catapult/crew_sheduling/getJobs/getJobsConstants";
import { LAIconButton } from "../../../../../shared/buttons";
import { ZEROTH } from "../../../../../shared/constExports";
import { DropPaper } from "../../../../../shared/dragAndDropPaper";
import LAGrid from "../../../../../shared/grid";
import LAGridItem from "../../../../../shared/gridList";
import { DeleteIcon } from "../../../../../shared/icons";
import { LAPaperWithLessPadding } from "../../../../../shared/paper";
import { RED_COLOR } from "../../../../../shared/theme";
import { sortRequiredCrewByDate, findCrew } from "./constFunctionsForCrew";
import { RenderCrewRequirement } from "./renderCrewRequirement";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

interface IRequiredCrewRenderProps {
    dayShift: boolean;
    job: IJob | undefined;
    crewList: ICrew[];
    assignedCrew: IAssignedCrew[];
    handleDelete: (cIndex: IAssignedCrew) => void;
    onDateChange: (sDate: Date, eDate: Date, ac: IAssignedCrew) => void;
    onTransport: (value: string, ac: IAssignedCrew) => void;
};


export const RequiredCrewRender: React.FC<IRequiredCrewRenderProps> = React.memo((props: IRequiredCrewRenderProps) =>
    <>
        {props.job?.required_Crews
            .sort(sortRequiredCrewByDate)
            .map((x, index): JSX.Element => {
                if (props.dayShift && x.shift === "Day") {
                    return <RenderShift {...props} index={index} x={x} />;
                } else if ((props.dayShift === false) && (x.shift === "Night")) {
                    return <RenderShift {...props} index={index} x={x} />;
                }
                return <></>;
            })}
    </>
);


interface IRenderShiftProps extends IRequiredCrewRenderProps {
    x: IREQUIRED_CREW;
    index: number;
};

export const RenderShift: React.FC<IRenderShiftProps> = React.memo((props: IRenderShiftProps): JSX.Element =>
    <LAGridItem md={12} lg={12} key={props.index}>
        <Droppable key={props.index} droppableId={props.index.toString()}>
            {(provided, snapshot) => (

                <DropPaper
                    className="cursor"
                    innerRef={provided.innerRef}
                    styleDraggar={snapshot.isDraggingOver}
                >
                    <LAGrid>
                        <LAGridItem xs={12}>
                            <RenderCrewRequirement
                                rc={props.x}
                                crewList={props.crewList}
                                orderId={props.job?.order_ID}
                                assignedCrew={props.assignedCrew}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12}>
                            <LAGrid>
                                {props.assignedCrew
                                    .filter((v) => findCrew(v, props.x, props.job?.order_ID)).length > ZEROTH &&
                                    <LAGridItem xs={12}>
                                        <LAPaperWithLessPadding>
                                            <LAGrid spacing={2}>
                                                {props.assignedCrew
                                                    .filter((v) => findCrew(v, props.x, props.job?.order_ID))
                                                    .sort((a, b) => sortBySup(a, b, props.crewList))
                                                    .map((p, ind) => {
                                                        const val = findCrewDetail(props.crewList, p.crew_ID);
                                                        return (
                                                            <LAGridItem xs={12} key={p.crew_ID}>
                                                                <LAGrid spacing={1} justify="center" alignItems="center" className={val?.active === "No" ? "borderBox" : ""}>

                                                                    <LAGridItem xs={12}>
                                                                        <strong>{(ind + 1)}.{val?.name}</strong>
                                                                        <span> ({getJobTitle(val)}) </span>
                                                                        <LAIconButton
                                                                            label="Remove"
                                                                            onClick={() => props.handleDelete(p)}
                                                                            icon={<DeleteIcon color={RED_COLOR} />} />
                                                                        <br />
                                                                    </LAGridItem>

                                                                    <LAGridItem lg={12}>
                                                                        <FormLabel component="legend">Transport</FormLabel>
                                                                        <FormControl component="fieldset">
                                                                            <RadioGroup row={true} aria-label="Transport" name="Transport" value={p.transport ?? "N/A"} onChange={(e, v) => props.onTransport(v, p)}>
                                                                                <FormControlLabel value="N/A" control={<Radio />} label="N/A" />
                                                                                <FormControlLabel value="Drive" control={<Radio />} label="Drive" />
                                                                                <FormControlLabel value="Flight" control={<Radio />} label="Flight" />
                                                                            </RadioGroup>
                                                                        </FormControl>
                                                                    </LAGridItem>

                                                                    <LAGridItem lg={12} xl={6}>
                                                                        <Flatpickr
                                                                            id="sDate"
                                                                            name="sDate"
                                                                            options={{
                                                                                dateFormat: "m/d/Y"
                                                                            }}
                                                                            value={new Date(p.start_Date)}
                                                                            placeholder="Select Start Date"
                                                                            onChange={(e) => props.onDateChange(e[0], new Date(p.end_Date), p)} />
                                                                    </LAGridItem>

                                                                    <LAGridItem lg={12} xl={6}>
                                                                        <Flatpickr
                                                                            id="eDate"
                                                                            name="eDate"
                                                                            options={{
                                                                                dateFormat: "m/d/Y"
                                                                            }}
                                                                            value={new Date(p.end_Date)}
                                                                            placeholder="Select End Date"
                                                                            onChange={(e) => props.onDateChange(new Date(p.start_Date), e[0], p)} />
                                                                    </LAGridItem>

                                                                </LAGrid>
                                                            </LAGridItem>
                                                        );
                                                    })}
                                            </LAGrid>
                                        </LAPaperWithLessPadding>
                                    </LAGridItem>
                                }
                            </LAGrid>
                        </LAGridItem>

                    </LAGrid>
                </DropPaper>
            )}
        </Droppable>
    </LAGridItem>
);


const findCrewDetail = (cl: ICrew[], id: number): ICrew | undefined => {
    return cl.find((x) => x.crew_ID === id);
};

const sortBySup = (a: IAssignedCrew, b: IAssignedCrew, crews: ICrew[]): any => {
    let val = findCrewDetail(crews, a.crew_ID);
    if (val) {
        if (val.job_Level === "Supervisor")
            return -1;
    }
    return 1;
};

export const getJobTitle = (val: ICrew | undefined): string => {

    if (val) {
        switch (val.job_Level) {
            case "Supervisor":
                return "Super";

            case "Operator_Level1":
                return "OP1";

            case "Operator_Level2":
                return "OP2";

            case "Operator_Level3":
                return "OP3";
        }
    };

    return "";
};