import { ById, SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetSieveCalibrationsRequest, IGET_SIEVES_CALIBRATIONS_REQUEST, ISieveCalibration } from "./getSievesCalibrationsConstaints";

export interface IGetSieveCalibrationsLoadAction {
    type: IGET_SIEVES_CALIBRATIONS_REQUEST.REQUEST;
    data: IGetSieveCalibrationsRequest
}
export const getSieveCalibrationsLoadAction = (data: IGetSieveCalibrationsRequest): IGetSieveCalibrationsLoadAction => ({
    type: IGET_SIEVES_CALIBRATIONS_REQUEST.REQUEST,
    data
});

export interface IGetSieveCalibrationsSuccessAction {
    type: IGET_SIEVES_CALIBRATIONS_REQUEST.SUCCESS;
    data: SilAPIResponse<ById<ISieveCalibration>>;
}
export const getSieveCalibrationsLoadSuccessAction = (data: SilAPIResponse<ById<ISieveCalibration>>): IGetSieveCalibrationsSuccessAction => ({
    type: IGET_SIEVES_CALIBRATIONS_REQUEST.SUCCESS,
    data
});

export interface IGetSieveCalibrationsLoadFailedAction {
    type: IGET_SIEVES_CALIBRATIONS_REQUEST.FAILED;
    message: string;
}
export const getSieveCalibrationsLoadFailedAction = (message: string): IGetSieveCalibrationsLoadFailedAction => ({
    type: IGET_SIEVES_CALIBRATIONS_REQUEST.FAILED,
    message
});
