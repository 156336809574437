import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { failed, hasPayload, loading, notLoaded, Server, succeeded } from "../../../server";
import { IGET_BIN_ADJUSTMENTS_REQUEST, IBinAdjustments } from "./getBinAdjustmentsConstaints";
import { IAddBinAdjustmentSuccessAction } from "../addBinAdjustment/addBinAdjustmentActions";
import { IADD_BIN_ADJUSTMENT_REQUEST } from "../addBinAdjustment/addBinAdjustmentConstaints";
import { IUpdateBinAdjustmentSuccessAction } from "../updateBinAdjustment/updateBinAdjustmentActions";
import { IUPDATE_BIN_ADJUSTMENT_REQUEST } from "../updateBinAdjustment/updateBinAdjustmentConstaints";
import { IGetBinAdjustmentsLoadAction, IGetBinAdjustmentsLoadFailedAction, IGetBinAdjustmentsSuccessAction } from "./getBinAdjustmentsActions";

type Actions =
    IGetBinAdjustmentsLoadAction
    | IGetBinAdjustmentsLoadFailedAction
    | IGetBinAdjustmentsSuccessAction
    | IAddBinAdjustmentSuccessAction
    | IUpdateBinAdjustmentSuccessAction
    | IFlushDataSuccessAction;

export const GetBinAdjustmentsReducer = (state: Server<SilAPIResponse<IBinAdjustments[]>> = notLoaded, action: Actions): Server<SilAPIResponse<IBinAdjustments[]>> => {
    switch (action.type) {
        case IGET_BIN_ADJUSTMENTS_REQUEST.REQUEST:
            return loading;

        case IGET_BIN_ADJUSTMENTS_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_BIN_ADJUSTMENTS_REQUEST.FAILED:
            return failed(action.message);

        case IUPDATE_BIN_ADJUSTMENT_REQUEST.SUCCESS:

            if (hasPayload(state)) {
                const currentState = [...state.payload.response];
                const ind = currentState.findIndex(x => x.id === action.data.id);

                if (ind >= 0)
                    currentState[ind] = action.data;

                return {
                    ...state,
                    payload: {
                        ...state.payload,
                        response: currentState
                    }
                };
            }
            return notLoaded;

        case IADD_BIN_ADJUSTMENT_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};