import { ById, SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IGET_SIEVES_CHECKS_REQUEST, ISieveCheck } from "./getSievesChecksConstaints";
import { IGetSieveChecksLoadAction, IGetSieveChecksLoadFailedAction, IGetSieveChecksSuccessAction } from "./getSievesChecksActions";
import { IUPDATE_SIEVE_CHECK_REQUEST } from "../updateSieveCheck/updateSieveCheckConstaints";
import { IADD_SIEVE_CHECK_REQUEST } from "../addSieveCheck/addSieveCheckConstaints";
import { IAddSieveCheckSuccessAction } from "../addSieveCheck/addSieveCheckActions";
import { IUpdateSieveCheckSuccessAction } from "../updateSieveCheck/updateSieveCheckActions";

type Actions =
    IGetSieveChecksLoadAction
    | IGetSieveChecksLoadFailedAction
    | IGetSieveChecksSuccessAction
    | IAddSieveCheckSuccessAction
    | IUpdateSieveCheckSuccessAction
    | IFlushDataSuccessAction;

export const GetSieveChecksReducer = (state: Server<SilAPIResponse<ById<ISieveCheck>>> = notLoaded, action: Actions): Server<SilAPIResponse<ById<ISieveCheck>>> => {
    switch (action.type) {
        case IGET_SIEVES_CHECKS_REQUEST.REQUEST:
            return loading;

        case IGET_SIEVES_CHECKS_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_SIEVES_CHECKS_REQUEST.FAILED:
            return failed(action.message);

        case IUPDATE_SIEVE_CHECK_REQUEST.SUCCESS:
            return notLoaded;

        case IADD_SIEVE_CHECK_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};