import React, {Component} from "react";
import '../../../../shared/simos/styling/popupstyle.css';
import {RouteComponentProps} from 'react-router-dom';
import editOrder from '../APICalls/editOrder';
import Popup from '../../../../shared/simos/helper/popupModal';
import { formatDateFromDBFormat, formatDateToDBFormat } from "../../../../shared/simos/helper/dateFormatter";
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {FaPencilAlt} from 'react-icons/fa';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import BackButton from '../../../../shared/simos/helper/backButton';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';
import editSelectedOrderContacts from '../APICalls/editSelectedOrderedByContact';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import PrintIcon from '@mui/icons-material/Print';
import EmailIcon from '@mui/icons-material/Email';
import getSIMOSTransportCompany from '../APICalls/getRequests/getSIMOSTransportCompany';
import getSIMOSWHProductInfo from '../APICalls/getRequests/getSIMOSWHProductInfo';
import deleteProductOrder from '../APICalls/deleteProductOrder';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import Typography from '@material-ui/core/Typography';
import { validateEditForm, errorStr} from "./formValidation";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import {getSIMOSUserGroupAccess} from '../APICalls/getSIMOSGroupAccess';
import { getAsyncSIMOSCustomerInfoByID } from "../APICalls/getRequests/getSIMOSCustomerInfoByID";
import { getSILURLParams } from "../../../../shared/simos/helper/getURLContainsSIL";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
const StyledTableBorder = {
    border: '2px solid rgba(0, 0, 0, 0.28)',
};
const StyledTableHeader = {
    backgroundColor:'#e3efff'
};

interface IProps extends RouteComponentProps{
    editID: number;
    allRequests:any;
    history:any;
}

interface IState {
    product:any[];
    allPerOptions:string[];
    allSizeOptions:string[];
    all_transports:any[];
    emailCheck:boolean;
    transportCheck:boolean;
    packagedCheck:boolean;
    completedCheck:boolean;
    addProduct:boolean;
    showPopup:boolean;
    contacts:any[];
    orderformName:string;
    orderformEmail:string;
    orderformFax:string;
    orderformPhone:string;
    copyedit:any;
    productedit:any[];
    showAddProduct:boolean;
    isOrderStatusCompleted:boolean;
    showDeletePopup:boolean;
    chosenProd:string;
    chosenSize:string;
    chosenPer:string;
    code:string;
    c_w:string;
    c_w_checkbox:boolean;
    d_c_w:string;
    d_c_w_checkbox:boolean;
    quantity:number;
    actualQuantity:number;
    totalTonnage:number;
    showCancelOrderPopup:boolean;
    cancelOrderConfirm:boolean;
    showError:boolean;
    errorText:string;
    selectedContact:string;
    customer_explorer_code:string;
}
let newProduct = {quantity:'',size:'',per:'',product:'',code:'',cw:'',double_CW:'', actual_Quantity:'', id:0};
var deleteIndex = 0;
let idx:number = 0;
let currentitem:any;
var productOptions:any;
var optionTransport;
var chosenCustomer:string;
var chosenDelivery:string;
var chosenCustomerID:number;
var productCascadeDropdown:any[]= [];
var cancelOrderConfirm = false;
var cancelTriggered=false;
export default class ViewSIMOSOrderForm extends Component<IProps, IState>{
    constructor(props:IProps) {
        super(props);
        this.state = {
            product:[], allPerOptions:[], allSizeOptions:[],all_transports:[],emailCheck:false,transportCheck:false,packagedCheck:false,completedCheck:false,addProduct:false,showPopup:false,contacts:[],
            orderformName:'',orderformEmail:'',orderformFax:'',orderformPhone:'',copyedit:{},productedit:[],showAddProduct:false, isOrderStatusCompleted:false, showDeletePopup:false,
            chosenSize:'', chosenProd:'', chosenPer:'', code:'',actualQuantity:0,quantity:0,c_w:'', c_w_checkbox:false, d_c_w:'', d_c_w_checkbox:false, totalTonnage:0, showCancelOrderPopup:false, cancelOrderConfirm:false, errorText:'', showError:false,
            selectedContact:'',customer_explorer_code:''
        }
    }
    

  

    async fetchOrderedBy(chosenCustomerID:number) {
        let initialContacts:any = [];
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type':'application/json' }, 
            body: JSON.stringify({request:{customer_id:chosenCustomerID, order_id:this.state.copyedit.id}, token: '19515195-d571-44a3-b0db-637f5bf24f54' })
        }
        await fetch('https://api2.propsense.com/api/GetSIMOSOrderedByContact', requestOptions)
        .then(async response => {
            const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
              // error response
              if(!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            // no error
            data.map((contacts:any) => {
                if(contacts.customer_ID === chosenCustomerID) {
                    initialContacts.push({orderformName: contacts.contact, orderformEmail:contacts.email, orderformFax:contacts.fax, orderformPhone:contacts.phone, 
                    contact_selected:false, contactID: contacts.id,customerID:contacts.customer_ID, deliveryID:contacts.delivery_ID});
                }
            });
        });
        fetch('https://api2.propsense.com/api/GetSIMOSSelectedContactEdit', requestOptions)
        .then(async response => {
            const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
              // error response
              if(!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            data.map((contact:any) => {
                // Changed contact.orderID to contact.id
                // if(contact.orderID===this.state.copyedit.id){
                    for(var ind=0;ind<initialContacts.length;ind++){
                        if(initialContacts[ind].orderformEmail === contact.email && initialContacts[ind].orderformName=== contact.contact  &&initialContacts[ind].customerID === contact.customer_ID){
                            initialContacts[ind].contact_selected = true;
                            this.setState({selectedContact:initialContacts[ind].orderformName});
                        }
                    }
                // }
                this.setState({contacts:initialContacts});
            })
        });
        var explorer_code:any = await getAsyncSIMOSCustomerInfoByID(chosenCustomerID);
        this.setState({customer_explorer_code:explorer_code});
    }

    async componentDidMount() {
        var response:any = await getSIMOSUserGroupAccess();
        var userRole:any = response.role;
        if(userRole!='Admin' && userRole!='Dispatcher' && userRole!='SuperAdmin'){
            this.props.history.push('/simos');
        }
        this.setState({all_transports: await getSIMOSTransportCompany()});
      // ! Get order by ID
      const orderOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ request:{
                Customer:'orderdesk',
                ID:window.location.pathname.split("=")[1].split("?")[0]
            },
                token: '19515195-d571-44a3-b0db-637f5bf24f54' })
        };
       var editdata:any=[];
       await fetch('https://api2.propsense.com/api/GetSIMOSOrderByID', orderOptions)
       .then(async response => {
            const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
            // error response
            if(!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            // no error
            editdata = data;
            // Disabled save button if order status is complete
            if(editdata.status==="Completed"){
                this.setState({isOrderStatusCompleted:true});
            }
            this.setState({copyedit:editdata});
            this.fetchOrderedBy(this.state.copyedit.customer_ID);

            })
            .catch(error => {
                console.log('There was an error!', error);
            });
            // ! Get orderedby products
            var editproducts:any=[];
            
            await fetch('https://api2.propsense.com/api/GetSIMOSProduct', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                token: '19515195-d571-44a3-b0db-637f5bf24f54' })
            })
            .then(async response => {
            const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
            // error response
            if(!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            // no error
            data.map((products:any) => {
                if(products.orderID.toString() === window.location.pathname.split("=")[1].split("?")[0]){
                editproducts.push(products);
                }
            });
                this.setState(state => ({copyedit:
                    {
                        ...state.copyedit,
                        product_Order:editproducts,
                    },
                }))
            })
            .catch(error => {
                console.log('There was an error!', error);
            })
    
            this.setState({completedCheck: this.state.copyedit.completed==='Yes', packagedCheck: this.state.copyedit.packaged==='Yes', 
            emailCheck: this.state.copyedit.emailUser==='Yes', transportCheck: this.state.copyedit.emailTransport==='Yes'});
            var all_products:string[] = [];
            productCascadeDropdown = await getSIMOSWHProductInfo("","",this.state.copyedit.location,"");
            productCascadeDropdown[0].map((res:any) => {
                if(all_products.indexOf(res.description)===-1){
                    all_products.push(res.description);
                }
            });
            this.setState({product:all_products.sort()});
    }

    handlePopupCancel(){
        this.setState({showPopup:!this.state.showPopup});
    }
    handlePopupExit(){
        this.props.history.push('/simos/orders'+getSILURLParams());
    }

    cancelEdit(event:any) {
        event.preventDefault();
        this.setState({showPopup:!this.state.showPopup});
    }

    // On form complete
    async saveEdit(event:any){
        this.props.history.replace('/simos/orders');
        // ! switch with setstate or refresh grid
        window.location.reload();
    }

    render() {
        let products = this.state.product.sort();
        productOptions = products.map((prod) => <option>{prod}</option>);
        productOptions.push(<option disabled selected></option>);

        let transports = this.state.all_transports;
        optionTransport = transports.map((company) => <option>{company}</option>);

        let allperoptions:any[] = this.state.allPerOptions.map((per) => <option>{per}</option>);
        allperoptions.push(<option disabled selected></option>);
        let allsizeoptions:any[] = this.state.allSizeOptions.map((sizes) => <option>{sizes}</option>);
        allsizeoptions.push(<option disabled selected></option>);

        let customerContactOptions = this.state.contacts.map((elem:any, idx:number) => 
            <MenuItem value={elem.orderformName}>
                <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: 0.1 }}>
                    <Checkbox disabled checked={elem.contact_selected} name="contactselected"></Checkbox>
                    <Grid item xs={3}>
                        <Chip icon={<PermContactCalendarIcon/>} label={elem.orderformName}></Chip>
                    </Grid>
                    <Grid item xs={3}>
                        <Chip icon={<LocalPhoneIcon/>} label={elem.orderformPhone}></Chip>
                    </Grid>
                    <Grid item xs={3}>
                        <Chip icon={<PrintIcon />} label={elem.orderformFax}></Chip>
                    </Grid>
                    <Grid item xs={3}>
                        <Chip icon={<EmailIcon/>} label={elem.orderformEmail}></Chip>
                    </Grid>
                </Box>
            </MenuItem>
        );

        if(this.state.copyedit.id != null && this.state.copyedit.product_Order != null){
            var formatPickupDate = formatDateFromDBFormat(this.state.copyedit.pickupdate, this.state.copyedit.pickuphour, this.state.copyedit.pickupminute);
            var formatDeliveryDate = formatDateFromDBFormat(this.state.copyedit.deliverydate, this.state.copyedit.deliveryhour, this.state.copyedit.deliveryminute);
            chosenCustomer = this.state.copyedit.customer;
            chosenCustomerID=this.state.copyedit.customer_ID;
            chosenDelivery = this.state.copyedit.delivery;

        return (
            <Paper className={getSILURLParams()?.includes("bru") ? "paper-popup":"paper-popup-sil-ed"}>
                <Paper className="paper-popup-inner">
            <BackButton goBack={this.cancelEdit.bind(this)} history={this.props.history}></BackButton>
                    <form>
                    
                    <div>
                        <h3>Viewing Order <label id='header-3-label'>#{this.state.copyedit.id}</label></h3>
                                    <Paper className="paper-card">
                                    <div id="customer-deliver">
                                        Customer: <label id='edit-customer-name-label'>{this.state.copyedit.customer}{this.state.customer_explorer_code != null? "("+this.state.customer_explorer_code+")":null}</label>
                                        <br></br> 
                                        {/* Order Type: <label  id='edit-delivery-name-label'>{this.state.copyedit.order_Type}</label><br></br> */}
                                        Delivery Address: <label  id='edit-delivery-name-label'>{this.state.copyedit.address}</label>
                                        <br></br>
                                        {this.state.copyedit.sales_Order_Number!=null && this.state.copyedit.sales_Order_Number!=""? <>Sales Order #:<label  id='edit-delivery-name-label'>{this.state.copyedit.sales_Order_Number}</label></>:null}
                                    </div>
                                    <br></br>
                               
                                <Paper className="paper-card">
                                    <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography style={{fontWeight:"bold"}} variant="h5" gutterBottom component="div">Customer Contact</Typography>
                                            <Select 
                                            variant="outlined"
                                            fullWidth
                                            name="customer_contact_select"
                                            value={this.state.selectedContact}
                                            disabled
                                            >
                                                {customerContactOptions}
                                            </Select>
                                    
                                        </Grid>
                                      
                                        <Grid item xs={6}>
                                        <InputLabel htmlFor="datetime-local">Scheduled Pickup Date/Time</InputLabel>
                                        <TextField disabled id='datetime-local' type='datetime-local' name="pickup_date" defaultValue={formatPickupDate} InputLabelProps={{shrink:true}}></TextField>
                                        {/* <label>Pickup Date</label><input id='edit-form-input-field' type="date" name="pickup_date" onChange={this.handleEdit.bind(this, this.props.editID, null) }defaultValue={obj.pickupDate}placeholder={obj.pickupDate}></input>
                                        <label> Hour:</label><select id='edit-form-input-field' name="pickup_hour" onChange={this.handleEdit.bind(this, this.props.editID, null) }defaultValue={obj.pickupHour}placeholder={obj.pickupHour}>{optionItems}</select>
                                        <label> Min:</label><select id='edit-form-input-field' name = "pickup_min" onChange={this.handleEdit.bind(this, this.props.editID, null) }defaultValue={obj.pickupMin}placeholder={obj.pickupMin}>{optionItems2}</select><br></br>
                                        <br></br> */}
                                        </Grid>
                                        
                                        <Grid item xs={6}>
                                        <InputLabel htmlFor="datetime-local">Delivery Date/Time</InputLabel>
                                        <TextField disabled id='datetime-local' type='datetime-local' name="delivery_date" defaultValue={formatDeliveryDate} InputLabelProps={{shrink:true}}></TextField>
                                        {/* <label>Delivery Date</label><input id='edit-form-input-field' type="date" name="delivery_date" onChange={this.handleEdit.bind(this, this.props.editID, null) }defaultValue={obj.deliveryDate}placeholder={obj.deliveryDate}></input>
                                        <label> Hour:</label><select id='edit-form-input-field' name="delivery_hour" onChange={this.handleEdit.bind(this, this.props.editID, null) }defaultValue={obj.deliveryHour}placeholder={obj.deliveryHour}>{optionItems}</select>
                                        <label> Min:</label><select id='edit-form-input-field' name ="delivery_min" onChange={this.handleEdit.bind(this, this.props.editID, null) }defaultValue={obj.deliverMin}placeholder={obj.deliveryMin}>{optionItems2}</select> */}
                                        </Grid>
                                        
                                        <Grid item xs={3}>
                                            <TextField disabled autoComplete="off" label="Site Contact" defaultValue={this.state.copyedit.sitecontact} name='site_contact' ></TextField>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField disabled autoComplete="off" label="Site Contact Phone #" defaultValue={this.state.copyedit.sitecontact_Phone} name='site_contact_phone' ></TextField>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField disabled autoComplete="off" required label="PO #" defaultValue={this.state.copyedit.pO_Number} name='po_num' ></TextField>
                                        </Grid>
                                      
                              
                                       <Grid item xs={3}>
                                    
                                        <TextField disabled label="Transport Company" defaultValue={this.state.copyedit.transport_Company}></TextField>
                                        
                                        </Grid>
                              
                                    <Grid item xs={3}>
                                        <TextField disabled label="Truck Size" defaultValue={this.state.copyedit.truck_Required}>
                                    </TextField>
                                    </Grid>
                                    {this.state.copyedit.order_Type === 'collect' ?
                                    <Grid item xs={3}>
                                        <TextField disabled autoComplete='off' value = {this.state.copyedit.transport_Company_Notes} multiline rows={2} variant="outlined"  id="standard-required" name="transport_notes" label="Transport Company/Truck Size Notes"  ></TextField>
                                    </Grid>
                                    :null}
                                     <Grid item xs={3}>
                                     <TextField disabled autoComplete="nope" label="Zone" defaultValue={this.state.copyedit.zone}></TextField>
                                 </Grid>
                                      <Grid item xs={3}>
                                     <TextField disabled type="number" autoComplete="nope" label="Hired Truck Rate" defaultValue={this.state.copyedit.hired_Truck_Rates} ></TextField>
                                 </Grid>
                                 {this.state.copyedit.transport_Company === 'Yellowhead Trucking' && this.state.copyedit.order_Type==='prepaid' ?   
                                    <Grid item xs={3}>
                                        <TextField autoComplete='off' disabled label="Truck Code" defaultValue={this.state.copyedit.truck_Code} ></TextField>
                                        </Grid>:null}
                                    </Grid>
                                    </Paper>
                                    <br></br>
                                    <TableContainer style={StyledTableBorder} component={Paper}>
                                        <Typography style={{fontWeight:"bold"}} variant="h5" gutterBottom component="div">Products</Typography>
                                        <FormLabel style={{fontWeight:'bold'}} component="legend">Location</FormLabel>
                                            <RadioGroup row defaultValue={this.state.copyedit.location} name='location'>
                                            {window.location.href.includes("sil_ed") ? 
                                                        <FormControlLabel value="SIL ED Warehouse" control={<Radio disabled/>} label="SIL Ed Warehouse"/>
                                                        :
                                                        <FormControlLabel value="SIL BRU Warehouse" control={<Radio disabled/>} label="SIL Bru Warehouse"/>}
                                                <FormControlLabel value="Bulk" control={<Radio disabled/>} label="Bulk"/>
                                            </RadioGroup>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                        <TableRow>
                                            <TableCell style={StyledTableHeader}>Product</TableCell>
                                            <TableCell style={StyledTableHeader}>Size</TableCell>
                                            {this.state.copyedit.location.includes("Warehouse") ?
                                            <>
                                            <TableCell style={StyledTableHeader}>Per</TableCell>
                                            <TableCell style={StyledTableHeader}># of Pallets</TableCell>
                                            </>
                                            :   <TableCell style={StyledTableHeader}>Requested Quantity(MT)</TableCell>}
                                            <TableCell style={StyledTableHeader}>Product Code</TableCell>
                                            {this.state.copyedit.location.includes("Warehouse") ?
                                            <>
                                            <TableCell style={StyledTableHeader}>C&W</TableCell>
                                            <TableCell style={StyledTableHeader}>Double C&W</TableCell>
                                            <TableCell style={StyledTableHeader}>Actual Quantity</TableCell>
                                            </>
                                            :<TableCell style={StyledTableHeader}>Actual Quantity(MT)</TableCell>}
                                               <TableCell style={StyledTableHeader}>Batch #</TableCell>
                                            {/* <TableCell style={StyledTableHeader}>Status</TableCell> */}

                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.copyedit.product_Order.map((product:any, index:number) => {
                                                var isProductReady = false;
                                                if(product.status === 'Ready for Pickup' || this.state.copyedit.status === 'Ready for pickup'){
                                                    isProductReady = true;
                                                }
                                                return  <StyledTableRow>
                                                        <TableCell>{product.product}</TableCell>
                                                        <TableCell>{product.size}</TableCell>
                                                        {this.state.copyedit.location.includes("Warehouse") ?
                                                        <TableCell>{product.per}</TableCell>
                                                        :null}
                                                        <TableCell><TextField disabled variant="outlined" name="prod_quantity"  defaultValue={product.quantity}></TextField></TableCell>
                                                        <TableCell><TextField disabled variant="outlined" name="prod_code"defaultValue={product.code}></TextField></TableCell>
                                                        {this.state.copyedit.location.includes("Warehouse") ?
                                                        <>
                                                        <TableCell><Checkbox disabled name="prod_cw"  checked={product.cw ==="Yes"}></Checkbox></TableCell>
                                                        <TableCell><Checkbox disabled name="prod_double_CW"  checked={product.double_CW ==="Yes"}></Checkbox></TableCell>
                                                        </>
                                                        :null}
                                                        {/* {this.state.copyedit.location.includes("Warehouse") ?
                                                        <TableCell><Checkbox disabled name="prod_double_CW"  checked={product.double_CW ==="Yes"}></Checkbox></TableCell>
                                                        :null} */}
                                                        <TableCell><TextField disabled name="prod_actual" variant="outlined"defaultValue={product.actual_Quantity}></TextField></TableCell>
                                                        <TableCell><TextField multiline disabled variant="outlined" defaultValue={product.batch}></TextField></TableCell>
                                                    </StyledTableRow>
                                            })}
                                        </TableBody>
                                        
                                        </Table>
                                    </TableContainer>
                                    <br></br>
                                    <Paper className="paper-card">
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                        <FaPencilAlt/><TextField disabled label="Comments" name="comments" fullWidth multiline rows={4} defaultValue={this.state.copyedit.comments} variant="outlined"></TextField>
                                        </Grid>
                                        
                                        <Grid item xs={3}>
                                            <FormControlLabel control={
                                                <Checkbox disabled checked={this.state.packagedCheck} name="packaged" color="primary"/>
                                                }               
                                                label={this.state.copyedit.location.includes("Warehouse") ? "Packaged":"In Bin"}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormControlLabel control={
                                                <Checkbox disabled checked={this.state.completedCheck}  name="completed" color="primary"/>
                                                } 
                                                label="Completed"
                                            />
                                        </Grid>
                                        {/* <Grid item xs={12}>
                                            <FormLabel component="legend">Cancelled</FormLabel>
                                                <RadioGroup aria-label="gender" name="cancelradio" defaultValue={this.state.copyedit.cancelled} onChange={this.handleEdit.bind(this, this.state.copyedit.id, null) }>
                                                    <FormControlLabel value="Yes" control={<Radio />} label="Yes"/>
                                                    <FormControlLabel value="No" control={<Radio />} label="No"/>
                                                </RadioGroup>
                                        </Grid> */}
                                        <Grid item xs={3}>
                                            <TextField disabled label="Truck #" name="truck_no" defaultValue={this.state.copyedit.truck_Number}></TextField>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField disabled label="Trailer #" name="trailer_no" defaultValue={this.state.copyedit.trailer_Number}></TextField>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField disabled autoComplete="off" label="Tare Weight (kg)" type="number" name="tare_weight" defaultValue={this.state.copyedit.tare_Weight}></TextField>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField disabled autoComplete="off" label="Gross Weight (kg)" type="number" name="gross_weight" defaultValue={this.state.copyedit.gross_Weight}></TextField>
                                        </Grid>
                                        <Grid item xs={3}>
                                        <TextField disabled label="Total Tonnage (tonnes)" value={this.state.copyedit.total_Tonnage ? this.state.copyedit.total_Tonnage: this.state.totalTonnage}></TextField>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel htmlFor="datetime-local">Time In</InputLabel>
                                            <TextField disabled type='datetime-local' autoComplete="nope" defaultValue={this.state.copyedit.time_In}></TextField>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel htmlFor="datetime-local">Time Out</InputLabel>
                                            <TextField disabled type='datetime-local' autoComplete="nope" defaultValue={this.state.copyedit.time_Out}></TextField>
                                        </Grid>
                                    
                                        </Grid>
                                    </Paper>
                                </Paper>
                         
                    <br></br>
                    {this.state.showError ? 
                        <Stack sx={{ width: '100%' }} spacing={2}>
                            <Alert variant="filled" severity="error" action={<IconButton color="inherit" onClick={() => {this.setState({showError:false})}}>X</IconButton>}>
                            {this.state.errorText}
                            </Alert><br></br>
                        </Stack>
                    :null}
                    <br></br>
                    <button onClick={this.cancelEdit.bind(this)} id="cancel-button">Close</button>
                    {this.state.showPopup? <Popup headerText="Leaving Page" text='Leave page?' savePopup = {this.handlePopupExit.bind(this)} closePopup={this.handlePopupCancel.bind(this)}/>:null}
                    </div>
                    </form> 
            </Paper>
            </Paper>
        );
        }
        else {
            return (null
            );
        }
    }
}