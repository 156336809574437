import { ICrane } from "../getCranes/getCranesConstaints";
import { IUpdateCraneRequest, IUPDATE_CRANE_REQUEST } from "./updateCraneConstaints";

export interface IUpdateCraneLoadAction {
    type: IUPDATE_CRANE_REQUEST.REQUEST;
    data: IUpdateCraneRequest
}
export const updateCraneLoadAction = (data: IUpdateCraneRequest): IUpdateCraneLoadAction => ({
    type: IUPDATE_CRANE_REQUEST.REQUEST,
    data
});

export interface IUpdateCraneSuccessAction {
    type: IUPDATE_CRANE_REQUEST.SUCCESS;
    data: ICrane;
}
export const updateCraneLoadSuccessAction = (data: ICrane): IUpdateCraneSuccessAction => ({
    type: IUPDATE_CRANE_REQUEST.SUCCESS,
    data
});

export interface IUpdateCraneLoadFailedAction {
    type: IUPDATE_CRANE_REQUEST.FAILED;
    message: string;
}
export const updateCraneLoadFailedAction = (message: string): IUpdateCraneLoadFailedAction => ({
    type: IUPDATE_CRANE_REQUEST.FAILED,
    message
});
