import { PropsenseAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../../server";
import { IGetJobsLoadAction, IGetJobsLoadFailedAction, IGetJobsSuccessAction } from "./getJobsActions";
import { IJob, IGET_JOBS_REQUEST } from "./getJobsConstants";

type Actions =
    | IGetJobsLoadAction
    | IGetJobsSuccessAction
    | IGetJobsLoadFailedAction
    | IFlushDataSuccessAction;

export const GetJobsReducer = (state: Server<PropsenseAPIResponse<IJob[]>> = notLoaded, action: Actions): Server<PropsenseAPIResponse<IJob[]>> => {
    switch (action.type) {
        case IGET_JOBS_REQUEST.REQUEST:
            return loading;

        case IGET_JOBS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_JOBS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};