import React from 'react';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import TextField from '@mui/material/TextField';
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import {currentUser} from '../../../../simosHome';

interface IProps {
    savePopup:Function;
    closePopup:Function;
    optionItems:any[];
    customerID:number;
}
interface IState {
    open:boolean;
    delivery:string;
    error:boolean;
    showError:boolean;
}

const BoxStyle:any = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    backgroundColor: 'rgb(241, 241, 241) !important',
    border: '2px solid #000',
    boxShadow: 24,
    padding: '4ch',
};


export default class CreateDeliveryAddressPopup extends React.Component<IProps, IState>{
    constructor(props:any){
        super(props);
        this.state = {open:true, delivery:'', error:false, showError:false}
    }
    async addDeliveryAddress(delivery_address: string) {
        var outputResponse = null;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type':'application/json'},
            body: JSON.stringify({
                request: {
                    Address:delivery_address,
                    Customer_ID: this.props.customerID,
                    Created_By:currentUser,
                    Modified_By:currentUser
                },
                token: '19515195-d571-44a3-b0db-637f5bf24f54'})
        }
        await fetch('https://api2.propsense.com/api/AddSIMOSDeliveryAddress', requestOptions)
        .then(async response => {
            outputResponse = response;
          // no error
        });
        return outputResponse;
    }
    async setClose(event:any){
        // If input field isnt empty 
        if(this.state.delivery != ""){
            // if item doesnt exist close popup and save
            if(!this.state.error){
                var res:any = await this.addDeliveryAddress(this.state.delivery);
                if(res.ok && res.status ===200){
                    alert("Item saved successfully");
                    this.setState({open:false});
                    this.props.savePopup(event);
                }
                else{
                    alert("API Error: Failed to add new delivery address")
                }
            }
        }
        else{
            //alert("Field cannot be empty");
            this.setState({showError:true});
        }
    }
    setCancel(event:any){
        this.props.closePopup(event)
    }
    onTextChange(event:any){
        var exists=false;
        switch(event.target.name){
            case 'delivery':
                this.props.optionItems.map((each: any) => {
                    // If item exists raise error
                    if(each.address === event.target.value){
                       exists = true;
                       this.setState({error:exists});
                    }
                });
                this.setState({delivery:event.target.value, error:exists});
                break;
        }
        
    }
    render() {
        return (<>
          <Modal id='warehouse-emp-modal'
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={this.state.open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={this.state.open}>
          <Box sx={BoxStyle}>
          {this.state.showError ? 
              <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert variant="filled" severity="error" action={<IconButton color="inherit" onClick={() => {this.setState({showError:false})}}>X</IconButton>}>
                  Delivery Address cannot be empty
                </Alert>
              </Stack>
            :null}
            <br></br>
            <Typography variant="h6" component="h2">
              Add new customer delivery address
            </Typography>
            <Grid container spacing={3} direction='row'>
                <Grid item xs={12}>
                    <TextField label="Delivery Address" helperText={this.state.error === true ? 'Delivery Address already exists':null} error={this.state.error===true} id={'outlined-error-helper-text'} name="delivery" onChange={this.onTextChange.bind(this)}></TextField>
                </Grid>
                <Grid item xs={6}>
            <Button variant="outlined" className="cancel-employee-save" onClick={this.setCancel.bind(this)}>Cancel Changes</Button>
            </Grid>
            <Grid item xs={6}>
            <Button variant="outlined" className="save-employee" onClick={this.setClose.bind(this)}>Save Info</Button>
            </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
        </>
        );
    }
}