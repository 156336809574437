import { IPassTokenWithRequest } from "../../dryPlant/token/getTokenConstants";

export enum IGET_CARRIER_FLEET_TRAILERS_REQUEST {
    REQUEST = "getCarrierFleetTrailers/GET_CARRIER_FLEET_TRAILERS_REQUEST",
    SUCCESS = "getCarrierFleetTrailers/GET_CARRIER_FLEET_TRAILERS_SUCCESS",
    FAILED = "getCarrierFleetTrailers/GET_CARRIER_FLEET_TRAILERS_FAILED"
};

export interface IGetCarrierFleetTrailersRequest extends IPassTokenWithRequest {

};

export interface ICarrierFleetTrailer {
    id: number;
    trailer_No: string;
    carrier: string;
    positive_Air_Shutoff: string;
    trailer_Registration_No: string;
    trailer_Registration_Expiry: string;
    trailer_Inspection_File_Name: string | null;
    trailer_Inspection_Date: string;
    active: string;
    inactive_Date: string;
    notes: string;
    created: string;
    created_By: string;
    modified: string;
    modified_By: string;
};