import { ISaveCarrierFormRequest, ISAVE_CARRIER_FORM_REQUEST } from "./saveCarrierFormConstaints";

export interface ISaveCarrierFormLoadAction {
    type: ISAVE_CARRIER_FORM_REQUEST.REQUEST;
    data: ISaveCarrierFormRequest
}
export const saveCarrierFormLoadAction = (data: ISaveCarrierFormRequest): ISaveCarrierFormLoadAction => ({
    type: ISAVE_CARRIER_FORM_REQUEST.REQUEST,
    data
});

export interface ISaveCarrierFormSuccessAction {
    type: ISAVE_CARRIER_FORM_REQUEST.SUCCESS;
    message: string;
}
export const saveCarrierFormLoadSuccessAction = (message: string): ISaveCarrierFormSuccessAction => ({
    type: ISAVE_CARRIER_FORM_REQUEST.SUCCESS,
    message
});
export interface ISaveCarrierFormLoadFailedAction {
    type: ISAVE_CARRIER_FORM_REQUEST.FAILED;
    message: string;
}
export const saveCarrierFormLoadFailedAction = (message: string): ISaveCarrierFormLoadFailedAction => ({
    type: ISAVE_CARRIER_FORM_REQUEST.FAILED,
    message
});
