import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import { ById, SilAPIResponse } from "../../../shared/publicInterfaces";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import { callRouteWithQueryString, userName } from "../../../shared/constExports";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { LAButton } from "../../../shared/buttons";
import { AddIcon } from "../../../shared/icons";
import { WHITE_COLOR } from "../../../shared/theme";
import { ROUTE } from "../../../routes";
import PageSpacing from "../../../shared/pageSpacing";
import SilRoleCheck from "../../../shared/silRoleCheck";
import { IGetSieveChecksRequest, ISieveCheck } from "../../../../redux/sil/sieves/getSievesChecks/getSievesChecksConstaints";
import { getSieveChecksLoadAction } from "../../../../redux/sil/sieves/getSievesChecks/getSievesChecksActions";
import { getSieveChecksStatus } from "../../../../redux/sil/sieves/getSievesChecks/getSievesChecksAccessor";
import LALoading from "../../../shared/loading";


interface ISievesChecksStoreProps {
    getToken: Server<SilAPIResponse<IToken>>;
    getSievesChecks: Server<SilAPIResponse<ById<ISieveCheck>>>;
};

interface ISievesChecksDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getSievesChecksRequest: (data: IGetSieveChecksRequest) => unknown;
};


interface ISievesChecksOwnProps {

};

interface ISievesChecksState {
    data: ISieveCheck[];
};

const SievesChecksStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
    };
`;

type ISievesChecksProps = RouteComponentProps
    & ISievesChecksStoreProps
    & ISievesChecksDispatchProps
    & ISievesChecksOwnProps;

class SievesChecks extends PureComponent<ISievesChecksProps, ISievesChecksState> {

    public constructor(props: ISievesChecksProps) {
        super(props);
        this.state = {
            data: []
        };
    }

    public componentDidMount(): void {
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: ISievesChecksProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();
        };
    };


    public render(): ReactNode {

        const { data } = this.state;
        const { getSievesChecks } = this.props;

        return (
            <PageSpacing title="SIL - Sieves Checks" description="SIL - Sieves Checks" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="sieveAccess">
                    <SievesChecksStyles>

                        {getSievesChecks.kind !== STATUS_ENUM.LOADING &&
                            <SilRoleCheck error={false} roleFor="sieveAccess">
                                <LAButton className="pull-left" startIcon={<AddIcon color={WHITE_COLOR} />} label="Add" onClick={this.handleAdd} />
                            </SilRoleCheck>}

                        <h2 className="text-center">CHECKS</h2>
                        <hr />

                        {getSievesChecks.kind === STATUS_ENUM.LOADING &&
                            <LALoading className="loading" message="Loading..." />}

                        {getSievesChecks.kind !== STATUS_ENUM.LOADING && <LADevExtremeGrid
                            data={data}
                            onEdit={this.onEdit}
                            onClick={this.onClick}
                            height={1000}
                            searchPanel={true}
                            filterHeader={true}
                            export={true}
                            exportFileName="SievesChecks"
                            storageKey="silSievesChecksStorageKey"
                            columns={[
                                { name: "date", caption: "Date", type: "date", sortAsc: true },
                                { name: "operator", caption: "Operator", type: "string" },
                                { name: "products", caption: "Products", type: "string" },
                                { name: "plant", caption: "Plant", type: "string" },
                                { name: "notes", caption: "Notes", type: "string" },
                                { name: "created", caption: "Created", type: "date" },
                                { name: "created_By", caption: "Created By", type: "string" }
                            ]}
                        />}
                    </SievesChecksStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString() },
            pathName: ROUTE.SIL.SIEVES.SIEVES_CHECK
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0" },
            pathName: ROUTE.SIL.SIEVES.SIEVES_CHECK
        });
    };

    private setDataToState = (): void => {

        if (hasPayload(this.props.getSievesChecks)) {
            const data: ISieveCheck[] = Object.values(this.props.getSievesChecks.payload.response);
            this.setState({ data });
        };

        if (isNotLoaded(this.props.getSievesChecks))
            this.callServer();
    };

    private callServer = (): void => {
        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getSievesChecks))
            this.props.getSievesChecksRequest({
                token: this.props.getToken.payload.response.token
            });


        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });
    }

}

const mapStateToProps = (state: IStore): ISievesChecksStoreProps => ({
    getToken: getTokenStatus(state),
    getSievesChecks: getSieveChecksStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): ISievesChecksDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getSievesChecksRequest: (data: IGetSieveChecksRequest) => dispatch(getSieveChecksLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(SievesChecks);