import { IGET_WAREHOUSE_PRODUCTS_REQUEST, IWarehouseProducts } from "./getWarehouseProductsConstaints";
import { IGetWarehouseProductsLoadAction, IGetWarehouseProductsLoadFailedAction, IGetWarehouseProductsSuccessAction } from "./getWarehouseProductsActions";
import { Server, failed, loading, notLoaded, succeeded } from "../../../../server";
import { SilAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../../flushData.tsx/flushDataConstants";
import { IAddWarehouseProductSuccessAction } from "../addWarehouseProduct/addWarehouseProductActions";
import { IUpdateWarehouseProductSuccessAction } from "../updateWarehouseProduct/updateWarehouseProductActions";
import { IUPDATE_WAREHOUSE_PRODUCT_REQUEST } from "../updateWarehouseProduct/updateWarehouseProductConstaints";
import { IADD_WAREHOUSE_PRODUCT_REQUEST } from "../addWarehouseProduct/addWarehouseProductConstaints";

type Actions =
    IGetWarehouseProductsLoadAction
    | IGetWarehouseProductsLoadFailedAction
    | IGetWarehouseProductsSuccessAction
    | IAddWarehouseProductSuccessAction
    | IUpdateWarehouseProductSuccessAction
    | IFlushDataSuccessAction;

export const GetWarehouseProductsReducer = (state: Server<SilAPIResponse<IWarehouseProducts[]>> = notLoaded, action: Actions): Server<SilAPIResponse<IWarehouseProducts[]>> => {
    switch (action.type) {
        case IGET_WAREHOUSE_PRODUCTS_REQUEST.REQUEST:
            return loading;

        case IGET_WAREHOUSE_PRODUCTS_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_WAREHOUSE_PRODUCTS_REQUEST.FAILED:
            return failed(action.message);

        case IADD_WAREHOUSE_PRODUCT_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_WAREHOUSE_PRODUCT_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};