import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../../shared/paper";
import { IDispatch, IStore } from "../../../../../redux/reducers";
import { hasPayload, isNotLoaded, Server } from "../../../../../redux/server";
import { YHDAPIResponse } from "../../../../shared/publicInterfaces";
import { IGetTokenRequest, IToken } from "../../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenLoadAction } from "../../../../../redux/sil/dryPlant/token/getTokenActions";
import { callRouteWithQueryString, userName } from "../../../../shared/constExports";
import { LADevExtremeGrid } from "../../../../shared/devExtreme";
import { getTokenStatus } from "../../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { LAButton } from "../../../../shared/buttons";
import { AddIcon } from "../../../../shared/icons";
import { WHITE_COLOR } from "../../../../shared/theme";
import { ROUTE } from "../../../../routes";
import PageSpacing from "../../../../shared/pageSpacing";
import { getYHDCarrierEmailsStatus } from "../../../../../redux/propsense/yhd/carrier/getCarrierEmails/getCarrierEmailsAccessor";
import { getYHDCarrierEmailsLoadAction } from "../../../../../redux/propsense/yhd/carrier/getCarrierEmails/getCarrierEmailsActions";
import { IYHDCarrierEmail, IGetYHDCarrierEmailsRequest } from "../../../../../redux/propsense/yhd/carrier/getCarrierEmails/getCarrierEmailsConstaints";
import { IYHDCarrierLookup, IGetYHDCarrierLookupsRequest } from "../../../../../redux/propsense/yhd/carrier/getCarrierLookups/getCarrierLookupsConstaints";
import { getYHDCarrierLookupsStatus } from "../../../../../redux/propsense/yhd/carrier/getCarrierLookups/getCarrierLookupsAccessor";
import { getYHDCarrierLookupsLoadAction } from "../../../../../redux/propsense/yhd/carrier/getCarrierLookups/getCarrierLookupsActions";
import SilRoleCheck from "../../../../shared/silRoleCheck";


interface ICarrierEmailsStoreProps {
    getToken: Server<YHDAPIResponse<IToken>>;
    getCarrierEmails: Server<YHDAPIResponse<IYHDCarrierEmail[]>>;
    getCarrierLookups: Server<YHDAPIResponse<IYHDCarrierLookup[]>>;
};

interface ICarrierEmailsDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getCarrierEmailsRequest: (data: IGetYHDCarrierEmailsRequest) => unknown;
    getCarrierLookupsRequest: (data: IGetYHDCarrierLookupsRequest) => unknown;
};


interface ICarrierEmailsOwnProps {

};

interface ICarrierEmailsState {
    data: IYHDCarrierEmail[];
};

const CarrierEmailsStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
    };
`;

type ICarrierEmailsProps = RouteComponentProps
    & ICarrierEmailsStoreProps
    & ICarrierEmailsDispatchProps
    & ICarrierEmailsOwnProps;

class YHDCarrierEmails extends PureComponent<ICarrierEmailsProps, ICarrierEmailsState> {

    public constructor(props: ICarrierEmailsProps) {
        super(props);
        this.state = {
            data: []
        };
    }

    public componentDidMount(): void {
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: ICarrierEmailsProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();
        };
    };


    public render(): ReactNode {

        const { data } = this.state;

        return (
            <PageSpacing title="YHD - Carrier Emails" description="YHD - Carrier Emails" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="yhdCarrierAccess">
                    <CarrierEmailsStyles>

                        <LAButton className="pull-left" startIcon={<AddIcon color={WHITE_COLOR} />} label="Add" onClick={this.handleAdd} />
                        <h2 className="text-center">CARRIER EMAILS</h2>
                        <hr />

                        <LADevExtremeGrid
                            data={data}
                            export={true}
                            buttonLabel="View"
                            searchPanel={true}
                            actionWidth={100}
                            filterHeader={true}
                            onEdit={this.onEdit}
                            onClick={this.onClick}
                            storageKey="yhdCarrieEmailsStorageKey"
                            exportFileName={"Carrier Emails"}
                            columns={[
                                { name: "subject", caption: "Subject", type: "string", width: 100 },
                                { name: "email_All_Carriers", caption: "Email All Carriers", type: "string", width: 100  },
                                { name: "carriers", caption: "Carriers", type: "string" },
                                { name: "email_Body", caption: "Body", type: "string" },                                
                                { name: "modified_By", caption: "Modified By", type: "string", width: 100  },
                                { name: "modified", caption: "Modified", type: "datetime" , width: 100, sortDesc: true },
                            ]}
                        />
                    </CarrierEmailsStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString() },
            pathName: ROUTE.PROPSENSE.YHD.CARRIERS.CARRIER_EMAIL
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0" },
            pathName: ROUTE.PROPSENSE.YHD.CARRIERS.CARRIER_EMAIL
        });
    };

    private setDataToState = (): void => {

        if (hasPayload(this.props.getCarrierEmails)) {
            const val: IYHDCarrierEmail[] = Object.values(this.props.getCarrierEmails.payload.response);
            this.setState({ data: val });
        };

        if (isNotLoaded(this.props.getCarrierEmails))
            this.callServer();
    };

    private callServer = (): void => {
        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getCarrierEmails))
            this.props.getCarrierEmailsRequest({
                token: this.props.getToken.payload.response.token
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getCarrierLookups))
            this.props.getCarrierLookupsRequest({
                token: this.props.getToken.payload.response.token,
                requested_Page: "Carriers_Email"
            });


        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });
    }

}

const mapStateToProps = (state: IStore): ICarrierEmailsStoreProps => ({
    getToken: getTokenStatus(state),
    getCarrierEmails: getYHDCarrierEmailsStatus(state),
    getCarrierLookups: getYHDCarrierLookupsStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): ICarrierEmailsDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getCarrierEmailsRequest: (data: IGetYHDCarrierEmailsRequest) => dispatch(getYHDCarrierEmailsLoadAction(data)),
    getCarrierLookupsRequest: (data: IGetYHDCarrierLookupsRequest) => dispatch(getYHDCarrierLookupsLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(YHDCarrierEmails);