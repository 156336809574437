import { IFeedMaterials } from "../getFeedMaterials/getFeedMaterialsConstaints";

export enum IUPDATE_FEED_MATERIAL_REQUEST {
    REQUEST = "updateFeedMaterial/UPDATE_FEED_MATERIAL_REQUEST",
    SUCCESS = "updateFeedMaterial/UPDATE_FEED_MATERIAL_SUCCESS",
    FAILED = "updateFeedMaterial/UPDATE_FEED_MATERIAL_FAILED"
};

export interface IUpdateFeedMaterialRequest {
    token: string;
    request: IFeedMaterials;
};