import { SilAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../../flushData.tsx/flushDataConstants";
import { Server, failed, loading, notLoaded, succeeded } from "../../../../server";
import { IAddBeltScaleSuccessAction } from "../addBeltScale/addBeltScaleActions";
import { IADD_BELT_SCALE_REQUEST } from "../addBeltScale/addBeltScaleConstaints";
import { IUpdateBeltScaleSuccessAction } from "../updateBeltScale/updateBeltScaleActions";
import { IUPDATE_BELT_SCALE_REQUEST } from "../updateBeltScale/updateBeltScaleConstaints";
import { IGetBeltScaleLoadAction, IGetBeltScaleLoadFailedAction, IGetBeltScaleSuccessAction } from "./getBeltScalesActions";
import { IBeltScale, IGET_BELT_SCALES_REQUEST } from "./getBeltScalesConstaints";

type Actions =
    IGetBeltScaleLoadAction
    | IGetBeltScaleLoadFailedAction
    | IGetBeltScaleSuccessAction
    | IAddBeltScaleSuccessAction
    | IUpdateBeltScaleSuccessAction
    | IFlushDataSuccessAction;

export const GetBeltScaleReducer = (state: Server<SilAPIResponse<IBeltScale[]>> = notLoaded, action: Actions): Server<SilAPIResponse<IBeltScale[]>> => {
    switch (action.type) {
        case IGET_BELT_SCALES_REQUEST.REQUEST:
            return loading;

        case IGET_BELT_SCALES_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_BELT_SCALES_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_BELT_SCALE_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_BELT_SCALE_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};