import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IUpdateRunMaterialLoadAction, IUpdateRunMaterialLoadFailedAction, IUpdateRunMaterialSuccessAction } from "./updateRunMaterialActions";
import { IUPDATE_RUN_MATERIAL_REQUEST } from "./updateRunMaterialConstaints";

type Actions =
    IUpdateRunMaterialLoadAction
    | IUpdateRunMaterialLoadFailedAction
    | IUpdateRunMaterialSuccessAction
    | IFlushDataSuccessAction;

export const UpdateRunMaterialReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IUPDATE_RUN_MATERIAL_REQUEST.REQUEST:
            return loading;

        case IUPDATE_RUN_MATERIAL_REQUEST.SUCCESS:
            return succeeded("Success");

        case IUPDATE_RUN_MATERIAL_REQUEST.FAILED:
            return failed(action.message);

            case FLUSH_DATA.SUCCESS:
                return notLoaded;

        default:
            return state;
    }
};