import { IPassTokenWithRequest } from "../../dryPlant/token/getTokenConstants";

export enum IADD_SIEVE_CHECK_REQUEST {
    REQUEST = "addSieveCheck/ADD_SIEVE_CHECK_REQUEST",
    SUCCESS = "addSieveCheck/ADD_SIEVE_CHECK_SUCCESS",
    FAILED = "addSieveCheck/ADD_SIEVE_CHECK_FAILED"
};

export interface IAddSieveCheckRequest extends IPassTokenWithRequest {
    request: IAddSieveCheck;
};

export interface IAddSieveCheck {
    ID: number;
    Date: string;
    Operator?: string;
    Plant: string;
    Products: string;
    Notes?: string;
    Created_By: string;
    Modified_By: string;
    Measurements: IAddSieveCheckMeasurementRequest[];
};

export interface IAddSieveCheckMeasurementRequest {
    ID: number;
    Sieve_Serial_No: string;
    ASTM_Serial_No: string;
    Product: string;
    Result: string;
    Notes: string;
};