import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IGetWashPlantRunMaterialsLoadAction, IGetWashPlantRunMaterialsLoadFailedAction, IGetWashPlantRunMaterialsSuccessAction, 
    getWashPlantRunMaterialsLoadFailedAction, getWashPlantRunMaterialsLoadSuccessAction
} from "./getWashPlantRunMaterialsActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IGET_WASH_PLANT_RUN_MATERIALS_REQUEST, IWashPlantRunMaterials } from "./getWashPlantRunMaterialsConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";

export const getWashPlantRunMaterialsEpic: Epic = (
    action$: ActionsObservable<IGetWashPlantRunMaterialsLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IGetWashPlantRunMaterialsSuccessAction | IGetWashPlantRunMaterialsLoadFailedAction> =>
    action$.ofType(IGET_WASH_PLANT_RUN_MATERIALS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<IWashPlantRunMaterials[]>>(
                    END_POINTS.PROPSENSE.SIL.WASH_PLANT.GET_WASH_PLANT_RUN_MATERIALS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<IWashPlantRunMaterials[]>): IGetWashPlantRunMaterialsSuccessAction => {
                            return getWashPlantRunMaterialsLoadSuccessAction(response);
                        }),
                        catchError(() => ActionsObservable.of(getWashPlantRunMaterialsLoadFailedAction("Unable to get run materials")))
                    )
            )
        );