import { IUpdateSieveCalibrationRequest, IUPDATE_SIEVE_CALIBRATION_REQUEST } from "./updateSieveCalibrationConstaints";

export interface IUpdateSieveCalibrationLoadAction {
    type: IUPDATE_SIEVE_CALIBRATION_REQUEST.REQUEST;
    data: IUpdateSieveCalibrationRequest
}
export const updateSieveCalibrationLoadAction = (data: IUpdateSieveCalibrationRequest): IUpdateSieveCalibrationLoadAction => ({
    type: IUPDATE_SIEVE_CALIBRATION_REQUEST.REQUEST,
    data
});

export interface IUpdateSieveCalibrationSuccessAction {
    type: IUPDATE_SIEVE_CALIBRATION_REQUEST.SUCCESS;
    message: string;
}
export const updateSieveCalibrationLoadSuccessAction = (message: string): IUpdateSieveCalibrationSuccessAction => ({
    type: IUPDATE_SIEVE_CALIBRATION_REQUEST.SUCCESS,
    message
});

export interface IUpdateSieveCalibrationLoadFailedAction {
    type: IUPDATE_SIEVE_CALIBRATION_REQUEST.FAILED;
    message: string;
}
export const updateSieveCalibrationLoadFailedAction = (message: string): IUpdateSieveCalibrationLoadFailedAction => ({
    type: IUPDATE_SIEVE_CALIBRATION_REQUEST.FAILED,
    message
});
