import { SilAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { IGetTruckScaleRequest, IGET_TRUCK_SCALES_REQUEST, ITruckScale } from "./getTruckScalesConstaints";

export interface IGetTruckScaleLoadAction {
    type: IGET_TRUCK_SCALES_REQUEST.REQUEST;
    data: IGetTruckScaleRequest
}
export const getTruckScaleLoadAction = (data: IGetTruckScaleRequest): IGetTruckScaleLoadAction => ({
    type: IGET_TRUCK_SCALES_REQUEST.REQUEST,
    data
});

export interface IGetTruckScaleSuccessAction {
    type: IGET_TRUCK_SCALES_REQUEST.SUCCESS;
    data: SilAPIResponse<ITruckScale[]>;
}
export const getTruckScaleLoadSuccessAction = (data: SilAPIResponse<ITruckScale[]>): IGetTruckScaleSuccessAction => ({
    type: IGET_TRUCK_SCALES_REQUEST.SUCCESS,
    data
});

export interface IGetTruckScaleLoadFailedAction {
    type: IGET_TRUCK_SCALES_REQUEST.FAILED;
    message: string;
}
export const getTruckScaleLoadFailedAction = (message: string): IGetTruckScaleLoadFailedAction => ({
    type: IGET_TRUCK_SCALES_REQUEST.FAILED,
    message
});
