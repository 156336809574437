import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IGetBeltScaleLoadAction, IGetBeltScaleLoadFailedAction, IGetBeltScaleSuccessAction, 
    getBeltScaleLoadFailedAction, getBeltScaleLoadSuccessAction
} from "./getBeltScalesActions";
import { IGET_BELT_SCALES_REQUEST, IBeltScale } from "./getBeltScalesConstaints";
import { IStore } from "../../../../reducers";
import { EpicDependencies } from "../../../../store";
import { SilAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { END_POINTS } from "../../../../endpoints";
import { withJsonContentType } from "../../../../epicUtils";

export const getBeltScaleEpic: Epic = (
    action$: ActionsObservable<IGetBeltScaleLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IGetBeltScaleSuccessAction | IGetBeltScaleLoadFailedAction> =>
    action$.ofType(IGET_BELT_SCALES_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<IBeltScale[]>>(
                    END_POINTS.PROPSENSE.SIL.CV.GetCVBeltScales,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<IBeltScale[]>): IGetBeltScaleSuccessAction => {
                            return getBeltScaleLoadSuccessAction(response);
                        }),
                        catchError(() => ActionsObservable.of(getBeltScaleLoadFailedAction("Unable to get belt scales")))
                    )
            )
        );