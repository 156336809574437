import { INCR } from "../getNCRs/getNCRsConstaints";

export enum IADD_NCR_REQUEST {
    REQUEST = "addNCR/ADD_NCR_REQUEST",
    SUCCESS = "addNCR/ADD_NCR_SUCCESS",
    FAILED = "addNCR/ADD_NCR_FAILED"
};

export interface IAddNCRRequest {
    token: string;
    request: INCR;
};