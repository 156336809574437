import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import { ById, SilAPIResponse } from "../../../shared/publicInterfaces";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import { callRouteWithQueryString, userName } from "../../../shared/constExports";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { LAButton } from "../../../shared/buttons";
import { AddIcon } from "../../../shared/icons";
import { WHITE_COLOR } from "../../../shared/theme";
import { ROUTE } from "../../../routes";
import PageSpacing from "../../../shared/pageSpacing";
import SilRoleCheck from "../../../shared/silRoleCheck";
import LALoading from "../../../shared/loading";
import { IGetWarehouseProductsRequest, IWarehouseProducts } from "../../../../redux/sil/warehouse/warehouseProducts/getWarehouseProducts/getWarehouseProductsConstaints";
import { getWarehouseProductsStatus } from "../../../../redux/sil/warehouse/warehouseProducts/getWarehouseProducts/getWarehouseProductsAccessor";
import { getWarehouseProductsLoadAction } from "../../../../redux/sil/warehouse/warehouseProducts/getWarehouseProducts/getWarehouseProductsActions";

interface IWarehouseProductsStoreProps {
    getToken: Server<SilAPIResponse<IToken>>;
    getWarehouseProductsList: Server<SilAPIResponse<IWarehouseProducts[]>>;
};

interface IWarehouseProductsDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getWarehouseProductsListRequest: (data: IGetWarehouseProductsRequest) => unknown;
};


interface IWarehouseProductsOwnProps {

};

interface IWarehouseProductsState {
    data: IWarehouseProducts[];
};

const WarehouseProductsStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .loading {
        margin-top: 5%;
    };

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
    };
`;

type IWarehouseProductsProps = RouteComponentProps
    & IWarehouseProductsStoreProps
    & IWarehouseProductsDispatchProps
    & IWarehouseProductsOwnProps;

class WarehouseProducts extends PureComponent<IWarehouseProductsProps, IWarehouseProductsState> {

    public constructor(props: IWarehouseProductsProps) {
        super(props);
        this.state = {
            data: []
        };
    }

    public componentDidMount(): void {
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IWarehouseProductsProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();
        };
    };


    public render(): ReactNode {

        const { data } = this.state;
        const { getWarehouseProductsList } = this.props;

        return (
            <PageSpacing title="SIL - Warehouse Products" description="SIL - Warehouse Products" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="warehouseAccess">
                    <WarehouseProductsStyles>

                        {getWarehouseProductsList.kind !== STATUS_ENUM.LOADING &&
                            <LAButton className="pull-left" startIcon={<AddIcon color={WHITE_COLOR} />} label="Add" onClick={this.handleAdd} />}
                        <h2 className="text-center">Warehouse Products</h2>
                        <hr />

                        {getWarehouseProductsList.kind === STATUS_ENUM.LOADING &&
                            <LALoading className="loading" message="Loading..." />}

                        {getWarehouseProductsList.kind !== STATUS_ENUM.LOADING && <LADevExtremeGrid
                            data={data}
                            onEdit={this.onEdit}
                            onClick={this.onClick}
                            height={1000}
                            searchPanel={true}
                            filterHeader={true}
                            export={true}
                            exportFileName="WarehouseProducts"
                            storageKey="silWarehouseProductsStorageKey"
                            columns={[                               
                                { name: "product", caption: "Product", type: "string" },
                                { name: "description", caption: "Description", type: "string" },
                                { name: "unit_Conversion", caption: "Unit_Conversion", type: "number" },
                                { name: "depletes_Bulk_Code_1", caption: "Depletes_Bulk_Code_1", type: "string" },
                                { name: "yield_1", caption: "Yield_1", type: "number" },
                                { name: "depletes_Bulk_Code_2", caption: "Depletes_Bulk_Code_2", type: "string" },                               
                                { name: "yield_2", caption: "Yield_2", type: "number" },
                                { name: "consumables_Depletion_Product", caption: "Consumables_Depletion_Product", type: "string" },
                                { name: "bags", caption: "Bags", type: "number" },
                                { name: "bag_Size", caption: "Bag_Size", type: "string" },
                                { name: "bag_Depletion", caption: "Bag_Depletion", type: "number" },
                                { name: "created", caption: "Created", type: "datetime" },
                                { name: "created_By", caption: "Created By", type: "string" },
                                { name: "modified", caption: "Modified", type: "datetime" },
                                { name: "modified_By", caption: "Modified By", type: "string" }
                            ]}
                        />}

                    </WarehouseProductsStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString() },
            pathName: ROUTE.SIL.WAREHOUSE_PRODUCTS.PRODUCT
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0" },
            pathName: ROUTE.SIL.WAREHOUSE_PRODUCTS.PRODUCT
        });
    };

    private setDataToState = (): void => {

        if (hasPayload(this.props.getWarehouseProductsList)) {
            const data: IWarehouseProducts[] = Object.values(this.props.getWarehouseProductsList.payload.response);
            this.setState({ data });
        };

        if (isNotLoaded(this.props.getWarehouseProductsList))
            this.callServer();
    };

    private callServer = (): void => {
        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getWarehouseProductsList))
            this.props.getWarehouseProductsListRequest({
                token: this.props.getToken.payload.response.token
            });


        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });
    }

}

const mapStateToProps = (state: IStore): IWarehouseProductsStoreProps => ({
    getToken: getTokenStatus(state),
    getWarehouseProductsList: getWarehouseProductsStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IWarehouseProductsDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getWarehouseProductsListRequest: (data: IGetWarehouseProductsRequest) => dispatch(getWarehouseProductsLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(WarehouseProducts);