import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IWashPlantProductionLookups } from "../getWashPlantProductionLookups/getWashPlantProductionLookupsConstaints";
import { IGetWashPlantProductLookupsLoadAction, IGetWashPlantProductLookupsLoadFailedAction, IGetWashPlantProductLookupsSuccessAction } from "./getWashPlantProductLookupsActions";
import { IGET_WASH_PLANT_PRODUCT_LOOKUPS_REQUEST } from "./getWashPlantProductLookupsConstaints";

type Actions =
    IGetWashPlantProductLookupsLoadAction
    | IGetWashPlantProductLookupsLoadFailedAction
    | IGetWashPlantProductLookupsSuccessAction
    | IFlushDataSuccessAction;

export const GetWashPlantProductLookupsReducer = (state: Server<SilAPIResponse<IWashPlantProductionLookups>> = notLoaded, action: Actions): Server<SilAPIResponse<IWashPlantProductionLookups>> => {
    switch (action.type) {
        case IGET_WASH_PLANT_PRODUCT_LOOKUPS_REQUEST.REQUEST:
            return loading;

        case IGET_WASH_PLANT_PRODUCT_LOOKUPS_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_WASH_PLANT_PRODUCT_LOOKUPS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};