import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetCarrierInfractionsRequest, IGET_CARRIER_INFRACTIONS_REQUEST, ICarrierInfraction } from "./getCarrierInfractionsConstaints";

export interface IGetCarrierInfractionsLoadAction {
    type: IGET_CARRIER_INFRACTIONS_REQUEST.REQUEST;
    data: IGetCarrierInfractionsRequest
}
export const getCarrierInfractionsLoadAction = (data: IGetCarrierInfractionsRequest): IGetCarrierInfractionsLoadAction => ({
    type: IGET_CARRIER_INFRACTIONS_REQUEST.REQUEST,
    data
});

export interface IGetCarrierInfractionsSuccessAction {
    type: IGET_CARRIER_INFRACTIONS_REQUEST.SUCCESS;
    data: SilAPIResponse<ICarrierInfraction[]>;
}
export const getCarrierInfractionsLoadSuccessAction = (data: SilAPIResponse<ICarrierInfraction[]>): IGetCarrierInfractionsSuccessAction => ({
    type: IGET_CARRIER_INFRACTIONS_REQUEST.SUCCESS,
    data
});

export interface IGetCarrierInfractionsLoadFailedAction {
    type: IGET_CARRIER_INFRACTIONS_REQUEST.FAILED;
    message: string;
}
export const getCarrierInfractionsLoadFailedAction = (message: string): IGetCarrierInfractionsLoadFailedAction => ({
    type: IGET_CARRIER_INFRACTIONS_REQUEST.FAILED,
    message
});
