import { ById, SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, hasPayload, loading, notLoaded, Server, succeeded } from "../../../server";
import { IAddProductionSuccessAction } from "../addProduction/addProductionActions";
import { IADD_PRODUCTION_REQUEST } from "../addProduction/addProductionConstaints";
import { IUpdateProductionSuccessAction } from "../updateProduction/updateProductionActions";
import { IUPDATE_PRODUCTION_REQUEST } from "../updateProduction/updateProductionConstaints";
import { IGetProductionRecordsLoadAction, IGetProductionRecordsLoadFailedAction, IGetProductionRecordsSuccessAction } from "./getProductionRecordsActions";
import { IGET_PRODUCTION_RECORDS_REQUEST, IProductionRecords } from "./getProductionRecordsConstaints";

type Actions =
    IGetProductionRecordsLoadAction
    | IGetProductionRecordsLoadFailedAction
    | IGetProductionRecordsSuccessAction
    | IUpdateProductionSuccessAction
    | IAddProductionSuccessAction
    | IFlushDataSuccessAction;

export const GetProductionRecordsReducer = (state: Server<SilAPIResponse<ById<IProductionRecords>>> = notLoaded, action: Actions): Server<SilAPIResponse<ById<IProductionRecords>>> => {
    switch (action.type) {
        case IGET_PRODUCTION_RECORDS_REQUEST.REQUEST:
            return loading;

        case IGET_PRODUCTION_RECORDS_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_PRODUCTION_RECORDS_REQUEST.FAILED:
            return failed(action.message);

        case IADD_PRODUCTION_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_PRODUCTION_REQUEST.SUCCESS:
            if (hasPayload(state) && (action.data.id > 0)) {
                console.log(action.data)
                const currentState = { ...state.payload.response };
                currentState[action.data.id] = action.data;

                return {
                    ...state,
                    payload: {
                        ...state.payload,
                        response: currentState
                    }
                };
            }
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};