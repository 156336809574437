import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IGET_SIMOS_ORDER_PRODUCT_VERSION_HISTORY_REQUEST, ISIMOSOrderProductVersionHistory } from "./getSIMOSOrderProductVersionHistoryConstaints";
import { IGetSIMOSOrderProductVersionHistoryLoadAction, IGetSIMOSOrderProductVersionHistoryLoadFailedAction, IGetSIMOSOrderProductVersionHistorySuccessAction } from "./getSIMOSOrderProductVersionHistoryActions";
import { SimosAPIResponse } from "../../../../react/shared/publicInterfaces";

type Actions =
    IGetSIMOSOrderProductVersionHistoryLoadAction
    | IGetSIMOSOrderProductVersionHistoryLoadFailedAction
    | IGetSIMOSOrderProductVersionHistorySuccessAction
    | IFlushDataSuccessAction;

export const GetSIMOSOrderProductVersionHistoryReducer = (state: Server<SimosAPIResponse<ISIMOSOrderProductVersionHistory[]>> = notLoaded, action: Actions): Server<SimosAPIResponse<ISIMOSOrderProductVersionHistory[]>> => {
    switch (action.type) {
        case IGET_SIMOS_ORDER_PRODUCT_VERSION_HISTORY_REQUEST.REQUEST:
            return loading;

        case IGET_SIMOS_ORDER_PRODUCT_VERSION_HISTORY_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_SIMOS_ORDER_PRODUCT_VERSION_HISTORY_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};