import { ById, SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetSieveProductsRequest, IGET_SIEVES_PRODUCTS_REQUEST, ISieveProduct } from "./getSievesProductsConstaints";

export interface IGetSieveProductsLoadAction {
    type: IGET_SIEVES_PRODUCTS_REQUEST.REQUEST;
    data: IGetSieveProductsRequest
}
export const getSieveProductsLoadAction = (data: IGetSieveProductsRequest): IGetSieveProductsLoadAction => ({
    type: IGET_SIEVES_PRODUCTS_REQUEST.REQUEST,
    data
});

export interface IGetSieveProductsSuccessAction {
    type: IGET_SIEVES_PRODUCTS_REQUEST.SUCCESS;
    data: SilAPIResponse<ById<ISieveProduct>>;
}
export const getSieveProductsLoadSuccessAction = (data: SilAPIResponse<ById<ISieveProduct>>): IGetSieveProductsSuccessAction => ({
    type: IGET_SIEVES_PRODUCTS_REQUEST.SUCCESS,
    data
});

export interface IGetSieveProductsLoadFailedAction {
    type: IGET_SIEVES_PRODUCTS_REQUEST.FAILED;
    message: string;
}
export const getSieveProductsLoadFailedAction = (message: string): IGetSieveProductsLoadFailedAction => ({
    type: IGET_SIEVES_PRODUCTS_REQUEST.FAILED,
    message
});
