import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../../shared/paper";
import { IDispatch, IStore } from "../../../../../redux/reducers";
import { hasPayload, isNotLoaded, isSucceeded, Server } from "../../../../../redux/server";
import { ById, SimosAPIResponse } from "../../../../shared/publicInterfaces";
import { userName, undefinedFunction, callRouteWithQueryString, NotApplicable, ReadOnly, } from "../../../../shared/constExports";
import { LAButton, LASaveAndCancelButton } from "../../../../shared/buttons";
import { ArrowLeftIcon } from "../../../../shared/icons";
import { WHITE_COLOR } from "../../../../shared/theme";
import LAGrid from "../../../../shared/grid";
import LAGridItem from "../../../../shared/gridList";
import LATextField from "../../../../shared/textField";
import { ROUTE } from "../../../../routes";
import queryString from "query-string";
import RequestStatus from "../../../../shared/requestStatusSnackbar";
import { FieldValidator, FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue, IFieldValidatorProps } from "../../../../shared/fieldValidator";
import PageSpacing from "../../../../shared/pageSpacing";
import { IGetTokenRequest, IToken } from "../../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenStatus } from "../../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { getTokenLoadAction } from "../../../../../redux/sil/dryPlant/token/getTokenActions";
import { ISimosCustomerContacts, ISimosCustomerContactsRequest } from "../../../../../redux/propsense/simos/simosCustomerContacts/getSimosCustomerContacts/getSimosCustomerContactsConstants";
import { IAddSimosCustomerContactRequest, IAddUpdateSimosCustomerContact } from "../../../../../redux/propsense/simos/simosCustomerContacts/addSimosCustomerContact/addSimosCustomerContactConstaints";
import { getSimosCustomerContacts } from "../../../../../redux/propsense/simos/simosCustomerContacts/getSimosCustomerContacts/getSimosCustomerContactsAccessor";
import { addSimosCustomerContactStatus } from "../../../../../redux/propsense/simos/simosCustomerContacts/addSimosCustomerContact/addSimosCustomerContactAccessor";
import { getSimosCustomerContactsLoadAction } from "../../../../../redux/propsense/simos/simosCustomerContacts/getSimosCustomerContacts/getSimosCustomerContactsActions";
import { addSimosCustomerContactLoadAction } from "../../../../../redux/propsense/simos/simosCustomerContacts/addSimosCustomerContact/addSimosCustomerContactActions";
import LAAutoComplete from "../../../../shared/autoComplete";
import { updateSimosCustomerContactStatus } from "../../../../../redux/propsense/simos/simosCustomerContacts/updateSimosCustomerContact/updateSimosCustomerContactAccessor";
import { IUpdateSimosCustomerContactRequest } from "../../../../../redux/propsense/simos/simosCustomerContacts/updateSimosCustomerContact/updateSimosCustomerContactConstaints";
import { updateSimosCustomerContactLoadAction } from "../../../../../redux/propsense/simos/simosCustomerContacts/updateSimosCustomerContact/updateSimosCustomerContactActions";
import { ISimosCustomers, ISimosCustomersRequest } from "../../../../../redux/propsense/simos/simosCustomers/getSimosCustomers/getSimosCustomersConstants";
import { getSimosCustomers } from "../../../../../redux/propsense/simos/simosCustomers/getSimosCustomers/getSimosCustomersAccessor";
import { getSimosCustomersLoadAction } from "../../../../../redux/propsense/simos/simosCustomers/getSimosCustomers/getSimosCustomersActions";
import { ISimosCustomerAddress, ISimosCustomerAddressRequest } from "../../../../../redux/propsense/simos/simosCustomerAddress/getSimosCustomerAddress/getSimosCustomerAddressConstants";
import { getSimosCustomerAddress } from "../../../../../redux/propsense/simos/simosCustomerAddress/getSimosCustomerAddress/getSimosCustomerAddressAccessor";
import { getSimosCustomerAddressLoadAction } from "../../../../../redux/propsense/simos/simosCustomerAddress/getSimosCustomerAddress/getSimosCustomerAddressActions";
import LAErrorBox from "../../../../shared/errorBox";

const RequiredFields = ["contact", "phone", "customer", "delivery_Address"];

interface IAddUpdateSimosCustomerContactStoreProps {
    getToken: Server<SimosAPIResponse<IToken>>;
    getSimosCustomers: Server<SimosAPIResponse<ISimosCustomers[]>>;
    getSimosCustomerAddress: Server<SimosAPIResponse<ISimosCustomerAddress[]>>;
    getSimosCustomerContacts: Server<SimosAPIResponse<ISimosCustomerContacts[]>>;
    addSimosCustomerContact: Server<string>;
    updateSimosCustomerContact: Server<string>;
};

interface IAddUpdateSimosCustomerContactDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getSimosCustomersRequest: (data: ISimosCustomersRequest) => unknown;
    getSimosCustomerAddressRequest: (data: ISimosCustomerAddressRequest) => unknown;
    getSimosCustomerContactsRequest: (data: ISimosCustomerContactsRequest) => unknown;
    addSimosCustomerContactRequest: (data: IAddSimosCustomerContactRequest) => unknown;
    updateSimosCustomerContactRequest: (data: IUpdateSimosCustomerContactRequest) => unknown;
};

interface IAddUpdateSimosCustomerContactOwnProps {
    id?: number | undefined;
    onClose?: () => void;
};

interface IAddUpdateSimosCustomerContactState {
    data: ISimosCustomerContacts;
    errors: ById<IFieldErrorKeyValue>;
};

const AddUpdateSimosCustomerContactStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;

    .dx-toolbar-after {
        display: none;
    };

    .variancepopup{
        height: 50% !important;
    }
`;

type IAddUpdateSimosCustomerContactProps = RouteComponentProps
    & IAddUpdateSimosCustomerContactStoreProps
    & IAddUpdateSimosCustomerContactDispatchProps
    & IAddUpdateSimosCustomerContactOwnProps;

class AddUpdateSimosCustomerContact extends PureComponent<IAddUpdateSimosCustomerContactProps, IAddUpdateSimosCustomerContactState> {

    public constructor(props: IAddUpdateSimosCustomerContactProps) {
        super(props);
        this.state = {
            data: {
                id: 0,
                contact: "",
                phone: "",
                cell: "",
                email: "",
                customer: "",
                customer_ID: 0,
                delivery_Address: "",
                delivery_ID: 0,
                created: "",
                created_By: userName,
                modified: "",
                modified_By: userName,
            },
            errors: {},
        };
    }

    public async componentDidMount(): Promise<void>{
        await this.getSimosCustomersAddress()
        this.callServer();
    };

    public componentDidUpdate(prevProps: IAddUpdateSimosCustomerContactProps): void {
        if (this.props !== prevProps) {
            this.callServer();

            if (this.props.addSimosCustomerContact !== prevProps.addSimosCustomerContact) {
                if (isSucceeded(this.props.addSimosCustomerContact)) {
                    this.handleCancel();
                };
            };

            if (this.props.updateSimosCustomerContact !== prevProps.updateSimosCustomerContact) {
                if (isSucceeded(this.props.updateSimosCustomerContact)) {
                    this.handleCancel();
                };
            };
        };
    };


    public render(): ReactNode {

        const { data, errors } = this.state;
        const { updateSimosCustomerContact, addSimosCustomerContact, getSimosCustomers, getSimosCustomerAddress, getToken } = this.props;
        const getRole = hasPayload(getToken) && getToken.payload.response.simosAccess.access;
        const readOnly = getRole === ReadOnly;
        const customerOptions = hasPayload(getSimosCustomers) ? getSimosCustomers.payload.response : [];
        const filteredCustomerOptions = customerOptions.filter(x => x.active === "Yes");
        const deliveryAddressOptions = hasPayload(getSimosCustomerAddress) ? getSimosCustomerAddress.payload.response : [];
        const filteredDeliveryAddressOptions:any = deliveryAddressOptions?.filter(x => x.customer_ID === data.customer_ID);
        filteredDeliveryAddressOptions.push({id: 0, address: "N/A"})
        const onCustomer = (event: unknown, value: any): void => this.handleCustomerChange("customer", value !== null ? value : "");
        const onDeliveryAddress = (event: unknown, value: any): void => this.handleDeliveryAddressChange("delivery_Address", value !== null ? value : "");
        
        return (
            <PageSpacing title="SIMOS - Simos Customer Contact" description="Simos Customer Contact" fixedSpaceOnSmallerScreens={true}>
                {getRole !== NotApplicable ?
                    <AddUpdateSimosCustomerContactStyles>

                        <LAButton startIcon={<ArrowLeftIcon color={WHITE_COLOR} />} label="Back to list" onClick={this.handleCancel} />
                        <h2 className="text-center">{data.id > 0 ? "VIEW/UPDATE " : "ADD "}SIMOS CUSTOMER CONTACT</h2>
                        <hr />

                        <LAPaperWithPadding>
                            <LAGrid spacing={1}>

                                <LAGridItem xs={12} sm={6} md={6}>
                                    <LAAutoComplete
                                        multiple={false}
                                        option={filteredCustomerOptions}
                                        autoHighlight={true}
                                        disabled={data.id > 0 || readOnly}
                                        onChange={onCustomer}
                                        filterSelectedOptions={true}
                                        getOptionLabel="customer"
                                        dropDownPlaceHolder="Customer"
                                        selectionRemove={undefinedFunction}
                                        errorText={errors["customer"] ? errors["customer"].message : undefined}
                                        value={data.customer ? customerOptions.find(q => q.customer === data.customer) : null}
                                        defaultValue={data.customer ? customerOptions.find(q => q.customer === data.customer) : null}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={6}>
                                    <LAAutoComplete
                                        multiple={false}
                                        option={filteredDeliveryAddressOptions}
                                        autoHighlight={true}
                                        disabled={data.id > 0 || readOnly}
                                        onChange={onDeliveryAddress}
                                        filterSelectedOptions={true}
                                        getOptionLabel="address"
                                        dropDownPlaceHolder="Delivery Address"
                                        selectionRemove={undefinedFunction}
                                        errorText={errors["delivery_Address"] ? errors["delivery_Address"].message : undefined}
                                        value={data.delivery_Address ? filteredDeliveryAddressOptions.find((q:any) => q.address === data.delivery_Address) : null}
                                        defaultValue={data.delivery_Address ? filteredDeliveryAddressOptions.find((q:any) => q.address === data.delivery_Address) : null}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>                                   
                                    <LATextField
                                        varient="outlined"
                                        label="Contact Name"
                                        fullWidth={true}
                                        name="contact"
                                        disabled={readOnly}
                                        onChange={this.onChange}
                                        value={data.contact ?? ""}
                                        errorText={errors && errors["contact"] ? errors["contact"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>                                   
                                    <LATextField
                                        varient="outlined"
                                        label="Phone"
                                        fullWidth={true}
                                        name="phone"
                                        disabled={readOnly}
                                        InputProps={{
                                            inputProps: {
                                                maxLength: 10
                                            }
                                        }}
                                        // type="number"
                                        onChange={this.onChange}
                                        errorText={errors && errors["phone"] ? errors["phone"].message : undefined}
                                        value={data.phone ?? ""}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>                                   
                                    <LATextField
                                        varient="outlined"
                                        label="Email"
                                        fullWidth={true}
                                        name="email"
                                        disabled={readOnly}
                                        onChange={this.onChange}
                                        errorText={errors && errors["email"] ? errors["email"].message : undefined}
                                        value={data.email ?? ""}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>                                   
                                    <LATextField
                                        varient="outlined"
                                        label="Cell"
                                        fullWidth={true}
                                        disabled={readOnly}
                                        name="cell"
                                        onChange={this.onChange}
                                        errorText={errors && errors["cell"] ? errors["cell"].message : undefined}
                                        value={data.cell ?? ""}
                                    />
                                </LAGridItem>

                                {data.id && data.id > 0 ? 
                                <>
                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <LATextField
                                            fullWidth={true}
                                            name="created"
                                            label="Created"
                                            varient="outlined"
                                            disabled={true}
                                            value={data.created ? new Date(data.created).toLocaleString() : ""}
                                            onChange={undefinedFunction}
                                            errorText={errors["created"] ? errors["created"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <LATextField
                                            fullWidth={true}
                                            name="created_By"
                                            label="Created By"
                                            disabled={true}
                                            varient="outlined"
                                            value={data.created_By}
                                            onChange={undefinedFunction}
                                            errorText={errors["created_By"] ? errors["created_By"].message : undefined}
                                        />
                                    </LAGridItem>
                                    

                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <LATextField
                                            fullWidth={true}
                                            name="modified"
                                            label="Last Modified"
                                            varient="outlined"
                                            disabled={true}
                                            value={data.modified ? new Date(data.modified).toLocaleString() : ""}
                                            onChange={undefinedFunction}
                                            errorText={errors["modified"] ? errors["modified"].message : undefined}
                                        />
                                    </LAGridItem>
                                    

                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <LATextField
                                            fullWidth={true}
                                            name="modified_By"
                                            label="Modified By"
                                            varient="outlined"
                                            disabled={true}
                                            value={data.modified_By}
                                            onChange={undefinedFunction}
                                            errorText={errors["modified_By"] ? errors["modified_By"].message : undefined}
                                        />
                                    </LAGridItem>
                                </> : null}

                                <LAGridItem xs={12}>
                                    <LASaveAndCancelButton
                                        onSave={this.onSave}
                                        onCancel={this.handleCancel}
                                        disableSave={(Object.values(errors).length > 0 || readOnly) ? true : undefined}
                                    />
                                </LAGridItem>

                            </LAGrid>
                        </LAPaperWithPadding>

                        <RequestStatus requestStatus={addSimosCustomerContact.kind} successMessage="Simos Customer Contact successfully saved" failedMessage={addSimosCustomerContact.kind === "failed" ? addSimosCustomerContact.message : "Your Request has failed. Please try again or contact IT.Developers for assistance."} />
                        <RequestStatus requestStatus={updateSimosCustomerContact.kind} successMessage="Simos Customer Contact successfully updated" failedMessage={updateSimosCustomerContact.kind === "failed" ? updateSimosCustomerContact.message : "Your Request has failed. Please try again or contact IT.Developers for assistance."}/>

                    </AddUpdateSimosCustomerContactStyles> :
                    <LAErrorBox text="You do not have permission to view this page. Contact your system admin." />
                }
            </PageSpacing>
        );
    }

    private handleCustomerChange = (name: string, value: any): void => {
        let errors = { ...this.state.errors };
        let rules: IFieldValidatorProps = {};

        if (RequiredFields.includes(name)) {
            rules.required = true;
            errors = this.errorChecker(name, value, errors, rules);
        }

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [name]: value.customer ?? "",
                customer_ID: value.id ?? 0
            },
            errors
        });
    };
    
    private handleDeliveryAddressChange = (name: string, value: any): void => {
        let errors = { ...this.state.errors };
        let rules: IFieldValidatorProps = {};

        if (RequiredFields.includes(name)) {
            rules.required = true;
            errors = this.errorChecker(name, value, errors, rules);
        }

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [name]: value.address ?? "",
                delivery_ID: value.id ?? 0
            },
            errors
        });
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, rules: IFieldValidatorProps): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, rules);
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        };
        return err;
    };

    private handleCancel = (): void => {
        // this.props.history.push(ROUTE.SIMOS.CUSTOMERCONTACT.INDEX);
        const query = queryString.parse(this.props.location.search);
        if(this.props.onClose) {
            this.props.onClose();
        }
        else {
            callRouteWithQueryString({
                route: this.props,
                search: { page: query.page ? query.page.toString() : "" },
                pathName: ROUTE.SIMOS.CUSTOMERCONTACT.INDEX
            });
        }
    };

    private onSave = async (): Promise<void> => {

        if (hasPayload(this.props.getToken)) {
            const { data } = this.state;

            const request: IAddUpdateSimosCustomerContact = {
                ID: data.id,
                Contact: data.contact,
                Phone: data.phone,
                Cell: data.cell,
                Email: data.email,
                Customer: data.customer,
                Customer_ID: data.customer_ID,
                Delivery_Address: data.delivery_Address,
                Delivery_ID: data.delivery_ID,
                Created_By: userName,
                Modified_By:  userName,
            };

            // console.log(request);

            if (data.id === 0) {
                this.props.addSimosCustomerContactRequest({
                    token: this.props.getToken.payload.response.token,
                    request
                });
            } 
            else {
                this.props.updateSimosCustomerContactRequest({
                    token: this.props.getToken.payload.response.token,
                    request
                });
            };
        }
    };

    private onChange = (name: string, value: string): void => {
        let errors = this.state.errors;
        let rules: IFieldValidatorProps = {};

        if (RequiredFields.includes(name)) {
            rules.required = true;
            if(name === "phone")
                rules.phone = true;
            
            errors = this.errorChecker(name, value, errors, rules);
        }

        if(name === 'email') {
            if(value.length > 0) {
                if(name === "email")
                    rules.email = true;
                
                errors = this.errorChecker(name, value, errors, rules);
            }
            else {
                delete errors["email"]
            }
        }

        this.setState({
            ...this.state,
            errors,
            data: {
                ...this.state.data,
                [name]: value
            }
        });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getSimosCustomerContacts))
            this.props.getSimosCustomerContactsRequest({
                token: this.props.getToken.payload.response.token
            });
            
        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getSimosCustomers))
            this.props.getSimosCustomersRequest({
                token: this.props.getToken.payload.response.token
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getSimosCustomerAddress))
            this.props.getSimosCustomerAddressRequest({
                token: this.props.getToken.payload.response.token
            });
        
        const query = queryString.parse(this.props.location.search);
        const id = this.props.id ? this.props.id : query.id ?? "0";

        if ((id !== "0") && (hasPayload(this.props.getSimosCustomerContacts)) && (this.state.data.id === 0)) {
            const data = this.props.getSimosCustomerContacts.payload.response.find((x) => x.id === +id);
            if (data)
                this.setState({ data, errors: {} });
            
        } else {
            const errors: ById<IFieldErrorKeyValue> = {};
            RequiredFields.forEach((x) => {
                errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
            });

            this.setState({ errors });
        }

    };

    private getSimosCustomersAddress = (): void => {
        if (hasPayload(this.props.getToken)) {
            this.props.getSimosCustomerAddressRequest({
                token: this.props.getToken.payload.response.token
            });
        };
    };

}

const mapStateToProps = (state: IStore): IAddUpdateSimosCustomerContactStoreProps => ({
    getToken: getTokenStatus(state),
    getSimosCustomers: getSimosCustomers(state),
    getSimosCustomerAddress: getSimosCustomerAddress(state),
    getSimosCustomerContacts: getSimosCustomerContacts(state),
    addSimosCustomerContact: addSimosCustomerContactStatus(state),
    updateSimosCustomerContact: updateSimosCustomerContactStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IAddUpdateSimosCustomerContactDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getSimosCustomersRequest: (data: ISimosCustomersRequest) => dispatch(getSimosCustomersLoadAction(data)),
    getSimosCustomerAddressRequest: (data: ISimosCustomerAddressRequest) => dispatch(getSimosCustomerAddressLoadAction(data)),
    getSimosCustomerContactsRequest: (data: ISimosCustomerContactsRequest) => dispatch(getSimosCustomerContactsLoadAction(data)),
    addSimosCustomerContactRequest: (data: IAddSimosCustomerContactRequest) => dispatch(addSimosCustomerContactLoadAction(data)),
    updateSimosCustomerContactRequest: (data: IUpdateSimosCustomerContactRequest) => dispatch(updateSimosCustomerContactLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateSimosCustomerContact);