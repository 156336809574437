
import { IADD_BAGGED_INVENTORY_REQUEST } from "./addBaggedInventoryConstaints";
import { IAddBaggedInventoryLoadAction, IAddBaggedInventoryLoadFailedAction, IAddBaggedInventorySuccessAction } from "./addBaggedInventoryActions";
import { Server, failed, loading, notLoaded, succeeded } from "../../../../server";
import { IFlushDataSuccessAction } from "../../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../../flushData.tsx/flushDataConstants";

type Actions =
    IAddBaggedInventoryLoadAction
    | IAddBaggedInventoryLoadFailedAction
    | IAddBaggedInventorySuccessAction
    | IFlushDataSuccessAction;

export const AddBaggedInventoryReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IADD_BAGGED_INVENTORY_REQUEST.REQUEST:
            return loading;

        case IADD_BAGGED_INVENTORY_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IADD_BAGGED_INVENTORY_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};