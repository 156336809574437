import {currentUser} from '../../../../simosHome';

/*
 * Returns entire response from getting userGroup Access
 * userrole -> response.role
 * onHoldAccess -> response.customers_on_hold_access
*/
 async function getSIMOSUserGroupAccess() {
     var entireResponse:any = null;
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type':'application/json' }, 
        body: JSON.stringify({request:{username:currentUser},token: '19515195-d571-44a3-b0db-637f5bf24f54' })
    }
    await fetch('https://api2.propsense.com/api/GetSIMOSUserGroup', requestOptions)
    .then( async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json');
          // error response
          if(!response.ok) {
            const error = (data) || response.status;
            return Promise.reject(error);
        }else{
            await response.json().then((res:any) => {
                if(res.isAuthenticated){
                    entireResponse = res;
                  } 
                })
                .catch((err:any) => {
                    console.log(err.toString(), err)
                })
        }
    });
   return entireResponse;
};

export {getSIMOSUserGroupAccess};