import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IGetSieveFracBallLoadAction, IGetSieveFracBallLoadFailedAction, IGetSieveFracBallSuccessAction, 
    getSieveFracBallLoadFailedAction, getSieveFracBallLoadSuccessAction
} from "./getSieveFracBallsActions";
import { IGET_SIEVE_FRAC_BALLS_REQUEST, ISieveFracBall } from "./getSieveFracBallsConstaints";
import { IStore } from "../../../../reducers";
import { EpicDependencies } from "../../../../store";
import { SilAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { END_POINTS } from "../../../../endpoints";
import { withJsonContentType } from "../../../../epicUtils";

export const getSieveFracBallEpic: Epic = (
    action$: ActionsObservable<IGetSieveFracBallLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IGetSieveFracBallSuccessAction | IGetSieveFracBallLoadFailedAction> =>
    action$.ofType(IGET_SIEVE_FRAC_BALLS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<ISieveFracBall[]>>(
                    END_POINTS.PROPSENSE.SIL.CV.GetCVSieveFracBall,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<ISieveFracBall[]>): IGetSieveFracBallSuccessAction => {
                            return getSieveFracBallLoadSuccessAction(response);
                        }),
                        catchError(() => ActionsObservable.of(getSieveFracBallLoadFailedAction("Unable to get frac ball")))
                    )
            )
        );