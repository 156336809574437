import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IUpdateYHDCarrierFleetTruckLoadAction, IUpdateYHDCarrierFleetTruckLoadFailedAction, IUpdateYHDCarrierFleetTruckSuccessAction, updateYHDCarrierFleetTruckLoadFailedAction,
     updateYHDCarrierFleetTruckLoadSuccessAction } from "./updateCarrierFleetTruckActions";
import { IStore } from "../../../../reducers";
import { EpicDependencies } from "../../../../store";
import { IUPDATE_YHD_CARRIER_FLEET_TRUCK_REQUEST } from "./updateCarrierFleetTruckConstaints";
import { END_POINTS } from "../../../../endpoints";
import { withJsonContentType } from "../../../../epicUtils";
import { YHDAPIResponse } from "../../../../../react/shared/publicInterfaces";

export const updateYHDCarrierFleetTruckEpic: Epic = (
    action$: ActionsObservable<IUpdateYHDCarrierFleetTruckLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IUpdateYHDCarrierFleetTruckSuccessAction | IUpdateYHDCarrierFleetTruckLoadFailedAction> =>
    action$.ofType(IUPDATE_YHD_CARRIER_FLEET_TRUCK_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<YHDAPIResponse<string>>(
                    END_POINTS.PROPSENSE.YHD.CARRIER.UPDATE_CARRIER_FLEET_TRUCKS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: YHDAPIResponse<string>): IUpdateYHDCarrierFleetTruckSuccessAction | IUpdateYHDCarrierFleetTruckLoadFailedAction => {
                            
                            if(response.message === "Success"){
                                return updateYHDCarrierFleetTruckLoadSuccessAction(response.message);
                            } else {
                                return updateYHDCarrierFleetTruckLoadFailedAction(response.message);
                            }
                        }),
                        catchError(() => ActionsObservable.of(updateYHDCarrierFleetTruckLoadFailedAction("Unable to update your changes")))
                    )
            )
        );