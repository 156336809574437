import { IFlushDataSuccessAction } from "../../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../../flushData.tsx/flushDataConstants";
import { Server, failed, loading, notLoaded, succeeded } from "../../../../server";
import { IAddLabScaleLoadAction, IAddLabScaleLoadFailedAction, IAddLabScaleSuccessAction } from "./addLabScaleActions";
import { IADD_LAB_SCALE_REQUEST } from "./addLabScaleConstaints";


type Actions =
    IAddLabScaleLoadAction
    | IAddLabScaleLoadFailedAction
    | IAddLabScaleSuccessAction
    | IFlushDataSuccessAction;

export const AddLabScaleReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IADD_LAB_SCALE_REQUEST.REQUEST:
            return loading;

        case IADD_LAB_SCALE_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IADD_LAB_SCALE_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};