import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../../shared/paper";
import { IDispatch, IStore } from "../../../../../redux/reducers";
import { hasPayload, isNotLoaded, isSucceeded, Server } from "../../../../../redux/server";
import { ById, SimosAPIResponse } from "../../../../shared/publicInterfaces";
import { userName, undefinedFunction, yesOrNoOptions, callRouteWithQueryString, NotApplicable, ReadOnly } from "../../../../shared/constExports";
import { LAButton, LASaveAndCancelButton } from "../../../../shared/buttons";
import { ArrowLeftIcon } from "../../../../shared/icons";
import { WHITE_COLOR } from "../../../../shared/theme";
import LAGrid from "../../../../shared/grid";
import LAGridItem from "../../../../shared/gridList";
import LATextField from "../../../../shared/textField";
import { ROUTE } from "../../../../routes";
import queryString from "query-string";
import RequestStatus from "../../../../shared/requestStatusSnackbar";
import { FieldValidator, FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue, IFieldValidatorProps } from "../../../../shared/fieldValidator";
import PageSpacing from "../../../../shared/pageSpacing";
import { IGetTokenRequest, IToken } from "../../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenStatus } from "../../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { getTokenLoadAction } from "../../../../../redux/sil/dryPlant/token/getTokenActions";
import LAAutoComplete from "../../../../shared/autoComplete";
import { ISimosTransportCompanies, ISimosTransportCompaniesRequest } from "../../../../../redux/propsense/simos/transportCompanies/getSimosTransportCompanies/getSimosTransportCompaniesConstants";
import { IUpdateSimosTransportCompanyRequest } from "../../../../../redux/propsense/simos/transportCompanies/updateSimosTransportCompany/updateSimosTransportCompanyConstaints";
import { IAddSimosTransportCompanyRequest, IAddUpdateSimosTransportCompany } from "../../../../../redux/propsense/simos/transportCompanies/addSimosTransportCompany/addSimosTransportCompanyConstaints";
import { getSimosTransportCompanies } from "../../../../../redux/propsense/simos/transportCompanies/getSimosTransportCompanies/getSimosTransportCompaniesAccessor";
import { getSimosTransportCompaniesLoadAction } from "../../../../../redux/propsense/simos/transportCompanies/getSimosTransportCompanies/getSimosTransportCompaniesActions";
import { addSimosTransportCompanyLoadAction } from "../../../../../redux/propsense/simos/transportCompanies/addSimosTransportCompany/addSimosTransportCompanyActions";
import { updateSimosTransportCompanyLoadAction } from "../../../../../redux/propsense/simos/transportCompanies/updateSimosTransportCompany/updateSimosTransportCompanyActions";
import { addSimosTransportCompanyStatus } from "../../../../../redux/propsense/simos/transportCompanies/addSimosTransportCompany/addSimosTransportCompanyAccessor";
import { updateSimosTransportCompanyStatus } from "../../../../../redux/propsense/simos/transportCompanies/updateSimosTransportCompany/updateSimosTransportCompanyAccessor";
import LAErrorBox from "../../../../shared/errorBox";

const RequiredFields = ["transport_Company", "email"];

interface ISimosTransportCompanyStoreProps {
    getToken: Server<SimosAPIResponse<IToken>>;
    getSimosTransportCompanies: Server<SimosAPIResponse<ISimosTransportCompanies[]>>;
    addSimosTransportCompany: Server<string>;
    updateSimosTransportCompany: Server<string>;
};

interface ISimosTransportCompanyDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getSimosTransportCompaniesRequest: (data: ISimosTransportCompaniesRequest) => unknown;
    addSimosTransportCompanyRequest: (data: IAddSimosTransportCompanyRequest) => unknown;
    updateSimosTransportCompanyRequest: (data: IUpdateSimosTransportCompanyRequest) => unknown;
};

interface ISimosTransportCompanyOwnProps {
    onClose?: () => void;
};

interface ISimosTransportCompanyState {
    data: ISimosTransportCompanies;
    errors: ById<IFieldErrorKeyValue>;
};

const SimosTransportCompanyStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;

    .dx-toolbar-after {
        display: none;
    };

    .variancepopup{
        height: 50% !important;
    }
`;

type ISimosTransportCompanyProps = RouteComponentProps
    & ISimosTransportCompanyStoreProps
    & ISimosTransportCompanyDispatchProps
    & ISimosTransportCompanyOwnProps;

class AddUpdateSimosTransportCompany extends PureComponent<ISimosTransportCompanyProps, ISimosTransportCompanyState> {

    public constructor(props: ISimosTransportCompanyProps) {
        super(props);
        this.state = {
            errors: {
                
            },
            data: {
                id: 0,
                transport_Company: "",
                email: "",
                active: "Yes",
                truck_Code_Y: "",
                truck_Code_Z: "",
                created: "",
                created_By: userName,
                modified: "",
                modified_By: userName,
            }
        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: ISimosTransportCompanyProps): void {
        if (this.props !== prevProps) {
            this.callServer();

            if (this.props.addSimosTransportCompany !== prevProps.addSimosTransportCompany) {
                if (isSucceeded(this.props.addSimosTransportCompany)) {
                    this.handleCancel();
                };
            };

            if (this.props.updateSimosTransportCompany !== prevProps.updateSimosTransportCompany) {
                if (isSucceeded(this.props.updateSimosTransportCompany)) {
                    this.handleCancel();
                };
            };
        };
    };


    public render(): ReactNode {

        const { data, errors } = this.state;
        const { updateSimosTransportCompany, addSimosTransportCompany, getToken  } = this.props;
        const getRole = hasPayload(getToken) && getToken.payload.response.simosAccess.access;
        const readOnly = getRole === ReadOnly;
        const superAdminAccess = hasPayload(getToken) && getToken.payload.response.simosAccess.superAdmin;
        const handleOnActiveChange = (event: unknown, value: { id: number, name: string }): void => this.onChange("active", value !== null ? value.name : data.active);

        return (
            <PageSpacing title="SIMOS - Transport Company" description="Simos Transport Company" fixedSpaceOnSmallerScreens={true}>
                {getRole !== NotApplicable ?
                    <SimosTransportCompanyStyles>

                    <LAButton startIcon={<ArrowLeftIcon color={WHITE_COLOR} />} label="Back to list" onClick={this.handleCancel} />
                    <h2 className="text-center">{data.id > 0 ? "VIEW/UPDATE " : "ADD "}SIMOS TRANSPORT COMPANY</h2>
                    <hr />

                    <LAPaperWithPadding>
                        <LAGrid spacing={1}>
                            <LAGridItem xs={12} sm={6} md={3}>                                   
                                <LATextField
                                    varient="outlined"
                                    label="Transport Company"
                                    disabled={data.id > 0 || readOnly}
                                    fullWidth={true}
                                    name="transport_Company"
                                    onChange={this.onChange}
                                    value={data.transport_Company ?? ""}
                                    errorText={errors && errors["transport_Company"] ? errors["transport_Company"].message : undefined}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={3}>                                   
                                <LATextField
                                    varient="outlined"
                                    label="Email"
                                    fullWidth={true}
                                    disabled={readOnly}
                                    name="email"
                                    onChange={this.onChange}
                                    value={data.email ?? ""}
                                    errorText={errors && errors["email"] ? errors["email"].message : undefined}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={3}>
                                <LAAutoComplete
                                    multiple={false}
                                    option={yesOrNoOptions}
                                    getOptionLabel="name"
                                    disabled={readOnly}
                                    autoHighlight={true}
                                    onChange={handleOnActiveChange}
                                    filterSelectedOptions={true}
                                    dropDownPlaceHolder="Active"
                                    selectionRemove={undefinedFunction}
                                    value={data.active ? yesOrNoOptions.find(q => q.name === data.active) : null}
                                    defaultValue={data.active ? yesOrNoOptions.find(q => q.name === data.active) : null}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={3}>                                   
                                <LATextField
                                    varient="outlined"
                                    label="Truck Code Y"
                                    disabled={!superAdminAccess || readOnly}
                                    fullWidth={true}
                                    name="truck_Code_Y"
                                    onChange={this.onChange}
                                    value={data.truck_Code_Y ?? ""}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={3}>                                   
                                <LATextField
                                    varient="outlined"
                                    label="Truck Code Z"
                                    fullWidth={true}
                                    disabled={!superAdminAccess || readOnly}
                                    name="truck_Code_Z"
                                    onChange={this.onChange}
                                    value={data.truck_Code_Z ?? ""}
                                />
                            </LAGridItem>
                            
                            {data.id && data.id > 0 ? 
                            <>
                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="created"
                                        label="Created"
                                        varient="outlined"
                                        disabled={true}
                                        value={data.created ? new Date(data.created).toLocaleString() : ""}
                                        onChange={undefinedFunction}
                                        errorText={errors["created"] ? errors["created"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="created_By"
                                        label="Created By"
                                        disabled={true}
                                        varient="outlined"
                                        value={data.created_By}
                                        onChange={undefinedFunction}
                                        errorText={errors["created_By"] ? errors["created_By"].message : undefined}
                                    />
                                </LAGridItem>
                                

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="modified"
                                        label="Last Modified"
                                        varient="outlined"
                                        disabled={true}
                                        value={data.modified ? new Date(data.modified).toLocaleString() : ""}
                                        onChange={undefinedFunction}
                                        errorText={errors["modified"] ? errors["modified"].message : undefined}
                                    />
                                </LAGridItem>
                                

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="modified_By"
                                        label="Modified By"
                                        varient="outlined"
                                        disabled={true}
                                        value={data.modified_By}
                                        onChange={undefinedFunction}
                                        errorText={errors["modified_By"] ? errors["modified_By"].message : undefined}
                                    />
                                </LAGridItem>
                            </> : null}

                            <LAGridItem xs={12}>
                                <LASaveAndCancelButton
                                    onSave={this.onSave}
                                    onCancel={this.handleCancel}
                                    disableSave={(Object.values(errors).length > 0 || readOnly) ? true : undefined}
                                />
                            </LAGridItem>

                        </LAGrid>
                    </LAPaperWithPadding>

                    <RequestStatus requestStatus={addSimosTransportCompany.kind} successMessage="Simos Transport Company successfully saved" failedMessage={addSimosTransportCompany.kind === "failed" ? addSimosTransportCompany.message : "Your Request has failed. Please try again or contact IT.Developers for assistance."} />
                    <RequestStatus requestStatus={updateSimosTransportCompany.kind} successMessage="Simos Transport Company  successfully updated" failedMessage={updateSimosTransportCompany.kind === "failed" ? updateSimosTransportCompany.message : "Your Request has failed. Please try again or contact IT.Developers for assistance."}/>

                    </SimosTransportCompanyStyles> : 
                    <LAErrorBox text="You do not have permission to view this page. Contact your system admin." />
                }
            </PageSpacing>
        );
    }

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, rules: IFieldValidatorProps): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, rules);
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        };
        return err;
    };

    private handleCancel = (): void => {
        // this.props.history.push(ROUTE.SIMOS.TRANSPORTCOMPANIES.INDEX);
        const query = queryString.parse(this.props.location.search);
        if(this.props.onClose) {
            this.props.onClose();
        } 
        else {
            callRouteWithQueryString({
                route: this.props,
                search: { page: query.page ? query.page.toString() : "" },
                pathName: ROUTE.SIMOS.TRANSPORTCOMPANIES.INDEX
            });
        }
    };

    private onSave = async (): Promise<void> => {

        if (hasPayload(this.props.getToken)) {
            const { data } = this.state;

            const request: IAddUpdateSimosTransportCompany = {
                ID: data.id,
                Transport_Company: data.transport_Company,
                Email: data.email,
                Active: data.active,
                Truck_Code_Y: data.truck_Code_Y,
                Truck_Code_Z: data.truck_Code_Z,
                Created_By: userName,
                Modified_By:  userName,
            };

            // console.log(request);

            if (data.id === 0) {
                this.props.addSimosTransportCompanyRequest({
                    token: this.props.getToken.payload.response.token,
                    request
                });
            } 
            else {
                this.props.updateSimosTransportCompanyRequest({
                    token: this.props.getToken.payload.response.token,
                    request
                });
            };
        }
    };

    private onChange = (name: string, value: string): void => {
        let errors = this.state.errors;

        let rules: IFieldValidatorProps = {};

        if (RequiredFields.includes(name)) {
            rules.required = true;

            if(name === "email")
                rules.email = true;

            errors = this.errorChecker(name, value, errors, rules);
        }

        this.setState({
            ...this.state,
            errors,
            data: {
                ...this.state.data,
                [name]: value
            }
        });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getSimosTransportCompanies))
            this.props.getSimosTransportCompaniesRequest({
                token: this.props.getToken.payload.response.token
            });
        
        const query = queryString.parse(this.props.location.search);
        const id = query.id ?? "0";

        if ((id !== "0") && (hasPayload(this.props.getSimosTransportCompanies)) && (this.state.data.id === 0)) {
            const data = this.props.getSimosTransportCompanies.payload.response.find((x) => x.id === +id);

            if (data)
                this.setState({ data, errors: {} });
        } else {
            const errors: ById<IFieldErrorKeyValue> = {};
            RequiredFields.forEach((x) => {
                errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
            });

            this.setState({ errors });
        }

    };

}

const mapStateToProps = (state: IStore): ISimosTransportCompanyStoreProps => ({
    getToken: getTokenStatus(state),
    getSimosTransportCompanies: getSimosTransportCompanies(state),
    addSimosTransportCompany: addSimosTransportCompanyStatus(state),
    updateSimosTransportCompany: updateSimosTransportCompanyStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): ISimosTransportCompanyDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getSimosTransportCompaniesRequest: (data: ISimosTransportCompaniesRequest) => dispatch(getSimosTransportCompaniesLoadAction(data)),
    addSimosTransportCompanyRequest: (data: IAddSimosTransportCompanyRequest) => dispatch(addSimosTransportCompanyLoadAction(data)),
    updateSimosTransportCompanyRequest: (data: IUpdateSimosTransportCompanyRequest) => dispatch(updateSimosTransportCompanyLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateSimosTransportCompany);