

async function getSIMOSTruckInfo(){
    var res:any=null;
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
            token: '19515195-d571-44a3-b0db-637f5bf24f54' })
    };
      
    await fetch('https://api2.propsense.com/api/GetSIMOSTruckInfo', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
        // error response
        if(!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        // no error
        // data.map((company_name:any) => {
        //     if(company_name.active === "Yes"){
        //         allTransports.push(company_name.transport_Company);
        //     }
        // });
        res = [response,data];
        console.log('returned: ', res, data);
    })
    .catch(error => {
        console.log('There was an error!', error);
    });
    return res;
}

export default getSIMOSTruckInfo;