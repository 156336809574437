import { IPassTokenWithRequest } from "../../../dryPlant/token/getTokenConstants";


export enum IADD_CHEMICAL_ANALYSES_REQUEST {
    REQUEST = "addChemicalAnalyses/ADD_CHEMICAL_ANALYSES_REQUEST",
    SUCCESS = "addChemicalAnalyses/ADD_CHEMICAL_ANALYSES_SUCCESS",
    FAILED = "addChemicalAnalyses/ADD_CHEMICAL_ANALYSES_FAILED"
};

export interface IAddChemicalAnalysesRequest extends IPassTokenWithRequest {
    request: IAddUpdateChemicalAnalyses;
};

export interface IAddUpdateChemicalAnalyses {
    ID?: number;
    Product: string;
    Plant: string;
    Sample_Location: string;
    Sample_Date: string;
    Sampler: string;
    Analysis_Date: string;
    Tests_Ran: number;
    Analyzed_By: string;
    MgO_Perc: number;
    Al2O3_Perc: number;
    SiO2_Perc: number;
    P2O5_Perc: number;
    CaO_Perc: number;
    TiO2_Perc: number;
    MnO_Perc: number;
    FeO_Perc: number;
    LE_Perc: number;
    Created_By: string;
    Modified_By: string;
}