import { IYHDCarrierForm } from "../../../../../react/components/propsense/yhd/carriers/carrierForm";
import { IPassTokenWithRequest } from "../../../../sil/dryPlant/token/getTokenConstants";

export enum IGET_YHD_CARRIERS_REQUEST {
    REQUEST = "getYHDCarriers/GET_YHD_CARRIERS_REQUEST",
    SUCCESS = "getYHDCarriers/GET_YHD_CARRIERS_SUCCESS",
    FAILED = "getYHDCarriers/GET_YHD_CARRIERS_FAILED"
};

export interface IGetYHDCarriersRequest extends IPassTokenWithRequest {

};

export interface IYHDCarrier extends IYHDCarrierForm {

};