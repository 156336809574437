import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithLessPadding, LAPaperWithPadding } from "../../../../shared/paper";
import { IDispatch, IStore } from "../../../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../../redux/server";
import { SimosAPIResponse } from "../../../../shared/publicInterfaces";
import { IGetTokenRequest, IToken } from "../../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenLoadAction } from "../../../../../redux/sil/dryPlant/token/getTokenActions";
import { NotApplicable, callRouteWithQueryString, pageAccessCheck, userName } from "../../../../shared/constExports";
import { LADevExtremeGrid } from "../../../../shared/devExtreme";
import { getTokenStatus } from "../../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { LAButton } from "../../../../shared/buttons";
import { AddIcon } from "../../../../shared/icons";
import { MEDIA_QUERY_PHONE, WHITE_COLOR } from "../../../../shared/theme";
import { ROUTE } from "../../../../routes";
import PageSpacing from "../../../../shared/pageSpacing";
import LALoading from "../../../../shared/loading";
import { ISimosProducts, ISimosProductsRequest } from "../../../../../redux/propsense/simos/simosProducts/getSimosProducts/getSimosProductsConstants";
import LAErrorBox from "../../../../shared/errorBox";
import { getSimosProductsLoadAction } from "../../../../../redux/propsense/simos/simosProducts/getSimosProducts/getSimosProductsActions";
import { getSimosProducts } from "../../../../../redux/propsense/simos/simosProducts/getSimosProducts/getSimosProductsAccessor";
import LAGrid from "../../../../shared/grid";
import LAGridItem from "../../../../shared/gridList";
import { SimosSubHeader, SimosSubHeaderMobile } from "../../../../header/simosHeader";
import queryString from "query-string";

interface ISimosProductsStoreProps {
    getToken: Server<SimosAPIResponse<IToken>>;
    getSimosProductsList: Server<SimosAPIResponse<ISimosProducts[]>>;
};

interface ISimosProductsDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getSimosProductsListRequest: (data: ISimosProductsRequest) => unknown;
};


interface ISimosProductsOwnProps {

};

interface ISimosProductsState {
    
};

const SimosProductsStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .loading {
        margin-top: 5%;
    };

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
    };

    .show-on-phone {
        display: none;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 5px 5px;

        .title {
            padding-top: 11%;
        };

        .pull-left {
            position: absolute;
            left: 3%;
            height: 3%;
            top: 1%;
        };

        .hide-on-phone, .hide-on-phone * {
            display: none;
        };

        .show-on-phone {
            display: block;
        };
    };
`;

type ISimosProductsProps = RouteComponentProps
    & ISimosProductsStoreProps
    & ISimosProductsDispatchProps
    & ISimosProductsOwnProps;

class SimosProducts extends PureComponent<ISimosProductsProps, ISimosProductsState> {

    public constructor(props: ISimosProductsProps) {
        super(props);
        this.state = {

        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: ISimosProductsProps): void {
        if (this.props !== prevProps) {
            this.callServer();
        };
    };


    public render(): ReactNode {

        const {  } = this.state;
        const { getSimosProductsList , getToken} = this.props;
        const data = hasPayload(getSimosProductsList) ? Object.values(getSimosProductsList.payload.response) : [];
        const getRole = hasPayload(getToken) && getToken.payload.response.simosAccess.access;
        // console.log(getRole)

        return (
            <PageSpacing title="SIMOS - Products" description="SIMOS - Products" fixedSpaceOnSmallerScreens={true}>
                {(getRole !== NotApplicable) &&
                    <SimosProductsStyles>

                        <div className="hide-on-phone">
                            <SimosSubHeader
                                {...this.props}
                            />
                        </div>

                        <div className="show-on-phone">
                            <SimosSubHeaderMobile
                                {...this.props}
                            />
                        </div>

                        <LAPaperWithPadding>
                            <LAGrid>
                                <LAGridItem xs={12}>
                                    <LAButton 
                                        className="pull-left" 
                                        startIcon={<AddIcon color={WHITE_COLOR} />} 
                                        label="Add" 
                                        onClick={this.handleAdd} 
                                    />
                                    <br></br>
                                    <h2 className="text-center">SIMOS PRODUCTS</h2>
                                    <hr />
                                    
                                    {getSimosProductsList.kind === STATUS_ENUM.LOADING && <LALoading className="loading" message="Loading..." />}
                                    {getSimosProductsList.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load Products..." />}

                                    {getSimosProductsList.kind === STATUS_ENUM.SUCCEEDED && 

                                        <LAGridItem xs={12} className="text-center">
                                            <LADevExtremeGrid
                                                data={data}
                                                onEdit={this.onEdit}
                                                onClick={this.onClick}
                                                height={1000}
                                                searchPanel={true}
                                                actionWidth={120}
                                                filterHeader={true}
                                                export={true}
                                                id="SimosProductsList"
                                                exportFileName="SimosProducts"
                                                storageKey="SimosProductsStorageKey"
                                                columns={[                               
                                                    { name: "description", caption: "Description", type: "string" },
                                                    { name: "code", caption: "Code", type: "string" },
                                                    { name: "size", caption: "Size", type: "string" },
                                                    { name: "location", caption: "Location", type: "string" },
                                                    { name: "created", caption: "Created", type: "datetime" },
                                                    { name: "created_By", caption: "Created By", type: "string" },
                                                    { name: "modified", caption: "Modified", type: "datetime" },
                                                    { name: "modified_By", caption: "Modified By", type: "string" }
                                                ]}
                                            />
                                        </LAGridItem>
                                    }
                                </LAGridItem>
                            </LAGrid>
                        </LAPaperWithPadding>
                    </SimosProductsStyles>
                }
            </PageSpacing>
        );
    }

    private onEdit = (e: any): void => {
        const query = queryString.parse(this.props.location.search);
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString(),  page: query.page ? query.page.toString() : ""},
            pathName: ROUTE.SIMOS.PRODUCT.ADDUPDATEPRODUCT
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        const query = queryString.parse(this.props.location.search);
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0" , page: query.page ? query.page.toString() : ""},
            pathName: ROUTE.SIMOS.PRODUCT.ADDUPDATEPRODUCT
        });
    };

    // private setDataToState = (): void => {

    //     if (hasPayload(this.props.getSimosProductsList)) {
    //         const data: ISimosProducts[] = Object.values(this.props.getSimosProductsList.payload.response);
    //         this.setState({ data });
    //     };

    //     if (isNotLoaded(this.props.getSimosProductsList))
    //         this.callServer();
    // };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });

        if (hasPayload(this.props.getToken)) {
            if (this.props.getToken.payload.response.simosAccess.access !== NotApplicable || this.props.getToken.payload.response.simosAccess.superAdmin !== true) {
                if (isNotLoaded(this.props.getSimosProductsList)) {
                    this.getDataForTable();
                };
            } else {
                this.props.history.push({
                    pathname: ROUTE.SIMOS.INDEX,
                });
            };
        };
    }

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken)) {
            this.props.getSimosProductsListRequest({
                token: this.props.getToken.payload.response.token
            });
        };
    };

}

const mapStateToProps = (state: IStore): ISimosProductsStoreProps => ({
    getToken: getTokenStatus(state),
    getSimosProductsList: getSimosProducts(state)
});

const mapDispatchToProps = (dispatch: IDispatch): ISimosProductsDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getSimosProductsListRequest: (data: ISimosProductsRequest) => dispatch(getSimosProductsLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(SimosProducts);