import { IBinAdjustments } from "../getBinAdjustments/getBinAdjustmentsConstaints";

export enum IADD_BIN_ADJUSTMENT_REQUEST {
    REQUEST = "addBinAdjustment/ADD_BIN_ADJUSTMENT_REQUEST",
    SUCCESS = "addBinAdjustment/ADD_BIN_ADJUSTMENT_SUCCESS",
    FAILED = "addBinAdjustment/ADD_BIN_ADJUSTMENT_FAILED"
};

export interface IAddBinAdjustmentRequest {
    token: string;
    request: IBinAdjustments;
};