import { ISaveAssignedCrewsRequest, ISAVE_ASSIGNED_CREWS_REQUEST } from "./saveAssignedCrewsConstaints";

export interface ISaveAssignedCrewsLoadAction {
    type: ISAVE_ASSIGNED_CREWS_REQUEST.REQUEST;
    data: ISaveAssignedCrewsRequest
}
export const saveAssignedCrewsLoadAction = (data: ISaveAssignedCrewsRequest): ISaveAssignedCrewsLoadAction => ({
    type: ISAVE_ASSIGNED_CREWS_REQUEST.REQUEST,
    data
});

export interface ISaveAssignedCrewsSuccessAction {
    type: ISAVE_ASSIGNED_CREWS_REQUEST.SUCCESS;
    message: string;
}
export const saveAssignedCrewsLoadSuccessAction = (message: string): ISaveAssignedCrewsSuccessAction => ({
    type: ISAVE_ASSIGNED_CREWS_REQUEST.SUCCESS,
    message
});
export interface ISaveAssignedCrewsLoadFailedAction {
    type: ISAVE_ASSIGNED_CREWS_REQUEST.FAILED;
    message: string;
}
export const saveAssignedCrewsLoadFailedAction = (message: string): ISaveAssignedCrewsLoadFailedAction => ({
    type: ISAVE_ASSIGNED_CREWS_REQUEST.FAILED,
    message
});
