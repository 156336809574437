import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IGetCarrierEmailsLoadAction, IGetCarrierEmailsLoadFailedAction, IGetCarrierEmailsSuccessAction, 
    getCarrierEmailsLoadFailedAction, getCarrierEmailsLoadSuccessAction
} from "./getCarrierEmailsActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IGET_CARRIER_EMAILS_REQUEST, ICarrierEmail } from "./getCarrierEmailsConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";

export const getCarrierEmailsEpic: Epic = (
    action$: ActionsObservable<IGetCarrierEmailsLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IGetCarrierEmailsSuccessAction | IGetCarrierEmailsLoadFailedAction> =>
    action$.ofType(IGET_CARRIER_EMAILS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<ICarrierEmail[]>>(
                    END_POINTS.PROPSENSE.SIL.CARRIER.GET_CARRIER_EMAIL,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<ICarrierEmail[]>): IGetCarrierEmailsSuccessAction => {
                            return getCarrierEmailsLoadSuccessAction(response);
                        }),
                        catchError(() => ActionsObservable.of(getCarrierEmailsLoadFailedAction("Unable to get carrier emails")))
                    )
            )
        );