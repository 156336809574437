import { IUPDATE_BAGGED_INVENTORY_REQUEST } from "./updateBaggedInventoryConstaints";
import { IUpdateBaggedInventoryLoadAction, IUpdateBaggedInventoryLoadFailedAction, IUpdateBaggedInventorySuccessAction } from "./updateBaggedInventoryActions";
import { IFlushDataSuccessAction } from "../../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../../flushData.tsx/flushDataConstants";
import { Server, notLoaded, loading, succeeded, failed } from "../../../../server";

type Actions =
    IUpdateBaggedInventoryLoadAction
    | IUpdateBaggedInventoryLoadFailedAction
    | IUpdateBaggedInventorySuccessAction
    | IFlushDataSuccessAction;

export const UpdateBaggedInventoryReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IUPDATE_BAGGED_INVENTORY_REQUEST.REQUEST:
            return loading;

        case IUPDATE_BAGGED_INVENTORY_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IUPDATE_BAGGED_INVENTORY_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};