import { IPassTokenWithRequest } from "../../../dryPlant/token/getTokenConstants";


export enum IADD_BAGGED_INVENTORY_REQUEST {
    REQUEST = "addBaggedInventory/ADD_BAGGED_INVENTORY_REQUEST",
    SUCCESS = "addBaggedInventory/ADD_BAGGED_INVENTORY_SUCCESS",
    FAILED = "addBaggedInventory/ADD_BAGGED_INVENTORY_FAILED"
};

export interface IAddBaggedInventoryRequest extends IPassTokenWithRequest {
    request: IAddUpdateBaggedInventory;
};

export interface IAddUpdateBaggedInventory {
    ID: number;
    Date: string;
    Count: number;
    Completed_By: string;
    Items: IBaggedInventoryItem[];
    Created_By: string;
    Modified_By: string;
};

export interface IBaggedInventoryItem {
    Product: string,
    Pallet_Size: string,
    Count: number,
}