import {currentUser} from '../../../../simosHome';

async function addNewProduct(product:any) {
    var res:any = null;
    let requestOptions = {
        method: 'POST',
        headers: { 'Content-Type':'application/json'},
        body: JSON.stringify({
            request: {
                Description:product.product_name,
                Code:product.product_code,
                Size:product.product_size,
                Location:product.product_location,
                Created_By:currentUser,
                Modified_By:currentUser
            },
            token: '19515195-d571-44a3-b0db-637f5bf24f54'})
    }
    await fetch('https://api2.propsense.com/api/AddSIMOSProductLookup', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
        // error response
        if(!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
      }
      res = response;
      // no error
    });
    return res;
}

export {addNewProduct};