import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithLessPadding, LAPaperWithPadding } from "../../../../shared/paper";
import { IDispatch, IStore } from "../../../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../../redux/server";
import { SimosAPIResponse } from "../../../../shared/publicInterfaces";
import { IGetTokenRequest, IToken } from "../../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenLoadAction } from "../../../../../redux/sil/dryPlant/token/getTokenActions";
import { NotApplicable, callRouteWithQueryString, undefinedFunction, userName } from "../../../../shared/constExports";
import { LADevExtremeGrid } from "../../../../shared/devExtreme";
import { getTokenStatus } from "../../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { LAButton, LAIconButton } from "../../../../shared/buttons";
import { AddIcon, DownloadIcon } from "../../../../shared/icons";
import { LIGHT_BLUE_COLOR, LIGHT_GREEN_COLOR, LIGHT_ORANGE_YELLOW_COLOR, LIGHT_RED_COLOR, MEDIA_QUERY_PHONE, WHITE_COLOR } from "../../../../shared/theme";
import { ROUTE } from "../../../../routes";
import PageSpacing from "../../../../shared/pageSpacing";
import LALoading from "../../../../shared/loading";
import LAErrorBox from "../../../../shared/errorBox";
import LAGrid from "../../../../shared/grid";
import LAGridItem from "../../../../shared/gridList";
import { SimosSubHeader, SimosSubHeaderMobile } from "../../../../header/simosHeader";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import queryString from "query-string";
import { IGetOrdersRequest, ISIMOSOrders } from "../../../../../redux/propsense/simos/getOrders/getOrdersConstaints";
import { getOrdersLoadAction } from "../../../../../redux/propsense/simos/getOrders/getOrdersActions";
import { getOrdersStatus } from "../../../../../redux/propsense/simos/getOrders/getOrdersAccessor";
import { END_POINTS } from "../../../../../redux/endpoints";
import { IGetOrderVersionHistoryRequest, ISIMOSOrderVersionHistory } from "../../../../../redux/propsense/simos/getOrderVersionHistory/getOrderVersionHistoryConstaints";
import { IGetProductsByIdRequest, ISIMOSProductsById } from "../../../../../redux/propsense/simos/getProductsById/getProductsByIdConstaints";
import { getProductsByIdLoadAction } from "../../../../../redux/propsense/simos/getProductsById/getProductsByIdActions";
import { getOrderVersionHistoryLoadAction } from "../../../../../redux/propsense/simos/getOrderVersionHistory/getOrderVersionHistoryActions";
import { getProductsById } from "../../../../../redux/propsense/simos/getProductsById/getProductsByIdAccessor";
import { getOrderVersionHistory } from "../../../../../redux/propsense/simos/getOrderVersionHistory/getOrderVersionHistoryAccessor";
import { LAPopover } from "../../../../shared/popOver";
import Tooltip from "devextreme-react/tooltip"
import { on } from "devextreme/events";

interface ISILBruOrdersStoreProps {
    getToken: Server<SimosAPIResponse<IToken>>;
    getSILBruOrders: Server<SimosAPIResponse<ISIMOSOrders[]>>;
    getVersionHistory: Server<SimosAPIResponse<ISIMOSOrderVersionHistory[]>>;
    getProducts: Server<SimosAPIResponse<ISIMOSProductsById[]>>;
};

interface ISILBruOrdersDispatchProps {
    getRequest: (data: IGetOrdersRequest) => unknown;
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getProductsRequest: (data: IGetProductsByIdRequest) => unknown;
    getVersionHistoryRequest: (data: IGetOrderVersionHistoryRequest) => unknown;
};


interface ISILBruOrdersOwnProps {

};

interface ISILBruOrdersState {
    view: string;
    versionPopup: boolean;
    productPopup: boolean;
    commentsTooltip: string | undefined;
    orderNumberTooltip: number | undefined;
    orderNumber: number | undefined;
};

const SILBruOrdersStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .loading {
        margin-top: 5%;
    };

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
    };

    .show-on-phone {
        display: none;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 5px 5px;

        .title {
            padding-top: 11%;
        };

        .pull-left {
            position: absolute;
            left: 3%;
            height: 3%;
            top: 1%;
        };

        .hide-on-phone, .hide-on-phone * {
            display: none;
        };

        .show-on-phone {
            display: block;
        };
    };
`;

type ISILBruOrdersProps = RouteComponentProps
    & ISILBruOrdersStoreProps
    & ISILBruOrdersDispatchProps
    & ISILBruOrdersOwnProps;

class SILBruOrders extends PureComponent<ISILBruOrdersProps, ISILBruOrdersState> {
    tooltip: Tooltip | null | undefined;
    
    public constructor(props: ISILBruOrdersProps) {
        super(props);
        this.state = {
            view: "All",
            versionPopup: false,
            productPopup: false,
            orderNumber: undefined,
            orderNumberTooltip: undefined,
            commentsTooltip: undefined,
        };
    }

    public componentDidMount(): void {
        this.getDataForTable();
        this.callServer();
    };

    public componentDidUpdate(prevProps: ISILBruOrdersProps): void {
        if (this.props !== prevProps) {
            this.callServer();
        };
    };


    public render(): ReactNode {

        const { view, versionPopup, productPopup } = this.state;
        const { getSILBruOrders, getToken, getProducts, getVersionHistory } = this.props;
        const getRole = hasPayload(getToken) && getToken.payload.response.simosAccess.access;
        const data = hasPayload(getSILBruOrders) ? getSILBruOrders.payload.response : [];
        const filterData = (view === "All") ? data : data.filter((x) => x.location === view && (x.status==='Assigned' || x.status ==='Initiated' || x.status==='Ready for pickup' || x.status === 'Loading'));
        // console.log(filterData)
        return (
            <PageSpacing title="SIMOS - Orders" description="SIMOS - Orders" fixedSpaceOnSmallerScreens={true}>
                {(getRole !== NotApplicable) &&
                    <SILBruOrdersStyles>

                        <div className="hide-on-phone">
                            <SimosSubHeader
                                {...this.props}
                            />
                        </div>

                        <div className="show-on-phone">
                            <SimosSubHeaderMobile
                                {...this.props}
                            />
                        </div>

                        <LAPaperWithPadding>
                            <LAGrid>
                                <LAGridItem xs={12}>
                                    <LAButton
                                        className="pull-left"
                                        startIcon={<AddIcon color={WHITE_COLOR} />}
                                        label="Add"
                                        onClick={this.handleAdd}
                                    />
                                    <br></br>
                                    <h2 className="text-center">SIL BRU Orders</h2>
                                    <hr />

                                    {getSILBruOrders.kind === STATUS_ENUM.LOADING && <LALoading className="loading" message="Loading..." />}
                                    {getSILBruOrders.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load Orders..." />}

                                    {getSILBruOrders.kind === STATUS_ENUM.SUCCEEDED &&
                                        <LAGrid>
                                            <LAGridItem xs={12}>
                                                <RadioGroup className="view-btn" row aria-label="" name="radioGroup" value={view} onChange={this.onViewClick}>
                                                    <FormControlLabel value="All" control={<Radio size="small" />} label="All" />
                                                    <FormControlLabel value="SIL BRU Warehouse" control={<Radio size="small" />} label="SIL Bru Warehouse" />
                                                    <FormControlLabel value="Bulk" control={<Radio size="small" />} label="Bulk" />
                                                    <LAIconButton label="Export" icon={<DownloadIcon />} onClick={this.handleExport} />
                                                </RadioGroup>
                                            </LAGridItem>

                                            <LAGridItem xs={12} className="text-center">
                                                <LADevExtremeGrid
                                                    data={filterData}
                                                    onEdit={this.onEdit}
                                                    onClick={this.onClick}
                                                    columnChoose={true}
                                                    height={1500}
                                                    columnsHiding={true}
                                                    export={true}
                                                    searchPanel={true}
                                                    actionWidth={120}
                                                    filterHeader={true}
                                                    id="SILBruOrdersList"
                                                    exportFileName="SILBruOrders"
                                                    onCustomClick={this.customClick}
                                                    onRowPrepared={this.rowPrepSILBruOrdersPage}
                                                    // onCellPrepared={this.cellPrepSILBruOrdersPage}
                                                    storageKey="SILBruOrdersStorageKey"
                                                    columns={[
                                                        { name: "id", caption: "Order #", type: "number", sortDesc: true, width: 80 },
                                                        { name: "pdf", caption: "Batch Summary", type: "button" },
                                                        { name: "customer", caption: "Customer", type: "string" },
                                                        { name: "pO_Number", caption: "PO #", type: "string" },
                                                        { name: "pickup_Datetime", caption: "Schedule Pickup Date", type: "datetime", width: 120 },
                                                        { name: "delivery_Datetime", caption: "Delivery Date", type: "datetime", width: 120 },
                                                        { name: "transport_Company", caption: "Transport Company", type: "string" },
                                                        { name: "products", caption: "Products", type: "string", width: 150,
                                                            renderCell: (e:any) => {
                                                                if (e.data) {
                                                                    const find = e.data["products"];
                                                                    const onClick = (): void => find &&  this.customClick("products", e) ;
                                                                    return <div>
                                                                        {find ? <div className="link-btn" onClick={onClick}>{find}</div> : ""}
                                                                    </div>
                                                                }
                                                            } 
                                                        },
                                                        { name: "status", caption: "Status", type: "string" },
                                                        { name: "comments", caption: "Comments", type: "string", width: 100,
                                                            renderCell: (e:any) => {
                                                                if (e.data) {
                                                                    const find = e.data["comments"];
                                                                    return <div>
                                                                        {find ? <div className="link-btn" onMouseOver={(event:any) => this.showTooltipForComments("mouseOver", event,e.data)} onMouseOut={(event:any) => this.showTooltipForComments("mouseOut",event,e.data)}>Comments</div> : ""}
                                                                    </div>
                                                                }
                                                            } 
                                                        },
                                                        { name: "history", caption: "Version History", type: "button" },
                                                        { name: "created", caption: "Created", type: "datetime", width: 120 },
                                                        { name: "created_By", caption: "Created By", type: "string", width: 120 },
                                                        { name: "modified", caption: "Modified", type: "datetime", show: false },
                                                        { name: "modified_By", caption: "Modified By", type: "string", show: false }
                                                    
                                                    ]}
                                                />
                                                <Tooltip ref={ref => this.tooltip = ref}position={'top'}>
                                                    <div style={{ maxWidth: "600px", height: "auto", wordWrap: "break-word", overflowWrap: "break-word" , whiteSpace: "normal" }}>
                                                        <div><b>{this.state.orderNumberTooltip && `Order# : ${this.state.orderNumberTooltip}`}</b></div>
                                                        <div><b>{this.state.commentsTooltip}</b></div>
                                                    </div>
                                                </Tooltip>
                                            </LAGridItem>

                                            {versionPopup && <SIMOSOrderVersionHistoryPopup
                                                data={getVersionHistory}
                                                onClose={this.closePopUp}
                                                orderNumber={this.state.orderNumber}
                                            />}

                                            {productPopup && <SIMOSProductsPopup
                                                data={getProducts}
                                                onClose={this.closePopUp}
                                                orderNumber={this.state.orderNumber}
                                            />}
                                        </LAGrid>
                                    }
                                </LAGridItem>
                            </LAGrid>
                        </LAPaperWithPadding>
                    </SILBruOrdersStyles>
                }
            </PageSpacing>
        );
    }

    private rowPrepSILBruOrdersPage = (e: any) => {
        // console.log(e)
        if(e.data) {
            e.columns.map((item: any, index: number) => {

                if(item.caption === "Status") {
                    if(e.data.status === "Loading") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = LIGHT_BLUE_COLOR;
                    }
                    if(e.data.status === "Completed") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = LIGHT_GREEN_COLOR;
                    }
                    if(e.data.status === "Ready for pickup") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = LIGHT_ORANGE_YELLOW_COLOR;
                    }
                    if(e.data.status === "Cancelled") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = LIGHT_RED_COLOR;
                    }
                }
            
            });
        }
    }

    // private cellPrepSILBruOrdersPage = (e: any) => {
    //     // console.log(e)
    //     if (e.rowType === "data" && e.column.caption === "Comments") {
    //         on(e.cellElement, "mouseover", (arg:any) => {
    //             this.setState({
    //               commentsTooltip: e.data.comments,
    //               orderNumberTooltip: e.data.id
    //             });
    //             if(e.data.comments)
    //                 this.tooltip && this.tooltip.instance.show(arg.target);
    //         });
        
    //         on(e.cellElement, "mouseout", (arg:any) => {
    //             this.setState({
    //                 commentsTooltip: undefined,
    //                 orderNumberTooltip: undefined
    //             });
    //             this.tooltip && this.tooltip.instance.hide();
    //         });
    //     }     
    // }

    private showTooltipForComments = (name: string, e:any, data:any) => {
        // console.log(e)
        if(name === "mouseOver") {
            if(data) {
                this.setState({
                    commentsTooltip: data.comments,
                    orderNumberTooltip: data.id
                });
                if(data.comments)
                    this.tooltip && this.tooltip.instance.show(e.target);
            }
        }
        else if(name === "mouseOut") {
            this.tooltip && this.tooltip.instance.hide();
            this.setState({
                commentsTooltip: undefined,
                orderNumberTooltip: undefined
            });
        }
    }

    private handleExport = (): void => {
        if (hasPayload(this.props.getToken)) {
            let view = this.state.view;

            if (view === "Bulk") {
                view = view.toLocaleLowerCase();
            } else if(view === "All"){
                view = "none"
            } else {
                view = "sil_bru_warehouse";
            };

            downloadOrdersExcel(view, "sil_bru", this.props.getToken.payload.response.token);
        };
    };

    private closePopUp = (): void => {
        this.setState({
            versionPopup: false,
            productPopup: false,
            orderNumber: undefined
        });
    };

    private customClick = (name: string, e: any): void => {
        if (hasPayload(this.props.getToken)) {
            const id = e.row.data.id;
            const token = this.props.getToken.payload.response.token;

            if (name === "pdf") {
                downloadSIMOSOrderTicketPDF(id, token);
            } else if (name === "products") {
                this.props.getProductsRequest({
                    token,
                    request: {
                        Order_ID: id
                    }
                });

                this.setState({
                    productPopup: true,
                    orderNumber: e.row.data.id
                });
            } else {
                this.props.getVersionHistoryRequest({
                    token,
                    id
                });

                this.setState({
                    versionPopup: true,
                    orderNumber: e.row.data.id
                });
            }
        }
    };

    private onViewClick = (event: unknown, view: string): void => {
        this.setState({ view });
    };

    private onEdit = (e: any): void => {
        const query = queryString.parse(this.props.location.search);
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString(), page: query.page ? query.page.toString() : "" },
            pathName: ROUTE.SIMOS.SIL_Bru_Orders.ADD_UPDATE
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        const query = queryString.parse(this.props.location.search);
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0", page: query.page ? query.page.toString() : "" },
            pathName: ROUTE.SIMOS.SIL_Bru_Orders.ADD_UPDATE
        });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });

        if (hasPayload(this.props.getToken)) {
            if (this.props.getToken.payload.response.simosAccess.access !== NotApplicable) {
                if (isNotLoaded(this.props.getSILBruOrders)) {
                    this.getDataForTable();
                };
            } else {
                this.props.history.push({
                    pathname: ROUTE.SIMOS.INDEX,
                });
            };
        };
    }

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken)) {
            this.props.getRequest({
                token: this.props.getToken.payload.response.token,
                request: {
                    Customer: "orderdesk",
                    Warehouse_Type: "SIL BRU Warehouse"
                }
            });
        };
    };

}

const mapStateToProps = (state: IStore): ISILBruOrdersStoreProps => ({
    getToken: getTokenStatus(state),
    getSILBruOrders: getOrdersStatus(state),
    getProducts: getProductsById(state),
    getVersionHistory: getOrderVersionHistory(state),
});

const mapDispatchToProps = (dispatch: IDispatch): ISILBruOrdersDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getRequest: (data: IGetOrdersRequest) => dispatch(getOrdersLoadAction(data)),
    getProductsRequest: (data: IGetProductsByIdRequest) => dispatch(getProductsByIdLoadAction(data)),
    getVersionHistoryRequest: (data: IGetOrderVersionHistoryRequest) => dispatch(getOrderVersionHistoryLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SILBruOrders);


export const downloadSIMOSOrderTicketPDF = (id: string, token: string): void => {
    fetch(END_POINTS.PROPSENSE.SIMOS.GET_ORDER_TICKET, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token, request: { id } })
    })
        .then(async response => {
            if (!response.ok) {
                const error = response.status;
                return Promise.reject(error);
            }
            response.blob().then(blob => {
                const fileURL = window.URL.createObjectURL(blob);
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = id + '.pdf';
                alink.click();
            });
        })
        .catch(error => {
            console.log('There was an error!', error);
        });
};

export const downloadOrdersExcel = async (view: string, type: string, token: string): Promise<void> => {
    const url = END_POINTS.PROPSENSE.SIMOS.DOWNLOAD_ORDER_EXCEL_FILE;
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ request: { view, type }, token })
    }

    try {
        const response = await fetch(url, requestOptions);
        if (response.ok) {
            const blob = await response.blob();
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Orders.xlsx');
            document.body.appendChild(link);
            link.click();
            if (link && link.parentNode)
                link.parentNode.removeChild(link);
        } else {
            console.error('Export failed:', response.statusText);
        }
    } catch (error) {
        console.error('Export failed:', error);
    }
}

interface IPopup<T> {
    onClose: () => void;
    data: Server<SimosAPIResponse<T[]>>;
    orderNumber: number | undefined;
}

export const SIMOSOrderVersionHistoryPopup = (props: IPopup<ISIMOSOrderVersionHistory>): JSX.Element => {
    const status = props.data.kind;
    const items = hasPayload(props.data) ? props.data.payload.response : [];
    // console.log(items)
    return (
        <LAPopover open={true} onClose={props.onClose} anchorRef={null} transformOrigin={{ vertical: "top", horizontal: "center" }} anchorOrigin={{ vertical: "top", horizontal: "left" }}>
            <LAPaperWithLessPadding>
                <h3 className="text-center">SIMOS Order Version History {props.orderNumber ? `(#${props.orderNumber})` : ""}</h3>

                {status === STATUS_ENUM.LOADING && <LALoading className="loading" message="Loading..." />}
                {status === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load Orders version history..." />}

                {status === STATUS_ENUM.SUCCEEDED &&
                    <LADevExtremeGrid
                        data={items}
                        height={600}
                        columnsHiding={true}
                        id="simosOrderVersionHistory"
                        searchPanel={true}
                        export={true}
                        storageKey="simosOrderVersionHistoryStorageKey"
                        filterHeader={true}
                        onClick={undefinedFunction}
                        columns={[
                            { name: "modified", caption: "Modified", type: "datetime", sortDesc: true },
                            { name: "modified_By", caption: "Modified By", type: "string" },
                            { name: "customer", caption: "Customer", type: "string" },
                            { name: "address", caption: "Address", type: "string" },
                            { name: "sitecontact", caption: "Site Contact", type: "string" },
                            { name: "sitecontact_Phone", caption: "Site Contact Phone", type: "string" },
                            { name: "pO_Number", caption: "PO Number", type: "string" },
                            { name: "emailuser", caption: "Email User", type: "string" },
                            { name: "emailtransport", caption: "Email Transport", type: "string" },
                            { name: "transport_Company", caption: "Transport Company", type: "string" },
                            { name: "truck_Required", caption: "Truck Required", type: "string" },
                            { name: "location", caption: "Location", type: "string" },
                            { name: "comments", caption: "Comments", type: "string" },
                            { name: "packaged", caption: "Packaged", type: "string" },
                            { name: "completed", caption: "Completed", type: "string" },
                            { name: "cancelled", caption: "Cancelled", type: "string" },
                            { name: "truck_Number", caption: "Truck Number", type: "string" },
                            { name: "trailer_Number", caption: "Trailer Number", type: "string" },
                            { name: "tare_Weight", caption: "Tare Weight", type: "string" },
                            { name: "gross_Weight", caption: "Gross Weight", type: "string" },
                            { name: "in_Bin", caption: "In Bin", type: "string" },
                            { name: "status", caption: "Status", type: "string" },
                            { name: "pickup", caption: "Pickup", type: "string" },
                            { name: "last_Email_Sent_Timestamp", caption: "Last Email Sent Timestamp", type: "string" },
                            { name: "created", caption: "Created", type: "string" },
                            { name: "created_By", caption: "Created By", type: "string" },
                            { name: "total_Tonnage", caption: "Total Tonnage", type: "string" },
                            { name: "order_Type", caption: "Order Type", type: "string" },
                            { name: "transport_Company_Notes", caption: "Transport Company Notes", type: "string" },
                            { name: "pickup_Datetime", caption: "Pickup Datetime", type: "string" },
                            { name: "delivery_Datetime", caption: "Delivery Datetime", type: "string" },
                            { name: "operation", caption: "Operation", type: "number" },
                            
                        ]}
                    />
                }
            </LAPaperWithLessPadding>
        </LAPopover>
    );
};

export const SIMOSProductsPopup = (props: IPopup<ISIMOSProductsById>): JSX.Element => {
    const status = props.data.kind;
    const items = hasPayload(props.data) ? props.data.payload.response : [];
    return (
        <LAPopover open={true} onClose={props.onClose} anchorRef={null} transformOrigin={{ vertical: "top", horizontal: "center" }} anchorOrigin={{ vertical: "top", horizontal: "left" }}>
            <LAPaperWithLessPadding>
                <h3 className="text-center">Products Ordered {props.orderNumber ? `(#${props.orderNumber})` : ""}</h3>

                {status === STATUS_ENUM.LOADING && <LALoading className="loading" message="Loading..." />}
                {status === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load Orders version history..." />}

                {status === STATUS_ENUM.SUCCEEDED &&
                    <LADevExtremeGrid
                        data={items}
                        height={400}
                        columnsHiding={true}
                        id="productOrdered"
                        searchPanel={true}
                        export={true}
                        storageKey="productOrderedStorageKey"
                        filterHeader={true}
                        onClick={undefinedFunction}
                        columns={[
                            { name: "quantity", caption: "Quantity", type: "number" },
                            { name: "size", caption: "Size", type: "string" },
                            { name: "per", caption: "Per", type: "string" },
                            { name: "product", caption: "Product", type: "string" },
                            { name: "code", caption: "Code", type: "string" },
                            { name: "wrapping", caption: "Wrapping", type: "string" },
                            { name: "batch", caption: "Batch #", type: "string" },
                        ]}
                    />
                }
            </LAPaperWithLessPadding>
        </LAPopover>
    );
}