import { YHDAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../../server";
import { IGET_YHD_CARRIER_FLEET_TRUCK_REQUEST, IYHDCarrierFleetTruck } from "./getCarrierFleetTruckConstaints";
import { IGetYHDCarrierFleetTruckLoadAction, IGetYHDCarrierFleetTruckLoadFailedAction, IGetYHDCarrierFleetTruckSuccessAction } from "./getCarrierFleetTruckActions";
import { IAddYHDCarrierFleetTruckSuccessAction } from "../addCarrierFleetTruck/addCarrierFleetTruckActions";
import { IUpdateYHDCarrierFleetTruckSuccessAction } from "../updateCarrierFleetTruck/updateCarrierFleetTruckActions";
import { IADD_YHD_CARRIER_FLEET_TRUCK_REQUEST } from "../addCarrierFleetTruck/addCarrierFleetTruckConstaints";
import { IUPDATE_YHD_CARRIER_FLEET_TRUCK_REQUEST } from "../updateCarrierFleetTruck/updateCarrierFleetTruckConstaints";

type Actions =
    IGetYHDCarrierFleetTruckLoadAction
    | IGetYHDCarrierFleetTruckLoadFailedAction
    | IGetYHDCarrierFleetTruckSuccessAction
    | IAddYHDCarrierFleetTruckSuccessAction
    | IUpdateYHDCarrierFleetTruckSuccessAction
    | IFlushDataSuccessAction;

export const GetYHDCarrierFleetTruckReducer = (state: Server<YHDAPIResponse<IYHDCarrierFleetTruck[]>> = notLoaded, action: Actions): Server<YHDAPIResponse<IYHDCarrierFleetTruck[]>> => {
    switch (action.type) {
        case IGET_YHD_CARRIER_FLEET_TRUCK_REQUEST.REQUEST:
            return loading;

        case IGET_YHD_CARRIER_FLEET_TRUCK_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_YHD_CARRIER_FLEET_TRUCK_REQUEST.FAILED:
            return failed(action.message);

        case IADD_YHD_CARRIER_FLEET_TRUCK_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_YHD_CARRIER_FLEET_TRUCK_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};