import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../../shared/paper";
import { IDispatch, IStore } from "../../../../../redux/reducers";
import { getYHDCarrierEmailsStatus } from "../../../../../redux/propsense/yhd/carrier/getCarrierEmails/getCarrierEmailsAccessor";
import { getYHDCarrierEmailsLoadAction } from "../../../../../redux/propsense/yhd/carrier/getCarrierEmails/getCarrierEmailsActions";
import { IYHDCarrierEmail, IGetYHDCarrierEmailsRequest } from "../../../../../redux/propsense/yhd/carrier/getCarrierEmails/getCarrierEmailsConstaints";
import { hasPayload, isNotLoaded, isSucceeded, Server } from "../../../../../redux/server";
import { ById, YHDAPIResponse } from "../../../../shared/publicInterfaces";
import { userName, undefinedFunction, ZEROTH, deleteText } from "../../../../shared/constExports";
import { LAButton, LAIconButton, LASaveAndCancelButton } from "../../../../shared/buttons";
import { ArrowFastForwardIcon, ArrowFastRewindIcon, ArrowLeftIcon, ArrowRightIcon, DeleteIcon } from "../../../../shared/icons";
import { BLUE_COLOR, RED_COLOR, WHITE_COLOR } from "../../../../shared/theme";
import LAGrid from "../../../../shared/grid";
import LAGridItem from "../../../../shared/gridList";
import LATextField from "../../../../shared/textField";
import LAAutoComplete from "../../../../shared/autoComplete";
import { ROUTE } from "../../../../routes";
import queryString from "query-string";
import { updateYHDCarrierEmailLoadAction } from "../../../../../redux/propsense/yhd/carrier/updateCarrierEmail/updateCarrierEmailActions";
import { updateYHDCarrierEmailStatus } from "../../../../../redux/propsense/yhd/carrier/updateCarrierEmail/updateCarrierEmailAccessor";
import { IUpdateYHDCarrierEmailRequest } from "../../../../../redux/propsense/yhd/carrier/updateCarrierEmail/updateCarrierEmailConstaints";
import { IAddYHDCarrierEmailAttachment, IAddYHDCarrierEmailRequest } from "../../../../../redux/propsense/yhd/carrier/addCarrierEmail/addCarrierEmailConstaints";
import { addYHDCarrierEmailStatus } from "../../../../../redux/propsense/yhd/carrier/addCarrierEmail/addCarrierEmailAccessor";
import { addCarrierEmailLoadAction } from "../../../../../redux/propsense/yhd/carrier/addCarrierEmail/addCarrierEmailActions";
import RequestStatus from "../../../../shared/requestStatusSnackbar";
import { FieldValidator, FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../../../shared/fieldValidator";
import PageSpacing from "../../../../shared/pageSpacing";
import { IGetTokenRequest, IToken } from "../../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenStatus } from "../../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { getTokenLoadAction } from "../../../../../redux/sil/dryPlant/token/getTokenActions";
import LATextArea from "../../../../shared/textArea";
import { END_POINTS } from "../../../../../redux/endpoints";
import { getYHDCarrierLookupsStatus } from "../../../../../redux/propsense/yhd/carrier/getCarrierLookups/getCarrierLookupsAccessor";
import { getYHDCarrierLookupsLoadAction } from "../../../../../redux/propsense/yhd/carrier/getCarrierLookups/getCarrierLookupsActions";
import { IYHDCarrierLookup, IGetYHDCarrierLookupsRequest } from "../../../../../redux/propsense/yhd/carrier/getCarrierLookups/getCarrierLookupsConstaints";
import React from "react";
import Paper from '@mui/material/Paper';
import { Checkbox, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import LAErrorBox from "../../../../shared/errorBox";
import SilRoleCheck from "../../../../shared/silRoleCheck";
import { LADropDone } from "../../../../shared/dragDropFiles";
import LALinkButton from "../../../../shared/linkButton";
import { activeOptions } from "../../../sil/dryPlant/dryPlantItemForm";


interface ICarrierEmailStoreProps {
    addCarrierEmail: Server<string>;
    updateCarrierEmail: Server<string>;
    getToken: Server<YHDAPIResponse<IToken>>;
    getCarrierEmails: Server<YHDAPIResponse<IYHDCarrierEmail[]>>;
    getCarrierLookups: Server<YHDAPIResponse<IYHDCarrierLookup[]>>;
};

interface ICarrierEmailDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getCarrierEmailsRequest: (data: IGetYHDCarrierEmailsRequest) => unknown;
    addCarrierEmailRequest: (data: IAddYHDCarrierEmailRequest) => unknown;
    updateCarrierEmailRequest: (data: IUpdateYHDCarrierEmailRequest) => unknown;
    getCarrierLookupsRequest: (data: IGetYHDCarrierLookupsRequest) => unknown;
};


interface ICarrierEmailOwnProps {

};

interface ICarrierEmailState {
    email: IYHDCarrierEmail;
    listOneSearch: string;
    listTwoSearch: string;
    attachments: IAddYHDCarrierEmailAttachment[];
    errors: ById<IFieldErrorKeyValue>;
    carriers: IYHDCarrierLookup[];
    addedToList: IYHDCarrierLookup[];
    listOneSelected: IYHDCarrierLookup[];
    listTwoSelected: IYHDCarrierLookup[];
};

const CarrierEmailStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;

    .text-left {
        text-align: left;
        padding-left: 5px;
    };
`;

type ICarrierEmailProps = RouteComponentProps
    & ICarrierEmailStoreProps
    & ICarrierEmailDispatchProps
    & ICarrierEmailOwnProps;

class YHDCarrierEmail extends PureComponent<ICarrierEmailProps, ICarrierEmailState> {

    public constructor(props: ICarrierEmailProps) {
        super(props);
        this.state = {
            errors: {
                "subject": { key: "subject", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "email_Body": { key: "email_Body", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
            },
            email: {
                id: 0,
                subject: "",
                email_Body: "",
                email_All_Carriers: "No",
                carriers: "",
                attachment_Name: "",
                created: "",
                created_By: userName,
                modified: "",
                modified_By: userName
            },
            attachments: [],
            carriers: [],
            addedToList: [],
            listOneSelected: [],
            listTwoSelected: [],
            listOneSearch: "",
            listTwoSearch: ""
        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: ICarrierEmailProps): void {

        if (this.props !== prevProps) {
            this.callServer();

            if (this.props.addCarrierEmail !== prevProps.addCarrierEmail) {
                if (isSucceeded(this.props.addCarrierEmail)) {
                    this.handleCancel();
                };
            };

            if (this.props.updateCarrierEmail !== prevProps.updateCarrierEmail) {
                if (isSucceeded(this.props.updateCarrierEmail)) {
                    this.handleCancel();
                };
            };
        };
    };


    public render(): ReactNode {

        const { updateCarrierEmail, addCarrierEmail } = this.props;
        const { email, errors, listOneSelected, listTwoSelected, addedToList, carriers, attachments, listOneSearch, listTwoSearch } = this.state;

        const isAdd = email.id > 0 ? false : true;

        const eAttachments = (email && email.attachment_Name) ? email.attachment_Name.split(";") : [];

        return (
            <PageSpacing title="YHD - Carrier" description="Carrier Email" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="yhdCarrierAccess">
                    <CarrierEmailStyles>

                        <LAButton startIcon={<ArrowLeftIcon color={WHITE_COLOR} />} label="Back to list" onClick={this.handleCancel} />
                        <h2 className="text-center">{!isAdd ? "VIEW " : "ADD "} CARRIER EMAIL</h2>
                        <hr />

                        <LAPaperWithPadding>
                            <LAGrid spacing={3}>

                                <LAGridItem xs={12}>
                                    <LATextField
                                        varient="outlined"
                                        label="Subject"
                                        fullWidth={true}
                                        name="subject"
                                        value={email.subject}
                                        onChange={this.onChange}
                                        disabled={isAdd ? undefined : true}
                                        errorText={errors["subject"] ? errors["subject"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12}>
                                    <LATextArea
                                        minRows={8}
                                        rowsMax={10}
                                        label="Email Body"
                                        fullWidth={true}
                                        name="email_Body"
                                        onChange={this.onChange}
                                        value={email.email_Body}
                                        disabled={isAdd ? undefined : true}
                                        errorText={errors["email_Body"] ? errors["email_Body"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={6}>
                                    <LAAutoComplete
                                        filterSelectedOptions={true}
                                        autoHighlight={true}
                                        selectionRemove={undefinedFunction}
                                        dropDownPlaceHolder="Email All Carriers"
                                        getOptionLabel="name"
                                        name="email_All_Carriers"
                                        option={activeOptions}
                                        multiple={false}
                                        disabled={isAdd ? undefined : true}
                                        onChange={this.handleDropDownChange}
                                        value={activeOptions.find(x => x.name === email.email_All_Carriers)}
                                        defaultValue={activeOptions.find(x => x.name === email.email_All_Carriers)}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={6} className="text-center">
                                    <strong>Upload Attachments</strong>
                                    <LADropDone onFileDrop={(e: Array<File | DataTransferItem>) => isAdd ? this.handleAtt(e) : undefinedFunction} />

                                    <LAGrid>
                                        {eAttachments.map((fName, ind) => {
                                            const findFile = attachments ? attachments.find(r => r.Name === fName)?.Base64String : undefined;
                                            const onAttFileDownload = (): void => this.handleDownloadAtt(fName, findFile);
                                            const onRemove = (event: any): void => this.handleDeleteAtt(fName)
                                            return (
                                                <LAGridItem key={ind} xs={6}>

                                                    <LALinkButton
                                                        className="pl-5"
                                                        label={fName}
                                                        onClick={onAttFileDownload} 
                                                    />

                                                    <LAIconButton
                                                        name={fName}
                                                        label="Remove"
                                                        icon={<DeleteIcon />}
                                                        onClick={onRemove} 
                                                    />
                                                </LAGridItem>
                                            )
                                        })}
                                    </LAGrid>
                                </LAGridItem>

                                {email.email_All_Carriers === "No" && <LAGridItem xs={6}>
                                    {isAdd ? <LAGrid className="text-center">

                                        <LAGridItem xs={12}>
                                            {addedToList.length === 0 ? <LAErrorBox text="Please select at least one carrier to be notified" />
                                                : <LAErrorBox text="Please select carriers to be notified" />}
                                        </LAGridItem>

                                        <LAGridItem xs={5}>
                                            <CarrierList
                                                {...this.state}
                                                items={carriers}
                                                title="Carriers List"
                                                selected={listOneSelected}
                                                onCheck={this.listOneCheck}

                                                search={listOneSearch}
                                                onSearch={this.handleListOneSearch}

                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={2}>
                                            <LAGrid>

                                                <LAGridItem xs={12}>
                                                    <LAIconButton
                                                        label="Move All to Right"
                                                        onClick={this.onMoveAllRight}
                                                        icon={<ArrowFastForwardIcon color={RED_COLOR} />}
                                                    />
                                                </LAGridItem>

                                                <LAGridItem xs={12}>
                                                    <LAIconButton
                                                        label="Move Selected to Right"
                                                        onClick={this.onMoveRight}
                                                        icon={<ArrowRightIcon color={RED_COLOR} />}
                                                    />
                                                </LAGridItem>

                                                <LAGridItem xs={12}>
                                                    <LAIconButton
                                                        label="Move Selected To Left"
                                                        onClick={this.onMoveLeft}
                                                        icon={<ArrowLeftIcon color={BLUE_COLOR} />}
                                                    />
                                                </LAGridItem>

                                                <LAGridItem xs={12}>
                                                    <LAIconButton
                                                        label="Move All to Left"
                                                        onClick={this.onMoveAllLeft}
                                                        icon={<ArrowFastRewindIcon color={BLUE_COLOR} />}
                                                    />
                                                </LAGridItem>
                                            </LAGrid>
                                        </LAGridItem>

                                        <LAGridItem xs={5}>
                                            <CarrierList
                                                {...this.state}
                                                title="Selected"
                                                items={addedToList}
                                                selected={listTwoSelected}                                                
                                                onCheck={this.listTwoCheck}
                                                search={listTwoSearch}
                                                onSearch={this.handleListTwoSearch}
                                            />
                                        </LAGridItem>

                                    </LAGrid>
                                        : <LAGrid className="text-center">
                                            <LAGridItem xs={5}>
                                                <CarrierList
                                                    {...this.state}
                                                    title="Email sent - Carriers List"
                                                    items={addedToList}                                                   
                                                    selected={listTwoSelected}
                                                    search={listTwoSearch}
                                                    onCheck={this.listTwoCheck}
                                                    disable={isAdd ? true : undefined}
                                                    onSearch={this.handleListTwoSearch}
                                                />
                                            </LAGridItem>

                                        </LAGrid>}
                                </LAGridItem>}

                                <LAGridItem xs={12}>
                                    <hr />
                                    <LASaveAndCancelButton
                                        onSave={this.onSave}
                                        onCancel={this.handleCancel}
                                        disableSave={!isAdd ? true : (Object.values(errors).length > 0 ? true : (email.email_All_Carriers === "No" && addedToList.length === 0) ? true : undefined)}
                                    />
                                </LAGridItem>

                            </LAGrid>
                        </LAPaperWithPadding>

                        <RequestStatus requestStatus={addCarrierEmail.kind} successMessage="Carrier Email successfully saved" />
                        <RequestStatus requestStatus={updateCarrierEmail.kind} successMessage="Carrier Email successfully updated" />
                    </CarrierEmailStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private handleListOneSearch = (name: string, value: string): void => {
        this.setState({
            listOneSearch: value
        });
    };

    private handleListTwoSearch = (name: string, value: string): void => {
        this.setState({
            listTwoSearch: value
        });
    };

    private listOneCheck = (obj: IYHDCarrierLookup): void => {
        const listOneSelected = [...this.state.listOneSelected];
        const index = listOneSelected.indexOf(obj);
        if (index !== -1) {
            listOneSelected.splice(index, 1);
        } else {
            listOneSelected.push(obj);
        };
        this.setState({ listOneSelected });
    };

    private listTwoCheck = (obj: IYHDCarrierLookup): void => {
        const listTwoSelected = [...this.state.listTwoSelected];
        const index = listTwoSelected.indexOf(obj);
        if (index !== -1) {
            listTwoSelected.splice(index, 1);
        } else {
            listTwoSelected.push(obj);
        };
        this.setState({ listTwoSelected });
    };

    private onMoveRight = (): void => {
        const carriers = [...this.state.carriers];
        const addedToList = [...this.state.addedToList];
        const listOneSelected = this.state.listOneSelected;

        listOneSelected.forEach(x => {
            carriers.splice(carriers.indexOf(x), 1);
            addedToList.push(x);
        });

        this.setState({ addedToList, listOneSelected: [], listTwoSelected: [], carriers });
    };

    private onMoveLeft = (): void => {
        const carriers = [...this.state.carriers];
        const addedToList = [...this.state.addedToList];
        const listTwoSelected = this.state.listTwoSelected;

        listTwoSelected.forEach(x => {
            addedToList.splice(addedToList.indexOf(x), 1);
            carriers.push(x);
        });

        this.setState({ addedToList, listOneSelected: [], listTwoSelected: [], carriers });
    };

    private onMoveAllRight = (): void => {
        const carriers = this.state.carriers;
        const addedToList = [...this.state.addedToList];

        carriers.forEach(x => {
            addedToList.push(x);
        });

        this.setState({ addedToList, listOneSelected: [], listTwoSelected: [], carriers: [] });
    };

    private onMoveAllLeft = (): void => {
        const addedToList = this.state.addedToList;
        const carriers = [...this.state.carriers];

        addedToList.forEach(x => {
            carriers.push(x);
        });

        this.setState({ addedToList: [], listOneSelected: [], listTwoSelected: [], carriers });
    };

    private handleDeleteAtt = (fName: string): void => {
        const selection = window.confirm(deleteText);
        if(selection) {
            const iS = { ...this.state.email };
            const atts = [...this.state.attachments];
    
            const eAttachments = iS.attachment_Name ? iS.attachment_Name.split(";") : [];
    
            const findIsIndex = eAttachments.findIndex(x => x === fName);
            const findIndex = atts.findIndex(x => x.Name === fName);
    
            if (findIndex >= 0)
                atts.splice(findIndex, 1);
    
            if (findIsIndex >= 0)
                eAttachments.splice(findIsIndex, 1);
    
            const newAtts = eAttachments.join(";");
    
            this.setState({
                email: {
                    ...this.state.email,
                    attachment_Name: newAtts
                },
                attachments: atts
            });
        }
    };

    private handleDownloadAtt = (fName: string, findFile?: string): void => {
        const { email } = this.state;

        if (fName) {
            if (findFile !== undefined) {
                let a = document.createElement("a");
                a.href = findFile;
                a.download = fName;
                a.click();
            };

            if (findFile === undefined) {
                if (hasPayload(this.props.getToken) && email.id) {

                    fetch(END_POINTS.PROPSENSE.YHD.CARRIER.GET_CARRIER_EMAIL_FILE, {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            request: {
                                "Carrier_Email_ID": email.id,
                                "File": fName
                            },
                            token: this.props.getToken.payload.response.token
                        })
                    })
                        .then((res: any) => {
                            return res.blob();
                        })
                        .then(blob => {
                            if (fName) {
                                let url = window.URL.createObjectURL(blob);
                                let a = document.createElement('a');
                                a.href = url;
                                a.download = fName;
                                document.body.appendChild(a);
                                a.click();
                                a.remove();
                            }
                        })
                        .catch((res) => console.log(res));
                };
            };
        }
    };

    private handleAtt = (files: Array<File | DataTransferItem>): void => {
        new Promise((resolve, reject): any => {
            if (files && (files !== null)) {

                const atts = [...this.state.attachments];
                const iS = { ...this.state.email };
                const eAttachments = iS.attachment_Name ? iS.attachment_Name.split(";") : [];

                Object.values(files).forEach((x: any, index, array) => {
                    const reader = new FileReader();

                    reader.onloadend = async (e): Promise<void> => {
                        if (reader.result !== null) {

                            eAttachments.push(x.name);
                            iS.attachment_Name = eAttachments.join(";");

                            atts.push({
                                Name: x.name,
                                Base64String: reader.result.toString()
                            });

                            this.setState({
                                ...this.state,
                                email: iS,
                                attachments: atts
                            });
                            resolve(true);
                        };
                    };
                    reader.readAsDataURL(x);
                })
            } else {
                reject();
            }
        });
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, isRequired: boolean): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: isRequired ? true : undefined, minLength: 1, decimal: undefined });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        };
        return err;
    };

    private handleCancel = (): void => {
        this.props.history.push(ROUTE.PROPSENSE.YHD.CARRIERS.CARRIER_EMAILS);
    };

    private onSave = (): void => {
        const { email, attachments, addedToList } = this.state;
        if (hasPayload(this.props.getToken))
            if (email.id === 0) {
                this.props.addCarrierEmailRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        subject: email.subject,
                        email_Body: email.email_Body,
                        email_All_Carriers: email.email_All_Carriers,
                        carriers: addedToList.map(({ id }) => id),
                        attachments,
                        attachment_Name: email.attachment_Name,
                        created_By: email.created_By,
                        modified_By: email.modified_By
                    }
                });
            } else {
                let data = this.state.email;
                data.modified_By = userName;
                this.props.updateCarrierEmailRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        id: email.id,
                        subject: email.subject,
                        email_Body: email.email_Body,
                        email_All_Carriers: email.email_All_Carriers,
                        carriers: addedToList.map(({ id }) => id),
                        attachments,
                        attachment_Name: email.attachment_Name,
                        created_By: email.created_By,
                        modified_By: email.modified_By
                    }
                });
            };
    };

    private handleDropDownChange = (event: unknown, value: { id: number, name: string } | "", name?: string): void => {
        if (name)
            this.setState({
                ...this.state,
                email: {
                    ...this.state.email,
                    [name]: value !== "" ? value.name : ""
                }
            });
    };


    private onChange = (name: string, value: string): void => {
        let errors = this.state.errors;
        errors = this.errorChecker(name, value, errors, true);

        this.setState({
            ...this.state,
            errors,
            email: {
                ...this.state.email,
                [name]: value
            }
        });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });

        if (hasPayload(this.props.getCarrierLookups)) {
            this.setState({ carriers: this.props.getCarrierLookups.payload.response });
        };

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getCarrierLookups))
            this.props.getCarrierLookupsRequest({
                token: this.props.getToken.payload.response.token,
                requested_Page: "Carriers_Email"
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getCarrierEmails))
            this.props.getCarrierEmailsRequest({
                token: this.props.getToken.payload.response.token
            });


        const query = queryString.parse(this.props.location.search);
        const id = query.id ?? "0";

        if ((id !== "0") && (hasPayload(this.props.getCarrierEmails)) && hasPayload(this.props.getCarrierLookups)) {
            const email = this.props.getCarrierEmails.payload.response.find(x => x.id === Number(id));
            const apiRes = this.props.getCarrierLookups.payload.response;
            const addedToList = [...this.state.addedToList];
            const carriers: IYHDCarrierLookup[] = [];

            if (email) {
                if ((email.carriers !== null) && (email.carriers !== undefined)) {
                    const pre = email.carriers.split(";");
                    apiRes.forEach(x => {
                        const index = pre.findIndex(q => q === x.name);
                        if (index !== -1) {
                            addedToList.push(x);
                        } else {
                            carriers.push(x);
                        }
                    });
                }

                this.setState({ email, errors: {}, addedToList, carriers, listOneSelected: [], listTwoSelected: [] });
            }
        };

    };

}

const mapStateToProps = (state: IStore): ICarrierEmailStoreProps => ({
    getToken: getTokenStatus(state),
    getCarrierEmails: getYHDCarrierEmailsStatus(state),
    addCarrierEmail: addYHDCarrierEmailStatus(state),
    updateCarrierEmail: updateYHDCarrierEmailStatus(state),
    getCarrierLookups: getYHDCarrierLookupsStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): ICarrierEmailDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getCarrierEmailsRequest: (data: IGetYHDCarrierEmailsRequest) => dispatch(getYHDCarrierEmailsLoadAction(data)),
    addCarrierEmailRequest: (data: IAddYHDCarrierEmailRequest) => dispatch(addCarrierEmailLoadAction(data)),
    updateCarrierEmailRequest: (data: IUpdateYHDCarrierEmailRequest) => dispatch(updateYHDCarrierEmailLoadAction(data)),
    getCarrierLookupsRequest: (data: IGetYHDCarrierLookupsRequest) => dispatch(getYHDCarrierLookupsLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(YHDCarrierEmail);


interface IListProps<T> {
    items: T[];
    selected: T[];
    title: string;
    disable?: true;
    search?: string;
    onCheck: (obj: IYHDCarrierLookup) => void;
    onSearch?: (name: string, value: string) => void;
};

const CarrierList: React.FC<IListProps<IYHDCarrierLookup>> = React.memo((props: IListProps<IYHDCarrierLookup>): JSX.Element =>
    <Paper sx={{ width: 300, height: 250, overflow: "auto" }}>
        <strong>{props.title}</strong>
        <hr />

        {(props.onSearch && props.items.length > ZEROTH) && <LATextField
            name="search"
            label="Search"
            className="text-left"
            onChange={props.onSearch}
            value={props.search ?? ""}
        />}

        <List dense component="div" role="list">
            {props.items
                .filter(x => props.search ? x.name.toLowerCase().includes(props.search.toLowerCase()) : true)
                .map((x: IYHDCarrierLookup, index: number) => {
                    const onSelect = (): void => props.onCheck(x);
                    return (
                        <ListItem
                            role="listitem"
                            button
                            key={index}
                            onClick={onSelect}
                        >
                            {props.disable && <ListItemIcon>
                                <Checkbox
                                    tabIndex={-1}
                                    disableRipple
                                    checked={props.selected.indexOf(x) !== -1}
                                />
                            </ListItemIcon>}
                            <ListItemText id={x.name} primary={x.name} />
                        </ListItem>
                    );
                })}
        </List>
    </Paper>
);