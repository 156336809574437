import { IUpdateYHDCarrierFleetTruckRequest, IUPDATE_YHD_CARRIER_FLEET_TRUCK_REQUEST } from "./updateCarrierFleetTruckConstaints";

export interface IUpdateYHDCarrierFleetTruckLoadAction {
    type: IUPDATE_YHD_CARRIER_FLEET_TRUCK_REQUEST.REQUEST;
    data: IUpdateYHDCarrierFleetTruckRequest
}
export const updateYHDCarrierFleetTruckLoadAction = (data: IUpdateYHDCarrierFleetTruckRequest): IUpdateYHDCarrierFleetTruckLoadAction => ({
    type: IUPDATE_YHD_CARRIER_FLEET_TRUCK_REQUEST.REQUEST,
    data
});

export interface IUpdateYHDCarrierFleetTruckSuccessAction {
    type: IUPDATE_YHD_CARRIER_FLEET_TRUCK_REQUEST.SUCCESS;
    message: string;
}
export const updateYHDCarrierFleetTruckLoadSuccessAction = (message: string): IUpdateYHDCarrierFleetTruckSuccessAction => ({
    type: IUPDATE_YHD_CARRIER_FLEET_TRUCK_REQUEST.SUCCESS,
    message
});
export interface IUpdateYHDCarrierFleetTruckLoadFailedAction {
    type: IUPDATE_YHD_CARRIER_FLEET_TRUCK_REQUEST.FAILED;
    message: string;
}
export const updateYHDCarrierFleetTruckLoadFailedAction = (message: string): IUpdateYHDCarrierFleetTruckLoadFailedAction => ({
    type: IUPDATE_YHD_CARRIER_FLEET_TRUCK_REQUEST.FAILED,
    message
});
