import { IPassTokenWithRequest } from "../../../dryPlant/token/getTokenConstants";


export enum IGET_LAB_SCALES_REQUEST {
    REQUEST = "getLabScale/GET_LAB_SCALES_REQUEST",
    SUCCESS = "getLabScale/GET_LAB_SCALES_SUCCESS",
    FAILED = "getLabScale/GET_LAB_SCALES_FAILED"
};

export interface IGetLabScaleRequest extends IPassTokenWithRequest {

};

export interface ILabScale {
    id: number;
    plant: string;
    model?: number;
    employee: string;
    month?: number;
    year?: number;
    ten_G_Reading?: number;
    five_Hundred_G_Reading?: number;
    thousand_G_Reading?: number;
    created: string | null;
    created_By: string;
    modified: string | null;
    modified_By: string;
};