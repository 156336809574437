import React, {Component} from "react";
import {hasInputError} from './formValidation';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import {formatDateToDBFormat} from '../../../../shared/simos/helper/dateFormatter';

interface IProps {

}
interface IState {
    date?: any;
    allHours: string[];
    allMin:string[];
    chosenHour: string;
    chosenMin: string;
}

var allDateField = {date: '', chosenHour: '', chosenMin:'', pickup_datefield:null};

class PickupDateField extends Component<IProps, IState>{
    
    constructor(props: IProps){
        super(props);
        this.state = {
            date: '', chosenHour: '', chosenMin: '', allHours:[], allMin:[]
        };
        this.onTextChange = this.onTextChange.bind(this);
    }

   private onTextChange (event: any){
        const fieldName = event.target.name;
        switch(fieldName) {
            case 'inputDate':
                this.setState({date:event.target.value});
                break;
    }
    } 
  
    public render() {
        if(this.state.date != ""){
            var formatted = formatDateToDBFormat(this.state.date);
            allDateField = {date: formatted[0], chosenHour: formatted[1], chosenMin: formatted[2], pickup_datefield:this.state.date};
        }
        return (
            // <Grid container spacing={3}>
            <div>
                {/* <input type="date" name="inputDate" value={this.state.date} onChange={this.onTextChange} className={hasInputError('pickup-date') ? 'showError':''}></input>
                <select id='create-dropdown-options' name ="hours" value = {this.state.chosenHour} onChange={this.onTextChange} className={hasInputError('pickup-hour') ? 'showError':''}>
                   {hourOptions}
                </select>
                <select id='create-dropdown-options' name="minutes" value = {this.state.chosenMin} onChange={this.onTextChange} className={hasInputError('pickup-minute') ? 'showError':''}>
                <option selected>Choose</option>
                 {minOptions}
                </select> */}
                    <InputLabel htmlFor="datetime-local">Scheduled Pickup Date/Time</InputLabel>
                 <TextField id='datetime-local' type='datetime-local' name="inputDate" value={this.state.date}onChange={this.onTextChange} InputLabelProps={{shrink:true}}></TextField>
            {/* </Grid> */}
            </div>
        );
        }
}

export default PickupDateField;
export {allDateField};