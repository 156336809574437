import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IUpdateTransloadBOLCountLoadAction, IUpdateTransloadBOLCountLoadFailedAction, IUpdateTransloadBOLCountSuccessAction, 
    updateTransloadBOLCountLoadFailedAction, updateTransloadBOLCountLoadSuccessAction
} from "./updateTransloadBOLCountActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IUPDATE_TRANSLOAD_BOL_COUNT_REQUEST } from "./updateTransloadBOLCountConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";

export const updateTransloadBOLCountEpic: Epic = (
    action$: ActionsObservable<IUpdateTransloadBOLCountLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IUpdateTransloadBOLCountSuccessAction | IUpdateTransloadBOLCountLoadFailedAction> =>
    action$.ofType(IUPDATE_TRANSLOAD_BOL_COUNT_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<string>>(
                    END_POINTS.PROPSENSE.SIL.DISPATCH.UPDATE_TRANSLOAD_BOL_COUNT,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<string>): IUpdateTransloadBOLCountSuccessAction => {
                            return updateTransloadBOLCountLoadSuccessAction(response.message);
                        }),
                        catchError(() => ActionsObservable.of(updateTransloadBOLCountLoadFailedAction("Unable to update trans bol count")))
                    )
            )
        );