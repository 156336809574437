import { IPassTokenWithRequest } from "../../dryPlant/token/getTokenConstants";
import { ITransloadBOLCount } from "../getTransloadBOLCounts/getTransloadBOLCountsConstaints";


export enum IADD_TRANSLOAD_BOL_COUNT_REQUEST {
    REQUEST = "addTransloadBOLCount/ADD_TRANSLOAD_BOL_COUNT_REQUEST",
    SUCCESS = "addTransloadBOLCount/ADD_TRANSLOAD_BOL_COUNT_SUCCESS",
    FAILED = "addTransloadBOLCount/ADD_TRANSLOAD_BOL_COUNT_FAILED"
};

export interface IAddTransloadBOLCountRequest extends IPassTokenWithRequest {
    request: ITransloadBOLCount;
};