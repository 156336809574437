import { SimosAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetOrderContactRequest, IGET_ORDER_CONTACT_REQUEST, ISIMOSOrderContact } from "./getOrderContactConstaints";

export interface IGetOrderContactLoadAction {
    type: IGET_ORDER_CONTACT_REQUEST.REQUEST;
    data: IGetOrderContactRequest
}
export const getOrderContactLoadAction = (data: IGetOrderContactRequest): IGetOrderContactLoadAction => ({
    type: IGET_ORDER_CONTACT_REQUEST.REQUEST,
    data
});

export interface IGetOrderContactSuccessAction {
    type: IGET_ORDER_CONTACT_REQUEST.SUCCESS;
    data: SimosAPIResponse<ISIMOSOrderContact[]>;
}
export const getOrderContactLoadSuccessAction = (data: SimosAPIResponse<ISIMOSOrderContact[]>): IGetOrderContactSuccessAction => ({
    type: IGET_ORDER_CONTACT_REQUEST.SUCCESS,
    data
});

export interface IGetOrderContactLoadFailedAction {
    type: IGET_ORDER_CONTACT_REQUEST.FAILED;
    message: string;
}
export const getOrderContactLoadFailedAction = (message: string): IGetOrderContactLoadFailedAction => ({
    type: IGET_ORDER_CONTACT_REQUEST.FAILED,
    message
});
