import { IAddSieveCalibrationRequest, IADD_SIEVE_CALIBRATION_REQUEST } from "./addSieveCalibrationConstaints";

export interface IAddSieveCalibrationLoadAction {
    type: IADD_SIEVE_CALIBRATION_REQUEST.REQUEST;
    data: IAddSieveCalibrationRequest
}
export const addSieveCalibrationLoadAction = (data: IAddSieveCalibrationRequest): IAddSieveCalibrationLoadAction => ({
    type: IADD_SIEVE_CALIBRATION_REQUEST.REQUEST,
    data
});

export interface IAddSieveCalibrationSuccessAction {
    type: IADD_SIEVE_CALIBRATION_REQUEST.SUCCESS;
    message: string;
}
export const addSieveCalibrationLoadSuccessAction = (message: string): IAddSieveCalibrationSuccessAction => ({
    type: IADD_SIEVE_CALIBRATION_REQUEST.SUCCESS,
    message
});

export interface IAddSieveCalibrationLoadFailedAction {
    type: IADD_SIEVE_CALIBRATION_REQUEST.FAILED;
    message: string;
}
export const addSieveCalibrationLoadFailedAction = (message: string): IAddSieveCalibrationLoadFailedAction => ({
    type: IADD_SIEVE_CALIBRATION_REQUEST.FAILED,
    message
});
