import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IAddSieveCheckLoadAction, IAddSieveCheckLoadFailedAction, IAddSieveCheckSuccessAction, 
    addSieveCheckLoadFailedAction, addSieveCheckLoadSuccessAction
} from "./addSieveCheckActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IADD_SIEVE_CHECK_REQUEST } from "./addSieveCheckConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";

export const addSieveCheckEpic: Epic = (
    action$: ActionsObservable<IAddSieveCheckLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IAddSieveCheckSuccessAction | IAddSieveCheckLoadFailedAction> =>
    action$.ofType(IADD_SIEVE_CHECK_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<string>>(
                    END_POINTS.PROPSENSE.SIL.SIEVES.ADD_SIEVES_CHECK,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<string>): IAddSieveCheckSuccessAction | IAddSieveCheckLoadFailedAction => {
                            if(response.message === "Success"){
                                return addSieveCheckLoadSuccessAction(response.message);
                            };
                            return addSieveCheckLoadFailedAction(response.message)
                        }),
                        catchError(() => ActionsObservable.of(addSieveCheckLoadFailedAction("Unable to add sieves check")))
                    )
            )
        );