import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetFeedMaterialsRequest, IGET_FEED_MATERIALS_REQUEST, IFeedMaterials } from "./getFeedMaterialsConstaints";

export interface IGetFeedMaterialsLoadAction {
    type: IGET_FEED_MATERIALS_REQUEST.REQUEST;
    data: IGetFeedMaterialsRequest
}
export const getFeedMaterialsLoadAction = (data: IGetFeedMaterialsRequest): IGetFeedMaterialsLoadAction => ({
    type: IGET_FEED_MATERIALS_REQUEST.REQUEST,
    data
});

export interface IGetFeedMaterialsSuccessAction {
    type: IGET_FEED_MATERIALS_REQUEST.SUCCESS;
    data: SilAPIResponse<IFeedMaterials[]>;
}
export const getFeedMaterialsLoadSuccessAction = (data: SilAPIResponse<IFeedMaterials[]>): IGetFeedMaterialsSuccessAction => ({
    type: IGET_FEED_MATERIALS_REQUEST.SUCCESS,
    data
});

export interface IGetFeedMaterialsLoadFailedAction {
    type: IGET_FEED_MATERIALS_REQUEST.FAILED;
    message: string;
}
export const getFeedMaterialsLoadFailedAction = (message: string): IGetFeedMaterialsLoadFailedAction => ({
    type: IGET_FEED_MATERIALS_REQUEST.FAILED,
    message
});
