import React from 'react';
import {updateProductLookup} from '../APICalls/editProductLookup';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import BackButton from '../../../../shared/simos/helper/backButton';
import isProductUnique, {isUnique} from '../APICalls/checkProductExists';
import { getSILURLParams } from '../../../../shared/simos/helper/getURLContainsSIL';

interface IProps {
    editID:number;
    closePopup:any;
    history:any;
    products:any;
}
interface IState {
    isDisabled:boolean;
    updatedProductName:string;
}

export default class EditProductLookupForm extends React.Component<IProps, IState> {
    constructor(props:IProps){
        super(props);
        this.state = {isDisabled:true, updatedProductName:''};
        this.onTextChange = this.onTextChange.bind(this);
    }

    onTextChange(event:any) {
        switch(event.target.name){
            case 'product_name':
                isProductUnique(event.target.value.trim());
                this.setState({updatedProductName:event.target.value, isDisabled:false});
                break;
        }
    }

    cancelEdit() {
        this.props.closePopup();
    }
    async saveEdit(event:any) {
        event.preventDefault();
        if(this.state.updatedProductName != ""){
            if(isUnique){
                var res:any = await updateProductLookup(this.state.updatedProductName, this.props.editID);
                if(res.status === 200){
                    alert("Successfully updated product");
                    this.props.closePopup();
                    window.location.reload();
                }
                else{
                    alert("There was an issue updating the product. Please refresh the page and try again");
                }
            }
            else{
                alert("Product name already exists!");
            }
        }
        else{
            alert("Product name cannot be empty!");
        }
    }
    render() {
        return (
            <div className={getSILURLParams()?.includes("bru") ? "edit-popup":"edit-popup-sil-ed"}>
                   <div className = 'edit-popup_inner'> 
                    <BackButton goBack={this.cancelEdit.bind(this)} history={this.props.history}></BackButton>
                    <form>
                    <h3>Editing Product <label id='header-3-label'>#{this.props.editID}</label></h3>
                    <div id="edit-info-fields">
                    {this.props.products.map((obj:any, ind:number) => {
                            if(obj.id === this.props.editID) {
                                return (
                                    <div className='row'>
                                         <Grid container spacing={3} direction="column">
                                                <Grid item xs={6}>
                                                    <TextField fullWidth disabled label="Location" name="location" onChange={this.onTextChange} defaultValue={obj.location}></TextField>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField disabled label="Product Code" name="code" onChange={this.onTextChange} defaultValue={obj.code}></TextField>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField label="Product Name" name="product_name" onChange={this.onTextChange} defaultValue={obj.description}></TextField>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField disabled variant="outlined"  label="Last Modified" onChange={this.onTextChange} defaultValue={obj.modified}></TextField>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField disabled variant="outlined"  label="Last Modified By" onChange={this.onTextChange} defaultValue={obj.modified_By}></TextField>
                                                </Grid>
                                                </Grid>
                                    </div>
                                );
                            }
                    })}
                    </div><br></br>
                    <button onClick={this.cancelEdit.bind(this)} id="cancel-contact-form-btn">Cancel</button>
                    <button disabled={this.state.isDisabled}onClick ={this.saveEdit.bind(this)} id="submit-contact-form-btn">Save</button>
                    </form>
                </div>
            </div>
        );
    }
}