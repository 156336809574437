import React from "react";
import { IAssignedCrew } from "../../../../../../redux/propsense/catapult/crew_sheduling/getAssignedCrews/getAssignedCrewsConstants";
import { ICrew } from "../../../../../../redux/propsense/catapult/crew_sheduling/getCrews/getCrewsConstants";
import { IREQUIRED_CREW } from "../../../../../../redux/propsense/catapult/crew_sheduling/getJobs/getJobsConstants";
import { getDate } from "../../../../../shared/constExports";
import LAGrid from "../../../../../shared/grid";
import LAGridItem from "../../../../../shared/gridList";
import { LongRightArrow } from "../../../../../shared/icons";
import { RED_COLOR } from "../../../../../shared/theme";
import { shiftIcon, crewCheck } from "./constFunctionsForCrew";

export interface IRenderCrewRequirementProps {
    rc: IREQUIRED_CREW;
    orderId?: number;
    crewList: ICrew[];
    assignedCrew: IAssignedCrew[];
};

export const RenderCrewRequirement: React.FC<IRenderCrewRequirementProps> = React.memo((props: IRenderCrewRequirementProps) =>
    <LAGrid>

        <LAGridItem xs={12}>
            <div>{shiftIcon(props.rc.shift)}</div>
            <div>{props.rc.shift}</div>
        </LAGridItem>

        <LAGridItem md={12} lg={5}>
            {getDate(false, props.rc.from_Date)}
        </LAGridItem>

        <LAGridItem sm={12} lg={2}>
            <LongRightArrow color={RED_COLOR} />
        </LAGridItem>

        <LAGridItem md={12} lg={5}>
            {getDate(false, props.rc.to_Date)}
        </LAGridItem>

        <LAGridItem md={12} lg={6}>
            <span>Super: </span>
            <strong className="redColor">
                {crewCheck(props.assignedCrew, props.rc, props.crewList, "Supervisor", props.orderId)}
            </strong>
            <strong className="warColor">
                {` / ${props.rc.super}`}
            </strong>
        </LAGridItem>

        <LAGridItem md={12} lg={6}>
            <span>OP1: </span>
            <strong className="redColor">
                {crewCheck(props.assignedCrew, props.rc, props.crewList, "Operator_Level1", props.orderId)}
            </strong>
            <strong className="warColor">
                {` / ${props.rc.operator_Level1}`}
            </strong>
        </LAGridItem>

        <LAGridItem md={12} lg={6}>
            <span>OP2: </span>
            <strong className="redColor">
                {crewCheck(props.assignedCrew, props.rc, props.crewList, "Operator_Level2", props.orderId)}
            </strong>
            <strong className="warColor">
                {` / ${props.rc.operator_Level2}`}
            </strong>
        </LAGridItem>

        <LAGridItem md={12} lg={6}>
            <span>OP3: </span>
            <strong className="redColor">
                {crewCheck(props.assignedCrew, props.rc, props.crewList, "Operator_Level3", props.orderId)}
            </strong>
            <strong className="warColor">
                {` / ${props.rc.operator_Level3}`}
            </strong>
        </LAGridItem>
    </LAGrid>
);