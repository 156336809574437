import { ISieveDryPlant } from "../getSieveDryPlants/getSieveDryPlantsConstaints";

export enum IADD_SIEVE_DRY_PLANT_REQUEST {
    REQUEST = "addSieveDryPlant/ADD_SIEVE_DRY_PLANT_REQUEST",
    SUCCESS = "addSieveDryPlant/ADD_SIEVE_DRY_PLANT_SUCCESS",
    FAILED = "addSieveDryPlant/ADD_SIEVE_DRY_PLANT_FAILED"
};

export interface IAddSieveDryPlantRequest {
    token: string;
    request: ISieveDryPlant;
};