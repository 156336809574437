import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, Server } from "../../../../redux/server";
import { SilAPIResponse } from "../../../shared/publicInterfaces";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import { callRouteWithQueryString, userName } from "../../../shared/constExports";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { LAButton } from "../../../shared/buttons";
import { AddIcon } from "../../../shared/icons";
import { WHITE_COLOR } from "../../../shared/theme";
import { ROUTE } from "../../../routes";
import PageSpacing from "../../../shared/pageSpacing";
import { getCarrierFleetTrailersStatus } from "../../../../redux/sil/carrier/getCarrierFleetTrailers/getCarrierFleetTrailersAccessor";
import { getCarrierFleetTrailersLoadAction } from "../../../../redux/sil/carrier/getCarrierFleetTrailers/getCarrierFleetTrailersActions";
import { ICarrierFleetTrailer, IGetCarrierFleetTrailersRequest } from "../../../../redux/sil/carrier/getCarrierFleetTrailers/getCarrierFleetTrailersConstaints";
import SilRoleCheck from "../../../shared/silRoleCheck";


interface ICarrierFleetTrailersStoreProps {
    getToken: Server<SilAPIResponse<IToken>>;
    getCarrierFleetTrailers: Server<SilAPIResponse<ICarrierFleetTrailer[]>>;
};

interface ICarrierFleetTrailersDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getCarrierFleetTrailersRequest: (data: IGetCarrierFleetTrailersRequest) => unknown;
};


interface ICarrierFleetTrailersOwnProps {

};

interface ICarrierFleetTrailersState {
    data: ICarrierFleetTrailer[];
};

const CarrierFleetTrailersStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
    };
`;

type ICarrierFleetTrailersProps = RouteComponentProps
    & ICarrierFleetTrailersStoreProps
    & ICarrierFleetTrailersDispatchProps
    & ICarrierFleetTrailersOwnProps;

class CarrierFleetTrailers extends PureComponent<ICarrierFleetTrailersProps, ICarrierFleetTrailersState> {

    public constructor(props: ICarrierFleetTrailersProps) {
        super(props);
        this.state = {
            data: []
        };
    }

    public componentDidMount(): void {
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: ICarrierFleetTrailersProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();
        };
    };


    public render(): ReactNode {

        const { data } = this.state;

        return (
            <PageSpacing title="SIL - Carrier Fleet Trailers" description="SIL - Carrier Fleet Trailers" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="carrierAccess">
                    <CarrierFleetTrailersStyles>

                        <LAButton className="pull-left" startIcon={<AddIcon color={WHITE_COLOR} />} label="Add" onClick={this.handleAdd} />
                        <h2 className="text-center">CARRIER FLEET TRAILERS</h2>
                        <hr />

                        <LADevExtremeGrid
                            data={data}
                            onEdit={this.onEdit}
                            onClick={this.onClick}
                            searchPanel={true}
                            filterHeader={true}
                            export={true}
                            storageKey="silCarrierFleetTrailersStorageKey"
                            exportFileName={"Carrier Fleet Trailers"}
                            columns={[
                                { name: "trailer_No", caption: "Trailer No", type: "string" },
                                { name: "carrier", caption: "Carrier", type: "string" },
                                { name: "positive_Air_Shutoff", caption: "Positive Air Shutoff", type: "string" },
                                { name: "trailer_Registration_No", caption: "Trailer Registration No", type: "string" },
                                { name: "trailer_Registration_Expiry", caption: "Trailer Registration Expiry", type: "date" },
                                { name: "trailer_Inspection_Date", caption: "Trailer Inspection Date", type: "date" },
                                { name: "active", caption: "Active", type: "string" },
                                { name: "inactive_Date", caption: "Inactive Date", type: "date" },
                                { name: "notes", caption: "Notes", type: "string" },
                                { name: "modified", caption: "Modified", type: "datetime", width: 100 },
                            ]}
                        />
                    </CarrierFleetTrailersStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString() },
            pathName: ROUTE.SIL.CARRIERS.CARRIER_TRAILER
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0" },
            pathName: ROUTE.SIL.CARRIERS.CARRIER_TRAILER
        });
    };

    private setDataToState = (): void => {

        if (hasPayload(this.props.getCarrierFleetTrailers)) {
            const val: ICarrierFleetTrailer[] = Object.values(this.props.getCarrierFleetTrailers.payload.response);
            this.setState({ data: val });
        };

        if (isNotLoaded(this.props.getCarrierFleetTrailers))
            this.callServer();
    };

    private callServer = (): void => {
        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getCarrierFleetTrailers))
            this.props.getCarrierFleetTrailersRequest({
                token: this.props.getToken.payload.response.token
            });


        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });
    }

}

const mapStateToProps = (state: IStore): ICarrierFleetTrailersStoreProps => ({
    getToken: getTokenStatus(state),
    getCarrierFleetTrailers: getCarrierFleetTrailersStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): ICarrierFleetTrailersDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getCarrierFleetTrailersRequest: (data: IGetCarrierFleetTrailersRequest) => dispatch(getCarrierFleetTrailersLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(CarrierFleetTrailers);