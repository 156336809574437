import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IUpdateGeoBagLoadAction, IUpdateGeoBagLoadFailedAction, IUpdateGeoBagSuccessAction, 
    updateGeoBagLoadFailedAction, updateGeoBagLoadSuccessAction
} from "./updateGeoBagActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IUPDATE_GEO_BAG_REQUEST } from "./updateGeoBagConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";

export const updateGeoBagEpic: Epic = (
    action$: ActionsObservable<IUpdateGeoBagLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IUpdateGeoBagSuccessAction | IUpdateGeoBagLoadFailedAction> =>
    action$.ofType(IUPDATE_GEO_BAG_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<string>>(
                    END_POINTS.PROPSENSE.SIL.DISPATCH.UPDATE_GEO_BAG,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<string>): IUpdateGeoBagSuccessAction => {
                            return updateGeoBagLoadSuccessAction(response.message);
                        }),
                        catchError(() => ActionsObservable.of(updateGeoBagLoadFailedAction("Unable to update geo bag")))
                    )
            )
        );