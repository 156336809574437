import { webConfig } from "../utils/webConfig";

export const END_POINTS = {
    LOGIN: "",
    PROPSENSE: {
        CATAPULT: {
            CREW_SCHEDULING: {
                GET_CREWS: `${webConfig.apiBaseUrl}/api/Loads/GetCatapultCrews`,
                GET_JOBS: `${webConfig.apiBaseUrl}/api/Loads/GetCatapultJobs`,
                GET_ASSIGNED_CREW: `${webConfig.apiBaseUrl}/api/Loads/GetCatapultAssignedCrews`,
                SAVE_ASSIGNED_CREW: `${webConfig.apiBaseUrl}/api/Loads/SaveCatapultCrewsAssign`,
            }
        },
        SIMOS: {
            GET_SIMOS_PRODUCTS: `${webConfig.apiBaseUrl}/api/PS/SIMOS/GetSIMOSProducts`,
            ADD_SIMOS_PRODUCT: `${webConfig.apiBaseUrl}/api/PS/SIMOS/AddNewSIMOSProduct`,
            UPDATE_SIMOS_PRODUCT: `${webConfig.apiBaseUrl}/api/PS/SIMOS/UpdateSIMOSProduct`,
            GET_SIMOS_TRANSPORT_COMPANIES: `${webConfig.apiBaseUrl}/api/PS/SIMOS/GetSIMOSTransportCompanies`,
            ADD_SIMOS_TRANSPORT_COMPANY: `${webConfig.apiBaseUrl}/api/PS/SIMOS/AddNewSIMOSTransportCompany`,
            UPDATE_SIMOS_TRANSPORT_COMPANY: `${webConfig.apiBaseUrl}/api/PS/SIMOS/UpdateNewSIMOSTransportCompany`,
            GET_SIMOS_CUSTOMERS: `${webConfig.apiBaseUrl}/api/PS/SIMOS/GetSIMOSCustomers`,//GetNewSIMOSCustomers //GetSIMOSCustomerList
            ADD_SIMOS_CUSTOMER: `${webConfig.apiBaseUrl}/api/PS/SIMOS/AddNewSIMOSCustomer`,
            UPDATE_SIMOS_CUSTOMER: `${webConfig.apiBaseUrl}/api/PS/SIMOS/UpdateSIMOSCustomer`,
            GET_SIMOS_CUSTOMER_CONTACTS: `${webConfig.apiBaseUrl}/api/PS/SIMOS/GetSIMOSCustomerContactList`,
            ADD_SIMOS_CUSTOMER_CONTACT: `${webConfig.apiBaseUrl}/api/PS/SIMOS/AddNewSIMOSCustomerContact`,
            UPDATE_SIMOS_CUSTOMER_CONTACT: `${webConfig.apiBaseUrl}/api/PS/SIMOS/UpdateSIMOSCustomerContactRecord`,
            GET_SIMOS_CUSTOMER_ADDRESS: `${webConfig.apiBaseUrl}/api/PS/SIMOS/GetSIMOSCustomerAddress`,

            GET_ORDER_BY_ID: `${webConfig.apiBaseUrl}/api/PS/SIMOS/GetOrderByID`,
            GET_ORDER: `${webConfig.apiBaseUrl}/api/PS/SIMOS/GetOrders`,
            ADD_ORDER: `${webConfig.apiBaseUrl}/api/PS/SIMOS/AddOrder`,
            UPDATE_ORDER: `${webConfig.apiBaseUrl}/api/PS/SIMOS/UpdateOrder`,
            GET_ORDER_TICKET: `${webConfig.apiBaseUrl}/api/GetSIMOSOrderTicket`,
            GET_PRODUCTS_BY_ID: `${webConfig.apiBaseUrl}/api/PS/SIMOS/GetProductsByOrderID `,
            GET_ORDER_VERSION_HISTORY: `${webConfig.apiBaseUrl}/api/PS/SIMOS/GetOrderVersionHistory`,
            GET_SIMOS_PRODUCT_ORDER_VERSION_HISTORY: `${webConfig.apiBaseUrl}/api/PS/SIMOS/GetOrderProductsVersionHistory`,
            DOWNLOAD_ORDER_EXCEL_FILE: `${webConfig.apiBaseUrl}/api/PS/SIMOS/GetSIMOSOrderExcel`,
            GET_ORDER_CONTACT: `${webConfig.apiBaseUrl}/api/PS/SIMOS/GetSelectedContactByOrderID`,
            GET_SIMOS_TRUCK: `${webConfig.apiBaseUrl}/api/PS/SIMOS/GetSIMOSTrucks`,
            GET_SALES_ORDER_NUMBERS_BY_CUSTOMER_ID: `${webConfig.apiBaseUrl}/api/PS/SIMOS/GetSalesOrderNumbersByCustomerID`,
            ADD_SALES_ORDER_NUMBER: `${webConfig.apiBaseUrl}/api/PS/SIMOS/AddSalesOrderNumber`,
        },
        SIL: {
            CARRIER: {
                GET_CARRIERS: `${webConfig.apiBaseUrl}/api/Sil/GetCarriers`,
                ADD_CARRIER_FORM: `${webConfig.apiBaseUrl}/api/Sil/AddCarrier`,
                UPDATE_CARRIER_FORM: `${webConfig.apiBaseUrl}/api/Sil/UpdateCarrier`,
                GET_CARRIER_FILE: `${webConfig.apiBaseUrl}/api/Sil/GetCarrierFile`,
                GET_CARRIER_LOOKUPS: `${webConfig.apiBaseUrl}/api/Sil/GetCarriersLookups`,
                GET_CARRIER_EMAIL: `${webConfig.apiBaseUrl}/api/Sil/GetCarriersEmail`,
                ADD_CARRIER_EMAIL: `${webConfig.apiBaseUrl}/api/Sil/AddCarriersEmail`,
                UPDATE_CARRIER_EMAIL: `${webConfig.apiBaseUrl}/api/Sil/UpdateCarriersEmail`,
                GET_CARRIER_EMAIL_FILE: `${webConfig.apiBaseUrl}/api/Sil/GetCarriersEmailFile`,
                GET_CARRIER_FLEET_TRAILER: `${webConfig.apiBaseUrl}/api/Sil/GetCarrierFleetTrailers`,
                ADD_CARRIER_FLEET_TRAILER: `${webConfig.apiBaseUrl}/api/Sil/AddCarrierFleetTrailers`,
                UPDATE_CARRIER_FLEET_TRAILER: `${webConfig.apiBaseUrl}/api/Sil/UpdateCarrierFleetTrailers`,
                GET_CARRIER_FLEET_TRAILER_FILE: `${webConfig.apiBaseUrl}/api/Sil/GetCarrierFleetTrailerFile`,
                GET_CARRIER_INFRACTION: `${webConfig.apiBaseUrl}/api/Sil/GetCarrierInfractions`,
                ADD_CARRIER_INFRACTION: `${webConfig.apiBaseUrl}/api/Sil/AddCarrierInfraction`,
                UPDATE_CARRIER_INFRACTION: `${webConfig.apiBaseUrl}/api/Sil/UpdateCarrierInfraction`,
                GET_CARRIER_INFRACTION_FILE: `${webConfig.apiBaseUrl}/api/Sil/GetCarrierInfractionFile`,
                GET_AUDIT: `${webConfig.apiBaseUrl}/api/Sil/GetCarrierAudits`,
                UPDATE_AUDIT: `${webConfig.apiBaseUrl}/api/Sil/UpdateCarrierAudit`,
                GET_AUDIT_FILE: `${webConfig.apiBaseUrl}/api/Sil/GetCarrierAuditFile`
            },
            DRY_PLANT: {
                GET_TOKEN: `${webConfig.apiBaseUrl}/api/PS/GetUserToken`,
                GET_DRY_PLANT_PRODUCTION_LOOKUPS: `${webConfig.apiBaseUrl}/api/PS/GetDryPlantProductionLookups`,
                GET_DRY_PLANT_PRODUCTION_RECORDS: `${webConfig.apiBaseUrl}/api/PS/GetDryPlantProductionRecords`,
                ADD_DRY_PLANT_PRODUCTION: `${webConfig.apiBaseUrl}/api/PS/AddDryPlantProduction`,
                UPDATE_DRY_PLANT_PRODUCTION: `${webConfig.apiBaseUrl}/api/PS/UpdateDryPlantProduction`,
                GET_DRY_PLANT_RUN_MATERIALS: `${webConfig.apiBaseUrl}/api/PS/GetDryPlantRunMaterials`,
                ADD_DRY_PLANT_RUN_MATERIALS: `${webConfig.apiBaseUrl}/api/PS/AddDryPlantRunMaterial`,
                UPDATE_DRY_PLANT_RUN_MATERIALS: `${webConfig.apiBaseUrl}/api/PS/UpdateDryPlantRunMaterial`,
                GET_DRY_PLANT_FEED_MATERIALS: `${webConfig.apiBaseUrl}/api/PS/GetDryPlantFeedMaterials`,
                ADD_DRY_PLANT_FEED_MATERIALS: `${webConfig.apiBaseUrl}/api/PS/AddDryPlantFeedMaterial`,
                UPDATE_DRY_PLANT_FEED_MATERIALS: `${webConfig.apiBaseUrl}/api/PS/UpdateDryPlantFeedMaterial`,
                GET_DRY_PLANT_EMPLOYEE: `${webConfig.apiBaseUrl}/api/PS/GetDryPlantEmployees`,
                ADD_DRY_PLANT_EMPLOYEE: `${webConfig.apiBaseUrl}/api/PS/AddDryPlantEmployee`,
                UPDATE_DRY_PLANT_EMPLOYEE: `${webConfig.apiBaseUrl}/api/PS/UpdateDryPlantEmployee`,
                GET_DRY_PLANT_BIN_ADJUSTMENT: `${webConfig.apiBaseUrl}/api/PS/GetDryPlantBinAdjustments`,
                ADD_DRY_PLANT_BIN_ADJUSTMENT: `${webConfig.apiBaseUrl}/api/PS/AddDryPlantBinAdjustment`,
                UPDATE_DRY_PLANT_BIN_ADJUSTMENT: `${webConfig.apiBaseUrl}/api/PS/UpdateDryPlantBinAdjustment`,
                GET_DRY_PLANT_PRODUCTION_SUMMARY: `${webConfig.apiBaseUrl}/api/PS/GetDryPlantByProductSummary`,
            },
            WASH_PLANT: {
                GET_WASH_PLANT_PRODUCTION_LOOKUPS: `${webConfig.apiBaseUrl}/api/PS/GetWashPlantProductionLookups`,
                GET_WASH_PLANT_PRODUCTION_RECORDS: `${webConfig.apiBaseUrl}/api/PS/GetWashPlantProductionRecords`,
                GET_WASH_PLANT_PRODUCTION_RECORD_BY_ID: `${webConfig.apiBaseUrl}/api/PS/GetWashPlantProductionRecordByID`,
                ADD_WASH_PLANT_PRODUCTION: `${webConfig.apiBaseUrl}/api/PS/AddWashPlantProduction`,
                UPDATE_WASH_PLANT_PRODUCTION: `${webConfig.apiBaseUrl}/api/PS/UpdateWashPlantProduction`,
                GET_WASH_PLANT_RUN_MATERIALS: `${webConfig.apiBaseUrl}/api/PS/GetWashPlantRunMaterials`,
                ADD_WASH_PLANT_RUN_MATERIALS: `${webConfig.apiBaseUrl}/api/PS/AddWashPlantRunMaterial`,
                UPDATE_WASH_PLANT_RUN_MATERIALS: `${webConfig.apiBaseUrl}/api/PS/UpdateWashPlantRunMaterial`,
                GET_WASH_PLANT_FEED_MATERIALS: `${webConfig.apiBaseUrl}/api/PS/GetWashPlantFeedMaterials`,
                ADD_WASH_PLANT_FEED_MATERIALS: `${webConfig.apiBaseUrl}/api/PS/AddWashPlantFeedMaterial`,
                UPDATE_WASH_PLANT_FEED_MATERIALS: `${webConfig.apiBaseUrl}/api/PS/UpdateWashPlantFeedMaterial`,
                GET_WASH_PLANT_EMPLOYEE: `${webConfig.apiBaseUrl}/api/PS/GetDryPlantEmployees`,
                ADD_WASH_PLANT_EMPLOYEE: `${webConfig.apiBaseUrl}/api/PS/AddDryPlantEmployee`,
                UPDATE_WASH_PLANT_EMPLOYEE: `${webConfig.apiBaseUrl}/api/PS/UpdateDryPlantEmployee`,
                GET_WASH_PLANT_BIN_ADJUSTMENT: `${webConfig.apiBaseUrl}/api/PS/GetWashPlantBinAdjustments`,
                ADD_WASH_PLANT_BIN_ADJUSTMENT: `${webConfig.apiBaseUrl}/api/PS/AddWashPlantBinAdjustment`,
                UPDATE_WASH_PLANT_BIN_ADJUSTMENT: `${webConfig.apiBaseUrl}/api/PS/UpdateWashPlantBinAdjustment`,
                GET_WASH_PLANT_PRODUCTION_SUMMARY: `${webConfig.apiBaseUrl}/api/PS/GetWashPlantByProductSummary`,
            },
            SIEVES: {
                GET_SIEVES_CALIBRATIONS: `${webConfig.apiBaseUrl}/api/PS/Sieve/GetSieveCalibrations`,
                GET_SIEVES_LOOKUPS: `${webConfig.apiBaseUrl}/api/PS/Sieve/GetSieveLookups`,
                ADD_SIEVES_CALIBRATION: `${webConfig.apiBaseUrl}/api/PS/Sieve/AddSieveCalibration`,
                UPDATE_SIEVES_CALIBRATION: `${webConfig.apiBaseUrl}/api/PS/Sieve/UpdateSieveCalibration`,
                GET_SIEVES_CHECKS: `${webConfig.apiBaseUrl}/api/PS/Sieve/GetSieveChecks`,
                ADD_SIEVES_CHECK: `${webConfig.apiBaseUrl}/api/PS/Sieve/AddSieveCheck`,
                UPDATE_SIEVES_CHECK: `${webConfig.apiBaseUrl}/api/PS/Sieve/UpdateSieveCheck`,
                GET_SIEVES: `${webConfig.apiBaseUrl}/api/PS/Sieve/GetSieves`,
                ADD_SIEVE: `${webConfig.apiBaseUrl}/api/PS/Sieve/AddSieve`,
                UPDATE_SIEVE: `${webConfig.apiBaseUrl}/api/PS/Sieve/UpdateSieve`,
                GET_SIEVES_PRODUCT: `${webConfig.apiBaseUrl}/api/PS/Sieve/GetSieveProducts`,
                ADD_SIEVE_PRODUCT: `${webConfig.apiBaseUrl}/api/PS/Sieve/AddSieveProduct`,
                UPDATE_SIEVE_PRODUCT: `${webConfig.apiBaseUrl}/api/PS/Sieve/UpdateSieveProduct`
            },
            EXPLORATION: {
                GET_CHEMICAL_ANALYSES: `${webConfig.apiBaseUrl}/api/Exploration/GetChemicalAnalyses`,
                ADD_CHEMICAL_ANALYSES: `${webConfig.apiBaseUrl}/api/Exploration/AddChemicalAnalyses`,
                UPDATE_CHEMICAL_ANALYSES: `${webConfig.apiBaseUrl}/api/Exploration/UpdateChemicalAnalyses`,
            },
            WAREHOUSE: {
                GET_PS_LOOKUPS: `${webConfig.apiBaseUrl}/api/Sil/GetPSLookups`,
                GET_WAREHOUSE_PRODUCTS: `${webConfig.apiBaseUrl}/api/Sil/Production/GetWarehouseProducts`,
                ADD_WAREHOUSE_PRODUCT: `${webConfig.apiBaseUrl}/api/Sil/Production/AddWarehouseProduct`,
                UPDATE_WAREHOUSE_PRODUCT: `${webConfig.apiBaseUrl}/api/Sil/Production/UpdateWarehouseProduct`,
                GET_BAGGED_INVENTORIES: `${webConfig.apiBaseUrl}/api/Sil/Production/GetBaggedInventory`,
                ADD_BAGGED_INVENTORY: `${webConfig.apiBaseUrl}/api/Sil/Production/AddBaggedInventory`,
                UPDATE_BAGGED_INVENTORY: `${webConfig.apiBaseUrl}/api/Sil/Production/UpdateBaggedInventory`,
            },
            COMPLAINT_LOGGING: {
                GET_COMPLAINT_LOGGING: `${webConfig.apiBaseUrl}/api/Sil/GetComplaintLoggings`,
                ADD_COMPLAINT_LOGGING: `${webConfig.apiBaseUrl}/api/Sil/AddComplaintLogging`,
                UPDATE_COMPLAINT_LOGGING: `${webConfig.apiBaseUrl}/api/Sil/UpdateComplaintLogging`,
            },
            DISPATCH: {
                GET_GEO_BAGS: `${webConfig.apiBaseUrl}/api/Sil/GetGeoBags`,
                ADD_GEO_BAG: `${webConfig.apiBaseUrl}/api/Sil/AddGeoBag`,
                UPDATE_GEO_BAG: `${webConfig.apiBaseUrl}/api/Sil/UpdateGeoBag`,
                GET_TRANSLOAD_BOL_COUNTS: `${webConfig.apiBaseUrl}/api/Sil/GetTransloadBOLCounts`,
                ADD_TRANSLOAD_BOL_COUNT: `${webConfig.apiBaseUrl}/api/Sil/AddTransloadBOLCount`,
                UPDATE_TRANSLOAD_BOL_COUNT: `${webConfig.apiBaseUrl}/api/Sil/UpdateTransloadBOLCount`,
                GET_TRAILER_ACTIVITY_LOGS: `${webConfig.apiBaseUrl}/api/Sil/GetTrailerActivityLogs`,
                ADD_TRAILER_ACTIVITY_LOG: `${webConfig.apiBaseUrl}/api/Sil/AddTrailerActivityLog`,
                UPDATE_TRAILER_ACTIVITY_LOG: `${webConfig.apiBaseUrl}/api/Sil/UpdateTrailerActivityLog`,
            },
            CV: {
                GetCVFile: `${webConfig.apiBaseUrl}/api/CV/GetCVFile`,
                GetCVBeltScales: `${webConfig.apiBaseUrl}/api/CV/GetCVBeltScales`,
                AddCVBeltScale: `${webConfig.apiBaseUrl}/api/CV/AddCVBeltScale`,
                UpdateCVBeltScale: `${webConfig.apiBaseUrl}/api/CV/UpdateCVBeltScale`,
                GetCVCranes: `${webConfig.apiBaseUrl}/api/CV/GetCVCranes`,
                AddCVCrane: `${webConfig.apiBaseUrl}/api/CV/AddCVCrane`,
                UpdateCVCrane: `${webConfig.apiBaseUrl}/api/CV/UpdateCVCrane`,
                GetCVLabScales: `${webConfig.apiBaseUrl}/api/CV/GetCVLabScales`,
                AddCVLabScale: `${webConfig.apiBaseUrl}/api/CV/AddCVLabScale`,
                UpdateCVLabScale: `${webConfig.apiBaseUrl}/api/CV/UpdateCVLabScale`,
                GetCVNCR: `${webConfig.apiBaseUrl}/api/CV/GetCVNCR`,
                AddCVNCR: `${webConfig.apiBaseUrl}/api/CV/AddCVNCR`,
                UpdateCVNCR: `${webConfig.apiBaseUrl}/api/CV/UpdateCVNCR`,
                GetCVTruckScales: `${webConfig.apiBaseUrl}/api/CV/GetCVTruckScales`,
                AddCVTruckScale: `${webConfig.apiBaseUrl}/api/CV/AddCVTruckScale`,
                UpdateCVTruckScale: `${webConfig.apiBaseUrl}/api/CV/UpdateCVTruckScale`,
                GetCVWarehouse: `${webConfig.apiBaseUrl}/api/CV/GetCVWarehouses`,
                AddCVWarehouse: `${webConfig.apiBaseUrl}/api/CV/AddCVWarehouse`,
                UpdateCVWarehouse: `${webConfig.apiBaseUrl}/api/CV/UpdateCVWarehouse`,
                GetCVSieveDryPlant: `${webConfig.apiBaseUrl}/api/CV/GetCVSieveDryPlants`,
                AddCVSieveDryPlant: `${webConfig.apiBaseUrl}/api/CV/AddCVSieveDryPlant`,
                UpdateCVSieveDryPlant: `${webConfig.apiBaseUrl}/api/CV/UpdateCVSieveDryPlant`,
                GetCVSieveFracBall: `${webConfig.apiBaseUrl}/api/CV/GetCVSieveFracBalls`,
                AddCVSieveFracBall: `${webConfig.apiBaseUrl}/api/CV/AddCVSieveFracBall`,
                UpdateCVSieveFracBall: `${webConfig.apiBaseUrl}/api/CV/UpdateCVSieveFracBall`,
                GetCVSieveWashPlant: `${webConfig.apiBaseUrl}/api/CV/GetCVSieveWashPlants`,
                AddCVSieveWashPlant: `${webConfig.apiBaseUrl}/api/CV/AddCVSieveWashPlant`,
                UpdateCVSieveWashPlant: `${webConfig.apiBaseUrl}/api/CV/UpdateCVSieveWashPlant`,
            },
            QUALITY_CONTROL_TESTINGS: {
                GET_QUALITY_CONTROL_TESTINGS: `${webConfig.apiBaseUrl}/api/Sil/GetQCTestings`,
                ADD_QUALITY_CONTROL_TESTING: `${webConfig.apiBaseUrl}/api/Sil/AddQCTesting`,
                UPDATE_QUALITY_CONTROL_TESTING: `${webConfig.apiBaseUrl}/api/Sil/UpdateQCTesting`,
            },
        },
        YHD: {
            CARRIER: {
                GET_CARRIERS: `${webConfig.apiBaseUrl}/api/Yhd/GetCarriers`,
                ADD_CARRIER_FORM: `${webConfig.apiBaseUrl}/api/Yhd/AddCarrier`,
                UPDATE_CARRIER_FORM: `${webConfig.apiBaseUrl}/api/Yhd/UpdateCarrier`,
                GET_CARRIER_FILE: `${webConfig.apiBaseUrl}/api/Yhd/GetCarrierFile`,
                GET_CARRIER_LOOKUPS: `${webConfig.apiBaseUrl}/api/Yhd/GetCarriersLookups`,
                GET_CARRIER_EMAIL: `${webConfig.apiBaseUrl}/api/Yhd/GetCarriersEmail`,
                ADD_CARRIER_EMAIL: `${webConfig.apiBaseUrl}/api/Yhd/AddCarriersEmail`,
                UPDATE_CARRIER_EMAIL: `${webConfig.apiBaseUrl}/api/Yhd/UpdateCarriersEmail`,
                GET_CARRIER_EMAIL_FILE: `${webConfig.apiBaseUrl}/api/Yhd/GetCarriersEmailFile`,

                GET_CARRIER_FLEET_TRUCKS: `${webConfig.apiBaseUrl}/api/Yhd/GetCarrierFleetTrucks`,
                ADD_CARRIER_FLEET_TRUCKS: `${webConfig.apiBaseUrl}/api/Yhd/AddCarrierFleetTruck`,
                UPDATE_CARRIER_FLEET_TRUCKS: `${webConfig.apiBaseUrl}/api/Yhd/UpdateCarrierFleetTruck`,
                GET_CARRIER_FLEET_TRUCK_FILE: `${webConfig.apiBaseUrl}/api/Yhd/GetCarrierFleetTruckFile`,
                GET_CARRIER_INFRACTIONS: `${webConfig.apiBaseUrl}/api/Yhd/GetCarrierInfractions`,
                ADD_CARRIER_INFRACTIONS: `${webConfig.apiBaseUrl}/api/Yhd/AddCarrierInfraction`,
                UPDATE_CARRIER_INFRACTIONS: `${webConfig.apiBaseUrl}/api/Yhd/UpdateCarrierInfraction`,
                GET_CARRIER_INFRACTIONS_FILE: `${webConfig.apiBaseUrl}/api/Yhd/GetCarrierInfractionFile`,
            },
        }
    }
};