import { ISieveCalibration } from "../../../../../utils/sharedTypes";
import { IPassTokenWithRequest } from "../../../dryPlant/token/getTokenConstants";


export enum IGET_SIEVE_WASH_PLANTS_REQUEST {
    REQUEST = "getSieveWashPlant/GET_SIEVE_WASH_PLANTS_REQUEST",
    SUCCESS = "getSieveWashPlant/GET_SIEVE_WASH_PLANTS_SUCCESS",
    FAILED = "getSieveWashPlant/GET_SIEVE_WASH_PLANTS_FAILED"
};

export interface IGetSieveWashPlantRequest extends IPassTokenWithRequest {

};

export interface ISieveWashPlant {
    id: number;
    month?: number;
    year?: number;
    employee: string;
    completed: string;
    is_Calibration_Good: string;
    created: string;
    created_By: string;
    modified: string;
    modified_By: string;
    calibrations: ISieveCalibration[];
}''