import { IWarehouse } from "../getWarehouses/getWarehousesConstaints";
import { IUpdateWarehouseRequest, IUPDATE_WAREHOUSE_REQUEST } from "./updateWarehouseConstaints";

export interface IUpdateWarehouseLoadAction {
    type: IUPDATE_WAREHOUSE_REQUEST.REQUEST;
    data: IUpdateWarehouseRequest
}
export const updateWarehouseLoadAction = (data: IUpdateWarehouseRequest): IUpdateWarehouseLoadAction => ({
    type: IUPDATE_WAREHOUSE_REQUEST.REQUEST,
    data
});

export interface IUpdateWarehouseSuccessAction {
    type: IUPDATE_WAREHOUSE_REQUEST.SUCCESS;
    data: IWarehouse;
}
export const updateWarehouseLoadSuccessAction = (data: IWarehouse): IUpdateWarehouseSuccessAction => ({
    type: IUPDATE_WAREHOUSE_REQUEST.SUCCESS,
    data
});

export interface IUpdateWarehouseLoadFailedAction {
    type: IUPDATE_WAREHOUSE_REQUEST.FAILED;
    message: string;
}
export const updateWarehouseLoadFailedAction = (message: string): IUpdateWarehouseLoadFailedAction => ({
    type: IUPDATE_WAREHOUSE_REQUEST.FAILED,
    message
});
