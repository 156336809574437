import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { ROUTE } from "../../../routes";
import { hasPayload, isNotLoaded, Server } from "../../../../redux/server";
import { LAPaperWithPadding } from "../../../shared/paper";
import PageSpacing from "../../../shared/pageSpacing";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { callRouteWithQueryString, userName } from "../../../shared/constExports";
import { SilAPIResponse } from "../../../shared/publicInterfaces";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { IGetCarrierAudit, IGetCarrierAuditRequest } from "../../../../redux/sil/carrier/audit/getAudit/getCarrierAuditConstants";
import { getSILCarrierAuditStatus } from "../../../../redux/sil/carrier/audit/getAudit/getCarrierAuditAccessor";
import { getCarrierAuditLoadAction } from "../../../../redux/sil/carrier/audit/getAudit/getCarrierAuditActions";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";


interface ISILAuditsStoreProps {
    getToken: Server<SilAPIResponse<IToken>>;
    getSILAudits: Server<SilAPIResponse<IGetCarrierAudit[]>>;
};

interface ISILAuditsDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getSILAuditsRequest: (data: IGetCarrierAuditRequest) => unknown;
};


interface ISILAuditsOwnProps {

};

interface ISILAuditsState {
    radioValue: string;
    approved: IGetCarrierAudit[];
    unapproved: IGetCarrierAudit[];
};

const SILAuditsStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
    };
`;

type ISILAuditsProps = RouteComponentProps
    & ISILAuditsStoreProps
    & ISILAuditsDispatchProps
    & ISILAuditsOwnProps;

class SILAudits extends PureComponent<ISILAuditsProps, ISILAuditsState> {

    public constructor(props: ISILAuditsProps) {
        super(props);
        this.state = {
            approved: [],
            unapproved: [],
            radioValue: "Unapproved"
        };
    }

    public componentDidMount(): void {
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: ISILAuditsProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();
        };
    };


    public render(): ReactNode {

        const { getSILAudits } = this.props;
        const { approved, unapproved, radioValue } = this.state;

        const records = (radioValue === "Unapproved") ? unapproved : approved;

        return (
            <PageSpacing title="SIL - Audit" description="SIL - Audit" fixedSpaceOnSmallerScreens={true}>
                <SILAuditsStyles>

                    <h2 className="text-center">AUDITS</h2>
                        <RadioGroup row aria-label="" name="radioGroup" value={radioValue} onChange={this.handleRadioClick}>
                            <FormControlLabel value="Unapproved" control={<Radio />} label="Unapproved" />
                            <FormControlLabel value="Approved" control={<Radio />} label="Approved" />
                        </RadioGroup>
                        <hr />

                    <LADevExtremeGrid
                        data={records}
                        height={1000}
                        export={true}
                        searchPanel={true}
                        filterHeader={true}
                        columnChoose={true}
                        onEdit={this.onEdit}
                        onClick={this.onClick}
                        exportFileName="Audit"
                        storageKey="silAuditStorageKey"
                        columns={[
                            // { name: "approvedDevExtreme", caption: "Approved", type: "boolean" },
                            { name: "date", caption: "Date", type: "date" },
                            { name: "insurance", caption: "Insurance", type: "string" },
                            { name: "policy_date", caption: "Policy Date", type: "date" },
                            { name: "wcb", caption: "WCB", type: "string" },
                            { name: "wcB_No", caption: "WCB No", type: "string" },
                            { name: "substance_Abuse_Policy", caption: "Substance Abuse Policy", type: "string" },
                            { name: "post_Incident_Testing", caption: "Post Incident Testing", type: "string" },
                            { name: "seatbelt_Policy", caption: "Seatbelt Policy", type: "string" },
                            { name: "carrier_Profile_Reviewed", caption: "Carrier Profile Reviewed", type: "string" },
                            { name: "audited", caption: "Audited", type: "string" },
                            { name: "unaudited", caption: "Unaudited", type: "string" },
                            { name: "r_Factor", caption: "R Factor", type: "string" },
                            { name: "driver_Abstracts", caption: "Driver Abstracts", type: "string" },
                            { name: "_6_Demerits", caption: "6 Demerits", type: "string" },
                            { name: "alcohol_Free", caption: "Alcohol Free", type: "string" },
                            { name: "carrier_Printed_Name", caption: "Carrier Printed Name", type: "string" },
                            { name: "created", caption: "Created", type: "datetime" },
                            { name: "created_By", caption: "Created By", type: "string" },
                            { name: "modified", caption: "Modified", type: "datetime" },
                            { name: "modified_By", caption: "Modified By", type: "string" }
                        ]}
                    />
                </SILAuditsStyles>
            </PageSpacing>
        );
    }

    private handleRadioClick = (event: React.ChangeEvent<HTMLInputElement>, radioValue: string): void => {
        this.setState({ radioValue });
    };

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString() },
            pathName: ROUTE.SIL.CARRIERS.CARRIER_AUDIT
        });
    };

    private onClick = (): void => {

    };

    private setDataToState = (): void => {

        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });
            
        if (hasPayload(this.props.getSILAudits)) {
            const unapproved: IGetCarrierAudit[] = Object.values(this.props.getSILAudits.payload.response)
                .filter(x => (x.audit === null) || (x.audit === "") || (x.audit === "No"))
                .map(x => ({ ...x, approvedDevExtreme: false }));

                const approved: IGetCarrierAudit[] = Object.values(this.props.getSILAudits.payload.response)
                .filter(x => (x.audit !== null) && (x.audit !== "") && (x.audit === "Yes"))
                .map(x => ({ ...x, approvedDevExtreme: true }));

            this.setState({ unapproved, approved });
        };

        if (isNotLoaded(this.props.getSILAudits))
            this.callServer();
    };

    private callServer = (): void => {
        if (hasPayload(this.props.getToken)) {
            this.props.getSILAuditsRequest({
                token: this.props.getToken.payload.response.token,
                request: {
                    IsAdmin: true
                }
            });
        }
    }

}

const mapStateToProps = (state: IStore): ISILAuditsStoreProps => ({
    getToken: getTokenStatus(state),
    getSILAudits: getSILCarrierAuditStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): ISILAuditsDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getSILAuditsRequest: (data: IGetCarrierAuditRequest) => dispatch(getCarrierAuditLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(SILAudits);