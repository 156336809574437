import { PropsenseAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { IGetRequestBody } from "../getJobs/getJobsConstants";
import { IAssignedCrew, IGET_ASSIGNED_CREWS_REQUEST } from "./getAssignedCrewsConstants";

export interface IGetAssignedCrewsLoadAction {
    type: IGET_ASSIGNED_CREWS_REQUEST.REQUEST;
    data: IGetRequestBody;
}
export const getAssignedCrewsLoadAction = (data: IGetRequestBody): IGetAssignedCrewsLoadAction => ({
    type: IGET_ASSIGNED_CREWS_REQUEST.REQUEST,
    data
});
export interface IGetAssignedCrewsSuccessAction {
    type: IGET_ASSIGNED_CREWS_REQUEST.SUCCESS;
    list: PropsenseAPIResponse<IAssignedCrew[]>;
}
export const getAssignedCrewsLoadSuccessAction = (list: PropsenseAPIResponse<IAssignedCrew[]>): IGetAssignedCrewsSuccessAction => ({
    type: IGET_ASSIGNED_CREWS_REQUEST.SUCCESS,
    list
});
export interface IGetAssignedCrewsLoadFailedAction {
    type: IGET_ASSIGNED_CREWS_REQUEST.FAILED;
    message: string;
}
export const getAssignedCrewsLoadFailedAction = (message: string): IGetAssignedCrewsLoadFailedAction => ({
    type: IGET_ASSIGNED_CREWS_REQUEST.FAILED,
    message
});
