import { SilAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../../flushData.tsx/flushDataConstants";
import { Server, failed, loading, notLoaded, succeeded } from "../../../../server";
import { IAddSieveDryPlantSuccessAction } from "../addSieveDryPlant/addSieveDryPlantActions";
import { IADD_SIEVE_DRY_PLANT_REQUEST } from "../addSieveDryPlant/addSieveDryPlantConstaints";
import { IUpdateSieveDryPlantSuccessAction } from "../updateSieveDryPlant/updateSieveDryPlantActions";
import { IUPDATE_SIEVE_DRY_PLANT_REQUEST } from "../updateSieveDryPlant/updateSieveDryPlantConstaints";
import { IGetSieveDryPlantLoadAction, IGetSieveDryPlantLoadFailedAction, IGetSieveDryPlantSuccessAction } from "./getSieveDryPlantsActions";
import { ISieveDryPlant, IGET_SIEVE_DRY_PLANTS_REQUEST } from "./getSieveDryPlantsConstaints";

type Actions =
    IGetSieveDryPlantLoadAction
    | IGetSieveDryPlantLoadFailedAction
    | IGetSieveDryPlantSuccessAction
    | IAddSieveDryPlantSuccessAction
    | IUpdateSieveDryPlantSuccessAction
    | IFlushDataSuccessAction;

export const GetSieveDryPlantReducer = (state: Server<SilAPIResponse<ISieveDryPlant[]>> = notLoaded, action: Actions): Server<SilAPIResponse<ISieveDryPlant[]>> => {
    switch (action.type) {
        case IGET_SIEVE_DRY_PLANTS_REQUEST.REQUEST:
            return loading;

        case IGET_SIEVE_DRY_PLANTS_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_SIEVE_DRY_PLANTS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_SIEVE_DRY_PLANT_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_SIEVE_DRY_PLANT_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};