import { ISaveYHDCarrierFormRequest, ISAVE_YHD_CARRIER_FORM_REQUEST } from "./saveCarrierFormConstaints";

export interface ISaveYHDCarrierFormLoadAction {
    type: ISAVE_YHD_CARRIER_FORM_REQUEST.REQUEST;
    data: ISaveYHDCarrierFormRequest
}
export const saveYHDCarrierFormLoadAction = (data: ISaveYHDCarrierFormRequest): ISaveYHDCarrierFormLoadAction => ({
    type: ISAVE_YHD_CARRIER_FORM_REQUEST.REQUEST,
    data
});

export interface ISaveYHDCarrierFormSuccessAction {
    type: ISAVE_YHD_CARRIER_FORM_REQUEST.SUCCESS;
    message: string;
}
export const saveYHDCarrierFormLoadSuccessAction = (message: string): ISaveYHDCarrierFormSuccessAction => ({
    type: ISAVE_YHD_CARRIER_FORM_REQUEST.SUCCESS,
    message
});
export interface ISaveYHDCarrierFormLoadFailedAction {
    type: ISAVE_YHD_CARRIER_FORM_REQUEST.FAILED;
    message: string;
}
export const saveYHDCarrierFormLoadFailedAction = (message: string): ISaveYHDCarrierFormLoadFailedAction => ({
    type: ISAVE_YHD_CARRIER_FORM_REQUEST.FAILED,
    message
});
