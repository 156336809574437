import { IWashPlantFeedMaterials } from "../getWashPlantFeedMaterials/getWashPlantFeedMaterialsConstaints";

export enum IUPDATE_WASH_PLANT_FEED_MATERIAL_REQUEST {
    REQUEST = "updateWashPlantFeedMaterial/UPDATE_WASH_PLANT_FEED_MATERIAL_REQUEST",
    SUCCESS = "updateWashPlantFeedMaterial/UPDATE_WASH_PLANT_FEED_MATERIAL_SUCCESS",
    FAILED = "updateWashPlantFeedMaterial/UPDATE_WASH_PLANT_FEED_MATERIAL_FAILED"
};

export interface IUpdateWashPlantFeedMaterialRequest {
    token: string;
    request: IWashPlantFeedMaterials;
};