import { IPassTokenWithRequest } from "../token/getTokenConstants";

export enum IGET_PRODUCTION_LOOKUPS_REQUEST {
    REQUEST = "getProductionLookups/GET_PRODUCTION_LOOKUPS_REQUEST",
    SUCCESS = "getProductionLookups/GET_PRODUCTION_LOOKUPS_SUCCESS",
    FAILED = "getProductionLookups/GET_PRODUCTION_LOOKUPS_FAILED"
};

export interface IGetProductionLookupsRequest extends IPassTokenWithRequest {
    requested_Page: "Dryplant" | "BinAdjustment";
};

export interface IProductionLookups {
    employees: string[],
    locations: string[];
    plants: string[],
    bottlenecks: string[],
    runMaterials: string[],
    byProducts: string[],
    feedMaterials: string[];
    downtime_Category: string[];
    products: string[];
};