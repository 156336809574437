import React, {Component} from 'react';
import CustomerPopup from './customerPopup';
import '../../../../shared/simos/styling/contactTable.css';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import PrintIcon from '@mui/icons-material/Print';
import EmailIcon from '@mui/icons-material/Email';
import {BsPlusCircle} from 'react-icons/bs';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Typography from '@material-ui/core/Typography';
import {currentUser} from '../../../../simosHome';
import { Button, TextField } from '@material-ui/core';
import CreateContactForm, {exportContact} from '../CustomerContactPage/createContactForm';
import getSIMOSSalesOrderNumbers, {getSIMOSSalesOrderNumbersByCustomerID} from '../APICalls/getRequests/getSIMOSSalesOrderNumbers';
import CreateSalesOrderNumberPopup from './createSalesOrderNumberPopup';
  
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
const StyledTableBorder = {
    border: '2px solid rgba(0, 0, 0, 0.28)',
    width:'100%'
    
};
const StyledTableHeader = {
    backgroundColor:'#e3efff'
};
interface IProps {
    changePickupOrder:Function;
}

interface IState {
    showCustomerPopup:boolean;
    showDeliverPopup:boolean;
    customer: any;
    newCustomerDropdown:string;
    newDeliveryDropdown:'';
    chosenCustomer:string;
    deliveryAddr: any;
    chosenDelivery:string;
    contact_selected:boolean;
    orderedby: [{orderformName: string, orderformPhone: string, orderformFax:string, orderformEmail:string, contact_selected:boolean, contactID:number}];
    checkbox?: [];
    orderformName:string;
    orderformPhone: string;
    orderformFax:string;
    pickupOrder:boolean;
    orderformEmail:string;
    pickupOrderRadio:string;
    deleteContactPopup:boolean;
    selectedContact:string;
    showCustomerContactCreateForm:boolean;
    newExplorerCode:string;
    salesOrderNumber:string;
    showSalesOrderNumberPopup:boolean;
    loaded:boolean;
}

var allValues = {customer: [],c_id:0, chosenCustomer:'', chosenDelivery:'', deliveryAddr: [], orderedby: [{orderformName:"", orderformPhone:"",orderformFax:"",orderformEmail:"", contact_selected:false,}], pickupOrder:'', salesOrderNumber:''};
let customerID:any = [];     let chosenId:any = null;
var salesOrderNumberOptions:any[] = [];

class OrderedBy extends Component<IProps, IState>{
    constructor(props: IProps){
        super(props);
        this.state = {
            showCustomerPopup: false, showDeliverPopup: false, newCustomerDropdown:'', newDeliveryDropdown:'',
            customer: [], chosenCustomer:'', chosenDelivery:'', deliveryAddr: [], pickupOrder:false,pickupOrderRadio:'',
             contact_selected: false, orderedby: [{orderformName:"", orderformPhone:"",orderformFax:"",orderformEmail:"", contact_selected:false, contactID:0}], 
             orderformName: '', orderformPhone: '', orderformFax: '', orderformEmail:'', deleteContactPopup:false, selectedContact:"", showCustomerContactCreateForm:false,
             newExplorerCode:'', salesOrderNumber:'',showSalesOrderNumberPopup:false,loaded:false
        };
        this.onTextChange = this.onTextChange.bind(this);
        this.cancelAddSalesOrderNumber = this.cancelAddSalesOrderNumber.bind(this);
        this.handleAddSalesOrderNumber = this.handleAddSalesOrderNumber.bind(this);
        this.getSalesOrderNumbers = this.getSalesOrderNumbers.bind(this);
    }
    cancelAddSalesOrderNumber(){
        this.setState({showSalesOrderNumberPopup:false});
    }
    handleAddSalesOrderNumber(){
        this.setState({showSalesOrderNumberPopup:true});
    }
    private async onTextChange (event: any) {
        const fieldName = event.target.name;
        switch(fieldName) {
            // Customer drop down
            case 'inputCustomer':
                var c_id= event.target.value.substr(0, event.target.value.indexOf(" "));
                this.setState({chosenCustomer:event.target.value.substr(event.target.value.indexOf(" ")+1)});
                // * Finds the ID of selected customer which is used for cascading dropdown
                customerID.map((each:any) => {
                    if(each.id ===  Number(c_id)) {
                        chosenId = each.id;
                    }
                });
                this.fetchDeliveryAddress();                                                    // API call to get all delivery address by customer ID
                this.fetchOrderedBy();       
                await this.getSalesOrderNumbers();                                                       // API call to get all contacts by customer ID
                break;
            // Delivery drop down
            case 'inputDelivery':
                this.setState({chosenDelivery:event.target.value});
                break;
            case 'pickuporder_radio':
                this.setState({pickupOrderRadio: event.target.value});
                if(event.target.value==='Yes'){
                    this.setState({pickupOrder:true});
                } else {
                    this.setState({pickupOrder:false});
                }
                this.props.changePickupOrder();
                break;
            case 'newcustomer':
                this.setState({orderformName:event.target.value});
                break;
            case 'newphone':
                this.setState({orderformPhone:event.target.value});
                break; 
            case 'newfax':
                this.setState({orderformFax:event.target.value});
                break;
            case 'newemail':
                this.setState({orderformEmail:event.target.value});
                break;
            case 'contactselected':
                this.setState(initialState => ({contact_selected: !initialState.contact_selected}));
                break;
            case 'sales_order_number':
                this.setState({salesOrderNumber:event.target.value});
                break;
        }
    }

    handleEdit(index: number, event:any) {
        let current_item = this.state.orderedby;
        switch(event.target.name){
            case 'contactselected':
                current_item[index].contact_selected = !current_item[index].contact_selected;
                if(current_item[index].contact_selected === false){
                    this.setState({selectedContact:''});
                }
                else{
                    this.setState({selectedContact:current_item[index].orderformName});
                }
                break;
            case 'contactname':
                current_item[index].orderformName = event.target.value;
                break;
            case 'contactphone':
                current_item[index].orderformPhone = event.target.value;
                break;
            case 'contactfax':
                current_item[index].orderformFax = event.target.value;
                break;
            case 'contactemail':
                current_item[index].orderformEmail = event.target.value;
                break;
        }
        this.setState({orderedby: current_item});
        
    }

    handleNewCustomerContactOpen(){
        if(exportContact){
            // let new_customer_contact = this.state.orderedby;
            // console.log(new_customer_contact, exportContact)
            // new_customer_contact.push(exportContact);
            // this.setState({orderedby: exportContact});
            this.fetchOrderedBy();
        }
        this.setState({showCustomerContactCreateForm:!this.state.showCustomerContactCreateForm});
    }
    

    handleNewItemClose(dropdownoption: string, event:any) {
        event.preventDefault();
       if(dropdownoption==="customer"){
            this.setState({
                showCustomerPopup: !this.state.showCustomerPopup,
                newCustomerDropdown: ''
            });
        } else {
            this.setState({
                showDeliverPopup: !this.state.showDeliverPopup,
                newDeliveryDropdown: ''
            });
        }
    }

    handleNewItem(dropdownoption: string, event: any) {
        if(dropdownoption==="customer"){
            this.setState({newCustomerDropdown: event.target.value})
        } else {
            this.setState({newDeliveryDropdown: event.target.value});
        }
    }
    editExplorerCode(dropdownoption: string, event: any){
        this.setState({newExplorerCode: event.target.value})
    }

    async handleNewItemSave(dropdownoption: string, event: any) {
        
        if(dropdownoption==="customer"){
            var response = await this.addCustomerAddress(this.state.newCustomerDropdown, this.state.newExplorerCode);
            if(response.status === 200){
                let new_option = this.state.customer;
                new_option.push(this.state.newCustomerDropdown);
                this.setState({customer: new_option});
                this.handleNewItemClose(dropdownoption, event);
                this.onPageLoad();
            }
            else{
                alert("error adding new customer address: server error");
            }
        } else {
            var response = await this.addDeliveryAddress(this.state.newDeliveryDropdown);
            if(response.status === 200){
                let new_option = this.state.deliveryAddr;
                new_option.push(this.state.newDeliveryDropdown);
                this.setState({deliveryAddr: new_option});
                this.handleNewItemClose(dropdownoption, event);
                this.fetchDeliveryAddress();
            }
            else{
                alert("Error adding new customer delivery address: server error");
            }   
        }
    } 
    async addCustomerAddress(customer_name: string, explorer_code:string) {
        var res:any = null;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type':'application/json'},
            body: JSON.stringify({
                request: {
                    Customer:customer_name,
                    Active:"Yes",
                    Explorer_Code:explorer_code,
                    Created_By:currentUser,
                    Modified_By:currentUser
                },
                token: '19515195-d571-44a3-b0db-637f5bf24f54'})
        }
        await fetch('https://api2.propsense.com/api/AddSIMOSCustomer', requestOptions)
        .then(async response => {
            const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
            // error response
            if(!response.ok) {
              const error = (data && data.message) || response.status;
              return Promise.reject(error);
          }
          res = response;
          // no error
        });
        return res;
    }
    async addDeliveryAddress(delivery_address: string) {
        var res:any = null;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type':'application/json'},
            body: JSON.stringify({
                request: {
                    Address:delivery_address,
                    Customer_ID: chosenId,
                    Created_By:currentUser,
                    Modified_By:currentUser
                },
                token: '19515195-d571-44a3-b0db-637f5bf24f54'})
        }
        await fetch('https://api2.propsense.com/api/AddSIMOSDeliveryAddress', requestOptions)
        .then(async response => {
            const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
            // error response
            if(!response.ok) {
              const error = (data && data.message) || response.status;
              return Promise.reject(error);
          }
          res = response;
          // no error
        });
        return res;
    }

    async fetchDeliveryAddress() {
        let initialDelivery:any = [];
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type':'application/json' }, 
            body: JSON.stringify({token: '19515195-d571-44a3-b0db-637f5bf24f54' })
        }
        fetch('https://api2.propsense.com/api/GetSIMOSCustomerAddress', requestOptions)
        .then(async response => {
            const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
              // error response
              if(!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            // no error
            initialDelivery.push("");
            data.map((deliveryaddr:any) => {
                if(deliveryaddr.customer_ID === chosenId){
                    initialDelivery.push(deliveryaddr.address);
                }
            });
            this.setState({deliveryAddr:initialDelivery});

        })
    }

    async fetchOrderedBy() {
        let initialContacts:any = [];
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type':'application/json' }, 
            body: JSON.stringify({token: '19515195-d571-44a3-b0db-637f5bf24f54' })
        }
        fetch('https://api2.propsense.com/api/GetSIMOSOrderedByContact', requestOptions)
        .then(async response => {
            const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
              // error response
              if(!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            // no error
            data.map((contacts:any) => {
                if(contacts.customer_ID === chosenId) {
              
                    //initialContacts.push(contacts);
                    initialContacts.push({orderformName: contacts.contact, orderformEmail:contacts.email, orderformFax:contacts.fax, orderformPhone:contacts.phone, 
                    contact_selected:false, contactID: contacts.id});
                }
            });
            this.setState({orderedby:initialContacts});
        });
     
    }
    async getSalesOrderNumbers(){
        salesOrderNumberOptions = [];
        //var res:any = await getSIMOSSalesOrderNumbers();
        var res:any = await getSIMOSSalesOrderNumbersByCustomerID(chosenId);
        console.log(res)
        res.map((sales_order_number:any) => {
                salesOrderNumberOptions.push(<option>{sales_order_number.sales_Order_Number}</option>);
        });
        this.setState({showSalesOrderNumberPopup:false});
    }
    async onPageLoad(){
        let initialCustomers:string[] = [];
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token: '19515195-d571-44a3-b0db-637f5bf24f54' })
        };
    
        await fetch('https://api2.propsense.com/api/GetSIMOSCustomers', requestOptions)
        .then(async response => {
            const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
            
            // error response
            if(!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            // no error
            data.map((customername:any) => {
                    initialCustomers.push(customername);
                    customerID.push(customername);
            });
            this.setState({customer:initialCustomers});
        })
        .catch(error => {
            console.log('There was an error!', error);
        });
        // await this.getSalesOrderNumbers();
        this.setState({loaded:true});
    }

    async componentDidMount() {
        this.onPageLoad();
    }

    public render() {
        //let customers = this.state.customer;
        let optionItems = this.state.customer.map((customer:any) => {if(customer.explorer_Code) { return <option value={customer.id +' '+customer.customer}>{customer.customer}({customer.explorer_Code})</option>}else{return <option value={customer.id +' '+customer.customer}>{customer.customer}</option>}});
        optionItems.push(<option disabled selected></option>);
        let deliver = this.state.deliveryAddr;
        let optionAddress = deliver.map((address:any) => <option>{address}</option>);
        let customerContactOptions = this.state.orderedby.map((elem:any, idx:number) =>
            <MenuItem value={elem.orderformName}>
                <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: 4 }}>
                <Checkbox checked={elem.contact_selected} onChange={this.handleEdit.bind(this, idx)} name="contactselected"></Checkbox>
                <Grid item xs={3}>
                    <Chip icon={<PermContactCalendarIcon/>} label={elem.orderformName}></Chip>
                </Grid>
                <Grid item xs={3}>
                    <Chip icon={<LocalPhoneIcon/>} label={elem.orderformPhone}></Chip>
                </Grid>
                <Grid item xs={3}>
                    <Chip icon={<PrintIcon />} label={elem.orderformFax}></Chip>
                </Grid>
                <Grid item xs={3}>
                    <Chip icon={<EmailIcon/>} label={elem.orderformEmail}></Chip>
                </Grid>
                </Box>
            </MenuItem>
        );
        
        allValues = {c_id:chosenId, customer: this.state.customer, chosenCustomer: this.state.chosenCustomer, chosenDelivery: this.state.chosenDelivery, deliveryAddr: this.state.deliveryAddr, 
        orderedby: this.state.orderedby,pickupOrder:this.state.pickupOrderRadio, salesOrderNumber:this.state.salesOrderNumber};
        if(this.state.loaded ){

    
        return (
            <div className="form-group">
                {/* <div className="customer-group"> */}
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <FormControl required>
                                <InputLabel style={{color:'black'}} htmlFor="customer-dropdown-options">Customer</InputLabel>
                                <Select native placeholder = {this.state.chosenCustomer} onChange={this.onTextChange} inputProps={{name:'inputCustomer',id:'customer-dropdown-options'}}>
                                {optionItems}
                                 </Select>         
                            </FormControl> <button onClick={this.handleNewItemClose.bind(this, "customer")} id='create-customer'><BsPlusCircle/></button> {this.state.showCustomerPopup ? 
                            <CustomerPopup labelText={"Customer Name"} optionitems={this.state.customer} text='Customer Lookup: New Customer' inputfield={this.state.newCustomerDropdown} editPopup = {this.handleNewItem.bind(this, "customer")} 
                            savePopup = {this.handleNewItemSave.bind(this, "customer")} closePopup={this.handleNewItemClose.bind(this, "customer")} editExplorerCode={this.editExplorerCode.bind(this,"explorercode")}/>:null}
                        </Grid>
                        {/* <Grid item xs={4}>
                            <TextField label="Sales Order #" style={{color:'black'}} onChange={this.onTextChange} name="sales_order_number"></TextField>
                        </Grid> */}
                        {/* <Grid item xs={3}>
                    <FormControl>
                        <InputLabel htmlFor="transport-native-simple">Sales Order #</InputLabel>
                            <Select native value = {this.state.salesOrderNumber} onChange={this.onTextChange} name="sales_order_number">
                            <option disabled value=""></option>
                            {salesOrderNumberOptions}
                        </Select>
                    </FormControl>
                    <button onClick={this.handleAddSalesOrderNumber.bind(this)} id='create-customer'><BsPlusCircle/></button> 
                         {this.state.showSalesOrderNumberPopup? <CreateSalesOrderNumberPopup refresh={this.getSalesOrderNumbers(chosenId)} cancelPopup={this.cancelAddSalesOrderNumber}></CreateSalesOrderNumberPopup>:null}
                </Grid> */}
                <Grid item xs={3}>
                    <FormControl>
                        <InputLabel htmlFor="transport-native-simple">Sales Order #</InputLabel>
                        {salesOrderNumberOptions.length > 1 ? 
                            <Select native value = {this.state.salesOrderNumber} onChange={this.onTextChange} name="sales_order_number">
                            <option disabled value=""></option>
                            {salesOrderNumberOptions}
                        </Select>
                        :
                        <Select native value= {this.state.salesOrderNumber} onChange={this.onTextChange}>
                                  <option disabled selected>No options available</option>
                                 </Select>
                        }
                    </FormControl>
                    <button disabled={this.state.chosenCustomer === ""}  onClick={this.handleAddSalesOrderNumber.bind(this)} id='create-customer'><BsPlusCircle/></button> 
                         {this.state.showSalesOrderNumberPopup? <CreateSalesOrderNumberPopup refresh={this.getSalesOrderNumbers} cancelPopup={this.cancelAddSalesOrderNumber}></CreateSalesOrderNumberPopup>:null}
                </Grid>
                        {/* <Grid item xs={4}>
                            <FormLabel component="legend">Pickup?</FormLabel>
                                <RadioGroup aria-label="gender" name="pickuporder_radio" value={this.state.pickupOrderRadio} onChange={this.onTextChange}>
                                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                    <FormControlLabel value="No" control={<Radio />} label="No" />
                                </RadioGroup>
                        </Grid> */}
                        <Grid item xs={4}>
                        <FormControl required>
                                <InputLabel style={{color:'black'}} htmlFor="delivery-dropdown-options">Delivery Address</InputLabel>
                                {optionAddress.length >1 ? <Select native defaultValue = {optionAddress[0]} value= {this.state.chosenDelivery} onChange={this.onTextChange} inputProps={{name:'inputDelivery',id:'delivery-dropdown-options'}}>
                                   {optionAddress}
                                 </Select>:<Select native value= {this.state.chosenDelivery} onChange={this.onTextChange} inputProps={{name:'inputDelivery',id:'delivery-dropdown-options'}}>
                                  <option disabled selected>No options available</option>
                                 </Select>}
                            </FormControl>
                         <button disabled={this.state.chosenCustomer === ""} onClick={this.handleNewItemClose.bind(this, "delivery")} id='create-delivery'><BsPlusCircle/></button> {this.state.showDeliverPopup ? 
                        <CustomerPopup labelText={"Delivery Address"} inputfield={this.state.newDeliveryDropdown} optionitems={this.state.deliveryAddr} text='Customer Address Lookup: New Delivery Address' editPopup = {this.handleNewItem.bind(this, "delivery")} 
                        savePopup = {this.handleNewItemSave.bind(this, "delivery")} closePopup={this.handleNewItemClose.bind(this, "delivery")} editExplorerCode={this.editExplorerCode.bind(this,"explorercode")}/>:null}
                        </Grid>
                 </Grid>
                <br></br>
                <br></br>
                <Grid container spacing={3}>
                    <Paper style={StyledTableBorder}>
                    <Grid item xs={12}>
                    <Typography style={{fontWeight:"bold"}} variant="h5" gutterBottom component="div">Select a Customer Contact</Typography>
                    <Button disabled={this.state.chosenDelivery === ""} onClick={this.handleNewCustomerContactOpen.bind(this)} id='create-customer-contact'><BsPlusCircle/></Button>
                        <Select 
                        variant="outlined"
                        fullWidth
                        name="customer_contact_select"
                        value={this.state.selectedContact}
                        onChange={this.onTextChange}
                        >
                             {customerContactOptions}
                        </Select>
                    </Grid>
                    </Paper>  <br></br>
                </Grid>
                {/* isCreateFromOrderPage true = creating customer contact from create form */}
                {this.state.showCustomerContactCreateForm ?  <CreateContactForm values={allValues} closeCreateForm={this.handleNewCustomerContactOpen.bind(this)} isCreateFromOrderPage={true} history={null}></CreateContactForm>:null}
                </div>
         
        );
                         }else{
                            return null;
                         }
        }
}
 

export default OrderedBy;
export {allValues, chosenId};
