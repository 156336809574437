import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IAddWarehouseProductLoadAction, 
    IAddWarehouseProductLoadFailedAction, 
    IAddWarehouseProductSuccessAction, 
    addWarehouseProductLoadFailedAction, addWarehouseProductLoadSuccessAction
} from "./addWarehouseProductActions";
import { IADD_WAREHOUSE_PRODUCT_REQUEST } from "./addWarehouseProductConstaints";
import { SilAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { END_POINTS } from "../../../../endpoints";
import { withJsonContentType } from "../../../../epicUtils";
import { IStore } from "../../../../reducers";
import { EpicDependencies } from "../../../../store";

export const addWarehouseProductEpic: Epic = (
    action$: ActionsObservable<IAddWarehouseProductLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IAddWarehouseProductSuccessAction | IAddWarehouseProductLoadFailedAction> =>
    action$.ofType(IADD_WAREHOUSE_PRODUCT_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<string>>(
                    END_POINTS.PROPSENSE.SIL.WAREHOUSE.ADD_WAREHOUSE_PRODUCT,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<string>): IAddWarehouseProductSuccessAction | IAddWarehouseProductLoadFailedAction => {
                            if(response.message === "Success"){
                                return addWarehouseProductLoadSuccessAction(response.message);
                            };
                            return addWarehouseProductLoadFailedAction(response.message)
                        }),
                        catchError(() => ActionsObservable.of(addWarehouseProductLoadFailedAction("Unable to add warehouse product")))
                    )
            )
        );