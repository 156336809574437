import { PropsenseAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { IGetRequestBody } from "../getJobs/getJobsConstants";
import { ICrew, IGET_CREWS_REQUEST } from "./getCrewsConstants";

export interface IGetCrewsLoadAction {
    type: IGET_CREWS_REQUEST.REQUEST;
    data: IGetRequestBody;
}
export const getCrewsLoadAction = (data: IGetRequestBody): IGetCrewsLoadAction => ({
    type: IGET_CREWS_REQUEST.REQUEST,
    data
});
export interface IGetCrewsSuccessAction {
    type: IGET_CREWS_REQUEST.SUCCESS;
    list: PropsenseAPIResponse<ICrew[]>;
}
export const getCrewsLoadSuccessAction = (list: PropsenseAPIResponse<ICrew[]>): IGetCrewsSuccessAction => ({
    type: IGET_CREWS_REQUEST.SUCCESS,
    list
});
export interface IGetCrewsLoadFailedAction {
    type: IGET_CREWS_REQUEST.FAILED;
    message: string;
}
export const getCrewsLoadFailedAction = (message: string): IGetCrewsLoadFailedAction => ({
    type: IGET_CREWS_REQUEST.FAILED,
    message
});
