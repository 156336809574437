import { IAddCarrierFleetTrailerRequest, IADD_CARRIER_FLEET_TRAILER_REQUEST } from "./addCarrierFleetTrailerConstaints";

export interface IAddCarrierFleetTrailerLoadAction {
    type: IADD_CARRIER_FLEET_TRAILER_REQUEST.REQUEST;
    data: IAddCarrierFleetTrailerRequest
}
export const addCarrierFleetTrailerLoadAction = (data: IAddCarrierFleetTrailerRequest): IAddCarrierFleetTrailerLoadAction => ({
    type: IADD_CARRIER_FLEET_TRAILER_REQUEST.REQUEST,
    data
});

export interface IAddCarrierFleetTrailerSuccessAction {
    type: IADD_CARRIER_FLEET_TRAILER_REQUEST.SUCCESS;
    message: string;
}
export const addCarrierFleetTrailerLoadSuccessAction = (message: string): IAddCarrierFleetTrailerSuccessAction => ({
    type: IADD_CARRIER_FLEET_TRAILER_REQUEST.SUCCESS,
    message
});

export interface IAddCarrierFleetTrailerLoadFailedAction {
    type: IADD_CARRIER_FLEET_TRAILER_REQUEST.FAILED;
    message: string;
}
export const addCarrierFleetTrailerLoadFailedAction = (message: string): IAddCarrierFleetTrailerLoadFailedAction => ({
    type: IADD_CARRIER_FLEET_TRAILER_REQUEST.FAILED,
    message
});
