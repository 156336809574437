import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IGetProductLookupsLoadAction, IGetProductLookupsLoadFailedAction, IGetProductLookupsSuccessAction, 
    getProductLookupsLoadFailedAction, getProductLookupsLoadSuccessAction
} from "./getProductLookupsActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IGET_PRODUCT_LOOKUPS_REQUEST } from "./getProductLookupsConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IProductionLookups } from "../getProductionLookups/getProductionLookupsConstaints";

export const getProductLookupsEpic: Epic = (
    action$: ActionsObservable<IGetProductLookupsLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IGetProductLookupsSuccessAction | IGetProductLookupsLoadFailedAction> =>
    action$.ofType(IGET_PRODUCT_LOOKUPS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<IProductionLookups>>(
                    END_POINTS.PROPSENSE.SIL.DRY_PLANT.GET_DRY_PLANT_PRODUCTION_LOOKUPS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<IProductionLookups>): IGetProductLookupsSuccessAction => {
                            return getProductLookupsLoadSuccessAction(response);
                        }),
                        catchError(() => ActionsObservable.of(getProductLookupsLoadFailedAction("Unable to get product lookups")))
                    )
            )
        );