import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IUpdateNCRLoadAction, IUpdateNCRLoadFailedAction, IUpdateNCRSuccessAction, updateNCRLoadFailedAction,
     updateNCRLoadSuccessAction } from "./updateNCRActions";
import { IUPDATE_NCR_REQUEST } from "./updateNCRConstaints";
import { IStore } from "../../../../reducers";
import { EpicDependencies } from "../../../../store";
import { SilAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { END_POINTS } from "../../../../endpoints";
import { withJsonContentType } from "../../../../epicUtils";

export const updateNCREpic: Epic = (
    action$: ActionsObservable<IUpdateNCRLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IUpdateNCRSuccessAction | IUpdateNCRLoadFailedAction> =>
    action$.ofType(IUPDATE_NCR_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<string>>(
                    END_POINTS.PROPSENSE.SIL.CV.UpdateCVNCR,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<string>): IUpdateNCRSuccessAction | IUpdateNCRLoadFailedAction => {
                            if(response.message === "Success"){
                                return updateNCRLoadSuccessAction(action.data.request);
                            }
                            return updateNCRLoadFailedAction(response.message);
                        }),
                        catchError(() => ActionsObservable.of(updateNCRLoadFailedAction("Unable to save your changes")))
                    )
            )
        );