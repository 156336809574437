import {currentUser} from '../../../../simosHome';

async function editOrder(all_info: any) {
    console.log('saving edit: ', all_info)
    var responseStatus:any = null;
    if(all_info.cancelled === "Yes"){
        all_info.status = "Cancelled";
    }
    let tempProducts = [];
    if(all_info.product_Order!=null){
        for (var i=0;i<all_info.product_Order.length; i++){
            tempProducts.push({ID:all_info.product_Order[i].id, Quantity:Number(all_info.product_Order[i].quantity), Size:all_info.product_Order[i].size, Per:all_info.product_Order[i].per,
                Product:all_info.product_Order[i].product,Code:all_info.product_Order[i].code,CW:all_info.product_Order[i].cw,Double_CW:all_info.product_Order[i].double_CW, Actual_Quantity:Number(all_info.product_Order[i].actual_Quantity)});
        }
    }
    let emailuser="No", emailtransport="No", packaged="No", completed="No";
    if(all_info.emailuser===true){
        emailuser = "Yes";
    }
    if(all_info.emailtransport===true) {
        emailtransport="Yes";
    }
    if(all_info.completed===true) {
        completed="Yes";
    }
    if(all_info.packaged === true || all_info.packaged==="Yes"){
        packaged = "Yes";
    }
    else{
        packaged = "No";
    }
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type':'application/json'},
        body: JSON.stringify({
            request: {
                ID: all_info.id,
                Pickupdate: all_info.pickupdate,
                Pickuphour: all_info.pickuphour,
                Pickupminute: all_info.pickupminute,
                Sitecontact: all_info.sitecontact,
                Sitecontact_Phone: all_info.sitecontact_Phone,
                PO_Number: all_info.pO_Number,
                Emailuser: emailuser,
                Emailtransport: emailtransport,
                Transport_Company: all_info.transport_Company,
                Truck_Required: all_info.truck_Required,
                Deliverydate: all_info.deliverydate,
                Deliveryhour: all_info.deliveryhour,
                Deliveryminute: all_info.deliveryminute,
                Location: all_info.location,
                Comments: all_info.comments,
                Packaged: packaged,
                Completed: completed,
                Cancelled: all_info.cancelled,
                Truck_Number: all_info.truck_Number, 
                Trailer_Number: all_info.trailer_Number,
                Tare_Weight: all_info.tare_Weight,
                Gross_Weight: all_info.gross_Weight,
                Modified_By:currentUser,
                Product_Order:tempProducts,
                Total_Tonnage:all_info.total_Tonnage,
                Status:"Assigned",
                Order_Type:all_info.order_Type,
                 // If order type is collect set transport notes, otherwise set to N/A
                Transport_Company_Notes:all_info.order_Type === 'collect' ? all_info.transport_Company_Notes:"N/A",
                Delivery_Datetime:all_info.delivery_Datetime,
                Pickup_Datetime:all_info.pickup_Datetime,
                Zone:all_info.zone,
                Hired_Truck_Rates:all_info.hired_Truck_Rates,
                Truck_Code:all_info.truck_Code,
                Time_In:all_info.time_In,
                Time_Out:all_info.time_Out,
                Customer_Job_Number:all_info.customer_Job_Number
            },
            token: '19515195-d571-44a3-b0db-637f5bf24f54'})
    }
    await fetch('https://api2.propsense.com/api/UpdateInternalSIMOSOrderChanged', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json')&& await response.json();
        // error response
        if(!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        responseStatus = response;
    });
    return responseStatus;
}
export default editOrder;