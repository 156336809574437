import React from "react";
import { LAIconButton } from "../../../shared/buttons";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { LAPaperWithLessPadding } from "../../../shared/paper";
import { AddIcon, DeleteIcon } from "../../../shared/icons";
import LAAutoComplete from "../../../shared/autoComplete";
import { undefinedFunction } from "../../../shared/constExports";
import LATextField from "../../../shared/textField";

interface IRRTable {
    disabled?: boolean;
    productList: Object[],
    palletSizeList: Object[],
    data: any;
    handleChange:(event:any,value:any, index?:number, name?:any | undefined) => void;
    onAdd:()=>void;
    onDelete:(index:Number | undefined)=>void;
  };
  
  const RRTable: React.FC<IRRTable> = React.memo((props: IRRTable) => (
    <TableContainer component={LAPaperWithLessPadding}>
        <LAIconButton
            label="Add New"
            icon={<AddIcon />}
            onClick={props.onAdd} 
        /> 
        <Table size="small" aria-label="RR Items">
            <TableHead className="header-row">
                <TableRow>
                    <TableCell className="title">Product</TableCell>
                    <TableCell className="title">Pallet Size</TableCell>
                    <TableCell className="title">Count</TableCell>
                    <TableCell className="title"></TableCell>
                </TableRow>
            </TableHead>
            {props.data.length > 0  && props.data !== undefined ? 
              props.data.map((x: any, index: number) => {
                console.log(x)
                // if(x.deleted!="Yes"){
                  return <TableBody key={index}>
                      <TableRow>
                          <TableCell>
                              <LAAutoComplete
                                  multiple={false}
                                  option={props.productList}
                                  autoHighlight={true}
                                  getOptionLabel="product"
                                  filterSelectedOptions={true}
                                  selectionRemove={undefinedFunction}
                                  dropDownPlaceHolder="Product"
                                  name="product"
                                  disabled={false}
                                  defaultValue={x.product ? props.productList.find((q:any) => q.product === x.product) : null}
                                  value={x.product ? props.productList.find((q:any) => q.product === x.product) : null}
                                  onChange={(e:any,val)=>{props.handleChange(e,val.product,index,'product')}}
                                  // defaultValue={x.product ? props.productList.find((q:any) => {if(q.product === x.product)
                                  //   {x.product=q.product; return true}}) : null}
                                  // indexPosition={index}
                              />
                          </TableCell>
    
                          <TableCell>
                            <LAAutoComplete
                                  multiple={false}
                                    option={props.palletSizeList}
                                    autoHighlight={true}
                                    getOptionLabel="name"
                                    filterSelectedOptions={true}
                                    selectionRemove={undefinedFunction}
                                    dropDownPlaceHolder="Pallet Size"
                                    name="pallet_Size"
                                    disabled={false}
                                    defaultValue={x.pallet_Size ? props.palletSizeList.find((q:any) => q.name === x.pallet_Size) : null}
                                    value={x.pallet_Size ? props.palletSizeList.find((q:any) => q.name === x.pallet_Size) : null}
                                    onChange={(e:any,val)=>{props.handleChange(e,val.name,index,'pallet_Size')}}
                                    // defaultValue={x.pallet_Size ? props.palletSizeList.find((q:any) => {if(q.name === x.pallet_Size)
                                    //   {x.pallet_Size=q.name; return true}}) : null}
                                    // indexPosition={index}
                                />
                          </TableCell>
    
                          <TableCell>
                              <LATextField
                                  fullWidth={true}
                                  varient="outlined"
                                  // indexPosition={index}
                                  name="count"
                                  label="Count"
                                  disabled={false}
                                  value={x.count?x.count:0}
                                  onChange={(e:any,val)=>{props.handleChange(e,val,index,'count')}}
                                  type="number"
                              />
                          </TableCell>
                          <TableCell>
                              <LAIconButton
                                  label="Delete"
                                  icon={<DeleteIcon />}
                                  onClick={(e:any)=>{props.onDelete(index)}} 
                              />
                          </TableCell>
                      </TableRow>
                  </TableBody>
                // }
              })
              : null
            }
         </Table>
     </TableContainer>
   ));
  
export default RRTable;