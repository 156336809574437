import { IWashPlantFeedMaterials } from "../getWashPlantFeedMaterials/getWashPlantFeedMaterialsConstaints";

export enum IADD_WASH_PLANT_FEED_MATERIAL_REQUEST {
    REQUEST = "addWashPlantFeedMaterial/ADD_WASH_PLANT_FEED_MATERIAL_REQUEST",
    SUCCESS = "addWashPlantFeedMaterial/ADD_WASH_PLANT_FEED_MATERIAL_SUCCESS",
    FAILED = "addWashPlantFeedMaterial/ADD_WASH_PLANT_FEED_MATERIAL_FAILED"
};

export interface IAddWashPlantFeedMaterialRequest {
    token: string;
    request: IWashPlantFeedMaterials;
};