import { IPassTokenWithRequest } from "../../../dryPlant/token/getTokenConstants";

export enum IGET_PS_LOOKUPS_REQUEST {
    REQUEST = "getPSLookups/GET_PS_LOOKUPS_REQUEST",
    SUCCESS = "getPSLookups/GET_PS_LOOKUPS_SUCCESS",
    FAILED = "getPSLookups/GET_PS_LOOKUPS_FAILED"
};

export interface IGetPSLookupsRequest extends IPassTokenWithRequest {
    Requested_Page: "Bagged_Inventory";
};

export interface IPSLookup {
    palletList: string[];
    products: { 
        id: number;
        product: string;
    }[];
};