import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IGetCarrierFleetTrailersLoadAction, IGetCarrierFleetTrailersLoadFailedAction, IGetCarrierFleetTrailersSuccessAction, 
    getCarrierFleetTrailersLoadFailedAction, getCarrierFleetTrailersLoadSuccessAction
} from "./getCarrierFleetTrailersActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IGET_CARRIER_FLEET_TRAILERS_REQUEST, ICarrierFleetTrailer } from "./getCarrierFleetTrailersConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";

export const getCarrierFleetTrailersEpic: Epic = (
    action$: ActionsObservable<IGetCarrierFleetTrailersLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IGetCarrierFleetTrailersSuccessAction | IGetCarrierFleetTrailersLoadFailedAction> =>
    action$.ofType(IGET_CARRIER_FLEET_TRAILERS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<ICarrierFleetTrailer[]>>(
                    END_POINTS.PROPSENSE.SIL.CARRIER.GET_CARRIER_FLEET_TRAILER,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<ICarrierFleetTrailer[]>): IGetCarrierFleetTrailersSuccessAction => {
                            return getCarrierFleetTrailersLoadSuccessAction(response);
                        }),
                        catchError(() => ActionsObservable.of(getCarrierFleetTrailersLoadFailedAction("Unable to get carrier fleet trailers")))
                    )
            )
        );