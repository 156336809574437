import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, isSucceeded, Server } from "../../../../redux/server";
import { ById, SilAPIResponse } from "../../../shared/publicInterfaces";
import { userName, undefinedFunction, yesOrNoOptions, ZEROTH, numberAndDecimalCheck, numberAndNotDecimalCheck } from "../../../shared/constExports";
import { LAButton, LASaveAndCancelButton } from "../../../shared/buttons";
import { ArrowLeftIcon } from "../../../shared/icons";
import { WHITE_COLOR } from "../../../shared/theme";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import LATextField from "../../../shared/textField";
import LAAutoComplete from "../../../shared/autoComplete";
import { ROUTE } from "../../../routes";
import queryString from "query-string";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import { FieldValidator, FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../../shared/fieldValidator";
import PageSpacing from "../../../shared/pageSpacing";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import SilRoleCheck from "../../../shared/silRoleCheck";
import { IGetWarehouseProductsRequest, IWarehouseProducts } from "../../../../redux/sil/warehouse/warehouseProducts/getWarehouseProducts/getWarehouseProductsConstaints";
import { getWarehouseProductsLoadAction } from "../../../../redux/sil/warehouse/warehouseProducts/getWarehouseProducts/getWarehouseProductsActions";
import { getWarehouseProductsStatus } from "../../../../redux/sil/warehouse/warehouseProducts/getWarehouseProducts/getWarehouseProductsAccessor";
import { addWarehouseProductStatus } from "../../../../redux/sil/warehouse/warehouseProducts/addWarehouseProduct/addWarehouseProductAccessor";
import { addWarehouseProductLoadAction } from "../../../../redux/sil/warehouse/warehouseProducts/addWarehouseProduct/addWarehouseProductActions";
import { IAddUpdateWarehouseProduct, IAddWarehouseProductRequest } from "../../../../redux/sil/warehouse/warehouseProducts/addWarehouseProduct/addWarehouseProductConstaints";
import { updateWarehouseProductStatus } from "../../../../redux/sil/warehouse/warehouseProducts/updateWarehouseProduct/updateWarehouseProductAccessor";
import { IUpdateWarehouseProductRequest } from "../../../../redux/sil/warehouse/warehouseProducts/updateWarehouseProduct/updateWarehouseProductConstaints";
import { updateWarehouseProductLoadAction } from "../../../../redux/sil/warehouse/warehouseProducts/updateWarehouseProduct/updateWarehouseProductActions";


const requiredFields = ["product", "description"];

interface IWarehouseProductStoreProps {
    addWarehouseProduct: Server<string>;
    updateWarehouseProduct: Server<string>;
    getToken: Server<SilAPIResponse<IToken>>;
    getWarehouseProducts: Server<SilAPIResponse<IWarehouseProducts[]>>;
};

interface IWarehouseProductDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getWarehouseProductsRequest: (data: IGetWarehouseProductsRequest) => unknown;
    addWarehouseProductRequest: (data: IAddWarehouseProductRequest) => unknown;
    updateWarehouseProductRequest: (data: IUpdateWarehouseProductRequest) => unknown;
};


interface IWarehouseProductOwnProps {

};

interface IWarehouseProductState {
    data: IWarehouseProducts;
    errors: ById<IFieldErrorKeyValue>;
};

const WarehouseProductStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;

    .dx-toolbar-after {
        display: none;
    };

    .variancepopup{
        height: 50% !important;
    }
`;

type IWarehouseProductProps = RouteComponentProps
    & IWarehouseProductStoreProps
    & IWarehouseProductDispatchProps
    & IWarehouseProductOwnProps;

class WarehouseProduct extends PureComponent<IWarehouseProductProps, IWarehouseProductState> {

    public constructor(props: IWarehouseProductProps) {
        super(props);
        this.state = {
            errors: {
                "product": { key: "product", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "description": { key: "description", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
            },
            data: {
                id: 0,
                product: "",
                description: "",
                unit_Conversion: 0,
                depletes_Bulk_Code_1: "",
                yield_1: 0,
                depletes_Bulk_Code_2: "",
                yield_2: 0,
                consumables_Depletion_Product: "",
                bags: 0,
                bag_Size: "",
                bag_Depletion: 0,
                active: "Yes",
                created: "",
                created_By: userName,
                modified: "",
                modified_By: userName,
            }
        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: IWarehouseProductProps): void {
        if (this.props !== prevProps) {
            this.callServer();

            if (this.props.addWarehouseProduct !== prevProps.addWarehouseProduct) {
                if (isSucceeded(this.props.addWarehouseProduct)) {
                    this.handleCancel();
                };
            };

            if (this.props.updateWarehouseProduct !== prevProps.updateWarehouseProduct) {
                if (isSucceeded(this.props.updateWarehouseProduct)) {
                    this.handleCancel();
                };
            };
        };
    };


    public render(): ReactNode {

        const { data, errors } = this.state;
        const { updateWarehouseProduct, addWarehouseProduct  } = this.props;
        console.log(addWarehouseProduct)
        // console.log(data)
        const handleOnActiveChange = (event: unknown, value: { id: number, name: string }): void => this.onChange("active", value !== null ? value.name : data.active);

        return (
            <PageSpacing title="SIL - Warehouse Product" description="Warehouse Product" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="warehouseAccess">
                    <WarehouseProductStyles>

                        <LAButton startIcon={<ArrowLeftIcon color={WHITE_COLOR} />} label="Back to list" onClick={this.handleCancel} />
                        <h2 className="text-center">{data.id > 0 ? "VIEW/UPDATE " : "ADD "} Warehouse Product</h2>
                        <hr />

                        <LAPaperWithPadding>
                            <LAGrid spacing={1}>
                                <LAGridItem xs={12} sm={6} md={3}>                                   
                                     <LATextField
                                        varient="outlined"
                                        label="Product"
                                        fullWidth={true}
                                        name="product"
                                        disabled={data.id > 0}
                                        onChange={this.onChange}
                                        value={data.product ?? ""}
                                        errorText={errors && errors["product"] ? errors["product"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>                                   
                                     <LATextField
                                        varient="outlined"
                                        label="Description"
                                        fullWidth={true}
                                        name="description"
                                        onChange={this.onChange}
                                        value={data.description ?? ""}
                                        errorText={errors && errors["description"] ? errors["description"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        label="Unit Conversion"
                                        fullWidth={true}
                                        varient="outlined"
                                        name="unit_Conversion"
                                        type="number"
                                        onChange={this.onChange}
                                        value={data.unit_Conversion}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>                                   
                                     <LATextField
                                        varient="outlined"
                                        label="Depletes_Bulk_Code_1"
                                        fullWidth={true}
                                        name="depletes_Bulk_Code_1"
                                        onChange={this.onChange}
                                        value={data.depletes_Bulk_Code_1 ?? ""}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        label="Yield_1"
                                        fullWidth={true}
                                        varient="outlined"
                                        name="yield_1"
                                        type="number"
                                        onChange={this.onChange}
                                        value={data.yield_1}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>                                   
                                     <LATextField
                                        varient="outlined"
                                        label="Depletes_Bulk_Code_2"
                                        fullWidth={true}
                                        name="depletes_Bulk_Code_2"
                                        onChange={this.onChange}
                                        value={data.depletes_Bulk_Code_2 ?? ""}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        label="Yield_2"
                                        fullWidth={true}
                                        varient="outlined"
                                        name="yield_2"
                                        type="number"
                                        onChange={this.onChange}
                                        value={data.yield_2}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>                                   
                                     <LATextField
                                        varient="outlined"
                                        label="Consumables_Depletion_Product"
                                        fullWidth={true}
                                        name="consumables_Depletion_Product"
                                        onChange={this.onChange}
                                        value={data.consumables_Depletion_Product ?? ""}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        label="Bags"
                                        fullWidth={true}
                                        varient="outlined"
                                        name="bags"
                                        type="number"
                                        onChange={this.onChange}
                                        value={data.bags}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>                                   
                                     <LATextField
                                        varient="outlined"
                                        label="Bag_Size"
                                        fullWidth={true}
                                        name="bag_Size"
                                        onChange={this.onChange}
                                        value={data.bag_Size}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        label="Bag_Depletion"
                                        fullWidth={true}
                                        varient="outlined"
                                        name="bag_Depletion"
                                        type="number"
                                        onChange={this.onChange}
                                        value={data.bag_Depletion}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LAAutoComplete
                                        multiple={false}
                                        option={yesOrNoOptions}
                                        getOptionLabel="name"
                                        autoHighlight={true}
                                        onChange={handleOnActiveChange}
                                        filterSelectedOptions={true}
                                        dropDownPlaceHolder="Active"
                                        selectionRemove={undefinedFunction}
                                        value={data.active ? yesOrNoOptions.find(q => q.name === data.active) : null}
                                        defaultValue={data.active ? yesOrNoOptions.find(q => q.name === data.active) : null}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12}>
                                    <LASaveAndCancelButton
                                        onSave={this.onSave}
                                        onCancel={this.handleCancel}
                                        disableSave={(Object.values(errors).length > 0 ? true : undefined)}
                                    />
                                </LAGridItem>

                            </LAGrid>
                        </LAPaperWithPadding>

                        <RequestStatus requestStatus={addWarehouseProduct.kind} successMessage="Warehouse Product successfully saved" failedMessage={addWarehouseProduct.kind === "failed" ? addWarehouseProduct.message : "Your Request has failed. Please try again or contact IT.Developers for assistance."} />
                        <RequestStatus requestStatus={updateWarehouseProduct.kind} successMessage="Warehouse Product  successfully updated" failedMessage={updateWarehouseProduct.kind === "failed" ? updateWarehouseProduct.message : "Your Request has failed. Please try again or contact IT.Developers for assistance."}/>

                    </WarehouseProductStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, isRequired: boolean): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: isRequired ? true : undefined, minLength: 1, decimal: undefined });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        };
        return err;
    };

    private handleCancel = (): void => {
        this.props.history.push(ROUTE.SIL.WAREHOUSE_PRODUCTS.PRODUCTS);
    };

    private onSave = async (): Promise<void> => {

        if (hasPayload(this.props.getToken)) {
            const { data } = this.state;

            const request: IAddUpdateWarehouseProduct = {
                ID: data.id,
                Product: data.product,
                Description: data.description,
                Unit_Conversion: data.unit_Conversion,
                Depletes_Bulk_Code_1: data.depletes_Bulk_Code_1,
                Yield_1: data.yield_1,
                Depletes_Bulk_Code_2: data.depletes_Bulk_Code_2,
                Yield_2: data.yield_2,
                Consumables_Depletion_Product: data.consumables_Depletion_Product,
                Bags: data.bags,
                Bag_Size: data.bag_Size,
                Bag_Depletion: data.bag_Depletion,
                Active: data.active,
                Created_By: userName,
                Modified_By:  userName,
            };

            if (data.id === 0) {
                this.props.addWarehouseProductRequest({
                    token: this.props.getToken.payload.response.token,
                    request
                });
            } else {
                this.props.updateWarehouseProductRequest({
                    token: this.props.getToken.payload.response.token,
                    request
                });
            };
        }
    };

    private onChange = (name: string, value: string): void => {
        let errors = this.state.errors;

        if (requiredFields.includes(name))
            errors = this.errorChecker(name, value, errors, true);

        if(name === "yield_1" || name === "yield_2") {
            if (numberAndNotDecimalCheck(value)) {
                this.setState({
                    ...this.state,
                    errors,
                    data: {
                        ...this.state.data,
                        [name]: value
                    }
                });
            }
        } else {
            this.setState({
                ...this.state,
                errors,
                data: {
                    ...this.state.data,
                    [name]: value
                }
            });
        }

    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getWarehouseProducts))
            this.props.getWarehouseProductsRequest({
                token: this.props.getToken.payload.response.token
            });


        const query = queryString.parse(this.props.location.search);
        const id = query.id ?? "0";

        if ((id !== "0") && (hasPayload(this.props.getWarehouseProducts)) && (this.state.data.id === 0)) {
            const data = this.props.getWarehouseProducts.payload.response.find((x) => x.id === +id);

            if (data)
                this.setState({ data, errors: {} });
        };

    };

}

const mapStateToProps = (state: IStore): IWarehouseProductStoreProps => ({
    getToken: getTokenStatus(state),
    getWarehouseProducts: getWarehouseProductsStatus(state),
    updateWarehouseProduct: updateWarehouseProductStatus(state),
    addWarehouseProduct: addWarehouseProductStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IWarehouseProductDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getWarehouseProductsRequest: (data: IGetWarehouseProductsRequest) => dispatch(getWarehouseProductsLoadAction(data)),
    addWarehouseProductRequest: (data: IAddWarehouseProductRequest) => dispatch(addWarehouseProductLoadAction(data)),
    updateWarehouseProductRequest: (data: IUpdateWarehouseProductRequest) => dispatch(updateWarehouseProductLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WarehouseProduct);