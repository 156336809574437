import { IPassTokenWithRequest } from "../../dryPlant/token/getTokenConstants";
import { IAddSieveCheckMeasurementRequest } from "../addSieveCheck/addSieveCheckConstaints";

export enum IUPDATE_SIEVE_CHECK_REQUEST {
    REQUEST = "updateSieveCheck/UPDATE_SIEVE_CHECK_REQUEST",
    SUCCESS = "updateSieveCheck/UPDATE_SIEVE_CHECK_SUCCESS",
    FAILED = "updateSieveCheck/UPDATE_SIEVE_CHECK_FAILED"
};

export interface IUpdateSieveCheckRequest extends IPassTokenWithRequest {
    request: IUpdateSieveCheck;
};

export interface IUpdateSieveCheck {
    ID: number;
    Date: string;
    Operator?: string;
    Plant: string;
    Products: string;
    Notes?: string;
    Created_By: string;
    Modified_By: string;
    Measurements: IAddSieveCheckMeasurementRequest[];
};