import { AuthenticationContext, adalFetch, withAdalLogin } from "react-adal";

export const adalConfig = {
    instance: "https://adfs.sureway.ca/",
    tenant: "adfs",
    clientId: "ce8f5df9-cb99-440d-bed5-7e2d3c299955",
    endpoints: {
        api: "",
    },
    cacheLocation: "localStorage",
};

export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch, url, options) =>
  adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);

