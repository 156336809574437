import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import { SilAPIResponse } from "../../../shared/publicInterfaces";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import { callRouteWithQueryString, userName } from "../../../shared/constExports";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { LAButton } from "../../../shared/buttons";
import { AddIcon } from "../../../shared/icons";
import { WHITE_COLOR } from "../../../shared/theme";
import { ROUTE } from "../../../routes";
import PageSpacing from "../../../shared/pageSpacing";
import SilRoleCheck from "../../../shared/silRoleCheck";
import LALoading from "../../../shared/loading";
import { IGetWarehouseRequest, IWarehouse } from "../../../../redux/sil/cv/warehouses/getWarehouses/getWarehousesConstaints";
import { getWarehouseStatus } from "../../../../redux/sil/cv/warehouses/getWarehouses/getWarehousesAccessor";
import { getWarehouseLoadAction } from "../../../../redux/sil/cv/warehouses/getWarehouses/getWarehousesActions";


interface IWarehousesStoreProps {
    getToken: Server<SilAPIResponse<IToken>>;
    getWarehouses: Server<SilAPIResponse<IWarehouse[]>>;
};

interface IWarehousesDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getWarehousesRequest: (data: IGetWarehouseRequest) => unknown;
};


interface IWarehousesOwnProps {

};

interface IWarehousesState {

};

const WarehousesStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
    };
`;

type IWarehousesProps = RouteComponentProps
    & IWarehousesStoreProps
    & IWarehousesDispatchProps
    & IWarehousesOwnProps;

class Warehouses extends PureComponent<IWarehousesProps, IWarehousesState> {

    public constructor(props: IWarehousesProps) {
        super(props);
        this.state = {
        };
    }

    public componentDidMount(): void {
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IWarehousesProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();
        };
    };


    public render(): ReactNode {

        const { getWarehouses } = this.props;

        return (
            <PageSpacing title="SIL - CV" description="SIL - Warehouses" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="carrierAccess">
                    <WarehousesStyles>

                        {getWarehouses.kind !== STATUS_ENUM.LOADING &&
                            <LAButton className="pull-left" startIcon={<AddIcon color={WHITE_COLOR} />} label="Add" onClick={this.handleAdd} />}
                        <h2 className="text-center">WAREHOUSE</h2>
                        <hr />

                        {getWarehouses.kind === STATUS_ENUM.LOADING &&
                            <LALoading className="loading" message="Loading..." />}

                        {getWarehouses.kind === STATUS_ENUM.SUCCEEDED && <LADevExtremeGrid
                            data={getWarehouses.payload.response}
                            onEdit={this.onEdit}
                            onClick={this.onClick}
                            height={1000}
                            searchPanel={true}
                            filterHeader={true}
                            export={true}
                            exportFileName="WarehousesList"
                            storageKey="silWarehousesStorageKey"
                            columns={[
                                { name: "scale", caption: "Scale", type: "string" },
                                { name: "model", caption: "Model", type: "string" },
                                { name: "month", caption: "Month", type: "number" },
                                { name: "year", caption: "Year", type: "number" },
                                { name: "five_Hundred_G_Reading", caption: "500g Reading", type: "number" },
                                { name: "one_KG_Reading", caption: "1kg Reading", type: "number" },
                                { name: "two_KG_Reading", caption: "2kg Reading", type: "number" },
                                { name: "employee", caption: "Employee", type: "string" },
                                { name: "modified", caption: "Modified", type: "datetime" },
                                { name: "modified_By", caption: "Modified By", type: "string" }
                            ]}
                        />}

                    </WarehousesStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString() },
            pathName: ROUTE.SIL.CV.WAREHOUSES.WAREHOUSE
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0" },
            pathName: ROUTE.SIL.CV.WAREHOUSES.WAREHOUSE
        });
    };

    private setDataToState = (): void => {
        if (isNotLoaded(this.props.getWarehouses))
            this.callServer();
    };

    private callServer = (): void => {
        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getWarehouses))
            this.props.getWarehousesRequest({
                token: this.props.getToken.payload.response.token
            });

        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });
    }

}

const mapStateToProps = (state: IStore): IWarehousesStoreProps => ({
    getToken: getTokenStatus(state),
    getWarehouses: getWarehouseStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IWarehousesDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getWarehousesRequest: (data: IGetWarehouseRequest) => dispatch(getWarehouseLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Warehouses);