import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IWashPlantProductionRecords } from "../getWashPlantProductionRecords/getWashPlantProductionRecordsConstaints";
import { IGetWashPlantProductionRecordByIdRequest, IGET_WASH_PLANT_PRODUCTION_RECORDS_REQUEST } from "./getWashPlantProductionRecordByIdConstaints";

export interface IGetWashPlantProductionRecordByIdLoadAction {
    type: IGET_WASH_PLANT_PRODUCTION_RECORDS_REQUEST.REQUEST;
    data: IGetWashPlantProductionRecordByIdRequest
}
export const getWashPlantProductionRecordByIdLoadAction = (data: IGetWashPlantProductionRecordByIdRequest): IGetWashPlantProductionRecordByIdLoadAction => ({
    type: IGET_WASH_PLANT_PRODUCTION_RECORDS_REQUEST.REQUEST,
    data
});

export interface IGetWashPlantProductionRecordByIdSuccessAction {
    type: IGET_WASH_PLANT_PRODUCTION_RECORDS_REQUEST.SUCCESS;
    data: SilAPIResponse<IWashPlantProductionRecords>;
}
export const getWashPlantProductionRecordByIdLoadSuccessAction = (data: SilAPIResponse<IWashPlantProductionRecords>): IGetWashPlantProductionRecordByIdSuccessAction => ({
    type: IGET_WASH_PLANT_PRODUCTION_RECORDS_REQUEST.SUCCESS,
    data
});

export interface IGetWashPlantProductionRecordByIdLoadFailedAction {
    type: IGET_WASH_PLANT_PRODUCTION_RECORDS_REQUEST.FAILED;
    message: string;
}
export const getWashPlantProductionRecordByIdLoadFailedAction = (message: string): IGetWashPlantProductionRecordByIdLoadFailedAction => ({
    type: IGET_WASH_PLANT_PRODUCTION_RECORDS_REQUEST.FAILED,
    message
});
