import { IAddTransloadBOLCountRequest, IADD_TRANSLOAD_BOL_COUNT_REQUEST } from "./addTransloadBOLCountConstaints";

export interface IAddTransloadBOLCountLoadAction {
    type: IADD_TRANSLOAD_BOL_COUNT_REQUEST.REQUEST;
    data: IAddTransloadBOLCountRequest
}
export const addTransloadBOLCountLoadAction = (data: IAddTransloadBOLCountRequest): IAddTransloadBOLCountLoadAction => ({
    type: IADD_TRANSLOAD_BOL_COUNT_REQUEST.REQUEST,
    data
});

export interface IAddTransloadBOLCountSuccessAction {
    type: IADD_TRANSLOAD_BOL_COUNT_REQUEST.SUCCESS;
    message: string;
}
export const addTransloadBOLCountLoadSuccessAction = (message: string): IAddTransloadBOLCountSuccessAction => ({
    type: IADD_TRANSLOAD_BOL_COUNT_REQUEST.SUCCESS,
    message
});

export interface IAddTransloadBOLCountLoadFailedAction {
    type: IADD_TRANSLOAD_BOL_COUNT_REQUEST.FAILED;
    message: string;
}
export const addTransloadBOLCountLoadFailedAction = (message: string): IAddTransloadBOLCountLoadFailedAction => ({
    type: IADD_TRANSLOAD_BOL_COUNT_REQUEST.FAILED,
    message
});
