import React from "react";
import LAGrid from "../../../../../shared/grid";
import LAGridItem from "../../../../../shared/gridList";
import { getDate } from "../../../../../shared/constExports";
import { LAPaperWithPadding } from "../../../../../shared/paper";
import { IJob } from "../../../../../../redux/propsense/catapult/crew_sheduling/getJobs/getJobsConstants";
import { ICrew } from "../../../../../../redux/propsense/catapult/crew_sheduling/getCrews/getCrewsConstants";
import { IAssignedCrew } from "../../../../../../redux/propsense/catapult/crew_sheduling/getAssignedCrews/getAssignedCrewsConstants";
import { RequiredCrewRender } from "./shiftList";



interface IJobDetailsOwnProps {
    job: IJob | undefined;
    crewList: ICrew[];
    assignedCrew: IAssignedCrew[];
    onTransport: (value: string, ac: IAssignedCrew) => void;
    onDragEnd: (result: any) => void;
    handleDelete: (cIndex: IAssignedCrew) => void;
    onDateChange: (sDate: Date, eDate: Date, ac: IAssignedCrew) => void;
};



const JobDetails: React.FC<IJobDetailsOwnProps> = React.memo((props: IJobDetailsOwnProps) =>
    <div>
        {props.job && <LAGrid spacing={2}>

            <LAGridItem xs={12}>
                <LAPaperWithPadding>
                    <LAGrid className="text-center">

                        <LAGridItem md={12} lg={6}>
                            <strong>Project Date: </strong>
                            {getDate(false, props.job?.start_Date)}
                        </LAGridItem>

                        <LAGridItem md={12} lg={6}>
                            <strong>Project End: </strong>
                            {getDate(false, props.job?.end_Date)}
                        </LAGridItem>

                    </LAGrid>
                </LAPaperWithPadding>
            </LAGridItem>

            <LAGridItem xs={12} className="text-center">
                <strong>Required Crew</strong>
                <LAGrid className="pt-2" spacing={2}>

                    <LAGridItem xs={6}>
                        <RequiredCrewRender {...props} dayShift={true} job={props.job} crewList={props.crewList} assignedCrew={props.assignedCrew}
                            handleDelete={props.handleDelete} onDateChange={props.onDateChange} onTransport={props.onTransport}
                        />
                    </LAGridItem>

                    <LAGridItem xs={6}>
                        <RequiredCrewRender {...props} dayShift={false} job={props.job} crewList={props.crewList} assignedCrew={props.assignedCrew}
                            handleDelete={props.handleDelete} onDateChange={props.onDateChange} onTransport={props.onTransport}
                        />
                    </LAGridItem>

                </LAGrid>
            </LAGridItem>

        </LAGrid>}
    </div>
);

export default JobDetails;