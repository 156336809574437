import { YHDAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { IGetYHDCarrierLookupsRequest, IGET_YHD_CARRIER_LOOKUPS_REQUEST, IYHDCarrierLookup } from "./getCarrierLookupsConstaints";

export interface IGetYHDCarrierLookupsLoadAction {
    type: IGET_YHD_CARRIER_LOOKUPS_REQUEST.REQUEST;
    data: IGetYHDCarrierLookupsRequest
}
export const getYHDCarrierLookupsLoadAction = (data: IGetYHDCarrierLookupsRequest): IGetYHDCarrierLookupsLoadAction => ({
    type: IGET_YHD_CARRIER_LOOKUPS_REQUEST.REQUEST,
    data
});

export interface IGetYHDCarrierLookupsSuccessAction {
    type: IGET_YHD_CARRIER_LOOKUPS_REQUEST.SUCCESS;
    data: YHDAPIResponse<IYHDCarrierLookup[]>;
}
export const getYHDCarrierLookupsLoadSuccessAction = (data: YHDAPIResponse<IYHDCarrierLookup[]>): IGetYHDCarrierLookupsSuccessAction => ({
    type: IGET_YHD_CARRIER_LOOKUPS_REQUEST.SUCCESS,
    data
});

export interface IGetYHDCarrierLookupsLoadFailedAction {
    type: IGET_YHD_CARRIER_LOOKUPS_REQUEST.FAILED;
    message: string;
}
export const getYHDCarrierLookupsLoadFailedAction = (message: string): IGetYHDCarrierLookupsLoadFailedAction => ({
    type: IGET_YHD_CARRIER_LOOKUPS_REQUEST.FAILED,
    message
});
