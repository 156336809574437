import { IAddTruckScaleRequest, IADD_TRUCK_SCALE_REQUEST } from "./addTruckScaleConstaints";

export interface IAddTruckScaleLoadAction {
    type: IADD_TRUCK_SCALE_REQUEST.REQUEST;
    data: IAddTruckScaleRequest
}
export const addTruckScaleLoadAction = (data: IAddTruckScaleRequest): IAddTruckScaleLoadAction => ({
    type: IADD_TRUCK_SCALE_REQUEST.REQUEST,
    data
});

export interface IAddTruckScaleSuccessAction {
    type: IADD_TRUCK_SCALE_REQUEST.SUCCESS;
    message: string;
}
export const addTruckScaleLoadSuccessAction = (message: string): IAddTruckScaleSuccessAction => ({
    type: IADD_TRUCK_SCALE_REQUEST.SUCCESS,
    message
});
export interface IAddTruckScaleLoadFailedAction {
    type: IADD_TRUCK_SCALE_REQUEST.FAILED;
    message: string;
}
export const addTruckScaleLoadFailedAction = (message: string): IAddTruckScaleLoadFailedAction => ({
    type: IADD_TRUCK_SCALE_REQUEST.FAILED,
    message
});
