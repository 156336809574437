import { IUPDATE_SIMOS_CUSTOMER_REQUEST, IUpdateSimosCustomerRequest } from "./updateSimosCustomerConstaints";

export interface IUpdateSimosCustomerLoadAction {
    type: IUPDATE_SIMOS_CUSTOMER_REQUEST.REQUEST;
    data: IUpdateSimosCustomerRequest
}
export const updateSimosCustomerLoadAction = (data: IUpdateSimosCustomerRequest): IUpdateSimosCustomerLoadAction => ({
    type: IUPDATE_SIMOS_CUSTOMER_REQUEST.REQUEST,
    data
});

export interface IUpdateSimosCustomerSuccessAction {
    type: IUPDATE_SIMOS_CUSTOMER_REQUEST.SUCCESS;
    message: string;
}
export const updateSimosCustomerLoadSuccessAction = (message: string): IUpdateSimosCustomerSuccessAction => ({
    type: IUPDATE_SIMOS_CUSTOMER_REQUEST.SUCCESS,
    message
});

export interface IUpdateSimosCustomerLoadFailedAction {
    type: IUPDATE_SIMOS_CUSTOMER_REQUEST.FAILED;
    message: string;
}
export const updateSimosCustomerLoadFailedAction = (message: string): IUpdateSimosCustomerLoadFailedAction => ({
    type: IUPDATE_SIMOS_CUSTOMER_REQUEST.FAILED,
    message
});
