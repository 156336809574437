import {currentUser} from '../../../../../simosHome';

export default async function getSIMOSWHProductInfo(product_name: string, bag_size:string,location:string, code:string){
    var results:any[] = [];
    let requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            request:{
                Description:product_name,
                Size:bag_size,
                Location:location,
                Code:code}, 
        token: '19515195-d571-44a3-b0db-637f5bf24f54' })
    }
    await fetch('https://api2.propsense.com/api/getSIMOSWHProductInfo', requestOptions)
    .then(async response => {
        await response.json().then(res => {
            results.push(res);
    }
    )});
    return results;
}

// * Gets all SIMOS Warehouse Employees that are active
async function getAllActiveSIMOSWHEmployees(){
    var results:any[] = [];
    let requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
        token: '19515195-d571-44a3-b0db-637f5bf24f54' })
    }
    await fetch('https://api2.propsense.com/api/getAllSIMOSWHEmployees', requestOptions)
    .then(async response => {
        await response.json().then(res => {
            res.map((eachItem:any) => {
                if(eachItem.active === 'Yes'){
                    results.push(eachItem);
                }
            })
    }
    )});
    return results;
}

// * Gets all SIMOS Warehouse Employees
async function getAllSIMOSWHEmployees(){
    var results:any[] = [];
    let requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
        token: '19515195-d571-44a3-b0db-637f5bf24f54' })
    }
    await fetch('https://api2.propsense.com/api/getAllSIMOSWHEmployees', requestOptions)
    .then(async response => {
        await response.json().then(res => {
            res.map((eachItem:any) => {
                    results.push(eachItem);
            })
    }
    )});
    return results;
}

// * Update SIMOS Warehouse Employee
async function updateSIMOSWHEmployee(editID:number, name:string, active:string){
    var orderInsert = "Failed";
    let requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ request:{
            ID:editID,
            Name:name,
            Active:active,
            Modified_By: currentUser
        },
        token: '19515195-d571-44a3-b0db-637f5bf24f54' })
    }
    await fetch('https://api2.propsense.com/api/updateSIMOSWHEmployee', requestOptions)
    .then(async response => {
        await response.json().then(res => {
            orderInsert =res;
        })
    })
    return orderInsert;
}

// * Add SIMOS Warehouse Employee
function addSIMOSWHEmployee(name:string, active:string){
    var orderInsert = "Failed";
    let requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ request:{
            Name:name,
            Active:active,
            Modified_By: currentUser,
            Created_By: currentUser
        },
        token: '19515195-d571-44a3-b0db-637f5bf24f54' })
    }
    fetch('https://api2.propsense.com/api/addSIMOSWHEmployee', requestOptions)
    .then(response => {
        const data = response.headers.get('Content-Type')?.includes('application/json');
        // error response
        if(!response.ok) {
          const error = (data) || response.status;
          console.log("should not be here");
          return error;
      } 
      else{
          return true;
      }
    });
}

// * Get SIMOS Warehouse Production Info
async function getSIMOSWHProductionInfo(){
    let productInfo:any[]=[];
    let requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
        token: '19515195-d571-44a3-b0db-637f5bf24f54' })
    }
    await fetch("https://api2.propsense.com/api/GetSIMOSWHProduction", requestOptions)
    .then(async response => {
        await response.json().then(res => {
            productInfo.push(res);
        })
    });
    return productInfo;
}
// * Gets all SIMOS Warehouse Product Names
async function getAllSIMOSWHProductName(){
    var results:any[] = [];
    let requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
        token: '19515195-d571-44a3-b0db-637f5bf24f54' })
    }
    await fetch('https://api2.propsense.com/api/GetSIMOSProductName', requestOptions)
    .then(async response => {
        await response.json().then(res => {
            res.map((eachItem:any) => {

                    results.push(eachItem);
            })
    }
    )});
    return results;
}

//* Returns all info for warehouse products (code/product name/size/per)
async function getSIMOSWHProductInfoList(){
    let all_products:any = [];
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type':'application/json' }, 
        body: JSON.stringify({token: '19515195-d571-44a3-b0db-637f5bf24f54' })
    }
    await fetch('https://api2.propsense.com/api/GetAllSIMOSWHProductInfo', requestOptions)
    .then(async response => {
        await response.json().then(res => {
            res.map((eachItem:any) => {
                // Some values are null so replace with "N/A"
                if(eachItem.description===null){
                    eachItem.description ='N/A';
                }
                if(eachItem.size === null){
                    eachItem.size = "N/A";
                }
              
                // Format created timestamp
                eachItem.created = eachItem.created.slice(0,10) +' '+eachItem.created.slice(11,16);
                eachItem.modified = eachItem.modified.slice(0,10) +' '+eachItem.modified.slice(11,16);
                all_products.push(eachItem);
        })
    })
    });
    return all_products;
      // sort the array by newest date first
    //   all_inventory = all_inventory.sort((a:any,b:any) => {
    //     return new Date(b.modified).getTime() - new Date(a.modified).getTime()
    //     });
      //oldProducts=all_products;
}

export {getAllActiveSIMOSWHEmployees, getAllSIMOSWHEmployees, updateSIMOSWHEmployee, addSIMOSWHEmployee, getSIMOSWHProductionInfo, getAllSIMOSWHProductName, getSIMOSWHProductInfoList};