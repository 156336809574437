import { ById, SimosAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { IGET_SIMOS_PRODUCTS_REQUEST, ISimosProducts, ISimosProductsRequest } from "./getSimosProductsConstants";


export interface IGetSimosProductsLoadAction {
    type: IGET_SIMOS_PRODUCTS_REQUEST.REQUEST;
    data: ISimosProductsRequest
}
export const getSimosProductsLoadAction = (data: ISimosProductsRequest): IGetSimosProductsLoadAction => ({
    type: IGET_SIMOS_PRODUCTS_REQUEST.REQUEST,
    data
});
export interface IGetSimosProductsSuccessAction {
    type: IGET_SIMOS_PRODUCTS_REQUEST.SUCCESS;
    data: SimosAPIResponse<ISimosProducts[]>;
}
export const getSimosProductsLoadSuccessAction = (data: SimosAPIResponse<ISimosProducts[]>): IGetSimosProductsSuccessAction => ({
    type: IGET_SIMOS_PRODUCTS_REQUEST.SUCCESS,
    data
});
export interface IGetSimosProductsLoadFailedAction {
    type: IGET_SIMOS_PRODUCTS_REQUEST.FAILED;
    message: string;
}
export const getSimosProductsLoadFailedAction = (message: string): IGetSimosProductsLoadFailedAction => ({
    type: IGET_SIMOS_PRODUCTS_REQUEST.FAILED,
    message
});
