import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import { SilAPIResponse } from "../../../shared/publicInterfaces";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import { callRouteWithQueryString, userName } from "../../../shared/constExports";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { LAButton } from "../../../shared/buttons";
import { AddIcon } from "../../../shared/icons";
import { WHITE_COLOR } from "../../../shared/theme";
import { ROUTE } from "../../../routes";
import PageSpacing from "../../../shared/pageSpacing";
import SilRoleCheck from "../../../shared/silRoleCheck";
import { IBeltScale, IGetBeltScaleRequest } from "../../../../redux/sil/cv/beltScales/getBeltScales/getBeltScalesConstaints";
import LALoading from "../../../shared/loading";
import { getBeltScaleLoadAction } from "../../../../redux/sil/cv/beltScales/getBeltScales/getBeltScalesActions";
import { getBeltScaleStatus } from "../../../../redux/sil/cv/beltScales/getBeltScales/getBeltScalesAccessor";


interface IBeltScalesStoreProps {
    getToken: Server<SilAPIResponse<IToken>>;
    getBeltScales: Server<SilAPIResponse<IBeltScale[]>>;
};

interface IBeltScalesDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getBeltScalesRequest: (data: IGetBeltScaleRequest) => unknown;
};


interface IBeltScalesOwnProps {

};

interface IBeltScalesState {

};

const BeltScalesStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
    };
`;

type IBeltScalesProps = RouteComponentProps
    & IBeltScalesStoreProps
    & IBeltScalesDispatchProps
    & IBeltScalesOwnProps;

class BeltScales extends PureComponent<IBeltScalesProps, IBeltScalesState> {

    public constructor(props: IBeltScalesProps) {
        super(props);
        this.state = {
        };
    }

    public componentDidMount(): void {
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IBeltScalesProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();
        };
    };


    public render(): ReactNode {

        const { getBeltScales } = this.props;

        return (
            <PageSpacing title="SIL - CV" description="SIL - Belt Scales" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="carrierAccess">
                    <BeltScalesStyles>

                        {getBeltScales.kind !== STATUS_ENUM.LOADING &&
                            <LAButton className="pull-left" startIcon={<AddIcon color={WHITE_COLOR} />} label="Add" onClick={this.handleAdd} />}
                        <h2 className="text-center">BELT SCALE</h2>
                        <hr />

                        {getBeltScales.kind === STATUS_ENUM.LOADING &&
                            <LALoading className="loading" message="Loading..." />}

                        {getBeltScales.kind === STATUS_ENUM.SUCCEEDED && <LADevExtremeGrid
                            data={getBeltScales.payload.response}
                            onEdit={this.onEdit}
                            onClick={this.onClick}
                            height={1000}
                            searchPanel={true}
                            filterHeader={true}
                            export={true}
                            exportFileName="BeltScalesList"
                            storageKey="silBeltScalesStorageKey"
                            columns={[
                                { name: "perc_Diff", caption: "Percentage Difference", type: "number" },
                                { name: "quarter", caption: "Quarter", type: "number" },
                                { name: "employee", caption: "Employee", type: "string" },
                                { name: "location", caption: "Location", type: "string" },
                                { name: "calibration_Form", caption: "Calibration Form", type: "string" },
                                { name: "year", caption: "Year", type: "number" },
                                { name: "created", caption: "Date", type: "date", sortDesc: true },
                                { name: "bucket_Weight", caption: "Bucket Weight", type: "number" },
                                { name: "scale_Weight", caption: "Scale Weight", type: "number" },
                                { name: "int_Or_Ext", caption: "Internal or External", type: "string" },
                                { name: "good_Or_Bad", caption: "Good or Bad", type: "string" },
                                { name: "completed", caption: "Completed", type: "string" },
                                { name: "modified", caption: "Modified", type: "datetime" },
                                { name: "modified_By", caption: "Modified By", type: "string" }
                            ]}
                        />}

                    </BeltScalesStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString() },
            pathName: ROUTE.SIL.CV.BELT_SCALES.BELT_SCALE
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0" },
            pathName: ROUTE.SIL.CV.BELT_SCALES.BELT_SCALE
        });
    };

    private setDataToState = (): void => {
        if (isNotLoaded(this.props.getBeltScales))
            this.callServer();
    };

    private callServer = (): void => {
        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getBeltScales))
            this.props.getBeltScalesRequest({
                token: this.props.getToken.payload.response.token
            });

        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });
    }

}

const mapStateToProps = (state: IStore): IBeltScalesStoreProps => ({
    getToken: getTokenStatus(state),
    getBeltScales: getBeltScaleStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IBeltScalesDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getBeltScalesRequest: (data: IGetBeltScaleRequest) => dispatch(getBeltScaleLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(BeltScales);