import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import { IDispatch, IStore } from "../../../../../redux/reducers";
import { LAPaperWithPadding } from "../../../../shared/paper";
import { DragDropContext } from 'react-beautiful-dnd';
import LAGridItem from "../../../../shared/gridList";
import LAGrid from "../../../../shared/grid";
import styled from "styled-components";
import { ByIdNumber, PropsenseAPIResponse } from "../../../../shared/publicInterfaces";
import { ICrew } from "../../../../../redux/propsense/catapult/crew_sheduling/getCrews/getCrewsConstants";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../../redux/server";
import { getCrews } from "../../../../../redux/propsense/catapult/crew_sheduling/getCrews/getCrewsAccessor";
import { getCrewsLoadAction } from "../../../../../redux/propsense/catapult/crew_sheduling/getCrews/getCrewsActions";
import CrewListDD from "./subComponents/crewListDD";
import { IGetRequestBody, IJob } from "../../../../../redux/propsense/catapult/crew_sheduling/getJobs/getJobsConstants";
import { getJobs } from "../../../../../redux/propsense/catapult/crew_sheduling/getJobs/getJobsAccessor";
import { getJobsLoadAction } from "../../../../../redux/propsense/catapult/crew_sheduling/getJobs/getJobsActions";
import JobDetails from "./subComponents/jobDetails";
import { IAssignedCrew } from "../../../../../redux/propsense/catapult/crew_sheduling/getAssignedCrews/getAssignedCrewsConstants";
import { getAssignedCrewsData } from "../../../../../redux/propsense/catapult/crew_sheduling/getAssignedCrews/getAssignedCrewsAccessor";
import { getAssignedCrewsLoadAction } from "../../../../../redux/propsense/catapult/crew_sheduling/getAssignedCrews/getAssignedCrewsActions";
import { ISaveAssignedCrews, ISaveAssignedCrewsRequest } from "../../../../../redux/propsense/catapult/crew_sheduling/saveAssignedCrews/saveAssignedCrewsConstaints";
import { getSaveAssignedCrewsStatus } from "../../../../../redux/propsense/catapult/crew_sheduling/saveAssignedCrews/saveAssignedCrewsAccessor";
import { saveAssignedCrewsLoadAction } from "../../../../../redux/propsense/catapult/crew_sheduling/saveAssignedCrews/saveAssignedCrewsActions";
import { LAIconButton, LASaveAndCancelButton } from "../../../../shared/buttons";
import RequestStatus from "../../../../shared/requestStatusSnackbar";
import { CrossIcon, SaveIcon, WarningIcon } from "../../../../shared/icons";
import jwt_decode from "jwt-decode";
import { sortRequiredCrewByDate } from "./subComponents/constFunctionsForCrew";
import { getDate, StaticToken, undefinedFunction } from "../../../../shared/constExports";
import SearchBox from "../../../../shared/searchBox";
import { BLUE_COLOR, MEDIA_QUERY_PHONE, ORANGE_COLOR, PURPLE_COLOR, RED_COLOR, WARNING_COLOR, WHITE_COLOR } from "../../../../shared/theme";
import LAAutoComplete from "../../../../shared/autoComplete";
import Link from "@material-ui/core/Link/Link";
import queryString from "query-string";
import { ROUTE } from "../../../../routes";
import { LAPopover } from "../../../../shared/popOver";


const getToken = localStorage.getItem("adal.idtoken");
const decoded: any = getToken ? jwt_decode(getToken) : "";

interface ICrewSchedulingStoreProps {
    GetCrewList: Server<PropsenseAPIResponse<ICrew[]>>;
    GetJobList: Server<PropsenseAPIResponse<IJob[]>>;
    GetAssignedCrews: Server<PropsenseAPIResponse<IAssignedCrew[]>>;
    SaveAssignedCrewsStatus: Server<string>;
};

interface ICrewSchedulingDispatchProps {
    RequestCrewList: (data: IGetRequestBody) => unknown;
    RequestJobList: (data: IGetRequestBody) => unknown;
    RequestAssignedCrew: (data: IGetRequestBody) => unknown;
    RequestSaveAssignedCrews: (data: ISaveAssignedCrewsRequest) => unknown;
};

interface ICrewSchedulingOwnProps {

};

interface ICrewSchedulingState {
    jobList: IJob[];
    crewList: ICrew[];
    crewSearch: string;
    currentJob: IJob | undefined;
    saveChanges: true | undefined;
    checkBoxVal: ByIdNumber<boolean>;
    assignedCrewList: IAssignedCrew[];
    showError: boolean;
};

const CrewSchedulingStyles = styled.div`
    margin: 40px 40px;
    word-break: break-word;

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };

    .red-dot {
        color: ${RED_COLOR};
    }
      
    .blue-dot {
        color: ${BLUE_COLOR};
    }
      
    .orange-dot {
        color: ${ORANGE_COLOR};
    }
      
    .purple-dot {
        color: ${PURPLE_COLOR};
    }

    .warColor {
        color: ${WARNING_COLOR}
    };

    .redColor {
        color: ${RED_COLOR}
    };

    .error {
        color: ${RED_COLOR}
    };

    .linkBtn {
        position: absolute;
        top: 3;
        right: 10px; 
    };

    .calendar-btn {
        position: fixed;
        bottom: 0;
        left: 5px; 
        z-index: 1100;
    };

    .warnIcon {
        position: absolute;
        top: 3px;
        left: 10px; 
    };

    .borderBox {
        border: 2px solid ${RED_COLOR};
        border-radius: 5px;
    };
`;

type ICrewSchedulingProps = RouteComponentProps
    & ICrewSchedulingStoreProps
    & ICrewSchedulingDispatchProps
    & ICrewSchedulingOwnProps;

class CrewScheduling extends PureComponent<ICrewSchedulingProps, ICrewSchedulingState> {

    public constructor(props: ICrewSchedulingProps) {
        super(props);
        this.state = {
            jobList: [],
            crewList: [],
            crewSearch: "",
            assignedCrewList: [],
            currentJob: undefined,
            saveChanges: true,
            checkBoxVal: {
                0: false,
                1: false,
                2: false,
                3: false
            },
            showError: false,
        };
    }

    public async componentDidMount(): Promise<void> {
        await this.getAndSetData();
        await this.initialErrorChecker();
    };

    public async componentDidUpdate(prevProps: ICrewSchedulingProps, prevState: ICrewSchedulingState): Promise<void> {
        if (this.props !== prevProps) {
            await this.getAndSetData();
            await this.initialErrorChecker();
        };
    };

    private onDragStart = () => {
        // Vibrate on drag (only Mobile)
        if (window.navigator.vibrate) {
            window.navigator.vibrate(200);
        };
    };

    public render(): ReactNode {

        const { crewList, jobList, currentJob, assignedCrewList, crewSearch, checkBoxVal, saveChanges, showError } = this.state;
        const list = this.getErrorDD();
        return (

            <CrewSchedulingStyles>
                <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
                    <LAGrid spacing={2} direction="row" alignItems="baseline">
                        {/* 
                        <LAGreenButton
                            className="calendar-btn"
                            label="Calendar"
                            onClick={this.handleDrawer}
                        /> */}

                        <LAGridItem xs={6}>

                            <LAPaperWithPadding className="text-center">
                                <LAAutoComplete
                                    className="p-2"
                                    dropDownPlaceHolder="Available Jobs"
                                    multiple={false}
                                    disabled={saveChanges ? undefined : true}
                                    getOptionLabel="po"
                                    getOptionLabel2="location"
                                    filterSelectedOptions={true}
                                    autoHighlight={true}
                                    onChange={this.handleJobClick}
                                    selectionRemove={() => undefined}
                                    option={list.sort(this.sortByDate)}
                                    value={currentJob ? jobList.filter((q) => q === currentJob)[0] : ""}
                                    defaultValue={currentJob ? jobList.filter((q) => q === currentJob)[0] : ""}
                                />
                            </LAPaperWithPadding>

                            {currentJob && <LAPaperWithPadding className="p-3">
                                <LAGrid>
                                    <LAGridItem xs={12} className="text-center">
                                        <strong>Job Details</strong>
                                        {this.RenderLinkBtn()}
                                        <div className="warnIcon">
                                            <LAIconButton icon={<WarningIcon color={WARNING_COLOR} />} label="Errors" onClick={this.handleError} />
                                        </div>
                                    </LAGridItem>

                                    <LAGridItem xs={12}>
                                        <JobDetails
                                            {...this.state}
                                            onDragEnd={this.onDragEnd}
                                            assignedCrew={assignedCrewList}
                                            onDateChange={this.handleDateChange}
                                            handleDelete={this.handleRemoveCrew}
                                            job={currentJob ? jobList.filter((q) => q === currentJob)[0] : undefined}
                                            crewList={hasPayload(this.props.GetCrewList) ? this.props.GetCrewList.payload.response : []}
                                            onTransport={this.handleTransport}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12}>
                                        <LASaveAndCancelButton
                                            saveButtonText="Save Changes"
                                            cancelButtonText="Reset/Cancel"
                                            onCancel={this.handleCancel}
                                            fullWidth={true}
                                            disableSave={saveChanges}
                                            onSave={this.handleSave}
                                            saveIcon={<SaveIcon color={WHITE_COLOR} />}
                                        />
                                    </LAGridItem>
                                </LAGrid>
                            </LAPaperWithPadding>}
                        </LAGridItem>

                        <LAGridItem xs={6}>
                            <SearchBox
                                className="pl-2 pr-2"
                                fullWidth={true}
                                text={crewSearch}
                                onSubmit={undefinedFunction}
                                onChange={this.handleCrewSearch}
                                placeHolder="Search crew by name"
                                searchStatus={STATUS_ENUM.SUCCEEDED}
                            />

                            <CrewListDD
                                jobs={jobList}
                                label="Crew List"
                                dropableId="crewList"
                                checkBoxVal={checkBoxVal}
                                assignedCrewList={assignedCrewList}
                                checkBoxClick={this.checkBoxClick}
                                crewList={crewList ? crewList.filter(x => x.active === "Yes") : []} disable={false}
                            />
                        </LAGridItem>
                    </LAGrid>
                </DragDropContext>

                <LAPopover open={showError} onClose={this.handleError} anchorRef={null}>
                    <LAPaperWithPadding>
                        <LAGrid className="text-center" spacing={2}>
                            <div className="linkBtn">
                                <LAIconButton icon={<CrossIcon color={RED_COLOR} />} label="Close" onClick={this.handleError} />
                            </div>

                            {jobList && jobList.filter((q) => q === currentJob)[0] && jobList.filter((q) => q === currentJob)[0].errors.map((q, index) => (
                                <LAGridItem key={index} xs={12}>
                                    {q}
                                </LAGridItem>
                            ))}
                        </LAGrid>
                    </LAPaperWithPadding>
                </LAPopover>

                {/* {drawer && <CrewCalendar {...this.props} open={drawer} onClose={this.handleDrawer} />} */}
                <RequestStatus
                    requestStatus={this.props.SaveAssignedCrewsStatus.kind}
                    successMessage="Crew Members have been updated successfully."
                    failedMessage="Crew Members update failed."
                />
            </CrewSchedulingStyles>
        );
    }

    private handleError = (): void => {
        this.setState({ showError: !this.state.showError });
    };

    private getErrorDD = (): IJob[] => {
        if (this.state.jobList && this.state.jobList.length > 0 && hasPayload(this.props.GetAssignedCrews)) {
            const data = this.state.jobList;

            data.forEach(async (x) => {
                if (x.errors.length > 0)
                    x.error = true;
            });
            return data;
        }
        return [];
    };

    private handleJobClick = async (e: unknown, currentJob: IJob): Promise<void> => {
        this.setState({ currentJob });
        window.history.replaceState(null, "Crew Scheduling", ROUTE.PROPSENSE.CATAPULT.CREW_SCHEDULING.INDEX(`jobId=${currentJob.order_ID}`));
    };

    // private handleDrawer = (): void => {
    //     this.setState({ drawer: !this.state.drawer });
    // };

    private RenderLinkBtn = (): JSX.Element => {
        return <Link
            className="linkBtn"
            component="button"
            variant="body2"
            color="secondary"
            rel="noopener"
            onClick={(e: any) => {
                window.open(`https://lms.propsense.com/Catapult_Orders/Edit/${this.state.currentJob?.order_ID}?tab=admin`, "_blank");
            }}
        >Open PS</Link>;
    };

    private handleCrewSearch = (value: string) => {
        if (hasPayload(this.props.GetCrewList)) {

            let crewData: ICrew[] = [];
            let checkBoxCheck = true;
            const initialCheck = this.state.checkBoxVal;

            if (initialCheck[0] === false && initialCheck[1] === false && initialCheck[2] === false && initialCheck[3] === false) {
                checkBoxCheck = false;
            }

            if (value.length > 0) {
                this.props.GetCrewList.payload.response.forEach((x) => {
                    if (x.name.toLocaleLowerCase().includes(value.toLowerCase())) {

                        if (x.job_Level === "Super" && ((initialCheck[0] === true) || checkBoxCheck === false)) {
                            crewData.push(x);
                        };

                        if (x.job_Level === "Operator_Level1" && ((initialCheck[1] === true) || checkBoxCheck === false)) {
                            crewData.push(x);
                        };

                        if (x.job_Level === "Operator_Level2" && ((initialCheck[2] === true) || checkBoxCheck === false)) {
                            crewData.push(x);
                        };

                        if (x.job_Level === "Operator_Level3" && ((initialCheck[3] === true) || checkBoxCheck === false)) {
                            crewData.push(x);
                        };
                    }
                });

                this.setState({ crewSearch: value, crewList: crewData });
            } else {
                this.checkBoxClick(0, this.state.checkBoxVal[0]);
            }
        }
    };

    private checkBoxClick = (index: number, value: boolean) => {
        let crewData: ICrew[] = [];
        const initialCheck = { ...this.state.checkBoxVal };

        initialCheck[index] = value;

        if (hasPayload(this.props.GetCrewList)) {
            const check = Object.values(initialCheck).some((x) => x === true);

            if (check === false) {
                crewData = this.props.GetCrewList.payload.response;
            } else {
                this.props.GetCrewList.payload.response.forEach((x) => {
                    if (x.job_Level === "Supervisor" && initialCheck[0] === true) {
                        crewData.push(x);
                    };

                    if (x.job_Level === "Operator_Level1" && initialCheck[1] === true) {
                        crewData.push(x);
                    };

                    if (x.job_Level === "Operator_Level2" && initialCheck[2] === true) {
                        crewData.push(x);
                    };

                    if (x.job_Level === "Operator_Level3" && initialCheck[3] === true) {
                        crewData.push(x);
                    };
                });
            }
        };

        this.setState({ checkBoxVal: initialCheck, crewList: crewData });
    };

    private handleCancel = (): void => {
        if (hasPayload(this.props.GetAssignedCrews)) {
            this.setState({ assignedCrewList: this.props.GetAssignedCrews.payload.response, currentJob: undefined, saveChanges: true });
        }
    };

    private handleSave = async (): Promise<void> => {
        const data = this.state;

        const val: ISaveAssignedCrews[] = [];
        if (data.currentJob !== undefined) {
            const job = data.currentJob;

            await data.assignedCrewList.filter((x) => x.order_ID === job.order_ID).forEach((x) => {
                val.push({
                    Crew_ID: x.crew_ID,
                    Order_ID: x.order_ID,
                    Start_Date: x.start_Date,
                    End_Date: x.end_Date,
                    Shift: x.shift,
                    Crew_Shift_ID: x.crew_Shift_ID,
                    Modified: new Date().toISOString(),
                    Modified_By: decoded.upn,
                    Created: new Date().toISOString(),
                    Created_By: decoded.upn
                });
            });

            // When all crew is removed then pass a single object in array with crew_Id = 0;
            if (val.length === 0) {
                val.push({
                    Crew_ID: 0,
                    Shift: "",
                    Start_Date: "",
                    End_Date: "",
                    Crew_Shift_ID: 0,
                    Order_ID: job.order_ID,
                    Modified: new Date().toISOString(),
                    Modified_By: decoded.upn,
                    Created: new Date().toISOString(),
                    Created_By: decoded.upn
                });
            };
            
            this.props.RequestSaveAssignedCrews({request: val, token: StaticToken});
            this.setState({ saveChanges: true, currentJob: data.currentJob });
            
        };
    };

    private initialErrorChecker = async (): Promise<void> => {
        if (this.state.assignedCrewList && this.state.assignedCrewList.length > 0) {
            const initialState = [...this.state.assignedCrewList];

            await initialState.forEach((crew, index) => {
                initialState.forEach((x) => {
                    if (x.crew_ID === crew.crew_ID && x.crew_Shift_ID !== crew.crew_Shift_ID) {
                        if (
                            (((x.start_Date >= crew.start_Date) && (x.end_Date <= crew.start_Date))
                                || ((x.start_Date <= crew.end_Date) && (x.end_Date >= crew.end_Date)))
                            ||
                            (((x.start_Date <= crew.start_Date) && (x.end_Date >= crew.start_Date))
                                || ((x.start_Date >= crew.end_Date) && (x.end_Date <= crew.end_Date)))
                        ) {
                            const crewJob = this.state.jobList.find(s => s.order_ID === crew.order_ID);
                            const finJob = this.state.jobList.find(s => s.order_ID === x.order_ID);
                            const finCrew = this.state.crewList.find(s => s.crew_ID === x.crew_ID);

                            if (finCrew) {
                                x.dateConflictError = `${finCrew.name} has been assigned to (${crewJob?.customer} - ${crewJob?.location}): Shift from ${getDate(false, crew.start_Date)} 
                            to ${getDate(false, crew.end_Date)}.`;

                                crew.dateConflictError = `${finCrew.name} has been assigned to (${finJob?.customer} - ${finJob?.location}): Shift from ${getDate(false, x.start_Date)} 
                            to ${getDate(false, x.end_Date)}.`;
                            }
                        }
                    };
                });
            });

            this.setState({ assignedCrewList: initialState });
        }
    };

    private sortByDate = (a: IJob, b: IJob): any => {
        return a.start_Date > b.start_Date;
    };

    private getAndSetData = (): void => {
        const value = queryString.parse(this.props.location.search);

        if (isNotLoaded(this.props.GetJobList)) {
            this.props.RequestJobList({token: StaticToken, request: null});
        };

        if (isNotLoaded(this.props.GetCrewList)) {
            this.props.RequestCrewList({token: StaticToken, request: null});
        };

        if (isNotLoaded(this.props.GetAssignedCrews)) {
            this.props.RequestAssignedCrew({token: StaticToken, request: null});
        };

        if (hasPayload(this.props.GetJobList)) {
            const data = this.props.GetJobList.payload.response;

            if (value.jobId) {
                const job = data.find((x) => x.order_ID === Number(value.jobId));
                if(job)
                this.setState({ jobList: data, currentJob: job });
            } else {
                this.setState({ jobList: data });
            }
        };

        if (hasPayload(this.props.GetCrewList)) {
            this.setState({ crewList: this.props.GetCrewList.payload.response });
        };

        if (hasPayload(this.props.GetAssignedCrews)) {
            this.setState({ assignedCrewList: this.props.GetAssignedCrews.payload.response });
        };

    };

    private onDragEnd = async (result: any): Promise<void> => {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        if (result.destination.droppableId !== "crewList") {

            const current = this.state;
            const initialState = [...this.state.assignedCrewList];

            if ((current.currentJob !== undefined)) {

                let check = false;
                const findOrder = current.currentJob;
                const crew = current.crewList.filter(a => a.active === "Yes")[source.index];
                const dest = findOrder.required_Crews.sort(sortRequiredCrewByDate)[Number(destination.droppableId)];

                // If their is no crew in the array 
                if (initialState.length === 0) {
                    initialState.push({
                        id: 0,
                        crew_Shift_ID: dest.crew_Shift_ID,
                        start_Date: dest.from_Date,
                        end_Date: dest.to_Date,
                        shift: dest.shift,
                        order_ID: findOrder.order_ID,
                        crew_ID: crew.crew_ID
                    });
                } else {
                    let error = "";

                    await initialState.forEach((x, index) => {
                        // If crew already exist
                        if ((x.crew_ID === crew.crew_ID) && x.crew_Shift_ID === dest.crew_Shift_ID) {
                            check = true;
                        };

                        //Check if this crew is working on other jobs at same time
                        if (x.crew_ID === crew.crew_ID) {
                            if (
                                (((x.start_Date >= dest.from_Date) && (x.end_Date <= dest.from_Date))
                                    || ((x.start_Date <= dest.to_Date) && (x.end_Date >= dest.to_Date)))
                                ||
                                (((x.start_Date <= dest.from_Date) && (x.end_Date >= dest.from_Date))
                                    || ((x.start_Date >= dest.to_Date) && (x.end_Date <= dest.to_Date)))
                            ) {
                                const finJob = current.jobList.find(s => s.order_ID === x.order_ID);

                                error = `${crew.name} has been assigned to (${finJob?.customer} - ${finJob?.location}): Shift from ${getDate(false, x.start_Date)} 
                                to ${getDate(false, x.end_Date)}.`;

                                x.dateConflictError = `${crew.name} has been assigned to (${findOrder?.customer} - ${findOrder?.location}): Shift from ${getDate(false, dest.from_Date)} 
                                to ${getDate(false, dest.to_Date)}.`;
                            }

                        };

                        //When it is last value in the loop and the crew not exist
                        if (((index + 1) === initialState.length) && (check === false)) {
                            initialState.push({
                                id: initialState.length + 1,
                                crew_Shift_ID: dest.crew_Shift_ID,
                                start_Date: dest.from_Date,
                                end_Date: dest.to_Date,
                                shift: dest.shift,
                                order_ID: findOrder.order_ID,
                                crew_ID: crew.crew_ID,
                                dateConflictError: error.length > 0 ? error : undefined
                            });
                        };
                    });

                };
                this.setState({ assignedCrewList: initialState, saveChanges: undefined });
            };
        }
    };

    private handleTransport = (value: string, ac: IAssignedCrew): void => {
        const initialState = [...this.state.assignedCrewList];

        initialState.forEach((x) => {
            if ((x.crew_ID === ac.crew_ID) && (x.crew_Shift_ID === ac.crew_Shift_ID)) {
                x.transport = value;
            }
        });
        this.setState({ assignedCrewList: initialState, saveChanges: undefined });
    };

    private handleDateChange = async (sDate: Date, eDate: Date, ac: IAssignedCrew): Promise<void> => {

        const current = this.state;
        const initialState = [...this.state.assignedCrewList];

        if (current.currentJob !== undefined) {
            let error = "";

            let p1 = await new Promise((resolve, reject) => {
                current.assignedCrewList.forEach((x, index) => {
                    //Check if this crew is working on other jobs at same time
                    if (x.crew_ID === ac.crew_ID) {

                        if (x.id !== ac.id) {
                            if (
                                (((new Date(x.start_Date) >= sDate) && (new Date(x.end_Date) <= sDate))
                                    || ((new Date(x.start_Date) <= eDate) && (new Date(x.end_Date) >= eDate)))
                                ||
                                (((new Date(x.start_Date) <= sDate) && (new Date(x.end_Date) >= sDate))
                                    || ((new Date(x.start_Date) >= eDate) && (new Date(x.end_Date) <= eDate)))
                            ) {
                                const finCrew = current.crewList.find((a) => a.crew_ID === x.crew_ID);
                                const finJob = current.jobList.find((a) => a.order_ID === x.order_ID);

                                if (finCrew) {
                                    error = `${finCrew.name} has been assigned to (${finJob?.customer} - ${finJob?.location}): Shift from ${getDate(false, x.start_Date)} 
                                    to ${getDate(false, x.end_Date)}.`;
                                }
                            } else {
                                x.dateConflictError = undefined;
                            }
                        };
                    };

                    if (index + 1 === initialState.length) {
                        return resolve(true);
                    }
                });
            });

            await Promise.all([p1]).then(() => {
                initialState.forEach((x) => {
                    if ((x.crew_ID === ac.crew_ID) && (x.crew_Shift_ID === ac.crew_Shift_ID)) {
                        x.dateConflictError = error.length > 0 ? error : undefined;
                        x.start_Date = sDate.toISOString();
                        x.end_Date = eDate.toISOString();
                    }
                });
            });


            // const holes: any[] = [];
            // // this code is not finish yet it's only for validation of dates gap
            // current.currentJob.Required_Crews.forEach((x, index) => {

            //         const dates: Date[] = getDatesBetweenDates(x.From_Date, x.To_Date);

            //         initialState.forEach((y, ind) => {
            //         dates.forEach((q) => {
            //                 if (y.Order_ID === current.currentJob?.Order_ID) {
            //                     const findCrew = current.crewList.find((q) => q.Crew_ID === y.Crew_ID);

            //                     if (findCrew && findCrew.Job_Level === "Supervisor") {

            //                         if (((q <= new Date(y.End_Date)) === false) && ((q >= new Date(y.Start_Date))) === false) {
            //                             const check = holes.find((a) => a.missed === q);

            //                             if(check === undefined){
            //                                 holes.push({ missed: q.toISOString(), jobLevel: findCrew.Job_Level });
            //                             } else {
            //                                 const findIn = holes.findIndex((a) => a.missed === q);
            //                                 holes.splice(findIn, 1);
            //                             }
            //                         }
            //                     }
            //                 }
            //             });
            //         });

            // });
            // holes.forEach((q) => {
            //     const val = initialState.some((y) => q <= new Date(y.End_Date) && q >= new Date(y.End_Date));
            //     console.log(val);

            // });

            this.setState({ assignedCrewList: initialState, saveChanges: undefined });
        }
    };

    private handleRemoveCrew = async (cIndex: IAssignedCrew): Promise<void> => {
        const initialState = [...this.state.assignedCrewList];

        initialState.forEach((x, index) => {
            if ((x.shift === cIndex.shift) && (x.crew_ID === cIndex.crew_ID) && (x.order_ID === cIndex.order_ID) && (x.crew_Shift_ID === cIndex.crew_Shift_ID)) {
                initialState.splice(index, 1);
            };
        });

        this.setState({ assignedCrewList: initialState, saveChanges: undefined });
    };

}

const mapStateToProps = (state: IStore): ICrewSchedulingStoreProps => ({
    GetCrewList: getCrews(state),
    GetJobList: getJobs(state),
    GetAssignedCrews: getAssignedCrewsData(state),
    SaveAssignedCrewsStatus: getSaveAssignedCrewsStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): ICrewSchedulingDispatchProps => ({
    RequestCrewList: (data: IGetRequestBody): unknown => dispatch(getCrewsLoadAction(data)),
    RequestJobList: (data: IGetRequestBody): unknown => dispatch(getJobsLoadAction(data)),
    RequestAssignedCrew: (data: IGetRequestBody): unknown => dispatch(getAssignedCrewsLoadAction(data)),
    RequestSaveAssignedCrews: (data: ISaveAssignedCrewsRequest): unknown => dispatch(saveAssignedCrewsLoadAction(data))
});


export default connect(mapStateToProps, mapDispatchToProps)(CrewScheduling);

