import { IProductionRecords } from "../getProductionRecords/getProductionRecordsConstaints";
import { IUpdateProductionRequest, IUPDATE_PRODUCTION_REQUEST } from "./updateProductionConstaints";

export interface IUpdateProductionLoadAction {
    type: IUPDATE_PRODUCTION_REQUEST.REQUEST;
    data: IUpdateProductionRequest
}
export const updateProductionLoadAction = (data: IUpdateProductionRequest): IUpdateProductionLoadAction => ({
    type: IUPDATE_PRODUCTION_REQUEST.REQUEST,
    data
});

export interface IUpdateProductionSuccessAction {
    type: IUPDATE_PRODUCTION_REQUEST.SUCCESS;
    data: IProductionRecords;
}
export const updateProductionLoadSuccessAction = (data: IProductionRecords): IUpdateProductionSuccessAction => ({
    type: IUPDATE_PRODUCTION_REQUEST.SUCCESS,
    data
});
export interface IUpdateProductionLoadFailedAction {
    type: IUPDATE_PRODUCTION_REQUEST.FAILED;
    message: string;
}
export const updateProductionLoadFailedAction = (message: string): IUpdateProductionLoadFailedAction => ({
    type: IUPDATE_PRODUCTION_REQUEST.FAILED,
    message
});
