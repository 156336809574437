import { YHDAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../../server";
import { IGET_YHD_CARRIER_EMAILS_REQUEST, IYHDCarrierEmail } from "./getCarrierEmailsConstaints";
import { IGetYHDCarrierEmailsLoadAction, IGetYHDCarrierEmailsLoadFailedAction, IGetYHDCarrierEmailsSuccessAction } from "./getCarrierEmailsActions";
import { IADD_YHD_CARRIER_EMAIL_REQUEST } from "../addCarrierEmail/addCarrierEmailConstaints";
import { IUPDATE_YHD_CARRIER_EMAIL_REQUEST } from "../updateCarrierEmail/updateCarrierEmailConstaints";
import { IAddYHDCarrierEmailSuccessAction } from "../addCarrierEmail/addCarrierEmailActions";
import { IUpdateYHDCarrierEmailSuccessAction } from "../updateCarrierEmail/updateCarrierEmailActions";

type Actions =
    IGetYHDCarrierEmailsLoadAction
    | IGetYHDCarrierEmailsLoadFailedAction
    | IGetYHDCarrierEmailsSuccessAction
    | IAddYHDCarrierEmailSuccessAction
    | IUpdateYHDCarrierEmailSuccessAction
    | IFlushDataSuccessAction;

export const GetYHDCarrierEmailsReducer = (state: Server<YHDAPIResponse<IYHDCarrierEmail[]>> = notLoaded, action: Actions): Server<YHDAPIResponse<IYHDCarrierEmail[]>> => {
    switch (action.type) {
        case IGET_YHD_CARRIER_EMAILS_REQUEST.REQUEST:
            return loading;

        case IGET_YHD_CARRIER_EMAILS_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_YHD_CARRIER_EMAILS_REQUEST.FAILED:
            return failed(action.message);

        case IADD_YHD_CARRIER_EMAIL_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_YHD_CARRIER_EMAIL_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};