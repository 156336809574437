import React from "react";
import { IProductionData } from "../../../../redux/sil/dryPlant/getProductionRecords/getProductionRecordsConstaints";
import LAAutoComplete from "../../../shared/autoComplete";
import { LASaveAndCancelButton } from "../../../shared/buttons";
import { undefinedFunction } from "../../../shared/constExports";
import { IFieldErrorKeyValue } from "../../../shared/fieldValidator";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import { LAPaperWithLessPadding } from "../../../shared/paper";
import { LAPopover } from "../../../shared/popOver";
import { ById, HasClass } from "../../../shared/publicInterfaces";
import LATextField from "../../../shared/textField";
import { IConvertList } from "./dryPlantItemForm";

interface ILADeleteConfirmProps extends HasClass {
    open: boolean;
    onSave: () => void;
    onClose: () => void;
    data: IProductionData;
    plants: IConvertList[];
    byProducts: IConvertList[];
    bottlenecks: IConvertList[];
    runMaterials: IConvertList[];
    feedMaterials: IConvertList[];
    errors: ById<IFieldErrorKeyValue>;
    onChange: (name: string, value: string | number) => void;
}

export const DryPlantItemProdPopup: React.FC<ILADeleteConfirmProps> = React.memo((props: ILADeleteConfirmProps) => {
    const hours = hourOptions();
    const onTimeChange = (event: unknown, value: any, name?: string): void => props.onChange(name ?? "", value !== "" ? value.id : 0);
    const onDDChange = (event: unknown, value: any, name?: string): void => props.onChange(name ?? "", value !== "" ? value.name : "");
    const onNumberField = (name: string, value: string): void => props.onChange(name, !isNaN(Number(value)) ? Number(value) : "");

    return <LAPopover open={props.open} anchorRef={null} onClose={props.onClose}>
        <LAPaperWithLessPadding>
            <LAGrid>

                <LAGridItem xs={12} className="text-center">
                    <h3>{props.data.id > 0 ? "UPDATE PRODUCTION ITEM" : "ADD PRODUCTION ITEM"}</h3>
                </LAGridItem>

                <LAGridItem sm={12} md={3}>
                    <LAAutoComplete
                        multiple={false}
                        option={props.plants}
                        autoHighlight={true}
                        getOptionLabel="name"
                        name="plant"
                        onChange={onDDChange}
                        filterSelectedOptions={true}
                        dropDownPlaceHolder="Plant"
                        selectionRemove={undefinedFunction}
                        value={props.plants.find(q => q.name === props.data.plant)}
                        defaultValue={props.plants.find(q => q.name === props.data.plant)}
                        errorText={props.errors["plant"] ? props.errors["plant"].message : undefined}
                    />
                </LAGridItem>

                <LAGridItem sm={12} md={3}>
                    <LAAutoComplete
                        multiple={false}
                        option={props.feedMaterials}
                        autoHighlight={true}
                        getOptionLabel="name"
                        name="feed_1_Material"
                        onChange={onDDChange}
                        filterSelectedOptions={true}
                        dropDownPlaceHolder="Feed 1 Material"
                        selectionRemove={undefinedFunction}
                        value={props.feedMaterials.find(q => q.name === props.data.feed_1_Material)}
                        defaultValue={props.feedMaterials.find(q => q.name === props.data.feed_1_Material)}
                        errorText={props.errors["feed_1_Material"] ? props.errors["feed_1_Material"].message : undefined}
                    />
                </LAGridItem>

                <LAGridItem sm={12} md={3}>
                    <LATextField
                        fullWidth={true}
                        name="feed_1_Perc"
                        label="Feed 1 %"
                        varient="outlined"
                        onChange={onNumberField}
                        value={props.data.feed_1_Perc}
                        errorText={props.errors["feed_1_Perc"] ? props.errors["feed_1_Perc"].message : undefined}
                    />
                </LAGridItem>

                <LAGridItem sm={12} md={3}>
                    <LAAutoComplete
                        multiple={false}
                        option={props.feedMaterials}
                        autoHighlight={true}
                        getOptionLabel="name"
                        name="feed_2_Material"
                        onChange={onDDChange}
                        filterSelectedOptions={true}
                        dropDownPlaceHolder="Feed 2 Material"
                        selectionRemove={undefinedFunction}
                        value={props.feedMaterials.find(q => q.name === props.data.feed_2_Material)}
                        defaultValue={props.feedMaterials.find(q => q.name === props.data.feed_2_Material)}
                        errorText={props.errors["feed_2_Material"] ? props.errors["feed_2_Material"].message : undefined}
                    />
                </LAGridItem>

                <LAGridItem sm={12} md={3}>
                    <LATextField
                        fullWidth={true}
                        name="feed_2_Perc"
                        label="Feed 2 %"
                        varient="outlined"
                        onChange={onNumberField}
                        value={props.data.feed_2_Perc ?? ""}
                        errorText={props.errors["feed_2_Perc"] ? props.errors["feed_2_Perc"].message : undefined}
                    />
                </LAGridItem>

                <LAGridItem sm={12} md={3}>
                    <LAAutoComplete
                        multiple={false}
                        option={props.feedMaterials}
                        autoHighlight={true}
                        getOptionLabel="name"
                        name="feed_3_Material"
                        onChange={onDDChange}
                        filterSelectedOptions={true}
                        dropDownPlaceHolder="Feed 3 Material"
                        selectionRemove={undefinedFunction}
                        value={props.feedMaterials.find(q => q.name === props.data.feed_3_Material)}
                        defaultValue={props.feedMaterials.find(q => q.name === props.data.feed_3_Material)}
                        errorText={props.errors["feed_3_Material"] ? props.errors["feed_3_Material"].message : undefined}
                    />
                </LAGridItem>

                <LAGridItem sm={12} md={3}>
                    <LATextField
                        fullWidth={true}
                        name="feed_3_Perc"
                        label="Feed 3 %"
                        varient="outlined"
                        onChange={onNumberField}
                        value={props.data.feed_3_Perc ?? ""}
                        errorText={props.errors["feed_3_Perc"] ? props.errors["feed_3_Perc"].message : undefined}
                    />
                </LAGridItem>

                <LAGridItem sm={12} md={3}>
                    <LAAutoComplete
                        multiple={false}
                        option={props.feedMaterials}
                        autoHighlight={true}
                        getOptionLabel="name"
                        name="feed_4_Material"
                        onChange={onDDChange}
                        filterSelectedOptions={true}
                        dropDownPlaceHolder="Feed 4 Material"
                        selectionRemove={undefinedFunction}
                        value={props.feedMaterials.find(q => q.name === props.data.feed_4_Material)}
                        defaultValue={props.feedMaterials.find(q => q.name === props.data.feed_4_Material)}
                        errorText={props.errors["feed_4_Material"] ? props.errors["feed_4_Material"].message : undefined}
                    />
                </LAGridItem>

                <LAGridItem sm={12} md={3}>
                    <LATextField
                        fullWidth={true}
                        name="feed_4_Perc"
                        label="Feed 4 %"
                        varient="outlined"
                        onChange={onNumberField}
                        value={props.data.feed_4_Perc ?? ""}
                        errorText={props.errors["feed_4_Perc"] ? props.errors["feed_4_Perc"].message : undefined}
                    />
                </LAGridItem>

                <LAGridItem sm={12} md={3}>
                    <LATextField
                        fullWidth={true}
                        name="feed_Tonnage"
                        label="Feed Tonnage"
                        varient="outlined"
                        onChange={onNumberField}
                        value={props.data.feed_Tonnage ?? ""}
                        errorText={props.errors["feed_Tonnage"] ? props.errors["feed_Tonnage"].message : undefined}
                    />
                </LAGridItem>

                {/* <LAGridItem sm={12} md={3}>
                    <LAAutoComplete
                        multiple={false}
                        option={props.runMaterials}
                        autoHighlight={true}
                        getOptionLabel="name"
                        name="run_Material"
                        onChange={onDDChange}
                        filterSelectedOptions={true}
                        dropDownPlaceHolder="Run Material"
                        selectionRemove={undefinedFunction}
                        value={props.runMaterials.find(q => q.name === props.data.run_Material)}
                        defaultValue={props.runMaterials.find(q => q.name === props.data.run_Material)}
                        errorText={props.errors["run_Material"] ? props.errors["run_Material"].message : undefined}
                    />
                </LAGridItem> */}

                {/* <LAGridItem sm={12} md={3}>
                    <LATextField
                        fullWidth={true}
                        name="run_Material_MT"
                        label="Run Material MT"
                        varient="outlined"
                        onChange={props.onChange}
                        value={props.data.run_Material_MT}
                        errorText={props.errors["run_Material_MT"] ? props.errors["run_Material_MT"].message : undefined}
                    />
                </LAGridItem> */}



                <LAGridItem sm={12} md={12}>
                </LAGridItem>

                <LAGridItem sm={12} md={3}>
                    <LATextField
                        fullWidth={true}
                        name="production_Top"
                        label="Production Top"
                        varient="outlined"
                        onChange={props.onChange}
                        value={props.data.production_Top}
                    />
                </LAGridItem>

                <LAGridItem sm={12} md={3}>
                    <LATextField
                        fullWidth={true}
                        name="production_Mid"
                        label="Production Mid"
                        varient="outlined"
                        onChange={props.onChange}
                        value={props.data.production_Mid}
                        disabled={(props.data.plant && (props.data.plant === "D201" || props.data.plant === "D202") ? true : undefined)}
                    />
                </LAGridItem>

                <LAGridItem sm={12} md={3}>
                    <LATextField
                        fullWidth={true}
                        name="production_Bottom"
                        label="Production Bottom"
                        varient="outlined"
                        onChange={props.onChange}
                        value={props.data.production_Bottom}
                    />
                </LAGridItem>

                <LAGridItem sm={12} md={3}>
                </LAGridItem>

                <LAGridItem sm={12} md={3}>
                    <LAAutoComplete
                        multiple={false}
                        option={hours}
                        autoHighlight={true}
                        getOptionLabel="name"
                        name="start_Time"
                        onChange={onTimeChange}
                        filterSelectedOptions={true}
                        dropDownPlaceHolder="Start Time"
                        selectionRemove={undefinedFunction}
                        value={hours.find(q => q.id === props.data.start_Time)}
                        defaultValue={hours.find(q => q.id === props.data.start_Time)}
                        errorText={props.errors["start_Time"] ? props.errors["start_Time"].message : undefined}
                    />
                </LAGridItem>

                <LAGridItem sm={12} md={2}>
                    <LAAutoComplete
                        multiple={false}
                        option={minOptions}
                        autoHighlight={true}
                        getOptionLabel="name"
                        name="start_Time_Mins"
                        onChange={onTimeChange}
                        filterSelectedOptions={true}
                        dropDownPlaceHolder="Start Time Mins"
                        selectionRemove={undefinedFunction}
                        value={minOptions.find(q => q.id === props.data.start_Time_Mins)}
                        defaultValue={minOptions.find(q => q.id === props.data.start_Time_Mins)}
                        errorText={props.errors["start_Time_Mins"] ? props.errors["start_Time_Mins"].message : undefined}
                    />
                </LAGridItem>

                <LAGridItem sm={12} md={3}>
                    <LAAutoComplete
                        multiple={false}
                        option={hours}
                        autoHighlight={true}
                        getOptionLabel="name"
                        name="stop_Time"
                        onChange={onTimeChange}
                        filterSelectedOptions={true}
                        dropDownPlaceHolder="Stop Time"
                        selectionRemove={undefinedFunction}
                        value={hours.find(q => q.id === props.data.stop_Time)}
                        defaultValue={hours.find(q => q.id === props.data.stop_Time)}
                        errorText={props.errors["stop_Time"] ? props.errors["stop_Time"].message : undefined}
                    />
                </LAGridItem>

                <LAGridItem sm={12} md={2}>
                    <LAAutoComplete
                        multiple={false}
                        option={minOptions}
                        autoHighlight={true}
                        getOptionLabel="name"
                        name="stop_Time_Mins"
                        onChange={onTimeChange}
                        filterSelectedOptions={true}
                        dropDownPlaceHolder="Stop Time Mins"
                        selectionRemove={undefinedFunction}
                        value={minOptions.find(q => q.id === props.data.stop_Time_Mins)}
                        defaultValue={minOptions.find(q => q.id === props.data.stop_Time_Mins)}
                        errorText={props.errors["stop_Time_Mins"] ? props.errors["stop_Time_Mins"].message : undefined}
                    />
                </LAGridItem>

                <LAGridItem sm={12} md={2}>
                    <LATextField
                        fullWidth={true}
                        name="run_Hours"
                        label="Run Hours"
                        varient="outlined"
                        onChange={props.onChange}
                        disabled={true}
                        value={props.data.run_Hours ?? ""}
                    />
                </LAGridItem>



                <LAGridItem sm={12} md={3}>
                    <LATextField
                        fullWidth={true}
                        name="tph"
                        label="TPH"
                        disabled={true}
                        varient="outlined"
                        onChange={onNumberField}
                        value={props.data.tph ?? ""}
                    />
                </LAGridItem>



                <LAGridItem sm={12} md={3}>
                    <LATextField
                        fullWidth={true}
                        name="magging"
                        label="Magging"
                        varient="outlined"
                        onChange={props.onChange}
                        value={props.data.magging ?? ""}
                    />
                </LAGridItem>

                <LAGridItem sm={12} md={3}>
                    <LAAutoComplete
                        multiple={false}
                        option={props.bottlenecks}
                        autoHighlight={true}
                        getOptionLabel="name"
                        name="bottle_Neck"
                        onChange={onDDChange}
                        filterSelectedOptions={true}
                        dropDownPlaceHolder="Bottle Neck"
                        selectionRemove={undefinedFunction}
                        value={props.bottlenecks.find(q => q.name === props.data.bottle_Neck)}
                        defaultValue={props.bottlenecks.find(q => q.name === props.data.bottle_Neck)}
                        errorText={props.errors["bottle_Neck"] ? props.errors["bottle_Neck"].message : undefined}
                    />
                </LAGridItem>

                <LAGridItem sm={12} md={3}>
                </LAGridItem>

                <LAGridItem sm={12} md={3}>
                    <LAAutoComplete
                        multiple={false}
                        option={props.byProducts}
                        autoHighlight={true}
                        getOptionLabel="name"
                        name="by_Product_1"
                        onChange={onDDChange}
                        filterSelectedOptions={true}
                        dropDownPlaceHolder="By Product 1"
                        selectionRemove={undefinedFunction}
                        value={props.byProducts.find(q => q.name === props.data.by_Product_1)}
                        defaultValue={props.byProducts.find(q => q.name === props.data.by_Product_1)}
                        errorText={props.errors["by_Product_1"] ? props.errors["by_Product_1"].message : undefined}
                    />
                </LAGridItem>

                <LAGridItem sm={12} md={3}>
                    <LATextField
                        fullWidth={true}
                        name="by_Product_1_MT"
                        label="By Product 1 MT"
                        varient="outlined"
                        onChange={onNumberField}
                        value={props.data.by_Product_1_MT ?? ""}
                        errorText={props.errors["by_Product_1_MT"] ? props.errors["by_Product_1_MT"].message : undefined}
                    />
                </LAGridItem>

                <LAGridItem sm={12} md={3}>
                    <LAAutoComplete
                        multiple={false}
                        option={props.runMaterials}
                        autoHighlight={true}
                        getOptionLabel="name"
                        name="by_Product_2"
                        onChange={onDDChange}
                        filterSelectedOptions={true}
                        dropDownPlaceHolder="By Product 2"
                        selectionRemove={undefinedFunction}
                        value={props.runMaterials.find(q => q.name === props.data.by_Product_2)}
                        defaultValue={props.runMaterials.find(q => q.name === props.data.by_Product_2)}
                        errorText={props.errors["by_Product_2"] ? props.errors["by_Product_2"].message : undefined}
                    />
                </LAGridItem>

                <LAGridItem sm={12} md={3}>
                    <LATextField
                        fullWidth={true}
                        name="by_Product_2_MT"
                        label="By Product 2 MT"
                        varient="outlined"
                        onChange={onNumberField}
                        value={props.data.by_Product_2_MT ?? ""}
                        errorText={props.errors["by_Product_2_MT"] ? props.errors["by_Product_2_MT"].message : undefined}
                    />
                </LAGridItem>

                <LAGridItem sm={12} md={3}>
                    <LAAutoComplete
                        multiple={false}
                        option={props.byProducts}
                        autoHighlight={true}
                        getOptionLabel="name"
                        name="by_Product_3"
                        onChange={onDDChange}
                        filterSelectedOptions={true}
                        dropDownPlaceHolder="By Product 3"
                        selectionRemove={undefinedFunction}
                        value={props.byProducts.find(q => q.name === props.data.by_Product_3)}
                        defaultValue={props.byProducts.find(q => q.name === props.data.by_Product_3)}
                        errorText={props.errors["by_Product_3"] ? props.errors["by_Product_3"].message : undefined}
                    />
                </LAGridItem>

                <LAGridItem sm={12} md={3}>
                    <LATextField
                        fullWidth={true}
                        name="by_Product_3_MT"
                        label="By Product 3 MT"
                        varient="outlined"
                        onChange={onNumberField}
                        value={props.data.by_Product_3_MT ?? ""}
                        errorText={props.errors["by_Product_3_MT"] ? props.errors["by_Product_3_MT"].message : undefined}
                    />
                </LAGridItem>

                <LAGridItem sm={12} md={3}>
                    <LAAutoComplete
                        multiple={false}
                        option={props.runMaterials}
                        autoHighlight={true}
                        getOptionLabel="name"
                        name="by_Product_4"
                        onChange={onDDChange}
                        filterSelectedOptions={true}
                        dropDownPlaceHolder="By Product 4"
                        selectionRemove={undefinedFunction}
                        value={props.runMaterials.find(q => q.name === props.data.by_Product_4)}
                        defaultValue={props.runMaterials.find(q => q.name === props.data.by_Product_4)}
                        errorText={props.errors["by_Product_4"] ? props.errors["by_Product_4"].message : undefined}
                    />
                </LAGridItem>

                <LAGridItem sm={12} md={3}>
                    <LATextField
                        fullWidth={true}
                        name="by_Product_4_MT"
                        label="By Product 4 MT"
                        varient="outlined"
                        onChange={onNumberField}
                        value={props.data.by_Product_4_MT ?? ""}
                        errorText={props.errors["by_Product_4_MT"] ? props.errors["by_Product_4_MT"].message : undefined}
                    />
                </LAGridItem>

                <LAGridItem sm={12} md={3}>
                    <LATextField
                        fullWidth={true}
                        name="polish"
                        label="Polish"
                        varient="outlined"
                        onChange={props.onChange}
                        value={props.data.polish ?? ""}
                    />
                </LAGridItem>

                <LAGridItem sm={12}>
                    <LASaveAndCancelButton
                        saveButtonText="Save"
                        cancelButtonText="Cancel"
                        onSave={props.onSave}
                        onCancel={props.onClose}
                        disableSave={Object.values(props.errors).length > 0 ? true : undefined}
                    />
                </LAGridItem>

            </LAGrid>
        </LAPaperWithLessPadding>
        <br />
    </LAPopover>
});


export interface IConvertNumberList {
    id: number;
    name: string;
};

export const minOptions: IConvertNumberList[] = [{ id: 0, name: "00" }, { id: 15, name: "15" }, { id: 30, name: "30" }, { id: 45, name: "45" }];

export const hourOptions = (): IConvertNumberList[] => {
    let response: IConvertNumberList[] = [];
    for (let i = 0; i < 24; i++) {
        response.push({ id: i, name: i.toString() });
    };
    return response;
};