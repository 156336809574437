import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IQualityControlTestings, IGET_QUALITY_CONTROL_TESTINGS_REQUEST } from "./getQualityControlTestingsConstaints";
import { IGetQualityControlTestingsLoadAction, IGetQualityControlTestingsLoadFailedAction, IGetQualityControlTestingsSuccessAction } from "./getQualityControlTestingsActions";
import { IADD_QUALITY_CONTROL_TESTING_REQUEST } from "../addQualityControlTesting/addQualityControlTestingConstaints";
import { IAddQualityControlTestingSuccessAction } from "../addQualityControlTesting/addQualityControlTestingActions";
import { IUpdateQualityControlTestingSuccessAction } from "../updateQualityControlTesting/updateQualityControlTestingActions";
import { IUPDATE_QUALITY_CONTROL_TESTING_REQUEST } from "../updateQualityControlTesting/updateQualityControlTestingConstaints";

type Actions =
    IGetQualityControlTestingsLoadAction
    | IGetQualityControlTestingsLoadFailedAction
    | IGetQualityControlTestingsSuccessAction
    | IAddQualityControlTestingSuccessAction
    | IUpdateQualityControlTestingSuccessAction
    | IFlushDataSuccessAction;

export const GetQualityControlTestingsReducer = (state: Server<SilAPIResponse<IQualityControlTestings[]>> = notLoaded, action: Actions): Server<SilAPIResponse<IQualityControlTestings[]>> => {
    switch (action.type) {
        case IGET_QUALITY_CONTROL_TESTINGS_REQUEST.REQUEST:
            return loading;

        case IGET_QUALITY_CONTROL_TESTINGS_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_QUALITY_CONTROL_TESTINGS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_QUALITY_CONTROL_TESTING_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_QUALITY_CONTROL_TESTING_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};