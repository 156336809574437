import React, { useEffect } from "react";
import {Provider} from "react-redux";
import {BrowserRouter, Router} from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "./react/shared/theme";
import Main from "./react/main";
import configureStore from "./redux/store";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();
export const store = configureStore();


const App: React.FC = () => {
    useEffect(() => {
    }, []);
    
    return <Provider store={store}>
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <Router history={history}>
                    <Main />
                </Router>
            </ThemeProvider>
        </BrowserRouter>
    </Provider>;
};
    

export default App;
