import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IUPDATE_CARRIER_INFRACTION_REQUEST } from "./updateCarrierInfractionConstaints";
import { IUpdateCarrierInfractionLoadAction, IUpdateCarrierInfractionLoadFailedAction, IUpdateCarrierInfractionSuccessAction } from "./updateCarrierInfractionActions";

type Actions =
    IUpdateCarrierInfractionLoadAction
    | IUpdateCarrierInfractionLoadFailedAction
    | IUpdateCarrierInfractionSuccessAction
    | IFlushDataSuccessAction;

export const UpdateCarrierInfractionReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IUPDATE_CARRIER_INFRACTION_REQUEST.REQUEST:
            return loading;

        case IUPDATE_CARRIER_INFRACTION_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IUPDATE_CARRIER_INFRACTION_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};