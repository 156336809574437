import { IPassTokenWithRequest } from "../token/getTokenConstants";

export enum IGET_EMPLOYEES_REQUEST {
    REQUEST = "getEmployees/GET_EMPLOYEES_REQUEST",
    SUCCESS = "getEmployees/GET_EMPLOYEES_SUCCESS",
    FAILED = "getEmployees/GET_EMPLOYEES_FAILED"
};

export interface IGetEmployeesRequest extends IPassTokenWithRequest {

};

export interface IEmployees {
    id: number;
    employee_Name: string;
    location: string;
    active: string;
    ball_Mill: string;
    created: string;
    modified: string;
    created_By: string;
    modified_By: string;
};