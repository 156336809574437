import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IGET_GEO_BAGS_REQUEST, IGeoBag } from "./getGeoBagsConstaints";
import { IGetGeoBagsLoadAction, IGetGeoBagsLoadFailedAction, IGetGeoBagsSuccessAction } from "./getGeoBagsActions";
import { IAddGeoBagSuccessAction } from "../addGeoBag/addGeoBagActions";
import { IADD_GEO_BAG_REQUEST } from "../addGeoBag/addGeoBagConstaints";
import { IUpdateGeoBagSuccessAction } from "../updateGeoBag/updateGeoBagActions";
import { IUPDATE_GEO_BAG_REQUEST } from "../updateGeoBag/updateGeoBagConstaints";

type Actions =
    IGetGeoBagsLoadAction
    | IGetGeoBagsLoadFailedAction
    | IGetGeoBagsSuccessAction
    | IUpdateGeoBagSuccessAction
    | IAddGeoBagSuccessAction
    | IFlushDataSuccessAction;

export const GetGeoBagsReducer = (state: Server<SilAPIResponse<IGeoBag[]>> = notLoaded, action: Actions): Server<SilAPIResponse<IGeoBag[]>> => {
    switch (action.type) {
        case IGET_GEO_BAGS_REQUEST.REQUEST:
            return loading;

        case IGET_GEO_BAGS_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_GEO_BAGS_REQUEST.FAILED:
            return failed(action.message);

        case IUPDATE_GEO_BAG_REQUEST.SUCCESS:
            return notLoaded;

        case IADD_GEO_BAG_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};