import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetWashPlantProductionSummaryRequest, IGET_WASH_PLANT_PRODUCTION_SUMMARY_REQUEST, IWashPlantProductionSummary } from "./getWashPlantProductionSummaryConstants";

export interface IGetWashPlantProductionSummaryLoadAction {
    type: IGET_WASH_PLANT_PRODUCTION_SUMMARY_REQUEST.REQUEST;
    data: IGetWashPlantProductionSummaryRequest
}
export const getWashPlantProductionSummaryLoadAction = (data: IGetWashPlantProductionSummaryRequest): IGetWashPlantProductionSummaryLoadAction => ({
    type: IGET_WASH_PLANT_PRODUCTION_SUMMARY_REQUEST.REQUEST,
    data
});

export interface IGetWashPlantProductionSummarySuccessAction {
    type: IGET_WASH_PLANT_PRODUCTION_SUMMARY_REQUEST.SUCCESS;
    data: SilAPIResponse<IWashPlantProductionSummary[]>;
}
export const getWashPlantProductionSummaryLoadSuccessAction = (data: SilAPIResponse<IWashPlantProductionSummary[]>): IGetWashPlantProductionSummarySuccessAction => ({
    type: IGET_WASH_PLANT_PRODUCTION_SUMMARY_REQUEST.SUCCESS,
    data
});

export interface IGetWashPlantProductionSummaryLoadFailedAction {
    type: IGET_WASH_PLANT_PRODUCTION_SUMMARY_REQUEST.FAILED;
    message: string;
}
export const getWashPlantProductionSummaryLoadFailedAction = (message: string): IGetWashPlantProductionSummaryLoadFailedAction => ({
    type: IGET_WASH_PLANT_PRODUCTION_SUMMARY_REQUEST.FAILED,
    message
});