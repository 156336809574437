import React, { useCallback, useState } from "react";
import { AddIcon } from "./icons";
import styled from "styled-components";
import { LAButton, LAIconButton } from "./buttons";
import "devextreme/dist/css/dx.light.css";
import { BLUE_COLOR, DARK_GREY_COLOR, GREEN_COLOR, LIGHT_GREY_COLOR, RED_COLOR, ROW_SELECT_COLOR } from "./theme";
import { EventInfo } from "devextreme/events";
import { undefinedFunction } from "./constExports";
import dxDataGrid, { DataChangeInfo, RowClickEvent, ToolbarPreparingInfo } from "devextreme/ui/data_grid";

import DataGrid, { Button, Column, Editing,Selection, FilterPanel, FilterRow, Grouping, HeaderFilter, Lookup, Scrolling, SearchPanel, StringLengthRule, AsyncRule, Summary, TotalItem, Export, StateStoring, ColumnChooser, Pager } from "devextreme-react/data-grid";

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import LAGridItem from "./gridList";

export interface IDevExtremeColumn {
    name: string;
    max?: number;
    type?: string;
    width?: number;
    dropDownId?: any;
    caption?: string;
    sortDesc?: boolean;
    sortAsc?: true;
    dropDownName?: any;
    customError?: true;
    requiredField?: true;
    dropDownSource?: any;
    allowEditing?: boolean;
    calulated?: (rowData: any) => any;
    renderCell?: (rowData: any) => any;
    show?: boolean;
};

interface ISumColumn {
    caption: string;
    columnName: string;
};

interface ILADevExtremeGridProps {
    id?: string;
    data: any[];
    autoFocus?: true;
    add?: boolean;
    height?: number;
    export?: true;
    keyExpr?: string;
    buttonLabel?: string;
    actionWidth?: number;
    columnWidth?: number;
    searchPanel: boolean;
    filterHeader: boolean;
    columnChoose?: boolean;
    moveBtnToLeft?: boolean;
    sumColumn?: ISumColumn[];
    onEditStart?: () => void;
    onEditCancel?: () => void;
    onEdit?: (e: any) => void;
    onClone?: (e: any) => void;
    onDelete?: (e: any) => void;
    onNewRowInsert?: () => void;
    columns: IDevExtremeColumn[];
    onCustomDelete?: (e: any) => void;
    onCustomClick?: (name: string, e: any) => void;
    onCustomEdit?: (e: any) => void;
    onClick: (e: RowClickEvent<any, unknown>) => void;
    exportFileName?: string;
    onEditSave?: (e: EventInfo<dxDataGrid<any, unknown>> & DataChangeInfo<any, unknown>) => void;
    storageKey?: string;
    selectionRow?: boolean;
    onSelectionChanged?: (row:any) => void;
    onRowPrepared?: (row: any) => void;
    columnsHiding?: boolean;
};

const LADevExtremeGridStyles = styled.div`
    .dx-datagrid-headers .dx-header-row {  
        font-weight: bold;
        background-color: ${LIGHT_GREY_COLOR};
    };
    .dx-header-filter-indicator, .dx-cell-focus-disabled, .dx-datagrid-content {
        font-size: 12px !important;
    };
    .dx-state-hover
    {
        background-color: ${ROW_SELECT_COLOR} !important;
    }
    .control-button{
        
        color: #337ab7;
        text-align:center;
        transition: all 0.2s;
    };

    a.button4:hover{
        border-color: rgba(255,255,255,1);
    };

    .link-btn {
        cursor: pointer;
        color: ${BLUE_COLOR};
    };

    .link-btn:hover {
        text-decoration: underline;
    }
    
    @media all and (max-width:30em){
        a.button4 {
            display:block;
            margin:0.2em auto;
        };
    };

    .editBg{
        background-color: #4CAF50;
    };

    .cloneBg{
        background-color: orange;
    };

    .deleteBg{
        background-color: #CD5C5C;
    };

    .editMode {
        .dx-edit-row {
            background-color: ${DARK_GREY_COLOR};
        };
    
        .dx-edit-row a {
            background-color: ${DARK_GREY_COLOR};
            color: ${RED_COLOR};
        };
    
        .dx-texteditor-input {
            color: ${GREEN_COLOR};
            border: 2px outset;
        };
    };
`;


export const LADevExtremeGrid: React.FC<ILADevExtremeGridProps> = React.memo((props: ILADevExtremeGridProps) => {
    const [searchValue, setSearchValue] = useState('');
    const customizeText = useCallback((e) => {
        return e.value > 0 ? "Total: " + Number(e.value).toFixed(2) : "";
    }, []);

    const [storageChange, setStorageChange] = useState<boolean>(false);

    const loadState = useCallback(() => {
        if (props.storageKey) {
            let data = localStorage.getItem(props.storageKey);

            if (data)
                return JSON.parse(data);
        }
    }, [storageChange]);

    const saveState = useCallback((state:any) => {
        if (state) {
            for (let i = 0; i < state.columns.length; i++) {
                state.columns[i].filterValue = null;
            }
        }

        if (props.storageKey)
            localStorage.setItem(props.storageKey, JSON.stringify(state));
    }, []);

    const clearFilters = async (): Promise<void> => {
        if (props.storageKey) {
            const getCurrent = localStorage.getItem(props.storageKey);

            if (getCurrent) {
                let parsed = JSON.parse(getCurrent);
                parsed.filterValue = null;
                parsed.filterValues = null;
                parsed.searchText = null;
                parsed.filterPanel = null;
                //remove header filters on every columns
                parsed.columns.map((row: any, id: number) => (
                    row.filterValue = null,
                    row.filterValues = null,
                    row.filterType = null
                ));

                localStorage.setItem(props.storageKey, JSON.stringify(parsed));
                setStorageChange(!storageChange);
            }

            // if(props.onClearClick)
            // props.onClearClick();
        }
    };

    const onGridReady = () => {
        if(props.autoFocus){
            const firstColumnInputFilter: HTMLInputElement | null = document.querySelector('tr.dx-row input.dx-texteditor-input[tabindex="0"]');
            if (firstColumnInputFilter)
                firstColumnInputFilter.focus();
        }
      };
    
    const onExportClick = (e: any): void => onExporting(e, props.exportFileName ?? "File");

    const handleValueChange = (e:any) => {
        // console.log(e);
        const trimmedValue = e.trim();
        setSearchValue(trimmedValue);
      };
      
    return (        
        <LADevExtremeGridStyles>
            <LAGridItem xs={12}>
                {props.storageKey && <LAButton
                    label="Clear Filters"
                    className="text-center"
                    onClick={clearFilters}
                    disabled={undefined}
                />} 
            </LAGridItem>
            <LAGridItem xs={12}>
                <DataGrid
                    keyExpr={props.keyExpr}
                    id={props.id}
                    showBorders={true}
                    columnAutoWidth={true}
                    wordWrapEnabled={true}
                    dataSource={props.data}
                    hoverStateEnabled={true}
                    className={props.onCustomEdit ? "editMode" : ""}
                    onRowClick={props.onClick}
                    onSaved={props.onEditSave}
                    rowAlternationEnabled={true}
                    height={props.height ?? 800}
                    columnWidth={props.columnWidth}
                    onEditingStart={function (e: any): void {
                        if (props.onEditStart)
                            props.onEditStart();
                    }}
                    onInitNewRow={props.onNewRowInsert}
                    onContentReady={onGridReady}
                    onEditCanceled={props.onEditCancel}
                    onToolbarPreparing={props.moveBtnToLeft ? moveAddBtn : undefined}
                    onExporting={onExportClick}
                    onSelectionChanged={props.onSelectionChanged}
                    onRowRemoved={props.onDelete ? props.onDelete : undefinedFunction}
                    onRowPrepared={props.onRowPrepared ? props.onRowPrepared : undefined}
                    columnHidingEnabled={props.columnsHiding ? true : false}
                >
                    {props.selectionRow && <Selection mode="multiple" />}

                    {(props.onEdit || props.onDelete || props.onCustomEdit || props.onCustomDelete) && <Editing
                        mode="row"
                        allowAdding={props.add ? true : false}
                        allowUpdating={props.onCustomEdit ? true : props.onEdit ? true : false}
                        allowDeleting={props.onDelete ? true : false}

                    />}

                    {(props.onEdit || props.onDelete || props.onClone || props.onCustomEdit) && <Column type="buttons" width={props.actionWidth ?? 180}>
                        {(props.onEdit) && <Button cssClass="control-button" text={props.buttonLabel ?? "View/Update"} onClick={props.onEdit} />}
                        {(props.onCustomEdit) && <LAIconButton label="Edit" icon={<AddIcon />} onClick={props.onCustomEdit} />}
                        {props.onClone && <Button cssClass="control-button cloneBg" text="Clone" onClick={props.onClone} />}
                        {props.onCustomDelete && <Button cssClass="control-button" text="Delete" onClick={props.onCustomDelete} />}
                    </Column>}

                    {props.columns.map((x, index): JSX.Element => {
                        if (x.dropDownSource !== undefined) {
                            return <Column key={index}    width={x.width} dataField={x.name} caption={x.caption ? x.caption : x.name} visible={x.show === false ? false : true}>
                                <Lookup dataSource={x.dropDownSource} displayExpr={x.dropDownName} valueExpr={x.dropDownId} />
                                {x.requiredField && <StringLengthRule
                                    min={1}
                                    message={(x.caption ? x.caption : x.name) + " Required"}
                                />}
                            </Column>
                        } else {
                            if ((x.dropDownSource === undefined) && (x.type === "date")) {
                                return <Column
                                    key={index}
                                    width={x.width}
                                    dataType={x.type}
                                    dataField={x.name}
                                    format="MM/dd/yyyy"
                                    caption={x.caption ? x.caption : x.name}
                                    sortOrder={x.sortDesc ? "desc" : undefined}
                                    visible={x.show === false ? false : true}
                                />
                            }
                            else if ((x.dropDownSource === undefined) && (x.type === "datetime")) {
                                return <Column
                                    key={index}
                                    width={x.width}
                                    dataType={x.type}
                                    dataField={x.name}
                                    allowEditing={x.allowEditing ?? true}
                                    format="MM/dd/yyyy HH:mm"
                                    caption={x.caption ? x.caption : x.name}
                                    sortOrder={x.sortDesc ? "desc" : undefined}
                                    visible={x.show === false ? false : true}
                                />
                            } else if ((x.dropDownSource === undefined) && (x.type === "button")) {
                                const onCustomClick = (e: any): void => props.onCustomClick ? props.onCustomClick(x.name, e) : undefined;
                                return <Column caption={x.caption} width={x.width} type="buttons" sortOrder={x.sortAsc ? "asc" : undefined} visible={x.show === false ? false : true}>
                                    <Button text={x.caption} onClick={onCustomClick} />
                                </Column>
                            } else {
                                return <Column
                                    key={index}
                                    width={x.width}
                                    dataType={x.type}
                                    dataField={x.name}
                                    allowSearch={true}
                                    alignment={"left"}
                                    sortOrder={x.sortAsc ? "asc" : x.sortDesc ? "desc" : undefined}
                                    calculateCellValue={x.calulated}
                                    allowEditing={x.allowEditing ?? true}
                                    caption={x.caption ? x.caption : x.name}
                                    cellRender={x.renderCell}
                                    visible={x.show === false ? false : true}
                                >
                                    {(x.requiredField && x.type === "string") ? <StringLengthRule
                                        min={1}
                                        max={x.max}
                                        message={(x.caption ? x.caption : x.name) + " Required"}
                                    /> : undefined}

                                    {(x.customError && x.type === "number") ?
                                        <AsyncRule
                                            type="async"
                                            validationCallback={validateAsync}
                                        /> : undefined}
                                </Column>
                            }
                        }
                    })}
                    <Pager
                        visible={true}
                        allowedPageSizes={"auto"}
                        displayMode={'full'}
                        showPageSizeSelector={true}
                        showInfo={true}
                        showNavigationButtons={true} 
                    />
                    {props.export && <Export enabled={true} />}

                    <Scrolling columnRenderingMode="virtual" />
                    <FilterRow visible={props.filterHeader ? true : false} />
                    <HeaderFilter visible={props.filterHeader ? true : false} />
                    <FilterPanel visible={props.filterHeader ? true : false} />
                    <Grouping autoExpandAll={false} />

       

                    <ColumnChooser
                            mode="select"
                            height={window.innerHeight - 100}
                            enabled={props.columnChoose ?? true}
                        />
                        

                    <StateStoring
                            type="custom"
                            customLoad={loadState}
                            customSave={saveState}
                            storageKey={props.storageKey}
                            enabled={props.storageKey ? true : false}
                        />

                    {props.sumColumn && <Summary>
                        {props.sumColumn.map((x) => (
                            <TotalItem
                                key={x.columnName}
                                alignment="left"
                                summaryType="sum"
                                column={x.columnName}
                                showInColumn={x.columnName}
                                displayFormat={`${x.caption}: {0}`}
                                customizeText={customizeText}
                            />
                        ))}
                        {/* <GroupItem
                    column={props.sumColumn}                     
                    summaryType="sum"
                    displayFormat="Total Hours: {0}"
                /> */}
                    </Summary>}

                    {props.searchPanel && <SearchPanel
                        visible={true}
                        width={200}
                        placeholder="Search..."
                        text={searchValue}
                        onTextChange={handleValueChange}
                    />}

                </DataGrid>
            </LAGridItem>
        </LADevExtremeGridStyles>
    );
});


const validateAsync = (prm: any): any => {
    return new Promise((resolve, reject) => {
        ((prm.data.feed_1_Perc) + (prm.data.feed_2_Perc ?? 0) + (prm.data.feed_3_Perc ?? 0) + (prm.data.feed_4_Perc ?? 0)) < 100 ? resolve(true) : reject("Total feed % is more than 100");
    });
};

const moveAddBtn = (e: EventInfo<dxDataGrid<any, unknown>> & ToolbarPreparingInfo): any => {
    return e.toolbarOptions.items?.forEach((x: any) => {
        x.location = "before";
    });
};

export const onExporting = (e: any, name: string): any => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(name);

    exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
    }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            saveAs(new Blob([buffer], { type: "application/octet-stream" }), name + ".xlsx");
        });
    });
    e.cancel = true;
};

