import { YHDAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../../server";
import { IGET_YHD_CARRIER_INFRACTIONS_REQUEST, IYHDCarrierInfractions } from "./getCarrierInfractionsConstaints";
import { IUpdateYHDCarrierInfractionSuccessAction } from "../updateCarrierInfraction/updateCarrierInfractionActions";
import { IAddYHDCarrierInfractionSuccessAction } from "../addCarrierInfraction/addCarrierInfractionActions";
import { IADD_YHD_CARRIER_INFRACTION_REQUEST } from "../addCarrierInfraction/addCarrierInfractionConstaints";
import { IUPDATE_YHD_CARRIER_INFRACTION_REQUEST } from "../updateCarrierInfraction/updateCarrierInfractionConstaints";
import { IGetYHDCarrierInfractionsLoadAction, IGetYHDCarrierInfractionsLoadFailedAction, IGetYHDCarrierInfractionsSuccessAction } from "./getCarrierInfractionsActions";

type Actions =
    IGetYHDCarrierInfractionsLoadAction
    | IGetYHDCarrierInfractionsLoadFailedAction
    | IGetYHDCarrierInfractionsSuccessAction
    | IAddYHDCarrierInfractionSuccessAction
    | IUpdateYHDCarrierInfractionSuccessAction
    | IFlushDataSuccessAction;

export const GetYHDCarrierInfractionsReducer = (state: Server<YHDAPIResponse<IYHDCarrierInfractions[]>> = notLoaded, action: Actions): Server<YHDAPIResponse<IYHDCarrierInfractions[]>> => {
    switch (action.type) {
        case IGET_YHD_CARRIER_INFRACTIONS_REQUEST.REQUEST:
            return loading;

        case IGET_YHD_CARRIER_INFRACTIONS_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_YHD_CARRIER_INFRACTIONS_REQUEST.FAILED:
            return failed(action.message);

        case IADD_YHD_CARRIER_INFRACTION_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_YHD_CARRIER_INFRACTION_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};