import { IPassTokenWithRequest } from "../../../dryPlant/token/getTokenConstants";
import { IAddChemicalAnalysesRequest } from "../addChemicalAnalyses/addChemicalAnalysesConstaints";

export enum IUPDATE_CHEMICAL_ANALYSES_REQUEST {
    REQUEST = "updateChemicalAnalyses/UPDATE_CHEMICAL_ANALYSES_REQUEST",
    SUCCESS = "updateChemicalAnalyses/UPDATE_CHEMICAL_ANALYSES_SUCCESS",
    FAILED = "updateChemicalAnalyses/UPDATE_CHEMICAL_ANALYSES_FAILED"
};

export interface IUpdateChemicalAnalysesRequest extends IAddChemicalAnalysesRequest {
};
