import { IFlushDataSuccessAction } from "../../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../../server";
import { IAddYHDCarrierFleetTruckLoadAction, IAddYHDCarrierFleetTruckLoadFailedAction, IAddYHDCarrierFleetTruckSuccessAction } from "./addCarrierFleetTruckActions";
import { IADD_YHD_CARRIER_FLEET_TRUCK_REQUEST } from "./addCarrierFleetTruckConstaints";


type Actions =
    IAddYHDCarrierFleetTruckLoadAction
    | IAddYHDCarrierFleetTruckLoadFailedAction
    | IAddYHDCarrierFleetTruckSuccessAction
    | IFlushDataSuccessAction;

export const AddYHDCarrierFleetTruckReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IADD_YHD_CARRIER_FLEET_TRUCK_REQUEST.REQUEST:
            return loading;

        case IADD_YHD_CARRIER_FLEET_TRUCK_REQUEST.SUCCESS:
            return succeeded("Success");

        case IADD_YHD_CARRIER_FLEET_TRUCK_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};