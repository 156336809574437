
import {addOrderedByContact} from './addOrderedByContact';
import {currentUser} from '../../../../simosHome';

async function addOrder(chosenID: number, all_info: any, orderID:number, allProducts:any, allValues:any) {
    var responseStatus:any = null;
    // Set status to initiated if transport company was not selected
    if(all_info[1].transCompany === ""){
        all_info[1].status = "Initiated";
        all_info[1].transCompany = "TBD";
    }
    // * Pass boolean values as strings into db
    let emailuser="No", emailtransport="No", packaged="No", completed="No";
    // * Re-format ordered products and orderedby email to match backend class structure
    let tempProducts = [], tempEmails:any=[];
    for (var i=0;i<allProducts.productOrder.length; i++){
        if(allProducts.productOrder[i].code!=""){
        tempProducts.push({Quantity:allProducts.productOrder[i].quantity, Size:allProducts.productOrder[i].chosenSize, Per:allProducts.productOrder[i].chosenPer,
            Product:allProducts.productOrder[i].chosenProd,Code:allProducts.productOrder[i].code,CW:allProducts.productOrder[i].c_w, Double_CW:allProducts.productOrder[i].d_c_w});
        }
    }
    for(var i=0;i<allValues.orderedby.length;i++){
            if(allValues.orderedby[i].contact_selected === true){
                tempEmails.push({Email:allValues.orderedby[i].orderformEmail, Contact:allValues.orderedby[i].orderformName,
                Fax:allValues.orderedby[i].orderformFax, Phone:allValues.orderedby[i].orderformPhone, Customer_ID:chosenID,
            Selected:"Yes"});
            }
        }
    // Format from boolean to "Yes" or "No"
    if(all_info[1].emailUser===true){
        emailuser = "Yes";
    }
    if(all_info[1].emailTransport===true) {
        emailtransport="Yes";
    }
    if(all_info[1].packaged===true) {
        packaged="Yes";
    }
    if(all_info[1].completed===true) {
        completed="Yes";
    }
    
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type':'application/json'},
        body: JSON.stringify({
            request: {
                Customer_ID: chosenID,
                Customer: all_info[1].customer,
                Address: all_info[1].delivery,
                Pickupdate: all_info[1].pickupDate,
                Pickuphour: all_info[1].pickupHour,
                Pickupminute: all_info[1].pickupMin,
                Sitecontact: all_info[1].siteContact,
                Sitecontact_Phone: all_info[1].siteContactPhone,
                PO_Number: all_info[1].poNum,
                Emailuser: emailuser,
                Emailtransport: emailtransport,
                Transport_Company: all_info[1].transCompany,
                Truck_Required: all_info[1].truckRequired,
                Deliverydate: all_info[1].deliveryDate,
                Deliveryhour: all_info[1].deliveryHour,
                Deliveryminute: all_info[1].deliveryMin,
                Location: all_info[1].location,
                Comments: all_info[1].comments,
                Packaged: packaged,
                Completed: completed,
                Cancelled: all_info[1].cancelled,
                Truck_Number: all_info[1].truckNumber, 
                Trailer_Number: all_info[1].trailerNumber,
                Tare_Weight: all_info[1].tareWeight,
                Gross_Weight: all_info[1].grossWeight,
                Created_By:currentUser,
                Modified_By:currentUser,
                Product_Order: tempProducts,
                Customer_Contact:tempEmails,
                Status:all_info[1].status,
                Pickup:'N/A',
                Order_Type:all_info[1].orderType,
                Transport_Company_Notes:all_info[1].transport_Company_Notes,
                Zone:all_info[1].zone,
                Hired_Truck_Rates:all_info[1].hired_truck_rate,
                Truck_Code:all_info[1].truck_code,
                Delivery_Datetime:all_info[1].delivery_Datetime,
                Pickup_Datetime:all_info[1].pickup_Datetime,
                Sales_Order_Number:all_info[1].salesOrderNumber,
                Customer_Job_Number:all_info[1].customerJobNumber
            },
            token: '19515195-d571-44a3-b0db-637f5bf24f54'})
    }
   await fetch('https://api2.propsense.com/api/AddSIMOSOrder', requestOptions)
     .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json');
        // error response
        if(!response.ok) {
          const error = (data) || response.status;
          console.log("should not be here");
          return error;
      } 
      responseStatus = response;
        //     if(response.status === 200){
        //     response.json().then(async res=> {
        //       orderID = res.id;
        //         // Add orderedBy contacts to the order #
        //         for (var i=0; i<allValues.orderedby.length; i++){
        //             addOrderedByContact(chosenID, allValues.orderedby, i, orderID);
        //         }
        //     });
        // }
    });
    return responseStatus;
}


export default addOrder;
