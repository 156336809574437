import ReactDOM from 'react-dom';
import App from './app';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { runWithAdal } from 'react-adal';
import { authContext } from './adalConfig';
import { reactVersionCheck } from './react/shared/constExports';

const DO_NOT_LOGIN = false;

// In order to run app locally comment this section (Production with Login) and uncomment section (Development without Login)
reactVersionCheck();

runWithAdal(authContext, () => {
  ReactDOM.render(
    <App />,
  document.getElementById('root')
);
reportWebVitals();
},DO_NOT_LOGIN);



// #region Development without Login


// ReactDOM.render(
//   <App />,
// document.getElementById('root')
// );
// reportWebVitals();



//#endregion


