import { IPassTokenRequestBody } from "../../../../../react/shared/publicInterfaces";

export enum IGET_SIMOS_CUSTOMER_CONTACTS_REQUEST {
    REQUEST = "getSimosCustomerContacts/GET_SIMOS_CUSTOMER_CONTACTS_REQUEST",
    SUCCESS = "getSimosCustomerContacts/GET_SIMOS_CUSTOMER_CONTACTS_SUCCESS",
    FAILED = "getSimosCustomerContacts/GET_SIMOS_CUSTOMER_CONTACTS_FAILED"
};

export interface ISimosCustomerContactsRequest extends IPassTokenRequestBody {

};

export interface ISimosCustomerContacts {
    id: number;
    contact: string;
    phone: string;
    cell: string;
    email: string;
    customer_ID: number;
    customer: string;
    delivery_ID: number;
    delivery_Address: string;
    created: string;
    created_By: string;
    modified: string;
    modified_By: string;   
};
