import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { getWashPlantBinAdjustmentsStatus } from "../../../../redux/sil/washPlant/getWashPlantBinAdjustments/getWashPlantBinAdjustmentsAccessor";
import { getWashPlantBinAdjustmentsLoadAction } from "../../../../redux/sil/washPlant/getWashPlantBinAdjustments/getWashPlantBinAdjustmentsActions";
import { IWashPlantBinAdjustments, IGetWashPlantBinAdjustmentsRequest } from "../../../../redux/sil/washPlant/getWashPlantBinAdjustments/getWashPlantBinAdjustmentsConstaints";
import { hasPayload, isNotLoaded, Server } from "../../../../redux/server";
import { SilAPIResponse } from "../../../shared/publicInterfaces";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import { callRouteWithQueryString, userName } from "../../../shared/constExports";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { LAButton } from "../../../shared/buttons";
import { AddIcon } from "../../../shared/icons";
import { WHITE_COLOR } from "../../../shared/theme";
import { ROUTE } from "../../../routes";
import PageSpacing from "../../../shared/pageSpacing";
import { IGetWashPlantProductionLookupsRequest, IWashPlantProductionLookups } from "../../../../redux/sil/washPlant/getWashPlantProductionLookups/getWashPlantProductionLookupsConstaints";
import { getWashPlantProductLookupsLoadAction } from "../../../../redux/sil/washPlant/getWashPlantProductLookups/getWashPlantProductLookupsActions";
import { getWashPlantProductLookupsStatus } from "../../../../redux/sil/washPlant/getWashPlantProductLookups/getWashPlantProductLookupsAccessor";
import SilRoleCheck from "../../../shared/silRoleCheck";


interface IWashPlantPileAdjustmentsStoreProps {
    getToken: Server<SilAPIResponse<IToken>>;
    getPileAdjustments: Server<SilAPIResponse<IWashPlantBinAdjustments[]>>;
    getDropDownData: Server<SilAPIResponse<IWashPlantProductionLookups>>;
};

interface IWashPlantPileAdjustmentsDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getPileAdjustmentsRequest: (data: IGetWashPlantBinAdjustmentsRequest) => unknown;
    getDropDownDataRequest: (data: IGetWashPlantProductionLookupsRequest) => unknown;
};


interface IWashPlantPileAdjustmentsOwnProps {

};

interface IWashPlantPileAdjustmentsState {

};

const WashPlantPileAdjustmentsStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
    };
`;

type IWashPlantPileAdjustmentsProps = RouteComponentProps
    & IWashPlantPileAdjustmentsStoreProps
    & IWashPlantPileAdjustmentsDispatchProps
    & IWashPlantPileAdjustmentsOwnProps;

class WashPlantPileAdjustments extends PureComponent<IWashPlantPileAdjustmentsProps, IWashPlantPileAdjustmentsState> {

    public constructor(props: IWashPlantPileAdjustmentsProps) {
        super(props);
        this.state = {
        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: IWashPlantPileAdjustmentsProps): void {
        if (this.props !== prevProps)
            this.callServer();
    };


    public render(): ReactNode {

        const { getPileAdjustments } = this.props;
        const data = hasPayload(getPileAdjustments) ? Object.values(getPileAdjustments.payload.response) : [];

        return (
            <PageSpacing title="SIL - Pile Adjustments" description="SIL - Pile Adjustments" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="washPlantAccess">
                    <WashPlantPileAdjustmentsStyles>

                        <LAButton className="pull-left" startIcon={<AddIcon color={WHITE_COLOR} />} label="Add" onClick={this.handleAdd} />
                        <h2 className="text-center">PILE ADJUSTMENTS</h2>
                        <hr />

                        <LADevExtremeGrid
                            data={data}
                            onEdit={this.onEdit}
                            onClick={this.onClick}
                            searchPanel={true}
                            filterHeader={true}
                            export={true}
                            exportFileName={"Pile Adjustments"}
                            storageKey="silWashPlantPileAdjustmentsStorageKey"
                            columns={[
                                { name: "type", caption: "Type", type: "string" },
                                { name: "adjustment_Type", caption: "Adjustment Type", type: "string" },
                                { name: "transfer_Date", caption: "Transfer Date", type: "date", width: 130, sortDesc: true },
                                { name: "bin", caption: "Pile", type: "string" },
                                { name: "bin_From", caption: "Pile From", type: "string" },
                                { name: "bin_To", caption: "Pile To", type: "string" },
                                { name: "product", caption: "Product", type: "string" },
                                { name: "tonnage", caption: "Tonnage", type: "string" },
                                { name: "reason", caption: "Reason", type: "string" },
                                { name: "modified", caption: "Modified", type: "string" },
                                { name: "modified_By", caption: "Modified By", type: "string" }
                            ]}
                        />
                    </WashPlantPileAdjustmentsStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString() },
            pathName: ROUTE.SIL.WASH_PLANT.WASH_PLANT_BIN_ADJUSTMENT
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0" },
            pathName: ROUTE.SIL.WASH_PLANT.WASH_PLANT_BIN_ADJUSTMENT
        });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getDropDownData))
            this.props.getDropDownDataRequest({
                token: this.props.getToken.payload.response.token,
                requested_Page: "BinAdjustment"
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getPileAdjustments))
            this.props.getPileAdjustmentsRequest({
                token: this.props.getToken.payload.response.token
            });
    };

}

const mapStateToProps = (state: IStore): IWashPlantPileAdjustmentsStoreProps => ({
    getToken: getTokenStatus(state),
    getPileAdjustments: getWashPlantBinAdjustmentsStatus(state),
    getDropDownData: getWashPlantProductLookupsStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IWashPlantPileAdjustmentsDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getPileAdjustmentsRequest: (data: IGetWashPlantBinAdjustmentsRequest) => dispatch(getWashPlantBinAdjustmentsLoadAction(data)),
    getDropDownDataRequest: (data: IGetWashPlantProductionLookupsRequest) => dispatch(getWashPlantProductLookupsLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WashPlantPileAdjustments);