import { IPassTokenWithRequest } from "../../../sil/dryPlant/token/getTokenConstants";

export enum IGET_SALES_ORDER_NUMBER_BY_CUSTOMER_ID_REQUEST {
    REQUEST = "getSalesOrderNumbersByCustomerID/GET_SALES_ORDER_NUMBER_BY_CUSTOMER_ID_REQUEST",
    SUCCESS = "getSalesOrderNumbersByCustomerID/GET_SALES_ORDER_NUMBER_BY_CUSTOMER_ID_SUCCESS",
    FAILED = "getSalesOrderNumbersByCustomerID/GET_SALES_ORDER_NUMBER_BY_CUSTOMER_ID_FAILED"
};

export interface IGetSalesOrderNumbersByCustomerIDRequest extends IPassTokenWithRequest {
    request: {
        Customer_ID: number;
    };
};

export interface ISalesOrderNumbersByCustomerID {
  id: number;
  sales_Order_Number: string;
  created_By: string;
  modified_By: string;
  created: string;
  modified: string;
  customer_ID: number;
};