import DatePicker from "@mui/lab/DatePicker";
import { HasClass } from "./publicInterfaces";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TextField from "@mui/material/TextField";
import { useState } from "react";

interface ILADateTimePickerProps extends HasClass {
    name: string;
    label: string;
    value: string;
    disabled?: true;
    fullWidth?: true;
    errorText?: string;
    disableFuture?: true;
    onChange: (name: string, date: any) => void;
}

export const LADateTimePicker = (props: ILADateTimePickerProps): JSX.Element => {
    const onChange = (date: any) => props.onChange(props.name, date);
    const [open, setOpen] = useState(false);

    return <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
            label={props.label}
            value={props.value}
            onChange={onChange}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            disableFuture={props.disableFuture}
            renderInput={(params: any) => <TextField
                {...params}
                autoComplete="off"
                variant="standard"
                color="secondary"
                name={props.name}
                fullWidth={props.fullWidth}
                disabled={props.disabled}
                helperText={props.errorText}
                onClick={(e) => setOpen(true)}
                error={props.errorText ? true : false}
            />}
        />
    </LocalizationProvider>;
};
