import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, hasPayload, loading, notLoaded, Server, succeeded } from "../../../server";
import { IGET_EMPLOYEES_REQUEST, IEmployees } from "./getEmployeesConstaints";
import { IUpdateEmployeeSuccessAction } from "../updateEmployee/updateEmployeeActions";
import { IAddEmployeeSuccessAction } from "../addEmployee/addEmployeeActions";
import { IUPDATE_EMPLOYEE_REQUEST } from "../updateEmployee/updateEmployeeConstaints";
import { IADD_EMPLOYEE_REQUEST } from "../addEmployee/addEmployeeConstaints";
import { IGetEmployeesLoadAction, IGetEmployeesLoadFailedAction, IGetEmployeesSuccessAction } from "./getEmployeesActions";

type Actions =
    IGetEmployeesLoadAction
    | IGetEmployeesLoadFailedAction
    | IGetEmployeesSuccessAction
    | IAddEmployeeSuccessAction
    | IUpdateEmployeeSuccessAction
    | IFlushDataSuccessAction;

export const GetEmployeesReducer = (state: Server<SilAPIResponse<IEmployees[]>> = notLoaded, action: Actions): Server<SilAPIResponse<IEmployees[]>> => {
    switch (action.type) {
        case IGET_EMPLOYEES_REQUEST.REQUEST:
            return loading;

        case IGET_EMPLOYEES_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_EMPLOYEES_REQUEST.FAILED:
            return failed(action.message);

        case IUPDATE_EMPLOYEE_REQUEST.SUCCESS:

            if (hasPayload(state)) {
                const currentState = [...state.payload.response];
                const ind = currentState.findIndex(x => x.id === action.data.id);

                if (ind >= 0)
                    currentState[ind] = action.data;

                return {
                    ...state,
                    payload: {
                        ...state.payload,
                        response: currentState
                    }
                };
            }
            return notLoaded;

        case IADD_EMPLOYEE_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};