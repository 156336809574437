import React from 'react';
import {Nav, NavDropdown} from 'react-bootstrap';
import '../styling/navBar.css';
import {BsList} from 'react-icons/bs';
import { Chip, Grid, TextField, Typography } from '@material-ui/core';
//import {getSIMOSUserGroupAccess} from '../../../components/propsense/simos/APICalls/getSIMOSGroupAccess';

interface NavProps{
    handleRoute: any;
}
//var adminAccess:boolean = false;

export default class NavigationMenu extends React.Component<NavProps>{
    constructor(props:NavProps) {
        super(props);
    }

    // async componentDidMount(){
    //     var response:any = await getSIMOSUserGroupAccess();
    //     adminAccess = response.role === 'SuperAdmin';
    //     this.setState({})
    // }

    render() {
        return (
            <div id='nav-bar-fixed'>
                <Grid container>
                    <Grid item xs={3}>
            <Nav id='nav-bar' variant="pills" activeKey="1" onSelect ={this.props.handleRoute}>
            <NavDropdown title={<BsList/>} id="nav-dropdown-icon">
                <div id='nav-dropdown-content'>
                    <NavDropdown.Item  id='nav-bar-item' eventKey="SIL Home" href="https://sharepoint.sureway.ca/sil/Pages/SIL_Home.aspx">Sil SharePoint</NavDropdown.Item>
               
                    <NavDropdown.Item id='nav-bar-item'eventKey="Home" >SIMOS Orders</NavDropdown.Item>
        
                   <NavDropdown.Item id='nav-bar-item'eventKey="CustomerContact">SIMOS Customer Contacts</NavDropdown.Item>

                   <NavDropdown.Item  id='nav-bar-item' eventKey="Customers">SIMOS Customers</NavDropdown.Item>

                   <NavDropdown.Item  id='nav-bar-item' eventKey="TransportCompany">SIMOS Transport Companies</NavDropdown.Item>

                   <NavDropdown.Item  id='nav-bar-item' eventKey="Products">SIMOS Products</NavDropdown.Item>

               </div>
           </NavDropdown>
            </Nav></Grid><Grid item xs={3}><Typography style={{color:'white',  fontSize:'20px', backgroundColor:'#292c2f', borderTop:'solid 2px rgb(153, 153, 153)'}}>Menu</Typography></Grid></Grid>
            </div>
        );
    }
}