import { IAddYHDCarrierEmailRequest, IADD_YHD_CARRIER_EMAIL_REQUEST } from "./addCarrierEmailConstaints";

export interface IAddYHDCarrierEmailLoadAction {
    type: IADD_YHD_CARRIER_EMAIL_REQUEST.REQUEST;
    data: IAddYHDCarrierEmailRequest
}
export const addCarrierEmailLoadAction = (data: IAddYHDCarrierEmailRequest): IAddYHDCarrierEmailLoadAction => ({
    type: IADD_YHD_CARRIER_EMAIL_REQUEST.REQUEST,
    data
});

export interface IAddYHDCarrierEmailSuccessAction {
    type: IADD_YHD_CARRIER_EMAIL_REQUEST.SUCCESS;
    message: string;
}
export const addYHDCarrierEmailLoadSuccessAction = (message: string): IAddYHDCarrierEmailSuccessAction => ({
    type: IADD_YHD_CARRIER_EMAIL_REQUEST.SUCCESS,
    message
});

export interface IAddYHDCarrierEmailLoadFailedAction {
    type: IADD_YHD_CARRIER_EMAIL_REQUEST.FAILED;
    message: string;
}
export const addYHDCarrierEmailLoadFailedAction = (message: string): IAddYHDCarrierEmailLoadFailedAction => ({
    type: IADD_YHD_CARRIER_EMAIL_REQUEST.FAILED,
    message
});
