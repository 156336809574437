import { IGET_CHEMICAL_ANALYSES_REQUEST, IChemicalAnalyses } from "./getChemicalAnalysesConstaints";
import { IGetChemicalAnalysesLoadAction, IGetChemicalAnalysesLoadFailedAction, IGetChemicalAnalysesSuccessAction } from "./getChemicalAnalysesActions";
import { Server, failed, loading, notLoaded, succeeded } from "../../../../server";
import { SilAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../../flushData.tsx/flushDataConstants";
import { IAddChemicalAnalysesSuccessAction } from "../addChemicalAnalyses/addChemicalAnalysesActions";
import { IUpdateChemicalAnalysesSuccessAction } from "../updateChemicalAnalyses/updateChemicalAnalysesActions";
import { IADD_CHEMICAL_ANALYSES_REQUEST } from "../addChemicalAnalyses/addChemicalAnalysesConstaints";
import { IUPDATE_CHEMICAL_ANALYSES_REQUEST } from "../updateChemicalAnalyses/updateChemicalAnalysesConstaints";

type Actions =
    IGetChemicalAnalysesLoadAction
    | IGetChemicalAnalysesLoadFailedAction
    | IGetChemicalAnalysesSuccessAction
    | IAddChemicalAnalysesSuccessAction
    | IUpdateChemicalAnalysesSuccessAction
    | IFlushDataSuccessAction;

export const GetChemicalAnalysesReducer = (state: Server<SilAPIResponse<IChemicalAnalyses[]>> = notLoaded, action: Actions): Server<SilAPIResponse<IChemicalAnalyses[]>> => {
    switch (action.type) {
        case IGET_CHEMICAL_ANALYSES_REQUEST.REQUEST:
            return loading;

        case IGET_CHEMICAL_ANALYSES_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_CHEMICAL_ANALYSES_REQUEST.FAILED:
            return failed(action.message);

        case IADD_CHEMICAL_ANALYSES_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_CHEMICAL_ANALYSES_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};