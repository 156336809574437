export enum IGET_CREWS_REQUEST {
    REQUEST = "getCrews/GET_CREWS_REQUEST",
    SUCCESS = "getCrews/GET_CREWS_SUCCESS",
    FAILED = "getCrews/GET_CREWS_FAILED"
};

export interface ICrew {
    crew_ID: number;
    name: string,
    job_Level: string;
    country: string;
    latitude: string;
    longitude: string;
    email: string;
    phone_No: string;
    active: string;
};