import { IFlushDataSuccessAction } from "../../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../../flushData.tsx/flushDataConstants";
import { Server, failed, loading, notLoaded, succeeded } from "../../../../server";
import { IUpdateWarehouseLoadAction, IUpdateWarehouseLoadFailedAction, IUpdateWarehouseSuccessAction } from "./updateWarehouseActions";
import { IUPDATE_WAREHOUSE_REQUEST } from "./updateWarehouseConstaints";


type Actions =
    IUpdateWarehouseLoadAction
    | IUpdateWarehouseLoadFailedAction
    | IUpdateWarehouseSuccessAction
    | IFlushDataSuccessAction;

export const UpdateWarehouseReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IUPDATE_WAREHOUSE_REQUEST.REQUEST:
            return loading;

        case IUPDATE_WAREHOUSE_REQUEST.SUCCESS:
            return succeeded("Success");

        case IUPDATE_WAREHOUSE_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};