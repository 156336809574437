import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IGetProductionLookupsLoadAction, IGetProductionLookupsLoadFailedAction, IGetProductionLookupsSuccessAction, 
    getProductionLookupsLoadFailedAction, getProductionLookupsLoadSuccessAction
} from "./getProductionLookupsActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IGET_PRODUCTION_LOOKUPS_REQUEST, IProductionLookups } from "./getProductionLookupsConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";

export const getProductionLookupsEpic: Epic = (
    action$: ActionsObservable<IGetProductionLookupsLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IGetProductionLookupsSuccessAction | IGetProductionLookupsLoadFailedAction> =>
    action$.ofType(IGET_PRODUCTION_LOOKUPS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<IProductionLookups>>(
                    END_POINTS.PROPSENSE.SIL.DRY_PLANT.GET_DRY_PLANT_PRODUCTION_LOOKUPS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<IProductionLookups>): IGetProductionLookupsSuccessAction => {
                            return getProductionLookupsLoadSuccessAction(response);
                        }),
                        catchError(() => ActionsObservable.of(getProductionLookupsLoadFailedAction("Unable to get production lookups")))
                    )
            )
        );