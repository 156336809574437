import React from 'react';
import NavigationMenu from '../../../../../shared/simos/helper/NavigationMenu';
//import InternalCustomerCreate from './internalCustomerCreate';
import AuthenticateLogin from '../../LoginPage/AuthenticateLogin';
import ProductHistoryTable from './productHistoryTable';
import {getSIMOSUserGroupAccess} from '../../APICalls/getSIMOSGroupAccess';
import urlContainsSILParameter, { getSILURLParams, redirectSIMOSHome } from '../../../../../shared/simos/helper/getURLContainsSIL';


interface IState {
    showCreateCustomer:boolean;
    customer:string[];
}
interface IProps {
    history:any;
}

export default class ProductHistoryPage extends React.Component<IProps,IState>{
    constructor(props:any) {
        super(props);
        this.state = {showCreateCustomer:false,customer:[]}
    }

    handleRoute (eventKey:string){
        switch(eventKey) {
            case 'Home':
             this.props.history.push('/simos/orders' + getSILURLParams());
             break;
         case 'CustomerContact':
             this.props.history.push('/simos/viewCustomerContacts' + getSILURLParams());
             break;
        case 'Customers':
            this.props.history.push('/simos/customers' + getSILURLParams());
            break;
         case 'TransportCompany':
               this.props.history.push('/simos/transport-companies' + getSILURLParams())
             break;
        case 'OrderHistory':
            this.props.history.push('/simos/history/orders' + getSILURLParams());
            break;
        case 'ProductHistory':
            this.props.history.push('/simos/history/products' + getSILURLParams());
            break;
        case 'ContactHistory':
            this.props.history.push('/simos/history/contacts' + getSILURLParams());
            break;
        }
    }

    handleLogout(){
        //Auth.logout();
        this.props.history.replace('/simos');
    }
    createNewCustomer() {
        this.props.history.push('/simos/customers/createcustomer');
        //return <InternalCustomerCreate history={this.props.history}></InternalCustomerCreate>
    }
    async componentWillMount(){
        var response:any = await getSIMOSUserGroupAccess();
        var userRole:any = response.role;
        if(userRole!='SuperAdmin'){
            this.props.history.push('/simos');
        }
        if(!urlContainsSILParameter()){
            redirectSIMOSHome();
        }
    }

    render() {
        return (
            <div>
            <header className={getSILURLParams()?.includes("bru")?"header-fixed":"header-fixed-sil-ed"}> 
                <NavigationMenu handleRoute={this.handleRoute.bind(this)}></NavigationMenu>
                <div className="header-limiter"> 
                    <h2>SIMOS<span> Product Version History</span><button type="submit" id="logout-btn" onClick={this.handleLogout.bind(this)}>Home</button>
                    </h2>
                </div>
            </header>
            <div className="data-grid-fixed">
                <ProductHistoryTable history={this.props.history}></ProductHistoryTable>
            </div>
            </div>
        );
    }
}