import { IAssignedCrew } from "../../../../../../redux/propsense/catapult/crew_sheduling/getAssignedCrews/getAssignedCrewsConstants";
import { ICrew } from "../../../../../../redux/propsense/catapult/crew_sheduling/getCrews/getCrewsConstants";
import { IREQUIRED_CREW } from "../../../../../../redux/propsense/catapult/crew_sheduling/getJobs/getJobsConstants";
import { MoonIcon, SunIcon } from "../../../../../shared/icons";
import { LIGHT_BLUE_COLOR, YELLOW_COLOR } from "../../../../../shared/theme";
import { IRenderCrewRequirementProps } from "./renderCrewRequirement";

export const findCrew = (ac: IAssignedCrew, rc: IREQUIRED_CREW, orderId?: number): boolean => {
    if ((ac.shift === rc.shift)
        && (ac.crew_Shift_ID === rc.crew_Shift_ID)
        && (ac.order_ID === orderId)
        // && (new Date(ac.Start_Date) >= new Date(rc.From_Date)) //This One
        // && (new Date(ac.End_Date) <= new Date(rc.To_Date)) //This One
    ) {
        return true;
    } else {
        return false;
    };
};

export const sortRequiredCrewByDate = (a: IREQUIRED_CREW, b: IREQUIRED_CREW): any => {
    return (a.from_Date > b.from_Date);
};

export const shiftIcon = (value: string): JSX.Element => {
    if (value === "Night")
        return <MoonIcon color={LIGHT_BLUE_COLOR} />

    return <SunIcon color={YELLOW_COLOR} />
};

export const crewCheck = (ac: IAssignedCrew[], rc: IREQUIRED_CREW, crewList: ICrew[], pos: string, orderId?: number): number => {
    let count = 0;

    new Promise((resolve: any, reject: any): any => {
        ac.forEach((x, index) => {
            if (findCrew(x, rc, orderId)) {
                let findCrew = crewList.find(a => a.crew_ID === x.crew_ID);
                if (findCrew)
                    if (findCrew.job_Level === pos) {
                        count++;
                    }
            };

            if (index + 1 === ac.length) {
                resolve(count);
            };
        });
    })

    return count;
};

export const totalRequiredCrew = (rc: IREQUIRED_CREW): number => {
    let count = 0;

    count = count + rc.super;
    count = count + rc.operator_Level1;
    count = count + rc.operator_Level2;
    count = count + rc.operator_Level3;

    return count;
};

export const getCountError = (props: IRenderCrewRequirementProps): boolean => {
    let response = false;

    if (crewCheck(props.assignedCrew, props.rc, props.crewList, "Supervisor", props.orderId) !== props.rc.super)
        response = true;

    if (crewCheck(props.assignedCrew, props.rc, props.crewList, "Operator_Level1", props.orderId) !== props.rc.operator_Level1)
        response = true;

    if (crewCheck(props.assignedCrew, props.rc, props.crewList, "Operator_Level2", props.orderId) !== props.rc.operator_Level2)
        response = true;

    if (crewCheck(props.assignedCrew, props.rc, props.crewList, "Operator_Level3", props.orderId) !== props.rc.operator_Level3)
        response = true;

    return response;
};

export const crewsRequiredByPosition = (ac: IAssignedCrew[], rc: IREQUIRED_CREW, cl: ICrew[], orderId?: number): string[] => {

    let countSup = 0;
    let countOp1 = 0;
    let countOp2 = 0;
    let countOp3 = 0;

    let errors: string[] = [];

    ac.forEach((a) => {
        if (orderId === a.order_ID && a.crew_Shift_ID === rc.crew_Shift_ID) {
            const crew = cl.find(q => q.crew_ID === a.crew_ID);

            if (crew?.job_Level === "Supervisor") {
                countSup++;
            }

            if (crew?.job_Level === "Operator_Level1") {
                countOp1++;
            }

            if (crew?.job_Level === "Operator_Level2") {
                countOp2++;
            }

            if (crew?.job_Level === "Operator_Level3") {
                countOp3++;
            }
        }
    });

    if (rc.super !== countSup) {
        if (rc.super < countSup) {
            errors.push("Number of Supervisors exceeds the required count");
        };
        if (rc.super > countSup) {
            errors.push("Number of Supervisors less than the required count");
        };
    };

    if (rc.operator_Level1 !== countOp1) {
        if (rc.operator_Level1 < countOp1) {
            errors.push("Number of Operator Level 1 exceeds the required count");
        };
        if (rc.operator_Level1 > countOp1) {
            errors.push("Number of Operator Level 1 less than the required count");
        };
    };

    if (rc.operator_Level2 !== countOp2) {
        if (rc.operator_Level2 < countOp2) {
            errors.push("Number of Operator Level 2 exceeds the required count");
        };
        if (rc.operator_Level2 > countOp2) {
            errors.push("Number of Operator Level 2 less than the required count");
        };
    };

    if (rc.operator_Level3 !== countOp3) {
        if (rc.operator_Level3 < countOp3) {
            errors.push("Number of Operator Level 3 exceeds the required count");
        };
        if (rc.operator_Level3 > countOp3) {
            errors.push("Number of Operator Level 3 less than the required count");
        };
    };

    return errors;
};

export interface ITimeHoles {
    from: string;
    to: string;
    crewJob: string;
    requiredIndex: number;
};


export const getDatesBetweenDates = (startDate: string, endDate: string): Date[] => {
    let dates: Date[] = []
    //to avoid modifying the original date
    const theDate = new Date(startDate);
    while (theDate < new Date(endDate)) {
        dates = [...dates, new Date(theDate)]
        theDate.setDate(theDate.getDate() + 1)
    }
    dates = [...dates, new Date(endDate)]
    return dates
};