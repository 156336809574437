import { IBeltScale } from "../getBeltScales/getBeltScalesConstaints";
import { IUpdateBeltScaleRequest, IUPDATE_BELT_SCALE_REQUEST } from "./updateBeltScaleConstaints";

export interface IUpdateBeltScaleLoadAction {
    type: IUPDATE_BELT_SCALE_REQUEST.REQUEST;
    data: IUpdateBeltScaleRequest
}
export const updateBeltScaleLoadAction = (data: IUpdateBeltScaleRequest): IUpdateBeltScaleLoadAction => ({
    type: IUPDATE_BELT_SCALE_REQUEST.REQUEST,
    data
});

export interface IUpdateBeltScaleSuccessAction {
    type: IUPDATE_BELT_SCALE_REQUEST.SUCCESS;
    data: IBeltScale;
}
export const updateBeltScaleLoadSuccessAction = (data: IBeltScale): IUpdateBeltScaleSuccessAction => ({
    type: IUPDATE_BELT_SCALE_REQUEST.SUCCESS,
    data
});

export interface IUpdateBeltScaleLoadFailedAction {
    type: IUPDATE_BELT_SCALE_REQUEST.FAILED;
    message: string;
}
export const updateBeltScaleLoadFailedAction = (message: string): IUpdateBeltScaleLoadFailedAction => ({
    type: IUPDATE_BELT_SCALE_REQUEST.FAILED,
    message
});
