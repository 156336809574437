import { SilAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { IGetBaggedInventoriesRequest, IGET_BAGGED_INVENTORIES_REQUEST, IBaggedInventories } from "./getBaggedInventoriesConstaints";

export interface IGetBaggedInventoriesLoadAction {
    type: IGET_BAGGED_INVENTORIES_REQUEST.REQUEST;
    data: IGetBaggedInventoriesRequest
}
export const getBaggedInventoriesLoadAction = (data: IGetBaggedInventoriesRequest): IGetBaggedInventoriesLoadAction => ({
    type: IGET_BAGGED_INVENTORIES_REQUEST.REQUEST,
    data
});

export interface IGetBaggedInventoriesSuccessAction {
    type: IGET_BAGGED_INVENTORIES_REQUEST.SUCCESS;
    data: SilAPIResponse<IBaggedInventories[]>;
}
export const getBaggedInventoriesLoadSuccessAction = (data: SilAPIResponse<IBaggedInventories[]>): IGetBaggedInventoriesSuccessAction => ({
    type: IGET_BAGGED_INVENTORIES_REQUEST.SUCCESS,
    data
});

export interface IGetBaggedInventoriesLoadFailedAction {
    type: IGET_BAGGED_INVENTORIES_REQUEST.FAILED;
    message: string;
}
export const getBaggedInventoriesLoadFailedAction = (message: string): IGetBaggedInventoriesLoadFailedAction => ({
    type: IGET_BAGGED_INVENTORIES_REQUEST.FAILED,
    message
});
