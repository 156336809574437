import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IComplaintLogging, IGET_COMPLAINT_LOGGING_REQUEST, IGetComplaintLoggingRequest } from "./getComplaintLoggingConstaints";

export interface IGetComplaintLoggingLoadAction {
    type: IGET_COMPLAINT_LOGGING_REQUEST.REQUEST;
    data: IGetComplaintLoggingRequest
}
export const getComplaintLoggingLoadAction = (data: IGetComplaintLoggingRequest): IGetComplaintLoggingLoadAction => ({
    type: IGET_COMPLAINT_LOGGING_REQUEST.REQUEST,
    data
});

export interface IGetComplaintLoggingSuccessAction {
    type: IGET_COMPLAINT_LOGGING_REQUEST.SUCCESS;
    data: SilAPIResponse<IComplaintLogging[]>;
}
export const getComplaintLoggingLoadSuccessAction = (data: SilAPIResponse<IComplaintLogging[]>): IGetComplaintLoggingSuccessAction => ({
    type: IGET_COMPLAINT_LOGGING_REQUEST.SUCCESS,
    data
});

export interface IGetComplaintLoggingLoadFailedAction {
    type: IGET_COMPLAINT_LOGGING_REQUEST.FAILED;
    message: string;
}
export const getComplaintLoggingLoadFailedAction = (message: string): IGetComplaintLoggingLoadFailedAction => ({
    type: IGET_COMPLAINT_LOGGING_REQUEST.FAILED,
    message
});
