export enum IGET_JOBS_REQUEST {
    REQUEST = "getJobs/GET_JOBS_REQUEST",
    SUCCESS = "getJobs/GET_JOBS_SUCCESS",
    FAILED = "getJobs/GET_JOBS_FAILED"
};

export interface IJob {
    country: string;
    order_ID: number;
    location: string,
    latitude: string;
    longitude: string;
    start_Date: string;
    end_Date: string;
    customer: string;
    po: string;
    operator: string | null;
    required_Crews: IREQUIRED_CREW[];
    error?: boolean;
    errors: string[];
};

export interface IREQUIRED_CREW {
    crew_Shift_ID: number;
    shift: string;
    from_Date: string;
    to_Date: string;
    super: number;
    operator_Level1: number;
    operator_Level2: number;
    operator_Level3: number;
};

export interface IGetRequestBody {
    request: null;
    token: string;
};