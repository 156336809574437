import React from 'react';
import {copyedit} from './internalCustomerTable';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import {customer_id} from './internalCustomerTable';
import CreateDeliveryAddressPopup from './internalCustomerAddressPopup'; 
import {FaPencilAlt} from 'react-icons/fa';
import {deleteSIMOSCustomerAddress, updateSIMOSCustomerAddress} from '../APICalls/updateCustomer';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@mui/material/TextField';
import Editor from 'devextreme/ui/editor/editor';

interface IProps{
    closePopup:Function;
    delivery:any[];
}
interface IState{
    open:boolean;
    showCustomerPopup:boolean;
    showDeletePopup:boolean;
    deleteIndex:number;
    showEditPopup:boolean;
}

const BoxStyle:any = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: 'rgb(241, 241, 241)',
    border: '2px solid #000',
    boxShadow: 24,
    padding: '4ch'
};

const ExitButtonStyle = {
    bgcolor: 'rgb(174, 180, 184)',
    color: 'white',
    fontWeight: 'bold',
    left:'94%'
}
var editObject = {id:0,address:'', modified_By:''};
var allAddresses:any = [];
export default class CustomerDeliveryPopup extends React.Component<IProps,IState>{
    constructor(props:IProps){
        super(props);
        this.state = {
            open:true, showCustomerPopup:false, showDeletePopup:false, deleteIndex:0, showEditPopup:false
        }
    }
    saveDeliveryPopup(){
        this.setState({showCustomerPopup:false});
        window.location.reload();
    }
    closePopup(){
        this.props.closePopup();
    }
    closeDeliveryPopup(){
        this.setState({showCustomerPopup:false});
    }
  
    handleNewDeliveryPopup(){
        this.setState({showCustomerPopup:true});
    }
    handleAddressDelete(elem:any){
        this.setState({deleteIndex:elem.id,showDeletePopup: true});
    }
    handleAddressDeletePopupCancel(){
        this.setState({showDeletePopup:false});
    }
    async handleAddressDeleteSave(){
        if(this.state.deleteIndex!=null && this.state.deleteIndex!=0){
            var response:any = await deleteSIMOSCustomerAddress(this.state.deleteIndex);
            if(response.status === 200){
                alert("Successfully delete customer address");
                this.setState({showDeletePopup: !this.state.showDeletePopup});
                //this.props.closePopup();
                window.location.reload();
            }
            else{
                alert("Error deleting customer address: server error");
            }
        }
        else{
            alert("Updating id error: cannot delete. try refreshing page");
        }
    }
    handleAddressEdit(elem:any,event:any){
        editObject.address = elem.address;
        editObject.id = elem.id;
        this.setState({showEditPopup:true});
    }
    async handleEditSave(){
        var isUnique:boolean = true;
        if(editObject.address != "" && editObject.address !=null){
            allAddresses.map((address:any) => {
                if(address.address === editObject.address){
                    isUnique = false;
                }
            });
            if(isUnique){
                var response:any = await updateSIMOSCustomerAddress(editObject.id, editObject.address);
                if(response.status === 200){
                    alert("Successfully updated customer delivery address");
                    this.setState({showEditPopup:false});
                    window.location.reload();
                }
                else{
                    alert("Could not update customer delivery address: server error");
                }
            }
            else {
                alert("Customer delivery address already exists")
            }
        }
    }
    handleEditCancel(){
        this.setState({showEditPopup:false});
    }
    onTextChange(event:any){
        switch(event.target.name){
            case 'delivery_address':
                editObject.address = event.target.value;
                break;
        }
    }

    componentDidMount(){
        this.props.delivery.map((each) => {
            if(customer_id === each.customer_ID){
                allAddresses.push(each);
            }
        })
    }
    
    render(){
        return (
        <>
            <Modal id='warehouse-emp-modal'
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state.open}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}
                    
                  ><Box sx={BoxStyle}>  
                    <Button sx={ExitButtonStyle} onClick={this.closePopup.bind(this)}>X</Button>    
                    <br></br><br></br>
                     <TableContainer component={Paper}>
                    <Table size="small" aria-label="Product info">
                            <TableHead>
                            <TableRow>
                                <TableCell>Edit</TableCell>
                                <TableCell>Address</TableCell>
                                <TableCell align="right">Created</TableCell>
                                <TableCell align="right">Created By</TableCell>
                                <TableCell align="right">Modified</TableCell>
                                <TableCell align="right">Modified By</TableCell>
                                <TableCell align="right">Delete</TableCell>
                            </TableRow>
                        </TableHead>
                        {this.props.delivery.map((delivery) => { 
                        if(customer_id === delivery.customer_ID){
                        return <TableBody>
                            <TableRow>
                                <TableCell><Button  onClick={this.handleAddressEdit.bind(this, delivery)}><FaPencilAlt/></Button></TableCell>
                                <TableCell>{delivery.address}</TableCell>
                                <TableCell align="right">{delivery.created.slice(0,10)}</TableCell>
                                <TableCell align="right">{delivery.created_By}</TableCell>
                                <TableCell align="right">{delivery.modified.slice(0,10)}</TableCell>
                                <TableCell align="right">{delivery.modified_By}</TableCell>
                                <TableCell align="right"><Button className={"del-customer-btn"} onClick={this.handleAddressDelete.bind(this, delivery)}>X</Button></TableCell>
                            </TableRow>
                        </TableBody>
                           }
                        })     }
                        </Table> 
                        
                </TableContainer>  
                <br></br>
                <Button style={{backgroundColor:"white"}} variant="outlined" onClick={this.handleNewDeliveryPopup.bind(this)}>Add new</Button>
                {this.state.showCustomerPopup ? 
                <CreateDeliveryAddressPopup customerID={customer_id} optionItems={this.props.delivery} closePopup={this.closeDeliveryPopup.bind(this)} savePopup={this.saveDeliveryPopup.bind(this)}/> :null}
    
                {this.state.showDeletePopup === true ? 
                 <Modal id='warehouse-emp-modal'
                 aria-labelledby="transition-modal-title"
                 aria-describedby="transition-modal-description"
                 open={this.state.open}
                 closeAfterTransition
                 BackdropComponent={Backdrop}
                 BackdropProps={{
                   timeout: 500,
                 }}
                >
                 <Fade in={this.state.open}>
                   <Box sx={BoxStyle}>
                     <Typography variant="h6" component="h2">
                       Are you sure you want to delete this customer address? <div style={{color:'red'}}>Warning: This action cannot be undone.</div>
                     </Typography>
                 <br></br>
                     <Grid container spacing={6} direction='row'>
                         <Grid item xs={6}>
                     <Button variant="outlined" className="cancel-employee-save" onClick={this.handleAddressDeletePopupCancel.bind(this)}>X</Button>
                     </Grid>
                     <Grid item xs={6}>
                     <Button variant="outlined" className="save-employee" onClick={this.handleAddressDeleteSave.bind(this)} >Confirm</Button>
                     </Grid>
                     </Grid>
                   </Box>
                 </Fade>
               </Modal>
                :null}

                {this.state.showEditPopup ? <Modal id='warehouse-emp-modal'
                 aria-labelledby="transition-modal-title"
                 aria-describedby="transition-modal-description"
                 open={this.state.open}
                 closeAfterTransition
                 BackdropComponent={Backdrop}
                 BackdropProps={{
                   timeout: 500,
                 }}
               >
                 <Fade in={this.state.open}>
                   <Box sx={BoxStyle}>
                     <Typography variant="h6" component="h2">
                       Editing Customer Delivery address <div style={{color:'orange'}}>{editObject.id}</div>
                     </Typography>
                 <br></br>
                     <Grid container spacing={6} direction='row'>
                        <Grid item xs={12}>
                            <TextField label="Delivery Address" defaultValue={editObject.address}id={'outlined-error-helper-text'} name="delivery_address" onChange={this.onTextChange.bind(this)}></TextField>
                        </Grid>
                         <Grid item xs={6}>
                     <Button variant="outlined" id="cancel-contact-form-btn" onClick={this.handleEditCancel.bind(this)}>Cancel</Button>
                     </Grid>
                     <Grid item xs={6}>
                     <Button variant="outlined" id="submit-contact-form-btn" onClick={this.handleEditSave.bind(this)} >Save</Button>
                     </Grid>
                     </Grid>
                   </Box>
                 </Fade>
               </Modal>
               :null}

          </Box>
            </Modal>
        </>
              
        );
    }
}