import { ICarrierForm } from "../../../../react/components/sil/carriers/carrierForm";
import { IPassTokenWithRequest } from "../../dryPlant/token/getTokenConstants";

export enum IGET_CARRIERS_REQUEST {
    REQUEST = "getCarriers/GET_CARRIERS_REQUEST",
    SUCCESS = "getCarriers/GET_CARRIERS_SUCCESS",
    FAILED = "getCarriers/GET_CARRIERS_FAILED"
};

export interface IGetCarriersRequest extends IPassTokenWithRequest {

};

export interface ICarrier extends ICarrierForm {
    // id: number;
    // name: string;
    // alternate_Name: string;
    // active: string;
    // approved: string;
    // approved_Date: string;
    // unApproved_Reason: string;
    // uses_Sil_Trailers: string;
    // mailing_Address: string;
    // city: string;
    // province: string;
    // postal_Code: string;
    // phone: string;
    // email: string;
    // service_Email: string;
    // notes: string;
    // owner_Name: string;
    // owner_Email: string;
    // owner_Phone: string;
    // registration_Form: string;
    // registration_Form_Name: string;
    // dispatch_Contact_Name: string;
    // dispatch_Email: string;
    // dispatch_Phone_Cell: string;
    // dispatch_Phone_Work: string;
    // dispatch_Contact_After_Hours: string;
    // dispatch_Phone_After_Hours: string;
    // truck_Code_Out: string;
    // truck_Code_IN: string;
    // created: string;
    // created_By: string;
    // modified: string;
    // modified_By: string;
};