import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IAddSieveCalibrationLoadAction, IAddSieveCalibrationLoadFailedAction, IAddSieveCalibrationSuccessAction, 
    addSieveCalibrationLoadFailedAction, addSieveCalibrationLoadSuccessAction
} from "./addSieveCalibrationActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IADD_SIEVE_CALIBRATION_REQUEST } from "./addSieveCalibrationConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";

export const addSieveCalibrationEpic: Epic = (
    action$: ActionsObservable<IAddSieveCalibrationLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IAddSieveCalibrationSuccessAction | IAddSieveCalibrationLoadFailedAction> =>
    action$.ofType(IADD_SIEVE_CALIBRATION_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<string>>(
                    END_POINTS.PROPSENSE.SIL.SIEVES.ADD_SIEVES_CALIBRATION,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<string>): IAddSieveCalibrationSuccessAction | IAddSieveCalibrationLoadFailedAction => {
                            if(response.message === "Success"){
                                return addSieveCalibrationLoadSuccessAction(response.message);
                            };
                            return addSieveCalibrationLoadFailedAction(response.message)
                        }),
                        catchError(() => ActionsObservable.of(addSieveCalibrationLoadFailedAction("Unable to add sieves calibrations")))
                    )
            )
        );