import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IUpdateFeedMaterialLoadAction, IUpdateFeedMaterialLoadFailedAction, IUpdateFeedMaterialSuccessAction } from "./updateFeedMaterialActions";
import { IUPDATE_FEED_MATERIAL_REQUEST } from "./updateFeedMaterialConstaints";


type Actions =
    IUpdateFeedMaterialLoadAction
    | IUpdateFeedMaterialLoadFailedAction
    | IUpdateFeedMaterialSuccessAction
    | IFlushDataSuccessAction;

export const UpdateFeedMaterialReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IUPDATE_FEED_MATERIAL_REQUEST.REQUEST:
            return loading;

        case IUPDATE_FEED_MATERIAL_REQUEST.SUCCESS:
            return succeeded("Success");

        case IUPDATE_FEED_MATERIAL_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};