var isUnique:boolean;

function isProductUnique(product_name: string) {

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type':'application/json'},
        body: JSON.stringify({
            request: {Description: product_name},
            token:'19515195-d571-44a3-b0db-637f5bf24f54'})
        }

    fetch('https://api2.propsense.com/api/checkProductExists', requestOptions)
    .then(response => {
        const data = response.headers.get('Content-Type')?.includes('application/json') ;
        // error response
        if(!response.ok) {
            const error = (data) || response.status;
            return Promise.reject(error);
        }  
        // handle validation
        else {
            response.json().then(res => { 
               if(res === true ){ 
                   isUnique = false;
               }
               else {
                   isUnique = true;
               }
            });
        }
    });
}

function checkDuplicateProductInfo(newProduct:any, product_list:any){
    product_list.map((product:any) => {
        if(newProduct.product_name === product.description && newProduct.product_size === product.size && newProduct.product_location === product.location){
            return false;
        }
    });
    return true;
}

export default isProductUnique;
export {isUnique, checkDuplicateProductInfo};