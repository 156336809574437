import { IPassTokenWithRequest } from "../../dryPlant/token/getTokenConstants";
import { IAddQualityControlTesting } from "../addQualityControlTesting/addQualityControlTestingConstaints";

export enum IUPDATE_QUALITY_CONTROL_TESTING_REQUEST {
    REQUEST = "updateQualityControlTesting/UPDATE_QUALITY_CONTROL_TESTING_REQUEST",
    SUCCESS = "updateQualityControlTesting/UPDATE_QUALITY_CONTROL_TESTING_SUCCESS",
    FAILED = "updateQualityControlTesting/UPDATE_QUALITY_CONTROL_TESTING_FAILED"
};

export interface IUpdateQualityControlTestingRequest extends IPassTokenWithRequest {
    request: IAddQualityControlTesting;
};

