import { IPassTokenRequestBody } from "../../../../../react/shared/publicInterfaces";
import { ISimosCustomerAddress } from "../getSimosCustomers/getSimosCustomersConstants";

export enum IADD_SIMOS_CUSTOMER_REQUEST {
    REQUEST = "addSimosCustomer/ADD_SIMOS_CUSTOMER_REQUEST",
    SUCCESS = "addSimosCustomer/ADD_SIMOS_CUSTOMER_SUCCESS",
    FAILED = "addSimosCustomer/ADD_SIMOS_CUSTOMER_FAILED"
};

export interface IAddSimosCustomerRequest extends IPassTokenRequestBody {
    request: IAddUpdateSimosCustomer;
};

export interface IAddUpdateSimosCustomer {
    ID: number;
    Customer: string;
    Active: string;
    On_Hold: string;
    Explorer_Code: string;
    Address?: any;
    Created_By: string;
    Modified_By: string;
}