import { IPassTokenWithRequest } from "../../../../sil/dryPlant/token/getTokenConstants";

export enum IGET_YHD_CARRIER_INFRACTIONS_REQUEST {
    REQUEST = "getYHDCarrierInfractions/GET_YHD_CARRIER_INFRACTIONS_REQUEST",
    SUCCESS = "getYHDCarrierInfractions/GET_YHD_CARRIER_INFRACTIONS_SUCCESS",
    FAILED = "getYHDCarrierInfractions/GET_YHD_CARRIER_INFRACTIONS_FAILED"
};

export interface IGetYHDCarrierInfractionsRequest extends IPassTokenWithRequest {

};

export interface IYHDCarrierInfractions {
    id: number;
    date_Of_Infraction: string;
    infraction_Notification_Method: string;
    alternate_Name: string;
    trailer_No: string;
    license_Plate?: string;
    location: string;
    ticket_No?: string;
    infraction: string;
    carrier_Notification_Provided_To?: string;
    description: string;
    immediate_Action_Taken: string;
    followup_Action_Taken?: string;
    attachment_File_Name?: string;
    attachment_File?: string;
    attachment_Details?: string;
    reported_By_Name?: string;
    contact_No?: string;
    complaint_Recorded_By?: string;
    status: string;
    created: string;
    created_By: string;
    modified: string;
    modified_By: string;
};