import { IPassTokenWithRequest } from "../../dryPlant/token/getTokenConstants";

export enum IGET_SIEVES_CHECKS_REQUEST {
    REQUEST = "getSieveChecks/GET_SIEVES_CHECKS_REQUEST",
    SUCCESS = "getSieveChecks/GET_SIEVES_CHECKS_SUCCESS",
    FAILED = "getSieveChecks/GET_SIEVES_CHECKS_FAILED"
};

export interface IGetSieveChecksRequest extends IPassTokenWithRequest {

};

export interface ISieveCheck {
    id: number;
    date: string;
    operator: string;
    products: string;
    plant: string;
    notes?: string;
    created: string;
    modified: string;
    created_By: string;
    modified_By: string;
    measurements: ISieveCheckMeasurement[];
};

export interface ISieveCheckMeasurement {
    id: number;
    listID: number;
    product: string;
    sieve_Serial_No: string;
    astM_Serial_No: string;
    result: string;
    notes: string;
    created: string;
    modified: string;
    created_By: string;
    modified_By: string;
}