import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IGET_CARRIER_FLEET_TRAILERS_REQUEST, ICarrierFleetTrailer } from "./getCarrierFleetTrailersConstaints";
import { IGetCarrierFleetTrailersLoadAction, IGetCarrierFleetTrailersLoadFailedAction, IGetCarrierFleetTrailersSuccessAction } from "./getCarrierFleetTrailersActions";
import { IUPDATE_CARRIER_FLEET_TRAILER_REQUEST } from "../updateCarrierFleetTrailer/updateCarrierFleetTrailerConstaints";
import { IADD_CARRIER_FLEET_TRAILER_REQUEST } from "../addCarrierFleetTrailer/addCarrierFleetTrailerConstaints";
import { IUpdateCarrierFleetTrailerSuccessAction } from "../updateCarrierFleetTrailer/updateCarrierFleetTrailerActions";
import { IAddCarrierFleetTrailerSuccessAction } from "../addCarrierFleetTrailer/addCarrierFleetTrailerActions";

type Actions =
    IGetCarrierFleetTrailersLoadAction
    | IGetCarrierFleetTrailersLoadFailedAction
    | IGetCarrierFleetTrailersSuccessAction
    | IUpdateCarrierFleetTrailerSuccessAction
    | IAddCarrierFleetTrailerSuccessAction
    | IFlushDataSuccessAction;

export const GetCarrierFleetTrailersReducer = (state: Server<SilAPIResponse<ICarrierFleetTrailer[]>> = notLoaded, action: Actions): Server<SilAPIResponse<ICarrierFleetTrailer[]>> => {
    switch (action.type) {
        case IGET_CARRIER_FLEET_TRAILERS_REQUEST.REQUEST:
            return loading;

        case IGET_CARRIER_FLEET_TRAILERS_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_CARRIER_FLEET_TRAILERS_REQUEST.FAILED:
            return failed(action.message);

        case IUPDATE_CARRIER_FLEET_TRAILER_REQUEST.SUCCESS:
            return notLoaded;

        case IADD_CARRIER_FLEET_TRAILER_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};