import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetEmployeesRequest, IGET_EMPLOYEES_REQUEST, IEmployees } from "./getEmployeesConstaints";

export interface IGetEmployeesLoadAction {
    type: IGET_EMPLOYEES_REQUEST.REQUEST;
    data: IGetEmployeesRequest
}
export const getEmployeesLoadAction = (data: IGetEmployeesRequest): IGetEmployeesLoadAction => ({
    type: IGET_EMPLOYEES_REQUEST.REQUEST,
    data
});

export interface IGetEmployeesSuccessAction {
    type: IGET_EMPLOYEES_REQUEST.SUCCESS;
    data: SilAPIResponse<IEmployees[]>;
}
export const getEmployeesLoadSuccessAction = (data: SilAPIResponse<IEmployees[]>): IGetEmployeesSuccessAction => ({
    type: IGET_EMPLOYEES_REQUEST.SUCCESS,
    data
});

export interface IGetEmployeesLoadFailedAction {
    type: IGET_EMPLOYEES_REQUEST.FAILED;
    message: string;
}
export const getEmployeesLoadFailedAction = (message: string): IGetEmployeesLoadFailedAction => ({
    type: IGET_EMPLOYEES_REQUEST.FAILED,
    message
});
