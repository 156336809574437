import { IPassTokenWithRequest } from "../../dryPlant/token/getTokenConstants";

export enum IADD_COMPLAINT_LOGGING_REQUEST {
    REQUEST = "addComplaintLogging/ADD_COMPLAINT_LOGGING_REQUEST",
    SUCCESS = "addComplaintLogging/ADD_COMPLAINT_LOGGING_SUCCESS",
    FAILED = "addComplaintLogging/ADD_COMPLAINT_LOGGING_FAILED"
};

export interface IAddComplaintLoggingRequest extends IPassTokenWithRequest {
    request: IAddComplaintLogging;
};

export interface IAddComplaintLogging {
    Id?: number;
    Date_Of_Complaint: string;
    Method_Of_Complaint: string;
    Complainant_First_Name: string;
    Complainant_Last_Name: string;
    Contact_Number: string;
    Residential_Address: string;
    Email_Address: string;
    Plant: string;
    Company: string;
    Description: string;
    Immediate_Action_Taken: string;
    Suspected_Cause: string;
    Equipment_Running: string;
    Follow_Up_Actions: string;
    Created: string;
    Created_By: string;
    Modified: string;
    Modified_By: string;
};