import { IPassTokenWithRequest } from "../../../../sil/dryPlant/token/getTokenConstants";

export enum IADD_YHD_CARRIER_FLEET_TRUCK_REQUEST {
    REQUEST = "addYHDCarrierFleetTruck/ADD_YHD_CARRIER_FLEET_TRUCK_REQUEST",
    SUCCESS = "addYHDCarrierFleetTruck/ADD_YHD_CARRIER_FLEET_TRUCK_SUCCESS",
    FAILED = "addYHDCarrierFleetTruck/ADD_YHD_CARRIER_FLEET_TRUCK_FAILED"
};

export interface IAddCarrierFleetTruck {
    ASGA_No: string;
    Truck_Type?: string;
    Truck_Registration_No?: string;
    Truck_Registration_Expiry?: string;
    Truck_Registration_File_Name?: string;
    Truck_Registration_File?: string;
    Truck_Inspection_File_Name?: string;
    Truck_Inspection_File?: string;
    Fleet_Trucks?: string;
    Truck_Inspection_Date?: string;
    Trailer_Inspection_File_Name?: string;
    Trailer_Inspection_File?: string;
    Trailer_Inspection_Date?: string;
    Active: string;
    Modified: string;
    Modified_By: string;
    Created: string;
    Created_By: string;
};

export interface IAddYHDCarrierFleetTruckRequest extends IPassTokenWithRequest {
    request: IAddCarrierFleetTruck;
};