import { IWashPlantBinAdjustments } from "../getWashPlantBinAdjustments/getWashPlantBinAdjustmentsConstaints";

export enum IUPDATE_WASH_PLANT_BIN_ADJUSTMENT_REQUEST {
    REQUEST = "updateWashPlantBinAdjustment/UPDATE_WASH_PLANT_BIN_ADJUSTMENT_REQUEST",
    SUCCESS = "updateWashPlantBinAdjustment/UPDATE_WASH_PLANT_BIN_ADJUSTMENT_SUCCESS",
    FAILED = "updateWashPlantBinAdjustment/UPDATE_WASH_PLANT_BIN_ADJUSTMENT_FAILED"
};

export interface IUpdateWashPlantBinAdjustmentRequest {
    token: string;
    request: IWashPlantBinAdjustments;
};