import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IGET_CARRIER_EMAILS_REQUEST, ICarrierEmail } from "./getCarrierEmailsConstaints";
import { IGetCarrierEmailsLoadAction, IGetCarrierEmailsLoadFailedAction, IGetCarrierEmailsSuccessAction } from "./getCarrierEmailsActions";
import { IADD_CARRIER_EMAIL_REQUEST } from "../addCarrierEmail/addCarrierEmailConstaints";
import { IUPDATE_CARRIER_EMAIL_REQUEST } from "../updateCarrierEmail/updateCarrierEmailConstaints";
import { IAddCarrierEmailSuccessAction } from "../addCarrierEmail/addCarrierEmailActions";
import { IUpdateCarrierEmailSuccessAction } from "../updateCarrierEmail/updateCarrierEmailActions";

type Actions =
    IGetCarrierEmailsLoadAction
    | IGetCarrierEmailsLoadFailedAction
    | IGetCarrierEmailsSuccessAction
    | IAddCarrierEmailSuccessAction
    | IUpdateCarrierEmailSuccessAction
    | IFlushDataSuccessAction;

export const GetCarrierEmailsReducer = (state: Server<SilAPIResponse<ICarrierEmail[]>> = notLoaded, action: Actions): Server<SilAPIResponse<ICarrierEmail[]>> => {
    switch (action.type) {
        case IGET_CARRIER_EMAILS_REQUEST.REQUEST:
            return loading;

        case IGET_CARRIER_EMAILS_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_CARRIER_EMAILS_REQUEST.FAILED:
            return failed(action.message);

        case IADD_CARRIER_EMAIL_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_CARRIER_EMAIL_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};