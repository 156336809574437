import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetWashPlantFeedMaterialsRequest, IGET_WASH_PLANT_FEED_MATERIALS_REQUEST, IWashPlantFeedMaterials } from "./getWashPlantFeedMaterialsConstaints";

export interface IGetWashPlantFeedMaterialsLoadAction {
    type: IGET_WASH_PLANT_FEED_MATERIALS_REQUEST.REQUEST;
    data: IGetWashPlantFeedMaterialsRequest
}
export const getWashPlantFeedMaterialsLoadAction = (data: IGetWashPlantFeedMaterialsRequest): IGetWashPlantFeedMaterialsLoadAction => ({
    type: IGET_WASH_PLANT_FEED_MATERIALS_REQUEST.REQUEST,
    data
});

export interface IGetWashPlantFeedMaterialsSuccessAction {
    type: IGET_WASH_PLANT_FEED_MATERIALS_REQUEST.SUCCESS;
    data: SilAPIResponse<IWashPlantFeedMaterials[]>;
}
export const getWashPlantFeedMaterialsLoadSuccessAction = (data: SilAPIResponse<IWashPlantFeedMaterials[]>): IGetWashPlantFeedMaterialsSuccessAction => ({
    type: IGET_WASH_PLANT_FEED_MATERIALS_REQUEST.SUCCESS,
    data
});

export interface IGetWashPlantFeedMaterialsLoadFailedAction {
    type: IGET_WASH_PLANT_FEED_MATERIALS_REQUEST.FAILED;
    message: string;
}
export const getWashPlantFeedMaterialsLoadFailedAction = (message: string): IGetWashPlantFeedMaterialsLoadFailedAction => ({
    type: IGET_WASH_PLANT_FEED_MATERIALS_REQUEST.FAILED,
    message
});
