import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, isSucceeded, Server } from "../../../../redux/server";
import { ById, SilAPIResponse } from "../../../shared/publicInterfaces";
import { userName, undefinedFunction, monthNames, getYearsList, yesOrNoOptions, deleteText } from "../../../shared/constExports";
import { LAButton, LAIconButton, LASaveAndCancelButton } from "../../../shared/buttons";
import { ArrowLeftIcon, DeleteIcon } from "../../../shared/icons";
import { BLUE_GREEN_COLOR, WHITE_COLOR } from "../../../shared/theme";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import LATextField from "../../../shared/textField";
import LAAutoComplete from "../../../shared/autoComplete";
import { ROUTE } from "../../../routes";
import queryString from "query-string";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import { FieldValidator, FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../../shared/fieldValidator";
import PageSpacing from "../../../shared/pageSpacing";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import SilRoleCheck from "../../../shared/silRoleCheck";
import { IGetBeltScaleRequest, IBeltScale } from "../../../../redux/sil/cv/beltScales/getBeltScales/getBeltScalesConstaints";
import { IAddBeltScaleRequest } from "../../../../redux/sil/cv/beltScales/addBeltScale/addBeltScaleConstaints";
import { IUpdateBeltScaleRequest } from "../../../../redux/sil/cv/beltScales/updateBeltScale/updateBeltScaleConstaints";
import { getBeltScaleStatus } from "../../../../redux/sil/cv/beltScales/getBeltScales/getBeltScalesAccessor";
import { addBeltScaleStatus } from "../../../../redux/sil/cv/beltScales/addBeltScale/addBeltScaleAccessor";
import { updateBeltScaleStatus } from "../../../../redux/sil/cv/beltScales/updateBeltScale/updateBeltScaleAccessor";
import { getBeltScaleLoadAction } from "../../../../redux/sil/cv/beltScales/getBeltScales/getBeltScalesActions";
import { addBeltScaleLoadAction } from "../../../../redux/sil/cv/beltScales/addBeltScale/addBeltScaleActions";
import { updateBeltScaleLoadAction } from "../../../../redux/sil/cv/beltScales/updateBeltScale/updateBeltScaleActions";
import { IIdName } from "../../../../utils/sharedTypes";
import { LADropDone } from "../../../shared/dragDropFiles";
import LALinkButton from "../../../shared/linkButton";
import { END_POINTS } from "../../../../redux/endpoints";
import { LAExpansionPanel } from "../../../shared/expansionPanel";
import LATextArea from "../../../shared/textArea";

const requiredFields = ["quarter", "location", "int_Or_Ext", "year", "employee"];

const quarterList: IIdName<number>[] = [
    { id: 1, name: "1" },
    { id: 2, name: "2" },
    { id: 3, name: "3" },
    { id: 4, name: "4" },
];

const LocationList: IIdName<number>[] = [
    { id: 0, name: "D201" },
    { id: 1, name: "D202" },
    { id: 2, name: "D203" },
    { id: 3, name: "B202" },
    { id: 4, name: "B203" },
    { id: 5, name: "WP202" },
    { id: 6, name: "WP203" }
];

const IntExtList: IIdName<number>[] = [
    { id: 0, name: "Internal" },
    { id: 1, name: "External" }
];

const GoodOrBadList: IIdName<number>[] = [
    { id: 0, name: "Good" },
    { id: 1, name: "Bad" }
];

interface IBeltScaleStoreProps {
    addBeltScale: Server<string>;
    updateBeltScale: Server<string>;
    getToken: Server<SilAPIResponse<IToken>>;
    getBeltScales: Server<SilAPIResponse<IBeltScale[]>>;
};

interface IBeltScaleDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getBeltScalesRequest: (data: IGetBeltScaleRequest) => unknown;
    addBeltScaleRequest: (data: IAddBeltScaleRequest) => unknown;
    updateBeltScaleRequest: (data: IUpdateBeltScaleRequest) => unknown;
};


interface IBeltScaleOwnProps {

};

interface IBeltScaleState {
    beltScale: IBeltScale;
    errors: ById<IFieldErrorKeyValue>;
};

const BeltScaleStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
`;

type IBeltScaleProps = RouteComponentProps
    & IBeltScaleStoreProps
    & IBeltScaleDispatchProps
    & IBeltScaleOwnProps;

class BeltScale extends PureComponent<IBeltScaleProps, IBeltScaleState> {

    public constructor(props: IBeltScaleProps) {
        super(props);
        this.state = {
            errors: {
                "location": { key: "location", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "year": { key: "year", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "employee": { key: "employee", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
            },
            beltScale: {
                id: 0,
                quarter: quarterList[0].id,
                good_Or_Bad: GoodOrBadList[0].name,
                attachments: "",
                location: "",
                int_Or_Ext: IntExtList[0].name,
                completed: yesOrNoOptions[0].name,
                notes: "",
                attachmentContent: [],
                created: "",
                employee: "",
                created_By: userName,
                modified: "",
                modified_By: userName
            }
        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: IBeltScaleProps): void {
        if (this.props !== prevProps) {
            this.callServer();

            if (this.props.addBeltScale !== prevProps.addBeltScale) {
                if (isSucceeded(this.props.addBeltScale)) {
                    this.handleCancel();
                };
            };

            if (this.props.updateBeltScale !== prevProps.updateBeltScale) {
                if (isSucceeded(this.props.updateBeltScale)) {
                    this.handleCancel();
                };
            };
        };
    };


    public render(): ReactNode {

        const { updateBeltScale, addBeltScale } = this.props;
        const { beltScale, errors } = this.state;

        const isAdd = beltScale.id > 0 ? false : true;
        const years = getYearsList(2018);

        return (
            <PageSpacing title="SIL - CV" description="Belt Scale" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="carrierAccess">
                    <BeltScaleStyles>

                        <LAButton startIcon={<ArrowLeftIcon color={WHITE_COLOR} />} label="Back to list" onClick={this.handleCancel} />
                        <h2 className="text-center">{!isAdd ? "VIEW " : "ADD "} BELT SCALE</h2>
                        <hr />

                        <LAPaperWithPadding>
                            <LAGrid>

                                <LAGridItem xs={12}>
                                    <LAExpansionPanel color={BLUE_GREEN_COLOR} textColor={WHITE_COLOR} label="Calibration" expanded={true} onClick={undefinedFunction}>
                                        <LAGrid spacing={3}>
                                            <LAGridItem xs={4}>
                                                <LAAutoComplete
                                                    filterSelectedOptions={true}
                                                    autoHighlight={true}
                                                    selectionRemove={undefinedFunction}
                                                    dropDownPlaceHolder="Quarter"
                                                    getOptionLabel="name"
                                                    name="quarter"
                                                    option={quarterList}
                                                    multiple={false}
                                                    onChange={this.handleDropDownIdChange}
                                                    value={quarterList.find(x => x.id === beltScale.quarter)}
                                                    defaultValue={quarterList.find(x => x.id === beltScale.quarter)}
                                                    errorText={errors["quarter"] ? errors["quarter"].message : undefined}
                                                />
                                            </LAGridItem>

                                            <LAGridItem xs={4}>
                                                <LAAutoComplete
                                                    filterSelectedOptions={true}
                                                    autoHighlight={true}
                                                    selectionRemove={undefinedFunction}
                                                    dropDownPlaceHolder="Year"
                                                    getOptionLabel="name"
                                                    name="year"
                                                    option={years}
                                                    multiple={false}
                                                    onChange={this.handleDropDownIdChange}
                                                    value={beltScale.year ? years.find(x => x.id === beltScale.year) : ""}
                                                    defaultValue={beltScale.year ? years.find(x => x.id === beltScale.year) : ""}
                                                    errorText={errors["year"] ? errors["year"].message : undefined}
                                                />
                                            </LAGridItem>

                                            <LAGridItem xs={4}>
                                                <LATextField
                                                    varient="outlined"
                                                    label="Employee"
                                                    fullWidth={true}
                                                    name="employee"
                                                    value={beltScale.employee ?? ""}
                                                    onChange={this.onChange}
                                                    errorText={errors["employee"] ? errors["employee"].message : undefined}
                                                />
                                            </LAGridItem>

                                            <LAGridItem xs={4}>
                                                <LATextField
                                                    varient="outlined"
                                                    label="Bucket Weight"
                                                    fullWidth={true}
                                                    type="number"
                                                    name="bucket_Weight"
                                                    value={beltScale.bucket_Weight ?? ""}
                                                    onChange={this.onNumberChange}
                                                    // errorText={errors["bucket_Weight"] ? errors["bucket_Weight"].message : undefined}
                                                />
                                            </LAGridItem>

                                            <LAGridItem xs={4}>
                                                <LAAutoComplete
                                                    filterSelectedOptions={true}
                                                    autoHighlight={true}
                                                    selectionRemove={undefinedFunction}
                                                    dropDownPlaceHolder="Location"
                                                    getOptionLabel="name"
                                                    name="location"
                                                    option={LocationList}
                                                    multiple={false}
                                                    onChange={this.handleDropDownChange}
                                                    value={beltScale.location ? LocationList.find(x => x.name === beltScale.location) : ""}
                                                    defaultValue={beltScale.location ? LocationList.find(x => x.name === beltScale.location) : ""}
                                                    errorText={errors["location"] ? errors["location"].message : undefined}
                                                />
                                            </LAGridItem>

                                            <LAGridItem xs={4}>
                                                <LATextField
                                                    varient="outlined"
                                                    label="Scale Weight"
                                                    fullWidth={true}
                                                    type="number"
                                                    name="scale_Weight"
                                                    value={beltScale.scale_Weight ?? ""}
                                                    onChange={this.onNumberChange}
                                                    // errorText={errors["scale_Weight"] ? errors["scale_Weight"].message : undefined}
                                                />
                                            </LAGridItem>

                                            <LAGridItem xs={4}>
                                                <LATextField
                                                    varient="outlined"
                                                    label="% Diff."
                                                    fullWidth={true}
                                                    type="number"
                                                    name="perc_Diff"
                                                    disabled={true}
                                                    value={beltScale.perc_Diff ?? ""}
                                                    onChange={this.onNumberChange}
                                                    // errorText={errors["perc_Diff"] ? errors["perc_Diff"].message : undefined}
                                                />
                                            </LAGridItem>

                                            <LAGridItem xs={4}>
                                                <LAAutoComplete
                                                    filterSelectedOptions={true}
                                                    autoHighlight={true}
                                                    selectionRemove={undefinedFunction}
                                                    dropDownPlaceHolder="Int or Ext"
                                                    getOptionLabel="name"
                                                    name="int_Or_Ext"
                                                    option={IntExtList}
                                                    multiple={false}
                                                    onChange={this.handleDropDownChange}
                                                    value={beltScale.int_Or_Ext ? IntExtList.find(x => x.name === beltScale.int_Or_Ext) : ""}
                                                    defaultValue={beltScale.int_Or_Ext ? IntExtList.find(x => x.name === beltScale.int_Or_Ext) : ""}
                                                    errorText={errors["int_Or_Ext"] ? errors["int_Or_Ext"].message : undefined}
                                                />
                                            </LAGridItem>

                                            <LAGridItem xs={6} className="text-center">
                                                <strong>Calibration Form</strong>
                                                <LADropDone onFileDrop={(e: Array<File | DataTransferItem>) => this.handleAtt(e)} />
                                                {beltScale.attachments && beltScale.attachments.length > 0 && <LALinkButton className="pl-5" label={beltScale.attachments} onClick={this.handleDownloadAtt} />}
                                                {beltScale.attachments && <LAIconButton
                                                    name="attachment_Name"
                                                    label="Remove"
                                                    icon={<DeleteIcon />}
                                                    onClick={this.handleDeleteAtt}
                                                />}
                                            </LAGridItem>

                                        </LAGrid>
                                    </LAExpansionPanel>
                                </LAGridItem>

                                <LAGridItem xs={12}>
                                    <LAExpansionPanel color={BLUE_GREEN_COLOR} textColor={WHITE_COLOR} label="Outcome" expanded={true} onClick={undefinedFunction}>
                                        <LAGrid spacing={3}>
                                            <LAGridItem xs={4}>
                                                <LAAutoComplete
                                                    filterSelectedOptions={true}
                                                    autoHighlight={true}
                                                    selectionRemove={undefinedFunction}
                                                    dropDownPlaceHolder="Good/Bad"
                                                    getOptionLabel="name"
                                                    name="good_Or_Bad"
                                                    option={GoodOrBadList}
                                                    multiple={false}
                                                    onChange={this.handleDropDownChange}
                                                    value={beltScale.good_Or_Bad ? GoodOrBadList.find(x => x.name === beltScale.good_Or_Bad) : ""}
                                                    defaultValue={beltScale.good_Or_Bad ? GoodOrBadList.find(x => x.name === beltScale.good_Or_Bad) : ""}
                                                    // errorText={errors["good_Or_Bad"] ? errors["good_Or_Bad"].message : undefined}
                                                />
                                            </LAGridItem>

                                            <LAGridItem xs={4}>
                                                <LAAutoComplete
                                                    filterSelectedOptions={true}
                                                    autoHighlight={true}
                                                    selectionRemove={undefinedFunction}
                                                    dropDownPlaceHolder="Completed"
                                                    getOptionLabel="name"
                                                    name="completed"
                                                    option={yesOrNoOptions}
                                                    multiple={false}
                                                    onChange={this.handleDropDownChange}
                                                    value={beltScale.completed ? yesOrNoOptions.find(x => x.name === beltScale.completed) : ""}
                                                    defaultValue={beltScale.completed ? yesOrNoOptions.find(x => x.name === beltScale.completed) : ""}
                                                    // errorText={errors["completed"] ? errors["completed"].message : undefined}
                                                />
                                            </LAGridItem>

                                            <LAGridItem xs={6}>
                                                <LATextArea
                                                    minRows={3}
                                                    rowsMax={8}
                                                    varient="outlined"
                                                    label="Notes"
                                                    fullWidth={true}
                                                    name="notes"
                                                    value={beltScale.notes ?? ""}
                                                    onChange={this.onChange}
                                                    // errorText={errors["notes"] ? errors["notes"].message : undefined}
                                                />
                                            </LAGridItem>
                                        </LAGrid>
                                    </LAExpansionPanel>
                                </LAGridItem>

                                <LAGridItem xs={12}>
                                    <hr />
                                    <LASaveAndCancelButton
                                        onSave={this.onSave}
                                        onCancel={this.handleCancel}
                                        disableSave={(Object.values(errors).length > 0 ? true : undefined)}
                                    />
                                </LAGridItem>


                            </LAGrid>
                        </LAPaperWithPadding>

                        <RequestStatus requestStatus={addBeltScale.kind} successMessage="Belt Scale successfully saved" />
                        <RequestStatus requestStatus={updateBeltScale.kind} successMessage="Belt Scale successfully updated" />
                    </BeltScaleStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private handleDeleteAtt = (): void => {
        const selection = window.confirm(deleteText);
        if(selection) {
            this.setState({
                beltScale: {
                    ...this.state.beltScale,
                    attachments: "",
                    attachmentContent: []
                }
            });
        }
    };

    private onNumberChange = (name: string, value: string): void => {
        console.log(value)
        if(!isNaN(+value)){
            let errors = this.state.errors;

        if(requiredFields.includes(name)){
            errors = this.errorChecker(name, value, errors, true);
        }

        this.setState({
            ...this.state,
            errors,
            beltScale: {
                ...this.state.beltScale,
                [name]: value !== "" ? +value : null
            }
        });
        }
    };

    private handleDownloadAtt = (): void => {
        const { beltScale } = this.state;
        if (beltScale.attachments) {
            if ((beltScale.attachmentContent !== null) && (beltScale.attachmentContent[0] !== undefined) 
            && (beltScale.attachmentContent[0].Base64String !== undefined)) {
                let a = document.createElement("a");
                a.href = beltScale.attachmentContent[0].Base64String;
                a.download = beltScale.attachments;
                a.click();
            };

            if ((beltScale.attachmentContent === null) || (beltScale.attachmentContent[0] === undefined)) {
                if (hasPayload(this.props.getToken) && beltScale.id) {
                    fetch(END_POINTS.PROPSENSE.SIL.CV.GetCVFile, {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            request: {
                                "ID": beltScale.id,
                                "File": beltScale.attachments,
                                "Page": "Belt_Scale"
                            },
                            token: this.props.getToken.payload.response.token
                        })
                    })
                        .then((res: any) => {
                            return res.blob();
                        })
                        .then(blob => {
                            if (beltScale.attachments) {
                                let url = window.URL.createObjectURL(blob);
                                let a = document.createElement('a');
                                a.href = url;
                                a.download = beltScale.attachments;
                                document.body.appendChild(a);
                                a.click();
                                a.remove();
                            }
                        })
                        .catch((res) => console.log(res));
                };
            };
        }
    };

    private handleAtt = (files: Array<File | DataTransferItem>): void => {

        new Promise((resolve, reject): any => {
            if (files && (files !== null)) {

                Object.values(files).forEach((x: any, index, array) => {
                    const reader = new FileReader();

                    reader.onloadend = async (e): Promise<void> => {
                        if (reader.result !== null) {
                            this.setState({
                                ...this.state,
                                beltScale: {
                                    ...this.state.beltScale,
                                    attachments: x.name,
                                    attachmentContent: [{
                                        Name: x.name,
                                        Base64String: reader.result.toString()
                                    }]
                                },
                            });
                            resolve(true);
                        };
                    };
                    reader.readAsDataURL(x);
                })
            } else {
                reject();
            }
        });
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, isRequired: boolean): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: isRequired ? true : undefined, minLength: 1, decimal: undefined });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        };
        return err;
    };

    private handleCancel = (): void => {
        this.props.history.push(ROUTE.SIL.CV.BELT_SCALES.INDEX);
    };

    private onSave = (): void => {
        const { beltScale } = this.state;
   
        if (hasPayload(this.props.getToken))
            if (beltScale.id === 0) {
                this.props.addBeltScaleRequest({
                    token: this.props.getToken.payload.response.token,
                    request: beltScale
                });
            } else {
                beltScale.modified_By = userName;
                
                this.props.updateBeltScaleRequest({
                    token: this.props.getToken.payload.response.token,
                    request: beltScale
                });
            };
    };

    private handleDropDownChange = (event: unknown, value: { id: number, name: string } | "", name?: string): void => {
        if (name) {
            let errors = this.state.errors;
            if(requiredFields.includes(name)){
                errors = this.errorChecker(name, value !== "" ? value.name : "", errors, true);
            }

            this.setState({
                ...this.state,
                errors,
                beltScale: {
                    ...this.state.beltScale,
                    [name]: value !== "" ? value.name : ""
                }
            });
        }
    };

    private handleDropDownIdChange = (event: unknown, value: { id: number, name: string } | "", name?: string): void => {
        if (name) {
            let errors = this.state.errors;
            if(requiredFields.includes(name)){
                errors = this.errorChecker(name, value !== "" ? value.name : "", errors, true);
            }

            this.setState({
                ...this.state,
                errors,
                beltScale: {
                    ...this.state.beltScale,
                    [name]: value !== "" ? value.id : undefined
                }
            });
        }
    };


    private onChange = (name: string, value: string): void => {
        let errors = this.state.errors;
        if(requiredFields.includes(name)){  
            errors = this.errorChecker(name, value, errors, true);
        }

        this.setState({
            ...this.state,
            errors,
            beltScale: {
                ...this.state.beltScale,
                [name]: value
            }
        });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getBeltScales))
            this.props.getBeltScalesRequest({
                token: this.props.getToken.payload.response.token
            });


        const query = queryString.parse(this.props.location.search);
        const id = query.id ?? "0";

        if ((id !== "0") && (hasPayload(this.props.getBeltScales))) {
            const beltScale = this.props.getBeltScales.payload.response.find(x => x.id === Number(id));

            if (beltScale)
                this.setState({ beltScale, errors: {} });
        };

    };

}

const mapStateToProps = (state: IStore): IBeltScaleStoreProps => ({
    getToken: getTokenStatus(state),
    getBeltScales: getBeltScaleStatus(state),
    addBeltScale: addBeltScaleStatus(state),
    updateBeltScale: updateBeltScaleStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IBeltScaleDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getBeltScalesRequest: (data: IGetBeltScaleRequest) => dispatch(getBeltScaleLoadAction(data)),
    addBeltScaleRequest: (data: IAddBeltScaleRequest) => dispatch(addBeltScaleLoadAction(data)),
    updateBeltScaleRequest: (data: IUpdateBeltScaleRequest) => dispatch(updateBeltScaleLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(BeltScale);