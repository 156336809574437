import { IPassTokenWithRequest } from "../../dryPlant/token/getTokenConstants";

export enum IGET_GEO_BAGS_REQUEST {
    REQUEST = "getGeoBags/GET_GEO_BAGS_REQUEST",
    SUCCESS = "getGeoBags/GET_GEO_BAGS_SUCCESS",
    FAILED = "getGeoBags/GET_GEO_BAGS_FAILED"
};

export interface IGetGeoBagsRequest extends IPassTokenWithRequest {

};

export interface IGeoBag {
    id: number;
    date: string;
    operator: string;
    sampleNTU_to_Pond?: number;
    sampleNTU_Before?: number;
    sampleNTU_Bag_One?: number;
    sampleNTU_Bag_Two?: number;
    sampleNTU_Bag_Three?: number;
    sampleNTU_Bag_Four?: number;
    sampleNTU_Bag_Five?: number;
    sampleNTU_Bag_Six?: number;
    bag_One_Before?: number;
    bag_One_After?: number;
    bag_Two_Before?: number;
    bag_Two_After?: number;
    bag_Three_Before?: number;
    bag_Three_After?: number;
    bag_Four_Before?: number;
    bag_Four_After?: number;
    bag_Five_Before?: number;
    bag_Five_After?: number;
    bag_Six_Before?: number;
    bag_Six_After?: number;
    dosagePercentage?: number;
    consumption?: number;
    plantUptime?: number;
    fuelUsageForTB?: number;
    tB_One_Downtime?: number;
    tB_Two_Downtime?: number;
    tB_Three_Downtime?: number;
    tB_Four_Downtime?: number;
    tB_Five_Downtime?: number;
    tB_Six_Downtime?: number;
    notes: string;
    created: string;
    created_By: string;
    modified: string;
    modified_By: string;
};