import { ICrane } from "../getCranes/getCranesConstaints";

export enum IUPDATE_CRANE_REQUEST {
    REQUEST = "updateCrane/UPDATE_CRANE_REQUEST",
    SUCCESS = "updateCrane/UPDATE_CRANE_SUCCESS",
    FAILED = "updateCrane/UPDATE_CRANE_FAILED"
};

export interface IUpdateCraneRequest {
    token: string;
    request: ICrane;
};