import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IGetEmployeesLoadAction, IGetEmployeesLoadFailedAction, IGetEmployeesSuccessAction, 
    getEmployeesLoadFailedAction, getEmployeesLoadSuccessAction
} from "./getEmployeesActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IGET_EMPLOYEES_REQUEST, IEmployees } from "./getEmployeesConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";

export const getEmployeesEpic: Epic = (
    action$: ActionsObservable<IGetEmployeesLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IGetEmployeesSuccessAction | IGetEmployeesLoadFailedAction> =>
    action$.ofType(IGET_EMPLOYEES_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<IEmployees[]>>(
                    END_POINTS.PROPSENSE.SIL.DRY_PLANT.GET_DRY_PLANT_EMPLOYEE,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<IEmployees[]>): IGetEmployeesSuccessAction => {
                            return getEmployeesLoadSuccessAction(response);
                        }),
                        catchError(() => ActionsObservable.of(getEmployeesLoadFailedAction("Unable to get employees")))
                    )
            )
        );