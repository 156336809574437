import React from "react";
import { STATUS_ENUM } from "../../../../../redux/server";
import { ById, HasClass } from "../../../../shared/publicInterfaces";
import { LAPopover } from "../../../../shared/popOver";
import { LAPaperWithLessPadding } from "../../../../shared/paper";
import LAGrid from "../../../../shared/grid";
import LAGridItem from "../../../../shared/gridList";
import { LACenteredLoading } from "../../../../shared/loading";
import LAErrorBox from "../../../../shared/errorBox";
import { TableContainer } from "@material-ui/core";
import { LADevExtremeGrid } from "../../../../shared/devExtreme";
import { undefinedFunction } from "../../../../shared/constExports";
import { ISIMOSOrderProductVersionHistory } from "../../../../../redux/propsense/simos/getSIMOSOrderProductVersionHistory/getSIMOSOrderProductVersionHistoryConstaints";


interface IProductVersionHistoryProps extends HasClass {
    open: boolean;
    status: STATUS_ENUM;
    onClose: () => void;
    data: ISIMOSOrderProductVersionHistory[];
};

export const ProductVersionHistory: React.FC<IProductVersionHistoryProps> = React.memo((props: IProductVersionHistoryProps) => {
    const data = props.data ? props.data : [];
    // console.log(data)

    return <LAPopover open={props.open} onClose={props.onClose} anchorRef={null} anchorOrigin={{ horizontal: "left", vertical: "top" }}
        transformOrigin={{ horizontal: "left", vertical: "center" }}>
        <LAPaperWithLessPadding>
            <LAGrid spacing={3} className="text-center">

                <LAGridItem xs={12}>
                    <h3>PRODUCT VERSION HISTORY</h3>
                    <hr />
                </LAGridItem>

                {(props.status === STATUS_ENUM.LOADING) && <LAGridItem xs={12}>
                    <br /><br /><br /><br />
                    <LACenteredLoading message="Loading Product Version History..." />
                </LAGridItem>}

                {(props.status === STATUS_ENUM.FAILED) && <LAGridItem xs={12}>
                    <LAErrorBox text="Failed to load..." />
                </LAGridItem>}


                {(props.status === STATUS_ENUM.SUCCEEDED) && <LAGridItem xs={12}>
                    {(data && data.length > 0) ? <TableContainer component={LAPaperWithLessPadding}>
                        
                        <LADevExtremeGrid
                            data={data}
                            columnChoose={true}
                            columnsHiding={true}
                            onClick={undefinedFunction}
                            searchPanel={true}
                            filterHeader={true}
                            export={true}
                            id="productVersionHistoryList"
                            exportFileName="Product-Version-History"
                            storageKey="productVersionHistorySessionKey"
                            columns={[
                                { name: "modified", caption: "Modified", type: "datetime", sortDesc: true },
                                { name: "modified_By", caption: "Modified By", type: "string" },
                                { name: "status", caption: "Status", type: "string" },
                                { name: "product", caption: "Product", type: "string" },
                                { name: "quantity", caption: "Quantity", type: "number" },
                                { name: "size", caption: "Size", type: "string" },
                                { name: "per", caption: "Per", type: "string" },
                            ]}
                        />
                    </TableContainer>
                        : <LAErrorBox text="No records Found" />}
                </LAGridItem>}

            </LAGrid>
        </LAPaperWithLessPadding>
    </LAPopover>
});