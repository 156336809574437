import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IADD_GEO_BAG_REQUEST } from "./addGeoBagConstaints";
import { IAddGeoBagLoadAction, IAddGeoBagLoadFailedAction, IAddGeoBagSuccessAction } from "./addGeoBagActions";

type Actions =
    IAddGeoBagLoadAction
    | IAddGeoBagLoadFailedAction
    | IAddGeoBagSuccessAction
    | IFlushDataSuccessAction;

export const AddGeoBagReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IADD_GEO_BAG_REQUEST.REQUEST:
            return loading;

        case IADD_GEO_BAG_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IADD_GEO_BAG_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};