import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IUpdateWarehouseProductLoadAction, 
    IUpdateWarehouseProductLoadFailedAction, 
    IUpdateWarehouseProductSuccessAction, 
    updateWarehouseProductLoadFailedAction, updateWarehouseProductLoadSuccessAction
} from "./updateWarehouseProductActions";
import { IUPDATE_WAREHOUSE_PRODUCT_REQUEST } from "./updateWarehouseProductConstaints";
import { SilAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { END_POINTS } from "../../../../endpoints";
import { withJsonContentType } from "../../../../epicUtils";
import { IStore } from "../../../../reducers";
import { EpicDependencies } from "../../../../store";

export const updateWarehouseProductEpic: Epic = (
    action$: ActionsObservable<IUpdateWarehouseProductLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IUpdateWarehouseProductSuccessAction | IUpdateWarehouseProductLoadFailedAction> =>
    action$.ofType(IUPDATE_WAREHOUSE_PRODUCT_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<string>>(
                    END_POINTS.PROPSENSE.SIL.WAREHOUSE.UPDATE_WAREHOUSE_PRODUCT,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<string>): IUpdateWarehouseProductSuccessAction | IUpdateWarehouseProductLoadFailedAction => {
                            if(response.message === "Success"){
                                return updateWarehouseProductLoadSuccessAction(response.message);
                            };
                            return updateWarehouseProductLoadFailedAction(response.message)
                        }),
                        catchError(() => ActionsObservable.of(updateWarehouseProductLoadFailedAction("Unable to update warehouse product")))
                    )
            )
        );