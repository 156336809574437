import { IPassTokenWithRequest } from "../../dryPlant/token/getTokenConstants";

export enum IGET_SIEVES_REQUEST {
    REQUEST = "getSieves/GET_SIEVES_REQUEST",
    SUCCESS = "getSieves/GET_SIEVES_SUCCESS",
    FAILED = "getSieves/GET_SIEVES_FAILED"
};

export interface IGetSievesRequest extends IPassTokenWithRequest {

};

export interface ISieve {
    id: number;
    date: string;
    operator: string;
    bin?: string;
    product: string;
    plant: string;
    load_No: string;
    feed_Material_1?: string;
    feed_Material_2?: string;
    feed_Material_3?: string;
    feed_Material_4?: string;
    feed_Material_1_Perc?: number;
    feed_Material_2_Perc?: number;
    feed_Material_3_Perc?: number;
    feed_Material_4_Perc?: number;
    cancelled: string;
    notes: string;
    initial_Mass: number;
    screen_Section?: string;
    split_Mass?: number;
    wash_Mass?: number;
    wash_NTU?: number;
    perc_Fines?: number;
    duplicate_CantyID?: number;
    rotap_ID?: number;
    created: string;
    modified: string;
    created_By: string;
    modified_By: string;
    measurements: ISieveMeasurement[];
};

export interface ISieveMeasurement {
    id: number;
    sieve_ID: number;
    mesh: string;
    weight?: number;
    perc_Return: number;
    perc_Pass: number;
    perc_Ret_Spec: string;
    perc_Pass_Spec: string;
    created: string;
    modified: string;
    created_By: string;
    modified_By: string;
}