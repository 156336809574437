import { SimosAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetProductsByIdRequest, IGET_PRODUCTS_BY_ID_REQUEST, ISIMOSProductsById } from "./getProductsByIdConstaints";

export interface IGetProductsByIdLoadAction {
    type: IGET_PRODUCTS_BY_ID_REQUEST.REQUEST;
    data: IGetProductsByIdRequest
}
export const getProductsByIdLoadAction = (data: IGetProductsByIdRequest): IGetProductsByIdLoadAction => ({
    type: IGET_PRODUCTS_BY_ID_REQUEST.REQUEST,
    data
});

export interface IGetProductsByIdSuccessAction {
    type: IGET_PRODUCTS_BY_ID_REQUEST.SUCCESS;
    data: SimosAPIResponse<ISIMOSProductsById[]>;
}
export const getProductsByIdLoadSuccessAction = (data: SimosAPIResponse<ISIMOSProductsById[]>): IGetProductsByIdSuccessAction => ({
    type: IGET_PRODUCTS_BY_ID_REQUEST.SUCCESS,
    data
});

export interface IGetProductsByIdLoadFailedAction {
    type: IGET_PRODUCTS_BY_ID_REQUEST.FAILED;
    message: string;
}
export const getProductsByIdLoadFailedAction = (message: string): IGetProductsByIdLoadFailedAction => ({
    type: IGET_PRODUCTS_BY_ID_REQUEST.FAILED,
    message
});
