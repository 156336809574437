import { IBaggedInventories, IGET_BAGGED_INVENTORIES_REQUEST } from "./getBaggedInventoriesConstaints";
import { IGetBaggedInventoriesLoadAction, IGetBaggedInventoriesLoadFailedAction, IGetBaggedInventoriesSuccessAction } from "./getBaggedInventoriesActions";
import { Server, failed, loading, notLoaded, succeeded } from "../../../../server";
import { SilAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../../flushData.tsx/flushDataConstants";
import { IAddBaggedInventorySuccessAction } from "../addBaggedInventory/addBaggedInventoryActions";
import { IADD_BAGGED_INVENTORY_REQUEST } from "../addBaggedInventory/addBaggedInventoryConstaints";
import { IUpdateBaggedInventorySuccessAction } from "../updateBaggedInventory/updateBaggedInventoryActions";
import { IUPDATE_BAGGED_INVENTORY_REQUEST } from "../updateBaggedInventory/updateBaggedInventoryConstaints";

type Actions =
    IGetBaggedInventoriesLoadAction
    | IGetBaggedInventoriesLoadFailedAction
    | IGetBaggedInventoriesSuccessAction
    | IAddBaggedInventorySuccessAction
    | IUpdateBaggedInventorySuccessAction
    | IFlushDataSuccessAction;

export const GetBaggedInventoriesReducer = (state: Server<SilAPIResponse<IBaggedInventories[]>> = notLoaded, action: Actions): Server<SilAPIResponse<IBaggedInventories[]>> => {
    switch (action.type) {
        case IGET_BAGGED_INVENTORIES_REQUEST.REQUEST:
            return loading;

        case IGET_BAGGED_INVENTORIES_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_BAGGED_INVENTORIES_REQUEST.FAILED:
            return failed(action.message);

        case IADD_BAGGED_INVENTORY_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_BAGGED_INVENTORY_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};