import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IUPDATE_COMPLAINT_LOGGING_REQUEST } from "./updateComplaintLoggingConstaints";
import { IUpdateComplaintLoggingLoadAction, IUpdateComplaintLoggingLoadFailedAction, IUpdateComplaintLoggingSuccessAction } from "./updateComplaintLoggingActions";

type Actions =
    IUpdateComplaintLoggingLoadAction
    | IUpdateComplaintLoggingLoadFailedAction
    | IUpdateComplaintLoggingSuccessAction
    | IFlushDataSuccessAction;

export const UpdateComplaintLoggingReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IUPDATE_COMPLAINT_LOGGING_REQUEST.REQUEST:
            return loading;

        case IUPDATE_COMPLAINT_LOGGING_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IUPDATE_COMPLAINT_LOGGING_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};