import { IPassTokenWithRequest } from "../../../../sil/dryPlant/token/getTokenConstants";

export enum IGET_YHD_CARRIER_EMAILS_REQUEST {
    REQUEST = "getYHDCarrierEmails/GET_YHD_CARRIER_EMAILS_REQUEST",
    SUCCESS = "getYHDCarrierEmails/GET_YHD_CARRIER_EMAILS_SUCCESS",
    FAILED = "getYHDCarrierEmails/GET_YHD_CARRIER_EMAILS_FAILED"
};

export interface IGetYHDCarrierEmailsRequest extends IPassTokenWithRequest {

};

export interface IYHDCarrierEmail {
    id: number;
    subject: string;
    email_Body: string;
    email_All_Carriers: string;
    carriers: string;
    attachment_Name?: string;
    created: string;
    created_By: string;
    modified: string;
    modified_By: string;
};