import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IGET_TRAILER_ACTIVITY_LOGS_REQUEST, ITrailerActivityLog } from "./getTrailerActivityLogsConstaints";
import { IGetTrailerActivityLogsLoadAction, IGetTrailerActivityLogsLoadFailedAction, IGetTrailerActivityLogsSuccessAction } from "./getTrailerActivityLogsActions";
import { IAddTrailerActivityLogSuccessAction } from "../addTrailerActivityLog/addTrailerActivityLogActions";
import { IADD_TRAILER_ACTIVITY_LOG_REQUEST } from "../addTrailerActivityLog/addTrailerActivityLogConstaints";
import { IUpdateTrailerActivityLogSuccessAction } from "../updateTrailerActivityLog/updateTrailerActivityLogActions";
import { IUPDATE_TRAILER_ACTIVITY_LOG_REQUEST } from "../updateTrailerActivityLog/updateTrailerActivityLogConstaints";

type Actions =
    IGetTrailerActivityLogsLoadAction
    | IGetTrailerActivityLogsLoadFailedAction
    | IGetTrailerActivityLogsSuccessAction
    | IAddTrailerActivityLogSuccessAction
    | IUpdateTrailerActivityLogSuccessAction
    | IFlushDataSuccessAction;

export const GetTrailerActivityLogsReducer = (state: Server<SilAPIResponse<ITrailerActivityLog[]>> = notLoaded, action: Actions): Server<SilAPIResponse<ITrailerActivityLog[]>> => {
    switch (action.type) {
        case IGET_TRAILER_ACTIVITY_LOGS_REQUEST.REQUEST:
            return loading;

        case IGET_TRAILER_ACTIVITY_LOGS_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_TRAILER_ACTIVITY_LOGS_REQUEST.FAILED:
            return failed(action.message);

        case IADD_TRAILER_ACTIVITY_LOG_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_TRAILER_ACTIVITY_LOG_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};