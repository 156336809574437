import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IUpdateBeltScaleLoadAction, IUpdateBeltScaleLoadFailedAction, IUpdateBeltScaleSuccessAction, updateBeltScaleLoadFailedAction,
     updateBeltScaleLoadSuccessAction } from "./updateBeltScaleActions";
import { IUPDATE_BELT_SCALE_REQUEST } from "./updateBeltScaleConstaints";
import { IStore } from "../../../../reducers";
import { EpicDependencies } from "../../../../store";
import { SilAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { END_POINTS } from "../../../../endpoints";
import { withJsonContentType } from "../../../../epicUtils";

export const updateBeltScaleEpic: Epic = (
    action$: ActionsObservable<IUpdateBeltScaleLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IUpdateBeltScaleSuccessAction | IUpdateBeltScaleLoadFailedAction> =>
    action$.ofType(IUPDATE_BELT_SCALE_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<string>>(
                    END_POINTS.PROPSENSE.SIL.CV.UpdateCVBeltScale,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<string>): IUpdateBeltScaleSuccessAction | IUpdateBeltScaleLoadFailedAction => {
                            if(response.message === "Success"){
                                return updateBeltScaleLoadSuccessAction(action.data.request);
                            }
                            return updateBeltScaleLoadFailedAction(response.message);
                        }),
                        catchError(() => ActionsObservable.of(updateBeltScaleLoadFailedAction("Unable to save your changes")))
                    )
            )
        );