import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IADD_TRAILER_ACTIVITY_LOG_REQUEST } from "./addTrailerActivityLogConstaints";
import { IAddTrailerActivityLogLoadAction, IAddTrailerActivityLogLoadFailedAction, IAddTrailerActivityLogSuccessAction } from "./addTrailerActivityLogActions";

type Actions =
    IAddTrailerActivityLogLoadAction
    | IAddTrailerActivityLogLoadFailedAction
    | IAddTrailerActivityLogSuccessAction
    | IFlushDataSuccessAction;

export const AddTrailerActivityLogReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IADD_TRAILER_ACTIVITY_LOG_REQUEST.REQUEST:
            return loading;

        case IADD_TRAILER_ACTIVITY_LOG_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IADD_TRAILER_ACTIVITY_LOG_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};