import { SilAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../../server";
import { IGET_PS_LOOKUPS_REQUEST, IPSLookup } from "./getPSLookupsConstaints";
import { IGetPSLookupsLoadAction, IGetPSLookupsLoadFailedAction, IGetPSLookupsSuccessAction } from "./getPSLookupsActions";

type Actions =
    IGetPSLookupsLoadAction
    | IGetPSLookupsLoadFailedAction
    | IGetPSLookupsSuccessAction
    | IFlushDataSuccessAction;

export const GetPSLookupsReducer = (state: Server<SilAPIResponse<IPSLookup>> = notLoaded, action: Actions): Server<SilAPIResponse<IPSLookup>> => {
    switch (action.type) {
        case IGET_PS_LOOKUPS_REQUEST.REQUEST:
            return loading;

        case IGET_PS_LOOKUPS_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_PS_LOOKUPS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};