import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_SIMOS_CUSTOMER_CONTACTS_REQUEST, ISimosCustomerContacts } from "./getSimosCustomerContactsConstants";
import { IGetSimosCustomerContactsLoadAction, IGetSimosCustomerContactsLoadFailedAction, IGetSimosCustomerContactsSuccessAction, getSimosCustomerContactsLoadFailedAction, getSimosCustomerContactsLoadSuccessAction } from "./getSimosCustomerContactsActions";
import { IStore } from "../../../../reducers";
import { EpicDependencies } from "../../../../store";
import { SimosAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { END_POINTS } from "../../../../endpoints";
import { withJsonContentType } from "../../../../epicUtils";

export const getSimosCustomerContactsEpic: Epic = (
    action$: ActionsObservable<IGetSimosCustomerContactsLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetSimosCustomerContactsSuccessAction | IGetSimosCustomerContactsLoadFailedAction> =>
    action$.ofType(IGET_SIMOS_CUSTOMER_CONTACTS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SimosAPIResponse<ISimosCustomerContacts[]>>(
                    END_POINTS.PROPSENSE.SIMOS.GET_SIMOS_CUSTOMER_CONTACTS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SimosAPIResponse<ISimosCustomerContacts[]>): IGetSimosCustomerContactsSuccessAction | IGetSimosCustomerContactsLoadFailedAction => {
                            if(response.message === "Success"){
                                return getSimosCustomerContactsLoadSuccessAction(response);
                            }
                            return getSimosCustomerContactsLoadFailedAction(response.message);
                        }),
                        catchError((response: SimosAPIResponse<string>) => ActionsObservable.of(getSimosCustomerContactsLoadFailedAction(response.message)))
                    )
            )
        );