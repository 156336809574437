import {currentUser} from '../../../../simosHome';

async function addTransportCompany(transport_company:string, active:string, email:string, y_code:string, z_code:string) {
    var success:any = null;
    let requestOptions = {
        method: 'POST',
        headers: { 'Content-Type':'application/json'},
        body: JSON.stringify({
            request: {
                Transport_Company:transport_company,
                Active:active,
                Email:email,
                Truck_Code_Y:y_code,
                Truck_Code_Z:z_code,
                Created_By:currentUser,
                Modified_By:currentUser
            },
            token: '19515195-d571-44a3-b0db-637f5bf24f54'})
    }
    await fetch('https://api2.propsense.com/api/AddSIMOSTransportCompany', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json')&& await response.json();
        // error response
        if(!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        success = response;
    });
    return success;
}

async function updateTransportCompany(id:number,email:string,active:string, y_code:string, z_code:string){
    var success:any=null;
    let requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type':'application/json'},
        body: JSON.stringify({
            request: {
                ID:id,
                Active:active,
                Email:email,
                Truck_Code_Y:y_code,
                Truck_Code_Z:z_code,
                Created_By:currentUser,
                Modified_By:currentUser
            },
            token: '19515195-d571-44a3-b0db-637f5bf24f54'})
    }
    await fetch('https://api2.propsense.com/api/updateSIMOSTransportCompany', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json')&& await response.json();
        // error response
        if(!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        success = response;
    });
    return success;
}

export {addTransportCompany, updateTransportCompany}