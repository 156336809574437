import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { HasClass } from "./publicInterfaces";

interface ILACheckBoxProps extends HasClass {
    label: string;
    name?: string;
    value: boolean;
    disabled?: true;
    onChange: (checked: boolean, name?: string) => void;
}

export const LACheckBox = (props: ILACheckBoxProps): JSX.Element =>
    {
        const onChange = (e: any, checked: boolean): void => props.onChange(checked, props.name);
        return <FormControlLabel
            className={props.className}
            control={<Checkbox
                color="secondary"
                name={props.label}
                checked={props.value}
                disabled={props.disabled}
                onChange={onChange} />}
            label={props.label} />;
    }
    ;
