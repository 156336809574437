import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IGET_ORDER_CONTACT_REQUEST, ISIMOSOrderContact } from "./getOrderContactConstaints";
import { IGetOrderContactLoadAction, IGetOrderContactLoadFailedAction, IGetOrderContactSuccessAction } from "./getOrderContactActions";
import { IADD_ORDER_REQUEST } from "../addOrder/addOrderConstaints";
import { IAddOrderSuccessAction } from "../addOrder/addOrderActions";
import { IUpdateOrderSuccessAction } from "../updateOrder/updateOrderActions";
import { IUPDATE_ORDER_REQUEST } from "../updateOrder/updateOrderConstaints";
import { SimosAPIResponse } from "../../../../react/shared/publicInterfaces";

type Actions =
    IGetOrderContactLoadAction
    | IGetOrderContactLoadFailedAction
    | IGetOrderContactSuccessAction
    | IAddOrderSuccessAction
    | IUpdateOrderSuccessAction
    | IFlushDataSuccessAction;

export const GetOrderContactReducer = (state: Server<SimosAPIResponse<ISIMOSOrderContact[]>> = notLoaded, action: Actions): Server<SimosAPIResponse<ISIMOSOrderContact[]>> => {
    switch (action.type) {
        case IGET_ORDER_CONTACT_REQUEST.REQUEST:
            return loading;

        case IGET_ORDER_CONTACT_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_ORDER_CONTACT_REQUEST.FAILED:
            return failed(action.message);

        case IADD_ORDER_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_ORDER_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};