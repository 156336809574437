import React, { ReactNode } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionActions";
import { HasChildren } from "./publicInterfaces";
import { CollapseIcon, ExpandIcon } from "./icons";
import { RED_COLOR } from "./theme";
import Typography from "@mui/material/Typography";

interface ILAExpansionPanelProps extends HasChildren {
    label: string;
    color?: string;
    icon?: ReactNode;
    expanded: boolean;
    textColor?: string;
    onClick: () => void;
}


export const LAExpansionPanel: React.FC<ILAExpansionPanelProps> = React.memo((props: ILAExpansionPanelProps) =>
    <Accordion expanded={props.expanded}>
        <AccordionSummary
            expandIcon={props.expanded ? <CollapseIcon color={RED_COLOR} /> : <ExpandIcon color={RED_COLOR} />}
            aria-controls="panel1a-content"
            onClick={props.onClick}
            id={props.label}
            style={{ backgroundColor: props.color }}
        >
            <Typography sx={{ width: "100%", flexShrink: 0 }}>
                <strong style={{ color: props.textColor, alignContent: "center"}}>{props.label}</strong>
            </Typography>
        </AccordionSummary>
        <AccordionDetails>
            {props.children}
        </AccordionDetails>
    </Accordion>
);