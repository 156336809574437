import { IPassTokenWithRequest } from "../../dryPlant/token/getTokenConstants";


export enum IGET_WASH_PLANT_PRODUCTION_RECORDS_REQUEST {
    REQUEST = "getWashPlantProductionRecords/GET_WASH_PLANT_PRODUCTION_RECORDS_REQUEST",
    SUCCESS = "getWashPlantProductionRecords/GET_WASH_PLANT_PRODUCTION_RECORDS_SUCCESS",
    FAILED = "getWashPlantProductionRecords/GET_WASH_PLANT_PRODUCTION_RECORDS_FAILED"
};

export interface IGetWashPlantProductionRecordsRequest extends IPassTokenWithRequest {
    request?: {
        view?: string;
    }
};

export interface IWashPlantProductionRecords {
    id: number;
    date: string;
    shift_Worked: string;
    control_Room: string;
    plants: string;
    washplant_Operator: string;
    load_Operator1: string;
    load_Operator2: string;
    flocculant_Operator: string;
    cleanup_Operator: string;
    employee4: string;
    employee5: string;
    labourer: string;
    production_Shift_Goal: string;
    qA_QC_Notes: string;
    production_Notes: string;
    wear_Issues_Notes: string;
    maintenance_By?: string;
    maintenance_Performed?: string;
    created: string;
    modified: string;
    created_By: string;
    modified_By: string;
    downtimes: IWashPlantDownTime[];
    production_Data: IWashPlantProductionData[];
};

export interface IWashPlantDownTime {
    id: number;
    listID: number;
    plant?: string;
    un_Scheduled_Downtime_Hrs?: number;
    unschD_Notes?: string;
    scheduled_Downtime_Hrs?: number;
    schD_Notes?: string;
    component?: string;
    cause?: string;
    reason?: string;
    category?: string;
    downtime_Note_1?: string;
    downtime_Note_2?: string;
    downtime_Note_3?: string;
    downtime_Note_4?: string;
    downtime_Note_5?: string;
    downtime_Note_6?: string;
    
};

export interface IWashPlantProductionData {
    id: number;
    listID: number;
    plant?: string;
    run_Material: string;
    run_Material_MT: number; //Decimal
    by_Product_1?: string;
    by_Product_1_MT?: number;
    by_Product_2?: string;
    by_Product_2_MT?: number;
    by_Product_3?: string;
    by_Product_3_MT?: number;
    by_Product_4?: string;
    by_Product_4_MT?: number;
    by_Product_5?: string;
    by_Product_5_MT?: number;
    by_Product_6?: string;
    by_Product_6_MT?: number;
    by_Product_7?: string;
    by_Product_7_MT?: number;
    by_Product_8?: string;
    by_Product_8_MT?: number;
    start_Time: number;
    start_Time_Mins: number;
    stop_Time: number;
    stop_Time_Mins: number;
    run_Hours?: string;
    production_Top: string;
    production_Mid: string;
    water_Feed_Rate?: number;
    production_Bottom: string;
    tph?: number; //Decimal
    feed_Tonnage?: number; //Decimal
    magging?: string;
    bottle_Neck: string;
    feed_1_Material: string;
    feed_1_Perc: number; //Decimal
    feed_2_Material?: string;
    feed_2_Perc?: number;
    feed_3_Material?: string;
    feed_3_Perc?: number;
    feed_4_Material?: string;
    feed_4_Perc?: number;
    polish?: string;
    created: string;
    modified: string;
    created_By: string;
    modified_By: string;
};