import { IPassTokenWithRequest } from "../../dryPlant/token/getTokenConstants";
import { IGeoBag } from "../getGeoBags/getGeoBagsConstaints";


export enum IADD_GEO_BAG_REQUEST {
    REQUEST = "addGeoBag/ADD_GEO_BAG_REQUEST",
    SUCCESS = "addGeoBag/ADD_GEO_BAG_SUCCESS",
    FAILED = "addGeoBag/ADD_GEO_BAG_FAILED"
};

export interface IAddGeoBagRequest extends IPassTokenWithRequest {
    request: IGeoBag;
};