import { ByIdNumber, SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IGET_CARRIERS_REQUEST, ICarrier } from "./getCarriersConstaints";
import { IGetCarriersLoadAction, IGetCarriersLoadFailedAction, IGetCarriersSuccessAction } from "./getCarriersActions";
import { ISAVE_CARRIER_FORM_REQUEST } from "../saveCarrierForm/saveCarrierFormConstaints";
import { ISaveCarrierFormSuccessAction } from "../saveCarrierForm/saveCarrierFormActions";
import { IUpdateCarrierFormSuccessAction } from "../updateCarrierForm/updateCarrierFormActions";
import { IUPDATE_CARRIER_FORM_REQUEST } from "../updateCarrierForm/updateCarrierFormConstaints";

type Actions =
    IGetCarriersLoadAction
    | IGetCarriersLoadFailedAction
    | IGetCarriersSuccessAction
    | ISaveCarrierFormSuccessAction
    | IUpdateCarrierFormSuccessAction
    | IFlushDataSuccessAction;

export const GetCarriersReducer = (state: Server<SilAPIResponse<ByIdNumber<ICarrier>>> = notLoaded, action: Actions): Server<SilAPIResponse<ByIdNumber<ICarrier>>> => {
    switch (action.type) {
        case IGET_CARRIERS_REQUEST.REQUEST:
            return loading;

        case IGET_CARRIERS_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_CARRIERS_REQUEST.FAILED:
            return failed(action.message);

        case ISAVE_CARRIER_FORM_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_CARRIER_FORM_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};