import { ReactNode, PureComponent } from "react";
import { Prompt, RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { hasPayload, isFailed, isNotLoaded, isSucceeded, Server } from "../../../../../redux/server";
import { getYHDSaveCarrierFormStatus } from "../../../../../redux/propsense/yhd/carrier/saveCarrierForm/saveCarrierFormAccessor";
import { IDispatch, IStore } from "../../../../../redux/reducers";
import { saveYHDCarrierFormLoadAction } from "../../../../../redux/propsense/yhd/carrier/saveCarrierForm/saveCarrierFormActions";
import { ISaveYHDCarrierFormRequest } from "../../../../../redux/propsense/yhd/carrier/saveCarrierForm/saveCarrierFormConstaints";
import { FieldValidator, FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../../../shared/fieldValidator";
import { ById, ByIdNumber, YHDAPIResponse } from "../../../../shared/publicInterfaces";
import { LAGreyPaper, LAPaperWithPadding } from "../../../../shared/paper";
import LAGridItem from "../../../../shared/gridList";
import LAGrid from "../../../../shared/grid";
import { LAButton, LAIconButton, LASaveAndCancelButton } from "../../../../shared/buttons";
import { LAExpansionPanel } from "../../../../shared/expansionPanel";
import LATextArea from "../../../../shared/textArea";
import { ArrowLeftIcon, DeleteIcon } from "../../../../shared/icons";
import { LADateTimePicker } from "../../../../shared/dateTimePicker";
import { addDashesToPhone, deleteText, generateRandomText, numberAndDashCheck, undefinedFunction, userName, ZEROTH } from "../../../../shared/constExports";
import { LACheckBox } from "../../../../shared/checkBox";
import { ROUTE } from "../../../../routes";
import queryString from "query-string";
import { IUpdateYHDCarrierFormRequest } from "../../../../../redux/propsense/yhd/carrier/updateCarrierForm/updateCarrierFormConstaints";
import { updateYHDCarrierFormLoadAction } from "../../../../../redux/propsense/yhd/carrier/updateCarrierForm/updateCarrierFormActions";
import { getYHDUpdateCarrierFormStatus } from "../../../../../redux/propsense/yhd/carrier/updateCarrierForm/updateCarrierFormAccessor";
import LAErrorBox from "../../../../shared/errorBox";
import { IYHDCarrier, IGetYHDCarriersRequest } from "../../../../../redux/propsense/yhd/carrier/getCarriers/getCarriersConstaints";
import { IGetTokenRequest, IToken } from "../../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenStatus } from "../../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { getYHDCarriersStatus } from "../../../../../redux/propsense/yhd/carrier/getCarriers/getCarriersAccessor";
import { getTokenLoadAction } from "../../../../../redux/sil/dryPlant/token/getTokenActions";
import { getYHDCarriersLoadAction } from "../../../../../redux/propsense/yhd/carrier/getCarriers/getCarriersActions";
import RequestStatus from "../../../../shared/requestStatusSnackbar";
import { DRY_PLANT_FORM_TILE_COLOR, WHITE_COLOR } from "../../../../shared/theme";
import { END_POINTS } from "../../../../../redux/endpoints";
import SilRoleCheck from "../../../../shared/silRoleCheck";
import { LADropDone } from "../../../../shared/dragDropFiles";
import LALinkButton from "../../../../shared/linkButton";
import LATextField from "../../../../shared/textField";
import { IGetYHDCarrierFleetTruckRequest, IYHDCarrierFleetTruck } from "../../../../../redux/propsense/yhd/carrier/getCarrierFleetTruck/getCarrierFleetTruckConstaints";
import { getYHDCarrierFleetTruckLoadAction } from "../../../../../redux/propsense/yhd/carrier/getCarrierFleetTruck/getCarrierFleetTruckActions";
import { getYHDCarrierFleetTruckStatus } from "../../../../../redux/propsense/yhd/carrier/getCarrierFleetTruck/getCarrierFleetTruckAccessor";
import { LADevExtremeGrid } from "../../../../shared/devExtreme";
import { LAPopover } from "../../../../shared/popOver";
import YHDCarrierFleetTruck from "./fleetTruck";

enum ICarrierFormSection {
    "Company" = 1,
    "Owner" = 2,
    "Dispatch" = 3,
    "Insurance" = 4,
    "Other" = 5,
    "Fleet" = 6,
    "Admin" = 7
};

const requiredFields = ["name", "mailing_Address", "city", "province", "postal_Code", "phone", "email", "owner_Name", "owner_Phone_Cell", "dispatch_Contact_Name", "dispatch_Email"];

interface ICarrierFormComponentStoreProps {
    SaveCarrierFormStatus: Server<string>;
    UpdateCarrierFormStatus: Server<string>;
    getToken: Server<YHDAPIResponse<IToken>>;
    getCarriers: Server<YHDAPIResponse<ByIdNumber<IYHDCarrier>>>;
    getYHDCarrierFleetTrucks: Server<YHDAPIResponse<IYHDCarrierFleetTruck[]>>;
};

interface ICarrierFormComponentDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getCarriersRequest: (data: IGetYHDCarriersRequest) => unknown;
    RequestSaveCarrierForm: (data: ISaveYHDCarrierFormRequest) => unknown;
    RequestUpdateCarrierForm: (data: IUpdateYHDCarrierFormRequest) => unknown;
    getYHDCarrierFleetTrucksRequest: (data: IGetYHDCarrierFleetTruckRequest) => unknown;
};

interface ICarrierFormComponentState {
    expanded: number[];
    cancelPopup: boolean;
    data: IYHDCarrierForm;
    popUpId: number | undefined;
    fleets: IYHDCarrierFleetTruck[];
    errors: ById<IFieldErrorKeyValue>;
};

const CarrierFormComponentStyles = styled(LAGreyPaper)`
    margin: 20px 20px;

`;

type ICarrierFormComponentProps =
    RouteComponentProps
    & ICarrierFormComponentStoreProps
    & ICarrierFormComponentDispatchProps;

class YHDCarrierFormComponent extends PureComponent<ICarrierFormComponentProps, ICarrierFormComponentState> {

    public constructor(props: ICarrierFormComponentProps) {
        super(props);
        this.state = {
            expanded: [1, 2, 3, 4, 5, 6, 7],
            data: {
                active: "Yes",
                approved: "Yes",
                modified: new Date().toISOString(),
                created: new Date().toISOString(),
                created_By: userName,
                modified_By: userName,
                corporate_Search_Files: undefined,
                wcB_Clearance_Letter_Files: undefined,
                insurance_Certificate_Files: undefined,
                carrier_Profile_Files: undefined,
                carrier_Agreement_Files: undefined,
                rate_Scheduled_Files: undefined,
                maintenance_Inspection_Policy_Files: undefined,
                fleet_Trucks_List_Files: undefined,
                hexCode: ''
            },
            fleets: [],
            errors: {},
            popUpId: undefined,
            cancelPopup: false
        };
    }

    public componentDidMount(): void {
        this.getData();
    };

    public async componentDidUpdate(prevProps: ICarrierFormComponentProps): Promise<void> {
        if ((this.props.getCarriers !== prevProps.getCarriers) || (this.props.getYHDCarrierFleetTrucks !== prevProps.getYHDCarrierFleetTrucks) || (this.props.getToken !== prevProps.getToken))
            this.getData();

        if ((this.props.SaveCarrierFormStatus !== prevProps.SaveCarrierFormStatus) && isSucceeded(this.props.SaveCarrierFormStatus))
            this.props.history.push(ROUTE.PROPSENSE.YHD.CARRIERS.CARRIERS);

        if ((this.props.UpdateCarrierFormStatus !== prevProps.UpdateCarrierFormStatus) && isSucceeded(this.props.UpdateCarrierFormStatus))
            this.props.history.push(ROUTE.PROPSENSE.YHD.CARRIERS.CARRIERS);
    };


    public render(): ReactNode {

        const { SaveCarrierFormStatus, UpdateCarrierFormStatus } = this.props;
        const { expanded, data, errors, fleets, popUpId, cancelPopup } = this.state;

        const handleCompany = (): void => this.handleExpand(ICarrierFormSection.Company);
        const handleOwner = (): void => this.handleExpand(ICarrierFormSection.Owner);
        const handleDispatch = (): void => this.handleExpand(ICarrierFormSection.Dispatch);
        const handleInsurance = (): void => this.handleExpand(ICarrierFormSection.Insurance);
        const handleOther = (): void => this.handleExpand(ICarrierFormSection.Other);
        const handleFleet = (): void => this.handleExpand(ICarrierFormSection.Fleet);
        const onFileDrop = (name: string, event?: any): void => this.uploadFile(name, event);
        const handlePhone = (name: string, value: string): void => numberAndDashCheck(value) ? this.handleChange(name, value) : undefined;
        const onPhoneBlur = (name: string, value: string): void => value.toString().length > 9 ? this.handleChange(name, addDashesToPhone(value.toString())) : undefined;

        let sError: string = "";

        const corpFiles = (data && data.corporate_Search_File_Name) ? data.corporate_Search_File_Name.split(";") : [];
        const rateSchFiles = (data && data.rate_Scheduled_File_Name) ? data.rate_Scheduled_File_Name.split(";") : [];
        const profileFiles = (data && data.carrier_Profile_File_Name) ? data.carrier_Profile_File_Name.split(";") : [];
        const agreementFiles = (data && data.carrier_Agreement_File_Name) ? data.carrier_Agreement_File_Name.split(";") : [];
        const truckFiles = (data && data.fleet_Trucks_List_File_Name) ? data.fleet_Trucks_List_File_Name.split(";") : [];
        const wcbFiles = (data && data.wcB_Clearance_Letter_File_Name) ? data.wcB_Clearance_Letter_File_Name.split(";") : [];
        const insuranceFiles = (data && data.insurance_Certificate_File_Name) ? data.insurance_Certificate_File_Name.split(";") : [];
        const maintenanceFiles = (data && data.maintenance_Inspection_Policy_File_Name) ? data.maintenance_Inspection_Policy_File_Name.split(";") : [];

        if (isFailed(SaveCarrierFormStatus)) {
            sError = SaveCarrierFormStatus.message;
        } else if (isFailed(UpdateCarrierFormStatus)) {
            sError = UpdateCarrierFormStatus.message;
        };

        return (
            <CarrierFormComponentStyles>
                <SilRoleCheck error={true} roleFor="yhdCarrierAccess">
                    <LAGrid>

                        <LAGridItem xs={12}>
                            <LAButton startIcon={<ArrowLeftIcon color={WHITE_COLOR} />} label="Back to list" onClick={this.handleCancel} />
                            <h2 className="text-center">{(data.id && data.id !== 0) ? "UPDATE" : "ADD"} CARRIER FORM</h2>
                            <hr />
                            <LAExpansionPanel color={DRY_PLANT_FORM_TILE_COLOR} textColor={WHITE_COLOR} label="COMPANY" expanded={expanded.includes(ICarrierFormSection.Company)} onClick={handleCompany}>
                                <LAGrid>

                                    <LAGridItem xs={6}>
                                        <LATextArea
                                            rowsMax={1}
                                            fullWidth={true}
                                            name="name"
                                            onChange={this.handleChange}
                                            label="Name"
                                            value={data.name ?? ""}
                                            errorText={errors["name"] ? errors["name"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LATextArea
                                            rowsMax={1}
                                            fullWidth={true}
                                            name="alternate_Name"
                                            onChange={this.handleChange}
                                            label="Alternate Name"
                                            value={data.alternate_Name ?? ""}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={3}>
                                        <LACheckBox
                                            name="active"
                                            label="Active"
                                            value={data.active && data.active === "Yes" ? true : false}
                                            onChange={this.handleCheckBoxChange}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={3}>
                                        <LACheckBox
                                            name="approved"
                                            label="Approved"
                                            onChange={this.handleCheckBoxChange}
                                            value={data.approved && data.approved === "Yes" ? true : false}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LADateTimePicker
                                            fullWidth={true}
                                            name="approved_Date"
                                            onChange={this.onDateChange}
                                            label="Approved Date"
                                            value={data.approved_Date ?? ""}
                                            errorText={errors["approved_Date"] ? errors["approved_Date"].message : undefined}
                                        />
                                    </LAGridItem>

                                    {((data.approved === undefined) || (data.approved === "No")) ? <LAGridItem xs={6}>
                                        <LATextArea
                                            rowsMax={1}
                                            fullWidth={true}
                                            name="unApproved_Reason"
                                            label="Unapproved Reason"
                                            onChange={this.handleChange}
                                            value={data.unApproved_Reason ?? ""}
                                            errorText={errors["unApproved_Reason"] ? errors["unApproved_Reason"].message : undefined}
                                        />
                                    </LAGridItem>
                                        : <></>}

                                    <LAGridItem xs={6}>
                                        <LATextArea
                                            rowsMax={1}
                                            fullWidth={true}
                                            name="mailing_Address"
                                            onChange={this.handleChange}
                                            label="Mailing Address"
                                            value={data.mailing_Address ?? ""}
                                            errorText={errors["mailing_Address"] ? errors["mailing_Address"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LATextArea
                                            rowsMax={1}
                                            fullWidth={true}
                                            name="city"
                                            onChange={this.handleChange}
                                            label="City"
                                            value={data.city ?? ""}
                                            errorText={errors["city"] ? errors["city"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LATextArea
                                            rowsMax={1}
                                            fullWidth={true}
                                            name="province"
                                            onChange={this.handleChange}
                                            label="Province"
                                            value={data.province ?? ""}
                                            errorText={errors["province"] ? errors["province"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LATextArea
                                            rowsMax={1}
                                            fullWidth={true}
                                            name="postal_Code"
                                            onChange={this.handleChange}
                                            label="Postal Code"
                                            value={data.postal_Code ?? ""}
                                            errorText={errors["postal_Code"] ? errors["postal_Code"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LATextArea
                                            rowsMax={1}
                                            type="number"
                                            fullWidth={true}
                                            name="phone"
                                            label="Phone"
                                            value={data.phone ?? ""}
                                            onChange={handlePhone}
                                            onBlur={() => onPhoneBlur("phone", data.phone ?? "")}
                                            errorText={errors["phone"] ? errors["phone"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LATextArea
                                            rowsMax={1}
                                            fullWidth={true}
                                            name="email"
                                            onChange={this.handleChange}
                                            label="Email"
                                            value={data.email ?? ""}
                                            errorText={errors["email"] ? errors["email"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LATextArea
                                            minRows={5}
                                            rowsMax={10}
                                            name="notes"
                                            label="Notes"
                                            fullWidth={true}
                                            value={data.notes ?? ""}
                                            onChange={this.handleChange}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LADateTimePicker
                                            fullWidth={true}
                                            name="carrier_Package_Date_Sent"
                                            onChange={this.onDateChange}
                                            label="Carrier Package Date Sent"
                                            value={data.carrier_Package_Date_Sent ?? ""}
                                            errorText={errors["carrier_Package_Date_Sent"] ? errors["carrier_Package_Date_Sent"].message : undefined}
                                        />
                                    </LAGridItem>


                                </LAGrid>
                            </LAExpansionPanel>
                        </LAGridItem>

                        <LAGridItem xs={12}>
                            <LAExpansionPanel color={DRY_PLANT_FORM_TILE_COLOR} textColor={WHITE_COLOR} label="OWNER" expanded={expanded.includes(ICarrierFormSection.Owner)} onClick={handleOwner}>
                                <LAGrid>

                                    <LAGridItem xs={6}>
                                        <LATextArea
                                            rowsMax={1}
                                            fullWidth={true}
                                            name="owner_Name"
                                            onChange={this.handleChange}
                                            label="Owner Name"
                                            value={data.owner_Name ?? ""}
                                            errorText={errors["owner_Name"] ? errors["owner_Name"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LATextField
                                            fullWidth={true}
                                            name="owner_Email"
                                            onChange={this.handleChange}
                                            label="Owner Email"
                                            value={data.owner_Email ?? ""}
                                            errorText={errors["owner_Email"] ? errors["owner_Email"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LATextArea
                                            rowsMax={1}
                                            fullWidth={true}
                                            name="owner_Phone_Cell"
                                            type="number"
                                            label="Owner Phone Cell"
                                            value={data.owner_Phone_Cell ?? ""}
                                            onChange={handlePhone}
                                            onBlur={() => onPhoneBlur("owner_Phone_Cell", data.owner_Phone_Cell ?? "")}
                                            errorText={errors["owner_Phone_Cell"] ? errors["owner_Phone_Cell"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LATextArea
                                            rowsMax={1}
                                            fullWidth={true}
                                            type="number"
                                            onChange={handlePhone}
                                            name="owner_Phone_Home"
                                            label="Owner Phone Home"
                                            value={data.owner_Phone_Home ?? ""}
                                            onBlur={() => onPhoneBlur("owner_Phone_Home", data.owner_Phone_Home ?? "")}
                                            errorText={errors["owner_Phone_Home"] ? errors["owner_Phone_Home"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LATextField
                                            fullWidth={true}
                                            onChange={this.handleChange}
                                            name="owner_Drivers_License_No"
                                            label="Owner Drivers License No"
                                            value={data.owner_Drivers_License_No ?? ""}
                                            errorText={errors["owner_Drivers_License_No"] ? errors["owner_Drivers_License_No"].message : undefined}
                                        />
                                    </LAGridItem>

                                </LAGrid>
                            </LAExpansionPanel>
                        </LAGridItem>

                        <LAGridItem xs={12}>
                            <LAExpansionPanel color={DRY_PLANT_FORM_TILE_COLOR} textColor={WHITE_COLOR} label="DISPATCH" expanded={(expanded.includes(ICarrierFormSection.Dispatch))} onClick={handleDispatch}>
                                <LAGrid>

                                    <LAGridItem xs={6}>
                                        <LATextArea
                                            rowsMax={1}
                                            fullWidth={true}
                                            name="dispatch_Contact_Name"
                                            onChange={this.handleChange}
                                            label="Dispatch Contract Name"
                                            value={data.dispatch_Contact_Name ?? ""}
                                            errorText={errors["dispatch_Contact_Name"] ? errors["dispatch_Contact_Name"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LATextField
                                            fullWidth={true}
                                            name="dispatch_Email"
                                            onChange={this.handleChange}
                                            label="Dispatch Email"
                                            value={data.dispatch_Email ?? ""}
                                            errorText={errors["dispatch_Email"] ? errors["dispatch_Email"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LATextArea
                                            rowsMax={1}
                                            fullWidth={true}
                                            type="number"
                                            name="dispatch_Phone_Cell"
                                            onChange={handlePhone}
                                            onBlur={() => onPhoneBlur("dispatch_Phone_Cell", data.dispatch_Phone_Cell ?? "")}
                                            label="Dispatch Phone Cell"
                                            value={data.dispatch_Phone_Cell ?? ""}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LATextArea
                                            rowsMax={1}
                                            fullWidth={true}
                                            type="number"
                                            name="dispatch_Phone_Work"
                                            onChange={handlePhone}
                                            onBlur={() => onPhoneBlur("dispatch_Phone_Work", data.dispatch_Phone_Work ?? "")}
                                            label="Disptach Phone Work"
                                            value={data.dispatch_Phone_Work ?? ""}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LATextArea
                                            rowsMax={1}
                                            fullWidth={true}
                                            name="dispatch_Contact_After_Hours"
                                            onChange={this.handleChange}
                                            label="Dispatch Contact After Hours"
                                            value={data.dispatch_Contact_After_Hours ?? ""}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LATextArea
                                            rowsMax={1}
                                            type="number"
                                            fullWidth={true}
                                            onChange={handlePhone}
                                            onBlur={() => onPhoneBlur("dispatch_Phone_After_Hours", data.dispatch_Phone_After_Hours ?? "")}
                                            name="dispatch_Phone_After_Hours"
                                            label="Dispatch Phone After Hours"
                                            value={data.dispatch_Phone_After_Hours ?? ""}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LATextArea
                                            rowsMax={1}
                                            fullWidth={true}
                                            name="username"
                                            onChange={undefinedFunction}
                                            label="Username"
                                            disabled={true}
                                            value={data.username ?? ""}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6} md={4}>
                                        <LATextArea
                                            rowsMax={1}
                                            fullWidth={true}
                                            name="password"
                                            label="Password"
                                            value={data.password ?? ""}
                                            onChange={this.handleChange}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} md={2} className="pt-3">
                                        <LAButton label="Auto Generate" onClick={this.onGenerate} />
                                    </LAGridItem>

                                    {((data.id) && (data.id > 0)) && <LAGridItem xs={12}>
                                        <LAGreyPaper>
                                            <strong>
                                                Carrier Portal Information
                                            </strong>
                                            <hr />
                                            <br />

                                            <span>
                                                Username: ${data.username}
                                            </span>
                                            <br />

                                            <span>
                                                Password: ${data.password}
                                            </span>
                                            <br />

                                            <span>
                                                Go To Portal:
                                            </span>
                                            <a href={`https://portal.propsense.com/login/yhd_carrier?user=${data.username}&pass=${data.password}`} target="_blank"> CLICK HERE</a>

                                        </LAGreyPaper>
                                    </LAGridItem>}
                                    {((data.id) && (data.id > 0)) && <LAGridItem xs={12}>
                                        <LAGreyPaper>
                                            <strong>
                                                Drivers Portal Information
                                            </strong>
                                            <hr />
                                            <br />

                                            <span>
                                                Go To Portal:
                                            </span>
                                            <a href={`https://ext-apps.propsense.com/Yhd/?carrierName=${data.hexCode}`} target="_blank"> CLICK HERE</a>

                                        </LAGreyPaper>
                                    </LAGridItem>}

                                </LAGrid>
                            </LAExpansionPanel>
                        </LAGridItem>

                        <LAGridItem xs={12}>
                            <LAExpansionPanel color={DRY_PLANT_FORM_TILE_COLOR} textColor={WHITE_COLOR} label="INSURANCE" expanded={expanded.includes(ICarrierFormSection.Insurance)} onClick={handleInsurance}>
                                <LAGrid>

                                    <LAGridItem xs={6}>
                                        <LATextArea
                                            rowsMax={1}
                                            fullWidth={true}
                                            name="gst"
                                            onChange={this.handleChange}
                                            label="GST"
                                            value={data.gst ?? ""}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LATextArea
                                            rowsMax={1}
                                            fullWidth={true}
                                            name="wcb"
                                            onChange={this.handleChange}
                                            label="WCB"
                                            value={data.wcb ?? ""}
                                            errorText={errors["wcb"] ? errors["wcb"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LADateTimePicker
                                            fullWidth={true}
                                            name="wcB_Clearance_Letter_Date"
                                            onChange={this.onDateChange}
                                            label="WCB Clearance Letter Date"
                                            value={data.wcB_Clearance_Letter_Date ?? ""}
                                            errorText={errors["wcB_Clearance_Letter_Date"] ? errors["wcB_Clearance_Letter_Date"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6} className="text-center">
                                        <strong>WCB Clearance Letter File</strong>
                                        <LADropDone onFileDrop={(e) => onFileDrop("wcB_Clearance_Letter_Files", e)} />
                                        
                                        <LAGrid>
                                            {wcbFiles.map((fName, ind) => {
                                                const findFile = data.wcB_Clearance_Letter_Files ? data.wcB_Clearance_Letter_Files.find(r => r.name === fName)?.base64String : undefined;
                                                 const CorporateSearchFileDownload = (): void => this.downloadFile(fName, "wcB_Clearance_Letter_Files", findFile);
                                                 const onRemove = (event: any): void => this.onFormRemove("wcB_Clearance_Letter_File_Name", fName)
                                                return (
                                                    <LAGridItem key={ind} xs={6}>

                                                        <LALinkButton
                                                            className="pl-5"
                                                            label={fName}
                                                            onClick={CorporateSearchFileDownload} />

                                                        <LAIconButton
                                                            name={fName}
                                                            label="Remove"
                                                            icon={<DeleteIcon />}
                                                            onClick={onRemove} />
                                                    </LAGridItem>
                                                )
                                            })}
                                        </LAGrid>
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LADateTimePicker
                                            fullWidth={true}
                                            name="insurance_Certificate_Date"
                                            onChange={this.onDateChange}
                                            label="Insurance Certificate Date"
                                            value={data.insurance_Certificate_Date ?? ""}
                                            errorText={errors["insurance_Certificate_Date"] ? errors["insurance_Certificate_Date"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6} className="text-center">
                                        <strong>Insurance Certificate File</strong>
                                        <LADropDone onFileDrop={(e) => onFileDrop("insurance_Certificate_Files", e)} />
                                        
                                        <LAGrid>
                                            {insuranceFiles.map((fName, ind) => {
                                                const findFile = data.insurance_Certificate_Files ? data.insurance_Certificate_Files.find(r => r.name === fName)?.base64String : undefined;
                                                 const CorporateSearchFileDownload = (): void => this.downloadFile(fName, "insurance_Certificate_Files", findFile);
                                                 const onRemove = (event: any): void => this.onFormRemove("insurance_Certificate_File_Name", fName)
                                                return (
                                                    <LAGridItem key={ind} xs={6}>

                                                        <LALinkButton
                                                            className="pl-5"
                                                            label={fName}
                                                            onClick={CorporateSearchFileDownload} />

                                                        <LAIconButton
                                                            name={fName}
                                                            label="Remove"
                                                            icon={<DeleteIcon />}
                                                            onClick={onRemove} />
                                                    </LAGridItem>
                                                )
                                            })}
                                        </LAGrid>
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LADateTimePicker
                                            fullWidth={true}
                                            name="insurance_Expiry"
                                            onChange={this.onDateChange}
                                            label="Insurance Expiry"
                                            value={data.insurance_Expiry ?? ""}
                                            errorText={errors["insurance_Expiry"] ? errors["insurance_Expiry"].message : undefined}
                                        />
                                    </LAGridItem>

                                </LAGrid>
                            </LAExpansionPanel>
                        </LAGridItem>

                        <LAGridItem xs={12}>
                            <LAExpansionPanel color={DRY_PLANT_FORM_TILE_COLOR} textColor={WHITE_COLOR} label="OTHER" expanded={expanded.includes(ICarrierFormSection.Other)} onClick={handleOther}>
                                <LAGrid>

                                    <LAGridItem xs={6}>
                                        <LADateTimePicker
                                            fullWidth={true}
                                            name="last_Verified"
                                            onChange={this.onDateChange}
                                            label="Last Verified"
                                            value={data.last_Verified ?? ""}
                                            errorText={errors["last_Verified"] ? errors["last_Verified"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LADateTimePicker
                                            fullWidth={true}
                                            name="carrier_Profile"
                                            onChange={this.onDateChange}
                                            label="Carrier Profile"
                                            value={data.carrier_Profile ?? ""}
                                            errorText={errors["carrier_Profile"] ? errors["carrier_Profile"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6} className="text-center">
                                        <strong>Carrier Profile File</strong>
                                        <LADropDone onFileDrop={(e) => onFileDrop("carrier_Profile_Files", e)} />
                                        
                                        <LAGrid>
                                            {profileFiles.map((fName, ind) => {
                                                const findFile = data.carrier_Profile_Files ? data.carrier_Profile_Files.find(r => r.name === fName)?.base64String : undefined;
                                                 const CorporateSearchFileDownload = (): void => this.downloadFile(fName, "carrier_Profile_Files", findFile);
                                                 const onRemove = (event: any): void => this.onFormRemove("carrier_Profile_File_Name", fName)
                                                return (
                                                    <LAGridItem key={ind} xs={6}>

                                                        <LALinkButton
                                                            className="pl-5"
                                                            label={fName}
                                                            onClick={CorporateSearchFileDownload} />

                                                        <LAIconButton
                                                            name={fName}
                                                            label="Remove"
                                                            icon={<DeleteIcon />}
                                                            onClick={onRemove} />
                                                    </LAGridItem>
                                                )
                                            })}
                                        </LAGrid>
                                    </LAGridItem>

                                    <LAGridItem xs={6} className="text-center">
                                        <strong>Company Information File</strong>
                                        <LADropDone onFileDrop={(e) => onFileDrop("corporate_Search_Files", e)} />
                                        
                                        <LAGrid>
                                            {corpFiles.map((fName, ind) => {
                                                const findFile = data.corporate_Search_Files ? data.corporate_Search_Files.find(r => r.name === fName)?.base64String : undefined;
                                                 const CorporateSearchFileDownload = (): void => this.downloadFile(fName, "corporate_Search_Files", findFile);
                                                 const onRemove = (event: any): void => this.onFormRemove("corporate_Search_File_Name", fName)
                                                return (
                                                    <LAGridItem key={ind} xs={6}>

                                                        <LALinkButton
                                                            className="pl-5"
                                                            label={fName}
                                                            onClick={CorporateSearchFileDownload} />

                                                        <LAIconButton
                                                            name={fName}
                                                            label="Remove"
                                                            icon={<DeleteIcon />}
                                                            onClick={onRemove} />
                                                    </LAGridItem>
                                                )
                                            })}
                                        </LAGrid>
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LADateTimePicker
                                            fullWidth={true}
                                            name="audit_Completed_Date"
                                            onChange={this.onDateChange}
                                            label="Audit Completed Date"
                                            value={data.audit_Completed_Date ?? ""}
                                            errorText={errors["audit_Completed_Date"] ? errors["audit_Completed_Date"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LADateTimePicker
                                            fullWidth={true}
                                            name="audit_Approved_Date"
                                            onChange={this.onDateChange}
                                            label="Audit Approved Date"
                                            value={data.audit_Approved_Date ?? ""}
                                            errorText={errors["audit_Approved_Date"] ? errors["audit_Approved_Date"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LADateTimePicker
                                            fullWidth={true}
                                            name="safety_Fitness_Expiry"
                                            onChange={this.onDateChange}
                                            label="Safety Fitness Expiry"
                                            value={data.safety_Fitness_Expiry ?? ""}
                                            errorText={errors["safety_Fitness_Expiry"] ? errors["safety_Fitness_Expiry"].message : undefined}
                                        />
                                    </LAGridItem>

                                </LAGrid>
                            </LAExpansionPanel>
                        </LAGridItem>

                        <LAGridItem xs={12}>
                            <LAExpansionPanel color={DRY_PLANT_FORM_TILE_COLOR} textColor={WHITE_COLOR} label="FLEET" expanded={expanded.includes(ICarrierFormSection.Fleet)} onClick={handleFleet}>
                                <LAGrid>

                                    <LAGridItem xs={6}>
                                        <LATextField
                                            fullWidth={true}
                                            name="truck_Code_Tandem"
                                            onChange={this.handleChange}
                                            label="Truck Code Tandem"
                                            value={data.truck_Code_Tandem ?? ""}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LATextField
                                            fullWidth={true}
                                            name="truck_Code_Tridem"
                                            onChange={this.handleChange}
                                            label="Truck Code Tridem"
                                            value={data.truck_Code_Tridem ?? ""}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LATextField
                                            fullWidth={true}
                                            name="truck_Code_Wagon"
                                            onChange={this.handleChange}
                                            label="Truck Code Wagon"
                                            value={data.truck_Code_Wagon ?? ""}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LATextField
                                            fullWidth={true}
                                            name="truck_Code_Quad"
                                            onChange={this.handleChange}
                                            label="Truck Code Quad"
                                            value={data.truck_Code_Quad ?? ""}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LATextField
                                            fullWidth={true}
                                            name="truck_Code_SuperB"
                                            onChange={this.handleChange}
                                            label="Truck Code SuperB"
                                            value={data.truck_Code_SuperB ?? ""}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LADateTimePicker
                                            fullWidth={true}
                                            name="carrier_Agreement_Date"
                                            onChange={this.onDateChange}
                                            label="Carrier Agreement Date"
                                            value={data.carrier_Agreement_Date ?? ""}
                                            errorText={errors["carrier_Agreement_Date"] ? errors["carrier_Agreement_Date"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6} className="text-center">
                                        <strong>Carrier Agreement File</strong>
                                        <LADropDone onFileDrop={(e) => onFileDrop("carrier_Agreement_Files", e)} />
                                        
                                        <LAGrid>
                                            {agreementFiles.map((fName, ind) => {
                                                const findFile = data.carrier_Agreement_Files ? data.carrier_Agreement_Files.find(r => r.name === fName)?.base64String : undefined;
                                                 const CorporateSearchFileDownload = (): void => this.downloadFile(fName, "carrier_Agreement_Files", findFile);
                                                 const onRemove = (event: any): void => this.onFormRemove("carrier_Agreement_File_Name", fName)
                                                return (
                                                    <LAGridItem key={ind} xs={6}>

                                                        <LALinkButton
                                                            className="pl-5"
                                                            label={fName}
                                                            onClick={CorporateSearchFileDownload} />

                                                        <LAIconButton
                                                            name={fName}
                                                            label="Remove"
                                                            icon={<DeleteIcon />}
                                                            onClick={onRemove} />
                                                    </LAGridItem>
                                                )
                                            })}
                                        </LAGrid>
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LADateTimePicker
                                            fullWidth={true}
                                            name="rate_Scheduled_Date"
                                            onChange={this.onDateChange}
                                            label="Rate Schedule Date"
                                            value={data.rate_Scheduled_Date ?? ""}
                                            errorText={errors["rate_Scheduled_Date"] ? errors["rate_Scheduled_Date"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6} className="text-center">
                                        <strong>Rate Schedule File</strong>
                                        <LADropDone onFileDrop={(e) => onFileDrop("rate_Scheduled_Files", e)} />
                                        
                                        <LAGrid>
                                            {rateSchFiles.map((fName, ind) => {
                                                const findFile = data.rate_Scheduled_Files ? data.rate_Scheduled_Files.find(r => r.name === fName)?.base64String : undefined;
                                                 const CorporateSearchFileDownload = (): void => this.downloadFile(fName, "rate_Scheduled_Files", findFile);
                                                 const onRemove = (event: any): void => this.onFormRemove("rate_Scheduled_File_Name", fName)
                                                return (
                                                    <LAGridItem key={ind} xs={6}>

                                                        <LALinkButton
                                                            className="pl-5"
                                                            label={fName}
                                                            onClick={CorporateSearchFileDownload} />

                                                        <LAIconButton
                                                            name={fName}
                                                            label="Remove"
                                                            icon={<DeleteIcon />}
                                                            onClick={onRemove} />
                                                    </LAGridItem>
                                                )
                                            })}
                                        </LAGrid>
                                    </LAGridItem>

                                    <LAGridItem xs={6}>
                                        <LADateTimePicker
                                            fullWidth={true}
                                            name="maintenance_Inspection_Policy_Date"
                                            onChange={this.onDateChange}
                                            label="Maintenance Inspection Policy Date"
                                            value={data.maintenance_Inspection_Policy_Date ?? ""}
                                            errorText={errors["maintenance_Inspection_Policy_Date"] ? errors["maintenance_Inspection_Policy_Date"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6} className="text-center">
                                        <strong>Maintenance Inspection Policy File</strong>
                                        <LADropDone onFileDrop={(e) => onFileDrop("maintenance_Inspection_Policy_Files", e)} />
                                        
                                        <LAGrid>
                                            {maintenanceFiles.map((fName, ind) => {
                                                const findFile = data.maintenance_Inspection_Policy_Files ? data.maintenance_Inspection_Policy_Files.find(r => r.name === fName)?.base64String : undefined;
                                                 const CorporateSearchFileDownload = (): void => this.downloadFile(fName, "maintenance_Inspection_Policy_Files", findFile);
                                                 const onRemove = (event: any): void => this.onFormRemove("maintenance_Inspection_Policy_File_Name", fName)
                                                return (
                                                    <LAGridItem key={ind} xs={6}>

                                                        <LALinkButton
                                                            className="pl-5"
                                                            label={fName}
                                                            onClick={CorporateSearchFileDownload} />

                                                        <LAIconButton
                                                            name={fName}
                                                            label="Remove"
                                                            icon={<DeleteIcon />}
                                                            onClick={onRemove} />
                                                    </LAGridItem>
                                                )
                                            })}
                                        </LAGrid>
                                    </LAGridItem>

                                    <LAGridItem xs={6} className="text-center">
                                        <strong>Fleet Truck File</strong>
                                        <LADropDone onFileDrop={(e) => onFileDrop("fleet_Trucks_List_Files", e)} />
                                        
                                        <LAGrid>
                                            {truckFiles.map((fName, ind) => {
                                                const findFile = data.fleet_Trucks_List_Files ? data.fleet_Trucks_List_Files.find(r => r.name === fName)?.base64String : undefined;
                                                 const CorporateSearchFileDownload = (): void => this.downloadFile(fName, "fleet_Trucks_List_Files", findFile);
                                                 const onRemove = (event: any): void => this.onFormRemove("fleet_Trucks_List_File_Name", fName)
                                                return (
                                                    <LAGridItem key={ind} xs={6}>

                                                        <LALinkButton
                                                            className="pl-5"
                                                            label={fName}
                                                            onClick={CorporateSearchFileDownload} />

                                                        <LAIconButton
                                                            name={fName}
                                                            label="Remove"
                                                            icon={<DeleteIcon />}
                                                            onClick={onRemove} />
                                                    </LAGridItem>
                                                )
                                            })}
                                        </LAGrid>
                                    </LAGridItem>


                                    <LAGridItem xs={12}>

                                        <div onClick={this.onFleetAdd} className="dx-widget dx-button dx-button-mode-contained dx-button-normal dx-button-has-text dx-button-has-icon dx-datagrid-toolbar-button dx-edit-button dx-datagrid-addrow-button">
                                            <div className="dx-button-content"><i className="dx-icon dx-icon-edit-button-addrow"></i></div>
                                        </div>

                                        {(data.id && data.id !== 0) && fleets &&
                                            <LADevExtremeGrid
                                                height={300}
                                                id="fleet-truck"
                                                columnWidth={175}
                                                searchPanel={false}
                                                filterHeader={false}
                                                moveBtnToLeft={true}
                                                data={fleets}
                                                onClick={undefinedFunction}
                                                onEdit={this.handleFleetTruck}
                                                onNewRowInsert={this.onFleetAdd}
                                                columns={[
                                                    { name: "asgA_No", caption: "ASGA No", type: "string" },
                                                    { name: "active", caption: "Active", type: "string" },
                                                    { name: "truck_Type", caption: "Truck Type", type: "string" },
                                                    { name: "truck_Registration_No", caption: "Truck Registration No", type: "string" },
                                                    { name: "truck_Registration_Expiry", caption: "Truck Registration Expiry", type: "string" },
                                                    { name: "truck_Inspection_Date", caption: "Truck Inspection Date", type: "date" },
                                                    { name: "fleet_Trucks", caption: "Fleet Truck", type: "string" },
                                                    { name: "trailer_Inspection_Date", caption: "Trailer Inspection Date", type: "date" },
                                                    { name: "modified", caption: "Modified", type: "string", sortDesc: true },
                                                ]}
                                            />}
                                    </LAGridItem>

                                </LAGrid>
                            </LAExpansionPanel>
                        </LAGridItem>

                        <LAGridItem xs={12}>
                            <LAPaperWithPadding>
                                {sError.length > 0 && <LAGridItem className="text-center" xs={12}>
                                    <LAErrorBox text={sError} />
                                </LAGridItem>}

                                <LASaveAndCancelButton
                                    onSave={this.handleSave}
                                    onCancel={this.handleCancel}
                                    disableSave={Object.values(errors).length > ZEROTH ? true : undefined}
                                />
                            </LAPaperWithPadding>
                        </LAGridItem>

                    </LAGrid>


                    {(popUpId !== undefined) && <LAPopover anchorRef={null} open={(popUpId !== undefined) ? true : false} onClose={this.handleFleetTruckClose}>
                            <YHDCarrierFleetTruck {...this.props} id={popUpId} carrierName={data.name} onClose={this.handleFleetTruckClose} />
                    </LAPopover>}

                    <Prompt when={cancelPopup} message="Any unsaved changes will be lost" />
                    <RequestStatus requestStatus={SaveCarrierFormStatus.kind} successMessage="New Form successfully saved" />
                    <RequestStatus requestStatus={UpdateCarrierFormStatus.kind} successMessage="Form successfully updated" />
                </SilRoleCheck>
            </CarrierFormComponentStyles>
        );
    }

    private onFleetAdd = (): void => {
        this.setState({ 
            popUpId: 0,
            cancelPopup: true
         });
    };

    private handleFleetTruck = (e: any): void => {
        this.setState({ 
            popUpId: this.state.fleets[e.row.dataIndex].id,
            cancelPopup: true
         });
    };

    private handleFleetTruckClose = (): void => {
        this.setState({ popUpId: undefined });
    };

    private onDateChange = (name: string, value: string): void => {
        const date = new Date(value);
        let errors = { ...this.state.errors };

        if (date.toString() === "Invalid Date") {
            if (!errors[name]) {
                errors[name] = { key: name, message: "Invalid Date" };
            }
        } else {
            if (errors[name])
                delete errors[name];
        };

        if (requiredFields.includes(name)) {
            errors = this.errorChecker(name, value, errors, 1, true);
        };

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [name]: value
            },
            errors,
            cancelPopup: true
        });
    };

    private onGenerate = (): void => {
        let errors = this.state.errors;
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                password: generateRandomText(Math.floor(Math.random() * (8 - 6 + 1)) + 6)
            },
            errors,
            cancelPopup: true
        });
    };

    private getData = (): void => {
        const query = queryString.parse(this.props.location.search);
        const id = query.id ?? "0";

        this.callServer();

        if (id === "0") {
            let errors = { ...this.state.errors };
            requiredFields.forEach(x => {
                errors = this.errorChecker(x, "", errors, 1, true);
            });
            this.setState({ errors });
        };

        if (hasPayload(this.props.getCarriers) && id !== "0") {

            const data = this.props.getCarriers.payload.response[Number(id)];
            let fleets: IYHDCarrierFleetTruck[] = [];

            if (hasPayload(this.props.getYHDCarrierFleetTrucks)) {
                fleets = Object.values(this.props.getYHDCarrierFleetTrucks.payload.response)
                    .filter(x => x.fleet_Trucks === data.name)

            }

            this.setState({ data, fleets });
        };
    };

    private callServer = (): void => {

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getYHDCarrierFleetTrucks))
            this.props.getYHDCarrierFleetTrucksRequest({
                token: this.props.getToken.payload.response.token
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getCarriers)) {
            this.props.getCarriersRequest({
                token: this.props.getToken.payload.response.token
            });
        };

        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });
    };

    private handleCancel = (): void => {
        this.props.history.push(ROUTE.PROPSENSE.YHD.CARRIERS.CARRIERS);
    };

    private handleSave = (): void => {
        const data = this.state.data;
        if (hasPayload(this.props.getToken)) {
            const { id } = data;
            data.modified_By = userName;
            if ((id === undefined) || (id === 0)) {
                this.props.RequestSaveCarrierForm({
                    token: this.props.getToken.payload.response.token,
                    request: data
                });
            } else {
                this.props.RequestUpdateCarrierForm({
                    token: this.props.getToken.payload.response.token,
                    request: data
                });
            }
            // this.setState({ cancelPopup: false });
        }
    };

    private onFormRemove = (name: string, fileName: string): void => {
            const selection = window.confirm(deleteText);

            if(selection) {
                const values: any = this.state.data;
                const objName = name.split("File_Name")[0];

                    const corpFileNames = values[name] ? values[name].split(";") : [];
                    const corpFiles = values[objName + "Files"] ? values[objName + "Files"] : [];

                    const findName = corpFileNames.findIndex((x: any) => x === fileName);
                    const findFile = corpFiles.findIndex((x: any) => x.name === fileName);

                    if(findName >= 0)
                        corpFileNames.splice(findName, 1);
                    
                    if(findFile >= 0){
                        corpFiles.splice(findFile, 1);
                    }

                    const names = corpFileNames.join(";");

                    this.setState({
                        ...this.state,
                        data: {
                            ...values,
                            [name]: names,
                            [objName + "Files"]: corpFiles
                        },
                        cancelPopup: true
                    });
        }
    };
    

    private downloadFile = (name?: string, objName?: string, base64?: string): void => {
console.log(name)
console.log(base64)
console.log(objName)

        if (name) {
            if ((base64 !== undefined) && (base64 !== null)) {
                let a = document.createElement("a");
                a.href = base64;
                a.download = name;
                a.click();
            };

            if ((base64 === undefined) || (base64 === null)) {

                if ((objName !== undefined) || (objName !== null)) {
                    if (hasPayload(this.props.getToken) && this.state.data.id) {

                        fetch(END_POINTS.PROPSENSE.YHD.CARRIER.GET_CARRIER_FILE, {
                            method: "POST",
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify({
                                request: {
                                    "Carrier_ID": this.state.data.id,
                                    "File": objName,
                                    "Name": name
                                },
                                token: this.props.getToken.payload.response.token
                            })
                        })
                            .then((res: any) => {
                                return res.blob();
                            })
                            .then(blob => {

                                var url = window.URL.createObjectURL(blob);
                                var a = document.createElement('a');
                                a.href = url;
                                a.download = name;
                                document.body.appendChild(a);
                                a.click();
                                a.remove();
                            })
                            .catch((res) => console.log(res));
                    };
                };
            };
        }
    };

    private uploadFile = (name: string, event?: any): void => {
        new Promise((resolve, reject): any => {
            if (event && (event !== null)) {

                Object.values(event).forEach((x: any, index, array) => {

                    if (x !== undefined) {
                        const reader = new FileReader();

                        reader.onloadend = async (e): Promise<void> => {
                            if (reader.result !== null) {

                                    const { data }: any = this.state;
                                    const objName = name.split("Files")[0];

                                    const corpFileNames = data[objName + "File_Name"] ? data[objName + "File_Name"].split(";") : [];
                                    const files = data[name] ? [...data[name]] : [];

                                    corpFileNames.push(x.name);
                                    const fileNames = corpFileNames.join(";");

                                    files.push({ 
                                        name: x.name,
                                        base64String: reader.result.toString()
                                    });


                                    this.setState({
                                        ...this.state,
                                        data: {
                                            ...this.state.data,
                                            [objName + "File_Name"]: fileNames,
                                            [name]: files
                                        },
                                        cancelPopup: true
                                    });
                                
                                resolve(true);
                            };
                        };
                        reader.readAsDataURL(x);
                    }
                })
            } else {
                reject();
            }
        })
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, minLength: number, isRequired: boolean, email?: true): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: isRequired ? true : undefined, minLength, email });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        }
        return err;
    };


    private handleCheckBoxChange = (value: boolean, name?: string): void => {
        if (name) {

            let errors = this.state.errors;
            if (name === "approved") {
                if (value === true)
                    delete errors["unApproved_Reason"];
                if (value === false)
                    errors["unApproved_Reason"] = { key: "unApproved_Reason", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
            };

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    [name]: value === true ? "Yes" : "No"
                },
                errors,
                cancelPopup: true
            });
        }
    };

    private handleChange = (name: string, value: string): void => {
        let errors = { ...this.state.errors };

        if (requiredFields.includes(name)) {
            errors = this.errorChecker(name, value, errors, 1, true);
        };

        if (name === "unApproved_Reason") {
            if (value.length > ZEROTH)
                delete errors["unApproved_Reason"];

            if (value.length === ZEROTH)
                errors["unApproved_Reason"] = { key: "unApproved_Reason", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
        }

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [name]: value
            },
            errors,
            cancelPopup: true
        });
    };

    private handleExpand = (expanded: number): void => {
        const iS = [...this.state.expanded];

        if (this.state.expanded.includes(expanded)) {
            iS.splice(iS.findIndex(x => x === expanded), 1);
        } else {
            iS.push(expanded);
        };

        this.setState({ expanded: iS });
    };

};

const mapStateToProps = (state: IStore): ICarrierFormComponentStoreProps => ({
    getToken: getTokenStatus(state),
    getCarriers: getYHDCarriersStatus(state),
    SaveCarrierFormStatus: getYHDSaveCarrierFormStatus(state),
    UpdateCarrierFormStatus: getYHDUpdateCarrierFormStatus(state),
    getYHDCarrierFleetTrucks: getYHDCarrierFleetTruckStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): ICarrierFormComponentDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getCarriersRequest: (data: IGetYHDCarriersRequest) => dispatch(getYHDCarriersLoadAction(data)),
    RequestSaveCarrierForm: (data: ISaveYHDCarrierFormRequest): unknown => dispatch(saveYHDCarrierFormLoadAction(data)),
    RequestUpdateCarrierForm: (data: IUpdateYHDCarrierFormRequest): unknown => dispatch(updateYHDCarrierFormLoadAction(data)),
    getYHDCarrierFleetTrucksRequest: (data: IGetYHDCarrierFleetTruckRequest) => dispatch(getYHDCarrierFleetTruckLoadAction(data))
});


export default connect(mapStateToProps, mapDispatchToProps)(YHDCarrierFormComponent);


export interface IYHDCarrierForm extends IYHDCompanyForm {

};

interface IYHDCompanyForm {
    id?: number;
    name?: string;
    alternate_Name?: string;
    active?: string;
    approved?: string;
    approved_Date?: string;
    unApproved_Reason?: string;
    mailing_Address?: string;
    city?: string;
    province?: string;
    postal_Code?: string;
    phone?: string;
    email?: string;
    notes?: string;
    carrier_Package_Date_Sent?: string;
    owner_Name?: string;
    owner_Email?: string;
    owner_Phone_Home?: string;
    owner_Phone_Cell?: string;
    owner_Drivers_License_No?: string;
    dispatch_Contact_Name?: string;
    dispatch_Email?: string;
    dispatch_Phone_Cell?: string;
    dispatch_Phone_Work?: string;
    dispatch_Contact_After_Hours?: string;
    dispatch_Phone_After_Hours?: string;
    username?: string;
    password?: string;
    gst?: string;
    wcb?: string;
    carrier_Profile?: string;
    wcB_Clearance_Letter_Date?: string;
    wcB_Clearance_Letter_File_Name?: string;
    insurance_Certificate_Date?: string;
    insurance_Certificate_File_Name?: string;
    insurance_Expiry?: string;
    last_Verified?: string;
    carrier_Profile_File_Name?: string;
    corporate_Search_File_Name?: string;
    audit_Completed_Date?: string;
    audit_Approved_Date?: string;
    safety_Fitness_Expiry?: string;
    truck_Code_Tandem?: string;
    truck_Code_Tridem?: string;
    truck_Code_Wagon?: string;
    truck_Code_Quad?: string;
    truck_Code_SuperB?: string;
    carrier_Agreement_Date?: null,
    carrier_Agreement_File_Name?: string;
    rate_Scheduled_Date?: string;
    rate_Scheduled_File_Name?: string;
    maintenance_Inspection_Policy_Date?: string;
    maintenance_Inspection_Policy_File_Name?: string;
    fleet_Trucks_List_File_Name?: string;
    status?: string;
    created: string;
    created_By: string;
    modified: string;
    modified_By: string;
    hexCode: string;

    wcB_Clearance_Letter_Files: YHD_Corporate_Search[] | undefined;
    insurance_Certificate_Files: YHD_Corporate_Search[] | undefined;
    carrier_Profile_Files: YHD_Corporate_Search[] | undefined;
    carrier_Agreement_Files: YHD_Corporate_Search[] | undefined;
    rate_Scheduled_Files: YHD_Corporate_Search[] | undefined;
    maintenance_Inspection_Policy_Files: YHD_Corporate_Search[] | undefined;
    corporate_Search_Files: YHD_Corporate_Search[] | undefined;
    fleet_Trucks_List_Files: YHD_Corporate_Search[] | undefined;
};

interface YHD_Corporate_Search
{
    name: string;
    base64String: string;
}