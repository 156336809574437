import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IGetBaggedInventoriesLoadAction,
    IGetBaggedInventoriesLoadFailedAction,
    IGetBaggedInventoriesSuccessAction,
    getBaggedInventoriesLoadFailedAction, 
    getBaggedInventoriesLoadSuccessAction, 
} from "./getBaggedInventoriesActions";
import { IGET_BAGGED_INVENTORIES_REQUEST, IBaggedInventories } from "./getBaggedInventoriesConstaints";
import { IStore } from "../../../../reducers";
import { EpicDependencies } from "../../../../store";
import { SilAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { END_POINTS } from "../../../../endpoints";
import { withJsonContentType } from "../../../../epicUtils";

export const getBaggedInventoriesEpic: Epic = (
    action$: ActionsObservable<IGetBaggedInventoriesLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IGetBaggedInventoriesSuccessAction | IGetBaggedInventoriesLoadFailedAction> =>
    action$.ofType(IGET_BAGGED_INVENTORIES_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<IBaggedInventories[]>>(
                    END_POINTS.PROPSENSE.SIL.WAREHOUSE.GET_BAGGED_INVENTORIES,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<IBaggedInventories[]>): IGetBaggedInventoriesSuccessAction => {
                            return getBaggedInventoriesLoadSuccessAction(response);
                        }),
                        catchError(() => ActionsObservable.of(getBaggedInventoriesLoadFailedAction("Unable to get bagged inventories")))
                    )
            )
        );