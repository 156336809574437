import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IUpdateWashPlantFeedMaterialLoadAction, IUpdateWashPlantFeedMaterialLoadFailedAction, IUpdateWashPlantFeedMaterialSuccessAction } from "./updateWashPlantFeedMaterialActions";
import { IUPDATE_WASH_PLANT_FEED_MATERIAL_REQUEST } from "./updateWashPlantFeedMaterialConstaints";


type Actions =
    IUpdateWashPlantFeedMaterialLoadAction
    | IUpdateWashPlantFeedMaterialLoadFailedAction
    | IUpdateWashPlantFeedMaterialSuccessAction
    | IFlushDataSuccessAction;

export const UpdateWashPlantFeedMaterialReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IUPDATE_WASH_PLANT_FEED_MATERIAL_REQUEST.REQUEST:
            return loading;

        case IUPDATE_WASH_PLANT_FEED_MATERIAL_REQUEST.SUCCESS:
            return succeeded("Success");

        case IUPDATE_WASH_PLANT_FEED_MATERIAL_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};