import { IUpdateQualityControlTestingRequest, IUPDATE_QUALITY_CONTROL_TESTING_REQUEST } from "./updateQualityControlTestingConstaints";

export interface IUpdateQualityControlTestingLoadAction {
    type: IUPDATE_QUALITY_CONTROL_TESTING_REQUEST.REQUEST;
    data: IUpdateQualityControlTestingRequest
}
export const updateQualityControlTestingLoadAction = (data: IUpdateQualityControlTestingRequest): IUpdateQualityControlTestingLoadAction => ({
    type: IUPDATE_QUALITY_CONTROL_TESTING_REQUEST.REQUEST,
    data
});

export interface IUpdateQualityControlTestingSuccessAction {
    type: IUPDATE_QUALITY_CONTROL_TESTING_REQUEST.SUCCESS;
    message: string;
}
export const updateQualityControlTestingLoadSuccessAction = (message: string): IUpdateQualityControlTestingSuccessAction => ({
    type: IUPDATE_QUALITY_CONTROL_TESTING_REQUEST.SUCCESS,
    message
});

export interface IUpdateQualityControlTestingLoadFailedAction {
    type: IUPDATE_QUALITY_CONTROL_TESTING_REQUEST.FAILED;
    message: string;
}
export const updateQualityControlTestingLoadFailedAction = (message: string): IUpdateQualityControlTestingLoadFailedAction => ({
    type: IUPDATE_QUALITY_CONTROL_TESTING_REQUEST.FAILED,
    message
});
