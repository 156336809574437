import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithLessPadding, LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { getCarrierInfractionsStatus } from "../../../../redux/sil/carrier/getCarrierInfractions/getCarrierInfractionsAccessor";
import { getCarrierInfractionsLoadAction } from "../../../../redux/sil/carrier/getCarrierInfractions/getCarrierInfractionsActions";
import { ICarrierInfraction, IGetCarrierInfractionsRequest } from "../../../../redux/sil/carrier/getCarrierInfractions/getCarrierInfractionsConstaints";
import { hasPayload, isNotLoaded, isSucceeded, Server } from "../../../../redux/server";
import { ById, SilAPIResponse } from "../../../shared/publicInterfaces";
import { userName, undefinedFunction, yesOrNoOptions, infractionStatus, methodOfNotification, findInfractionNotification, deleteText } from "../../../shared/constExports";
import { LAButton, LAIconButton, LASaveAndCancelButton } from "../../../shared/buttons";
import { AddIcon, ArrowLeftIcon, DeleteIcon } from "../../../shared/icons";
import { DRY_PLANT_FORM_TILE_COLOR, WHITE_COLOR } from "../../../shared/theme";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import LATextField from "../../../shared/textField";
import LAAutoComplete from "../../../shared/autoComplete";
import { ROUTE } from "../../../routes";
import queryString from "query-string";
import { updateCarrierInfractionLoadAction } from "../../../../redux/sil/carrier/updateCarrierInfraction/updateCarrierInfractionActions";
import { updateCarrierInfractionStatus } from "../../../../redux/sil/carrier/updateCarrierInfraction/updateCarrierInfractionAccessor";
import { IUpdateCarrierInfractionRequest } from "../../../../redux/sil/carrier/updateCarrierInfraction/updateCarrierInfractionConstaints";
import { IAddCarrierInfractionRequest, IAddUpdatePersonInvolvement } from "../../../../redux/sil/carrier/addCarrierInfraction/addCarrierInfractionConstaints";
import { addCarrierInfractionStatus } from "../../../../redux/sil/carrier/addCarrierInfraction/addCarrierInfractionAccessor";
import { addCarrierInfractionLoadAction } from "../../../../redux/sil/carrier/addCarrierInfraction/addCarrierInfractionActions";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import { FieldValidator, FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../../shared/fieldValidator";
import PageSpacing from "../../../shared/pageSpacing";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import LATextArea from "../../../shared/textArea";
import { END_POINTS } from "../../../../redux/endpoints";
import { getCarrierLookupsStatus } from "../../../../redux/sil/carrier/getCarrierLookups/getCarrierLookupsAccessor";
import { getCarrierLookupsLoadAction } from "../../../../redux/sil/carrier/getCarrierLookups/getCarrierLookupsActions";
import { ICarrierLookup, IGetCarrierLookupsRequest } from "../../../../redux/sil/carrier/getCarrierLookups/getCarrierLookupsConstaints";
import SilRoleCheck from "../../../shared/silRoleCheck";
import { LADropDone } from "../../../shared/dragDropFiles";
import LALinkButton from "../../../shared/linkButton";
import { LAExpansionPanel } from "../../../shared/expansionPanel";
import { LACheckBox } from "../../../shared/checkBox";
import { LADateTimeMUIPicker } from "../../../shared/dateTimePickerMUI";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";

enum IInfractionFormSection {
    "Infraction" = 1,
    "Reported_By" = 2,
    "Reported_To" = 3
};

interface ICarrierInfractionStoreProps {
    addCarrierInfraction: Server<string>;
    updateCarrierInfraction: Server<string>;
    getToken: Server<SilAPIResponse<IToken>>;
    getCarrierInfractions: Server<SilAPIResponse<ICarrierInfraction[]>>;
    getCarrierLookups: Server<SilAPIResponse<ICarrierLookup[]>>;
};

interface ICarrierInfractionDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getCarrierInfractionsRequest: (data: IGetCarrierInfractionsRequest) => unknown;
    addCarrierInfractionRequest: (data: IAddCarrierInfractionRequest) => unknown;
    updateCarrierInfractionRequest: (data: IUpdateCarrierInfractionRequest) => unknown;
    getCarrierLookupsRequest: (data: IGetCarrierLookupsRequest) => unknown;
};


interface ICarrierInfractionOwnProps {

};

interface ICarrierInfractionState {
    isAdd: boolean;
    expanded: number[];
    infraction: ICarrierInfraction;
    attachment: string | undefined;
    errors: ById<IFieldErrorKeyValue>;
    driverStat: string | undefined;
    customerStat: string | undefined;
    otherStat: string | undefined;
    otherNotification: string;
};

const CarrierInfractionStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
`;

const requiredFields = ["date_Of_Infraction", "infraction_Notification_Method", "carrier", "trailer_No", "location", "infraction_Type", "description", "immediate_Action_Taken", "status"];

type ICarrierInfractionProps = RouteComponentProps
    & ICarrierInfractionStoreProps
    & ICarrierInfractionDispatchProps
    & ICarrierInfractionOwnProps;

class CarrierInfraction extends PureComponent<ICarrierInfractionProps, ICarrierInfractionState> {

    public constructor(props: ICarrierInfractionProps) {
        super(props);
        this.state = {
            errors: {
                "date_Of_Infraction": { key: "date_Of_Infraction", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "infraction_Notification_Method": { key: "infraction_Notification_Method", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "carrier": { key: "carrier", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "trailer_No": { key: "trailer_No", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "location": { key: "location", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "infraction_Type": { key: "infraction_Type", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "description": { key: "description", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "immediate_Action_Taken": { key: "immediate_Action_Taken", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "status": { key: "status", message: FIELD_VALIDATOR_ERRORS.REQUIRED }
            },
            infraction: {
                id: 0,
                date_Of_Infraction: "",
                infraction_Notification_Method: "",
                carrier: "",
                trailer_No: "",
                location: "",
                infraction_Type: "",
                description: "",
                immediate_Action_Taken: "",
                status: "Pending",
                created: "",
                created_By: userName,
                modified: "",
                modified_By: userName,
                persons_Involved: []
            },
            expanded: [1, 2, 3],
            attachment: undefined,
            driverStat: undefined,
            customerStat: undefined,
            otherStat: undefined,
            otherNotification: "",
            isAdd: true
        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: ICarrierInfractionProps): void {
        if (this.props !== prevProps) {

            if((this.props.getCarrierLookups !== prevProps.getCarrierLookups) || (this.props.getToken !== prevProps.getToken) || (this.props.getCarrierInfractions !== prevProps.getCarrierInfractions)){
                this.callServer();
            }

            if (this.props.addCarrierInfraction !== prevProps.addCarrierInfraction) {
                if (isSucceeded(this.props.addCarrierInfraction)) {
                    this.handleCancel();
                };
            };

            if (this.props.updateCarrierInfraction !== prevProps.updateCarrierInfraction) {
                if (isSucceeded(this.props.updateCarrierInfraction)) {
                    this.handleCancel();
                };
            };
        };
    };


    public render(): ReactNode {

        const { updateCarrierInfraction, addCarrierInfraction, getCarrierLookups } = this.props;
        const { infraction, errors, attachment, driverStat, customerStat, otherStat, otherNotification, isAdd, expanded } = this.state;

        const carriers = hasPayload(getCarrierLookups) ? getCarrierLookups.payload.response : [];

        const onInfraction = () => this.handleExpand(IInfractionFormSection.Infraction);
        const onReportedBy = () => this.handleExpand(IInfractionFormSection.Reported_By);
        const onReportedTo = () => this.handleExpand(IInfractionFormSection.Reported_To);

        const handleDownloadAtt = (): void => this.handleDownloadAtt("Attachment_File", infraction.attachment_File_Name, attachment);
        const handleDownloadDriverStat = (): void => this.handleDownloadAtt("Driver_Statement_File", infraction.driver_Statement_File_Name, driverStat);
        const handleCustomerStat = (): void => this.handleDownloadAtt("Customer_Statement_File", infraction.customer_Statement_File_Name, customerStat);
        const handleOtherStat = (): void => this.handleDownloadAtt("Other_Statement_File", infraction.other_Statement_File_Name, otherStat);
        const handleUploadAtt = (files: Array<File | DataTransferItem>): void => this.handleAtt("attachment", "attachment_File_Name", files);
        const handleUploadDriverStat = (files: Array<File | DataTransferItem>): void => this.handleAtt("driverStat", "driver_Statement_File_Name", files);
        const handleUploadCustomerStat = (files: Array<File | DataTransferItem>): void => this.handleAtt("customerStat", "customer_Statement_File_Name", files);
        const handleUploadOtherStat = (files: Array<File | DataTransferItem>): void => this.handleAtt("otherStat", "other_Statement_File_Name", files);
        const handleDeleteAtt = (): void => this.handleDeleteAtt("attachment", "attachment_File_Name");
        const handleDeleteDriverStat = (): void => this.handleDeleteAtt("driverStat", "driver_Statement_File_Name");
        const handleDeleteCustomerStat = (): void => this.handleDeleteAtt("customerStat", "customer_Statement_File_Name");
        const handleDeleteOtherStat = (): void => this.handleDeleteAtt("otherStat", "other_Statement_File_Name");

        const notification: any = findInfractionNotification(infraction.infraction_Notification_Method);

        return (
            <PageSpacing title="SIL - Carrier" description="Carrier Infraction" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="carrierAccess">
                    <CarrierInfractionStyles>

                        <LAGrid>

                            <LAGridItem xs={12}>
                                <LAButton startIcon={<ArrowLeftIcon color={WHITE_COLOR} />} label="Back to list" onClick={this.handleCancel} />
                                <h2 className="text-center">{!isAdd ? "VIEW " : "ADD "} CARRIER INFRACTION</h2>
                                <hr />
                            </LAGridItem>

                            <LAGridItem xs={12}>
                                <LAExpansionPanel color={DRY_PLANT_FORM_TILE_COLOR} textColor={WHITE_COLOR} label="INFRACTION" expanded={expanded.includes(IInfractionFormSection.Infraction)} onClick={onInfraction}>
                                    <LAGrid spacing={3}>

                                        <LAGridItem xs={4}>
                                            <LATextArea
                                                minRows={1}
                                                rowsMax={1}
                                                name="id"
                                                disabled={true}
                                                fullWidth={true}
                                                label="Infraction #"
                                                value={infraction.id}
                                                onChange={undefinedFunction}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={4}>
                                            <LADateTimeMUIPicker
                                                name="date_Of_Infraction"
                                                label="Date"
                                                fullWidth={true}
                                                value={infraction.date_Of_Infraction}
                                                onChange={this.handleDate}
                                                errorText={errors && errors["date_Of_Infraction"] ? errors["date_Of_Infraction"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="Method of Infraction Notification"
                                                getOptionLabel="name"
                                                name="infraction_Notification_Method"
                                                option={methodOfNotification}
                                                multiple={false}
                                                onChange={this.handleNotificationDropDown}
                                                value={notification}
                                                defaultValue={notification}
                                            />

                                            {((notification.name) && (notification.name === methodOfNotification[3].name)) &&
                                                <>
                                                    <br />
                                                    <LATextArea
                                                        minRows={1}
                                                        rowsMax={1}
                                                        name="otherNotification"
                                                        fullWidth={true}
                                                        label="Other Notification"
                                                        value={otherNotification}
                                                        onChange={this.handleOtherNotification}
                                                    />
                                                </>}
                                        </LAGridItem>

                                        <LAGridItem xs={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="Injuries"
                                                getOptionLabel="name"
                                                name="injuries"
                                                option={yesOrNoOptions}
                                                multiple={false}
                                                onChange={this.handleDropDownChange}
                                                value={yesOrNoOptions.find(x => x.name === infraction.injuries)}
                                                defaultValue={yesOrNoOptions.find(x => x.name === infraction.injuries)}
                                                errorText={errors && errors["injuries"] ? errors["injuries"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="Carrier"
                                                getOptionLabel="name"
                                                name="carrier"
                                                option={carriers}
                                                multiple={false}
                                                onChange={this.handleDropDownChange}
                                                value={hasPayload(getCarrierLookups) && carriers ? carriers.find(x => x.name === infraction.carrier) : ""}
                                                defaultValue={hasPayload(getCarrierLookups) && carriers ? carriers.find(x => x.name === infraction.carrier) : ""}
                                                errorText={errors && errors["carrier"] ? errors["carrier"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={4}>
                                            <LATextField
                                                name="load_No"
                                                label="Load #"
                                                fullWidth={true}
                                                onChange={this.onChange}
                                                value={infraction.load_No ?? ""}
                                                errorText={errors && errors["load_No"] ? errors["load_No"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={4}>
                                            <LATextField
                                                name="trailer_No"
                                                fullWidth={true}
                                                label="Trailer #"
                                                onChange={this.onChange}
                                                value={infraction.trailer_No ?? ""}
                                                errorText={errors && errors["trailer_No"] ? errors["trailer_No"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={4}>
                                            <LATextField
                                                name="license_Plate"
                                                fullWidth={true}
                                                label="License Plate"
                                                onChange={this.onChange}
                                                value={infraction.license_Plate ?? ""}
                                                errorText={errors && errors["license_Plate"] ? errors["license_Plate"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={4}>
                                            <LATextField
                                                name="location"
                                                fullWidth={true}
                                                label="Location"
                                                onChange={this.onChange}
                                                value={infraction.location ?? ""}
                                                errorText={errors && errors["location"] ? errors["location"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={4}>
                                            <LATextField
                                                name="ticket_No"
                                                fullWidth={true}
                                                label="Ticket"
                                                onChange={this.onChange}
                                                value={infraction.ticket_No ?? ""}
                                                errorText={errors && errors["ticket_No"] ? errors["ticket_No"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={4}>
                                            <LATextField
                                                name="ticket_No"
                                                fullWidth={true}
                                                label="Ticket (if applicable)"
                                                onChange={this.onChange}
                                                value={infraction.ticket_No ?? ""}
                                                errorText={errors && errors["ticket_No"] ? errors["ticket_No"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={4}>
                                            <LATextField
                                                name="supervisor"
                                                fullWidth={true}
                                                label="Supervisor"
                                                onChange={this.onChange}
                                                value={infraction.supervisor ?? ""}
                                                errorText={errors && errors["supervisor"] ? errors["supervisor"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={6}>
                                        <strong>Persons Involved: </strong>
                                            <TableContainer component={LAPaperWithLessPadding}>
                                                <LAIconButton
                                                    label="Add"
                                                    icon={<AddIcon />}
                                                    onClick={this.onAddPerson}
                                                />

                                                <Table size="small" aria-label="Person's Involved">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell align="center">Name</TableCell>
                                                            <TableCell align="center">Occupation</TableCell>
                                                            <TableCell align="center">Phone</TableCell>
                                                            <TableCell align="right"></TableCell>
                                                        </TableRow>
                                                    </TableHead>

                                                    <TableBody>
                                                        {infraction.persons_Involved
                                                            .map((x, index) => {

                                                                const onDelete = (): void => this.onPersonDelete(index);
                                                                const onTChange = (name: string, value: string): void => this.onTableChange(name, value, index);

                                                                return <TableRow key={index}>

                                                                    <TableCell align="center">
                                                                        <LATextArea
                                                                            minRows={1}
                                                                            rowsMax={1}
                                                                            name="name"
                                                                            fullWidth={true}
                                                                            label="Name"
                                                                            value={x.name}
                                                                            onChange={onTChange}
                                                                        />
                                                                    </TableCell>

                                                                    <TableCell align="center">
                                                                        <LATextArea
                                                                            minRows={1}
                                                                            rowsMax={1}
                                                                            name="occupation"
                                                                            fullWidth={true}
                                                                            label="Occupation"
                                                                            value={x.occupation}
                                                                            onChange={onTChange}
                                                                        />
                                                                    </TableCell>

                                                                    <TableCell align="center">
                                                                        <LATextArea
                                                                            minRows={1}
                                                                            rowsMax={1}
                                                                            type="number"
                                                                            name="phone_No"
                                                                            fullWidth={true}
                                                                            label="Phone No"
                                                                            value={x.phone_No}
                                                                            onChange={onTChange}
                                                                        />
                                                                    </TableCell>

                                                                    {/* <TableCell>
                                                                        <LAIconButton
                                                                            label="Remove"
                                                                            onClick={onDelete}
                                                                            icon={<DeleteIcon />}
                                                                        />
                                                                    </TableCell> */}
                                                                </TableRow>
                                                            })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </LAGridItem>

                                        <LAGridItem xs={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="Infraction Type"
                                                getOptionLabel="name"
                                                name="infraction_Type"
                                                multiple={false}
                                                option={typeOfInfractionDropDown}
                                                onChange={this.handleDropDownChange}
                                                value={typeOfInfractionDropDown.find(x => x.name === infraction.infraction_Type)}
                                                defaultValue={typeOfInfractionDropDown.find(x => x.name === infraction.infraction_Type)}
                                                errorText={errors && errors["infraction_Type"] ? errors["infraction_Type"].message : undefined}
                                            />

                                        </LAGridItem>

                                        <LAGridItem xs={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="Reportable"
                                                getOptionLabel="name"
                                                name="reportable"
                                                option={reportableDropDown}
                                                multiple={false}
                                                onChange={this.handleDropDownChange}
                                                value={reportableDropDown.find(x => x.name === infraction.reportable)}
                                                defaultValue={reportableDropDown.find(x => x.name === infraction.reportable)}
                                                errorText={errors && errors["reportable"] ? errors["reportable"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="Preventable"
                                                getOptionLabel="name"
                                                name="preventable"
                                                option={preventableDropDown}
                                                multiple={false}
                                                onChange={this.handleDropDownChange}
                                                value={preventableDropDown.find(x => x.name === infraction.preventable)}
                                                defaultValue={preventableDropDown.find(x => x.name === infraction.preventable)}
                                                errorText={errors && errors["preventable"] ? errors["preventable"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={4}>
                                            <LATextField
                                                name="carrier_Notification_Provided_To"
                                                fullWidth={true}
                                                label="Carrier Notification Provided To"
                                                onChange={this.onChange}
                                                value={infraction.carrier_Notification_Provided_To ?? ""}
                                                errorText={errors && errors["carrier_Notification_Provided_To"] ? errors["carrier_Notification_Provided_To"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={4}>
                                            <LATextArea
                                                minRows={3}
                                                rowsMax={5}
                                                name="description"
                                                fullWidth={true}
                                                label="Description of Infraction"
                                                onChange={this.onChange}
                                                value={infraction.description ?? ""}
                                                errorText={errors && errors["description"] ? errors["description"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={4}>
                                            <LATextArea
                                                minRows={3}
                                                rowsMax={5}
                                                name="immediate_Action_Taken"
                                                fullWidth={true}
                                                label="Immediate Action Taken"
                                                onChange={this.onChange}
                                                value={infraction.immediate_Action_Taken}
                                                errorText={errors && errors["immediate_Action_Taken"] ? errors["immediate_Action_Taken"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={4}>
                                            <LATextArea
                                                minRows={3}
                                                rowsMax={5}
                                                name="followup_Action_Taken"
                                                fullWidth={true}
                                                label="Followup Action Taken"
                                                onChange={this.onChange}
                                                value={infraction.followup_Action_Taken ?? ""}
                                                errorText={errors && errors["followup_Action_Taken"] ? errors["followup_Action_Taken"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={4}>
                                            <LATextField
                                                name="investigation_Notes"
                                                fullWidth={true}
                                                label="Investigation Notes"
                                                onChange={this.onChange}
                                                value={infraction.investigation_Notes ?? ""}
                                                errorText={errors && errors["investigation_Notes"] ? errors["investigation_Notes"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={6} className="text-center">
                                            <strong>Upload Attachment</strong>
                                            <LADropDone onFileDrop={(e: Array<File | DataTransferItem>) => handleUploadAtt(e)} />
                                            {infraction.attachment_File_Name && infraction.attachment_File_Name.length > 0 && <LALinkButton className="pl-5" label={infraction.attachment_File_Name} onClick={handleDownloadAtt} />}
                                            {infraction.attachment_File_Name && <LAIconButton
                                                name="attachment_Name"
                                                label="Remove"
                                                icon={<DeleteIcon />}
                                                onClick={handleDeleteAtt}
                                            />}
                                        </LAGridItem>

                                        <LAGridItem xs={4}>
                                            <LATextField
                                                name="attachment_Details"
                                                fullWidth={true}
                                                label="Attachment Details"
                                                onChange={this.onChange}
                                                value={infraction.attachment_Details ?? ""}
                                                errorText={errors && errors["attachment_Details"] ? errors["attachment_Details"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={4}>
                                            <LAAutoComplete
                                                name="conditions"
                                                multiple={false}
                                                autoHighlight={true}
                                                getOptionLabel="name"
                                                filterSelectedOptions={true}
                                                selectionRemove={undefinedFunction}
                                                onChange={this.handleDropDownChange}
                                                option={conditionAtTheTimeOfInfractionDropDown}
                                                dropDownPlaceHolder="Condition at the time of infraction"
                                                value={conditionAtTheTimeOfInfractionDropDown.find(x => x.name === infraction.conditions)}
                                                defaultValue={conditionAtTheTimeOfInfractionDropDown.find(x => x.name === infraction.conditions)}
                                                errorText={errors && errors["conditions"] ? errors["conditions"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={4}>
                                            <LATextArea
                                                minRows={3}
                                                rowsMax={5}
                                                name="corrective_Action_Recommended"
                                                fullWidth={true}
                                                label="Corrective Action Recommended"
                                                onChange={this.onChange}
                                                value={infraction.corrective_Action_Recommended ?? ""}
                                                errorText={errors && errors["corrective_Action_Recommended"] ? errors["corrective_Action_Recommended"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={4}>
                                            <LATextArea
                                                minRows={3}
                                                rowsMax={5}
                                                name="corrective_Action_Taken"
                                                fullWidth={true}
                                                label="Corrective Action Taken"
                                                onChange={this.onChange}
                                                value={infraction.corrective_Action_Taken ?? ""}
                                                errorText={errors && errors["corrective_Action_Taken"] ? errors["corrective_Action_Taken"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={4}>
                                            <LATextField
                                                name="customer_Involved"
                                                fullWidth={true}
                                                label="Customer Involved"
                                                onChange={this.onChange}
                                                value={infraction.customer_Involved ?? ""}
                                                errorText={errors && errors["customer_Involved"] ? errors["customer_Involved"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={4}>
                                            <LATextField
                                                name="root_Cause_Immediate"
                                                fullWidth={true}
                                                label="Root Cause Immediate"
                                                onChange={this.onChange}
                                                value={infraction.root_Cause_Immediate ?? ""}
                                                errorText={errors && errors["root_Cause_Immediate"] ? errors["root_Cause_Immediate"].message : undefined}
                                            />
                                        </LAGridItem>

                                        {/* <LAGridItem xs={4}>
                                            Missing Root Cause - Underlying
                                        </LAGridItem> */}

                                        <LAGridItem xs={4}>
                                            <LATextField
                                                name="temperature"
                                                fullWidth={true}
                                                label="Temperature at time of infraction"
                                                onChange={this.onChange}
                                                value={infraction.temperature ?? ""}
                                                errorText={errors && errors["date_Of_Infraction"] ? errors["date_Of_Infraction"].message : undefined}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={6} className="text-center">
                                            <strong>Upload Driver Statement</strong>
                                            <LADropDone onFileDrop={(e: Array<File | DataTransferItem>) => handleUploadDriverStat(e)} />
                                            {infraction.driver_Statement_File_Name && infraction.driver_Statement_File_Name.length > 0 && <LALinkButton className="pl-5" label={infraction.driver_Statement_File_Name} onClick={handleDownloadDriverStat} />}
                                            {infraction.driver_Statement_File_Name && <LAIconButton
                                                name="driver_Statement_File_Name"
                                                label="Remove"
                                                icon={<DeleteIcon />}
                                                onClick={handleDeleteDriverStat}
                                            />}
                                        </LAGridItem>

                                        <LAGridItem xs={6} className="text-center">
                                            <strong>Upload Customer Statement</strong>
                                            <LADropDone onFileDrop={(e: Array<File | DataTransferItem>) => handleUploadCustomerStat(e)} />
                                            {infraction.customer_Statement_File_Name && infraction.customer_Statement_File_Name.length > 0 && <LALinkButton className="pl-5" label={infraction.customer_Statement_File_Name} onClick={handleCustomerStat} />}
                                            {infraction.customer_Statement_File_Name && <LAIconButton
                                                name="customer_Statement_File_Name"
                                                label="Remove"
                                                icon={<DeleteIcon />}
                                                onClick={handleDeleteCustomerStat}
                                            />}
                                        </LAGridItem>

                                        <LAGridItem xs={6} className="text-center">
                                            <strong>Upload Other Statement</strong>
                                            <LADropDone onFileDrop={(e: Array<File | DataTransferItem>) => handleUploadOtherStat(e)} />
                                            {infraction.other_Statement_File_Name && infraction.other_Statement_File_Name.length > 0 && <LALinkButton className="pl-5" label={infraction.other_Statement_File_Name} onClick={handleOtherStat} />}
                                            {infraction.other_Statement_File_Name && <LAIconButton
                                                name="other_Statement_File_Name"
                                                label="Remove"
                                                icon={<DeleteIcon />}
                                                onClick={handleDeleteOtherStat}
                                            />}
                                        </LAGridItem>

                                    </LAGrid>
                                </LAExpansionPanel>
                            </LAGridItem>

                            <LAGridItem xs={12}>
                                <LAExpansionPanel color={DRY_PLANT_FORM_TILE_COLOR} textColor={WHITE_COLOR} label="INFRACTION REPORTED BY" expanded={expanded.includes(IInfractionFormSection.Reported_By)} onClick={onReportedBy}>
                                    <LAGrid>
                                        <LAGridItem xs={4}>
                                            <LATextField
                                                name="reported_By_Name"
                                                label="Name"
                                                fullWidth={true}
                                                onChange={this.onChange}
                                                value={infraction.reported_By_Name ?? ""}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={4}>
                                            <LATextField
                                                name="contact_No"
                                                label="Contact Number"
                                                fullWidth={true}
                                                onChange={this.onChange}
                                                value={infraction.contact_No ?? ""}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={4}>
                                            <LAAutoComplete
                                                filterSelectedOptions={true}
                                                autoHighlight={true}
                                                selectionRemove={undefinedFunction}
                                                dropDownPlaceHolder="Status"
                                                getOptionLabel="name"
                                                name="status"
                                                option={infractionStatus}
                                                multiple={false}
                                                onChange={this.handleDropDownChange}
                                                value={infractionStatus.find(x => x.name === infraction.status)}
                                                defaultValue={infractionStatus.find(x => x.name === infraction.status)}
                                                errorText={errors && errors["status"] ? errors["status"].message : undefined}
                                            />
                                        </LAGridItem>
                                    </LAGrid>
                                </LAExpansionPanel>
                            </LAGridItem>

                            <LAGridItem xs={12}>
                                <LAExpansionPanel color={DRY_PLANT_FORM_TILE_COLOR} textColor={WHITE_COLOR} label="INFRACTION REPORTED TO" expanded={expanded.includes(IInfractionFormSection.Reported_To)} onClick={onReportedTo}>
                                    <LAGrid>
                                        <LAGridItem xs={4}>
                                            <LACheckBox
                                                name="Customer"
                                                label="Customer"
                                                onChange={this.handleCheckBox}
                                                value={infraction.reported_To && infraction.reported_To.includes("Customer") ? true : false}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={4}>
                                            <LACheckBox
                                                name="Sil OH&S"
                                                label="Sil OH&S"
                                                onChange={this.handleCheckBox}
                                                value={infraction.reported_To && infraction.reported_To.includes("Sil OH&S") ? true : false}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={4}>
                                            <LACheckBox
                                                name="Carrier"
                                                label="Carrier"
                                                onChange={this.handleCheckBox}
                                                value={infraction.reported_To && infraction.reported_To.includes("Carrier") ? true : false}
                                            />
                                        </LAGridItem>

                                    </LAGrid>
                                </LAExpansionPanel>
                            </LAGridItem>

                            <LAGridItem xs={12}>
                                <hr />
                                <LASaveAndCancelButton
                                    onSave={this.onSave}
                                    onCancel={this.handleCancel}
                                    disableSave={(Object.values(errors).length > 0 ? true : undefined)}
                                />
                            </LAGridItem>

                        </LAGrid>

                        <RequestStatus requestStatus={addCarrierInfraction.kind} successMessage="Carrier Infraction successfully saved" />
                        <RequestStatus requestStatus={updateCarrierInfraction.kind} successMessage="Carrier Infraction successfully updated" />

                    </CarrierInfractionStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private onAddPerson = (): void => {
        const iS = [...this.state.infraction.persons_Involved];
        let maxId = Math.max.apply(Math, iS.map(function (o) { return o.id; }));

        if (maxId === -Infinity) {
            maxId = 0;
        };

        iS.push({
            id: 0,
            list_ID: maxId + 1,
            name: "",
            occupation: "",
            phone_No: "",
            created: "",
            created_By: "",
            modified: "",
            modified_By: ""
        });

        this.setState({
            ...this.state,
            infraction: {
                ...this.state.infraction,
                persons_Involved: iS
            }
        });
    };

    private onTableChange = (name: string, value: string, index: number): void => {
        const iS = [...this.state.infraction.persons_Involved];
        let record = { ...iS[index] };
        record = { ...record, [name]: value };

        iS[index] = record;

        this.setState({
            ...this.state,
            infraction: {
                ...this.state.infraction,
                persons_Involved: iS
            }
        });
    };

    private onPersonDelete = (index: number): void => {
        const iS = [...this.state.infraction.persons_Involved];

        iS.splice(index, 1);

        this.setState({
            ...this.state,
            infraction: {
                ...this.state.infraction,
                persons_Involved: iS
            }
        });
    };

    private handleExpand = (expanded: number): void => {
        const iS = [...this.state.expanded];

        if (this.state.expanded.includes(expanded)) {
            iS.splice(iS.findIndex(x => x === expanded), 1);
        } else {
            iS.push(expanded);
        };

        this.setState({ expanded: iS });
    };

    private handleOtherNotification = (name: string, value: string): void => {
        this.setState({
            ...this.state,
            [name]: value
        });
    };

    private handleDate = (name: string, date: string): void => {
        let errors = this.state.errors;
        errors = this.errorChecker(name, date, errors, true);
        let tDate = new Date(date)
        this.setState({
            ...this.state,
            errors,
            infraction: {
                ...this.state.infraction,
                [name]: tDate.toLocaleString()
            }
        });
    };

    private handleCheckBox = (checked: boolean, name?: string | undefined): void => {
        if (name) {
            const iS = { ...this.state.infraction };

            const list = iS.reported_To ? iS.reported_To.split(",") : [];

            if (checked) {
                const index = list.findIndex((x) => x === name);

                if (index < 0)
                    list.push(name);
            } else {
                const index = list.findIndex((x) => x === name);

                if (index >= 0)
                    list.splice(index, 1);
            };

            iS.reported_To = list.join(",");

            this.setState({ infraction: iS });
        }
    };

    private handleDeleteAtt = (baseObjName: string, objInsideInfraction: string): void => {
        const selection = window.confirm(deleteText);
        if(selection) {
            this.setState({
                ...this.state,
                infraction: {
                    ...this.state.infraction,
                    [objInsideInfraction]: undefined
                },
                [baseObjName]: undefined
            });
        }
    };

    private handleDownloadAtt = (objName: string, name: string | undefined, file: string | undefined): void => {
        if (name) {
            const data = this.state.infraction;

            if ((file !== undefined) && (file !== null)) {
                let a = document.createElement("a");
                a.href = file;
                a.download = name;
                a.click();
            };


            if ((file === undefined) || (file === null)) {

                if (hasPayload(this.props.getToken) && data.id) {

                    fetch(END_POINTS.PROPSENSE.SIL.CARRIER.GET_CARRIER_INFRACTION_FILE, {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            request: {
                                "ID": data.id,
                                "File": objName
                            },
                            token: this.props.getToken.payload.response.token
                        })
                    })
                        .then((res: any) => {
                            return res.blob();
                        })
                        .then(blob => {
                            if (name) {
                                let url = window.URL.createObjectURL(blob);
                                let a = document.createElement('a');
                                a.href = url;
                                a.download = name;
                                document.body.appendChild(a);
                                a.click();
                                a.remove();
                            }
                        })
                        .catch((res) => console.log(res));
                };
            };
        }
    };

    private handleAtt = (baseObjName: string, objInsideInfraction: string, files: Array<File | DataTransferItem>): void => {
        new Promise((resolve, reject): any => {
            if (files && (files !== null)) {

                Object.values(files).forEach((x: any, index, array) => {
                    const reader = new FileReader();

                    reader.onloadend = async (e): Promise<void> => {
                        if (reader.result !== null) {
                            this.setState({
                                ...this.state,
                                infraction: {
                                    ...this.state.infraction,
                                    [objInsideInfraction]: x.name,
                                },
                                [baseObjName]: reader.result.toString()
                            });
                            resolve(true);
                        };
                    };
                    reader.readAsDataURL(x);
                })
            } else {
                reject();
            }
        });
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, isRequired: boolean): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: isRequired ? true : undefined, minLength: 1, decimal: undefined });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        };
        return err;
    };

    private handleCancel = (): void => {
        this.props.history.push(ROUTE.SIL.CARRIERS.CARRIER_INFRACTIONS);
    };

    private onSave = async (): Promise<void> => {
        const { infraction, attachment, driverStat, otherStat, customerStat, otherNotification, isAdd } = this.state;

        const persons: IAddUpdatePersonInvolvement[] = [];

        await infraction.persons_Involved
            .forEach((x) => {
                persons.push({
                    id: x.id,
                    Name: x.name,
                    Phone_No: x.phone_No,
                    Occupation: x.occupation
                })
            });

        if (hasPayload(this.props.getToken))
            if (isAdd) {
                await this.props.addCarrierInfractionRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        Date_Of_Infraction: infraction.date_Of_Infraction.toLocaleString(),
                        Infraction_Notification_Method: (infraction.infraction_Notification_Method === methodOfNotification[3].name) ? otherNotification : infraction.infraction_Notification_Method,
                        Injuries: infraction.injuries,
                        Carrier: infraction.carrier,
                        Load_No: infraction.load_No,
                        Trailer_No: infraction.trailer_No,
                        License_Plate: infraction.license_Plate,
                        Location: infraction.location,
                        Ticket_No: infraction.ticket_No,
                        Supervisor: infraction.supervisor,
                        Infraction_Type: infraction.infraction_Type,
                        Carrier_Notification_Provided_To: infraction.carrier_Notification_Provided_To,
                        Description: infraction.description,
                        Immediate_Action_Taken: infraction.immediate_Action_Taken,
                        // Trailer_Inspection_File: attachment,
                        Followup_Action_Taken: infraction.followup_Action_Taken,
                        Inspection_Notes: infraction.investigation_Notes,
                        Attachment_File_Name: infraction.attachment_File_Name,
                        Attachment_File: attachment,
                        Attachment_Details: infraction.attachment_Details,
                        Conditions: infraction.conditions,
                        Corrective_Action_Recommended: infraction.corrective_Action_Recommended,
                        Corrective_Action_Taken: infraction.corrective_Action_Taken,
                        Customer_Involved: infraction.customer_Involved,
                        Root_Cause_Immediate: infraction.root_Cause_Immediate,
                        Temperature: infraction.temperature,
                        Driver_Statement_File: driverStat,
                        Driver_Statement_File_Name: infraction.driver_Statement_File_Name,
                        Customer_Statement_File: customerStat,
                        Customer_Statement_File_Name: infraction.customer_Statement_File_Name,
                        Other_Statement_File: otherStat,
                        Other_Statement_File_Name: infraction.other_Statement_File_Name,
                        Reported_By_Name: infraction.reported_By_Name,
                        Contact_No: infraction.contact_No,
                        Reported_To: infraction.reported_To,
                        Status: infraction.status,
                        Modified: infraction.modified,
                        Modified_By: userName,
                        Created: infraction.created,
                        Created_By: userName,
                        persons_Involved: persons
                    }
                });
            } else {
                await this.props.updateCarrierInfractionRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        ID: infraction.id,
                        Date_Of_Infraction: infraction.date_Of_Infraction.toLocaleString(),
                        Infraction_Notification_Method: (infraction.infraction_Notification_Method === methodOfNotification[3].name) ? otherNotification : infraction.infraction_Notification_Method,
                        Injuries: infraction.injuries,
                        Carrier: infraction.carrier,
                        Load_No: infraction.load_No,
                        Trailer_No: infraction.trailer_No,
                        License_Plate: infraction.license_Plate,
                        Location: infraction.location,
                        Ticket_No: infraction.ticket_No,
                        Supervisor: infraction.supervisor,
                        Infraction_Type: infraction.infraction_Type,
                        Carrier_Notification_Provided_To: infraction.carrier_Notification_Provided_To,
                        Description: infraction.description,
                        Immediate_Action_Taken: infraction.immediate_Action_Taken,
                        // Trailer_Inspection_File: attachment,
                        Followup_Action_Taken: infraction.followup_Action_Taken,
                        Inspection_Notes: infraction.investigation_Notes,
                        Attachment_File_Name: infraction.attachment_File_Name,
                        Attachment_File: attachment,
                        Attachment_Details: infraction.attachment_Details,
                        Conditions: infraction.conditions,
                        Corrective_Action_Recommended: infraction.corrective_Action_Recommended,
                        Corrective_Action_Taken: infraction.corrective_Action_Taken,
                        Customer_Involved: infraction.customer_Involved,
                        Root_Cause_Immediate: infraction.root_Cause_Immediate,
                        Temperature: infraction.temperature,
                        Driver_Statement_File: driverStat,
                        Driver_Statement_File_Name: infraction.driver_Statement_File_Name,
                        Customer_Statement_File: customerStat,
                        Customer_Statement_File_Name: infraction.customer_Statement_File_Name,
                        Other_Statement_File: otherStat,
                        Other_Statement_File_Name: infraction.other_Statement_File_Name,
                        Reported_By_Name: infraction.reported_By_Name,
                        Contact_No: infraction.contact_No,
                        Reported_To: infraction.reported_To,
                        Status: infraction.status,
                        Modified: infraction.modified,
                        Modified_By: userName,
                        persons_Involved: persons
                    }
                });
            };
    };

    private handleNotificationDropDown = (event: unknown, value: { id: number, name: string } | "", name?: string): void => {
        if (name) {
            let errors = this.state.errors;
            let val = value !== "" ? value.name : "";

            if (requiredFields.includes(name))
                errors = this.errorChecker(name, val, errors, true);

            this.setState({
                ...this.state,
                errors,
                infraction: {
                    ...this.state.infraction,
                    [name]: val
                },
                otherNotification: ""
            });
        }
    };

    private handleDropDownChange = (event: unknown, value: { id: number, name: string } | "", name?: string): void => {
        if (name) {
            let errors = this.state.errors;

            if (requiredFields.includes(name))
                errors = this.errorChecker(name, value !== "" ? value.name : "", errors, true);

            this.setState({
                ...this.state,
                errors,
                infraction: {
                    ...this.state.infraction,
                    [name]: value !== "" ? value.name : ""
                }
            });
        }
    };


    private onChange = (name: string, value: string): void => {
        let errors = this.state.errors;

        if (requiredFields.includes(name))
            errors = this.errorChecker(name, value, errors, true);

        this.setState({
            ...this.state,
            errors,
            infraction: {
                ...this.state.infraction,
                [name]: value
            }
        });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });


        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getCarrierLookups))
            this.props.getCarrierLookupsRequest({
                token: this.props.getToken.payload.response.token,
                requested_Page: "Carriers_Infractions"
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getCarrierInfractions))
            this.props.getCarrierInfractionsRequest({
                token: this.props.getToken.payload.response.token
            });


        const query = queryString.parse(this.props.location.search);
        const id = query.id ?? "0";

        if (hasPayload(this.props.getCarrierInfractions)) {
            const data = this.props.getCarrierInfractions.payload.response;
            if (id !== "0") {
                const infraction = data.find(x => x.id === Number(id));

                if (infraction) {
                    const notification: any = findInfractionNotification(infraction.infraction_Notification_Method);

                    this.setState({
                        infraction,
                        errors: {},
                        isAdd: false,
                        otherNotification: ((notification.name) && (notification.name === methodOfNotification[3].name)) ? infraction.infraction_Notification_Method : ""
                    });
                }
            } else {
                const maxId = Math.max.apply(Math, data.map(function (o) { return o.id; }));

                this.setState({
                    ...this.state,
                    infraction: {
                        ...this.state.infraction,
                        id: maxId + 1
                    }
                });
            }
        }
    };

}

const mapStateToProps = (state: IStore): ICarrierInfractionStoreProps => ({
    getToken: getTokenStatus(state),
    getCarrierInfractions: getCarrierInfractionsStatus(state),
    addCarrierInfraction: addCarrierInfractionStatus(state),
    updateCarrierInfraction: updateCarrierInfractionStatus(state),
    getCarrierLookups: getCarrierLookupsStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): ICarrierInfractionDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getCarrierInfractionsRequest: (data: IGetCarrierInfractionsRequest) => dispatch(getCarrierInfractionsLoadAction(data)),
    addCarrierInfractionRequest: (data: IAddCarrierInfractionRequest) => dispatch(addCarrierInfractionLoadAction(data)),
    updateCarrierInfractionRequest: (data: IUpdateCarrierInfractionRequest) => dispatch(updateCarrierInfractionLoadAction(data)),
    getCarrierLookupsRequest: (data: IGetCarrierLookupsRequest) => dispatch(getCarrierLookupsLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(CarrierInfraction);


interface IInterfaceDropDown {
    id: string;
    name: string;
};


const typeOfInfractionDropDown: IInterfaceDropDown[] = [{ id: "Accident", name: "Accident" }, { id: "Incident", name: "Incident" }, { id: "Ticket", name: "Ticket" }];

const reportableDropDown: IInterfaceDropDown[] = [{ id: "Damage – Major", name: "Damage – Major" }, { id: "Damage – Minor", name: "Damage – Minor" }, { id: "Damage – Minor", name: "Damage – Minor" }
    , { id: "Injury", name: "Injury" }, { id: "Near Miss", name: "Near Miss" }, { id: "Not reportable", name: "Not reportable" }, { id: "No Loss", name: "No Loss" }];

const preventableDropDown: IInterfaceDropDown[] = [{ id: "Preventable", name: "Preventable" }, { id: "Non Preventable", name: "Non Preventable" }];
const conditionAtTheTimeOfInfractionDropDown: IInterfaceDropDown[] = [{ id: "Rain", name: "Rain" }, { id: "Sun", name: "Sun" }, { id: "Snow", name: "Snow" }, { id: "Fog", name: "Fog" }, { id: "Ice", name: "Ice" }
    , { id: "Mud", name: "Mud" }, { id: "Wind", name: "Wind" }];
