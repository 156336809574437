import { IPassTokenWithRequest } from "../../dryPlant/token/getTokenConstants";

export enum IGET_CARRIER_EMAILS_REQUEST {
    REQUEST = "getCarrierEmails/GET_CARRIER_EMAILS_REQUEST",
    SUCCESS = "getCarrierEmails/GET_CARRIER_EMAILS_SUCCESS",
    FAILED = "getCarrierEmails/GET_CARRIER_EMAILS_FAILED"
};

export interface IGetCarrierEmailsRequest extends IPassTokenWithRequest {

};

export interface ICarrierEmail {
    id: number;
    subject: string;
    email_Body: string;
    email_All_Carriers: string;
    carriers: string;
    attachment_Name?: string;
    created: string;
    created_By: string;
    modified: string;
    modified_By: string;
};