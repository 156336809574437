import { ById, SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IGET_SIEVES_PRODUCTS_REQUEST, ISieveProduct } from "./getSievesProductsConstaints";
import { IGetSieveProductsLoadAction, IGetSieveProductsLoadFailedAction, IGetSieveProductsSuccessAction } from "./getSievesProductsActions";

type Actions =
    IGetSieveProductsLoadAction
    | IGetSieveProductsLoadFailedAction
    | IGetSieveProductsSuccessAction
    | IFlushDataSuccessAction;

export const GetSieveProductsReducer = (state: Server<SilAPIResponse<ById<ISieveProduct>>> = notLoaded, action: Actions): Server<SilAPIResponse<ById<ISieveProduct>>> => {
    switch (action.type) {
        case IGET_SIEVES_PRODUCTS_REQUEST.REQUEST:
            return loading;

        case IGET_SIEVES_PRODUCTS_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_SIEVES_PRODUCTS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};