import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetCarrierFleetTrailersRequest, IGET_CARRIER_FLEET_TRAILERS_REQUEST, ICarrierFleetTrailer } from "./getCarrierFleetTrailersConstaints";

export interface IGetCarrierFleetTrailersLoadAction {
    type: IGET_CARRIER_FLEET_TRAILERS_REQUEST.REQUEST;
    data: IGetCarrierFleetTrailersRequest
}
export const getCarrierFleetTrailersLoadAction = (data: IGetCarrierFleetTrailersRequest): IGetCarrierFleetTrailersLoadAction => ({
    type: IGET_CARRIER_FLEET_TRAILERS_REQUEST.REQUEST,
    data
});

export interface IGetCarrierFleetTrailersSuccessAction {
    type: IGET_CARRIER_FLEET_TRAILERS_REQUEST.SUCCESS;
    data: SilAPIResponse<ICarrierFleetTrailer[]>;
}
export const getCarrierFleetTrailersLoadSuccessAction = (data: SilAPIResponse<ICarrierFleetTrailer[]>): IGetCarrierFleetTrailersSuccessAction => ({
    type: IGET_CARRIER_FLEET_TRAILERS_REQUEST.SUCCESS,
    data
});

export interface IGetCarrierFleetTrailersLoadFailedAction {
    type: IGET_CARRIER_FLEET_TRAILERS_REQUEST.FAILED;
    message: string;
}
export const getCarrierFleetTrailersLoadFailedAction = (message: string): IGetCarrierFleetTrailersLoadFailedAction => ({
    type: IGET_CARRIER_FLEET_TRAILERS_REQUEST.FAILED,
    message
});
