import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IAddOrderLoadAction, IAddOrderLoadFailedAction, IAddOrderSuccessAction, 
    addOrderLoadFailedAction, addOrderLoadSuccessAction
} from "./addOrderActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IADD_ORDER_REQUEST } from "./addOrderConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SimosAPIResponse } from "../../../../react/shared/publicInterfaces";

export const addOrderEpic: Epic = (
    action$: ActionsObservable<IAddOrderLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IAddOrderSuccessAction | IAddOrderLoadFailedAction> =>
    action$.ofType(IADD_ORDER_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SimosAPIResponse<string>>(
                    END_POINTS.PROPSENSE.SIMOS.ADD_ORDER,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SimosAPIResponse<string>): IAddOrderSuccessAction | IAddOrderLoadFailedAction => {
                            if(response.message !== "Success")
                                return addOrderLoadFailedAction(response.message);
                            return addOrderLoadSuccessAction(response.message);
                        }),
                        catchError(() => ActionsObservable.of(addOrderLoadFailedAction("Unable to add orders")))
                    )
            )
        );