import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IUpdateLabScaleLoadAction, IUpdateLabScaleLoadFailedAction, IUpdateLabScaleSuccessAction, updateLabScaleLoadFailedAction,
     updateLabScaleLoadSuccessAction } from "./updateLabScaleActions";
import { IUPDATE_LAB_SCALE_REQUEST } from "./updateLabScaleConstaints";
import { IStore } from "../../../../reducers";
import { EpicDependencies } from "../../../../store";
import { SilAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { END_POINTS } from "../../../../endpoints";
import { withJsonContentType } from "../../../../epicUtils";

export const updateLabScaleEpic: Epic = (
    action$: ActionsObservable<IUpdateLabScaleLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IUpdateLabScaleSuccessAction | IUpdateLabScaleLoadFailedAction> =>
    action$.ofType(IUPDATE_LAB_SCALE_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<string>>(
                    END_POINTS.PROPSENSE.SIL.CV.UpdateCVLabScale,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<string>): IUpdateLabScaleSuccessAction | IUpdateLabScaleLoadFailedAction => {
                            if(response.message === "Success"){
                                return updateLabScaleLoadSuccessAction(action.data.request);
                            }
                            return updateLabScaleLoadFailedAction(response.message);
                        }),
                        catchError(() => ActionsObservable.of(updateLabScaleLoadFailedAction("Unable to save your changes")))
                    )
            )
        );