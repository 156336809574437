import { IPassTokenWithRequest } from "../../dryPlant/token/getTokenConstants";


export enum IGET_WASH_PLANT_PRODUCTION_LOOKUPS_REQUEST {
    REQUEST = "getWashPlantProductionLookups/GET_WASH_PLANT_PRODUCTION_LOOKUPS_REQUEST",
    SUCCESS = "getWashPlantProductionLookups/GET_WASH_PLANT_PRODUCTION_LOOKUPS_SUCCESS",
    FAILED = "getWashPlantProductionLookups/GET_WASH_PLANT_PRODUCTION_LOOKUPS_FAILED"
};

export interface IGetWashPlantProductionLookupsRequest extends IPassTokenWithRequest {
    requested_Page: "Dryplant" | "BinAdjustment" | "Washplant";
};

export interface IWashPlantProductionLookups {
    employees: string[],
    locations: string[];
    plants: string[],
    bottlenecks: string[],
    runMaterials: string[],
    byProducts: string[],
    feedMaterials: string[];
    downtime_Category: string[];
    products: string[];
};