import { IADD_SALES_ORDER_NUMBER_REQUEST, IAddSalesOrderNumberRequest } from "./addSalesOrderNumberConstaints";

export interface IAddSalesOrderNumberLoadAction {
    type: IADD_SALES_ORDER_NUMBER_REQUEST.REQUEST;
    data: IAddSalesOrderNumberRequest;
}
export const addSalesOrderNumberLoadAction = (data: IAddSalesOrderNumberRequest): IAddSalesOrderNumberLoadAction => ({
    type: IADD_SALES_ORDER_NUMBER_REQUEST.REQUEST,
    data
});

export interface IAddSalesOrderNumberSuccessAction {
    type: IADD_SALES_ORDER_NUMBER_REQUEST.SUCCESS;
    message: string;
}
export const addSalesOrderNumberLoadSuccessAction = (message: string): IAddSalesOrderNumberSuccessAction => ({
    type: IADD_SALES_ORDER_NUMBER_REQUEST.SUCCESS,
    message
});

export interface IAddSalesOrderNumberLoadFailedAction {
    type: IADD_SALES_ORDER_NUMBER_REQUEST.FAILED;
    message: string;
}
export const addSalesOrderNumberLoadFailedAction = (message: string): IAddSalesOrderNumberLoadFailedAction => ({
    type: IADD_SALES_ORDER_NUMBER_REQUEST.FAILED,
    message
});
