import { IPassTokenWithRequest } from "../../dryPlant/token/getTokenConstants";

export enum IADD_QUALITY_CONTROL_TESTING_REQUEST {
    REQUEST = "addQualityControlTesting/ADD_QUALITY_CONTROL_TESTING_REQUEST",
    SUCCESS = "addQualityControlTesting/ADD_QUALITY_CONTROL_TESTING_SUCCESS",
    FAILED = "addQualityControlTesting/ADD_QUALITY_CONTROL_TESTING_FAILED"
};

export interface IAddQualityControlTestingRequest extends IPassTokenWithRequest {
    request: IAddQualityControlTesting;
};

export interface IAddQualityControlTesting {
    Id?: number;
    Date: string;
    Test_For: string;
    Product: string;
    Turbidity_Test_Result: string;
    Remarks: string;
    Created_By: string;
    Modified_By: string;
};