import React from 'react';
import '../../../../shared/simos/styling/loginPage.css';
import AuthenticateLogin from './AuthenticateLogin';

interface IProps {
    history:any;
}

interface Credentials {
    username:string;
    password:string;
    isLoggedIn:boolean;
}
const Auth = new AuthenticateLogin();

export default class LoginHome extends React.Component<IProps,Credentials> {
    constructor(props:any) {
        super(props);
        this.state = {username:'',password:'',isLoggedIn:false};
    }
    setLoginInfo(event:any) {
        switch(event.target.name) {
            case 'username':
                this.setState({username: event.target.value});
                break;
            case 'password':
                this.setState({password: event.target.value});
                break;
        }
    }

    handleSubmit(event:any) {
        //event.preventDefault();
        if(Auth.login(this.state.username, this.state.password) === true){
            this.props.history.push('/simos/orders');
        } 
    }
    componentDidMount() {
        if(Auth.loggedIn()){
            console.log('already logged in');
            this.props.history.replace('/simos/orders');
        } 
    }

    render() {
        return (
            <div>
            <div className='login-center'>
            <div className='login-box-outer'>
                <h2 className='login-form-h1'>Login</h2>
                <form>
                    <label className='login-label'>Company Name: </label><input type="text" className='login-input-field' name='username' onChange={this.setLoginInfo.bind(this)}></input><br></br>
                    <label className='login-label'>Password: </label><input type="password" className='login-input-field' name='password' onChange={this.setLoginInfo.bind(this)}></input><br></br>
                    <button className='login-form-submit-btn' onClick={this.handleSubmit.bind(this)}>Log in</button>
                </form><br></br>
                {/* <div className='login-box-register'>
                    <a href='/simos/register'>Register</a>
                </div> */}
            </div>
            </div>
            </div>
        );
    }
}