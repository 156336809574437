import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IUpdateCarrierEmailLoadAction, IUpdateCarrierEmailLoadFailedAction, IUpdateCarrierEmailSuccessAction, 
    updateCarrierEmailLoadFailedAction, updateCarrierEmailLoadSuccessAction
} from "./updateCarrierEmailActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IUPDATE_CARRIER_EMAIL_REQUEST } from "./updateCarrierEmailConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";

export const updateCarrierEmailEpic: Epic = (
    action$: ActionsObservable<IUpdateCarrierEmailLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IUpdateCarrierEmailSuccessAction | IUpdateCarrierEmailLoadFailedAction> =>
    action$.ofType(IUPDATE_CARRIER_EMAIL_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<string>>(
                    END_POINTS.PROPSENSE.SIL.CARRIER.UPDATE_CARRIER_EMAIL,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<string>): IUpdateCarrierEmailSuccessAction | IUpdateCarrierEmailLoadFailedAction => {
                            if(response.message === "Success"){
                                return updateCarrierEmailLoadSuccessAction(response.message);
                            };
                            return updateCarrierEmailLoadFailedAction(response.message)
                        }),
                        catchError(() => ActionsObservable.of(updateCarrierEmailLoadFailedAction("Unable to update carrier emails")))
                    )
            )
        );