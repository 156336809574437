import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IStore } from "../../../../reducers";
import { EpicDependencies } from "../../../../store";
import { END_POINTS } from "../../../../endpoints";
import { withJsonContentType } from "../../../../epicUtils";
import { getJobsLoadFailedAction, getJobsLoadSuccessAction, IGetJobsLoadAction, IGetJobsLoadFailedAction, IGetJobsSuccessAction } from "./getJobsActions";
import { IGET_JOBS_REQUEST } from "./getJobsConstants";
import { PropsenseAPIResponse } from "../../../../../react/shared/publicInterfaces";


export const getJobsEpic: Epic = (
    action$: ActionsObservable<IGetJobsLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetJobsSuccessAction | IGetJobsLoadFailedAction> =>
    action$.ofType(IGET_JOBS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<PropsenseAPIResponse<any>>(
                    END_POINTS.PROPSENSE.CATAPULT.CREW_SCHEDULING.GET_JOBS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: PropsenseAPIResponse<any>): IGetJobsSuccessAction => {
                                return getJobsLoadSuccessAction(response);
                        }),
                        catchError(() => ActionsObservable.of(getJobsLoadFailedAction("Unable to load the job list")))
                    )
            )
        );