import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import styled from "styled-components";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import PageSpacing from "../../../shared/pageSpacing";
import { LAPaperWithPadding } from "../../../shared/paper";
import SilRoleCheck from "../../../shared/silRoleCheck";


const WashPlantHomeStyles = styled(LAPaperWithPadding)`
    margin: 40px 30px;
`;

type IWashPlantHomeProps = RouteComponentProps;

class WashPlantHome extends PureComponent<IWashPlantHomeProps> {

    public constructor(props: IWashPlantHomeProps) {
        super(props);
        this.state = {
        };
    }

    public componentDidMount(): void {

    };

    public render(): ReactNode {

        return (
            <PageSpacing title="SIL - Wash Plant" description="SIL - Wash Plant" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="washPlantAccess">
                    <WashPlantHomeStyles>
                        <LAGrid spacing={3} className="text-center">

                            <LAGridItem xs={12}>
                                <h2 className="title">Wash Plant Home</h2>
                            </LAGridItem>

                        </LAGrid>
                    </WashPlantHomeStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

}

export default WashPlantHome;


