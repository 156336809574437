import { IAddBinAdjustmentRequest, IADD_BIN_ADJUSTMENT_REQUEST } from "./addBinAdjustmentConstaints";

export interface IAddBinAdjustmentLoadAction {
    type: IADD_BIN_ADJUSTMENT_REQUEST.REQUEST;
    data: IAddBinAdjustmentRequest
}
export const addBinAdjustmentLoadAction = (data: IAddBinAdjustmentRequest): IAddBinAdjustmentLoadAction => ({
    type: IADD_BIN_ADJUSTMENT_REQUEST.REQUEST,
    data
});

export interface IAddBinAdjustmentSuccessAction {
    type: IADD_BIN_ADJUSTMENT_REQUEST.SUCCESS;
    message: string;
}
export const addBinAdjustmentLoadSuccessAction = (message: string): IAddBinAdjustmentSuccessAction => ({
    type: IADD_BIN_ADJUSTMENT_REQUEST.SUCCESS,
    message
});
export interface IAddBinAdjustmentLoadFailedAction {
    type: IADD_BIN_ADJUSTMENT_REQUEST.FAILED;
    message: string;
}
export const addBinAdjustmentLoadFailedAction = (message: string): IAddBinAdjustmentLoadFailedAction => ({
    type: IADD_BIN_ADJUSTMENT_REQUEST.FAILED,
    message
});
