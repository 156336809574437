import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import { SilAPIResponse } from "../../../shared/publicInterfaces";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import { callRouteWithQueryString, userName } from "../../../shared/constExports";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { LAButton } from "../../../shared/buttons";
import { AddIcon } from "../../../shared/icons";
import { WHITE_COLOR } from "../../../shared/theme";
import { ROUTE } from "../../../routes";
import PageSpacing from "../../../shared/pageSpacing";
import SilRoleCheck from "../../../shared/silRoleCheck";
import LALoading from "../../../shared/loading";
import { IGeoBag, IGetGeoBagsRequest } from "../../../../redux/sil/dispatch/getGeoBags/getGeoBagsConstaints";
import { getGeoBagsLoadAction } from "../../../../redux/sil/dispatch/getGeoBags/getGeoBagsActions";
import { getGeoBagsStatus } from "../../../../redux/sil/dispatch/getGeoBags/getGeoBagsAccessor";


interface IGeoBagsStoreProps {
    getToken: Server<SilAPIResponse<IToken>>;
    getGeoBags: Server<SilAPIResponse<IGeoBag[]>>;
};

interface IGeoBagsDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getGeoBagsRequest: (data: IGetGeoBagsRequest) => unknown;
};


interface IGeoBagsOwnProps {

};

interface IGeoBagsState {

};

const GeoBagsStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
    };
`;

type IGeoBagsProps = RouteComponentProps
    & IGeoBagsStoreProps
    & IGeoBagsDispatchProps
    & IGeoBagsOwnProps;

class GeoBags extends PureComponent<IGeoBagsProps, IGeoBagsState> {

    public constructor(props: IGeoBagsProps) {
        super(props);
        this.state = {
        };
    }

    public componentDidMount(): void {
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IGeoBagsProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();
        };
    };


    public render(): ReactNode {

        const { getGeoBags } = this.props;

        return (
            <PageSpacing title="SIL - CV" description="SIL - GeoBags" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="carrierAccess">
                    <GeoBagsStyles>

                        {getGeoBags.kind !== STATUS_ENUM.LOADING &&
                            <LAButton className="pull-left" startIcon={<AddIcon color={WHITE_COLOR} />} label="Add" onClick={this.handleAdd} />}
                        <h2 className="text-center">GEO BAGS</h2>
                        <hr />

                        {getGeoBags.kind === STATUS_ENUM.LOADING &&
                            <LALoading className="loading" message="Loading..." />}

                        {getGeoBags.kind === STATUS_ENUM.SUCCEEDED && <LADevExtremeGrid
                            data={getGeoBags.payload.response}
                            onEdit={this.onEdit}
                            onClick={this.onClick}
                            height={1000}
                            searchPanel={true}
                            filterHeader={true}
                            export={true}
                            exportFileName="GeoBagsList"
                            storageKey="silGeoBagsStorageKey"
                            columns={[
                                { name: "date", caption: "Date", type: "date" },
                                { name: "operator", caption: "Operator", type: "string" },
                                { name: "sampleNTU_to_Pond", caption: "Sample NTU to Pond", type: "number" },
                                { name: "sampleNTU_Before", caption: "Sample NTU Before", type: "number" },
                                { name: "sampleNTU_Bag_One", caption: "Sample NTU Bag One", type: "number" },
                                { name: "sampleNTU_Bag_Two", caption: "Sample NTU Bag Two", type: "number" },
                                { name: "sampleNTU_Bag_Three", caption: "Sample NTU Bag Three", type: "number" },
                                { name: "sampleNTU_Bag_Four", caption: "Sample NTU Bag Four", type: "number" },
                                { name: "sampleNTU_Bag_Five", caption: "Sample NTU Bag Five", type: "number" },
                                { name: "sampleNTU_Bag_Six", caption: "Sample NTU Bag Six", type: "number" },
                                { name: "notes", caption: "Notes", type: "string" },
                                { name: "created", caption: "Created", type: "datetime" },
                                { name: "created_By", caption: "Created By", type: "string" },
                                { name: "modified", caption: "Modified", type: "datetime" },
                                { name: "modified_By", caption: "Modified By", type: "string" }
                            ]}
                        />}

                    </GeoBagsStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString() },
            pathName: ROUTE.SIL.DISPATCH.GEO_BAG
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0" },
            pathName: ROUTE.SIL.DISPATCH.GEO_BAG
        });
    };

    private setDataToState = (): void => {
        if (isNotLoaded(this.props.getGeoBags))
            this.callServer();
    };

    private callServer = (): void => {
        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getGeoBags))
            this.props.getGeoBagsRequest({
                token: this.props.getToken.payload.response.token
            });

        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });
    }

}

const mapStateToProps = (state: IStore): IGeoBagsStoreProps => ({
    getToken: getTokenStatus(state),
    getGeoBags: getGeoBagsStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IGeoBagsDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getGeoBagsRequest: (data: IGetGeoBagsRequest) => dispatch(getGeoBagsLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(GeoBags);