import { IPassTokenWithRequest } from "../../dryPlant/token/getTokenConstants";
import { ISieveProduct } from "../getSievesProducts/getSievesProductsConstaints";

export enum IUPDATE_SIEVE_PRODUCT_REQUEST {
    REQUEST = "updateSieveProduct/UPDATE_SIEVE_PRODUCT_REQUEST",
    SUCCESS = "updateSieveProduct/UPDATE_SIEVE_PRODUCT_SUCCESS",
    FAILED = "updateSieveProduct/UPDATE_SIEVE_PRODUCT_FAILED"
};

export interface IUpdateSieveProductRequest extends IPassTokenWithRequest {
    request: IUpdateSieveProduct;
};

export interface IUpdateSieveProduct extends ISieveProduct {
};