import { IADD_SIMOS_PRODUCT_REQUEST, IAddSimosProductRequest } from "./addSimosProductConstaints";

export interface IAddSimosProductLoadAction {
    type: IADD_SIMOS_PRODUCT_REQUEST.REQUEST;
    data: IAddSimosProductRequest
}
export const addSimosProductLoadAction = (data: IAddSimosProductRequest): IAddSimosProductLoadAction => ({
    type: IADD_SIMOS_PRODUCT_REQUEST.REQUEST,
    data
});

export interface IAddSimosProductSuccessAction {
    type: IADD_SIMOS_PRODUCT_REQUEST.SUCCESS;
    message: string;
}
export const addSimosProductLoadSuccessAction = (message: string): IAddSimosProductSuccessAction => ({
    type: IADD_SIMOS_PRODUCT_REQUEST.SUCCESS,
    message
});

export interface IAddSimosProductLoadFailedAction {
    type: IADD_SIMOS_PRODUCT_REQUEST.FAILED;
    message: string;
}
export const addSimosProductLoadFailedAction = (message: string): IAddSimosProductLoadFailedAction => ({
    type: IADD_SIMOS_PRODUCT_REQUEST.FAILED,
    message
});
