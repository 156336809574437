import { IWashPlantRunMaterials } from "../getWashPlantRunMaterials/getWashPlantRunMaterialsConstaints";

export enum IUPDATE_WASH_PLANT_RUN_MATERIAL_REQUEST {
    REQUEST = "updateWashPlantRunMaterial/UPDATE_WASH_PLANT_RUN_MATERIAL_REQUEST",
    SUCCESS = "updateWashPlantRunMaterial/UPDATE_WASH_PLANT_RUN_MATERIAL_SUCCESS",
    FAILED = "updateWashPlantRunMaterial/UPDATE_WASH_PLANT_RUN_MATERIAL_FAILED"
};

export interface IUpdateWashPlantRunMaterialRequest {
    token: string;
    request: IWashPlantRunMaterials;
};