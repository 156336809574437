import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IUpdateYHDCarrierFormLoadAction, IUpdateYHDCarrierFormLoadFailedAction, IUpdateYHDCarrierFormSuccessAction, updateYHDCarrierFormLoadFailedAction,
     updateYHDCarrierFormLoadSuccessAction } from "./updateCarrierFormActions";
import { IStore } from "../../../../reducers";
import { EpicDependencies } from "../../../../store";
import { IUPDATE_YHD_CARRIER_FORM_REQUEST } from "./updateCarrierFormConstaints";
import { END_POINTS } from "../../../../endpoints";
import { withJsonContentType } from "../../../../epicUtils";
import { YHDAPIResponse } from "../../../../../react/shared/publicInterfaces";

export const updateYHDCarrierFormEpic: Epic = (
    action$: ActionsObservable<IUpdateYHDCarrierFormLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IUpdateYHDCarrierFormSuccessAction | IUpdateYHDCarrierFormLoadFailedAction> =>
    action$.ofType(IUPDATE_YHD_CARRIER_FORM_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<YHDAPIResponse<string>>(
                    END_POINTS.PROPSENSE.YHD.CARRIER.UPDATE_CARRIER_FORM,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: YHDAPIResponse<string>): IUpdateYHDCarrierFormSuccessAction | IUpdateYHDCarrierFormLoadFailedAction => {
                            
                            if(response.message === "Success"){
                                return updateYHDCarrierFormLoadSuccessAction(response.message);
                            } else {
                                return updateYHDCarrierFormLoadFailedAction(response.message);
                            };
                        }),
                        catchError(() => ActionsObservable.of(updateYHDCarrierFormLoadFailedAction("Unable to update your changes")))
                    )
            )
        );