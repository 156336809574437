import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, isSucceeded, Server } from "../../../../redux/server";
import { ById, SilAPIResponse } from "../../../shared/publicInterfaces";
import { userName, undefinedFunction, numberAndNotDecimalCheck, ComplaintCompanyOptions, ComplaintPlantOptions, hourArray, minuteArray } from "../../../shared/constExports";
import { LAButton, LASaveAndCancelButton } from "../../../shared/buttons";
import { ArrowLeftIcon } from "../../../shared/icons";
import { WARNING_COLOR, WHITE_COLOR } from "../../../shared/theme";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import LATextField from "../../../shared/textField";
import LAAutoComplete from "../../../shared/autoComplete";
import { ROUTE } from "../../../routes";
import queryString from "query-string";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import { FieldValidator, FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue, IFieldValidatorProps } from "../../../shared/fieldValidator";
import PageSpacing from "../../../shared/pageSpacing";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import SilRoleCheck from "../../../shared/silRoleCheck";
import { LADateTimeMUIPicker } from "../../../shared/dateTimePickerMUI";
import LATextArea from "../../../shared/textArea";
import { IAddComplaintLogging, IAddComplaintLoggingRequest } from "../../../../redux/sil/complaintLogging/addComplaintLogging/addComplaintLoggingConstaints";
import { IUpdateComplaintLoggingRequest } from "../../../../redux/sil/complaintLogging/updateComplaintLogging/updateComplaintLoggingConstaints";
import { addComplaintLoggingLoadAction } from "../../../../redux/sil/complaintLogging/addComplaintLogging/addComplaintLoggingActions";
import { updateComplaintLoggingLoadAction } from "../../../../redux/sil/complaintLogging/updateComplaintLogging/updateComplaintLoggingActions";
import { updateComplaintLoggingStatus } from "../../../../redux/sil/complaintLogging/updateComplaintLogging/updateComplaintLoggingAccessor";
import { addComplaintLoggingStatus } from "../../../../redux/sil/complaintLogging/addComplaintLogging/addComplaintLoggingAccessor";
import { IComplaintLogging, IGetComplaintLoggingRequest } from "../../../../redux/sil/complaintLogging/getComplaintLogging/getComplaintLoggingConstaints";
import { getComplaintLoggingLoadAction } from "../../../../redux/sil/complaintLogging/getComplaintLogging/getComplaintLoggingActions";
import { getComplaintLoggingStatus } from "../../../../redux/sil/complaintLogging/getComplaintLogging/getComplaintLoggingAccessor";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { IIdName } from "../../../../utils/sharedTypes";

const methodOfComplaintOptions: string[] = ["By Phone", "By Email", "In Person", "Other"];
const requiredFields = ["date_Of_Complaint", "complainant_First_Name", "immediate_Action_Taken", "suspected_Cause", "description", "method_Of_Complaint"];

interface IComplaintLoggingStoreProps {
    addComplaintLogging: Server<string>;
    updateComplaintLogging: Server<string>;
    getToken: Server<SilAPIResponse<IToken>>;
    getComplaintLogging: Server<SilAPIResponse<IComplaintLogging[]>>;
};

interface IComplaintLoggingDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getComplaintLoggingRequest: (data: IGetComplaintLoggingRequest) => unknown;
    addComplaintLoggingRequest: (data: IAddComplaintLoggingRequest) => unknown;
    updateComplaintLoggingRequest: (data: IUpdateComplaintLoggingRequest) => unknown;
};


interface IComplaintLoggingOwnProps {

};

interface IComplaintLoggingState {
    data: IComplaintLogging;
    hour: string;
    minute: string;
    methodOfComplaint: string;
    errors: ById<IFieldErrorKeyValue>;
};

const ComplaintLoggingStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;

    .dx-toolbar-after {
        display: none;
    };

    .required {
        color: ${WARNING_COLOR};
    }

    .variancepopup{
        height: 50% !important;
    }
`;

type IComplaintLoggingProps = RouteComponentProps
    & IComplaintLoggingStoreProps
    & IComplaintLoggingDispatchProps
    & IComplaintLoggingOwnProps;

class ComplaintLogging extends PureComponent<IComplaintLoggingProps, IComplaintLoggingState> {

    public constructor(props: IComplaintLoggingProps) {
        super(props);
        this.state = {
            errors: {
                "date_Of_Complaint": { key: "date_Of_Complaint", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "complainant_First_Name": { key: "complainant_First_Name", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "description": { key: "description", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "immediate_Action_Taken": { key: "immediate_Action_Taken", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "suspected_Cause": { key: "suspected_Cause", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "method_Of_Complaint": { key: "method_Of_Complaint", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
            },
            methodOfComplaint: "",
            hour: "",
            minute: "",
            data: {
                id: 0,
                plant: ComplaintPlantOptions[0].name,
                date_Of_Complaint: "",
                complainant_First_Name: "",
                complainant_Last_Name: "",
                contact_Number: "",
                method_Of_Complaint: "",
                residential_Address: "",
                email_Address: "",
                description: "",
                immediate_Action_Taken: "",
                suspected_Cause: "",
                equipment_Running: "",
                company: ComplaintCompanyOptions[0].name,
                last_Note: "",
                last_Note_User: "",
                last_Note_Date: "",
                follow_Up_Actions: "",
                created: "",
                created_By: userName,
                modified: "",
                modified_By: userName
            }
        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: IComplaintLoggingProps): void {
        if (this.props !== prevProps) {
            this.callServer();

            if (this.props.addComplaintLogging !== prevProps.addComplaintLogging) {
                if (isSucceeded(this.props.addComplaintLogging)) {
                    this.handleCancel();
                };
            };

            if (this.props.updateComplaintLogging !== prevProps.updateComplaintLogging) {
                if (isSucceeded(this.props.updateComplaintLogging)) {
                    this.handleCancel();
                };
            };
        };
    };


    public render(): ReactNode {

        const { data, errors, methodOfComplaint, hour, minute } = this.state;
        const { addComplaintLogging, updateComplaintLogging } = this.props;
        const handleOnHourChange = (event: unknown, value: IIdName<number>): void => this.onTimeChange("hour", value !== null ? value.name : "");
        const handleOnMinuteChange = (event: unknown, value: IIdName<number>): void => this.onTimeChange("minute", value !== null ? value.name : "");
        const handleOnPlantChange = (event: unknown, value: IIdName<number>): void => this.onChange("plant", value !== null ? value.name : data.plant);
        const handleOnCompanyChange = (event: unknown, value: IIdName<number>): void => this.onChange("company", value !== null ? value.name : data.company);

        return (
            <PageSpacing title="SIL - Warehouse Product" description="Warehouse Product" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="complaintLoggingAccess">
                    <ComplaintLoggingStyles>

                        <LAButton startIcon={<ArrowLeftIcon color={WHITE_COLOR} />} label="Back to list" onClick={this.handleCancel} />
                        <h2 className="text-center">{data.id > 0 ? "VIEW/UPDATE " : "ADD "} Complaint Logging</h2>
                        <hr />

                        <LAPaperWithPadding>
                            <LAGrid spacing={1}>
                            <LAGridItem xs={12} sm={5} md={4}>
                                    <strong>Method Of Complaint</strong>
                                    <br />
                                    {errors && errors["method_Of_Complaint"] && <span className="required">Required</span>}
                                    <RadioGroup row aria-label="" name="methodOfComplaint" value={methodOfComplaint} onChange={this.handleRadioClick}>
                                        <FormControlLabel value={methodOfComplaintOptions[0]} control={<Radio />} label={methodOfComplaintOptions[0]} />
                                        <FormControlLabel value={methodOfComplaintOptions[1]} control={<Radio />} label={methodOfComplaintOptions[1]} />
                                        <FormControlLabel value={methodOfComplaintOptions[2]} control={<Radio />} label={methodOfComplaintOptions[2]} />
                                        <FormControlLabel value={methodOfComplaintOptions[3]} control={<Radio />} label={methodOfComplaintOptions[3]} />
                                    </RadioGroup>

                                    {(methodOfComplaint === methodOfComplaintOptions[3]) && <LATextField
                                        varient="outlined"
                                        label="Method of Complainant"
                                        fullWidth={true}
                                        name="method_Of_Complaint"
                                        onChange={this.onChange}
                                        value={data.method_Of_Complaint ?? ""}
                                        errorText={errors && errors["method_Of_Complaint"] ? errors["method_Of_Complaint"].message : undefined}
                                    />}
                                </LAGridItem>

                                <LAGridItem xs={12} sm={3} md={2}>
                                    <LADateTimeMUIPicker
                                        name="date_Of_Complaint"
                                        label="Date of Complaint"
                                        fullWidth={true}
                                        dateOnly={true}
                                        value={data.date_Of_Complaint ?? ""}
                                        onChange={this.onChange}
                                        errorText={errors && errors["date_Of_Complaint"] ? errors["date_Of_Complaint"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={6} sm={1} md={1}>
                                <LAAutoComplete
                                        multiple={false}
                                        option={hourArray}
                                        getOptionLabel="name"
                                        autoHighlight={true}
                                        onChange={handleOnHourChange}
                                        filterSelectedOptions={true}
                                        dropDownPlaceHolder="Hour"
                                        selectionRemove={undefinedFunction}
                                        value={hour ? hourArray.find(q => q.name === hour) : null}
                                        defaultValue={hour ? hourArray.find(q => q.name === hour) : null}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={6} sm={1} md={1}>
                                    <LAAutoComplete
                                        multiple={false}
                                        option={minuteArray}
                                        getOptionLabel="name"
                                        autoHighlight={true}
                                        onChange={handleOnMinuteChange}
                                        filterSelectedOptions={true}
                                        dropDownPlaceHolder="Minutes"
                                        selectionRemove={undefinedFunction}
                                        value={minute ? minuteArray.find(q => q.name === minute) : null}
                                        defaultValue={minute ? minuteArray.find(q => q.name === minute) : null}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={4}>                                   
                                     <LATextField
                                        varient="outlined"
                                        label="Immediate Action Taken"
                                        fullWidth={true}
                                        name="immediate_Action_Taken"
                                        onChange={this.onChange}
                                        value={data.immediate_Action_Taken ?? ""}
                                        errorText={errors && errors["immediate_Action_Taken"] ? errors["immediate_Action_Taken"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>                                   
                                     <LATextField
                                        varient="outlined"
                                        label="First Name of Complainant"
                                        fullWidth={true}
                                        name="complainant_First_Name"
                                        disabled={data.id > 0}
                                        onChange={this.onChange}
                                        value={data.complainant_First_Name ?? ""}
                                        errorText={errors && errors["complainant_First_Name"] ? errors["complainant_First_Name"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>                                   
                                     <LATextField
                                        varient="outlined"
                                        label="Last Name of Complainant"
                                        fullWidth={true}
                                        name="complainant_Last_Name"
                                        disabled={data.id > 0}
                                        onChange={this.onChange}
                                        value={data.complainant_Last_Name ?? ""}  
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        label="Contact Number"
                                        fullWidth={true}
                                        varient="outlined"
                                        name="contact_Number"
                                        type="number"
                                        InputProps={{ inputProps: { min: 0, max: 10 } }}
                                        onChange={this.onChange}
                                        value={data.contact_Number}
                                        errorText={errors["contact_Number"] ? errors["contact_Number"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        label="Email Address"
                                        fullWidth={true}
                                        varient="outlined"
                                        name="email_Address"
                                        onChange={this.onChange}
                                        value={data.email_Address ?? ""}
                                        errorText={errors["email_Address"] ? errors["email_Address"].message : undefined}
                                        />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>                                   
                                     <LATextField
                                        varient="outlined"
                                        label="Residential Address"
                                        fullWidth={true}
                                        name="residential_Address"
                                        onChange={this.onChange}
                                        value={data.residential_Address ?? ""}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LAAutoComplete
                                        multiple={false}
                                        option={ComplaintPlantOptions}
                                        getOptionLabel="name"
                                        autoHighlight={true}
                                        onChange={handleOnPlantChange}
                                        filterSelectedOptions={true}
                                        dropDownPlaceHolder="Plant"
                                        selectionRemove={undefinedFunction}
                                        value={data.plant ? ComplaintPlantOptions.find(q => q.name === data.plant) : null}
                                        defaultValue={data.plant ? ComplaintPlantOptions.find(q => q.name === data.plant) : null}
                                    />
                                </LAGridItem>
                                
                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LAAutoComplete
                                        multiple={false}
                                        option={ComplaintCompanyOptions}
                                        getOptionLabel="name"
                                        autoHighlight={true}
                                        onChange={handleOnCompanyChange}
                                        filterSelectedOptions={true}
                                        dropDownPlaceHolder="Company"
                                        selectionRemove={undefinedFunction}
                                        value={data.company ? ComplaintCompanyOptions.find(q => q.name === data.company) : null}
                                        defaultValue={data.company ? ComplaintCompanyOptions.find(q => q.name === data.company) : null}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        label="Suspected Cause"
                                        fullWidth={true}
                                        varient="outlined"
                                        name="suspected_Cause"
                                        onChange={this.onChange}
                                        value={data.suspected_Cause}
                                        errorText={errors && errors["suspected_Cause"] ? errors["suspected_Cause"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6}>                                   
                                    <LATextArea
                                        minRows={4}
                                        rowsMax={10}
                                        label="Description of Complaint"
                                        fullWidth={true}
                                        name="description"
                                        onChange={this.onChange}
                                        value={data.description}
                                        errorText={errors["description"] ? errors["description"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6}>                                   
                                    <LATextArea
                                        minRows={4}
                                        rowsMax={10}
                                        label="Equipment Running at Time of Complaint"
                                        fullWidth={true}
                                        name="equipment_Running"
                                        onChange={this.onChange}
                                        value={data.equipment_Running}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6}>                                   
                                    <LATextArea
                                        minRows={4}
                                        rowsMax={10}
                                        label="Follow up Actions/Notes"
                                        fullWidth={true}
                                        name="follow_Up_Actions"
                                        onChange={this.onChange}
                                        value={data.follow_Up_Actions}
                                    />
                                </LAGridItem>

                                {data.id > 0 && <LAGridItem xs={12} sm={6}>                                   
                                    <LATextArea
                                        minRows={4}
                                        rowsMax={10}
                                        label="Last Notes"
                                        fullWidth={true}
                                        name="last_Note"
                                        disabled={true}
                                        onChange={this.onChange}
                                        value={data.last_Note}
                                    />
                                </LAGridItem>}

                                {data.id > 0 && <LAGridItem xs={12} sm={6} md={3}>                                   
                                     <LATextField
                                        varient="outlined"
                                        label="Last Note User"
                                        fullWidth={true}
                                        disabled={true}
                                        name="last_Note_User"
                                        onChange={this.onChange}
                                        value={data.last_Note_User ?? ""}
                                    />
                                </LAGridItem>}

                                {data.id > 0 && <LAGridItem xs={12} sm={6} md={3}>
                                    <LADateTimeMUIPicker
                                        name="last_Note_Date"
                                        label="Last Note Date"
                                        fullWidth={true}
                                        // dateOnly={true}
                                        disabled={true}
                                        onChange={this.onChange}
                                        value={data.last_Note_Date ?? ""}
                                    />
                                </LAGridItem>}

                                <LAGridItem xs={12}>
                                    <LASaveAndCancelButton
                                        onSave={this.onSave}
                                        onCancel={this.handleCancel}
                                        disableSave={(Object.values(errors).length > 0 ? true : undefined)}
                                    />
                                </LAGridItem>

                            </LAGrid>
                        </LAPaperWithPadding>

                        <RequestStatus requestStatus={addComplaintLogging.kind} successMessage="Complaint Logging successfully saved" />
                        <RequestStatus requestStatus={updateComplaintLogging.kind} successMessage="Complaint Logging successfully updated" />

                    </ComplaintLoggingStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private onTimeChange = (name: string, value: string): void => {
        const iS = {...this.state.data};
        const date = new Date(iS.date_Of_Complaint);

        if(name === "hour"){
            date.setHours(+value);
        } else {
            date.setMinutes(+value);
        };

        this.setState({
            ...this.state,
            hour: name === "hour" ? value : this.state.hour,
            minute: name !== "hour" ? value : this.state.minute,
            data: {
                ...this.state.data,
                date_Of_Complaint: date.toLocaleString()
            }
        });
    };

    private handleRadioClick = (event: React.ChangeEvent<HTMLInputElement>, radioValue: string): void => {
        let errors = this.state.errors;

        let rules: IFieldValidatorProps = {};

        rules.required = true;

        if(radioValue !== methodOfComplaintOptions[3]){
            errors = this.errorChecker("method_Of_Complaint", radioValue, errors, rules);

            this.setState({
                ...this.state,
                errors,
                data: {
                    ...this.state.data,
                    method_Of_Complaint: radioValue
                },
                methodOfComplaint: radioValue
            });
        } else {
            errors = this.errorChecker("method_Of_Complaint", "", errors, rules);
            this.setState({
                ...this.state,
                errors,
                methodOfComplaint: methodOfComplaintOptions[3],
                data: {
                    ...this.state.data,
                    method_Of_Complaint: ""
                }
            });
        };
    };

    // private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, isRequired: boolean): ById<IFieldErrorKeyValue> => {
    //     const result = FieldValidator(value, { required: isRequired ? true : undefined, minLength: 1, decimal: undefined });
    //     const err: ById<IFieldErrorKeyValue> = errors;

    //     if (result.length > 0) {
    //         err[name] = { key: name, message: result };
    //     } else {
    //         delete err[name];
    //     };
    //     return err;
    // };

    private errorChecker = (
        name: string,
        value: string,
        errors: ById<IFieldErrorKeyValue>,
        rules?: IFieldValidatorProps
      ): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, rules ?? { required: true, minLength: 1 });
        const err: ById<IFieldErrorKeyValue> = errors;
      
        if (result.length > 0) {
          err[name] = { key: name, message: result };
        } else {
          delete err[name];
        }
        return err;
      };
    

    private handleCancel = (): void => {
        this.props.history.push(ROUTE.SIL.COMPLAINT_LOGGINGS.INDEX);
    };

    private onSave = async (): Promise<void> => {

        if (hasPayload(this.props.getToken)) {
            const { data } = this.state;

            let request: IAddComplaintLogging = {
                Date_Of_Complaint: data.date_Of_Complaint,
                Method_Of_Complaint: data.method_Of_Complaint,
                Complainant_First_Name: data.complainant_First_Name,
                Complainant_Last_Name: data.complainant_Last_Name,
                Contact_Number: data.contact_Number,
                Residential_Address: data.residential_Address,
                Email_Address: data.email_Address,
                Plant: data.plant,
                Company: data.company,
                Description: data.description,
                Immediate_Action_Taken: data.immediate_Action_Taken,
                Suspected_Cause: data.suspected_Cause,
                Equipment_Running: data.equipment_Running,
                Follow_Up_Actions: data.follow_Up_Actions,
                Created: data.created,
                Modified: data.modified,
                Created_By: data.created_By,
                Modified_By: userName
            };

            if (data.id === 0) {
                this.props.addComplaintLoggingRequest({
                    token: this.props.getToken.payload.response.token,
                    request
                });
            } else {
                request.Id = data.id;

                this.props.updateComplaintLoggingRequest({
                    token: this.props.getToken.payload.response.token,
                    request
                });
            };
        }
    };

    private onChange = (name: string, value: string): void => {
        let errors = this.state.errors;

        let rules: IFieldValidatorProps = {};

        if (name === "contact_Number") {   
            if(value.length > 0) {
                rules.phone = true;
                rules.maxLength = 10;
            } 
            errors = this.errorChecker(name, value, errors, rules);
        }
        if (name === "email_Address") { 
            if(value.length > 0) {
                rules.email = true;
            }  
            errors = this.errorChecker(name, value, errors, rules);
        }

        if (requiredFields.includes(name)) {
            rules.required = true;
            errors = this.errorChecker(name, value, errors, rules);
        }

            this.setState({
                ...this.state,
                errors,
                data: {
                    ...this.state.data,
                    [name]: value
                }
            });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getComplaintLogging))
            this.props.getComplaintLoggingRequest({
                token: this.props.getToken.payload.response.token
            });


        const query = queryString.parse(this.props.location.search);
        const id = query.id ?? "0";

        if ((id !== "0") && (hasPayload(this.props.getComplaintLogging)) && (this.state.data.id === 0)) {
            const data = this.props.getComplaintLogging.payload.response.find((x) => x.id === +id);

            if (data){
                const date = new Date(data.date_Of_Complaint);
                const minute = date.getMinutes().toString();
                const hour = date.getHours().toString();
                const method = methodOfComplaintOptions.includes(data.method_Of_Complaint);
                this.setState({ data, hour, minute, methodOfComplaint: method ? data.method_Of_Complaint : methodOfComplaintOptions[3], errors: {} });
            }
        };

    };

}

const mapStateToProps = (state: IStore): IComplaintLoggingStoreProps => ({
    getToken: getTokenStatus(state),
    getComplaintLogging: getComplaintLoggingStatus(state),
    updateComplaintLogging: updateComplaintLoggingStatus(state),
    addComplaintLogging: addComplaintLoggingStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IComplaintLoggingDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getComplaintLoggingRequest: (data: IGetComplaintLoggingRequest) => dispatch(getComplaintLoggingLoadAction(data)),
    addComplaintLoggingRequest: (data: IAddComplaintLoggingRequest) => dispatch(addComplaintLoggingLoadAction(data)),
    updateComplaintLoggingRequest: (data: IUpdateComplaintLoggingRequest) => dispatch(updateComplaintLoggingLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ComplaintLogging);