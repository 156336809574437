import { IPassTokenWithRequest } from "../../dryPlant/token/getTokenConstants";

export enum IADD_SIEVE_REQUEST {
    REQUEST = "addSieve/ADD_SIEVE_REQUEST",
    SUCCESS = "addSieve/ADD_SIEVE_SUCCESS",
    FAILED = "addSieve/ADD_SIEVE_FAILED"
};

export interface IAddSieveRequest extends IPassTokenWithRequest {
    request: IAddSieve;
};

export interface IAddSieve {
    ID: number;
    Date: string;
    Operator: string;
    Bin?: string;
    Plant: string;
    Product: string;
    Notes: string;
    Load_No: string;
    Feed_Material_1?: string;
    Feed_Material_2?: string;
    Feed_Material_3?: string;
    Feed_Material_4?: string;
    Feed_Material_1_Perc?: number;
    Feed_Material_2_Perc?: number;
    Feed_Material_3_Perc?: number;
    Feed_Material_4_Perc?: number;
    Cancelled: string;
    Wash_Mass?: number;
    Wash_NTU?: number;
    Duplicate_CantyID?: number;
    Screen_Section?: string;
    Initial_Mass: number;
    Split_Mass?: number;
    Perc_Fines?: number;
    Rotap_ID?: number;
    Created_By: string;         
    Modified_By: string;
    Measurements: IAddSieveMeasurementRequest[];
};

export interface IAddSieveMeasurementRequest {
    ID: number;
    Mesh: string;
    Weight: number;
    Perc_Pass: number;
    Perc_Return: number;
    Perc_Pass_Spec: string;
    Perc_Ret_Spec: string;
};