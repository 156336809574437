import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IGetRunMaterialsLoadAction, IGetRunMaterialsLoadFailedAction, IGetRunMaterialsSuccessAction, 
    getRunMaterialsLoadFailedAction, getRunMaterialsLoadSuccessAction
} from "./getRunMaterialsActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IGET_RUN_MATERIALS_REQUEST, IRunMaterials } from "./getRunMaterialsConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";

export const getRunMaterialsEpic: Epic = (
    action$: ActionsObservable<IGetRunMaterialsLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IGetRunMaterialsSuccessAction | IGetRunMaterialsLoadFailedAction> =>
    action$.ofType(IGET_RUN_MATERIALS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<IRunMaterials[]>>(
                    END_POINTS.PROPSENSE.SIL.DRY_PLANT.GET_DRY_PLANT_RUN_MATERIALS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<IRunMaterials[]>): IGetRunMaterialsSuccessAction => {
                            return getRunMaterialsLoadSuccessAction(response);
                        }),
                        catchError(() => ActionsObservable.of(getRunMaterialsLoadFailedAction("Unable to get run materials")))
                    )
            )
        );