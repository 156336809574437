import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IGetWashPlantProductionLookupsLoadAction, IGetWashPlantProductionLookupsLoadFailedAction, IGetWashPlantProductionLookupsSuccessAction, 
    getWashPlantProductionLookupsLoadFailedAction, getWashPlantProductionLookupsLoadSuccessAction
} from "./getWashPlantProductionLookupsActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IGET_WASH_PLANT_PRODUCTION_LOOKUPS_REQUEST, IWashPlantProductionLookups } from "./getWashPlantProductionLookupsConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";

export const getWashPlantProductionLookupsEpic: Epic = (
    action$: ActionsObservable<IGetWashPlantProductionLookupsLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IGetWashPlantProductionLookupsSuccessAction | IGetWashPlantProductionLookupsLoadFailedAction> =>
    action$.ofType(IGET_WASH_PLANT_PRODUCTION_LOOKUPS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<IWashPlantProductionLookups>>(
                    END_POINTS.PROPSENSE.SIL.WASH_PLANT.GET_WASH_PLANT_PRODUCTION_LOOKUPS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<IWashPlantProductionLookups>): IGetWashPlantProductionLookupsSuccessAction => {
                            return getWashPlantProductionLookupsLoadSuccessAction(response);
                        }),
                        catchError(() => ActionsObservable.of(getWashPlantProductionLookupsLoadFailedAction("Unable to getWashPlant production lookups")))
                    )
            )
        );