import { ROUTE } from "../routes";
import styled from "styled-components";
import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { LAMenuItem } from "../shared/dropDownMenu";
import { DARK_GREY_COLOR, MAIN_COLOR, RED_COLOR } from "../shared/theme";
import { Menu } from "@material-ui/core";


const WashPlantHeaderStyles = styled.div`
    .sil-page {
        background-color: ${MAIN_COLOR};
        width: 100%;
        display: flex;
        align-items: center;
        height: 50px;
    };

    span {
        color: ${RED_COLOR};
    };

    .current {
        background-color: ${DARK_GREY_COLOR};
    };
`;

interface IWashPlantHeaderState {
    report: any;
};

type IWashPlantHeaderProps =
    RouteComponentProps;

class WashPlantHeader extends PureComponent<IWashPlantHeaderProps, IWashPlantHeaderState> {

    public constructor(props: IWashPlantHeaderProps) {
        super(props);
        this.state = {
            report: null
        };
    }

    public render(): ReactNode {
        const path = this.props.location.pathname;
        return (
            <WashPlantHeaderStyles>
                <div className="sil-page">

                    <LAMenuItem onClick={this.onHome}>
                        {/* <WarrantyIcon color={WHITE_COLOR} /> */}
                        <span className="">
                            SIL Home
                        </span>
                    </LAMenuItem>

                    <LAMenuItem onClick={this.onWashPlant} className={(path === ROUTE.SIL.DRY_PLANT.DRY_PLANT_ITEMS) ? "current" : ""}>
                        {/* <WarrantyIcon color={WHITE_COLOR} /> */}
                        <span>
                            Wash Plant
                        </span>
                    </LAMenuItem>

                    <LAMenuItem onClick={this.onRunMaterial} className={(path === ROUTE.SIL.DRY_PLANT.DRY_PLANT_RUN_MATERIALS) ? "current" : ""}>
                        {/* <WarrantyIcon color={WHITE_COLOR} /> */}
                        <span className="">
                            By Products
                        </span>
                    </LAMenuItem>

                    <LAMenuItem onClick={this.onFeedMaterial} className={(path === ROUTE.SIL.DRY_PLANT.DRY_PLANT_FEED_MATERIALS) ? "current" : ""}>
                        {/* <WarrantyIcon color={WHITE_COLOR} /> */}
                        <span className="">
                            Feed Materials
                        </span>
                    </LAMenuItem>

                    <LAMenuItem onClick={this.onPlantEmployee} className={(path === ROUTE.SIL.DRY_PLANT.DRY_PLANT_EMPLOYEES) ? "current" : ""}>
                        {/* <WarrantyIcon color={WHITE_COLOR} /> */}
                        <span className="">
                            Plant Employees
                        </span>
                    </LAMenuItem>

                    <LAMenuItem onClick={this.onBinAdjustment} className={(path === ROUTE.SIL.DRY_PLANT.DRY_PLANT_BIN_ADJUSTMENTS) ? "current" : ""}>
                        {/* <WarrantyIcon color={WHITE_COLOR} /> */}
                        <span className="">
                            Pile Adjustments
                        </span>
                    </LAMenuItem>

                    <LAMenuItem onClick={this.onReport}>
                        {/* <WarrantyIcon color={WHITE_COLOR} /> */}
                        <span className="">
                            Reports
                        </span>
                    </LAMenuItem>

                

                    <Menu
                        anchorEl={this.state.report}
                        open={this.state.report}
                        onClose={this.onReport}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                    >
                        <LAMenuItem onClick={this.onReportOne}>
                            <span className="">
                                Washplant Production
                            </span>
                        </LAMenuItem>

                        <LAMenuItem onClick={this.onReportTwo}>
                            <span className="">
                            Washplant Production - API Explorer
                            </span>
                        </LAMenuItem>
                    </Menu>

                </div>
            </WashPlantHeaderStyles>
        );
    }

    private onDryPlant = (): void => {
        this.props.history.push(ROUTE.SIL.DRY_PLANT.INDEX);
    };

    private onHome = (): void => {
        this.props.history.push(ROUTE.SIL.INDEX);
    };

    private onBinAdjustment = (): void => {
        this.props.history.push(ROUTE.SIL.WASH_PLANT.WASH_PLANT_BIN_ADJUSTMENTS);
    };

    private onWashPlant = (): void => {
        this.props.history.push(ROUTE.SIL.WASH_PLANT.WASH_PLANT_ITEMS);
    };

    private onRunMaterial = (): void => {
        this.props.history.push(ROUTE.SIL.WASH_PLANT.WASH_PLANT_RUN_MATERIALS);
    };

    private onFeedMaterial = (): void => {
        this.props.history.push(ROUTE.SIL.WASH_PLANT.WASH_PLANT_FEED_MATERIALS);
    };

    private onPlantEmployee = (): void => {
        this.props.history.push(ROUTE.SIL.WASH_PLANT.WASH_PLANT_EMPLOYEES);
    };

    private onReport = (event?: any): void => {
        if(this.state.report !== null){
            this.setState({ report: null });
        } else {
            this.setState({ report: event.currentTarget });
        }
    };

    private onReportOne = (): void => {
       window.open('https://sharepoint.sureway.ca/SIL/Reports/WashPlant_Production_PS.rdl', '_blank');
    };

    private onReportTwo = (): void => {
    window.open('https://sharepoint.sureway.ca/SIL/Reports/API_Explorer_WashPlantProduction.rdl', '_blank');
    };

}

export default (WashPlantHeader);