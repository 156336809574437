import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IUPDATE_CARRIER_EMAIL_REQUEST } from "./updateCarrierEmailConstaints";
import { IUpdateCarrierEmailLoadAction, IUpdateCarrierEmailLoadFailedAction, IUpdateCarrierEmailSuccessAction } from "./updateCarrierEmailActions";

type Actions =
    IUpdateCarrierEmailLoadAction
    | IUpdateCarrierEmailLoadFailedAction
    | IUpdateCarrierEmailSuccessAction
    | IFlushDataSuccessAction;

export const UpdateCarrierEmailReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IUPDATE_CARRIER_EMAIL_REQUEST.REQUEST:
            return loading;

        case IUPDATE_CARRIER_EMAIL_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IUPDATE_CARRIER_EMAIL_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};