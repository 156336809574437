import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IGetSimosTrucksLoadAction, IGetSimosTrucksLoadFailedAction, IGetSimosTrucksSuccessAction, 
    getSimosTrucksLoadFailedAction, getSimosTrucksLoadSuccessAction
} from "./getSimosTrucksActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IGET_SIMOS_TRUCKS_REQUEST, ISimosTrucks } from "./getSimosTrucksConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SimosAPIResponse } from "../../../../react/shared/publicInterfaces";

export const getSimosTrucksEpic: Epic = (
    action$: ActionsObservable<IGetSimosTrucksLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IGetSimosTrucksSuccessAction | IGetSimosTrucksLoadFailedAction> =>
    action$.ofType(IGET_SIMOS_TRUCKS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SimosAPIResponse<ISimosTrucks[]>>(
                    END_POINTS.PROPSENSE.SIMOS.GET_SIMOS_TRUCK,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SimosAPIResponse<ISimosTrucks[]>): IGetSimosTrucksSuccessAction | IGetSimosTrucksLoadFailedAction => {
                            if(response.message !== "Success")
                                return getSimosTrucksLoadFailedAction(response.message);
                            return getSimosTrucksLoadSuccessAction(response);
                        }),
                        catchError(() => ActionsObservable.of(getSimosTrucksLoadFailedAction("Unable to get SimosTrucks info")))
                    )
            )
        );