import { IWashPlantProductionRecords } from "../getWashPlantProductionRecords/getWashPlantProductionRecordsConstaints";

export enum IADD_WASH_PLANT_PRODUCTION_REQUEST {
    REQUEST = "addWashPlantProduction/ADD_WASH_PLANT_PRODUCTION_REQUEST",
    SUCCESS = "addWashPlantProduction/ADD_WASH_PLANT_PRODUCTION_SUCCESS",
    FAILED = "addWashPlantProduction/ADD_WASH_PLANT_PRODUCTION_FAILED"
};

export interface IAddWashPlantProductionRequest {
    token: string;
    request: IWashPlantProductionRecords;
};