import React from 'react';
import DataGrid, {
    Column, FilterRow, HeaderFilter, 
    Paging, Button, Pager, SearchPanel, 
    FilterPanel,
    FilterBuilderPopup,
    Export,
  } from 'devextreme-react/data-grid';
  import 'devextreme/dist/css/dx.light.css';

  const renderTitleHeader = (data:any) => {
    return <p style={{color:'#5383d3',font: 'Cookie',fontSize:'14px'}}>{data.column.caption}</p>
}
function StyledComponent(cellData:any):any {
    return (
        <div className={"devex-styled-grid-row-default"}>{cellData.value}</div>
    )
}
const filterBuilderPopupPosition = {
    of: window,
    at: 'top',
    my: 'top',
    offset: { y: 10 },
  };
interface IProps {
    history:any;
}
interface IState {
    orderHistory:any;
    searchValue:string;
}
export default class OrderHistoryTable extends React.Component<IProps,IState>{
    constructor(props:IProps){
        super(props);
        this.state = {orderHistory:[], searchValue: ''}

    }
    componentDidMount(){
        var n = window.location.pathname.lastIndexOf('=');
        var orderID = Number(window.location.pathname.substring(n + 1));
        var orderHistory:any = [];
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type':'application/json' }, 
            body: JSON.stringify({token: '19515195-d571-44a3-b0db-637f5bf24f54', id:orderID})
        }
        fetch('https://api2.propsense.com/api/GetSIMOSOrderVersionHistory', requestOptions)
        .then(async response => {
            const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
              // error response
              if(!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            // no error
            data.map((history:any) => {
                history.created = history.created?.replace("T"," ");
                history.modified = history.modified?.replace("T"," ");
                history.pickup_Datetime = history.pickup_Datetime?.replace("T"," ");
                history.delivery_Datetime = history.delivery_Datetime?.replace("T"," ");
                orderHistory.push(history);
            });
            this.setState({orderHistory:orderHistory});
        })
    }

    handleValueChange = (e:any) => {
        // console.log(e);
        const trimmedValue = e.trim();
        this.setState({searchValue: trimmedValue});
      };

    render(){
        return (
            <div className='grid-view'>
            <br></br><br></br>
                <div style={{width:'100%'}}>
                    <DataGrid id={'gridContainer'}
                    focusedRowEnabled={true}
                    hoverStateEnabled={true}
                    rowAlternationEnabled={true}
                        dataSource={this.state.orderHistory}
                        keyExpr="id"
                        showBorders={true}
                        // took out height={740}
                        width={'100%'}> 
                    <SearchPanel visible={true}
                        width={340}
                        placeholder="Search for..." 
                        text={this.state.searchValue}
                        onTextChange={this.handleValueChange}
                        />    
                    <Paging enabled={true} defaultPageSize={10}/>
                    <Pager
                        visible={true}
                        allowedPageSizes={[10,15,20]}
                        displayMode={'full'}
                        showPageSizeSelector={true}
                        showInfo={true}
                        showNavigationButtons={true} />
                        <FilterRow visible={true}></FilterRow>
                        <FilterBuilderPopup position={filterBuilderPopupPosition} />
                    <HeaderFilter visible={true} allowSearch={true} />
                    <FilterPanel visible={true} />
                        <Export enabled={true} allowExportSelectedData={true} />
                        <Column dataField="modified" width={300} caption="Modified" alignment="left"  cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
                        <Column dataField="modified_By" width={300} caption="Modified By" alignment="left"  cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
                        <Column dataField="customer" width={300} caption="Customer" alignment="left"  cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
                        <Column dataField="address" width={300} caption="Address" alignment="left"  cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
                        <Column dataField="pickup_Datetime" width={300} caption="Pickup Date" alignment="left"  cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
                        <Column dataField="delivery_Datetime" width={300} caption="Delivery Date" alignment="left"  cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
                        <Column dataField="sitecontact" width={300} caption="Site Contact" alignment="left"  cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
                        <Column dataField="sitecontact_Phone" width={300} caption="Site Contact Phone" alignment="left"  cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
                        <Column dataField="pO_Number" width={300} caption="PO #" alignment="left"  cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
                        <Column dataField="transport_Company" width={300} caption="Transport Company" alignment="left"  cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
                        <Column dataField="truck_Required" width={300} caption="Truck Required" alignment="left"  cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
                        <Column dataField="location" width={300} caption="Location" alignment="left"  cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
                        <Column dataField="comments" width={300} caption="Comments" alignment="left"  cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
                        <Column dataField="status" width={300} caption="Status" alignment="left"  cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
                        <Column dataField="packaged" width={300} caption="Packaged" alignment="left"  cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
                        <Column dataField="completed" width={300} caption="Completed" alignment="left"  cellRender={StyledComponent} headerCellRender={renderTitleHeader}/>
                        
                    </DataGrid>
                </div>
            </div>
        );
    }
}