import { IBeltScale } from "../getBeltScales/getBeltScalesConstaints";

export enum IADD_BELT_SCALE_REQUEST {
    REQUEST = "addBeltScale/ADD_BELT_SCALE_REQUEST",
    SUCCESS = "addBeltScale/ADD_BELT_SCALE_SUCCESS",
    FAILED = "addBeltScale/ADD_BELT_SCALE_FAILED"
};

export interface IAddBeltScaleRequest {
    token: string;
    request: IBeltScale;
};