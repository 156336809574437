import { IPassTokenWithRequest } from "../../../sil/dryPlant/token/getTokenConstants";
import {  IProductOrder, ISIMOSOrders } from "../getOrders/getOrdersConstaints";

export enum IGET_ORDER_BY_ID_REQUEST {
    REQUEST = "getOrderById/GET_ORDER_BY_ID_REQUEST",
    SUCCESS = "getOrderById/GET_ORDER_BY_ID_SUCCESS",
    FAILED = "getOrderById/GET_ORDER_BY_ID_FAILED"
};

export interface IGetOrderByIdRequest extends IPassTokenWithRequest {
    request: {
        id: number;
    }
};

export interface ISIMOSOrderById extends ISIMOSOrders {
};