import React from 'react';
import {Modal, Box, Backdrop, Grid, Button, TextField, InputLabel, Checkbox, FormControl, FormControlLabel, Typography} from '@mui/material';
// import {addTransportCompany} from '../../../components/propsense/simos/APICalls/addUpdateTransportCompany';
// import {optionTransport} from '../../../components/propsense/simos/OrderPage/siteInfo';
import {addSalesOrderNumber} from '../APICalls/addSalesOrderNumber';
import {isSalesOrderNumberUnique} from '../APICalls/getRequests/getSIMOSSalesOrderNumbers';
import { chosenId } from './orderedBy';

interface IProps{
    cancelPopup:any;
    refresh:any;
}
interface IState{

}
const BoxStyle:any = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '25%',
    backgroundColor: 'rgb(241, 241, 241) !important',
    border: '2px solid #000',
    boxShadow: 24,
    padding: '4ch',
  };
  const ExitButtonStyle = {
      bgcolor: 'rgb(174, 180, 184)',
      color: 'white',
      fontWeight: 'bold',
      left:'90%'
  }
var new_transport_company:any = {sales_order_number:''};
export default class CreateSalesOrderNumberPopup extends React.Component<IProps, IState>{
    constructor(props:IProps){
        super(props);
        this.state = {};
        this.onTextChange = this.onTextChange.bind(this);
        this.onSave = this.onSave.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }

    onTextChange(event:any) {
      var exists = false;
      switch(event.target.name){
          case 'sales_order_number':
              new_transport_company.sales_order_number = event.target.value;
              break;
      }
      this.setState({});
  }
    closePopup(){
        this.props.cancelPopup();
    }
    async onSave(){
          if(new_transport_company.sales_order_number!="" ){
            var isUnique:any = await isSalesOrderNumberUnique(new_transport_company.sales_order_number);
            if(isUnique){
              var success:any = await addSalesOrderNumber(new_transport_company.sales_order_number, chosenId);
              if(success.status === 200){
                  this.props.refresh();
                  alert("Successfully added new sales order number");
              }
              else{
                alert("This sales order number already exists!");
              }
            }
      }
      else{
          //alert("All fields must be filled!");
          this.setState({showError:true, errorText:'All fields must be filled!'});
      }
    }

    render(){

    return (
        <Modal id='warehouse-emp-modal'
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={true}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}>
                    <Box sx={BoxStyle}>
                    <Button sx={ExitButtonStyle} onClick={this.closePopup}>X</Button>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography>Creating New Sales Order Number</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField fullWidth label="Sales Order Number" onChange={this.onTextChange} name="sales_order_number"></TextField>
                      </Grid>
                      <Grid item xs={12}>
                      <Button style={{width:'100px',backgroundColor:'#bf0000', left:'80%'}} variant="contained" onClick={this.onSave}>Save</Button>
                      </Grid>
                      </Grid></Box></Modal>
        
    );
                }
}