import { ISieveDryPlant } from "../getSieveDryPlants/getSieveDryPlantsConstaints";

export enum IUPDATE_SIEVE_DRY_PLANT_REQUEST {
    REQUEST = "updateSieveDryPlant/UPDATE_SIEVE_DRY_PLANT_REQUEST",
    SUCCESS = "updateSieveDryPlant/UPDATE_SIEVE_DRY_PLANT_SUCCESS",
    FAILED = "updateSieveDryPlant/UPDATE_SIEVE_DRY_PLANT_FAILED"
};

export interface IUpdateSieveDryPlantRequest {
    token: string;
    request: ISieveDryPlant;
};