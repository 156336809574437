import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IGetTokenLoadAction, IGetTokenLoadFailedAction, IGetTokenSuccessAction } from "./getTokenActions";
import { IGET_TOKEN_REQUEST, IToken } from "./getTokenConstants";

type Actions =
    IGetTokenLoadAction
    | IGetTokenLoadFailedAction
    | IGetTokenSuccessAction
    | IFlushDataSuccessAction;

export const GetTokenReducer = (state: Server<SilAPIResponse<IToken>> = notLoaded, action: Actions): Server<SilAPIResponse<IToken>> => {
    switch (action.type) {
        case IGET_TOKEN_REQUEST.REQUEST:
            return loading;

        case IGET_TOKEN_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_TOKEN_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};