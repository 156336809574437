import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IUpdateSimosTransportCompanyLoadAction, 
    IUpdateSimosTransportCompanyLoadFailedAction, 
    IUpdateSimosTransportCompanySuccessAction, 
    updateSimosTransportCompanyLoadFailedAction, updateSimosTransportCompanyLoadSuccessAction
} from "./updateSimosTransportCompanyActions";
import { IUPDATE_SIMOS_TRANSPORT_COMPANY_REQUEST } from "./updateSimosTransportCompanyConstaints";
import { EpicDependencies } from "../../../../store";
import { SimosAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { END_POINTS } from "../../../../endpoints";
import { withJsonContentType } from "../../../../epicUtils";
import { IStore } from "../../../../reducers";

export const updateSimosTransportCompanyEpic: Epic = (
    action$: ActionsObservable<IUpdateSimosTransportCompanyLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IUpdateSimosTransportCompanySuccessAction | IUpdateSimosTransportCompanyLoadFailedAction> =>
    action$.ofType(IUPDATE_SIMOS_TRANSPORT_COMPANY_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SimosAPIResponse<string>>(
                    END_POINTS.PROPSENSE.SIMOS.UPDATE_SIMOS_TRANSPORT_COMPANY,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SimosAPIResponse<string>): IUpdateSimosTransportCompanySuccessAction | IUpdateSimosTransportCompanyLoadFailedAction => {
                            console.log(response);
                            if(response.message === "Success"){
                                return updateSimosTransportCompanyLoadSuccessAction(response.message);
                            };
                            return updateSimosTransportCompanyLoadFailedAction(response.message)
                        }),
                        catchError(() => ActionsObservable.of(updateSimosTransportCompanyLoadFailedAction("Unable to update Simos Transport Company")))
                    )
            )
        );