import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IAddEmployeeLoadAction, IAddEmployeeLoadFailedAction, IAddEmployeeSuccessAction } from "./addEmployeeActions";
import { IADD_EMPLOYEE_REQUEST } from "./addEmployeeConstaints";


type Actions =
    IAddEmployeeLoadAction
    | IAddEmployeeLoadFailedAction
    | IAddEmployeeSuccessAction
    | IFlushDataSuccessAction;

export const AddEmployeeReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IADD_EMPLOYEE_REQUEST.REQUEST:
            return loading;

        case IADD_EMPLOYEE_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IADD_EMPLOYEE_REQUEST.FAILED:
            return failed(action.message);

            case FLUSH_DATA.SUCCESS:
                return notLoaded;

        default:
            return state;
    }
};