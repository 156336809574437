import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IStore } from "../../../../reducers";
import { EpicDependencies } from "../../../../store";
import { END_POINTS } from "../../../../endpoints";
import { withJsonContentType } from "../../../../epicUtils";
import { PropsenseAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { getAssignedCrewsLoadFailedAction, getAssignedCrewsLoadSuccessAction, IGetAssignedCrewsLoadAction, IGetAssignedCrewsLoadFailedAction, IGetAssignedCrewsSuccessAction } from "./getAssignedCrewsActions";
import { IAssignedCrew, IGET_ASSIGNED_CREWS_REQUEST } from "./getAssignedCrewsConstants";


export const getAssignedCrewsEpic: Epic = (
    action$: ActionsObservable<IGetAssignedCrewsLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetAssignedCrewsSuccessAction | IGetAssignedCrewsLoadFailedAction> =>
    action$.ofType(IGET_ASSIGNED_CREWS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<PropsenseAPIResponse<IAssignedCrew[]>>(
                    END_POINTS.PROPSENSE.CATAPULT.CREW_SCHEDULING.GET_ASSIGNED_CREW,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: PropsenseAPIResponse<IAssignedCrew[]>): IGetAssignedCrewsSuccessAction => {
                                return getAssignedCrewsLoadSuccessAction(response);
                        }),
                        catchError(() => ActionsObservable.of(getAssignedCrewsLoadFailedAction("Unable to load the assigned crew list")))
                    )
            )
        );