import { IPassTokenWithRequest } from "../token/getTokenConstants";

export enum IGET_BIN_ADJUSTMENTS_REQUEST {
    REQUEST = "getBinAdjustments/GET_BIN_ADJUSTMENTS_REQUEST",
    SUCCESS = "getBinAdjustments/GET_BIN_ADJUSTMENTS_SUCCESS",
    FAILED = "getBinAdjustments/GET_BIN_ADJUSTMENTS_FAILED"
};

export interface IGetBinAdjustmentsRequest extends IPassTokenWithRequest {

};

export interface IBinAdjustments {
    id: number;
    type: "Adjustment" | "Transfer";
    adjustment_Type: "Add" | "Remove";
    bin: string;
    bin_From: string;
    bin_To: string;
    product: string;
    tonnage: number | undefined;
    reason: string;
    created?: string;
    created_By: string;
    modified?: string;
    transfer_Date?: string;
    modified_By: string;
};