import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IGetSieveCalibrationsLoadAction, IGetSieveCalibrationsLoadFailedAction, IGetSieveCalibrationsSuccessAction, 
    getSieveCalibrationsLoadFailedAction, getSieveCalibrationsLoadSuccessAction
} from "./getSievesCalibrationsActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IGET_SIEVES_CALIBRATIONS_REQUEST, ISieveCalibration } from "./getSievesCalibrationsConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { ById, SilAPIResponse } from "../../../../react/shared/publicInterfaces";

export const getSieveCalibrationsEpic: Epic = (
    action$: ActionsObservable<IGetSieveCalibrationsLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IGetSieveCalibrationsSuccessAction | IGetSieveCalibrationsLoadFailedAction> =>
    action$.ofType(IGET_SIEVES_CALIBRATIONS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<ById<ISieveCalibration>>>(
                    END_POINTS.PROPSENSE.SIL.SIEVES.GET_SIEVES_CALIBRATIONS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<ById<ISieveCalibration>>): IGetSieveCalibrationsSuccessAction => {
                            return getSieveCalibrationsLoadSuccessAction(response);
                        }),
                        catchError(() => ActionsObservable.of(getSieveCalibrationsLoadFailedAction("Unable to get sieves calibration")))
                    )
            )
        );