import { getTransloadBOLCountsStatus } from '../../../../redux/sil/dispatch/getTransloadBOLCounts/getTransloadBOLCountsAccessor';
import { updateTransloadBOLCountStatus } from '../../../../redux/sil/dispatch/updateTransloadBOLCount/updateTransloadBOLCountAccessor';
import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, isSucceeded, Server } from "../../../../redux/server";
import { ById, SilAPIResponse } from "../../../shared/publicInterfaces";
import { undefinedFunction, userName } from "../../../shared/constExports";
import { ROUTE } from "../../../routes";
import queryString from "query-string";
import { FieldValidator, FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../../shared/fieldValidator";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import { ITransloadBOLCount, IGetTransloadBOLCountsRequest } from '../../../../redux/sil/dispatch/getTransloadBOLCounts/getTransloadBOLCountsConstaints';
import { IUpdateTransloadBOLCountRequest } from '../../../../redux/sil/dispatch/updateTransloadBOLCount/updateTransloadBOLCountConstaints';
import { getTransloadBOLCountsLoadAction } from '../../../../redux/sil/dispatch/getTransloadBOLCounts/getTransloadBOLCountsActions';
import { updateTransloadBOLCountLoadAction } from '../../../../redux/sil/dispatch/updateTransloadBOLCount/updateTransloadBOLCountActions';
import PageSpacing from '../../../shared/pageSpacing';
import SilRoleCheck from '../../../shared/silRoleCheck';
import { LAButton, LASaveAndCancelButton } from '../../../shared/buttons';
import LAGrid from '../../../shared/grid';
import LAGridItem from '../../../shared/gridList';
import { ArrowLeftIcon } from '../../../shared/icons';
import RequestStatus from '../../../shared/requestStatusSnackbar';
import LATextField from '../../../shared/textField';
import { WHITE_COLOR } from '../../../shared/theme';


interface ITransloadBOLCountStoreProps {
    updateTransloadBOLCount: Server<string>;
    getToken: Server<SilAPIResponse<IToken>>;
    getTransloadBOLCounts: Server<SilAPIResponse<ITransloadBOLCount[]>>;
};

interface ITransloadBOLCountDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getTransloadBOLCountsRequest: (data: IGetTransloadBOLCountsRequest) => unknown;
    updateTransloadBOLCountRequest: (data: IUpdateTransloadBOLCountRequest) => unknown;
};


interface ITransloadBOLCountOwnProps {

};

interface ITransloadBOLCountState {
    transloadBOLCount: ITransloadBOLCount;
    errors: ById<IFieldErrorKeyValue>;
};

const TransloadBOLCountStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
`;

type ITransloadBOLCountProps = RouteComponentProps
    & ITransloadBOLCountStoreProps
    & ITransloadBOLCountDispatchProps
    & ITransloadBOLCountOwnProps;

class TransloadBOLCount extends PureComponent<ITransloadBOLCountProps, ITransloadBOLCountState> {

    public constructor(props: ITransloadBOLCountProps) {
        super(props);
        this.state = {
            errors: {
                "trans_BOL_Submitted": { key: "trans_BOL_Submitted", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "direct_BOL_Submitted": { key: "direct_BOL_Submitted", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "customer_BOL_Submitted": { key: "customer_BOL_Submitted", message: FIELD_VALIDATOR_ERRORS.REQUIRED }
            },
            transloadBOLCount: {
                id: 0,
                date: "",
                transload: "",
                trans_BOL_Submitted: "",
                direct_BOL_Submitted: "",
                customer_BOL_Submitted: "",
                created: "",
                created_By: userName,
                modified: "",
                modified_By: userName
            }
        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: ITransloadBOLCountProps): void {
        if (this.props !== prevProps) {
            this.callServer();

            if (this.props.updateTransloadBOLCount !== prevProps.updateTransloadBOLCount) {
                if (isSucceeded(this.props.updateTransloadBOLCount)) {
                    this.handleCancel();
                };
            };
        };
    };


    public render(): ReactNode {

        const { updateTransloadBOLCount } = this.props;
        const { transloadBOLCount, errors } = this.state;

        return (
            <PageSpacing title="SIL - DISPATCH" description="TRANSLOAD BOL COUNT" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="carrierAccess">
                    <TransloadBOLCountStyles>

                        <LAButton startIcon={<ArrowLeftIcon color={WHITE_COLOR} />} label="Back to list" onClick={this.handleCancel} />
                        <h2 className="text-center">VIEW TRANSLOAD BOL COUNT</h2>
                        <hr />

                        <LAPaperWithPadding>
                            <LAGrid spacing={3}>

                                <LAGridItem xs={4}>
                                    <LATextField
                                        varient="outlined"
                                        label="Date"
                                        fullWidth={true}
                                        disabled={true}
                                        name="date"
                                        onChange={undefinedFunction}
                                        value={transloadBOLCount.date ?? ""}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LATextField
                                        varient="outlined"
                                        label="Transload"
                                        fullWidth={true}
                                        disabled={true}
                                        name="transload"
                                        onChange={undefinedFunction}
                                        value={transloadBOLCount.transload ?? ""}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LATextField
                                        varient="outlined"
                                        fullWidth={true}
                                        type="number"
                                        name="customer_BOL_Submitted"
                                        label="Customer BOL Submitted"
                                        onChange={this.onNumberChange}
                                        value={transloadBOLCount.customer_BOL_Submitted ?? ""}
                                        errorText={errors["customer_BOL_Submitted"] ? errors["customer_BOL_Submitted"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LATextField
                                        varient="outlined"
                                        fullWidth={true}
                                        type="number"
                                        name="direct_BOL_Submitted"
                                        label="Direct BOL Submitted"
                                        onChange={this.onNumberChange}
                                        value={transloadBOLCount.direct_BOL_Submitted ?? ""}
                                        errorText={errors["direct_BOL_Submitted"] ? errors["direct_BOL_Submitted"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LATextField
                                        varient="outlined"
                                        label="Trans BOL Submitted"
                                        fullWidth={true}
                                        type="number"
                                        name="trans_BOL_Submitted"
                                        onChange={this.onNumberChange}
                                        value={transloadBOLCount.trans_BOL_Submitted ?? ""}
                                        errorText={errors["trans_BOL_Submitted"] ? errors["trans_BOL_Submitted"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12}>
                                    <hr />
                                    <LASaveAndCancelButton
                                        onSave={this.onSave}
                                        onCancel={this.handleCancel}
                                        disableSave={(Object.values(errors).length > 0 ? true : undefined)}
                                    />
                                </LAGridItem>

                            </LAGrid>
                        </LAPaperWithPadding>

                        <RequestStatus requestStatus={updateTransloadBOLCount.kind} successMessage="Transload BOL Count successfully updated" />
                    </TransloadBOLCountStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }


    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, isRequired: boolean): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: isRequired ? true : undefined, minLength: 1, decimal: undefined });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        };
        return err;
    };

    private handleCancel = (): void => {
        this.props.history.push(ROUTE.SIL.DISPATCH.TRANSLOAD_BOL_COUNTS);
    };

    private onSave = (): void => {
        const { transloadBOLCount } = this.state;

        if (hasPayload(this.props.getToken)){
            transloadBOLCount.modified_By = userName;

                this.props.updateTransloadBOLCountRequest({
                    token: this.props.getToken.payload.response.token,
                    request: transloadBOLCount
                });
        }
    };


    private onNumberChange = (name: string, value: string): void => {
        if (!isNaN(+value)) {
            let errors = this.state.errors;
            errors = this.errorChecker(name, value, errors, true);

            this.setState({
                ...this.state,
                errors,
                transloadBOLCount: {
                    ...this.state.transloadBOLCount,
                    [name]: +value
                }
            });
        }
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getTransloadBOLCounts))
            this.props.getTransloadBOLCountsRequest({
                token: this.props.getToken.payload.response.token
            });


        const query = queryString.parse(this.props.location.search);
        const id = query.id ?? "0";

        if ((id !== "0") && (hasPayload(this.props.getTransloadBOLCounts))) {
            const transloadBOLCount = this.props.getTransloadBOLCounts.payload.response.find(x => x.id === Number(id));

            if (transloadBOLCount)
                this.setState({ transloadBOLCount, errors: {} });
        };

    };

}

const mapStateToProps = (state: IStore): ITransloadBOLCountStoreProps => ({
    getToken: getTokenStatus(state),
    updateTransloadBOLCount: updateTransloadBOLCountStatus(state),
    getTransloadBOLCounts: getTransloadBOLCountsStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): ITransloadBOLCountDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getTransloadBOLCountsRequest: (data: IGetTransloadBOLCountsRequest) => dispatch(getTransloadBOLCountsLoadAction(data)),
    updateTransloadBOLCountRequest: (data: IUpdateTransloadBOLCountRequest) => dispatch(updateTransloadBOLCountLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(TransloadBOLCount);