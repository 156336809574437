import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IAddSimosTransportCompanyLoadAction, 
    IAddSimosTransportCompanyLoadFailedAction, 
    IAddSimosTransportCompanySuccessAction, 
    addSimosTransportCompanyLoadFailedAction, addSimosTransportCompanyLoadSuccessAction
} from "./addSimosTransportCompanyActions";
import { IADD_SIMOS_TRANSPORT_COMPANY_REQUEST } from "./addSimosTransportCompanyConstaints";
import { EpicDependencies } from "../../../../store";
import { SimosAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { END_POINTS } from "../../../../endpoints";
import { withJsonContentType } from "../../../../epicUtils";
import { IStore } from "../../../../reducers";

export const addSimosTransportCompanyEpic: Epic = (
    action$: ActionsObservable<IAddSimosTransportCompanyLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IAddSimosTransportCompanySuccessAction | IAddSimosTransportCompanyLoadFailedAction> =>
    action$.ofType(IADD_SIMOS_TRANSPORT_COMPANY_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SimosAPIResponse<string>>(
                    END_POINTS.PROPSENSE.SIMOS.ADD_SIMOS_TRANSPORT_COMPANY,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SimosAPIResponse<string>): IAddSimosTransportCompanySuccessAction | IAddSimosTransportCompanyLoadFailedAction => {
                            if(response.message === "Success"){
                                return addSimosTransportCompanyLoadSuccessAction(response.message);
                            };
                            return addSimosTransportCompanyLoadFailedAction(response.message)
                        }),
                        catchError(() => ActionsObservable.of(addSimosTransportCompanyLoadFailedAction("Unable to add Simos Transport Company")))
                    )
            )
        );