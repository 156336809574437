import { IUPDATE_WAREHOUSE_PRODUCT_REQUEST, IUpdateWarehouseProductRequest } from "./updateWarehouseProductConstaints";

export interface IUpdateWarehouseProductLoadAction {
    type: IUPDATE_WAREHOUSE_PRODUCT_REQUEST.REQUEST;
    data: IUpdateWarehouseProductRequest
}
export const updateWarehouseProductLoadAction = (data: IUpdateWarehouseProductRequest): IUpdateWarehouseProductLoadAction => ({
    type: IUPDATE_WAREHOUSE_PRODUCT_REQUEST.REQUEST,
    data
});

export interface IUpdateWarehouseProductSuccessAction {
    type: IUPDATE_WAREHOUSE_PRODUCT_REQUEST.SUCCESS;
    message: string;
}
export const updateWarehouseProductLoadSuccessAction = (message: string): IUpdateWarehouseProductSuccessAction => ({
    type: IUPDATE_WAREHOUSE_PRODUCT_REQUEST.SUCCESS,
    message
});

export interface IUpdateWarehouseProductLoadFailedAction {
    type: IUPDATE_WAREHOUSE_PRODUCT_REQUEST.FAILED;
    message: string;
}
export const updateWarehouseProductLoadFailedAction = (message: string): IUpdateWarehouseProductLoadFailedAction => ({
    type: IUPDATE_WAREHOUSE_PRODUCT_REQUEST.FAILED,
    message
});
