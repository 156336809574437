import { IAddWarehouseProductRequest } from "../addWarehouseProduct/addWarehouseProductConstaints";

export enum IUPDATE_WAREHOUSE_PRODUCT_REQUEST {
    REQUEST = "updateWarehouseProduct/UPDATE_WAREHOUSE_PRODUCT_REQUEST",
    SUCCESS = "updateWarehouseProduct/UPDATE_WAREHOUSE_PRODUCT_SUCCESS",
    FAILED = "updateWarehouseProduct/UPDATE_WAREHOUSE_PRODUCT_FAILED"
};

export interface IUpdateWarehouseProductRequest extends IAddWarehouseProductRequest {
};
