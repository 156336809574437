import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { ISaveYHDCarrierFormLoadAction, ISaveYHDCarrierFormLoadFailedAction, ISaveYHDCarrierFormSuccessAction, saveYHDCarrierFormLoadFailedAction,
     saveYHDCarrierFormLoadSuccessAction } from "./saveCarrierFormActions";
import { IStore } from "../../../../reducers";
import { EpicDependencies } from "../../../../store";
import { ISAVE_YHD_CARRIER_FORM_REQUEST } from "./saveCarrierFormConstaints";
import { END_POINTS } from "../../../../endpoints";
import { withJsonContentType } from "../../../../epicUtils";
import { YHDAPIResponse } from "../../../../../react/shared/publicInterfaces";

export const saveYHDCarrierFormEpic: Epic = (
    action$: ActionsObservable<ISaveYHDCarrierFormLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<ISaveYHDCarrierFormSuccessAction | ISaveYHDCarrierFormLoadFailedAction> =>
    action$.ofType(ISAVE_YHD_CARRIER_FORM_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<YHDAPIResponse<string>>(
                    END_POINTS.PROPSENSE.YHD.CARRIER.ADD_CARRIER_FORM,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: YHDAPIResponse<string>): ISaveYHDCarrierFormSuccessAction | ISaveYHDCarrierFormLoadFailedAction => {
                            
                            if(response.message === "Success"){
                                return saveYHDCarrierFormLoadSuccessAction(response.message);
                            } else {
                                return saveYHDCarrierFormLoadFailedAction(response.message);
                            }
                        }),
                        catchError(() => ActionsObservable.of(saveYHDCarrierFormLoadFailedAction("Unable to save your changes")))
                    )
            )
        );