import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetProductionSummaryRequest, IGET_PRODUCTION_SUMMARY_REQUEST, IProductionSummary } from "./getProductionSummaryConstants";

export interface IGetProductionSummaryLoadAction {
    type: IGET_PRODUCTION_SUMMARY_REQUEST.REQUEST;
    data: IGetProductionSummaryRequest
}
export const getProductionSummaryLoadAction = (data: IGetProductionSummaryRequest): IGetProductionSummaryLoadAction => ({
    type: IGET_PRODUCTION_SUMMARY_REQUEST.REQUEST,
    data
});

export interface IGetProductionSummarySuccessAction {
    type: IGET_PRODUCTION_SUMMARY_REQUEST.SUCCESS;
    data: SilAPIResponse<IProductionSummary[]>;
}
export const getProductionSummaryLoadSuccessAction = (data: SilAPIResponse<IProductionSummary[]>): IGetProductionSummarySuccessAction => ({
    type: IGET_PRODUCTION_SUMMARY_REQUEST.SUCCESS,
    data
});

export interface IGetProductionSummaryLoadFailedAction {
    type: IGET_PRODUCTION_SUMMARY_REQUEST.FAILED;
    message: string;
}
export const getProductionSummaryLoadFailedAction = (message: string): IGetProductionSummaryLoadFailedAction => ({
    type: IGET_PRODUCTION_SUMMARY_REQUEST.FAILED,
    message
});