import { SilAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { IGetWarehouseRequest, IGET_WAREHOUSES_REQUEST, IWarehouse } from "./getWarehousesConstaints";

export interface IGetWarehouseLoadAction {
    type: IGET_WAREHOUSES_REQUEST.REQUEST;
    data: IGetWarehouseRequest
}
export const getWarehouseLoadAction = (data: IGetWarehouseRequest): IGetWarehouseLoadAction => ({
    type: IGET_WAREHOUSES_REQUEST.REQUEST,
    data
});

export interface IGetWarehouseSuccessAction {
    type: IGET_WAREHOUSES_REQUEST.SUCCESS;
    data: SilAPIResponse<IWarehouse[]>;
}
export const getWarehouseLoadSuccessAction = (data: SilAPIResponse<IWarehouse[]>): IGetWarehouseSuccessAction => ({
    type: IGET_WAREHOUSES_REQUEST.SUCCESS,
    data
});

export interface IGetWarehouseLoadFailedAction {
    type: IGET_WAREHOUSES_REQUEST.FAILED;
    message: string;
}
export const getWarehouseLoadFailedAction = (message: string): IGetWarehouseLoadFailedAction => ({
    type: IGET_WAREHOUSES_REQUEST.FAILED,
    message
});
