import React, {Component} from 'react';
import '../../../../shared/simos/styling/productTable.css';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import getSIMOSWHProductInfo from '../APICalls/getRequests/getSIMOSWHProductInfo';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@mui/material/Checkbox';
import getSIMOSTruckInfo from '../APICalls/getRequests/getSIMOSTruckInfo';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
const StyledTableBorder = {
    border: '2px solid rgba(0, 0, 0, 0.28)',
};
const StyledTableHeader = {
    backgroundColor:'#e3efff'
};

interface IProps {

}

interface IState {
    quantity:number;
    size:string[];
    per:string[];
    product:string[];
    code:string;
    c_w:string;
    c_w_checkbox:boolean;
    d_c_w:string;
    d_c_w_checkbox:boolean;
    location:string;
    chosenSize:string;
    chosenPer:string;
    chosenProd:string;
    // productOrder:[{quantity:number, chosenSize:string, chosenPer:string, chosenProd:string, code:string, c_w:string}];
    productOrder:[{quantity:number, chosenSize:string, chosenPer:string, chosenProd:string, code:string, c_w:string,d_c_w:string}];
}
var productCascadeDropdown:any[]= [];
// var allProducts = {location: '', productOrder:[{quantity:0, chosenSize:"", chosenPer:"", chosenProd:"", code:"", c_w:""}]}
var allProducts = {location: '', productOrder:[{quantity:0, chosenSize:"", chosenPer:"", chosenProd:"", code:"", c_w:"",d_c_w:""}]}
let optionProd:any;
var truckInfo:any = [];
class ProductOrder extends Component<IProps, IState>{
    constructor(props: IProps) {
        super(props);
        // this.state = {
        //     quantity:0, size:[], per:[],product:[],code:'',c_w:'',location:'', c_w_checkbox:false,
        //     chosenSize:'',chosenPer:'',chosenProd:'',
        //     productOrder:[{quantity:0, chosenSize:'', chosenPer:'', chosenProd:'', code:"", c_w:''}]
        // }
        this.state = {
            quantity:0, size:[], per:[],product:[],code:'',c_w:'',location:'', c_w_checkbox:false, d_c_w:'', d_c_w_checkbox:false,
            chosenSize:'',chosenPer:'',chosenProd:'', 
            productOrder:[{quantity:0, chosenSize:'', chosenPer:'', chosenProd:'', code:"", c_w:'', d_c_w:''}]
        }
        this.onTextChange = this.onTextChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    async onTextChange(event: any) {
        const fieldname = event.target.name;
        switch(fieldname){ 
            case 'newquantity':
                var toNum = Number(event.target.value).toFixed(0);
                this.setState({quantity:Number(toNum)});
                break;
            case 'newsize':
                this.setState({chosenSize:event.target.value});
                if(this.state.location.includes("Warehouse")){ 
                    productCascadeDropdown[0].map((res:any) => {
                        if(event.target.value === res.size && this.state.chosenProd === res.description){
                           this.setState({code:res.code});
                        }
                    });
                }
                else{
                    truckInfo.map((trucks:any) => {
                        if(trucks.truck === event.target.value){
                            this.setState({quantity:trucks.size});
                        }
                    });
                }
                break;
            case 'newper':
                this.setState({chosenPer:event.target.value});
                break;
            case 'newproductname':
                let allSizes:string[] = [];
                this.setState({chosenProd:event.target.value, chosenSize:'', chosenPer:'', code:'', per:[]});
                  productCascadeDropdown[0].map((res:any) => {
                    if(event.target.value === res.description){
                        allSizes.push(res.size);
                    }
                });
                if(this.state.location === "Bulk"){
                    var bulkSizes:any = [];
                    this.setState({quantity:0});
                    var response:any = await getSIMOSTruckInfo();
                    if(response[0].status === 200){
                        response[1].map((elem:any) => {
                            bulkSizes.push(elem.truck);
                            truckInfo.push(elem);
                        });
                        productCascadeDropdown[0].map((res:any) => {
                            if(this.state.chosenProd === res.description){
                               this.setState({code:res.code, size:bulkSizes});
                            }
                        });
                    }
                    else{
                        alert("error retreiving bulk truck size: server error");
                    }
                }
                else{
                    this.setState({size:allSizes});   
                }
                break;
            case 'newcode':
                var chosenProd = "";
                let allSize:string[] = [];
                this.setState({code:event.target.value, chosenSize:'', chosenProd:''});
                if(this.state.location.includes('Warehouse')){
                    productCascadeDropdown[0].map((res:any) => {
                        if(res.code === event.target.value){
                            chosenProd = res.description;
                            allSize.push(res.size);
                        }
                    });
                    this.setState({chosenProd:chosenProd, size:allSize});
                }
               else{
                var bulkSize:any = [];
                productCascadeDropdown[0].map((res:any) => {
                    if(res.code === event.target.value){
                        chosenProd = res.description;
                    }
                });
                this.setState({chosenProd:chosenProd});
                var response:any = await getSIMOSTruckInfo();
                if(response[0].status === 200){
                    response[1].map((elem:any) => {
                        bulkSize.push(elem.truck);
                        truckInfo.push(elem);
                    });
                }
                else{
                    alert("error retreiving bulk truck size: server error");
                }
                this.setState({size:bulkSize});
            }
                break;
            case 'new_cw':
                this.setState({c_w_checkbox:!this.state.c_w_checkbox});
                if(event.target.value === false){
                    this.setState({c_w:"No"});
                }
                else{
                    this.setState({c_w:"Yes"});
                }
                break;
            case 'new_dcw':
                this.setState({d_c_w_checkbox:!this.state.d_c_w_checkbox});
                if(event.target.value === false){
                    this.setState({d_c_w:"No"});
                }
                else{
                    this.setState({d_c_w:"Yes"});
                }
                break;
            case 'location':
                var all_product_descriptions:string[] = [];
                // this.setState({size:[],code:'', chosenProd:'', chosenSize:'', chosenPer:'', quantity:0, c_w:''});
                this.setState({size:[],code:'', chosenProd:'', chosenSize:'', chosenPer:'', quantity:0, c_w:'', d_c_w:''});
                productCascadeDropdown = await getSIMOSWHProductInfo("","",event.target.value,"");
                productCascadeDropdown[0].map((res:any) => {
                    if(all_product_descriptions.indexOf(res.description)===-1){
                        all_product_descriptions.push(res.description);
                    }
                });
                this.setState({location:event.target.value, product:all_product_descriptions.sort()});
                break;
        }
    }
    handleClick(event:any) {
        event.preventDefault();
        if(this.state.quantity !=null && this.state.quantity > 0){
            var c_w = this.state.c_w;
            var d_c_w = this.state.d_c_w;
            if(this.state.c_w === ""){
                c_w = "No";
            }
            if(this.state.d_c_w === ""){
                d_c_w = "No";
            }
            let temp = this.state.productOrder;
            // temp.push({quantity: this.state.quantity, chosenSize: this.state.chosenSize, chosenPer: this.state.chosenPer, chosenProd:this.state.chosenProd, 
            // code:this.state.code, c_w:c_w});
            temp.push({quantity: this.state.quantity, chosenSize: this.state.chosenSize, chosenPer: this.state.chosenPer, chosenProd:this.state.chosenProd, 
            code:this.state.code, c_w:c_w, d_c_w:d_c_w});
            // this.setState({productOrder:temp, quantity:0, chosenSize:'',chosenPer:'',chosenProd:'',code:'',c_w:'', c_w_checkbox:false});
            this.setState({productOrder:temp, quantity:0, chosenSize:'',chosenPer:'',chosenProd:'',code:'',c_w:'', c_w_checkbox:false, d_c_w:'', d_c_w_checkbox:false});
            console.log(this.state.productOrder);
        }
        else{
            if(this.state.location === 'Bulk'){
                alert("Requested quantity must be entered and greater than 0");
            }
            else{
                alert("Number of pallets must be entered and greater than 0");
            }
        }
    }

    handleEdit(index: number, event: any) {
            let current_item = this.state.productOrder;
            switch(event.target.name){
                case 'editquantity':
                    current_item[index].quantity = event.target.value;
                    break;
                case 'editsize':
                    current_item[index].chosenSize = event.target.value;
                    break;
                case 'editper':
                    current_item[index].chosenPer = event.target.value;
                    break;
                case 'editproduct':
                    current_item[index].chosenProd = event.target.value;
                    break;
                case 'editcode':
                    current_item[index].code = event.target.value;
                    break;
                case 'editcw':
                    current_item[index].c_w = event.target.value;
                    break;
                case 'editdcw':
                    current_item[index].d_c_w = event.target.value;
                    break;
            }
            this.setState({productOrder: current_item});
    }

    handleDelete(idx:number) {
        alert("You are about to delete this record. Continue?");
        let currentProd = this.state.productOrder;
        currentProd.splice(idx, 1);
        this.setState({productOrder: currentProd});
    }

    componentDidMount(){
        // this.setState({c_w_checkbox:false});
        this.setState({c_w_checkbox:false, d_c_w_checkbox:false});
    }

    public render() {
        let sizes = this.state.size;
        let products = this.state.product;
        let optionSizes = sizes.map((size) => <option>{size}</option>);
        optionSizes.push(<option disabled selected></option>);
        optionProd = products.map((prod) => <option>{prod}</option>);
        optionProd.push(<option disabled selected></option>);
        allProducts = {location: this.state.location, productOrder: this.state.productOrder};
        return (
            <Grid container >
                <TableContainer style={StyledTableBorder} component={Paper}>
                    <Typography style={{fontWeight:"bold"}} variant="h5" gutterBottom component="div">Products</Typography> 
                    <FormControl required>
                        <FormLabel style={{fontWeight:'bold'}} component="legend">Location</FormLabel>
                        <RadioGroup row defaultValue={this.state.location} onChange={this.onTextChange} name='location'>
                        {window.location.href.includes("sil_ed") ? 
                            <FormControlLabel value="SIL ED Warehouse" control={<Radio />} label="SIL Ed Warehouse"/>
                            :
                            <FormControlLabel value="SIL BRU Warehouse" control={<Radio />} label="SIL Bru Warehouse"/>}
                            <FormControlLabel value="Bulk" control={<Radio />} label="Bulk"/>
                        </RadioGroup>
                    </FormControl>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell style={StyledTableHeader}>Product</TableCell>
                            <TableCell style={StyledTableHeader}>Size</TableCell>
                            {this.state.location.includes('Warehouse') ? <>
                            <TableCell style={StyledTableHeader}>Bags Per Pallet</TableCell>
                            <TableCell style={StyledTableHeader}># of Pallets</TableCell>
                            </>: <TableCell style={StyledTableHeader}>Requested Quantity(MT)</TableCell>}
                            <TableCell style={StyledTableHeader}>Product Code</TableCell>
                            {this.state.location.includes('Warehouse') ?
                            <>
                            <TableCell style={StyledTableHeader}>C&W</TableCell>
                            <TableCell style={StyledTableHeader}>Double C&W</TableCell>
                            <TableCell style={StyledTableHeader}>Total Quantity</TableCell>
                            </>
                            :null}
                            <TableCell style={StyledTableHeader}>Actions</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.productOrder.map((obj, ind) => {
                             if(obj.code!=""){
                                    return <StyledTableRow>
                                    <TableCell>{obj.chosenProd}</TableCell> 
                                    <TableCell>{obj.chosenSize}</TableCell>
                                    {this.state.location.includes('Warehouse') ?
                                    <TableCell>{obj.chosenPer}</TableCell>  
                                    :null}
                                    <TableCell><TextField type='number' autoComplete="nope" name="editquantity" onChange={this.handleEdit.bind(this, ind)} defaultValue={obj.quantity}></TextField></TableCell>
                                    <TableCell>{obj.code}</TableCell> 
                                    {this.state.location.includes('Warehouse') ?
                                    <>
                                    <TableCell><Checkbox disabled checked={obj.c_w==="Yes"}></Checkbox></TableCell>  
                                    <TableCell><Checkbox disabled checked={obj.d_c_w==="Yes"}></Checkbox></TableCell>
                                    <TableCell></TableCell>
                                    </>
                                    :null}  
                                    <TableCell><Button id="del-product-btn" onClick={this.handleDelete.bind(this, ind)} >X</Button></TableCell> 
                                    </StyledTableRow>
                             }
                            })}
                        </TableBody>
                        <TableBody>
                            <TableCell><Select native name="newproductname" value={this.state.chosenProd} onChange={this.onTextChange}>{optionProd}</Select></TableCell>
                            <TableCell><Select native name="newsize" value={this.state.chosenSize} onChange={this.onTextChange}>{optionSizes}</Select></TableCell>
                            {this.state.location.includes('Warehouse') ?
                            <TableCell><TextField autoComplete="nope" type='number' name="newper" value={this.state.chosenPer} onChange={this.onTextChange}></TextField></TableCell>
                            :null}
                            <TableCell><TextField autoComplete="nope" type='number' name="newquantity" onChange={this.onTextChange} value={this.state.quantity}></TextField></TableCell>
                            <TableCell><TextField autoComplete="nope" name="newcode" value={this.state.code} onChange={this.onTextChange} ></TextField></TableCell>
                            {this.state.location.includes('Warehouse')?
                            <>
                            <TableCell>
                            <Checkbox checked={this.state.c_w_checkbox} onChange={this.onTextChange}  name="new_cw"></Checkbox>
                                {/* <Select native name="new_cw" onChange={this.onTextChange} value={this.state.c_w}><option disabled selected></option>
                                        <option>Yes</option>
                                        <option>No</option>
                                        </Select> */}
                            </TableCell>
                            <TableCell>
                                <Checkbox checked={this.state.d_c_w_checkbox} onChange={this.onTextChange}  name="new_dcw"></Checkbox>
                            </TableCell>
                            <TableCell><TextField disabled value={this.state.quantity * Number(this.state.chosenPer)}></TextField></TableCell>
                            </>
                            :null}
                            <TableCell><Button onClick={this.handleClick} id="add-product-btn">Add Row</Button></TableCell>
                        </TableBody>
                    </Table>
                </TableContainer>
                    <br></br>

                    </Grid>
                
        );
    }
}
export default ProductOrder;
export {allProducts};
export {optionProd};