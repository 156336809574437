import { IPSAttachment } from "../../../../../utils/sharedTypes";
import { IPassTokenWithRequest } from "../../../dryPlant/token/getTokenConstants";


export enum IGET_CRANES_REQUEST {
    REQUEST = "getCrane/GET_CRANES_REQUEST",
    SUCCESS = "getCrane/GET_CRANES_SUCCESS",
    FAILED = "getCrane/GET_CRANES_FAILED"
};

export interface IGetCraneRequest extends IPassTokenWithRequest {

};

export interface ICrane {
    id: number;
    month?: number;
    year?: number;
    crane: string;
    completed: string;
    attachments: string;
    created: string;
    created_By: string;
    modified: string;
    modified_By: string;
    attachmentContent: IPSAttachment[];
};