import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IUpdateSimosCustomerContactLoadAction, 
    IUpdateSimosCustomerContactLoadFailedAction, 
    IUpdateSimosCustomerContactSuccessAction, 
    updateSimosCustomerContactLoadFailedAction, updateSimosCustomerContactLoadSuccessAction
} from "./updateSimosCustomerContactActions";
import { IUPDATE_SIMOS_CUSTOMER_CONTACT_REQUEST } from "./updateSimosCustomerContactConstaints";
import { EpicDependencies } from "../../../../store";
import { SimosAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { END_POINTS } from "../../../../endpoints";
import { withJsonContentType } from "../../../../epicUtils";
import { IStore } from "../../../../reducers";

export const updateSimosCustomerContactEpic: Epic = (
    action$: ActionsObservable<IUpdateSimosCustomerContactLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IUpdateSimosCustomerContactSuccessAction | IUpdateSimosCustomerContactLoadFailedAction> =>
    action$.ofType(IUPDATE_SIMOS_CUSTOMER_CONTACT_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SimosAPIResponse<string>>(
                    END_POINTS.PROPSENSE.SIMOS.UPDATE_SIMOS_CUSTOMER_CONTACT,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SimosAPIResponse<string>): IUpdateSimosCustomerContactSuccessAction | IUpdateSimosCustomerContactLoadFailedAction => {
                            console.log(response);
                            if(response.message === "Success"){
                                return updateSimosCustomerContactLoadSuccessAction(response.message);
                            };
                            return updateSimosCustomerContactLoadFailedAction(response.message)
                        }),
                        catchError(() => ActionsObservable.of(updateSimosCustomerContactLoadFailedAction("Unable to update Simos Customer Contact")))
                    )
            )
        );