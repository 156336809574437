import React from "react";
import { ISimosCustomerAddress } from "../../../../../redux/propsense/simos/simosCustomerAddress/getSimosCustomerAddress/getSimosCustomerAddressConstants";
import { undefinedFunction } from "../../../../shared/constExports";
import { LADevExtremeGrid } from "../../../../shared/devExtreme";
import { LAPaperWithPadding } from "../../../../shared/paper";
import { LAPopover } from "../../../../shared/popOver";

interface ICustomerAddressPopup {
    open: boolean;
    onClose: () => void;
    data: ISimosCustomerAddress[];
};

export const CustomerAddressPopup: React.FC<ICustomerAddressPopup> = React.memo((props: ICustomerAddressPopup) => (
    <LAPopover open={props.open} onClose={props.onClose} anchorRef={null}>
        <LAPaperWithPadding>
            <LADevExtremeGrid
                height={400}
                id="customerAddressPopup"
                searchPanel={false}
                filterHeader={false}
                data={props.data}
                onClick={undefinedFunction}
                columns={[
                    { name: "address", caption: "Address", type: "string" },
                    { name: "created", caption: "Created", type: "datetime" },
                    { name: "created_By", caption: "Created By", type: "string" },
                    { name: "modified", caption: "Modified", type: "datetime" },
                    { name: "modified_By", caption: "Modified By", type: "string" }
                   
                ]}
            />
        </LAPaperWithPadding>
    </LAPopover>
));