import { INCR } from "../getNCRs/getNCRsConstaints";
import { IUpdateNCRRequest, IUPDATE_NCR_REQUEST } from "./updateNCRConstaints";

export interface IUpdateNCRLoadAction {
    type: IUPDATE_NCR_REQUEST.REQUEST;
    data: IUpdateNCRRequest
}
export const updateNCRLoadAction = (data: IUpdateNCRRequest): IUpdateNCRLoadAction => ({
    type: IUPDATE_NCR_REQUEST.REQUEST,
    data
});

export interface IUpdateNCRSuccessAction {
    type: IUPDATE_NCR_REQUEST.SUCCESS;
    data: INCR;
}
export const updateNCRLoadSuccessAction = (data: INCR): IUpdateNCRSuccessAction => ({
    type: IUPDATE_NCR_REQUEST.SUCCESS,
    data
});

export interface IUpdateNCRLoadFailedAction {
    type: IUPDATE_NCR_REQUEST.FAILED;
    message: string;
}
export const updateNCRLoadFailedAction = (message: string): IUpdateNCRLoadFailedAction => ({
    type: IUPDATE_NCR_REQUEST.FAILED,
    message
});
