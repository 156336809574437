import { SilAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { IUpdateCarrierAuditRequest, IUPDATE_CARRIER_AUDIT_REQUEST } from "./updateAuditConstants";

export interface IUpdateCarrierAuditLoadAction {
    type: IUPDATE_CARRIER_AUDIT_REQUEST.REQUEST;
    data: IUpdateCarrierAuditRequest
}
export const updateCarrierAuditLoadAction = (data: IUpdateCarrierAuditRequest): IUpdateCarrierAuditLoadAction => ({
    type: IUPDATE_CARRIER_AUDIT_REQUEST.REQUEST,
    data
});
export interface IUpdateCarrierAuditSuccessAction {
    type: IUPDATE_CARRIER_AUDIT_REQUEST.SUCCESS;
    list: SilAPIResponse<string>;
}
export const updateCarrierAuditLoadSuccessAction = (list: SilAPIResponse<string>): IUpdateCarrierAuditSuccessAction => ({
    type: IUPDATE_CARRIER_AUDIT_REQUEST.SUCCESS,
    list
});
export interface IUpdateCarrierAuditLoadFailedAction {
    type: IUPDATE_CARRIER_AUDIT_REQUEST.FAILED;
    message: string;
}
export const updateCarrierAuditLoadFailedAction = (message: string): IUpdateCarrierAuditLoadFailedAction => ({
    type: IUPDATE_CARRIER_AUDIT_REQUEST.FAILED,
    message
});
