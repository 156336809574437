import { IAddBeltScaleRequest, IADD_BELT_SCALE_REQUEST } from "./addBeltScaleConstaints";

export interface IAddBeltScaleLoadAction {
    type: IADD_BELT_SCALE_REQUEST.REQUEST;
    data: IAddBeltScaleRequest
}
export const addBeltScaleLoadAction = (data: IAddBeltScaleRequest): IAddBeltScaleLoadAction => ({
    type: IADD_BELT_SCALE_REQUEST.REQUEST,
    data
});

export interface IAddBeltScaleSuccessAction {
    type: IADD_BELT_SCALE_REQUEST.SUCCESS;
    message: string;
}
export const addBeltScaleLoadSuccessAction = (message: string): IAddBeltScaleSuccessAction => ({
    type: IADD_BELT_SCALE_REQUEST.SUCCESS,
    message
});
export interface IAddBeltScaleLoadFailedAction {
    type: IADD_BELT_SCALE_REQUEST.FAILED;
    message: string;
}
export const addBeltScaleLoadFailedAction = (message: string): IAddBeltScaleLoadFailedAction => ({
    type: IADD_BELT_SCALE_REQUEST.FAILED,
    message
});
