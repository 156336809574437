import { IADD_WAREHOUSE_PRODUCT_REQUEST, IAddWarehouseProductRequest } from "./addWarehouseProductConstaints";

export interface IAddWarehouseProductLoadAction {
    type: IADD_WAREHOUSE_PRODUCT_REQUEST.REQUEST;
    data: IAddWarehouseProductRequest
}
export const addWarehouseProductLoadAction = (data: IAddWarehouseProductRequest): IAddWarehouseProductLoadAction => ({
    type: IADD_WAREHOUSE_PRODUCT_REQUEST.REQUEST,
    data
});

export interface IAddWarehouseProductSuccessAction {
    type: IADD_WAREHOUSE_PRODUCT_REQUEST.SUCCESS;
    message: string;
}
export const addWarehouseProductLoadSuccessAction = (message: string): IAddWarehouseProductSuccessAction => ({
    type: IADD_WAREHOUSE_PRODUCT_REQUEST.SUCCESS,
    message
});

export interface IAddWarehouseProductLoadFailedAction {
    type: IADD_WAREHOUSE_PRODUCT_REQUEST.FAILED;
    message: string;
}
export const addWarehouseProductLoadFailedAction = (message: string): IAddWarehouseProductLoadFailedAction => ({
    type: IADD_WAREHOUSE_PRODUCT_REQUEST.FAILED,
    message
});
