import {currentUser} from '../../../../simosHome';

export default function editSelectedOrderContacts(new_contact:any, orderID:number, customer_id:number){
    var delivery_id=0;
    const requestOption = {
        method: 'POST',
        headers: { 'Content-Type':'application/json' }, 
        body: JSON.stringify({token: '19515195-d571-44a3-b0db-637f5bf24f54' })
    }
    fetch('https://api2.propsense.com/api/GetSIMOSCustomerAddress', requestOption)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
          // error response
          if(!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        // no error
        data.map((deliveryaddr:any) => {
            // obtain a list of all delivery addresses
            if(deliveryaddr.customer_ID === customer_id){
                delivery_id=deliveryaddr.id;
            }
        })
        let requestOptions = {
            method: 'PATCH',
            headers: { 'Content-Type':'application/json'},
            body: JSON.stringify({
                request: {
                    Selected:"Yes",
                    Contact:new_contact.orderformName,
                    Phone:new_contact.orderformPhone,
                    Fax:new_contact.orderformFax,
                    Email:new_contact.orderformEmail,
                    OrderID:orderID,
                    Customer_ID:customer_id,
                    Delivery_ID:delivery_id,
                    Created_By:currentUser,
                    Modified_By:currentUser
                },
                token: '19515195-d571-44a3-b0db-637f5bf24f54'})
        }
        fetch('https://api2.propsense.com/api/UpdateSIMOSSelectedContact', requestOptions)
        .then(async response => {
            const data = response.headers.get('Content-Type')?.includes('application/json')&& await response.json();
            // error response
            if(!response.ok) {console.log('patch request fail');
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
        });
    })

}