import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IGET_TRANSLOAD_BOL_COUNTS_REQUEST, ITransloadBOLCount } from "./getTransloadBOLCountsConstaints";
import { IGetTransloadBOLCountsLoadAction, IGetTransloadBOLCountsLoadFailedAction, IGetTransloadBOLCountsSuccessAction } from "./getTransloadBOLCountsActions";
import { IAddTransloadBOLCountSuccessAction } from "../addTransloadBOLCount/addTransloadBOLCountActions";
import { IUpdateTransloadBOLCountSuccessAction } from "../updateTransloadBOLCount/updateTransloadBOLCountActions";
import { IADD_TRANSLOAD_BOL_COUNT_REQUEST } from "../addTransloadBOLCount/addTransloadBOLCountConstaints";
import { IUPDATE_TRANSLOAD_BOL_COUNT_REQUEST } from "../updateTransloadBOLCount/updateTransloadBOLCountConstaints";

type Actions =
    IGetTransloadBOLCountsLoadAction
    | IGetTransloadBOLCountsLoadFailedAction
    | IGetTransloadBOLCountsSuccessAction
    | IUpdateTransloadBOLCountSuccessAction
    | IAddTransloadBOLCountSuccessAction
    | IFlushDataSuccessAction;

export const GetTransloadBOLCountsReducer = (state: Server<SilAPIResponse<ITransloadBOLCount[]>> = notLoaded, action: Actions): Server<SilAPIResponse<ITransloadBOLCount[]>> => {
    switch (action.type) {
        case IGET_TRANSLOAD_BOL_COUNTS_REQUEST.REQUEST:
            return loading;

        case IGET_TRANSLOAD_BOL_COUNTS_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_TRANSLOAD_BOL_COUNTS_REQUEST.FAILED:
            return failed(action.message);

        case IADD_TRANSLOAD_BOL_COUNT_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_TRANSLOAD_BOL_COUNT_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};