import { IADD_SIMOS_TRANSPORT_COMPANY_REQUEST, IAddSimosTransportCompanyRequest } from "./addSimosTransportCompanyConstaints";

export interface IAddSimosTransportCompanyLoadAction {
    type: IADD_SIMOS_TRANSPORT_COMPANY_REQUEST.REQUEST;
    data: IAddSimosTransportCompanyRequest
}
export const addSimosTransportCompanyLoadAction = (data: IAddSimosTransportCompanyRequest): IAddSimosTransportCompanyLoadAction => ({
    type: IADD_SIMOS_TRANSPORT_COMPANY_REQUEST.REQUEST,
    data
});

export interface IAddSimosTransportCompanySuccessAction {
    type: IADD_SIMOS_TRANSPORT_COMPANY_REQUEST.SUCCESS;
    message: string;
}
export const addSimosTransportCompanyLoadSuccessAction = (message: string): IAddSimosTransportCompanySuccessAction => ({
    type: IADD_SIMOS_TRANSPORT_COMPANY_REQUEST.SUCCESS,
    message
});

export interface IAddSimosTransportCompanyLoadFailedAction {
    type: IADD_SIMOS_TRANSPORT_COMPANY_REQUEST.FAILED;
    message: string;
}
export const addSimosTransportCompanyLoadFailedAction = (message: string): IAddSimosTransportCompanyLoadFailedAction => ({
    type: IADD_SIMOS_TRANSPORT_COMPANY_REQUEST.FAILED,
    message
});
