import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGetCarrierAudit, IGET_CARRIER_AUDIT_REQUEST } from "./getCarrierAuditConstants";
import { IGetCarrierAuditLoadAction, IGetCarrierAuditLoadFailedAction, IGetCarrierAuditSuccessAction, 
    getCarrierAuditLoadFailedAction, getCarrierAuditLoadSuccessAction } from "./getCarrierAuditActions";
import { IStore } from "../../../../reducers";
import { EpicDependencies } from "../../../../store";
import { END_POINTS } from "../../../../endpoints";
import { ajaxHeadersFromStore, withJsonContentType } from "../../../../epicUtils";
import { SilAPIResponse } from "../../../../../react/shared/publicInterfaces";

export const getCarrierAuditEpic: Epic = (
    action$: ActionsObservable<IGetCarrierAuditLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetCarrierAuditSuccessAction | IGetCarrierAuditLoadFailedAction> =>
    action$.ofType(IGET_CARRIER_AUDIT_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<IGetCarrierAudit[]>>(
                    END_POINTS.PROPSENSE.SIL.CARRIER.GET_AUDIT,
                    action.data,
                    withJsonContentType(ajaxHeadersFromStore(store.value))
                )
                    .pipe(
                        map((response: SilAPIResponse<IGetCarrierAudit[]>): IGetCarrierAuditSuccessAction => getCarrierAuditLoadSuccessAction(response)),
                        catchError(() => ActionsObservable.of(getCarrierAuditLoadFailedAction("Unable to load carrier audit")))
                    )
            )
        );