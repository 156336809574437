import { IUpdateYHDCarrierFormRequest, IUPDATE_YHD_CARRIER_FORM_REQUEST } from "./updateCarrierFormConstaints";

export interface IUpdateYHDCarrierFormLoadAction {
    type: IUPDATE_YHD_CARRIER_FORM_REQUEST.REQUEST;
    data: IUpdateYHDCarrierFormRequest
}
export const updateYHDCarrierFormLoadAction = (data: IUpdateYHDCarrierFormRequest): IUpdateYHDCarrierFormLoadAction => ({
    type: IUPDATE_YHD_CARRIER_FORM_REQUEST.REQUEST,
    data
});

export interface IUpdateYHDCarrierFormSuccessAction {
    type: IUPDATE_YHD_CARRIER_FORM_REQUEST.SUCCESS;
    message: string;
}
export const updateYHDCarrierFormLoadSuccessAction = (message: string): IUpdateYHDCarrierFormSuccessAction => ({
    type: IUPDATE_YHD_CARRIER_FORM_REQUEST.SUCCESS,
    message
});
export interface IUpdateYHDCarrierFormLoadFailedAction {
    type: IUPDATE_YHD_CARRIER_FORM_REQUEST.FAILED;
    message: string;
}
export const updateYHDCarrierFormLoadFailedAction = (message: string): IUpdateYHDCarrierFormLoadFailedAction => ({
    type: IUPDATE_YHD_CARRIER_FORM_REQUEST.FAILED,
    message
});
