import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import { SilAPIResponse } from "../../../shared/publicInterfaces";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import { callRouteWithQueryString, userName } from "../../../shared/constExports";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { LAButton } from "../../../shared/buttons";
import { AddIcon } from "../../../shared/icons";
import { WHITE_COLOR } from "../../../shared/theme";
import { ROUTE } from "../../../routes";
import PageSpacing from "../../../shared/pageSpacing";
import SilRoleCheck from "../../../shared/silRoleCheck";
import LALoading from "../../../shared/loading";
import { IGetSieveWashPlantRequest, ISieveWashPlant } from "../../../../redux/sil/cv/sieveWashPlant/getSieveWashPlants/getSieveWashPlantsConstaints";
import { getSieveWashPlantLoadAction } from "../../../../redux/sil/cv/sieveWashPlant/getSieveWashPlants/getSieveWashPlantsActions";
import { getSieveWashPlantStatus } from "../../../../redux/sil/cv/sieveWashPlant/getSieveWashPlants/getSieveWashPlantsAccessor";


interface ISieveWashPlantsStoreProps {
    getToken: Server<SilAPIResponse<IToken>>;
    getSieveWashPlants: Server<SilAPIResponse<ISieveWashPlant[]>>;
};

interface ISieveWashPlantsDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getSieveWashPlantsRequest: (data: IGetSieveWashPlantRequest) => unknown;
};


interface ISieveWashPlantsOwnProps {

};

interface ISieveWashPlantsState {

};

const SieveWashPlantsStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
    };
`;

type ISieveWashPlantsProps = RouteComponentProps
    & ISieveWashPlantsStoreProps
    & ISieveWashPlantsDispatchProps
    & ISieveWashPlantsOwnProps;

class SieveWashPlants extends PureComponent<ISieveWashPlantsProps, ISieveWashPlantsState> {

    public constructor(props: ISieveWashPlantsProps) {
        super(props);
        this.state = {
        };
    }

    public componentDidMount(): void {
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: ISieveWashPlantsProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();
        };
    };


    public render(): ReactNode {

        const { getSieveWashPlants } = this.props;

        return (
            <PageSpacing title="SIL - CV" description="SIL - Sieve Wash Plants" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="carrierAccess">
                    <SieveWashPlantsStyles>

                        {getSieveWashPlants.kind !== STATUS_ENUM.LOADING &&
                            <LAButton className="pull-left" startIcon={<AddIcon color={WHITE_COLOR} />} label="Add" onClick={this.handleAdd} />}
                        <h2 className="text-center">SIEVE WASH PLANT</h2>
                        <hr />

                        {getSieveWashPlants.kind === STATUS_ENUM.LOADING &&
                            <LALoading className="loading" message="Loading..." />}

                        {getSieveWashPlants.kind === STATUS_ENUM.SUCCEEDED && <LADevExtremeGrid
                            data={getSieveWashPlants.payload.response}
                            onEdit={this.onEdit}
                            onClick={this.onClick}
                            height={1000}
                            searchPanel={true}
                            filterHeader={true}
                            export={true}
                            exportFileName="SieveWashPlantsList"
                            storageKey="silSieveWashPlantsStorageKey"
                            columns={[
                                { name: "month", caption: "Month", type: "number" },
                                { name: "year", caption: "Year", type: "number" },
                                { name: "employee", caption: "Employee", type: "string" },
                                { name: "completed", caption: "Completed", type: "string" },
                                { name: "is_Calibration_Good", caption: "Is Calibration Good", type: "string" },
                                { name: "modified", caption: "Modified", type: "datetime" },
                                { name: "modified_By", caption: "Modified By", type: "string" }
                            ]}
                        />}

                    </SieveWashPlantsStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString() },
            pathName: ROUTE.SIL.CV.SIEVE_WASH_PLANTS.SIEVE_WASH_PLANT
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0" },
            pathName: ROUTE.SIL.CV.SIEVE_WASH_PLANTS.SIEVE_WASH_PLANT
        });
    };

    private setDataToState = (): void => {
        if (isNotLoaded(this.props.getSieveWashPlants))
            this.callServer();
    };

    private callServer = (): void => {
        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getSieveWashPlants))
            this.props.getSieveWashPlantsRequest({
                token: this.props.getToken.payload.response.token
            });

        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });
    }

}

const mapStateToProps = (state: IStore): ISieveWashPlantsStoreProps => ({
    getToken: getTokenStatus(state),
    getSieveWashPlants: getSieveWashPlantStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): ISieveWashPlantsDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getSieveWashPlantsRequest: (data: IGetSieveWashPlantRequest) => dispatch(getSieveWashPlantLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(SieveWashPlants);