import { IWarehouse } from "../getWarehouses/getWarehousesConstaints";

export enum IUPDATE_WAREHOUSE_REQUEST {
    REQUEST = "updateWarehouse/UPDATE_WAREHOUSE_REQUEST",
    SUCCESS = "updateWarehouse/UPDATE_WAREHOUSE_SUCCESS",
    FAILED = "updateWarehouse/UPDATE_WAREHOUSE_FAILED"
};

export interface IUpdateWarehouseRequest {
    token: string;
    request: IWarehouse;
};