import { IAddYHDCarrierInfractionRequest, IADD_YHD_CARRIER_INFRACTION_REQUEST } from "./addCarrierInfractionConstaints";

export interface IAddYHDCarrierInfractionLoadAction {
    type: IADD_YHD_CARRIER_INFRACTION_REQUEST.REQUEST;
    data: IAddYHDCarrierInfractionRequest
}
export const addYHDCarrierInfractionLoadAction = (data: IAddYHDCarrierInfractionRequest): IAddYHDCarrierInfractionLoadAction => ({
    type: IADD_YHD_CARRIER_INFRACTION_REQUEST.REQUEST,
    data
});

export interface IAddYHDCarrierInfractionSuccessAction {
    type: IADD_YHD_CARRIER_INFRACTION_REQUEST.SUCCESS;
    message: string;
}
export const addYHDCarrierInfractionLoadSuccessAction = (message: string): IAddYHDCarrierInfractionSuccessAction => ({
    type: IADD_YHD_CARRIER_INFRACTION_REQUEST.SUCCESS,
    message
});
export interface IAddYHDCarrierInfractionLoadFailedAction {
    type: IADD_YHD_CARRIER_INFRACTION_REQUEST.FAILED;
    message: string;
}
export const addYHDCarrierInfractionLoadFailedAction = (message: string): IAddYHDCarrierInfractionLoadFailedAction => ({
    type: IADD_YHD_CARRIER_INFRACTION_REQUEST.FAILED,
    message
});
