import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IUpdateQualityControlTestingLoadAction, IUpdateQualityControlTestingLoadFailedAction, IUpdateQualityControlTestingSuccessAction, 
    updateQualityControlTestingLoadFailedAction, updateQualityControlTestingLoadSuccessAction
} from "./updateQualityControlTestingActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IUPDATE_QUALITY_CONTROL_TESTING_REQUEST } from "./updateQualityControlTestingConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";

export const updateQualityControlTestingEpic: Epic = (
    action$: ActionsObservable<IUpdateQualityControlTestingLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IUpdateQualityControlTestingSuccessAction | IUpdateQualityControlTestingLoadFailedAction> =>
    action$.ofType(IUPDATE_QUALITY_CONTROL_TESTING_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<string>>(
                    END_POINTS.PROPSENSE.SIL.QUALITY_CONTROL_TESTINGS.UPDATE_QUALITY_CONTROL_TESTING,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<string>): IUpdateQualityControlTestingSuccessAction | IUpdateQualityControlTestingLoadFailedAction => {
                            if(response.message === "Success"){
                                return updateQualityControlTestingLoadSuccessAction(response.message);
                            };
                            return updateQualityControlTestingLoadFailedAction(response.message)
                        }),
                        catchError(() => ActionsObservable.of(updateQualityControlTestingLoadFailedAction("Unable to update turbidity quality control testing ")))
                    )
            )
        );