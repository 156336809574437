import { IPassTokenWithRequest } from "../../dryPlant/token/getTokenConstants";
import { IGeoBag } from "../getGeoBags/getGeoBagsConstaints";


export enum IUPDATE_GEO_BAG_REQUEST {
    REQUEST = "updateGeoBag/UPDATE_GEO_BAG_REQUEST",
    SUCCESS = "updateGeoBag/UPDATE_GEO_BAG_SUCCESS",
    FAILED = "updateGeoBag/UPDATE_GEO_BAG_FAILED"
};

export interface IUpdateGeoBagRequest extends IPassTokenWithRequest {
    request: IGeoBag;
};