import { IPassTokenWithRequest } from "../../../dryPlant/token/getTokenConstants";


export enum IGET_BAGGED_INVENTORIES_REQUEST {
    REQUEST = "getBaggedInventories/GET_BAGGED_INVENTORIES_REQUEST",
    SUCCESS = "getBaggedInventories/GET_BAGGED_INVENTORIES_SUCCESS",
    FAILED = "getBaggedInventories/GET_BAGGED_INVENTORIES_FAILED"
};

export interface IGetBaggedInventoriesRequest extends IPassTokenWithRequest {

};

export interface IBaggedInventories {
    id: number;
    date: string;
    count: number;
    completed_By: string;
    items: IBaggedInventoriesItem[];
    created: string;
    created_By: string;
    modified: string;
    modified_By: string;
};

export interface IBaggedInventoriesItem {
    id: number;
    list_ID: number;
    product: string,
    pallet_Size: string,
    count: number,
    created: string;
    created_By: string;
    modified: string;
    modified_By: string;
}