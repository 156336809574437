import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../../shared/paper";
import { IDispatch, IStore } from "../../../../../redux/reducers";
import { hasPayload, isNotLoaded, Server } from "../../../../../redux/server";
import { ByIdNumber, SilAPIResponse, YHDAPIResponse } from "../../../../shared/publicInterfaces";
import { IGetTokenRequest, IToken } from "../../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenLoadAction } from "../../../../../redux/sil/dryPlant/token/getTokenActions";
import { callRouteWithQueryString, userName } from "../../../../shared/constExports";
import { LADevExtremeGrid } from "../../../../shared/devExtreme";
import { getTokenStatus } from "../../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { LAButton } from "../../../../shared/buttons";
import { AddIcon } from "../../../../shared/icons";
import { WHITE_COLOR } from "../../../../shared/theme";
import { ROUTE } from "../../../../routes";
import PageSpacing from "../../../../shared/pageSpacing";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { IYHDCarrier, IGetYHDCarriersRequest } from "../../../../../redux/propsense/yhd/carrier/getCarriers/getCarriersConstaints";
import { getYHDCarriersLoadAction } from "../../../../../redux/propsense/yhd/carrier/getCarriers/getCarriersActions";
import { getYHDCarriersStatus } from "../../../../../redux/propsense/yhd/carrier/getCarriers/getCarriersAccessor";
import SilRoleCheck from "../../../../shared/silRoleCheck";


interface IYHDCarriersStoreProps {
    getToken: Server<SilAPIResponse<IToken>>;
    getYHDCarriers: Server<YHDAPIResponse<ByIdNumber<IYHDCarrier>>>;
};

interface IYHDCarriersDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getYHDCarriersRequest: (data: IGetYHDCarriersRequest) => unknown;
};


interface IYHDCarriersOwnProps {

};

interface IYHDCarriersState {
    data: IYHDCarrier[];
    allData: IYHDCarrier[];
    radioValue: string;
};

const YHDCarriersStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
    };
`;

type IYHDCarriersProps = RouteComponentProps
    & IYHDCarriersStoreProps
    & IYHDCarriersDispatchProps
    & IYHDCarriersOwnProps;

class YHDCarriers extends PureComponent<IYHDCarriersProps, IYHDCarriersState> {

    public constructor(props: IYHDCarriersProps) {
        super(props);
        this.state = {
            data: [],
            allData: [],
            radioValue: "Active"
        };
    }

    public componentDidMount(): void {
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IYHDCarriersProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();
        };
    };


    public render(): ReactNode {

        const { radioValue, data } = this.state;

        return (
            <PageSpacing title="YHD - Carriers" description="YHD - Carriers" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="yhdCarrierAccess">
                    <YHDCarriersStyles>

                        <LAButton className="pull-left" startIcon={<AddIcon color={WHITE_COLOR} />} label="Add" onClick={this.handleAdd} />
                        <h2 className="text-center">CARRIERS</h2>
                        <RadioGroup row aria-label="" name="radioGroup" value={radioValue} onChange={this.handleRadioClick}>
                            <FormControlLabel value="Active" control={<Radio />} label="Active" />
                            <FormControlLabel value="All" control={<Radio />} label="All" />
                        </RadioGroup>
                        <hr />

                        <LADevExtremeGrid
                            data={data}
                            onEdit={this.onEdit}
                            onClick={this.onClick}
                            height={1000}
                            searchPanel={true}
                            filterHeader={true}
                            export={true}
                            storageKey="yhdCarriersStorageKey"
                            exportFileName={"Carriers"}
                            columns={[
                                { name: "name", caption: "Name", type: "string"},
                                { name: "alternate_Name", caption: "Alternate Name", type: "string" },
                                { name: "approved", caption: "Approved", type: "string" },
                                { name: "dispatch_Contact_Name", caption: "Dispatch Contact Name", type: "string" },
                                { name: "dispatch_Phone_Work", caption: "Dispatch Phone", type: "string" },
                                { name: "dispatch_Email", caption: "Dispatch Email", type: "string" },                                
                                { name: "truck_Code_Tandem", caption: "Truck Code Tandem", type: "string" },
                                { name: "truck_Code_Tridem", caption: "Truck Code Tridem", type: "string" },
                                { name: "truck_Code_Wagon", caption: "Truck Code Wagon", type: "string" },                                
                                { name: "truck_Code_Quad", caption: "Truck Code Quad", type: "string" },
                                { name: "truck_Code_SuperB", caption: "Truck Code SuperB", type: "string" },
                                { name: "modified", caption: "Modified", type: "datetime", sortDesc: true  },
                            ]}
                        />
                    </YHDCarriersStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private handleRadioClick = (event: React.ChangeEvent<HTMLInputElement>, radioValue: string): void => {
        if (radioValue === "Active") {
            const val = this.state.allData;
            const data = val.filter(x => x.active === "Yes");
            this.setState({ data, radioValue });
        } else {
            this.setState({ radioValue, data: this.state.allData });
        };
    };

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString() },
            pathName: ROUTE.PROPSENSE.YHD.CARRIERS.CARRIER
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0" },
            pathName: ROUTE.PROPSENSE.YHD.CARRIERS.CARRIER
        });
    };

    private setDataToState = (): void => {

        if (hasPayload(this.props.getYHDCarriers)) {
            const val: IYHDCarrier[] = Object.values(this.props.getYHDCarriers.payload.response);
            const list = val.filter(x => x.active === "Yes");
            this.setState({ data: list, allData: val });
        };

        if (isNotLoaded(this.props.getYHDCarriers))
            this.callServer();
    };

    private callServer = (): void => {
        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getYHDCarriers))
            this.props.getYHDCarriersRequest({
                token: this.props.getToken.payload.response.token
            });


        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });
    }

}

const mapStateToProps = (state: IStore): IYHDCarriersStoreProps => ({
    getToken: getTokenStatus(state),
    getYHDCarriers: getYHDCarriersStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IYHDCarriersDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getYHDCarriersRequest: (data: IGetYHDCarriersRequest) => dispatch(getYHDCarriersLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(YHDCarriers);