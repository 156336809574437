import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IUPDATE_CARRIER_FLEET_TRAILER_REQUEST } from "./updateCarrierFleetTrailerConstaints";
import { IUpdateCarrierFleetTrailerLoadAction, IUpdateCarrierFleetTrailerLoadFailedAction, IUpdateCarrierFleetTrailerSuccessAction } from "./updateCarrierFleetTrailerActions";

type Actions =
    IUpdateCarrierFleetTrailerLoadAction
    | IUpdateCarrierFleetTrailerLoadFailedAction
    | IUpdateCarrierFleetTrailerSuccessAction
    | IFlushDataSuccessAction;

export const UpdateCarrierFleetTrailerReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IUPDATE_CARRIER_FLEET_TRAILER_REQUEST.REQUEST:
            return loading;

        case IUPDATE_CARRIER_FLEET_TRAILER_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IUPDATE_CARRIER_FLEET_TRAILER_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};