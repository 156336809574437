import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IAddCarrierFleetTrailerLoadAction, IAddCarrierFleetTrailerLoadFailedAction, IAddCarrierFleetTrailerSuccessAction, 
    addCarrierFleetTrailerLoadFailedAction, addCarrierFleetTrailerLoadSuccessAction
} from "./addCarrierFleetTrailerActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IADD_CARRIER_FLEET_TRAILER_REQUEST } from "./addCarrierFleetTrailerConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";

export const addCarrierFleetTrailerEpic: Epic = (
    action$: ActionsObservable<IAddCarrierFleetTrailerLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IAddCarrierFleetTrailerSuccessAction | IAddCarrierFleetTrailerLoadFailedAction> =>
    action$.ofType(IADD_CARRIER_FLEET_TRAILER_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<string>>(
                    END_POINTS.PROPSENSE.SIL.CARRIER.ADD_CARRIER_FLEET_TRAILER,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<string>): IAddCarrierFleetTrailerSuccessAction | IAddCarrierFleetTrailerLoadFailedAction => {
                            if(response.message === "Success"){
                                return addCarrierFleetTrailerLoadSuccessAction(response.message);
                            };
                            return addCarrierFleetTrailerLoadFailedAction(response.message)
                        }),
                        catchError(() => ActionsObservable.of(addCarrierFleetTrailerLoadFailedAction("Unable to add carrier fleet trailers")))
                    )
            )
        );