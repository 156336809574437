import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetWashPlantBinAdjustmentsRequest, IGET_WASH_PLANT_BIN_ADJUSTMENTS_REQUEST, IWashPlantBinAdjustments } from "./getWashPlantBinAdjustmentsConstaints";

export interface IGetWashPlantBinAdjustmentsLoadAction {
    type: IGET_WASH_PLANT_BIN_ADJUSTMENTS_REQUEST.REQUEST;
    data: IGetWashPlantBinAdjustmentsRequest
}
export const getWashPlantBinAdjustmentsLoadAction = (data: IGetWashPlantBinAdjustmentsRequest): IGetWashPlantBinAdjustmentsLoadAction => ({
    type: IGET_WASH_PLANT_BIN_ADJUSTMENTS_REQUEST.REQUEST,
    data
});

export interface IGetWashPlantBinAdjustmentsSuccessAction {
    type: IGET_WASH_PLANT_BIN_ADJUSTMENTS_REQUEST.SUCCESS;
    data: SilAPIResponse<IWashPlantBinAdjustments[]>;
}
export const getWashPlantBinAdjustmentsLoadSuccessAction = (data: SilAPIResponse<IWashPlantBinAdjustments[]>): IGetWashPlantBinAdjustmentsSuccessAction => ({
    type: IGET_WASH_PLANT_BIN_ADJUSTMENTS_REQUEST.SUCCESS,
    data
});

export interface IGetWashPlantBinAdjustmentsLoadFailedAction {
    type: IGET_WASH_PLANT_BIN_ADJUSTMENTS_REQUEST.FAILED;
    message: string;
}
export const getWashPlantBinAdjustmentsLoadFailedAction = (message: string): IGetWashPlantBinAdjustmentsLoadFailedAction => ({
    type: IGET_WASH_PLANT_BIN_ADJUSTMENTS_REQUEST.FAILED,
    message
});
