import { IPassTokenWithRequest } from "../../dryPlant/token/getTokenConstants";

export enum IGET_SIEVES_PRODUCTS_REQUEST {
    REQUEST = "getSieveProducts/GET_SIEVES_PRODUCTS_REQUEST",
    SUCCESS = "getSieveProducts/GET_SIEVES_PRODUCTS_SUCCESS",
    FAILED = "getSieveProducts/GET_SIEVES_PRODUCTS_FAILED"
};

export interface IGetSieveProductsRequest extends IPassTokenWithRequest {

};

export interface ISieveProduct {
    id: number;
    product: string;
    plant_Type: string;
    created: string;
    modified: string;
    created_By: string;
    modified_By: string;
    items: ISieveProductItem[];
};

export interface ISieveProductItem {
    id: number;
    mesh: string;
    perc_Ret_Spec: string;
    perc_Pass_Spec: string;
}