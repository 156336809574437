import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IGetProductionSummaryLoadAction, IGetProductionSummaryLoadFailedAction, IGetProductionSummarySuccessAction, 
    getProductionSummaryLoadFailedAction, getProductionSummaryLoadSuccessAction
} from "./getProductionSummaryActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IGET_PRODUCTION_SUMMARY_REQUEST, IProductionSummary } from "./getProductionSummaryConstants";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";

export const getProductionSummaryEpic: Epic = (
    action$: ActionsObservable<IGetProductionSummaryLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IGetProductionSummarySuccessAction | IGetProductionSummaryLoadFailedAction> =>
    action$.ofType(IGET_PRODUCTION_SUMMARY_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<IProductionSummary[]>>(
                    END_POINTS.PROPSENSE.SIL.DRY_PLANT.GET_DRY_PLANT_PRODUCTION_SUMMARY,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<IProductionSummary[]>): IGetProductionSummarySuccessAction => {
                            return getProductionSummaryLoadSuccessAction(response);
                        }),
                        catchError(() => ActionsObservable.of(getProductionSummaryLoadFailedAction("Unable to get production summary")))
                    )
            )
        );