import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { SilAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { END_POINTS } from "../../../../endpoints";
import { withJsonContentType } from "../../../../epicUtils";
import { IStore } from "../../../../reducers";
import { EpicDependencies } from "../../../../store";
import { IAddBaggedInventoryLoadAction, IAddBaggedInventoryLoadFailedAction, IAddBaggedInventorySuccessAction, addBaggedInventoryLoadFailedAction, addBaggedInventoryLoadSuccessAction } from "./addBaggedInventoryActions";
import { IADD_BAGGED_INVENTORY_REQUEST } from "./addBaggedInventoryConstaints";

export const addBaggedInventoryEpic: Epic = (
    action$: ActionsObservable<IAddBaggedInventoryLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IAddBaggedInventorySuccessAction | IAddBaggedInventoryLoadFailedAction> =>
    action$.ofType(IADD_BAGGED_INVENTORY_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<string>>(
                    END_POINTS.PROPSENSE.SIL.WAREHOUSE.ADD_BAGGED_INVENTORY,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<string>): IAddBaggedInventorySuccessAction | IAddBaggedInventoryLoadFailedAction => {
                            if(response.message === "Success"){
                                return addBaggedInventoryLoadSuccessAction(response.message);
                            };
                            return addBaggedInventoryLoadFailedAction(response.message)
                        }),
                        catchError(() => ActionsObservable.of(addBaggedInventoryLoadFailedAction("Unable to add bagged inventory")))
                    )
            )
        );