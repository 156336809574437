import { IAddSieveFracBallRequest, IADD_SIEVE_FRAC_BALL_REQUEST } from "./addSieveFracBallConstaints";

export interface IAddSieveFracBallLoadAction {
    type: IADD_SIEVE_FRAC_BALL_REQUEST.REQUEST;
    data: IAddSieveFracBallRequest
}
export const addSieveFracBallLoadAction = (data: IAddSieveFracBallRequest): IAddSieveFracBallLoadAction => ({
    type: IADD_SIEVE_FRAC_BALL_REQUEST.REQUEST,
    data
});

export interface IAddSieveFracBallSuccessAction {
    type: IADD_SIEVE_FRAC_BALL_REQUEST.SUCCESS;
    message: string;
}
export const addSieveFracBallLoadSuccessAction = (message: string): IAddSieveFracBallSuccessAction => ({
    type: IADD_SIEVE_FRAC_BALL_REQUEST.SUCCESS,
    message
});
export interface IAddSieveFracBallLoadFailedAction {
    type: IADD_SIEVE_FRAC_BALL_REQUEST.FAILED;
    message: string;
}
export const addSieveFracBallLoadFailedAction = (message: string): IAddSieveFracBallLoadFailedAction => ({
    type: IADD_SIEVE_FRAC_BALL_REQUEST.FAILED,
    message
});
