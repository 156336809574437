import { IYHDCarrierForm } from "../../../../../react/components/propsense/yhd/carriers/carrierForm";

export enum ISAVE_YHD_CARRIER_FORM_REQUEST {
    REQUEST = "saveYHDCarrierForm/SAVE_YHD_CARRIER_FORM_REQUEST",
    SUCCESS = "saveYHDCarrierForm/SAVE_YHD_CARRIER_FORM_SUCCESS",
    FAILED = "saveYHDCarrierForm/SAVE_YHD_CARRIER_FORM_FAILED"
};

export interface ISaveYHDCarrierFormRequest {
    token: string;
    request: IYHDCarrierForm;
};