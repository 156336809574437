import { IUpdateYHDCarrierEmailRequest, IUPDATE_YHD_CARRIER_EMAIL_REQUEST } from "./updateCarrierEmailConstaints";

export interface IUpdateYHDCarrierEmailLoadAction {
    type: IUPDATE_YHD_CARRIER_EMAIL_REQUEST.REQUEST;
    data: IUpdateYHDCarrierEmailRequest
}
export const updateYHDCarrierEmailLoadAction = (data: IUpdateYHDCarrierEmailRequest): IUpdateYHDCarrierEmailLoadAction => ({
    type: IUPDATE_YHD_CARRIER_EMAIL_REQUEST.REQUEST,
    data
});

export interface IUpdateYHDCarrierEmailSuccessAction {
    type: IUPDATE_YHD_CARRIER_EMAIL_REQUEST.SUCCESS;
    message: string;
}
export const updateYHDCarrierEmailLoadSuccessAction = (message: string): IUpdateYHDCarrierEmailSuccessAction => ({
    type: IUPDATE_YHD_CARRIER_EMAIL_REQUEST.SUCCESS,
    message
});

export interface IUpdateYHDCarrierEmailLoadFailedAction {
    type: IUPDATE_YHD_CARRIER_EMAIL_REQUEST.FAILED;
    message: string;
}
export const updateYHDCarrierEmailLoadFailedAction = (message: string): IUpdateYHDCarrierEmailLoadFailedAction => ({
    type: IUPDATE_YHD_CARRIER_EMAIL_REQUEST.FAILED,
    message
});
