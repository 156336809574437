import React, { useEffect, useState } from "react";
import { FIELD_VALIDATOR_ERRORS, FieldValidator, IFieldErrorKeyValue, IFieldValidatorProps } from "../../../../shared/fieldValidator";
import { ById } from "../../../../shared/publicInterfaces";
import { LAPopover } from "../../../../shared/popOver";
import { LAPaperWithPadding } from "../../../../shared/paper";
import LAGrid from "../../../../shared/grid";
import LAGridItem from "../../../../shared/gridList";
import LATextField from "../../../../shared/textField";
import { LASaveAndCancelButton } from "../../../../shared/buttons";
import { userName } from "../../../../shared/constExports";

interface IAddSalesOrderNumberPopupProps {
    open: boolean;
    onClose: () => void;
    onSave: (data: any) => void;
};

interface AddAddSalesOrderNumberPopupState {
    data: any;
    errors: ById<IFieldErrorKeyValue>;
}

const RequireFields: string[] = ["sales_Order_Number"];
  
export const AddSalesOrderNumberPopup: React.FC<IAddSalesOrderNumberPopupProps> = React.memo((props: IAddSalesOrderNumberPopupProps) => {

    const initialState: AddAddSalesOrderNumberPopupState = {
        data: {
            id: 0,
            sales_Order_Number: "",
            created: "",
            modified: "",
            created_By: userName,
            modified_By: userName,
        },
        errors: {},
    }

    const [state, setState] = useState<AddAddSalesOrderNumberPopupState>(initialState);

    useEffect(() => {
        const errors: ById<IFieldErrorKeyValue> = {};
        RequireFields.forEach((x) => {
            errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
        });

        setState((prevState) => ({
            ...prevState,
            data: {
                id: 0,
                sales_Order_Number: "",
                created: "",
                modified: "",
                created_By: userName,
                modified_By: userName,
            },
            errors
        }));
    }, [props.open]);

    const errorChecker = (
        name: string,
        value: string,
        errors: ById<IFieldErrorKeyValue>,
        rules?: IFieldValidatorProps
      ): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, rules ?? { required: true, minLength: 1 });
        const err: ById<IFieldErrorKeyValue> = errors;
      
        if (result.length > 0) {
          err[name] = { key: name, message: result };
        } else {
          delete err[name];
        }
        return err;
      };

    const disableSave = ((Object.values(state.errors).length > 0) ? true :  undefined);
    // const disableSave = ((Object.values(state.errors).length > 0) ? true : (addUpdateLoading ? true : undefined));

    const handleOnChange = (name: string, value: string) => {
        let errors = { ...state.errors };

        if (RequireFields.includes(name))
            errors = errorChecker(name, value, errors);


        setState((prevState) => ({
            ...prevState,
            data: {
                ...prevState.data,
                [name]: value,
            },
            errors,
        }));
    };

    const handleSave = () : void => {
        props.onSave(state.data);
    }

    const handleCancel = () : void => { 
        setState(initialState);
        props.onClose();
    }

    return <LAPopover open={props.open} onClose={handleCancel} anchorRef={null} anchorOrigin={{ horizontal: "center", vertical: "top" }} >
        <LAPaperWithPadding>
            <LAGrid spacing={3} >

                <LAGridItem xs={12} className="text-center">
                    <h3>Create Sales Order Number</h3>
                    <hr />
                </LAGridItem>

                <LAGridItem xs={12}>
                    <LAGridItem xs={12} sm={12} md={12}>
                        <strong>Sales Order Number</strong>
                        <LATextField
                            id="sales_Order_Number"
                            value={state.data.sales_Order_Number ?? ""}
                            fullWidth={true}
                            varient="outlined"
                            disabled={undefined }
                            name="sales_Order_Number"
                            label=""
                            onChange={handleOnChange}
                            errorText={state.errors["sales_Order_Number"] ? state.errors["sales_Order_Number"].message : undefined}
                        />
                    </LAGridItem>
                    <br />

                    <LAGridItem xs={12} sm={12} md={12}>
                        <LASaveAndCancelButton
                            fullWidth={true}
                            saveButtonText="Save"
                            onSave={handleSave}
                            cancelButtonText="Close"
                            onCancel={handleCancel}
                            disableSave={disableSave}
                        />
                    </LAGridItem>
                </LAGridItem>

            </LAGrid>
        </LAPaperWithPadding>
    </LAPopover>
});
