
let filteredContacts;
function filterOrderInfo(selectedList:string[], all_contacts:string[], filtered:any[]) {
    let search_text='';
    let search_param=['delivery','customer','transCompany','poNum'];

    // If the first filter has not been applied yet use the old array structure indexed at 0
    if(filtered.length===1) {
        filtered = filtered[0];
    }
    // If there is a filter then filter results
    if(selectedList.length>=1){
        // eslint-disable-next-line array-callback-return
        filteredContacts = filtered.filter((row) => {   
            for(var i=0; i<selectedList.length;i++){
        
                if(row['transCompany'] === selectedList[i] || row['customer'] === selectedList[i] || row['location']===selectedList[i] || row['status'] === selectedList[i]){  
                    return search_param.some((new_item) => {
                        return  row[new_item].toString().toLowerCase().indexOf(search_text.toLowerCase()) > -1
                    })
                }  

                else if(row['transCompany']===selectedList[i] && row['customer'] === selectedList[i] && row['location']===selectedList[i] && row['status'] === selectedList[i]){
                    return search_param.some((new_item) => {
                        return  row[new_item].toString().toLowerCase().indexOf(search_text.toLowerCase()) > -1
                    })
                }
            }
        });   
      
        return filteredContacts;
    }
    // No filter, reset results 
    else {
        return all_contacts[0];
    }
}


function filterOrderByStatus(allRequestsArray:any, view:string, alternateView:string){
    var a;
    if(view === 'active' && alternateView==='none'){
        if(allRequestsArray != null){
            // changed to render all orders
           // a = allRequestsArray.filter((obj:any) => {return (obj.status==='Assigned') || (obj.status==='Initiated') || (obj.status==='Ready for pickup')});
           a = allRequestsArray.filter((obj:any) => {return (obj.status!=null)});
            return a;
        }
    }
    if(view==='completed' && alternateView==='none'){
        if(allRequestsArray != null){
            a = allRequestsArray.filter((obj:any) => {return (obj.status!=null)});
            return a;
        }
    }
    if(view === 'active' && alternateView==='bulk'){
        if(allRequestsArray != null){
            a = allRequestsArray.filter((obj:any) => {return  ((obj.status==='Assigned') || (obj.status==='Initiated') || (obj.status==='Ready for pickup') || (obj.status === 'Loading')) && (obj.location==='Bulk')});
            return a;
        }
    }
    if(view === 'active' && alternateView==='sil_bru_warehouse'){
        if(allRequestsArray != null){
            a = allRequestsArray.filter((obj:any) => {return ((obj.status==='Assigned') || (obj.status==='Initiated') || (obj.status==='Ready for pickup') || (obj.status === 'Loading')) && (obj.location==='SIL BRU Warehouse')});
            return a;
        }
    }
    if(view === 'active' && alternateView==='sil_ed_warehouse'){
        if(allRequestsArray != null){
            a = allRequestsArray.filter((obj:any) => {return ((obj.status==='Assigned') || (obj.status==='Initiated') || (obj.status==='Ready for pickup') || (obj.status === 'Loading')) && (obj.location==='SIL ED Warehouse')});
            return a;
        }
    }
    if(view==='completed' && alternateView==='bulk'){
        if(allRequestsArray != null){
            a = allRequestsArray.filter((obj:any) => {return (obj.status!=null) && (obj.location==='Bulk')});
            return a;
        }
    }
    if(view==='completed' && alternateView==='warehouse'){
        if(allRequestsArray != null){
            a = allRequestsArray.filter((obj:any) => {return (obj.status!=null) && (obj.location==='Warehouse')});
            return a;
        }
    }  
}

export {filterOrderInfo, filterOrderByStatus};