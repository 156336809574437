import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IGetProductionRecordsLoadAction, IGetProductionRecordsLoadFailedAction, IGetProductionRecordsSuccessAction, 
    getProductionRecordsLoadFailedAction, getProductionRecordsLoadSuccessAction
} from "./getProductionRecordsActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IGET_PRODUCTION_RECORDS_REQUEST, IProductionRecords } from "./getProductionRecordsConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { ById, SilAPIResponse } from "../../../../react/shared/publicInterfaces";

export const getProductionRecordsEpic: Epic = (
    action$: ActionsObservable<IGetProductionRecordsLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IGetProductionRecordsSuccessAction | IGetProductionRecordsLoadFailedAction> =>
    action$.ofType(IGET_PRODUCTION_RECORDS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<ById<IProductionRecords>>>(
                    END_POINTS.PROPSENSE.SIL.DRY_PLANT.GET_DRY_PLANT_PRODUCTION_RECORDS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<ById<IProductionRecords>>): IGetProductionRecordsSuccessAction => {
                            return getProductionRecordsLoadSuccessAction(response);
                        }),
                        catchError(() => ActionsObservable.of(getProductionRecordsLoadFailedAction("Unable to get production records")))
                    )
            )
        );