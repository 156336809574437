import { IPassTokenWithRequest } from "../../dryPlant/token/getTokenConstants";

export enum IUPDATE_CARRIER_FLEET_TRAILER_REQUEST {
    REQUEST = "updateCarrierFleetTrailer/UPDATE_CARRIER_FLEET_TRAILER_REQUEST",
    SUCCESS = "updateCarrierFleetTrailer/UPDATE_CARRIER_FLEET_TRAILER_SUCCESS",
    FAILED = "updateCarrierFleetTrailer/UPDATE_CARRIER_FLEET_TRAILER_FAILED"
};

export interface IUpdateCarrierFleetTrailerRequest extends IPassTokenWithRequest {
    request: {
        id: number;
        trailer_No: string;
        carrier: string;
        positive_Air_Shutoff: string;
        trailer_Registration_No: string;
        trailer_Registration_Expiry: string;
        trailer_Inspection_File_Name: string | null;
        trailer_Inspection_File: string | null;
        trailer_Inspection_Date: string;
        active: string;
        inactive_Date: string;
        notes: string;
        created_By: string;
        modified_By: string;
    };
};
