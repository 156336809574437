
  async function getSIMOSCustomerInfoByID(customer_id:number){
    let  explorer_code:any = "";
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({request:{ID:customer_id}, token: '19515195-d571-44a3-b0db-637f5bf24f54' })
    };
     await fetch('https://api2.propsense.com/api/GetSIMOSCustomerInfoByID', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
        // error response
        if(!response.ok) {
            const error = (data) || response.status;
            return Promise.reject(error);
        }
        explorer_code = data.explorer_Code;
        });
    return explorer_code;
}

async function getAsyncSIMOSCustomerInfoByID(customer_id:number){
    let  explorer_code:any = "";
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({request:{ID:customer_id}, token: '19515195-d571-44a3-b0db-637f5bf24f54' })
    };
     await fetch('https://api2.propsense.com/api/GetSIMOSCustomerInfoByID', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
        // error response
        if(!response.ok) {
            const error = (data) || response.status;
            return Promise.reject(error);
        }
        explorer_code = data.explorer_Code;
        });
    return explorer_code;
}

export {getSIMOSCustomerInfoByID, getAsyncSIMOSCustomerInfoByID};