import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import { ById, SilAPIResponse } from "../../../shared/publicInterfaces";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import { callRouteWithQueryString, userName } from "../../../shared/constExports";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { LAButton } from "../../../shared/buttons";
import { AddIcon } from "../../../shared/icons";
import { WHITE_COLOR } from "../../../shared/theme";
import { ROUTE } from "../../../routes";
import PageSpacing from "../../../shared/pageSpacing";
import SilRoleCheck from "../../../shared/silRoleCheck";
import LALoading from "../../../shared/loading";
import { getChemicalAnalysesLoadAction } from "../../../../redux/sil/exploration/chemicalAnalyses/getChemicalAnalyses/getChemicalAnalysesActions";
import { getChemicalAnalysesStatus } from "../../../../redux/sil/exploration/chemicalAnalyses/getChemicalAnalyses/getChemicalAnalysesAccessor";
import { IChemicalAnalyses, IGetChemicalAnalysesRequest } from "../../../../redux/sil/exploration/chemicalAnalyses/getChemicalAnalyses/getChemicalAnalysesConstaints";


interface IChemicalAnalysesListStoreProps {
    getToken: Server<SilAPIResponse<IToken>>;
    getChemicalAnalysesList: Server<SilAPIResponse<IChemicalAnalyses[]>>;
};

interface IChemicalAnalysesListDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getChemicalAnalysesListRequest: (data: IGetChemicalAnalysesRequest) => unknown;
};


interface IChemicalAnalysesListOwnProps {

};

interface IChemicalAnalysesListState {
    data: IChemicalAnalyses[];
};

const ChemicalAnalysesListStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .loading {
        margin-top: 5%;
    };

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
    };
`;

type IChemicalAnalysesListProps = RouteComponentProps
    & IChemicalAnalysesListStoreProps
    & IChemicalAnalysesListDispatchProps
    & IChemicalAnalysesListOwnProps;

class ChemicalAnalysesList extends PureComponent<IChemicalAnalysesListProps, IChemicalAnalysesListState> {

    public constructor(props: IChemicalAnalysesListProps) {
        super(props);
        this.state = {
            data: []
        };
    }

    public componentDidMount(): void {
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IChemicalAnalysesListProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();
        };
    };


    public render(): ReactNode {

        const { data } = this.state;
        const { getChemicalAnalysesList } = this.props;

        return (
            <PageSpacing title="SIL - Chemical Analyses List" description="SIL - Chemical Analyses List" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="explorationAccess">
                    <ChemicalAnalysesListStyles>

                        {getChemicalAnalysesList.kind !== STATUS_ENUM.LOADING &&
                            <LAButton className="pull-left" startIcon={<AddIcon color={WHITE_COLOR} />} label="Add" onClick={this.handleAdd} />}
                        <h2 className="text-center">CHEMICAL ANALYSES</h2>
                        <hr />

                        {getChemicalAnalysesList.kind === STATUS_ENUM.LOADING &&
                            <LALoading className="loading" message="Loading..." />}

                        {getChemicalAnalysesList.kind !== STATUS_ENUM.LOADING && <LADevExtremeGrid
                            data={data}
                            onEdit={this.onEdit}
                            onClick={this.onClick}
                            height={1000}
                            searchPanel={true}
                            filterHeader={true}
                            export={true}
                            exportFileName="ChemicalAnalysesList"
                            storageKey="silChemicalAnalysesStorageKey"
                            columns={[                               
                                { name: "product", caption: "Product", type: "string" },
                                { name: "plant", caption: "Plant", type: "string" },
                                { name: "sample_Location", caption: "Sample Location", type: "string" },
                                { name: "sample_Date", caption: "Sample Date", type: "datetime" },
                                { name: "sampler", caption: "Sampler", type: "string" },
                                { name: "analysis_Date", caption: "Analysis Date", type: "datetime" },                               
                                { name: "tests_Ran", caption: "Tests Run", type: "number" },
                                { name: "analyzed_By", caption: "Analyzed By", type: "string" },
                                { name: "mgO_Perc", caption: "MgO %", type: "number" },
                                { name: "al2O3_Perc", caption: "Al203 %", type: "number" },
                                { name: "siO2_Perc", caption: "SiO2 %", type: "number" },
                                { name: "p2O5_Perc", caption: "P205 %", type: "number" },
                                { name: "caO_Perc", caption: "CaO %", type: "number" },
                                { name: "tiO2_Perc", caption: "TiO2 %", type: "number" },
                                { name: "mnO_Perc", caption: "MnO %", type: "number" },
                                { name: "feO_Perc", caption: "FeO %", type: "number" },
                                { name: "lE_Perc", caption: "Le %", type: "number" },
                                { name: "modified", caption: "Modified", type: "datetime" },
                                { name: "modified_By", caption: "Modified By", type: "string" }
                            ]}
                        />}


                    </ChemicalAnalysesListStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString() },
            pathName: ROUTE.SIL.EXPLORATION.CHEMICAL_ANALYSES
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0" },
            pathName: ROUTE.SIL.EXPLORATION.CHEMICAL_ANALYSES
        });
    };

    private setDataToState = (): void => {

        if (hasPayload(this.props.getChemicalAnalysesList)) {
            const data: IChemicalAnalyses[] = Object.values(this.props.getChemicalAnalysesList.payload.response);
            this.setState({ data });
        };

        if (isNotLoaded(this.props.getChemicalAnalysesList))
            this.callServer();
    };

    private callServer = (): void => {
        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getChemicalAnalysesList))
            this.props.getChemicalAnalysesListRequest({
                token: this.props.getToken.payload.response.token
            });


        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });
    }

}

const mapStateToProps = (state: IStore): IChemicalAnalysesListStoreProps => ({
    getToken: getTokenStatus(state),
    getChemicalAnalysesList: getChemicalAnalysesStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IChemicalAnalysesListDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getChemicalAnalysesListRequest: (data: IGetChemicalAnalysesRequest) => dispatch(getChemicalAnalysesLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ChemicalAnalysesList);