import { IAddWarehouseRequest, IADD_WAREHOUSE_REQUEST } from "./addWarehouseConstaints";

export interface IAddWarehouseLoadAction {
    type: IADD_WAREHOUSE_REQUEST.REQUEST;
    data: IAddWarehouseRequest
}
export const addWarehouseLoadAction = (data: IAddWarehouseRequest): IAddWarehouseLoadAction => ({
    type: IADD_WAREHOUSE_REQUEST.REQUEST,
    data
});

export interface IAddWarehouseSuccessAction {
    type: IADD_WAREHOUSE_REQUEST.SUCCESS;
    message: string;
}
export const addWarehouseLoadSuccessAction = (message: string): IAddWarehouseSuccessAction => ({
    type: IADD_WAREHOUSE_REQUEST.SUCCESS,
    message
});
export interface IAddWarehouseLoadFailedAction {
    type: IADD_WAREHOUSE_REQUEST.FAILED;
    message: string;
}
export const addWarehouseLoadFailedAction = (message: string): IAddWarehouseLoadFailedAction => ({
    type: IADD_WAREHOUSE_REQUEST.FAILED,
    message
});
