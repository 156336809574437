import { ById, SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IGET_SIEVES_CALIBRATIONS_REQUEST, ISieveCalibration } from "./getSievesCalibrationsConstaints";
import { IGetSieveCalibrationsLoadAction, IGetSieveCalibrationsLoadFailedAction, IGetSieveCalibrationsSuccessAction } from "./getSievesCalibrationsActions";
import { IUPDATE_SIEVE_CALIBRATION_REQUEST } from "../updateSieveCalibration/updateSieveCalibrationConstaints";
import { IADD_SIEVE_CALIBRATION_REQUEST } from "../addSieveCalibration/addSieveCalibrationConstaints";
import { IAddSieveCalibrationSuccessAction } from "../addSieveCalibration/addSieveCalibrationActions";
import { IUpdateSieveCalibrationSuccessAction } from "../updateSieveCalibration/updateSieveCalibrationActions";

type Actions =
    IGetSieveCalibrationsLoadAction
    | IGetSieveCalibrationsLoadFailedAction
    | IGetSieveCalibrationsSuccessAction
    | IAddSieveCalibrationSuccessAction
    | IUpdateSieveCalibrationSuccessAction
    | IFlushDataSuccessAction;

export const GetSieveCalibrationsReducer = (state: Server<SilAPIResponse<ById<ISieveCalibration>>> = notLoaded, action: Actions): Server<SilAPIResponse<ById<ISieveCalibration>>> => {
    switch (action.type) {
        case IGET_SIEVES_CALIBRATIONS_REQUEST.REQUEST:
            return loading;

        case IGET_SIEVES_CALIBRATIONS_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_SIEVES_CALIBRATIONS_REQUEST.FAILED:
            return failed(action.message);

        case IUPDATE_SIEVE_CALIBRATION_REQUEST.SUCCESS:
            return notLoaded;

        case IADD_SIEVE_CALIBRATION_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};