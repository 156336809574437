import { ILabScale } from "../getLabScales/getLabScalesConstaints";
import { IUpdateLabScaleRequest, IUPDATE_LAB_SCALE_REQUEST } from "./updateLabScaleConstaints";

export interface IUpdateLabScaleLoadAction {
    type: IUPDATE_LAB_SCALE_REQUEST.REQUEST;
    data: IUpdateLabScaleRequest
}
export const updateLabScaleLoadAction = (data: IUpdateLabScaleRequest): IUpdateLabScaleLoadAction => ({
    type: IUPDATE_LAB_SCALE_REQUEST.REQUEST,
    data
});

export interface IUpdateLabScaleSuccessAction {
    type: IUPDATE_LAB_SCALE_REQUEST.SUCCESS;
    data: ILabScale;
}
export const updateLabScaleLoadSuccessAction = (data: ILabScale): IUpdateLabScaleSuccessAction => ({
    type: IUPDATE_LAB_SCALE_REQUEST.SUCCESS,
    data
});

export interface IUpdateLabScaleLoadFailedAction {
    type: IUPDATE_LAB_SCALE_REQUEST.FAILED;
    message: string;
}
export const updateLabScaleLoadFailedAction = (message: string): IUpdateLabScaleLoadFailedAction => ({
    type: IUPDATE_LAB_SCALE_REQUEST.FAILED,
    message
});
