import { IFlushDataSuccessAction } from "../../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../../flushData.tsx/flushDataConstants";
import { Server, failed, loading, notLoaded, succeeded } from "../../../../server";
import { IAddSieveDryPlantLoadAction, IAddSieveDryPlantLoadFailedAction, IAddSieveDryPlantSuccessAction } from "./addSieveDryPlantActions";
import { IADD_SIEVE_DRY_PLANT_REQUEST } from "./addSieveDryPlantConstaints";


type Actions =
    IAddSieveDryPlantLoadAction
    | IAddSieveDryPlantLoadFailedAction
    | IAddSieveDryPlantSuccessAction
    | IFlushDataSuccessAction;

export const AddSieveDryPlantReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IADD_SIEVE_DRY_PLANT_REQUEST.REQUEST:
            return loading;

        case IADD_SIEVE_DRY_PLANT_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IADD_SIEVE_DRY_PLANT_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};