import {currentUser} from '../../../../simosHome';

export default async function updateSIMOSCustomer(customer:any) {
    var responseStatus:any = null;
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type':'application/json'},
        body: JSON.stringify({
            request: {
                ID:customer.id,
                Customer:customer.customer,
                Active:customer.active,
                On_Hold:customer.on_Hold,
                Explorer_Code:customer.explorer_Code,
                Modified_By:currentUser, 
            },
            token: '19515195-d571-44a3-b0db-637f5bf24f54'})
    }
    await fetch('https://api2.propsense.com/api/updateSIMOSCustomer', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json');
        // error response
        if(!response.ok) {
          const error = (data) || response.status;
        return Promise.reject(error);
        } 
        responseStatus = response;
    });
    return responseStatus;
}

async function deleteSIMOSCustomerAddress(id:number) {
    var res:any = null;
    let requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type':'application/json'},
        body: JSON.stringify({
            request: {
                ID: id,
            },
            token: '19515195-d571-44a3-b0db-637f5bf24f54'})
    }
    // await fetch("http://localhost:62391/api/DeleteSIMOSDeliveryAddress", requestOptions)
    await fetch("https://api2.propsense.com/api/DeleteSIMOSDeliveryAddress", requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json');
        // error response
        if(!response.ok) {
          const error = (data) || response.status;
          console.log("should not be here");
          return error;
      }
      res = response;
    });
    return res;
}

async function updateSIMOSCustomerAddress(id:number, address:string){
    var res:any = null;
    let requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type':'application/json'},
        body: JSON.stringify({
            request: {
                ID: id,
                Address:address,
                Modified_By:currentUser
            },
            token: '19515195-d571-44a3-b0db-637f5bf24f54'})
    }
    // await fetch("http://localhost:62391/api/UpdateSIMOSDeliveryAddress",requestOptions)
    await fetch("https://api2.propsense.com/api/UpdateSIMOSDeliveryAddress",requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json');
        // error response
        if(!response.ok) {
          const error = (data) || response.status;
          console.log("should not be here");
          return error;
      }
      res = response;

    });
    return res;
}

export {deleteSIMOSCustomerAddress, updateSIMOSCustomerAddress};