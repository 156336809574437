import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IADD_CARRIER_INFRACTION_REQUEST } from "./addCarrierInfractionConstaints";
import { IAddCarrierInfractionLoadAction, IAddCarrierInfractionLoadFailedAction, IAddCarrierInfractionSuccessAction } from "./addCarrierInfractionActions";

type Actions =
    IAddCarrierInfractionLoadAction
    | IAddCarrierInfractionLoadFailedAction
    | IAddCarrierInfractionSuccessAction
    | IFlushDataSuccessAction;

export const AddCarrierInfractionReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IADD_CARRIER_INFRACTION_REQUEST.REQUEST:
            return loading;

        case IADD_CARRIER_INFRACTION_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IADD_CARRIER_INFRACTION_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};