import { ISieveCalibration } from "../../../../../utils/sharedTypes";
import { IPassTokenWithRequest } from "../../../dryPlant/token/getTokenConstants";


export enum IGET_SIEVE_FRAC_BALLS_REQUEST {
    REQUEST = "getSieveFracBall/GET_SIEVE_FRAC_BALLS_REQUEST",
    SUCCESS = "getSieveFracBall/GET_SIEVE_FRAC_BALLS_SUCCESS",
    FAILED = "getSieveFracBall/GET_SIEVE_FRAC_BALLS_FAILED"
};

export interface IGetSieveFracBallRequest extends IPassTokenWithRequest {

};


export interface ISieveFracBall {
    id: number;
    month?: number;
    year?: number;
    employee: string;
    completed: string;
    is_Calibration_Good: string;
    created: string;
    created_By: string;
    modified: string;
    modified_By: string;
    calibrations: ISieveCalibration[];
};