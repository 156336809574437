import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IUpdateSieveProductLoadAction, IUpdateSieveProductLoadFailedAction, IUpdateSieveProductSuccessAction, 
    updateSieveProductLoadFailedAction, updateSieveProductLoadSuccessAction
} from "./updateSieveProductActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IUPDATE_SIEVE_PRODUCT_REQUEST } from "./updateSieveProductConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";

export const updateSieveProductEpic: Epic = (
    action$: ActionsObservable<IUpdateSieveProductLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IUpdateSieveProductSuccessAction | IUpdateSieveProductLoadFailedAction> =>
    action$.ofType(IUPDATE_SIEVE_PRODUCT_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<string>>(
                    END_POINTS.PROPSENSE.SIL.SIEVES.UPDATE_SIEVE_PRODUCT,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<string>): IUpdateSieveProductSuccessAction | IUpdateSieveProductLoadFailedAction => {
                            if(response.message === "Success"){
                                return updateSieveProductLoadSuccessAction(response.message);
                            };
                            return updateSieveProductLoadFailedAction(response.message)
                        }),
                        catchError(() => ActionsObservable.of(updateSieveProductLoadFailedAction("Unable to update sieves product")))
                    )
            )
        );