import { ITruckScale } from "../getTruckScales/getTruckScalesConstaints";
import { IUpdateTruckScaleRequest, IUPDATE_TRUCK_SCALE_REQUEST } from "./updateTruckScaleConstaints";

export interface IUpdateTruckScaleLoadAction {
    type: IUPDATE_TRUCK_SCALE_REQUEST.REQUEST;
    data: IUpdateTruckScaleRequest
}
export const updateTruckScaleLoadAction = (data: IUpdateTruckScaleRequest): IUpdateTruckScaleLoadAction => ({
    type: IUPDATE_TRUCK_SCALE_REQUEST.REQUEST,
    data
});

export interface IUpdateTruckScaleSuccessAction {
    type: IUPDATE_TRUCK_SCALE_REQUEST.SUCCESS;
    data: ITruckScale;
}
export const updateTruckScaleLoadSuccessAction = (data: ITruckScale): IUpdateTruckScaleSuccessAction => ({
    type: IUPDATE_TRUCK_SCALE_REQUEST.SUCCESS,
    data
});

export interface IUpdateTruckScaleLoadFailedAction {
    type: IUPDATE_TRUCK_SCALE_REQUEST.FAILED;
    message: string;
}
export const updateTruckScaleLoadFailedAction = (message: string): IUpdateTruckScaleLoadFailedAction => ({
    type: IUPDATE_TRUCK_SCALE_REQUEST.FAILED,
    message
});
