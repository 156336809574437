import React from 'react';
import {Modal, Box, Backdrop, Grid, Button, TextField, InputLabel, Checkbox, FormControl, FormControlLabel, Typography} from '@mui/material';
import {addTransportCompany} from '../../../components/propsense/simos/APICalls/addUpdateTransportCompany';
import {optionTransport} from '../../../components/propsense/simos/OrderPage/siteInfo';

interface IProps{
    cancelPopup:any;
    refresh:any;
}
interface IState{

}




const BoxStyle:any = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '25%',
    backgroundColor: 'rgb(241, 241, 241) !important',
    border: '2px solid #000',
    boxShadow: 24,
    padding: '4ch',
  };
  const ExitButtonStyle = {
      bgcolor: 'rgb(174, 180, 184)',
      color: 'white',
      fontWeight: 'bold',
      left:'90%'
  }
var new_transport_company:any = {transport_company:'',email:'',active:'',truck_code_y:'',truck_code_z:''};
export default class CreateTransportCompanyPopup extends React.Component<IProps, IState>{
    constructor(props:IProps){
        super(props);
        this.state = {};
        this.onTextChange = this.onTextChange.bind(this);
        this.onSave = this.onSave.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }

    onTextChange(event:any) {
      var exists = false;
      switch(event.target.name){
          case 'transportcompany':
            optionTransport.map((cust:any) => {
                  if(cust.transport_Company === event.target.value){
                      exists = true;
                      this.setState({error:exists});
                  }
              });
              new_transport_company.transport_company = event.target.value;
              break;
          case 'transportemail':
              new_transport_company.email=event.target.value;
              break;
          case 'activeradio':
            new_transport_company.active=event.target.value;
              break;
          case 'truck_code_y':
            new_transport_company.truck_code_y=event.target.value;
              break;
          case 'truck_code_z':
            new_transport_company.truck_code_z=event.target.value;
              break;
      }
      this.setState({});
  }
    closePopup(){
        this.props.cancelPopup();
    }
    async onSave(){
        this.props.cancelPopup();
          if(new_transport_company.email!="" && new_transport_company.active != "" &&new_transport_company.transportcompany!= "" ){
          var success:any = await addTransportCompany(new_transport_company.transport_company, new_transport_company.active, new_transport_company.email, new_transport_company.truck_code_y,
            new_transport_company.truck_code_z);
          if(success.status === 200){
              this.props.refresh();
              //this.props.history.replace('/simos/transport-companies');
              alert("Successfully added new transport company");
              //window.location.reload();
          }
      }
      else{
          //alert("All fields must be filled!");
          this.setState({showError:true, errorText:'All fields must be filled!'});
      }
    }

    render(){

    return (
        <Modal id='warehouse-emp-modal'
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={true}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}>
                    <Box sx={BoxStyle}>
                    <Button sx={ExitButtonStyle} onClick={this.closePopup}>X</Button>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography>Creating New Transport Company</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField fullWidth label="Transport Company" onChange={this.onTextChange} name="transport_company"></TextField>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField fullWidth label="Email" onChange={this.onTextChange} name="email"></TextField>
                      </Grid>
                      <Grid item xs={12}>
                            <FormControlLabel control={<Checkbox onChange={this.onTextChange} name="active" />} label="Active"></FormControlLabel>
                      </Grid>
                      {/* <Grid item xs={12}>
                                <TextField disabled={user_role !== 'SuperAdmin'} autoComplete='nope'  label="Truck Code Y" name="truck_code_y" onChange={this.onTextChange}></TextField>
                            </Grid><Grid item xs={12}>
                                    <TextField disabled={user_role !== 'SuperAdmin'} autoComplete='nope'  label="Truck Code Z" name="truck_code_z" onChange={this.onTextChange}></TextField>
                                </Grid> */}
                      <Grid item xs={12}>
                      <Button style={{width:'100px',backgroundColor:'#bf0000', left:'80%'}} variant="contained" onClick={this.onSave}>Save</Button>
                      </Grid>
                      </Grid></Box></Modal>
        
    );
                }
}