

async function getSIMOSSalesOrderNumbers(){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: '19515195-d571-44a3-b0db-637f5bf24f54' })
    };
    let allSalesOrderNumbers:string[] = [];
      
    await fetch('https://api2.propsense.com/api/getSIMOSSalesOrderNumbers', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
        // error response
        if(!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        // no error
        allSalesOrderNumbers = data;
    })
    .catch(error => {
        console.log('There was an error!', error);
    });
    return allSalesOrderNumbers;
}

async function isSalesOrderNumberUnique(sales_order_number:string){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({request:{Sales_Order_Number:sales_order_number}, token: '19515195-d571-44a3-b0db-637f5bf24f54' })
    };
    let allSalesOrderNumbers:string = "";
      
    await fetch('https://api2.propsense.com/api/IsSalesOrderNumberUnique', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
        // error response
        if(!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        console.log(data)
        // no error
        allSalesOrderNumbers = data;
    })
    .catch(error => {
        console.log('There was an error!', error);
    });
    return allSalesOrderNumbers;
}


async function getSIMOSSalesOrderNumbersByCustomerID(customer_id:number){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({request:{CustomerID:customer_id}, token: '19515195-d571-44a3-b0db-637f5bf24f54' })
    };
    let allSalesOrderNumbers:string[] = [];
      
    await fetch('https://api2.propsense.com/api/getSIMOSSalesOrderNumbersByCustomerID', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
        // error response
        if(!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        // no error
        allSalesOrderNumbers = data;
    })
    .catch(error => {
        console.log('There was an error!', error);
    });
    return allSalesOrderNumbers;
}

export default getSIMOSSalesOrderNumbers;
export {isSalesOrderNumberUnique, getSIMOSSalesOrderNumbersByCustomerID};