import { IGET_SIMOS_CUSTOMER_ADDRESS_REQUEST, ISimosCustomerAddress } from "./getSimosCustomerAddressConstants";
import { IGetSimosCustomerAddressLoadAction, IGetSimosCustomerAddressLoadFailedAction, IGetSimosCustomerAddressSuccessAction } from "./getSimosCustomerAddressActions";
import { SimosAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../../server";
import { IFlushDataSuccessAction } from '../../../../flushData.tsx/flushDataActions';
import { FLUSH_DATA } from '../../../../flushData.tsx/flushDataConstants';
// import { IAddSimosCustomerAddressuccessAction } from "../addSimosCustomerAddress/addSimosCustomerAddressActions";
// import { IUpdateSimosCustomerAddressuccessAction } from "../updateSimosCustomerAddress/updateSimosCustomerAddressActions";
// import { IADD_SIMOS_CUSTOMER_ADDRESS_REQUEST } from "../addSimosCustomerAddress/addSimosCustomerAddressConstaints";
// import { IUPDATE_SIMOS_CUSTOMER_ADDRESS_REQUEST } from "../updateSimosCustomerAddress/updateSimosCustomerAddressConstaints";

type Actions =
    | IGetSimosCustomerAddressLoadAction
    | IGetSimosCustomerAddressSuccessAction
    | IGetSimosCustomerAddressLoadFailedAction
    // | IAddSimosCustomerAddressuccessAction
    // | IUpdateSimosCustomerAddressuccessAction
    | IFlushDataSuccessAction;

export const GetSimosCustomerAddressReducer = (state: Server<SimosAPIResponse<ISimosCustomerAddress[]>> = notLoaded, action: Actions): Server<SimosAPIResponse<ISimosCustomerAddress[]>> => {
    switch (action.type) {
        case IGET_SIMOS_CUSTOMER_ADDRESS_REQUEST.REQUEST:
            return loading;

        case IGET_SIMOS_CUSTOMER_ADDRESS_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_SIMOS_CUSTOMER_ADDRESS_REQUEST.FAILED:
            return failed(action.message);

        // case IADD_SIMOS_CUSTOMER_ADDRESS_REQUEST.SUCCESS:
        //     return notLoaded;

        // case IUPDATE_SIMOS_CUSTOMER_ADDRESS_REQUEST.SUCCESS:
        //     return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};