import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, isSucceeded, Server } from "../../../../redux/server";
import { ById, SilAPIResponse } from "../../../shared/publicInterfaces";
import { userName, undefinedFunction, yesOrNoOptions, ZEROTH } from "../../../shared/constExports";
import { LAButton, LASaveAndCancelButton } from "../../../shared/buttons";
import { ArrowLeftIcon } from "../../../shared/icons";
import { LIGHT_GREY_COLOR, RED_COLOR, WHITE_COLOR } from "../../../shared/theme";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import LATextField from "../../../shared/textField";
import LAAutoComplete from "../../../shared/autoComplete";
import { ROUTE } from "../../../routes";
import queryString from "query-string";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import { FieldValidator, FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../../shared/fieldValidator";
import PageSpacing from "../../../shared/pageSpacing";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import LATextArea from "../../../shared/textArea";
import SilRoleCheck from "../../../shared/silRoleCheck";
import { IAddSieveMeasurementRequest, IAddSieveRequest } from "../../../../redux/sil/sieves/addSieve/addSieveConstaints";
import { IUpdateSieveRequest } from "../../../../redux/sil/sieves/updateSieve/updateSieveConstaints";
import { IGetSieveLookupsRequest, ISieveLookup } from "../../../../redux/sil/sieves/getSieveLookups/getSieveLookupsConstaints";
import { updateSieveStatus } from "../../../../redux/sil/sieves/updateSieve/updateSieveAccessor";
import { getSieveLookupsStatus } from "../../../../redux/sil/sieves/getSieveLookups/getSieveLookupsAccessor";
import { addSieveStatus } from "../../../../redux/sil/sieves/addSieve/addSieveAccessor";
import { addSieveLoadAction } from "../../../../redux/sil/sieves/addSieve/addSieveActions";
import { updateSieveLoadAction } from "../../../../redux/sil/sieves/updateSieve/updateSieveActions";
import { getSieveLookupsLoadAction } from "../../../../redux/sil/sieves/getSieveLookups/getSieveLookupsActions";
import { IGetSievesRequest, ISieve, ISieveMeasurement } from "../../../../redux/sil/sieves/getSieves/getSievesConstaints";
import { getSievesStatus } from "../../../../redux/sil/sieves/getSieves/getSievesAccessor";
import { getSievesLoadAction } from "../../../../redux/sil/sieves/getSieves/getSievesActions";
import { convertList } from "../washPlant/washPlantItemForm";
import DataGrid, { Column, Editing, Paging, Summary, TotalItem } from "devextreme-react/data-grid";
import { LADateTimeMUIPicker } from "../../../shared/dateTimePickerMUI";
import { LAPopover } from "../../../shared/popOver";
import LAErrorBox from "../../../shared/errorBox";


const requiredFields = ["date", "product", "plant", "operator", "initial_Mass"];

interface ISieveStoreProps {
    addSieve: Server<string>;
    updateSieve: Server<string>;
    getToken: Server<SilAPIResponse<IToken>>;
    getSieve: Server<SilAPIResponse<ById<ISieve>>>;
    getSieveLookups: Server<SilAPIResponse<ISieveLookup>>;
};

interface ISieveDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getSieveRequest: (data: IGetSievesRequest) => unknown;
    addSieveRequest: (data: IAddSieveRequest) => unknown;
    updateSieveRequest: (data: IUpdateSieveRequest) => unknown;
    getSieveLookupsRequest: (data: IGetSieveLookupsRequest) => unknown;
};


interface ISieveOwnProps {

};

interface ISieveState {
    sieve: ISieve;
    massPopup: boolean;
    errors: ById<IFieldErrorKeyValue>;
};

const SieveStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;

    .dx-toolbar-after {
        display: none;
    };

    .variancepopup{
        height: 50% !important;
    }
`;

type ISieveProps = RouteComponentProps
    & ISieveStoreProps
    & ISieveDispatchProps
    & ISieveOwnProps;

class Sieve extends PureComponent<ISieveProps, ISieveState> {

    public constructor(props: ISieveProps) {
        super(props);
        this.state = {
            errors: {
                // "date": { key: "date", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "product": { key: "product", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "plant": { key: "plant", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "operator": { key: "operator", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "initial_Mass": { key: "initial_Mass", message: FIELD_VALIDATOR_ERRORS.REQUIRED }
            },
            sieve: {
                id: 0,
                date: new Date().toLocaleString(),
                operator: "",
                bin: "",
                product: "",
                plant: "",
                load_No: "",
                cancelled: "No",
                notes: "",
                initial_Mass: 0,
                created: "",
                created_By: userName,
                modified: "",
                modified_By: userName,
                measurements: []
            },
            massPopup: false
        };
    }

    public componentDidMount(): void {
        if (hasPayload(this.props.getToken))
            this.props.getSieveLookupsRequest({
                token: this.props.getToken.payload.response.token,
                Requested_Page: "Sieve"
            });

        this.callServer();
    };

    public componentDidUpdate(prevProps: ISieveProps): void {
        if (this.props !== prevProps) {
            this.callServer();

            if (this.props.addSieve !== prevProps.addSieve) {
                if (isSucceeded(this.props.addSieve)) {
                    this.handleCancel();
                };
            };

            if (this.props.updateSieve !== prevProps.updateSieve) {
                if (isSucceeded(this.props.updateSieve)) {
                    this.handleCancel();
                };
            };
        };
    };


    public render(): ReactNode {

        const { sieve, errors, massPopup } = this.state;
        const { updateSieve, addSieve, getSieveLookups } = this.props;
        const lookUps = hasPayload(getSieveLookups) ? getSieveLookups.payload.response : { plants: [], products: [], mesh: [], screens: [] };
        const plants = convertList(lookUps.plants);
        const filteredProduct = this.filterProduct(lookUps);
        const filteredScreen = this.filterScreen(lookUps);
        const products = convertList(filteredProduct);
        const screens = convertList(filteredScreen);
        const percFine = this.calculatePerFine();

        return (
            <PageSpacing title="SIL - Sieve" description="Sieve" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="sieveAccess">
                    <SieveStyles>

                        <LAButton startIcon={<ArrowLeftIcon color={WHITE_COLOR} />} label="Back to list" onClick={this.handleCancel} />
                        <h2 className="text-center">{sieve.id > 0 ? "VIEW/UPDATE " : "ADD "} SIEVE</h2>
                        <hr />

                        <LAPaperWithPadding>
                            <LAGrid spacing={1}>

                                <LAGridItem xs={3}>
                                    {sieve.id > 0 &&

                                        <LATextField
                                            varient="outlined"
                                            label="PS ID"
                                            fullWidth={true}
                                            name="id"
                                            disabled={true}
                                            onChange={undefinedFunction}
                                            value={sieve.id ?? ""}
                                        />
                                    }
                                    {sieve.id === 0 &&

                                        <LATextField
                                            varient="outlined"
                                            label="PS ID"
                                            fullWidth={true}
                                            name="ps_id"
                                            disabled={true}
                                            onChange={undefinedFunction}
                                            value="NA"
                                        />
                                    }
                                </LAGridItem>

                                <LAGridItem xs={3}>
                                    <LADateTimeMUIPicker
                                        name="date"
                                        label="Date"
                                        fullWidth={true}
                                        value={sieve.date}
                                        onChange={this.handleDate}
                                        errorText={errors && errors["date"] ? errors["date"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={3}>
                                    <LATextField
                                        varient="outlined"
                                        label="Operator"
                                        fullWidth={true}
                                        name="operator"
                                        onChange={this.onChange}
                                        value={sieve.operator ?? ""}
                                        errorText={errors && errors["operator"] ? errors["operator"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={3}>
                                    <LAAutoComplete
                                        filterSelectedOptions={true}
                                        autoHighlight={true}
                                        selectionRemove={undefinedFunction}
                                        dropDownPlaceHolder="Plant"
                                        getOptionLabel="name"
                                        name="plant"
                                        option={plants}
                                        multiple={false}
                                        onChange={this.handlePlantDropDownChange}
                                        disabled={(sieve.id > 0 ? true : undefined)}
                                        errorText={errors && errors["plant"] ? errors["plant"].message : undefined}
                                        value={hasPayload(getSieveLookups) && sieve.plant ? plants.find(x => x.name === sieve.plant) : ""}
                                        defaultValue={hasPayload(getSieveLookups) && sieve.plant ? plants.find(x => x.name === sieve.plant) : ""}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={3}>
                                    <LAAutoComplete
                                        filterSelectedOptions={true}
                                        autoHighlight={true}
                                        selectionRemove={undefinedFunction}
                                        dropDownPlaceHolder="Product"
                                        getOptionLabel="name"
                                        name="product"
                                        option={products}
                                        multiple={false}
                                        onChange={this.handleProductSelection}
                                        disabled={((sieve.id === 0) && (sieve.plant)) ? undefined : true}
                                        errorText={errors && errors["product"] ? errors["product"].message : undefined}
                                        value={hasPayload(getSieveLookups) && sieve.product ? products.find(x => x.name === sieve.product) : ""}
                                        defaultValue={hasPayload(getSieveLookups) && sieve.product ? products.find(x => x.name === sieve.product) : ""}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={3}>
                                    <LATextField
                                        varient="outlined"
                                        label="Bin"
                                        fullWidth={true}
                                        name="bin"
                                        onChange={this.onChange}
                                        value={sieve.bin ?? ""}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={3}>
                                    <LATextField
                                        varient="outlined"
                                        label="Load No"
                                        fullWidth={true}
                                        name="load_No"
                                        onChange={this.onChange}
                                        value={sieve.load_No ?? ""}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={3}>
                                    <LAAutoComplete
                                        filterSelectedOptions={true}
                                        autoHighlight={true}
                                        selectionRemove={undefinedFunction}
                                        dropDownPlaceHolder="Cancelled"
                                        getOptionLabel="name"
                                        name="cancelled"
                                        option={yesOrNoOptions}
                                        multiple={false}
                                        onChange={this.handleDropDownChange}
                                        disabled={false}
                                        errorText={errors && errors["cancelled"] ? errors["cancelled"].message : undefined}
                                        value={sieve.cancelled ? yesOrNoOptions.find(x => x.name === sieve.cancelled) : ""}
                                        defaultValue={sieve.cancelled ? yesOrNoOptions.find(x => x.name === sieve.cancelled) : ""}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={3}>
                                    <LATextField
                                        varient="outlined"
                                        label="Feed Material 1"
                                        fullWidth={true}
                                        name="feed_Material_1"
                                        onChange={this.onChange}
                                        value={sieve.feed_Material_1 ?? ""}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={3}>
                                    <LATextField
                                        varient="outlined"
                                        label="Feed Material 2"
                                        fullWidth={true}
                                        name="feed_Material_2"
                                        onChange={this.onChange}
                                        value={sieve.feed_Material_2 ?? ""}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={3}>
                                    <LATextField
                                        varient="outlined"
                                        label="Feed Material 3"
                                        fullWidth={true}
                                        name="feed_Material_3"
                                        onChange={this.onChange}
                                        value={sieve.feed_Material_3 ?? ""}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={3}>
                                    <LATextField
                                        varient="outlined"
                                        label="Feed Material 4"
                                        fullWidth={true}
                                        name="feed_Material_4"
                                        onChange={this.onChange}
                                        value={sieve.feed_Material_4 ?? ""}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={3}>
                                    <LATextField
                                        varient="outlined"
                                        label="Feed Material 1 %"
                                        fullWidth={true}
                                        name="feed_Material_1_Perc"
                                        onChange={this.onDecimalChange}
                                        value={sieve.feed_Material_1_Perc ?? ""}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={3}>
                                    <LATextField
                                        varient="outlined"
                                        label="Feed Material 2 %"
                                        fullWidth={true}
                                        name="feed_Material_2_Perc"
                                        onChange={this.onDecimalChange}
                                        value={sieve.feed_Material_2_Perc ?? ""}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={3}>
                                    <LATextField
                                        varient="outlined"
                                        label="Feed Material 3 %"
                                        fullWidth={true}
                                        name="feed_Material_3_Perc"
                                        onChange={this.onDecimalChange}
                                        value={sieve.feed_Material_3_Perc ?? ""}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={3}>
                                    <LATextField
                                        varient="outlined"
                                        label="Feed Material 4 %"
                                        fullWidth={true}
                                        name="feed_Material_4_Perc"
                                        onChange={this.onDecimalChange}
                                        value={sieve.feed_Material_4_Perc ?? ""}
                                    />
                                </LAGridItem>



                                <LAGridItem xs={3}>
                                    <LATextField
                                        varient="outlined"
                                        label="Initial Mass"
                                        fullWidth={true}
                                        name="initial_Mass"
                                        onChange={this.onDecimalChange}
                                        value={sieve.initial_Mass ?? ""}
                                        errorText={errors && errors["initial_Mass"] ? errors["initial_Mass"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={3}>
                                    <LAAutoComplete
                                        filterSelectedOptions={true}
                                        autoHighlight={true}
                                        selectionRemove={undefinedFunction}
                                        dropDownPlaceHolder="Screen Section"
                                        getOptionLabel="name"
                                        name="screen_Section"
                                        option={screens}
                                        multiple={false}
                                        onChange={this.handleScreenDownChange}
                                        disabled={((sieve.id === 0) && (sieve.plant)) ? undefined : true}
                                        errorText={errors && errors["screen_Section"] ? errors["screen_Section"].message : undefined}
                                        value={hasPayload(getSieveLookups) && sieve.screen_Section ? screens.find(x => x.name === sieve.screen_Section) : ""}
                                        defaultValue={hasPayload(getSieveLookups) && sieve.screen_Section ? screens.find(x => x.name === sieve.screen_Section) : ""}
                                    />
                                </LAGridItem>

                                {sieve.screen_Section === "Raw" && <>
                                    <LAGridItem xs={3}>
                                        <LATextField
                                            varient="outlined"
                                            label="Split Mass"
                                            fullWidth={true}
                                            name="split_Mass"
                                            onChange={this.onDecimalChange}
                                            value={sieve.split_Mass ?? ""}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={3}>
                                        <LATextField
                                            varient="outlined"
                                            label="Wash Mass"
                                            fullWidth={true}
                                            name="wash_Mass"
                                            onChange={this.onDecimalChange}
                                            value={sieve.wash_Mass ?? ""}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={3}>
                                        <LATextField
                                            varient="outlined"
                                            label="Wash NTU"
                                            fullWidth={true}
                                            name="wash_NTU"
                                            onChange={this.onDecimalChange}
                                            value={sieve.wash_NTU ?? ""}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={3}>
                                        <LATextField
                                            varient="outlined"
                                            label="Perc Fines"
                                            fullWidth={true}
                                            disabled={true}
                                            name="perc_Fines"
                                            onChange={this.onDecimalChange}
                                            value={percFine}
                                        />
                                    </LAGridItem>
                                </>}

                                <LAGridItem xs={3}>
                                    <LATextField
                                        varient="outlined"
                                        label="Duplicate CantyId"
                                        fullWidth={true}
                                        name="duplicate_CantyID"
                                        onChange={this.onChange}
                                        value={sieve.duplicate_CantyID ?? ""}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={3}>
                                    <LATextField
                                        varient="outlined"
                                        label="Rotap Id"
                                        fullWidth={true}
                                        name="rotap_ID"
                                        onChange={this.onNumberChange}
                                        value={sieve.rotap_ID ?? ""}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={3}>
                                    <LATextArea
                                        varient="outlined"
                                        label="Notes"
                                        fullWidth={true}
                                        name="notes"
                                        minRows={3}
                                        rowsMax={5}
                                        onChange={this.onChange}
                                        value={sieve.notes ?? ""}
                                    />
                                </LAGridItem>
                                {/* <LAGridItem xs={3}>
                                    <strong>Created: </strong>
                                    {sieve.created}                                   
                                </LAGridItem>
                                <LAGridItem xs={3}>
                                    <strong>Created By: </strong>
                                    {sieve.created_By}                                   
                                </LAGridItem>
                                <LAGridItem xs={3}>
                                    <strong>Modified: </strong>
                                    {sieve.modified}                                   
                                </LAGridItem>
                                <LAGridItem xs={3}>
                                    <strong>Modified By: </strong>
                                    {sieve.modified_By}                                   
                                </LAGridItem> */}
{this.state.sieve.product !== "Exploration Wash Only" &&
                                <LAGridItem xs={12}>
                                    <DataGrid
                                        keyExpr="id"
                                        showBorders={true}
                                        columnAutoWidth={true}
                                        dataSource={sieve.measurements}
                                        onRowPrepared={(e: any) => {
                                            if (e.rowElement.cells[0])
                                                e.rowElement.cells[0].style.backgroundColor = LIGHT_GREY_COLOR;

                                            if (e.rowElement.cells[2])
                                                e.rowElement.cells[2].style.backgroundColor = LIGHT_GREY_COLOR;

                                            if (e.rowElement.cells[3])
                                                e.rowElement.cells[3].style.backgroundColor = LIGHT_GREY_COLOR;

                                            if (e.rowElement.cells[4])
                                                e.rowElement.cells[4].style.backgroundColor = LIGHT_GREY_COLOR;

                                            if (e.rowElement.cells[5])
                                                e.rowElement.cells[5].style.backgroundColor = LIGHT_GREY_COLOR;

                                            if (e.data) {
                                                const pRet = this.calculatePercReturn(e.data);
                                                const pPass = this.calculatePercPass(e.data);
                                                let pRetSpecVal = e.data.perc_Ret_Spec;
                                                let pPassSpecVal = e.data.perc_Pass_Spec;
                                                if (pRetSpecVal !== "NA") {
                                                    if (pRetSpecVal != null && pRetSpecVal !== undefined && pRetSpecVal !== "") {
                                                        const rangeArr = pRetSpecVal.split('-');

                                                        let retMin = 0.0;
                                                        let retMax = 0.0;
                                                        if (rangeArr != null && rangeArr !== undefined && rangeArr.length > 0) {
                                                            if (rangeArr[0] != null && rangeArr[0])
                                                                retMin = rangeArr[0];
                                                            if (rangeArr[1] != null && rangeArr[1])
                                                                retMax = rangeArr[1];

                                                        }
                                                        if (Number(pRet) < retMin || Number(pRet) > retMax) {
                                                            if (e.rowElement.cells[2]) {
                                                                e.rowElement.cells[2].style.color = WHITE_COLOR;
                                                                e.rowElement.cells[2].style.backgroundColor = RED_COLOR;
                                                            }
                                                        }
                                                    }
                                                }


                                                if (pPassSpecVal !== "NA") {
                                                    if (pPassSpecVal != null && pPassSpecVal !== undefined && pPassSpecVal !== "") {
                                                        const rangeArr = pPassSpecVal.split('-');

                                                        let retMin = 0.0;
                                                        let retMax = 0.0;
                                                        if (rangeArr != null && rangeArr !== undefined && rangeArr.length > 0) {
                                                            if (rangeArr[0] != null && rangeArr[0])
                                                                retMin = rangeArr[0];
                                                            if (rangeArr[1] != null && rangeArr[1])
                                                                retMax = rangeArr[1];

                                                        }
                                                        if (Number(pPass) < retMin || Number(pPass) > retMax) {
                                                            if (e.rowElement.cells[2]) {
                                                                e.rowElement.cells[2].style.color = WHITE_COLOR;
                                                                e.rowElement.cells[2].style.backgroundColor = RED_COLOR;
                                                            }
                                                        }
                                                    }
                                                }

                                            }
                                        }}

                                    >
                                        <Paging enabled={false} />
                                        <Editing
                                            mode="batch"
                                            allowUpdating={true}
                                            onChangesChange={this.onChangesChange}
                                        />

                                        <Column dataField="mesh" caption="Mesh" allowEditing={false} dataType="string" />
                                        <Column dataField="weight" caption="Weight" dataType="number" />
                                        <Column dataField="perc_Return" format="#.00" caption="Perc Return" allowEditing={false} calculateCellValue={this.calculatePercReturn} dataType="number" />
                                        <Column dataField="perc_Pass" format="#.00" caption="Perc Pass" allowEditing={false} calculateCellValue={this.calculatePercPass} dataType="number" />
                                        <Column dataField="perc_Ret_Spec" format="#.00" caption="Perc Ret Spec" allowEditing={false} dataType="string" />
                                        <Column dataField="perc_Pass_Spec" format="#.00" caption="Perc Pass Spec" allowEditing={false} dataType="string" />

                                        <Summary>
                                            <TotalItem
                                                summaryType="sum"
                                                valueFormat="#.00"
                                                column="weight"
                                                displayFormat="Total: {0}"
                                            />
                                        </Summary>
                                    </DataGrid>

                                </LAGridItem>
    }

                                <LAGridItem xs={12}>
                                    <LASaveAndCancelButton
                                        onSave={this.onSave}
                                        onCancel={this.handleCancel}
                                        disableSave={(Object.values(errors).length > 0 ? true : undefined)}
                                    />
                                </LAGridItem>

                            </LAGrid>
                        </LAPaperWithPadding>

                        <RequestStatus requestStatus={addSieve.kind} successMessage="Sieve  successfully saved" />
                        <RequestStatus requestStatus={updateSieve.kind} successMessage="Sieve  successfully updated" />

                        {massPopup && <LAPopover open={massPopup} onClose={this.handleMassPopup} anchorRef={null}>
                            <LAPaperWithPadding className="text-center">
                                <br /><br />
                                <LAErrorBox text="Please redo the sample as Initial Mass and Weight are out of spec." />
                                <br />
                                <LAButton label="Close" onClick={this.handleMassPopup} />
                            </LAPaperWithPadding>
                        </LAPopover>}

                    </SieveStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }


    private handleMassPopup = (): void => {
        this.setState({ massPopup: !this.state.massPopup });
    };

    private calculatePerFine = (): number => {
        const { split_Mass, wash_Mass } = this.state.sieve;
        const val = ((((split_Mass ?? 0) - (wash_Mass ?? 0)) / (split_Mass ?? 0)) * 100);
        return !isNaN(val) ? Number(val.toFixed(2)) : 0;

    };

    private calculatePercReturn = (data: ISieveMeasurement): number => {
        if (data.id !== undefined) {
            const iS = this.state.sieve.measurements;
            const index = iS.findIndex((x) => x.id === data.id);

            if (index >= ZEROTH) {
                let curWeight = 0;
                let result = 0;
                curWeight = (iS[index].weight ?? 0);

                const totalW = this.calculateTotalWeight();
                result = (((curWeight) / totalW) * 100);
                return !isNaN(result) ? Number(result.toFixed(2)) : 0;
            } else {
                return 0;
            };
        }
        return 0;
    };


    private calculatePercPass = (data: ISieveMeasurement): number => {
        if (data.id !== undefined) {
            const iS = this.state.sieve.measurements;
            const index = iS.findIndex((x) => x.id === data.id);

            if (index >= ZEROTH) {
                let prevWeight = 0;
                let result = 0;
                for (let i = 0; i <= index; i++) {
                    prevWeight += (iS[i].weight ?? 0);
                };

                const totalW = this.calculateTotalWeight();
                result = (((totalW - prevWeight) / totalW) * 100);
                return !isNaN(result) ? Number(result.toFixed(2)) : 0;
            } else {
                return 0;
            };
        }
        return 0;
    };

    private calculateTotalWeight = (): number => {
        const data = this.state.sieve.measurements;

        if (data.length > ZEROTH) {
            return data.map(item => (item.weight ?? 0)).reduce((prev, next) => prev + next);
        } else {
            return 0;
        }
    };

    private onChangesChange = (updatedData: { data: ISieveMeasurement, key: number, type: string }[]): void => {
        const iS = [...this.state.sieve.measurements];

        updatedData.forEach((x: { data: ISieveMeasurement, key: number, type: string }) => {
            const index = iS.findIndex((q) => q.id === x.key);
            let record = { ...iS[index] };

            record = { ...record, ...x.data };
            iS[index] = record;
        });

        this.setState({
            ...this.state,
            sieve: {
                ...this.state.sieve,
                measurements: iS
            }
        });
    };

    private filterProduct = (data: ISieveLookup): string[] => {
        const res: string[] = [];
        const plant = this.state.sieve.plant;

        for (let i in data.products) {
            if (data.products[i].plant === plant)
                res.push(data.products[i].product);
        };

        return res;
    };

    private filterScreen = (data: ISieveLookup): string[] => {
        const res: string[] = [];
        const plant = this.state.sieve.plant;

        for (let i in data.screens) {
            if (data.screens[i].plant === plant)
                res.push(data.screens[i].screen);
        };

        return res;
    };


    private handleDate = (name: string, date: string): void => {
        let errors = this.state.errors;
        errors = this.errorChecker(name, date, errors, true);

        this.setState({
            ...this.state,
            errors,
            sieve: {
                ...this.state.sieve,
                [name]: date,
            }
        });
    };


    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, isRequired: boolean): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: isRequired ? true : undefined, minLength: 1, decimal: undefined });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        };
        return err;
    };

    private handleCancel = (): void => {
        this.props.history.push(ROUTE.SIL.SIEVES.SIEVES_ITEMS);
    };

    private onSave = async (): Promise<void> => {

        const { sieve } = this.state;
        let tDate = new Date(sieve.date)
        const measurements = [...sieve.measurements];
        let totalMass = 0.0;
        measurements.forEach(async (x) => {
            totalMass = totalMass + (x.weight ?? 0);
        });

        const init_Mass = sieve.initial_Mass;

        let variance = ((totalMass - init_Mass) / init_Mass) * 100;
        if (variance > 1) {
            this.handleMassPopup();
            this.setState({
                ...this.state,
                massPopup: true,
                sieve: {
                    ...this.state.sieve,
                    measurements,
                    date: tDate.toLocaleString(),
                }
            });
        }
        else {

            if (hasPayload(this.props.getToken)) {

                const meas: IAddSieveMeasurementRequest[] = [];

                measurements
                    .forEach(async (x) => {
                        meas.push({
                            ID: x.id,
                            Mesh: x.mesh,
                            Weight: x.weight ?? 0,
                            Perc_Pass: this.calculatePercPass(x),
                            Perc_Return: this.calculatePercReturn(x),
                            Perc_Pass_Spec: x.perc_Pass_Spec,
                            Perc_Ret_Spec: x.perc_Ret_Spec
                        });
                    });

                if (sieve.id === 0) {
                    this.props.addSieveRequest({
                        token: this.props.getToken.payload.response.token,
                        request: {
                            ID: sieve.id,
                            Date: tDate.toLocaleString(),
                            Operator: sieve.operator,
                            Bin: sieve.bin,
                            Plant: sieve.plant,
                            Product: sieve.product,
                            Notes: sieve.notes,
                            Load_No: sieve.load_No,
                            Feed_Material_1: sieve.feed_Material_1,
                            Feed_Material_2: sieve.feed_Material_2,
                            Feed_Material_3: sieve.feed_Material_3,
                            Feed_Material_4: sieve.feed_Material_4,
                            Feed_Material_1_Perc: sieve.feed_Material_1_Perc,
                            Feed_Material_2_Perc: sieve.feed_Material_2_Perc,
                            Feed_Material_3_Perc: sieve.feed_Material_3_Perc,
                            Feed_Material_4_Perc: sieve.feed_Material_4_Perc,
                            Cancelled: sieve.cancelled,
                            Wash_Mass: Number(sieve.wash_Mass),
                            Wash_NTU: sieve.wash_NTU,
                            Duplicate_CantyID: sieve.duplicate_CantyID,
                            Screen_Section: sieve.screen_Section,
                            Initial_Mass: sieve.initial_Mass,
                            Split_Mass: sieve.split_Mass,
                            Perc_Fines: this.calculatePerFine(),
                            Rotap_ID: sieve.rotap_ID,
                            Modified_By: sieve.modified_By,
                            Created_By: sieve.created_By,
                            Measurements: meas
                        }
                    });
                } else {
                    this.props.updateSieveRequest({
                        token: this.props.getToken.payload.response.token,
                        request: {
                            ID: sieve.id,
                            Date: tDate.toLocaleString(),
                            Operator: sieve.operator,
                            Bin: sieve.bin,
                            Plant: sieve.plant,
                            Product: sieve.product,
                            Notes: sieve.notes,
                            Load_No: sieve.load_No,
                            Feed_Material_1: sieve.feed_Material_1,
                            Feed_Material_2: sieve.feed_Material_2,
                            Feed_Material_3: sieve.feed_Material_3,
                            Feed_Material_4: sieve.feed_Material_4,
                            Feed_Material_1_Perc: sieve.feed_Material_1_Perc,
                            Feed_Material_2_Perc: sieve.feed_Material_2_Perc,
                            Feed_Material_3_Perc: sieve.feed_Material_3_Perc,
                            Feed_Material_4_Perc: sieve.feed_Material_4_Perc,
                            Cancelled: sieve.cancelled,
                            Wash_Mass: sieve.wash_Mass,
                            Wash_NTU: sieve.wash_NTU,
                            Duplicate_CantyID: sieve.duplicate_CantyID,
                            Screen_Section: sieve.screen_Section,
                            Initial_Mass: sieve.initial_Mass,
                            Split_Mass: sieve.split_Mass,
                            Perc_Fines: this.calculatePerFine(),
                            Rotap_ID: sieve.rotap_ID,
                            Modified_By: userName,
                            Created_By: sieve.created_By,
                            Measurements: meas
                        }
                    });
                };
            };
            this.setState({
                ...this.state,
                sieve: {
                    ...this.state.sieve,
                    measurements,
                    date: tDate.toLocaleString(),
                }
            });
        }


    };

    private handleDropDownChange = (event: unknown, value: { id: number, name: string } | "", name?: string): void => {
        if (name) {
            let errors = this.state.errors;

            if (requiredFields.includes(name))
                errors = this.errorChecker(name, value !== "" ? value.name : "", errors, true);

            this.setState({
                ...this.state,
                errors,
                sieve: {
                    ...this.state.sieve,
                    [name]: value !== "" ? value.name : ""
                }
            });
        };
    };

    private handlePlantDropDownChange = (event: unknown, value: { id: number, name: string } | "", name?: string): void => {
        if (name && hasPayload(this.props.getSieveLookups)) {
            let errors = this.state.errors;

            if (requiredFields.includes(name))
                errors = this.errorChecker(name, value !== "" ? value.name : "", errors, true);

            let screen_Section = "";

            if (value !== "") {
                const index = this.props.getSieveLookups.payload.response.screens.findIndex((x) => x.plant === value.name);
                screen_Section = this.props.getSieveLookups.payload.response.screens[index].screen;
            };

            this.setState({
                ...this.state,
                errors,
                sieve: {
                    ...this.state.sieve,
                    screen_Section,
                    [name]: value !== "" ? value.name : ""
                }
            });
        };
    };

    private handleScreenDownChange = (event: unknown, value: { id: number, name: string } | "", name?: string): void => {
        if (name && hasPayload(this.props.getSieveLookups)) {
            let errors = this.state.errors;

            if (requiredFields.includes(name))
                errors = this.errorChecker(name, value !== "" ? value.name : "", errors, true);

            let screen_Section = "";

            if (value !== "") {
                screen_Section = value.name;
            };

            this.setState({
                ...this.state,
                errors,
                sieve: {
                    ...this.state.sieve,
                    screen_Section
                }
            });
        };
    };

    private handleProductSelection = (event: unknown, value: { id: number, name: string } | "", name?: string): void => {
       
            if (name) {

                const newMesh: ISieveMeasurement[] = [];
    
                if (hasPayload(this.props.getSieveLookups) && value !== "") {
    
                    const { created_By, modified_By, id } = this.state.sieve;
                    const { mesh } = this.props.getSieveLookups.payload.response;
    
                    const index = mesh.findIndex((x) => x.product === value.name);
                    const meshArray = mesh[index].mesh.split(",");
                    let retainSpecArray = mesh[index].perc_Retain_Specs ? mesh[index].perc_Retain_Specs?.split(",") : [];
                    if (retainSpecArray !== undefined && retainSpecArray[0] !== "NA") {
                        for (let i = 0; i < retainSpecArray.length; i++) {
                            if (retainSpecArray[i].indexOf(';') !== -1) {
                                retainSpecArray[i] = retainSpecArray[i].split(';')[1];
                            }
                        }
                    }
    
                    let passSpecArray = mesh[index].perc_Pass_Specs ? mesh[index].perc_Pass_Specs?.split(",") : [];
                    if (passSpecArray !== undefined && passSpecArray[0] !== "NA") {
                        for (let i = 0; i < passSpecArray.length; i++) {
                            if (passSpecArray[i].indexOf(';') !== -1) {
                                passSpecArray[i] = passSpecArray[i].split(';')[1];
                            }
                        }
                    }
    
                    meshArray.forEach((x, index) => {
                        newMesh.push({
                            id: index,
                            sieve_ID: id,
                            mesh: x,
                            perc_Return: 0,
                            perc_Pass: 0,
                            perc_Ret_Spec: retainSpecArray ? (retainSpecArray[0] && retainSpecArray[0] === "NA") ? "NA" : retainSpecArray[index] : "",
                            perc_Pass_Spec: passSpecArray ? (passSpecArray[0] && passSpecArray[0] === "NA") ? "NA" : passSpecArray[index] : "",
                            created: "",
                            modified: "",
                            created_By: created_By,
                            modified_By: modified_By
                        });
                    });
    
                };
    
                let errors = this.state.errors;
    
                if (requiredFields.includes(name))
                    errors = this.errorChecker(name, value !== "" ? value.name : "", errors, true);
    
                this.setState({
                    ...this.state,
                    errors,
                    sieve: {
                        ...this.state.sieve,
                        measurements: newMesh,
                        [name]: value !== "" ? value.name : ""
                    }
                });
            
        }
    };

    private onDecimalChange = (name: string, value: string): void => {
        if (!isNaN(Number(value))) {
            let errors = this.state.errors;

            if (requiredFields.includes(name))
                errors = this.errorChecker(name, value, errors, true);

            this.setState({
                ...this.state,
                errors,
                sieve: {
                    ...this.state.sieve,
                    [name]: value
                }
            });
        };
    };



    private onNumberChange = (name: string, value: string): void => {
        if (!isNaN(Number(value)) && !value.includes(".")) {
            let errors = this.state.errors;

            if (requiredFields.includes(name))
                errors = this.errorChecker(name, value, errors, true);

            this.setState({
                ...this.state,
                errors,
                sieve: {
                    ...this.state.sieve,
                    [name]: value
                }
            });
        };
    };


    private onChange = (name: string, value: string): void => {
        let errors = this.state.errors;

        if (requiredFields.includes(name))
            errors = this.errorChecker(name, value, errors, true);

        this.setState({
            ...this.state,
            errors,
            sieve: {
                ...this.state.sieve,
                [name]: value
            }
        });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getSieveLookups))
            this.props.getSieveLookupsRequest({
                token: this.props.getToken.payload.response.token,
                Requested_Page: "Sieve"
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getSieve))
            this.props.getSieveRequest({
                token: this.props.getToken.payload.response.token
            });


        const query = queryString.parse(this.props.location.search);
        const id = query.id ?? "0";

        if ((id !== "0") && (hasPayload(this.props.getSieve)) && (this.state.sieve.id === 0)) {
            const sieve = this.props.getSieve.payload.response[id.toString()];

            sieve.measurements.forEach((x) => {
                if((x.weight !== undefined) && (x.weight === 0))
                    x.weight = undefined;
            });

            if (sieve)
                this.setState({ sieve, errors: {} });
        };

    };

}

const mapStateToProps = (state: IStore): ISieveStoreProps => ({
    getToken: getTokenStatus(state),
    getSieve: getSievesStatus(state),
    updateSieve: updateSieveStatus(state),
    getSieveLookups: getSieveLookupsStatus(state),
    addSieve: addSieveStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): ISieveDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getSieveRequest: (data: IGetSievesRequest) => dispatch(getSievesLoadAction(data)),
    addSieveRequest: (data: IAddSieveRequest) => dispatch(addSieveLoadAction(data)),
    updateSieveRequest: (data: IUpdateSieveRequest) => dispatch(updateSieveLoadAction(data)),
    getSieveLookupsRequest: (data: IGetSieveLookupsRequest) => dispatch(getSieveLookupsLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Sieve);