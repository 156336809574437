import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import { ById, SilAPIResponse } from "../../../shared/publicInterfaces";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import { callRouteWithQueryString, userName } from "../../../shared/constExports";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { LAButton } from "../../../shared/buttons";
import { AddIcon } from "../../../shared/icons";
import { WHITE_COLOR } from "../../../shared/theme";
import { ROUTE } from "../../../routes";
import PageSpacing from "../../../shared/pageSpacing";
import SilRoleCheck from "../../../shared/silRoleCheck";
import LALoading from "../../../shared/loading";
import { IGetPSLookupsRequest, IPSLookup } from "../../../../redux/sil/warehouse/baggedInventory/getSieveLookups/getPSLookupsConstaints";
import { getPSLookupsStatus } from "../../../../redux/sil/warehouse/baggedInventory/getSieveLookups/getPSLookupsAccessor";
import { getPSLookupsLoadAction } from "../../../../redux/sil/warehouse/baggedInventory/getSieveLookups/getPSLookupsActions";
import { IComplaintLogging, IGetComplaintLoggingRequest } from "../../../../redux/sil/complaintLogging/getComplaintLogging/getComplaintLoggingConstaints";
import { getComplaintLoggingStatus } from "../../../../redux/sil/complaintLogging/getComplaintLogging/getComplaintLoggingAccessor";
import { getComplaintLoggingLoadAction } from "../../../../redux/sil/complaintLogging/getComplaintLogging/getComplaintLoggingActions";

interface IComplaintLoggingsStoreProps {
    getToken: Server<SilAPIResponse<IToken>>;
    getComplaintLogging: Server<SilAPIResponse<IComplaintLogging[]>>;
    getPSLookups: Server<SilAPIResponse<IPSLookup>>;
};

interface IComplaintLoggingsDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getComplaintLoggingRequest: (data: IGetComplaintLoggingRequest) => unknown;
    getPSLookupsRequest: (data: IGetPSLookupsRequest) => unknown;
};


interface IComplaintLoggingsOwnProps {

};

interface IComplaintLoggingsState {
    data: IComplaintLogging[];
};

const ComplaintLoggingsStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .loading {
        margin-top: 5%;
    };

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
    };
`;

type IComplaintLoggingsProps = RouteComponentProps
    & IComplaintLoggingsStoreProps
    & IComplaintLoggingsDispatchProps
    & IComplaintLoggingsOwnProps;

class ComplaintLoggings extends PureComponent<IComplaintLoggingsProps, IComplaintLoggingsState> {

    public constructor(props: IComplaintLoggingsProps) {
        super(props);
        this.state = {
            data: []
        };
    }

    public componentDidMount(): void {
        if (hasPayload(this.props.getToken))
            this.props.getPSLookupsRequest({
                token: this.props.getToken.payload.response.token,
                Requested_Page: "Bagged_Inventory"
            });
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IComplaintLoggingsProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();
        };
    };


    public render(): ReactNode {

        const { data } = this.state;
        const { getComplaintLogging } = this.props;

        return (
            <PageSpacing title="SIL - Complaint Loggings" description="SIL - Complaint Loggings" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="complaintLoggingAccess">
                    <ComplaintLoggingsStyles>

                        {getComplaintLogging.kind !== STATUS_ENUM.LOADING &&
                            <LAButton className="pull-left" startIcon={<AddIcon color={WHITE_COLOR} />} label="Add" onClick={this.handleAdd} />}
                        <h2 className="text-center">Complaint Loggings</h2>
                        <hr />

                        {getComplaintLogging.kind === STATUS_ENUM.LOADING &&
                            <LALoading className="loading" message="Loading..." />}

                        {(getComplaintLogging.kind === STATUS_ENUM.SUCCEEDED) && <LADevExtremeGrid
                            data={getComplaintLogging.payload.response}
                            onEdit={this.onEdit}
                            onClick={this.onClick}
                            height={1000}
                            searchPanel={true}
                            filterHeader={true}
                            export={true}
                            storageKey="silComplaintLoggingsStorageKey"
                            exportFileName="ComplaintLoggings"
                            columns={[
                                { name: "plant", caption: "Plant", width:100, type: "string" },
                                { name: "date_Of_Complaint", caption: "Date of Compalaint",width:150, type: "datetime", sortDesc: true },
                                { name: "method_Of_Complaint", caption: "Method of Compalaint", width:100, type: "string" },
                                { name: "complainant_First_Name", caption: "First  Name", width:100, type: "string" },
                                { name: "complainant_Last_Name", caption: "Last Name",  width:100,type: "string" },
                                { name: "email_Address", caption: "Email", width:100, type: "string" },
                                { name: "description", caption: "Description", type: "string" },
                                { name: "immediate_Action_Taken", caption: "Immediate Action", width:150, type: "string" },
                                { name: "suspected_Cause", caption: "Suspected Cause", type: "string" },
                                { name: "company", caption: "Company", width:100, type: "string" },
                                { name: "modified", caption: "Modified", width:100, type: "datetime" },
                                { name: "modified_By", caption: "Modified By", width:100, type: "string" }
                            ]}
                        />}

                    </ComplaintLoggingsStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString() },
            pathName: ROUTE.SIL.COMPLAINT_LOGGINGS.COMPLAINT_LOGGING
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0" },
            pathName: ROUTE.SIL.COMPLAINT_LOGGINGS.COMPLAINT_LOGGING
        });
    };

    private setDataToState = (): void => {

        if (hasPayload(this.props.getComplaintLogging)) {
            const data: IComplaintLogging[] = this.props.getComplaintLogging.payload.response;
            this.setState({ data });
        };

        if (isNotLoaded(this.props.getComplaintLogging))
            this.callServer();
    };

    private callServer = (): void => {
        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getComplaintLogging))
            this.props.getComplaintLoggingRequest({
                token: this.props.getToken.payload.response.token
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getPSLookups))
            this.props.getPSLookupsRequest({
                token: this.props.getToken.payload.response.token,
                Requested_Page: "Bagged_Inventory"
            });


        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });
    }

}

const mapStateToProps = (state: IStore): IComplaintLoggingsStoreProps => ({
    getToken: getTokenStatus(state),
    getComplaintLogging: getComplaintLoggingStatus(state),
    getPSLookups: getPSLookupsStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IComplaintLoggingsDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getComplaintLoggingRequest: (data: IGetComplaintLoggingRequest) => dispatch(getComplaintLoggingLoadAction(data)),
    getPSLookupsRequest: (data: IGetPSLookupsRequest) => dispatch(getPSLookupsLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ComplaintLoggings);