import { SilAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { IGetCraneRequest, IGET_CRANES_REQUEST, ICrane } from "./getCranesConstaints";

export interface IGetCraneLoadAction {
    type: IGET_CRANES_REQUEST.REQUEST;
    data: IGetCraneRequest
}
export const getCraneLoadAction = (data: IGetCraneRequest): IGetCraneLoadAction => ({
    type: IGET_CRANES_REQUEST.REQUEST,
    data
});

export interface IGetCraneSuccessAction {
    type: IGET_CRANES_REQUEST.SUCCESS;
    data: SilAPIResponse<ICrane[]>;
}
export const getCraneLoadSuccessAction = (data: SilAPIResponse<ICrane[]>): IGetCraneSuccessAction => ({
    type: IGET_CRANES_REQUEST.SUCCESS,
    data
});

export interface IGetCraneLoadFailedAction {
    type: IGET_CRANES_REQUEST.FAILED;
    message: string;
}
export const getCraneLoadFailedAction = (message: string): IGetCraneLoadFailedAction => ({
    type: IGET_CRANES_REQUEST.FAILED,
    message
});
