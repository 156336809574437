import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IUpdateCraneLoadAction, IUpdateCraneLoadFailedAction, IUpdateCraneSuccessAction, updateCraneLoadFailedAction,
     updateCraneLoadSuccessAction } from "./updateCraneActions";
import { IUPDATE_CRANE_REQUEST } from "./updateCraneConstaints";
import { IStore } from "../../../../reducers";
import { EpicDependencies } from "../../../../store";
import { SilAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { END_POINTS } from "../../../../endpoints";
import { withJsonContentType } from "../../../../epicUtils";

export const updateCraneEpic: Epic = (
    action$: ActionsObservable<IUpdateCraneLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IUpdateCraneSuccessAction | IUpdateCraneLoadFailedAction> =>
    action$.ofType(IUPDATE_CRANE_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<string>>(
                    END_POINTS.PROPSENSE.SIL.CV.UpdateCVCrane,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<string>): IUpdateCraneSuccessAction | IUpdateCraneLoadFailedAction => {
                            if(response.message === "Success"){
                                return updateCraneLoadSuccessAction(action.data.request);
                            }
                            return updateCraneLoadFailedAction(response.message);
                        }),
                        catchError(() => ActionsObservable.of(updateCraneLoadFailedAction("Unable to save your changes")))
                    )
            )
        );