import { IFlushDataSuccessAction } from "../../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../../flushData.tsx/flushDataConstants";
import { Server, failed, loading, notLoaded, succeeded } from "../../../../server";
import { IUpdateSieveFracBallLoadAction, IUpdateSieveFracBallLoadFailedAction, IUpdateSieveFracBallSuccessAction } from "./updateSieveFracBallActions";
import { IUPDATE_SIEVE_FRAC_BALL_REQUEST } from "./updateSieveFracBallConstaints";


type Actions =
    IUpdateSieveFracBallLoadAction
    | IUpdateSieveFracBallLoadFailedAction
    | IUpdateSieveFracBallSuccessAction
    | IFlushDataSuccessAction;

export const UpdateSieveFracBallReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IUPDATE_SIEVE_FRAC_BALL_REQUEST.REQUEST:
            return loading;

        case IUPDATE_SIEVE_FRAC_BALL_REQUEST.SUCCESS:
            return succeeded("Success");

        case IUPDATE_SIEVE_FRAC_BALL_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};