import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IAddWashPlantProductionLoadAction, IAddWashPlantProductionLoadFailedAction, IAddWashPlantProductionSuccessAction } from "./addWashPlantProductionActions";
import { IADD_WASH_PLANT_PRODUCTION_REQUEST } from "./addWashPlantProductionConstaints";


type Actions =
    IAddWashPlantProductionLoadAction
    | IAddWashPlantProductionLoadFailedAction
    | IAddWashPlantProductionSuccessAction
    | IFlushDataSuccessAction;

export const AddWashPlantProductionReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IADD_WASH_PLANT_PRODUCTION_REQUEST.REQUEST:
            return loading;

        case IADD_WASH_PLANT_PRODUCTION_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IADD_WASH_PLANT_PRODUCTION_REQUEST.FAILED:
            return failed(action.message);

            case FLUSH_DATA.SUCCESS:
                return notLoaded;

        default:
            return state;
    }
};