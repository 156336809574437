import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { getEmployeesStatus } from "../../../../redux/sil/dryPlant/getEmployees/getEmployeesAccessor";
import { getEmployeesLoadAction } from "../../../../redux/sil/dryPlant/getEmployees/getEmployeesActions";
import { IEmployees, IGetEmployeesRequest } from "../../../../redux/sil/dryPlant/getEmployees/getEmployeesConstaints";
import { hasPayload, isNotLoaded, Server } from "../../../../redux/server";
import { SilAPIResponse } from "../../../shared/publicInterfaces";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import { callRouteWithQueryString, userName } from "../../../shared/constExports";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { LAButton } from "../../../shared/buttons";
import { AddIcon } from "../../../shared/icons";
import { WHITE_COLOR } from "../../../shared/theme";
import { ROUTE } from "../../../routes";
import PageSpacing from "../../../shared/pageSpacing";
import SilRoleCheck from "../../../shared/silRoleCheck";


interface IDryPlantEmployeesStoreProps {
    getToken: Server<SilAPIResponse<IToken>>;
    getEmployees: Server<SilAPIResponse<IEmployees[]>>;
};

interface IDryPlantEmployeesDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getEmployeesRequest: (data: IGetEmployeesRequest) => unknown;
};


interface IDryPlantEmployeesOwnProps {

};

interface IDryPlantEmployeesState {

};

const DryPlantEmployeesStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
    };
`;

type IDryPlantEmployeesProps = RouteComponentProps
    & IDryPlantEmployeesStoreProps
    & IDryPlantEmployeesDispatchProps
    & IDryPlantEmployeesOwnProps;

class DryPlantEmployees extends PureComponent<IDryPlantEmployeesProps, IDryPlantEmployeesState> {

    public constructor(props: IDryPlantEmployeesProps) {
        super(props);
        this.state = {
        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: IDryPlantEmployeesProps): void {
        if (this.props !== prevProps)
            this.callServer();
    };


    public render(): ReactNode {

        const { getEmployees } = this.props;
        const data = hasPayload(getEmployees) ? getEmployees.payload.response : [];

        return (
            <PageSpacing title="SIL - Employees" description="SIL - Employees" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="dryPlantAccess">
                    <DryPlantEmployeesStyles>

                        <LAButton className="pull-left" startIcon={<AddIcon color={WHITE_COLOR} />} label="Add" onClick={this.handleAdd} />
                        <h2 className="text-center">PLANT EMPLOYEE</h2>
                        <hr />

                        <LADevExtremeGrid
                            data={data}
                            onEdit={this.onEdit}
                            onClick={this.onClick}
                            searchPanel={true}
                            filterHeader={true}
                            export={true}
                            exportFileName={"Plant Employees"}
                            storageKey="silPlantEmployeesStorageKey"
                            columns={[
                                { name: "employee_Name", caption: "Name", type: "string", width: 180 },
                                { name: "location", caption: "Location", type: "string" },
                                { name: "active", caption: "Active", type: "string" },
                                { name: "created", caption: "Created", type: "date" },
                                { name: "modified", caption: "Modified", type: "date" },
                                { name: "created_By", caption: "Created By", type: "string" },
                                { name: "modified_By", caption: "Modified By", type: "string" }
                            ]}
                        />
                    </DryPlantEmployeesStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString() },
            pathName: ROUTE.SIL.DRY_PLANT.DRY_PLANT_EMPLOYEE
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0" },
            pathName: ROUTE.SIL.DRY_PLANT.DRY_PLANT_EMPLOYEE
        });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });


        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getEmployees))
            this.props.getEmployeesRequest({
                token: this.props.getToken.payload.response.token
            });
    };

}

const mapStateToProps = (state: IStore): IDryPlantEmployeesStoreProps => ({
    getToken: getTokenStatus(state),
    getEmployees: getEmployeesStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IDryPlantEmployeesDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getEmployeesRequest: (data: IGetEmployeesRequest) => dispatch(getEmployeesLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(DryPlantEmployees);