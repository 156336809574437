import { SimosAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetSalesOrderNumbersByCustomerIDRequest, IGET_SALES_ORDER_NUMBER_BY_CUSTOMER_ID_REQUEST, ISalesOrderNumbersByCustomerID } from "./getSalesOrderNumbersByCustomerIDConstaints";

export interface IGetSalesOrderNumbersByCustomerIDLoadAction {
    type: IGET_SALES_ORDER_NUMBER_BY_CUSTOMER_ID_REQUEST.REQUEST;
    data: IGetSalesOrderNumbersByCustomerIDRequest
}
export const getSalesOrderNumbersByCustomerIDLoadAction = (data: IGetSalesOrderNumbersByCustomerIDRequest): IGetSalesOrderNumbersByCustomerIDLoadAction => ({
    type: IGET_SALES_ORDER_NUMBER_BY_CUSTOMER_ID_REQUEST.REQUEST,
    data
});

export interface IGetSalesOrderNumbersByCustomerIDSuccessAction {
    type: IGET_SALES_ORDER_NUMBER_BY_CUSTOMER_ID_REQUEST.SUCCESS;
    data: SimosAPIResponse<ISalesOrderNumbersByCustomerID[]>;
}
export const getSalesOrderNumbersByCustomerIDLoadSuccessAction = (data: SimosAPIResponse<ISalesOrderNumbersByCustomerID[]>): IGetSalesOrderNumbersByCustomerIDSuccessAction => ({
    type: IGET_SALES_ORDER_NUMBER_BY_CUSTOMER_ID_REQUEST.SUCCESS,
    data
});

export interface IGetSalesOrderNumbersByCustomerIDLoadFailedAction {
    type: IGET_SALES_ORDER_NUMBER_BY_CUSTOMER_ID_REQUEST.FAILED;
    message: string;
}
export const getSalesOrderNumbersByCustomerIDLoadFailedAction = (message: string): IGetSalesOrderNumbersByCustomerIDLoadFailedAction => ({
    type: IGET_SALES_ORDER_NUMBER_BY_CUSTOMER_ID_REQUEST.FAILED,
    message
});
