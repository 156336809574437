import { ById, SimosAPIResponse } from "../../../../../react/shared/publicInterfaces";
import { IGET_SIMOS_TRANSPORT_COMPANIES_REQUEST, ISimosTransportCompanies, ISimosTransportCompaniesRequest } from "./getSimosTransportCompaniesConstants";


export interface IGetSimosTransportCompaniesLoadAction {
    type: IGET_SIMOS_TRANSPORT_COMPANIES_REQUEST.REQUEST;
    data: ISimosTransportCompaniesRequest
}
export const getSimosTransportCompaniesLoadAction = (data: ISimosTransportCompaniesRequest): IGetSimosTransportCompaniesLoadAction => ({
    type: IGET_SIMOS_TRANSPORT_COMPANIES_REQUEST.REQUEST,
    data
});
export interface IGetSimosTransportCompaniesSuccessAction {
    type: IGET_SIMOS_TRANSPORT_COMPANIES_REQUEST.SUCCESS;
    data: SimosAPIResponse<ISimosTransportCompanies[]>;
}
export const getSimosTransportCompaniesLoadSuccessAction = (data: SimosAPIResponse<ISimosTransportCompanies[]>): IGetSimosTransportCompaniesSuccessAction => ({
    type: IGET_SIMOS_TRANSPORT_COMPANIES_REQUEST.SUCCESS,
    data
});
export interface IGetSimosTransportCompaniesLoadFailedAction {
    type: IGET_SIMOS_TRANSPORT_COMPANIES_REQUEST.FAILED;
    message: string;
}
export const getSimosTransportCompaniesLoadFailedAction = (message: string): IGetSimosTransportCompaniesLoadFailedAction => ({
    type: IGET_SIMOS_TRANSPORT_COMPANIES_REQUEST.FAILED,
    message
});
