import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, isSucceeded, Server } from "../../../../redux/server";
import { ById, SilAPIResponse } from "../../../shared/publicInterfaces";
import { userName, undefinedFunction, monthNames, getYearsList, yesOrNoOptions, deleteText } from "../../../shared/constExports";
import { LAButton, LAIconButton, LASaveAndCancelButton } from "../../../shared/buttons";
import { ArrowLeftIcon, DeleteIcon } from "../../../shared/icons";
import { WHITE_COLOR } from "../../../shared/theme";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import LAAutoComplete from "../../../shared/autoComplete";
import { ROUTE } from "../../../routes";
import queryString from "query-string";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import { FieldValidator, FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../../shared/fieldValidator";
import PageSpacing from "../../../shared/pageSpacing";
import { IGetTokenRequest, IToken } from "../../../../redux/sil/dryPlant/token/getTokenConstants";
import { getTokenStatus } from "../../../../redux/sil/dryPlant/token/getTokenAccessor";
import { getTokenLoadAction } from "../../../../redux/sil/dryPlant/token/getTokenActions";
import SilRoleCheck from "../../../shared/silRoleCheck";
import { IGetTruckScaleRequest, ITruckScale } from "../../../../redux/sil/cv/truckScales/getTruckScales/getTruckScalesConstaints";
import { IAddTruckScaleRequest } from "../../../../redux/sil/cv/truckScales/addTruckScale/addTruckScaleConstaints";
import { IUpdateTruckScaleRequest } from "../../../../redux/sil/cv/truckScales/updateTruckScale/updateTruckScaleConstaints";
import { getTruckScaleStatus } from "../../../../redux/sil/cv/truckScales/getTruckScales/getTruckScalesAccessor";
import { addTruckScaleStatus } from "../../../../redux/sil/cv/truckScales/addTruckScale/addTruckScaleAccessor";
import { updateTruckScaleStatus } from "../../../../redux/sil/cv/truckScales/updateTruckScale/updateTruckScaleAccessor";
import { getTruckScaleLoadAction } from "../../../../redux/sil/cv/truckScales/getTruckScales/getTruckScalesActions";
import { addTruckScaleLoadAction } from "../../../../redux/sil/cv/truckScales/addTruckScale/addTruckScaleActions";
import { updateTruckScaleLoadAction } from "../../../../redux/sil/cv/truckScales/updateTruckScale/updateTruckScaleActions";
import { IIdName } from "../../../../utils/sharedTypes";
import { LADropDone } from "../../../shared/dragDropFiles";
import LALinkButton from "../../../shared/linkButton";
import { END_POINTS } from "../../../../redux/endpoints";


const scaleList: IIdName<number>[] = [
    { id: 0, name: "BR A1/A2" },
    { id: 1, name: "BR Truck In" },
    { id: 2, name: "BR Truck Out" },
    { id: 3, name: "BR X4" },
    { id: 4, name: "BR Warehouse" },
    { id: 5, name: "Fox Creek" },
    { id: 6, name: "Lloyd" },
    { id: 7, name: "Marlboro" },
    { id: 8, name: "Netherhill" }
];

interface ITruckScaleStoreProps {
    addTruckScale: Server<string>;
    updateTruckScale: Server<string>;
    getToken: Server<SilAPIResponse<IToken>>;
    getTruckScales: Server<SilAPIResponse<ITruckScale[]>>;
};

interface ITruckScaleDispatchProps {
    getTokenRequest: (data: IGetTokenRequest) => unknown;
    getTruckScalesRequest: (data: IGetTruckScaleRequest) => unknown;
    addTruckScaleRequest: (data: IAddTruckScaleRequest) => unknown;
    updateTruckScaleRequest: (data: IUpdateTruckScaleRequest) => unknown;
};


interface ITruckScaleOwnProps {

};

interface ITruckScaleState {
    truckScale: ITruckScale;
    errors: ById<IFieldErrorKeyValue>;
};

const TruckScaleStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
`;

type ITruckScaleProps = RouteComponentProps
    & ITruckScaleStoreProps
    & ITruckScaleDispatchProps
    & ITruckScaleOwnProps;

class TruckScale extends PureComponent<ITruckScaleProps, ITruckScaleState> {

    public constructor(props: ITruckScaleProps) {
        super(props);
        this.state = {
            errors: {
                "scale": { key: "scale", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "month": { key: "month", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "year": { key: "year", message: FIELD_VALIDATOR_ERRORS.REQUIRED }
            },
            truckScale: {
                id: 0,
                scale: "",
                active: yesOrNoOptions[0].name,
                latest: yesOrNoOptions[0].name,
                attachments: "",
                attachmentContent: [],
                created: "",
                created_By: userName,
                modified: "",
                modified_By: userName
            }
        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: ITruckScaleProps): void {
        if (this.props !== prevProps) {
            this.callServer();

            if (this.props.addTruckScale !== prevProps.addTruckScale) {
                if (isSucceeded(this.props.addTruckScale)) {
                    this.handleCancel();
                };
            };

            if (this.props.updateTruckScale !== prevProps.updateTruckScale) {
                if (isSucceeded(this.props.updateTruckScale)) {
                    this.handleCancel();
                };
            };
        };
    };


    public render(): ReactNode {

        const { updateTruckScale, addTruckScale } = this.props;
        const { truckScale, errors } = this.state;

        const isAdd = truckScale.id > 0 ? false : true;
        const years = getYearsList(2018);

        return (
            <PageSpacing title="SIL - CV" description="Truck Scale" fixedSpaceOnSmallerScreens={true}>
                <SilRoleCheck error={true} roleFor="carrierAccess">
                    <TruckScaleStyles>

                        <LAButton startIcon={<ArrowLeftIcon color={WHITE_COLOR} />} label="Back to list" onClick={this.handleCancel} />
                        <h2 className="text-center">{!isAdd ? "VIEW " : "ADD "} LAB SCALE</h2>
                        <hr />

                        <LAPaperWithPadding>
                            <LAGrid spacing={3}>

                                <LAGridItem xs={4}>
                                <LAAutoComplete
                                        filterSelectedOptions={true}
                                        autoHighlight={true}
                                        selectionRemove={undefinedFunction}
                                        dropDownPlaceHolder="Month"
                                        getOptionLabel="name"
                                        name="month"
                                        option={monthNames}
                                        multiple={false}
                                        onChange={this.handleDropDownIdChange}
                                        value={truckScale.month ? monthNames.find(x => x.id === truckScale.month) : ""}
                                        defaultValue={truckScale.month ? monthNames.find(x => x.id === truckScale.month) : ""}
                                        errorText={errors["month"] ? errors["month"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                <LAAutoComplete
                                        filterSelectedOptions={true}
                                        autoHighlight={true}
                                        selectionRemove={undefinedFunction}
                                        dropDownPlaceHolder="Year"
                                        getOptionLabel="name"
                                        name="year"
                                        option={years}
                                        multiple={false}
                                        onChange={this.handleDropDownIdChange}
                                        value={truckScale.year ? years.find(x => x.id === truckScale.year) : ""}
                                        defaultValue={truckScale.year ? years.find(x => x.id === truckScale.year) : ""}
                                        errorText={errors["year"] ? errors["year"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LAAutoComplete
                                        filterSelectedOptions={true}
                                        autoHighlight={true}
                                        selectionRemove={undefinedFunction}
                                        dropDownPlaceHolder="Scale"
                                        getOptionLabel="name"
                                        name="scale"
                                        option={scaleList}
                                        multiple={false}
                                        onChange={this.handleDropDownChange}
                                        value={scaleList.find(x => x.name === truckScale.scale)}
                                        defaultValue={scaleList.find(x => x.name === truckScale.scale)}
                                        errorText={errors["scale"] ? errors["scale"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LAAutoComplete
                                        filterSelectedOptions={true}
                                        autoHighlight={true}
                                        selectionRemove={undefinedFunction}
                                        dropDownPlaceHolder="Active"
                                        getOptionLabel="name"
                                        name="active"
                                        option={yesOrNoOptions}
                                        multiple={false}
                                        onChange={this.handleDropDownChange}
                                        value={yesOrNoOptions.find(x => x.name === truckScale.active)}
                                        defaultValue={yesOrNoOptions.find(x => x.name === truckScale.active)}
                                        errorText={errors["active"] ? errors["active"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={4}>
                                    <LAAutoComplete
                                        filterSelectedOptions={true}
                                        autoHighlight={true}
                                        selectionRemove={undefinedFunction}
                                        dropDownPlaceHolder="Latest"
                                        getOptionLabel="name"
                                        name="latest"
                                        option={yesOrNoOptions}
                                        multiple={false}
                                        onChange={this.handleDropDownChange}
                                        value={yesOrNoOptions.find(x => x.name === truckScale.latest)}
                                        defaultValue={yesOrNoOptions.find(x => x.name === truckScale.latest)}
                                        errorText={errors["latest"] ? errors["latest"].message : undefined}
                                    />
                                </LAGridItem>
                                
                                <LAGridItem xs={6} className="text-center">
                                    <strong>Calibration Form</strong>
                                    <LADropDone onFileDrop={(e: Array<File | DataTransferItem>) => this.handleAtt(e)} />
                                    {truckScale.attachments && truckScale.attachments.length > 0 && <LALinkButton className="pl-5" label={truckScale.attachments} onClick={this.handleDownloadAtt} />}
                                    {truckScale.attachments && <LAIconButton
                                        name="attachment_Name"
                                        label="Remove"
                                        icon={<DeleteIcon />}
                                        onClick={this.handleDeleteAtt}
                                    />}
                                </LAGridItem>

                                <LAGridItem xs={12}>
                                    <hr />
                                    <LASaveAndCancelButton
                                        onSave={this.onSave}
                                        onCancel={this.handleCancel}
                                        disableSave={(Object.values(errors).length > 0 ? true : undefined)}
                                    />
                                </LAGridItem>

                            </LAGrid>
                        </LAPaperWithPadding>

                        <RequestStatus requestStatus={addTruckScale.kind} successMessage="Truck Scale successfully saved" />
                        <RequestStatus requestStatus={updateTruckScale.kind} successMessage="Truck Scale successfully updated" />
                    </TruckScaleStyles>
                </SilRoleCheck>
            </PageSpacing>
        );
    }

    private handleDeleteAtt = (): void => {
        const selection = window.confirm(deleteText);
        if(selection) {
            this.setState({
                truckScale: {
                    ...this.state.truckScale,
                    attachments: "",
                    attachmentContent: []
                }
            });
        }
    };

    private handleDownloadAtt = (): void => {
        const { truckScale } = this.state;
        if (truckScale.attachments) {
            if ((truckScale.attachmentContent !== null) && (truckScale.attachmentContent[0] !== undefined) && (truckScale.attachmentContent[0].Base64String !== undefined)) {
                let a = document.createElement("a");
                a.href = truckScale.attachmentContent[0].Base64String;
                a.download = truckScale.attachments;
                a.click();
            };

            if ((truckScale.attachmentContent === null) || (truckScale.attachmentContent[0] === undefined)) {
                if (hasPayload(this.props.getToken) && truckScale.id) {

                    fetch(END_POINTS.PROPSENSE.SIL.CV.GetCVFile, {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            request: {
                                "ID": truckScale.id,
                                "File": truckScale.attachments,
                                "Page": "Truck_Scale"
                            },
                            token: this.props.getToken.payload.response.token
                        })
                    })
                        .then((res: any) => {
                            return res.blob();
                        })
                        .then(blob => {
                            if (truckScale.attachments) {
                                let url = window.URL.createObjectURL(blob);
                                let a = document.createElement('a');
                                a.href = url;
                                a.download = truckScale.attachments;
                                document.body.appendChild(a);
                                a.click();
                                a.remove();
                            }
                        })
                        .catch((res) => console.log(res));
                };
            };
        }
    };

    private handleAtt = (files: Array<File | DataTransferItem>): void => {
        new Promise((resolve, reject): any => {
            if (files && (files !== null)) {

                Object.values(files).forEach((x: any, index, array) => {
                    const reader = new FileReader();

                    reader.onloadend = async (e): Promise<void> => {
                        if (reader.result !== null) {
                            this.setState({
                                ...this.state,
                                truckScale: {
                                    ...this.state.truckScale,
                                    attachments: x.name,
                                    attachmentContent: [{
                                        Name: x.name,
                                        Base64String: reader.result.toString()
                                    }]
                                },
                            });
                            resolve(true);
                        };
                    };
                    reader.readAsDataURL(x);
                })
            } else {
                reject();
            }
        });
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, isRequired: boolean): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: isRequired ? true : undefined, minLength: 1, decimal: undefined });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        };
        return err;
    };

    private handleCancel = (): void => {
        this.props.history.push(ROUTE.SIL.CV.TRUCK_SCALES.INDEX);
    };

    private onSave = (): void => {
        const { truckScale } = this.state;

        if (hasPayload(this.props.getToken))
            if (truckScale.id === 0) {
                this.props.addTruckScaleRequest({
                    token: this.props.getToken.payload.response.token,
                    request: truckScale
                });
            } else {
                truckScale.modified_By = userName;
                
                this.props.updateTruckScaleRequest({
                    token: this.props.getToken.payload.response.token,
                    request: truckScale
                });
            };
    };

    private handleDropDownChange = (event: unknown, value: { id: number, name: string } | "", name?: string): void => {
        if (name){
            let errors = this.state.errors;
            errors = this.errorChecker(name, value !== "" ? value.name : "", errors, true);

            this.setState({
                ...this.state,
                errors,
                truckScale: {
                    ...this.state.truckScale,
                    [name]: value !== "" ? value.name : ""
                }
            });
        }
    };

    private handleDropDownIdChange = (event: unknown, value: { id: number, name: string } | "", name?: string): void => {
        if (name){
            let errors = this.state.errors;
            errors = this.errorChecker(name, value !== "" ? value.name : "", errors, true);

            this.setState({
                ...this.state,
                errors,
                truckScale: {
                    ...this.state.truckScale,
                    [name]: value !== "" ? value.id : undefined
                }
            });
        }
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: userName
                }
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getTruckScales))
            this.props.getTruckScalesRequest({
                token: this.props.getToken.payload.response.token
            });


        const query = queryString.parse(this.props.location.search);
        const id = query.id ?? "0";

        if ((id !== "0") && (hasPayload(this.props.getTruckScales))) {
            const truckScale = this.props.getTruckScales.payload.response.find(x => x.id === Number(id));

            if(truckScale)
                this.setState({ truckScale, errors: {} });
        };

    };

}

const mapStateToProps = (state: IStore): ITruckScaleStoreProps => ({
    getToken: getTokenStatus(state),
    getTruckScales: getTruckScaleStatus(state),
    addTruckScale: addTruckScaleStatus(state),
    updateTruckScale: updateTruckScaleStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): ITruckScaleDispatchProps => ({
    getTokenRequest: (data: IGetTokenRequest) => dispatch(getTokenLoadAction(data)),
    getTruckScalesRequest: (data: IGetTruckScaleRequest) => dispatch(getTruckScaleLoadAction(data)),
    addTruckScaleRequest: (data: IAddTruckScaleRequest) => dispatch(addTruckScaleLoadAction(data)),
    updateTruckScaleRequest: (data: IUpdateTruckScaleRequest) => dispatch(updateTruckScaleLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(TruckScale);