import { IFlushDataSuccessAction } from "../../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../../server";
import { IAddYHDCarrierInfractionLoadAction, IAddYHDCarrierInfractionLoadFailedAction, IAddYHDCarrierInfractionSuccessAction } from "./addCarrierInfractionActions";
import { IADD_YHD_CARRIER_INFRACTION_REQUEST } from "./addCarrierInfractionConstaints";


type Actions =
    IAddYHDCarrierInfractionLoadAction
    | IAddYHDCarrierInfractionLoadFailedAction
    | IAddYHDCarrierInfractionSuccessAction
    | IFlushDataSuccessAction;

export const AddYHDCarrierInfractionReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IADD_YHD_CARRIER_INFRACTION_REQUEST.REQUEST:
            return loading;

        case IADD_YHD_CARRIER_INFRACTION_REQUEST.SUCCESS:
            return succeeded("Success");

        case IADD_YHD_CARRIER_INFRACTION_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};