import { IFlushDataSuccessAction } from "../../../flushData.tsx/flushDataActions";
import { FLUSH_DATA } from "../../../flushData.tsx/flushDataConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IUPDATE_TRANSLOAD_BOL_COUNT_REQUEST } from "./updateTransloadBOLCountConstaints";
import { IUpdateTransloadBOLCountLoadAction, IUpdateTransloadBOLCountLoadFailedAction, IUpdateTransloadBOLCountSuccessAction } from "./updateTransloadBOLCountActions";

type Actions =
    IUpdateTransloadBOLCountLoadAction
    | IUpdateTransloadBOLCountLoadFailedAction
    | IUpdateTransloadBOLCountSuccessAction
    | IFlushDataSuccessAction;

export const UpdateTransloadBOLCountReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IUPDATE_TRANSLOAD_BOL_COUNT_REQUEST.REQUEST:
            return loading;

        case IUPDATE_TRANSLOAD_BOL_COUNT_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IUPDATE_TRANSLOAD_BOL_COUNT_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};