import { IUpdateBaggedInventoryRequest, IUPDATE_BAGGED_INVENTORY_REQUEST } from "./updateBaggedInventoryConstaints";

export interface IUpdateBaggedInventoryLoadAction {
    type: IUPDATE_BAGGED_INVENTORY_REQUEST.REQUEST;
    data: IUpdateBaggedInventoryRequest
}
export const updateBaggedInventoryLoadAction = (data: IUpdateBaggedInventoryRequest): IUpdateBaggedInventoryLoadAction => ({
    type: IUPDATE_BAGGED_INVENTORY_REQUEST.REQUEST,
    data
});

export interface IUpdateBaggedInventorySuccessAction {
    type: IUPDATE_BAGGED_INVENTORY_REQUEST.SUCCESS;
    message: string;
}
export const updateBaggedInventoryLoadSuccessAction = (message: string): IUpdateBaggedInventorySuccessAction => ({
    type: IUPDATE_BAGGED_INVENTORY_REQUEST.SUCCESS,
    message
});

export interface IUpdateBaggedInventoryLoadFailedAction {
    type: IUPDATE_BAGGED_INVENTORY_REQUEST.FAILED;
    message: string;
}
export const updateBaggedInventoryLoadFailedAction = (message: string): IUpdateBaggedInventoryLoadFailedAction => ({
    type: IUPDATE_BAGGED_INVENTORY_REQUEST.FAILED,
    message
});
