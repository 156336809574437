import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import {
    IAddSalesOrderNumberLoadAction, IAddSalesOrderNumberLoadFailedAction, IAddSalesOrderNumberSuccessAction, 
    addSalesOrderNumberLoadFailedAction, addSalesOrderNumberLoadSuccessAction
} from "./addSalesOrderNumberActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IADD_SALES_ORDER_NUMBER_REQUEST } from "./addSalesOrderNumberConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SimosAPIResponse } from "../../../../react/shared/publicInterfaces";

export const addSalesOrderNumberEpic: Epic = (
    action$: ActionsObservable<IAddSalesOrderNumberLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IAddSalesOrderNumberSuccessAction | IAddSalesOrderNumberLoadFailedAction> =>
    action$.ofType(IADD_SALES_ORDER_NUMBER_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SimosAPIResponse<string>>(
                    END_POINTS.PROPSENSE.SIMOS.ADD_SALES_ORDER_NUMBER,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SimosAPIResponse<string>): IAddSalesOrderNumberSuccessAction | IAddSalesOrderNumberLoadFailedAction => {
                            if(response.message !== "Success")
                                return addSalesOrderNumberLoadFailedAction(response.message);
                            return addSalesOrderNumberLoadSuccessAction(response.message);
                        }),
                        catchError(() => ActionsObservable.of(addSalesOrderNumberLoadFailedAction("Unable to add sales order number")))
                    )
            )
        );