import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetWashPlantProductionLookupsRequest, IWashPlantProductionLookups } from "../getWashPlantProductionLookups/getWashPlantProductionLookupsConstaints";
import { IGET_WASH_PLANT_PRODUCT_LOOKUPS_REQUEST } from "./getWashPlantProductLookupsConstaints";

export interface IGetWashPlantProductLookupsLoadAction {
    type: IGET_WASH_PLANT_PRODUCT_LOOKUPS_REQUEST.REQUEST;
    data: IGetWashPlantProductionLookupsRequest
}
export const getWashPlantProductLookupsLoadAction = (data: IGetWashPlantProductionLookupsRequest): IGetWashPlantProductLookupsLoadAction => ({
    type: IGET_WASH_PLANT_PRODUCT_LOOKUPS_REQUEST.REQUEST,
    data
});

export interface IGetWashPlantProductLookupsSuccessAction {
    type: IGET_WASH_PLANT_PRODUCT_LOOKUPS_REQUEST.SUCCESS;
    data: SilAPIResponse<IWashPlantProductionLookups>;
}
export const getWashPlantProductLookupsLoadSuccessAction = (data: SilAPIResponse<IWashPlantProductionLookups>): IGetWashPlantProductLookupsSuccessAction => ({
    type: IGET_WASH_PLANT_PRODUCT_LOOKUPS_REQUEST.SUCCESS,
    data
});

export interface IGetWashPlantProductLookupsLoadFailedAction {
    type: IGET_WASH_PLANT_PRODUCT_LOOKUPS_REQUEST.FAILED;
    message: string;
}
export const getWashPlantProductLookupsLoadFailedAction = (message: string): IGetWashPlantProductLookupsLoadFailedAction => ({
    type: IGET_WASH_PLANT_PRODUCT_LOOKUPS_REQUEST.FAILED,
    message
});
