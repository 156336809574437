import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IUpdateBinAdjustmentLoadAction, IUpdateBinAdjustmentLoadFailedAction, IUpdateBinAdjustmentSuccessAction, updateBinAdjustmentLoadFailedAction,
     updateBinAdjustmentLoadSuccessAction } from "./updateBinAdjustmentActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IUPDATE_BIN_ADJUSTMENT_REQUEST } from "./updateBinAdjustmentConstaints";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";
import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";

export const updateBinAdjustmentEpic: Epic = (
    action$: ActionsObservable<IUpdateBinAdjustmentLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IUpdateBinAdjustmentSuccessAction | IUpdateBinAdjustmentLoadFailedAction> =>
    action$.ofType(IUPDATE_BIN_ADJUSTMENT_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SilAPIResponse<string>>(
                    END_POINTS.PROPSENSE.SIL.DRY_PLANT.UPDATE_DRY_PLANT_BIN_ADJUSTMENT,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SilAPIResponse<string>): IUpdateBinAdjustmentSuccessAction | IUpdateBinAdjustmentLoadFailedAction => {
                            if(response.message === "Success"){
                                return updateBinAdjustmentLoadSuccessAction(action.data.request);
                            }
                            return updateBinAdjustmentLoadFailedAction(response.message);
                        }),
                        catchError(() => ActionsObservable.of(updateBinAdjustmentLoadFailedAction("Unable to save your changes")))
                    )
            )
        );