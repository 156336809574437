import { SilAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetTransloadBOLCountsRequest, IGET_TRANSLOAD_BOL_COUNTS_REQUEST, ITransloadBOLCount } from "./getTransloadBOLCountsConstaints";

export interface IGetTransloadBOLCountsLoadAction {
    type: IGET_TRANSLOAD_BOL_COUNTS_REQUEST.REQUEST;
    data: IGetTransloadBOLCountsRequest
}
export const getTransloadBOLCountsLoadAction = (data: IGetTransloadBOLCountsRequest): IGetTransloadBOLCountsLoadAction => ({
    type: IGET_TRANSLOAD_BOL_COUNTS_REQUEST.REQUEST,
    data
});

export interface IGetTransloadBOLCountsSuccessAction {
    type: IGET_TRANSLOAD_BOL_COUNTS_REQUEST.SUCCESS;
    data: SilAPIResponse<ITransloadBOLCount[]>;
}
export const getTransloadBOLCountsLoadSuccessAction = (data: SilAPIResponse<ITransloadBOLCount[]>): IGetTransloadBOLCountsSuccessAction => ({
    type: IGET_TRANSLOAD_BOL_COUNTS_REQUEST.SUCCESS,
    data
});

export interface IGetTransloadBOLCountsLoadFailedAction {
    type: IGET_TRANSLOAD_BOL_COUNTS_REQUEST.FAILED;
    message: string;
}
export const getTransloadBOLCountsLoadFailedAction = (message: string): IGetTransloadBOLCountsLoadFailedAction => ({
    type: IGET_TRANSLOAD_BOL_COUNTS_REQUEST.FAILED,
    message
});
